import gql from 'graphql-tag'
import duck from '../../../duck'
import getIdArrForQuery from '../../../utils/getIdArrForQuery'


const addLearningSlide = async (learningSlideId,input,learningObjectivesIds=[],practiceQuestionsIds=[]) =>
  duck.query({
    query: gql`
    mutation($input: LearningSlideUpdate) {
        updateLearningSlide(id:"${learningSlideId}",input: $input,
        ${learningObjectivesIds.length > 0 ? `learningObjectivesConnectIds: [${getIdArrForQuery(learningObjectivesIds)}]` : ''},
        ${practiceQuestionsIds.length > 0 ? `practiceQuestionsConnectIds: [${getIdArrForQuery(practiceQuestionsIds)}]` : ''}) {
        id
    }
    }
    `,
    variables: {
      input
    },
    type: 'updateLearningSlides/update',
    key: 'updateLearningSlides',
  })

export default addLearningSlide
