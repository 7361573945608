import gql from 'graphql-tag'
import duck from '../../duck'

const updateMentorMenteeSession = async (id, input, key) => (
  duck.query({
    query: gql`
        mutation($input:MentorMenteeSessionUpdate) {
          updateMentorMenteeSession(id:"${id}",input:$input) {
            id
            sessionCommentByMentor
            createdAt
            salesOperation{
              id
              leadStatus
              enrollmentType
            }
            course{
              id
              title
              thumbnail{
                uri
              }
              secondaryCategory
              topics(filter:{status:published}){
                id
                order
                title
                thumbnailSmall{
                  uri
                }
              }
            }
            topic {
              id
              title
              order
            }
            sessionStatus
            sessionStartDate
            sessionEndDate
            sessionRecordingLink
            isFeedbackSubmitted
            attentionLevel
            previousSessionUnderstandingLevel
            currentSessionUnderstandingLevel
            learningSpeed
            analyticSkills
            problemSolvingAbility
            interestInLearning
            eagerness
            triedToReachParent
            menteeSession {
              id
              bookingDate
              slot0
              slot1
              slot2
              slot3
              slot4
              slot5
              slot6
              slot7
              slot8
              slot9
              slot10
              slot11
              slot12
              slot13
              slot14
              slot15
              slot16
              slot17
              slot18
              slot19
              slot20
              slot21
              slot22
              slot23
              user {
                id
                name
              }
            }
            mentorSession{
              user{
                id
                name
                mentorProfile{
                  googleMeetLink
                  sessionLink
                }
              }
            }
          }
        }
     `,
    variables: {
      input
    },
    type: (key === 'mentorSession') ? 'mentorSessions/update' : 'mentorMenteeSessions/update',
    key: (key === 'mentorSession') ? 'updateMentorMenteeSession' : 'mentorClasses',
  })
)

export default updateMentorMenteeSession
