import { Switch } from 'antd';
import React, { Component } from 'react';
import { DivFlexStyle } from '../TeachersTab.styles';

export default class ClassRoomControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CreatingAClassroom: 0,
            SendNotices: 0,
            AddInviteStudent: 0,
            AccessRecordings: 0,
            LiveSessionProgress: 0,
        }
    }
    render() {
        const { schoolName, mentorProfileRowData, setMentorProfileRowData } = this.props;

        return (
            <>

                <div style={{ margin: "20px" }}>
                    <DivFlexStyle>
                        <div>Creating a Classroom</div>
                        <Switch onChange={() => {
                            const obj = {
                                Name: mentorProfileRowData.Name,
                                Email: mentorProfileRowData.Email,
                                Phone: mentorProfileRowData.Phone,
                                setPassword: mentorProfileRowData.setPassword,
                                confirmPassword: mentorProfileRowData.confirmPassword,
                                SelectGradesId:mentorProfileRowData.SelectGradesId,
                                SelectGrades: mentorProfileRowData.SelectGrades,
                                AccessType: mentorProfileRowData.AccessType,
                                displayName: mentorProfileRowData.displayName,
                                CountryCode:mentorProfileRowData.CountryCode,
                                SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
                                SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
                                ReschedulingClass: mentorProfileRowData.ReschedulingClass,
                                SchedulingClass: mentorProfileRowData.SchedulingClass,
                                DeleteSessions: mentorProfileRowData.DeleteSessions,

                                CreatingAClassroom: mentorProfileRowData.CreatingAClassroom === 0 ? 1 : 0,
                                SendNotices: mentorProfileRowData.SendNotices,
                                AddInviteStudent: mentorProfileRowData.AddInviteStudent,
                                AccessRecordings: mentorProfileRowData.AccessRecordings,
                                LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

                                AccessCourseControl: mentorProfileRowData.AccessCourseControl,
                                CreateTests: mentorProfileRowData.CreateTests,
                                EvaluateTests: mentorProfileRowData.EvaluateTests,
                                AddToQBanks: mentorProfileRowData.AddToQBanks,

                                AccessReports: mentorProfileRowData.AccessReports,
                                DownloadReports: mentorProfileRowData.DownloadReports,
                                ShareReports: mentorProfileRowData.ShareReports,
                            }
                            setMentorProfileRowData(obj)
                        }}
                            checked={mentorProfileRowData.CreatingAClassroom === 0 ? true : false}
                        />
                    </DivFlexStyle>
                    <DivFlexStyle>
                        <div>Send Notices</div>
                        <Switch onChange={() => {
                            const obj = {
                                Name: mentorProfileRowData.Name,
                                Email: mentorProfileRowData.Email,
                                Phone: mentorProfileRowData.Phone,
                                setPassword: mentorProfileRowData.setPassword,
                                confirmPassword: mentorProfileRowData.confirmPassword,
                                SelectGradesId:mentorProfileRowData.SelectGradesId,
                                SelectGrades: mentorProfileRowData.SelectGrades,
                                AccessType: mentorProfileRowData.AccessType,
                                displayName: mentorProfileRowData.displayName,
                                CountryCode:mentorProfileRowData.CountryCode,
                                SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
                                SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
                                ReschedulingClass: mentorProfileRowData.ReschedulingClass,
                                SchedulingClass: mentorProfileRowData.SchedulingClass,
                                DeleteSessions: mentorProfileRowData.DeleteSessions,

                                CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
                                SendNotices: mentorProfileRowData.SendNotices === 0 ? 1 : 0,
                                AddInviteStudent: mentorProfileRowData.AddInviteStudent,
                                AccessRecordings: mentorProfileRowData.AccessRecordings,
                                LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

                                AccessCourseControl: mentorProfileRowData.AccessCourseControl,
                                CreateTests: mentorProfileRowData.CreateTests,
                                EvaluateTests: mentorProfileRowData.EvaluateTests,
                                AddToQBanks: mentorProfileRowData.AddToQBanks,

                                AccessReports: mentorProfileRowData.AccessReports,
                                DownloadReports: mentorProfileRowData.DownloadReports,
                                ShareReports: mentorProfileRowData.ShareReports,
                            }
                            setMentorProfileRowData(obj)
                        }}
                            checked={mentorProfileRowData.SendNotices === 0 ? true : false}
                        />
                    </DivFlexStyle>
                    <DivFlexStyle>
                        <div>Add / Invite Student</div>
                        <Switch onChange={() => {
                            const obj = {
                                Name: mentorProfileRowData.Name,
                                Email: mentorProfileRowData.Email,
                                Phone: mentorProfileRowData.Phone,
                                setPassword: mentorProfileRowData.setPassword,
                                confirmPassword: mentorProfileRowData.confirmPassword,
                                SelectGradesId:mentorProfileRowData.SelectGradesId,
                                SelectGrades: mentorProfileRowData.SelectGrades,
                                AccessType: mentorProfileRowData.AccessType,
                                displayName: mentorProfileRowData.displayName,
                                CountryCode:mentorProfileRowData.CountryCode,
                                SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
                                SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
                                ReschedulingClass: mentorProfileRowData.ReschedulingClass,
                                SchedulingClass: mentorProfileRowData.SchedulingClass,
                                DeleteSessions: mentorProfileRowData.DeleteSessions,

                                CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
                                SendNotices: mentorProfileRowData.SendNotices,
                                AddInviteStudent: mentorProfileRowData.AddInviteStudent === 0 ? 1 : 0,
                                AccessRecordings: mentorProfileRowData.AccessRecordings,
                                LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

                                AccessCourseControl: mentorProfileRowData.AccessCourseControl,
                                CreateTests: mentorProfileRowData.CreateTests,
                                EvaluateTests: mentorProfileRowData.EvaluateTests,
                                AddToQBanks: mentorProfileRowData.AddToQBanks,

                                AccessReports: mentorProfileRowData.AccessReports,
                                DownloadReports: mentorProfileRowData.DownloadReports,
                                ShareReports: mentorProfileRowData.ShareReports,
                            }
                            setMentorProfileRowData(obj)
                        }}
                            checked={mentorProfileRowData.AddInviteStudent === 0 ? true : false}
                        />
                    </DivFlexStyle>
                    <DivFlexStyle>
                        <div>Access Recordings</div>
                        <Switch onChange={() => {
                            const obj = {
                                Name: mentorProfileRowData.Name,
                                Email: mentorProfileRowData.Email,
                                Phone: mentorProfileRowData.Phone,
                                setPassword: mentorProfileRowData.setPassword,
                                confirmPassword: mentorProfileRowData.confirmPassword,
                                SelectGradesId:mentorProfileRowData.SelectGradesId,
                                SelectGrades: mentorProfileRowData.SelectGrades,
                                AccessType: mentorProfileRowData.AccessType,
                                displayName: mentorProfileRowData.displayName,
                                CountryCode:mentorProfileRowData.CountryCode,
                                SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
                                SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
                                ReschedulingClass: mentorProfileRowData.ReschedulingClass,
                                SchedulingClass: mentorProfileRowData.SchedulingClass,
                                DeleteSessions: mentorProfileRowData.DeleteSessions,

                                CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
                                SendNotices: mentorProfileRowData.SendNotices,
                                AddInviteStudent: mentorProfileRowData.AddInviteStudent,
                                AccessRecordings: mentorProfileRowData.AccessRecordings === 0 ? 1 : 0,
                                LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

                                AccessCourseControl: mentorProfileRowData.AccessCourseControl,
                                CreateTests: mentorProfileRowData.CreateTests,
                                EvaluateTests: mentorProfileRowData.EvaluateTests,
                                AddToQBanks: mentorProfileRowData.AddToQBanks,

                                AccessReports: mentorProfileRowData.AccessReports,
                                DownloadReports: mentorProfileRowData.DownloadReports,
                                ShareReports: mentorProfileRowData.ShareReports,
                            }
                            setMentorProfileRowData(obj)
                        }}
                            checked={mentorProfileRowData.AccessRecordings === 0 ? true : false}
                        />
                    </DivFlexStyle>
                    <DivFlexStyle>
                        <div>Live Session Progress</div>
                        <Switch onChange={() => {
                            const obj = {
                                Name: mentorProfileRowData.Name,
                                Email: mentorProfileRowData.Email,
                                Phone: mentorProfileRowData.Phone,
                                setPassword: mentorProfileRowData.setPassword,
                                confirmPassword: mentorProfileRowData.confirmPassword,
                                SelectGradesId:mentorProfileRowData.SelectGradesId,
                                SelectGrades: mentorProfileRowData.SelectGrades,
                                AccessType: mentorProfileRowData.AccessType,
                                displayName: mentorProfileRowData.displayName,
                                CountryCode:mentorProfileRowData.CountryCode,
                                SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
                                SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
                                ReschedulingClass: mentorProfileRowData.ReschedulingClass,
                                SchedulingClass: mentorProfileRowData.SchedulingClass,
                                DeleteSessions: mentorProfileRowData.DeleteSessions,

                                CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
                                SendNotices: mentorProfileRowData.SendNotices,
                                AddInviteStudent: mentorProfileRowData.AddInviteStudent,
                                AccessRecordings: mentorProfileRowData.AccessRecordings,
                                LiveSessionProgress: mentorProfileRowData.LiveSessionProgress === 0 ? 1 : 0,

                                AccessCourseControl: mentorProfileRowData.AccessCourseControl,
                                CreateTests: mentorProfileRowData.CreateTests,
                                EvaluateTests: mentorProfileRowData.EvaluateTests,
                                AddToQBanks: mentorProfileRowData.AddToQBanks,

                                AccessReports: mentorProfileRowData.AccessReports,
                                DownloadReports: mentorProfileRowData.DownloadReports,
                                ShareReports: mentorProfileRowData.ShareReports,
                            }
                            setMentorProfileRowData(obj)
                        }}
                            checked={mentorProfileRowData.LiveSessionProgress === 0 ? true : false}
                        />
                    </DivFlexStyle>
                </div>
            </>
        )
    }
}
