import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import Courses from './Courses'
import withMentorDashboardNav from '../../../components/withUpdatedNav'
import injectProps from '../../../components/injectProps'
import { MENTOR_DASHBOARD } from '../../../constants/navItemsConst'

const CoursesSideNav = withMentorDashboardNav(Courses)({
  title: 'Mentor Dashboard',
  activeNavItem: 'Course',
  showUMSNavigation: true,
  renderNavItems: MENTOR_DASHBOARD
})

const CoursesSideNavWithExtraProps = injectProps({
  notification
})(CoursesSideNav)
export default connect()(withRouter(CoursesSideNavWithExtraProps))
