import { Button } from 'antd';
import React, { Component } from 'react';

export default class ScheduleControlReporting extends Component {

    OpenSchedule = () => {
        const { ToggleComponentButtons } = this.props;
        ToggleComponentButtons(0);
    }
    OpenClassroom = () => {
        const { ToggleComponentButtons } = this.props;
        ToggleComponentButtons(1);
    }
    OpenCourse = () => {
        const { ToggleComponentButtons } = this.props;
        ToggleComponentButtons(2);
    }
    OpenReporting = () => {
        const { ToggleComponentButtons } = this.props;
        ToggleComponentButtons(3);
    }
    render() {
        const { buttonType } = this.props;
        return (
            <>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "21%", marginRight: "20px" }} onClick={() => { this.OpenSchedule() }}>
                        <Button type={buttonType.type1} style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} ><p style={{ fontSize: "9px", margin: '0' }}>Schedule Management</p></Button>
                    </div>
                    <div style={{ width: "21%", marginRight: "20px" }} onClick={() => { this.OpenClassroom() }}>
                        <Button type={buttonType.type2} style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}><p style={{ fontSize: "9px", margin: '0' }} >Classroom Control</p></Button>
                    </div>
                    <div style={{ width: "21%", marginRight: "20px" }} onClick={() => { this.OpenCourse() }}>
                        <Button type={buttonType.type3} style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}><p style={{ fontSize: "9px", margin: '0' }} >Course Control</p></Button>
                    </div>
                    <div style={{ width: "21%" }} onClick={() => { this.OpenReporting() }}>
                        <Button type={buttonType.type4} style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}><p style={{ fontSize: "9px", margin: '0' }} >Reporting</p></Button>
                    </div>
                </div>
            </>
        );
    }
}
