import React from 'react'
import {
    Form, Icon, Input,
    Select,
    Spin,
} from "antd";
import { StyledError, StyledSpeakerForm, StyledSpeakerImageSection, StyledSpeakersFormAndImageContainer } from "../SpeakerPrizes.style";
import { debounce, get } from 'lodash';
import fetchSpeakers from '../../../../actions/eventDashboard/fetchSpeakers';
import getFullPath from '../../../../utils/getFullPath';
import styles from '../SpeakerPrizes.module.scss'


class SpeakerFormAndImage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            search: '',
            selectedSpeaker: {},
        }
    }
    componentDidMount = () => {
        if (get(this.props, 'form.key')) {
            this.setState({
                selectedSpeaker: get(this.props, 'form')
            })
        }
    }
    handleSearch = (value) => {
        this.setState({ search: value }, () => {
            const { search } = this.state
            if (search.length >= 3) {
                debounce(() => {
                    fetchSpeakers(search)
                }, 800)()
            }
        })
    }

    handleInputChange = (value, key) => {
        const { location, setAllSelectedSpeakers, setSpeakerForms } = this.props
        let { speakersData } = this.props
        speakersData = speakersData && speakersData.toJS() || []

        const findSpeaker = speakersData.find(speaker =>
            get(speaker, 'id') === value)

        setSpeakerForms(findSpeaker, location)
        if (findSpeaker) {
            setAllSelectedSpeakers(value, location)
            this.setState({
                selectedSpeaker: {
                    key: findSpeaker
                },
                search: ''
            })
        }
    }

    speakersFilter = () => {
        let { speakersData, allSelectedSpeakers } = this.props
        speakersData = speakersData && speakersData.toJS() || []
        let allSpeakersDataFiltered = speakersData.filter(speaker => get(speaker, 'user.name', '').toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1)
        allSpeakersDataFiltered = allSpeakersDataFiltered.map(speaker => {
            if (this.isSpeakerAlreadySelected(speaker.id)) {
                return { ...speaker, isAlreadySelected: true }
            } return speaker
        })
        if (this.state.search.length < 2) {
            allSpeakersDataFiltered = []
        }
        return allSpeakersDataFiltered
    }

    isSpeakerAlreadySelected = (speakerId) => {
        const { allSelectedSpeakers } = this.props
        return !!allSelectedSpeakers.find(id => id === speakerId)
    }

    // removeFormAndResetAlreadySelectedValue = () => {
    //     const { removeForm, location } = this.props
    //     let { speakersData, allSelectedSpeakers } = this.props
    //     speakersData = speakersData && speakersData.toJS() || []
    //     let allSpeakersDataFiltered = speakersData.filter(speaker => get(speaker, 'user.name', '').toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1)
    //     allSpeakersDataFiltered = allSpeakersDataFiltered.map(speaker => {
    //         if (!this.isSpeakerAlreadySelected(speaker.id)) {
    //             return { ...speaker, isAlreadySelected: false }
    //         } return speaker
    //     })
    //     if (this.state.search.length < 2) {
    //         allSpeakersDataFiltered = []
    //     }
    //     removeForm(location, 'speakerForms')
    // }

    render() {
        const { removeForm, location, isFetchingAllSpeakers = false, error, speakerForm } = this.props
        const { selectedSpeaker: { key } } = this.state
        return <>
            <StyledSpeakersFormAndImageContainer>
                <h2 className={styles.speakerFormNumber}>{location + 1}.</h2>
                <StyledSpeakerForm>
                    <Form
                        className={styles.speakerForm}
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            span: 21,
                        }}
                        layout="horizontal"
                        initialValues={{
                            size: 'default',
                        }}
                        size={'default'}
                    >
                        <Form.Item className={styles.speakerSelect}>
                            <Select
                                showSearch
                                placeholder={'Type min 3 characters'}
                                loading={isFetchingAllSpeakers}
                                filterOption={false}
                                // value={get(key, 'user.name')}
                                value={get(speakerForm, 'name')}
                                notFoundContent={isFetchingAllSpeakers ? <Spin size='small' /> : null}
                                onSearch={debounce(this.handleSearch, 800)}
                                onChange={this.handleInputChange}
                                style={{ width: '100%' }}
                            >
                                {
                                    this.speakersFilter().map(speaker =>
                                        <Select.Option
                                            disabled={speaker.isAlreadySelected}
                                            key={get(speaker, 'id') || ''}
                                            label={get(speaker, 'user.name')}
                                            value={get(speaker, 'id')}
                                        >
                                            {get(speaker, 'user.name')}
                                        </Select.Option>
                                    )
                                }
                            </Select>
                            {
                                (error && !get(this.state.selectedSpeaker, 'key.id')) && <StyledError>Required*</StyledError>
                            }
                        </Form.Item>
                        <Form.Item className={styles.speakerDetail} >
                            {/* <Input placeholder='Email' disabled value={get(key, 'user.email')} /> */}
                            <Input placeholder='Email' disabled value={get(speakerForm, 'email')} />
                        </Form.Item>
                        <Form.Item className={styles.speakerDetail} >
                            {/* <Input placeholder='Name' disabled value={get(key, 'user.name')} /> */}
                            <Input placeholder='Name' disabled value={get(speakerForm, 'name')} />
                        </Form.Item>
                        <Form.Item className={styles.speakerDetail} >
                            {/* <Input placeholder='LinkedIn profile' disabled value={get(key, 'linkedInLink')} /> */}
                            <Input placeholder='Name' disabled value={get(speakerForm, 'linkedInLink')} />

                        </Form.Item>
                        <Form.Item className={styles.speakerDetail} >
                            {/* <Input placeholder='Phone number' value={get(key, 'user.phone') ? `${get(key, 'user.phone.countryCode') + get(key, 'user.phone.number')}` : ''} disabled /> */}
                            <Input placeholder='Phone number' value={get(speakerForm, 'phone') ? `${get(speakerForm, 'phone.countryCode') + get(speakerForm, 'phone.number')}` : ''} disabled />
                        </Form.Item>
                        <Form.Item className={styles.speakerDetail} >
                            {/* <Input placeholder='About' disabled value={get(key, 'about')} /> */}
                            <Input placeholder='About' disabled value={get(speakerForm, 'about')} />
                        </Form.Item>
                        <Form.Item className={styles.speakerDetail} >
                            {/* <Input placeholder='Role at organisation' disabled value={get(key, 'roleAtOrganization')} /> */}
                            <Input placeholder='Role at organisation' disabled value={get(speakerForm, 'roleAtOrganization')} />
                        </Form.Item>
                        <Form.Item className={styles.speakerDetail} >
                            {/* <Input placeholder='Organisation' disabled value={get(key, 'organization')} /> */}
                            <Input placeholder='Organisation' disabled value={get(speakerForm, 'organization')} />
                        </Form.Item>

                    </Form>
                </StyledSpeakerForm>
                {/* {
                    get(key, 'user.profilePic.uri') && (
                        <StyledSpeakerImageSection>
                            <img
                                width={200}
                                height={200}
                                src={getFullPath(get(key, 'user.profilePic.uri', null))}
                            />
                        </StyledSpeakerImageSection>
                    )
                } */}
                {
                    get(speakerForm, 'profilePic') && (
                        <StyledSpeakerImageSection>
                            <img
                                width={200}
                                height={200}
                                src={getFullPath(get(speakerForm, 'profilePic', null))}
                            />
                        </StyledSpeakerImageSection>
                    )
                }
                {/* {location !== 0 && <Icon onClick={() => this.removeFormAndResetAlreadySelectedValue()} type='close' className={styles.removeForm} />} */}
                {location !== 0 && <Icon onClick={() => removeForm(location, 'speakerForms')} type='close' className={styles.removeForm} />}
            </StyledSpeakersFormAndImageContainer></>
    }
}

export default SpeakerFormAndImage