import gql from 'graphql-tag'
import duck from '../../../duck'

const deleteLearningSlideContent = async (id) => duck.query({
  query: gql`
    mutation {
        deleteLearningSlideContent(id: "${id}") {
        id
      }
    }
  `,
  type: 'deleteLearningSlideContent/delete',
  key: `deleteLearningSlideContent`,
})

export default deleteLearningSlideContent
