import gql from 'graphql-tag'
import duck from '../../duck'

const removeFromBatchCoursePackageCourses = async (batchId, batchCode, courseId) => duck.query({
  query: gql`
    mutation {
        removeFromBatchCoursePackageCourses(batchId: "${batchId}", courseId: "${courseId}") {
            typeName
        }
    }
    `,
  type: 'removeFromBatchCoursePackageCourses/update',
  key: 'removeFromBatchCoursePackageCourses',
})

export default removeFromBatchCoursePackageCourses
