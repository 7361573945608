import gql from "graphql-tag";
import { get } from "lodash";
import requestToGraphql from "../../utils/requestToGraphql";

const fetchSchoolsLabInspections = async (filterString) => {
    const schoolsLabInspections = await requestToGraphql(gql`
    query{
      labInspections(filter: ${filterString}) {
        id
        labName
        labNo
        description
        createdAt
        school {
          id
          name
        }
        labConfiguration {
          totalNumberOfComputers
          totalNumberOfWorkingComputers
          projectInteractivePanel
          speakers
          powerBackupType
          powerBackup
          internetConnection
          serviceProviderType
          inspectionDate
          internetSpeed
        }
        systems {
          id
          serialNo
          uniqueDeviceId
          createdAt
          updatedAt
          status
          comment
          inspectionMode
          inspectionChecks {
            name
            type
            spec
            status
          }
          inspectionMode
          uniqueDeviceId
        }
      }
    }
    `)
    return get(schoolsLabInspections, 'data.labInspections')
}

export default fetchSchoolsLabInspections;
