import styled from 'styled-components'

const StudentListStyle = styled.div`
    height: 100%;
    width: 100%;
    margin: 0 auto;
    font-family: 'Inter' !important;
`
const TopBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    padding-top: 20px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
`
const GreyBack = styled.div`
    background: #F2F2F2;
    font-size: 12px;
    color: #616161;
`
StudentListStyle.TopBar = TopBar
StudentListStyle.GreyBack = GreyBack

export default StudentListStyle
