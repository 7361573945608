import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'
import getIdArrForQuery from '../../utils/getIdArrForQuery'
import requestToGraphql from '../../utils/requestToGraphql'

const addLeadPartnerAgent = async (mutationQuery) => {
  await requestToGraphql(gql`mutation{
    ${mutationQuery}
  }`)
}

const UPDATE_LEAD_PARTNERS = ({ id, title, adminsConnectIds }) => gql`
  
mutation{
    updateLeadPartner(id:"${id}",input:{
      title:"${title}"
    },
    ${adminsConnectIds.length > 0 ? `adminsConnectIds: [${getIdArrForQuery(adminsConnectIds)}]` : ''}
    )
    {
      id
      title
      admins {
        id
        name
      }
      agents {
        id
        agent {
          id
          name
          email
          phone {
            number
            countryCode
          }
        }
      }
    }
  }
`
const updateLeadPartners = async ({ id, title,
  agentsConnectIds = [], adminsConnectIds }) => duck.query({
  query: UPDATE_LEAD_PARTNERS({ id, title, agentsConnectIds, adminsConnectIds }),
  type: 'leadPartners/update',
  key: 'leadPartners',
  changeExtractedData: async (extractedData, originalData) => {
    const leadPartnerId = get(originalData, 'updateLeadPartner.id')
    let mutationQuery = ''
    agentsConnectIds.forEach((agent, index) => {
      if (!get(agent, 'agentId')) {
        mutationQuery += `addLeadPartnerAgent_${index}: addLeadPartnerAgent(input: {}, leadPartnerConnectId: "${leadPartnerId}", agentConnectId: "${get(agent, 'key')}") {
          id
        }`
      }
    })
    if (agentsConnectIds.length > 0 && mutationQuery) {
      await addLeadPartnerAgent(mutationQuery)
    }
    return { ...extractedData }
  }
})

export default updateLeadPartners
