import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import { filterKey } from '../../utils/data-utils'
import MentorClasses from './MentorClasses'
import withMentorDashboardNav from '../../components/withUpdatedNav'
import injectProps from '../../components/injectProps'
import { MENTOR_DASHBOARD } from '../../constants/navItemsConst'

const MentorDashboardSideNav = withMentorDashboardNav(MentorClasses)({
  title: 'Mentor Dashboard',
  activeNavItem: 'Classes',
  showUMSNavigation: false,
  renderNavItems: MENTOR_DASHBOARD,
})

const mapStateToProps = (state, props) => ({
  batch: filterKey(
    state.data.getIn([
      'batch',
      'data'
    ]), 'mentorClasses'
  ),
  batchFetchStatus: state.data.getIn([
    'batch',
    'fetchStatus'
  ]),
  course: filterKey(
    state.data.getIn([
      'course',
      'data'
    ]), 'mentorClasses'
  ),
  topics: filterKey(
    state.data.getIn([
      'topic',
      'data'
    ]), 'mentorClasses'
  ),
  coursePackages: filterKey(
    state.data.getIn([
      'coursePackages',
      'data'
    ]), `teacherTrainingClasses/${props.match.params.batchId}`
  ),
  teachers: filterKey(
    state.data.getIn([
      'user',
      'data'
    ]), `teacherTrainingClasses/${props.match.params.batchId}`
  ),
  chapters: filterKey(
    state.data.getIn([
      'chapters',
      'data'
    ]), 'mentorClasses'
  ),
  batchSessions: filterKey(
    state.data.getIn([
      'batchSessions',
      'data'
    ]), 'mentorClasses'
  ),
  updateSessionStatus:
    state.data.getIn([
      'mentorMenteeSessions',
      'updateStatus',
      'mentorClasses',
    ]),
  updateBatchSessionStatus:
    state.data.getIn([
      'batchSessions',
      'updateStatus',
      'mentorClasses'
    ]),
  mentorMenteeSessions: filterKey(
    state.data.getIn([
      'completedSession',
      'data'
    ]), 'mentorClasses'
  ),
  menteeSession: filterKey(
    state.data.getIn([
      'menteeSession',
      'data'
    ]), 'mentorClasses'
  ),
  user: filterKey(
    state.data.getIn([
      'user',
      'data'
    ]), 'mentorClasses'
  ),
  sessionTopic: filterKey(
    state.data.getIn([
      'topic',
      'data'
    ]), 'mentorClasses'
  ),
  school: filterKey(
    state.data.getIn([
      'school',
      'data'
    ]), 'mentorClasses'
  ),
})


const MentorDashboardSideNavWithExtraProps = injectProps({
  notification
})(MentorDashboardSideNav)
export default connect(mapStateToProps)(withRouter(MentorDashboardSideNavWithExtraProps))
