/* eslint-disable react/jsx-pascal-case */
import React, { Component } from 'react'
import { Spin, Button, Progress, message, Icon, Table } from 'antd'
import { ConsoleSqlOutlined, LoadingOutlined } from '@ant-design/icons'
import { get } from 'lodash'
import moment from 'moment'
import { RatioSVG, TypeSVG, BatchSVG, SchoolSvg, StudentSvg } from '../../constants/icons'
import getFullPath from '../../../src/utils/getFullPath'
import meetingLinkIcon from '../../assets/meetingLink.png'
import MentorClassesStyle from './MentorClasses.style'
import ClassContent from './Components/ClassContent'
import ClassComponent from './Components/ClassComponent/ClassComponent'
import { TekieAmethyst } from '../../constants/colors'
import ClassComponentForMentorMentee from './Components/ClassComponent/ClassComponentForMenorMentee'
import StudentsList from './Components/StudentsList/StudentsList'
import fetchBatchSessions from '../../actions/batch/fetchBatchSessions'
import fetchMentorMenteeSessions from '../../actions/mentorMenteeSessions/fetchMentorMenteeSessions'
import fetchTrainingClasses from '../../actions/batchDashboard/teacherTrainingClasses'

const loadingIcon = <LoadingOutlined style={{ fontSize: 16, marginRight: '8px', color: TekieAmethyst }} spin />

class MentorClasses extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: '',
      secondaryCategory: '',
      mentor: '',
      studentName: '',
      title: '',
      batchCode: '',
      students: [],
      course: {},
      createdAt: '',
      batchType: '',
      school: null,
      classLink: '',
      topics: [],
      color: '',
      currentTopicOrder: 0,
      isFetching: false,
      // latestSessionStatus: '',
      selectedTopic: {
        id: '',
        title: '',
        order: 1,
        sessionCreated: false,
        image: ''
      },
      teachers: [],
      courseImage: '',
      enrollmentType: '',
      topicsLength: 1
    }
  }
  componentDidMount = async () => {
    const query = new URLSearchParams(this.props.location.search)
    const batchId = query.get('batchId')
    const userId = query.get('userId')
    const courseId = query.get('courseId')
    this.setState({ isFetching: true })
    let type = ''
    let session
    let currentComponent

    if (this.props.location.pathname.includes('/teacherTraining')) {
      await fetchTrainingClasses(this.props.match.params.batchId)
      setTimeout(() => {
        const topics = get(this.props.coursePackages.toJS(), '0.topic', []).sort((a, b) => (a.order > b.order) ? 1 : -1)
        // const teachers = get(this.props.coursePackages.toJS(), '0.topic', []).sort((a, b) => (a.order > b.order) ? 1 : -1)
        this.setState({
          topics,
          teachers: this.props.teachers.toJS(),
          selectedTopic: {
            id: get(topics, '0.id', ''),
            title: get(topics, '0.title', ''),
            order: get(topics, '0.order', ''),
            image: get(topics, '0.thumbnailSmall.uri', '')
          },
          isFetching: false
        })
      }, 0)

      return;
    }

    if (batchId !== null) {
      await fetchBatchSessions(batchId)
      session = this.props.batch && this.props.batch.toJS()
      currentComponent = get(session[0], 'currentComponent')
      type = 'batch'
    } else if (userId !== null && courseId !== null) {
      await fetchMentorMenteeSessions(userId, courseId)
      session = this.props.mentorMenteeSessions && this.props.mentorMenteeSessions.toJS()
      type = 'mentorMenteeSessions'
    }
    const course = this.props.course && this.props.course.toJS()
    const topics = this.props.topics && this.props.topics.toJS()
    const school = this.props.school && this.props.school.toJS()
    topics.sort((a, b) => (a.order > b.order) ? 1 : -1)
    const user = this.props.user && this.props.user.toJS()
    const numberOfsession = session.length || 0
    let currentTopicOrder
    if (type === 'batch') {
      if (get(currentComponent, 'latestSessionStatus', '') === 'completed') {
        currentTopicOrder = get(currentComponent, 'currentTopic.order') + 1
      } else {
        currentTopicOrder = get(currentComponent, 'currentTopic.order')
      }
    } else if (get(session[numberOfsession - 1], 'sessionStatus', '') === 'completed') {
      currentTopicOrder = get(topics[numberOfsession - 1], 'order', 1) + 1
    } else {
      currentTopicOrder = get(topics[numberOfsession - 1], 'order', 1)
    }
    let link = ''
    if (type === 'batch') {
      if (get(session[0], 'currentComponent.currentTopic.order', 0) === 1) {
        link = get(session[0], 'allottedMentor.mentorProfile.sessionLink', '')
      } else if (get(session[0], 'allottedMentor.mentorProfile.googleMeetLink', '') !== '' && get(session[0], 'allottedMentor.mentorProfile.googleMeetLink', '') !== null) {
        link = 'https://'
        link = link.concat(get(session[0], 'allottedMentor.mentorProfile.googleMeetLink'))
      }
    }
    let color = ''
    if (get(session[0], 'type', ' ') === 'normal' || type === 'mentorMenteeSessions') {
      color = '#21b6a2'
    } else if (get(session[0], 'type', ' ') === 'b2b') {
      color = '#faad14'
    } else {
      color = '#04cad9'
    }
    const enrollmentType = get(session[0], 'salesOperation.leadStatus') === 'won' ? 'PAID' : 'FREE'
    this.setState({
      // latestSessionStatus: '',
      batchId,
      userId,
      courseId,
      type,
      currentTopicOrder,
      mentor: get(session[numberOfsession - 1], 'mentorSession.user.name', ''),
      secondaryCategory: get(course[numberOfsession - 1], 'secondaryCategory', ''),
      studentName: get(user[numberOfsession - 1], 'name', '-'),
      title: get(course[numberOfsession - 1], 'title', ''),
      batchCode: get(session[numberOfsession - 1], 'code', ''),
      students: get(session[numberOfsession - 1], 'students', ''),
      course: course[numberOfsession - 1],
      createdAt: moment(get(session[numberOfsession - 1], 'createdAt', '')).format('DD MMM YYYY, h:mm a'),
      batchType: get(session[numberOfsession - 1], 'type', 'B2C'),
      school: get(school, 'name', '-'),
      classLink: link,
      topics,
      color,
      topicsLength: topics.length,
      enrollmentType: type === 'batch' ? get(currentComponent, 'enrollmentType', '').toUpperCase() : enrollmentType,
      selectedTopic: {
        id: type === 'batch' ? get(currentComponent, 'currentTopic.id', '') : get(session[numberOfsession - 1], 'topic.id', ''),
        title: type === 'batch' ? get(currentComponent, 'currentTopic.title', '') : get(topics[numberOfsession - 1], 'title', ''),
        order: type === 'batch' ? get(currentComponent, 'currentTopic.order', 1) : get(topics[numberOfsession - 1], 'order', 1),
        image: type === 'batch' ? get(currentComponent, 'currentTopic.thumbnailSmall.uri', '') : get(topics[numberOfsession - 1], 'thumbnailSmall.uri')
      },
      courseImage: getFullPath(get(course[0], 'thumbnail.uri', '')),
      isFetching: false
    })
  }

  getProgress = () => {
    let progress = 0
    progress = Math.floor((this.state.currentTopicOrder / this.state.topicsLength) * 100)
    return progress
  }
  handleSelectedTopic = (topic) => {
    this.setState({ selectedTopic: {
      id: topic.id,
      title: topic.title,
      order: topic.order,
      image: get(topic, 'thumbnailSmall.uri')
    } })
  }
  latestSessionStatus = () => {
    let session
    if (this.state.type === 'batch') {
      session = this.props.batch && this.props.batch.toJS()
      const currentComponent = get(session[0], 'currentComponent')
      return get(currentComponent, 'latestSessionStatus')
    }
  }

  getLOWiseData = (userId, LOs) => {
    const loWiseData = {}
    LOs.forEach(lo => {
      loWiseData[get(lo, 'id', '')] = userId
    })
    return loWiseData
  }
  
  render() {
    const { batchCode, students, course, secondaryCategory,
      createdAt, type, batchType, school, classLink, title,
      color, mentor, studentName, currentTopicOrder, courseImage } = this.state
    const route = this.props.location.pathname
    const isTeacherTrainingPage = route.includes('/teacherTraining')
    const currentTopic = this.state.topics.find(topic => topic.id === this.state.selectedTopic.id) || {}
    const LOs = (currentTopic.learningObjectives || [])
    // const loColumns = LOs.map((lo) => ({
    //   title: get(lo, 'title', ''),
    //   dataIndex: get(lo, 'id', ''),
    //   key: get(lo, 'id', ''),
    //   render: (userId) => <a href={`https://www.tekie.in/teacher-report/${userId}/${this.state.selectedTopic.id}`} target="_blank">View Report</a>
    // }))
    const columns = [
      {
        title: 'Teacher Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: get(currentTopic, 'title'),
        dataIndex: 'report',
        key: 'report',
        render: (userId) => <a href={window.location.origin.includes('tekie.in') && !window.location.origin.includes('staging')
          ?  `https://www.tekie.in/teacher-report/${userId}/${this.state.selectedTopic.id}`
          : `https://staging.tekie.in/teacher-report/${userId}/${this.state.selectedTopic.id}`
        } target="_blank">View Report</a>
      },
      // ...loColumns
    ]
    
    const tableData = this.state.teachers.map((teacher) => ({
      key: get(teacher, 'id', ''),
      name: get(teacher, 'name', ''),
      report: get(teacher, 'id', '')
      // ...this.getLOWiseData(get(teacher, 'id', ''), LOs)
    }))
    console.log(tableData, columns)
    return (
      <MentorClassesStyle.TopContainer >
        {this.state.isFetching ? (
          <>
            <div className='loading-container show'>
              <div className='loading-bar-container'>
                <div />
              </div>
            </div>
            <div className='mentor-dashboard-calender-loading-container'>
              <Spin indicator={loadingIcon} />
              <div className='mentor-dashboard-calender-loading'>Fetching Sessions...</div>
            </div>
          </>
        ) : (null)}
        {this.state.errorLoading ? (
          <>
            <div className='loading-container show'>
              <div className='loading-bar-container'>
                <div />
              </div>
            </div>
            <div className='mentor-dashboard-calender-loading-container'>
              <Spin indicator={loadingIcon} />
              <div className='mentor-dashboard-calender-loading'>Fetching Sessions...</div>
            </div>
          </>
        ) : (null)}
        {!isTeacherTrainingPage && (
          <>
            <div style={{ display: 'flex', marginBottom: '20px' }}>
              <MentorClassesStyle.ClassTop style={{ fontWeight: '400' }}>Classes {'>'} </MentorClassesStyle.ClassTop>
              <MentorClassesStyle.ClassTop style={{ fontWeight: '500' }}>{ type === 'batch' && get(course, 'title', '')}
                {type === 'batch' ? (`  (${batchCode})  `) : (studentName)}
              </MentorClassesStyle.ClassTop>
            </div>

            <div 
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingRight: '70px',
                background: 'white', 
                marginBottom: '20px',
                borderRadius: '16px'
              }}
            >
              <div style={{ marginLeft: '3%', marginTop: '30px' }}>
                <MentorClassesStyle.DateBox>Created On : { createdAt }</MentorClassesStyle.DateBox>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: '16px' }} >
                  <MentorClassesStyle.batchColor style={{ color, backgroundColor: color }} />
                  <MentorClassesStyle.batchBuss>{batchType === 'normal' ? 'B2C' : batchType}</MentorClassesStyle.batchBuss>
                  <MentorClassesStyle.batchType>
                    {this.state.enrollmentType}
                  </MentorClassesStyle.batchType>
                </div>
                {secondaryCategory !== '' && (
                  <div style={{ color: '#BDBDBD', fontSize: '12px', fontWeight: '500' }}>{secondaryCategory}</div>
                )}
                <div style={{ color: 'black', fontSize: '24px', fontWeight: '600' }}>{title}</div>
                <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '25px' }}>
                  <MentorClassesStyle.Container>
                    {type === 'batch' ? (
                      <Icon component={BatchSVG} style={{ fill: '#9E9E9E' }} />
                    ) : (
                      <Icon component={StudentSvg} style={{ fill: '#9E9E9E' }} />
                    )}
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '15px ' }}>
                      <MentorClassesStyle.ClassInfo>{type === 'batch' ? 'Batch' : 'Mentor'}</MentorClassesStyle.ClassInfo>
                      <MentorClassesStyle.ClassSubInfo>{type === 'batch' ? batchCode : mentor}</MentorClassesStyle.ClassSubInfo>
                    </div>
                  </MentorClassesStyle.Container>
                  <MentorClassesStyle.Container>
                    <Icon component={RatioSVG} style={{ fill: '#9E9E9E' }} />
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '15px ' }}>
                      <MentorClassesStyle.ClassInfo>{type === 'batch' ? 'Students' : 'Student'}</MentorClassesStyle.ClassInfo>
                      <MentorClassesStyle.ClassSubInfo>{type === 'batch' ? students.length : studentName}</MentorClassesStyle.ClassSubInfo>
                    </div>
                  </MentorClassesStyle.Container>
                  <MentorClassesStyle.Container>
                    {type === 'batch' ? (
                      <Icon component={SchoolSvg} style={{ fill: '#9E9E9E' }} />
                    ) : (
                      <Icon component={TypeSVG} style={{ fill: '#9E9E9E' }} />
                    )}
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '15px ' }}>
                      <MentorClassesStyle.ClassInfo>{type === 'batch' ? 'School' : 'Type'}</MentorClassesStyle.ClassInfo>
                      <MentorClassesStyle.ClassSubInfo>{type === 'batch' ? school : 'learning'}</MentorClassesStyle.ClassSubInfo>
                    </div>
                  </MentorClassesStyle.Container>
                </div>
                <MentorClassesStyle.progressDiv>
                  <MentorClassesStyle.progress>
                    Progress
                  </MentorClassesStyle.progress>
                  <Progress style={{ width: '350px' }} percent={this.getProgress()} trailColor='#6d736e' strokeColor='#8C61CB' />
                </MentorClassesStyle.progressDiv>
              </div>
              <MentorClassesStyle.flexDiv2>
                <MentorClassesStyle.courseImageBorder>
                  <img src={courseImage} alt='' style={{ height: '170px', width: '170px' }} />
                </MentorClassesStyle.courseImageBorder>
                <Button
                  style={{ backgroundColor: '#8C61CB',
                  borderRadius: '8px',
                  color: 'white',
                  width: '200px',
                  marginBottom: '10px',
                  marginTop: '5px' }}
                  onClick={() => {
                    if (classLink !== '') {
                      window.open(classLink, '_blank')
                    } else {
                      message.error('Meeting link not provided')
                    }
                  }}
                ><img src={meetingLinkIcon} alt='' style={{ padding: '7px 12px' }} />Meeting Link
                </Button>
              </MentorClassesStyle.flexDiv2>
            </div>
          </>
        )}
        <div style={{ display: 'flex', backgroundColor: 'white', paddingBottom: '40px', borderRadius: '16px', }}>
          <ClassContent
            style={{ flex: '4' }}
            teacherTraining={isTeacherTrainingPage}
            topics={this.state.topics}
            handleSelectedTopic={this.handleSelectedTopic}
            currentTopicOrder={currentTopicOrder}
            selectedTopicOrder={get(this.state.selectedTopic, 'order')}
          />
          <div style={{ flex: '6' }}>
            {isTeacherTrainingPage && (
              <div style={{
                padding: 40
              }}>
                <Table
                  bordered
                  // dataSource={dataSource}
                  columns={[
                    ...columns,
                  ]}
                  pagination={{
                    hideOnSinglePage: true
                  }}
                  dataSource={tableData}
                />
              </div>
            )}
            {type === 'batch' && (
            <ClassComponent
              batchId={this.state.batchId}
              students={students}
              userId={this.state.userId}
              courseId={this.state.courseId}
              type={type}
              selectedTopic={this.state.selectedTopic}
              batchSessions={this.props.batchSessions &&
                this.props.batchSessions.toJS()}
              classLink={this.state.classLink}
              updateSessionStatus={this.props.updateSessionStatus &&
                this.props.updateSessionStatus.toJS()}
              updateBatchSessionStatus={this.props.updateBatchSessionStatus &&
                this.props.updateBatchSessionStatus.toJS()}
              currentTopicOrder={currentTopicOrder}
            />)}
            {type === 'mentorMenteeSessions' && (
              <ClassComponentForMentorMentee
                selectedTopic={this.state.selectedTopic}
                userId={this.state.userId}
                courseId={this.state.courseId}
                currentTopicOrder={currentTopicOrder}
                menteeSession={this.props.menteeSession && this.props.menteeSession.toJS()}
                updateSessionStatus={this.props.updateSessionStatus &&
                  this.props.updateSessionStatus.toJS()}
                studentName={studentName}
                mentorMenteeSessions={this.props.mentorMenteeSessions &&
                  this.props.mentorMenteeSessions.toJS()}
                updateBatchSessionStatus={this.props.updateBatchSessionStatus &&
                    this.props.updateBatchSessionStatus.toJS()}
              />
            )}
          </div>
        </div>
        {type === 'batch' && (
          <StudentsList students={students} batchCode={batchCode} />
          )}
      </MentorClassesStyle.TopContainer >
    )
  }
}

export default MentorClasses
