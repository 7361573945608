import gql from 'graphql-tag'
import { get } from 'lodash'
import moment from 'moment'
import requestToGraphql from '../../../utils/requestToGraphql'

const getDateFilter = (fromDate, toDate, type = '') => {
  let dateFilter = ''
  if (type === 'menteeSession') {
    if (fromDate) dateFilter += `{ bookingDate_gte: "${moment(fromDate).startOf('day')}" }`
    if (toDate) dateFilter += `{ bookingDate_lte: "${moment(toDate).endOf('day')}" }`
  } else if (type === 'mentorSession') {
    if (fromDate) dateFilter += `{ availabilityDate_gte: "${moment(fromDate).startOf('day')}" }`
    if (toDate) dateFilter += `{ availabilityDate_lte: "${moment(toDate).endOf('day')}" }`
  } else {
    if (fromDate) dateFilter += `{ sessionStartDate_gte: "${moment(fromDate).startOf('day')}" }`
    if (toDate) dateFilter += `{ sessionStartDate_lte: "${moment(toDate).endOf('day')}" }`
  }
  return dateFilter
}


const fetchSupplyStatsData = async (fromDate, toDate) => {
  const batchSessionDemoCompleted = await requestToGraphql(gql`{
      batchSessionDemoCompleted: batchSessions(
        filter: {
        and: [
            { sessionStatus: completed }
            ${getDateFilter(fromDate, toDate)}
            { batch_some: { students_exists: true } }
            { topic_some: { order: 1 } }
        ]
        }
    ) {
        id
    }
  }`)
  const mmsDemoCompleted = await requestToGraphql(gql`{
        mmsDemoCompleted: mentorMenteeSessions(
        filter: {
        and: [
            ${getDateFilter(fromDate, toDate)}
            { sessionStatus: completed }
            { studentProfile_some: { batch_exists: false } }
            { topic_some: { order: 1 } }
            { source_not: school }
        ]
        }
    ) {
        id
    }
    }`)
  const assignedBatchSession = await requestToGraphql(gql`{
    assignedBatchSession: batchSessions(
        filter: {
        and: [
            ${getDateFilter(fromDate, toDate)}
            { batch_some: { and: [{ students_exists: true }, { type_not: b2b }] } }
            { topic_some: { order: 1 } }
        ]
        }
    ) {
        id
    }
}`)
  const assignedMMSession = await requestToGraphql(gql`{
        assignedMMSession: mentorMenteeSessions(
        filter: {
        and: [
            ${getDateFilter(fromDate, toDate)}
            { topic_some: { order: 1 } }
            { studentProfile_some: { batch_exists: false } }
            { source_not: school }
        ]
        }
    ) {
        id
    }
    }`)
  const totalBookedBatchSession = await requestToGraphql(gql`{
    totalBookedBatchSession: batchSessions(
        filter: {
        and: [
            ${getDateFilter(fromDate, toDate)}
            { batch_some: { students_exists: true } }
            { topic_some: { order: 1 } }
        ]
        }
    ) {
        id
    }
    }`)
  const menteeSessionDemoBooked = await requestToGraphql(gql`{
        menteeSessionDemoBooked: menteeSessions(
        filter: {
        and: [
            ${getDateFilter(fromDate, toDate, 'menteeSession')}
            { studentProfile_some: { batch_exists: false } }
            { topic_some: { order: 1 } }
            { source_not: school }
        ]
        }
    ) {
        id
    }
    }`)
  return {
    batchSessionDemoCompleted: get(batchSessionDemoCompleted, 'data.batchSessionDemoCompleted', []).length,
    mmsDemoCompleted: get(mmsDemoCompleted, 'data.mmsDemoCompleted', []).length,
    assignedBatchSession: get(assignedBatchSession, 'data.assignedBatchSession', []).length,
    assignedMMSession: get(assignedMMSession, 'data.assignedMMSession', []).length,
    totalBookedBatchSession: get(totalBookedBatchSession, 'data.totalBookedBatchSession', []).length,
    menteeSessionDemoBooked: get(menteeSessionDemoBooked, 'data.menteeSessionDemoBooked', []).length
  }
}

export default fetchSupplyStatsData

