import React, { useState } from 'react'
import { get } from 'lodash'
import { format } from 'date-fns'
import moment from 'moment'
import { SearchOutlined, UserOutlined } from '@ant-design/icons'
import DemandCalendarWrapper from '../DemandCalendar.style'
import Styles from '../../DemandSupplyDashboard.style'
import { BatchSVG, BuildingIcon, ClockSVG, ContactUsSvg, MailSvg, RatioSVG, StudentsSvg, TypeSVG } from '../../../../constants/icons'
import { B2B, B2B2C, B2C } from '../../../../constants/demandVerticals'
import getFullPath from '../../../../utils/getFullPath'
import studentImage from '../../../../assets/studentIcon.png'

const classDetail = (props) => {
  const { sessionData } = props
  const getSessionType = () => get(sessionData, 'sessionType', 'trial').toUpperCase()
  const [searchStudent, setSearchStudent] = useState('')
  const getVertical = () => {
    const sessionVertical = get(sessionData, 'recordType')
    if (sessionVertical === 'menteeSession') {
      return B2C
    }
    return get(sessionData, 'batch.type')
  }
  const getVerticalColor = () => {
    let color = ''
    if (getVertical() === B2B) color = '#FAAD14'
    else if (getVertical() === B2C) color = '#01AA93'
    else if (getVertical() === B2B2C) color = '#2593E2'
    return color
  }

  const renderSessionDateAndSlot = (iconComponent, style = {}) => (
    <div style={style}>
      <Styles.Icon theme='twoTone' component={iconComponent} />
      {get(sessionData, 'startTime') && format(get(sessionData, 'startTime'), 'hh:mm a')}
      {get(sessionData, 'endTime') && ` - ${format(get(sessionData, 'endTime'), 'hh:mm a')}`}
      {' '} &bull; {' '}
      {get(sessionData, 'sessionStartDate')
        && new Date(get(sessionData, 'sessionStartDate')).toDateString()}
    </div>
  )

  const renderSessionDetails = ({ type, value, icon, highLight = false }) => (
    <Styles.ContentClassDetail style={{ alignItems: 'flex-start', marginRight: 20 }}>
      <Styles.Icon theme='twoTone'
        component={icon}
      />
      <div>
        <div className='classDetailsText'>{type}</div>
        <Styles.Text highLight={highLight}>{ value }</Styles.Text>
      </div>
    </Styles.ContentClassDetail>
  )
  const gradeNumber = (grade = '') => grade.replace('Grade', '') || '-'

  const renderSingleStudentDetail = () => (
    <DemandCalendarWrapper.EventCard>
      <h1>Student Details</h1>
      <Styles.FlexContainer justify='flex-start' style={{ alignItems: 'flex-start', marginTop: 10 }}>
        <Styles.TopicThumbnail studentImage
          bgImage={get(sessionData, 'session.user.profilePic.uri') ? getFullPath(get(sessionData, 'session.user.profilePic.uri')) : studentImage}
        />
        <div>
          <h3>{get(sessionData, 'session.user.name')}</h3>
          <p className='dateTime'>Created on: {moment(get(sessionData, 'session.user.createdAt')).format('DD MMM hh:mm A')}</p>
        </div>
        <div style={{ marginLeft: 10 }}>
          <h3>Parent Details</h3>
          <Styles.FlexContainer>
            {renderSessionDetails({
              type: 'Parent’s Name',
              value: get(sessionData, 'session.user.studentProfile.parents[0].user.name'),
              icon: StudentsSvg
            })}
            {renderSessionDetails({
              type: 'Parent’s Phone',
              value: `${get(sessionData, 'session.user.studentProfile.parents[0].user.phone.countryCode')} ${get(sessionData, 'session.user.studentProfile.parents[0].user.phone.number')}`,
              icon: ContactUsSvg
            })}
          </Styles.FlexContainer>
          {renderSessionDetails({
            type: 'Parent’s Email',
            value: get(sessionData, 'session.user.studentProfile.parents[0].user.email'),
            icon: MailSvg
          })}
        </div>
      </Styles.FlexContainer>
      <Styles.CustomDivider />
    </DemandCalendarWrapper.EventCard>
  )

  const getTableColumn = () => {
    const columns = [
      {
        title: 'Student Name',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        render: (_, record) => (
          <Styles.FlexContainer justify='center'>
            {getFullPath(get(record, 'user.profilePic.uri'))
              ? <DemandCalendarWrapper.MentorProfile style={{ marginRight: 10 }} profilePic={getFullPath(get(record, 'profilePic.uri'))} />
              : <Styles.StyledProfileIcon component={UserOutlined} />}
            {get(record, 'user.name')}
          </Styles.FlexContainer>
        )
      },
      {
        title: 'Parent Name',
        dataIndex: 'parentName',
        key: 'parentName',
        align: 'center',
        render: (_, record) => get(record, 'parents[0].user.name')
      },
      {
        title: 'Parent Phone',
        dataIndex: 'parentPhone',
        key: 'parentPhone',
        align: 'center',
        render: (_, record) => `${get(record, 'parents[0].user.phone.countryCode') || ''} ${get(record, 'parents[0].user.phone.number') || ''}`
      },
      {
        title: 'Parent Email',
        dataIndex: 'parentEmail',
        key: 'parentEmail',
        align: 'center',
        render: (_, record) => get(record, 'parents[0].user.email')
      },
      {
        title: 'Created On',
        dataIndex: 'createdOn',
        key: 'createdOn',
        align: 'center',
        render: (_, record) => moment(get(record, 'user.createdAt')).format('DD-MM-YYYY')
      }
    ]
    return columns
  }
  const renderMultipleStudents = () => {
    const studentsData = get(sessionData, 'session.batch.students', []).filter(student =>
      get(student, 'user.name', '').toLowerCase().includes(searchStudent.toLowerCase()))
    return (
      <DemandCalendarWrapper.EventCard>
        <h1>Student Details</h1>
        <Styles.SearchInput suffix={<SearchOutlined />}
          placeholder='Search for student'
          value={searchStudent}
          onChange={({ target: { value } }) => setSearchStudent(value)}
        />
        <DemandCalendarWrapper.DemandSupplyTable dataSource={studentsData}
          columns={getTableColumn()}
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      </DemandCalendarWrapper.EventCard>
    )
  }
  const getGradesForBatch = () => {
    let grades = get(sessionData, 'session.batch.students', []).map(student => gradeNumber(get(student, 'grade')))
    grades = [...new Set(grades)].map(grade => `${grade},`)
    return grades.length > 0 ? grades : '-'
  }
  return (
    <>
      <DemandCalendarWrapper.EventCard>
        <h1>Class Details</h1>
        <p className='dateTime'>Created on: {moment(get(sessionData, 'createdAt')).format('DD MMM hh:mm A')}</p>
        <Styles.FlexContainer justify='flex-start' style={{ flexWrap: 'nowrap' }}>
          <Styles.TopicThumbnail bgImage={getFullPath(get(sessionData, 'course.thumbnail.uri'))} />
          <div>
            <Styles.FlexContainer justify='flex-start'>
              <Styles.HeaderSessionIndicator bgColor={getVerticalColor()} />
              <Styles.PreHeaderText>
                {getVertical()}
              </Styles.PreHeaderText>
              <Styles.HeaderTag
                bgColor={getVerticalColor()}
              >
                {getSessionType()}
              </Styles.HeaderTag>
            </Styles.FlexContainer>
            <h2 style={{ wordBreak: 'break-all' }}>
              {get(sessionData, 'course.title')} · {get(sessionData, 'topic.title')}
            </h2>
            {renderSessionDateAndSlot(ClockSVG)}
          </div>
        </Styles.FlexContainer>
        <Styles.FlexContainer justify='flex-start' style={{ marginTop: '15px' }}>
          {get(sessionData, 'recordType') === 'batchSession' && get(sessionData, 'batch.code') &&
          renderSessionDetails({ type: 'Batch', value: get(sessionData, 'batch.code'), icon: BatchSVG })}
          {renderSessionDetails({
            type: 'Grade',
            value: get(sessionData, 'recordType') === 'batchSession' ?
              getGradesForBatch() : gradeNumber(get(sessionData, 'session.user.studentProfile.grade')),
            icon: RatioSVG
          })}
          {get(sessionData, 'recordType') === 'batchSession'
            && renderSessionDetails({ type: 'Students', value: get(sessionData, 'session.batch.students', []).length, icon: RatioSVG })}
          {renderSessionDetails({ type: 'Type', value: 'Learning', icon: TypeSVG, highLight: true })}
          {getVertical() !== B2C && get(sessionData, 'session.batch.school.name') && renderSessionDetails({
            type: 'School',
            value: get(sessionData, 'session.batch.school.name'),
            icon: BuildingIcon
          })}
        </Styles.FlexContainer>
        <Styles.CustomDivider />
      </DemandCalendarWrapper.EventCard>
      {getVertical() === B2C ? renderSingleStudentDetail() : renderMultipleStudents()}
    </>
  )
}

export default classDetail
