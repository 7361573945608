import gql from 'graphql-tag'
import duck from '../../duck'

const FETCH_LEAD_PARTNERS = () => gql`
  {
  users(filter: { role: leadPartner }) {
    id
    name
  }
}

`
const fetchLeadPartnerusers = async () => duck.query({
  query: FETCH_LEAD_PARTNERS(),
  type: 'user/fetch',
  key: 'leadPartners',
  changeExtractedData: (extractedData) => {
    extractedData.user = []
    return { ...extractedData }
  }
})

export default fetchLeadPartnerusers
