import React from 'react'
import { UserOutlined } from '@ant-design/icons'
import { Table } from 'antd'
import { get } from 'lodash'
import BatchStyle from './BatchContainer.styles'
import batchIcon from '../../../assets/studentTab.png'
import studentIcon from '../../../assets/studentIcon.png'
import './antdTableStyle.scss'


const columns = [
  {
    title: 'Student Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Grade',
    dataIndex: 'grade',
    key: 'grade',
  },
  {
    title: 'Parents name',
    dataIndex: 'pname',
    key: 'pname',
  },
  {
    title: 'Parent Phone',
    dataIndex: 'pphone',
    key: 'pphone',
  },
  {
    title: 'Parent Email',
    dataIndex: 'pemail',
    key: 'pemail',
  },
  {
    title: 'Created On',
    dataIndex: 'createdOn',
    key: 'createdOn',
  }
]

const BatchContainer = ({ batch }) => {
  const getTableData = () => {
    const data = []
    const students = get(batch, 'students', [])
    const studentsLength = get(batch, 'students', []).length
    for (let i = 0; i < studentsLength; i += 1) {
      const student = students[i]
      data.push({
        key: i + 1,
        name: <div style={{ display: 'flex', alignItems: 'center' }}><img style={{ height: '24px', width: '24px', marginRight: '15px' }} src={studentIcon} alt='' />{get(student, 'user.name', '')}</div>,
        grade: <div>{get(student, 'grade', '').substr(5, 5)}</div>,
        pname: <div>{get(student, 'parents[0].user.name', '')}</div>,
        pphone: <div>{get(student, 'parents[0].user.phone.number', '')}</div>,
        pemail: <div>{get(student, 'parents[0].user.phone.email', '')}</div>,
        createdOn: '21 July, 2020'
      })
    }
    return data
  }
  return (
    <div style={{ background: 'white', borderRadius: '8px', paddingBottom: '25px' }}>
      <BatchStyle.TopContainer>
        <BatchStyle.BatchLogoDiv>
          <img src={batchIcon} alt='' />
        </BatchStyle.BatchLogoDiv>
        <BatchStyle.Heading>Batch</BatchStyle.Heading>
        <BatchStyle.BatchCodeDiv>
          <BatchStyle.BatchCode>{batch.code}</BatchStyle.BatchCode>
        </BatchStyle.BatchCodeDiv>
        <UserOutlined style={{ paddingLeft: '60px' }} />
        <BatchStyle.StudentHeading>Students</BatchStyle.StudentHeading>
        <div style={{ fontSize: '17px', paddingLeft: '15px', color: 'black', fontWeight: '700' }}>{get(batch, 'students.length', 0)}</div>
      </BatchStyle.TopContainer>
      {!batch.courseData && <Table
        className='studentTabTable'
        columns={columns}
        dataSource={getTableData()}
        pagination={false}
      />}
    </div>
  )
}

export default BatchContainer
