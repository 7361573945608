import gql from 'graphql-tag'
import duck from '../../../../duck'

const addEventSpeakerProfile = async (input, userConnectId) =>
  duck.query({
    query: gql`
      mutation($input: EventSpeakerProfileInput!) {
        addEventSpeakerProfile( 
          input: $input
          userConnectId: "${userConnectId}"
        ) {
            id
            user {
              name
              email
              phone {
                number
              }
              profilePic {
                id
                uri
              }
            }
            roleAtOrganization
            organization
            about
            linkedInLink
        }
      }
    `,
    variables: {
      input
    },
    type: 'eventSpeakerProfiles/add',
    key: 'eventSpeakerProfiles',
  })

export default addEventSpeakerProfile