import gql from 'graphql-tag'
import duck from '../../../../duck'

const removePictureFromEventUser = async (userId, fileId) =>
  duck.query({
    query: gql`
      mutation {
        removeFromUserProfilePic( 
          userId: "${userId}"
          fileId: "${fileId}"
        ) {
            user {
                id
            }
        }
      }
    `,
    type: 'user/delete',
    key: 'removeFromUserProfilePic',
  })

export default removePictureFromEventUser