import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const fetchTrainingBatches = async ({ perPage, skip, schoolId }) =>
  duck.query({
    query: gql`{
  batch: batches(
    filter: {
      and: [{ isTeacherTraining_not: false }, { school_some: { id: "${schoolId}" } }]
    }
    ${perPage ? `first: ${perPage}` : ''}
    ${skip ? `skip: ${perPage * skip}` : ''}
    orderBy: createdAt_ASC
  ) {
    id
    code
    classroomTitle
    description
    createdAt
    classes {
      id
      grade
      section
    }
    allottedMentor {
      id
      name
    }
    studentsMeta {
      count
    }
    coursePackage {
      id
      title
    }
  }
  trainingBatchesMeta: batchesMeta(filter: {
    and: [{ isTeacherTraining_not: false }, { school_some: { id: "${schoolId}" } }]
    }){
    count
  }
}
`,
    type: 'batch/fetch',
    key: 'trainingBatches',
    changeExtractedData: (extractedData, originalData) => {
      const batches = []
      get(originalData, 'batch', []).forEach((batch, index) => {
        const grades = [...new Set(get(batch, 'classes', []).map(({ grade }) => grade))]
        const sections = [...new Set(get(batch, 'classes', []).map(({ section }) => section))]
        batches.push({
          ...batch,
          index: index + 1,
          grade: grades,
          section: sections,
          students: get(batch, 'studentsMeta.count', 0),
          allottedMentor: get(batch, 'allottedMentor'),
        })
      })
      extractedData.coursePackage = {}
      extractedData.batch = batches;
      return { ...extractedData }
    }
  })

export default fetchTrainingBatches

