import gql from 'graphql-tag'
import duck from '../../duck'
import getDataFromLocalStorage from '../../utils/extract-from-localStorage'
import { ADMIN, UMS_ADMIN, UMS_VIEWER, MENTOR } from '../../constants/roles'

const fetchMentorMenteeSessions = (userId, courseId) => {
  const role = getDataFromLocalStorage('login.role')
  const mentorId = getDataFromLocalStorage('login.id')
  let filterStatement
  if (role === ADMIN || role === UMS_ADMIN || role === UMS_VIEWER) {
    filterStatement = `{and: [
      {
        menteeSession_some:{
          user_some:{
            id: "${userId}"
          }
        }
      }
      {
        course_some:{
          id: "${courseId}"
        }
      }
    ]}`
  } else if (role === MENTOR) {
    filterStatement = `{and: [
      {
        menteeSession_some:{
          user_some:{
            id: "${userId}"
          }
        }
      }
      {
        course_some:{
          id: "${courseId}"
        }
      }
      { mentorSession_some: { user_some: { id: "${mentorId}" } } }
    ]}`
  }
  return duck.query({
    query: gql`
    {
        mentorMenteeSessions(filter: ${filterStatement}) {
            id
            sessionCommentByMentor
            createdAt
            salesOperation{
              id
              leadStatus
              enrollmentType
            }
            course{
              id
              title
              thumbnail{
                uri
              }
              secondaryCategory
              topics(filter:{status:published}){
                id
                order
                title
                thumbnailSmall{
                  uri
                }
              }
            }
            topic {
              id
              title
              order
            }
            sessionStatus
            sessionStartDate
            sessionEndDate
            sessionRecordingLink
            isFeedbackSubmitted
            menteeSession {
              id
              bookingDate
              slot0
              slot1
              slot2
              slot3
              slot4
              slot5
              slot6
              slot7
              slot8
              slot9
              slot10
              slot11
              slot12
              slot13
              slot14
              slot15
              slot16
              slot17
              slot18
              slot19
              slot20
              slot21
              slot22
              slot23
              user {
                id
                name
              }
            }
            mentorSession{
              user{
                id
                name
                mentorProfile{
                  googleMeetLink
                  sessionLink
                }
              }
            }
          }
    }
    `,
    type: 'mentorMenteeSessions/fetch',
    key: 'mentorClasses'
  })
}

export default fetchMentorMenteeSessions
