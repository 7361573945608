import React from 'react'
import { Spin, Icon } from 'antd';
import { uploadStatus } from './UploadFileModal';

const antIcon = <Icon type="loading" spin />;

function UploadStatus({systemDataFromUploadedFile = [], showUploadStatus = false}) {
    const totalUploadedSystems = systemDataFromUploadedFile.filter(
        (item) => item.uploadStatus === uploadStatus.upload
      )
      console.log(totalUploadedSystems,systemDataFromUploadedFile)
    const isAllSystemsUploaded = systemDataFromUploadedFile.length === totalUploadedSystems.length
      
  return (
    showUploadStatus ? (
        <div className={`upload-status ${isAllSystemsUploaded ? 'uploaded': ''}`}>
          {
            isAllSystemsUploaded ? (
              <>
                 <Icon type="check-circle"  theme="filled" twoToneColor="#eb2f96"style={{
                    color: '#559E80',
                    fontSize: '12px',
                }} />
                    <div className={`upload-status-text`}>
                      Uploaded Successfully
                    </div>

              </>
            ) : (
              <>
                <Spin size="medium" indicator={antIcon} />
                <div className={`upload-status-text`}>
                  Uploading {totalUploadedSystems.length + 1} of {systemDataFromUploadedFile.length}
                </div>
              </>
            )
          }
        </div>
          ) : null
  )
}

export default UploadStatus