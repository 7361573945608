/* eslint-disable no-unused-vars */
import React from 'react'
import { get } from 'lodash'
import { Menu, Dropdown, Button, Modal, Input, Select, Pagination } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import UtmDashboardStyle from './UtmDashboard.style'
import UtmDetailsTable from './UtmDetailsTable'
import fetchUtmDetails from '../../actions/utm/fetchUtmDetails'
import addUtmDetails from '../../actions/utm/addUtmDetails'
import fetchLeadPartnersUtm from '../../actions/utm/fetchLeadPartnersUtm'

const UtmDetails = () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [partnerTypes, setPartnerTypes] = React.useState([])
  const [partnerTypeFilter, setPartnerTypeFilter] = React.useState('')
  const [selectedAgent, setSelectedAgent] = React.useState('')
  const [groupedLeads, setGroupedLeads] = React.useState({})
  const [addUtmDetailsController, setAddUtmDetailsController] = React.useState({
    partnerType: partnerTypeFilter,
    source: '',
    campaign: '',
    term: '',
    content: '',
    medium: '',
    selectedAgent: '',
    utmUrl: '',
  })
  const [utmData, setUtmData] = React.useState([])
  const [currentPage, setCurrentPage] = React.useState(1)
  const mapPartnerTypeToId = (partnerType, agentId) => {
    if (!groupedLeads) { return '' }
    const partnerTypeId = get(groupedLeads, partnerType, []).filter((agent) => get(agent, 'id') === agentId)
    return partnerTypeId
  }

  const fetchUtmData = async () => {
    setIsLoading(true)
    let leadFilter = ''
    if (partnerTypeFilter === 'All' || selectedAgent === 'All') {
      if (partnerTypeFilter === 'All') {
        leadFilter = ''
      }
      if (selectedAgent === 'All' && partnerTypeFilter !== 'All') {
        let leadAgentIds = ''
        get(groupedLeads, partnerTypeFilter, []).forEach(agent => { leadAgentIds += `"${get(agent, 'id')}"` })
        leadFilter = `filter: { leadPartnerAgent_some: { id_in: [${leadAgentIds}] } }`
      }
    } else if (partnerTypeFilter && selectedAgent) {
      const partnerType = mapPartnerTypeToId(partnerTypeFilter, selectedAgent)
      if (partnerType.length > 0) {
        leadFilter = `filter: { leadPartnerAgent_some: { id: "${get(partnerType, '[0].id')}" } }`
      }
    }
    fetchUtmDetails(leadFilter).then(res => {
      if (res && res.utmDetails) {
        setUtmData(res.utmDetails)
      }
      setIsLoading(false)
    })
  }
  const showModal = () => {
    setAddUtmDetailsController({
      partnerType: partnerTypeFilter,
      source: '',
      campaign: '',
      term: '',
      content: '',
      medium: '',
      selectedAgent: selectedAgent
    })
    setIsModalVisible(true)
  }

  const addUtmDetailsHandler = () => {
    const partnertype = mapPartnerTypeToId(partnerTypeFilter, selectedAgent)
    if (addUtmDetailsController.selectedAgent && addUtmDetailsController.partnerType) {
      setIsModalVisible(false)
      setIsLoading(true)
      addUtmDetails(addUtmDetailsController.selectedAgent, addUtmDetailsController).then((res) => {
        fetchUtmData()
        setIsLoading(false)
        setAddUtmDetailsController({
          partnerType: partnerTypeFilter,
          source: '',
          campaign: '',
          term: '',
          content: '',
          medium: '',
          selectedAgent: '',
          utmUrl: '',
        })
      })
    }
  }

  const handleCancel = () => {
    setAddUtmDetailsController({
      partnerType: partnerTypeFilter,
      source: '',
      campaign: '',
      term: '',
      content: '',
      medium: '',
      selectedAgent: ''
    })
    setIsModalVisible(false)
  }

  const provideVal = (url, i) => {
    const len = url.length
    const obj = {
      key: '',
      value: '',
      index: i
    }
    while (url[i] !== '_' && i < len) { i += 1 }
    i += 1
    while (url[i] !== '=' && i < len) {
      obj.key += url[i]; i += 1
    }
    i += 1
    while (i < len && url[i] !== '&') {
      obj.value += url[i]
      i += 1
    }
    i += 1
    obj.index = i
    return obj
  }
  const giveParam = (url) => {
    let i = 0
    const len = url.length
    const obj = {
      source: null,
      medium: null,
      campaign: null,
      id: null,
      term: null,
      content: null
    }
    while (i < len) {
      const tem = provideVal(url, i)
      obj[tem.key] = tem.value
      i = tem.index
    }
    return obj
  }

  // const parseStringValues = (url) => {
  //   const query = qs.parse(url)
  //   const queryKeys = Object.keys(query)
  //   for (const key of queryKeys) {
  //     localStorage.setItem(key, query[key])
  //   }
  // }

  const handleUtmDetailsChange = (e) => {
    if (e.target.name === 'utmUrl' && e.target.value !== '') {
      const utmParams = giveParam(e.target.value)
      setAddUtmDetailsController({
        ...addUtmDetailsController,
        source: utmParams.source,
        medium: utmParams.medium,
        campaign: utmParams.campaign,
        term: utmParams.term,
        content: utmParams.content,
        utmUrl: e.target.value,
      })
    } else {
      setAddUtmDetailsController({ ...addUtmDetailsController, [e.target.name]: e.target.value })
    }
  }

  const renderAddUtmDetailsForm = () => (
        <div style={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridGap: 15
          }}
        >
            <div style={{ display: 'grid', gridTemplateColumns: '48% 48%', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>Partner Type:{' '}</span>
          <Select
            value={addUtmDetailsController.partnerType}
            style={{ width: '100%' }}
            onChange={val => {
                  setAddUtmDetailsController({
                    ...addUtmDetailsController, partnerType: val, selectedAgent: get(groupedLeads, `${val}[0].id`)
                  })
                }}
          >
                {Object.keys(groupedLeads).map(partnerType => (
                  <Select.Option
                    value={partnerType}
                    label={partnerType}
                  >
                    {partnerType}
                  </Select.Option>
                ))}
          </Select>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>Agent:{' '}</span>
          <Select
            value={addUtmDetailsController.selectedAgent}
            style={{ width: '100%' }}
            onChange={val => {
                  setAddUtmDetailsController({
                    ...addUtmDetailsController, selectedAgent: val
                  })
                }}
          >
                        {get(groupedLeads, addUtmDetailsController.partnerType, []).map(partner => (
                        <Select.Option
                          value={get(partner, 'id')}
                          label={get(partner, 'agent.name')}
                        >
                            {get(partner, 'agent.name')}
                        </Select.Option>
                        ))}
          </Select>
        </div>
            </div>
      <Input
        value={addUtmDetailsController.utmUrl}
        onChange={handleUtmDetailsChange}
        placeholder='Paste the UTM URL'
        name='utmUrl'
        addonBefore='UTM Url: '
        style={{ marginBottom: '15px' }}
      />
            <Input
              value={addUtmDetailsController.source}
              onChange={handleUtmDetailsChange}
              placeholder='Type Source'
              name='source'
              addonBefore='Source: '
            />
            <Input
              value={addUtmDetailsController.campaign}
              onChange={handleUtmDetailsChange}
              placeholder='Type Campaign'
              name='campaign'
              addonBefore='Campaign: '
            />
            <Input
              value={addUtmDetailsController.term}
              onChange={handleUtmDetailsChange}
              placeholder='Type Term'
              name='term'
              addonBefore='Term: '
            />
            <Input
              value={addUtmDetailsController.content}
              onChange={handleUtmDetailsChange}
              placeholder='Type Content'
              name='content'
              addonBefore='Content: '
            />
            <Input
              value={addUtmDetailsController.medium}
              onChange={handleUtmDetailsChange}
              placeholder='Type Medium'
              name='medium'
              addonBefore='Medium: '
            />
        </div>
  )
  // const onPageChange = (page) => {
  //   setCurrentPage(page)
  // }
  React.useEffect(() => {
    (async () => {
      const partnerTypesData = await fetchLeadPartnersUtm()
      setPartnerTypes(partnerTypesData.leadPartners)
      const groupedData = get(partnerTypesData, 'leadPartners', []).reduce((accumulator, currentValue) => {
        accumulator[currentValue.title] = accumulator[currentValue.title] || []
        accumulator[currentValue.title].push(...get(currentValue, 'agents'))
        return accumulator
      }, {})
      setGroupedLeads(groupedData)
      // setPartnerTypeFilter(get(Object.keys(groupedData), '[0]', ''))
      setPartnerTypeFilter('All')
      // setSelectedAgent(get(groupedData, `${get(Object.keys(groupedData), '[0]', '')}[0].id`))
      // setSelectedAgent('All')
      setAddUtmDetailsController({
        ...addUtmDetailsController,
        partnerType: get(Object.keys(groupedData), '[0]', ''),
        selectedAgent: get(groupedData, `${get(Object.keys(groupedData), '[0]', '')}[0].id`)
      })
    })()
  }, [])
  React.useEffect(() => {
    if ((partnerTypeFilter && selectedAgent) || partnerTypeFilter === 'All') {
      fetchUtmData()
    }
  }, [partnerTypeFilter, selectedAgent])
  return (
        <div>
            <UtmDashboardStyle.HeaderContainer>
                <div>
                    <Select
                      value={partnerTypeFilter}
                      style={{ width: 200 }}
                      onChange={val => {
                        setPartnerTypeFilter(val)
                        setSelectedAgent(get(groupedLeads, `${val}[0].id`))
                      }}
                    >
                        {['All', ...Object.keys(groupedLeads)].map(partnerType => (
                        <Select.Option
                          value={partnerType}
                          label={partnerType}
                        >
                            {partnerType}
                        </Select.Option>
                        ))}
                    </Select>
          <Select
            value={selectedAgent}
            style={{ width: 200 }}
            onChange={val => setSelectedAgent(val)}
            disabled={partnerTypeFilter === 'All'}
          >
                        {[{ id: 'All', agent: { name: 'All' } }, ...get(groupedLeads, partnerTypeFilter, [])].map(partner => (
                        <Select.Option
                          value={get(partner, 'id')}
                          label={get(partner, 'agent.name')}
                        >
                            {get(partner, 'agent.name')}
                        </Select.Option>
                        ))}
          </Select>
                </div>
                <Button type='primary' onClick={showModal}>ADD UTM DETAILS</Button>
                <Modal
                  title='ADD UTM DETAILS'
                  visible={isModalVisible}
                  onOk={addUtmDetailsHandler}
                  onCancel={handleCancel}
                  footer={[
                    <Button key='cancel' onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key='submit' type='primary' loading={isLoading} onClick={addUtmDetailsHandler}>
                        Save
                    </Button>,
                ]}
                  width='50%'
                >
                    {renderAddUtmDetailsForm()}
                </Modal>
            </UtmDashboardStyle.HeaderContainer>
            <UtmDashboardStyle.MainContainer>
                <UtmDetailsTable
                  partnerTypes={partnerTypes}
                  partnerTypeFilter={partnerTypeFilter}
                  utmDetails={utmData}
                  isLoading={isLoading}
                  groupedLeads={groupedLeads}
                  fetchUtmData={fetchUtmData}
                  selectedAgent={selectedAgent}
                />
            </UtmDashboardStyle.MainContainer>
            {/* {
              utmData.length > 15 &&
              <div style={{ textAlign: 'center', paddingTop: '5.5vh', paddingBottom: '4.5vh' }}>
                <Pagination
                  total={utmData.length || 0}
                  onChange={onPageChange}
                  defaultPageSize={15}
                />
              </div>
            } */}
        </div>
  )
}


export default UtmDetails
