import React from 'react'
import { Button, Modal, notification, Select } from 'antd'
import { get } from 'lodash'
import fetchTeachers from '../../../actions/CoursePackageMaker/fetchTeachers'
import updateCoursePackagesBatch from '../../../actions/CoursePackageMaker/updateCoursePackageBatch'
import addBatch from '../../../actions/batchDashboard/addBatch'
import deleteBatch from '../../../actions/batchDashboard/deleteBatch'
import removeBatchStudents from '../../../actions/CoursePackageMaker/removeBatchStudents'
import removeFromBatchCoursePackageCourses from '../../../actions/CoursePackageMaker/removeFromBatchCoursePackageCourses'
import updateBatchCurrentComponentStatus from '../../../actions/CoursePackageMaker/updateBatchCurrentComponentStatus'

const { Option } = Select
class AssignTeacherModal extends React.Component {
    state = {
        oldCourseTeacherMap: [],
        newCourseTeacherMap: [],
        teachers: [],
        topics: [],
        isSubmittedSuccessfully: null
    }

    componentDidUpdate = async(prevProps) => {
        const { currentBatch, fetchTeachersStatus, packageDetails, teachers, showAssignTeacherModal } = this.props
        if ((showAssignTeacherModal !== prevProps.showAssignTeacherModal) && showAssignTeacherModal && (this.state.teachers && this.state.teachers.length === 0)) {
            fetchTeachers(get(currentBatch, '[0].school.id'))
        }
        if (fetchTeachersStatus !== prevProps.fetchTeachersStatus) {
            this.setState({ teachers: teachers && teachers.toJS() })
        }
        if (currentBatch !== prevProps.currentBatch) {
            const initialState = this.getInitialBatchState()
            console.log('initial state ', initialState)
            this.setState({
                topics: get(packageDetails, 'topics', []),
                newCourseTeacherMap: initialState
            })
        }
    }

    getBatchCourses = (batch) => {
        const componentRule = get(batch, 'coursePackageTopicRule', [])
        let courses = []
        for (const course of componentRule) {
            const tempCoruse = get(course, 'topic.courses[0].title')
            if (!(courses.includes(tempCoruse))) {
                courses.push(tempCoruse)
            }
        }
        return courses
    }

    getInitialBatchState = () => {
        const { currentBatch } = this.props
        let courseTeacherMapTemp = []
        if (currentBatch && currentBatch.length > 0) {
            if (currentBatch && currentBatch.length === 1) {
                const courses = get(currentBatch, '[0].coursePackage.courses', [])
                courses.length > 0 && courses.forEach(course => {
                    let obj = {}
                    obj.course = course.title
                    obj.teacher = get(currentBatch, '[0].allottedMentor.name')
                    courseTeacherMapTemp.push(obj)
                })
            } else {
                for (const batch of currentBatch) {
                    const teacher = get(batch, 'allottedMentor.name')
                    const courses = this.getBatchCourses(batch)
                    const inherited = get(batch, 'inheritedFrom.id', null)
                    for (const course of courses) {
                        let obj = {}
                        obj.course = course
                        obj.teacher = teacher
                        obj.inherited = inherited
                        courseTeacherMapTemp.push(obj)
                    }
                }
            }
        }
        return courseTeacherMapTemp
    }

    checkNullOnChange = (i, teacherMap) => {
        let inherited = null
        const masterExist = teacherMap.filter((item, index) => i !== index && get(item, 'inherited') === null)
        if (get(teacherMap[i], 'inherited') === null) {
            if (masterExist.length > 0) {
                const batch = this.getBatch(get(teacherMap[i], 'teacher'))
                inherited = get(batch, 'id')
            } else {
                inherited = null
            }
        } else {
            inherited = get(teacherMap[i], 'inherited')
        }
        return inherited
    }

    handleChange = (i, teacher) => {
        const { currentBatch } = this.props
        const { teachers } = this.state
        const teacherToAdd = teachers.filter(item => get(item, 'id') === teacher)
        const teacherName = get(teacherToAdd, '[0].name')
        if (teacherName && currentBatch) {
            const courseTeacherMapTemp = [...this.state.newCourseTeacherMap]
            if (currentBatch.length > 1) {
                const getInherited = this.checkNullOnChange(i, courseTeacherMapTemp)
                courseTeacherMapTemp[i].inherited = getInherited
            }
            courseTeacherMapTemp[i].teacher = teacherName
            this.setState({ newCourseTeacherMap: courseTeacherMapTemp })
        }
    }

    getTeachersArray = (courseMap) => {
        const teacherMap = {}
        courseMap.length && courseMap.forEach(item => {
            const teacher = item.teacher
            const course = item.course
            if (teacherMap[teacher]) {
                teacherMap[teacher] = [...teacherMap[teacher], course]
            } else {
                teacherMap[teacher] = [course]
            }
        })
        return teacherMap
    }

    getTeachersArrayMultipleBatch = (courseMap) => {
        const teacherMap = {}
        courseMap.length && courseMap.forEach(item => {
            const teacher = item.teacher
            const course = item.course
            let inherited = item.inherited
            if (teacherMap[teacher]) {
                const tempTeacherMap = teacherMap[teacher]
                const newInherited = tempTeacherMap.inherited === null ? null : inherited
                const newCourses = [...tempTeacherMap.courses, course]
                teacherMap[teacher] = { courses: newCourses, inherited: newInherited }
            } else {
                teacherMap[teacher] = { courses: [course], inherited } 
            }
        })
        return teacherMap
    }

    getTopicsByOrder = (courses) => {
        const { topics } = this.state
        let coursePackageTopicRule = []
        for(const topic of topics) {
            const course = get(topic, 'topic.courses[0].title')
            if (courses.includes(course)) {
                let obj = {}
                obj.topicConnectId = get(topic, 'topic.id')
                obj.order = topic.order
                coursePackageTopicRule.push(obj)
            }
        }
        return coursePackageTopicRule
    }

    getBatch = (teacher, oldTeacherName) => {
        const { currentBatch } = this.props
        const filteredBatch = currentBatch.filter(item => get(item, 'allottedMentor.name') === teacher)
        if (filteredBatch && filteredBatch.length > 0) {
            return filteredBatch[0]
        } else {
            const filteredBatchByOldTeacher = currentBatch.filter(item => get(item, 'allottedMentor.name') === oldTeacherName)
            return filteredBatchByOldTeacher && filteredBatchByOldTeacher.length > 0 ? filteredBatchByOldTeacher[0] : currentBatch[0]
        }
    }

    allottedMentorConnectId = (teacher) => {
        const { teachers } = this.state
        const filteredTeacher = teachers.filter(item => get(item, 'name') === teacher)
        return filteredTeacher && filteredTeacher.length > 0 && filteredTeacher[0].id
    }

    getCourseId = (name) => {
        const { packageDetails } = this.props
        const courses = get(packageDetails, 'courses')
        const course = courses.find(item => get(item, 'title') == name)
        return get(course, 'id')
    }

    getCourseIds = (topicsCourse) => {
        const { packageDetails } = this.props
        const courses = get(packageDetails, 'courses')
        let coursesId = []
        topicsCourse.forEach(item => {
            courses.forEach(course => {
                if (item == get(course, 'title')) {
                    coursesId.push(get(course, 'id'))
                }
            })
        })
        return coursesId
    }

    getOldTeacherName = (teacherMap) => {
        const filetedTeacher = Object.keys(teacherMap).filter(item => get(teacherMap[item], 'inherited') === null)
        return filetedTeacher && filetedTeacher.length > 0 ? filetedTeacher[0] : null
    }

    addBatchQuery = async (inheritedId, teacher, courses, isSubmittedSuccessfully) => {
        const { addBatchStatus, packageDetails, currentBatch } = this.props
        const students = get(currentBatch, '[0].students', [])
        const classes = get(currentBatch, '[0].classes', [])
        const coursePackageConnectId = get(packageDetails, 'id')
        const allottedMentorConnectId = this.allottedMentorConnectId(teacher)
        const schoolConnectId = get(currentBatch, '[0].school.id')
        const batchStudentsConnectIds = students.length > 0 && students.map(item => get(item, 'id'))
        const classesConnectIds = classes.length > 0 && classes.map(item => get(item, 'id'))
        const inheritedFromConnectId = inheritedId
        const courseConnectId = this.getCourseId(courses[0])
        const coursePackageCoursesConnectIds = this.getCourseIds(courses)

        let input = {
            code: get(currentBatch, '[0].code'),
            type: get(currentBatch, '[0].type'),
            documentType: get(currentBatch, '[0].documentType'),
            coursePackageTopicRule: this.getTopicsByOrder(courses),
            isTeacherTraining: get(currentBatch, '[0].isTeacherTraining')
        }
        if (get(currentBatch, '[0].classroomTitle')) {
            input.classroomTitle = get(currentBatch, '[0].classroomTitle')
        }
        if (get(currentBatch, '[0].description')) {
            input.description = get(currentBatch, '[0].description')
        }
        await addBatch(input, courseConnectId, allottedMentorConnectId, coursePackageConnectId, schoolConnectId, batchStudentsConnectIds, classesConnectIds, inheritedFromConnectId, coursePackageCoursesConnectIds)
        if (addBatchStatus && get(addBatchStatus.toJS(), 'success')) {
            isSubmittedSuccessfully.add = true
        }
        if (addBatchStatus && get(addBatchStatus.toJS(), 'failure')) {
            isSubmittedSuccessfully.add = false
        }
    }

    updatedUpdateBatchQuery = async(batch, teacher, courses, isSubmittedSuccessfully) => {
        const { updateBatchStatus } = this.props
        const coursePackageCourses = get(batch, 'coursePackageCourses', [])
        const fetchedCourses = coursePackageCourses.map(item => get(item, 'title'))
        const courseIdsToAdd = []
        const courseIdsToDelete = []
        const batchId = get(batch, 'id')
        if (batchId) {
            fetchedCourses.forEach(item => {
                if (!courses.includes(item)) {
                    courseIdsToDelete.push(item)
                }
            })
            courses.forEach(item => {
                if (!fetchedCourses.includes(item)) {
                    courseIdsToAdd.push(item)
                }
            })
            const topicsToUpdate = this.getTopicsByOrder(courses)
            const input = {
                coursePackageTopicRule: { replace: topicsToUpdate }
            }
            const allottedMentorConnectId = this.allottedMentorConnectId(teacher)
            const coursePackageCoursesConnectIds = this.getCourseIds(courseIdsToAdd)
            const coursePackageCoursesConnectIdsDelete = this.getCourseIds(courseIdsToDelete)
    
            await updateCoursePackagesBatch(batchId, allottedMentorConnectId, coursePackageCoursesConnectIds, input)
            
            const currentComponentId = get(batch, 'currentComponent.id', null)
            if (currentComponentId) {
                await updateBatchCurrentComponentStatus(currentComponentId, get(topicsToUpdate, '[0].topicConnectId'))
            }
            for (let i=0; i<coursePackageCoursesConnectIdsDelete.length; i++) {
                await removeFromBatchCoursePackageCourses(batchId, null, coursePackageCoursesConnectIdsDelete[i])
            }
            if (updateBatchStatus && get(updateBatchStatus.toJS(), 'success')) {
                isSubmittedSuccessfully.update = true
            }
            if (updateBatchStatus && get(updateBatchStatus.toJS(), 'failure')) {
                isSubmittedSuccessfully.update = false
            }
        }
    }

    updateBatchQuery = async (teacher, oldTeacherName, courses, isSubmittedSuccessfully) => {
        const batch = this.getBatch(teacher, oldTeacherName)
        await this.updatedUpdateBatchQuery(batch, teacher, courses, isSubmittedSuccessfully)
    }

    deleteBatchQuery = async (teacher, isSubmittedSuccessfully) => {
        const { deleteBatchStatus } = this.props
        const batch = this.getBatch(teacher, null)
        if (batch.id) {
            await removeBatchStudents(batch.id)
            await deleteBatch(batch.id)
            if (deleteBatchStatus && get(deleteBatchStatus.toJS(), 'success')) {
                isSubmittedSuccessfully.delete = true
            }
            if (deleteBatchStatus && get(deleteBatchStatus.toJS(), 'failure')) {
                isSubmittedSuccessfully.delete = false
            }
        }
    }

    handleMasterBatchUpdate = async (newTeacher, courses, oldCourseMapMultiple, isSubmittedSuccessfully) => {
        const teacherName = this.getOldTeacherName(oldCourseMapMultiple)
        const batch = this.getBatch(teacherName, null)
        await this.updatedUpdateBatchQuery(batch, newTeacher, courses, isSubmittedSuccessfully)
    }

    checkBatchWithNoCourse = (teacher, oldCourseMapMultiple, newCourseMapMultiple) => {
        if (newCourseMapMultiple[teacher] && newCourseMapMultiple[teacher].inherited === null && oldCourseMapMultiple[teacher].inherited !== null) {
            const prevCourse = oldCourseMapMultiple[teacher].courses
            if (prevCourse.length === 1) {
                const presentCourses = newCourseMapMultiple[teacher].courses
                return presentCourses.includes(get(prevCourse, '[0]'))
            }
        }
    }
 
    handleSave = async () => {
        const { currentBatch, fetchCoursePackageBatches, handleCancel } = this.props
        const { newCourseTeacherMap } = this.state
        const oldCourseTeacherMap = this.getInitialBatchState()
        console.log('oldCourseTeacherMap ', oldCourseTeacherMap, newCourseTeacherMap)
        let isSubmittedSuccessfully = {}
        if (currentBatch && currentBatch.length === 1) {
            let oldTeacherMap = this.getTeachersArray(oldCourseTeacherMap)
            let newTeacherMap = this.getTeachersArray(newCourseTeacherMap)
            const firstTeacher = oldCourseTeacherMap[0].teacher
            if (newTeacherMap[firstTeacher]) {
                for (const teacher in newTeacherMap) {
                    const courses = newTeacherMap[teacher]
                    if (oldTeacherMap[teacher]) {
                        if (oldTeacherMap[teacher].sort().toString() !== newTeacherMap[teacher].sort().toString()) {
                            await this.updateBatchQuery(teacher, null, courses, isSubmittedSuccessfully)
                        }
                    } else {
                        await this.addBatchQuery(get(currentBatch, '[0].id'), teacher, courses, isSubmittedSuccessfully)
                    }
                }
            } else {
                Object.keys(newTeacherMap).map(async(teacher, i) => {
                    const courses = newTeacherMap[teacher]
                    if (i==0) {
                        await this.updateBatchQuery(teacher, null, courses, isSubmittedSuccessfully)
                    } else {
                        await this.addBatchQuery(get(currentBatch, '[0].id'), teacher, courses, isSubmittedSuccessfully)
                    }
                })
            }
        } else {
            const oldCourseMapMultiple = this.getTeachersArrayMultipleBatch(oldCourseTeacherMap)
            const newCourseMapMultiple = this.getTeachersArrayMultipleBatch(newCourseTeacherMap)
            for (const teacher in newCourseMapMultiple) {
                const courses = newCourseMapMultiple[teacher].courses
                const oldCourseMapMultipleString = oldCourseMapMultiple[teacher] && oldCourseMapMultiple[teacher].courses.sort().toString()
                const oldTeacherName = this.getOldTeacherName(oldCourseMapMultiple)
                if (newCourseMapMultiple[teacher].inherited == null) {
                    if (oldCourseMapMultipleString !== newCourseMapMultiple[teacher].courses.sort().toString() || oldTeacherName !== teacher) {
                        this.handleMasterBatchUpdate(teacher, courses, oldCourseMapMultiple, isSubmittedSuccessfully)
                    }
                } else if (oldCourseMapMultiple[teacher]) {
                    if (oldCourseMapMultipleString !== newCourseMapMultiple[teacher].courses.sort().toString()) {
                        await this.updateBatchQuery(teacher, oldTeacherName, newCourseMapMultiple[teacher].courses, isSubmittedSuccessfully)
                    }
                } else {
                    await this.addBatchQuery(newCourseMapMultiple[teacher].inherited, teacher, newCourseMapMultiple[teacher].courses, isSubmittedSuccessfully)
                }
            }
            for (const teacher in oldCourseMapMultiple) {
                if ((!newCourseMapMultiple[teacher] && oldCourseMapMultiple[teacher].inherited !== null) || (this.checkBatchWithNoCourse(teacher, oldCourseMapMultiple, newCourseMapMultiple))) {
                    await this.deleteBatchQuery(teacher, isSubmittedSuccessfully)
                }
            }
        }
        let message = null
        for (const temp in isSubmittedSuccessfully) {
            message = true
            if (!isSubmittedSuccessfully[temp]) {
                message = false
                break
            }
        }
        if (message !== null) {
            if (message) {
                notification.success({
                    message: 'Batches updated successfully'
                })
                handleCancel()
                fetchCoursePackageBatches()
            } else {
                notification.error({
                    message: 'Something went wrong'
                })
            }
        }
    }

    render() {
        const { showAssignTeacherModal, addBatchStatus, updateBatchStatus, deleteBatchStatus, fetchTeachersStatus } = this.props
        const { newCourseTeacherMap, teachers } = this.state
        return (
            <Modal
                visible={showAssignTeacherModal}
                onCancel={() => this.props.handleCancel()}
                footer={null}
                title='Assign Teacher'
            >
                <div>
                    {newCourseTeacherMap && newCourseTeacherMap.length > 0 && newCourseTeacherMap.map((item, i) => (
                        <div
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}
                        >
                            <p style={{ marginRight: '20px' }}>{get(item , 'course')}</p>
                            <Select
                                showSearch
                                defaultValue={item.teacher}
                                style={{
                                    minWidth: '200px',
                                    maxWidth: '200px'
                                }}
                                onChange={(value) => this.handleChange(i, value)}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                get(option, 'props.children')
                                    ? get(option, 'props.children')
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                    : false
                                }
                                loading={fetchTeachersStatus && get(fetchTeachersStatus.toJS(), 'loading')}
                            >
                                {teachers.length > 0 && teachers.map(teacher => (
                                    <Option value={teacher.id}>{teacher.name}</Option>
                                ))}
                            </Select>
                        </div>
                    ))}
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                        <Button
                            type='primary'
                            icon='file'
                            id='add-btn'
                            onClick={this.handleSave}
                            loading={(addBatchStatus && get(addBatchStatus.toJS(), 'loading')) ||
                            (updateBatchStatus && get(updateBatchStatus.toJS(), 'loading')) ||
                            (deleteBatchStatus && get(deleteBatchStatus.toJS(), 'loading'))}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default AssignTeacherModal