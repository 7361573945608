import gql from 'graphql-tag';
import duck from '../../duck';

const updateMentorActiveStatus = async (id, input) => duck.query({
    query:gql`
        mutation($input: MentorProfileUpdate) {
        updateMentorProfile(id: "${id}", input: $input) {
            id
            isMentorActive
        }}
    `,
    variables: {
        input
    },
    type: 'updateMentor/update',
    key: 'mentorProfileUpdate',
    changeExtractedData: (extractedData, originalData) => {
    extractedData.updateMentorProfile = originalData.updateMentorProfile;
    return { ...extractedData };
    }
})

export default updateMentorActiveStatus