import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import React from 'react'
import MainModal from '../../../components/MainModal'
import { Form as AntForm, Input, Button, notification } from "antd";
import styles from '../SpeakerPrizes/SpeakerPrizes.module.scss'
import { get } from 'lodash';
import sendCommsMessage from '../../../actions/eventDashboard/sendCommsMessage';
import * as yup from 'yup'

class EnterTestDataModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSubmitting: false,
            isParentPhoneNumber: false,
        }
    }

    initialValuesFromProps = () => {
        const { modalData } = this.props
        const data = {}
        const schema = {}
        if (modalData && modalData.length) {
            modalData.forEach((elem) => {

                data[get(elem, 'dataField')] = get(elem, 'value')
                if (get(elem, 'dataField') === 'parentPhone') {
                    schema[get(elem, 'dataField')] = yup.string().required('Phone (Not Found)').matches(/^[6-9]\d{9}$/, 'Phone (Invalid format)')
                } else if (get(elem, 'dataField') === 'parentEmail') {
                    schema[get(elem, 'dataField')] = yup.string().required('Phone (Not Found)').email('Invalid email')
                } else {
                    schema[get(elem, 'dataField')] = yup.string().required('Input required').min(3, 'Input (Min. 3 characters)').max(30, 'Input (Max. 30 characters)')
                }
            })
            return [data, yup.object().shape(schema)]
        }
        return [{}, yup.object().shape({})]

    }

    handleSubmit = async (value) => {
        this.setState({
            isSubmitting: true
        })
        if (!get(this.props, 'templateName')) {
            this.setState({
                isSubmitting: true
            })
            return notification.warn({
                message: 'Please add template name'
            })
        }
        let input = '{'
        const dataKeys = Object.keys(value)
        dataKeys.forEach((key) => {
            input += `${key}:"${value[key]}",`
        })
        if (this.props.isMail) {
            input += `mail:true,`
        }
        input += `templateName: "${get(this.props, 'templateName')}"}`
        await sendCommsMessage(input).then(res => {
            if (get(res, 'data.sendCommsMessage.result')) {
                this.setState({
                    isSubmitting: false
                })
                this.props.isTemplateChecked(true, '', value, this.props.modalId)
                this.props.closeModal()
            }
        }).catch(err => {
            this.setState({
                isSubmitting: false
            })
            if (get(err, 'errors[0].message')) {
                notification.error({
                    message: get(err, 'errors[0].message')
                })
            } else notification.error({
                message: 'Something went wrong'
            })
        })
    }

    render() {
        const { closeModal, visible, modalData, templateName } = this.props
        const propsData = this.initialValuesFromProps()
        const data = propsData[0]
        const schema = propsData[1]
        return <>
            <MainModal
                visible={visible}
                title={[
                    <div>
                        <div style={{ textAlign: 'center' }}>Enter Test Data</div>
                    </div>]}
                onCancel={closeModal}
                maskClosable='true'
                width='500px'
                className={styles.addSpeakerModal}
                footer={null}
            >
                <Formik
                    initialValues={data}
                    validationSchema={schema}
                    enableReinitialize={true}
                    onSubmit={this.handleSubmit}
                >
                    {({ values, handleChange, errors, touched }) => (
                        <Form
                            labelCol={{
                                span: 21,
                            }}
                            wrapperCol={{
                                span: 21,
                            }}
                            layout="vertical"
                            initialValues={{
                                size: 'default',
                            }}
                            size={'default'}>
                            <div className={styles.addSpeakerProfileForm}>
                                {
                                    modalData && modalData.length > 0 && templateName
                                    && modalData.map((field) => (
                                        <AntForm.Item label={field.label}  >
                                            <Input
                                                style={{ width: '160px' }}
                                                placeholder={`Enter ${field.label}`}
                                                name={field.dataField}
                                                value={values[field.dataField]}
                                                onChange={handleChange}
                                            />
                                            {errors[field.dataField] && touched[field.dataField] ? (<div style={{ color: 'red', fontSize: '0.6rem', margin: '0px', lineHeight: '8px' }}>{errors[field.dataField]}</div>) : null}
                                        </AntForm.Item>
                                    ))
                                }
                                {
                                    !templateName
                                    && (
                                        <div>Kindly enter template name, to be sent.</div>
                                    )
                                }
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '10px' }}>
                                    <Button
                                        type='primary'
                                        icon='file'
                                        id='add-btn'
                                        htmlType='submit'
                                        loading={this.state.isSubmitting}
                                        disabled={!templateName}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </Form>

                    )}
                </Formik>
            </MainModal>
        </>
    }
}

const mapStateToProps = state => ({
    commsMessage: state.data.getIn(['commsMessage', 'data']),
    commsFailure: state.data.getIn(['errors', 'commsMessage/fetch'])
})

export default connect(mapStateToProps)(EnterTestDataModal)