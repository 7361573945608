import gql from 'graphql-tag'
import duck from '../../duck'

const FETCH_MENTORS_LIST = (sales) => gql`
  query {
    users(filter: { and:[{ role: mentor }{ secondaryRole_exists: false }]}) {
      id
      role
      name
      email
      phone {
        countryCode
        number
      }
      mentorProfile {
        id
        ${sales ? 'salesExecutive' : 'senseiProfile'} {
          id
        }
      }
    }
  }
`;

const fetchMentorsList = async (sales) =>
  duck.query({
    query: FETCH_MENTORS_LIST(sales),
    type: 'users/fetch',
    key: 'users',
  })

export default fetchMentorsList
