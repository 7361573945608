import { Button, Modal, Select, Table,message, Icon, Tooltip } from "antd";
import Stack from "../../../../components/Stack";
import React, { useEffect, useRef, useState } from "react";
import Label from "../../../../components/Label";
import { get } from "lodash";
import "./style.scss";
import gql from "graphql-tag";
import requestToGraphql from "../../../../utils/requestToGraphql";
import { useParams } from "react-router-dom";
import FileDrop from "./FileDrop";
import UploadStatus from "./UploadStatus";
import RedCrossIcon from "./RedCrossIcon";

const configStatus = {
  new: "New Field",
  update: "Override",
  noChange: "noChange",
};
const overviewStatus = {
  add: "New",
  update: "Update",
};
export const uploadStatus = {
  notUpload: "Not Upload",
  upload: "upload",
};
const defaultConfiguration = {
  totalComputers: {
    value: "totalNumberOfComputers",
    label: "Total Number of Computers",
  },

  totalWorkingComputers: {
    value: "totalNumberOfWorkingComputers",
    label: "Total Working Computers",
  },
  selectedSpeaker: {
    value: "speakers",
    label: "Speaker",
  },
  selectedPowerBackup: {
    value: "powerBackup",
    label: "Power Backup",
  },
  selectedProjector: {
    value: "projectInteractivePanel",
    label: "Projector",
  },
  internetMode: {
    value: "internetConnection",
    label: "Internet Mode",
  },
  internetSpeed: {
    value: "internetSpeed",
    label: "Internet Speed",
  },
  selectedPowerBackupType: {
    value: "powerBackupType",
    label: "Power Backup Type",
  },
  serviceProviderType: {
    value: "serviceProviderType",
    label: "Service Provider Type",
  },
  inspectionDate: {
    value: "inspectionDate",
    label: "Inspection Date",
  },
  sharedSystemArchSetup: {
    value: "sharedSystemArchSetup",
    label: "Shared System Arch Setup",
  },
  masterSystem: {
    value: "masterSystem",
    label: "Master System",
  },
  totalNumberOfConnectedSystems: {
    value: "totalNumberOfConnectedSystems",
    label: "Total Number of Connected Systems",
  },
};

const uploadOverview = {
  totalfiles: "Total Files",
  newSystems: "New Systems",
  updatedSystems: "Updated Systems",
};

function UploadFileModal({ labs, handleClose = () => {}, open, labInspections,getSchoolName,fetchData,openAddModal }) {
  const { schoolId } = useParams();
  const [step, setStep] = React.useState(1);
  const [isSubmitting,setIsSubmitting] = React.useState(false);
  const [files, setFiles] = useState([]);
  const [currentLab, setCurrentLab] = useState();
  const [latestLabConfiguration, setLatestLabConfiguration] = useState(null);
  const [finalLabConfiguration, setFinalLabConfiguration] = useState([]);
  const [invalidConfiguration, setInvalidConfiguration] = useState(false);
  const [finalSystems, setFinalSystems] = useState({});
  const [systemDataFromUploadedFile, setSystemDataFromUploadedFile] = useState(
    []
  );
  const [showUploadStatus,setShowUploadStatus] = useState(false)
  const configureSteps = [
    {
      title: "Upload File",
      content: (
        <UploadFirstStep
          labs={labs}
          setFiles={setFiles}
          setLatestLabConfiguration={setLatestLabConfiguration}
          setCurrentLab={setCurrentLab}
          currentLab={currentLab}
          files={files}
          handleClose={handleClose}
          openAddModal={openAddModal}
          labInspections={labInspections}
        />
      ),
      footer: (
        <Button
          disabled={!latestLabConfiguration}
          type="primary"
          key="next"
          onClick={async () => {
            const {
                systemOverview,
                labConfigurationStatus,
                systemDataFromUploadedFile,
              } = await initalSetupForSecondSetup({
                currentLab,
                latestLabConfiguration,
                files,
              });
            setFinalSystems(systemOverview);
            setFinalLabConfiguration(labConfigurationStatus);
            setSystemDataFromUploadedFile(systemDataFromUploadedFile);
            setStep(step + 1);
            

          }}
        >
          Next
        </Button>
      ),
    },
    {
      title: "Summary of Upload",
      content: (
        <UploadSecondStep
          setFinalLabConfiguration={setFinalLabConfiguration}
          finalLabConfiguration={finalLabConfiguration}
          setInvalidConfiguration={setInvalidConfiguration}
          setFinalSystems={setFinalSystems}
          systemDataFromUploadedFile={systemDataFromUploadedFile}
          finalSystems={finalSystems}
          setSystemDataFromUploadedFile={setSystemDataFromUploadedFile}
        />
      ),
      footer: (
        <>
          <div className="submit-footer">
            <UploadStatus
              systemDataFromUploadedFile={systemDataFromUploadedFile}
              showUploadStatus={showUploadStatus}
            />
            <div className="handle-btns">
              <Button
                key="back"
                onClick={() => {
                  setStep(step - 1);
                }}
              >
                Go back
              </Button>
              <Button
                key="Submit"
                type="primary"
                disabled={invalidConfiguration}
                title={invalidConfiguration ? 'Invalid Configuration' : ''}
                loading={isSubmitting}
                onClick={async () => {
                  const labId = get(currentLab, "[0].id", "");
                  setIsSubmitting(true);
                  setShowUploadStatus(true);
                  await updateLabConfiguration(labId, finalLabConfiguration);
                  const newSystems = [];
                  systemDataFromUploadedFile.forEach((system) => {
                    const { uniqueDeviceId, serialNo } = system;
                    const isSystemExist = newSystems.find(
                      (newSystem) =>
                        newSystem.uniqueDeviceId === uniqueDeviceId &&
                        newSystem.serialNo === serialNo
                    );
                    if (!isSystemExist) {
                      newSystems.push(system);
                    }
                  });

                  for (let i = 0; i < newSystems.length; i++) {
                    const res = await updateLabSystem(
                      labId,
                      schoolId,
                      newSystems[i],
                      files[i].data
                    );
                    if (res) {
                      setSystemDataFromUploadedFile((prev) => {
                        const newSystemDataFromUploadedFile = [...prev];
                        newSystemDataFromUploadedFile[i].uploadStatus =
                          uploadStatus.upload;
                        return newSystemDataFromUploadedFile;
                      });
                    }
                  }
                  setTimeout(() => {
                    setShowUploadStatus(false);
                    // reset
                    setStep(1);
                    setFiles([]);
                    setLatestLabConfiguration(null);
                    setCurrentLab(null);
                    setFinalLabConfiguration([]);
                    setInvalidConfiguration(false);
                    setFinalSystems({});
                    setSystemDataFromUploadedFile([]);
                    handleClose();
                    fetchData();
                  }, 2000);
                  setIsSubmitting(false);
                }}
              >
                Sync Data
              </Button>
            </div>
          </div>
        </>
      ),
    },
  ];

  const currentStep = configureSteps[step - 1];
  const labInfo = {
    'School Name': getSchoolName(schoolId),
    ...(currentLab && {
      'Lab Name': get(currentLab, "[0].labName", ""),
      'Lab No.': get(currentLab, "[0].labNo", ""),
    }),
  };

  return (
    <Modal
      wrapClassName="upload-file-modal"
      title={currentStep.title}
      visible={open}
      onCancel={handleClose}
      footer={[configureSteps[step - 1].footer]}
      centered
    >
      <Stack gap={25}>
        <LabInfo info={labInfo}> </LabInfo>
        {currentStep.content}
      </Stack>
    </Modal>
  );
}

const UploadFirstStep = ({
  labs,
  setFiles,
  setLatestLabConfiguration,
  setCurrentLab,
  labInspections,
  currentLab,
  files,
  handleClose,
  openAddModal,
}) => {
  return (
    <>
      <Stack gap={15}>
        <Stack gap={5}>
          <Label color="#292A2F" htmlFor="lab-name">
            Lab
          </Label>
          <Select
            id="lab"
            placeholder="Lab"
            size="medium"
            onChange={(value) => {
              const currentLab = getCurrentLab(labInspections, value);
              setCurrentLab(currentLab);
            }}
            // defaultValue={
            //   get(currentLab, "[0].id", "")
            //     ? get(currentLab, "[0].id", "")
            //     : undefined
            // }
          >
            {labs.length > 0
              ? labs.map((lab) => (
                  <Option
                    value={get(lab, "value", "")}
                    label={get(lab, "label", "")}
                  >
                    {get(lab, "label", "")}
                  </Option>
                ))
              : null}
          </Select>
          <p className="add-lab-text">To add a new lab,
                <span onClick={() => {
                  handleClose()
                  openAddModal()

                }}> click here</span>
          </p>
        </Stack>
        <Stack>
          <FileDrop
            disabled={get(currentLab, "[0].id", false)}
            files={files}
            onDrop={(acceptedFiles) => {
              setFiles(acceptedFiles);
              const fileLabConfiguration = getLabConfigurationData(acceptedFiles)
              if(!!fileLabConfiguration){
                setLatestLabConfiguration(fileLabConfiguration);
              }
            }}
          ></FileDrop>
        </Stack>
      </Stack>
    </>
  );
};

const labConfigurationFieldValidations = {
  selectedProjector: ['smartBoard', 'projector', 'smartTV', 'none'],
  selectedSpeaker: ['centralisedSpeaker', 'headphones', 'none'],
  selectedPowerBackupType: ['centralised', 'individual', 'none'],
  selectedPowerBackup: ['yes', 'no', 'partial'],
  internetMode: ['hotspot', 'lan', 'wifi', 'none'],
  serviceProviderType: ['broadband', 'leasedLine', 'fiberOpticConnection', 'simDongle', 'none'],
  sharedSystemArchSetup: ['yes', 'no', 'partial'],
  masterSystem: ['yes', 'no', 'partial'],
}

const checkIfValueIsValid = (value, record) => {
  if (record && labConfigurationFieldValidations[record.field]) {
    const isValid = labConfigurationFieldValidations[record.field].includes(value)
    return !isValid ? labConfigurationFieldValidations[record.field] : true;
  }
  return true;
};

const UploadSecondStep = ({
  finalLabConfiguration,
  finalSystems,
  systemDataFromUploadedFile,
  setSystemDataFromUploadedFile,
  setInvalidConfiguration,
  setFinalLabConfiguration,
  setFinalSystems,
}) => {
  
  const labConfigurationColumns = [
    {
      title: "Configuration Field",
      dataIndex: "field",
      key: "field",
      render: (field) => {
        const newField = defaultConfiguration[field].label;
        return <div>{newField}</div>
      },
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (value, record) => {
        const validOptions = checkIfValueIsValid(value, record);
        if (!validOptions) setInvalidConfiguration(true);
        return (validOptions && validOptions.length) ?
            (
              <Tooltip placement="top" title={`Invalid Value. Acceptable values - ${validOptions.join(", ")}`} style={{
                
            }}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}> 
                  {value}
                  <RedCrossIcon />
                </div>
              </Tooltip>
            ) : value
      }
    },
    {
      title: "Update Type",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let mode = "default";
        if (status == configStatus.new) mode = "new";
        else if (status === configStatus.update) mode = "success";
        return <StatusPill status={status} mode={mode}></StatusPill>;
      },
    },
  ];
  const renderLabConfigurationContent = () => {
    if (finalLabConfiguration && finalLabConfiguration.length > 0) {
      return (
        <>
          <Stack gap={10}>
            <Label color="#000">Lab Meta Summary</Label>
            <div className="labconfig-table">
              <Table
                columns={labConfigurationColumns}
                dataSource={finalLabConfiguration}
                pagination={false}
                scroll={{ y: 140 }}
              ></Table>
            </div>
          </Stack>
        </>
      );
    }
  };

  const showOverviewColumns = [
    {
      title: "SrNo",
      dataIndex: "serialNo",
      ellipsis: true,
      width: 100,
      key: "serialNo",
    },
    {
      title: "Device Id",
      dataIndex: "uniqueDeviceId",
      key: "uniqueDeviceId",
      width: 200,

      ellipsis: true,
    },
    {
      title: "status",
      dataIndex: "status",
      width: 100,
      key: "status",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      // width: 100,
      ellipsis: true,
      key: "comment",
      render: (text) => {
        try {
          return decodeURIComponent(text);
        } catch {
          return text;
        }
      },
    },
    {
      title: "System Type",
      dataIndex: "overviewStatus",
      key: "overviewStatus",
      ellipsis: true,
      align: "center",
      fixed: "right",
      render: (systemType) => {
        const {add,update} = overviewStatus
        let mode;
        if(systemType === update) mode="default"
        else if(systemType === add) mode="success"
        return (
          <div style={{ marginLeft: "10px" }}>
            <StatusPill status={systemType} mode={mode} noIcon tab></StatusPill>
          </div>
        );
      },
    },
    {
      title: "Action",
      fixed: "right",
      align: "center",

      render: (text, record, index) => {
        return (
          <Icon
            style={{ color: "red" }}
            onClick={() => {
              const updatedSystemData = systemDataFromUploadedFile.filter(
                (item, i) => i !== index
              );
              setSystemDataFromUploadedFile(updatedSystemData);
            }}
            type="delete"
          />
        );
      },
    },
  ];
  return (
    <>
      <Stack gap={20}>
        <div classNme="lab-configure-overview">
          {renderLabConfigurationContent()}
        </div>
        <Stack gap={10}>
          <Label color="#000">Upload Overview</Label>
          <div className="upload-overview">
            <div className="upload-overview-container">
              {Object.keys(finalSystems).map((key) => {
                const {
                  totalfiles,
                  newSystems,
                  updatedSystems,
                } = uploadOverview;
                let mode = "default";
                if (key === newSystems) mode = "success";
                else if (key === totalfiles) mode = "new";
                const status = `${key} - ${finalSystems[key]}`;
                return (
                  <StatusPill
                    status={status}
                    mode={mode}
                    noIcon
                    tab
                  ></StatusPill>
                );
              })}
            </div>
          </div>
        </Stack>
        <Stack gap={10}>
          <div className="upload-overview-table">
            <Table
              columns={showOverviewColumns}
              dataSource={systemDataFromUploadedFile}
              pagination={false}
              scroll={{ x: 700 }}
            ></Table>
          </div>
        </Stack>
      </Stack>
    </>
  );
};

const initalSetupForSecondSetup = async ({
  currentLab,
  latestLabConfiguration,
  files,
}) => {
  const labConfiguration = get(currentLab, "[0].labConfiguration", {});
  const systems = get(currentLab, "[0].systems", []);
  // There are there status :- New upload to field value, No change in field value, Update in field value for labConfiguration
  const labConfigurationStatus = [];
  Object.keys(latestLabConfiguration).forEach((field) => {
    const newField = defaultConfiguration[field].value;
    if (!labConfiguration[field] && !latestLabConfiguration[field]) return;
    if(!latestLabConfiguration[field] || latestLabConfiguration[field] === "0") return
    if (!newField) {
      labConfigurationStatus.push({
        field,
        status: configStatus.new,
        value: latestLabConfiguration[field],
      });
    } else if (newField !== latestLabConfiguration[field]) {
      labConfigurationStatus.push({
        field,
        status: configStatus.update,
        value: latestLabConfiguration[field],
      });
    } else if (newField === latestLabConfiguration[field]) {
      labConfigurationStatus.push({
        field,
        status: configStatus.update,
        value: newField,
      });
    }
  });
  const { totalfiles, newSystems, updatedSystems } = uploadOverview;
  const systemOverview = {
    [totalfiles]: 0,
    [newSystems]: 0,
    [updatedSystems]: 0,
  };

  const systemDataFromUploadedFile = [];
  
  for (const file of files) {
    const fileName = file.name;
    let serialNoFromFileName =
    fileName.match(/sr_no_(\d+)/) || fileName.match(/sr_no (\d+)/);
    const fileData = file.data;
    const systemFileData = {};
    const fileDataByCategory = {};

    if (fileData.length > 0) {
      fileData.forEach((item) => {
        fileDataByCategory[item["Category"]] = item;
      });
      const uniqueDeviceId = get(fileDataByCategory, "uniqueDeviceId.Value", "");
      const serialNo = get(fileDataByCategory, "serialNo.Value", "");
      const systemStatus = get(fileDataByCategory, "status.Status", "");
      const comment = get(fileDataByCategory, "comment.Value", "");
      
      if (!serialNoFromFileName) {
        const system = systems.find((system) => {
          return system.uniqueDeviceId === uniqueDeviceId;
        });
        if (system) {
          systemFileData["overviewStatus"] = overviewStatus.update;
        } else {
          systemFileData["overviewStatus"] = overviewStatus.add;
        }
        systemFileData["uniqueDeviceId"] = uniqueDeviceId;
        systemFileData["id"] = system ? system.id : null;
      } else {
        const filterudi = systems.filter((item) => {
          return item.uniqueDeviceId === uniqueDeviceId;
        });
        const filterSerialNo =
        filterudi.length > 0
            ? filterudi.filter((item) => {
                return parseInt(item.serialNo) === parseInt(serialNoFromFileName[1]);
              })
            : 0;
        const totalUdi = filterudi.length;
        const totalSerialNo = filterSerialNo.length;
        if (totalUdi > 0) {
          if (totalUdi === totalSerialNo) {
            const system = systems.find((system) => {
              return (
                (system.serialNo === serialNoFromFileName[1] ||
                  system.serialNo === parseInt(serialNoFromFileName[1])) &&
                system.uniqueDeviceId === uniqueDeviceId
              );
            });
            systemFileData["uniqueDeviceId"] = uniqueDeviceId;
            systemFileData["id"] = system ? system.id : null;
            systemFileData["overviewStatus"] = overviewStatus.update;
          } else {
            const id = `${uniqueDeviceId}:${serialNoFromFileName[1]}`;
            systemFileData["uniqueDeviceId"] = id;
            const system = systems.find((system) => {
                return system.uniqueDeviceId === id;
             });
            if(system) {
              systemFileData["overviewStatus"] = overviewStatus.update;
              systemFileData["id"] = system.id;
            }
            else{
              systemFileData["overviewStatus"] = overviewStatus.add;

            }
          }
        } else {
          systemFileData["uniqueDeviceId"] = uniqueDeviceId;
          systemFileData["overviewStatus"] = overviewStatus.add;
        }
      }
      systemFileData["serialNo"] = serialNoFromFileName ? serialNoFromFileName[1] : serialNo;
      systemFileData["comment"] = comment ? comment : null;
      systemFileData["status"] = systemStatus ? systemStatus : null;
    }
    systemFileData["uploadStatus"] = uploadStatus.notUpload;
    systemDataFromUploadedFile.push(systemFileData);
  }
  systemOverview[totalfiles] = systemDataFromUploadedFile.length;
  systemOverview[newSystems] = systemDataFromUploadedFile.filter(
    (system) => system.overviewStatus === overviewStatus.add
  ).length;
  systemOverview[updatedSystems] = systemDataFromUploadedFile.filter(
    (system) => system.overviewStatus === overviewStatus.update
  ).length;

  return {
    labConfigurationStatus,
    systemOverview,
    systemDataFromUploadedFile,
  };
};

const getLabConfigurationData = (data) => {
  let latestLabConfigurationData = [];
  data.forEach((file) => {
    const fileData = file.data;
    // chech if file has value labConfiguratione in type column
    const labConfiguration = fileData.filter(
      (item) => item["Type"] === "labConfiguration"
    );
    if (labConfiguration.length > 0) {
      latestLabConfigurationData = labConfiguration;
    }
  });

  const latestLabConfiguration = {};
  if (latestLabConfigurationData.length > 0) {
    latestLabConfigurationData.forEach((item) => {
      latestLabConfiguration[item["Category"]] = item["Value"];
    });
  } else {
    message.error("No lab configuration found in uploaded file");
    return false;
  }
  return latestLabConfiguration;
};

const getCurrentLab = (labs, id) => {
  return labs.filter((lab) => lab.id === id);
};

const updateLabConfiguration = async (
  labId,
  labConfiguration,
  inspectionChecks
) => {
  let labConfigurationString = "labConfiguration:{";
  labConfiguration.forEach((item) => {
    let field = defaultConfiguration[item.field].value;
    let value =
      item.field === "inspectionDate" ? `"${item.value}"` : item.value;
    if (
      item.field === "totalComputers" ||
      item.field === "totalWorkingComputers" ||
      item.field === "internetSpeed" ||
      item.field === "totalNumberOfConnectedSystems"
    ) {
      value = parseInt(item.value);
    }

    labConfigurationString += `${field}: ${value},`;
  });
  labConfigurationString += "}";

  const queryString = gql`mutation {
    updateLabInspection(
      id: "${labId}"
      input:{
      ${labConfigurationString || ""}
    }) {
      id
      labName
    }
  } `;
  const res = await requestToGraphql(queryString);
};

const updateLabSystem = async (labId, schoolId, inspectedLabDevice, file) => {
  const id = get(inspectedLabDevice, "id", "");
  const serialNo = parseInt(get(inspectedLabDevice, "serialNo", ""));
  const uniqueDeviceId = get(inspectedLabDevice, "uniqueDeviceId", "");
  let inspectionDate = get(inspectedLabDevice, "inspectionDate", "");
  let inspectionChecks = id
    ? "inspectionChecks: { replace: ["
    : "inspectionChecks: [";
  const labChecksType = ["software", "basic", "manual", "firewall"];

  file.forEach((data) => {
    if (labChecksType.includes(data["Type"])) {
      inspectionChecks += `
        {
          name: "${data["Category"]}",
          status: "${data["Status"]}",
          type: "${data["Type"]}",
          spec: "${data["Value"]}",
        }
      `;
    }
  });

  inspectionChecks += id ? "] }" : "]";
  if (inspectionDate) {
    inspectionDate = new Date(inspectionDate).toISOString();
  } else {
    inspectionDate = new Date().toISOString();
  }
  // loop the inspectedLabDevice and filter the
  const userComment = get(inspectedLabDevice, "comment", "");
  const status = get(inspectedLabDevice, "status", "");

  const res = await requestToGraphql(
    gql`
  mutation {
    ${id ? "update" : "add"}LabInspectedDevice(
      ${id ? `id: "${id}"` : ""}
      input: {
        ${serialNo ? `serialNo: ${serialNo}` : ""}
        ${uniqueDeviceId ? `uniqueDeviceId: "${uniqueDeviceId}"` : ""}
        ${inspectionDate ? `inspectionDate: "${inspectionDate}"` : ""}
        ${userComment && userComment !== "" ? `comment: "${userComment}"` : ""}
        inspectionMode: "offline"
        ${status ? `status: "${status}"` : ""}
        ${inspectionChecks}
      }
      inspectionConnectId: "${labId}"
      schoolConnectId: "${schoolId}"
    ) {
      id
    }
  }`
  );
  return res;
};

const LabInfo = ({ info, mode }) => {
  return (
    <>
      <div className={`lab-info ${mode}`}>
        {Object.keys(info).map((key) => {
          let showDot = false;
          let dotStatus = "";
          const { totalfiles, newSystems, updatedSystems } = uploadOverview;
          if (key === updatedSystems || key === newSystems) showDot = true;
          if (key === updatedSystems) dotStatus = "old";
          if (key === newSystems) dotStatus = "new";
          return (
            <Stack gap={3}>
              <span className="lab-info-item-label">
                {key}
                {showDot && <div className={`dot large ${dotStatus}`}></div>}
              </span>
              <span className="lab-info-item-value">{info[key]}</span>
            </Stack>
          );
        })}
      </div>
    </>
  );
};

const StatusPill = ({ status, mode, noIcon, tab }) => {
  return (
    <div className={`status-pill ${mode} ${tab ? "tab" : ""}`}>
      {!noIcon ? <div className="dot"></div> : null}
      <span>{status}</span>
    </div>
  );
};

export default UploadFileModal;
