import styled from 'styled-components'
import { Button, Switch } from 'antd'

const SwitchButton = styled(Switch)`
  .ant-switch-checked {
    background: #000;
  }
`

const Container = styled.div`
  width: 360px;
  border: 1px solid #DCDCDC;
  margin: 0 20px 20px 0;
  font-family: Inter;
  :hover {
    border: 1px solid #8C61CB;
  }
`

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${props => props.direction ? props.direction : 'row'};
  justify-content: ${props => props.justify ? props.justify : 'flex-start'};
  align-items: ${props => props.align ? props.align : 'flex-start'};
`

const InternalName = styled.h3`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
  cursor: pointer;
`

const PackageName = styled.h4`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  cursor: pointer;
`

const VersionButton = styled(Button)`
  &&& {
    border-radius: 4px;
    padding: 0;
    padding: 0 8px;
    background: #F2F2F2;
    color: #757575;
    font-size: 12px;
    line-height: 16px;
    border: none;
    outline: none;
    .number {
      color: #000;
    }
  }
`

const ImageContainer = styled.div`
    height: 48px;
    width: 48px;
    background: #C4C4C4;
    margin-right: 12px;
`

const InfoContainer = styled.div`
    display: flex;
    align-items: baseline;
    margin: 10px 0;
    font-size: 12px;
    line-height: 16px;
    font-family: Inter;
    .star {
      margin-right: 10px;
    }
    .info-constant {
      color: #757575;
    }
    .info-variable {
      color: #212121;
      margin-top: 10px;
    }
`

const CourseButton = styled(Button)`
    &&& {
      background: #EEEEEE;
      border: none;
      outline: none;
      padding: 0;
      padding: 0 8px;
      margin-right: 10px;
      margin-top: 10px;
      border-radius: 16px;
      font-size: 10px;
      line-height: 12px;
      cursor: pointer;
    }
`

Container.FlexContainer = FlexContainer
Container.ImageContainer = ImageContainer
Container.InternalName = InternalName
Container.PackageName = PackageName
Container.VersionButton = VersionButton
Container.InfoContainer = InfoContainer
Container.CourseButton = CourseButton
Container.SwitchButton = SwitchButton

export default Container