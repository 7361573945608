import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'
import getActionsError from '../../utils/getActionsError'
import requestToGraphql from '../../utils/requestToGraphql'
import uploadFile from '../utils/uploadFile'

const addEventWinner = async (studentProfileId,eventConnectId,prizeConnectId,winnersDatas) => duck.query({
  query: gql`
  mutation {
    addEventWinner(
      input: {}
      studentProfileConnectId: "${studentProfileId}",
      eventConnectId: "${eventConnectId}",
      prizeConnectId: "${prizeConnectId}",
    ) {
      id
    }
  }
  `,
  type: 'addEventWinner/add',
  key: 'addEventWinner',
  changeExtractedData: (extractedData, originalData) => {
    extractedData.addEventWinner=get(originalData,'addEventWinner');
    if (winnersDatas.uploadImageFileObject && typeof (winnersDatas.uploadImageFileObject) !== 'string') {
      (async function imageUpload() {
          try {
             
              await uploadFile(winnersDatas.uploadImageFileObject, {
                  fileBucket: 'python'
              }, {
                  typeField: 'image', typeId: get(originalData, 'addEventWinner.id') , type: 'EventWinner',
              })
          } catch (error) {
              console.log(error)
          }
      }())
  }
    return { ...extractedData }
  }
})


export default addEventWinner
