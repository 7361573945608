import { Button, Form } from "antd";
import styled, { css } from "styled-components";
import { TekieRed } from "../../../constants/colors";


const StyledSpeakerAndPrizesContainer = styled.div`
max-height: 85vh;
    background: #FFFFFF;
    padding: 15px;
    overFlow: scroll;
    font-family: Inter;
    margin: 0px 25px;
`
const StyledSpeakersFormAndImageContainer = styled.section`
padding: 1rem 0;
display: flex;
justify-content: flex-start;
position: relative;
`

const StyledSpeakerForm = styled.div`
flex-basis:55% ;
`
const StyledSpeakerImageSection = styled.div`
flex-basis: 45%;
display: flex;
justify-content: center;
`
const StyledAddBtn = styled(Button)`
&&&{
    display: flex;
    justify-content: center;
    align-items:center;
    border-radius:50%;
    background-color:rgb(217, 219, 240);
    color: rgb(65, 75, 178);
    height: 1.25rem;
    width: 1.25rem;
    padding: 1.5rem;
    margin-right: 1rem;
    &:hover{
        border: 1px solid rgb(65, 75, 178);
    }
}
`
const StyledPrizeFormAndImageContainer = styled.div`
padding: 1rem 0;
display: flex;
justify-content: flex-start;
position: relative;
`
const StyledPrizeImageSection = styled(StyledSpeakerImageSection)`
flex-basis: 45%;
align-items: center;
min-height: auto;
display: flex;
flex-direction: column;
`

const StyledButton = styled(Button)`
&&& {
    
    background-color:rgb(65, 75, 178);
    color: white;
    border: none;
    cursor: pointer;
    &:hover{
        background-color:rgb(51, 61, 161);
       
    }
    &:disabled{
        color: white;
    }
    ${props => props.alignSelfCenter && css`
   align-self: center;
  `}
}
`
const StyledBackButton = styled(Button)`
&&& {
    
    min-width: 130px;
    background-color:rgb(128,128,128);
    color: white;
    border: none;
    cursor: pointer;
    &:hover{
        background-color:rgb(99, 99, 99);
    }
    ${props => props.alignSelfCenter && css`
   align-self: center;
  `}
  ${props => props.mRight && css`
   margin-right:2rem;
  `}
}
`
const StyledFormItem = styled(Form.Item)`
&&&{
      margin-bottom: 0.25rem;
}
`
const StyledError = styled.small`
    font-family: 'Inter';
    font-weight: normal;
    font-size: 12px;
    color: ${TekieRed};
    padding: 0;
    letter-spacing: 0px;
`

const StyledFooter = styled.div`
margin: 1rem 0;
width: 100%;
text-align: right;
padding: 1rem 3rem 1rem 1rem;
`
export { StyledSpeakerAndPrizesContainer, StyledButton, StyledBackButton, StyledSpeakersFormAndImageContainer, StyledSpeakerForm, StyledSpeakerImageSection, StyledPrizeFormAndImageContainer, StyledPrizeImageSection, StyledAddBtn, StyledFooter, StyledFormItem, StyledError }