import gql from "graphql-tag"
import duck from '../../../duck'

const fetchEventVariables = async (first, skip, searchQuery) => {
    await duck.query({
      query: gql`
            query {
                eventVariables: commsVariables (
                    filter: { and : [ ${searchQuery ? `{ ${searchQuery} }`: ''} ] }
                    orderBy: createdAt_ASC
                    first: ${first}
                    skip: ${first * skip}
                ){
                    id
                    whatsappVariableName
                    emailVariableName
                    dataField
                    createdAt
                    createdBy {
                        name
                    }
                    eventsMeta {
                        count
                    }   
                }
                commsVariablesMeta (
                    filter: { and : [ ${searchQuery ? `{ ${searchQuery} }`: ''} ] }
                ){
                    count
                }
            }
            `,
      type: 'eventVariables/fetch',
      key: 'eventVariables'
    })
  }
  
  export default fetchEventVariables