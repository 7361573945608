import gql from 'graphql-tag'
import duck from '../../../duck'

const fetchCourseCount = async (filterString='') =>
  duck.query({
    query: gql`
    {
        coursesMeta(filter:{
          and:[${filterString}]
        }) {
          count
        }
    }
    `,
    type: 'courses/fetch',
    key: 'coursesCount',
  })

export default fetchCourseCount

