import { Modal, Select, Tooltip } from 'antd'
import { Form, Formik } from 'formik'
import { get } from 'lodash'
import React from 'react'
import { addTrainingBatch, updateTrainingBatch } from '../../../../actions/SchoolOnboarding'
import { FlexContainer, SectionButton } from '../../SchoolOnBoarding.style'
import AddInput from '../AddStudentModal/AddInput'
import getTrainingBatchCode from '../school-utils/getTrainingBatchCode'
import { batchValidation } from '../school-utils/validationSchema'

class TrainingBatchModal extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            coursePackage: '',
            allottedMentor: '',
            classroomTitle: '',
            description: '',
            addBatchLoading: false
        }
    }
    handleSubmit = async (value) => {
      const { operation, schoolCode, schoolId, coursePackages, editData, academicYearConnectId } = this.props
      if (operation === 'add') {
        this.setState({ addBatchLoading: true })
        const batchCode = await getTrainingBatchCode(schoolCode)
        const { classroomTitle, description, coursePackage, allottedMentor } = value;
        const selectedCoursePackage = coursePackages.find(cp => get(cp, 'id') === coursePackage)
        const courseConnectId = get(selectedCoursePackage, 'coursesData[0].id')
        const addBatchInput = {
          code: batchCode,
          classroomTitle,
          description,
          documentType: 'classroom',
          type: 'b2b',
          isTeacherTraining: true,
          viewContentBasedOnCurrentComponent: true,
        }
        await addTrainingBatch({
          input: addBatchInput, schoolConnectId: schoolId,
          allottedMentorConnectId: allottedMentor,
          courseConnectId, coursePackageConnectId: coursePackage,
          academicYearConnectId
        })
        this.setState({ addBatchLoading: false })
      } else {
        const { classroomTitle, description, coursePackage, allottedMentor } = value;
        const selectedCoursePackage = coursePackages.find(cp => get(cp, 'id') === coursePackage)
        const courseConnectId = get(selectedCoursePackage, 'coursesData[0].id')
        const batchId = get(editData, 'id')
        const editBatchInput = {
          classroomTitle,
          description,
        }
        await updateTrainingBatch({
          input: editBatchInput,
          schoolConnectId: schoolId,
          allottedMentorConnectId: allottedMentor,
          courseConnectId, coursePackageConnectId: coursePackage,
          batchId
        })
      }
    }
    renderBefore = (value, showStart = true) => <div>{value} {showStart && <span style={{ color: 'red' }}>*</span>}</div>
    render() {
      const { openModal, operation, closeModal, editData,
        trainingBatchesUpdate, trainingBatchesAdd,
        coursePackages = [], schoolTrainers = [],
      } = this.props
      const { addBatchLoading } = this.state
      const isAddingBatch = operation === 'add'
      let formLoading = (trainingBatchesAdd && get(trainingBatchesAdd.toJS(), 'loading')) || addBatchLoading
      if (!isAddingBatch) {
        formLoading = trainingBatchesUpdate && get(trainingBatchesUpdate.toJS(), 'loading')
      }
        return (
            <Modal
                visible={openModal}
                title={isAddingBatch ? 'Add Batch' : 'Edit Batch'}
                onCancel={closeModal}
                maskClosable={false}
                width='550px'
                centered
                destroyOnClose
                footer={null}
            >
                <Formik
              initialValues={isAddingBatch ? { ...this.state } : {
                ...editData,
                description: get(editData, 'description', '') || ''
              }}
                    onSubmit={this.handleSubmit}
                    validationSchema={batchValidation}
    >
      {({ values, handleChange, errors, touched }) => (
        <Form style={{ padding: '0 10px', width: '100%' }}>
          <>
          <p>Classroom Title</p>
            <AddInput
              placeholder='Classroom Title'
              name='classroomTitle'
              value={values.classroomTitle || ''}
              onChange={handleChange}
            />
            <p>Classroom Description</p>
            <AddInput
              placeholder='Classroom Desc'
              name='description'
              value={values.description || ''}
              onChange={handleChange}
            />
            <div style={{ marginBottom: '1.2vw' }}>
              <p>Select Trainer</p>
              <Select
                showSearch
                placeholder='Select Trainier'
                optionFilterProp='children'
                name='allottedMentor'
                value={values.allottedMentor || ''}
                onChange={(value) => handleChange('allottedMentor')(value)}
                filterOption={(input, option) =>
                  option.props.children
                    ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    : false
                }
              >
                {
                  schoolTrainers && schoolTrainers.map(({ name, id }) =>
                    <Option key={id}
                      value={id}
                    >{name}
                    </Option>
                  )}
              </Select>
              {touched.allottedMentor && errors.allottedMentor 
              && <p style={{ fontSize: 'small', color: 'red' }} >{errors.allottedMentor}</p>}
            </div>
            <div style={{ marginBottom: '1.2vw' }}>
              <p>Select Course Package</p>
              <Select
                showSearch
                placeholder='Select a Course Package'
                optionFilterProp='children.props.children'
                name='coursePackage'
                value={values.coursePackage || ''}
                onChange={async (value) => {
                  handleChange('coursePackage')(value)
                }}
                filterOption={(input, option) =>
                  typeof get(option, 'props.children.props.children') === 'string'
                    ? get(option, 'props.children.props.children').toLowerCase().indexOf(input.toLowerCase()) >= 0
                    : Array.isArray(get(option, 'props.children.props.children'))
                    ? get(option, 'props.children.props.children[0]').toLowerCase().indexOf(input.toLowerCase()) >= 0
                    : false
                }
              >
                {
                  coursePackages && coursePackages.map(({ title, id, internalName }) =>
                    <Option
                      key={id}
                      value={id}
                    >
                      <Tooltip title={title}>
                      {title}<br />
                      <span
                          style={{
                            fontSize: '12px',
                            color: '#8c8c8c',
                          }}
                        >
                          {internalName}
                        </span>
                      </Tooltip>
                    </Option>
                  )}
              </Select>
              {touched.coursePackage && errors.coursePackage 
              && <p style={{ fontSize: 'small', color: 'red' }} >{errors.coursePackage}</p>}
            </div>
            <FlexContainer style={{ width: '60%', margin: '0 auto' }}>
              <SectionButton
                campaign
                loading={formLoading}
                style={{ margin: '0 10px' }}
                type='primary'
                htmlType='submit'
              >{isAddingBatch ? 'Add Batch' : 'Update Batch'}
              </SectionButton>
            </FlexContainer>
          </>
        </Form>
      )}
    </Formik>
    </Modal>
    )
  }
}

export default TrainingBatchModal;