import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const fetchClassroomBatches = async ({ perPage, skip, schoolId, academicYearConnectId }) =>
  duck.query({
    query: gql`{
    batch: batches(
        filter: {
            and: [
                { school_some: { id: "${schoolId}" } }
                { documentType: classroom }
                { isTeacherTraining_not: true }
        		{ academicYear_some: { id: "${academicYearConnectId}" } }
            ]
        }
        ${perPage ? `first: ${perPage}` : ''}
        ${skip ? `skip: ${perPage * skip}` : ''}
        orderBy: createdAt_ASC
    ) {
        id
        code
        classroomTitle
        description
        createdAt
        classes {
            id
            grade
            section
        }
        allottedMentor {
            id
            name
        }
        studentsMeta {
            count
        }
        coursePackage {
            id
            title
        }
        studentsList: students {
            id
            rollNo
            user {
                id
                name
            }
        }
        coursePackage {
            courses {
                id
                title
            }
        }
        inheritedFrom {
            id
        }
        coursePackageTopicRule {
            topic {
                courses {
                    id
                    title
                }
            }
        }
        coursePackageCourses {
            id
            title
        }
        currentComponent {
            id
        }
    }
    }
`,
    type: 'batch/fetch',
    key: 'classroomBatches',
    changeExtractedData: (extractedData, originalData) => {
      const batches = []
      get(originalData, 'batch', []).forEach((batch, index) => {
        const grades = [...new Set(get(batch, 'classes', []).map(({ grade }) => grade))]
        const sections = [...new Set(get(batch, 'classes', []).map(({ section }) => section))]
        batches.push({
          ...batch,
          index: index + 1,
          grade: grades,
          section: sections,
          students: get(batch, 'studentsMeta.count', 0),
          allottedMentor: get(batch, 'allottedMentor'),
        })
      })
      extractedData.coursePackage = {}
      extractedData.batch = batches;
      return { ...extractedData }
    }
  })

export default fetchClassroomBatches
