import gql from 'graphql-tag'
import duck from '../../../duck'

const deleteLearningSlide = async (id) => duck.query({
  query: gql`
    mutation {
        deleteLearningSlide(id: "${id}") {
        id
      }
    }
  `,
  type: 'deleteLearningSlides/delete',
  key: `deleteLearningSlides`,
})

export default deleteLearningSlide
