import { get } from 'lodash'
import React from 'react'
import { BlocklyWorkspace } from 'tekie-blockly'
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight'
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/hljs'
// import CodeTagParser from '../../../../../../utils/CodeTagParser'
import { Mcq, Option, OptionText, QuestionStatement } from './QuestionView.styles'
import parseChatStatement from '../../../../../../utils/parseStatement'
import { isBase64, decodeBase64 } from '../../../../../../utils/base64Utility'
import { PreviewEditor } from '../../../../../../components/ContentEditor'
import getDecodedStatement from '../../../../../../utils/getDecodedStatement'
import { BLOCKLY, IMAGE } from '../../../../../../constants/questionBank'
import { buildCustomToolJSON, DEFAULT_WORKSPACE_CONFIG } from '../../../../../../utils/blocklyUtils'
import getFullPath from '../../../../../../utils/getFullPath'

const renderMCQQuestions = (mcqOptions, isBlocklyView = false) => {
  if (mcqOptions) {
    return mcqOptions.map((option) => {
      if (isBlocklyView) {
        return (
          <Mcq.BlocklyItem highlightOptions={option.isCorrect}>
            {buildCustomToolJSON(decodeBase64(get(option, 'blocksJSON'))) && (
              <BlocklyWorkspace
                useDefaultToolbox
                customTools={buildCustomToolJSON(decodeBase64(get(option, 'blocksJSON')))}
                workspaceConfiguration={DEFAULT_WORKSPACE_CONFIG({ readOnly: true })}
                initialXml={decodeBase64(get(option, 'initialXML', null)) || ''}
              />
            )}
          </Mcq.BlocklyItem>
        )
      }
      return (
        <Option highlightOptions={option.isCorrect}>
          <OptionText highlightOptions={option.isCorrect}>
            {option.statement}
          </OptionText>
        </Option>
      )
    })
  }
}

const McqBlock = ({ question, inputCodeStyles, isReordering }) => {
  const { statement, questionCodeSnippet, mcqOptions, questionLayoutType } = question
  const isBlocklyView = questionLayoutType === BLOCKLY
  const isImageView = questionLayoutType === IMAGE
  return (

    <Mcq>
      <QuestionStatement>
        {
          isBase64(statement) ? (
            <PreviewEditor
              init={{
                selector: `CM-MCQ-${get(question, 'id')}`
              }}
              value={getDecodedStatement(statement)}
            />
          ) : parseChatStatement({ statement })
        }
      </QuestionStatement>
      {
        !isReordering && (
          <>{questionLayoutType}
            {questionCodeSnippet && (
              <SyntaxHighlighter
                language='python'
                style={darcula}
                customStyle={inputCodeStyles}
                codeTagProps={{ style: { marginHorizontal: 15, fontFamily: 'monaco' } }}
                fontSize={16}
                fontFamily='Monaco'
                highlighter='prism'
              >
                {decodeURIComponent(questionCodeSnippet)}
              </SyntaxHighlighter>
            )}
            {!isBlocklyView && !isImageView && (
              renderMCQQuestions(mcqOptions)
            )
              // mcqOptions &&
              //   mcqOptions.map((option) =>
              //     <Option>
              //       <OptionText>
              //         {option.statement}
              //       </OptionText>
              //     </Option>)
            }
            {isBlocklyView && (
              <Mcq.BlocklyContainer>
                {renderMCQQuestions(mcqOptions, isBlocklyView)}
              </Mcq.BlocklyContainer>
            )}
            {isImageView && (
              <Mcq.BlocklyContainer>
                {mcqOptions.map(option => (
                  <div className='mcqImageContainer'>
                    <Mcq.mcqImage src={getFullPath(option.questionBankImage.image.uri)} alt='mcqImage' className='mcqImage' highlightOptions={option.isCorrect} />
                  </div>
                ))}
              </Mcq.BlocklyContainer>
            )}
            {/* <QuestionStatement style={{ minHeight: 'auto' }}>
              Answer: {mcqOptions.filter(option => option.isCorrect).map(option =>
                <span>{CodeTagParser(option.statement)},</span>)}
            </QuestionStatement> */}
            {get(question, 'hint') ? (
              <QuestionStatement style={{ minHeight: 'auto' }}>
                Hint: {parseChatStatement({ statement: get(question, 'hint') })}
              </QuestionStatement>
            ) : null}
          </>
        )

      }
    </Mcq>
  )
}

export default McqBlock
