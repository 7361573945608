import gql from 'graphql-tag'
import { filter, get } from 'lodash'
import duck from '../../duck'

const addParentChild = async (input, bookingAgentId) => {
  duck.query({
    query: gql`
      mutation parentChildSignUp($input: ParentChildSignUpInput) {
        parentChildSignUp(input: $input, ${bookingAgentId ? `bookingAgentId:"${bookingAgentId}"` : ''}) {
          id
          name
          token
          role
          email
          source
          phone {
            countryCode
            number
          }
          createdAt
          parentProfile {
            children {
              user {
                id
                source
                name
                country
                timezone
                studentProfile {
                  id
                  grade
                  parents{
                    id
                    user{
                      id
                      name
                      email
                      phone {
                        countryCode
                        number
                      }
                    }
                    hasLaptopOrDesktop
                  }
                }
              }
              school {
                id
                name
              }
            }
          }
        }
      }
    `,
    variables: {
      input,
      tokenType: 'appTokenOnly'
    },
    type: 'user/add',
    key: 'user',
    changeExtractedData: (extractedData, originalData) => {
      const { parentChildSignUp } = originalData
      const child = filter(get(parentChildSignUp, 'parentProfile.children'),
        singleChild => get(singleChild, 'user.name') === get(input, 'childName'))
      const temp = {
        id: get(child, '0.user.id'),
        role: 'mentee',
        name: get(input, 'childName'),
        email: get(parentChildSignUp, 'email'),
        phone: get(parentChildSignUp, 'phone'),
        studentProfile: get(child, '0.user.studentProfile')
      }
      return ({
        userForDashBoard: temp
      })
    }
  })
}

export default addParentChild
