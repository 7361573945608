import { connect } from 'react-redux'
import LeadPartner from './LeadPartner'
import withNav from '../../components/withNav'

const LeadPartnerNav = withNav(LeadPartner)({
  title: 'Lead Partner',
  activeNavItem: 'Lead Partner',
  showUMSNavigation: true,
})

const mapStateToProps = state => ({
  leadPartners: state.data.getIn([
    'leadPartners',
    'data'
  ]),
  leadPartnersMeta: state.data.getIn([
    'leadPartnersMeta',
    'data'
  ]),
  isFetchingLeadPartners: state.data.getIn([
    'leadPartners',
    'fetchStatus',
    'leadPartners',
    'loading'
  ]),
  hasFetchedLeadPartners: state.data.getIn([
    'leadPartners',
    'fetchStatus',
    'leadPartners',
    'success'
  ]),
  isAddingLeadPartners: state.data.getIn([
    'leadPartners',
    'addStatus',
    'leadPartners',
    'loading'
  ]),
  hasAddedLeadPartners: state.data.getIn([
    'leadPartners',
    'addStatus',
    'leadPartners',
    'success'
  ]),
  hasAddingLeadPartnersFailed: state.data.getIn([
    'leadPartners',
    'addStatus',
    'leadPartners',
    'failure'
  ]),
  isUpdatingLeadPartners: state.data.getIn([
    'leadPartners',
    'updateStatus',
    'leadPartners',
    'loading'
  ]),
  hasUpdatedLeadPartners: state.data.getIn([
    'leadPartners',
    'updateStatus',
    'leadPartners',
    'success'
  ]),
  hasUpdatingLeadPartnersFailed: state.data.getIn([
    'leadPartners',
    'updateStatus',
    'leadPartners',
    'failure'
  ]),
  isDeletingLeadPartners: state.data.getIn([
    'leadPartners',
    'deleteStatus',
    'leadPartners',
    'loading'
  ]),
  hasDeletedLeadPartners: state.data.getIn([
    'leadPartners',
    'deleteStatus',
    'leadPartners',
    'success'
  ]),
  hasDeletingLeadPartnersFailed: state.data.getIn([
    'leadPartners',
    'deleteStatus',
    'leadPartners',
    'failure'
  ]),
  addLeadPartnerError: state.data.getIn([
    'errors',
    'leadPartners/add'
  ]),
  updateLeadPartnerError: state.data.getIn([
    'errors',
    'leadPartners/update'
  ]),
  deleteLeadPartnerError: state.data.getIn([
    'errors',
    'leadPartners/delete'
  ])
})

export default connect(mapStateToProps)(LeadPartnerNav)
