import React from 'react'
import moment from 'moment'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import DemandSupplyDashboard from '../DemandSupplyDashboard.style'

const iconStyle = { margin: '10px' }

const DateSelector = (props) => {
  const { selectedDate, onDateChange } = props
  return (
    <DemandSupplyDashboard.FlexContainer>
      <LeftOutlined style={iconStyle} onClick={() => onDateChange('prev')} />
      <RightOutlined style={iconStyle} onClick={() => onDateChange('next')} />
      <p>{selectedDate && moment(selectedDate).format('ll')}</p>
    </DemandSupplyDashboard.FlexContainer>
  )
}

export default DateSelector
