import gql from 'graphql-tag'
import duck from '../../duck'
import {get} from 'lodash'

const fetchCourses = async (category, grade, isTeacherTraining = false) => duck.query({
    query: gql`
    query{
        courses(filter: {
            and: [{
                category: ${category}
            }
            {
                minGrade_lte: ${grade}
            }
            ${isTeacherTraining ? `{
                contentType: teacherTraining
            }` : `{
                contentType_not: teacherTraining
            }`}
            {
                maxGrade_gte: ${grade}
            }]}){
            id
            minGrade
            maxGrade
            title
            category
            contentType
            topics{
            id
            title
            status
            description
            order
            chapter{
                id
                title
            }
            }
        }
    }
    `,
    type: 'course/fetch',
    key: 'course',
    changeExtractedData: (extractedData, originalData) => {
        for(let i = 0; i < get(originalData, 'courses').length; i++){
            get(originalData, 'courses')[i].choosenGrade = grade
        }
        extractedData.course = [...originalData.courses]
        extractedData.topic = []
        extractedData.chapters = []
        return {...extractedData }
}
})

export default fetchCourses
