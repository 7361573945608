import styled from 'styled-components'

const MainContainer = styled.div`
display:flex;
flex-direction: ${props=>props.direction};
&.twoGrid{
    grid-template-columns: 48% 48%;
  }
`
const DivHolder = styled.div`
height:  ${props=>props.height};
width:100%;
`
const GridHolder = styled.div`
display:flex;
flex-direction:row;
`
const GridContainerDiv = styled.div`
display:flex;
flex-direction:column;
width:100%
`
const OuterDiv = styled.div`
position: relative;
margin: 0px 0px 47px 0px;
`
const IconHolder = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 3%;
`
const AddSlideDiv=styled.div`
align-items: center;
display: flex;
justify-content: center;
`
export {
    MainContainer,
    DivHolder,
    GridContainerDiv,
    GridHolder,
    OuterDiv,
    IconHolder,
    AddSlideDiv
    }
    