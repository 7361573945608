import React, { useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { Button } from 'antd';
import requestToGraphql from '../../../utils/requestToGraphql';
import { DownloadOutlined } from '@ant-design/icons';
import gql from 'graphql-tag';
import { get } from 'lodash';
import toastrMessage from '../../../utils/messages';
import moment from 'moment';
import { getSlotLabel } from '../../MentorDashboard/utils';

const DownloadEventsButton = (props) => {
  const [eventDetailsDataState, setEventDetailsDataState] = useState([]);
  const [loading, setLoading] = useState(false);
  const downloadRef = useRef();
  const fetchEventDetailsData = async (id) => {
    const {
      data: { eventDetailsData = {} },
    } = await requestToGraphql(gql`
      {
      eventDetailsData: event(id: "${id}") {
        id
        name
        eventStartTime
        eventEndTime
        registeredUsers {
          id
          grade
          school {
            id
            name
          }
          schoolName
          user {
            id
            name
            email
            phone {
              countryCode
              number
            }
            vertical
            utmCampaign
            utmSource
            utmMedium
            utmTerm
            utmContent
          }
          parents {
            id
            user {
              id
              name
              email
              utmContent
              utmCampaign
              utmSource
              utmMedium
              utmTerm
              phone {
                number
                countryCode
              }
            }
          }
        }
        eventSessions {
          id
          attendance {
            isPresent
            student {
              id
            }
          }
        }
      }
    }
    `);
    const data = [];
    const { name, eventStartTime, registeredUsers, eventSessions, eventEndTime } = eventDetailsData;
    for (let i = 0; i < registeredUsers.length; i++) {
      const currentUser = {};
      currentUser.Event_Name = name;
      currentUser.Event_Date = moment(eventStartTime).format('DD MMMM');
      currentUser.Event_Time = getSlotLabel(moment(eventStartTime).get('hours')).startTime;
      currentUser.Student_Name = get(registeredUsers[i], 'user.name');
      currentUser.Parent_Name = get(registeredUsers[i], 'parents[0].user.name');
      currentUser.Parent_Email = get(
        registeredUsers[i],
        'parents[0].user.email'
      );
      currentUser.Parent_Phone = `${get(
        registeredUsers[i],
        'parents[0].user.phone.countryCode'
      )} ${get(registeredUsers[i], 'parents[0].user.phone.number')} `;
      currentUser.Grade = get(registeredUsers[i], 'grade') ? get(registeredUsers[i], 'grade').replace('Grade', '') : '';
      currentUser.Vertical = get(registeredUsers[i], 'user.vertical');
      currentUser.School_Name = get(registeredUsers[i], 'school.name') || get(registeredUsers[i], 'schoolName');
      currentUser.Utm_Campaign = get(registeredUsers[i], 'user.utmCampaign') || get(registeredUsers[i], 'parents[0].user.utmCampaign');
      currentUser.Utm_Source = get(registeredUsers[i], 'user.utmSource') || get(registeredUsers[i], 'parents[0].user.utmSource');
      currentUser.Utm_Medium = get(registeredUsers[i], 'user.utmMedium') || get(registeredUsers[i], 'parents[0].user.utmMedium');
      currentUser.Utm_Term = get(registeredUsers[i], 'user.utmTerm') || get(registeredUsers[i], 'parents[0].user.utmTerm');
      currentUser.Utm_Content = get(registeredUsers[i], 'user.utmContent') || get(registeredUsers[i], 'parents[0].user.utmContent');
      if(moment().isAfter(moment(eventEndTime))){
      for (let session = 0; session < eventSessions.length; session++) {
        const id = get(registeredUsers[i], 'id');
        let attendedThisEvent = false;
        if (get(eventSessions, `[${session}].attendance`, []).length > 0) {
          for (
            let attendee = 0;
            attendee < get(eventSessions, `[${session}].attendance`, []).length;
            attendee++
          ) {
            if (attendedThisEvent) break;
            if (
              get(eventSessions, `[${session}].attendance.[${attendee}].student.id`) === id
            ) {
              attendedThisEvent = true;
            }
          }
        }
        currentUser[`Attended Day_${session + 1}`] = attendedThisEvent ? 'Yes' : 'No';
      }
    }
      data.push(currentUser);
    }
    if (data.length === 0) {
      toastrMessage('alert', '', 'error', 'No registered users!');
    } else {
      setEventDetailsDataState(data);
      downloadRef.current && downloadRef.current.link.click();
    }
    setLoading(false);
  };

  const fetchUtmsData = async () => {
    const query = gql`{
      event(id:"${props.eventId}"){
      utm{
        utmTerm
        utmSource
        utmCampaign
        utmMedium
        utmSource
        webUrl
      }
    }
    }`
    const data = await requestToGraphql(query)
    if (get(data, 'data.event.utm', []).length) {
      const utmData = [];
      get(data, 'data.event.utm', []).forEach(utm => {
        utmData.push({ ...utm })
      })
      setEventDetailsDataState(utmData);
      downloadRef.current && downloadRef.current.link.click();
    } else {
      toastrMessage('alert', '', 'error', 'No UTM parameter added!');
    }
    setLoading(false);
  }
  return (
    <>
      <CSVLink
        display={{ display: 'none' }}
        data={eventDetailsDataState}
        ref={downloadRef}
        filename="eventDetails.csv"
      />
      <Button
        style={{
          border: 'none',
          backgroundColor: 'transparent',
          marginLeft: '10px',
        }}
        loading={loading}
        onClick={() => {
          if (get(props, 'isUTM')) fetchUtmsData(props.eventId)
          else fetchEventDetailsData(props.eventId);
          setLoading(true);
        }}
      >
        <DownloadOutlined />
      </Button>
    </>
  );
};

export default DownloadEventsButton;
