import React, { Component } from 'react';
import { MailOutlined } from '@ant-design/icons';
import SwitchConstant from '../../Constant/Switch/SwitchConstant';
import { FlexboxGrid } from 'rsuite';
import { Tooltip } from 'antd'

export default class WinnerHeading extends Component {

    render() {
        const { toggle,switchColor, selectWinnersMsg } = this.props;
        return (<>
            <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: "26px" }}>
                        <MailOutlined style={{
                            color: "white",
                            fontSize: "26px",
                            backgroundColor: "black",
                            height: "19px",
                            display: "flex",
                            alignItems: "center",
                        }} />
                    </div>
                    <div style={{ fontSize: "24px",marginLeft:"16px" }}>
                        <b>Select Winners</b>
                    </div>
                    <Tooltip
                        title={selectWinnersMsg}
                        placement='topLeft'
                        >
                        <div style={{
                            display: "flex",
                            alignItems: "center", justifyContent: "center", marginLeft: "30px"
                        }}>
                        
                            <SwitchConstant on={"ON"} off={"OFF"} toggle={selectWinnersMsg !== '' ? "disabled" : toggle} switchColor={switchColor}/>
                        </div>
                    </Tooltip>
                </div>
                <div className="" style={{
                    fontSize: "12px",
                    marginLeft: "43px",
                    color: "#0707078a",
                }}>
                    <b>Show off the winners on the event page</b>
                </div>
            </div>
        </>
        )
    }
}
