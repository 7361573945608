import gql from 'graphql-tag'
import duck from '../../../duck'
import getIdArrForQuery from '../../../utils/getIdArrForQuery'


const addLearningSlide = async (input,learningObjectiveIds=[],practiceQuestionsIds=[],topicIds=[],courseIds=[],slideContentsConnectIds=[]) =>
  duck.query({
    query: gql`
    mutation($input: LearningSlideInput!) {
    addLearningSlide(input: $input,
      ${learningObjectiveIds.length > 0 ? `learningObjectivesConnectIds: [${getIdArrForQuery(learningObjectiveIds)}]` : ''},
      ${practiceQuestionsIds.length > 0 ? `practiceQuestionsConnectIds: [${getIdArrForQuery(practiceQuestionsIds)}]` : ''},
      ${topicIds.length > 0 ? `topicsConnectIds: [${getIdArrForQuery(topicIds)}]` : ''},
      ${courseIds.length > 0 ? `coursesConnectIds: [${getIdArrForQuery(courseIds)}]` : ''},
      ${slideContentsConnectIds.length > 0 ? `slideContentsConnectIds: [${getIdArrForQuery(slideContentsConnectIds)}]` : ''}) {
        id
    }
    }
    `,
    variables: {
      input
    },
    type: 'addLearningSlides/add',
    key: 'addLearningSlides',
  })

export default addLearningSlide
