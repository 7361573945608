const serverConfig = {
  apiBaseURL: process.env.REACT_APP_API_BASE_URL,
  apiBaseURLForBatch: process.env.REACT_APP_API_BATCH_URL,
  apiScriptUrl: process.env.REACT_APP_API_SCRIPT_URL,
  fileBaseUrl: process.env.REACT_APP_FILE_BASE_URL,
  
  cloudFrontBaseUrl: process.env.REACT_APP_CLOUDFRONT_BASE_URL
}

export default serverConfig
