import { Modal, Button, TimePicker, DatePicker, InputNumber } from 'antd'
import styled from 'styled-components'

const ModalWrapper = styled(Modal)`
    font-family: Inter;
`

const Content = styled.div`
    margin-bottom: 20px;
    h3, h4 {
        color: #575757;
    }
    color: #a3a3a3;
`

const HeadingContainer = styled.div`
    display: flex;
    margin-bottom: 10px;
    .heading-icon {
        background: #575757;
        color: #fff;
        border-radius: 50%;
        border: none;
        outline: none;
    }
    h1 {
        color: #575757;
        font-weight: 600;
        margin: 0;
    }
    p {
        color: #a3a3a3;
        font-weight: 100;
    }
`

const TitleInputContainer = styled.div`
    border: 1px solid #EEE;
    padding: 5px;
    width: 50%;
    border-radius: 8px;
    background: #fff;
    margin-bottom: 20px;
`

const styledButton = styled(Button)`
    &&& {
        color: ${props => props.color ? props.color : '#a3a3a3'};
        background: ${props => props.background ? props.background : '#fff'};
        border-color: ${props => props.background ? '' : '#a3a3a3'};
        ${props => props.isSelected ? `
            border-color: #575757;
            color: #575757;
        ` : ''}
    }
`

const tagButton = styled(Button)`
    &&& {
        margin-right: 5px;
        text-transform: capitalize;
    }
`

const TimePickerButton = styled(TimePicker)`
    .ant-time-picker-input {
        color: #575757;
    }
    .ant-time-picker-input:hover {
        border-color: #575757;
    }
`

const DatePickerButton = styled(DatePicker)`
    &&& {
        margin-right: 10px;
    }
    &.ant-calendar-picker:hover {
        &.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
            border-color: #575757;
        }
    }
`

const InputNumberStyle = styled(InputNumber)`
    &&& {
        border: none;
        border-bottom: 2px solid #a3a3a3;
        margin: 0 15px;
    }
`

ModalWrapper.HeadingContainer = HeadingContainer
ModalWrapper.Content = Content
ModalWrapper.TitleInputContainer = TitleInputContainer
ModalWrapper.DatePickerButton = DatePickerButton
ModalWrapper.TimePickerButton = TimePickerButton
ModalWrapper.styledButton = styledButton
ModalWrapper.InputNumberStyle = InputNumberStyle
ModalWrapper.tagButton = tagButton

export default ModalWrapper