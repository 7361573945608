import gql from 'graphql-tag'
import duck from '../../duck'

const getFirstTopicAndLearningObjective = (courseId) => (
  duck.query({
    query: gql`
      query{ 
        topics(
          filter:{
            and:[
              {
                status: published
              }
              {
                courses_some:{
                  ${courseId ? `id: "${courseId}"` : 'title: python'}
                }
              }
            ]
          }
          orderBy:order_ASC
          first: 1
        ){
          id
          learningObjectives(
            filter:{
              and:[
                {
                  status: published
                }
              ]
            }
            orderBy:order_ASC
            first: 1
          ){
            id
          }
        }
      }
  `,
    type: 'topics/fetch',
    key: 'topics',
  })
)

export default getFirstTopicAndLearningObjective
