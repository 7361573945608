import { get, groupBy, mapValues, omit } from 'lodash'
import userStatusConst from '../../constants/userStatusConst';

const convertSchoolCountAndGrade = (originalData, studentsCount = []) => {
  let schoolClasses = []
  const studentProfiles = [];
  get(originalData, 'school.classes', []).forEach(classData => {
    get(classData, 'students', []).forEach(student => {
      if (get(student, 'user.status') === userStatusConst.active) {
        studentProfiles.push({ ...student });
      }
    })
  })
  get(originalData, 'school.classes', []).map(schoolClass => {
    const data = schoolClasses.find(d => get(d, 'grade') === get(schoolClass, 'grade'))
    const gradeOrder = get(schoolClass, 'grade').match(/\d+/g)
    const sectionStudentCount = studentProfiles.filter(student =>
      get(student, 'grade') && get(student, 'section') && get(student, 'grade') === get(schoolClass, 'grade')
      && get(student, 'section') === get(schoolClass, 'section')).length || 0
    if (data) {
      data.sections = [...data.sections,
        {
          section: get(schoolClass, 'section'),
          id: get(schoolClass, 'id'),
          sectionStudentCount,
          sectionDisplayName: get(schoolClass, 'sectionDisplayName', '')
        }]
      const newSchoolClasses = schoolClasses.filter((d) => get(d, 'grade') !== get(data, 'grade'))
      schoolClasses = [...newSchoolClasses, data]
    } else {
      const studentCount = studentsCount.find(count => get(count, 'groupByFieldValue') === get(schoolClass, 'grade'))
      schoolClasses.push({
        id: get(schoolClass, 'id'),
        grade: get(schoolClass, 'grade'),
        order: Number(get(gradeOrder, '[0]', 0)),
        gradeDisplayName: get(schoolClass, 'gradeDisplayName', ''),
        sections: [{
          section: get(schoolClass, 'section'),
          id: get(schoolClass, 'id'),
          sectionStudentCount,
          sectionDisplayName: get(schoolClass, 'sectionDisplayName', '')
        }],
        studentCount: get(studentCount, 'count', 0) || 0
      })
    }
  })
  const studentsMeta = get(originalData, 'usersMeta.count', 0) || 0
  const grades = [...new Set(get(originalData, 'school.classes', []).map(({ grade }) => grade))]
  return { studentsMeta, grades, schoolClasses }
}

export default convertSchoolCountAndGrade
