import fetchUsers from '../../../actions/ums/fetchUsers'
import fetchUsersCount from '../../../actions/ums/fetchUserCount'
import hasUserAcces from './users-access'
import { get } from 'lodash'
import { MENTEE, PARENT } from '../../../constants/roles'
// import fetchDemoSessionDetails from '../../../actions/ums/fetchDemoSessionDetails'
// import fetchOtherSessionDetails from '../../../actions/ums/fetchOtherSessionDetail'

const getRoleBasedUserAndCount = async (userId, userRole, fetchLimits) => {
  if (hasUserAcces(userRole)) {
    // if (get(fetchLimits, 'role', '') === PARENT || get(fetchLimits, 'role', '') === MENTEE) {
    //   fetchDemoSessionDetails(fetchLimits)
    //   fetchOtherSessionDetails(fetchLimits)
    // }
    fetchUsersCount(fetchLimits)
    await fetchUsers(fetchLimits)
  }
}

export default getRoleBasedUserAndCount
