/*eslint-disable*/
import React, { useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import { get } from 'lodash'
import Blockly from 'blockly'
import { BlocklyWorkspace } from 'tekie-blockly';
import { Select, Radio, Tooltip, Checkbox } from 'antd'
import { DifficultyInput, Input, PublishInput } from './FormElements'
import Dropzone from '../../../../../components/Dropzone'
import { FlexContainer, StyledButton } from '../../ContentProject.styles'
import { onAddIframeLink, projectValidation } from '../../../contentUtils'
import { ContentEditor } from '../../../../../components/ContentEditor'
import { encodeBase64 } from '../../../../../utils/base64Utility'
import getDecodedStatement from '../../../../../utils/getDecodedStatement'
import { BLOCKLY_PLAYGROUND_THEME } from '../../../../../utils/blocklyUtils'

const AddProjectForm = (props) => {
  const { handleAddProject, addFormData, projectAddStatus,
    coursesFetchStatus, coursesList,
    orderInUse } = props
  const externalPlatformLogoRef = useRef()
  const projectThumbnailRef = useRef()
  const [externalPlatformLogo, setExternalPlatformLogo] = useState(null)
  const [projectThumbnail, setProjectThumbnail] = useState(null)
  const [selectedCourses, setSelectedCourses] = useState([])
  const [currentCheckedLink, setCurrentCheckedLink] = useState(3)
  const [currentCheckedBlock, setCurrentCheckedBlock] = useState(3)
  const [xmlData, setXmlData] = React.useState('')
  const formData = useRef()
  const onDropPlatformLogo = (file) => {
    setExternalPlatformLogo(file)
  }

  const onDropThumbnail = (file) => {
    setProjectThumbnail(file)
  }

  const handleSubmit = (value, meta) => {
    if(value.layout === 'playground'){
      value.externalPlatformLink = ''
      value.answerDescription = ''
    }
    value.layout === 'externalPlatform' || currentCheckedBlock == 2 ? value.initialBlocks = '' : null;
    if(selectedCourses.length > 0) {
    handleAddProject(value, meta, externalPlatformLogo, projectThumbnail, selectedCourses)
    }
  }

  const onSelect = (value) => {
    setSelectedCourses([...selectedCourses, value])
  }

  const onDeselect = (value) => {
    const newCourse = selectedCourses.filter(course =>
      get(course, 'key') !== get(value, 'key'))
    setSelectedCourses(newCourse)
  }

  const onLinkChange = (value) => {
    setCurrentCheckedLink(value.target.value)
    if (value.target.value === 1 ) {
      setCurrentCheckedBlock(3)
    }
  }

  const onBlockChange = (value) => {
    setCurrentCheckedBlock(value.target.value)
  }

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  }

  const saveXML = (workspace) => {
    var xml = Blockly.Xml.workspaceToDom(Blockly.mainWorkspace)
    var xml_text = Blockly.Xml.domToText(xml)
    setXmlData(xml_text)
    return xml_text
    // var xml_got = Blockly.Xml.textToDom(xml_text)
  }

  const workspaceConfiguration = {
    readOnly: false,
    grid: false,
    move:{
      scrollbars: {
        horizontal: true,
        vertical: true
      },
      drag: true,
      wheel: true
    },
    zoom: {
      controls: true,
      wheel: true,
      startScale: 1.0,
      maxScale: 2,
      minScale: 0.3,
      scaleSpeed: 1.2,
      pinch: true
    },
    trashcan: true,
  }

  const filterOption = (input, option) => (
    get(option, 'props.children.props.children')
      ? get(option, 'props.children.props.children')
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
      : false
  )

  return (
    <Formik
      initialValues={addFormData}
      onSubmit={handleSubmit}
      validateOnBlur
      validationSchema={projectValidation}
      innerRef={formData}
    >
      {({ values, handleChange, setFieldValue }) => (
        <Form style={{ padding: '0 10px' }} id='form'>
          <FlexContainer style={{ alignItems: 'flex-start' }}>
            <div style={{ flex: '0.6' }}>
              <Input
                label='Project Name'
                placeholder='Project Name'
                name='title'
                type='text'
                value={values.title || ''}
                onChange={handleChange}
                setFieldValue={setFieldValue}
              />
               <div className="projectDescriptionContainer" >
                <ContentEditor
                 id={get(props, 'addFormData.id')}
                 initialValue={getDecodedStatement(addFormData.projectDescription)}
                 setFieldValue={setFieldValue}
                 inputRef='projectDescription'
                 inputValue={values.projectDescription || ''}
                 handleChange={handleChange}
                 title="Project Description"
                 name='projectDescription'
                 placeholder='Project Description'
                 type='text'
                 bulletsEnabled
                 init={{
                   height: 250,
                   selector: `BBP-${get(props, 'addFormData.id')}`,
                   placeholder: "Start adding your question title here...",
                 }}
                 onChange={(_, editor) => {                   
                  formData.current.setFieldValue('projectDescription',encodeBase64(editor.getContent()))
               }}               
              />
              </div>
              <div style={{
                display: 'grid',
                alignItems: 'flex-start',
                gridTemplateColumns: '30% 60%',
              }}
              >
                <h3>Select Courses</h3>
                <Select
                  mode='multiple'
                  labelInValue
                  placeholder='Select Courses'
                  loading={coursesFetchStatus && !get(coursesFetchStatus.toJS(), 'loading')}
                  filterOption={filterOption}
                  value={selectedCourses}
                  onSelect={onSelect}
                  onDeselect={onDeselect}
                  style={{ width: '100%' }}
                >
                  {
                    coursesList.map(item =>
                      <Select.Option
                        value={get(item, 'id')}
                        key={get(item, 'id')}
                      >
                        <Tooltip title={get(item, 'title')}>{get(item, 'title')}</Tooltip>
                      </Select.Option>
                    )
                  }
                </Select>
              </div>
            </div>
            <FlexContainer style={{ alignItems: 'flex-start', flex: '0.3' }}>
              <h3>Project Thumbnail</h3>
              <Dropzone
                style={{ height: '200px', width: '100%', marginBottom: '15px' }}
                getDropzoneFile={onDropThumbnail}
                ref={projectThumbnailRef}
                defaultFile={projectThumbnail}
              >Click or drag to attach
              </Dropzone>
            </FlexContainer>
          </FlexContainer>
          <DifficultyInput value={values.difficulty} setFieldValue={setFieldValue} />
          <FlexContainer justify='space-between' modalGrid>
            <FlexContainer style={{ alignItems: 'flex-start', flex: '0.3' }}>
              <h3>Platform Thumbnail:</h3>
              <Dropzone
                style={{ height: '200px', width: '100%', marginBottom: '15px' }}
                getDropzoneFile={onDropPlatformLogo}
                ref={externalPlatformLogoRef}
                defaultFile={externalPlatformLogo}
              >Click or drag to attach
              </Dropzone>
            </FlexContainer>
          </FlexContainer>
          <div>
            <span>Enable doc embed : </span>
            <Checkbox
              id='externalDescriptionEnabled'
              checked={values.externalDescriptionEnabled}
              onChange={({ target: { checked } }) => setFieldValue('externalDescriptionEnabled', checked)}
            />
          </div>
          <FlexContainer>
            {get(values, 'externalDescriptionEnabled', false) ? (
              <Input
                label='External Description Link'
                placeholder='External Description Link'
                name='projectCreationDescription'
                type='text'
                value={values.projectCreationDescription || ''}
                onChange={(e) => setFieldValue('projectCreationDescription', onAddIframeLink(e.target.value))}
                textArea
                inputStyles={{ width: '60%', gridTemplateColumns: 'auto' }}
              />
            ) :
          <div style={{width:'60%'}}>                
              <ContentEditor
                 id={get(props, 'addFormData.id')}
                 initialValue={getDecodedStatement(addFormData.projectCreationDescription)}
                 setFieldValue={setFieldValue}
                 shrinkedEditor
                 handleChange={handleChange}
                 title="Create Description"
                 placeholder='Create Description'
                 name="projectCreationDescription"
                 inputRef='projectCreationDescription'
                 type='text'    
                 inputValue={values.projectCreationDescription || ''}
                 bulletsEnabled
                 init={{
                   height: 250,
                   selector: `PJFCD-${get(props, 'addFormData.id')}`,
                   placeholder: "Start adding your create description here...",
                 }}
                 onChange={(_, editor) => {                   
                  formData.current.setFieldValue('projectCreationDescription',encodeBase64(editor.getContent()))
               }}               
              />
              </div>}
          </FlexContainer>
          <FlexContainer style = {{justifyContent:"flex-start"}}>
            <h3>Select Layout </h3>
            <Radio.Group 
              onChange={(e) => {     
                onLinkChange(e)
                return setFieldValue('layout', e.target.value==1 ? 'externalPlatform': 'playground')      
              }}
              value={currentCheckedLink} >
              <Radio style={radioStyle} value={1}>Practice Link</Radio>  
              <Radio style={radioStyle} value={2}>Blockly Code Playground</Radio>
            </Radio.Group>
          </FlexContainer>
          {currentCheckedLink === 1 ? <FlexContainer>
            <Input
              inputStyles={{ flex: '0.6' }}
              label='Create CTA link'
              placeholder='Create CTA link'
              name='externalPlatformLink'
              type='text'
              onBlur={() => setFieldValue('externalPlatformLink', get(values, 'externalPlatformLink', '').trim())}
              value={values.externalPlatformLink || ''}
              onChange={handleChange}
              setFieldValue={setFieldValue}
            />
          </FlexContainer> : null}
          {currentCheckedLink === 2 ? <FlexContainer style = {{justifyContent:"flex-start"}}>
            <h3>Set Initial Blocks </h3>
            <Radio.Group onChange={onBlockChange} value={currentCheckedBlock} >
              <Radio style={radioStyle} value={1}>Yes</Radio>  
              <Radio style={radioStyle} value={2}>No</Radio>
            </Radio.Group>
          </FlexContainer> : null}
          {currentCheckedBlock === 1 ? <div style={{ width: '100%', height: '500px', marginBottom: '20px' }}>
              <BlocklyWorkspace
                useDefaultToolbox
                workspaceConfiguration={workspaceConfiguration}
                onWorkspaceChange={(workspace) => {
                }}
                onInject={(e) => {
                }}
                customTheme={BLOCKLY_PLAYGROUND_THEME}
                onXmlChange={(workspace) => {     
                  var xmld = saveXML (workspace)
                  return setFieldValue('initialBlocks', encodeBase64(xmld))      
                }}
                initialXml=''
              />
            </div> : null
          }
          {currentCheckedLink === 1 ? <FlexContainer>
            <Input
              inputStyles={{ flex: '0.6' }}
              label='Submit Description'
              placeholder='Submit Description'
              name='answerDescription'
              type='text'
              textArea
              value={values.answerDescription || ''}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              inputRef='answerDescription'
            />
          </FlexContainer> : null}
          <FlexContainer justify='flex-start'>
            <Input
              placeholder='Enter Order'
              inputStyles={{ marginBottom: '0' }}
              type='number'
              name='order'
              label='Order'
              value={values.order || ''}
              order
              values={values}
              orderInUse={orderInUse}
              setFieldValue={setFieldValue}
              onChange={(e) => handleChange(e)}
            />
            <PublishInput
              values={values}
              setFieldValue={setFieldValue}
            />
          </FlexContainer>
          <FlexContainer justify='center'>
            <StyledButton
              icon='file'
              id='add-btn'
              htmlType='submit'
              loading={projectAddStatus && get(projectAddStatus.toJS(), 'loading')}
            >
              Save
            </StyledButton>
          </FlexContainer>
        </Form>
    )}
    </Formik>
  )
}

export default AddProjectForm
