import { Select, Input } from 'antd'
import { CopyOutlined, ShareAltOutlined } from '@ant-design/icons'
import { Form, Formik } from 'formik'
import React from 'react'
import { get } from 'lodash'
import { FlexContainer, SectionButton, StyledSelect } from '../../SchoolOnBoarding.style'
import AddInput from '../AddStudentModal/AddInput'
import studentValidationSchema from '../school-utils/studentValidationSchema'
import type from '../../../../constants/types'
import timeDuration from '../../../../constants/timeDuration'
import restrictedNumverValues from '../../../../constants/restrictedNumberValues'

const { Option } = Select

const StudentLinkEditForm = (props) => {
  const { loadingStatus, handleEmailCredentails, handleCopyLink,
    editStudent, copyLinkLoading } = props
  const renderBefore = (value) => <div>{value} <span style={{ color: 'red' }}>*</span></div>
  return (
    <Formik
      initialValues={{
        childName: get(editStudent, 'studentName'),
        parentEmail: get(editStudent, 'parents[0].user.email', ''),
        phoneNumber: get(editStudent, 'parents[0].user.phone.number', ''),
        countryCode: get(editStudent, 'parents[0].user.phone.countryCode', ''),
        grade: get(editStudent, 'grade'),
        section: get(editStudent, 'section'),
        type: null,
        duration: null
      }}
      validateOnBlur
      validationSchema={studentValidationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form style={{ padding: '0 10px', width: '100%' }}>
          <>
            <AddInput
              placeholder='Type Parent Name'
              name='childName'
              value={values.childName || ''}
              onChange={e => { values.makeNotEditable = e }}
              addonBefore={renderBefore('Student Name: ')}
            />
            <AddInput
              value={values.phoneNumber || ''}
              onChange={e => { values.makeNotEditable = e }}
              placeholder='Type Phone Number'
              name='phoneNumber'
              type='text'
              addonBefore={renderBefore('Phone Number: ')}
            />
            <AddInput
              placeholder='Type Email'
              name='parentEmail'
              value={values.parentEmail}
              // onChange={handleChange}
              onChange={e => { values.makeNotEditable = e }}
              addonBefore={renderBefore('Email: ')}
            />
            <FlexContainer noPadding justify='flex-start' style={{ marginBottom: '1vw' }}>
              <h4 style={{ marginBottom: 0 }}>Expiry Period: </h4>
              <StyledSelect
                placeholder='Type'
                value={values.type || 'Type'}
                style={{ width: 200, marginLeft: '10px' }}
                onChange={value => setFieldValue('type', value)}
              >
                {type.map(s => <Option key={s} value={s}>{`${s}`}</Option>)}
              </StyledSelect>
              <StyledSelect
                placeholder='Enter'
                value={values.duration || 'Enter'}
                style={{ width: 200, marginLeft: '10px' }}
                onChange={value => setFieldValue('duration', value)}
              >
                {timeDuration.map(s => <Option key={s} value={s}>{`${s}`}</Option>)}
              </StyledSelect>
            </FlexContainer>
            <FlexContainer noPadding justify='flex-start' style={{ marginBottom: '1vw' }}>
              <h4 style={{ marginBottom: 0 }}>Link Visit Limit: </h4>
              <Input
                placeholder='Enter Limit'
                value={values.linkVisitLimit || ''}
                style={{ width: 200, marginLeft: '10px' }}
                onKeyDown={(e) => {
                  if (restrictedNumverValues.includes(e.key)) {
                    e.preventDefault()
                  }
                }}
                onChange={value => setFieldValue('linkVisitLimit', value.target.value)}
              />
            </FlexContainer>
            <FlexContainer style={{ width: '60%', margin: '0 auto' }}>
              <ShareAltOutlined style={{ backgroundColor: 'black', color: 'white', padding: 6, borderRadius: 4, fontSize: 20 }} />
              <SectionButton
                campaign
                onClick={() => handleEmailCredentails(values)}
                loading={loadingStatus}
                style={{ margin: '0 10px' }}
                type='primary'
                htmlType='submit'
              >Email Credentials
              </SectionButton>
              <CopyOutlined style={{ backgroundColor: 'black', color: 'white', padding: 6, borderRadius: 4, fontSize: 20 }} />
              <SectionButton
                campaign
                style={{ margin: '0 10px' }}
                type='primary'
                loading={copyLinkLoading || false}
                onClick={() => handleCopyLink(values)}
              >Copy Link
              </SectionButton>
            </FlexContainer>
          </>
        </Form>
      )}
    </Formik>
  )
}

export default StudentLinkEditForm
