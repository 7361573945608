import gql from 'graphql-tag'
import duck from '../../duck'

const fetchGradeReport = async (filterQuery, fetchCount) =>
  duck.query({
    query: gql`
    {
      sessionGradeReports(filter: { and: [${filterQuery || ''}] }) {
        id
        date
        grade1{
        registered
        converted
        booked
       demoCompleted
        } 
        grade2{
          registered
          converted
          booked
          demoCompleted
        }
        grade3{
          registered
          converted
          booked
          demoCompleted
        }grade4{
          registered
          converted
          booked
          demoCompleted
        }grade5{
          registered
          converted
          booked
          demoCompleted
        }grade6{
          registered
          converted
          booked
          demoCompleted
        }grade7{
          registered
          converted
          booked
          demoCompleted
        }grade8{
          registered
          converted
          booked
          demoCompleted
        }grade9{
          registered
          converted
          booked
          demoCompleted
        }grade10{
          registered
          converted
          booked
          demoCompleted
        }grade11{
          registered
          converted
          booked
          demoCompleted
        }grade12{
          registered
          converted
          booked
          demoCompleted
        }
        vertical
        createdAt
        updatedAt
        country
      }
    }
  `,
    type: 'sessionGradeReports/fetch',
    key: `sessionGradeReports/${fetchCount}`,
  })

export default fetchGradeReport
