import getDataFromLocalStorage from '../extract-from-localStorage'

const renderFreshChat = () => {
  if (typeof window !== 'undefined') {
    if (window.fcWidget && window.fcWidget.isInitialized()) {
      window.fcWidget.show()
    } else {
      window.fcWidget.init({
        config: {
          cssNames: {
            widget: 'custom_fc_frame'
          }
        },
        token: `${process.env.REACT_APP_FRESH_CHAT_TOKEN}`,
        host: 'https://wchat.in.freshchat.com',
        externalId: getDataFromLocalStorage('login.username'),
        firstName: getDataFromLocalStorage('login.name'),
        email: getDataFromLocalStorage('login.email'),
        phone: getDataFromLocalStorage('login.phone'),
      })
    }

    if (getDataFromLocalStorage('login.role') === 'mentor') {
      window.fcWidget.user.setProperties({
        sessionLink: getDataFromLocalStorage('login.sessionLink'),
        googleMeetLink: getDataFromLocalStorage('login.googleMeetLink'),
      })
    }
    window.fcWidget.user.setProperties({
      role: getDataFromLocalStorage('login.role'),
    })
  }
}

export default renderFreshChat
