import gql from 'graphql-tag'
import duck from '../../duck'

const addMentorChild = async (mentorId) => duck.query({
  query: gql`
  mutation {
    generateMentorChild(mentorId : "${mentorId}") {
      mentorChildId
    }
  } 
    `,
  type: 'mentorChild/add',
  key: 'mentorChild',
})

export default addMentorChild
