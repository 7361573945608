import gql from 'graphql-tag'
import { get } from 'lodash'
import moment from 'moment'
import appConfig from '../../config/appConfig'
import ThunderIcon from '../../assets/thunder.png'
import FocusedIcon from '../../assets/focused.png'
import QuickLearnerIcon from '../../assets/quickLearner.png'
import DeterminedIcon from '../../assets/determined.png'
import CuriousIcon from '../../assets/curious.png'
import AmbitiousIcon from '../../assets/ambitious.png'
import getPaySlabSlots from '../../utils/getPaySlabSlots'
import requestToGraphql from '../../utils/requestToGraphql'
import getSlotNames from '../../utils/slots/slot-names'

export const withHttps = (url) => url.replace(/^(?:(.*:)?\/\/)?(.*)/i, (match, schemma, nonSchemmaUrl) => schemma ? match : `https://${nonSchemmaUrl}`)

export const getSelectedSlotsStringArray = (slots = {}) => {
  const slotTimeStringArray = []
  Object.keys(slots).forEach(slot => {
    if (slot.includes('slot')) {
      if (slots[slot]) {
        slotTimeStringArray.push(slot)
      }
    }
  })
  return slotTimeStringArray
}

export const getSlotLabel = (slotNumberString, isCapital = true) => {
  const slotNumber = Number(slotNumberString)
  let AM = 'AM'
  let PM = 'PM'
  if (!isCapital) {
    AM = 'am'
    PM = 'pm'
  }
  let startTime = ''
  let endTime = ''
  if (slotNumber < 12) {
    if (slotNumber === 0) {
      startTime = `12:00 ${AM}`
    } else {
      startTime = `${slotNumber}:00 ${AM}`
    }
    if (slotNumber === 11) {
      endTime = `12:00 ${PM}`
    } else {
      endTime = `${slotNumber + 1}:00 ${AM}`
    }
  } else if (slotNumber > 12) {
    startTime = `${slotNumber - 12}:00 ${PM}`
    if (slotNumber === 23) {
      endTime = `12:00 ${AM}`
    } else {
      endTime = `${slotNumber - 11}:00 ${PM}`
    }
  } else {
    startTime = `12:00 ${PM}`
    endTime = `1:00 ${PM}`
  }
  return {
    startTime,
    endTime
  }
}

export const getSlotTime = (batchSession, newLogic = false) => {
  const slotTimeStringArray = getSelectedSlotsStringArray(batchSession)
  if (newLogic) {
    const slotArr = slotTimeStringArray.map(slots => {
      const label = getSlotLabel(slots.split('slot')[1])
      return label
    })
    return slotArr
  }
  if (slotTimeStringArray && slotTimeStringArray.length) {
    const slotNumber = slotTimeStringArray[0].split('slot')[1]
    const label = getSlotLabel(slotNumber, false)
    return label
  }
  return null
}

export const getMentorMenteeSessionData = async (date) => {
  const data = await requestToGraphql(gql`
    {
    availableSlots(filter: { and: [{ date: "${date}" }] }, orderBy: date_ASC) {
      date
      ${getSlotNames()}
    }
    }`)
  return data
}

const filterSessions = (session) => {
  return (get(session, 'menteeSession.id') && get(session, 'mentorSession.sessionType') !== 'batch')
}
export const getMentorActiveSessions = async (mentorId) => {
  const data = await requestToGraphql(gql`
  {
    mentorMenteeSessions(filter:{and :[{mentorSession_some:{ user_some:{ id: "${mentorId}"}}}, {sessionStatus: started} ]}){
      id
      isFeedbackSubmitted
      sessionStartDate
      mentorSession{
        id
        sessionType
      }
      menteeSession {
        id
        user {
          id
          name
        }
      }
      topic {
        id
        title
      }
    }
  }`)
  return get(data, 'data.mentorMenteeSessions').filter(session => filterSessions(session))
}

export const endSessionWithId = async (id) => {
  const data = await requestToGraphql(gql`
  {
    updateMentorMenteeSession(id: "${id}", input:{sessionStatus: completed}){
      id
    }
  }
  `)
}

export const getMentorSessionData = async (date, mentorId, sessionType, slotNumber) => {
  const data = await requestToGraphql(gql`
    {
    mentorSessions(
      filter: { and: [
      { availabilityDate: "${date}" },
      ${sessionType ? `{ sessionType: ${sessionType} }` : '{ sessionType: trial }'}
      ${mentorId ? `{ user_some: { id: "${mentorId}" } }` : ''}
      ${slotNumber ? `{ slot${slotNumber}: true }` : ''}
    ] }
    ) {
      id
      ${!slotNumber ? getSlotNames() : ''}
    }
  }
  `)
  return data
}

export const getSelectedSlots = (slotsObj, checkCount = false) => {
  const selectedSlots = []
  for (let i = 0; i < appConfig.timeSlots.length; i += 1) {
    if (checkCount) {
      if (slotsObj[`slot${appConfig.timeSlots[i]}`] > 1) {
        const slotObj = {}
        slotObj[`slot${appConfig.timeSlots[i]}`] = slotsObj[`slot${appConfig.timeSlots[i]}`]
        selectedSlots.push(slotObj)
      }
      /* eslint-disable no-lonely-if */
    } else {
      if (slotsObj[`slot${appConfig.timeSlots[i]}`]) {
        selectedSlots.push(appConfig.timeSlots[i])
      }
    }
  }
  return selectedSlots
}


export const getDates = (startDate, stopDate) => {
  const dateArray = []
  let currentDate = moment(startDate).endOf('day')
  while (currentDate <= moment(stopDate).endOf('day')) {
    dateArray.push({
      date: new Date(moment(currentDate).endOf('day')),
      month: moment(currentDate).month()
    })
    currentDate = moment(currentDate).add(1, 'day')
  }
  return dateArray
}

export const getMentorAvailabilitySlot = async (id, onlyCount = false) => {
  const data = await requestToGraphql(gql`{
  mentorAvailabilitySlot(id: "${id}") {
    count
    ${!onlyCount ? `
    id
    date
    slotName
    ` : ''}
    mentorSessionsMeta {
      count
    }
    ${!onlyCount ? `broadCastedMentors {
      id
    }` : ''}
  }
}
`)
  return get(data, 'data.mentorAvailabilitySlot')
}

export const getMenteeSession = async (id) => {
  const data = await requestToGraphql(gql`{
  menteeSession(id: "${id}") {
    id
    ${getSlotNames()}
    bookingDate
    course {
      id
      title
    }
    mentorAvailabilitySlot {
      id
      paySlab {
        id
        title
        description
        vertical
        ${getPaySlabSlots()}
      }
    }
    user {
      id
      name
      studentProfile {
        grade
      }
    }
    broadCastedMentors {
      id
    }
    topic {
      id
      title
      order
      thumbnailSmall {
        uri
      }
    }
  }
}
`)
  return get(data, 'data.menteeSession')
}

export const getMentorMenteeSession = async (id) => {
  const data = await requestToGraphql(gql`
  {
  mentorMenteeSessions(filter: { menteeSession_some: { id: "${id}" } }) {
    id
    mentorSession{
      user{
        id
      }
    }
  }
}
`)
  return get(data, 'data.mentorMenteeSessions', [])
}

export const getMentorMenteeSessionForDemo = async (userId, id) => {
  const data = await requestToGraphql(gql`
    {
  mentorSessions(
    filter: {
      and: [
        { user_some: { id: "${userId}" } }
        { mentorMenteeSessions_some: { id: "${id}" } }
      ]
    }
  ) {
    id
    course {
      id
      title
    }
    createdAt
    availabilityDate
    sessionType
    ${getSlotNames()}
    user {
      id
      name
      profile: mentorProfile {
        sessionLink
      }
    }
    mentorMenteeSessions(filter: { id: "${id}" }) {
      id
      salesOperationData: salesOperation {
        id
      }
      topic {
        order
        title
        description
        thumbnailSmall {
          uri
        }
      }
      course {
        id
        title
      }
      sessionRecordingLink
      isFeedbackSubmitted
      hasRescheduled
      sessionStatus
      sendSessionLink
      sessionStartDate
      sessionEndDate
      menteeSession {
        id
        bookingDate
        ${getSlotNames()}
        user {
          id
          name
        }
      }
    }
  }
}
  `)
  return get(data, 'data.mentorSessions[0]')
}

export const getDaySlotDetails = async (slotId, mentorId) => {
  const data = await requestToGraphql(gql`{
  mentorDemandSlot(id: "${slotId}") {
    id
    date
    slots(filter: { broadCastedMentors_some: { user_some: { id: "${mentorId}" } } }) {
      id
      slotName
    }
  }
}
`)
  return get(data, 'data.mentorDemandSlot')
}

export const studentNotesData = {
  label: 'Notes for the student',
  studentNoteForIQ: {
    smartAndAttentive: 'The student is very smart & attentive and understood the concepts clearly. Also, the kid tried answering all the question and was very curious. Overall the kid has great potential',
    interestedAndEagerToLearn: 'The student was really interested in coding and was eager to learn as well. Also, the student was asking questions constantly.',
    goodCommunicationAndCurious: 'The student is an extrovert and has amazing communication skills, also the kid was able to quickly grasp the concepts and had a lot of curiosity to learn more. The kid has great potential overall',
    interactiveAndFocused: 'The student was good at catching concepts and was really interactive and focused throughout the sessions and was very interested to learn coding',
    problemSolvingAndCreativeThinkingSkill: 'The student was really curious and filled with tons of energy also, had good problem-solving skills and creative thinking. Amazing kid!',
  },
  isRequired: true
}

export const iqaTags = {
  label: 'Tags for the students',
  iqaTags: {
    ambitious: 'Ambitious',
    energetic: 'Energetic',
    curious: 'Curious',
    quickLearner: 'Quick Learner',
    focused: 'Focused',
    determined: 'Determined'
  },
  iqaTagsArray: [
    {
      value: 'ambitious', text: 'Ambitious', Icon: AmbitiousIcon
    },
    {
      value: 'energetic', text: 'Energetic', Icon: ThunderIcon
    },
    {
      value: 'curious', text: 'Curious', Icon: CuriousIcon
    },
    {
      value: 'quickLearner', text: 'Quick Learner', Icon: QuickLearnerIcon
    },
    {
      value: 'focused', text: 'Focused', Icon: FocusedIcon
    },
    {
      value: 'determined', text: 'Determined', Icon: DeterminedIcon
    }
  ],
  isRequired: true
}

export const b2cPaidFormConstants = [
  {
    label: 'How attentive was the kid ?',
    key: 'attentionLevel'
  },
  {
    label: "How clearly has the kid understood previous session's concept ?",
    key: 'previousSessionUnderstandingLevel'
  },
  {
    label: "How well kid's has understood this session ?",
    key: 'currentSessionUnderstandingLevel'
  },
]

export const b2cPaidFormKeyEveryFiveHead = ['Learning speed', 'Analytical skills', 'Problem solving skills', 'Interest in learning', 'Eagerness']
export const b2cPaidFormKeyEveryFive = ['learningSpeed', 'analyticSkills', 'problemSolvingAbility', 'interestInLearning', 'eagerness']


export default { getSlotTime }
