import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import { learningSlideType } from '../../../../../constants/learningSlideConstants'
import { Main,SlideContentHolder,
GridSlideText,StyledSelect} from './PracticeQuestion.style'
import getDecodedStatement from '../../../../../utils/getDecodedStatement'
import { QuestionView } from '../../../../AddSessions/components/SessionModal/TopicComponents'
import { isBase64 } from '../../../../../utils/base64Utility'
import parseChatStatement from '../../../../../utils/parseStatement'

const PracticeQuestion =(props)=> {
  const [selectVal, setSelectVal] = useState('')
  let IdArray = props.learningSlides.filter(slide => get(slide, 'practiceQuestions', []).length > 0
    && get(slide, 'id') !== get(props, 'learningSlide.id')).map(slide => get(get(slide, 'practiceQuestions')[0], 'id'))
    const renderOptionStatement = (item) => {
      if (isBase64(get(item, 'statement'))) {
        return getDecodedStatement(get(item, 'statement'))
      }
      return parseChatStatement({ statement: get(item, 'statement') })
    }
    const handleValue =(obj)=>{
      if (props.learningSlide) {
        setSelectVal(get(obj, 'id'))
        if(!get(props.learningSlide,'id','')) {
          props.SetPracticeData(obj,0)
        } else {
          props.SetPracticeData(obj,1)
        }
        let newComponent={}
        newComponent={
          ...props.learningSlide,
          practiceFlag:true
        }
        props.setLearningSlide(newComponent)
      }
    }
    const getPracticeQuestion = () =>{
      let pqQuestion = get(props.learningSlide,'practiceQuestions[0]')
      if (get(props.learningSlide,'practiceQuestions',[]).length === 1) {
        pqQuestion = get(props.learningSlide,'practiceQuestions[0]')
      } else if (get(props.learningSlide, 'practiceQuestions', []).length > 1) {
        pqQuestion = get(props.learningSlide, 'practiceQuestions[1]')
      }
      return pqQuestion
    }
    const pqQuestion = getPracticeQuestion()
    useEffect(()=>{
      if (pqQuestion) {
        setSelectVal(get(pqQuestion, 'id'))
      }
    },[])
    const practiceQuestions = pqQuestion ? [pqQuestion] : []
    return <Main>
    {  get(props.learningSlide,'type','') === learningSlideType.practiceQuestion ? <SlideContentHolder flexDirection='column'>
        <GridSlideText>Select Practice Question</GridSlideText>
               <StyledSelect value={selectVal} style={{ width: '70%' }}>
                 {
                   props.practiceQuestions && props.practiceQuestions.length>0 && props.practiceQuestions.filter((ques)=>{ if(ques.id){return !IdArray.includes(ques.id)}}).map((obj)=>{
                     return <StyledSelect.Option title={renderOptionStatement(obj)} value={get(obj, 'id')} onClick={()=>{handleValue(obj)}}>{renderOptionStatement(obj)}</StyledSelect.Option>
                   })
                 }
        </StyledSelect>
        {practiceQuestions.length ? <div><QuestionView key={selectVal} questions={practiceQuestions} /></div> : null}
    </SlideContentHolder> : ""
    }
    </Main>
}
export default PracticeQuestion