import React, { useState } from 'react'
import { get } from 'lodash'
import {Checkbox,Input} from 'antd'
import { ComponentHolder,HeadingDiv,TerminalType,
StyledSelect,TypeHolder,SvgDiv,
SvgHolder,PlaygroundOption,CheckBoxHolder,
PlaygroundHolder} from './CodeEditor.style'
import {LayoutOneSvg,LayoutTwoSvg} from '../../../../../../../../constants/icons'
import LayoutOne from './Components/LayoutOne'
import LayoutTwo from './Components/LayoutTwo'

const CodeEditor =(props)=> {
  const[playGround,setPlayGround]=useState(get(props.selectedContent,'codeEditorConfig.executionAccess'))
    const options=[
        {
          key: 'row',
          iconRenderrer: () => <LayoutTwoSvg/>,
        },
        {
          key: 'column',
          iconRenderrer: () => <LayoutOneSvg/>,
        },
      ]
    const [layout,setLayout]=useState(get(props.selectedContent,'codeEditorConfig.layout',''))
    const [layoutOptions,setlayoutOptions]=useState(options)
    const handleValue=(val)=>{
        props.selectedContent.codeInput=val
    }
    const handleLayoutType=(value)=>{
        setLayout(value)
        if(props.selectedContent) {
          if(props.selectedContent.codeEditorConfig) {
            if(value === 'row') {
              props.Helper('row','layout','codeEditor',props.slideContents,props.number)
           } else if(value === 'column') {
            props.Helper('column','layout','codeEditor',props.slideContents,props.number)
           }
          }
       }
    }
    const LayoutType=(val)=>{
        if(layout === 'row') {
          return <LayoutTwo selectedContent={props.selectedContent} Helper={props.Helper} Index={props.Index} number={props.number} slideContents={props.slideContents}/>
       } else if(layout === 'column') {
         return <LayoutOne selectedContent={props.selectedContent} Helper={props.Helper} Index={props.Index} number={props.number} slideContents={props.slideContents}/>
       } else if (layout === null) {
        return <LayoutOne selectedContent={props.selectedContent} Helper={props.Helper} Index={props.Index} number={props.number} slideContents={props.slideContents}/>
       }
    }
    const handleSelectValue=(value)=>{
      if(props.selectedContent) {
         if(props.selectedContent.codeEditorConfig) {
          props.Helper(value,'editorMode','codeEditor',props.slideContents,props.number)
         }
      }
    }
    const handleCheckBox=(e)=>{
      if(props.selectedContent) {
        if(props.selectedContent.codeEditorConfig) {
           props.Helper(e.target.checked,'executionAccess','codeEditor',props.slideContents,props.number)
        }
     }
    }
    return <ComponentHolder>
        <div style={{height:'fit-content'}}>
        <HeadingDiv>
            <TypeHolder>
              <TerminalType>Terminal Type:</TerminalType>
              <StyledSelect defaultValue={"python"} onChange={(value)=>{handleSelectValue(value)}} value={get(props.selectedContent,'codeEditorConfig.editorMode')}>
                  <StyledSelect.Option value="python">Python</StyledSelect.Option>
                  <StyledSelect.Option value="markup">Web Development</StyledSelect.Option>
                  <StyledSelect.Option value="java">Java</StyledSelect.Option>
                  <StyledSelect.Option value="sql">Sql</StyledSelect.Option>
              </StyledSelect>
            </TypeHolder>
            <TypeHolder>
              <TerminalType>Terminal layout:</TerminalType>
              <SvgDiv>
                  {
                      layoutOptions.map((obj)=>{
                        return <SvgHolder onClick={()=>{handleLayoutType(obj.key)}} ticked={layout=== obj.key}>
                        {obj.iconRenderrer()}   
                        </SvgHolder>
                      })
                  }
              </SvgDiv>
            </TypeHolder>
        </HeadingDiv>
        <PlaygroundHolder>
              <PlaygroundOption>Run in Codeplayground:</PlaygroundOption>
              <CheckBoxHolder>
                <Checkbox checked={get(props.selectedContent,'codeEditorConfig.executionAccess','')===null ?false:get(props.selectedContent,'codeEditorConfig.executionAccess','')} onChange={(e)=>{handleCheckBox(e)}}/>
              </CheckBoxHolder>
        </PlaygroundHolder>
        </div>
        {LayoutType()}
    </ComponentHolder>
}
export default CodeEditor; 
