import styled from 'styled-components'
import {Button,Input} from 'antd'
const {TextArea} =Input

const ComponentHolder = styled.div`
text-align: center;
width: 100%;
height: 100%;
border-radius: 11px;
`

const StyledTextArea = styled(TextArea)`
&.ant-input {
 height:100% !important;
 border-radius:11px;
}
`
const AddImageButton = styled(Button)`
&.ant-btn {
    background: #8C61CB !important;
    color: #FFFFFF !important;
    font-family: 'Inter'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    border-radius: 8px !important;
    padding: 7px 16px 7px 12px !important;
}
`

export {
    ComponentHolder,
    AddImageButton,
    StyledTextArea
    }
    