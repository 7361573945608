import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import withUpdatedDashboardNav from '../../../components/withUpdatedNav'
import { DEMAND_SUPPLY_DASHBOARD } from '../../../constants/navItemsConst'
import injectProps from '../../../components/injectProps'
import SupplyCalendar from './SupplyCalendar'

const SupplyCalendarSideNav = withUpdatedDashboardNav(SupplyCalendar)({
  title: 'Supply Calendar',
  activeNavItem: 'Supply Calendar',
  showUMSNavigation: true,
  renderNavItems: DEMAND_SUPPLY_DASHBOARD,
  noPadding: true
})

const mapStateToProps = state => ({
  mentorSupplyPaySlabsFetchStatus: state.data.getIn(['mentorSupplyPaySlabs', 'fetchStatus', 'mentorSupplyPaySlabs']),
  mentorSupplyPaySlabs: state.data.getIn(['mentorSupplyPaySlabs', 'data']),
  mentorDemandSlotsFetchStatus: state.data.getIn(['mentorDemandSlots', 'fetchStatus', 'mentorDemandSlots']),
  mentorDemandSlots: state.data.getIn(['mentorDemandSlots', 'data']),
  mentorDemandSlotsAddStatus: state.data.getIn(['mentorDemandSlots', 'addStatus', 'mentorDemandSlots']),
  mentorDemandSlotsAddFailure: state.data.getIn(['errors', 'mentorDemandSlots/add']),
  mentorDemandSlotsUpdateStatus: state.data.getIn(['mentorDemandSlots', 'updateStatus', 'mentorDemandSlots']),
  mentorDemandSlotsUpdateFailure: state.data.getIn(['errors', 'mentorDemandSlots/update']),
  mentorAvailabilitySlots: state.data.getIn(['mentorAvailabilitySlots', 'data']),
  mentorAvailabilitySlotsFetchStatus: state.data.getIn(['mentorAvailabilitySlots', 'fetchStatus', 'mentorAvailabilitySlots']),
})


const SupplyCalendarSideNavWithExtraProps = injectProps({
  notification
})(SupplyCalendarSideNav)
export default connect(mapStateToProps)(withRouter(SupplyCalendarSideNavWithExtraProps))
