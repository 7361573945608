import gql from 'graphql-tag'
import duck from '../../duck'

const fetchTeacherBatches = async ({ mentorId }) =>
  duck.query({
    query: gql`
  {
    teacherBatches: batches(filter: {and: [{allottedMentor_some: {id: "${mentorId}"}}, {documentType: classroom}, {isTeacherTraining: true}]}) {
      id  
      school {
        id
        name
      }
      studentsMeta {
        count
      }
      code
      classroomTitle
      }
    }
`,
    type: 'teacherBatches/fetch',
    key: 'teacherBatches',
    changeExtractedData: (extractedData, originalData) => {
      return { ...originalData }
    }
  })

export default fetchTeacherBatches
