import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const eventWinners = async (id) => duck.query({
  query: gql`
  {
    event(id: "${id}") {
      id
      showWinnersOnWeb
      showMomentsOnWeb
      showCompletedEvent
      momentFromEventLink
      registeredUsersMeta{
        count
      }
      eventWinners {
        id
        image {
          id
          uri
        }
        prize {
          id
          title
        }
        studentProfile {
          id
          user {
            id
            name
            profilePic {
              id
              uri
            }
          }
          parents {
            id
            user {
              id
              phone {
                countryCode
                number
              }
            }
          }
        }
      }
    }
  }
  `,
  type: 'event/fetch',
  key: 'event',
  changeExtractedData: (extractedData, originalData) => {
    extractedData.event = get(originalData, 'event');
    return { ...extractedData }
  }
})


export default eventWinners