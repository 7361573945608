import gql from "graphql-tag"
import { get } from "lodash"
import requestToGraphql from "../../utils/requestToGraphql"

const addMentorsAsTrainer = async (updateInputStr) => {
    const updateUsersMentors = await requestToGraphql(gql`mutation {
    updateUsers(
        input: [${updateInputStr}]
    ) {
        id
    }
    }
    `)
    return get(updateUsersMentors, 'data.updateUsers', [])
}

export default addMentorsAsTrainer;