import gql from 'graphql-tag'
import { get } from 'lodash';
import duck from '../../duck'


const UpdateUser = async (id,mentorProfileRowData) =>
  duck.query({
    query: gql`
    mutation {
        updateUser(
          id:"${id}"
          input: {
            role: mentor
            secondaryRole: schoolTeacher 
            name: "${get(mentorProfileRowData,'Name')}"
            email: "${get(mentorProfileRowData, 'Email')}"
            ${get(mentorProfileRowData, 'setPassword') ? `password:"${get(mentorProfileRowData, 'setPassword')}"` : ''}
            ${get(mentorProfileRowData, 'setPassword') ? `savedPassword:"${get(mentorProfileRowData, 'setPassword')}"` : ''}
            ${get(mentorProfileRowData, 'Phone', '') ? `
            phone: { countryCode: "${get(mentorProfileRowData,'CountryCode')}", number: "${get(mentorProfileRowData,'Phone', '')}" }
            ` : ''}
            displayName: "${get(mentorProfileRowData,'displayName')}"
          }
        ){
            id
        }
      }
    `,
    type: 'users/update',
    key: 'users',
  })

export default UpdateUser
