import gql from 'graphql-tag'
import duck from '../../duck'
import getIdArrForQuery from '../../utils/getIdArrForQuery'

const addTimeTableSchedule = async (input, schoolConnectId, batchConnectIds) => await duck.query({
  query: gql`
    mutation($input:TimetableScheduleInput!){
      addTimetableSchedule(
        ${schoolConnectId ? `schoolConnectId:"${schoolConnectId}"` : ''},
        ${batchConnectIds ? `batchConnectIds:[${getIdArrForQuery(batchConnectIds)}]` : ''},
        input:$input
      ){
        id
      }
    }
  `,
  variables: {
    input
  },
  type: 'timeTableSchedule/add',
  key: 'addTimeTableSchedule'
})

export default addTimeTableSchedule
