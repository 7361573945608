import gql from 'graphql-tag';
import { get } from 'lodash';
import duck from '../../duck';

const updateMentorProfileLinks = async (id, input) => {
    return duck.query({
      query: gql`
            mutation($input: MentorProfileUpdate){
                updateMentorProfile(
                    id: "${id}"
                    input: $input,
                ){
                    googleMeetLink
                    sessionLink
                    meetingId
                    meetingPassword
                    knownLanguages{
                      value
                    }
                }
            }
        `,
      variables: {
        input,
      },
      type: 'updateMentor/update',
      key: 'mentorProfileUpdate',
      changeExtractedData: (extractedData, originalData) => {
        extractedData.updateMentorProfile = originalData.updateMentorProfile;
        return { ...extractedData };
      },
    });
}

export default updateMentorProfileLinks