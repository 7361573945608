/*eslint-disable*/
import React from 'react'
import './BlocklyModal.css'
import Blockly from 'blockly'
import { BlocklyWorkspace } from 'tekie-blockly'
import MainModal from '../../../components/MainModal'
import { BLOCKLY_PLAYGROUND_THEME } from '../../../utils/blocklyUtils'
import StyledMcq from './Blockly.style'

const BlocklyModal = (props) => {
  const workspaceConfiguration = {
    readOnly: false,
    grid: false,
    move: {
      scrollbars: {
        horizontal: true,
        vertical: true,
      },
      drag: true,
      wheel: true,
    },
    zoom: {
      controls: true,
      wheel: true,
      startScale: 1.0,
      maxScale: 2,
      minScale: 0.3,
      scaleSpeed: 1.2,
      pinch: true,
    },
    trashcan: true,
  };
  var xml
  const { openModal, closeModal, handleModalCallback, currentXML} = props
  return (
    <MainModal
        visible={openModal}
        title={'Add Blockly Option'}
        onCancel={closeModal}
        maskClosable={false}
        width='800px'
        centered
        destroyOnClose
        footer={null}
      >
        {
          <div>
            <div style={{ width: '100%', height: '400px', marginBottom: '20px' }}>
              <BlocklyWorkspace
                useDefaultToolbox
                workspaceConfiguration={workspaceConfiguration}
                customTheme={BLOCKLY_PLAYGROUND_THEME}
                onXmlChange={(workspace) => {
                  if (window && window[`GenerateModalWorkspace`]) {
                    xml = Blockly.Xml.workspaceToDom(window[`GenerateModalWorkspace`]);
                    xml = Blockly.Xml.domToText(xml)
                  }
                }}
                initialXml={currentXML}
                blocklyKey='GenerateModal'
              />
            </div>
            <StyledMcq.StyledButton
              icon='plus'
              id='add-btn'
              onClick={(e) => handleModalCallback(xml)}
            >Generate</StyledMcq.StyledButton>
          </div>
            
        }
      </MainModal>
  );
};  
export default BlocklyModal