import gql from 'graphql-tag'
import duck from '../../duck'

const removeBatchStudents = async (batchId) => duck.query({
  query: gql`
    mutation {
        removeBatchStudents(batchId: "${batchId}") {
          batchId
        }
    }
    `,
  type: 'removeBatchStudents/update',
  key: 'removeBatchStudents',
})

export default removeBatchStudents
