/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { Spin, Popconfirm, message, notification } from 'antd'
import { difference, get, sortBy} from 'lodash'
import topicJourneyRoutes from '../../../constants/topicJourneyRoutes'
import TopicNav from '../../../components/TopicNav'
import { Cross, AddPlus, Grid1X1, Grid1X2, Grid2X2, Grid2X2MergeLeft,CheckMark,NRowspan } from '../../../constants/icons'
import Grid from './Components/Grid'
import { learningSlideType,gridLayoutType,slideContentType } from '../../../constants/learningSlideConstants'
import { Main, HeadingTag, SlideHolder,
  InnerSlideHeading, ListContainer, Slide,
  SliderInnerText, CrossHolder, AddButtonHolder,
  PlusHolder, PlusButtonText, InfoHolder,
  FilterHolder, StyledInput, NameDiv,
  FilterText, StyledSelect, SvgHolder, SvgDiv, CheckMarkHolder, SlideOptionDiv,
  PreviewButton, SaveNFinishButton, SaveButton, SelectHolder, SpinEncloser, MessageText,
  InputHolder, HeadingHolder, ExistsText, SlideDivContainer, ButtonHolders, SaveButtons, SvgInfoEncloser } from './ContentLearningSlides.style'
import fetchContentLearningObjective from '../../../actions/contentMaker/contentLearningObjective/fetchContentLearningObjective'
import uploadFile from '../../../actions/utils/uploadFile'
import fetchSlides from '../../../actions/contentMaker/contentLearningSlides/fetchSlides'
import fetchSlideContents from '../../../actions/contentMaker/contentLearningSlides/fetchSlideContents'
import addLearningSlide from '../../../actions/contentMaker/contentLearningSlides/addLearningSlide'
import updateLearningSlide from '../../../actions/contentMaker/contentLearningSlides/updateLearningSlide'
import updateLearningSlideContent from '../../../actions/contentMaker/contentLearningSlides/updateLearningSlideContent'
import addLearningSlideContent from '../../../actions/contentMaker/contentLearningSlides/addLearningSlideContent'
import fetchPracticeQuestions from '../../../actions/contentMaker/contentLearningSlides/fetchPracticeQuestions'
import removeSlideContentFile from '../../../actions/contentMaker/contentLearningSlides/removeSlideContentFile'
import deleteLearningSlide from '../../../actions/contentMaker/contentLearningSlides/deleteLearningSlide'
import deleteLearningSlideContent from '../../../actions/contentMaker/contentLearningSlides/deleteLearningSlideContent'
import removeLearningSlideQuestion from '../../../actions/contentMaker/contentLearningSlides/removeLearningSlideQuestion'
import fetchLearningObjective from '../../../actions/contentMaker/contentLearningSlides/fetchLearningObjective'

const ContentLearningSlides = (props) => {
  const options = [
    {
      key: 'grid1X1',
      iconRenderrer: () => <Grid1X1 />,
    },
    {
      key: 'grid1X2',
      iconRenderrer: () => <Grid1X2 />,
    },
    {
      key: 'grid2X2',
      iconRenderrer: () => <Grid2X2 />,
    },
    {
      key: 'grid2X2MergeLeft',
      iconRenderrer: () => <Grid2X2MergeLeft />,
    },
    {
      key: 'nRowspan',
      iconRenderrer: () => <NRowspan/>,
    },

  ]
  const [listArr, setlistArr] = useState([])
  const [submitButton,setSubmitButton] = useState(false)
  const [quesArr, setQuesArr] = useState([])
  const [learningObjectiveObj, setlearningObjectiveObj] = useState({})
  const [duplicate, setDuplicate] = useState(false)
  const [isRequiredArr, setIsRequiredArr] = useState([])
  const [isRequiredArrHeading, setisRequiredArrHeading] = useState([])
  const [slideContentArr, setSlideContentArr] = useState([])
  const [intialGridType, setIntialGridType] = useState('')
  const [learningSlide, setLearningSlide] = useState()
  const [gridOptions, setGridOptions] = useState(options)
  const [addedSlides, setAddedSlides] = useState([])
  const [deletedSlides, setDeletedSlides] = useState([])
  const [addedSlideContent, setaddedSlideContent] = useState([])
  const [isSlideDataChange, setisSlideDataChange] = useState(false)
  const [isPracticeDataChange, setisPracticeDataChange] = useState(false)
  const [showMsg, setshowMsg] = useState(false)
  const [Index, setIndex] = useState(0)
  const[isMediaUploaded,setIsMediaUploaded]=useState(false)
  const[finalSubmit,setFinalSubmit]=useState(false)
  const[successFlag,setSuccessFlag]=useState(false)


  const getMessage = (exp, status) => {
    if (exp === '' && status === 'slideAdded') {
      return 'Slide added successfully'
    } else if (status === 'error' && exp !== null) {
      return `Please fill ${exp} in this slide`
    } else if (status === 'success' && exp === '') {
      return 'All the slides submitted successfully'
    }
  }

  const handleSlide = async (slide, index,list) => {
    if(list!==undefined) {
      if(list.length>1) {
        const valObj=list[index]
        setLearningSlide(valObj)
        if (get(valObj, 'id','')) {
          const id=get(valObj, 'id','')
          await fetchSlideContents(id)
          if(get(valObj, 'layoutType','')) {
            setIntialGridType(get(valObj, 'layoutType'))
          }
        } else {
          setSlideContentArr([])
        }
        setIndex(index)
      }
    } else {
    setLearningSlide(slide)
    if (get(slide, 'id','')) {
      const id=get(slide, 'id','')
      await fetchSlideContents(id)
      if(get(slide, 'layoutType','')) {
        setIntialGridType(get(slide, 'layoutType'))
      }
    } else {
      setSlideContentArr([])
    }
    setIndex(index)
    }
  }
  const handleGridOption =async(selectedSlide)=>{

    if(get(selectedSlide,'id','')){
      const id=get(selectedSlide, 'id','')
      await fetchSlideContents(id)
    }
  }

  const openNotification = (exp, status) => {
    notification.open({
      message: getMessage(exp, status),
      duration: 2,
      style: {
        backgroundColor: '#8C61CB',
        color: 'white',
        borderRadius: '10px',
        fontFamily: 'inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
      },
    })
  }
  const addSlide =() => {
    setSuccessFlag(false)
    const nextOrderNo = listArr.length > 0 ? Math.max(...listArr.map((arr) => arr.order )) + 1 : 1
    const input = { name: `This is Slide  ${nextOrderNo}`,
      order: nextOrderNo,
      layoutType: 'grid1X1',
      type: 'grid',
      status: 'published',
    }
    const newList = [...listArr, input]
    setIntialGridType(get(input,'layoutType',''))
    setlistArr(newList)
    const newSlides = [...addedSlides, input]
    if (newList.length>0) {
      setLearningSlide(newList[newList.length - 1])
    }
    setAddedSlides(newSlides)
    setSlideContentArr([])
  }
  const handleDelete = async (id, input) => {
    setIsRequiredArr([])
    const updatedList = sortBy(listArr, 'order').filter((elem, index) => index !== id)
    if(get(input, 'id','')) {
      const id=get(input, 'id','')
      await deleteLearningSlide(id)
    }
    setlistArr(updatedList)
    if (updatedList.length === 0) {
      setLearningSlide()
    }
    const newSlides = [...deletedSlides, input]
    setDeletedSlides(newSlides)
  }
  const handleSlideType = async(value) => {
    let newComponent={}
    if (!get(learningSlide, 'id','')) {
      newComponent={...learningSlide,newlyAdded:true}
    } else {
      setisSlideDataChange(true)
    }
    if (learningSlide) {
      if (value === learningSlideType.grid) {
        newComponent={...learningSlide,type: value}
        // newComponent={...learningSlide,type: value, layoutType: gridLayoutType.gridOneByOne}
        // await handleSlide(newComponent)
        // getSlideContent()
        // if (get(learningSlide, 'id')) {
        //   newComponent = {
        //     id: get(learningSlide, 'id'),
        //     order: get(learningSlide, 'order'),
        //     name: get(learningSlide, 'name'),
        //     layoutType: 'grid1X1',
        //     type: 'grid',
        //     status: 'published',
        //   }
        //   handleSvgClick('grid1X1', newComponent)
        // } else {
        //   newComponent={...learningSlide,type: value,}
        // }
        
      } else if (value === learningSlideType.googleSlides) {
        newComponent={...learningSlide,type: value}
      } else {
        newComponent={...learningSlide,type: value,practiceFlag:false}
        setisPracticeDataChange(true)
      }
    }
    setLearningSlide(newComponent)
    setlistArr([...listArr.filter((obj) => obj.order !== newComponent.order), newComponent])
  }
  const handleNameInput = (value) => {
    let newComponent={}
    if (!get(learningSlide, 'id','')) {
      newComponent={...learningSlide,newlyAdded: true}
    } else {
      setisSlideDataChange(true)
    }
    if (learningSlide) {
      if (get(learningSlide, 'name', '') === '' && value === '') {
        newComponent={...learningSlide,name: ''}
      } else {
        newComponent={...learningSlide,name: value}
      }
      const valueArr = listArr.map((item) => item.name)
      if (valueArr.includes(value, 0) === true) {
        setDuplicate(true)
      } else {
        setDuplicate(false)
      }
      setlistArr([...listArr.filter((obj) => obj.order !== newComponent.order), newComponent])
    }
    setLearningSlide(newComponent)
  }
  const handleSvgClick = async(value,slide) => {
    if(get(slide,'id','')) {
      await handleGridOption(slide)
       if (value !== intialGridType) {
        let newComponent={}
        newComponent={...learningSlide,layoutType: value,GridAdditon: true}
        if(intialGridType === 'nRowspan') {
          if (value === gridLayoutType.gridOneByOne) {
            if (learningSlide) {
              const newArr = []
              if(slideContentArr.length<1) {
                let difference=1-slideContentArr.length
                if(difference>0) {
                  for (let i = slideContentArr.length; i<1; i++) {
                    newArr.push({
                      type: 'codeSyntax',
                      gridPlacement: `0${i}`,
                      statement: null,
                      url: null,
                      codeInput: null,
                      codeOutput: null,
                      media: null,
                      shouldAdd: true,
                      showCodeInput:true,
                      showCodeOutput:false,
                      codeEditorConfig: {
                        editorMode: 'python',
                        layout: 'row',
                        executionAccess: null,
                      }
                    })
                  }
                }
                setaddedSlideContent(newArr)
              }
              if(newArr.length>0) {
                const arrToSet=[...slideContentArr,...newArr]
                newComponent={...newComponent,slideContents: arrToSet}
              } else {
                let arrToSet=[]
                for(let i=0;i<1;i++) {
                  let obj=slideContentArr.find(obj => obj.gridPlacement === `0${i}`)
                  arrToSet=[
                    ...arrToSet,
                    {
                      ...obj,
                      gridPlacement:'00'
                    }
                  ]
                }
                newComponent={...newComponent,slideContents: arrToSet}
              }
            }
          } else if (value === gridLayoutType.gridOneByTwo) {
            if (learningSlide) {
              const newArr = []
               if(slideContentArr.length<2) {
                const difference=2-slideContentArr.length
                if(difference>0) {
                  for (let i = slideContentArr.length; i<2; i++) {
                    newArr.push({
                      type: 'codeSyntax',
                      gridPlacement: `0${i}`,
                      statement: null,
                      url: null,
                      codeInput: null,
                      codeOutput: null,
                      media: null,
                      shouldAdd: true,
                      showCodeInput:true,
                      showCodeOutput:false,
                      codeEditorConfig: {
                        editorMode: 'python',
                        layout: 'row',
                        executionAccess: null,
                      }
                    })
                  }
                }
                setaddedSlideContent(newArr)
               }
               if(newArr.length>0) {
                const arrToSet=[...slideContentArr,...newArr]
                newComponent={...newComponent,slideContents: arrToSet}
              } else {
                let arrToSet=[]
                for(let i=0;i<2;i++) {
                  let obj=slideContentArr.find(obj => obj.gridPlacement === `${i}0`)
                  arrToSet=[
                    ...arrToSet,
                    {
                      ...obj,
                      gridPlacement:i === 0 ? '00' : '01' 
                    }
                  ]
                }
                newComponent={...newComponent,slideContents: arrToSet}
              }
            }
          } else if (value === gridLayoutType.gridTwoByTwo) {
            if (learningSlide) {
              const newArr = []
              if(slideContentArr.length<4) {
                const difference=4-slideContentArr.length
                if(difference>0) {
                  for (let i = slideContentArr.length; i<4; i++) {
                    newArr.push({
                      type: 'codeSyntax',
                      gridPlacement:  i === 0 ? '00' : i === 1 ? '01' : i === 2 ? '10' : '11',
                      statement: null,
                      url: null,
                      codeInput: null,
                      codeOutput: null,
                      media: null,
                      shouldAdd: true,
                      showCodeInput:true,
                      showCodeOutput:false,
                      codeEditorConfig: {
                        editorMode: 'python',
                        layout: 'row',
                        executionAccess: null,
                      }
                    })
                  }
                }
                setaddedSlideContent(newArr)
               }
               if(newArr.length>0) {
                const arrToSet=[...slideContentArr,...newArr]
                newComponent={...newComponent,slideContents: arrToSet}
              } else {
                let arrToSet=[]
                for(let i=0;i<4;i++) {
                  let obj=slideContentArr.find(obj => obj.gridPlacement === (i === 0 ? '00' : i === 1 ? '10' : i === 2 ? '20' : `${i}0`))
                  arrToSet=[
                    ...arrToSet,
                    {
                      ...obj,
                      gridPlacement:i === 0 ? '00' : i === 1 ? '01' : i === 2 ? '10' : '11',
                    }
                  ]
                }
                newComponent={...newComponent,slideContents: arrToSet}
              }
            }
          } else if (value === gridLayoutType.gridTwoByTwoMergeLeft) {
            if (learningSlide) {
              const newArr = []
              if(slideContentArr.length<3) {
                const difference=3-slideContentArr.length
                if(difference>0) {
                  for (let i = slideContentArr.length; i<3; i++) {
                    newArr.push({
                      type: 'codeSyntax',
                      gridPlacement: i === 0 ? '00' : i === 1 ? '01' : i === 2 ? '10' : '11',
                      statement: null,
                      url: null,
                      codeInput: null,
                      codeOutput: null,
                      media: null,
                      shouldAdd: true,
                      showCodeInput:true,
                      showCodeOutput:false,
                      codeEditorConfig: {
                        editorMode: 'python',
                        layout: 'row',
                        executionAccess: null,
                      }
                    })
                  }
                }
                setaddedSlideContent(newArr)
               }
               if(newArr.length>0) {
                const arrToSet=[...slideContentArr,...newArr]
                newComponent={...newComponent,slideContents: arrToSet}
              } else {
                let arrToSet=[]
                for(let i=0;i<3;i++) {
                  let obj=slideContentArr.find(obj => obj.gridPlacement === (i === 0 ? '00' : i === 1 ? '10' : i === 2 ? '20' : `${i}0`))
                  arrToSet=[
                    ...arrToSet,
                    {
                      ...obj,
                      gridPlacement:i === 0 ? '00' : i === 1 ? '01' : i === 2 ? '10' : '11',
                    }
                  ]
                }
                newComponent={...newComponent,slideContents: arrToSet}
              }
            }
          } 
        } else {
          if(value === gridLayoutType.nRowSpan) {
            if (learningSlide) {
              let newArr = []
              if(slideContentArr.length>0) {
                  for (let i = 0; i<slideContentArr.length; i++) {
                    let obj=slideContentArr.find(obj => obj.gridPlacement === (i === 0 ? '00' : i === 1 ? '01' : i === 2 ? '10' : '11'))
                     newArr=[
                       ...newArr,
                       {
                         ...obj,
                         gridPlacement:`${i}0`
                       }
                     ]
                  }
  
                setaddedSlideContent(newArr)
              }
  
              if(newArr.length>0) {
                // const arrToSet=[...slideContentArr,...newArr]
                newComponent={...newComponent,slideContents: newArr}
              }
            }
          } else {
            if (value === gridLayoutType.gridOneByOne) {
              if (learningSlide) {
                const newArr = []
                if(slideContentArr.length<1) {
                  let difference=1-slideContentArr.length
                  if(difference>0) {
                    for (let i = slideContentArr.length; i<1; i++) {
                      newArr.push({
                        type: 'codeSyntax',
                        gridPlacement: `0${i}`,
                        statement: null,
                        url: null,
                        codeInput: null,
                        codeOutput: null,
                        media: null,
                        shouldAdd: true,
                        showCodeInput:true,
                        showCodeOutput:false,
                        codeEditorConfig: {
                          editorMode: 'python',
                          layout: 'row',
                          executionAccess: null,
                        }
                      })
                    }
                  }
                  setaddedSlideContent(newArr)
                }
                if(newArr.length>0) {
                  const arrToSet=[...slideContentArr,...newArr]
                  newComponent={...newComponent,slideContents: arrToSet}
                } else {
                  let arrToSet=[]
                  for(let i=0;i<1;i++) {
                    let obj=slideContentArr.find(obj => obj.gridPlacement === `0${i}`)
                    arrToSet=[
                      ...arrToSet,
                      {
                        ...obj,
                        gridPlacement:'00'
                      }
                    ]
                  }
                  newComponent={...newComponent,slideContents: arrToSet}
                }
              }
            } else if (value === gridLayoutType.gridOneByTwo) {
              if (learningSlide) {
                const newArr = []
                 if(slideContentArr.length<2) {
                  const difference=2-slideContentArr.length
                  if(difference>0) {
                    for (let i = slideContentArr.length; i<2; i++) {
                      newArr.push({
                        type: 'codeSyntax',
                        gridPlacement: `0${i}`,
                        statement: null,
                        url: null,
                        codeInput: null,
                        codeOutput: null,
                        media: null,
                        shouldAdd: true,
                        showCodeInput:true,
                        showCodeOutput:false,
                        codeEditorConfig: {
                          editorMode: 'python',
                          layout: 'row',
                          executionAccess: null,
                        }
                      })
                    }
                  }
                  setaddedSlideContent(newArr)
                 }
                 if(newArr.length>0) {
                  const arrToSet=[...slideContentArr,...newArr]
                  newComponent={...newComponent,slideContents: arrToSet}
                } else {
                  let arrToSet=[]
                  for(let i=0;i<2;i++) {
                    let obj=slideContentArr.find(obj => obj.gridPlacement === `0${i}`)
                    arrToSet=[
                      ...arrToSet,
                      {
                        ...obj,
                        gridPlacement:i === 0 ? '00' : '01' 
                      }
                    ]
                  }
                  newComponent={...newComponent,slideContents: arrToSet}
                }
              }
            } else if (value === gridLayoutType.gridTwoByTwo) {
              if (learningSlide) {
                const newArr = []
                if(slideContentArr.length<4) {
                  const difference=4-slideContentArr.length
                  if(difference>0) {
                    for (let i = slideContentArr.length; i<4; i++) {
                      newArr.push({
                        type: 'codeSyntax',
                        gridPlacement:  i === 0 ? '00' : i === 1 ? '01' : i === 2 ? '10' : '11',
                        statement: null,
                        url: null,
                        codeInput: null,
                        codeOutput: null,
                        media: null,
                        shouldAdd: true,
                        showCodeInput:true,
                        showCodeOutput:false,
                        codeEditorConfig: {
                          editorMode: 'python',
                          layout: 'row',
                          executionAccess: null,
                        }
                      })
                    }
                  }
                  setaddedSlideContent(newArr)
                 }
                 if(newArr.length>0) {
                  const arrToSet=[...slideContentArr,...newArr]
                  newComponent={...newComponent,slideContents: arrToSet}
                } else {
                  let arrToSet=[]
                  for(let i=0;i<4;i++) {
                    let obj=slideContentArr.find(obj => obj.gridPlacement === (i === 0 ? '00' : i === 1 ? '01' : i === 2 ? '10' : '11'))
                    arrToSet=[
                      ...arrToSet,
                      {
                        ...obj,
                        gridPlacement:i === 0 ? '00' : i === 1 ? '01' : i === 2 ? '10' : '11',
                      }
                    ]
                  }
                  newComponent={...newComponent,slideContents: arrToSet}
                }
              }
            } else if (value === gridLayoutType.gridTwoByTwoMergeLeft) {
              if (learningSlide) {
                const newArr = []
                if(slideContentArr.length<3) {
                  const difference=3-slideContentArr.length
                  if(difference>0) {
                    for (let i = slideContentArr.length; i<3; i++) {
                      newArr.push({
                        type: 'codeSyntax',
                        gridPlacement: i === 0 ? '00' : i === 1 ? '01' : i === 2 ? '10' : '11',
                        statement: null,
                        url: null,
                        codeInput: null,
                        codeOutput: null,
                        media: null,
                        shouldAdd: true,
                        showCodeInput:true,
                        showCodeOutput:false,
                        codeEditorConfig: {
                          editorMode: 'python',
                          layout: 'row',
                          executionAccess: null,
                        }
                      })
                    }
                  }
                  setaddedSlideContent(newArr)
                 }
                 if(newArr.length>0) {
                  const arrToSet=[...slideContentArr,...newArr]
                  newComponent={...newComponent,slideContents: arrToSet}
                } else {
                  let arrToSet=[]
                  for(let i=0;i<3;i++) {
                    let obj=slideContentArr.find(obj => obj.gridPlacement === (i === 0 ? '00' : i === 1 ? '01' : i === 2 ? '10' : '11'))
                    arrToSet=[
                      ...arrToSet,
                      {
                        ...obj,
                        gridPlacement:i === 0 ? '00' : i === 1 ? '01' : i === 2 ? '10' : '11',
                      }
                    ]
                  }
                  newComponent={...newComponent,slideContents: arrToSet}
                }
              }
            } 
            
          }
        }
       
        setLearningSlide(newComponent)
        setlistArr([...listArr.filter((obj) => obj.order !== newComponent.order), newComponent])
      } else {
        let newComponent={}
        if (learningSlide) {
          newComponent={...learningSlide,layoutType: value,slideContents:slideContentArr,GridAdditon:false}
        }
        setLearningSlide(newComponent)
        setlistArr([...listArr.filter((obj) => obj.order !== newComponent.order), newComponent])
      }
    } else {
      let newComponent={}
      if (value !== intialGridType) {
        newComponent={...learningSlide,layoutType: value,GridAdditon: true}
        if (value === gridLayoutType.gridOneByOne) {
          if (learningSlide) {
            const newArr = []
            for (let i = 0; i<1; i++) {
              newArr.push({
                type: 'codeSyntax',
                gridPlacement: '00',
                statement: null,
                url: null,
                codeInput: null,
                codeOutput: null,
                media: null,
                shouldAdd: true,
                showCodeInput:true,
                showCodeOutput:false,
                codeEditorConfig: {
                  editorMode: 'python',
                  layout: 'row',
                  executionAccess: false,
                }
              })
            }
            setaddedSlideContent(newArr)
            newComponent={...newComponent,slideContents: newArr}
          }
        } else if (value === gridLayoutType.gridOneByTwo) {
          if (learningSlide) {
            const newArr = []
          for (let i = 0; i<2; i++) {
            newArr.push({
              type: 'codeSyntax',
              gridPlacement: `0${i}`,
              statement: null,
              url: null,
              codeInput: null,
              codeOutput: null,
              media: null,
              shouldAdd: true,
              showCodeInput:true,
                showCodeOutput:false,
              codeEditorConfig: {
                editorMode: 'python',
                layout: 'row',
                executionAccess: false,
              }
            })
          }
          setaddedSlideContent(newArr)
          newComponent={...newComponent,slideContents: newArr}
          }
        } else if (value === gridLayoutType.gridTwoByTwo) {
          if (learningSlide) {
            const newArr = []
            for (let i = 0; i<4; i++) {
              newArr.push({
                type: 'codeSyntax',
                gridPlacement: i === 0 ? '00' : i === 1 ? '01' : i === 2 ? '10' : '11',
                statement: null,
                url: null,
                codeInput: null,
                codeOutput: null,
                media: null,
                shouldAdd: true,
                showCodeInput:true,
                showCodeOutput:false,
                codeEditorConfig: {
                  editorMode: 'python',
                  layout: 'row',
                  executionAccess: false,
                }
              })
            }
            setaddedSlideContent(newArr)
            newComponent={...newComponent,slideContents: newArr}
          }
        } else if (value === gridLayoutType.gridTwoByTwoMergeLeft) {
          if (learningSlide) {
            const newArr = []
            for (let i = 0; i<3; i++) {
              newArr.push({
                type: 'codeSyntax',
                gridPlacement: i === 0 ? '00' : i === 1 ? '01' : i === 2 ? '10' : '11',
                statement: null,
                url: null,
                codeInput: null,
                codeOutput: null,
                media: null,
                shouldAdd: true,
                showCodeInput:true,
                showCodeOutput:false,
                codeEditorConfig: {
                  editorMode: 'python',
                  layout: 'row',
                  executionAccess: false,
                }
              })
            }
            setaddedSlideContent(newArr)
            newComponent={...newComponent,slideContents: newArr}
          }
        } else if (value === gridLayoutType.nRowSpan) {
          if (learningSlide) {
            const newArr = []
            for (let i = 0; i<1; i++) {
              newArr.push({
                type: 'codeSyntax',
                gridPlacement: '00',
                statement: null,
                url: null,
                codeInput: null,
                codeOutput: null,
                media: null,
                shouldAdd: true,
                showCodeInput:true,
                showCodeOutput:false,
                codeEditorConfig: {
                  editorMode: 'python',
                  layout: 'row',
                  executionAccess: false,
                }
              })
            }
            setaddedSlideContent(newArr)
            newComponent={...learningSlide,slideContents: newArr,layoutType: value}
          }
        }
      } else {
        if (learningSlide) {
          newComponent={...learningSlide,layoutType: value,slideContents:slideContentArr,GridAdditon:false}
        }
      }
      setLearningSlide(newComponent)
      setlistArr([...listArr.filter((obj) => obj.order !== newComponent.order), newComponent])
    }
  }
  const getValueType = async (obj, value) => {
    if (value !== '') {
      if (value === slideContentType.codeSyntax) {
        return 'codeInput'
      } else if (value === slideContentType.text) {
        return 'statement'
      } else if (value === slideContentType.code) {
        if (!get(obj, 'codeInput', '')) {
          return 'codeInput'
        }
      }
    }
  }
  const getValueTypeForFinal = (value) => {
    if (value !== '') {
      if (value === slideContentType.codeSyntax) {
        return 'codeInput'
      } else if (value === slideContentType.text) {
        return 'statement'
      } else if (value === slideContentType.image) {
        return 'media'
      } else if (value === slideContentType.video) {
        return 'media'
      }
    }
  }
  const ImageInput = async(slideContent, DataObj) => {
    let learningSlideContentID = ''
    if(get(slideContent, 'id','')) {
      learningSlideContentID=get(slideContent, 'id','')
    }
    async function DataUpload(learningSlideContent, UploadObj) {
      try {
        if (get(learningSlideContent, 'media.id')) {
          await removeSlideContentFile(learningSlideContentID, get(learningSlideContent, 'media.id'))
        }
        setIsMediaUploaded(true)
        await uploadFile(UploadObj, {
          fileBucket: 'python'
        }, {
          typeField: 'media', typeId: learningSlideContentID, type: 'LearningSlideContent',
        })
        setIsMediaUploaded(false)
      } catch (error) {
        console.log(error)
      }
    }
    if (DataObj !== '' && DataObj.length>0) {
      await DataUpload(slideContent, DataObj[0])
    }
  }
  const VideoInput = async(slideContent, DataObj) => {
    let learningSlideContentID = ''
    if(get(slideContent, 'id','')) {
      learningSlideContentID=get(slideContent, 'id','')
    }
    async function DataUpload(learningSlideContent, UploadObj) {
      try {
        if (get(learningSlideContent, 'media.id')) {
          await removeSlideContentFile(learningSlideContentID, get(learningSlideContent, 'media.id'))
        }
        setIsMediaUploaded(true)
        await uploadFile(UploadObj, {
          fileBucket: 'python'
        }, {
          typeField: 'media', typeId: learningSlideContentID, type: 'LearningSlideContent',
        })
        setIsMediaUploaded(false)
      } catch (error) {
        console.log(error)
      }
    }
    if (DataObj !== '' && DataObj.length>0) {
      await DataUpload(slideContent, DataObj[0])
    }
  }
  const ImageInputForAddition = async (slideContent, contentID, DataObj) => {
    let learningSlideContentID=''
    if(contentID) {
      learningSlideContentID = contentID
    }
    async function imageUpload(learningSlideContent, UploadObj) {
      try {
        if (get(learningSlideContent, 'media.id')) {
          await removeSlideContentFile(learningSlideContentID, get(learningSlideContent, 'media.id'))// mediaid of slideContent//ask pawan bhaiya
        }
        setIsMediaUploaded(true)
        await uploadFile(UploadObj, {
          fileBucket: 'python'
        }, {
          typeField: 'media', typeId: learningSlideContentID, type: 'LearningSlideContent',
        })
        setIsMediaUploaded(false)
      } catch (error) {
        console.log(error)
      }
    }
    if (DataObj !== '' && DataObj.length>0) {
        await imageUpload(slideContent, DataObj[0])
    }
  }
  const VideoInputForAddition = async (slideContent, contentID, DataObj) => {
    let learningSlideContentID=''
    if(contentID) {
      learningSlideContentID = contentID
    }
    async function videoUpload(learningSlideContent, UploadObj) {
      try {
        if (get(learningSlideContent, 'media.id')) {
          await removeSlideContentFile(learningSlideContentID, get(learningSlideContent, 'media.id'))// mediaid of slideContent//ask pawan bhaiya
        }
        setIsMediaUploaded(true)
        await uploadFile(UploadObj, {
          fileBucket: 'python'
        }, {
          typeField: 'media', typeId: learningSlideContentID, type: 'LearningSlideContent',
        })
        setIsMediaUploaded(false)
      } catch (error) {
        console.log(error)
      }
    }
    if (DataObj !== '' && DataObj.length>0) {
      await videoUpload(slideContent, DataObj[0])
    }
  }
  const getInput =async (value, obj) => {
    if (value !== '') {
      if (value === slideContentType.codeSyntax) {
        const input = {
          codeInput: get(obj, 'codeInput', ''),
          type: get(obj, 'type', ''),
          gridPlacement: get(obj, 'gridPlacement',''),
        }
        return input
      } else if (value === slideContentType.text) {
        const input = {
          statement: get(obj, 'statement', ''),
          type: get(obj, 'type', ''),
          gridPlacement: get(obj, 'gridPlacement',''),
        };
        return input
      } else if (value === slideContentType.code) {
        const input = {}
        // if (get(obj, 'showCodeInput', '') === true) {
          input.codeInput = get(obj, 'codeInput', '')=== null ?'' :get(obj, 'codeInput', '')
        // }
        // if (get(obj, 'showCodeOutput', '') === true) {
          input.codeOutput = get(obj, 'codeOutput', '') === null ?'' :get(obj, 'codeOutput', '')
        // }
        input.type = get(obj, 'type', '')
        input.gridPlacement = get(obj, 'gridPlacement','')
        const source = {
          codeEditorConfig: {
            editorMode: get(obj, 'codeEditorConfig.editorMode', ''),
            executionAccess: get(obj, 'codeEditorConfig.executionAccess', ''),
            layout: get(obj, 'codeEditorConfig.layout', ''),
          }
        }
        const res = Object.assign(input, source)
        return res
      } else if (value === slideContentType.image) {
        await ImageInput(obj, get(obj, 'imageData', []))
      } else if (value === slideContentType.video) {
        await VideoInput(obj, get(obj, 'VideoData', []))
      }
    }
  }
  async function getInputforAddition(value, obj, contentID, learningSlide) {
    if (value !== '') {
      if (value === slideContentType.codeSyntax) {
        const input = {
          codeInput: get(obj, 'codeInput', ''),
          type: get(obj, 'type', ''),
          gridPlacement: get(obj, 'gridPlacement','')
        }
        return input
      } else if (value === slideContentType.text) {
        const input = {
          statement: get(obj, 'statement', ''),
          type: get(obj, 'type', ''),
          gridPlacement: get(obj, 'gridPlacement','')
        }
        return input
      } else if (value === slideContentType.code) {
        const input = {}
        if (get(obj, 'codeInput')) {
          input.codeInput = get(obj, 'codeInput', '')
        }
        if (get(obj, 'codeOutput')) {
          input.codeOutput = get(obj, 'codeOutput', '')
        }
        input.type = get(obj, 'type', '')
        input.gridPlacement = get(obj, 'gridPlacement','')
        const source = {
          codeEditorConfig: {
            editorMode: get(obj, 'codeEditorConfig.editorMode', '') === null ? 'python' : get(obj, 'codeEditorConfig.editorMode', ''),
            executionAccess: get(obj, 'codeEditorConfig.executionAccess', '') === null ? false : get(obj, 'codeEditorConfig.executionAccess', ''),
            layout: get(obj, 'codeEditorConfig.layout', '') === null ? 'row' : get(obj, 'codeEditorConfig.layout', ''),
          }
        }
        const res = Object.assign(input, source)
        return res
      } else if (value === slideContentType.image) {
          await ImageInputForAddition(obj, contentID, get(obj, 'imageData', []))
      } else if (value === slideContentType.video) {
          await VideoInputForAddition(obj, contentID, get(obj, 'VideoData', []))
      }
    }
  }

  const handleGridUpdate = async (learningSlide, status, gridInput) => {
    if (get(learningSlide, 'slideContents',[]).length>0 && get(learningSlide, 'SlideAddition',false) === true) {
      const checkArr = get(learningSlide, 'slideContents', [])
      if(status==='Final') {
              const inputObj = { layoutType: get(learningSlide,'layoutType',''),
                name: get(learningSlide,'name',''),
                order: get(learningSlide,'order',''),
                status: get(learningSlide,'status',''),
                type: get(learningSlide,'type','')
              }
              let learningObjectiveId=''
              if(get(props,'match.params.learningObjectiveId','')) {
                learningObjectiveId = get(props,'match.params.learningObjectiveId','')
              }
              const learningObjectiveIds = []
              learningObjectiveIds.push(learningObjectiveId)
              const topicIds = []
              const courseIds = []
              if (learningObjectiveObj) {
                if (get(learningObjectiveObj, 'topics', []).length>0) {
                  const topicArr = get(learningObjectiveObj, 'topic', [])
                  topicArr.map((obj) => {
                    topicIds.push(obj.id)
                  })
                }
                if (get(learningObjectiveObj, 'courses', []).length>0) {
                  const courseArr = get(learningObjectiveObj, 'courses', [])
                  courseArr.map((obj) => {
                    courseIds.push(obj.id)
                  })
                }
              }
    
              const practiceQuestionsIds = []
              if(get(learningSlide, 'type', '')) {
                const Type=get(learningSlide, 'type', '') 
                if ( Type === learningSlideType.practiceQuestion) {
                  if (isPracticeDataChange === true ) {
                    // this means pq was selected and I only need to send query for pq and I am newly added slide with no id
                    const AddedObj = get(learningSlide, 'practiceQuestions', [])[0]
                    if(get(AddedObj,'id','')) {
                      practiceQuestionsIds.push(AddedObj.id)
                    } 
                    // const learningSlideObj = await addLearningSlide(inputObj, learningObjectiveIds, practiceQuestionsIds, topicIds, courseIds)
                  }
                } else {
                  let learningSlideObj
                  if (!get(learningSlide, 'id')) {
                    learningSlideObj = await addLearningSlide(inputObj, learningObjectiveIds, practiceQuestionsIds, topicIds, courseIds)
                  }
                  let LearningSlideId = ''
                  if (learningSlideObj) {
                    if (get(learningSlideObj,'addLearningSlide','')) {
                      LearningSlideId = get(learningSlideObj,'addLearningSlide.id','')
                    }
                  }
                  const checkArr = get(learningSlide, 'slideContents',[])
                  if(checkArr && checkArr.length>0)
                   await Promise.all(checkArr.map(async (obj) => {
                    const requiredValue = get(obj, 'type', '')
                    if (get(obj, 'shouldAdd',false) === true) {
                      if (requiredValue === slideContentType.image || requiredValue === slideContentType.video) {
                        const input = {
                          type: get(obj, 'type', ''),
                          gridPlacement: get(obj, 'gridPlacement','')
                        }
                        const learningSlideIds = []
                        learningSlideIds.push(LearningSlideId)
                        const res = await addLearningSlideContent(input, learningSlideIds)
                        let addedContentId = ''
                        if (res) {
                          if (res.addLearningSlideContent) {
                            addedContentId = get(res,'addLearningSlideContent.id','')
                          }
                        }
                        await getInputforAddition(requiredValue, obj, addedContentId, learningSlide)
                      } else {
                        const input = await getInputforAddition(requiredValue, obj, '', '')
                        const learningSlideIds = []
                        learningSlideIds.push(LearningSlideId)
                        await addLearningSlideContent(input, learningSlideIds)
                      }
                    }
                    obj.shouldAdd = false
                  }))
                }
              }
           
      } else {
        const inputObj = { layoutType: get(learningSlide,'layoutType',''),
                name: get(learningSlide,'name',''),
                order: get(learningSlide,'order',''),
                status: get(learningSlide,'status',''),
                type: get(learningSlide,'type','')
              }     
        let learningObjectiveId=''
              if(get(props,'match.params.learningObjectiveId','')) {
                learningObjectiveId = get(props,'match.params.learningObjectiveId','')
              }
        const learningObjectiveIds = []
              learningObjectiveIds.push(learningObjectiveId)
              const topicIds = []
              const courseIds = []
              if (learningObjectiveObj) {
                if (get(learningObjectiveObj, 'topics', []).length>0) {
                  const topicArr = get(learningObjectiveObj, 'topic', [])
                  topicArr.map((obj) => {
                    topicIds.push(obj.id)
                  })
                }
                if (get(learningObjectiveObj, 'courses', []).length>0) {
                  const courseArr = get(learningObjectiveObj, 'courses', [])
                  courseArr.map((obj) => {
                    courseIds.push(obj.id)
                  })
                }
              }
        const practiceQuestionsIds = []
              if(get(learningSlide, 'type', '')) {
                const Type=get(learningSlide, 'type', '') 
                if ( Type === learningSlideType.practiceQuestion) {
                  if (isPracticeDataChange === true ) {
                    // this means pq was selected and I only need to send query for pq and I am newly added slide with no id
                    const AddedObj = get(learningSlide, 'practiceQuestions', [])[0]
                    if(get(AddedObj,'id','')) {
                      practiceQuestionsIds.push(AddedObj.id)
                    } 
                    // const learningSlideObj = await addLearningSlide(inputObj, learningObjectiveIds, practiceQuestionsIds, topicIds, courseIds)
                  }
                } else {
                  let learningSlideObj
                  let LearningSlideId = ''
                  if (!get(learningSlide, 'id')) {
                    learningSlideObj = await addLearningSlide(inputObj, learningObjectiveIds, practiceQuestionsIds, topicIds, courseIds)
                    if (learningSlideObj) {
                      if (get(learningSlideObj,'addLearningSlide','')) {
                        LearningSlideId = get(learningSlideObj,'addLearningSlide.id','')
                      }
                    }
                  } else {
                    const inputObjUpdate = {
                      layoutType: get(learningSlide,'layoutType',''),
                      name: get(learningSlide,'name',''),
                      type: get(learningSlide,'type','')
                    }     
                    learningSlideObj = await updateLearningSlide(get(learningSlide, 'id'), inputObjUpdate)
                    if (learningSlideObj) {
                      if (get(learningSlideObj,'updateLearningSlide','')) {
                        LearningSlideId = get(learningSlideObj,'updateLearningSlide.id','')
                      }
                    }
                  }
                  const checkArr = get(learningSlide, 'slideContents',[])
                  if(checkArr && checkArr.length>0)
                   await Promise.all(checkArr.map(async (obj) => {
                    const requiredValue = get(obj, 'type', '')
                    if (get(obj, 'shouldAdd',false) === true) {
                      if (requiredValue === slideContentType.image || requiredValue === slideContentType.video) {
                        const input = {
                          type: get(obj, 'type', ''),
                          gridPlacement: get(obj, 'gridPlacement','')
                        }
                        const learningSlideIds = []
                        learningSlideIds.push(LearningSlideId)
                        const res = await addLearningSlideContent(input, learningSlideIds)
                        let addedContentId = ''
                        if (res) {
                          if (res.addLearningSlideContent) {
                            addedContentId = get(res,'addLearningSlideContent.id','')
                          }
                        }
                        await getInputforAddition(requiredValue, obj, addedContentId, learningSlide)
                      } else {
                        const input = await getInputforAddition(requiredValue, obj, '', '')
                        const learningSlideIds = []
                        learningSlideIds.push(LearningSlideId)
                        await addLearningSlideContent(input, learningSlideIds)
                      }
                    }
                    obj.shouldAdd = false
                  }))
                }
              }
                      
      }
      setAddedSlides([])
      learningSlide.SlideAddition = false
    }
    const checkArr = get(learningSlide, 'slideContents', [])
    if (checkArr.length !== slideContentArr.length) {
      if (get(learningSlide, 'GridAdditon',false) === true) {
        const input = {
          ...gridInput,
          layoutType: get(learningSlide, 'layoutType', '')
        }
        if(get(learningSlide,'id','')) {
          const id=get(learningSlide,'id','')
          await updateLearningSlide(id, input)
        }
        let UpdatedArr=[]
        const value=get(learningSlide, 'layoutType', '')
        if (value === gridLayoutType.gridOneByOne) {
          if(intialGridType!=='nRowspan') {
            UpdatedArr=slideContentArr.filter((obj)=>{return obj.gridPlacement!=='00'})
          } else {
            UpdatedArr=slideContentArr.filter((obj)=>{return obj.gridPlacement!=='00'})
          }
        } else if (value === gridLayoutType.gridOneByTwo) {
          if(intialGridType!=='nRowspan') {
            UpdatedArr=slideContentArr.filter((obj)=>{return obj.gridPlacement!=='00' && obj.gridPlacement!=='01'})
          } else {
            UpdatedArr=slideContentArr.filter((obj)=>{return obj.gridPlacement!=='00' && obj.gridPlacement!=='10'})
          }
        } else if (value === gridLayoutType.gridTwoByTwo) {
          if(intialGridType!=='nRowspan') {
            UpdatedArr=slideContentArr.filter((obj)=>{return obj.gridPlacement!=='00' && obj.gridPlacement!=='01' && obj.gridPlacement!=='10'&& obj.gridPlacement!=='11'})
          } else {
            UpdatedArr=slideContentArr.filter((obj)=>{return obj.gridPlacement!=='00' && obj.gridPlacement!=='10' && obj.gridPlacement!=='20'&& obj.gridPlacement!=='30'})
          }
        } else if (value === gridLayoutType.gridTwoByTwoMergeLeft) {
          if(intialGridType!=='nRowspan') {
            UpdatedArr=slideContentArr.filter((obj)=>{return obj.gridPlacement!=='00' && obj.gridPlacement!=='01' && obj.gridPlacement!=='10'})
          } else {
            UpdatedArr=slideContentArr.filter((obj)=>{return obj.gridPlacement!=='00' && obj.gridPlacement!=='10' && obj.gridPlacement!=='20'})
          }
        }
        for(let i=0;i<UpdatedArr.length;i++) {
              const obj=UpdatedArr[i]
              if(get(obj,'id','')) {
                await deleteLearningSlideContent(get(obj,'id',''))
              }
            }
        const learningSlideIds = []
        if(get(learningSlide,'id','')) {
          learningSlideIds.push(get(learningSlide,'id',''))
        }    
        if(checkArr && checkArr.length>0) {
          await Promise.all(checkArr.map(async (obj) => {
            const requiredValue = get(obj, 'type', '')
            if (get(obj, 'id')) {
              if (requiredValue === slideContentType.image || requiredValue === slideContentType.video) {
                const input = {
                  type: get(obj, 'type', ''),
                  gridPlacement: get(obj, 'gridPlacement'),
                }
                const learningSlideIds = []
                if(get(learningSlide,'id','')) {
                  learningSlideIds.push(get(learningSlide,'id',''))
                }
                await updateLearningSlideContent(obj.id, input, learningSlideIds)
                await getInput(requiredValue, obj)
              } else {
                const input = await getInput(requiredValue, obj)
                const learningSlideIds = []
                if(get(learningSlide,'id','')) {
                  learningSlideIds.push(get(learningSlide,'id',''))
                }
                if(get(obj,'id','')) {
                  await updateLearningSlideContent(get(obj,'id',''), input, learningSlideIds)
                }
              }
            }
          }))
        }
        if(checkArr && checkArr.length>0) {
         await Promise.all(checkArr.map(async (obj) => {
          const requiredValue = get(obj, 'type', '')
          if (get(obj, 'shouldAdd',false) === true) {
            if (requiredValue === slideContentType.image  || requiredValue === slideContentType.video ) {
              const input = {
                type: get(obj, 'type', ''),
                gridPlacement: get(obj, 'gridPlacement','')
              }
              const learningSlideIds = []
              if(get(learningSlide,'id','')) {
                learningSlideIds.push(get(learningSlide,'id',''))
              }
              const res = await addLearningSlideContent(input, learningSlideIds)
              let addedContentId = ''
              if (res) {
                if (res.addLearningSlideContent) {
                  addedContentId = get(res,'addLearningSlideContent.id','')
                }
              }
              await getInputforAddition(requiredValue, obj, addedContentId, learningSlide)
            } else {
              const input = await getInputforAddition(requiredValue, obj)
              const learningSlideIds = []
              if(get(learningSlide,'id','')) {
                learningSlideIds.push(get(learningSlide,'id',''))
              }
              await addLearningSlideContent(input, learningSlideIds)
            }
          }
        }))
      }
      }
     } else if(checkArr.length === slideContentArr.length && get(learningSlide, 'layoutType', '') ==='nRowspan') {
      if (get(learningSlide, 'GridAdditon',false) === true || get(learningSlide, 'layoutType', '') ==='nRowspan') {
        const input = {
          layoutType: get(learningSlide, 'layoutType', '')
        }
        if(get(learningSlide,'id','')) {
          const id=get(learningSlide,'id','')
          await updateLearningSlide(id, input)
        }
        const learningSlideIds = []
        if(get(learningSlide,'id','')) {
          learningSlideIds.push(get(learningSlide,'id',''))
        }    
        if(checkArr && checkArr.length>0) {
          await Promise.all(checkArr.map(async (obj) => {
            const requiredValue = get(obj, 'type', '')
            if (get(obj, 'id')) {
              if (requiredValue === slideContentType.image || requiredValue === slideContentType.video) {
                const input = {
                  type: get(obj, 'type', ''),
                  gridPlacement: get(obj, 'gridPlacement'),
                }
                const learningSlideIds = []
                if(get(learningSlide,'id','')) {
                  learningSlideIds.push(get(learningSlide,'id',''))
                }
                await updateLearningSlideContent(obj.id, input, learningSlideIds)
                await getInput(requiredValue, obj)
              } else {
                const input = await getInput(requiredValue, obj)
                const learningSlideIds = []
                if(get(learningSlide,'id','')) {
                  learningSlideIds.push(get(learningSlide,'id',''))
                }
                if(get(obj,'id','')) {
                  await updateLearningSlideContent(get(obj,'id',''), input, learningSlideIds)
                }
              }
            }
          }))
        }
      //   if(checkArr && checkArr.length>0) {
      //    await Promise.all(checkArr.map(async (obj) => {
      //     const requiredValue = get(obj, 'type', '')
      //     if (get(obj, 'shouldAdd',false) === true) {
      //       if (requiredValue === slideContentType.image  || requiredValue === slideContentType.video ) {
      //         const input = {
      //           type: get(obj, 'type', ''),
      //           gridPlacement: get(obj, 'gridPlacement','')
      //         }
      //         const learningSlideIds = []
      //         if(get(learningSlide,'id','')) {
      //           learningSlideIds.push(get(learningSlide,'id',''))
      //         }
      //         const res = await addLearningSlideContent(input, learningSlideIds)
      //         let addedContentId = ''
      //         if (res) {
      //           if (res.addLearningSlideContent) {
      //             addedContentId = get(res,'addLearningSlideContent.id','')
      //           }
      //         }
      //         await getInputforAddition(requiredValue, obj, addedContentId, learningSlide)
      //       } else {
      //         const input = await getInputforAddition(requiredValue, obj)
      //         const learningSlideIds = []
      //         if(get(learningSlide,'id','')) {
      //           learningSlideIds.push(get(learningSlide,'id',''))
      //         }
      //         await addLearningSlideContent(input, learningSlideIds)
      //       }
      //     }
      //   }))
      // }
      }
     } 
    else  {
      const checkArr = get(learningSlide, 'slideContents', [])
      if(checkArr && checkArr.length>0)
       await Promise.all(checkArr.map(async (obj) => {
         const requiredValue = get(obj, 'type', '')
        if (get(obj, 'shouldUpdate') === undefined) {
          if (requiredValue === slideContentType.image || requiredValue === slideContentType.video) {
            const input = {
              type: get(obj, 'type', ''),
              gridPlacement: get(obj, 'gridPlacement'),
            }
            const learningSlideIds = []
            if(get(learningSlide,'id','')) {
              learningSlideIds.push(get(learningSlide,'id',''))
            }
            await updateLearningSlideContent(obj.id, input, learningSlideIds)
            await getInput(requiredValue, obj)
          } else {
            const input =  await getInput(requiredValue, obj)
            const learningSlideIds = []
            if(get(learningSlide,'id','')) {
              learningSlideIds.push(get(learningSlide,'id',''))
            }
            if(get(obj,'id','')) {
              await updateLearningSlideContent(get(obj,'id',''), input, learningSlideIds)
            }
          }
        }
      }))
      const inputToSend = {
        ...gridInput,
        type: get(learningSlide, 'type', '')
      }
      await updateLearningSlide(get(learningSlide, 'id'), inputToSend)
    }
  }

  const submitData = async(slide, status) => {
    const learningObjectiveId = get(props,'match.params.learningObjectiveId','')
    const learningObjectivesIds = [learningObjectiveId]
    const input = {
      type: get(slide, 'type', ''),
      name: get(slide, 'name', ''),
    }
    if(get(slide, 'type', '') === learningSlideType.googleSlides) {
      if (get(slide, 'googleSlideLink', '')) {
        input.googleSlideLink = get(slide, 'googleSlideLink', '')
      }
      if(get(slide,'id','')) {
        const id = get(slide, 'id', '')
        await updateLearningSlide(id, input)
        openNotification('', 'slideAdded')
      }
      else{
        if (get(slide, 'status')) {
          input.status = get(slide, 'status')
        }
        if (get(slide,'order','')) {
          input.order = get(slide,'order','')
        }
        if (get(slide, 'googleSlideLink', '')) {
          input.googleSlideLink = get(slide, 'googleSlideLink', '')
        }
        await addLearningSlide(input, [learningObjectiveId]) 
      }
      return
    }
    if (get(slide, 'type', '') === learningSlideType.practiceQuestion) {
      if (get(slide, 'status')) {
        input.status = get(slide, 'status')
      }
      if (get(slide, 'id')) {
        if (get(slide, 'practiceQuestions', []).length === 1) {
          const AddedObj = get(slide, 'practiceQuestions', [])[0]
          const practiceQuestionsIds = []
          if(get(AddedObj,'id','')) {
            practiceQuestionsIds.push(get(AddedObj,'id',''))
          }
          if(get(slide,'id','')) {
            await updateLearningSlide(get(slide,'id',''), input, learningObjectivesIds, practiceQuestionsIds)
          }
        } else if (get(slide, 'id','') && get(slide, 'practiceQuestions', []).length>1) {
          const AddedObj = get(slide, 'practiceQuestions', [])[0]
          const practiceQuestionsIds = []
          const practiceArray = get(slide, 'practiceQuestions', [])
          if(get(slide,'id','') && get(AddedObj,'id','')) {
            await removeLearningSlideQuestion(slide.id, AddedObj.id)
          }
          if (practiceArray.length>0) {
            const toBeAdded = practiceArray[1]
            if(get(toBeAdded,'id','') && get(slide,'id','')) {
              practiceQuestionsIds.push(toBeAdded.id)
              await updateLearningSlide(get(slide,'id',''), input, learningObjectivesIds, practiceQuestionsIds)
            }
          }
        }
      } else {
        const topicIds = []
        const courseIds = []
        if (learningObjectiveObj) {
          if (get(learningObjectiveObj, 'topics', []).length>0) {
            const topicArr = get(learningObjectiveObj, 'topic', [])
            topicArr.map((obj) => {
              topicIds.push(obj.id)
            })
          }
          if (get(learningObjectiveObj, 'courses', []).length>0) {
            const courseArr = get(learningObjectiveObj, 'courses', [])
            courseArr.map((obj) => {
              courseIds.push(obj.id)
            })
          }
        }
        const practiceQuestionsIds = []
        const AddedObj = get(slide, 'practiceQuestions', [])[0]
        if(get(AddedObj,'id','')) {
          practiceQuestionsIds.push(AddedObj.id)
        }
        if (get(slide, 'order')) {
          input.order = get(slide, 'order')
        }
        if (get(slide, 'status')) {
          input.status = get(slide, 'status')
        }
        await addLearningSlide(input, learningObjectivesIds, practiceQuestionsIds, topicIds, courseIds)
      }
      return
    }
    if (get(slide, 'type', '') === learningSlideType.grid) {
      await handleGridUpdate(slide, status, input)
      return
    }
  }

  async function handleDataSubmit() {
    for (let index=0; index<listArr.length; index++) {
      const slide = listArr[index]
      const status = get(slide, 'id') ? 'Redirect' : 'Final'
      await submitData(slide, status)
    }
  }

  const PrintArr=(isRequiredArr,checkingArr)=>{
    if(isRequiredArr.length===checkingArr.length && checkingArr.length!==0) {
          openNotification('', 'success')
          props.history.push('/content-learningObjective')
        }
  }

  const validateSlides = async() => {
    let errorFound = false
    for (let index=0; index<listArr.length; index++) {
      errorFound = false
      const slideObj = listArr[index]
      if (get(slideObj, 'name','') === null || get(slideObj, 'name','') === '') {
        openNotification('Name', 'error')
        await handleSlide(slideObj, index)
        errorFound = true
        return errorFound
      }
      if (get(slideObj, 'type','') === null || get(slideObj, 'type','') === '') {
        openNotification('Type', 'error')
        await handleSlide(slideObj, index)
        errorFound = true
        return errorFound
      }
      if (get(slideObj, 'type','') === learningSlideType.googleSlides && (!get(slideObj, 'googleSlideLink','') || get(slideObj, 'googleSlideLink','') === '' || get(slideObj, 'googleSlideLink','') === null)) {
        openNotification('Google Slide Link', 'error')
        await handleSlide(slideObj, index)
        errorFound = true
        return errorFound
      }
      if (get(slideObj, 'type','') === learningSlideType.grid && (get(slideObj, 'layoutType','') === null || get(slideObj, 'layoutType','') === '')) {
        openNotification('Layout Type', 'error')
        await handleSlide(slideObj, index)
        errorFound = true
        return errorFound
      }
      if (get(slideObj, 'type', '') === learningSlideType.grid) {
        if (get(slideObj, 'id')) {
          const arr = await fetchSlideContents(slideObj.id)
          const checkArr = arr.learningSlideContents
          if(checkArr && checkArr.length>0)
              await Promise.all(checkArr.map(async(obj) => {
              if (get(obj, 'type', '') === 'code') {
                if (get(obj, 'showCodeInput', '') === true) {
                  if (obj.codeInput === null || obj.codeInput === '') {
                    await handleSlide(slideObj, index)
                    openNotification('Code Input', 'error')
                    errorFound = true
                    return errorFound
                  }
                }
                if (get(obj, 'showCodeOutput', '') === true) {
                  if (obj.codeOutput === null || obj.codeOutput === '') {
                    await handleSlide(slideObj, index)
                    openNotification('Code Output', 'error')
                    errorFound = true
                    return errorFound
                  }
                }
              } else {
                const requiredValue = getValueTypeForFinal(get(obj, 'type', ''))
                if (obj[requiredValue] === null || obj[requiredValue] === '' || obj[requiredValue] === undefined) {
                  await handleSlide(slideObj, index)
                  openNotification(requiredValue, 'error')
                  errorFound = true
                  return errorFound
                }
              }
            }))
        } else {
          return errorFound
        }
      }
      if (get(slideObj, 'type', '') === learningSlideType.practiceQuestion && get(slideObj, 'practiceQuestions', []).length === 0) {
        await handleSlide(slideObj)
        openNotification(requiredValue, 'error')
        errorFound = true
        return errorFound
      }
    }
    return errorFound
  }

  async function handleFinalSubmit(arr, exit=false) {
    setSubmitButton(true)
    const isRequiredFinal = []
    const validationFailed = await validateSlides()
    if (validationFailed) {
      return
    } else {
      await handleDataSubmit()
    }
    await fetchSlides(props.match.params.learningObjectiveId)
    const obj = listArr[listArr.length - 1]
    // if (get(obj, 'type') !== learningSlideType.grid) {
    //   console.log('afterqueryupdate ', obj);
    //   await handleSlide(obj, listArr.length - 1)
    // }
    setSubmitButton(false)

    if(exit){
      props.history.push('/content-learningObjective')
    }
    setFinalSubmit(false)
    setIsRequiredArr([])
  }

  useEffect(() => {
    setQuesArr(props.questionBanks.toJS())
  }, [props.isPracticeQuestionsFetched])


  const firstAdd = React.useRef(true)

  useEffect(() => {
    const { isSlideDataAdded, isSlideDataAddLoading } = props
    if (firstAdd.current) {
      firstAdd.current = false
      return
    }
    if (isSlideDataAdded && !isSlideDataAddLoading) {
      openNotification('', 'slideAdded')
    }
  }, [props.isSlideDataAdded])

  useEffect(() => {
    const { isSlideDataAdded, isSlideDataAddLoading, isSlidesFetched, isSlideContentDataAdded, isSlideContentDataAddLoading } = props
    if (isSlideDataAdded && !isSlideDataAddLoading && isSlidesFetched && isSlideContentDataAdded && !isSlideContentDataAddLoading) {
      const obj = listArr[listArr.length - 1]
      handleSlide(obj, listArr.length - 1)
    }
  }, [props.isSlideDataAdded, props.isSlideDataAddLoading, props.isSlideContentDataAdded, props.isSlideContentDataAddLoading])

  useEffect(() => {
    setlearningObjectiveObj(props.learningObjective.toJS())
  }, [props.isLearningObjectiveFetched])

  const isRequired = () => <span style={{ fontSize: '12px', color: 'red' }}>
      is Required
                           </span>
  const isRequiredHeading = () => <span style={{ fontSize: '13px', color: 'red', display: 'flex', alignItems: 'center', margin: '4px 0px 0px 6px' }}>
      Please fill all the details
                                  </span>
  const checkEmpty = (key) => {
    if (key === 'name') {
      if (duplicate === true || get(learningSlide,'name','') === null || get(learningSlide,'name','') === '' || get(learningSlide,'name','').trim() === '') {
        return true
      }
    } else {
      if (learningSlide[key] === null || learningSlide[key] === '') {
        return true
      }
      return false
    }
  }

  useEffect(() => {
    async function getData() {
      await fetchSlides(props.match.params.learningObjectiveId)
    }
    getData()
    // props.isSlidesUpdated, props.isSlideDataAdded, props.isSlideContentDataAdded, props.isSlidesContentUpdated, props.isSlideContentDataDeleted
  }, [])
  useEffect(() => {
    const loId = props.match.params.learningObjectiveId
    if (!props.topicTitle) {
      fetchContentLearningObjective({ loId })
    }
  }, [])
  useEffect(() => {
    async function getData(){
      fetchLearningObjective(props.match.params.learningObjectiveId)
      await fetchPracticeQuestions(props.match.params.learningObjectiveId)
      handleSlide(listArr[Index], Index)
    }
    getData()
  }, [])
  useEffect(() => {
    const { isSlideContentDataDeletedLoading, isSlideContentDataDeleted } = props
    if (!isSlideContentDataDeletedLoading && isSlideContentDataDeleted) {
      handleSlide(learningSlide, get(learningSlide, 'order','') - 1)
    }
  }, [props.isSlideContentDataDeleted, props.isSlideContentDataDeletedLoading])
  useEffect(() => {
    let { learningSlides } = props
    learningSlides = (learningSlides && learningSlides.toJS()) || []
    setlistArr(learningSlides)
    const obj = learningSlides[learningSlides.length - 1]
    handleSlide(obj, learningSlides.length - 1)
  }, [props.isSlidesFetched])

  useEffect(() => {
    if(submitButton) return
    getSlideContent()

  }, [props.isSlidesContentFetched])
  const getSlideContent = () => {
    let learningSlidecontentArr = props.learningSlideContents && props.learningSlideContents.toJS() || []
    // learningSlidecontentArr = learningSlidecontentArr.filter(slide => get(slide, 'connectedls', []).map(ls => get(ls, 'id')).includes(get(learningSlide, 'id')))
    const key = 'slideContents'
    let newList=[]
      if(get(learningSlide,'layoutType','')==='nRowspan') {
        if(learningSlidecontentArr.length>0) {
          for(let i=0;i<learningSlidecontentArr.length;i++) {
            let arr=learningSlidecontentArr.filter((obj)=>{return obj.gridPlacement===`${i}0`})
            let obj=arr[0]
            newList=[
              ...newList,
              {
              ...obj,
              gridPlacement:`${i}0`
              }
            ]
          }
        }
      }
      if(newList.length>0) {
        if (get(learningSlide, 'id','')) {
          setSlideContentArr(newList)
        }
        const newComponent={...learningSlide,slideContents: newList}
        setLearningSlide(newComponent)
        setlistArr([...listArr.filter((obj) => obj.order !== newComponent.order), newComponent])
      } else {
        if (get(learningSlide, 'id','')) {
          setSlideContentArr(learningSlidecontentArr)
        }
        const newComponent={...learningSlide,slideContents: learningSlidecontentArr}
        setLearningSlide(newComponent)
        setlistArr([...listArr.filter((obj) => obj.order !== newComponent.order), newComponent])
      }
      const arr=[...isRequiredArr]
      setIsRequiredArr(arr)
  }
  const Helper=async(valueToSet,key,type,slideContents,number)=>{
    let content = {}
    if(slideContents) {
      content = slideContents.filter((el) => get(el, 'gridPlacement') === number)[0]
    }
    let slideContentsArray=get(learningSlide,'slideContents',[])
    let updatedSlideContents=[] 
    if(slideContentsArray.length>0) {
      updatedSlideContents = slideContentsArray.map((slideContent, i) => {
        if (get(slideContent, 'gridPlacement') === get(content, 'gridPlacement') && type!== 'codeEditor') {
          if(key==='type' && (valueToSet ==='image' || valueToSet==='video')) {
              return {
                ...slideContent,
                [key]: valueToSet,
                media:null
            }
          }
          if(key==='showCodeOutput' && valueToSet ===false) {
            return {
              ...slideContent,
              codeOutput:''
          }
        }
        if(key==='showCodeInput' && valueToSet ===false) {
          return {
            ...slideContent,
            codeInput:''
        }
      }
            return {
                ...slideContent,
                [key]: valueToSet,
            }
        } else if (get(slideContent, 'gridPlacement') === get(content, 'gridPlacement') && type=== 'codeEditor') {
          return {
            ...slideContent,
            codeEditorConfig: {
              ...get(slideContent,'codeEditorConfig'),
              [key]:valueToSet
            } ,
         }
        }
        return slideContent
    })
    }
    let newComponent={}
    newComponent={...learningSlide,slideContents: updatedSlideContents}
    setlistArr([...listArr.filter((obj) => obj.order !== newComponent.order), newComponent])
    setLearningSlide(newComponent)
}
const SetPracticeData=(valueToSet,index)=>{
  let UpdatedpracticeQuestions=get(learningSlide,'practiceQuestions',[])
  UpdatedpracticeQuestions[index]=valueToSet
  let newComponent={}
  newComponent={...learningSlide,practiceQuestions: UpdatedpracticeQuestions}
  setLearningSlide(newComponent)
  setlistArr([...listArr.filter((obj) => obj.order !== newComponent.order), newComponent])
}

const setGoogleSlideLink = (googleSlideLink) => {
  if (!googleSlideLink) {
    openNotification('Slide Link', 'error')
    return
  }
  let newComponent={}
  newComponent={...learningSlide,googleSlideLink}
  setLearningSlide(newComponent)
  setlistArr([...listArr.filter((obj) => obj.order !== newComponent.order), newComponent])
}
  return <>
  <TopicNav activeTab={topicJourneyRoutes.contentLearningSlides} slideOption={'LearningSlide'} loNav />
    <Main>
      <HeadingTag>Add Learning Slides</HeadingTag>
      <SlideHolder>
        <InnerSlideHeading>Name of Learning Slide</InnerSlideHeading>
        <ListContainer>
          {
            listArr.length>0 ? (
                
                sortBy(listArr, 'order').map((slide, index) => <SlideDivContainer>
                  <Popconfirm
                    title='Do you want to delete this Slide?'
                    placement='topRight'
                    onConfirm={() => { handleDelete(index, slide) }}
                    okText='Yes'
                    cancelText='Cancel'
                    key='delete'
                    overlayClassName='popconfirm-overlay-primary'
                  >
                  <CrossHolder>
                    <Cross />
                  </CrossHolder>
                  </Popconfirm>
                <Slide
                  onClick={() => {
                      if (get(slide, 'order') !== get(learningSlide, 'order')) {
                         handleSlide(slide, index)
                      }
                    }}
                  checked={learningSlide ? get(slide, 'order') === get(learningSlide, 'order') : index === 0 ? handleSlide(slide, index) : null }
                >
                <SliderInnerText checked={get(slide, 'order') === get(learningSlide, 'order')}>
                  {get(slide, 'name', '')}
                  </SliderInnerText>
                </Slide>
                </SlideDivContainer>
                )
            ) : ''
          }
          <AddButtonHolder onClick={() => { addSlide() }}>
            <PlusHolder><AddPlus /></PlusHolder>
            <PlusButtonText>Add Another Slide</PlusButtonText>
          </AddButtonHolder>
        </ListContainer>
        {props.isSlidesFetchLoading || props.isSlidesContentFetchLoading || isMediaUploaded || finalSubmit  ? <SpinEncloser><Spin spinning /> </SpinEncloser> :(<SlideOptionDiv>
        {
        learningSlide !== undefined ? <InfoHolder>
          <HeadingTag>Learning Slide Options</HeadingTag>
          <FilterHolder>
            <NameDiv>
                  <FilterText>Name of Slide</FilterText>
                  <InputHolder>
                  <StyledInput
                    placeholder='Type here'
                    onChange={(e) => {
                      handleNameInput(e.target.value)
                  }}
                    value={get(learningSlide, 'name', '')}
                  />
                  {checkEmpty('name') === true && showMsg === true ? isRequired() : ''}
                  {duplicate === true ? <ExistsText>Name already exists</ExistsText> : ''}
                  </InputHolder>
            </NameDiv>
            <NameDiv>
                  <FilterText>Type of slide</FilterText>
                  <SelectHolder>
                  <StyledSelect onChange={(value) => { handleSlideType(value) }} value={get(learningSlide, 'type', '')}>
                  <StyledSelect.Option value='grid'>Grids</StyledSelect.Option>
                  <StyledSelect.Option value='practiceQuestion'>PQ</StyledSelect.Option>
                  <StyledSelect.Option value='googleSlides'>Google Slides</StyledSelect.Option>
                  </StyledSelect>
                  {checkEmpty('type') === true && showMsg === true ? isRequired() : ''}
                  </SelectHolder>
            </NameDiv>
            <NameDiv>
                  {get(learningSlide, 'type', '') === learningSlideType.grid ? <>
                  <SelectHolder>
                    <FilterText>Grid Style</FilterText>
                    {checkEmpty('layoutType') === true && showMsg === true ? isRequired() : ''}
                  </SelectHolder>
                  <SvgInfoEncloser>
                    <SvgDiv>
                    {gridOptions.map((obj) => <SvgHolder 
                      onClick={() => {
                          handleSvgClick(obj.key,learningSlide)
}}
                      ticked={get(learningSlide, 'layoutType', '') === obj.key}
                    >
                        {get(learningSlide, 'layoutType', '') === obj.key ? <CheckMarkHolder>
                          <CheckMark />
                                                                            </CheckMarkHolder> : ''}
                        {obj.iconRenderrer()}
                                              </SvgHolder>)}
                    </SvgDiv>
                  </SvgInfoEncloser> </> : ''}
            </NameDiv>
          </FilterHolder>
          <HeadingHolder>
          <HeadingTag>Learning Slide Content</HeadingTag>
          {isRequiredArrHeading.length>0 ? isRequiredHeading() : ''}
          </HeadingHolder>
          {get(learningSlide, 'type', '') ? <Grid gridOption={get(learningSlide, 'layoutType', '')}
            slideType={get(learningSlide, 'type', '')}
            componentId={learningSlide.order}
            learningSlide={learningSlide}
            slideContents={get(learningSlide, 'slideContents', [])}
            practiceQuestions={quesArr}
            learningSlides={listArr}
            // updateLearningSlideContent={(contents) => {
            // }}
            Helper={Helper}
            SetPracticeData={SetPracticeData}
            setLearningSlide={setLearningSlide}
            listArr={listArr}
            setlistArr={setlistArr}
            setGoogleSlideLink={setGoogleSlideLink}
          /> : null}
          <ButtonHolders style={{ justifyContent: 'flex-end' }}>
            <SaveButtons>
              <SaveButton onClick={async() => { await handleFinalSubmit(isRequiredArr) }}>Save &amp; Next</SaveButton>
            </SaveButtons>
          </ButtonHolders>
                                </InfoHolder> : <></>
        }
        </SlideOptionDiv>)}
      </SlideHolder>
      </Main>
  </>
}
export default ContentLearningSlides
