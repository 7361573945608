import { LoadingOutlined } from '@ant-design/icons'
import { Checkbox, Switch, Select, notification } from 'antd'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { CloseIcon } from '../../../SchoolOnBoarding.style'
import { AntModal, CheckBoxTextContainer, CurrentClassName, FlexContainer, InputContainer, InputLabel, PrimaryButton, StudentsList, StyledSelect, ToggleContainer } from '../Classrooms.styles'

const { Option } = Select

function EditClassroom({ editClassroomModalVisible, handleClose, handleDeleteSection, coursePackages, schoolTrainers,
    selectedGradeSection, classStudents, handleEditClassroomDetails, addBatchLoading, schoolClassesDeleteStatus,
    fetchingCoursePackage, fetchingClassroomTeachers, classGradeList, fetchingStudentProfile }) {
    const [classDetails, setClassDetails] = useState({
        displayName: '',
        coursePackage: '',
        mentor: '',
        students: [],
        batchId: '',
        assignMultipleTeacher: false,
        multipleTeacherMap: [],
        coursePackageSameForAllClasses: false,
        teachersSameForAllClasses: false,
        isSameForAllSelected: false,
        isCoursePackageChanged: false,
    })
    const [currentStep, setCurrentStep] = useState(1)
    const [coursePackageCourses, setCoursePackageCourses] = useState([])
    const [searchValue, setSearchValue] = useState('')

    const filteredStudents = classStudents.length && classStudents.filter(item => item.studentName.toLowerCase().includes(searchValue && searchValue.toLowerCase()))

    useEffect(() => {
        const { section } = selectedGradeSection
        const batchData = get(section, 'batchData', [])
        const obj = { ...classDetails }
        if (batchData.length) {
            let masterBatch = findMasterBatch(batchData)
            if (!masterBatch) {
                masterBatch = batchData[0]
            }
            obj.batchId = get(masterBatch, 'id')
            obj.displayName = get(masterBatch, 'classroomTitle')
            obj.coursePackage = get(masterBatch, 'coursePackage.id')
            obj.mentor = get(masterBatch, 'allottedMentor.id')
            const studentList = get(masterBatch, 'studentsList', [])
            const studentIds = studentList.length ? studentList.map(item => get(item, 'id')) : []
            obj.students = studentIds
            setClassDetails(obj)
        }
    }, [])

    useEffect(() => {
        if (classDetails.assignMultipleTeacher) {
            const classDetailsTemp = {...classDetails}
            const courseTeacherMapTemp = getMultipleTeacherMap()
            classDetailsTemp.multipleTeacherMap = courseTeacherMapTemp
            setClassDetails(classDetailsTemp)
        }
    }, [classDetails.assignMultipleTeacher, classDetails.coursePackage])

    const getBatchCourses = (batch) => {
        const componentRule = get(batch, 'coursePackageTopicRule', [])
        let courses = []
        for (const course of componentRule) {
            const tempCoruse = get(course, 'topic.courses[0].title')
            if (!(courses.includes(tempCoruse))) {
                courses.push(tempCoruse)
            }
        }
        return courses
    }

    const getMultipleTeacherMap = () => {
        const { section } = selectedGradeSection
        const batchData = get(section, 'batchData', [])
        const courseTeacherMapTemp = []
        let coursePackageCoursesTemp = []
        if (batchData.length) {
            let masterBatch = findMasterBatch(batchData)
            if (!masterBatch) {
                masterBatch = batchData[0]
            }
            const prevCoursePackage = get(masterBatch, 'coursePackage.id')
            if (prevCoursePackage !== get(classDetails, 'coursePackage')) {
                const coursePackageId = get(classDetails, 'coursePackage')
                const selectedCoursePackage = coursePackages.find(item => get(item, 'id') === coursePackageId)
                const courses = get(selectedCoursePackage, 'coursesData', [])
                courses.length && courses.forEach(item => {
                    const obj = {}
                    obj.course = get(item, 'title')
                    obj.teacher = get(batchData, '[0].allottedMentor.name')
                    courseTeacherMapTemp.push(obj)
                })
                coursePackageCoursesTemp = courses
            } else {
                if (batchData.length === 1) {
                    const courses = get(batchData, '[0].coursePackage.courses', [])
                    courses.length > 0 && courses.forEach(course => {
                        let obj = {}
                        obj.course = get(course, 'title')
                        obj.teacher = get(batchData, '[0].allottedMentor.name')
                        courseTeacherMapTemp.push(obj)
                    })
                    coursePackageCoursesTemp = courses
                } else {
                    for (const batch of batchData) {
                        const teacher = get(batch, 'allottedMentor.name')
                        const courses = getBatchCourses(batch)
                        const inherited = get(batch, 'inheritedFrom.id', null)
                        courses.length && courses.forEach(course => {
                            let obj = {}
                            obj.course = course
                            obj.teacher = teacher
                            obj.inherited = inherited
                            courseTeacherMapTemp.push(obj)
                            coursePackageCoursesTemp.push(course)
                        })
                    }
                }
            }
        } else {
            const coursePackageId = get(classDetails, 'coursePackage')
            const selectedCoursePackage = coursePackages.find(item => get(item, 'id') === coursePackageId)
            const courses = get(selectedCoursePackage, 'coursesData', [])
            courses.length && courses.forEach(item => {
                const obj = {}
                obj.course = get(item, 'title')
                obj.teacher = get(classDetails, 'mentor')
                courseTeacherMapTemp.push(obj)
            })
            coursePackageCoursesTemp = courses
        }
        setCoursePackageCourses(coursePackageCoursesTemp)
        return courseTeacherMapTemp
    }

    const findMasterBatch = (batchData) => {
        const masterBatch = batchData.find(item => get(item, 'inheritedFrom') === null)
        return masterBatch
    }

    const resetState = () => {
        setClassDetails({
            displayName: '',
            coursePackage: '',
            mentor: '',
            students: [],
            batchId: '',
            assignMultipleTeacher: false,
            multipleTeacherMap: [],
            coursePackageSameForAllClasses: false,
            teachersSameForAllClasses: false,
            isSameForAllSelected: false,
        })
        setCurrentStep(1)
        setCoursePackageCourses([])
    }

    const checkIfAssignMultipleTeacherDisabled = () => {
        const { section } = selectedGradeSection
        const batchData = get(section, 'batchData', [])
        if (!batchData.length && !get(classDetails, 'coursePackage')) {
            return true
        }
    }

    const saveSubmitButtonClicked = () => {
        const multipleTeacherMapInitial = getMultipleTeacherMap()
        const { displayName, coursePackage, mentor, students, batchId, multipleTeacherMap } = classDetails;
        if (displayName && coursePackage && mentor) {
            if (!displayName.trim()) {
                notification.error({ message: 'Please remove empty spaces from classroom display name' })
            } else {
                handleEditClassroomDetails({ classDetails, multipleTeacherMapInitial, coursePackageCourses })
            }
        } else {
            notification.error({ message: 'Please Fill all the details' })
        }
    }

    const getBatch = (teacher, oldTeacherName) => {
        const { section } = selectedGradeSection
        const batchData = get(section, 'batchData', [])
        const filteredBatch = batchData.filter(item => get(item, 'allottedMentor.name') === teacher)
        if (filteredBatch && filteredBatch.length > 0) {
            return filteredBatch[0]
        } else {
            const filteredBatchByOldTeacher = batchData.filter(item => get(item, 'allottedMentor.name') === oldTeacherName)
            return filteredBatchByOldTeacher && filteredBatchByOldTeacher.length > 0 ? filteredBatchByOldTeacher[0] : batchData[0]
        }
    }

    const checkNullOnChange = (i, teacherMap) => {
        let inherited = null
        const masterExist = teacherMap.filter((item, index) => i !== index && get(item, 'inherited') === null)
        if (get(teacherMap[i], 'inherited') === null) {
            if (masterExist.length > 0) {
                const batch = getBatch(get(teacherMap[i], 'teacher'))
                inherited = get(batch, 'id')
            } else {
                inherited = null
            }
        } else {
            inherited = get(teacherMap[i], 'inherited')
        }
        return inherited
    }

    const handleChange = (i, teacher) => {
        const { section } = selectedGradeSection
        const batchData = get(section, 'batchData', [])
        const teacherToAdd = schoolTrainers.filter(item => get(item, 'id') === teacher)
        const teacherName = get(teacherToAdd, '[0].name')
        const classDetailsTemp = {...classDetails}
        if (teacherName && batchData) {
            const courseTeacherMapTemp = classDetails.multipleTeacherMap
            if (batchData.length > 1) {
                const getInherited = checkNullOnChange(i, courseTeacherMapTemp)
                courseTeacherMapTemp[i].inherited = getInherited
            }
            courseTeacherMapTemp[i].teacher = teacherName
            classDetailsTemp.multipleTeacherMap = courseTeacherMapTemp
            setClassDetails(classDetailsTemp)
        }
    }

    const getRelatedSections = () => {
        const { section } = selectedGradeSection
        let getSections = []
        if (classGradeList.length) {
            for (const item of classGradeList) {
                const allSections = get(item, 'sections', [])
                for(const classSection of allSections) {
                    if (get(classSection, 'id') === get(section, 'id')) {
                        getSections = allSections
                        break
                    }
                }
                if (getSections.length) {
                    break
                }
            }
        }
        return getSections
    }

    const showSameForAllSection = () => {
        const allSections = getRelatedSections()
        let isBatchExist = false
        if (allSections.length) {
            for (const section of allSections) {
                if (get(section, 'batchData', []).length) {
                    isBatchExist = true
                    break
                }
            }
        }
        return isBatchExist
    }

    const renderAssignMultipleTeacherBlock = () => {
        const { multipleTeacherMap } = classDetails
        return (
            <FlexContainer
                style={{
                    margin: '15px 0',
                    maxHeight: '180px',
                    overflow: 'auto',
                    flexWrap: 'nowrap'
                }}
                direction='column'
                width='100%'
            >
                {multipleTeacherMap.length ? multipleTeacherMap.map((courseTeacher, index) => (
                    <FlexContainer align='center' width='100%' style={{ marginBottom: '5px' }}>
                        <InputLabel style={{ marginRight: '40px', minWidth: '50%' }}>{get(courseTeacher, 'course')}</InputLabel>
                        <StyledSelect
                            style={{ width: '150px' }}
                            value={get(courseTeacher, 'teacher')}
                            onChange={(value) => handleChange(index, value)}
                        >
                            {
                                schoolTrainers && schoolTrainers.map(({ name, id }) =>
                                    <Option
                                        key={id}
                                        value={id}
                                    >{name}
                                    </Option>
                                )
                            }
                        </StyledSelect>
                    </FlexContainer>
                )) : null}
            </FlexContainer>
        )
    }

    const renderBasicDetails = () => {
        return (
            <>
                <FlexContainer
                    direction='column'
                    width='100%'
                    style={{ padding: '20px 0', borderTop: '1.375px solid #E8E8E8' }}
                >
                    <InputContainer>
                        <InputLabel>Classroom Display Name</InputLabel>
                        <input
                            placeholder='Display Name'
                            value={get(classDetails, 'displayName')}
                            onChange={(e) => {
                                const obj = { ...classDetails }
                                obj.displayName = e.target.value
                                setClassDetails(obj)
                            }}
                        />
                    </InputContainer>
                    <FlexContainer direction='column' width='100%' style={{ marginBottom: '15px' }}>
                        <InputLabel>Course Package</InputLabel>
                        <StyledSelect
                            style={{ width: '100%' }}
                            showSearch
                            placeholder='Select a Course Package'
                            optionFilterProp='children.props.children'
                            value={get(classDetails, 'coursePackage')}
                            onChange={(value) => {
                                const obj = { ...classDetails }
                                obj.coursePackage = value
                                obj.isCoursePackageChanged = true
                                setClassDetails(obj)
                            }}
                            loading={fetchingCoursePackage}
                            filterOption={(input, option) =>
                                typeof get(option, 'props.children', '') === 'string'
                                    ? get(option, 'props.children', '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    : Array.isArray(get(option, 'props.children'))
                                    ? get(option, 'props.children[0]').toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    : false
                                }
                        >
                            {
                                coursePackages && coursePackages.map(({ title, id, internalName }) =>
                                    <Option
                                        key={id}
                                        value={id}
                                    >
                                        {title}
                                        {internalName ? <>
                                            <br /><span
                                            style={{
                                                fontSize: '12px',
                                                color: '#8c8c8c',
                                            }}
                                            >
                                            {internalName}
                                        </span>
                                        </> : null}
                                    </Option>
                                )
                            }
                        </StyledSelect>
                        {!showSameForAllSection() ? (
                            <CheckBoxTextContainer
                                style={{ marginTop: '8px' }}
                                checked={get(classDetails, 'coursePackageSameForAllClasses')}
                                onChange={e => {
                                    const obj = { ...classDetails }
                                    obj.coursePackageSameForAllClasses = e.target.checked
                                    obj.isSameForAllSelected = e.target.checked
                                    setClassDetails(obj)
                                }}
                            >
                                Apply same to all sections
                            </CheckBoxTextContainer>
                        ) : null}
                    </FlexContainer>
                    <FlexContainer direction='column' width='100%' style={{ marginBottom: '15px' }}>
                        <InputLabel>Assign Teachers</InputLabel>
                        <StyledSelect
                            style={{ width: '100%' }}
                            showSearch
                            placeholder='Select Trainier'
                            optionFilterProp='children'
                            value={get(classDetails, 'mentor')}
                            onChange={(value) => {
                                const obj = { ...classDetails }
                                obj.mentor = value
                                setClassDetails(obj)
                            }}
                            loading={fetchingClassroomTeachers}
                            filterOption={(input, option) =>
                            option.props.children
                                ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                : false
                            }
                        >
                            {
                                schoolTrainers && schoolTrainers.map(({ name, id }) =>
                                    <Option
                                        key={id}
                                        value={id}
                                    >{name}
                                    </Option>
                                )
                            }
                        </StyledSelect>
                        <ToggleContainer>
                            <Switch
                                size='small'
                                value={get(classDetails, 'assignMultipleTeacher')}
                                onChange={(value) => {
                                    const classDetailsTemp = { ...classDetails }
                                    classDetailsTemp.assignMultipleTeacher = value
                                    setClassDetails(classDetailsTemp)
                                }}
                                disabled={checkIfAssignMultipleTeacherDisabled()}
                            />
                            <p>Assign Multiple Teachers</p>
                        </ToggleContainer>
                        {get(classDetails, 'assignMultipleTeacher') ? (
                            <>
                                {renderAssignMultipleTeacherBlock()}
                            </>
                        ) : null}
                        {!showSameForAllSection() ? (
                            <CheckBoxTextContainer
                                style={{ marginTop: '8px' }}
                                checked={get(classDetails, 'teachersSameForAllClasses')}
                                onChange={e => {
                                    const obj = { ...classDetails }
                                    obj.teachersSameForAllClasses = e.target.checked
                                    obj.isSameForAllSelected = e.target.checked
                                    setClassDetails(obj)
                                }}
                            >
                                Apply same to all sections
                            </CheckBoxTextContainer>
                        ) : null}
                    </FlexContainer>
                </FlexContainer>
            </>
        )
    }

    const renderStudentsList = () => {
        return (
            <StudentsList>
                <div className='listHeader'>
                    <div className='checkboxContainer'>
                        <input type='checkbox'
                            onChange={(e) => {
                                if (e.target.checked && classStudents.length) {
                                    const obj = { ...classDetails }
                                    const studentIds = classStudents.length > 0 && classStudents.map(item => get(item, 'id'))
                                    obj.students = studentIds
                                    setClassDetails(obj)
                                } else {
                                    const obj = { ...classDetails }
                                    obj.students = []
                                    setClassDetails(obj)
                                }
                            }}
                        />
                    </div>
                    <div className='rollNo'>
                        <h4>Roll No.</h4>
                    </div>
                    <div className='studentName'>
                        <h4>Student Name</h4>
                    </div>
                </div>
                <div
                    className='listContainer'
                >
                    {filteredStudents && filteredStudents.length && filteredStudents.map(student => {
                        const obj = { ...classDetails }
                        const students = obj.students
                        const studentId = get(student, 'id')
                        const studentActiveStatus = get(student, 'user.status')
                        if (students.includes(studentId) || (!students.includes(studentId) && studentActiveStatus === 'active')) {
                            return (
                                <div className='listHeader' style={{ borderTop: '1.3px solid #E2E2E2' }}>
                                    <div className='checkboxContainer'>
                                        <input
                                            type='checkbox'
                                            checked={classDetails.students.includes(get(student, 'id'))}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    const obj = { ...classDetails }
                                                    const students = obj.students
                                                    students.push(get(student, 'id'))
                                                    obj.students = students
                                                    setClassDetails(obj)
                                                } else {
                                                    const obj = { ...classDetails }
                                                    const students = obj.students
                                                    const filtered = students.filter(item => item !== get(student, 'id'))
                                                    obj.students = filtered
                                                    setClassDetails(obj)
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className='rollNo'>
                                        <h4 style={{ color: '#666' }}>{get(student, 'rollNo')}</h4>
                                    </div>
                                    <div className='studentName'>
                                        <h4 style={{ color: '#666' }}>{get(student, 'studentName')}</h4>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </StudentsList>
        )
    }

    const renderAddStudent = () => {
        const grade = get(selectedGradeSection, 'grade')
        const gradeNumber = grade.slice(5)
        const section = get(selectedGradeSection, 'section.section')
        const currentClass = `Class ${gradeNumber}-${section}`
        return (
            <FlexContainer width='100%'>
                <FlexContainer justify='space-between' width='100%' align='center' style={{ margin: '20px 0' }}>
                    <CurrentClassName>
                        <p>{currentClass}</p>
                    </CurrentClassName>
                    <InputContainer style={{ width: '60%', marginBottom: '0' }}>
                        <input
                            placeholder='search'
                            value={searchValue}
                            onChange={(e) => {
                                const value = e.target.value
                                setSearchValue(value)
                            }}
                        />
                    </InputContainer>
                </FlexContainer>
                {fetchingStudentProfile ? (
                    <FlexContainer direction='column' align='center' justify='center' width='100%' style={{ height: '300px' }}>
                        <LoadingOutlined />
                    </FlexContainer>
                ) : (
                    classStudents.length ? (
                        renderStudentsList()
                    ) : (
                        <FlexContainer direction='column' align='center' justify='center' width='100%' style={{ height: '300px' }}>
                            <h3>No Student Found</h3>
                        </FlexContainer>
                    )
                )}
            </FlexContainer>
        )
    }

    const renderClassroomDetails = () => {
        switch (currentStep) {
            case 1:
                return renderBasicDetails()
            case 2:
                return renderAddStudent()
            default:
                break;
        }
    }

    return (
        <AntModal
            visible={editClassroomModalVisible}
            onCancel={() => {
                handleClose()
                resetState()
            }}
            centered
            closable={false}
            footer={null}
            modalWidth='300px'
            style={{ minHeight: '500px' }}
        >
            <FlexContainer style={{ minHeight: '500px' }} direction='column' justify='space-between'>
                <FlexContainer width='100%' direction='column'>
                    <FlexContainer>
                        <div style={{ padding: '0.5vw 0' }}>
                            <h3>{`Edit Classroom: ${get(selectedGradeSection, 'grade')}${get(selectedGradeSection, 'section.section')}`}</h3>
                            <CloseIcon
                                onClick={() => {
                                    handleClose()
                                    resetState()
                                }}
                            />
                        </div>
                    </FlexContainer>
                    <FlexContainer>
                        <PrimaryButton
                            style={{
                                border: 'none',
                                background: '#fff',
                                color: currentStep === 1 ? '#8C61CB' : '#A8A7A7',
                                borderBottom: currentStep === 1 ? '1px solid #8C61CB' : 'none',
                                borderRadius: '0',
                            }}
                            onClick={() => setCurrentStep(1)}
                        >
                            Basic Info
                        </PrimaryButton>
                        <PrimaryButton
                            style={{
                                border: 'none',
                                background: '#fff',
                                color: currentStep === 2 ? '#8C61CB' : '#A8A7A7',
                                borderBottom: currentStep === 2 ? '1px solid #8C61CB' : 'none',
                                borderRadius: '0',
                            }}
                            onClick={() => setCurrentStep(2)}
                        >
                            Classroom Students
                        </PrimaryButton>
                    </FlexContainer>
                    {renderClassroomDetails()}
                </FlexContainer>
                <FlexContainer width='100%'>
                    {currentStep === 1 ? (
                        <FlexContainer justify='space-between' width='100%'>
                            <PrimaryButton
                                onClick={() => handleDeleteSection()}
                                style={{ border: '1px solid #FF5744', background: '#fff', color: '#FF5744' }}
                            >
                                {schoolClassesDeleteStatus ? (
                                    <LoadingOutlined />
                                ) : 'Delete Classroom'}
                            </PrimaryButton>
                            <PrimaryButton
                                onClick={() => setCurrentStep(2)}
                            >
                            Save
                            </PrimaryButton>
                        </FlexContainer>
                    ) : (
                        <FlexContainer justify='space-between' width='100%'>
                            <PrimaryButton
                                onClick={() => setCurrentStep(1)}
                                style={{
                                    color: '#8C61CB',
                                    background: '#8c61cb1f'
                                }}
                            >
                                Back
                            </PrimaryButton>
                            <PrimaryButton
                                onClick={() => saveSubmitButtonClicked()}
                            >
                                {addBatchLoading ? (
                                    <LoadingOutlined />
                                ) : 'Save'}
                            </PrimaryButton>
                        </FlexContainer>
                    )}
                </FlexContainer>
            </FlexContainer>
        </AntModal>
    )
}

export default EditClassroom