import styled from 'styled-components'
import { TekieAmethyst } from '../../constants/colors'

const CoursePackageContainer = styled.div`
    padding: 0 45px;
`
const CoursePackageContainerHeading = styled.div`
    margin-left: 20px;
    font-size: 32px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
`
const StyledBox = styled.div`
    height: 80px;
    background: #C4C4C4;
    margin: 0px 20px 10px;
`
const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 45px;
    margin-bottom: 20px;
`
const StyledButton = styled.button`
    padding: 0;
    padding: 10px 8px;
    width: 142px;
    height: 36px;
    background: ${TekieAmethyst};
    border: none;
    border-radius: 3px;
    box-sizing: border-box;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`

const TopNavigationContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 42px;
`

const NavigationButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    .anticon svg {
        display: inline-block;
        width: 20px;
        height: 20px;
    }
`

const NavigationButton = styled.button`
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border-radius: 100%;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 1px solid ${TekieAmethyst};
    ${props => props.isSelected ? `
        width: 40px;
        height: 40px;
        border: 1px dashed ${TekieAmethyst} !important;
    ` : ``};
    > span {
        padding: 0;
        width: 32px;
        height: 32px;
        font-size: 16px;
        line-height: 32px;
        color: #fff;
        background: ${TekieAmethyst};
        font-weight: 600;
        border-radius: 100%;
    }
`
export {
    CoursePackageContainer,
    CoursePackageContainerHeading,
    StyledBox,
    StyledButton,
    ButtonContainer,
    NavigationButtonContainer,
    NavigationButton,
    TopNavigationContainer
}
