import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import injectProps from '../../components/injectProps'
import { login } from '../../actions/login'
import ForgetPassword from './Forget'

const mapStateToProps = state => state.login

const mapDispatchToProps = dispatch => ({
  login: (username,
    password,
    rawphone,
    countryCode,
    resetpassword,
    confirmPassword,
    authToken) =>
    dispatch(login(username = null,
      password = null,
      rawphone,
      countryCode,
      resetpassword,
      confirmPassword,
      authToken)),
})

const ForgotWithExtraProps = injectProps({
  notification
})(ForgetPassword)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotWithExtraProps))

