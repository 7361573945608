/* eslint-disable max-len */
import React from 'react'
import { get, sortBy } from 'lodash'
import moment from 'moment'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import { Select, Spin } from 'antd'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import SupplyCalendarWrapper from '../SupplyCalendar.style'
import { TekieAmethyst } from '../../../../constants/colors'
import { CalendarSvg, FemaleSvg, MaleSvg, SlotIcon } from '../../../../constants/icons'
import Styles from '../../DemandSupplyDashboard.style'
import verticals from '../../demandSupply-utils/verticals'
import countryAndState from '../../../../constants/CountryAndStates'
import requestToGraphql from '../../../../utils/requestToGraphql'
import getFullPath from '../../../../utils/getFullPath'
import {
  addMentorDemandSlot, fetchSchoolsAndCampaign,
  removeLinksFromMentorAvailabilitySlots,
  updateMentorAvailabilitySlot, updateMentorDemandSlot
} from '../../../../actions/demandSupplyDashboard'
import { getSuccessStatus } from '../../../../utils/data-utils'
import { filterLabel, filterOption } from '../../demandSupply-utils/filterOptions'
import getIdArrForQuery from '../../../../utils/getIdArrForQuery'
import restrictedNumverValues from '../../../../constants/restrictedNumberValues'
import EditSlotTabs from './EditSlotTabs'
import { B2B, B2B2C, B2C } from '../../../../constants/demandVerticals'
import customNotification from '../../components/CustomNotification'
import { TRIAL } from '../../../../constants/sessionTypeConstant'
import PageLoading from '../../components/PageLoading'
import getDataFromLocalStorage from '../../../../utils/extract-from-localStorage'
import { SENSEI } from '../../../../constants/roles'


const SELECTED_SLOTS = 'selectedSlots'

const SELECT_MORE = 'selectMoreDetails'

const SELECT_MENTORS = 'selectMentor'

const TimePickerFormat = 'hh a'

class BulkAddModal extends React.Component {
  state = {
    addedSlots: [],
    paySlabs: [],
    activeTab: SELECTED_SLOTS,
    selectedVertical: [],
    selectedGeography: '',
    selectedPaySlab: '',
    mentorsList: [],
    ismentorLoading: false,
    selectedMentors: [],
    selectedSchool: [],
    schoolList: [],
    selectedCampaign: [],
    isPublishing: false,
    singleSlotCount: 0,
    isEditable: true
  }
  componentDidUpdate = (prevProps) => {
    const { visible, schoolFetchingStatus } = this.props
    if (prevProps.visible !== visible && visible) {
      this.getDataFromProps()
    }

    if (getSuccessStatus(schoolFetchingStatus, prevProps.schoolFetchingStatus)) {
      this.setState({
        schoolList: this.props.schools && this.props.schools.toJS() || []
      })
    }
  }

  fetchMentors = async () => {
    this.setState({
      ismentorLoading: true
    })
    let mentors = []
    const savedRole = getDataFromLocalStorage('login.role')
    if (savedRole && savedRole === SENSEI) {
      const senseiId = getDataFromLocalStorage('login.id')
      const { data: mentorsData } = await await requestToGraphql(gql`{
        user(id: "${senseiId}") {
          senseiProfile {
            id
            mentors {
              user {
                id
                name
                gender
                mentorProfile {
                  id
                }
                profilePic {
                  id
                  uri
                }
              }
            }
          }
        }
      }
      `)
      mentors = get(mentorsData, 'user.senseiProfile')
      mentors = get(mentors, 'mentors', []).map(mentor => get(mentor, 'user'))
      if (mentors) {
        return this.setState({
          mentorsList: mentors,
          ismentorLoading: false
        }, () => {
          const savedRole = getDataFromLocalStorage('login.role')
          const { selectedSlots, isSlotEditing } = this.props
          const selectedMentors = get(selectedSlots, '[0].singleSlotMentors', []).map(slotMentor => get(slotMentor, 'id'))
          if (savedRole === SENSEI && isSlotEditing) {
            const newSelectedMentors = []
            this.state.mentorsList.forEach(mentor => {
              if (selectedMentors.includes(get(mentor, 'mentorProfile.id'))) {
                newSelectedMentors.push(get(mentor, 'mentorProfile.id'))
              }
            })
            this.setState({
              selectedMentors: newSelectedMentors
            })
          }
        })
      }
    } else {
      const query = `{ role: mentor },
      { secondaryRole_exists: false },
      { mentorProfile_exists: true },
      { mentorProfile_some: { isMentorActive: true } },`;
      const { data: mentorsData } = await requestToGraphql(gql`{
          users(filter: { and: [
            ${query}
          ] }) {
            id
            name
            gender
            mentorProfile {
              id
            }
            profilePic {
              id
              uri
            }
          }
        }
        `)
      mentors = mentorsData
      if (mentors) {
        this.setState({
          mentorsList: get(mentors, 'users', []),
          ismentorLoading: false
        })
      }
    }
  }
  getDataFromProps = () => {
    const { selectedSlots, paySlabArray, isSlotEditing } = this.props
    const { schoolList } = this.state
    let addedSlots = []
    let paySlabs = []
    let selectedVertical = []
    let selectedGeography = ''
    let selectedPaySlab = ''
    let selectedSchool = []
    let selectedCampaign = []
    let selectedMentors = []
    let singleSlotCount = 0
    let isEditable = true
    if (selectedSlots && selectedSlots.length > 0) {
      addedSlots = [...selectedSlots]
      if (isSlotEditing) {
        selectedVertical = get(selectedSlots, '[0].selectedVertical').map(vertical =>
          ({ key: get(vertical, 'value'), label: get(vertical, 'value') }))
        selectedGeography = get(selectedSlots, '[0].countries[0].value')
        selectedPaySlab = get(selectedSlots, '[0].paySlab.id')
        selectedSchool = get(selectedSlots, '[0].schools').map(school =>
          ({ key: get(school, 'id'), label: get(school, 'name') }))
        selectedCampaign = get(selectedSlots, '[0].campaigns').map(campaign =>
          ({ key: get(campaign, 'id'), label: get(campaign, 'title') }))
        selectedMentors = get(selectedSlots, '[0].singleSlotMentors', []).map(slotMentor => get(slotMentor, 'id'))
        singleSlotCount = get(selectedSlots, '[0].count')
        isEditable = get(selectedSlots, '[0].isEditable')
      }
      const selectedVerticalValues = selectedVertical.map(vertical => get(vertical, 'key'))
      if (isSlotEditing && schoolList.length === 0
        && selectedVerticalValues.length > 0
        && (selectedVerticalValues.includes(B2B)
          || selectedVerticalValues.includes(B2B2C))) fetchSchoolsAndCampaign()
    }
    if (paySlabArray && paySlabArray.length > 0) {
      const newPaySlab = [...paySlabArray]
      newPaySlab.shift()
      paySlabs = [...newPaySlab]
    }
    this.setState({
      addedSlots,
      paySlabs,
      selectedVertical,
      selectedGeography,
      selectedPaySlab,
      selectedSchool,
      selectedCampaign,
      selectedMentors,
      singleSlotCount,
      isEditable
    }, () => {
      const savedRole = getDataFromLocalStorage('login.role')
      if (savedRole === SENSEI) {
        const newSelectedMentors = []
        this.state.mentorsList.forEach(mentor => {
          if (selectedMentors.includes(get(mentor, 'mentorProfile.id'))) {
            newSelectedMentors.push(get(mentor, 'mentorProfile.id'))
          }
        })
        this.setState({
          selectedMentors: newSelectedMentors
        })
      }
    })
  }
  handleStateChange = (name, value) => {
    const { isSlotEditing, selectedSlots } = this.props
    if (name === 'selectedPaySlab' && isSlotEditing) {
      const singleSlotId = get(selectedSlots, '[0].singleSlotId')
      removeLinksFromMentorAvailabilitySlots(singleSlotId, this.state.selectedPaySlab, 'paySlab')
    }
    this.setState({
      [name]: value
    })
  }

  onMultiValueAddEdit = (value, name) => {
    const { selectedSchool, selectedCampaign, selectedVertical, schoolList } = this.state
    const { schoolFetchingStatus, isSlotEditing, selectedSlots } = this.props
    const singleSlotId = get(selectedSlots, '[0].singleSlotId')
    if (name === 'selectedSchool') {
      let newSelectedSchool = [...selectedSchool]
      const isExist = newSelectedSchool.find(school => get(school, 'key') === get(value, 'key'))
      if (isExist) {
        newSelectedSchool = newSelectedSchool.filter(school => get(school, 'key') !== get(value, 'key'))
        if (isSlotEditing) {
          removeLinksFromMentorAvailabilitySlots(singleSlotId, get(value, 'key'), 'school')
        }
      } else {
        newSelectedSchool = [...newSelectedSchool, value]
      }
      this.setState({
        selectedSchool: newSelectedSchool
      })
    } else if (name === 'selectedCampaign') {
      let newSelectedCampaign = [...selectedCampaign]
      const isExist = newSelectedCampaign.find(school => get(school, 'key') === get(value, 'key'))
      if (isExist) {
        newSelectedCampaign = newSelectedCampaign.filter(school => get(school, 'key') !== get(value, 'key'))
        if (isSlotEditing) {
          removeLinksFromMentorAvailabilitySlots(singleSlotId, get(value, 'key'), 'campaign')
        }
      } else {
        newSelectedCampaign = [...newSelectedCampaign, value]
      }
      this.setState({
        selectedCampaign: newSelectedCampaign
      })
    } else if (name === 'selectedVertical') {
      let newSelectedVerticals = [...selectedVertical]
      const isExist = newSelectedVerticals.find(vertical => get(vertical, 'key') === get(value, 'key'))
      if (isExist) {
        newSelectedVerticals = newSelectedVerticals.filter(vertical => get(vertical, 'key') !== get(value, 'key'))
      } else {
        newSelectedVerticals = [...newSelectedVerticals, value]
      }
      this.setState({
        selectedVertical: newSelectedVerticals
      }, () => {
        const selectedVerticalValues = this.state.selectedVertical.map(vertical => get(vertical, 'key'))
        if ((selectedVerticalValues.includes(B2B2C) || selectedVerticalValues.includes(B2B))
          && schoolList.length === 0 && (!schoolFetchingStatus || !get(schoolFetchingStatus.toJS(), 'loading'))) {
          fetchSchoolsAndCampaign()
        }
      })
    }
  }

  onPublishClick = async () => {
    const { addedSlots, selectedVertical, selectedGeography,
      selectedPaySlab, selectedMentors, selectedSchool, selectedCampaign,
      singleSlotCount
    } = this.state
    const { isSlotEditing = false } = this.props
    let addQuery = ''
    const addedSingleSlots = []
    this.setState({ isPublishing: true })
    let newSelectedSchool = []
    let newSelectedCampaign = []
    const selectedVerticalValues = selectedVertical.map(vertical => get(vertical, 'key'))
    if (selectedVerticalValues.includes(B2B2C) || selectedVerticalValues.includes(B2B)) {
      newSelectedSchool = selectedSchool.map(school => get(school, 'key'))
      newSelectedCampaign = selectedCampaign.map(campaign => get(campaign, 'key'))
    }
    if (!isSlotEditing) {
      let verticalInput = ''
      selectedVerticalValues.forEach(vertical => {
        verticalInput += `{ value: ${vertical} }`
      })
      addedSlots.forEach(slot => {
        let slotName = ''
        for (const slotObj in slot) {
          if (slotObj && slotObj.startsWith('slot')) {
            slotName = slotObj
          }
        }

        const date = moment(get(slot, 'startTime')).set('hours', 0)
        addedSingleSlots.push({
          slotId: get(slot, 'slotId'),
          slotName,
          date
        })
        addQuery += `addSingleSlot_${get(slot, 'slotId')}: addMentorAvailabilitySlot(
          input: {
          date: "${date}"
          verticals: [${verticalInput}]
          slotName: ${slotName}
          countries: [{
            value: ${selectedGeography}
          }]
          count: ${get(slot, 'count')}
          sessionType: trial
          isBroadCasted: ${selectedMentors.length > 0 ? 'true' : 'false'}
          }
          paySlabConnectId: "${selectedPaySlab}"
          ${newSelectedSchool ? `schoolsConnectIds: [${getIdArrForQuery(newSelectedSchool)}]` : ''}
          ${selectedMentors ? `broadCastedMentorsConnectIds: [${getIdArrForQuery(selectedMentors)}]` : ''}
          ${newSelectedCampaign ? `campaignsConnectIds: [${getIdArrForQuery(newSelectedCampaign)}]` : ''}
      ) {
          id
      }`
      })
      if (addQuery) {
        const { data } = await requestToGraphql(gql`mutation {
        ${addQuery}
      }`)
        if (data) {
          const mentorDemandSlots = []
          for (const property in data) {
            if (property && property.startsWith('addSingleSlot')) {
              const slotsProperty = property.split('_')
              if (slotsProperty && slotsProperty.length > 0) {
                const findSlot = addedSingleSlots.find(slot => get(slot, 'slotId') === slotsProperty[1])
                const singleSlotData = data[property]
                const newSlots = {
                  ...findSlot,
                  date: get(findSlot, 'date'),
                  singleSlotData
                }
                mentorDemandSlots.push(newSlots)
              }
            }
          }
          const newMentorDemandSlots = mentorDemandSlots.reduce((acc, currVal) => {
            if (acc && acc[get(currVal, 'date')] && acc[get(currVal, 'date')].length) {
              acc[get(currVal, 'date')].push(currVal)
            } else {
              acc[get(currVal, 'date')] = [currVal]
            }
            return acc
          }, {})
          let totalCount = 0
          const mentorSlotsKeys = Object.keys(newMentorDemandSlots)
          for (let i = 0; i < mentorSlotsKeys.length; i += 1) {
            const key = mentorSlotsKeys[i]
            /* eslint-disable no-await-in-loop, no-loop-func */
            const { data: mentorDemandData } = await requestToGraphql(gql`{
            mentorDemandSlots(filter: { date: "${new Date(key)}" }) {
              id
            }
          }`)
            const slotsConnectIds = get(newMentorDemandSlots, `${key}`, []).map(slots => get(slots, 'singleSlotData.id'))
            if (get(mentorDemandData, 'mentorDemandSlots', []).length > 0) {
              const mentorDemandId = get(mentorDemandData, 'mentorDemandSlots[0].id')
              await updateMentorDemandSlot({
                id: mentorDemandId,
                input: {},
                broadCastedMentorsConnectIds: selectedMentors,
                slotsConnectIds,
                mentors: this.state.mentorsList && this.state.mentorsList.map(mentor => get(mentor, 'id'))
              }).then(res => {
                if (res && res.updateMentorDemandSlot && res.updateMentorDemandSlot.id) {
                  totalCount += 1
                }
              })
            } else {
              await addMentorDemandSlot({
                input: {
                  date: new Date(key),
                  verticals: selectedVertical.map(vertical => ({
                    value: get(vertical, 'key')
                  })),
                  sessionType: TRIAL
                },
                slotsConnectIds,
                broadCastedMentorsConnectIds: selectedMentors,
                mentors: this.state.mentorsList && this.state.mentorsList.map(mentor => get(mentor, 'id'))
              }).then(res => {
                if (res && res.addMentorDemandSlot && res.addMentorDemandSlot.id) {
                  totalCount += 1
                }
              })
            }
          }
          if (totalCount === mentorSlotsKeys.length) {
            customNotification('Slots Added Successfully', '', SlotIcon, 'success')
            this.setState({ isPublishing: false })
            this.closeModal(true)
          }
        }
      }
    } else if (isSlotEditing && addedSlots.length > 0) {
      const { singleSlotId } = get(addedSlots, '[0]')
      let updateInput = {
        count: singleSlotCount,
        verticals: {
          replace: selectedVertical.map(vertical => ({
            value: get(vertical, 'key')
          }))
        },
        sessionType: TRIAL,
        isBroadCasted: selectedMentors.length > 0
      }
      if (selectedGeography) {
        updateInput = {
          ...updateInput,
          countries: {
            replace: [selectedGeography]
            // replace: [{
            //   value: selectedGeography
            // }]
          }
        }
      }
      await updateMentorAvailabilitySlot({
        id: singleSlotId,
        input: updateInput,
        paySlabConnectId: selectedPaySlab,
        schoolsConnectIds: newSelectedSchool,
        broadCastedMentorsConnectIds: selectedMentors,
        campaignsConnectIds: newSelectedCampaign
      }).then(res => {
        if (res && res.updateMentorAvailabilitySlot
          && res.updateMentorAvailabilitySlot.id) {
          customNotification('Slots updated Successfully', '', SlotIcon, 'success')
          this.setState({ isPublishing: false })
          this.closeModal(true)
        }
      })
    }
  }

  onSelectMentor = (mentorId) => {
    const { selectedMentors } = this.state
    const { isSlotEditing, selectedSlots } = this.props
    const singleSlotId = get(selectedSlots, '[0].singleSlotId')
    if (selectedMentors.includes(mentorId)) {
      this.setState({
        selectedMentors: selectedMentors.filter(id => id !== mentorId)
      })
      if (isSlotEditing) removeLinksFromMentorAvailabilitySlots(singleSlotId, mentorId, 'mentor')
    } else {
      this.setState({
        selectedMentors: [...selectedMentors, mentorId]
      })
    }
  }

  onChangeCount = (type, event) => {
    const { singleSlotCount } = this.state
    let count = singleSlotCount
    if (type === 'add') {
      count += 1
    } else if (type === 'sub' && count > 0) {
      count -= 1
    } else if (type === 'enter' && typeof parseInt(get(event, 'target.value'), 0) === 'number') {
      count = parseInt(get(event, 'target.value'), 0)
    }
    this.setState({
      singleSlotCount: count
    })
  }

  onInputKeyDown = (e) => {
    if (restrictedNumverValues.includes(e.key)) {
      e.preventDefault()
    }
  }
  renderActiveTab = () => {
    const { activeTab, addedSlots, selectedVertical,
      selectedGeography, paySlabs, selectedPaySlab,
      ismentorLoading, mentorsList, selectedMentors,
      singleSlotCount, isEditable } = this.state
    const { isSlotEditing } = this.props
    if (activeTab === SELECTED_SLOTS) {
      const slotsElement = []
      sortBy(addedSlots, 'startTime').forEach(slot => {
        slotsElement.push(
          <>
            <Styles.FlexContainer style={{ margin: '10px 0' }}>
              <Styles.FlexContainer flexDirection='column' style={{ alignItems: 'flex-start' }}>
                <Styles.SecondaryText>Timings</Styles.SecondaryText>
                <Styles.FlexContainer style={{ padding: '10px 0 0 0', alignItems: 'center' }}>
                  <SupplyCalendarWrapper.CustomTimePicker
                    use12Hours
                    format={TimePickerFormat}
                    hideDisabledOptions
                    allowClear={false}
                    inputReadOnly
                    open={false}
                    value={moment(get(slot, 'startTime'))}
                    placeholder='Start'
                    disabledHours={() => {
                      if (new Date().setHours(0, 0, 0, 0)
                        === new Date(get(slot, 'startTime')).setHours(0, 0, 0, 0)) {
                        return [...Array(new Date().getHours() + 1).keys()].slice(1)
                      }
                    }}
                  />
                  <Styles.SecondaryText style={{ padding: '5px' }}>to</Styles.SecondaryText>
                  <SupplyCalendarWrapper.CustomTimePicker
                    use12Hours
                    format={TimePickerFormat}
                    hideDisabledOptions
                    allowClear={false}
                    inputReadOnly
                    open={false}
                    value={moment(get(slot, 'endTime'))}
                    placeholder='End'
                    disabledHours={() =>
                      [...Array(get(slot, 'startTime') + 1).keys()].slice(1)
                    }
                  />
                </Styles.FlexContainer>
              </Styles.FlexContainer>
              <Styles.FlexContainer flexDirection='column' style={{ alignItems: 'flex-start' }}>
                <Styles.SecondaryText>
                  Date
                </Styles.SecondaryText>
                <Styles.FlexContainer style={{ padding: '10px 0 0 0', alignItems: 'center' }}>
                  <SupplyCalendarWrapper.CustomDatePicker
                    placeholder='Start Date'
                    allowClear={false}
                    inputReadOnly
                    open={false}
                    value={moment(get(slot, 'startTime'))}
                    disabledDate={(current) => current &&
                      current < new Date().setDate((new Date().getDate()) - 1)
                    }
                    format='DD MMMM YYYY'
                    style={{ width: '100%' }}
                  />
                </Styles.FlexContainer>
              </Styles.FlexContainer>
            </Styles.FlexContainer>
            <Styles.CustomDivider />
          </>
        )
      })
      if (isSlotEditing) {
        slotsElement.push(
          <>
            <Styles.GridContainer>
              <Styles.FlexContainer flexDirection='column' style={{ alignItems: 'flex-start' }}>
                <Styles.SecondaryText style={{ paddingBottom: '10px' }}>Vertical</Styles.SecondaryText>
                <Styles.Select
                  style={{ width: '100%' }}
                  value={selectedVertical}
                  disabled={!isEditable}
                  verticals
                  placeholder='Vertical'
                  name='selectedVertical'
                  mode='multiple'
                  labelInValue
                  onSelect={(value) => this.onMultiValueAddEdit(value, 'selectedVertical')}
                  onDeselect={(value) => this.onMultiValueAddEdit(value, 'selectedVertical')}
                >
                  {[...verticals].map(verticalType => (
                    <Styles.Option className='custom-selectOption' style={{ textTransform: 'uppercase' }} value={verticalType}>
                      {/* <Styles.Checkbox checked={verticalType === selectedVertical} /> */}
                      {/* <span> */}
                      {verticalType}
                      {/* </span> */}
                    </Styles.Option>
                  ))}
                </Styles.Select>
              </Styles.FlexContainer>
              <Styles.FlexContainer flexDirection='column' style={{ alignItems: 'flex-start' }}>
                <Styles.SecondaryText style={{ paddingBottom: '10px' }}>Geography</Styles.SecondaryText>
                <Styles.Select
                  showSearch
                  filterOption={filterOption}
                  style={{ width: '100%' }}
                  value={selectedGeography}
                  disabled={!isEditable}
                  placeholder='Geography'
                  name='selectedGeography'
                  onChange={(value) => this.handleStateChange('selectedGeography', value)}
                >
                  {countryAndState.map(country => (
                    <Styles.Option value={country.countryValue} label={country.country}>
                      <Styles.Checkbox checked={selectedGeography === country.countryValue} />
                      <span>{country.country}</span>
                    </Styles.Option>
                  ))}
                </Styles.Select>
              </Styles.FlexContainer>
            </Styles.GridContainer>
            <Styles.GridContainer>
              <Styles.FlexContainer flexDirection='column' style={{ alignItems: 'flex-start' }}>
                <Styles.SecondaryText style={{ paddingBottom: '10px' }}>Count</Styles.SecondaryText>
                <Styles.CustomInput
                  showSearch
                  style={{ width: '100%' }}
                  value={singleSlotCount}
                  disabled={!isEditable}
                  type='number'
                  prefix={
                    <MinusOutlined
                      disabled={!isEditable}
                      onClick={() => isEditable && this.onChangeCount('sub')}
                    />
                  }
                  suffix={
                    <PlusOutlined
                      disabled={!isEditable}
                      onClick={() => isEditable && this.onChangeCount('add')}
                    />
                  }
                  placeholder='Count'
                  name='singleSlotCount'
                  onKeyDown={this.onInputKeyDown}
                  onChange={(event) => isEditable && this.onChangeCount('enter', event)}
                />
              </Styles.FlexContainer>
              <Styles.FlexContainer flexDirection='column' style={{ alignItems: 'flex-start' }}>
                <Styles.SecondaryText style={{ paddingBottom: '10px' }}>Pay-Slab</Styles.SecondaryText>
                <Styles.Select
                  showSearch
                  filterOption={filterOption}
                  style={{ width: '100%' }}
                  value={selectedPaySlab}
                  disabled={!isEditable}
                  placeholder='PaySlab'
                  name='selectedPaySlab'
                  onChange={(value) => this.handleStateChange('selectedPaySlab', value)}
                >
                  {paySlabs.map(paySlab => (
                    <Styles.Option className='custom-selectOption' value={get(paySlab, 'id')}>
                      <Styles.Checkbox checked={get(paySlab, 'id') === selectedPaySlab} />
                      <span>{get(paySlab, 'title')}</span>
                    </Styles.Option>
                  ))}
                </Styles.Select>
              </Styles.FlexContainer>
            </Styles.GridContainer>
          </>
        )
      }
      return slotsElement
    } else if (activeTab === SELECT_MORE) {
      const selectedVerticalValues = selectedVertical.map(vertical => get(vertical, 'key'))
      if (isSlotEditing && selectedVertical.length &&
        (selectedVerticalValues.includes(B2B) || selectedVerticalValues.includes(B2B2C))) {
        return this.renderB2B2CSection()
      }
      return (
        <>
          <Styles.GridContainer>
            <Styles.FlexContainer flexDirection='column' style={{ alignItems: 'flex-start' }}>
              <Styles.SecondaryText style={{ paddingBottom: '10px' }}>Vertical</Styles.SecondaryText>
              <Styles.Select
                style={{ width: '100%' }}
                value={selectedVertical}
                vertical
                disabled={!isEditable}
                placeholder='Vertical'
                name='selectedVertical'
                mode='multiple'
                labelInValue
                onSelect={(value) => this.onMultiValueAddEdit(value, 'selectedVertical')}
                onDeselect={(value) => this.onMultiValueAddEdit(value, 'selectedVertical')}
              >
                {[...verticals].map(verticalType => (
                  <Styles.Option className='custom-selectOption' style={{ textTransform: 'uppercase' }} value={verticalType}>
                    {/* <Styles.Checkbox checked={verticalType === selectedVertical} /> */}
                    {/* <span> */}
                    {verticalType}
                    {/* </span> */}
                  </Styles.Option>
                ))}
              </Styles.Select>
            </Styles.FlexContainer>
            <Styles.FlexContainer flexDirection='column' style={{ alignItems: 'flex-start' }}>
              <Styles.SecondaryText style={{ paddingBottom: '10px' }}>Geography</Styles.SecondaryText>
              <Styles.Select
                showSearch
                disabled={!isEditable}
                filterOption={filterOption}
                style={{ width: '100%' }}
                value={selectedGeography}
                placeholder='Geography'
                name='selectedGeography'
                onChange={(value) => this.handleStateChange('selectedGeography', value)}
              >
                {countryAndState.map(country => (
                  <Styles.Option value={country.countryValue} label={country.country}>
                    <Styles.Checkbox checked={selectedGeography === country.countryValue} />
                    <span>{country.country}</span>
                  </Styles.Option>
                ))}
              </Styles.Select>
            </Styles.FlexContainer>
          </Styles.GridContainer>
          <Styles.GridContainer>
            <Styles.FlexContainer flexDirection='column' style={{ alignItems: 'flex-start' }}>
              <Styles.SecondaryText style={{ paddingBottom: '10px' }}>Pay-Slab</Styles.SecondaryText>
              <Styles.Select
                showSearch
                filterOption={filterOption}
                disabled={!isEditable}
                style={{ width: '100%' }}
                value={selectedPaySlab}
                placeholder='PaySlab'
                name='selectedPaySlab'
                onChange={(value) => this.handleStateChange('selectedPaySlab', value)}
              >
                {paySlabs.map(paySlab => (
                  <Styles.Option className='custom-selectOption' value={get(paySlab, 'id')}>
                    <Styles.Checkbox checked={get(paySlab, 'id') === selectedPaySlab} />
                    <span>{get(paySlab, 'title')}</span>
                  </Styles.Option>
                ))}
              </Styles.Select>
            </Styles.FlexContainer>
            {
              isSlotEditing && (
                <Styles.FlexContainer flexDirection='column' style={{ alignItems: 'flex-start' }}>
                  <Styles.SecondaryText style={{ paddingBottom: '10px' }}>Count</Styles.SecondaryText>
                  <Styles.CustomInput
                    showSearch
                    filterOption={filterOption}
                    style={{ width: '100%' }}
                    disabled={!isEditable}
                    value={singleSlotCount}
                    type='number'
                    prefix={
                      <MinusOutlined
                        disabled={!isEditable}
                        onClick={() => isEditable && this.onChangeCount('sub')}
                      />
                    }
                    suffix={
                      <PlusOutlined
                        disabled={!isEditable}
                        onClick={() => isEditable && this.onChangeCount('add')}
                      />
                    }
                    placeholder='Count'
                    name='singleSlotCount'
                    onKeyDown={this.onInputKeyDown}
                    onChange={(event) => isEditable && this.onChangeCount('enter', event)}
                  />
                </Styles.FlexContainer>
              )
            }
            {/* <Styles.FlexContainer flexDirection='column' style={{ alignItems: 'flex-start' }}>
              <Styles.SecondaryText style={{ paddingBottom:
                '10px' }}
              >Type
              </Styles.SecondaryText>
              <Styles.StyledRadioGroup
                name='sessionType'
                buttonStyle='solid'
                value={sessionType}
                onChange={({ target: { value } }) => this.handleStateChange('sessionType', value)}
              >
                <MainModal.StyledRadio value={TRIAL}>Trial</MainModal.StyledRadio>
                <MainModal.StyledRadio value={PAID}>Paid</MainModal.StyledRadio>
              </Styles.StyledRadioGroup>
            </Styles.FlexContainer> */}
          </Styles.GridContainer>
          {this.renderB2B2CSection()}
        </>
      )
    } else if (activeTab === SELECT_MENTORS) {
      return (
        <div>
          {ismentorLoading ? <span>Loading Mentors</span> : (
            <>
              <Styles.Checkbox
                checked={selectedMentors.length === mentorsList.length}
                onChange={() => isEditable &&
                  (selectedMentors.length === mentorsList.length ? this.setState({ selectedMentors: [] }) :
                    this.setState({ selectedMentors: mentorsList.map(mentor => get(mentor, 'mentorProfile.id')) }))
                }
              >
                Select All
              </Styles.Checkbox>
              <Styles.GridContainer>
                {mentorsList.map(mentor => (
                  <Styles.FlexContainer
                    justify='flex-start'
                    style={{ cursor: 'pointer', margin: '5px 0' }}
                    onClick={() => isEditable && this.onSelectMentor(get(mentor, 'mentorProfile.id'))}
                  >
                    <Styles.Checkbox
                      onChange={() => isEditable && this.onSelectMentor(get(mentor, 'mentorProfile.id'))}
                      checked={selectedMentors.includes(get(mentor, 'mentorProfile.id'))}
                    />
                    <SupplyCalendarWrapper.MentorProfile profilePic={getFullPath(get(mentor, 'profilePic.uri'))} />
                    <span className='mentor__name'>{get(mentor, 'name')}</span>
                    {get(mentor, 'gender', 'female')
                      && (get(mentor, 'gender', 'female').toLowerCase() === 'female'
                        ? <FemaleSvg /> : <MaleSvg />)}
                  </Styles.FlexContainer>
                ))}
              </Styles.GridContainer>
            </>
          )}
        </div>
      )
    }
    return null
  }
  getSplitedValue = (value, index) => {
    if (value) {
      const splittedValue = value.split('|')
      if (splittedValue.length > 0) {
        return splittedValue[index]
      }
    }
    return ''
  }
  getSchoolCampaign = () => {
    const { selectedSchool } = this.state
    let { campaigns } = this.props
    campaigns = campaigns && campaigns.toJS() || []
    const groupedCampaign = campaigns.reduce((acc, currVal) => {
      const schoolName = `${get(currVal, 'school.id')}|${get(currVal, 'school.name')}`
      if (acc && acc[schoolName]) {
        acc[schoolName].push(currVal)
      } else {
        acc[schoolName] = [currVal]
      }
      return acc
    }, {})
    const newGroupedLo = Object.keys(groupedCampaign).filter(key => selectedSchool.map(school => get(school, 'key')).includes(this.getSplitedValue(key, 0)))
    const newGroupedCampaign = {}
    newGroupedLo.forEach(key => {
      if (get(groupedCampaign, key) && get(groupedCampaign, key).length) {
        newGroupedCampaign[key] = get(groupedCampaign, key) || []
      }
    })
    return newGroupedCampaign
  }
  renderB2B2CSection = () => {
    const { selectedVertical, selectedSchool, schoolList,
      selectedCampaign, isEditable } = this.state
    const { schoolFetchingStatus } = this.props
    const selectedVerticalValues = selectedVertical.map(vertical => get(vertical, 'key'))
    if (selectedVertical.length
      && (selectedVerticalValues.includes(B2B) || selectedVerticalValues.includes(B2B2C))) {
      const schoolLoading = schoolFetchingStatus && get(schoolFetchingStatus.toJS(), 'loading')
      return (
        <Styles.GridContainer>
          <Styles.FlexContainer flexDirection='column' style={{ alignItems: 'flex-start' }}>
            <Styles.SecondaryText style={{ paddingBottom: '10px' }}>School Name</Styles.SecondaryText>
            <Styles.Select
              showSearch
              filterOption={filterLabel}
              style={{ width: '100%' }}
              value={selectedSchool}
              loading={schoolLoading}
              disabled={!isEditable}
              maxTagCount={4}
              notFoundContent={schoolLoading ? <Spin size='small' /> : 'No School Found'}
              placeholder='School'
              name='selectedSchool'
              mode='multiple'
              labelInValue
              onSelect={(value) => this.onMultiValueAddEdit(value, 'selectedSchool')}
              onDeselect={(value) => this.onMultiValueAddEdit(value, 'selectedSchool')}
            >
              {schoolList.map(school => (
                <Styles.Option className='custom-selectOption' value={get(school, 'id')} label={get(school, 'name')}>
                  {get(school, 'name')}
                </Styles.Option>
              ))}
            </Styles.Select>
          </Styles.FlexContainer>
          <Styles.FlexContainer flexDirection='column' style={{ alignItems: 'flex-start' }}>
            <Styles.SecondaryText style={{ paddingBottom: '10px' }}>Campaign Name</Styles.SecondaryText>
            <Styles.Select
              showSearch
              filterOption={filterLabel}
              style={{ width: '100%' }}
              value={selectedCampaign}
              maxTagCount={4}
              disabled={!isEditable}
              loading={schoolLoading}
              notFoundContent={schoolLoading ? <Spin size='small' /> : 'No Campaign Found'}
              placeholder='Campaign'
              name='selectedCampaign'
              mode='multiple'
              labelInValue
              onSelect={(value) => this.onMultiValueAddEdit(value, 'selectedCampaign')}
              onDeselect={(value) => this.onMultiValueAddEdit(value, 'selectedCampaign')}
            >
              {Object.keys(this.getSchoolCampaign()).map((groupedSchool) => (
                <Select.OptGroup key={groupedSchool} label={this.getSplitedValue(groupedSchool, 1)}>
                  {
                    this.getSchoolCampaign()[groupedSchool].map((campaign) => (
                      <Styles.Option
                        className='custom-selectOption'
                        value={get(campaign, 'id')}
                        label={get(campaign, 'title')}
                        key={get(campaign, 'id')}
                      >
                        {get(campaign, 'title')}
                      </Styles.Option>
                    ))
                  }
                </Select.OptGroup>))}
            </Styles.Select>
          </Styles.FlexContainer>
        </Styles.GridContainer>
      )
    }
  }
  renderModalHeader = (isSlotEditing = false) => (
    <>
      <Styles.HeaderIcon>
        <Styles.Icon theme='twoTone'
          marginRight='0px'
          fillSvg={TekieAmethyst}
          component={CalendarSvg}
        />
      </Styles.HeaderIcon>
      <Styles.HeaderDetails>
        <Styles.HeaderTitle>
          {isSlotEditing ? 'Edit ' : 'Open '}Slot Requests
        </Styles.HeaderTitle>
        {
          isSlotEditing && get(this.props, 'selectedSlots[0].openedBy.user') && (
            <Styles.HeaderTitle style={{ fontSize: '15px' }}>
              Slot Opened by {get(this.props, 'selectedSlots[0].openedBy.user.name')}
            </Styles.HeaderTitle>
          )
        }
        <Styles.HeaderDescription>
          Send requests to mentors to open slots at specified timings.
        </Styles.HeaderDescription>
      </Styles.HeaderDetails>
    </>
  )

  onNextClick = (value) => {
    const { selectedVertical, selectedGeography, selectedPaySlab
      , selectedSchool, selectedCampaign, singleSlotCount, isEditable } = this.state
    const { isSlotEditing } = this.props
    const selectedVerticalValues = selectedVertical.map(vertical => get(vertical, 'key'))
    if (value === SELECT_MENTORS) {
      if ((!selectedVertical.length || !selectedGeography || !selectedPaySlab) && isEditable) {
        customNotification('Please enter value', '', null, 'warn')
        return
      } else if ((selectedVertical.length &&
        (selectedVerticalValues.includes(B2B) || selectedVerticalValues.includes(B2B2C)))
        && (!selectedSchool.length || !selectedCampaign.length) && isEditable) {
        customNotification('Please enter School/Campaign value', '', null, 'warn')
        return
      }
    }
    if (isSlotEditing && isEditable) {
      const isB2b2c = (selectedVerticalValues.includes(B2B)
        || selectedVerticalValues.includes(B2B2C))
      if (value === SELECT_MORE) {
        if (!selectedVertical.length || !selectedGeography
          || !selectedPaySlab || (!singleSlotCount || singleSlotCount <= 0)) {
          customNotification('Please enter value', '', null, 'warn')
          return
        }
      } else if (value === SELECT_MENTORS) {
        if (isB2b2c) {
          if (!selectedSchool.length || !selectedCampaign.length) {
            customNotification('Please enter School/Campaign value', '', null, 'warn')
            return
          }
        } else if (!isB2b2c) {
          if (!selectedVertical.length || !selectedGeography
            || !selectedPaySlab || (!singleSlotCount || singleSlotCount <= 0)) {
            customNotification('Please enter value', '', null, 'warn')
            return
          }
        }
      }
    }
    this.setState({
      activeTab: value
    }, () => {
      const { mentorsList } = this.state
      if (value === SELECT_MENTORS && mentorsList.length === 0) {
        this.fetchMentors()
      }
    })
  }

  onBackClick = (value) => {
    this.setState({
      activeTab: value
    })
  }
  renderModalFooter = () => {
    const { addedSlots, activeTab, isPublishing, selectedVertical,
      selectedMentors, isEditable } = this.state
    const { isSlotEditing = false } = this.props
    const selectedVerticalValues = selectedVertical.map(vertical => get(vertical, 'key'))
    const navigateToLast = isSlotEditing && selectedVerticalValues.includes(B2C)
      && !selectedVerticalValues.includes(B2B) && !selectedVerticalValues.includes(B2B2C)
    if (activeTab === SELECTED_SLOTS) {
      return (
        <Styles.FlexContainer style={{ alignItems: 'center', width: '100%', padding: 0 }}>
          <Styles.FooterText>
            Total slots {addedSlots.length}
          </Styles.FooterText>
          <Styles.FlexContainer style={{ padding: 0 }}>
            <Styles.SecondaryButton
              onClick={() => this.closeModal()}
              style={{ marginRight: '10px' }}
            >
              {isSlotEditing ? 'Go Back' : 'Back to Editing'}
            </Styles.SecondaryButton>
            <Styles.PrimaryButton
              disabled={addedSlots.length === 0}
              onClick={() => this.onNextClick(navigateToLast ? SELECT_MENTORS : SELECT_MORE)}
            >
              Next
            </Styles.PrimaryButton>
          </Styles.FlexContainer>
        </Styles.FlexContainer>
      )
    } else if (activeTab === SELECT_MORE) {
      return (
        <Styles.FlexContainer style={{ alignItems: 'center', width: '100%', padding: 0 }}>
          <Styles.FooterText>
            Total slots {addedSlots.length}
          </Styles.FooterText>
          <Styles.FlexContainer style={{ padding: 0 }}>
            <Styles.SecondaryButton
              onClick={() => this.onNextClick(SELECTED_SLOTS)}
              style={{ marginRight: '10px' }}
            >
              Back
            </Styles.SecondaryButton>
            <Styles.PrimaryButton
              disabled={addedSlots.length === 0}
              onClick={() => this.onNextClick(SELECT_MENTORS)}
            >
              Next
            </Styles.PrimaryButton>
          </Styles.FlexContainer>
        </Styles.FlexContainer>
      )
    } else if (activeTab === SELECT_MENTORS) {
      return (
        <Styles.FlexContainer style={{ alignItems: 'center', width: '100%', padding: 0 }}>
          <Styles.FooterText>
            Total slots {addedSlots.length}
          </Styles.FooterText>
          <Styles.FlexContainer style={{ padding: 0 }}>
            <Styles.SecondaryButton
              onClick={() => this.onNextClick(navigateToLast ? SELECTED_SLOTS : SELECT_MORE)}
              style={{ marginRight: '10px' }}
            >
              Back
            </Styles.SecondaryButton>
            <Styles.PrimaryButton
              disabled={addedSlots.length === 0 || selectedMentors.length === 0
                || isPublishing || !isEditable}
              onClick={this.onPublishClick}
            >
              {isPublishing && <Styles.Spinner />}
              Publish
            </Styles.PrimaryButton>
          </Styles.FlexContainer>
        </Styles.FlexContainer>
      )
    }
  }
  closeModal = (removeAddSlots = false) => {
    const { onClose, onCancelSelectSlots, isSlotEditing = false } = this.props
    this.setState({
      addedSlots: [],
      paySlabs: [],
      activeTab: SELECTED_SLOTS,
      selectedVertical: [],
      selectedGeography: '',
      selectedPaySlab: '',
      selectedMentors: [],
      selectedSchool: [],
      selectedCampaign: [],
      isPublishing: false,
      isEditable: true
    })
    onClose()
    if (removeAddSlots || isSlotEditing) onCancelSelectSlots()
  }
  render() {
    const { visible, isSlotEditing } = this.props
    const { activeTab, ismentorLoading, selectedVertical } = this.state
    const selectedVerticalValues = selectedVertical.map(vertical => get(vertical, 'key'))
    return (
      <SupplyCalendarWrapper.WithBackdrop>
        <Styles.ModalBox visible={visible}>
          <PageLoading isfetching={ismentorLoading} fullPage={false} />
          <Styles.Header>
            <Styles.CloseIcon theme='twoTone'
              onClick={() => this.closeModal()}
            />
            {this.renderModalHeader(isSlotEditing)}
          </Styles.Header>
          {
            isSlotEditing && (
              <EditSlotTabs
                isGeneralActive={activeTab === SELECTED_SLOTS}
                onGeneralClick={() => this.onNextClick(SELECTED_SLOTS)}
                isStudentActive={activeTab === SELECT_MORE}
                onStudentClick={() => this.onNextClick(SELECT_MORE)}
                isMentorActive={activeTab === SELECT_MENTORS}
                onMentorClick={() => this.onNextClick(SELECT_MENTORS)}
                isSlotEditing={isSlotEditing}
                isB2C={selectedVerticalValues.includes(B2C) &&
                  !selectedVerticalValues.includes(B2B2C) && !selectedVerticalValues.includes(B2B)}
              />
            )
          }
          <Styles.ModalContent isSlotEditing={isSlotEditing}>
            {this.renderActiveTab()}
          </Styles.ModalContent>
          <Styles.Footer>
            {
              !isSlotEditing && (
                <EditSlotTabs
                  isGeneralActive={activeTab === SELECTED_SLOTS}
                  onGeneralClick={() => this.onNextClick(SELECTED_SLOTS)}
                  isStudentActive={activeTab === SELECT_MORE}
                  onStudentClick={() => this.onNextClick(SELECT_MORE)}
                  isMentorActive={activeTab === SELECT_MENTORS}
                  onMentorClick={() => this.onNextClick(SELECT_MENTORS)}
                  isSlotEditing={isSlotEditing}
                />
              )
            }
            <Styles.InnerFooter>
              {this.renderModalFooter()}
            </Styles.InnerFooter>
          </Styles.Footer>
        </Styles.ModalBox>
      </SupplyCalendarWrapper.WithBackdrop>
    )
  }
}

const mapStateToProps = (state) => ({
  schoolFetchingStatus: state.data.getIn(['schools', 'fetchStatus', 'schools']),
  schools: state.data.getIn(['schools', 'data']),
  campaigns: state.data.getIn(['campaigns', 'data'])
})

export default connect(mapStateToProps)(BulkAddModal)
