/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react'
import { Spin } from 'antd'
import fetchUtmDetails from '../../actions/utm/fetchUtmDetails'
import { Table } from '../../components/StyledComponents'
import UtmDetailsTableHeader from './UtmDetailsTableHeader'
import UtmDetailsTableRow from './UtmDetailsTableRow'
import UtmDashboardStyle from './UtmDashboard.style'


const UtmDetailsTable = (props) => {
  const columnsTemplate = 'minmax(700px,1fr) minmax(150px,1fr) minmax(150px,1fr) minmax(200px,1fr) minmax(200px,1fr) minmax(200px,1fr) minmax(200px,1fr) minmax(200px,1fr)'
  const minWidth = '2000px'
  const rowLayoutProps = {
    minWidth,
    columnsTemplate
  }
  const {
    isLoading,
    partnerTypeFilter,
    utmDetails,
    fetchUtmData,
    partnerTypes,
    groupedLeads
  } = props
  const [utmData, setUtmData] = React.useState([])
  React.useEffect(() => {
    setUtmData(utmDetails)
  }, [utmDetails])

  return (
        <div style={{ marginTop: '4.5vh', marginBottom: '4.5vh' }}>
          <Table style={{ width: '100%', overflowX: 'scroll' }}>
              <UtmDetailsTableHeader {...rowLayoutProps} />
              {
                isLoading ?
                  <UtmDashboardStyle.IconContainer>
                    <Spin />
                  </UtmDashboardStyle.IconContainer>
                  :
                  utmData && utmData.map(
                    (val, idx) => <UtmDetailsTableRow
                      key={val.id}
                      partnerTypeFilter={partnerTypeFilter}
                      partnerTypes={partnerTypes}
                      columnsTemplate={columnsTemplate}
                      minWidth={minWidth}
                      index={idx}
                      utmDetails={val}
                      fetchUtmData={fetchUtmData}
                      groupedLeads={groupedLeads}
                    />
                )
              }
          </Table>
        </div>
  )
}

export default UtmDetailsTable
