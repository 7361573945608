/*eslint-disable*/
import React, { useState } from 'react'
import './imageUpload.css'
import { Button } from 'antd'
import Dropzone from '../../../../../components/Dropzone'
import addQuestionBankImage from '../../../../../actions/image/addQuestionBankImage'
import uploadFile from '../../../../../actions/utils/uploadFile'
import { CheckCircleTwoTone } from '@ant-design/icons';
import questionBank from '../../../../../reducers/questionBank'
import deleteQuestionBankImage from '../../../../../actions/image/deleteQuestionBankImage'
import getFullPath from '../../../../../utils/getFullPath'

export default function ImageUpload({ option, setQuestionBankConnectIds, questionBankConnectIds, questionBankImageURI }) {
    const [selectedImage, setSelectedImage] = useState(null)
    const [selectedImageURI, setSelectedImageURI] = useState(null)
    const [imageUploaded, setImageUploaded] = useState(false)
    const [imageUploading, setImageUploading] = useState(false)
    const [uploadSuccessfull,setUploadSuccessfull] = useState(false)
    const [imageId, setImageId] = useState(null)

    const imageSelectHandler = (e) => {
        if(imageUploaded && questionBankConnectIds[option]) {
            deleteQuestionBankImage(questionBankConnectIds[option])
            setImageUploaded(false)           
        }
        setSelectedImage(e.target.files[0])
    }
    const deleteImage = () => {
        if (questionBankConnectIds[option]) {
            deleteQuestionBankImage(questionBankConnectIds[option])
            setQuestionBankConnectIds(null)
        }
        setImageUploaded(false)
    }

    const addImageMcq = async (
        imageFile,
        imageId,
    ) => {
        let data = {}
        if (imageId) {
            if (imageFile) {
                const mappingInfo = imageFile && {
                    typeId: imageId,
                    type: 'QuestionBankImage',
                    typeField: 'image'
                }
                const fileInfo = {
                    fileBucket: 'python'
                }
                if (imageFile) {
                    await uploadFile(imageFile, fileInfo, mappingInfo).then(res => data = res)
                }
            }
        }
        return data
    }

    const fileUploadHandler = async () => {
        if(!imageUploaded){
        if (selectedImage) {
            setImageUploaded(false)
            setUploadSuccessfull(false)
            setImageUploading(true)
            const res = await addQuestionBankImage()
            let imageId = res.addQuestionBankImage.id
            setImageId(imageId)
          const imageUploadedData = await addImageMcq(
                selectedImage,
                imageId,
            )
            if(imageUploadedData) {
                setQuestionBankConnectIds(imageId)
                setImageUploaded(true)
                setImageUploading(false)  
                setUploadSuccessfull(true)
                setTimeout(() => {
                    setUploadSuccessfull(false)
                }, 2000)              
            }
            
        }
    }
    }
    const defaultImageURI = getFullPath(questionBankImageURI)
    return (
        <>
            <p className="imageLabelNumber">Image {option+1}</p>
            <div className="ImageContainer">
                <div className="imageSelectorWrapper">
                    <Dropzone
                        onChange={(e) => { imageSelectHandler(e) }}
                        onClose={deleteImage}
                        width="250px"
                        margin-right="5px"
                        defaultImage={defaultImageURI || selectedImageURI}
                        onImageUrl={imgUrl => setSelectedImageURI(imgUrl)}
                        type="McqImage"
                        selectedImage={selectedImage}
                        setSelectedImage={setSelectedImage}
                        imageUploaded={imageUploaded}
                        imageId={imageId}
                        setImageUploaded={setImageUploaded}
                        questionBankConnectIds={questionBankConnectIds}
                        setQuestionBankConnectIds={() => { }}
                    />
                </div>
                <div className="uploadbtnContainer">
                    <Button icon='edit' style={{ marginInline: 'auto', cursor:imageUploading && 'wait' }} onClick={fileUploadHandler} disabled={!selectedImage || imageUploaded}>{'Upload'}</Button>
                </div>
            </div>
            <div className={uploadSuccessfull?"imageUploadedPopup":"hiddenPopup"}>
             <p>Image Uploaded</p>
             <CheckCircleTwoTone twoToneColor="#52c41a" className="sucessLogo"/>
            </div>
        </>
    )
}
