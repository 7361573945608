/* eslint-disable max-len */
import React from 'react'
import { get, min, max, sortBy } from 'lodash'
import { Spin, notification, Checkbox, Progress, Input } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { MENTOR, ADMIN, UMS_ADMIN, UMS_VIEWER, SALES_EXECUTIVE } from '../../../constants/roles'
import { TypeSVG, RatioSVG, BatchSVG } from '../../../constants/icons'
import fetchMentorForSalesExec from '../../../actions/sessions/fetchMentorForSales'
import fetchMentorSessions from '../../../actions/mentorDashboardClasses/fetchMentorSessions'
import fetchFiltersForMentorDasboardClasses from '../../../actions/mentorDashboardClasses/fetchFiltersForMentorDasboardClasses'
import getDataFromLocalStorage from '../../../utils/extract-from-localStorage'
import getIdArrForQuery from '../../../utils/getIdArrForQuery'
import ClassesStyle, { Filter, SessionCard, PaidFreeCheckboxStyle } from './Classes.style'
import { mentorDashboard as colors, TekieAmethyst } from '../../../constants/colors'
import DefaultCourseImage from './assets/defaulCoursePage.jpeg'
import '../customStyles.scss'
import fetchTrainingClasses from '../../../actions/batchDashboard/teacherTrainingClasses'

const loadingIcon = <LoadingOutlined style={{ fontSize: 16, marginRight: '8px', color: TekieAmethyst }} spin />

const { Search } = Input

const PaidFreeCheckboxOptions = [
  { label: 'Paid', value: 'pro', type: 'free/paid' },
  { label: 'Free', value: 'free', type: 'free/paid' },
]

class Classes extends React.Component {
  loadingRef
  lastKnownEventRef
  constructor(props) {
    super(props)
    this.loadingRef = React.createRef()
    this.lastKnownEventRef = React.createRef()
    this.state = {
      isFetching: true,
      isFetchingFilters: true,
      fetchedEvents: [],
      availableFilters: {
        Courses: {
          options: [],
          hardLimit: 3,
          isExpanded: false,
          optionsTolimit: 3,
          stateKey: 'courseFilterIds'
        },
        Batch: {
          options: [],
          hardLimit: 3,
          isExpanded: false,
          optionsTolimit: 3,
          stateKey: 'batchFilterIds'
        },
        School: {
          options: [],
          hardLimit: 4,
          isExpanded: false,
          optionsTolimit: 4,
          stateKey: 'schoolFilterIds'
        },
        Model: {
          options: [
            {
              key: 'normal',
              label: 'B2C',
              type: 'model',
            },
            {
              key: 'b2b2c',
              label: 'B2B2C',
              type: 'model'
            },
            {
              key: 'b2b',
              label: 'B2B',
              type: 'model'
            },
          ],
          stateKey: 'modelFilterIds'
        },
      },
      paidFreeCheckbox: ['pro'],
      courseFilterIds: [],
      batchFilterIds: [],
      schoolFilterIds: [],
      modelFilterIds: [],
      studentNameFilter: '',
      skip: 0,
      isFetchingFromIntersectionObserver: false,
      courseFilterInput: '',
      batchFilterInput: '',
      schoolFilterInput: '',
      isCoursesSearchboxHovered: false,
      isCourseSearchboxFocused: false,
      isBatchSearchboxHovered: false,
      isBatchSearchboxFocused: false,
      isSchoolSearchboxHovered: false,
      isSchoolSearchboxFocused: false,
      mentorIds: [],
    }
  }

  async componentDidMount() {
    const savedRole = getDataFromLocalStorage('login.role')
    const isAdmin = savedRole === ADMIN || savedRole === UMS_ADMIN
    || savedRole === UMS_VIEWER || savedRole === SALES_EXECUTIVE
    await this.setState({
      isAdminLoggedIn: isAdmin
    })
    await this.fetchSalesManagerMentorsIfLoggedIn()
    this.intersectionObserver = new IntersectionObserver(entries => {
      const ratio = entries[0].intersectionRatio
      const { skip } = this.state
      if (ratio > 0 && !this.state.isFetchingFromIntersectionObserver) {
        this.setState({
          skip: skip + 10,
          isFetchingFromIntersectionObserver: true,
        }, () => this.fetchMentorSessionsQuery(true))
      }
    })
    if (this.loadingRef && this.loadingRef.current) {
      this.intersectionObserver.observe(this.loadingRef.current)
    }
    if (savedRole === MENTOR) {
      fetchFiltersForMentorDasboardClasses(this.getUserIdToFilter())
    }
    if (savedRole === ADMIN || savedRole === UMS_ADMIN
    || savedRole === UMS_VIEWER) {
      fetchFiltersForMentorDasboardClasses(this.getUserIdToFilter(), isAdmin)
    }
    this.fetchMentorSessionsQuery()
  }
  componentWillUnmount() {
    this.intersectionObserver.disconnect()
  }
  componentDidUpdate(prevProps) {
    const { mentorId } = this.props.match.params
    const { mentorId: prevMentorId } = prevProps.match.params
    const { batchCodes, schools, courses } = this.props
    if (mentorId !== prevMentorId && mentorId) {
      this.setState({
        isFetching: true
      })
      fetchFiltersForMentorDasboardClasses([mentorId])
      this.fetchMentorSessionsQuery()
    }
    if (mentorId !== prevMentorId && prevMentorId && !mentorId) {
      this.setState({
        isFetching: true
      })
      this.fetchSalesManagerMentorsIfLoggedIn()
      this.fetchMentorSessionsQuery()
    }
    if (batchCodes !== prevProps.batchCodes || schools !== prevProps.schools || courses !== prevProps.courses) {
      this.createSessionFilters()
    }
    if (this.state.fetchedEvents.length >= (this.props.totalBatches + this.props.totalMentorMenteeSessions)) {
      this.intersectionObserver.disconnect()
    }
  }
  getUniqueArr = (arr, key) => {
    const unique = arr.map(e => e[key])
      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e]).map(e => arr[e])
    return unique
  }
  createSessionFilters = () => {
    const batchCodes = this.props.batchCodes && this.props.batchCodes.toJS()
    const schools = this.props.schools && this.props.schools.toJS()
    const courses = this.props.courses && this.props.courses.toJS()
    const { School, Batch, Courses } = this.state.availableFilters
    const coursesArr = []
    const batchArr = []
    const schoolArr = []
    // eslint-disable-next-line no-unused-expressions
    courses && courses.forEach(course => {
      if (get(course, 'id')) {
        coursesArr.push({
          key: get(course, 'id'),
          label: get(course, 'title'),
          type: 'course',
        })
      }
    })
    // eslint-disable-next-line no-unused-expressions
    schools && schools.forEach(school => {
      if (get(school, 'id')) {
        schoolArr.push({
          key: get(school, 'id'),
          label: get(school, 'name'),
          type: 'school',
        })
      }
    })
    // eslint-disable-next-line no-unused-expressions
    batchCodes && batchCodes.forEach(batch => {
      if (get(batch, 'id')) {
        batchArr.push({
          key: get(batch, 'id'),
          label: get(batch, 'code'),
          type: 'batch',
          createdAt: get(batch, 'createdAt'),
        })
      }
    })
    Courses.options = this.getUniqueArr(coursesArr, 'key')
    Batch.options = this.getUniqueArr(batchArr, 'key')
    School.options = this.getUniqueArr(schoolArr, 'key')
    this.setState((prevState) => ({
      isFetchingFilters: false,
      availableFilters: {
        ...prevState.availableFilters,
        Courses,
        Batch,
        School
      }
    }))
  }
  /** Utils  */
  getBgColorBasedOnSessionType = (sessionType, batchType, colorType = '') => {
    if (sessionType === 'free' || sessionType === 'pro') {
      return colors[`b2cAccent${colorType}`]
    } else if (sessionType === 'batch') {
      if (batchType === 'b2b') {
        return colors[`b2bAccent${colorType}`]
      } else if (batchType === 'b2b2c') {
        return colors[`b2b2cAccent${colorType}`]
      }
      return colors[`b2cAccent${colorType}`]
    }
    return colors[`b2cAccent${colorType}`]
  }
  getUserIdToFilter = () => {
    const savedId = getDataFromLocalStorage('login.id')
    const savedRole = getDataFromLocalStorage('login.role')
    const { isAdminLoggedIn } = this.state
    if (savedRole && savedRole === SALES_EXECUTIVE && !this.props.match.params.mentorId) {
      return this.state.mentorIds
    } else if (isAdminLoggedIn) {
      const { mentorId } = this.props.match.params
      if (mentorId) {
        return [mentorId]
      }
      return null
    } else if (savedRole === MENTOR) {
      return [savedId]
    }
    return null
  }
  mapSessionsToEventObject = (eventType, title, backgroundColor,
    // eslint-disable-next-line max-len
    borderColor, batchType, sessionType, courseId, course, currentTopicOrder, totalTopics, batchId, batchCode, totalStudent, school, classRange, topicImageUri, menteeName, menteeId, coursePackageTitle, students) => (
    {
      eventType,
      title,
      backgroundColor,
      borderColor,
      batchType,
      sessionType,
      courseId,
      course,
      currentTopicOrder,
      totalTopics,
      batchId,
      batchCode,
      totalStudent,
      school,
      classRange,
      topicImageUri,
      menteeName,
      menteeId,
      coursePackageTitle, students
    }
  )
  getClassRange= (classesArray) => {
    if (!classesArray || classesArray.length < 1) {
      return ''
    }
    if (classesArray.length === 1 && classesArray[0].grade) {
      return `Class ${Number(classesArray[0].grade.split('Grade')[1])}`
    }
    if (classesArray.length > 1 && classesArray[0].grade) {
      const range = classesArray.map(classObject => Number(classObject.grade.split('Grade')[1]))
      if (min(range) === max(range)) {
        return `Class ${min(range)}`
      }
      return `Class ${min(range)} - ${max(range)}`
    }
  }
  getAllowedQueriesBasedOnfilters = () => {
    const { batchFilterIds, schoolFilterIds, modelFilterIds, studentNameFilter } = this.state
    if (batchFilterIds.length > 0 || schoolFilterIds.length > 0 || (modelFilterIds.length > 0 && !modelFilterIds.includes('normal'))) {
      return { shouldFetchBatches: true, shouldFetchMenteeMentorSession: false }
    }
    if (studentNameFilter.length > 0) {
      return { shouldFetchBatches: false, shouldFetchMenteeMentorSession: true }
    }
    return { shouldFetchBatches: true, shouldFetchMenteeMentorSession: true }
  }
  getEnrollmentType = (order) => {
    if (order >= 2) {
      return 'pro'
    }
    return 'free'
  }
  fetchSalesManagerMentorsIfLoggedIn = async () => {
    const savedRole = getDataFromLocalStorage('login.role')
    if (savedRole && savedRole === SALES_EXECUTIVE) {
      const salesExecutiveId = getDataFromLocalStorage('login.id')
      await fetchMentorForSalesExec(salesExecutiveId).then(res => {
        const mentorIds = res.user.salesExecutiveProfile.mentors.map(({ user }) => user.id)
        this.setState({
          mentorIds
        }, () => fetchFiltersForMentorDasboardClasses(this.getUserIdToFilter()))
      })
    }
    return null
  }
  /** Mentor Session Query  */
  fetchMentorSessionsQuery = async (isFromInfiniteScroll = false) => {
    const isTeacherTraining = this.props.location.pathname.includes('/teacherTraining')
    const { batchFilterIds, schoolFilterIds, courseFilterIds, modelFilterIds, paidFreeCheckbox: paidFreeFilter, studentNameFilter, } = this.state
    const { shouldFetchBatches, shouldFetchMenteeMentorSession } = this.getAllowedQueriesBasedOnfilters()
    const skip = isFromInfiniteScroll ? this.state.skip : 0
    if (isFromInfiniteScroll && isTeacherTraining) {
      return;
    }
    await fetchMentorSessions(getIdArrForQuery(this.getUserIdToFilter()), batchFilterIds, schoolFilterIds, courseFilterIds, modelFilterIds, paidFreeFilter, studentNameFilter, 10, skip, shouldFetchBatches, shouldFetchMenteeMentorSession)
    const mentorSessions = this.props.mentorSessions && this.props.mentorSessions.toJS()
    const mentorBatches = this.props.mentorBatches && this.props.mentorBatches.toJS()
    const events = isFromInfiniteScroll ? this.state.fetchedEvents : []

    if (shouldFetchBatches && mentorBatches) {
      mentorBatches.forEach(batch => {
        events.push(this.mapSessionsToEventObject(
          'batch',
          get(batch, 'courseData.secondaryCategory'),
          this.getBgColorBasedOnSessionType('batch', get(batch, 'type')),
          this.getBgColorBasedOnSessionType(get(batch, 'currentComponent.enrollmentType'), 'Muted'),
          get(batch, 'type'),
          this.getEnrollmentType(get(batch, 'currentComponent.currentTopic.order')),
          get(batch, 'courseData.id'),
          get(batch, 'courseData.title'),
          get(batch, 'currentComponent.currentTopic.order'),
          get(batch, 'courseData.topicsMeta.count'),
          get(batch, 'id'),
          get(batch, 'code'),
          get(batch, 'studentsMeta.count'),
          get(batch, 'schoolData.name', null),
          this.getClassRange(get(batch, 'classes')),
          get(batch, 'courseData.thumbnail.uri'),
          null,
          null,
          get(batch, 'coursePackageData.title'),
          get(batch, 'studentData', []),
          // get(batch, 'schoolData.thu', []),
        ))
      })
    }
    if (shouldFetchMenteeMentorSession && mentorSessions) {
      // mentorSessions.forEach(session => {
      //   events.push(this.mapSessionsToEventObject(
      //     'mentorMenteeSession',
      //     get(session, 'courseData.secondaryCategory'),
      //     this.getBgColorBasedOnSessionType('batch', get(session, 'type')),
      //     this.getBgColorBasedOnSessionType(this.getEnrollmentType(get(session, 'currentTopic.order')) === 'pro' ? 'pro' : 'free', 'Muted'),
      //     'B2C',
      //     this.getEnrollmentType(get(session, 'currentTopic.order')) === 'pro' ? 'pro' : 'free',
      //     get(session, 'courseData.id'),
      //     get(session, 'courseData.title'),
      //     get(session, 'currentTopic.order'),
      //     get(session, 'courseData.topicsMeta.count'),
      //     get(session, 'code', null),
      //     null,
      //     null,
      //     get(session, 'schoolData.name', null),
      //     this.getClassRange(get(session, 'classes', null)),
      //     get(session, 'courseData.thumbnail.uri'),
      //     get(session, 'menteeInfo.user.name'),
      //     get(session, 'menteeInfo.user.id')
      //   ))
      // })
    }
    this.setState({
      fetchedEvents: events,
      isFetching: false,
      isFetchingFromIntersectionObserver: false,
    })
  }

  getBatchType = (batchType) => {
    if (batchType === 'normal') {
      return 'b2c'
    }
    return batchType || 'b2c'
  }

  expandFilterOptions = (filterTitle) => {
    this.setState(prevState => {
      const { options, optionsTolimit } = prevState.availableFilters[filterTitle]
      const optionsLen = options.length
      const { hardLimit } = prevState.availableFilters[filterTitle]
      return {
        availableFilters: {
          ...prevState.availableFilters,
          [filterTitle]: {
            ...prevState.availableFilters[filterTitle],
            isExpanded: optionsTolimit === hardLimit,
            optionsTolimit: optionsTolimit === hardLimit ? optionsLen : hardLimit
          }
        }
      }
    })
  }

  handleFilterChange = (e, filter) => {
    const isChecked = e.target.checked
    if (filter.type === 'course') {
      if (!isChecked) {
        this.setState({
          courseFilterIds: this.state.courseFilterIds.filter(key => key !== filter.key),
          isFetching: true
        }, () => this.fetchMentorSessionsQuery())
      } else {
        this.setState({
          courseFilterIds: [...this.state.courseFilterIds, filter.key],
          isFetching: true
        }, () => this.fetchMentorSessionsQuery())
      }
    }
    if (filter.type === 'batch') {
      if (!isChecked) {
        this.setState({
          batchFilterIds: this.state.batchFilterIds.filter(key => key !== filter.key),
          isFetching: true
        }, () => this.fetchMentorSessionsQuery())
      } else {
        this.setState({
          batchFilterIds: [...this.state.batchFilterIds, filter.key],
          isFetching: true
        }, () => this.fetchMentorSessionsQuery())
      }
    }
    if (filter.type === 'school') {
      if (!isChecked) {
        this.setState({
          schoolFilterIds: this.state.schoolFilterIds.filter(key => key !== filter.key),
          isFetching: true
        }, () => this.fetchMentorSessionsQuery())
      } else {
        this.setState({
          schoolFilterIds: [...this.state.schoolFilterIds, filter.key],
          isFetching: true
        }, () => this.fetchMentorSessionsQuery())
      }
    }
    if (filter.type === 'model') {
      if (!isChecked) {
        this.setState({
          modelFilterIds: this.state.modelFilterIds.filter(key => key !== filter.key),
          isFetching: true
        }, () => this.fetchMentorSessionsQuery())
      } else {
        this.setState({
          modelFilterIds: [...this.state.modelFilterIds, filter.key],
          isFetching: true
        }, () => this.fetchMentorSessionsQuery())
      }
    }
  }

  renderFilterOptions = (filter) => (
    <Filter.Options>
      <Filter.Checkbox
        alignItems='center'
        justifyContent='flex-start'
        onClick={(e) => this.handleFilterChange(e, filter)}
        value={filter.key}
      >
        <Filter.CheckboxLabel>
          {filter.label}
        </Filter.CheckboxLabel>
      </Filter.Checkbox>
    </Filter.Options>
  )

  getFilterSearchBarProperties = (filterTitle) => {
    if (filterTitle === 'Courses') {
      const { isCoursesSearchboxHovered, isCourseSearchboxFocused, courseFilterInput: filterInput } = this.state
      const showSearchInput = isCoursesSearchboxHovered || isCourseSearchboxFocused
      const onMouseEnter = () => this.setState({ isCoursesSearchboxHovered: true })
      const onMouseLeave = () => this.setState({ isCoursesSearchboxHovered: false })
      const onFocus = () => this.setState({ isCourseSearchboxFocused: true })
      const onBlur = () => this.setState({ isCourseSearchboxFocused: false })
      const onChange = (e) => {
        const courseSearchText = e.target.value
        this.setState({
          courseFilterInput: courseSearchText,
        })
      }
      return { showSearchInput, onMouseEnter, onMouseLeave, onFocus, onBlur, onChange, filterInput }
    }
    if (filterTitle === 'Batch') {
      const { isBatchSearchboxHovered, isBatchSearchboxFocused } = this.state
      const showSearchInput = isBatchSearchboxHovered || isBatchSearchboxFocused
      const onMouseEnter = () => this.setState({ isBatchSearchboxHovered: true })
      const onMouseLeave = () => this.setState({ isBatchSearchboxHovered: false })
      const onFocus = () => this.setState({ isBatchSearchboxFocused: true })
      const onBlur = () => this.setState({ isBatchSearchboxFocused: false })
      const onChange = (e) => {
        const batchSearchText = e.target.value
        this.setState({
          batchFilterInput: batchSearchText,
        })
      }
      return { showSearchInput, onMouseEnter, onMouseLeave, onFocus, onBlur, onChange }
    }
    if (filterTitle === 'School') {
      const { isSchoolSearchboxHovered, isSchoolSearchboxFocused } = this.state
      const showSearchInput = isSchoolSearchboxHovered || isSchoolSearchboxFocused
      const onMouseEnter = () => this.setState({ isSchoolSearchboxHovered: true })
      const onMouseLeave = () => this.setState({ isSchoolSearchboxHovered: false })
      const onFocus = () => this.setState({ isSchoolSearchboxFocused: true })
      const onBlur = () => this.setState({ isSchoolSearchboxFocused: false })
      const onChange = (e) => {
        const schoolSearchText = e.target.value
        this.setState({
          schoolFilterInput: schoolSearchText,
        })
      }
      return { showSearchInput, onMouseEnter, onMouseLeave, onFocus, onBlur, onChange }
    }
  }

  renderFilterContainerSearchBar = (filterTitle) => {
    const { showSearchInput, onMouseEnter, onMouseLeave, onFocus, onBlur, onChange, filterInput } = this.getFilterSearchBarProperties(filterTitle)
    return (
      <Filter.SearchBoxContainer
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onFocus={onFocus}
        onBlur={onBlur}
        hover={showSearchInput}
        showSearchInput={showSearchInput}
      >
        <Filter.FilterSearchboxSearchInput type='text' placeholder='Search' value={filterInput} showSearchInput={showSearchInput} onChange={(e) => onChange(e)} />
        <Filter.FilterSearchBoxMagnifyingGlassIconRight />
      </Filter.SearchBoxContainer>

    )
  }

  filterAvailableFiltersLabel = (filterTitle, filterArray) => {
    if (filterTitle === 'Courses') {
      const { courseFilterInput } = this.state
      const filteredCourses = filterArray.filter(course => course.label.toLowerCase().indexOf(courseFilterInput.toLowerCase()) > -1)
      return filteredCourses
    }
    if (filterTitle === 'Batch') {
      const { batchFilterInput } = this.state
      const filteredBatch = filterArray.filter(batch => batch.label.toLowerCase().indexOf(batchFilterInput.toLowerCase()) > -1)
      return filteredBatch
    }
    if (filterTitle === 'School') {
      const { schoolFilterInput } = this.state
      const filteredSchool = filterArray.filter(school => school.label.toLowerCase().indexOf(schoolFilterInput.toLowerCase()) > -1)
      return filteredSchool
    }
    return filterArray
  }

  renderFilterContainer = () => {
    const { availableFilters } = this.state
    if (this.props.location.pathname.includes('/teacherTraining')) {
      return <></>
    }
    return (
      <Filter>
        <Filter.HeaderText fontSize='16px' paddingBottom='12px'>
          Filters
        </Filter.HeaderText>
        {Object.keys(availableFilters).map(filterTitle => (
          availableFilters[filterTitle].options.length > 0 ? (
            <Filter.SectionContainer>
              <Filter.FilterSearchBoxContainer>
                <Filter.HeaderText paddingBottom='8px'>
                  {filterTitle}
                </Filter.HeaderText>
                {filterTitle !== 'Model' ? this.renderFilterContainerSearchBar(filterTitle) : null}
              </Filter.FilterSearchBoxContainer>
              <Filter.FilterChecboxContainer>
                {this.filterAvailableFiltersLabel(filterTitle, availableFilters[filterTitle].options).length > 0 ? (
                  <Checkbox.Group value={this.state[availableFilters[filterTitle].stateKey]}>
                    {
                sortBy(this.filterAvailableFiltersLabel(filterTitle, availableFilters[filterTitle].options), 'createdAt')
                .slice(0, availableFilters[filterTitle].optionsTolimit)
                .map(filter => (
                this.renderFilterOptions(filter, availableFilters[filterTitle].stateKey)
              ))}
                  </Checkbox.Group>
              ) : <Filter.CheckboxLabel>No Filters</Filter.CheckboxLabel>}
              </Filter.FilterChecboxContainer>
              {availableFilters[filterTitle].optionsTolimit && availableFilters[filterTitle].options.length > 3 && (
                <Filter.MoreOptionText onClick={() => {
                    this.expandFilterOptions(filterTitle)
                  }}
                >
                {
                  // eslint-disable-next-line no-nested-ternary
                  this.filterAvailableFiltersLabel(filterTitle, availableFilters[filterTitle].options).length > 3 ? (
                  this.filterAvailableFiltersLabel(filterTitle, availableFilters[filterTitle].options)
                  .slice(availableFilters[filterTitle].optionsTolimit).length === 0 ?
                  'Collapse All'
                  : (
                    `+${this.filterAvailableFiltersLabel(filterTitle, availableFilters[filterTitle].options)
                    .slice(availableFilters[filterTitle].optionsTolimit).length} More`
                  )
                  ) : null
                }

                </Filter.MoreOptionText>
              )}
            </Filter.SectionContainer>
          ) : null
        ))}
      </Filter>
    )
  }

  renderSubFilters = () => (
    <div style={{ width: '100%', justifyContent: 'flex-end', alignContent: 'center' }}>
      Show
    </div>
  )

  renderClassDetails = ({ type, value, icon }) => (
    <SessionCard.ContentClassDetail style={this.props.location.pathname.includes('/teacherTraining') && {
      marginLeft: 8,
      width: 250,
    }}>
      <ClassesStyle.Icon
        fillSvg='#9E9E9E'
        theme='twoTone'
        component={icon}
      />
      <div className='classDetailsContainer' style={{ marginLeft: '8px' }}>
        <span className='classDetailsText'>{type}</span>
        <SessionCard.ContentText style={this.props.location.pathname.includes('/teacherTraining') && {
          whiteSpace: 'wrap'
        }}>{ value }</SessionCard.ContentText>
      </div>
    </SessionCard.ContentClassDetail>
  )

  getCompletedTopicsMeta = (session) => {
    const completedTopicsMeta = get(session, 'currentTopicOrder', 0)
    const totalTopics = get(session, 'totalTopics', 45)
    return Math.round((completedTopicsMeta / totalTopics) * 100)
  }

  onPaidFreeCheckboxChange = checked => {
    this.setState({
      paidFreeCheckbox: [...checked],
      isFetching: true
    }, () => {
      this.fetchMentorSessionsQuery()
    })
  };

  renderPaidFreeCheckbox = () => (
    <PaidFreeCheckboxStyle>
      <PaidFreeCheckboxStyle.CheckboxText>Show: </PaidFreeCheckboxStyle.CheckboxText>
      <PaidFreeCheckboxStyle.CustomCheckbox
        options={PaidFreeCheckboxOptions}
        defaultValue={['pro']}
        onChange={this.onPaidFreeCheckboxChange}
      />
    </PaidFreeCheckboxStyle>
  )

  onNameSearch = value => {
    this.setState({
      studentNameFilter: value,
      isFetching: true
    }, () => this.fetchMentorSessionsQuery())
  }

  onSessionCardClickHandle = (eventType, batchId, menteeId, courseId) => {
    if (this.props.location.pathname.includes('/teacherTraining')) {
      return this.props.history.push(`/teacherTraining/classes/${batchId}`)
    }
    if (eventType === 'batch' && batchId) {
      return this.props.history.push(`/mentorClasses/?batchId=${batchId}`)
    }
    if (menteeId && courseId) {
      return this.props.history.push(`/mentorClasses/?userId=${menteeId}&courseId=${courseId}`)
    }
    notification.error({
      message: 'Not A Valid Mentor Session'
    })
  }

  render() {
    const { fetchedEvents } = this.state
    const { totalBatches, totalMentorMenteeSessions } = this.props
    const isTeacherTraining = this.props.location.pathname.includes('/teacherTraining')
    return (
      <div id='mentor-dashboard-calender-container'>
        {(this.state.isFetching || this.state.isFetchingFilters) ? (
          <>
            <div className='loading-container show'>
              <div className='loading-bar-container'>
                <div />
              </div>
            </div>
            <div className='mentor-dashboard-calender-loading-container'>
              <Spin indicator={loadingIcon} />
              <div className='mentor-dashboard-calender-loading'>Fetching Sessions...</div>
            </div>
          </>
        ) : null}
        <ClassesStyle.PageTitle>All Classes</ClassesStyle.PageTitle>
        <ClassesStyle.SearchBoxDiv>
          <Search placeholder='Search Students' allowClear onSearch={this.onNameSearch} style={isTeacherTraining ? ClassesStyle.searchBoxCSSTeacherTraining : ClassesStyle.searchBoxCSS} />
          {!isTeacherTraining && this.renderPaidFreeCheckbox()}
        </ClassesStyle.SearchBoxDiv>
        <ClassesStyle.Row>
          {this.renderFilterContainer()}
          <ClassesStyle.SessionsContainer style={isTeacherTraining ? { display: 'flex', flexDirection: 'row',  flexWrap: 'wrap'  } : {}}>
            {/* {this.renderSubFilters()} */}
            {(fetchedEvents && fetchedEvents.length > 0) && fetchedEvents.map((session, i) => (
              <SessionCard
                onClick={() => 
                  this.onSessionCardClickHandle(
                    session.eventType, 
                    session.batchId, 
                    session.menteeId, 
                    session.courseId
                )}
                style={isTeacherTraining && { width: 516, marginRight: (i + 1) % 2 === 0 ? 0 : 50 }}
              >
                <SessionCard.ImageContainer>
                {
                  get(session, 'topicImageUri', null) ?
                  (
                    <SessionCard.Image bgImage={get(session, 'topicImageUri', null)} />
                  ) :
                  (
                    <SessionCard.DefaultImage src={DefaultCourseImage} alt='course' />
                  )
                }
                </SessionCard.ImageContainer>
                <SessionCard.DetailsContainer>
                {!isTeacherTraining ? (
                  <ClassesStyle.FlexContainer style={{ paddingBottom: '12px' }}>
                    <SessionCard.SessionIndicator bgColor={get(session, 'backgroundColor')} />
                    <SessionCard.HeaderText>
                      {this.getBatchType(get(session, 'batchType'))}
                    </SessionCard.HeaderText>
                    <SessionCard.HeaderTag
                      bgColor={get(session, 'sessionType') === 'free' ? '#333333' : null}
                    >
                      {get(session, 'sessionType') !== 'pro' ? 'Demo' : 'Paid'}
                    </SessionCard.HeaderTag>
                    {get(session, 'classRange') ? (
                      <SessionCard.ClassRangeText>
                        <p>{get(session, 'classRange')}</p>
                      </SessionCard.ClassRangeText>) : null }
                  </ClassesStyle.FlexContainer>
                )  : <></>
                }

                  {!isTeacherTraining && <SessionCard.CourseCategory>{get(session, 'title') ? get(session, 'title') : null }</SessionCard.CourseCategory>}
                  <SessionCard.CourseName>{get(session, 'coursePackageTitle') ? get(session, 'coursePackageTitle') : get(session, 'course', '') }</SessionCard.CourseName>
                  <ClassesStyle.FlexContainer style={isTeacherTraining && { display: 'block' }}>
                    {session.eventType === 'batch' ? this.renderClassDetails({ type: 'Batch', value: get(session, 'batchCode', '-'), icon: BatchSVG }) : null}
                    {session.eventType === 'batch' 
                      ? isTeacherTraining 
                        ? this.renderClassDetails({
                            type: 'Students',
                            value: get(session, 'students', []).map(student => get(student, 'userData.name')).join(', '),
                            icon: RatioSVG 
                          })
                        : this.renderClassDetails({ type: 'Students', value: get(session, 'totalStudent'), icon: RatioSVG })
                      : this.renderClassDetails({ type: 'Student', value: get(session, 'menteeName'), icon: RatioSVG })}
                    {get(session, 'school') && (
                        this.renderClassDetails({ type: 'School', value: get(session, 'school'), icon: TypeSVG })
                    )}
                  </ClassesStyle.FlexContainer>
                  {!isTeacherTraining && (
                    <ClassesStyle.FlexContainer>
                      <SessionCard.ProgressText>Progress</SessionCard.ProgressText>
                      <Progress
                        style={{
                          width: '100%'
                        }}
                        percent={this.getCompletedTopicsMeta(session)}
                        strokeColor={TekieAmethyst}
                      />
                    </ClassesStyle.FlexContainer>
                  )}
                </SessionCard.DetailsContainer>
              </SessionCard>
            ))}
            {!isTeacherTraining && (
              <div
                ref={this.loadingRef}
                style={ClassesStyle.loadingCSS}
              >
                {(fetchedEvents && fetchedEvents.length > 0 && fetchedEvents.length > 3 && fetchedEvents.length <= (totalBatches + totalMentorMenteeSessions) && (totalBatches + totalMentorMenteeSessions) > 20) ? (
                  <><Spin indicator={loadingIcon} /><div className='mentor-dashboard-calender-loading'>Fetching More Sessions...</div></>
                ) : null}
              </div>
            )}
          </ClassesStyle.SessionsContainer>
        </ClassesStyle.Row>
      </div>
    )
  }
}

export default Classes
