const ratingHeaderConfig = [
  {
    label: 'Kid Name',
    key: 'name',
  },
  {
    label: 'Course',
    key: 'course',
  },
  {
    label: 'Comment',
    key: 'comment',
  },
  {
    label: 'Rating',
    key: 'rating',
  },
  {
    label: 'Topic',
    key: 'topic',
  },
  {
    label: 'Slot',
    key: 'slot',
  },
  {
    label: 'Booking Date',
    key: 'date',
  }
]

export default ratingHeaderConfig

