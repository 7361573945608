/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react'
import './coursesCard.scss'
import { batchesSvg, databaseIcon, sessionsSvg } from './assets'
import format from 'date-fns/format'
import { fetchMenteeMentorSessionData, fetchUserProfileData } from '../../fetchData'
import { fetchCoursesAllotedData } from '../../fetchData'
import getFullPath from '../../../../utils/getFullPath'
import { get } from 'lodash'

export default function CoursesCard({ studentCourseTitle, courseSessions, studentId, courseId, courseDetails, courseThumbnail, courseType, batchSessions, setCurrentTab }) {
    const sessionsLogo = sessionsSvg()
    const batchLogo = batchesSvg()
    const innerProgressOne = useRef()
    const databaseLogo = databaseIcon()
    const innerProgressTwo = useRef()
    const [completedSessions, setCompletedSessions] = useState(0)
    const [expandCard, setExpandCard] = useState(false)
    const [sessionData, setSessionData] = useState([])
    const [latestCourseDetails, setLatestCourseDetails] = useState()
    const [foundIds, setFoundIds] = useState([])
    const [homeworkProfiecientData, setHomeworkProfiecientData] = useState({
        proficientTopics: [],
        familiarTopics: [],
        masteredTopics: [],
    })

    const expandClassHandler = async () => {
        let a = []
        setExpandCard(!expandCard)
        const res = await fetchCoursesAllotedData(studentId, courseId)
        courseSessions.map((session, index) => {
            get(res, 'mentorMenteeSessions').map(singleSessionData => {
                if (get(singleSessionData, 'topic.id') === get(session, 'id')) {
                    a.push(singleSessionData)
                }
            })
        })
        setFoundIds(a)
    }
    const homeworkProficienyData = async (studentId) => {
        let proficientTopicsArr = []
        let familiarTopicsArr = []
        let masteredTopicsArr = []
        const res = await fetchUserProfileData(studentId)
        if (res && res.userProfiles !== undefined) {
            if (get(res, 'userProfiles[0]').length > 0) {
                if (get(res, 'userProfiles[0]').proficientTopics.length > 0) {
                    get(res, 'userProfiles[0].proficientTopics').map(item => {
                        proficientTopicsArr.push(item.id)
                    })
                    setHomeworkProfiecientData({ ...homeworkProfiecientData, proficientTopics: proficientTopicsArr })
                }
                if (get(res, 'userProfiles[0].familiarTopics').length > 0) {
                    get(res, 'userProfiles[0].familiarTopics').map(item => {
                        familiarTopicsArr.push(item.id)
                    })
                    setHomeworkProfiecientData({ ...homeworkProfiecientData, proficientTopics: familiarTopicsArr })
                }
                if (get(res, 'userProfiles[0].masteredTopics').length > 0) {
                    get(res, 'userProfiles[0].masteredTopics').map(item => {
                        masteredTopicsArr.push(item.id)
                    })
                }
            }
            setHomeworkProfiecientData({ ...homeworkProfiecientData, proficientTopics: masteredTopicsArr })
        }
    }

    const homeworkProficiencyHandler = (topicId) => {
        let proficiencyOutput = ''
        if (get(homeworkProfiecientData, 'proficientTopics', []).includes(topicId)) {
            proficiencyOutput = 'Proficient'
        }
        else if (get(homeworkProfiecientData, 'familiarTopics', []).includes(topicId)) {
            proficiencyOutput = 'Familiar'
        }
        else if (get(homeworkProfiecientData, 'masteredTopics',[]).includes(topicId)) {
            proficiencyOutput = 'Mastered'
        }
        if (proficiencyOutput === '') {
            proficiencyOutput = '-'
            return proficiencyOutput
        }
        else {
            return proficiencyOutput
        }
    }

    useEffect(() => {
        const fetchMentorMenteeSession = async () => {
            const res = await fetchMenteeMentorSessionData(studentId, courseId)
            setSessionData(get(res, 'mentorMenteeSessions'))
            setCompletedSessions(get(res, 'mentorMenteeSessions').length)
            if (get(res, 'mentorMenteeSessions').length !== 0) {
                courseSessions.map(session => {                    
                    if (get(session, 'order') === get(res, 'mentorMenteeSessions[0].topic.order') + 1) {
                        setLatestCourseDetails(session)
                    }
                })
            }
            else if (res.mentorMenteeSessions.length === 0) {
                setLatestCourseDetails(courseSessions[0])
            }
        }
        fetchMentorMenteeSession()
        homeworkProficienyData(studentId)
    }, [])

    const completedSessionsPercentageHandler = () => {
        if (courseSessions) {
            if (completedSessions === 0) {
                return 0
            }
            else {
                let percentage
                percentage = ((completedSessions / courseSessions.length) * 100).toFixed(2)
                return percentage
            }
        }
    }

    useEffect(() => {
        innerProgressOne.current.style.width = `${completedSessionsPercentageHandler()}%`
        innerProgressTwo.current.style.width = `${completedSessionsPercentageHandler()}%`
    }, [completedSessions])

    const actualDateHandler = (topicId) => {
        let date = ''
        let found = false
        if (expandCard && foundIds.length > 0) {
            foundIds.map(
                foundId => {
                    if (foundId.topic.id === topicId) {
                        date = `${((get(foundId, 'sessionStartDate').split('-')[2]).split('T')[0])} ${format(get(foundId, 'sessionStartDate'), 'MMMM')} ${get(foundId, 'sessionStartDate').split('-')[0]}`
                        found = true
                    }
                    else if (found !== true) {
                        date = 'To be decided'
                    }
                })
        }
        else if (foundIds.length === 0) {
            date = 'To be decided'
        }
        return date
    }

    const homeworkSubmittedHandler = (topicId, index) => {
        let status = ''
        if (expandCard && foundIds.length !== 0) {
            foundIds.map(
                foundId => {
                    if (foundId.index === index) {
                        if (foundId.homeworkStatus === true) {
                            status = `Attempted`
                        } else status = `Not Attempted`
                    }
                    else status = 'Not Attempted'
                }
            )
        }
        else {
            status = 'Not Atempted'
        }
        return status
    }

    const scheduledDateHandler = (topicId) => {
        let date = ''
        let found = false
        if (expandCard && foundIds.length > 0) {
            foundIds.map(
                foundId => {
                    if (foundId.topic.id === topicId) {
                        date = `${(get(foundId, 'menteeSession.bookingDate').split('-')[2]).split('T')[0]} ${format(get(foundId, 'menteeSession.bookingDate'), 'MMMM')} ${get(foundId, 'menteeSession.bookingDate').split('-')[0]}`
                        found = true
                    }
                    else if (!found) {
                        date = 'To be decided'
                    }
                })
        }
        else if (foundIds.length === 0) {
            date = 'To be decided'
        }
        return date
    }
    
    const redirectToReportHandler = () => {
        setCurrentTab('Reports')
    }
    return (
        <>
            <div className={expandCard ? "expandedSingleCourseCardComponent" : "singleCourseCardComponent"}>
                <div className="singleCourseCardWrapper">
                    <div className="imageContainer">
                        <img src={getFullPath(courseThumbnail)} alt="" className="courseThumbnailImage" />
                    </div>
                    <div className="courseDetailsAndStatusContainer">
                        <h3>{studentCourseTitle}</h3>
                        <div className="sessionsDetails">
                            {sessionsLogo}
                            <p>Sessions</p>
                            <p>{`${completedSessions}/${courseSessions.length}`}</p>
                        </div>
                        <div className="courseProgressDetails">
                            <p className="progressText">Progress</p>
                            <div className="courseProgressBarTrack">
                                <div className="progress" ref={innerProgressOne}>
                                </div>
                            </div>
                            <p> {`${completedSessionsPercentageHandler()}%`}</p>
                        </div>
                    </div>
                </div>
                <hr className="classAndCourseSeperator" />
                <div className="singleClassCardWrapper">
                    <div className="classImageContainer">
                        <img className="classImg" src={getFullPath(get(latestCourseDetails,'thumbnailSmall.uri'))} alt="" width="39px" height="47px" />
                    </div>
                    <div className="classDetailsAndStatusContainer">
                        <p className="classStatus">
                            Upcoming Class
                        </p>
                        <h4>
                            {get(latestCourseDetails, 'title')}
                        </h4>
                        {courseType === 'batch session' && <div className="batchDetails">
                            {batchLogo}
                            <p style={{ fontSize: "10px" }}>Batch</p>
                            <p className="batchName">{get(batchSessions, 'code')}</p>
                        </div>}
                    </div>
                    <div className="viewReportDetailsAndExpandBtnContainer">                   
                    <button className="expandClassesBtn" onClick={expandClassHandler}>{expandCard ? 'Hide All Class' : 'View all class'}</button>                    
                    <button className="viewReportDetailsBtn" onClick={redirectToReportHandler}>
                        {databaseLogo}
                        View Report
                        </button>
                    </div>
                </div>
            </div>
            <div className={expandCard ? "expandedSingleCourseCardTableComponent" : "collapsedSingleCourseCardComponent"}>
                <div className="expandedSingleCourseCardSessionDetails">
                    <div className="expandedSingleCourseCardSessionDetailsText">
                        <div className="sessionsCompleteNumbers">
                            <p>Sessions Complete</p>
                            <p className="sessionsCompleteNumberDetails">{`${completedSessions}/${courseSessions.length}`}</p>
                        </div>
                        <p className="expandedCardSessionProgressPercentageText">{`${completedSessionsPercentageHandler()}%`}</p>
                    </div>
                    <div className="expandedSingleCourseCardSessionProgressBarOuter">
                        <div className="expandedSingleCourseCardSessionProgressBarInner" ref={innerProgressTwo}>

                        </div>
                    </div>
                </div>
                {expandCard && <table className="expandedComponentSessionDetailsTableContainer">
                    <thead>
                        <th>Session</th>
                        <th>Scheduled</th>
                        <th>Actual Date</th>
                        <th>Homework Status</th>
                        <th>Homework proficiency</th>
                    </thead>
                    {courseSessions.map((courseSession, index) => (
                        <tr>
                            <td style={{ display: "flex", alignItems: "center" }}>
                                <img src={getFullPath(get(courseSession, 'thumbnailSmall.uri'))} alt="courseThumbnail" className="topicThumbnailImage" />
                                <h4>{get(courseSession, 'title')}</h4>
                            </td>
                            <td>{scheduledDateHandler(get(courseSession, 'id'))}</td>
                            <td>
                                {actualDateHandler(get(courseSession, 'id'))}
                            </td>
                            <td>{homeworkSubmittedHandler(get(courseSession, 'id'), index)}</td>
                            <td>{homeworkProficiencyHandler(get(courseSession, 'id'))}</td>
                        </tr>
                    ))}
                </table>}
            </div>
        </>
    )
}
