/*eslint-disable*/
import gql from "graphql-tag";
import duck from "../../duck";

const addQuestionBankImage = async () => {
    const res = duck.query({
        query:gql`
        mutation{
            addQuestionBankImage(input:{}) {
            id
          }
        }`,
        type:"addQuestionBankImage/add",
        key:"addQuestionBankImage",
 })
 return res
}

export default addQuestionBankImage;