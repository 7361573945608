import gql from 'graphql-tag'
import { get } from 'lodash';
import duck from '../../duck'
import AddMentorProfile from './AddMentorProfile';
import UpdateMentorProfile from './UpdateMentorProfile';

const AddUsers = async (schoolId,mentorProfileRowData) =>
  duck.query({
    query: gql`
    mutation {
        addUser(
          input: {
            role: mentor
            secondaryRole: schoolTeacher
            name: "${get(mentorProfileRowData,'Name')}"
            email: "${get(mentorProfileRowData,'Email')}"
            password:"${get(mentorProfileRowData, 'setPassword')}"
            ${get(mentorProfileRowData, 'Phone', '') ? `
            phone: { countryCode: "${get(mentorProfileRowData,'CountryCode')}", number: "${get(mentorProfileRowData,'Phone')}" }
            ` : ''}
            displayName: "${get(mentorProfileRowData,'displayName', '')}"
          }
        ) {
          id
          mentor: mentorProfile{
            id
          }
        }
      }
    `,
    type: 'users/add',
    key: 'users',
    changeExtractedData: async (extractedData, originalData) => {
      const userConnectId = get(originalData, 'addUser.id')
      const mentorProfileId = get(originalData, 'addUser.mentor.id')
      await UpdateMentorProfile(mentorProfileId, schoolId, mentorProfileRowData, userConnectId);
      return { ...extractedData }
    }
  })

export default AddUsers
