import { connect } from 'react-redux'
import { filterKey } from '../../../../utils/data-utils'
import Classrooms from './Classrooms'

const mapStateToProps = (state) => ({
    schoolClassesAddStatus: state.data.getIn(['schoolClasses', 'addStatus', 'schoolClasses']),
    classroomCoursePackagesFetchStatus: state.data.getIn(['coursePackages', 'fetchStatus', 'coursePackages']),
    coursePackagesData: filterKey(state.data.getIn(['coursePackages', 'data']), 'coursePackages'),
    classroomTeachersFetchStatus: state.data.getIn(['users', 'fetchStatus', 'classroomTeachers']),
    classroomTeachers: filterKey(state.data.getIn(['user', 'data']), 'classroomTeachers'),
    studentProfiles: filterKey(state.data.getIn(['studentProfiles', 'data']), 'studentProfiles'),
    studentProfilesFetchStatus: state.data.getIn(['studentProfiles', 'fetchStatus', 'studentProfiles']),
    classroomBatches: filterKey(state.data.getIn(['batch', 'data']), 'classroomBatches'),
    classroomBatchesFetching: state.data.getIn(['batch', 'fetchStatus', 'classroomBatches']),
    classroomBatchesAddStatus: state.data.getIn(['batch', 'addStatus', 'trainingBatches']),
    classroomBatchesUpdateStatus: state.data.getIn(['batch', 'updateStatus', 'trainingBatches']),
    classroomBatchesDeleteStatus: state.data.getIn(['batch', 'deleteStatus', 'trainingBatches']),
    classroomGradesFetchStatus: state.data.getIn(['classroomGrades', 'fetchStatus', 'classroomGrades']),
    classroomGrades: state.data.getIn(['classroomGrades', 'data']),
    schoolClassesDeleteStatus: state.data.getIn(['schoolClasses', 'deleteStatus', 'schoolClasses']),
    schoolClassAddFailure: state.data.getIn([
        'errors',
        'schoolClasses/add'
    ]),
})

export default connect(mapStateToProps)(Classrooms)
