const headerConfig = [
  {
    label: 'Student Name',
    key: 'studentName',
  },
  {
    label: 'Grade',
    key: 'studentGrade',
  },
  {
    label: 'Section',
    key: 'studentSection',
  },
  {
    label: 'Parent Name',
    key: 'parentName',
  },
  {
    label: 'Email',
    key: 'email',
  },
  {
    label: 'Phone Number',
    key: 'phoneNumber',
  },
  {
    label: 'Login Link',
    key: 'linkUri',
  },
  {
    label: 'Created At',
    key: 'createdAt',
  },
]

export default headerConfig
