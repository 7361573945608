/* eslint-disable no-console */
import uploadFile from '../utils/uploadFile'


const fileUpload = async ({
  file, bucket
}) => {
  let status = {}
  if (file && bucket) {
    const fileInfo = {
      fileBucket: bucket
    }
    if (file) {
      status = uploadFile(file, fileInfo)
    }
  }
  return status
}

export default fileUpload
