import React from 'react'
import Container from './CoursePackageMaker.styles'
import { PlusOutlined } from '@ant-design/icons';
import { Input, notification, Select, Spin } from 'antd';
import { Link } from 'react-router-dom'
import Package from './components/Package/Package';
import fetchCoursePackages from '../../actions/CoursePackageMaker/fetchCoursePackages';
import { get, sortBy } from 'lodash';
import fetchCoursePackagesMeta from '../../actions/CoursePackageMaker/fetchCoursePackagesMeta';
import FilterModal from './components/FilterModal/FilterModal';
import filterSvg from '../../assets/filter.svg'
import { coursesTabs } from '../AddCourse/AddCourse';
const { Search } = Input;

const packageTabs = [
  {
    label: 'All Packages',
    key: 'allPackages'
  },
  {
    label: 'Published Packages',
    key: 'published'
  },
  {
    label: 'Unpublished Packages',
    key: 'unpublished'
  },
]
class CoursePackageMaker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      packages: [],
      currentPage: 1,
      totalPackageCount: 0,
      searchString: '',
      activeTab: 'allPackages',
      perPage: 20,
      skip: 0,
      showFilterModal: false,
      filterOptions: coursesTabs,
      searchKey: coursesTabs[0].key,
    }
  }
  componentDidMount = async () => {
    await this.fetchCoursePackagesWithFilter()
  }
  fetchCoursePackagesWithFilter = () => {
    const { perPage, skip, searchString, activeTab, searchKey } = this.state
    let filterString = ''
    if (searchKey !== 'allCourses') filterString += `{
      packageType: ${searchKey}
    }`
    if (searchString) filterString += `{
      title_contains:"${searchString}"
    }`
    if (activeTab !== 'allPackages') filterString += `{
      status:${activeTab}
    }`
    fetchCoursePackages({ filterString, perPage, skip })
    fetchCoursePackagesMeta(filterString);
  }
  componentDidUpdate = async (prevProps) => {
    const { coursePackagesData, fetchCoursePackages, updateCoursePackagesStatus, packageUpdateFailure } = this.props;
    if (fetchCoursePackages && !get(fetchCoursePackages.toJS(), 'loading')
      && get(fetchCoursePackages.toJS(), 'success')
      && prevProps.fetchCoursePackages !== fetchCoursePackages) {
      const packages = coursePackagesData && coursePackagesData.toJS() || []
      this.setState({ packages: sortBy(packages, 'createdAt').reverse() })
    }
    if (updateCoursePackagesStatus && !get(updateCoursePackagesStatus.toJS(), 'loading')
      && get(updateCoursePackagesStatus.toJS(), 'success') &&
      (prevProps.updateCoursePackagesStatus !== updateCoursePackagesStatus)) {
      const packages = coursePackagesData && coursePackagesData.toJS() || []
      this.setState({ packages: sortBy(packages, 'createdAt').reverse() })
      notification.success({
        message: 'Package updated successfully'
      })
    } else if (updateCoursePackagesStatus && !get(updateCoursePackagesStatus.toJS(), 'loading')
      && get(updateCoursePackagesStatus.toJS(), 'failure') &&
      (prevProps.packageUpdateFailure !== packageUpdateFailure)) {
      if (packageUpdateFailure && packageUpdateFailure.toJS().length > 0) {
        const errors = packageUpdateFailure.toJS().pop()
        notification.error({
          message: get(get(errors, 'error').errors[0], 'message')
        })
      }
    }
  }
  onSearch = (result) => {
    if (result.length) {
      this.setState({
        searchString: result
      }, this.fetchCoursePackagesWithFilter)
    }
  }
  onPageChange = (page) => {
    this.setState({
      currentPage: page,
      skip: page - 1
    }, this.fetchCoursePackagesWithFilter)
  }

  handleSearchKeyChange = (value) => {
    this.setState({
      searchKey: value,
    }, this.fetchCoursePackagesWithFilter)
  }

  setPackage = (value) => {
    this.setState({
      packages: value,
    })
  }

  closeFilterModal = () => {
    this.setState({ showFilterModal: false })
  }

  applyFilters = (filters) => {
    const { skip, perPage } = this.state
    let { minGrade, maxGrade, courseType, courses } = filters
    let filterString = ''
    if (minGrade.length > 0) {
      if (minGrade.includes('Select All')) {
        minGrade = Array.from({ length: 12 }, (_, i) => i+1)
      } else {
        minGrade = minGrade.map(item => parseInt(item[item.length-1]))
      }
    }
    if (maxGrade.length > 0) {
      if (maxGrade.includes('Select All')) {
        maxGrade = Array.from({ length: 12 }, (_, i) => i+1)
      } else {
        maxGrade = maxGrade.map(item => parseInt(item[item.length-1]))
      }
    }
    if (courseType.length > 0) {
      if (courseType.includes('Select All')) {
        courseType = courseType.filter(item => item != 'Select All')
      }
    }
    if (courses.length > 0) {
      if (courses.includes('Select All')) {
        courses = courses.filter(item => item != 'Select All')
      }
    }
    if (minGrade.length > 0) {
      filterString += `{ minGrade_in: [${minGrade}] }`
    }
    if (maxGrade.length > 0) {
      filterString += `{ maxGrade_in: [${maxGrade}] }`
    }
    if (courseType.length > 0) {
      courseType = courseType.map(item => item.toLowerCase())
      filterString += `{ category_in: [${courseType}] }`
    }
    if (courses.length > 0) {
      filterString += `{ courses_some: { title_in: ${JSON.stringify(courses)} } }`
    }
    fetchCoursePackages({ filterString, perPage, skip })
    fetchCoursePackagesMeta(filterString);
    this.closeFilterModal()
  }

  renderTopNavigation = () => (
    <>
      <Container.FlexContainer>
        <Select
          style={{ minWidth: '200px', marginRight: '15px' }}
          value={this.state.searchKey}
          onChange={this.handleSearchKeyChange}
        >
          {
            this.state.filterOptions.map(option => (
              <Option
                key={option.key}
              >{option.label}
              </Option>
            ))
          }
        </Select>
        <Container.FlexContainer>
          {packageTabs.map(navItem => (
            <Container.NavButton
              onClick={() => {
                this.setState({
                  activeTab: navItem.key
                }, this.fetchCoursePackagesWithFilter)
              }}
              isSelected={this.state.activeTab === navItem.key}
            >
              {navItem.label}
            </Container.NavButton>
          ))}
        </Container.FlexContainer>
      </Container.FlexContainer>
      <Link to="/coursePackages/create"><Container.NavButton><PlusOutlined /> Create New Package</Container.NavButton></Link>
    </>
  )
  renderSearchBarContainer = () => (
    <>
      <Search
        placeholder="input search text"
        style={{ width: 300, marginRight: '20px' }}
        onSearch={(e) => { this.onSearch(e) }}
        onChange={(e) => {
          if (e.target.value === '') {
            this.setState({
              searchString: ''
            }, this.fetchCoursePackagesWithFilter)
          }
        }}
      />
      <Container.FilterButton onClick={() => this.setState({ showFilterModal: true })}>
        <i style={{ backgroundImage: `url(${filterSvg})` }}></i> Filter
      </Container.FilterButton>
    </>
  )
  render() {
    const { fetchCoursePackages, coursePackagesMetaData } = this.props;
    const { packages } = this.state
    return (
      <Container>
        <Container.FlexContainer justify='space-between'>
          {this.renderTopNavigation()}
        </Container.FlexContainer>
        <Container.FlexContainer style={{ marginTop: '20px', display: 'flex', justifyContent: "space-between" }}>
          <div style={{ display: 'flex' }}>
            {this.renderSearchBarContainer()}
          </div>
          {coursePackagesMetaData && coursePackagesMetaData > 20 && (
            <Container.PaginationButton
              total={coursePackagesMetaData || 0}
              onChange={this.onPageChange}
              current={this.state.currentPage}
              pageSize={this.state.perPage}
            />
          )}
        </Container.FlexContainer>
        {
          get(fetchCoursePackages && fetchCoursePackages.toJS(), 'loading') ?
            <Container.FlexContainer style={{
              width: "100%",
              height: "58vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <Spin size='large' />
            </Container.FlexContainer>
            :
            <>
              <Container.FlexContainer style={{ marginTop: '20px' }}>
                {packages && packages.length > 0 && packages.map(packageInfo => (
                  <Package
                    packageInfo={packageInfo}
                    status={get(packageInfo, 'status')}
                    packageId={get(packageInfo, 'id')}
                    setPackage={(value) => { this.setPackage(value) }}
                  />
                ))}
              </Container.FlexContainer>
            </>
        }
        <FilterModal
          showFilterModal={this.state.showFilterModal}
          closeFilterModal={this.closeFilterModal}
          applyFilters={this.applyFilters}
        />
      </Container>
    )
  }
}
export default CoursePackageMaker
