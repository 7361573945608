import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { Select, Spin, Switch, Button, notification, Popconfirm } from 'antd';
import { MailFilled, CaretUpOutlined, CheckCircleFilled } from '@ant-design/icons'
import WebPreview from '../Components/WebPreview'
import CardPreview from '../Components/CardPreview'
import { debounce, get, set } from 'lodash';
import { useParams, useHistory, Link } from 'react-router-dom';
import fetchSchools from '../../../actions/eventDashboard/fetchSchools';
import '../styles.css'
import { StyledBackButton, StyledButton, StyledFooter } from '../SpeakerPrizes/SpeakerPrizes.style';
import updateEvent from '../../../actions/eventDashboard/updateEvent';
import EditEventPreview from './EditEventPreview';
import RescheduleModal from '../Components/rescheduleModal/RescheduleModal';
import updateEventPreview from '../../../actions/eventDashboard/updateEventPreview';
import fetchEventPreview from '../../../actions/eventDashboard/fetchEventPreview';
import PageLoader from '../Components/PageLoader';
import { Event } from '../createEvent.style';
import EventsStyles from '../CreateEvent/createEvent.style';
import EventNavigation from '../Components/EventNavigation';

const EventPreview = (props) => {
    const [selectedSchools, setSelectedSchools] = useState([])
    const [selectedSchoolsIds, setSelectedSchoolsIds] = useState([])
    const [searchText, setSearchText] = useState('')
    const [isListed, setWebList] = useState(false)
    const [isPublished, setPublishedStatus] = useState(false)
    const [isSchoolEvents, setSchoolEvent] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isEditView, setEditView] = useState(isEditView)
    const [eventStatus, setEventStatus] = useState('published')
    const [status, setStatus] = useState('')
    const [visible, setVisible] = useState(false)
    const [updateData, setUpdateData] = useState({})
    const [showPopup, setShowPopup] = useState(false)

    const { id } = useParams()
    const history = useHistory()

    const updateTab = get(history.location.state, 'urlFrom')

    let { schoolData, isFetchingSchoolData, hasSchoolDataFetched } = props
    const schoolFilter = () => {
        schoolData = schoolData && schoolData.toJS() || []
        let allSchoolsDataFiltered = schoolData.filter(school => get(school, 'name', '').toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
        if (searchText.length < 2) {
            allSchoolsDataFiltered = []
        }
        return allSchoolsDataFiltered
    }

    useEffect(() => {
        setEditView(true)
        async function callEvent() {
            await fetchEventPreview(id)
        }
        callEvent()
    }, [])

    useEffect(() => {
        const { hasEventDataFetched } = props
        if (hasEventDataFetched) {
            const findStatus = props.eventData && props.eventData.toJS().length && props.eventData.toJS()[0].status
            setEventStatus(findStatus)
            if (findStatus !== 'cancelled') {
                setStatus(findStatus)
            } else {
                setStatus('unpublished')
            }
        }
    }, [props.eventData])

    const handleSave = async () => {
        if (updateTab !== '/eventDashboard/events') {
            const inputs = {}
            inputs.isListedOnWeb = isListed
            inputs.status = `${isPublished ? 'published' : 'unpublished'}`
            inputs.isSchoolEvent = isSchoolEvents
            const eventId = { eventId: id }
            await updateEvent(
                inputs,
                eventId,
                (isSchoolEvents ? selectedSchoolsIds : []),
                true
            ).then(res => {
                if (get(res, 'updateEvent.id')) {
                    history.push('/eventDashboard/events')
                    notification.success({
                        message: 'Event added successfully'
                    })
                }
            })
        } else {
            await updateEventPreview(id, updateData, get(updateData, 'status')).then(res => {
                if (get(res, 'data.updateEvent.id')) {
                    history.push('/eventDashboard/events')
                    notification.success({
                        message: 'Event updated successfully'
                    })
                }
            }).catch(err => {
                if (get(err, 'errors[0].message')) {
                    notification.error({
                        message: get(err, 'errors[0].message')
                    })
                } else {
                    notification.error({
                        message: 'Error while updating event'
                    })
                }
            })
        }
    }

    const handleSearch = (value) => {
        setSearchText(value)
    }

    const handleInputChange = (value) => {
        setSelectedSchools(value)
        setSelectedSchoolsIds(() => value.map((schoolId) => `${schoolId.key}`))
    }

    const openModalHandler = () => {
        setVisible(true)
    }

    const closeModalHandler = () => {
        setVisible(false)
        setEventStatus(status)
    }
    useEffect(() => {
        (function () {
            debounce(async () => {
                if (searchText.length > 3) {
                    await fetchSchools(searchText)
                }
            }, 800)()
        })()

    }, [searchText])

    const setIsEditHandler = (value) => {
        setIsEdit(value)
    }

    const editEventStatus = (value) => {
        if (value === 'published' || value === 'unpublished') {
            setStatus(value)
            setEventStatus(value)
            const newData = { ...updateData, status: value }
            setUpdateData({ updateData: newData })
        } else {
            setEventStatus(value)
        }
    }

    useEffect(() => {
        if (eventStatus === 'rescheduled' && isEdit) {
            openModalHandler()
        } else if (eventStatus === 'cancelled' && isEdit) {
            openModalHandler()
        }
    }, [eventStatus])

    const updateEventHandler = async (updatedEventStatus, data) => {
        let input = {}
        input = { ...data, status: updatedEventStatus }
        if (updatedEventStatus !== 'cancelled') {
            input = {
                ...data,
                status: status,
                updatedStatus: updatedEventStatus !== 'rescheduled' ? status : 'rescheduled',
            }
            setUpdateData(input)
        }
        if (updatedEventStatus === 'cancelled') {
            input = {
                ...data,
                status: updatedEventStatus,
                updatedStatus: updatedEventStatus
            }
            setUpdateData(input)
        }
        notification.success({
            message: 'Data for update ready.'
        })
    }

    const onRescheduledSaveText = (
        <div style={{ alignItems: 'center' }}>
            <h4 style={{ fontWeight: 'bold' }}>Are you Sure ?</h4>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'flex-start' }}>
                <p><b>Changes Made:</b></p>
                <p>Event Status Changed to: <b>Reschudeled</b></p>
                <p>Event Date Changed to <b>{moment(get(updateData, 'selectedDateRange.startDate')).format('Do MMMM YYYY')}</b></p>
                <p>Event Time Changed to <b>{moment(get(updateData, 'timeValues.startTime')).set('minutes', 0).format('hh:mm A')}</b></p>
            </div>
        </div>
    )

    const allMandatoryEventDetailsFilled = () => {
        let { eventData } = props
        eventData = eventData && eventData.toJS()
        if (eventData && eventData.length) {
            const { speakers, eventJoinReasons, eventCommsRule, embeds, utm } = eventData[0]
            if (!get(speakers, 'length') || !get(eventJoinReasons, 'length') || !get(eventCommsRule, 'length') || !get(embeds, 'length')) {
                return false
            }
            return true
        }
        return false
    }

    const onSaveText = (
        <div style={{ alignItems: 'center' }}>
            <h4 style={{ fontWeight: 'bold' }}>Save Changes ?</h4>
        </div>
    )
    const { isEventDataFetching, eventUpdateStatus } = props
    const currentPath = history.location.pathname.split('/')
    const currentNav = currentPath[currentPath.length - 1]
    return (
        <Event.EventsWrapper style={{ padding: '1rem' }}>
            <PageLoader isfetching={isEventDataFetching && get(isEventDataFetching.toJS(), 'loading')} />
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <EventsStyles.PageTitle>{`${updateTab === '/eventDashboard/events' ? 'Update Event' : 'Create Event'}`}</EventsStyles.PageTitle>
                {!updateTab && id && <EventNavigation id={id} isCurrent={currentNav} />}
            </div>
            <div className="preview">
                <div className="web">
                    <div className="mainHeader">
                        <MailFilled className="mail" />
                        {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> */}
                        <span>Review Event Listing & Page</span>
                        {/* </div> */}
                        <p className="header-desc">Review the event listing</p>
                        <div className="link-box" onClick={() => window.open(`https://www.tekie.in/events/${id}`)}>
                            <p className="link-head">Event Page Link<CaretUpOutlined className="redirect" /></p>
                            <p className="link">https://www.tekie.in/events/${id}</p>
                        </div>
                    </div>
                    {
                        updateTab === '/eventDashboard/events' && (
                            <div className='editEvent'>
                                <EditEventPreview
                                    disabled={!props.hasEventDataFetched}
                                    edit={isEdit}
                                    eventStatus={eventStatus}
                                    setEdit={setIsEditHandler}
                                    editEventStatus={editEventStatus}
                                />
                            </div>
                        )
                    }
                    <p style={{ fontWeight: 'bold' }}>
                        Event Page
                    </p>
                    <WebPreview
                        data={(props.eventData && props.eventData.toJS().length && props.eventData.toJS()[0]) || updateData}
                        style={{ background: '#fff' }}
                    />
                    {
                        (updateTab !== '/eventDashboard/events' && allMandatoryEventDetailsFilled()) && (
                            <>
                                <div className="mainHeader">
                                    <CheckCircleFilled className="mail" />
                                    <span>Going Live</span>
                                    <p className="header-desc">Review the event listing</p>
                                    <div className="bottomPart">
                                        <p>OFF<Switch style={isListed ? { backgroundColor: '#0ca789' } : { backgroundColor: 'grey' }} className="swtich" onChange={() => setWebList(!isListed)} />ON<span className="bottomLine">List Event On Website</span></p>
                                        <p>OFF<Switch style={isPublished ? { backgroundColor: '#0ca789' } : { backgroundColor: 'grey' }} className="swtich" onChange={() => setPublishedStatus(!isPublished)} />ON<span className="bottomLine">Publish Event After Clicking On "Save"</span></p>
                                        <p>OFF<Switch style={isSchoolEvents ? { backgroundColor: '#0ca789' } : { backgroundColor: 'grey' }} className="swtich" onChange={() => setSchoolEvent(!isSchoolEvents)} />ON<span className="bottomLine">Schools Exclusive Events</span></p>
                                    </div>
                                </div>
                                <div>
                                    {isSchoolEvents && <Select
                                        mode='multiple'
                                        labelInValue
                                        allowClear
                                        placeholder={'Type min 3 characters'}
                                        loading={isFetchingSchoolData}
                                        filterOption={false}
                                        value={selectedSchools || []}
                                        notFoundContent={!hasSchoolDataFetched ? <Spin size='small' /> : null}
                                        onSearch={debounce(handleSearch, 800)}
                                        onChange={handleInputChange}
                                        style={{ width: '400px' }}
                                    >
                                        {
                                            schoolFilter().map(item =>
                                                <Select.Option
                                                    // key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </Select.Option>
                                            )
                                        }
                                    </Select>}
                                </div>
                            </>
                        )
                    }
                    <div>
                        {
                            eventStatus === 'rescheduled' && visible && isEdit && (
                                <RescheduleModal
                                    visible={visible}
                                    rescheduleEvent={eventStatus === 'rescheduled'}
                                    cancelEvent={eventStatus === 'cancelled'}
                                    eventData={(props.eventData && props.eventData.toJS().length && props.eventData.toJS()[0]) || updateData}
                                    updateEvent={updateEventHandler}
                                    closeModal={closeModalHandler}
                                    eventStatus={eventStatus}
                                />
                            )
                        }
                        {
                            eventStatus === 'cancelled' && visible && isEdit && (
                                <RescheduleModal
                                    visible={visible}
                                    rescheduleEvent={eventStatus === 'rescheduled'}
                                    cancelEvent={eventStatus === 'cancelled'}
                                    eventData={(props.eventData && props.eventData.toJS().length && props.eventData.toJS()[0]) || updateData}
                                    updateEvent={updateEventHandler}
                                    closeModal={closeModalHandler}
                                    eventStatus={eventStatus}
                                />
                            )
                        }
                    </div>
                </div>

                <div className="card">
                    <div className="eventListingCard">
                        <p style={{ fontWeight: 'bold' }}>
                            Event Listing
                        </p>
                        <CardPreview
                            data={(props.eventData && props.eventData.toJS().length && props.eventData.toJS()[0]) || updateData}
                        />
                    </div>
                </div>
            </div>
            <StyledFooter>
                <StyledBackButton mRight onClick={() => props.history.goBack()}>
                    Back
                </StyledBackButton>
                {updateTab === '/eventDashboard/events' ? (
                    <Popconfirm
                        title={get(updateData, 'updatedStatus') === 'rescheduled' ? onRescheduledSaveText : onSaveText}
                        icon=''
                        visible={showPopup}
                        onConfirm={handleSave}
                        onCancel={() => setShowPopup(!showPopup)}
                        placement='leftTop'
                        okText='Confirm'
                        okType='danger'
                        cancelText='Cancel'
                    // key='toggle'
                    >
                        <StyledButton
                            loading={eventUpdateStatus && get(eventUpdateStatus.toJS(), 'loading')}
                            disabled={eventUpdateStatus && get(eventUpdateStatus.toJS(), 'loading')}
                            onClick={() => {
                                if (Object.keys(updateData).length) {
                                    setShowPopup(true)
                                }
                                else {
                                    notification.error({
                                        message: 'No data to Update.'
                                    })
                                }
                            }}>
                            Save & Continue
                        </StyledButton>
                    </Popconfirm>
                ) : (
                    <StyledButton
                        loading={eventUpdateStatus && get(eventUpdateStatus.toJS(), 'loading')}
                        disabled={eventUpdateStatus && get(eventUpdateStatus.toJS(), 'loading')}
                        onClick={handleSave}>
                        Save & Continue
                    </StyledButton>
                )}
                {/* </Link> */}
            </StyledFooter>
        </Event.EventsWrapper>
    )
}

export default EventPreview;