import styled from 'styled-components'

const AssignmentCard = styled.div`
padding: 15px;
background: #F6F8F7;
border: 1px dashed #282828;
box-sizing: border-box;
min-width: 250px;
width: 100%;
max-width: 700px;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
flex-direction: column;
margin:0;
position: relative;
`
const ProjectDescription = styled.div`
    height: auto;
    min-height: 78px;
    width: 95%;
    margin-bottom: 20px;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    color: #504f4f;
    font-weight: 600;
    line-height: 1.4;
    padding-top: 24px;
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
`

export { AssignmentCard, ProjectDescription }
