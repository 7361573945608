import { Button } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import gql from 'graphql-tag'
import React, { useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import requestToGraphql from '../../../utils/requestToGraphql'
import ratingHeaderConfig from './ratingsHeaderConfig'
import getSlot from '../utils'

const mentorRatingsFields = (first, skip, mentorId, kidId) => gql` {
    mentorMenteeSessions(orderBy: createdAt_DESC, 
      ${first ? `first:${first}` : ''}, 
      ${skip ? `skip:${first * skip}` : ''}, 
      filter: 
      { and: 
        [
          {mentorSession_some: 
            {user_some: 
              {id: "${mentorId}" }
            }
          },
          
          ${kidId ? ` {
            menteeSession_some: 
            {user_some: 
              {id: "${kidId}" }
            }
          }` : ''}
        ]
      }
      ) {
      id
      menteeSession {
        id
        user {
          id
          name
        }
        slot0
        slot1
        slot2
        slot3
        slot4
        slot5
        slot6
        slot7
        slot8
        slot9
        slot10
        slot11
        slot12
        slot13
        slot14
        slot15
        slot16
        slot17
        slot18
        slot19
        slot20
        slot21
        slot22
        slot23
        bookingDate 
      }
      mentorSession {
        user {
            id
            name
          }
      }
      id
      course {
        id
        title
      }
      topic {
        id
        title
      }
      comment
      rating
    }
  }
  `

const DownloadMentorRatingsReport = (props) => {
  const [loading, setLoading] = useState(false)
  const [ratingsData, setRatingsData] = useState([])
  const ratingRef = useRef()

  const fetchRatingsData = async () => {
    setLoading(true)
    const particularMentorRatingsData = await requestToGraphql(mentorRatingsFields(props.perPage,
      props.currentPage, props.query, props.kidId))
    const mentorRatingsData = particularMentorRatingsData.data.mentorMenteeSessions
    const mentorRatingsTableData = []
    mentorRatingsData.forEach((mentorMenteeSession) => {
      mentorRatingsTableData.push({
        name: get(mentorMenteeSession.menteeSession, 'user.name', '-'),
        comment: get(mentorMenteeSession, 'comment', '-'),
        topic: get(mentorMenteeSession.topic, 'title', '-'),
        course: get(mentorMenteeSession.course, 'title', '-'),
        rating: get(mentorMenteeSession, 'rating', 0) || 0,
        slot: getSlot(mentorMenteeSession.menteeSession),
        date: `${moment(get(mentorMenteeSession.menteeSession, 'bookingDate', '')).format('DD-MM-YYYY')}`
      })
    })
    setRatingsData(mentorRatingsTableData)
    setLoading(false)
    if (ratingRef && ratingRef.current) ratingRef.current.link.click()
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
      <Button
        type='primary'
        icon='download'
        onClick={fetchRatingsData}
        loading={loading}
      >Download Ratings
      </Button>
      <CSVLink
        style={{ display: 'none' }}
        filename='MentorRatingsInfo.csv'
        data={ratingsData}
        headers={ratingHeaderConfig}
        ref={ratingRef}
      />
    </div>
  )
}

export default DownloadMentorRatingsReport
