import React, { Component } from 'react';
import { MailOutlined } from '@ant-design/icons';
import SwitchConstant from '../../Constant/Switch/SwitchConstant';

export default class UploadTitle extends Component {
  render() {
      const { toggle,switchColor } = this.props;
    return (
        <>
            <div>
                <div style={{display: "flex",
                            alignItems: "center"}}>
                    <div style={{ width: "26px" }}>
                        <MailOutlined style={{
                            color: "white",
                            fontSize: "26px",
                            backgroundColor: "black",
                            height: "19px",
                            display: "flex",
                            alignItems: "center",
                        }} />
                    </div>
                    <div style={{ fontSize: "24px",marginLeft:"16px" }}>
                        <b>Upload Moments from the events</b>
                    </div>
                    <div style={{display: "flex",
                            alignItems: "center",justifyContent:"center",marginLeft:"30px"}}>
                        <SwitchConstant on={"ON"} off={"OFF"} toggle={toggle} switchColor={switchColor}/>
                    </div>
                </div>
                <div className="" style={{
                    fontSize: "12px",
                    marginLeft: "43px",
                    color: "#0707078a",
                }}>
                    <b>Show the world how amazing the event was!</b>
                </div>
            </div>
        </>
    )
  }
}
