import React, { Component } from 'react'
import { Table, Input } from 'antd'
import { get } from 'lodash'
import { UserOutlined, SearchOutlined } from '@ant-design/icons'
import StudentListStyle from './StudentList.style'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'grade',
    dataIndex: 'grade',
    key: 'grade',
  },
  {
    title: 'Parents name',
    dataIndex: 'pname',
    key: 'pname',
  },
  {
    title: 'Parent Phone',
    dataIndex: 'pphone',
    key: 'pphone',
  },
  {
    title: 'Parent Email',
    dataIndex: 'pemail',
    key: 'pemail',
  },
  {
    title: 'Created On',
    dataIndex: 'createdOn',
    key: 'createdOn',
  }
]

class StudentsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      loading: true,
      serachedStudent: ''
    }
  }
  componentDidMount = () => {
    this.getTableData(this.props.students)
  }
  handleSearch = (e) => {
    this.setState({ serachedStudent: e.target.value })
    const { students } = this.props
    let searchedNames = []
    searchedNames = students.filter((s) => {
      if (e.target.value === '') {
        return s
      } else if (get(s, 'user.name').toLowerCase().includes(e.target.value.toLowerCase())) {
        return s
      }
    })
    this.getTableData(searchedNames)
  }
  getTableData = (students) => {
    const n = students.length
    const data = []
    this.setState({ loading: true })
    for (let i = 0; i < n; i += 1) {
      data.push({
        key: i + 1,
        name: get(students[i], 'user.name', ''),
        grade: get(students[i], 'grade', 0),
        pname: get(students[i], 'parents[0].user.name', ''),
        pphone: get(students[i], 'parents[0].user.phone.number', 0),
        pemail: get(students[i], 'parents[0].user.email', ''),
        createdOn: get(students[i], 'user.createdAt').substring(0, 10)
      })
    }
    this.setState({
      data,
      loading: false
    })
  }
  render() {
    const { serachedStudent } = this.state
    return (
      <div style={{ backgroundColor: 'white' }}>
        <StudentListStyle.TopBar>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ fontSize: '16px', color: '#212121', marginRight: '20px' }}>All Students</div>
            <StudentListStyle.GreyBack>
              <UserOutlined />{this.props.batchCode}
            </StudentListStyle.GreyBack>
          </div>
          <div style={{ flex: '0.5' }}>
            <Input placeholder='Search' value={serachedStudent} onChange={e => this.handleSearch(e)} suffix={<SearchOutlined />} />
          </div>
        </StudentListStyle.TopBar>
        <Table
          loading={this.state.loading}
          columns={columns}
          dataSource={this.state.data}
          pagination={false}
        />
      </div>
    )
  }
}
export default StudentsList
