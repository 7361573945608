import React, { Component } from 'react'
import { InputNumber, Select, notification, Icon } from 'antd'
import fetchCommsVariables from '../../../actions/eventDashboard/fetchCommsVariables'
import EventsStyles, { SessionCard, Event } from '../createEvent.style'
import { PlusOutlined } from '@ant-design/icons'
import { get } from 'lodash'
import { StyledBackButton, StyledButton, StyledError, StyledFooter } from '../SpeakerPrizes/SpeakerPrizes.style'
import EnterTestDataModal from './EnterTestDataModal'
import EventNavigation from '../Components/EventNavigation'
import updateEventComms from '../../../actions/eventDashboard/updateEventComms'
import { DEFAULT_TEMPLATE_DATA, dateFieldNames, attendeesFieldNames, timeFieldNames } from './utils'
import fetchSingleEvent from '../../../actions/eventDashboard/fetchSingleEvent'
import PageLoader from '../Components/PageLoader'

const invalidChars = [
    43, 45, 101, 69, 189, 187
];

const attendanceMap = {
    'nonAttendees': 'To non-attendees',
    'attendees': 'To attendees',
    'allUser': 'To all'
}
const conditionMap = {
    'after': 'After the event',
    'before': 'Before the event',
    'afterRegistration': 'After the Registeration',
}
const timeUnitMap = {
    'hour': 'Hour(s)',
    'minute': 'Minute(s)',
    'day': 'Day(s)',
    'week': 'Week(s)',
    'month': 'Month(s)',
    'year': 'Year(s)'
}

class Tickets extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Time: ['Hour(s)', 'Minute(s)', 'Day(s)', 'Week(s)', 'Month(s)', 'Year(s)'], //Ask Defaults
            Variable: ['Student Name', 'Event Name', 'Event Date', 'Event Time', 'Student Grade'], //Ask Defaults
            Event: ['Before the event', 'After the event', 'After the Registeration'],
            Attendees: ['To attendees', 'To non-attendees', 'To all'], //Ask Defaults
            Template: [0, 1],
            Tickets: 'free',
            ticketName: '',
            registrationLimit: null,
            visible: false,
            showModal: false,
            showAttendee: false,
            currentTemplateData: {},
            currentTemplateId: null,
            modalData: [],
            isSaving: false,
            error: false,
            currentTemplateName: '',
            isFetching: false,
            fetchedEventData: [],
            eventData: null,
            addedCommsRule: []
        }
    }

    async componentDidMount() {
        const eventIdFromRoute = get(this.props, 'match.params.id')
        if (eventIdFromRoute) {
            this.setState({
                isFetching: true
            })
            await fetchCommsVariables()
            fetchSingleEvent(eventIdFromRoute, 'whatsappPage').then(res => {
                let modalDataArr = []
                let Template = []
                const addedCommsRule = []
                if (get(res, 'event.eventCommsRule', []).length) {
                    get(res, 'event.eventCommsRule', []).forEach((comm, index) => {
                        if (!get(comm, 'isEmailRule', false)) {
                            const addedComms = get(comm, 'commsVariables', []).map(variable => get(variable, 'dataField'))
                            const templateData = []
                            const addedVariables = get(comm, 'commsVariables', []).map(variable => get(variable, 'whatsappVariableName'))
                            get(res, 'event.commsVariables', []).forEach((variable) => {
                                if (addedComms.includes(get(variable, 'dataField'))
                                    && addedVariables.includes(get(variable, 'whatsappVariableName'))) {
                                    templateData.push({
                                        value: '',
                                        id: get(variable, 'id'),
                                        keyId: get(variable, 'id'),
                                        label: get(variable, 'whatsappVariableName'),
                                        dataField: get(variable, 'dataField')
                                    })
                                }
                            })
                            Template.push(index)
                            modalDataArr.push({
                                ...comm,
                                id: modalDataArr.length,
                                commsVariables: templateData,
                                templateFieldData: templateData,
                                showAttendee: get(comm, 'condition') === 'after'
                            })
                        } else {
                            addedCommsRule.push(comm)
                        }
                    })
                }
                if (!modalDataArr.length) {
                    for (let i = 0; i < this.state.Template.length; i++) {
                        modalDataArr = [...modalDataArr, { ...DEFAULT_TEMPLATE_DATA, id: i }]
                        Template.push(i)
                    }
                }
                this.setState({
                    modalData: modalDataArr,
                    currentTemplateData: {},
                    isFetching: false,
                    ticketName: get(res, 'event.ticketName'),
                    registrationLimit: get(res, 'event.ticketregistrationLimit'),
                    Template: Template,
                    eventData: get(res, 'event'),
                    addedCommsRule: addedCommsRule
                })
            })
        }
    }

    componentDidUpdate(prevProps) {

        const { eventDataFetchStatus, eventData } = this.props

        // if (eventDataFetchStatus && !get(eventDataFetchStatus.toJS(), 'loading') 
        //     && get(eventDataFetchStatus.toJS(), 'success') &&
        //     (prevProps.eventDataFetchStatus !== eventDataFetchStatus)) {
        //     const eventsData = eventData && eventData.toJS()
        //     let updateEventCommsRule = []
        //     let templates = []
        //     if (eventsData.length) {
        //         const eventCommsRule = get(eventsData[0], 'eventCommsRule')
        //         if (eventCommsRule.length) {
        //             for (let i=0; i<eventCommsRule.length; i++) {
        //                 templates = [ ...templates, i ]
        //             }
        //             eventCommsRule.forEach((comms) => {
        //                 updateEventCommsRule = [... updateEventCommsRule, 
        //                     {   ...comms,
        //                         commsVariables: get(comms, 'commsVariables').map((variable) => ({
        //                             ...variable,
        //                             label: get(variable, 'whatsappVariableName'), 
        //                             dataField: get(variable, 'dataField'),
        //                             value: get(variable, 'dataField')
        //                         }))
        //                     }
        //                 ]
        //             })
        //         }
        //     }
        //     this.setState({
        //         Template: templates,
        //         fetchedEventData: eventsData[0],
        //         ticketName: get(eventsData[0], 'ticketName'),
        //         registrationLimit: get(eventsData[0], 'ticketregistrationLimit'),
        //     })
        // }
    }

    isTemplateChecked = (result, _error, templateData, templateId) => {
        const { modalData } = this.state
        const selectedTemplate = modalData.find((elem) => elem.id === templateId)
        const templateDataKeys = Object.keys(templateData)
        let templateFieldData = []
        templateDataKeys.forEach((key) => {
            templateFieldData = [...templateFieldData, { dataField: key, whatsappVariableName: templateData[key] }]
        })
        const newTemplate = { ...selectedTemplate, templateFieldData: templateFieldData, isPassed: true, isTested: true }
        const newData = modalData.map((elem) => (elem.id === templateId) ? { ...newTemplate } : elem)
        this.setState({ modalData: newData })
    }

    selectEvent = (value, templateId) => {
        if (value === 'After the event') {
            const { modalData } = this.state
            const selectedTemplate = modalData.find((elem) => elem.id === templateId)
            const newTemplate = { ...selectedTemplate, showAttendee: true }
            const newData = modalData.map((elem) => (elem.id === templateId) ? { ...newTemplate } : elem)
            this.setState({ modalData: newData })
        } else {
            const { modalData } = this.state
            const selectedTemplate = modalData.find((elem) => elem.id === templateId)
            const newTemplate = { ...selectedTemplate, showAttendee: false }
            const newData = modalData.map((elem) => (elem.id === templateId) ? { ...newTemplate } : elem)
            this.setState({ modalData: newData })
        }
    }

    isModalDataEmpty = (modalData) => {
        if (modalData && modalData.length === 0) return true
        for (const form of modalData) {
            if (!form.templateName || form.commsVariables.length === 0) {
                return true
            }
        }
        return false
    }


    validateForm = () => {
        const { modalData, ticketName, registrationLimit } = this.state
        if (!ticketName || !registrationLimit || this.isModalDataEmpty(modalData)) {
            this.setState({ error: true })
            return false
        } else {
            this.setState({ error: false })
            return true
        }
    }

    handleSave = () => {

        if (this.validateForm()) {
            this.setState({
                isSaving: true
            }, async () => {
                const { modalData } = this.state
                let saveData = []
                let errors = []
                let commsVariables = [];
                for (let i = 0; i < modalData.length; i++) {
                    const elem = modalData[i]
                    if (modalData[i].templateName.length < 3) {
                        errors.push(`Template Name for template-${i + 1} cannot be shorter than three characters.`)
                        break
                    }
                    if (!modalData[i].isTested) {
                        errors.push(`Template ${i + 1} not tested.`)
                        break
                    } else {
                        for (let i = 0; i < modalData.length; i++) {
                            const isExist = modalData.filter((m) => get(m, 'templateName') === get(modalData[i], 'templateName'))
                            if (isExist.length > 1) {
                                return notification.warn({
                                    message: '2 Comms cannot have same template name'
                                })
                            }
                        }
                        get(elem, 'commsVariables', []).forEach(comm => {
                            commsVariables.push(get(comm, 'id'))
                        })
                        const data = {
                            templateName: elem.templateName,
                            commsVariables: elem.commsVariables.map(comm => ({
                                dataField: get(comm, 'dataField'), whatsappVariableName: get(comm, 'label')
                            })),
                            condition: elem.condition,
                            attendanceFilter: elem.attendanceFilter,
                            unit: elem.unit,
                            value: elem.value,
                            isTested: elem.isTested,
                            isPassed: elem.isPassed
                        }
                        saveData.push(data)
                    }
                }
                if (!errors.length) {
                    saveData = [...saveData, ...this.state.addedCommsRule]
                    const { id } = this.props.match.params;
                    const newCommsVariables = [...new Set(commsVariables)]
                    await updateEventComms(id, saveData, {
                        ticketName: this.state.ticketName,
                        ticketregistrationLimit: Number(this.state.registrationLimit),
                        commsVariablesConnectIds: newCommsVariables
                    })
                    this.setState({
                        isSaving: false
                    })
                    let updatedStatus = this.props.eventUpdateStatus && this.props.eventUpdateStatus.toJS()
                    if (!get(updatedStatus, 'loading') && get(updatedStatus, 'success')) {
                        // notification.success({
                        //     message: `Successfully updated.`
                        // })
                        this.props.history.push(`/eventDashboard/events/${id}/certificateTemplate`)
                    }
                } else {
                    notification.warning({
                        message: errors[0]
                    })
                }
            })
        }

    }

    getTicket = (status) => {
        if (status === 'free') {
            return (
                <div style={{ display: 'flex', width: '600px' }}>
                    <div style={{ marginRight: '30px' }}>
                        <SessionCard.input value={this.state.ticketName} onChange={(e) => { this.setState({ ticketName: e.target.value }) }} size='large' prefix={<SessionCard.InputTitle>Ticket Name</SessionCard.InputTitle>} />
                        {
                            this.state.error && !this.state.ticketName && <StyledError>Required*</StyledError>
                        }
                    </div>
                    <div>
                        <SessionCard.input onKeyPress={(event) =>
                            invalidChars.includes(event.which) &&
                            event.preventDefault()} type='number' value={this.state.registrationLimit} min={0} defaultValue={0} onChange={(e) => { this.setState({ registrationLimit: e.target.value }) }} size='large' prefix={<SessionCard.InputTitle>Registeration Limit</SessionCard.InputTitle>} />
                        {
                            this.state.error && !this.state.registrationLimit && <StyledError>Required*</StyledError>
                        }
                    </div>
                </div>
            )
        } else if (status === 'paid') {
            return (
                <>
                    <div style={{ display: 'flex', width: '600px', marginBottom: '20px' }}>
                        <SessionCard.input size='large' style={{ marginRight: '30px' }} prefix={<SessionCard.InputTitle>Ticket Name</SessionCard.InputTitle>} />
                        <SessionCard.input size='large' prefix={<SessionCard.InputTitle>Registeration Limit</SessionCard.InputTitle>} />
                    </div>
                    <div style={{ display: 'flex', width: '90%' }}>
                        <SessionCard.input placeholder='Price per Ticket' size='large' style={{ marginRight: '20px', width: '40%' }} prefix='₹' />
                        <div style={{ width: '100%' }}>
                            <SessionCard.InputLabel>Ticket Sales ends</SessionCard.InputLabel>
                            <InputNumber min={1} max={10} defaultValue={1} size='large' style={{ width: '15%', marginLeft: '10px' }} />
                            <Select defaultValue={this.state.Time[0]} style={{ marginLeft: '10px' }} size='large' >
                                {this.state.Time.map((time) => (
                                    <Select.Option
                                        key={time}
                                        value={time}
                                    >{time}</Select.Option>
                                ))}
                            </Select>
                            <Select defaultValue={this.state.Event[0]} style={{ marginLeft: '15px' }} size='large' >
                                {this.state.Event.map((event) => (
                                    <Select.Option key={event}
                                        value={event}>{event}</Select.Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </>
            )
        }
    }
    getTemplate = (i) => {
        let comms = (this.props.commsTags && this.props.commsTags.toJS()) || []
        comms = comms.filter(comm => get(comm, 'id') && get(comm, 'whatsappVariableName'))
        return (
            <div style={{ display: 'flex' }}>
                <Event.IndexNumber>{i + 1}</Event.IndexNumber>
                <div style={{ width: '98%', position: 'relative' }}>
                    <div style={{ display: 'flex', marginBottom: '30px' }}>
                        <div style={{ width: '30%' }}>
                            <SessionCard.InputLabel>Template Name (WATI)</SessionCard.InputLabel>
                            <SessionCard.input
                                placeholder='Code_Carnival Bash'
                                size='large'
                                value={get(this.state, `modalData[${i}].templateName`)}
                                onChange={(e) => {
                                    const { modalData } = this.state
                                    const newData = modalData.map((elem) => (elem.id === i) ? { ...elem, templateName: e.target.value, isPassed: false, isTested: false } : elem)
                                    this.setState({
                                        modalData: newData
                                    })
                                }}
                            />
                            {
                                (this.state.error && !get(this.state, `modalData[${i}].templateName`)) && <StyledError>Required*</StyledError>
                            }
                        </div>
                        <div style={{
                            width: '40%',
                            marginLeft: '15px',
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <SessionCard.InputLabel>Variable Used</SessionCard.InputLabel>
                            <Select
                                labelInValue
                                size='large'
                                style={{ minWidth: '65%' }}
                                mode='multiple'
                                value={get(this.state, `modalData[${i}].commsVariables`, []).map(comm =>
                                    ({ key: get(comm, 'id'), label: get(comm, 'label') }))}
                                onChange={(value, _option) => {
                                    if (!value.length) {
                                        const { modalData } = this.state
                                        const selectedTemplate = modalData.find((elem) => elem.id === i)
                                        const newTemplate = { ...selectedTemplate, commsVariables: [] }
                                        const clearData = modalData.map((elem) => (elem.id === i) ? { ...newTemplate } : elem)
                                        this.setState({ modalData: clearData })
                                    }
                                }}
                                onSelect={(value, _option) => {
                                    const { modalData } = this.state
                                    const selectedOption = comms && comms.find((variable) => variable.id === get(value, 'key'))
                                    const selectedTemplate = modalData.find((elem) => elem.id === i)
                                    const addData = [...selectedTemplate.commsVariables,
                                    {
                                        value: '',
                                        id: get(selectedOption, 'id'),
                                        label: get(selectedOption, 'whatsappVariableName'),
                                        dataField: get(selectedOption, 'dataField')
                                    }
                                    ]
                                    const newTemplate = { ...selectedTemplate, commsVariables: addData }
                                    const newData = modalData.map((elem) => (elem.id === i) ? { ...newTemplate } : elem)
                                    this.setState({ modalData: newData })
                                }}
                                onDeselect={(value, _option) => {
                                    const { modalData } = this.state
                                    const selectedTemplate = modalData.find((elem) => elem.id === i)
                                    const filteredVariable = selectedTemplate.commsVariables.find(elem => elem.id === get(value, 'key'))
                                    const updateData = selectedTemplate.commsVariables.filter((elem) =>
                                        elem.id !== get(value, 'key'))
                                    const newTemplate = { ...selectedTemplate, commsVariables: updateData }
                                    const newData = modalData.map((elem) => (elem.id === i) ? { ...newTemplate } : elem)
                                    this.setState({ modalData: newData })
                                }}
                            >
                                {comms.map((variable) => (
                                    <Select.Option key={get(variable, 'id')}
                                        value={get(variable, 'id')}>{get(variable, 'whatsappVariableName')}</Select.Option>
                                ))}
                            </Select>
                            {
                                (this.state.error && !get(this.state, `modalData[${i}].commsVariables`, []).length) && <StyledError>Required*</StyledError>
                            }
                        </div>
                        <div style={{ margin: '20px 0 0 20px' }}>
                            <SessionCard.TestButton
                                isTested={this.state.modalData
                                    && this.state.modalData[i]
                                    && this.state.modalData[i].isPassed}
                                onClick={() =>
                                    this.testVisible(comms, this.state.modalData[i].commsVariables,
                                        get(this.state, `modalData[${i}].templateName`), i)
                                }
                            >
                                Test Template
                            </SessionCard.TestButton>
                        </div>
                    </div>
                    <div style={{ width: '70%' }}>
                        <SessionCard.InputLabel>Trigger Time</SessionCard.InputLabel>
                        <InputNumber
                            type='number'
                            min={1}
                            value={get(this.state, `modalData[${i}].value`)}
                            size='large'
                            style={{ width: '10%' }}
                            onKeyDown={(event) => {
                                invalidChars.includes(event.which) &&
                                    event.preventDefault()
                            }}
                            onChange={(value) => {
                                const { modalData } = this.state
                                const selectedTemplate = modalData.find((elem) => elem.id === i)
                                const newTemplate = { ...selectedTemplate, value: value }
                                const newData = modalData.map((elem) => (elem.id === i) ? { ...newTemplate } : elem)
                                this.setState({ modalData: newData })
                            }}
                        />
                        <Select
                            value={timeUnitMap[get(this.state, `modalData[${i}].unit`)]}
                            style={{ marginLeft: '10px', minWidth: '15%' }}
                            size='large'
                            onChange={(value) => {
                                const { modalData } = this.state
                                const selectedTemplate = modalData.find((elem) => elem.id === i)
                                let newValue;
                                timeFieldNames.forEach((field) => {
                                    if (field.value === value) {
                                        newValue = field.fieldName
                                    }
                                })
                                const newTemplate = { ...selectedTemplate, unit: newValue }
                                const newData = modalData.map((elem) => (elem.id === i) ? { ...newTemplate } : elem)
                                this.setState({ modalData: newData })
                            }}
                        >
                            {this.state.Time.map((time) => (
                                <Select.Option value={time} key={time}>{time}</Select.Option>
                            ))}
                        </Select>
                        <Select
                            value={conditionMap[get(this.state, `modalData[${i}].condition`)]}
                            style={{ marginLeft: '15px', minWidth: '27%' }}
                            size='large'
                            onChange={(value) => {
                                const { modalData } = this.state
                                const selectedTemplate = modalData.find((elem) => elem.id === i)
                                let newValue;
                                dateFieldNames.forEach((field) => {
                                    if (field.value === value) {
                                        newValue = field.fieldName
                                    }
                                })
                                const newTemplate = { ...selectedTemplate, condition: newValue }
                                const newData = modalData.map((elem) => (elem.id === i) ? { ...newTemplate } : elem)
                                this.setState({ modalData: newData }, () => this.selectEvent(value, i))
                            }}
                        >
                            {this.state.Event.map((event) => (
                                <Select.Option value={event} key={event}>{event}</Select.Option>
                            ))}
                        </Select>
                        {this.state.modalData && this.state.modalData[i]
                            && this.state.modalData[i].showAttendee &&
                            <Select
                                value={attendanceMap[get(this.state, `modalData[${i}].attendanceFilter`)]}
                                style={{ marginLeft: '15px', minWidth: '23%' }}
                                size='large'
                                onChange={(value) => {
                                    const { modalData } = this.state
                                    const selectedTemplate = modalData.find((elem) => elem.id === i)
                                    let newValue;
                                    attendeesFieldNames.forEach((field) => {
                                        if (field.value === value) {

                                            newValue = field.fieldName
                                        }
                                    })
                                    const newTemplate = { ...selectedTemplate, attendanceFilter: newValue }

                                    const newData = modalData.map((elem) => (elem.id === i) ? { ...newTemplate } : elem)
                                    this.setState({ modalData: newData })
                                }}
                            >
                                {this.state.Attendees.map((attendee) => (
                                    <Select.Option
                                        value={attendee}
                                        key={attendee}>{attendee}
                                    </Select.Option>
                                ))}
                            </Select>}
                    </div>
                    {<Icon
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                        }}
                        onClick={() => this.removeTemplate(i)} type='close' />}
                    <Event.TempDivider dashed />
                </div>
            </div>
        )
    }

    removeTemplate = (index) => {
        const { modalData, Template } = this.state
        this.setState({
            modalData: modalData.filter((data, ind) => ind !== index),
            Template: Template.filter((temp, ind) => ind !== index)
        })
    }

    closeModal = () => {
        this.setState({
            visible: false,
            currentTemplateData: {},
            currentTemplateName: '',
            currentTemplateId: null
        })
    }

    testVisible = (comms, data, templateName, id) => {
        const checkParentNumberFlag = data.find((item) => item.dataField === 'parentPhone')
        let updatedData = [...data]
        if (!checkParentNumberFlag) {
            const selectedOption = comms.find((item) => get(item, 'dataField') === 'parentPhone')
            updatedData = [...updatedData,
            {
                value: '',
                id: get(selectedOption, 'id'),
                label: get(selectedOption, 'whatsappVariableName'),
                dataField: get(selectedOption, 'dataField')
            }
            ]
        }
        this.setState({
            currentTemplateData: updatedData,
            currentTemplateName: templateName,
            currentTemplateId: id
        }, () => {
            this.setState({
                visible: true
            })
        })
    }

    render() {
        const { Tickets, Template, visible, isFetching } = this.state
        let updatedStatus = this.props.eventUpdateStatus
            && this.props.eventUpdateStatus.toJS()
        const { id } = this.props.match.params
        const currentPath = this.props.match.path.split('/')
        const currentNav = currentPath[currentPath.length - 1]
        return (
            <Event.EventsWrapper style={{ padding: '15px' }}>
                <PageLoader isfetching={isFetching} />
                <EnterTestDataModal
                    modalId={this.state.currentTemplateId}
                    modalData={this.state.currentTemplateData}
                    templateName={this.state.currentTemplateName}
                    isTemplateChecked={this.isTemplateChecked}
                    closeModal={this.closeModal}
                    visible={visible}
                    isMail={false}
                />
                <EventsStyles>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '-10px' }}>
                        <EventsStyles.PageTitle>Create Event</EventsStyles.PageTitle>
                        {id && <EventNavigation id={id} isCurrent={currentNav} />}
                    </div>
                    <EventsStyles.Container>
                        <SessionCard.InfoContainer>
                            <SessionCard.InfoTitle>
                                <Event.Ticket />
                                Tickets
                                <SessionCard.Subtitle>
                                    Select/Create the speaker for this event
                                </SessionCard.Subtitle>
                            </SessionCard.InfoTitle>
                            {!visible && <div style={{ padding: '25px 25px' }} >
                                {Tickets && this.getTicket(Tickets)}
                            </div>}
                        </SessionCard.InfoContainer>
                        <SessionCard.InfoContainer>
                            <SessionCard.InfoTitle>
                                <Event.WhatsApp />
                                WhatsApp Comms
                                <SessionCard.Subtitle>
                                    Enter comms to be sent via WhatsApp
                                </SessionCard.Subtitle>
                            </SessionCard.InfoTitle>
                            <div style={{ padding: '30px 0' }} >
                                {Template.map((e, index) => this.getTemplate(index))}
                                {
                                    (this.state.error && !get(this.state, 'modalData', []).length) && <StyledError>Required Comms rule*</StyledError>
                                }
                                <div>
                                    <SessionCard.PlusButton
                                        type="primary"
                                        // back='#cbbedf'
                                        shape='circle'
                                        size='large'
                                        onClick={() => {
                                            this.setState({
                                                Template: [...Template, !Template.length ? 0 : Template.length]
                                            }, () => {
                                                this.setState({
                                                    modalData: [...this.state.modalData,
                                                    { ...DEFAULT_TEMPLATE_DATA, id: this.state.Template.length - 1 }]
                                                })
                                            })
                                        }}
                                    ><PlusOutlined style={{ fontSize: '20px' }} /></SessionCard.PlusButton>
                                    <SessionCard.Smalltitle>Add more Templates</SessionCard.Smalltitle>
                                </div>
                            </div>
                        </SessionCard.InfoContainer>

                    </EventsStyles.Container>
                </EventsStyles>
                <StyledFooter>
                    <StyledBackButton
                        mRight
                        onClick={() => this.props.history.goBack()}
                    >
                        Back
                    </StyledBackButton>
                    <StyledButton
                        loading={get(updatedStatus, 'loading')}
                        onClick={this.handleSave}
                    >
                        Save & Continue
                    </StyledButton>
                </StyledFooter>
            </Event.EventsWrapper>
        )
    }
}

export default Tickets
