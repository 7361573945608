const editors = [
  { name: 'HTML editor', key: 'markup' },
  { name: 'Native Python editor', key: 'python' },
  { name: 'Sql editor', key: 'sql' },
  { name: 'Blockly Editor', key: 'blockly' },
  { name: 'Java Editor', key: 'java' },
  //   { name: 'Jupyter Notebook', key: 'jupyterNotebook' },
  // { name: 'Blockly Playground', key: 'blockly' }
]

export default editors
