import React from 'react'
import { Table } from '../../../../components/StyledComponents'
import UploadTableBody from './UploadTableBody'
import UploadTableHead from './UploadTableHead'
import hs from '../../../../utils/scale'

const columnsTemplate = '14% 36% 15% 15% 14% 6%'
const minWidth = '813px'
const rowLayoutProps = {
  columnsTemplate,
  minWidth,
  isVideoLOMapping: true
}

const UploadTable = props => (
  <Table.UploadTable>
    <UploadTableHead {...rowLayoutProps} />
    <div style={{ height: `${hs(720)}`, overflowY: 'scroll', overflowX: 'hidden', borderRadius: '0 0 20px 20px' }}>
      <UploadTableBody {...props} {...rowLayoutProps} />
    </div>
  </Table.UploadTable>
)
export default UploadTable

