import gql from 'graphql-tag'
import requestToGraphql from '../../utils/requestToGraphql'

const removeCoursePackageCoursesFromBatch = async (batchId) => {
    const REMOVE_COURSE = gql`
        mutation {
            removeCoursePackageCoursesFromBatch(batchId: "${batchId}") {
                result
            }
        }
    `
    await requestToGraphql(REMOVE_COURSE)
}

export default removeCoursePackageCoursesFromBatch
