import {
    Form, Icon, Input,
    Select,
} from "antd";
import React from "react";
import { StyledError, StyledPrizeFormAndImageContainer, StyledPrizeImageSection } from "../SpeakerPrizes.style";
import Dropzone from '../../../../components/Dropzone'
import styles from '../SpeakerPrizes.module.scss'
import { get } from "lodash";
import getFullPath from "../../../../utils/getFullPath";

const ranges = []
for (let i = 1; i <= 100; i += 1) {
    ranges.push(i)
}

class PrizeFormAndImage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            imageFile: null,
            imageUrl: getFullPath(get(this.props, 'form.image')),
            error: '',
        }
    }
    dropzoneRef = React.createRef(null)

    onDropFile = (file) => {
        this.setState({
            error: ''
        })
        const { prizeForms, location, setAllPrizes } = this.props
        if (file) {
            const img = new Image()
            img.src = window.URL.createObjectURL(file)
            img.onload = () => {
                const newUpdatedPrizeForms = prizeForms.map((prizeForm, idx) => {
                    if (location === idx) {
                        return { ...prizeForm, image: file }
                    } return prizeForm
                })
                setAllPrizes(newUpdatedPrizeForms)
                // if (img && (parseInt(img.width) >= 239 && parseInt(img.width) <= 254)
                //     && (parseInt(img.height) >= 239 && parseInt(img.height) <= 254)) {
                //     const newUpdatedPrizeForms = prizeForms.map((prizeForm, idx) => {
                //         if (location === idx) {
                //             return { ...prizeForm, image: file }
                //         } return prizeForm
                //     })
                //     setAllPrizes(newUpdatedPrizeForms)
                // } else {
                //     this.setState({ error: 'Recommended Size: between 239 to 254' })
                // }
            }
        }
    }

    setTitle = (e) => {
        const { prizeForms, location, setAllPrizes } = this.props
        const updatedPrizeForms = prizeForms.map((prizeForm, idx) => {
            if (location === idx) {
                return { ...prizeForm, title: e.target.value }
            } return prizeForm
        })
        setAllPrizes(updatedPrizeForms)
    }

    onBlurTitle = () => {
        const { setAllPrizes, prizeForms } = this.props
        const updatedPrizeForms = prizeForms.map((prizeForm, idx) => {
            return { ...prizeForm, title: get(prizeForm, 'title', '').trim() }
        })
        setAllPrizes(updatedPrizeForms)
    }

    setMinRank = (value) => {
        const { prizeForms, location, setAllPrizes } = this.props
        const updatedPrizeForms = prizeForms.map((prizeForm, idx) => {
            if (location === idx) {
                return { ...prizeForm, minRank: value }
            } return prizeForm
        })
        setAllPrizes(updatedPrizeForms)
    }
    setMaxRank = (value) => {
        const { prizeForms, location, setAllPrizes } = this.props
        const updatedPrizeForms = prizeForms.map((prizeForm, idx) => {
            if (location === idx) {
                return { ...prizeForm, maxRank: value }
            }
            if (idx === (location + 1)) {
                const findNumIndex = ranges.findIndex(num => Number(value) === num)
                if (findNumIndex !== -1) {
                    return { ...prizeForm, minRank: ranges[findNumIndex] + 1 }
                }
            }
            return prizeForm
        })
        setAllPrizes(updatedPrizeForms)
    }
    removeImageFromForm = () => {
        const { location, prizeForms, setAllPrizes } = this.props
        const newUpdatedPrizeForms = prizeForms.map((prizeForm, idx) => {
            if (location === idx) {
                return { ...prizeForm, image: '' }
            } return prizeForm
        })
        setAllPrizes(newUpdatedPrizeForms)
    }

    render() {
        const { removeForm, location, form, formError, prizeForms } = this.props
        const { error } = this.state;
        return <>
            <StyledPrizeFormAndImageContainer>
                <Form
                    className={styles.prizeForm}
                    labelCol={{
                        span: 10,
                    }}
                    wrapperCol={{
                        span: 21,
                    }}
                    layout="horizontal"
                    initialValues={{
                        size: 'default',
                    }}
                    size={'default'}
                >
                    <Form.Item>
                        <div className={styles.prizeNameWrapper}>
                            {`Prize ${location + 1}`}
                            <Input
                                style={{ border: 'none' }}
                                placeholder={`Add prize name`}
                                value={get(form, 'title')}
                                onChange={this.setTitle}
                                onBlur={this.onBlurTitle}
                            />
                        </div>
                        {(formError && !form.title) && <StyledError>Required*</StyledError>}
                    </Form.Item>
                    <Form.Item>
                        <Select
                            onChange={this.setMinRank}
                            value={get(form, 'minRank')}
                            disabled
                            placeholder='From rank'
                            style={{ width: 120, marginRight: '1rem' }}>
                            {
                                [...ranges].map(rank => <Select.Option key={Math.random() * 16 + parseInt(rank)} value={parseInt(rank)}>{rank}</Select.Option>)
                            }
                        </Select>
                        {(formError && !form.minRank) && <StyledError>Required*</StyledError>}

                    </Form.Item>
                    <Form.Item>
                        <Select
                            onChange={this.setMaxRank}
                            value={get(form, 'maxRank')}
                            placeholder='To rank'
                            style={{ width: 120 }}>
                            {
                                [...ranges].filter(num =>
                                    location === 0 ? num :
                                        num > Number(get(prizeForms, `[${location - 1}].maxRank`))).map(rank =>
                                            <Select.Option value={parseInt(rank)}>{rank}</Select.Option>)
                            }
                        </Select>
                        {(formError && !form.maxRank) && <StyledError>Required*</StyledError>}

                    </Form.Item>

                </Form>
                <StyledPrizeImageSection>
                    <Dropzone
                        className={styles.prizeImage}
                        getDropzoneFile={(file) => this.onDropFile(file)}
                        ref={this.dropzoneRef}
                        defaultImage={this.state.imageUrl}
                        defaultFile={this.state.imageFile}
                        imageFile={this.state.imageFile}
                        onClose={this.removeImageFromForm}
                        onImageUrl={imgUrlOne => !error && this.setState({ imgUrlOne })}
                    >Click or drag to attach
                    </Dropzone>
                    {(formError && !form.image) && <StyledError>{this.state.error ? this.state.error : '*Required'}</StyledError>}

                </StyledPrizeImageSection>
                {location > 0 && <Icon onClick={() => removeForm(location, 'prizeForms')} type='close' className={styles.removeForm} />}
            </StyledPrizeFormAndImageContainer>
        </>
    }
}

export default PrizeFormAndImage