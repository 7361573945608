import moment from 'moment'

const getDateRangeArray = (startDate, stopDate) => {
  const dateArray = []
  let currentDate = moment(startDate).endOf('day')
  while (currentDate <= moment(stopDate).endOf('day')) {
    dateArray.push({
      date: new Date(moment(currentDate).endOf('day')),
      month: moment(currentDate).month()
    })
    currentDate = moment(currentDate).add(1, 'day')
  }
  return dateArray
}

export default getDateRangeArray
