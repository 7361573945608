import { connect } from 'react-redux'
import { filterKey } from '../../utils/data-utils'
import SchoolOnboarding from './SchoolOnboarding'
import withNav from '../../components/withNav'

const SchoolOnboardingNav = withNav(SchoolOnboarding)({
  title: 'School Onboarding',
  activeNavItem: 'School Onboarding',
  showSMSNavigation: true,
  noPadding: true
})

const mapStateToProps = (state) => ({
  userSecondaryRole: state.login.secondaryRole,
  schoolData: filterKey(state.data.getIn(['schools', 'data']), 'school'),
  schoolDashboardCount: state.data.getIn(['schoolDashboardCount', 'data']),
  schoolClassesGrades: state.data.getIn(['schoolClasses', 'data']),
  schoolClassGradesFetchStatus: state.data.getIn(['schoolClasses', 'fetchStatus', 'schoolClasses']),
  schoolClassesAddStatus: state.data.getIn(['schoolClasses', 'addStatus', 'schoolClasses']),
  schoolClassesDeleteStatus: state.data.getIn(['schoolClasses', 'deleteStatus', 'schoolClasses']),
  campaigns: state.data.getIn(['campaigns', 'data']),
  campaignsFetchStatus: state.data.getIn(['campaigns', 'fetchStatus', 'campaigns']),
  campaignsAddStatus: state.data.getIn(['campaigns', 'addStatus', 'campaigns']),
  campaignsUpdateStatus: state.data.getIn(['campaigns', 'updateStatus', 'campaigns']),
  campaignsUpdateBatchStatus: state.data.getIn(['campaigns', 'updateStatus', 'addBatches']),
  campaignsDeleteStatus: state.data.getIn(['campaigns', 'deleteStatus', 'campaigns']),
  parentSignUpStatus: state.data.getIn([
    'parentChildSignUp', 'addStatus', 'addParentSignUp'
  ]),

  courses: state.data.getIn(['course', 'data']),
  courseFetchingStatus: state.data.getIn(['courses', 'fetchStatus', 'courses']),
  MentorProfilesStatus: state.data.getIn(['mentorProfiles','fetchStatus','mentorProfiles']),
  MentorProfilesData: state.data.getIn(['mentorProfiles','data']),
  MentorProfilesMetaData: state.data.getIn(['mentorProfilesMeta','data']),
  MentorProfilesMetaStatus: state.data.getIn(['mentorProfilesMeta','fetchStatus','mentorProfilesMeta']),

  timeTableScheduleStatus: state.data.getIn(['timeTableSchedule', 'addStatus', 'addTimeTableSchedule']),
  updateTimeTableScheduleStatus: state.data.getIn(['timeTableSchedule', 'updateStatus', 'updateTimeTableSchedule']),
  addtimeTableScheduleError: state.data.getIn(['errors','timeTableSchedule/add']),
  updatetimeTableScheduleError: state.data.getIn(['errors','timeTableSchedule/update']),
  academicYearAddStatus: state.data.getIn(['academicYear', 'addStatus', 'academicYear']),
  academicYearsData: state.data.getIn(['academicYears', 'data']),
  academicYearsFetchStatus: state.data.getIn(['academicYears', 'fetchStatus', 'academicYears']),
})


export default connect(mapStateToProps)(SchoolOnboardingNav)
