import React from 'react'
import moment from 'moment'
import { get, sortBy } from 'lodash'
import { SyncOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import Calendar from '../../../components/FullCalendar/FullCalendar'
import { mentorDashboard as colors, TekieGreen } from '../../../constants/colors'
import DemandCalendarWrapper from './DemandCalendar.style'
import Styles from '../DemandSupplyDashboard.style'
import '../../MentorDashboard/customStyles.scss'
import DateSelector from '../components/DateSelector'
import EventView from './components/EventView'
import { fetchMenteeSessionsForDemand, fetchBatchSessionForDemand } from '../../../actions/demandSupplyDashboard'
import { getSuccessStatus } from '../../../utils/data-utils'
import { getSlotTime, isTrialSession } from '../demandSupply-utils/timeFunctions'
import PageLoading from '../components/PageLoading'
import { B2B, B2B2C, B2C } from '../../../constants/demandVerticals'
import { getDateDiff } from '../demandSupply-utils'
import customNotification from '../components/CustomNotification'

const verticalFilters = {
  All: { filterValue: null },
  b2c: { filterValue: null },
  b2b: { filterValue: ',{batch_some: {type: b2b}}' },
  b2b2c: { filterValue: ',{batch_some: {type: b2b2c}}' },
}

const stages = [
  {
    title: 'All'
  },
  {
    title: 'Assigned'
  },
  {
    title: 'UnAssigned'
  }
]

class DemandCalendar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fromDate: null,
      toDate: null,
      selectedVertical: 'All',
      // paySlab: 'All',
      stage: 'All',
      activeEvent: null,
      selectedDate: new Date(),
      fetchedEvents: [],
      selectedEventId: '',
      shoudUpdate: true,
      initialCalendarView: 'timeGridDay',
      isFetching: false
    }
  }

  handleFilterChange = (name, value) => {
    this.setState({
      [name]: value
    }, this.fetchSessionDetails)
  }

  fetchSessionDetails = async () => {
    const { fromDate, toDate, selectedVertical, initialCalendarView } = this.state
    let sessionFilters = ''
    if (fromDate) sessionFilters += `{ bookingDate_gte: "${moment(fromDate).startOf('day').toISOString()}" }`
    if (toDate) sessionFilters += `{ bookingDate_lte: "${moment(toDate).subtract(1, 'day').endOf('day').toISOString()}" }`
    let batchSessionFilter = `${sessionFilters}, {batch_some: {type_not: normal}}`
    if (selectedVertical === B2B || selectedVertical === B2B2C) {
      batchSessionFilter += get(verticalFilters, `[${selectedVertical}].filterValue`, '')
    }
    if (initialCalendarView === 'timeGridDay' && getDateDiff(fromDate, toDate, 1)) {
      fetchMenteeSessionsForDemand(sessionFilters)
      fetchBatchSessionForDemand(batchSessionFilter)
    } else if (initialCalendarView === 'timeGridWeek' && getDateDiff(fromDate, toDate, 7)) {
      fetchMenteeSessionsForDemand(sessionFilters)
      fetchBatchSessionForDemand(batchSessionFilter)
    } else if (initialCalendarView === 'listMonth') {
      if (getDateDiff(fromDate, toDate, moment(moment(fromDate).endOf('month')).get('dates'))) {
        fetchMenteeSessionsForDemand(sessionFilters)
        fetchBatchSessionForDemand(batchSessionFilter)
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    const { menteeSessionsForDemandFetchStatus,
      mentorMenteeSessionsForDemandFetchStatus,
      batchSessionsFetchStatus,
      mentorMenteeSessionsForDemandDeleteStatus,
      mentorMenteeSessionsForDemandAddStatus,
      batchSessionsUpdateStatus,
      menteeSessionUpdateStatus,
      batchSessionsUpdateStatusFailure,
      mentorMenteeSessionsForDemandAddFailure } = this.props
    if (getSuccessStatus(menteeSessionsForDemandFetchStatus,
      prevProps.menteeSessionsForDemandFetchStatus)) {
      this.convertDataToEvents()
    }

    if (getSuccessStatus(mentorMenteeSessionsForDemandFetchStatus,
      prevProps.mentorMenteeSessionsForDemandFetchStatus)) {
      this.convertDataToEvents()
    }

    if (getSuccessStatus(batchSessionsFetchStatus, prevProps.batchSessionsFetchStatus)) {
      this.convertDataToEvents()
    }

    if (getSuccessStatus(mentorMenteeSessionsForDemandDeleteStatus,
      prevProps.mentorMenteeSessionsForDemandDeleteStatus)) {
      this.setState({
        shoudUpdate: false
      })
      this.convertDataToEvents()
    }

    if (getSuccessStatus(mentorMenteeSessionsForDemandAddStatus,
      prevProps.mentorMenteeSessionsForDemandAddStatus)) {
      this.setState({
        shoudUpdate: false
      })
      this.convertDataToEvents()
    } else if (mentorMenteeSessionsForDemandAddStatus &&
      !get(mentorMenteeSessionsForDemandAddStatus.toJS(), 'loading')
      && get(mentorMenteeSessionsForDemandAddStatus.toJS(), 'failure') &&
      (prevProps.mentorMenteeSessionsForDemandAddFailure !==
        mentorMenteeSessionsForDemandAddFailure)) {
      if (mentorMenteeSessionsForDemandAddFailure &&
        mentorMenteeSessionsForDemandAddFailure.toJS().length > 0) {
        const errors = mentorMenteeSessionsForDemandAddFailure.toJS().pop()
        customNotification(get(get(errors, 'error').errors[0], 'message'), '', null, 'error')
      }
    }

    if (getSuccessStatus(batchSessionsUpdateStatus,
      prevProps.batchSessionsUpdateStatus)) {
      this.setState({
        shoudUpdate: false
      })
      this.convertDataToEvents()
    } else if (batchSessionsUpdateStatus && !get(batchSessionsUpdateStatus.toJS(), 'loading')
      && get(batchSessionsUpdateStatus.toJS(), 'failure') &&
      (prevProps.batchSessionsUpdateStatusFailure !== batchSessionsUpdateStatusFailure)) {
      if (batchSessionsUpdateStatusFailure && batchSessionsUpdateStatusFailure.toJS().length > 0) {
        const errors = batchSessionsUpdateStatusFailure.toJS().pop()
        const errorMsg = get(errors, 'error.errors[0].extensions.exception.data.message')
        if (errorMsg && errorMsg.includes('Mentee')) {
          customNotification(`Session is already booked for ${errorMsg.split('->')[1]}`, '', null, 'error')
        } else {
          customNotification(get(get(errors, 'error').errors[0], 'message'), '', null, 'error')
        }
      }
    }
    if (getSuccessStatus(menteeSessionUpdateStatus, prevProps.menteeSessionUpdateStatus)) {
      customNotification('Mentors broadcasted successfully', '', null, 'success')
    }
  }

  getTimeRangeFromSession = (bookingDate, session, newLogic = false) => {
    bookingDate = new Date(bookingDate).toDateString()
    const startTime = `${bookingDate}, ${get(getSlotTime(session, newLogic), 'startTime')}`
    const endTime = `${bookingDate}, ${get(getSlotTime(session, newLogic), 'endTime')}`
    return { startTime, endTime }
  }
  getBgColorBasedOnSessionType = (sessionType, batchSession, colorType = '') => {
    if (sessionType === 'trial' || sessionType === 'paid') {
      return colors[`b2cAccent${colorType}`]
    } else if (sessionType === 'batch') {
      if (batchSession && batchSession.batch) {
        if (batchSession.batch.type === 'b2b') {
          return colors[`b2bAccent${colorType}`]
        } else if (batchSession.batch.type === 'b2b2c') {
          return colors[`b2b2cAccent${colorType}`]
        }
        return colors[`b2cAccent${colorType}`]
      }
    }
    return colors[`b2cAccent${colorType}`]
  }
  convertDataToEvents = () => {
    let { menteeSessionsForDemand, mentorMenteeSessionsForDemand, batchSessions } = this.props
    const { menteeSessionsForDemandFetchStatus, mentorMenteeSessionsForDemandFetchStatus,
      batchSessionsFetchStatus } = this.props
    menteeSessionsForDemand = menteeSessionsForDemand && menteeSessionsForDemand.toJS() || []
    mentorMenteeSessionsForDemand = mentorMenteeSessionsForDemand
      && mentorMenteeSessionsForDemand.toJS() || []
    batchSessions = batchSessions && batchSessions.toJS() || []
    const isFetchingData = (menteeSessionsForDemandFetchStatus && get(menteeSessionsForDemandFetchStatus.toJS(), 'loading')) ||
      (mentorMenteeSessionsForDemandFetchStatus && get(mentorMenteeSessionsForDemandFetchStatus.toJS(), 'loading')) ||
      (batchSessionsFetchStatus && get(batchSessionsFetchStatus.toJS(), 'loading'))
    menteeSessionsForDemand.forEach(menteeSession => {
      const findMMSession = mentorMenteeSessionsForDemand.find(mmSession =>
        get(mmSession, 'menteeSession.id') === get(menteeSession, 'id'))
      menteeSession.mentorMenteeSession = findMMSession
    })
    const events = []
    menteeSessionsForDemand.forEach(menteeSession => {
      const { startTime, endTime } = this.getTimeRangeFromSession(
        get(menteeSession, 'bookingDate'), menteeSession)
      const sessionType = isTrialSession(get(menteeSession, 'topic.order')) ? 'trial' : 'paid'
      let backgroundColor = ''
      let textColor = ''
      if (get(menteeSession, 'mentorMenteeSession.id')) {
        backgroundColor = this.getBgColorBasedOnSessionType(sessionType, menteeSession)
        textColor = 'white'
      } else {
        backgroundColor = 'white'
        textColor = this.getBgColorBasedOnSessionType(sessionType, menteeSession)
      }
      if (!isFetchingData) {
        events.push({
          title: get(menteeSession, 'course.title', get(menteeSession, 'topic.title', '')),
          allDay: false,
          date: new Date(startTime),
          end: new Date(endTime),
          backgroundColor,
          borderColor: TekieGreen,
          extendedProps: {
            startTime: new Date(startTime),
            endTime: new Date(endTime),
            theme: {
              color: textColor
            },
            record: menteeSession,
            recordId: get(menteeSession, 'id'),
            recordType: 'menteeSession',
            sessionType,
            totalStudent: 1,
            courseId: get(menteeSession, 'course.id'),
            sessionId: get(menteeSession, 'id'),
            rawBookingDate: get(menteeSession, 'bookingDate'),
            isB2B2CTrial: false,
            clickable: true,
            documentType: 'regular',
            tooltipVisible: false,
            mentorMenteeSession: get(menteeSession, 'mentorMenteeSession'),
            course: get(menteeSession, 'course'),
            topic: get(menteeSession, 'topic'),
            broadCastedMentors: get(menteeSession, 'broadCastedMentors', []),
            sessionStartDate: get(menteeSession, 'mentorMenteeSession.sessionStartDate'),
            sessionStatus: get(menteeSession, 'mentorMenteeSession.sessionStatus'),
            createdAt: get(menteeSession, 'createdAt'),
            width: 'fit-content',
            maxWidth: '120px',
            mentorDetail: get(menteeSession, 'mentorMenteeSession.mentorSession.user'),
            mentorAvailabilitySlotId: get(menteeSession, 'mentorAvailabilitySlot'),
            isSessionAssigned: !!get(menteeSession, 'mentorMenteeSession'),
          }
        })
      }
    })
    batchSessions.forEach(batchSession => {
      const { startTime, endTime } = this.getTimeRangeFromSession(
        get(batchSession, 'bookingDate'), batchSession)
      if (!isFetchingData) {
        const sessionType = get(batchSession, 'batch.type') !== 'b2b' && isTrialSession(get(batchSession, 'topic.order')) ? 'trial' : 'paid'
        let borderColor = ''
        let backgroundColor = ''
        if (get(batchSession, 'batch.type') === B2B) {
          borderColor = '#FAAD14'
          backgroundColor = '#FAAD14'
        } else if (get(batchSession, 'batch.type') === B2B2C) {
          borderColor = '#2593E2'
          backgroundColor = '#2593E2'
        }
        events.push({
          title: get(batchSession, 'course.title', get(batchSession, 'topic.title', '')),
          allDay: false,
          date: new Date(startTime),
          end: new Date(endTime),
          backgroundColor,
          borderColor,
          extendedProps: {
            startTime: new Date(startTime),
            endTime: new Date(endTime),
            theme: {
              color: 'white'
            },
            record: batchSession,
            recordId: get(batchSession, 'id'),
            recordType: 'batchSession',
            sessionType,
            totalStudent: get(batchSession, 'attendance', []).length || 0,
            courseId: get(batchSession, 'course.id'),
            sessionId: get(batchSession, 'id'),
            rawBookingDate: get(batchSession, 'bookingDate'),
            isB2B2CTrial: false,
            clickable: true,
            documentType: 'regular',
            tooltipVisible: false,
            mentorMenteeSession: {},
            batch: get(batchSession, 'batch'),
            course: get(batchSession, 'course'),
            topic: get(batchSession, 'topic'),
            broadCastedMentors: get(batchSession, 'broadCastedMentors', []),
            sessionStartDate: get(batchSession, 'sessionStartDate'),
            sessionStatus: get(batchSession, 'sessionStatus'),
            createdAt: get(batchSession, 'createdAt'),
            width: 'fit-content',
            maxWidth: '120px',
            mentorDetail: get(batchSession, 'mentorSession.user'),
            mentorAvailabilitySlotId: get(batchSession, 'mentorAvailabilitySlot'),
            mentorSession: get(batchSession, 'mentorSession'),
            isSessionAssigned: !!get(batchSession, 'mentorSession'),
          }
        })
      }
    })
    if (!isFetchingData) {
      this.setState({
        isFetching: false
      })
    }
    this.setState({
      fetchedEvents: sortBy(events, 'date')
    }, this.filterEventsLocally)
  }

  filterEventsLocally = () => {
    const { fetchedEvents, shoudUpdate, selectedVertical, stage } = this.state
    let newFetchedEvents = [...fetchedEvents]
    if (selectedVertical === B2C) {
      newFetchedEvents = newFetchedEvents.filter(event => get(event, 'extendedProps.recordType', '') === 'menteeSession')
    } else if (selectedVertical !== 'All' && selectedVertical !== B2C) {
      newFetchedEvents = newFetchedEvents.filter(event => get(event, 'extendedProps.recordType', '') !== 'menteeSession')
    }
    if (stage === 'Assigned') {
      newFetchedEvents = newFetchedEvents.filter(event => get(event, 'extendedProps.isSessionAssigned', false))
    } else if (stage === 'UnAssigned') {
      newFetchedEvents = newFetchedEvents.filter(event => !get(event, 'extendedProps.isSessionAssigned', false))
    }
    if (newFetchedEvents.length > 0 && shoudUpdate) {
      const newActiveEvent = get(newFetchedEvents, '[0].extendedProps')
      this.setState({
        fetchedEvents: newFetchedEvents,
        activeEvent: newActiveEvent,
        selectedEventId: get(newActiveEvent, 'sessionId')
      }, () => this.setState({ shoudUpdate: true }))
    } else if (newFetchedEvents.length === 0) {
      this.setState({
        activeEvent: null,
        selectedEventId: '',
      }, () => this.setState({ shoudUpdate: true }))
    }
  }
  onDateChange = (type) => {
    const { initialCalendarView, fromDate } = this.state
    let changeByDay = 1
    if (initialCalendarView === 'timeGridDay') changeByDay = 1
    else if (initialCalendarView === 'timeGridWeek') changeByDay = 7
    else if (initialCalendarView === 'listMonth') {
      changeByDay = moment(moment(fromDate).endOf('month')).get('dates')
    }
    if (type === 'prev') {
      this.setState({
        selectedDate: new Date(moment(fromDate).subtract(changeByDay, 'day'))
      })
    } else {
      this.setState({
        selectedDate: new Date(moment(fromDate).add(changeByDay, 'day'))
      })
    }
  }

  handleEventClick = (args) => {
    const { clickable } = get(args, 'event.extendedProps')
    if (clickable) {
      this.setState({
        activeEvent: get(args, 'event.extendedProps'),
        selectedEventId: get(args, 'event.extendedProps.sessionId')
      })
    }
  }

  onNavigation = (type) => {
    const { fetchedEvents, selectedEventId } = this.state
    const findEventIndex = fetchedEvents.findIndex(event => get(event, 'extendedProps.sessionId') === selectedEventId)
    if (findEventIndex !== -1) {
      if (type === 'prev') {
        if (findEventIndex === 0) {
          this.onDateChange('prev')
        } else {
          const newSelectedEvent = fetchedEvents[findEventIndex - 1]
          if (newSelectedEvent) {
            const { clickable } = get(newSelectedEvent, 'extendedProps')
            if (clickable) {
              this.setState({
                activeEvent: get(newSelectedEvent, 'extendedProps'),
                selectedEventId: get(newSelectedEvent, 'extendedProps.sessionId')
              })
            }
          }
        }
      } else if (type === 'next') {
        if (findEventIndex === (fetchedEvents.length - 1)) {
          this.onDateChange('next')
        } else {
          const newSelectedEvent = fetchedEvents[findEventIndex + 1]
          if (newSelectedEvent) {
            const { clickable } = get(newSelectedEvent, 'extendedProps')
            if (clickable) {
              this.setState({
                activeEvent: get(newSelectedEvent, 'extendedProps'),
                selectedEventId: get(newSelectedEvent, 'extendedProps.sessionId')
              })
            }
          }
        }
      }
    }
  }

  render() {
    const { selectedVertical, stage, activeEvent, selectedDate,
      fetchedEvents, selectedEventId, initialCalendarView, isFetching } = this.state
    const { menteeSessionsForDemandFetchStatus,
      mentorMenteeSessionsForDemandFetchStatus,
      batchSessionsFetchStatus } = this.props
    const isFetchingData = (menteeSessionsForDemandFetchStatus && get(menteeSessionsForDemandFetchStatus.toJS(), 'loading')) ||
      (mentorMenteeSessionsForDemandFetchStatus && get(mentorMenteeSessionsForDemandFetchStatus.toJS(), 'loading')) ||
      (batchSessionsFetchStatus && get(batchSessionsFetchStatus.toJS(), 'loading'))
    return (
      <DemandCalendarWrapper id={initialCalendarView !== 'timeGridWeek' ? 'demand-calendar-dashboard-container' : 'demand-calendar-dashboard-container-week'}>
        <PageLoading isfetching={isFetchingData} />
        <Styles.TopBarContainer>
          <Styles.FlexContainer>
            <DateSelector selectedDate={selectedDate} onDateChange={this.onDateChange} />
            <Tooltip
              title='Refresh'
              overlayClassName='styledTooltip-demandEvent'
            >
              <SyncOutlined
                style={{ fontSize: '20px', marginLeft: '10px' }}
                onClick={() => {
                  this.setState({
                    isFetching: true
                  }, this.fetchSessionDetails)
                }}
                spin={isFetching && isFetchingData}
              />
            </Tooltip>
          </Styles.FlexContainer>
          <Styles.FlexContainer>
            <DemandCalendarWrapper.CustomDatePicker
              placeholder='Date'
              allowClear={false}
              value={moment(selectedDate)}
              onChange={(value) => {
                this.setState({
                  selectedDate: new Date(value)
                })
              }}
              format='DD MMMM YYYY'
              style={{ width: '190px' }}
            />
            <Styles.SelectWrapper>
              <h3>Vertical</h3>
              <Styles.Select
                style={{ width: '100%' }}
                value={selectedVertical}
                placeholder='Vertical'
                verticals
                name='selectedVertical'
                onChange={(value) => this.handleFilterChange('selectedVertical', value)}
              >
                {Object.keys(verticalFilters).map(verticalType => (
                  <Styles.Option className='custom-selectOption' style={{ textTransform: 'uppercase' }} value={verticalType}>
                    <Styles.Checkbox checked={verticalType === selectedVertical} />
                    <span>{verticalType}</span>
                  </Styles.Option>
                ))}
              </Styles.Select>
            </Styles.SelectWrapper>
            {/* <Styles.SelectWrapper>
              <h3>PaySlab</h3>
              <Styles.Select
                style={{ width: '100%' }}
                value={paySlab}
                placeholder='PaySlab'
                name='paySlab'
                onChange={(value) => this.handleFilterChange('paySlab', value)}
              >
                {paySlabFilters.map(paySlabFilter => (
                  <Styles.Option className='custom-selectOption' value={paySlabFilter.title}>
                    <Styles.Checkbox checked={paySlabFilter.title === paySlab} />
                    <span>{paySlabFilter.text}</span>
                  </Styles.Option>
                ))}
              </Styles.Select>
            </Styles.SelectWrapper> */}
            <Styles.SelectWrapper>
              <h3>Stages</h3>
              <Styles.Select
                style={{ width: '100%' }}
                value={stage}
                placeholder='Stages'
                name='stage'
                onChange={(value) => this.handleFilterChange('stage', value)}
              >
                {stages.map(stageFilter => (
                  <Styles.Option className='custom-selectOption' value={stageFilter.title}>
                    <Styles.Checkbox checked={stageFilter.title === stage} />
                    <span>{stageFilter.title}</span>
                  </Styles.Option>
                ))}
              </Styles.Select>
            </Styles.SelectWrapper>
            <Styles.TabContainer isB2C calendarView>
              <Styles.TabButton className='day'
                isActive={initialCalendarView === 'timeGridDay'}
                onClick={() => this.handleFilterChange('initialCalendarView', 'timeGridDay')}
              >
                Day
              </Styles.TabButton>
              <Styles.TabButton className='week'
                isActive={initialCalendarView === 'timeGridWeek'}
                onClick={() => this.handleFilterChange('initialCalendarView', 'timeGridWeek')}
              >
                Week
              </Styles.TabButton>
              {/* <Styles.TabButton className='list'
                isActive={initialCalendarView === 'listMonth'}
                onClick={() => this.handleFilterChange('initialCalendarView', 'listMonth')}
              >
                List
              </Styles.TabButton> */}
            </Styles.TabContainer>
          </Styles.FlexContainer>
        </Styles.TopBarContainer>
        <Styles.CalendarContainer isActive={Boolean(activeEvent)}>
          <Calendar
            initialCalendarView={initialCalendarView}
            demandCalendar
            navLinks={false}
            customViews={{
              timeGridWeek: {
                dayHeaderFormat: { weekday: 'short', day: 'numeric' },
                dayHeaderContent: ({ date }) => (
                  <>
                    <div className='fullcalendar-timeGrid-header-weekday'>{date.toLocaleDateString('en', { weekday: 'short' })}</div>
                    <div className='fullcalendar-timeGrid-header-date'>{date.getDate()}</div>
                  </>
                )
              },
              timeGridDay: {
                dayHeaderFormat: { weekday: 'short', day: 'numeric' },
                dayHeaderContent: ({ date }) => (
                  <>
                    <div className='fullcalendar-timeGrid-header-date'>{date.toLocaleDateString('en', { weekday: 'short' })} {date.getDate()}</div>
                  </>
                )
              }
            }}
            customHeaderToolBar={{
              start: '',
              right: ''
            }}
            customDateToNavigate={selectedDate}
            datesSet={(args) => {
              const { fromDate, toDate } = this.state
              if (fromDate !== args.startStr || toDate !== args.endStr) {
                this.setState({
                  fromDate: args.startStr,
                  toDate: args.endStr,
                }, this.fetchSessionDetails)
              }
            }}
            handleEventClick={this.handleEventClick}
            fetchedEvents={fetchedEvents}
            selectedEventId={selectedEventId}
            eventMaxStack={1}
          />
          {Boolean(activeEvent) && (
            <EventView
              activeEvent={activeEvent}
              selectedEventId={selectedEventId}
              onNavigation={this.onNavigation}
            />
          )
          }
        </Styles.CalendarContainer>
      </DemandCalendarWrapper>
    )
  }
}

export default DemandCalendar
