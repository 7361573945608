import gql from 'graphql-tag'
import duck from '../../duck'

const DELETE_LEAD_PARTNERS = ({ leadPartnerId, userId }) => gql`
  mutation {
  ${userId && leadPartnerId ? `removeFromLeadPartnerAdminUser(leadPartnerId: "${leadPartnerId}", userId: "${userId}") {
    leadPartner {
      id
    }
  }` : ''}
  ${leadPartnerId ? `deleteLeadPartnerAgent(id: "${leadPartnerId}") {
    id
  }` : ''}
}
`
const removeOrDeleteLeadPartner = async ({ leadPartnerId, userId }) => duck.query({
  query: DELETE_LEAD_PARTNERS({ leadPartnerId, userId }),
  type: 'leadPartners/delete',
  key: 'leadPartners12',
})

export default removeOrDeleteLeadPartner
