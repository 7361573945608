/* eslint-disable import/no-duplicates */
import * as yup from 'yup'

const studentValidationSchema = yup.object().shape({
  grade: yup.string().required('grade (Not found)'),
  section: yup.string().matches(/^[A-Z]$/, 'section (Invalid format)').nullable(),
  childName: yup.string().required('childName (Not found)').min(3, 'childName (Min. 3 characters)').max(30, 'childName (Max. 30 characters)'),
  parentName: yup.string().required('parentName (Not Found)').min(3, 'parentName (Min. 3 characters)').max(30, 'parentName (Max. 30 characters)'),
  phoneNumber: yup.string().matches(/^[6-9]\d{9}$/, 'phoneNumber (Invalid format)')
    .notRequired(),
  parentEmail: yup.string().email('parentEmail (Invalid format)').notRequired().nullable(),
  password: yup.string().notRequired('password is required').min(6, 'Password should be atleast of 6 character.').nullable(),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'passwords must match').nullable(),
  rollNo: yup.string().notRequired().nullable(),
})

export default studentValidationSchema
