/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react'
import { Form, Select, Spin } from 'antd'
import { debounce, get, method } from 'lodash'
import RadioGroup from 'antd/lib/radio/group'
import MainModal from '../../../../components/MainModal'
import addMentorToSensei from '../../../../actions/senseiProfiles/addMentorProfile'
import addMentorToSenseiTeam from '../../../../actions/senseiProfiles/addMentorToSenseiTeam'
import fetchMentorsList from '../../../../actions/senseiProfiles/fetchMentorsList'

export default class AddMentorsModal extends React.Component {
  state = {
    search: '',
    selectedMentorNames: [],
    selectedMentorIds: [],
    searchParam: 'mentor',
    allSelectedUsers: [],
  }

  onCancel = () => {
    const { selectedMentorNames } = this.state
    if (selectedMentorNames.length !== 0) {
      this.setState({
        selectedMentorIds: [],
        selectedMentorNames: []
      })
    }
    this.props.onCancel()
  }
  onSave = () => {
    let found = -1
    this.props.mentorsUnderSensei.map(mentor => {
      this.state.selectedMentorIds.map(mentorId => {
        if (mentor.user.id === mentorId.key) {
          found = 1
          this.props.handleCannotAddMentor()
        }
      })
    })
    const selectedMentors = []
    this.state.selectedMentorNames.map(mentorId => {
      this.state.allSelectedUsers.map(mentor => {
        if (mentor.id === mentorId.key.id) {
          selectedMentors.push(mentor)
        }
      })
    })

    if (found === -1) {
      this.handlePropsForQuery()
    }
    const { selectedMentorNames } = this.state
    if (selectedMentorNames.length !== 0) {
      this.setState({
        selectedMentorIds: [],
        selectedMentorNames: []
      })
    }
  }
  isSaving = () => {
    if (this.props.isAddingMentor) {
      return true
    }
  }
  handleSearch = (value) => {
    const { searchParam } = this.state
    this.setState({ search: value }, () => {
      const { search } = this.state
      if (search.length >= 3) {
        if (searchParam === 'mentor') {
          debounce(() => {
            fetchMentorsList(`{name_contains: "${search}"}`)
          }, 800)()
        } else {
          debounce(() => {
            fetchMentorsList(`{phone_number_subDoc_contains: "${search}"}`)
          }, 800)()
        }
      }
    })
  }
  handleInputChange = (value, key) => {
    const latestUserSelected = value[value.length - 1]
    const getDetailsOfLatestUserSelected = this.mentorsFilter().forEach(user => {
      if (get(user, 'id') === latestUserSelected.key) {
        this.setState({ allSelectedUsers: [...this.state.allSelectedUsers, { id: get(user, 'id'), mentorProfileId: get(user, 'mentorProfile.id'), label: get(user, 'name') }] })
      }
    })
    this.setState({
      selectedMentorIds: key,
      selectedMentorNames: value,
      search: ''
    })
  }

  handlePropsForQuery = async () => {
    const { senseiId: senseiConnectId, senseiProfile } = this.props
    const { selectedMentorNames, allSelectedUsers } = this.state

    const mentorIds = []
    let mentorQuery = ''
    selectedMentorNames.map((mentorId, index) => {
      const isExist = allSelectedUsers.find(mentor => get(mentor, 'id') === mentorId.key)

      const userConnectId = mentorId.key
      if (isExist && get(isExist, 'mentorProfileId')) {
        mentorIds.push(get(isExist, 'mentorProfileId'))

        mentorQuery += `
        updateMentorProfile_${index}: updateMentorProfile(id: "${get(isExist, 'mentorProfileId')}", senseiProfileConnectId: "${senseiConnectId}") {
          id
        }`
      } else {
        mentorQuery += `
        addMentorProfile_${index}: addMentorProfile(input: {}, userConnectId: "${userConnectId}", senseiProfileConnectId: "${senseiConnectId}") {
          id
        }`
      }
    })
    if (mentorQuery) {
      await addMentorToSensei(mentorQuery)
      if (get(senseiProfile, 'senseiMentorTeams[0].id')) {
        await addMentorToSenseiTeam(senseiProfile.senseiMentorTeams[0].id, mentorIds)
        this.props.onCancel()
      }
      this.props.onCancel()
    }
  }

  hasDataFetched() {
    return this.props.hasFetchedAllMentors
  }

  onSearchChange = (event) => {
    this.setState({
      searchParam: event.target.value
    })
  }

  mentorsFilter = () => {
    const { allMentorsData } = this.props
    const { search = '', searchParam } = this.state
    let allMentorsDataFiltered = allMentorsData.filter(mentor => get(mentor, 'name') && get(mentor, 'name', '').toLowerCase().indexOf(search.toLowerCase()) !== -1)
    if (searchParam === 'number') {
      allMentorsDataFiltered = allMentorsData.slice(0, 30)
    }
    if (search.length < 2) {
      allMentorsDataFiltered = []
    }
    return allMentorsDataFiltered
  }

  render() {
    const { visible, title, allMentorsData, mentorsUnderSensei, isFetchingAllMentors } = this.props

    const { selectedMentorNames } = this.state
    const { Option } = Select
    const { search = '' } = this.state
    const filteredMentors = mentorsUnderSensei.map(mentor => get(mentor, 'user.id'))
    const allMentorsDataFiltered = allMentorsData.filter(mentor =>
      !filteredMentors.includes(mentor.id) && mentor && get(mentor, 'name', '').toLowerCase().indexOf(search.toLowerCase()) !== -1)
    return (
      <MainModal
        visible={visible}
        title={[
          <div>
            <div>{title}</div>
          </div>]}
        onCancel={() => this.onCancel()}
        maskClosable='true'
        width='500px'
        footer={
          [
            <MainModal.SaveButton
              type='primary'
              htmlType='submit'
              onClick={this.onSave}
            >
              {' '}
              {this.isSaving() ? 'Adding...' : 'ADD'}
            </MainModal.SaveButton>
          ]}
      >
        <Form id='mentor-edit-form'>
          <Form.Item label={
            <>
              {'Search by '}
              <RadioGroup
                options={[
                  { label: 'Mentor Name', value: 'mentor' },
                  { label: 'Phone Number', value: 'number' }
                ]}
                defaultValue='mentor'
                onChange={this.onSearchChange}
                value={this.state.searchParam}
              />
            </>}
          >
            <Select
              mode='multiple'
              labelInValue
              placeholder={this.state.searchParam === 'mentor' ? 'Type min 3 characters' : 'Type min 3 digits'}
              loading={isFetchingAllMentors}
              filterOption={false}
              value={this.state.selectedMentorNames || this.state.search}
              notFoundContent={!this.hasDataFetched() ? <Spin size='small' /> : null}
              onSearch={debounce(this.handleSearch, 800)}
              onChange={this.handleInputChange}
              style={{ width: '100%' }}
            >
              {
                this.mentorsFilter().map(item =>
                  <Select.Option
                    key={item.id}
                    label={get(item, 'name')}
                    // value={!item.mentorProfile ? null : item}
                    value={get(item, 'id') || ''}
                  >
                    {this.state.searchParam === 'mentor' ? item.name : `${item.name} - ${get(item, 'phone.number', 'null')}`}
                  </Select.Option>
                )
              }
            </Select>
          </Form.Item>
        </Form>
      </MainModal>
    )
  }
}
