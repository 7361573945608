/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import { get } from 'lodash'
import Blockly from 'blockly'
import { BlocklyWorkspace } from 'tekie-blockly';
import { BLOCKLY_PLAYGROUND_THEME } from '../../../../../utils/blocklyUtils'
import { Select, Radio, Tooltip, Checkbox } from 'antd'
import { DifficultyInput, Input, PublishInput } from './FormElements'
import Dropzone from '../../../../../components/Dropzone'
import { FlexContainer, StyledButton } from '../../ContentProject.styles'
import getFullPath from '../../../../../utils/getFullPath'
import { getSelectedValues } from '../../../../../utils/data-utils'
import { onAddIframeLink, projectValidation } from '../../../contentUtils'
import { removeFromCourseComponent } from '../../../../../actions/contentMaker'
import { PROJECT } from '../../../../../constants/CourseComponents'
import { encodeBase64, isBase64, decodeBase64 } from '../../../../../utils/base64Utility'
import { ContentEditor } from '../../../../../components/ContentEditor'
import getDecodedStatement from '../../../../../utils/getDecodedStatement'

const EditProjectForm = (props) => {
  const { handleEditProject, editFormData, projectUpdateStatus,
    coursesFetchStatus, coursesList,
    orderInUse } = props
  const externalPlatformLogoRef = useRef()
  const editFormRef = useRef()
  const projectThumbnailRef = useRef()
  const [externalPlatformLogo, setExternalPlatformLogo] = useState(null)
  const [projectThumbnail, setProjectThumbnail] = useState(null)
  const [selectedCourses, setSelectedCourses] = useState([])
  const [currentCheckedLink, setCurrentCheckedLink] = useState(3)
  const [currentCheckedBlock, setCurrentCheckedBlock] = useState(3)
  const [xmlData, setXmlData] = React.useState('')
  var init = 1;
  useEffect(() => {
    const newSelectedCourse = getSelectedValues(editFormData, coursesList, 'courses')
    setSelectedCourses(newSelectedCourse)
    const {layout, initialBlocks} = editFormData
    layout === 'externalPlatform' ? setCurrentCheckedLink(1) : setCurrentCheckedLink(2)
    initialBlocks ? setCurrentCheckedBlock(1) : setCurrentCheckedBlock(2)
    initialBlocks ? setXmlData(decodeBase64(initialBlocks)) : null;
  }, [editFormData.id])

  const onDropPlatformLogo = (file) => {
    setExternalPlatformLogo(file)
  }

  const onDropThumbnail = (file) => {
    setProjectThumbnail(file)
  }

  const handleSubmit = (value, meta) => {
    if(value.layout === 'playground'){
      value.externalPlatformLink = ''
      value.answerDescription = ''
    }
    value.layout === 'externalPlatform' || currentCheckedBlock == 2 ? value.initialBlocks = '' : null;
    handleEditProject(value, meta, externalPlatformLogo, projectThumbnail, selectedCourses)
  }

  const onSelect = (value) => {
    setSelectedCourses([...selectedCourses, value])
  }

  const onDeselect = (value) => {
    const newCourse = selectedCourses.filter(course =>
      get(course, 'key') !== get(value, 'key'))
    const addedCourses = get(editFormData, 'courses', []).map(course => get(course, 'id'))
    if (addedCourses.includes(get(value, 'key'))) {
      removeFromCourseComponent(get(value, 'key'), get(editFormData, 'id'), PROJECT)
    }
    setSelectedCourses(newCourse)
  }
  const checkTextType = (value) => {
    if (isBase64(value)) {
      return getDecodedStatement(value)
    }
    else return value
  }

  const onLinkChange = (value) => {
    setCurrentCheckedLink(value.target.value)
    if (value.target.value === 1 ) {
      setCurrentCheckedBlock(3)
    }
  }

  const onBlockChange = (value) => {
    setCurrentCheckedBlock(value.target.value)
  }

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  }

  const saveXML = (workspace) => {
    var xml = Blockly.Xml.workspaceToDom(Blockly.mainWorkspace)
    var xml_text = Blockly.Xml.domToText(xml)
    setXmlData(xml_text)
    return xml_text
    // var xml_got = Blockly.Xml.textToDom(xml_text)
  }

  const workspaceConfiguration = {
    readOnly: false,
    grid: false,
    move:{
      scrollbars: {
        horizontal: true,
        vertical: true
      },
      drag: true,
      wheel: true
    },
    zoom: {
      controls: true,
      wheel: true,
      startScale: 1.0,
      maxScale: 2,
      minScale: 0.3,
      scaleSpeed: 1.2,
      pinch: true
    },
    trashcan: true,
  }

  const filterOption = (input, option) => (
    get(option, 'props.children.props.children')
      ? get(option, 'props.children.props.children')
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
      : false
  )
 
  return (
    <Formik
      initialValues={editFormData}
      onSubmit={handleSubmit}
      validateOnBlur
      validationSchema={projectValidation}
      innerRef={editFormRef}
    >
      {({ values, handleChange, setFieldValue }) => (
        <Form style={{ padding: '0 10px' }} id='form'>
          <FlexContainer style={{ alignItems: 'flex-start' }}>
            <div style={{ flex: '0.6' }}>
              <Input
                label='Project Name'
                placeholder='Project Name'
                name='title'
                type='text'
                value={values.title || ''}
                onChange={handleChange}
                setFieldValue={setFieldValue}
              />
              <div >
                <ContentEditor
                  id={get(editFormData, 'id')}
                  initialValue={checkTextType(get(editFormData, 'projectDescription'))}
                  setFieldValue={setFieldValue}
                  inputRef='projectDescription'
                  name='projectDescription'
                  type='text'
                  placeholder='Project Description'
                  title='Project Description'
                  handleChange={handleChange}
                  inputValue={(!isBase64(values.projectDescription) && values.projectDescription) || ''}
                  bulletsEnabled
                  init={{
                    height: 250,
                    selector: `BBP-${get(editFormData, 'id')}`,
                    placeholder: "Start adding your question title here...",
                  }}
                  onChange={(_, editor) => {
                    editFormRef.current.setFieldValue('projectDescription', encodeBase64(editor.getContent()))
                  }}
                />
              </div>

              <div style={{
                display: 'grid',
                alignItems: 'flex-start',
                gridTemplateColumns: '30% 60%',
              }}
              >
                <h3>Select Courses</h3>
                <Select
                  mode='multiple'
                  labelInValue
                  placeholder='Select Courses'
                  loading={coursesFetchStatus && !get(coursesFetchStatus.toJS(), 'loading')}
                  filterOption={filterOption}
                  value={selectedCourses}
                  disabled
                  onSelect={onSelect}
                  onDeselect={onDeselect}
                  style={{ width: '100%' }}
                >
                  {
                    coursesList.map(item =>
                      <Select.Option
                        value={get(item, 'id')}
                        key={get(item, 'id')}
                      >
                        <Tooltip title={get(item, 'title')}>{get(item, 'title')}</Tooltip>
                      </Select.Option>
                    )
                  }
                </Select>
              </div>
            </div>
            <FlexContainer style={{ alignItems: 'flex-start', flex: '0.3' }}>
              <h3>Project Thumbnail</h3>
              <Dropzone
                style={{ height: '200px', width: '100%', marginBottom: '15px' }}
                getDropzoneFile={onDropThumbnail}
                ref={projectThumbnailRef}
                defaultImage={getFullPath(get(editFormData, 'projectThumbnail.uri'))}
                defaultFile={projectThumbnail}
              >Click or drag to attach
              </Dropzone>
            </FlexContainer>
          </FlexContainer>
          <DifficultyInput value={values.difficulty} setFieldValue={setFieldValue} />
          <FlexContainer justify='space-between' modalGrid>
            <FlexContainer style={{ alignItems: 'flex-start', flex: '0.3' }}>
              <h3>Platform Thumbnail:</h3>
              <Dropzone
                style={{ height: '200px', width: '100%', marginBottom: '15px' }}
                getDropzoneFile={onDropPlatformLogo}
                ref={externalPlatformLogoRef}
                defaultImage={getFullPath(get(editFormData, 'externalPlatformLogo.uri'))}
                defaultFile={externalPlatformLogo}
              >Click or drag to attach
              </Dropzone>
            </FlexContainer>
          </FlexContainer>
          <div>
            <span>Enable doc embed : </span>
            <Checkbox
              id='externalDescriptionEnabled'
              checked={values.externalDescriptionEnabled}
              onChange={({ target: { checked } }) => setFieldValue('externalDescriptionEnabled', checked)}
            />
          </div>
          <FlexContainer>
            <div style={{ width: '60%' }}>
              {get(values, 'externalDescriptionEnabled', false) ? (
                <Input
                  label='External Description Link'
                  placeholder='External Description Link'
                  name='projectCreationDescription'
                  type='text'
                  value={values.projectCreationDescription || ''}
                  onChange={(e) => setFieldValue('projectCreationDescription', onAddIframeLink(e.target.value))}
                  textArea
                  inputStyles={{ width: '100%', gridTemplateColumns: 'auto' }}
                />
              ) : <ContentEditor
                id={`2_${get(editFormData, 'id')}`}
                initialValue={checkTextType(get(editFormData, 'projectCreationDescription'))}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                name='projectCreationDescription'
                type='text'
                title='Create Description'
                shrinkedEditor
                inputRef='projectCreationDescription'
                placeholder='Create Description'
                inputValue={(!isBase64(values.projectCreationDescription) && values.projectCreationDescription) || ''}
                bulletsEnabled
                init={{
                  height: 250,
                  selector: `PJFCD-${get(editFormData, 'id')}`,
                  placeholder: "Start adding your question title here...",
                }}
                onChange={(_, editor) => {
                  editFormRef.current.setFieldValue('projectCreationDescription', encodeBase64(editor.getContent()))
                }}
              />}
            </div>
          </FlexContainer>
          <FlexContainer style = {{justifyContent:"flex-start"}}>
            <h3>Select Layout </h3>
            <Radio.Group 
              onChange={(e) => {     
                onLinkChange(e)
                return setFieldValue('layout', e.target.value==1 ? 'externalPlatform': 'playground')      
              }}
              value={currentCheckedLink} >
              <Radio style={radioStyle} value={1}>Practice Link</Radio>  
              <Radio style={radioStyle} value={2}>Blockly Code Playground</Radio>
            </Radio.Group>
          </FlexContainer>
          {currentCheckedLink === 1 ? <FlexContainer>
            <Input
              inputStyles={{ flex: '0.6' }}
              label='Create CTA link'
              placeholder='Create CTA link'
              name='externalPlatformLink'
              type='text'
              onBlur={() =>{if(get(values, 'externalPlatformLink', '') != null) return setFieldValue('externalPlatformLink', get(values, 'externalPlatformLink', '').trim())}}
              value={values.externalPlatformLink || ''}
              onChange={handleChange}
              setFieldValue={setFieldValue}
            />
          </FlexContainer> : null}
          {currentCheckedLink === 2 ? <FlexContainer style = {{justifyContent:"flex-start"}}>
            <h3>Set Initial Blocks </h3>
            <Radio.Group onChange={onBlockChange} value={currentCheckedBlock} >
              <Radio style={radioStyle} value={1}>Yes</Radio>  
              <Radio style={radioStyle} value={2}>No</Radio>
            </Radio.Group>
          </FlexContainer> : null}
          {currentCheckedBlock === 1 ? <div style={{ width: '100%', height: '500px', marginBottom: '20px' }}>
              <BlocklyWorkspace
                useDefaultToolbox
                workspaceConfiguration={workspaceConfiguration}
                onWorkspaceChange={(workspace) => {
                }}
                onInject={(e) => {
                }}
                customTheme={BLOCKLY_PLAYGROUND_THEME}
                onXmlChange={(workspace) => {  
                  init === 1 ? setFieldValue('initialBlocks', encodeBase64(xmlData)) : setFieldValue('initialBlocks', encodeBase64(saveXML(workspace)))      
                  init++
                }}
                initialXml={xmlData}
              />
            </div> : null
          }
          {currentCheckedLink === 1 ? <FlexContainer>
            <Input
              inputStyles={{ flex: '0.6' }}
              label='Submit Description'
              placeholder='Submit Description'
              name='answerDescription'
              type='text'
              textArea
              value={values.answerDescription || ''}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              inputRef='answerDescription'
            />
          </FlexContainer> : null}
          {/* <FlexContainer>
            <Input
              inputStyles={{ flex: '0.6' }}
              label='Create CTA link'
              placeholder='Create CTA link'
              name='externalPlatformLink'
              type='text'
              onBlur={() => setFieldValue('externalPlatformLink', get(values, 'externalPlatformLink', '').trim())}
              value={values.externalPlatformLink || ''}
              onChange={handleChange}
              setFieldValue={setFieldValue}
            />
          </FlexContainer> */}
          {/* <FlexContainer>
            <Input
              inputStyles={{ flex: '0.6' }}
              label='Submit Description'
              placeholder='Submit Description'
              name='answerDescription'
              type='text'
              textArea
              value={values.answerDescription || ''}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              inputRef='answerDescription'
            />
          </FlexContainer> */}
          <FlexContainer justify='flex-start'>
            <Input
              placeholder='Enter Order'
              inputStyles={{ marginBottom: '0' }}
              type='number'
              name='order'
              label='Order'
              value={values.order || ''}
              order
              values={values}
              orderInUse={orderInUse}
              setFieldValue={setFieldValue}
              onChange={(e) => handleChange(e)}
            />
            <PublishInput
              values={values}
              setFieldValue={setFieldValue}
            />
          </FlexContainer>
          <FlexContainer justify='center'>
            <StyledButton
              icon='file'
              id='add-btn'
              htmlType='submit'
              loading={projectUpdateStatus && get(projectUpdateStatus.toJS(), 'loading')}
            >
              Update
            </StyledButton>
          </FlexContainer>
        </Form>
      )}
    </Formik>
  )
}

export default EditProjectForm
