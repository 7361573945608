import requestToGraphql from './requestToGraphql';
import gql from 'graphql-tag';
import { get } from 'lodash'
import { notification } from 'antd';

const checkMentorDetails = async (mentorId) => {
const {data: {user: userObj}} = await requestToGraphql(gql`
    {
    user(id:"${mentorId}") {
        id
        profilePic {
            id
        }
        mentorProfile {
            sessionLink
            googleMeetLink
        }
        userBankDetail {
            id
        }}
    }`)
    
    // if(!get(userObj, 'mentorProfile.sessionLink') && !get(userObj, 'mentorProfile.googleMeetLink')){
    //     notification.error({
    //     message: 'Cannot open slot, as session Links doesn\'t exist for mentor'
    //     })
    // return false
    // }
    // if(!get(userObj, 'profilePic.id')){
    //     notification.error({
    //     message: 'Cannot open slot, as profile Pic doesn\'t exist for mentor'
    //     })
    // return false
    // }
    // if(!get(userObj, 'userBankDetail.id')){
    //     notification.error({
    //     message: 'Cannot open slot, as bank details doesn\'t exist for mentor'
    //     })
    // return false
    // }
    return true
} 
export default checkMentorDetails