const syncCoursePackageMappings = async (id) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // Specify the content type if sending JSON data
      Authorization: process.env.REACT_APP_TEKIE_25_ACCESS_TOKEN,
    },
    body: JSON.stringify({ coursePackageId: id }), // Convert data to JSON string before sending
  };
  const res = await fetch(
    `${process.env.REACT_APP_TEKIE_25_API_BASE_URL}batch-course-mapping/edit`,
    options
  );
  if (!res.ok) {
    console.log("Course package sync failed");
  }
};

export default syncCoursePackageMappings;
