import styled from 'styled-components'
import getFullPath from '../../../utils/getFullPath'

const CoursesStyle = styled.div`
    height: 100%;
    width: 100%;
    margin: 0 auto;
    font-family: 'Inter' !important;
`

const PageTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
`

const PageTitle = styled.div`
    font-size: 24px;
    letter-spacing: 0px;
    color: #212121;
    padding: 6px 10px 18px;
`

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const CourseContainer = styled.div`
    max-width: 100%;
    border-radius: 16px;
    display: flex;
    padding: 40px;
    background: #fff;
    margin: 10px;
    cursor: pointer;
`

const CourseThumbnail = styled.div`
    background-image: ${props => props.bgImage ? `url(${getFullPath(props.bgImage)})` : 'python/course/thumbnail_ckpwgsqpx00010txl9q1s19f2_1625117891120.jpeg'};
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 259px;
    height: 248px;
`

const CourseDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 603px;
    font-family: Inter;
    font-style: normal;
    margin-left: 30px;
    letter-spacing: 0px;
    text-align: left;
`

const CourseTypeText = styled.p`
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: #9E9E9E;
    margin-bottom: 8px;
`

const CourseClassText = styled.p`
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: #8C61CB;
    margin-left: 10px;
`

const CourseTitle = styled.h2`
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    color: #212121;
    margin-bottom: 15px;
`

const CourseDescription = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #616161;
    margin: 20px 0;
`

const CourseDurationContainer = styled.div`
    display: flex;
    margin-top: 10px;
`

const CourseChapterClass = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 70px;
`

const CourseChapterClassName = styled.div`
    display: flex;
    .svg {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #757575;
    }
    .name {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #757575;
        margin-left: 8px;
        margin-top: -1px;
    }
`

const CourseChapterClassStats = styled.p`
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #212121;
    text-align: center;
    margin-top: 5px;
`

const FetchError = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #616161;
`

CoursesStyle.FlexContainer = FlexContainer
CoursesStyle.PageTitleContainer = PageTitleContainer
CoursesStyle.PageTitle = PageTitle
CoursesStyle.CourseContainer = CourseContainer
CoursesStyle.CourseThumbnail = CourseThumbnail
CoursesStyle.CourseDataContainer = CourseDataContainer
CoursesStyle.CourseTypeText = CourseTypeText
CoursesStyle.CourseClassText = CourseClassText
CoursesStyle.CourseTitle = CourseTitle
CoursesStyle.CourseDescription = CourseDescription
CoursesStyle.CourseDurationContainer = CourseDurationContainer
CoursesStyle.CourseChapterClass = CourseChapterClass
CoursesStyle.CourseChapterClassName = CourseChapterClassName
CoursesStyle.CourseChapterClassStats = CourseChapterClassStats
CoursesStyle.FetchError = FetchError

export default CoursesStyle
