import React, { Component } from 'react'
import { Pagination } from 'antd'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import DownloadRatings from './components/DownloadMentorRatings'
import fetchParticularMentorRating from '../../actions/mentorRating/fetchParticularMentorRating'
import getDataFromLocalStorage from '../../utils/extract-from-localStorage'
import { UMS_ADMIN, UMS_VIEWER, ADMIN } from '../../constants/roles'
import MentorRatingAnalysisStyle from './MentorRatingAnalysis.style'
import SearchInput from '../MentorReport/components/SearchInput'
import RatingTable from './components/RatingTable'

class MentorRatingAnalysis extends Component {
  constructor(props) {
    super(props)
    this.state = {
      perPage: 20,
      skip: 0,
      searchKey: 'All',
      searchName: '',
      currentPage: 1,
      mentorName: '',
      kids: [],
      mentorId: '',
      kidId: '',
      totalCount: 0,
      filterDropdownOptions: [
        'Search By',
        'All',
        'Kid\'s name',
      ],
    }
  }

  componentDidMount() {
    const saveRole = getDataFromLocalStorage('login.role')
    if (saveRole &&
      (saveRole === ADMIN || saveRole === UMS_ADMIN || saveRole === UMS_VIEWER)) {
      this.setState({
        savedRole: saveRole,
        mentorId: this.props.match.params.code
      })
      const kidsData = []
      fetchParticularMentorRating(this.state.perPage, this.state.skip,
        this.props.match.params.code, this.state.kidId)
        .then(res => {
          this.setState({
            totalCount: res.mentorMenteeSessionsMeta.count,
            mentorName: get(res.mentorMenteeSessions, '[0].mentorSession.user.name', '')
          })

          res.mentorMenteeSessions.forEach((mentorMenteeSession) => {
            kidsData.push({
              name: get(mentorMenteeSession.menteeSession, 'user.name', '-'),
              id: get(mentorMenteeSession.menteeSession, 'user.id', '')
            })
          })

          const extractedKidsData = kidsData.reduce((ref, current) => {
            const result = ref.find(item => item.id === current.id)
            if (!result) {
              return ref.concat([current])
            }
            return ref
          }, [])

          this.setState({
            kids: extractedKidsData
          })
        })
    }
  }

  onPageChange = (page) => {
    this.setState({
      currentPage: page
    }, () => fetchParticularMentorRating(this.state.perPage, this.state.currentPage - 1,
      this.state.mentorId, this.state.kidId))
  }

  searchByFilter = () => {
    const {
      searchKey,
      searchName,
      savedRole } = this.state
    if (savedRole &&
      (savedRole === ADMIN || savedRole === UMS_ADMIN || savedRole === UMS_VIEWER)) {
      let filteredQuery = ''
      if (searchKey === 'All') {
        filteredQuery = ''
      }
      if (searchName !== '') {
        filteredQuery = searchName
      }
      this.setState({
        kidId: filteredQuery
      }, () => fetchParticularMentorRating(this.state.perPage, this.state.currentPage - 1,
        this.state.mentorId, this.state.kidId))
    }
  }

  fetchMentorFilteredData = () => {
    const { searchKey } = this.state
    if (searchKey === 'Kid\'s name') {
      this.searchByFilter()
    }
  }

  handleValueSelect = (value, type) => {
    if (type === 'Kid\'s name') {
      this.setState({
        searchName: value
      }, () => this.fetchMentorFilteredData(this.state.searchName))
    }
  }

  renderSearchInputs = () => {
    const {
      searchKey,
      kids,
    } = this.state
    if (searchKey === 'Kid\'s name') {
      return (
        <SearchInput
          datasArray={kids}
          handleValueSelect={(value) => this.handleValueSelect(value, searchKey)}
          searchByFilter={() => this.fetchMentorFilteredData(this.state.searchName)}
          placeholder='Search by Kid name'
          onChange={(value) => this.setState({
            searchName: value
          })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.fetchMentorFilteredData(this.state.searchName)
            }
          }}
        />
      )
    }
    return null
  }

  renderPagination = () => {
    const {
      savedRole,
      perPage,
      currentPage,
      totalCount,
    } = this.state
    if ((savedRole === UMS_ADMIN || savedRole === UMS_VIEWER || savedRole === ADMIN)) {
      return (
        <MentorRatingAnalysisStyle.PaginationHolder>
          {totalCount > perPage && (
            <Pagination
              total={totalCount}
              onChange={this.onPageChange}
              current={currentPage}
              pageSize={perPage}
            />
          )}
          <div style={{ marginLeft: 'auto' }} >
            <MentorRatingAnalysisStyle.Select
              value={perPage}
              onChange={(value) => this.setState({
                currentPage: 1,
                perPage: value
              }, () => fetchParticularMentorRating(this.state.perPage, this.state.currentPage - 1,
              this.state.mentorId, this.state.kidId))}
            >
              {
                [10, 20, 50, 100].map((option) =>
                  <MentorRatingAnalysisStyle.Option
                    key={option}
                    value={option}
                  >{option}
                  </MentorRatingAnalysisStyle.Option>
                )
              }
            </MentorRatingAnalysisStyle.Select>
          </div>
        </MentorRatingAnalysisStyle.PaginationHolder>
      )
    } return null
  }

  render() {
    const { savedRole, searchKey } = this.state

    return (<>
     <h3>
       <Link rel='noopener noreferrer' to='/ums/mentorRating'>
     Mentor Ratings
       </Link>
      {' '} {'>'}  {this.state.mentorName}
     </h3>
    <MentorRatingAnalysisStyle.TopContainer>
      {((savedRole === ADMIN || savedRole === UMS_ADMIN || savedRole === UMS_VIEWER
        )) && (
        <div
          style={{
            marginRight: '20px',
            minWidth: '438px',
            display: 'flex',
            alignItems: 'flex-start'
          }}
        >
          <MentorRatingAnalysisStyle.Select
            value={searchKey}
            onChange={(value) => this.setState({
              searchKey: value,
            }, () => {
              if (this.state.searchKey === 'All') {
                this.setState({
                  searchName: '',
                  currentPage: 1
                }, () => {
                  this.setState({
                    kidId: ''
                  })
                 return fetchParticularMentorRating(this.state.perPage, this.state.currentPage - 1,
                  this.state.mentorId, '')
                })
              }
            })}
          >
            {
              this.state.filterDropdownOptions.map((option) =>
                <MentorRatingAnalysisStyle.Option
                  key={option}
                  value={option}
                >{option}
                </MentorRatingAnalysisStyle.Option>
              )
            }
          </MentorRatingAnalysisStyle.Select>
          {this.renderSearchInputs()}
        </div>
      )}
        {this.renderPagination()}
    </MentorRatingAnalysisStyle.TopContainer>
    <DownloadRatings
      perPage={this.state.perPage}
      currentPage={this.state.currentPage - 1}
      query={this.state.mentorId}
      kidId={this.state.kidId}
    />
     <RatingTable
       {...this.props}
       filters={this.state}
       savedRole={savedRole}
       fetchStatus={this.props.ratingsFetchStatus.toJS().particularMentorRatings}
     />
            </>)
  }
}

export default MentorRatingAnalysis
