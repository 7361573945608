import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'
import removeFromEventAction from '../eventDashboard/removeFromEventAction'
import uploadFile from '../utils/uploadFile'

const updateEventWinner = async (eventConnectId, prizeConnectId, winnersDatas) => duck.query({
    query: gql`
  mutation {
    updateEventWinner(id: "${eventConnectId}", prizeConnectId: "${prizeConnectId}") {
      id
    }
  }
  `,
    type: 'updateEventWinner/update',
    key: 'updateEventWinner',
    changeExtractedData: (extractedData, originalData) => {
        extractedData.updateEventWinner = get(originalData, 'updateEventWinner');
        if (winnersDatas.uploadImageFileObject && typeof (winnersDatas.uploadImageFileObject) !== 'string') {
            (async function imageUpload() {
                try {
                      if (get(winnersDatas,'prevUploadImageID')) {
                          await removeFromEventAction(get(originalData, 'updateEventWinner.id'), get(winnersDatas,'prevUploadImageID'),'','eventWinner')
                      }
                    await uploadFile(winnersDatas.uploadImageFileObject, {
                        fileBucket: 'python'
                    }, {
                        typeField: 'image', typeId: get(originalData, 'updateEventWinner.id'), type: 'EventWinner',
                    })
                } catch (error) {
                    console.log(error)
                }
            }())
        }
        return { ...extractedData }
    }
})


export default updateEventWinner
