import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import withUpdatedDashboardNav from '../../../components/withUpdatedNav'
import { EVENT_DASHBOARD } from '../../../constants/navItemsConst'
import injectProps from '../../../components/injectProps'
import EventPreview from './EventPreview'
import { filterKey } from '../../../utils/data-utils'

const EventPreviewSideNav = withUpdatedDashboardNav(EventPreview)({
  title: 'Event Preview',
  activeNavItem: 'Events',
  showUMSNavigation: true,
  renderNavItems: EVENT_DASHBOARD,
  noPadding: true
})

const mapStateToProps = state => ({
  schoolData: filterKey(state.data.getIn(['schools', 'data']), 'schools'),
  eventData: state.data.getIn(['events', 'data']),
  hasEventDataFetched: state.data.getIn(['events', 'fetchStatus', 'events', 'success']),
  isEventDataFetching: state.data.getIn(['events', 'fetchStatus', 'events']),
  isFetchingSchoolData: state.data.getIn([
    'schools',
    'fetchStatus',
    'schools',
    'loading'
  ]),
  hasSchoolDataFetched: state.data.getIn([
    'schools',
    'fetchStatus',
    'schools',
    'success'
  ]),
  eventUpdateStatus: state.data.getIn(['events', 'updateStatus', 'events']),
})


const EventPreviewSideNavWithExtraProps = injectProps({
  notification
})(EventPreviewSideNav)
export default connect(mapStateToProps)(withRouter(EventPreviewSideNavWithExtraProps))
