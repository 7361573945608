import { Button, Modal, Select } from 'antd'
import { debounce, get } from 'lodash';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { fetchSchoolList } from '../../../../actions/SchoolOnboarding';
import fetchSchoolsLabInspections from '../../../../actions/SchoolOnboarding/fetchSchoolsLabInspections';
import getIdArrForQuery from '../../../../utils/getIdArrForQuery';
import { SectionButton } from '../../SchoolOnBoarding.style'
import LabInspectionReport from './LabInspectionReport';

const DownloadReportMainContainer = styled.div`
	display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`;

const DownloadReportMultipleContainer = styled.div`
	display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 40px;
`;

const DownloadReportDropdownContainer = styled.div`
	display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 400px;
`;

const allClass = { id: 'All', name: 'All' }

function DownLoadLabInspectionReport({ open, handleClose, labInspections, schoolsList = [] }) {
    const [isDownloadForThisSchool, setIsDownloadForThisSchool] = useState(true)
    const [selectedSchools, setSelectedSchools] = useState(['All'])
    const [newSchoolList, setNewSchoolList] = useState([])
    const [isFetchingSchoolLab, setIsFetchingSchoolLab] = useState(false)
    const [schoolLabInspections, setSchoolLabInspections] = useState([])
    const [downloadReport, setDownloadReport] = useState(false)
    const [isSearchingForSchool, setIsSearchingForSchool] = useState(false)

    useEffect(() => {
        setNewSchoolList([allClass, ...schoolsList])
    }, [])

    const handleDownloadMultipleSchoolReport = async() => {
        let schoolIds = []
        let schoolLabInspectionsData
        let filterString = ''
        setIsFetchingSchoolLab(true)
        if (selectedSchools.includes('All')) {
            filterString = `{ school_exists: true }`
            schoolLabInspectionsData = await fetchSchoolsLabInspections(filterString)
        } else {
            schoolIds = [...selectedSchools]
            filterString = `{
                school_some: { id_in: [${getIdArrForQuery(schoolIds)}] }
            }`
            schoolLabInspectionsData = await fetchSchoolsLabInspections(filterString)
        }
        setIsFetchingSchoolLab(false)
        const schoolLabInspectionsDataTemp = getFormatedData(schoolLabInspectionsData)
        setSchoolLabInspections(schoolLabInspectionsDataTemp)
        setDownloadReport(true)
    }

    const getFormatedData = (labInspections) => {
        const formatLabInspectionBySchoolName = {}
        labInspections && labInspections.length && labInspections.forEach(item => {
            const schoolName = get(item, 'school.name')
            if(!formatLabInspectionBySchoolName[schoolName]) {
                formatLabInspectionBySchoolName[schoolName] = [item]
            } else {
                formatLabInspectionBySchoolName[schoolName] = [...formatLabInspectionBySchoolName[schoolName], item]
            }
        })
        return formatLabInspectionBySchoolName
    }

    const getLabInspectionsBySchoolName = () => {
        return getFormatedData(labInspections)
    }

    const handleSchoolSearch = async(value) => {
        if (value && value.trim()) {
          setIsSearchingForSchool(true)
          const filterString = `{
            name_contains: "${value}"
          }`
          const { schools } = await fetchSchoolList(null, filterString)
          const newSchools = [...newSchoolList, ...schools]
          const uniqueSchoolsList = []
          newSchools.forEach(school => {
            const alreadyInList = uniqueSchoolsList.find(sData => get(sData, 'id') === get(school, 'id'))
            if (!alreadyInList) uniqueSchoolsList.push(school)
          })
          setNewSchoolList([...uniqueSchoolsList])
          setIsSearchingForSchool(false)
        }
    }
    const onSearch = (value) => {
        const schoolDetail = newSchoolList.find(school => get(school, 'name', '').toLowerCase().includes((value || '').toLowerCase()))
        if (!schoolDetail) {
            debounce(() => handleSchoolSearch(value), 800)()
        }
    }
    return (
        <>
        <Modal
            title="Download Lab Inspection Report"
            visible={open}
            onCancel={handleClose}
            centered
            footer={null}
        >
            <DownloadReportMainContainer>
                <SectionButton
                    type={isDownloadForThisSchool ? "primary" : "default"}
                    onClick={() => {
                        setIsDownloadForThisSchool(true);
                    }}
                >
                    This School
                </SectionButton>
                <SectionButton
                    type={!isDownloadForThisSchool ? "primary" : "default"}
                    onClick={() => {
                        setIsDownloadForThisSchool(false);
                    }}
                >
                    Multiple Schools
                </SectionButton>
            </DownloadReportMainContainer>
            <DownloadReportMainContainer>
                {isDownloadForThisSchool ? (
                    <LabInspectionReport
                        labInspectionsOfSchools={getLabInspectionsBySchoolName()}
                    />
                ) : (
                    <DownloadReportDropdownContainer>
                        <Select
                            showSearch
                            mode="multiple"
                            allowClear={!isSearchingForSchool}
                            style={{
                                width: '100%',
                                minWidth: '300px'
                            }}
                            value={selectedSchools}
                            filterOption={(input, option) =>
                                get(option, 'props.children')
                                ? get(option, 'props.children')
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                : false
                            }
                            onSearch={onSearch}
                            placeholder="Please select schools"
                            loading={isSearchingForSchool}
                            onChange={(e) => {
                                if (downloadReport) {
                                    setDownloadReport(false)
                                }
                                if (e.length >= 2 && e.includes('All') && selectedSchools.includes('All')) {
                                    const newSelectedSchools = e.slice(1)
                                    setSelectedSchools(newSelectedSchools)
                                } else if (e.includes('All') && !selectedSchools.includes('All')) {
                                    setSelectedSchools(['All'])
                                } else {
                                    setSelectedSchools(e)
                                }
                            }}
                        >
                            {newSchoolList && newSchoolList.length && newSchoolList.map(school => (
                                <Select.Option key={get(school, 'id')} value={get(school, 'id')}>{get(school, 'name')}</Select.Option>
                            ))}
                        </Select>
                        {downloadReport ? (
                            <p>Report Data Fetched Successfully, Please Download Report</p>
                        ) : null}
                        <p></p>
                        {downloadReport ? (
                            <LabInspectionReport
                                labInspectionsOfSchools={schoolLabInspections}
                            />
                        ) : (
                            <Button
                                type="primary"
                                onClick={handleDownloadMultipleSchoolReport}
                                disabled={!selectedSchools.length}
                                loading={isFetchingSchoolLab}
                            >
                                Fetch Report Data
                            </Button>
                        )}
                    </DownloadReportDropdownContainer>
                )}
            </DownloadReportMainContainer>
        </Modal>
        </>
    )
}

export default DownLoadLabInspectionReport