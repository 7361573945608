import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const fetchBatchStudents = async (id, studentSearch, grades = [], isTrainingBatch = false) =>
  duck.query({
    query: gql`
    {
        studentProfiles(
          ${
  studentSearch && (grades.length || isTrainingBatch) ? `
            filter: {
                  and: [
                    ${!isTrainingBatch ? `{ school_some: { id: "${id}" } }` : ''}
                    ${grades.length ? `{ grade_in: [${grades.map(g => g)}] }` : ''}
                    ${isTrainingBatch ? `{ mentor_some: { schools_some: { id: "${id}" } } }` : '{ batch_exists: false }'}
                  ]
                }
            ` : `
            filter: { or: [
              {
                batch_some: { id: "${id}" }
              }
              {
                batches_some: { id: "${id}" }
              }
            ] }`
}
          ) {
            id
            user {
            id
            name
            status
            }
            batch {
              id
            }
            batchesData: batches{
              id
            }
            school {
              id
            }
            ${isTrainingBatch ? `mentor{
              user{
                id
                email
                name
                phone{
                  number
                  countryCode
                }
              }
              schools{
                id
              }
            }` : ''}
            grade
            section
            ${isTrainingBatch ? `` : `parents {
            id
            user {
                id
                name
                phone {
                    countryCode
                    number
                }
                email
            }
            }`}
        }
    }
    `,
    type: 'studentProfiles/fetch',
    key: studentSearch || 'studentProfiles',
    changeExtractedData: (extractedData, originalData) => {
      const studentProfiles = []
      const getParentData = (data) => get(data, 'parents', []).length > 0 && get(data, 'parents[0].user') ? get(data, 'parents[0].user') : {}
      get(originalData, 'studentProfiles', []).forEach((student, i) => {
        let phoneNo = `${get(getParentData(student), 'phone.countryCode')}`&&`${get(getParentData(student), 'phone.number')}`
        if (!studentSearch) {
          const studentExistInBatch = get(student, 'batchesData', []).find(bData => get(bData, 'id') === id);
          if (get(student, 'batch.id') === id || studentExistInBatch) {
            if (isTrainingBatch) {
              const phoneNo = get(student, 'mentor.user.phone.number');
              const countryCode = get(student, 'mentor.user.phone.countryCode');
              const number = get(student, 'mentor.user.phone.number');
              let phone = phoneNo === 'undefined' ? `-` : `${countryCode}${number}`
              const email = get(student, 'mentor.user.email')
              studentProfiles.push({
                index: i + 1,
                studentName: get(student, 'user.name'),
                parentName: get(getParentData(student), 'name'),
                phone,
                email,
                ...student
              })
            } else {
              studentProfiles.push({
                index: i + 1,
                studentName: get(student, 'user.name'),
                parentName: get(getParentData(student), 'name'),
                phone: phoneNo === 'undefined' ? `-` : `${get(getParentData(student), 'phone.countryCode')}${get(getParentData(student), 'phone.number')}`,
                email: get(getParentData(student), 'email'),
                ...student
              })
            }
          }
          /* eslint-disable no-lonely-if */
        } else {
          if (get(student, 'school.id') === id || (isTrainingBatch && get(student, 'mentor.schools[0].id') === id)) {
            studentProfiles.push({
              index: i + 1,
              studentName: get(student, 'user.name'),
              parentName: get(getParentData(student), 'name'),
              phone:  phoneNo === 'undefined' ? `-` : `${get(getParentData(student), 'phone.countryCode')}${get(getParentData(student), 'phone.number')}`,
              email: get(getParentData(student), 'email'),
              ...student
            })
          }
        }
      })
      extractedData.batch = []
      extractedData.schools = []
      extractedData.user = []
      extractedData.studentProfiles = studentProfiles
      return { ...extractedData }
    }
  })

export default fetchBatchStudents

