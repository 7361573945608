import React, { Component, createRef } from 'react'
import { Button, notification, Spin } from 'antd'
import UserProfilePageStyle from '../UserProfilePage.style'
import Dropzone from '../../../components/Dropzone/Dropzone'
import adduserBankDetail from '../../../actions/userProfile/adduserBankDetails';
import { get } from 'lodash';
import updateUserBankDetails from '../../../actions/userProfile/updateuserBankDetails';
import getFullPath from '../../../utils/getFullPath';
import { CheckOutlined} from '@ant-design/icons';

export default class MentorBankDetails extends Component {
    constructor(props) {
        super(props);
        this.dropzoneRef = createRef(null);
        this.state = {
            id: '',
            webError: '',
            webImage: '',
            name: '',
            accountNo: '',
            ifscCode: '',
            branch: '',
            passBookCopyOrCancelledChequeImage: null,
            showSaveButton: [false, false, false, false, false],
            constId: '',
            constName: '',
            constAccountNo: '',
            constIfscCode: '',
            constBranch: '',
            constPassBookCopyOrCancelledChequeImage: null,
            SubmitButtonTextChange: 0,
            clickOnSave: 0,
            isDocumentFileEmpty: false,
            isAddBankDetails: 0,
            isAddUserBankDetails: false,
            isUpdateUserBankDetails: false,
            isSubmiting: false
        };
    }
    componentDidMount = async () => {
        const { userProfile } = this.props;
        const bankDetails = get(userProfile, '[0].userBankDetail') || null;
        this.setState({
            id: get(bankDetails, 'id') || '',
            name: get(bankDetails, 'bankName') || '',
            accountNo: get(bankDetails, 'accountNumber') || '',
            ifscCode: get(bankDetails, 'ifscCode') || '',
            branch: get(bankDetails, 'branchName') || '',
            passBookCopyOrCancelledChequeImage: getFullPath(get(bankDetails, 'documentFile.uri')) || null,
            constId: get(bankDetails, 'id') || '',
            constName: get(bankDetails, 'bankName') || '',
            constAccountNo: get(bankDetails, 'accountNumber') || '',
            constIfscCode: get(bankDetails, 'ifscCode') || '',
            constBranch: get(bankDetails, 'branchName') || '',
            constPassBookCopyOrCancelledChequeImage: getFullPath(get(bankDetails, 'documentFile.uri')) || null,
            isDocumentFileEmpty: (getFullPath(get(bankDetails, 'documentFile.uri') || null) !== null) ? true : false,
            isAddBankDetails: get(bankDetails, 'id') ? 1 : 0,
        })
    }
    componentDidUpdate = async (prevProps) => {
        const { updateUserBankDetailStatus, addUserBankDetailStatus, addUserBankDetailData, updateUserBankDetailData } = this.props;
        const { name, accountNo, ifscCode, branch, id, passBookCopyOrCancelledChequeImage } = this.state;
        if (updateUserBankDetailStatus && !get(updateUserBankDetailStatus, 'loading') && get(updateUserBankDetailStatus, 'success')
            && prevProps.updateUserBankDetailData !== updateUserBankDetailData) {
            this.setState({
                showSaveButton: [false, false, false, false, false],
                constId: id,
                constName: name,
                constAccountNo: accountNo,
                constIfscCode: ifscCode,
                constBranch: branch,
                constPassBookCopyOrCancelledChequeImage: passBookCopyOrCancelledChequeImage,
                SubmitButtonTextChange: 0,
            })
            setTimeout(() => {
                this.setState({
                    isUpdateUserBankDetails: false,
                })
            }, 3000);
        }
        if (addUserBankDetailStatus && !get(addUserBankDetailStatus, 'loading') && get(addUserBankDetailStatus, 'success')
            && prevProps.addUserBankDetailData !== addUserBankDetailData) {
            this.setState({
                showSaveButton: [false, false, false, false, false],
                constId: id,
                constName: name,
                constAccountNo: accountNo,
                constIfscCode: ifscCode,
                constBranch: branch,
                constPassBookCopyOrCancelledChequeImage: passBookCopyOrCancelledChequeImage,
                SubmitButtonTextChange: 0,
                isAddBankDetails: 1,
                webImage: '',
                isAddUserBankDetails: false,
            })
        }

        if (addUserBankDetailStatus && !get(addUserBankDetailStatus, 'loading') && get(addUserBankDetailStatus, 'failure')
            && prevProps.addUserBankDetailStatus !== addUserBankDetailStatus) {
            this.setState({
                SubmitButtonTextChange: 1,
            })
        }
        if (updateUserBankDetailStatus && !get(updateUserBankDetailStatus, 'loading') && get(updateUserBankDetailStatus, 'failure')
            && prevProps.updateUserBankDetailStatus !== updateUserBankDetailStatus) {
            this.setState({
                SubmitButtonTextChange: 1,
            })
        }
    }
    changeName = (name) => {
        const { showSaveButton }=this.state;

        let nameString = '';
        for (var i = 0; i < name.length; i++) {
            if (isNaN(name[i]) || name[i] === ' ') {
                nameString = nameString + name[i];
            }
        }

        if (nameString !== this.state.constName) {
            this.setState({
                showSaveButton: [true,showSaveButton[1], showSaveButton[2], showSaveButton[3], showSaveButton[4]],
            })
        }
        else {
            this.setState({
                showSaveButton: [false,showSaveButton[1], showSaveButton[2], showSaveButton[3], showSaveButton[4]],
            })
        }
        this.setState({
            name: nameString,
        })
    }
    accountNoChange = (accountNo) => {
        const { showSaveButton }=this.state;
        let accountNoString ='';
        for (var i = 0; i < accountNo.length; i++) {
            if (accountNo[i] !== ' ' && !isNaN(accountNo[i])) {
                accountNoString = accountNoString + accountNo[i];
            }
        }
        
        if (accountNoString !== this.state.constAccountNo) {
            this.setState({
                showSaveButton: [showSaveButton[0],true, showSaveButton[2], showSaveButton[3], showSaveButton[4]],

            })
        }
        else {
            this.setState({
                showSaveButton: [showSaveButton[0],false, showSaveButton[2], showSaveButton[3], showSaveButton[4]],
            })
        }

        this.setState({
            accountNo: accountNoString,
        })
    }
    ifscCodeChange = (ifscCode) => {
        const { showSaveButton }=this.state;
        let ifscCodeString = '';
        for (var i = 0; i < ifscCode.length; i++) {
            if (ifscCode[i] !== ' ') {
                ifscCodeString = ifscCodeString + ifscCode[i];
            }
        }

        if (ifscCodeString !== this.state.constIfscCode) {
            this.setState({
                showSaveButton: [showSaveButton[0], showSaveButton[1],true, showSaveButton[3],showSaveButton[4]],

            })
        }
        else {
            this.setState({
                showSaveButton: [showSaveButton[0], showSaveButton[1],false, showSaveButton[3], showSaveButton[4]],
            })
        }
        this.setState({
            ifscCode: ifscCodeString,
        })
    }
    branchChange = (branch) => {
        const { showSaveButton }=this.state;
        let branchString = '';
        for (var i = 0; i < branch.length; i++) {
            if (isNaN(branch[i]) || branch[i] === ' ') {
                branchString = branchString + branch[i];
            }
        }

        if (branchString !== this.state.constBranch) {
            this.setState({
                showSaveButton: [showSaveButton[0], showSaveButton[1], showSaveButton[2], true, showSaveButton[4]],

            })
        }
        else {
            this.setState({
                showSaveButton: [showSaveButton[0], showSaveButton[1], showSaveButton[2], false, showSaveButton[4]],
            })
        }
        this.setState({
            branch: branchString,
        })
    }

    onDropWeb = (file) => {
        const { showSaveButton }=this.state;
        if (file) {
            const File = file;
            const img = new Image()
            img.src = window.URL.createObjectURL(File)
            if (img.src !== this.state.constPassBookCopyOrCancelledChequeImage) {
                this.setState({
                    showSaveButton: [showSaveButton[0], showSaveButton[1], showSaveButton[2], showSaveButton[3], true],
                })
            }
            else {
                this.setState({
                    showSaveButton: [showSaveButton[0], showSaveButton[1], showSaveButton[2], showSaveButton[3], false],
                })
            }
            this.setState({
                passBookCopyOrCancelledChequeImage: img.src,
            })
            img.onload = () => {
                this.setState({
                    webError: '',
                    webImage: File,
                    isDocumentFileEmpty: true,
                })

            }
        }

    }
    submitBankDetails = async () => {
        const { userProfile, addUserBankDetailData } = this.props;
        const bankDetails = get(userProfile, '[0].userBankDetail') || null;
        const { name, accountNo, ifscCode, branch, passBookCopyOrCancelledChequeImage, webImage, isAddBankDetails } = this.state;

        if (passBookCopyOrCancelledChequeImage !== null && passBookCopyOrCancelledChequeImage !== '') {
            if (name.trim() !== null && accountNo !== null && ifscCode !== null && branch.trim() !== null && name.trim() !== '' && accountNo !== '' && ifscCode !== '' && branch.trim() !== '') {
                
                if (/^[a-zA-Z ]{2,30}$/.test(name.trim())) {
                    if (/^[0-9]{9,18}$/.test(accountNo)) {
                        if (/^[A-Za-z]{4}[a-zA-Z0-9]{7}$/.test(ifscCode)) {
                            if (/^[a-zA-Z ]{2,30}$/.test(branch.trim())) {
                                this.setState({
                                    name: name.trim(),
                                    branch: branch.trim(),
                                })
                        
                                const bankDetailsObj = {
                                    bankName: name.trim(),
                                    accountNumber: accountNo,
                                    ifscCode: ifscCode,
                                    branchName: branch.trim(),
                                    documentFile: {
                                        uri: passBookCopyOrCancelledChequeImage
                                    }
                                }
                                if (isAddBankDetails === 0) {
                                    this.setState({
                                        isSubmiting: true
                                    })
                                    await adduserBankDetail(bankDetailsObj, webImage, get(userProfile, '[0].id'))
                                    this.setState({
                                        clickOnSave: 1,
                                        isAddUserBankDetails: true,
                                        webImage: null,
                                        isSubmiting: false
                                    })
                                }
                                else {
                                    this.setState({
                                        isSubmiting: true
                                    })
                                    await updateUserBankDetails(bankDetailsObj, get(addUserBankDetailData, '[0].id') === undefined ? get(bankDetails, 'id') : get(addUserBankDetailData, '[0].id'), webImage, get(userProfile, '[0].userBankDetail.documentFile.id') || null);
                                    this.setState({
                                        clickOnSave: 1,
                                        isUpdateUserBankDetails: true,
                                        webImage: null,
                                        isSubmiting: false
                                    })
                                }
                            }
                            else {
                                notification.info({
                                    message: `Error`,
                                    description:
                                        'Invalid Branch Name...!!!!',
                                });
                            }
                        }
                        else {
                            notification.info({
                                message: `Error`,
                                description:
                                    'Invalid IFSC Code....!!!!',
                            });
                        }
                    }
                    else {
                        notification.info({
                            message: `Error`,
                            description:
                                'Account number length should vary from 9 digits to 18 digits...!!!!',
                        });
                    }
                }
                else {
                    notification.info({
                        message: `Error`,
                        description:
                            'Invalid Mentor Name...!!!!',
                    });
                }
            } else {
                notification.info({
                    message: `Error`,
                    description:
                        'Input fields cannot be empty...!!!!',
                });
            }
        }
        else {
            notification.info({
                message: `Error`,
                description:
                    'Document File cannot be empty...!!!!',
            });
        }

    }
    render() {
        const { Title, Card, StyledFieldName, StyledInput, StyledBankDetailsBody } = UserProfilePageStyle;
        const { name, accountNo, ifscCode, branch, passBookCopyOrCancelledChequeImage, webImage, showSaveButton, SubmitButtonTextChange, clickOnSave } = this.state;
        const { editBankDetailsOnDate } = this.props;
        return (
            <>
                <Title style={{ marginTop: "10px" }} >Bank Details</Title>
                <Card
                    style={{
                        maxWidth: 600,
                        minHeight: 250
                    }}
                >
                    <div>
                        <div><b>Upload Document :-</b></div>
                        <div style={{ margin: "0px" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{
                                    marginRight: "30px",
                                    display: "flex",
                                    width: '100%',
                                    justifyContent: "space-between",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginBottom: "13px",
                                        width: "161px",
                                        alignItems: "center",
                                    }}>
                                        PassBook Photo/ Cancelled Cheque

                                    </div>
                                    <Dropzone
                                        style={{ width: "57%", margin: '0 10px', width: '210px' }}
                                        getDropzoneFile={(e) => this.onDropWeb(e)}
                                        ref={this.dropzoneRef}
                                        defaultImage={passBookCopyOrCancelledChequeImage}
                                        defaultFile={webImage}
                                        onImageUrl={(imgUrlOne) => {
                                            this.setState({ passBookCopyOrCancelledChequeImage: imgUrlOne })
                                        }}
                                        UploadImageLink={passBookCopyOrCancelledChequeImage}
                                        imageFile={webImage}
                                        disabled={!editBankDetailsOnDate}
                                    ></Dropzone>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div><b>{`Bank Details :-`}</b>{(editBankDetailsOnDate === false) ? <p style={{
                                fontSize: "10px",
                                color: "red",
                                marginTop: '3px'
                            }}>{`*Option not be available from 25th to 10th to avoid issue in payout`}</p> : ``}</div>
                            <div>
                                <StyledBankDetailsBody>
                                    <StyledFieldName>Name</StyledFieldName>
                                    <StyledInput style={{ width: "69%" }} placeholder='Enter Name' value={name} onChange={(e, prevProps) => { { this.changeName(e.target.value) } }} disabled={!editBankDetailsOnDate} />
                                </StyledBankDetailsBody>
                                <StyledBankDetailsBody>
                                    <StyledFieldName>Account No.</StyledFieldName>
                                    <StyledInput style={{ width: "69%" }} placeholder='Enter Account No.' value={accountNo} onChange={(e) => { { this.accountNoChange(e.target.value) } }} disabled={!editBankDetailsOnDate} />
                                </StyledBankDetailsBody>
                                <StyledBankDetailsBody>
                                    <StyledFieldName>IFSC Code</StyledFieldName>
                                    <StyledInput style={{ width: "69%" }} placeholder='Enter IFSC Code' value={ifscCode} onChange={(e) => { { this.ifscCodeChange(e.target.value) } }} disabled={!editBankDetailsOnDate} />
                                </StyledBankDetailsBody>
                                <StyledBankDetailsBody>
                                    <StyledFieldName>Branch</StyledFieldName>
                                    <StyledInput style={{ width: "69%" }} placeholder='Enter Branch' value={branch} onChange={(e) => { { this.branchChange(e.target.value) } }} disabled={!editBankDetailsOnDate} />
                                </StyledBankDetailsBody>
                                {((showSaveButton[0] === true || showSaveButton[1] === true || showSaveButton[2] === true || showSaveButton[3] === true || showSaveButton[4] === true) || this.state.isSubmiting) ?
                                    <Button
                                        style={{
                                            marginLeft: "84%",
                                        }}
                                        type='primary'
                                        onClick={() => this.submitBankDetails()}
                                        loading={this.state.isSubmiting}
                                        disabled={!editBankDetailsOnDate}
                                    >
                                        {SubmitButtonTextChange === 1 && clickOnSave === 1 ?
                                            <span>
                                                Try Again
                                            </span> :
                                            <span>
                                                <CheckOutlined /> Save
                                            </span>
                                        }
                                    </Button> : ''
                                }
                            </div>
                        </div>
                    </div>
                </Card>
            </>
        )
    }
}