import { Tooltip } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Table } from '../../../components/StyledComponents'
import MainTable from '../../../components/MainTable'
import ActionPanel from '../../../components/ActionsPanel'
import formatDate from '../../../utils/formatDate'
import { PUBLISHED_STATUS } from '../../../constants/questionBank'
/** @returns a row of course */
const CourseRow = (props) => {
  const [isHovering, setIsHovering] = useState(false)
  const {
    course: { id, order, title, description, chaptersMeta, status, topicsMeta }, serial
  } = props
  const createdAt = formatDate(props.course.createdAt)
  const updatedAt = formatDate(props.course.updatedAt)
  const onMouseEnter = () => {
    setIsHovering(true)
  }
  let rowColor = 'white'
  if (serial % 2 === 0) {
    rowColor = '#b6dec1'
  }
  const onMouseLeave = () => {
    setIsHovering(false)
  }
  return (
    <Table.Row
      isHovering={isHovering}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...props}
    >
      <Table.Item style={{ backgroundColor: `${rowColor}` }}><MainTable.Item>{order}</MainTable.Item></Table.Item>
      <Table.Item style={{ backgroundColor: `${rowColor}` }}>
        <MainTable.Item>
          <Tooltip title={title} style={{ overflow: 'hidden' }}>
            <Link to={`/course-sessions/${id}`} style={{ overflow: 'hidden' }}><span style={{ display: 'block', width: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{title}</span></Link>
          </Tooltip>
        </MainTable.Item>
      </Table.Item>
      <Table.Item style={{ backgroundColor: `${rowColor}` }}>
        <MainTable.Item>
          <Tooltip title={description} style={{ overflow: 'hidden' }}>
            <span style={{ width: '400px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{description}</span>
          </Tooltip>
        </MainTable.Item>
      </Table.Item>
      <Table.Item style={{ backgroundColor: `${rowColor}` }}><MainTable.Item><Link to={`/chapters/${id}`}>{chaptersMeta.count}</Link></MainTable.Item></Table.Item>
      <Table.Item style={{ backgroundColor: `${rowColor}` }}><MainTable.Item><Link to={`/topics/${id}`} params={{ passId: 'hello' }}>{topicsMeta.count}</Link></MainTable.Item></Table.Item>
      <Table.Item style={{ backgroundColor: `${rowColor}` }}>
        <MainTable.DateItem>
          <div>{createdAt.date}</div>
          <div>{createdAt.time}</div>
        </MainTable.DateItem>
      </Table.Item>
      <Table.Item style={{ backgroundColor: `${rowColor}` }}>
        <MainTable.DateItem>
          <div>{updatedAt.date}</div>
          <div>{updatedAt.time}</div>
        </MainTable.DateItem>
      </Table.Item>
      <Table.Item style={{ backgroundColor: `${rowColor}` }}>
        <MainTable.Status status={status} />
      </Table.Item>
      <Table.Item style={{ backgroundColor: `${rowColor}` }}>
        <ActionPanel
          id={id}
          title='Course'
          deleteItem={props.deleteCourse}
          openEdit={props.openEditModal}
          publish={props.publishCourse}
          unpublish={props.unpublishCourse}
          isPublished={status === PUBLISHED_STATUS}
        />
      </Table.Item>
    </Table.Row>
  )
}
CourseRow.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.string,
    order: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    status: PropTypes.string,
    chaptersMeta: PropTypes.shape({
      count: PropTypes.number
    })
  }).isRequired,
  // function to open edit modal
  openEditModal: PropTypes.func.isRequired,
  deleteCourse: PropTypes.func.isRequired,
  publishCourse: PropTypes.func.isRequired,
  unpublishCourse: PropTypes.func.isRequired
}
export default CourseRow
