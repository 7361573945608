import getSlotLabel from '../../utils/slots/slot-label'

const getSlot = (item) => {
  for (let i = 0; i <= 23; i += 1) {
    if (item[`slot${i}`] !== null && item[`slot${i}`] === true) {
      const label = getSlotLabel(i)
      return label.startTime
    }
  }
  return '-'
}

export default getSlot
