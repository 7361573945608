import styled from 'styled-components'
import {Input,Select,Button} from 'antd'

const Main = styled.div`
margin: 19px 0px 0px 16px;
`
const SlideOptionDiv = styled.div`
`
// const StyledSelect = styled.select`
// width: 144px;
// height: 38px;
// border-radius: 8px;
// background: #FFFFFF;
// padding: 9px 8px 9px 12px;
// border: 1px solid #AAAAAA;
// margin: 0px 7px;
// `
const StyledSelect = styled(Select)`
width: 144px !important;
min-height: 38px !important; 
margin: 0px 19px 0px 0px!important;
.ant-select-selection{
    border-radius:8px !important;
    border: 1px solid #AAAAAA !important;
}
.ant-select-selection--single{
    height:40px !important;
}
.ant-select-selection:hover {
    border-color:none !important;
}
.ant-select-selection-selected-value {
    margin-top: 4%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}
`
const PreviewButton = styled(Button)`
&.ant-btn {
    background-color: #8C61CB !important;
    color: #FFFFFF !important;
    font-family: 'Inter'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    border-radius: 8px !important;
    padding: 7px 16px 7px 12px !important;
    margin: 24px 0px !important;
}
`
const SaveNFinishButton = styled(Button)`
&.ant-btn {
    background: #F3EFFA !important;
    color: #8C61CB !important;
    font-family: 'Inter'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    border-radius: 8px !important;
    padding: 7px 16px 7px 12px !important;
    margin: 24px 0px !important;
}
`
const SaveButton = styled(Button)`
&.ant-btn {
    background: #8C61CB !important;
    color: #FFFFFF !important;
    font-family: 'Inter'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    border-radius: 8px !important;
    padding: 7px 16px 7px 12px !important;
    margin: 24px 26px 0px 45px !important;
}
`
const StyledInput = styled(Input)`
width:137px !important;
min-height: 38px !important;
background: #FFFFFF !important;
border: 1px solid #AAAAAA !important;
border-radius: 8px !important;
font-family: 'Inter' !important;
font-style: normal !important;
font-weight: 400 !important;
font-size: 14px !important;
padding: 10px 12px 8px !important;
margin: 0px 19px 0px 0px!important;
`
const Slide = styled.div`
background: #FFFFFF;
    border: ${ props => props.checked ? `1px solid #000000` : `1px solid #AAAAAA`};
    padding: 10px 12px 8px;
    width: 212px;
    height: 38px;
    border-radius: 8px;
    margin: 0px 15px 8px 0px;
    cursor:pointer;
`
const HeadingTag=styled.div`
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #282828;
}
`
const SlideHolder = styled.div`
display:flex
flex-direction:column;
margin-top:1.5%;
`
const InnerSlideHeading=styled.div`
    display: flex;
    align-items: center;
    text-transform: capitalize;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #333333;
`
const ListContainer = styled.div`
display:flex;
flex-direction:row;
flex-wrap:wrap;
margin-top:1%;
margin-bottom: 2%;
`
const SliderInnerText = styled.p`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
color:${ props => props.checked ? `#000000` : `#333333`};
opacity:${ props => props.checked ? `` : `0.45`};
overflow: hidden;
text-overflow: ellipsis;
cursor:pointer;
white-space: nowrap;
`
const CrossHolder = styled.div`
position: absolute;
top: -6px;
left: 201px;
cursor:pointer;
`
const AddButtonHolder = styled.div`
display: flex;
flex-direction:row;
justify-content: center;
align-items: center;
cursor:pointer;
`
const PlusHolder = styled.div`
`
const PlusButtonText = styled.div`
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
text-decoration-line: underline;
color: #8C61CB;
margin:0px 0px 6px 8px;
`
const InfoHolder = styled.div`
`
const FilterHolder = styled.div`
display: flex;
flex-direction:row;
margin-top: 1.3%;
margin-bottom: 2%;
`
const SlideContentHolder = styled.div`
display: flex;
flex-direction:row;
margin-top: 1.3%;
`
const NameDiv = styled.div`
display: flex;
flex-direction:row;
`
const FilterText = styled.p`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
color: #333333;
margin: 8px 7px 9px 0px;
`
const ExistsText = styled.div`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 10px;
color: red;
margin: 2px 0px 0px 6px;
`

const SvgHolder = styled.div`
margin:0px 16px 0px 0px;
position:relative;
opacity:${ props => props.ticked ? `1` : `0.7`};
cursor:'pointer';
`
const SvgDiv = styled.div`
display: flex;
flex-direction:row;
cursor:pointer;
`
const CheckMarkHolder = styled.div`
position:absolute;
top: -9px;
right: -7px;
`
const SelectHolder = styled.div`
display: flex;
flex-direction:column;
`
const InputHolder = styled.div`
display: flex;
flex-direction:column;
`
const HeadingHolder = styled.div`
display: flex;
flex-direction:row;
`
const SlideDivContainer = styled.div`
position:relative;
`
const ButtonHolders=styled.div`
display: flex;
justify-content: space-between;
margin-top:3%;
`
const SaveButtons=styled.div`
display: flex;
flex-direction:row;
`
const SpinEncloser=styled.div`
height:100%;
width:100%;
display:flex;
justify-content:center;
align-item:center
`
const SvgInfoEncloser=styled.div`
display: flex;
flex-direction:column;
`
const MessageText = styled.div`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 10px;
color:#8C61CB;
`
export {
Main,
HeadingTag,
SlideHolder,
InnerSlideHeading,
ListContainer,
Slide,
SliderInnerText,
CrossHolder,
AddButtonHolder,
PlusHolder,
PlusButtonText,
InfoHolder,
FilterHolder,
StyledInput,
NameDiv,
FilterText,
StyledSelect,
SvgHolder,
SvgDiv,
CheckMarkHolder,
SlideContentHolder,
SlideOptionDiv,
PreviewButton,
SaveNFinishButton,
SaveButton,
SelectHolder,
InputHolder,
HeadingHolder,
ExistsText,
SlideDivContainer,
ButtonHolders,
SaveButtons,
SpinEncloser,
SvgInfoEncloser,
MessageText
}
