import React, { Component } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';

export default class WrapupEventTitle extends Component {
  render() {
    return (
        <>
            <div>
                <div style={{display: "flex",
                            alignItems: "center"}}>
                    <div style={{ width: "26px" }}>
                        <CheckCircleFilled style={{
                            color: "black",
                            fontSize: "26px",
                            height: "19px",
                            display: "flex",
                            alignItems: "center",
                        }} />
                    </div>
                    <div style={{ fontSize: "24px",marginLeft:"16px"}}>
                        <b>Wrapup the Event</b>
                    </div>
                </div>
                <div className="" style={{
                    fontSize: "12px",
                    marginLeft: "43px",
                    color: "#0707078a",
                }}>
                    <b>Review the event listing</b>
                </div>
            </div>
        </>
    )
  }
}
