import { print } from 'graphql/language/printer'
import extractFiles from './extract-files'

export default class GqlClient {
  constructor({ url, batchURL,scriptURL, errorHandler }) {
    this.url = url
    this.batchURL = batchURL || url
    this.scriptUrl = scriptURL || url;
    this.errorHandler = errorHandler
  }

  async query(query, variables, options) {
    const { headers, callBatchAPI,callScriptApi, ...others } = options
    // Extracts all files from variables and replaces them
    // with null
    const files = extractFiles(variables)
    let fetchOptions
    // Creates a stringfied query
    const graphqlQuery = JSON.stringify({
      query: print(query), // "print" changes graphql AST into normal string
      variables,
    })
    // Checks if there are any files in the query
    // if there is then ...
    if (files.length) {
      // ...then creates a form object
      const body = new FormData()
      // appends query into body
      body.append('operations', graphqlQuery)
      // apppend files into body
      files.forEach(({ path, file }) => {
        if (path === 'file') {
          // If file type is empty in file object,
          // then we are extracting the type and creating a new File with type
          const type = file && file.type
          if (!type && file.name) {
            const fileType = file.name.split('.')[1]
            if (fileType && ['accdb', 'mdb', 'accdt'].includes(fileType)) {
              const newFile = new File([file], file.name, { type: `application/${fileType}` })
              file = newFile
            }
          }
        }
        body.append(path, file)
      })
      // sets fetchOptions
      fetchOptions = {
        method: 'POST',
        body,
        ...options
      }
    } else {
      // sets fetchOption without any body append
      // because there are no files here and we
      // directly assign body to graphqlQuery
      fetchOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...headers },
        body: graphqlQuery,
        ...others
      }
    }
    try {
      const url = callBatchAPI ? this.batchURL : this.url
      const urlToUse = (process.env.REACT_APP_NODE_ENV === 'production' && callScriptApi) ? this.scriptUrl : url
      // fetches the data from the url
      // fetches the Data
      const response = await fetch(urlToUse, fetchOptions)
      const result = await response.json()
      // Checks if there are any error in result
      if (result.errors) {
        // throw the result
        throw result
      }
      // otherwise just normally return them
      return result
    } catch (e) {
      // For other normal errors
      // just throw them
      throw Object({
        status: this.errorHandler(e),
        ...e,
      })
    }
  }
}
