import gql from 'graphql-tag'
import duck from '../../duck'
import getDataFromLocalStorage from '../../utils/extract-from-localStorage'
import { ADMIN, UMS_ADMIN, UMS_VIEWER, MENTOR } from '../../constants/roles'

const fetchBatchSessions = async (batchId) => {
  const role = getDataFromLocalStorage('login.role')
  const mentorId = getDataFromLocalStorage('login.id')
  let filterStatement
  if (role === ADMIN || role === UMS_ADMIN || role === UMS_VIEWER) {
    filterStatement = `{ batch_some: { id: "${batchId}" } }`
  } else if (role === MENTOR) {
    filterStatement = `
    { and: [
        { batch_some: { id: "${batchId}" } },
        { mentorSession_some: { user_some: { id: "${mentorId}" } } }
      ]
    }
  `
  }
  return duck.query({
    query: gql`
    {
        batchSessions(
          filter: ${filterStatement}
        ) {
          id
    createdAt
    batch {
      id
      code
      type
      course {
        id
        title
        thumbnail {
          uri
        }
        chapters(filter: { status: published }) {
          id
          order
          title
          description
        }
        secondaryCategory
        topics(filter: { status: published }) {
          id
          order
          title
          thumbnailSmall {
            uri
          }
        }
      }
      studentsMeta {
        count
      }
      students {
        id
        grade
        user {
          name
          createdAt
        }
        parents {
          user {
            name
            phone {
              number
              countryCode
            }
            email
          }
        }
      }
      allottedMentor {
        id
        name
        mentorProfile {
          googleMeetLink
          sessionLink
        }
      }
      school {
        id
        name
      }
      currentComponent {
        id
        enrollmentType
        latestSessionStatus
        currentTopic {
          id
          title
          order
          thumbnailSmall {
            uri
          }
        }
      }
      createdAt
    }
    topic {
      id
      title
      order
    }
    sessionStartDate
    sessionEndDate
    sessionRecordingLink
    sessionCommentByMentor
    sessionStatus
    bookingDate
    slot0
    slot1
    slot2
    slot3
    slot4
    slot5
    slot6
    slot7
    slot8
    slot9
    slot10
    slot11
    slot12
    slot13
    slot14
    slot15
    slot16
    slot17
    slot18
    slot19
    slot20
    slot21
    slot22
    slot23
    attendance {
      student {
        id
        user {
          id
          username
          name
        }
        parents {
          user {
            username
            name
            email
            phone {
              number
            }
          }
        }
      }
      status
      absentReason
    }
        }
      }
      `,
    type: 'batchSessions/fetch',
    key: 'mentorClasses'
  })
}

export default fetchBatchSessions
