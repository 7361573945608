/* eslint-disable */
import React from 'react'
import { StarFilled } from '@ant-design/icons'
import { get } from 'lodash'
import DemandCalendarWrapper from '../../DemandCalendar.style'
import Styles from '../../../DemandSupplyDashboard.style'
import { getMentorRating } from '../../../demandSupply-utils'
import getFullPath from '../../../../../utils/getFullPath'

const AssignedMentor = ({ assignedMentor }) => assignedMentor ? (
  <DemandCalendarWrapper.EventCard>
    <Styles.FlexContainer>
      <h1>Mentor Details</h1>
    </Styles.FlexContainer>
    <Styles.FlexContainer justify='flex-start'>
      {
        get(assignedMentor, 'profilePic.uri') && (
          <Styles.TopicThumbnail mentorImage bgImage={getFullPath(get(assignedMentor, 'profilePic.uri'))} />
        )
      }
      <div>
        <Styles.FlexContainer justify='flex-start'>
          <h4>
            {get(assignedMentor, 'name')}
          </h4>
          <DemandCalendarWrapper.MentorRatingBox>
            <StarFilled />
            {getMentorRating(get(assignedMentor, 'mentorProfile'))}
          </DemandCalendarWrapper.MentorRatingBox>
        </Styles.FlexContainer>
        <Styles.FlexContainer>
          Year of Exp. {get(assignedMentor, 'mentorProfile.experienceYear', 0)}
        </Styles.FlexContainer>
      </div>
    </Styles.FlexContainer>
    <Styles.CustomDivider />
  </DemandCalendarWrapper.EventCard>
) : null

export default AssignedMentor
