/* eslint-disable no-console */
import { get } from 'lodash'
import { notification } from 'antd'
import createB2B2CBatch from '../../../actions/mentorSessions/createB2B2CBatch'
import addB2B2CBatchSession from '../../../actions/mentorSessions/addB2B2CBatchSession'
import fetchLastBatchCode from '../../../actions/mentorSessions/fetchLastBatchCode'
import getFirstTopicAndLearningObjective from '../../../actions/mentorSessions/getFirstTopicAndLearningObjective'
import deleteStudent from '../../../actions/batchUserMap/deleteStudent'

const getSelectedSlotsTime = (slots, action = 'trueOnly') => {
  const slotTimeArray = []
  Object.keys(slots).forEach((slot) => {
    if (slot.includes('slot')) {
      switch (action) {
        case 'trueOnly': {
          if (slots[slot] === true) {
            slotTimeArray.push(Number(slot.toString().split('slot')[1]))
          }
          break
        }
        case 'falseOnly': {
          if (slots[slot] === false) {
            slotTimeArray.push(Number(slot.toString().split('slot')[1]))
          }
          break
        }
        default: {
          if (slots[slot] === true || slots[slot] === false) {
            slotTimeArray.push(Number(slot.toString().split('slot')[1]))
            break
          }
        }
      }
    }
  })
  return slotTimeArray.sort((a, b) => b - a)
}

const createBatchForB2B2C = async (campaignId, courseId, schoolId,
  classesConnectIds, schoolCode, allottedMentorId, mentorSessionId, bookingDate,
  slots, studentsArr, existingBatchId) => {
  let lastBatchCode = null
  let formattedBookingDate
  let selectedSlot = null
  let batchId = ''
  let schoolIdString = null
  if (campaignId) {
    schoolIdString = schoolId
  }
  await fetchLastBatchCode('b2b2c', schoolIdString).then(res => {
    if (res && res.batches && res.batches.length) {
      lastBatchCode = res.batches[0].code
    }
  })
  let numeric = lastBatchCode ? Number(lastBatchCode.split('-BCS')[1]) : 0
  numeric += 1
  /*
    logic to add batch Session
    the first published topic will get populated in the document
    */
  let firstTopicId = null
  await getFirstTopicAndLearningObjective(courseId).then(res => {
    if (res && res.topics && res.topics.length) {
      firstTopicId = get(res.topics[0], 'id')
    }
  })
  if (bookingDate && slots) {
    const selectedSlots = getSelectedSlotsTime(slots)
    formattedBookingDate = new Date(bookingDate)
    formattedBookingDate.setHours(0, 0, 0, 0)
    selectedSlot = `slot${selectedSlots[0]}`
    const allottedMentorConnectId = allottedMentorId
    const mentorSessionConnectId = mentorSessionId
    let batchCode = null
    if (campaignId) {
      batchCode = `${schoolCode}-BCS${numeric}`
    } else {
      batchCode = `TK-BCS${numeric}`
    }
    await deleteStudent(studentsArr[0], existingBatchId)
    for (let i = 0; i < 20; i += 1) {
      try {
        let addBatchRes = null
        /* eslint-disable no-await-in-loop */
        await createB2B2CBatch(batchCode, schoolId, campaignId,
          courseId, formattedBookingDate.toISOString(), selectedSlot,
          allottedMentorConnectId, mentorSessionConnectId,
          classesConnectIds, studentsArr).then(res => {
          if (res && res.addBatch) {
            addBatchRes = res.addBatch
          }
        })
        numeric += 1
        batchId = addBatchRes && addBatchRes.id
        console.log(`Batch ${batchCode} added`)
        break
      } catch (err) {
        console.log(`Batch ${batchCode} not added. Trying next batch code.`)
        batchCode = `${schoolCode}-BCS${numeric += 1}`
      }
    }
    if (!batchId) {
      notification.error({
        message: 'Error Creating Batch'
      })
    }
    // moving this to different block to avoid case of multiple batches being created
    if (batchId) {
      try {
        await addB2B2CBatchSession(courseId, formattedBookingDate.toISOString(),
          selectedSlot, mentorSessionConnectId, batchId, firstTopicId)
      } catch (err) {
        console.log(err)
        notification.error({
          message: 'Error Creating Batch Session'
        })
      }
    }
  }
  return { formattedBookingDate, selectedSlot, batchId }
}

export default createBatchForB2B2C
