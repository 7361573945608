import gql from 'graphql-tag'
import duck from '../../duck'
import updateMentorSession from './updateMentorSession'

const deleteBatchSession = (batchSessionId, input, mentorSessionId) =>
  duck.createQuery({
    query: gql`
        mutation {
        deleteBatchSession(id: "${batchSessionId}") {
          id
        }
      }
    `,
    variables: {
      callBatchAPI: true
    },
    type: 'batchSessions/delete',
    key: 'batchSessions',
    changeExtractedData: (extractedData) => {
      updateMentorSession(input, mentorSessionId)
      return extractedData
    }
  })

export default deleteBatchSession

