import gql from 'graphql-tag'
import duck from '../../duck'

const excelDownload = async (filterQuery, fetchCount) =>
  duck.query({
    query: gql`
    {
      getMagicLink(${filterQuery}) {
        linkUri
    linkToken
    expiresIn
    user{
      id
      name
      studentProfile{
        parents{
          user{
            name
            email
            phone{
              number
              countryCode
            }
          }
        }
        grade
        section
      }
    }
    school{
      id
      name
    }
      }
    }
  `,
    type: 'excelDownload/fetch',
    key: `excelDownload/${fetchCount}`
  })


export default excelDownload
