import gql from 'graphql-tag'
import duck from '../../duck'

const getAdditionalFilter = (sessionConnectId) => {
  if (sessionConnectId) {
    return `mentorSessionConnectId: "${sessionConnectId}",`
  }
  return ''
}

const updateBatchSession = (batchSessionId, input, sessionConnectId) => (
  duck.query({
    query: gql`
        mutation updateBatchSession($input: BatchSessionUpdate){
        updateBatchSession(
          input: $input,
          id:"${batchSessionId}",
          ${getAdditionalFilter(sessionConnectId)}
        ) {
            id
            createdAt
            batch {
              id
              code
              type
              course {
                id
                title
                thumbnail {
                  uri
                }
                chapters(filter: { status: published }) {
                  id
                  order
                  title
                  description
                }
                secondaryCategory
                topics(filter: { status: published }) {
                  id
                  order
                  title
                  thumbnailSmall {
                    uri
                  }
                }
              }
              studentsMeta {
                count
              }
              students {
                id
                grade
                user {
                  name
                  createdAt
                }
                parents {
                  user {
                    name
                    phone {
                      number
                      countryCode
                    }
                    email
                  }
                }
              }
              allottedMentor {
                id
                name
                mentorProfile {
                  googleMeetLink
                  sessionLink
                }
              }
              school {
                name
              }
              currentComponent {
                id
                enrollmentType
                latestSessionStatus
                currentTopic {
                  id
                  title
                  order
                  thumbnailSmall {
                    uri
                  }
                }
              }
              createdAt
            }
            topic {
              id
              title
              order
            }
            sessionStartDate
            sessionEndDate
            sessionRecordingLink
            sessionCommentByMentor
            sessionStatus
            bookingDate
            slot0
            slot1
            slot2
            slot3
            slot4
            slot5
            slot6
            slot7
            slot8
            slot9
            slot10
            slot11
            slot12
            slot13
            slot14
            slot15
            slot16
            slot17
            slot18
            slot19
            slot20
            slot21
            slot22
            slot23
            attendance {
              student {
                id
                user {
                  id
                  username
                  name
                }
                parents {
                  user {
                    username
                    name
                    email
                    phone {
                      number
                    }
                  }
                }
              }
              status
              absentReason
            }
          }
        }
        `,
    variables: {
      input
    },
    type: 'batchSessions/update',
    key: 'mentorClassess'
  })
)

export default updateBatchSession
