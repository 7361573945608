import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../../../duck'
import updateEventSpeakerProfile from './updateEventSpeakerProfile'
import addPictureToEventSpeaker from './addPictureToEventSpeaker'

const updateEventSpeakerUser = async (input, speakerInput, thumbNailFile, userId, speakerId) =>
  duck.query({
    query: gql`
      mutation($input: UserUpdate) {
        updateUser( 
            id: "${userId}"
            input: $input 
        ) {
            id
            name
            role
            phone {
                number
            }
            profilePic {
                id
                uri
            }
            email
        }
      }
    `,
    variables: {
      input
    },
    type: 'user/update',
    key: 'user',
    changeExtractedData: async (extractedData, originalData) => {
        if (get(originalData, 'updateUser')) {
          const userId = get(originalData, 'updateUser.id')
          const prevFileId = get(originalData, 'updateUser.profilePic.id')
          let thumbnailData = null
          if (thumbNailFile) {
            thumbnailData = addPictureToEventSpeaker({ 
                file: thumbNailFile, 
                userId,
                prevFileId
             })
          }
          await updateEventSpeakerProfile(speakerId, speakerInput)
        }
        return extractedData
    }
  })

export default updateEventSpeakerUser