
import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const FETCH_MENTORS_QUERY = ({ first, skip, senseiFilter }) => gql`
  query {
  senseiProfiles: senseiProfiles(
    filter: {and: [${!senseiFilter ? '' : senseiFilter}]},
    orderBy: createdAt_DESC,
    first: ${first},
    skip: ${skip * first}
  ){
    id
    user {
      id
      name
      username
      phone {
        number
      }
      email
    }
    senseiMentorTeams{
      id
      name
      mentors{
        id
      }
      teamProfilePic{
        id
        uri
      }
    }
    mentors {
      id
      user{
        id
        username
        name
        email
        phone {
          number
        }
      }
      status
      isMentorActive
    }
    createdAt
    updatedAt
    mentorsMeta {
      count
    }
  }
  senseiProfilesMeta(filter: {and: [${!senseiFilter ? '' : senseiFilter}]}){
    count
  }
}
`

const fetchMentors = async ({ first, skip, senseiFilter }) => {
  duck.query({
    query: FETCH_MENTORS_QUERY({ first, skip, senseiFilter }),
    type: 'senseiProfiles/fetch',
    key: 'senseiProfiles',
    changeExtractedData: (extractedData, originalData) => ({
      senseiProfiles: originalData.senseiProfiles,
      senseiProfilesMeta: get(originalData, 'senseiProfilesMeta')
    })
  })
}

export default fetchMentors
