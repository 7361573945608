import { get } from 'lodash'
import getDataFromLocalStorage from '../extract-from-localStorage'

const onOpenFreshChat = (props) => {
  window.fcWidget.user.get().then((result) => {
    if (get(result, 'data.meta.current_page')) {
      window.fcWidget.user.update({
        meta: {
          current_page: get(props, 'activeNavItem') || 'Dashboard',
        }
      })
    } else {
      window.fcWidget.user.setProperties({
        current_page: get(props, 'activeNavItem') || 'Dashboard',
      })
    }
    window.fcWidget.user.update({
      firstName: getDataFromLocalStorage('login.name'),
      email: getDataFromLocalStorage('login.email'),
      phone: getDataFromLocalStorage('login.phone'),
    })
  })
}

export default onOpenFreshChat
