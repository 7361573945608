import { notification, Table } from "antd";
import React, { useState, useRef, useContext, useEffect } from "react";
import gql from "graphql-tag";
import requestToGraphql from "../../../../utils/requestToGraphql";
import requestToGraphqlFromDuck from "../../../../duck/requestToGraphql";
import "./style.scss";
import "antd/dist/antd.css";
import { get, cloneDeep, isEqual } from "lodash";
import { useParams } from "react-router-dom";
import { FileExcelOutlined } from "@ant-design/icons";
import Papa from "papaparse";
import { fetchSchoolGrades } from "../../../../actions/SchoolOnboarding";
import { Button, Form, Input, Modal, Icon, Select, message, Spin } from "antd";
import { romanToInt } from "./utils/romanToInt";
import { containsDuplicates } from "./utils/containsDuplicate";
import { swapKeysAndValues } from "./utils/swapKeysandValues";
import { Prompt } from "react-router-dom";
import download from "./download.mp3";
import { useInterval } from "./useInterval";
import StatusTab from "./component/StatusTab";
import HeaderValidation from "./component/HeaderValidations";
import SelectChevronIcon from "./component/SelectChevronIcon";
import { transformCase } from "./utils/transformCase";
import fetchUserCred from "../../../../actions/userCred/fetchUserCred";

const uploadSound = new Audio(download);
const EditableContext = React.createContext();
const UploadContext = React.createContext();
// this fn is fires when a user leaves the page (refresh/tabClose)
// And shows prompt to confirm if there are unsaved changes
const initBeforeUnLoad = (showExitPrompt) => {
  window.onbeforeunload = (event) => {
    // Show prompt based on state
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = "";
      }
      return "";
    }
  };
};

const statusTabs = [
  {
    type: "total",
    label: "Total Students",
  },
  {
    type: "upload",
    label: "Uploaded Students",
  },
  {
    type: "error",
    label: "Errors",
  },
  {
    type: "left",
    label: "Not uploaded",
  },
];

// data for table header info validation
const headerValidations = {
  studentName: [
    <span>
      It should only <strong>start with an alphabet letter.</strong>
    </span>,
    <span>
      Should not contain <strong>special symbols(like: @#$)(^).</strong>
    </span>,
    <span>
      Name should be atleast<strong> 3 characters long.</strong>
    </span>,
  ],
  parentName: [
    <span>
      It should only <strong>start with an alphabet letter.</strong>
    </span>,
    <span>
      Should not contain <strong>special symbols(like: @#$)(^).</strong>
    </span>,
    <span>
      Name should be atleast<strong> 3 characters long.</strong>
    </span>,
    <span>
      {" "}
      If parent name is not available then use this format: [
      <strong>stundentName]'s Parent.</strong>
    </span>,
  ],
  grade: [
    <span>
      Should be in format: <strong>Grade + grade_num.</strong>
    </span>,
    <span>
      Should only <strong>range from 1 to 12.</strong>
    </span>,
  ],
  section: [
    <span>
      Should only range from <strong>A to Z.</strong>
    </span>,
  ],
  email: [
    <span>
      Should be <strong>a valid Email.</strong>
    </span>,
    <span>
      If not provided from school use this format:{" "}
      <strong>[school_code][unique_code]@tekie.in.</strong>
    </span>,
  ],
  rollNo: [
    <span>
      Should contain<strong> only numbers.</strong>
    </span>,
    <span>
      Should be<strong> unique </strong>within particular grade and section.
    </span>,
  ],
};
// default column data
const columnsData = [
  {
    title: "Student Name",
    dataIndex: "studentName",
    key: "studentname",
    editable: true,
    width: 180,
  },
  {
    title: "Parent Name",
    dataIndex: "parentName",
    key: "parentname",
    editable: true,
    width: 200,
  },
  {
    title: "Grade",
    dataIndex: "grade",
    key: "grade",
    width: 140,
    editable: true,
  },
  {
    title: "Section",
    dataIndex: "section",
    key: "section",
    editable: true,
    width: 100,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    editable: true,
    width: 220,
  },
  {
    title: "Roll No",
    dataIndex: "rollNo",
    key: "rollNo",
    editable: true,
    width: 100,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 100,
  },
  {
    title: "Error",
    dataIndex: "error",
    key: "error",
    width: 110,
  },
];
const fetchstudentEmail = async(schoolId) => {
  const studentsMeta = await requestToGraphqlFromDuck(gql`{
    usersMeta(
      filter: {
        and: [
          { role: parent }
          {
            parentProfile_some: {
              children_some: { school_some: { id: "${schoolId}" } }
            }
          }
        ]
      }
    ) {
      count
    }
  }
  `, { callScriptApi: true }
  )
  const studentsCountValue = get(studentsMeta, 'data.usersMeta.count', 0);
  const chunksCountValues = [];
  const firstCountValue = 200;
  for (let skip = 0; skip <= studentsCountValue; skip+=firstCountValue){
    chunksCountValues.push({ first: firstCountValue, skip })
  }
  const userCredsArray = [];
  for (const chunk of chunksCountValues) {
    const userData = await requestToGraphqlFromDuck(gql`{
      users(
        filter: {
          and: [
            { role: parent }
            {
              parentProfile_some: {
                children_some: { school_some: { id: "${schoolId}" } }
              }
            }
          ]
        }
        first: ${chunk.first}
        skip: ${chunk.skip}
      ) {
        email
      }
    }
    `, { callScriptApi: true }
    )
    if (get(userData,'data.users').length) userCredsArray.push(...get(userData,'data.users'))
  }
  return userCredsArray
}

// getting the columns with out status and error
let columnWithoutStatusAndError = [];
columnsData.forEach((item) => {
  if (item.title === "Status" || item.title === "Error") {
    return;
  }
  columnWithoutStatusAndError.push(item);
});

// custom row and column for table
const EditableRow = ({ form, ...props }) => {
  return (
    <>
      <EditableContext.Provider value={{ form }}>
        <tr {...props} />
      </EditableContext.Provider>
    </>
  );
};

const EditableFormRow = Form.create()(EditableRow);

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const { form } = useContext(EditableContext);
  const { studentsUploadStatus, downloading } = useContext(UploadContext);
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      form.validateFields();
    }
  }, [inputRef.current]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  // save for when on blur or press enter
  const save = (e) => {
    form.validateFields((error, values) => {
      if (error && error[dataIndex]) {
        return;
      }
      toggleEdit();
      inputRef.current.blur();
      handleSave({ ...record, ...values });
    });
  };
  // transform the data to be displayed in the table as per by their case
  const textCase = () => {
    if (
      dataIndex === "studentName" ||
      dataIndex === "parentName" ||
      dataIndex === "grade"
    ) {
      return "capitalize";
    }
    if (dataIndex === "section") {
      return "uppercase";
    }
    if (dataIndex === "email") {
      return "lowercase";
    }
  };
  let childNode = children;

  if (editable) {
    childNode = (
      <Form.Item
        name={dataIndex}
        className="form-item"
        style={{
          margin: 0,
        }}
      >
        {form.getFieldDecorator(dataIndex, {
          //    create rule for all the fields
          rules: [
            {
              required: true,

              message: `Please Input ${dataIndex}! `,
            },
            {
              validator: (rule, value) => {
                // name cannot have a special character
                if (dataIndex === "parentName" && value !== "") {
                  if (Number(value[0])) {
                    return Promise.reject(`Cannot start with a number`);
                  }
                  if (/[!@#$%^&*()_+\-=\[\]{};:"\\|,<>\/?]/.test(value)) {
                    return Promise.reject(
                      `${dataIndex} cannot contain special characters`
                    );
                  }

                  if (value.length < 3) {
                    return Promise.reject(
                      "Name should be atleast 3 characters long"
                    );
                  }
                }
                if (dataIndex === "studentName" && value !== "") {
                  if (Number(value[0])) {
                    return Promise.reject(`Cannot start with a number`);
                  }
                  if (/[!@#$%^&*()_+\-=\[\]{};:"\\|,<>\/?]/.test(value)) {
                    return Promise.reject(
                      `${dataIndex} cannot contain special characters`
                    );
                  }

                  if (value.length < 3) {
                    return Promise.reject(
                      "Name should be atleast 3 characters long"
                    );
                  }
                }

                if (dataIndex === "email" && value !== "") {
                  if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
                  ) {
                    return Promise.reject("Email should be valid");
                  }
                }
                if (dataIndex === "grade" && value !== "") {
                  // get digits from the value
                  let grade = value.replace(/\D/g, "");

                  // grade should start with Grade and end with digit
                  if (!/^Grade\d+$/.test(value)) {
                    return Promise.reject(
                      "It must start with Grade and end with digit e.g Grade1"
                    );
                  } else {
                    if (grade > 12 || grade < 1) {
                      return Promise.reject("Grade must be between 1 and 12");
                    }
                  }
                }
                // should only be range from A to Z
                if (dataIndex === "section" && value !== "") {
                  if (!/^[a-zA-Z]+$/.test(value) || value.length > 1) {
                    return Promise.reject("Should only range from A to Z");
                  }
                }
                // roll should only be number
                if (dataIndex === "rollNo" && value !== "") {
                  if (value < 1) {
                    return Promise.reject("Roll No must be greater than 0");
                  }
                }
                return Promise.resolve();
              },
            },
          ],
          initialValue: record[dataIndex],
        })(
          !(dataIndex === "rollNo") ? (
            <Input
              disabled={
                record.status === "Uploaded" ||
                downloading ||
                studentsUploadStatus === "inProgress"
              }
              style={{
                textTransform: textCase(),
              }}
              className="table-cell-input"
              data-editing={editing}
              ref={inputRef}
              type="text"
              autoComplete="off"
              onPressEnter={save}
              onBlur={save}
            />
          ) : (
            <Input
              disabled={
                record.status === "Uploaded" ||
                downloading ||
                studentsUploadStatus === "inProgress"
              }
              className="table-cell-input"
              data-editing={editing}
              ref={inputRef}
              type="number"
              min={1}
              autoComplete="off"
              onPressEnter={save}
              onBlur={save}
            />
          )
        )}
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
// mainComponent for uploadStudentTab
function UploadStudentsTab({
  schoolDashboardCount,
  schoolName,
  studentsUploadStatus,
  academicYearConnectId,

}) {
  const [showExitPrompt, setShowExitPrompt] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [orignalDataSource, setOrignalDataSource] = useState([]);
  const [uploadedColumn, setUploadedColumn] = useState([]);
  const [defaultHeader, setDefaultHeader] = useState([]);
  const [columns, setColumns] = useState(columnsData);
  const [rows, setRows] = useState([]);
  const [drag, setDrag] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [uploadDataLoading, setUploadDataLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [uploadStarted, setUploadStarted] = useState(false);
  const [overlayDrop, setOverlayDrop] = useState(false);
  const [statusErrors, setStatusErrors] = useState();
  const [statusUpload, setStatusUpload] = useState();
  const [statusLeft, setStatusLeft] = useState();
  const [saveDraftLoading, setSaveDraftLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [removeStatusLoading, setRemoveStatusLoading] = useState(false);
  const [iconClicked, setIconClicked] = React.useState(false);
  const [isData, setIsData] = useState(false);
  const [tableCreatedCount, setTableCreatedCount] = useState(0);
  const [mapError,setmapError] = useState(false);
  const [fileName, setFileName] = useState(null);
  const { Option } = Select;
  const { schoolId } = useParams();
  const fileRef = useRef();
  const inputCheckref = useRef();
  const anotherFileUploadref = useRef(null);
  const [isFetchingPrevCred, setIsFetchingPrevCred] = useState(false)
  // Initialize the beforeunload event listener after the resources are loaded
  window.onload = function() {
    initBeforeUnLoad(showExitPrompt);
  };
  // Re-Initialize the onbeforeunload event listener when the data has made any changes or not.
  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);
  }, [showExitPrompt]);

  // this side-effect fires when students changes
  useEffect(() => {
    countStatus();
  }, [orignalDataSource]);

  // this side-effect fires when mounts
  useEffect(() => {
    setIsData(true);
    setRemoveStatusLoading(true);
    const root = document.getElementById("root");
    root.addEventListener("dragenter", handleDragEnter);
    document.addEventListener(
      "keydown",
      (e) => {
        if (e.ctrlKey && e.key === "s") {
          e.preventDefault();
          handleSaveDraft();
        }
      },
      false
    );

    return () => {
      root.removeEventListener("dragenter", handleDragEnter);
    };
  }, []);
  // for drag and drop
  const handleDragEnter = (e) => {
    setOverlayDrop(true);
  };
  // for drag and drop
  const handleDrop = (e) => {
    e.preventDefault();
    setOverlayDrop(false);
    const file = e.dataTransfer.files[0];
    // if file type is csv then only upload
    if (file) {
      if (file.type === "text/csv") {
        setFileName(get(file, 'name'));

        Papa.parse(file, {
          download: true,
          complete: (results) => {
            resultsData(results);
          },
        });
      } else {
        // if file type is not csv then show error antd
        message.error("Please upload csv file only");
      }
    }
  };

  // getting the status of the uploaded students every 5 seconds
  useInterval(async () => {
    if (uploadStarted || studentsUploadStatus === "inProgress") {
      const fetch = await fetchSchoolGrades(schoolId);
      if (get(fetch, "school.studentsUploadStatus") === "complete") {
        message.success("Uploaded Successfully 🎉");
        uploadSound.currentTime = 0;
        uploadSound.play();
        setRemoveStatusLoading(true);
        setDownloading(false);
        setUploadStarted(false);
      }
    }
  }, 15000);

  // effect for updating the table data after getting data from the server
  useEffect(() => {
    if (!isData) return;
    let newDataSource = [];
    // added this check to avoid the error when the dataSource is empty
    if (
      drag &&
      get(schoolDashboardCount, "[studentsDraftCSV.length]", 0) === 0
    ) {
      return;
    }
    if (get(schoolDashboardCount, "[studentsDraftCSV]")) {
      countStatus();
      get(schoolDashboardCount, "[studentsDraftCSV]").forEach(
        (student, index) => {
          newDataSource.push({
            key: index++,
            studentName: student.childName || "",
            parentName: student.parentName || "",
            grade: student.grade || "",
            section: student.section || "",
            email: student.parentEmail || "",
            rollNo: student.rollNo || "",
            status: student.status || "",
            error: student.error || "",
          });
        }
      );

      const columnsDataResult = [
         {
          title: "Sr. No",
          dataIndex: "srno",
          key: "srno",
          align: "center",
          width: 60,
          render: (text, record, index) => <div style={{fontWeight: "bold"}}>{index + 1}</div>,
        },
        {
          title: () => {
            return (
              <HeaderValidation
                headerTitle="Student Name"
                type="studentName"
                iconClicked={iconClicked}
                setIconClicked={setIconClicked}
                validations={headerValidations.studentName}
              />
            );
          },
          dataIndex: "studentName",
          key: "studentname",
          editable: true,
          width: 180,
        },
        {
          title: () => {
            return (
              <HeaderValidation
                headerTitle="Parent Name"
                type="parentName"
                iconClicked={iconClicked}
                setIconClicked={setIconClicked}
                validations={headerValidations.parentName}
              />
            );
          },
          dataIndex: "parentName",
          key: "parentname",
          width: 140,
          editable: true,
        },
        {
          title: () => {
            return (
              <HeaderValidation
                headerTitle="Grade"
                type="grade"
                iconClicked={iconClicked}
                setIconClicked={setIconClicked}
                validations={headerValidations.grade}
              />
            );
          },
          dataIndex: "grade",
          key: "grade",
          width: 140,
          editable: true,
        },
        {
          title: () => {
            return (
              <HeaderValidation
                headerTitle="Section"
                type="section"
                iconClicked={iconClicked}
                setIconClicked={setIconClicked}
                validations={headerValidations.section}
              />
            );
          },
          dataIndex: "section",
          key: "section",
          editable: true,
          width: 100,
        },
        {
          title: () => {
            return (
              <HeaderValidation
                headerTitle="Email"
                type="email"
                iconClicked={iconClicked}
                setIconClicked={setIconClicked}
                validations={headerValidations.email}
              />
            );
          },
          dataIndex: "email",
          key: "email",
          editable: true,
          width: 220,
        },
        {
          title: () => {
            return (
              <HeaderValidation
                headerTitle="Roll No"
                type="rollNo"
                iconClicked={iconClicked}
                setIconClicked={setIconClicked}
                validations={headerValidations.rollNo}
              />
            );
          },
          dataIndex: "rollNo",
          key: "rollNo",
          editable: true,
          width: 100,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          width: 110,
          render: (text, record) => {
            let status;
            if (text === "Uploaded") {
              status = "upload";
            }
            if (text === "Error") {
              status = "error";
            }
            if (!text) {
              status = "left";
            }
            return (
              <div className="student-data-status" data-type={status}>
                <div className="icon"></div>
                <p>{text ? text : "Not uploaded"}</p>
              </div>
            );
          },
        },
        {
          title: "Error",
          dataIndex: "error",
          key: "error",
          width: 140,
          render: (text, render) => {
            return <p className="student-data-error">{text}</p>;
          },
        },
      ];

      setColumns(columnsDataResult);
      // showing filtered data in the table due to tabs
      filterData(newDataSource, tabIndex);
      setOrignalDataSource(newDataSource);
      setUploadDataLoading(false);
    }
  }, [get(schoolDashboardCount, "[studentsDraftCSV]")]);
  useEffect(() => {
    if (
      get(schoolDashboardCount, "[studentsDraftCSVConfig]") &&
      rows.length === 0
    ) {
      // if fileName is null then set the fileName to the file name of the file uploaded
      setFileName(get(schoolDashboardCount, "[studentsDraftCSVConfig].title"));

      const fileConfig = get(schoolDashboardCount, "[studentsDraftCSVConfig]");
      // get all the values from the fileConfig
      const uploadedFileDraftHeaders = Object.values(fileConfig).splice(1);
      setUploadedColumn(uploadedFileDraftHeaders);
      let newCsvHeader = {};
      for (const prop in fileConfig) {
        if (prop === "studentNameHeader") {
          newCsvHeader["studentName"] = fileConfig[prop];
        }
        if (prop === "gradeHeader") {
          newCsvHeader["grade"] = fileConfig[prop];
        }
        if (prop === "parentEmailHeader") {
          newCsvHeader["email"] = fileConfig[prop];
        }
        if (prop === "parentNameHeader") {
          newCsvHeader["parentName"] = fileConfig[prop];
        }
        if (prop === "rollNoHeader") {
          newCsvHeader["rollNo"] = fileConfig[prop];
        }
        if (prop === "sectionHeader") {
          newCsvHeader["section"] = fileConfig[prop];
        }
      }

      setDefaultHeader(newCsvHeader);
      let studentData = [];
      get(schoolDashboardCount, "[studentsDraftCSV]").forEach(
        (student, index) => {
          studentData.push({
            studentName: student.childName || "",
            parentName: student.parentName || "",
            grade: student.grade || "",
            section: student.section || "",
            email: student.parentEmail || "",
            rollNo: student.rollNo || "",
          });
        }
      );
      let columns = Object.keys(newCsvHeader);
      //  rearrange the stundentdata by keys according to the columns
      let newStudentData = [];
      studentData.forEach((student) => {
        let newStudent = {};
        columns.forEach((column) => {
          newStudent[column] = student[column];
        });
        newStudentData.push(newStudent);
      });
      const rowsData = newStudentData.map((student) => Object.values(student));
      setRows(rowsData);
    }
  }, [get(schoolDashboardCount, "[studentsDraftCSVConfig]")]);
  // it counts the total no of each status
  const countStatus = () => {
    let upload = 0;
    let error = 0;
    let left = 0;
    orignalDataSource.forEach((item) => {
      if (item.status === "Uploaded") {
        upload++;
      } else if (item.status === "Error") {
        error++;
      } else {
        left++;
      }
    });
    setStatusErrors(error);
    setStatusUpload(upload);
    setStatusLeft(left);
  };
  const getNextCountForEmail = async (schoolId) => {
    const studentEmails = await fetchstudentEmail(schoolId)
    // get the email and get max Count 
    const count = studentEmails.length > 0 ? Math.max(...studentEmails.map((item) => {
      if( get(item,'email', '')){
        return get(item,'email', '').match(/\d+(?=@)/) ? get(item,'email', '').match(/\d+(?=@)/) : 0
      }
      return 0
    })) : 1
    return count
  }
  
  // This here is create  Table
  const createTableData = async() => {
    if(mapError) return message.error(mapError);
     let newRows = []
    if(orignalDataSource.length > 0){
       newRows = orignalDataSource.map((item) => {
        return Object.values(item).splice(1, 6);
      });      
    }
    
    const rowsDatawithKeys = rows.map((row) => {
      let newRow = {};
      row.forEach((item, index) => {
        newRow[uploadedColumn[index]] = item;
      });
      return newRow;
    });
    const swapDefaultHeader = swapKeysAndValues(defaultHeader);
    const newHeader = {};
    uploadedColumn.forEach((item) => {
      if (swapDefaultHeader[item]) {
        newHeader[item] = swapDefaultHeader[item];
      }
    });
    const rowsData = rowsDatawithKeys.map((row, index) => {
      let newRow = {};
      Object.keys(row).forEach((key) => {
        if (newHeader[key]) {
          newRow[newHeader[key]] = row[key];
        }
      });
      return newRow;
    });
    setIsFetchingPrevCred(true)
    const startIndex = await getNextCountForEmail(schoolId)
    setIsFetchingPrevCred(false)
    // // validate the data
    for (const [index,item] of rowsData.entries()) {
      for (const prop in item) {
        if (prop === "studentName") {
          // it should only start with alphabet
          item[prop] = transformCase(item[prop].replace(/[^a-zA-Z0-9]/g," ").trim(),"capitalize");
        }
        // if parent name is empty, use student name
        if (prop === "parentName") {
          item[prop] = transformCase(item[prop].replace(/[!@#$%^&*()_+\-=\[\]{};:"\\|,<>\/?]/g, " ").trim(),"capitalize");
          if (item[prop] === "") {
            item[prop] = `${transformCase(item["studentName"].trim(),"capitalize")}'s Parent`;
          }
        }
        if (prop === "email") {

          item[prop] = item[prop].toLowerCase().trim();
          // if email is not valid generate small unique email witch shchool name + random number
          const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if(item[prop].match(emailRegex)){
            item[prop] = item[prop]
          }
          else{
            item[prop] = `${schoolDashboardCount.code}${startIndex + index +
              1}@tekie.in`
          }
          
        }
        if (prop === "grade") {
          if (parseInt(item[prop])) {
            item[prop] = `Grade${item[prop]}`;
          }
          // if the string contains number then return grade + number
          if (item[prop].match(/\d/)) {
            item[prop] = `Grade${item[prop].match(/\d/)[0]}`;
          }
          if (item[prop].match(/[IVXLCDM]+/)) {
            item[prop] = `Grade${romanToInt(
              item[prop].match(/[IVXLCDM]+/)[0]
            )}`;
          }
        }

        if (prop === "rollNo") {
          if (item[prop].match(/\d+/)) {
            item[prop] = item[prop].match(/\d+/)[0];
          } else {
            item[prop] = "";
          }
        }
      }
    };
    // create header for status and table too
    const rowDataTable = rowsData.map((item,index) => {
      return {
        key: index++,
        ...item,
        status: "",
        error: "",
      };
    });
    setRemoveStatusLoading(true);
    setDataSource(rowDataTable);
    setOrignalDataSource(rowDataTable);
    setShowExitPrompt(true);
    setIsModal(false);
    setmapError(false)
    setDrag(true);
    setTableCreatedCount(tableCreatedCount + 1);
    message.success("Table created successfully");
  };
  // This handles when changing through item in the select input
  const handleChange = (e, label) => {
    let newInput = { ...defaultHeader, [label]: e.key };
    if(Object.keys(newInput).every(item => item)){
      setmapError(false)
    }
    else{
      setmapError("Please map all the headers")
    }
    if(containsDuplicates(Object.values(newInput))){
      
      setmapError("Duplicate headers found")
    }
    else{
      setmapError(false)
    }
    setDefaultHeader(newInput);
  };
  const autoMapHeaders = (columnHeader) => {
    setmapError(false)
    let mapHeader = {};
    // auto mapping the headers
    columnWithoutStatusAndError.forEach(column => {
     let newColumn = column.dataIndex;
      if(newColumn === "studentName"){
        mapHeader["studentName"] = columnHeader[columnHeader.findIndex(item => {
          let newItem = item.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
          return newItem.includes("student") || newItem.includes("childs") || newItem.includes("child") || newItem.includes("name")
        
        })]
      }
      if(newColumn === "parentName"){
        mapHeader["parentName"] = columnHeader[columnHeader.findIndex(item => {
          let newItem = item.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
          return newItem.includes("parent") || newItem.includes("guardian") || newItem.includes("childp")
        })]
      }
      if(newColumn === "email"){
        mapHeader["email"] = columnHeader[columnHeader.findIndex(item => {
          let newItem = item.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
          return newItem.includes("email") || newItem.includes("id") || newItem.includes("address")
        })]
      }
      if(newColumn === "grade"){
        mapHeader["grade"] = columnHeader[columnHeader.findIndex(item => {
          let newItem = item.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
          return newItem.includes("grade") || newItem.includes("clas")
        })]
      }
      if(newColumn === "rollNo"){
        mapHeader["rollNo"] = columnHeader[columnHeader.findIndex(item => {
          let newItem = item.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
          return newItem.includes("rol")
        })]
      }
      if(newColumn === "section"){
        mapHeader["section"] = columnHeader[columnHeader.findIndex(item => {
          let newItem = item.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
          return newItem.includes("sec")
        })]
      }
          
    })
    setDefaultHeader(mapHeader);
    
    if (!(Object.values(mapHeader).every((item) => item))) {
      message.error("Please map all the headers");
      setmapError("Please map all the headers");
      return
    }
    if(containsDuplicates(Object.values(mapHeader))){
      message.error("Duplicate headers found");
      setmapError("Duplicate headers found");
      return
    }
  };
  // Things that needed to be create table
  const resultsData = (results) => {
    // getting the header from the csv file
    const columnHeader = results.data[0];
   
    setUploadedColumn(columnHeader);
    // setting the rest data as student data to the rows state
    // FILTER THE DATA, if the rows has empty column 
    const rows = results.data.splice(1).filter((item) => {
      return Object.values(item).some((value) => 
      value.replace(/\s/g, "").length > 0
      );
    });
    setRows(rows);
    autoMapHeaders(columnHeader);
    
    // Everything is set and ready to show modal
    setIsModal(true);
  };

  // custom row and cell kind of config for table
  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  };
  // Here , it save the data as draft
  const handleSaveDraft = async () => {
    setIsData(false);
    setSaveDraftLoading(true);

    let newDataSource = cloneDeep(orignalDataSource);
    newDataSource.forEach((item, index) => {
      for (const [key, value] of Object.entries(item)) {
        // don't add key
        if (key === "key") {
          delete item[key];
        }

        if (key === "studentName") {
          newDataSource[index].childName = value;
          delete newDataSource[index][key];
        }
        if (key === "email") {
          newDataSource[index].parentEmail = value;
          delete newDataSource[index][key];
        }
        // remove if the value is empty to go to uploaded api
        if (!value) {
          delete newDataSource[index][key];
        }
      }
    });
    // swapping the keys and values of the header,so it can easily be used to update the StudentsDraftCSVConfig
    const newMappedHeaders = cloneDeep(defaultHeader);
    const {
      parentName,
      studentName,
      grade,
      section,
      rollNo,
      email,
    } = newMappedHeaders;
    const splitDataLength = 500;
    let dataLength = newDataSource ? newDataSource.length : 0
    dataLength = Math.ceil(dataLength/splitDataLength)
    let res = {}
    let variables = {}
    for(let i=0; i < dataLength; i++){
      const modifiedDataSource = newDataSource.slice(i*splitDataLength, i*splitDataLength + splitDataLength)
      if(i == 0){
        variables = {
          input: {
            studentsDraftCSVConfig: {
              title: fileName,
              parentNameHeader: parentName,
              parentEmailHeader: email,
              studentNameHeader: studentName,
              rollNoHeader: rollNo,
              gradeHeader: grade,
              sectionHeader: section,
            },
            studentsDraftCSV: {
              replace: modifiedDataSource,
            },
          },
        };
      }else {
        variables.input.studentsDraftCSV.pushMany = modifiedDataSource;
        delete variables.input.studentsDraftCSV.replace
      }
      res = await requestToGraphql(
        gql`
              mutation($input: SchoolUpdate) {
                updateSchool(id: "${schoolId}", input: $input) {
                  id
                }
              }
            `,
        variables
      );
    }
    if (res.data) {
      const resp = await fetchSchoolGrades(schoolId);
      if (resp.school) {
        setIsData(true);
      }
      message.success("Draft saved successfully");
      setShowExitPrompt(false);
      setSaveDraftLoading(false);
    }
  };
  // It uploads the data to the server one by one
  const handleUploadData = async () => {
    
    // if the tab is in other than all students, then set the tab to all students for getting the error of input
    // map the columns and set hte editable to false if the editble key is present

    if (tabIndex !== 0) {
      setTabIndex(0);
      setDataSource(orignalDataSource);
    }
    setIsData(false);
    // this timeout to make sure all the students cell is rendered
    setTimeout(async () => {
      const antFormControl = document.querySelectorAll(
        ".ant-form-item-control"
      );
      let formError = [];

      if (antFormControl) {
        antFormControl.forEach((item) => {
          formError.push(item.classList.contains("has-error"));
        });
      }

      if (formError.includes(true)) {
        message.error("Please fill out all the fields");
        return;
      }

      setDownloading(true);
      if (removeStatusLoading) {
        setRemoveStatusLoading(false);
      }

      let newDataSource = cloneDeep(orignalDataSource);

      newDataSource.forEach((item, index) => {
        for (const [key, value] of Object.entries(item)) {
          if (key === "key") {
            delete item[key];
          }
          if (key === "studentName") {
            newDataSource[index].childName = value;
            delete newDataSource[index][key];
          }
          if (key === "email") {
            newDataSource[index].parentEmail = value;
            delete newDataSource[index][key];
          }
          if (!value) {
            delete newDataSource[index][key];
          }
        }
      });
      const newMappedHeaders = cloneDeep(defaultHeader);
      const {
        parentName,
        studentName,
        grade,
        section,
        rollNo,
        email,
      } = newMappedHeaders;
      const splitDataLength = 500;
      let dataLength = newDataSource ? newDataSource.length : 0
      dataLength = Math.ceil(dataLength/splitDataLength)
      let variables = {}
      let resDraft;
      for(let i=0; i < dataLength; i++){
        const modifiedDataSource = newDataSource.slice(i*splitDataLength, i*splitDataLength + splitDataLength)
        if(i == 0){
          variables = {
            input: {
              studentsDraftCSVConfig: {
                title: fileName,
                parentNameHeader: parentName,
                parentEmailHeader: email,
                studentNameHeader: studentName,
                rollNoHeader: rollNo,
                gradeHeader: grade,
                sectionHeader: section,
              },
              studentsUploadStatus: "todo",
              studentsDraftCSV: {
                replace: modifiedDataSource,
              },
            },
          };
        }else {
          variables.input.studentsDraftCSV.pushMany = modifiedDataSource;
          delete variables.input.studentsDraftCSV.replace
        }
        resDraft = await requestToGraphql(
          gql`
            mutation($input: SchoolUpdate) {
              updateSchool(id: "${schoolId}", input: $input) {
                id
    
              }
            }
          `,
          variables
        );
      }
      if (resDraft.data) {
        setUploadStarted(true);
        setShowExitPrompt(false);

        const resp = await fetchSchoolGrades(schoolId);
        if (resp.school) {
          setIsData(true);
        }
        await requestToGraphql(
          gql`
          mutation {
            addUpdateBulkSchoolUserData(
              schoolName: "${schoolName}"
              setPassword: true
              academicYearId: "${academicYearConnectId}"
            ) {
              status
             
            }
          }
        `
        );
      }
    });
  };
  // This here is fn runs when the user edit the cell value and it updates the state
  const handleSave = (row) => {
    const newData = [...dataSource];
    const allData = [...orignalDataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const allindex = allData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item,...row });
    // add to the the originalDataSource if the changed have been made after filter tab
    allData.splice(allindex, 1, { ...item ,...row});
    !isEqual(allData, orignalDataSource) && setShowExitPrompt(true);
    setDataSource(newData);
    setOrignalDataSource(allData);
  };

  const finalColumn = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  // It handles that tab changes
  const filterData = (data, index) => {
    if (index === 0) data;
    if (index === 1) {
      data = data.filter((item) => item.status === "Uploaded");
    } else if (index === 2) {
      data = data.filter((item) => item.status === "Error");
    } else if (index === 3) {
      data = data.filter((item) => item.status === "");
    }
    setDataSource(data);
  };
  const handleTabActive = (index) => {
    setTabIndex(index);
    let newDataSource = orignalDataSource;
    filterData(newDataSource, index);
  };
  // this spinners show when uploading happens
  const uploadingIcon = <Icon type="loading" style={{ fontSize: 15 }} spin />;

  //  this spinner shows until the studentDraftcsv is fetched from the server
  if (uploadDataLoading) {
    return (
      <div className="upload-student-tab-container">
        <Spin size="large" wrapperClassName="upload-loading" />
      </div>
    );
  }

  return (
    <>
      {/* this prompt shows when route changes */}
      <UploadContext.Provider value={{ studentsUploadStatus, downloading }}>
        <Prompt
          when={showExitPrompt}
          message="Are you sure You want to leave? All your changes will be lost."
        />
        <div className="upload-student-tab-container" ref={inputCheckref}>
          {overlayDrop && (
            <div
              onDragLeave={() => {
                setOverlayDrop(false);
              }}
              onDragOver={(e) => {
                e.preventDefault();
                setOverlayDrop(true);
              }}
              onDrop={(e) => {
                if (!academicYearConnectId) {
                  setOverlayDrop(false);
                  return notification.warn({ message: 'Please select Academic Year to proceed further' })
                }
                handleDrop(e);
              }}
              className={`drop-overlay`}
            >
              <p>Drop it!</p>
            </div>
          )}

          {!(orignalDataSource.length > 0) &&
            (studentsUploadStatus !== "complete" ||
              studentsUploadStatus !== "inProgress") && (
              <div
                className="upload-container"
                onClick={() => fileRef.current.click()}
              >
                <input
                  type="file"
                  ref={fileRef}
                  hidden
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (!academicYearConnectId) {
                      return notification.warn({ message: 'Please select Academic Year to proceed further' })
                    }
                    if(!file) return
                    setFileName(get(file, 'name'));
                    Papa.parse(file, {
                      download: true,
                      complete: (results) => {
                        resultsData(results);
                      },
                    });
                  }}
                />
                <div className="upload-drag-icon">
                  <FileExcelOutlined />
                </div>
                <div className="upload-drag-info">
                  <h3>Select a CSV file to import</h3>
                  <p className="upload-text">
                    or drag file to this page to upload
                  </p>
                </div>
              </div>
            )}

          {orignalDataSource.length > 0 && (
            <>
              <div className="upload-student-nav">
                <div className="data-status-uploading-container">
                  {statusTabs.map((item, index) => {
                    let status;
                    if (item.type === "upload") {
                      status = statusUpload;
                    }
                    if (item.type === "error") {
                      status = statusErrors;
                    }
                    if (item.type === "left") {
                      status = statusLeft;
                    }
                    if (item.type === "total") {
                      status = orignalDataSource.length;
                    }

                    return (
                      <StatusTab
                        index={index}
                        type={item.type}
                        label={item.label}
                        status={status}
                        tabIndex={tabIndex}
                        handleTabActive={handleTabActive}
                        key={index}
                      ></StatusTab>
                    );
                  })}
                </div>
                <div className="upload-student-nav-btns">
                  <Button
                    className="upload-file default-btn"
                    onClick={() => {
                      anotherFileUploadref.current.click();
                    }}
                  >
                    <svg
                      width={14}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                      />
                    </svg>
                    <p className="upload-file-link">Upload new file</p>
                    <input
                      ref={anotherFileUploadref}
                      type="file"
                      name=""
                      id=""
                      hidden
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if(!file) return
                        if (!academicYearConnectId) {
                          return notification.warn({ message: 'Please select Academic Year to proceed further' })
                        }
                        setFileName(get(file, "name", ""));
                        Papa.parse(file, {
                          download: true,
                          complete: (results) => {
                            resultsData(results);
                          },
                        });
                        e.target.value = ""
                      }}
                    />
                  </Button>

                  <Button
                    className="default-btn"
                    onClick={() => {
                      setIsModal(true);
                    }}
                  >
                    <svg
                      width={14}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                      />
                    </svg>
                    <p>Remap headers</p>
                  </Button>

                  <Button
                    type="primary"
                    icon="save"
                    loading={saveDraftLoading}
                    onClick={() => handleSaveDraft()}
                  >
                    Save as Draft
                  </Button>
                  <Button
                    type="primary"
                    disabled={
                      downloading || studentsUploadStatus === "inProgress" || orignalDataSource.length === Number(statusUpload)
                    }
                    icon="cloud-upload"
                    onClick={() => handleUploadData()}
                  >
                    Upload Students
                  </Button>
                </div>
              </div>
              <div>
                <div className="file-header">
                  {fileName && (
                    <div className="file-info">
                      <svg
                        width="11"
                        height="15"
                        viewBox="0 0 11 15"
                        fill="none"
                      >
                        <path
                          d="M6.875 0H1.375C0.615312 0 0.00687516 0.67125 0.00687516 1.5L0 13.5C0 14.3287 0.608437 15 1.36812 15H9.625C10.3847 15 11 14.3287 11 13.5V4.5L6.875 0ZM8.25 12H2.75V10.5H8.25V12ZM8.25 9H2.75V7.5H8.25V9ZM6.1875 5.25V1.125L9.96875 5.25H6.1875Z"
                          fill="#58BC9E"
                        />
                      </svg>
                      {fileName}
                    </div>
                  )}

                  <div
                    className="data-status-upload"
                    style={{
                      opacity:
                        !removeStatusLoading ||
                        studentsUploadStatus === "inProgress"
                          ? 1
                          : 0,
                      transition: "opacity 0.5s ease",
                    }}
                  >
                    <Spin
                      wrapperClassName="spinner"
                      indicator={uploadingIcon}
                    ></Spin>
                    <p>Uploading...</p>
                  </div>
                </div>
                <Table
                  key={tableCreatedCount}
                  size="small"
                  components={components}
                  pagination={false}
                  rowClassName={() => "editable-row"}
                  scroll={{
                    x: 1300,
                  }}
                  columns={finalColumn}
                  dataSource={dataSource}
                />
              </div>
            </>
            
          )}
          {isModal && (
            <Modal
              key={isModal}
              wrapClassName="upload-student-modal"
              centered
              visible={isModal}
              maskClosable={false}
              closable={false}
              destroyOnClose={true}
              okText="Map Headers"
              onOk={() => {
                createTableData();
              }}
              okButtonProps={{
                loading: isFetchingPrevCred
              }}
              onCancel={() => {
                setIsModal(false);
              }}
            >
              <div className="header">
                <p>User Fields</p>
                <p>Headers in your file</p>
              </div>
              <div className="select-headers">
                <div className="export-headers">
                  {columnWithoutStatusAndError.map((item, index) => {
                    return (
                      <>
                        <div>
                          <p className="text turncate" title={item.title}>
                            {item.title}
                          </p>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="choose-headers">
                  {columnWithoutStatusAndError.map((column, index) => {
                    return (
                      <>
                        <Select
                          suffixIcon={<SelectChevronIcon />}
                          name="columns"
                          labelInValue
                          defaultValue={{
                            key: defaultHeader[column.dataIndex],
                          }}
                          onChange={(e) => handleChange(e, column.dataIndex)}
                        >
                          {uploadedColumn.map((item, i) => {
                            return (
                              <Option value={item} key={item}>
                                {item}
                              </Option>
                            );
                          })}
                        </Select>
                      </>
                    );
                  })}
                </div>
                {mapError && <p className="error-text">* {mapError}</p>}
              </div>
            </Modal>
          )}
        </div>
      </UploadContext.Provider>
    </>
  );
}

export default UploadStudentsTab;
