import { Button } from "antd";
import styled from "styled-components";
import { TekieAmethyst } from '../../constants/colors'

const Container = styled.div`
    padding: 0 25px 25px 25px;
    font-family: Inter;
    letter-spacing: 0.03em;
    .collapsable-button {
        width: 18%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: none;
        outline: none;
        padding: 0;
        background: none;
        >span {
            padding: 4px 8px;
            height: 24px;
            color: #757575;
            background: #F2F2F2;
            border-radius: 4px;
            font-size: 12px;
            line-height: 16px;
        }
    }
`

const DetailsContainer = styled.div`
    width: 100%;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`

const TopNavigation = styled.div`
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #282828;
`

const PackageDetailsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const NameTheoryCourseContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    margin-left: 29px;
`

const NameDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const PackageName = styled.div`
    display: flex;
    align-items: center;
    > h3 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #9E9E9E;
        text-transform: uppercase;
        margin: 0;
        margin-right: 8px;
    }
    > button {
        padding: 4px 8px;
        width: 74px;
        height: 24px;
        background: #F2F2F2;
        border-radius: 4px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #757575;
        display: flex;
        border: none;
        outline: none;
        > span {
            color: #212121;
        }
    }
`

const PackageInternalName = styled.h2`
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin: 8px 0;
`

const DescriptionContainer = styled.p`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #757575;
    > span {
        color: #000000;
    }
`

const SessionsContainer = styled.div`
    display: flex;
    margin-top: 24px;
    margin-bottom: 16px;
`

const IndividualDataContainer = styled.div`
    display: flex;
    margin-right: 48px;
`

const SessionStats = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    > p {
        font-size: 10px;
        line-height: 12px;
        color: #757575;
        margin: 0;
        margin-bottom: 4px;
    }
    > span {
        font-size: 12px;
        line-height: 16px;
        color: #424242;
    }
`

const TagsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 350px;
    > button {
        padding: 2px 8px;
        background: #EEEEEE;
        border-radius: 15px;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #616161;
        margin: 3px 8px 3px 0;
        outline: none;
        border: none;
    }
`

const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    aling-items: center;
    margin: 16px 16px 20px 29px;
`

const ButtonContainer = styled.div`
    display: flex;
`

const StyledButton = styled.button`
    background: ${TekieAmethyst};
    color: #fff;
    border: none;
    outline: none;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 146px;
    height: 36px;
    padding: 7px 12px;
    cursor: pointer;
    margin-left: 16px;
    > p {
        margin: 0;
        margin-left: 12px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }
`

const NavButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 25px;
`

const NavButton = styled.button`
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    opacity: 0.4;
    font-weight: 500;
    outline: none;
    border: none;
    padding-bottom: 14px;
    margin-right: 47px;
    cursor: pointer;
    background: #fff;
    ${props => props.isSelected ? `
        color: ${TekieAmethyst};
        opacity: 1;
        border-bottom: 3px solid ${TekieAmethyst};
        border-radius: 24px 24px 0px 0px;
    ` : ''}
`

const PackageListContainer = styled.div`
    border: 1px solid #000000;
    box-sizing: border-box;
    padding: 24px 20px 24px 17px;
    > h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
    }
`

const SessionListHeadContainer = styled.div`
    background: #F5F5F5;
    border-radius: 6px;
    padding: 14px 18px 13px 14px;
    display: flex;
    width: 100%;
    > span {
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;
        color: #212121;
        width: 18%;
    }
`

const SessionListDataContainer = styled.div`
    box-shadow: inset 0px -1px 0px #EEEEEE;
    padding: 22px 18px 21px 14px;
    display: flex;
    width: 100%;
    > span {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.03em;
        color: #212121;
        width: 18%;
        box-sizing: border-box;
    }
`

const PublishedContainer = styled.div`
    display: flex;
    align-items: center;
    > h3 {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        margin: 0;
        margin-left: 8px;
        text-transform: capitalize;
    }
`

const PanelHead = styled.div`
    min-height: 40px;
    box-sizing: border-box;
    > h3 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #000;
        text-transform: capitalize;
        padding: 12px 0 12px 44px;
    }
`

const BatchesHead = styled.div`
    min-height: 56px;
    background: #EEEEEE;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    > p {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #212121;
        margin: 0;
        padding: 12px 0;
    }
`

const BatchesData = styled.div`
    min-height: 56px;
    box-sizing: border-box;
    display: flex;
    border-bottom: 0.5px solid #e5e5e587;
    > p {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #212121;
        margin: 0;
        padding: 17px 0;
    }
`

const EditButton = styled.button`
    border: none;
    outline: none;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 5px;
    cursor: pointer;
    > span {
        margin: 0;
        font-weight: 500;
        font-size: 12px;
        text-decoration: none;
    }
`


Container.DetailsContainer = DetailsContainer
Container.TopNavigation = TopNavigation
Container.PackageDetailsContainer = PackageDetailsContainer
Container.NameTheoryCourseContainer = NameTheoryCourseContainer
Container.NameDescriptionContainer = NameDescriptionContainer
Container.PackageName = PackageName
Container.PackageInternalName = PackageInternalName
Container.DescriptionContainer = DescriptionContainer
Container.SessionsContainer = SessionsContainer
Container.IndividualDataContainer = IndividualDataContainer
Container.SessionStats = SessionStats
Container.TagsContainer = TagsContainer
Container.FooterContainer = FooterContainer
Container.ButtonContainer = ButtonContainer
Container.StyledButton = StyledButton
Container.NavButtonsContainer = NavButtonsContainer
Container.NavButton = NavButton
Container.PackageListContainer = PackageListContainer
Container.SessionListHeadContainer = SessionListHeadContainer
Container.SessionListDataContainer = SessionListDataContainer
Container.PublishedContainer = PublishedContainer
Container.PanelHead = PanelHead
Container.BatchesHead = BatchesHead
Container.BatchesData = BatchesData
Container.EditButton = EditButton

export default Container