import React, { Component } from 'react';
import SwitchConstant from '../../Constant/Switch/SwitchConstant';
import { WrapupMomentMargin } from '../../EventCompletion.style';
import WrapupEventTitle from './WrapupEventTitle';

export default class WrapupEvent extends Component {
  render() {
      const { toggleWrapupEventSwitch,switchColor } = this.props;
    return (
        <>
            <WrapupEventTitle />
            <WrapupMomentMargin style={{display:"flex"}}>
                <div>
                    <SwitchConstant on={"YES"} off={"NO"} toggle={toggleWrapupEventSwitch} switchColor={switchColor}/>
                </div>
                <div style={{marginLeft:"30px",color: "0707078a" }}>
                    <b>{`Show Completed event on Event Listing Page`}</b>
                </div>
            </WrapupMomentMargin>
            
        </>
    )
  }
}
