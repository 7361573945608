/* eslint-disable no-console */
import gql from 'graphql-tag'
import { get } from 'lodash'
import requestToGraphql from '../../utils/requestToGraphql'
import getFullPath from '../../utils/getFullPath'

const UPLOAD_FILE = gql`
mutation($fileInput: FileInput!, $connectInput: FileConnectInput!) {
  uploadFile(fileInput: $fileInput,
  connectInput: $connectInput
  ){
    id,
    name,
    uri
  }
}
`
const UPLOAD_FILE_WITHOUT_CONNECT_ID = gql`
mutation($fileInput: FileInput!) {
  uploadFile(fileInput: $fileInput){
    id,
    name,
    size,
    uri
  }
}
`

const uploadFile = async (file, fileInfo, mappingInfo, returnFullPath = true) => {
  let res = {}
  if (mappingInfo && mappingInfo.typeId) {
    res = await requestToGraphql(UPLOAD_FILE,
      { file, fileInput: fileInfo, connectInput: mappingInfo })
  } else {
    res = await requestToGraphql(UPLOAD_FILE_WITHOUT_CONNECT_ID,
      { file, fileInput: fileInfo })
  }

  const uploadedFileInfo = get(res, 'data.uploadFile', null)
  /** When a file is updated,Appending the uri with Date.now() prevents browser to
   show the image with the same uri */
   let fileUri = `${getFullPath(
     uploadedFileInfo.signedUri || uploadedFileInfo.uri
   )}?${Date.now()}`;
  if (!returnFullPath) fileUri = uploadedFileInfo.uri;
  return { ...uploadedFileInfo, uri: fileUri }
}

export default uploadFile
