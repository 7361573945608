import styled from 'styled-components'
import {Input,Select} from 'antd'
const Main = styled.div`
display:flex;
flex-direction:column;
width:100%;
height:100%;
`
const SlideContentHolder = styled.div`
display: flex;
flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'};
margin-top: 1.3%;
margin-bottom: 1.3%;
`
const NameDiv = styled.div`
display: flex;
flex-direction:row;
`
const GridSlideText = styled.p`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
color: #333333;
margin: 8px 7px 9px 0px;
`
const StyledSelect = styled(Select)`
min-width: 220px;
width: 60%;
min-height: 38px !important; 
margin: 0px 19px 0px 0px!important;
.ant-select-selection{
    border-radius:8px !important;
    border: 1px solid #AAAAAA !important;
}



.ant-select-selection:hover {
    border-color:none !important;
}
.ant-select-selection-selected-value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}
`
const SlideTypeHolder=styled.div`
    width: 98.8%;
    height: 100%;
`
export {
    Main,
    SlideContentHolder,
    NameDiv,
    GridSlideText,
    StyledSelect,
    SlideTypeHolder
    }
    