import React, { useState, useEffect } from 'react';
import { Input,Select, notification, Switch } from 'antd';
import {get} from 'lodash'
import {
    StyledFormContainer,
    InputContainer,
    FormInputContainer,
    GradeInputContainer,
    GradeInputDropdown,
    SwitchContainer
    } from './CoursePackageDetails.style';
import {
        MainContainer,
        MainContainerHeading
    } from '../SelectCourses/SelectCourses.style'


const { Option } = Select;

const { TextArea } = Input;
const CoursePackageDetails = ({handleInput, input, packageData, isTeacherTraining, setIsTeacherTraining}) => {
    const [packageName, setPackageName] = useState(get(input, 'title') != ''? get(input, 'title') : "")
    const [internalName, setInternalName] = useState(get(input, 'internalName') != ''?get(input, 'internalName'):"")
    const [description, setDescription] = useState(get(input, 'description') != ''?get(input, 'description'):"")
    const [minGrade, setMinGrade] = useState(get(input, 'minGrade') > 0?get(input, 'minGrade'): '-')
    const [maxGrade, setMaxGrade] = useState(get(input, 'maxGrade') > 0 ? get(input, 'maxGrade'): '-')

    useEffect(() => {
        if (packageData) {
            setPackageName(get(packageData, 'title', ""))
            setInternalName(get(packageData, 'internalName', ""))
            setDescription(get(packageData, 'description', ""))
            setMinGrade(get(packageData, 'minGrade', ""))
            setMaxGrade(get(packageData, 'maxGrade', ""))
        }
    }, [packageData])
    const renderInputField = (label, inputContainer, onChange, value) => {
        return (
            <InputContainer isTextAreaLabel={inputContainer === "textarea"}>
                <label>{label}</label>
                {
                    inputContainer === 'textarea' ? (
                    <TextArea 
                        onChange={onChange}
                        value={value}
                        rows={6}
                        placeholder= 'more than 6 characters'
                         />
                    ) : (
                    <Input
                        value={value}
                        onChange={onChange}/>
                    )
                } 
            </InputContainer>
        )
    }
    const handleDropdownChange = (event,grade)=> {
        if(grade == "min"){
            setMinGrade(event)
            handleInput('minGrade', event)
        }else{
            if(event < minGrade){
                notification.error({
                    message: 'MaxGrade cannot be less then MinGrade!'
                })
                setMinGrade('-')
            }else{
            setMaxGrade(event)
            }
            handleInput('maxGrade', event)
        }
    }
    return (
        <MainContainer>
            <MainContainerHeading>Package Details</MainContainerHeading>
            <StyledFormContainer>
            <FormInputContainer>
                {renderInputField('Course Package Name', 'input', (e) => {setPackageName(e.target.value); handleInput('title', e.target.value)}, packageName)}
                {renderInputField('Internal Reference Name', 'input', (e) => {setInternalName(e.target.value); handleInput('internalName', e.target.value)}, internalName)}
                {renderInputField('Description', 'textarea', (e) => {setDescription(e.target.value); handleInput('description', e.target.value)}, description)}
                <GradeInputContainer>
                    <GradeInputDropdown>
                        <h3>Min Grade:</h3>
                        <Select style={{ width: 217, marginRight:'16px' }} value={minGrade} onChange={(e)=>handleDropdownChange(e,"min")}>
                            <Option value={1}>1</Option>
                            <Option value={2}>2</Option>
                            <Option value={3}>3</Option>
                            <Option value={4}>4</Option>
                            <Option value={5}>5</Option>
                            <Option value={6}>6</Option>
                            <Option value={7}>7</Option>
                            <Option value={8}>8</Option>
                            <Option value={9}>9</Option>
                            <Option value={10}>10</Option>
                            <Option value={11}>11</Option>
                            <Option value={12}>12</Option>
                        </Select>
                    </GradeInputDropdown>
                    <GradeInputDropdown>
                        <h3>Max Grade:</h3>
                        <Select style={{ width: 217 }} value={maxGrade} onChange={(e)=>handleDropdownChange(e, "max")}>
                            <Option value={1}>1</Option>
                            <Option value={2}>2</Option>
                            <Option value={3}>3</Option>
                            <Option value={4}>4</Option>
                            <Option value={5}>5</Option>
                            <Option value={6}>6</Option>
                            <Option value={7}>7</Option>
                            <Option value={8}>8</Option>
                            <Option value={9}>9</Option>
                            <Option value={10}>10</Option>
                            <Option value={11}>11</Option>
                            <Option value={12}>12</Option>
                        </Select>
                    </GradeInputDropdown>
                </GradeInputContainer>
                <SwitchContainer>
                    <h3>IsTeacherTraining</h3>
                    <Switch
                        checked={isTeacherTraining}
                        onChange={(e) => setIsTeacherTraining(e)}
                    />
                </SwitchContainer>
            </FormInputContainer>
            </StyledFormContainer>
        </MainContainer>
    )
}

export default CoursePackageDetails