import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'
import uploadFile from '../utils/uploadFile'

const adduserBankDetail = async (userBankDetailInput, webImage,userConnectId) => duck.query({
    query: gql`
  mutation($input: UserBankDetailInput!) {
    addUserBankDetail(input: $input,userConnectId:"${userConnectId}") {
      id
      documentFile{
          id
      }
    }
  }
  `,
    variables: {
        input: {
            bankName: get(userBankDetailInput,'bankName'),
            accountNumber: get(userBankDetailInput,'accountNumber'),
            ifscCode: get(userBankDetailInput,'ifscCode'),
            branchName: get(userBankDetailInput,'branchName'),
        },
    },
    type: 'userBankDetail/add',
    key: 'userBankDetail',
    changeExtractedData: (extractedData, originalData) => {
        extractedData.userBankDetails=get(originalData,'addUserBankDetail');
            if (webImage && typeof webImage !== 'string') {
              (async function imageUpload() {
                  try {
                      await uploadFile(webImage, {
                          fileBucket: 'python'
                      },
                       {
                          typeField: 'documentFile', typeId: get(originalData, 'addUserBankDetail.id') , type: 'UserBankDetail',
                      })
                  } catch (error) {
                      console.log(error)
                  }
              }())
          }
        return { ...extractedData }
    }
})


export default adduserBankDetail
