import { get } from 'lodash';
import React from 'react'
import Workbook from "react-excel-workbook";
import { Button } from "antd";

function LabInspectionReport({ labInspectionsOfSchools }) {
    const formatInspectionChecks = (inspectionChecks) => {
        const configs = {}
        inspectionChecks && inspectionChecks.length && inspectionChecks.forEach(inspectionCheck => {
            const type = get(inspectionCheck, 'type')
            if(!configs[type]) {
                configs[type] = [inspectionCheck]
            } else {
                configs[type] = [...configs[type], inspectionCheck]
            }
        })
        const newConfig = {}
        Object.keys(configs).length && Object.keys(configs).map(item => {
            const inspectionChecks = configs[item]
            const nestedObj = {}
            inspectionChecks.forEach(inspectionCheck => {
                const type = get(inspectionCheck, 'name')
                if(!nestedObj[type]) {
                    nestedObj[type] = [inspectionCheck]
                } else {
                    nestedObj[type] = [...nestedObj[type], inspectionCheck]
                }
            })
            newConfig[item] = nestedObj
        })
        return newConfig
    }

    const getSystemsInfo = (labInspections) => {
        let totalSystems = 0
        let totalComputers = 0
        let totalWorkingComputers = 0
        let systemInspectedOnline = 0
        let systemInspectedOffline = 0
        let compatibleSystems = 0
        let inCompatibleSystems = 0
        let allInspectionChecks = []
        labInspections.forEach(item => {
            const systems = get(item, 'systems', [])
            totalSystems += systems.length
            totalComputers += get(item, 'labConfiguration.totalNumberOfComputers')
            totalWorkingComputers += get(item, 'labConfiguration.totalNumberOfWorkingComputers')
            systems && systems.length && systems.forEach(system => {
                if (get(system, 'inspectionMode') === 'online') {
                    systemInspectedOnline += 1
                }
                if (get(system, 'inspectionMode') === 'offline') {
                    systemInspectedOffline += 1
                }
                if (get(system, 'status') === 'compatible') {
                    compatibleSystems += 1
                }
                if (get(system, 'status') === 'incompatible') {
                    inCompatibleSystems += 1
                }
                const inspectionChecks = get(system, 'inspectionChecks', [])
                inspectionChecks.length && inspectionChecks.forEach(inspectionCheck => allInspectionChecks.push(inspectionCheck))
            })
        })
        const newConfig = formatInspectionChecks(allInspectionChecks)
        return {
            totalSystems,
            totalComputers,
            totalWorkingComputers,
            systemInspectedOnline,
            systemInspectedOffline,
            compatibleSystems,
            inCompatibleSystems,
            newConfig
        }
    }

    const getStatusCount = (arr, key) => {
        let count = 0
        arr.forEach(item => {
            if (get(item, 'status') === key) {
                count += 1
            }
        })
        return count
    }

    const getConfigRatio = (config, isCompatible) => {
        let text = ''
        Object.keys(config).length && Object.keys(config).forEach(item => {
            const category = item ? item.charAt(0).toUpperCase() + item.slice(1) : item;
            const individualConfigs = config[item]
            let categoryText = ''
            let index = 0;
            Object.keys(individualConfigs).length && Object.keys(individualConfigs).forEach((individualConfig) => {
                const arr = individualConfigs[individualConfig] || []
                let count = null
                if (isCompatible) {
                    count = getStatusCount(arr, 'compatible')
                } else {
                    count = getStatusCount(arr, 'incompatible')
                }
                if (count && count > 0) {
                    categoryText += `${index + 1}) ${individualConfig} - ${count}/${arr.length} Systems\n`
                    index += 1;
                }
            })
            if (categoryText) {
                text += `${category}\n`;
                text += categoryText;
                text += '..................';
                text += '\n';
            }
        })
        return text
    }

    const getOnlineInspectedMode = (systems) => {
        let count = 0
        systems.length && systems.forEach(item => {
            if (get(item, 'inspectionMode') === 'online') {
                count += 1
            }
        })
        return count
    }
    const getOfflineInspectedMode = (systems) => {
        let count = 0
        systems.length && systems.forEach(item => {
            if (get(item, 'inspectionMode') === 'offline') {
                count += 1
            }
        })
        return count
    }
    const getCompatibleSystem = (systems) => {
        let count = 0
        systems.length && systems.forEach(item => {
            if (get(item, 'status') === 'compatible') {
                count += 1
            }
        })
        return count
    }
    const getInCompatibleSystem = (systems) => {
        let count = 0
        systems.length && systems.forEach(item => {
            if (get(item, 'status') === 'incompatible') {
                count += 1
            }
        })
        return count
    }
    
    const getCommentString = (comment) => {
        try {
            return decodeURIComponent(comment)
        } catch {
            return comment
        }
    }

    const getOverallLabInspectionData = () => {
        let overAll = []
        Object.keys(labInspectionsOfSchools).length && Object.keys(labInspectionsOfSchools).forEach(key => {
            const labInspections = labInspectionsOfSchools[key]
            const sortedLabs = labInspections.sort((a, b) => get(a, 'labNo') - get(b, 'labNo'))
            const latestLab = sortedLabs[sortedLabs.length-1]
            const { totalSystems, totalComputers, totalWorkingComputers, systemInspectedOnline,
                systemInspectedOffline, compatibleSystems, inCompatibleSystems, newConfig } = getSystemsInfo(labInspections)
            if (labInspections && labInspections.length) {
                const obj = {
                    srNo: overAll.length + 1,
                    schoolName: key,
                    inspectionDate: new Date(get(latestLab, 'labConfiguration.inspectionDate', 'createdAt')).toLocaleDateString(),
                    inspectionTime: new Date(get(latestLab, 'labConfiguration.inspectionDate', 'createdAt')).toLocaleTimeString(),
                    noOfLabs: labInspections.length,
                    totalSystemsInSchool: totalComputers,
                    totalWorkingSystemsInSchool: totalWorkingComputers,
                    totalSystemsInspected: totalSystems,
                    systemsInspectedOnline: systemInspectedOnline,
                    systemsInspectedOffline: systemInspectedOffline,
                    totalCompatibleSystems: compatibleSystems,
                    totalIncompatibleSystems: inCompatibleSystems,
                    incompatibleConfigs: getConfigRatio(newConfig, false),
                    compatibleConfigs: getConfigRatio(newConfig, true),
                }
                overAll.push(obj)
            }
        })
        return overAll
	}

    const getLabDetailsData = () => {
		let labDetails = []
        Object.keys(labInspectionsOfSchools).length && Object.keys(labInspectionsOfSchools).forEach(key => {
            const labInspections = labInspectionsOfSchools[key]
            if (labInspections && labInspections.length) {
                labInspections.forEach(item => {
                    const systems = get(item, 'systems', [])
                    const allInspectionChecks = []
                    systems.length && systems.forEach(system => {
                        const inspectionChecks = get(system, 'inspectionChecks', [])
                        inspectionChecks.length && inspectionChecks.forEach(inspectionCheck => allInspectionChecks.push(inspectionCheck))
                    })
                    const config = formatInspectionChecks(allInspectionChecks)
                    const obj = {
                        schoolName: key,
                        LabNo: get(item, 'labNo'),
                        labName: get(item, 'labName'),
                        totalSystems: get(item, 'labConfiguration.totalNumberOfComputers', 0),
                        totalNumberOfComputers: get(item, 'labConfiguration.totalNumberOfWorkingComputers', 0),
                        totalNumberOfWorkingComputers: get(item, 'systems', []).length,
                        inspectedOnline: getOnlineInspectedMode(get(item, 'systems', [])),
                        inspectedOffline: getOfflineInspectedMode(get(item, 'systems', [])),
                        compatibleSystem: getCompatibleSystem(get(item, 'systems', [])),
                        inCompatibleSystem: getInCompatibleSystem(get(item, 'systems', [])),
                        incompatibleConfigs: getConfigRatio(config, false),
                        compatibleConfigs: getConfigRatio(config, true),
                        projectInteractivePanel: get(item, 'labConfiguration.projectInteractivePanel'),
                        speakers: get(item, 'labConfiguration.speakers'),
                        powerBackup: get(item, 'labConfiguration.powerBackup'),
                        powerBackupType: get(item, 'labConfiguration.powerBackupType'),
                        internetConnection: get(item, 'labConfiguration.internetConnection'),
                        serviceProviderType: get(item, 'labConfiguration.serviceProviderType'),
                        internetSpeed: get(item, 'labConfiguration.internetSpeed'),
                        inspectionDate: get(item, 'labConfiguration.inspectionDate'),
                    }
                    labDetails.push(obj)
                })
            }
        })
		return labDetails
    }

    const getSystemDetailsData = () => {
        const systemsDetails = []
        Object.keys(labInspectionsOfSchools).length && Object.keys(labInspectionsOfSchools).forEach(key => {
            const labInspections = labInspectionsOfSchools[key]
            let allSystems = []
            labInspections.forEach(item => {
                const systems = get(item, 'systems')
                systems.forEach(system => {
                    const inspectionChecks = get(system, 'inspectionChecks', [])
                    const formatedInspectionChecks = {}
                    inspectionChecks.length && inspectionChecks.forEach(inspectionCheck => {
                        const name = get(inspectionCheck, 'name')
                        formatedInspectionChecks[name] = inspectionCheck
                    })
                    const obj = {
                        labName: get(item, 'labName'),
                        ...system,
                        inspectionChecks: formatedInspectionChecks
                    }
                    allSystems.push(obj)
                })
            })
            allSystems.length && allSystems.forEach(system => {
                let macAddress = '';
                try {
                    const info = JSON.parse(decodeURIComponent(get(system, 'systemInformation')))
                    const macAddressString = get(info, 'uuid.macs', []).join(', ')
                    macAddress = macAddressString || '-'
                } catch {
                    macAddress = '-';
                }
                const obj = {
                    schoolName: key,
                    labName: get(system, 'labName'),
                    srNo: get(system, 'serialNo'),
                    uniqueID: get(system, 'uniqueDeviceId'),
                    macAddress,
                    systemStatus: get(system, 'status'),
                    inspectionMode: get(system, 'inspectionMode'),
                    updatedAt: get(system, 'updatedAt') ? new Date(get(system, 'updatedAt')).toLocaleString() : '',
                    ram: get(system, 'inspectionChecks[ram].spec'),
                    processor: get(system, 'inspectionChecks[processor].spec'),
                    monitor: get(system, 'inspectionChecks[monitor].spec'),
                    storage: get(system, 'inspectionChecks[storage].spec'),
                    os: get(system, 'inspectionChecks[os].spec'),
                    chrome: get(system, 'inspectionChecks[chrome].status'),
                    paint: get(system, 'inspectionChecks[paint].status'),
                    filmora: get(system, 'inspectionChecks[filmora].status'),
                    notepad: get(system, 'inspectionChecks[notepad].status'),
                    github: get(system, 'inspectionChecks["Github.com"].status'),
                    diagramsnet: get(system, 'inspectionChecks["app.diagrams.net"].status'),
                    tekie: get(system, 'inspectionChecks["Tekie.in"].status'),
                    kahoot: get(system, 'inspectionChecks["kahoot.it"].status'),
                    googleapis: get(system, 'inspectionChecks["fonts.googleapis.com"].status'),
                    figma: get(system, 'inspectionChecks["figma.com"].status'),
                    googleDocs: get(system, 'inspectionChecks["docs.google.com"].status'),
                    codeorg: get(system, 'inspectionChecks["code.org"].status'),
                    blockly: get(system, 'inspectionChecks["developers.google.com/blockly"].status'),
                    playcode: get(system, 'inspectionChecks["playcode.io"].status'),
                    filmoraWondershare: get(system, 'inspectionChecks["filmora.wondershare.com"].status'),
                    google: get(system, 'inspectionChecks["google.com"].status'),
                    googleMail: get(system, 'inspectionChecks["mail.google.com"].status'),
                    mouse: get(system, 'inspectionChecks[mouse].status'),
                    keyboard: get(system, 'inspectionChecks[keyboard].status'),
                    msAccess: get(system, 'inspectionChecks[msAccess].status'),
                    canva: get(system, 'inspectionChecks[canva].status'),
                    sticker: get(system, 'inspectionChecks[sticker].status'),
                    comments: getCommentString(get(system, 'comment')),
                }
                systemsDetails.push(obj)
            })
        })
        return systemsDetails
    }

    return (
        <Workbook filename={`lab-inspection-report.xlsx`} element={
            // <Workbook filename={`${get(labInspections, '[0].school.name')}-lab-inspection-report.xlsx`} element={
            <Button type="primary" >
                Download Report
            </Button>
        }>
            <Workbook.Sheet data={getOverallLabInspectionData()} name="School Level">
                <Workbook.Column label="Sr No" value="srNo"/>
                <Workbook.Column label="School Name" value="schoolName"/>
                <Workbook.Column label="Inspection Date" value="inspectionDate"/>
                <Workbook.Column label="Inspection Time" value="inspectionTime"/>
                <Workbook.Column label="No. of Labs" value="noOfLabs"/>
                <Workbook.Column label="Total systems in School (Manual)" value="totalSystemsInSchool"/>
                <Workbook.Column label="Total Working Systems in School (Manual)" value="totalWorkingSystemsInSchool"/>
                <Workbook.Column label="Total systems inspected (Automatic)" value="totalSystemsInspected"/>
                <Workbook.Column label="No. of Systems Inspected Online" value="systemsInspectedOnline"/>
                <Workbook.Column label="No. of Systems Inspected Offline" value="systemsInspectedOffline"/>
                <Workbook.Column label="Total Compatible systems" value="totalCompatibleSystems"/>
                <Workbook.Column label="Total Incompatible systems" value="totalIncompatibleSystems"/>
                <Workbook.Column label="Incompatible Configs" value="incompatibleConfigs"/>
                <Workbook.Column label="Compatible Configs" value="compatibleConfigs"/>
            </Workbook.Sheet>
            <Workbook.Sheet data={getLabDetailsData()} name="Lab Level Info">
                <Workbook.Column label="School Name" value="schoolName"/>
                <Workbook.Column label="Lab No" value="LabNo"/>
                <Workbook.Column label="Lab Name" value="labName"/>
                <Workbook.Column label="Total systems in lab (Manual)" value="totalSystems"/>
                <Workbook.Column label="Total Working Systems in lab (Manual)" value="totalNumberOfComputers"/>
                <Workbook.Column label="Total systems inspected in lab (Automatic)" value="totalNumberOfWorkingComputers"/>
                <Workbook.Column label="No. of Systems Inspected Online" value="inspectedOnline"/>
                <Workbook.Column label="No. of Systems Inspected Offline" value="inspectedOffline"/>
                <Workbook.Column label="Total Compatible systems" value="compatibleSystem"/>
                <Workbook.Column label="Total Incompatible systems" value="inCompatibleSystem"/>
                <Workbook.Column label="Incompatible Configs" value="incompatibleConfigs"/>
                <Workbook.Column label="Compatible Configs" value="compatibleConfigs"/>
                <Workbook.Column label="Project Interactive Panel (Manual)" value="projectInteractivePanel"/>
                <Workbook.Column label="Speakers (Manual)" value="speakers"/>
                <Workbook.Column label="PowerBackup (Manual)" value="powerBackup"/>
                <Workbook.Column label="Power Backup Type (Manual)" value="powerBackupType"/>
                <Workbook.Column label="Internet Connection (Manual)" value="internetConnection"/>
                <Workbook.Column label="Service Provider Type (Manual)" value="serviceProviderType"/>
                <Workbook.Column label="Internet Speed (Manual)" value="internetSpeed"/>
                <Workbook.Column label="Inspection Date" value="inspectionDate"/>
            </Workbook.Sheet>
            <Workbook.Sheet data={getSystemDetailsData()} name="System Level Info">
                <Workbook.Column label="School Name" value="schoolName"/>
                <Workbook.Column label="Lab Name" value="labName"/>
                <Workbook.Column label="Sr No" value="srNo"/>
                <Workbook.Column label="Unique ID" value="uniqueID"/>
                <Workbook.Column label="Mac Address" value="macAddress"/>
                <Workbook.Column label="Status" value="systemStatus"/>
                <Workbook.Column label="Inspection Mode" value="inspectionMode"/>
                <Workbook.Column label="Inspection Date" value="updatedAt"/>
                <Workbook.Column label="ram" value="ram"/>
                <Workbook.Column label="processor" value="processor"/>
                <Workbook.Column label="monitor" value="monitor"/>
                <Workbook.Column label="storage" value="storage"/>
                <Workbook.Column label="os" value="os"/>
                <Workbook.Column label="chrome" value="chrome"/>
                <Workbook.Column label="paint" value="paint"/>
                <Workbook.Column label="filmora" value="filmora"/>
                <Workbook.Column label="notepad" value="notepad"/>
                <Workbook.Column label="Github.com" value="github"/>
                <Workbook.Column label="app.diagrams.net" value="diagramsnet"/>
                <Workbook.Column label="Tekie.in" value="tekie"/>
                <Workbook.Column label="kahoot.it" value="kahoot"/>
                <Workbook.Column label="fonts.googleapis.com" value="googleapis"/>
                <Workbook.Column label="figma.com" value="figma"/>
                <Workbook.Column label="docs.google.com" value="googleDocs"/>
                <Workbook.Column label="code.org" value="codeorg"/>
                <Workbook.Column label="developers.google.com/blockly" value="blockly"/>
                <Workbook.Column label="playcode.io" value="playcode"/>
                <Workbook.Column label="filmora.wondershare.com" value="filmoraWondershare"/>
                <Workbook.Column label="google.com" value="google"/>
                <Workbook.Column label="mail.google.com" value="googleMail"/>
                <Workbook.Column label="Mouse" value="mouse"/>
                <Workbook.Column label="Keyboard" value="keyboard"/>
                <Workbook.Column label="MS Access" value="msAccess"/>
                <Workbook.Column label="Canva" value="canva"/>
                <Workbook.Column label="Sticker" value="sticker"/>
                <Workbook.Column label="Comments" value="comments"/>
            </Workbook.Sheet>
        </Workbook>
    )
}

export default LabInspectionReport