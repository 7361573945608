import gql from 'graphql-tag'
import duck from '../../duck'
import refineMentorRatingData from './convertParticularMentorData'

const fetchParticularMentorRating = async (first, skip, mentorId, kidId) => duck.query({
  query: gql`
    {
        mentorMenteeSessions(orderBy: createdAt_DESC, 
            ${first ? `first:${first}` : ''}, 
            ${skip ? `skip:${first * skip}` : ''}, 
            filter: 
            { and: 
              [
                {mentorSession_some: 
                  {user_some: 
                    {id: "${mentorId}" }
                  }
                },
                
                ${kidId ? ` {
                  menteeSession_some: 
                  {user_some: 
                    {id: "${kidId}"}
                  }
                }` : ''}
              ]
            }
            ) {
          id
          menteeSession {
            id
            user {
              id
              name
            }
            slot0
            slot1
            slot2
            slot3
            slot4
            slot5
            slot6
            slot7
            slot8
            slot9
            slot10
            slot11
            slot12
            slot13
            slot14
            slot15
            slot16
            slot17
            slot18
            slot19
            slot20
            slot21
            slot22
            slot23
            bookingDate 
          }
          mentorSession {
            user {
                id
                name
              }
          }
          id
          course {
            id
            title
          }
          topic {
            id
            title
          }
          comment
          rating
        }
        mentorMenteeSessionsMeta(filter: 
          { and: 
            [
              {mentorSession_some: 
                {user_some: 
                  {id: "${mentorId}" }
                }
              },
              
              ${kidId ? ` {
                menteeSession_some: 
                {user_some: 
                  {id: "${kidId}"}
                }
              }` : ''}
            ]
          }) {
            count
          }
      }
    `,
  type: 'particularMentorRatings/fetch',
  key: 'particularMentorRatings',
  changeExtractedData: (extractedData, originalData) => {
    const convertedData = refineMentorRatingData(originalData)
    return {
      particularMentorRatingsData: convertedData
    }
  }
})

export default fetchParticularMentorRating
