/* eslint-disable quotes */
import React from 'react'
import styled, { css } from 'styled-components'
import { Icon } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Modal from '../components/Modal.styles'
import getFullPath from '../../../utils/getFullPath'
import { TekieOrange } from '../../../constants/colors'


const ClassesStyle = styled.div`
    height: 100%;
    width: 100%;
    margin: 0 auto;
    font-family: 'Inter' !important;
`

const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    .ant-progress-bg{
        background: rgba(1, 170, 147, 1) !important;
    }
`

const PaidFreeCheckboxStyle = styled.div`
    display:flex;
    justify-content: end;
    margin-right: 20px;
    align-items: center;
`
const SearchBoxDiv = styled.div`
    font-family: Inter;
    display: flex;
    justify-content: space-between;
    .ant-input {
        border-radius: 8px;
    }
`

const StyledIcon = styled(({ isActive, ...rest }) => <Icon {...rest} />)`
  transition: 0.3s all ease-in-out;
  font-size: ${props => props.fontSize ? props.fontSize : '26px'};
  fill: ${props => props.fillSvg ? props.fillSvg : '#666666'};
  margin-right: ${props => props.marginRight ? props.marginRight : '8px'};
`
const PageTitle = styled.div`
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0px;
    color: #212121;
    padding: 6px 10px 18px;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0px 10px;
`

const FilterContainer = styled.div`
    flex: 0 0 30%;
    max-width: 280px;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 16px;
    margin-right: 28px;
    padding: 18px;
    position: sticky;
    top: 0;
    height: 662px;
    overflow-y: scroll;
`
const FilterHeaderText = styled.div`
    font-family: Inter;
    font-weight: 500;
    font-size: ${props => props.fontSize ? props.fontSize : '14px'};
    color: #212121;
    padding-bottom: ${props => props.paddingBottom ? props.paddingBottom : '0'};
    padding-top: 5px;
`
const FilterSectionContainer = styled.div`
    padding: 10px 0px;
    border-bottom: 2px solid #EEEEEE;
    ${'' /* max-height: 185px;
    min-height: 100px;
    overflow-y: auto; */}
`
const FilterSearchBoxContainer = styled.div`
  display: flex;
`
const FilterChecboxContainer = styled.div`
    max-height: 185px;
    min-height: 95px;
    overflow-y: auto;
 `
const SearchBoxContainer = styled.div`
  position: relative;
  width: 20px;
  height: 30px;
  font-size: 20px;
  box-sizing: border-box;
  border-radius: 50px;
  border: 4px solid  #ffffff;
  padding: 5px;
  background: #ffffff;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: auto;

  ${({ hover }) =>
    hover &&
    css`
      width: 50%;
      border: 1px solid #000;

      @media (min-width: 768px) {
        width: 65%;
        border-radius: 5px;
      }
    `}
    &: hover {
        border-color: ${(props) => (props.showSearchInput ? '#1890ff' : '#ffffff')};
        box-shadow: none;
    }
    .anticon svg {
    height: 26px;
    width: 26px;
    margin-top: -4px;
    border-Radius: 15px;
    background: #FAFAFA;
    padding: 4px;
    }
    &: hover .anticon svg {
        margin-top: -4px;
        height: 26px;
        width: 26px;
        background: none;
        padding: 4px;
    }
`
const FilterSearchboxSearchInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 85%;
  height: 88%;
  line-height: 20px;
  outline: 0;
  border: 0;
  font-size: 14px;
  border-radius: 5px;
  padding: 0px 8px;
  padding-top: 4px;
  margin: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  display: ${(props) => (props.showSearchInput ? 'block' : 'none')};

`

const IconCommonCss = css`
  height: 1.25rem;
  width: 1.25rem;
  z-index: 10;
`

const FilterSearchBoxMagnifyingGlassIcon = styled(SearchOutlined)`
  ${IconCommonCss}
`

const FilterSearchBoxMagnifyingGlassIconRight = styled(SearchOutlined)`
  ${IconCommonCss}
  align-self: flex-end;
  cursor: pointer;
`
const FilterOptions = styled.div`
    padding-left: 0px;

    .ant-checkbox {
        .ant-checkbox-inner{
            border-radius: 0px;
            border: 2px solid #BDBDBD !important;
        }
    }
`
const Checkbox = styled(Modal.CustomCheckbox)`
    padding: 4px 0 !important;
    .ant-checkbox-inner {
        border: 1px solid #BDBDBD !important;
    }
`
const CheckboxLabel = styled.div`
    font-family: 'Inter';
    letter-spacing: 0px;
    font-weight: normal;
    font-size: 14px;
    color: #424242;
    text-transform: capitalize;
`
const CheckboxText = styled.p`
    margin-right:14px;
`
const CustomCheckbox = styled(Checkbox.Group)`
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color:  #8C61CB;
        border-color:  #8C61CB;
    }
    .ant-checkbox + span {
        font-weight: 500;
    }
`
const Divider = styled.div`
    border-bottom: 1px solid #EEEEEE;
    height: 2px;
    width: 100%;
    padding: 8px 0px;
`
const MoreOptionText = styled.div`
    font-family: 'Inter';
    letter-spacing: 0px;
    font-weight: 500;
    font-size: 14px;
    color: #8C61CB;
    cursor: pointer;
    transition: all .3s;
    opacity: 0.9;
    &:hover {
        opacity: 1;
    }
`

const SessionsContainer = styled.div`
    flex: 1;
    display: block;
    max-width: 100%;
    overflow-x: hidden;
`

const SessionCard = styled.div`
    background: #FFFFFF;
    border-radius: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-item: center;
    justify-content: flex-start;
    padding: 18px;
    margin: 18px 0px;
    cursor: pointer;
`

const SessionImageContainer = styled.div`
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 16px;
    min-width: 180px;
    height: 180px;
    padding: 12px;
`

const SessionImage = styled.div`
    background-image: ${props => props.bgImage ? `url(${getFullPath(props.bgImage)})` : ``};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
`
const DefaultImage = styled.img`
    width: 100%;
    height: 100%;
`
const SessionDetailsContainer = styled.div`
    display: block;
    padding: 4px 18px;
    flex: 1;
`

const SessionIndicator = styled.div`
    font-family: 'Inter';
    background: ${props => props.bgColor ? props.bgColor : TekieOrange};
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    color: white;
    font-size: 10px;
    font-weight: 600;
    display: flex;
    text-transform: Uppercase;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 2px;
`
const SessionHeaderText = styled.div`
    font-family: 'Inter';
    text-transform: Uppercase;
    font-weight: 550;
    font-size: 16px;
    color: #111111;
    margin: 0px 14px;
`
const SessionClassRangeText = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    width: 66px;
    height: 20px;
    background: #F1ECF9;
    border-radius: 4px;
    margin-left: auto;
    p {
        font-family: Inter;
         font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #8C61CB;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 10px;
    }
`
const HeaderTag = styled.div`
    font-family: 'Inter';
    font-weight: normal;
    font-size: 9px;
    color: #FFFFFF;
    width: 50px;
    background: ${props => props.bgColor || '#01AA93'};
    border-radius: 6px;
    padding: 4px;
    text-align: center;
    text-transform: uppercase;
`
const CourseCategory = styled.div`
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #BDBDBD;
    padding: 0px 0px 4px;
`
const CourseName = styled.div`
    font-family: 'Inter';
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    padding: 0px 0px 4px;
    &::first-letter {
        text-transform: uppercase;
    }
`
const CourseDuration = styled.div`
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    color: #616161;
    padding: 0px 0px 6px;
`
const ContentClassDetail = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    margin: 12px 40px 18px 0px;

    & .classDetailsText {
        font-weight: normal;
        font-size: 14px;
        display: block;
        color: #757575;
        white-space: no-wrap;
        padding: 0px 0px;
        flex: 0 1 20%;
        margin-bottom: 4px;
    }

    @media screen and (max-width: 500px) {
        .classDetailsText {
            flex: 0 1 30%;
        }
    }
`
const ContentText = styled.div`
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
const ProgressText = styled.div`
    font-family: 'Inter';
    font-weight: normal;
    font-size: 12px;
    color: #757575;
    padding-right: 8px;
`
const loadingCSS = {
  height: '30px',
  margin: '30px',
  textAlign: 'center'
}
const searchBoxCSS = {
  marginLeft: '27%',
  width: 270
}
const searchBoxCSSTeacherTraining = {
  marginLeft: '1%',
  width: 270
}

SessionCard.ImageContainer = SessionImageContainer
SessionCard.Image = SessionImage
SessionCard.DefaultImage = DefaultImage
SessionCard.DetailsContainer = SessionDetailsContainer
SessionCard.SessionIndicator = SessionIndicator
SessionCard.HeaderText = SessionHeaderText
SessionCard.ClassRangeText = SessionClassRangeText
SessionCard.HeaderTag = HeaderTag
SessionCard.CourseCategory = CourseCategory
SessionCard.CourseName = CourseName
SessionCard.CourseDuration = CourseDuration
SessionCard.ContentClassDetail = ContentClassDetail
SessionCard.ContentText = ContentText
SessionCard.ProgressText = ProgressText


const Filter = FilterContainer
Filter.SectionContainer = FilterSectionContainer
Filter.FilterSearchBoxContainer = FilterSearchBoxContainer
Filter.SearchBoxContainer = SearchBoxContainer
Filter.FilterChecboxContainer = FilterChecboxContainer
Filter.HeaderText = FilterHeaderText
Filter.Options = FilterOptions
Filter.Checkbox = Checkbox
Filter.CheckboxLabel = CheckboxLabel
Filter.FilterSearchboxSearchInput = FilterSearchboxSearchInput
Filter.FilterSearchBoxMagnifyingGlassIcon = FilterSearchBoxMagnifyingGlassIcon
Filter.FilterSearchBoxMagnifyingGlassIconRight = FilterSearchBoxMagnifyingGlassIconRight


Filter.Divider = Divider
Filter.MoreOptionText = MoreOptionText

ClassesStyle.Icon = StyledIcon
ClassesStyle.PageTitle = PageTitle
ClassesStyle.Row = Row
ClassesStyle.SessionsContainer = SessionsContainer
ClassesStyle.FlexContainer = FlexContainer
ClassesStyle.SearchBoxDiv = SearchBoxDiv
ClassesStyle.loadingCSS = loadingCSS
ClassesStyle.searchBoxCSS = searchBoxCSS
ClassesStyle.searchBoxCSSTeacherTraining = searchBoxCSSTeacherTraining


PaidFreeCheckboxStyle.CheckboxText = CheckboxText
PaidFreeCheckboxStyle.CustomCheckbox = CustomCheckbox

export { Filter, SessionCard, PaidFreeCheckboxStyle }
export default ClassesStyle
