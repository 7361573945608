/* eslint-disable */
import gql from 'graphql-tag'
import duck from '../../../duck'


export const fetchStudentData = async (userId) => duck.query({
  query: gql`
  {
    userCourses(filter: {and: [{user_some: {id: "${userId}"}}]}) {
    courses {
        title
          secondaryCategory
          thumbnail{
          uri
        }
        id
        topicsMeta(filter:{
          status:published
        }
        ){
          count
        }
        topics(filter:{
          status:published
        }){
          id
          thumbnailSmall{
            uri
            name
            id
          }
          createdAt
          title
          order
        }
      }
      user{
        createdAt
        name
        email
        id
        profilePic{
          uri
        }
        studentProfile{
          batch{
               id
              code
              type
              course{
                title
                id
              }
              studentsMeta{
                count
              }
              school{
                name
              }
              currentComponent {
                id
                enrollmentType
                latestSessionStatus
                currentTopic {
                  id
                  title
                  order
                  thumbnailSmall {
                    uri
                  }
                    }
              }
              createdAt
              updatedAt
          }
          parents{
            user{
              name
              email
              phone{
                number
                countryCode
              }
            }
          }
          grade
          school{
            name
          }
        }
      }
    }
  }
    `,
  type: 'postSaleStudentData/fetch',
  key: 'postSaleStudentData',
})

export const fetchMenteeMentorSessionData = async (userId,courseId) => duck.query({
  query:gql`
  {
    mentorMenteeSessions(filter: {and: [{menteeSession_some: {user_some: {id: "${userId}"}}},{course_some:{id:"${courseId}"}},{sessionStatus:completed}]}
        orderBy: sessionStartDate_DESC) {
      id   
      sessionStartDate    
      course { 
        title
      }
      topic {
        id
        title
        order
        createdAt
      }
      sessionStatus
      menteeSession {
        id  
        bookingDate  
        user {
          id        
          name
        }
      }
      salesOperation {
        leadStatus
      }
    }
  }`,
  type: 'menteeMentorSessionData/fetch',
  key: 'menteeMentorSessionData',
})

export const fetchCoursesAllotedData  = async (studentId,courseId) => duck.query({
  query: gql`
  {
    mentorMenteeSessions(filter: {and: [{menteeSession_some: {user_some: {id_in: ["${studentId}"]}}},{course_some:{id:"${courseId}"}}]}
        orderBy: sessionStartDate_DESC) {
      id   
      isQuizSubmitted
      sessionStartDate       
      course { 
        title
        id
      }
      topic {
        id
        title
        order
        createdAt
      }
      sessionStatus
      menteeSession {
        id  
        bookingDate  
        user {
          id        
          name
        }
      }
      salesOperation {
        leadStatus
      }
    }
  }`,
  type: 'fetchCoursesAllotedData/fetch',
  key: 'fetchCoursesAllotedData',
})

export const fetchUserProfileData = async(userId) => duck.query({
  query:gql`
  {
    userProfiles(filter: {and: [{user_some: {id: "${userId}"}}]}) {
      user{
        id
        name
      }
      proficientTopics{
       title
      }   
      topicsCompleted
      familiarTopics{
        title
      }
      masteredTopics{
        title
      }
      totalTopics{
        title
      }
      createdAt
      totalTopicsMeta {
        count
      }
    }
  }`,
  type: 'fetchUserProfileData/fetch',
  key: 'fetchUserProfileData',
})

export const fetchSessionReportsData = (studentId,courseId) => duck.query({
  query: gql`
  {
    mentorMenteeSessions(filter: {and: [
      {
        menteeSession_some:{
          user_some:{
            id: "${studentId}"
          }
        }
      }
      {
        course_some:{
          id: "${courseId}"
        }
      }
    ]}) {
      id
      course{
        id
        title
        secondaryCategory
        topics(filter:{status:published}){
          id
          order
          title
          thumbnailSmall{
            uri
          }
        }
      }
      topic {
        id
        title
        order
        thumbnailSmall{
          uri
        }
      }
      sessionStatus
      sessionStartDate
      sessionEndDate
      menteeSession {
        id
        bookingDate
        slot0
        slot1
        slot2
        slot3
        slot4
        slot5
        slot6
        slot7
        slot8
        slot9
        slot10
        slot11
        slot12
        slot13
        slot14
        slot15
        slot16
        slot17
        slot18
        slot19
        slot20
        slot21
        slot22
        slot23
        user {
          id
          name
        }
      }
      mentorSession{
        user{
          id
          name
          profilePic{
            uri
          }
          gender
          mentorProfile{
            pythonCourseRating5
            pythonCourseRating4
            pythonCourseRating3
            pythonCourseRating2
            pythonCourseRating1
          }
        }
      }
    }
  }`,
  type: 'sessionReportsData/fetch',
  key: 'sessionReportsData',
})

export const fetchSessionTopicData = (studentId,courseId,topicId) => duck.query({
  query: gql`
  {
    mentorMenteeSessions(filter: {and: [
      {
        menteeSession_some:{
          user_some:{
            id: "${studentId}"
          }
        }
      }
      {
        course_some:{
          id: "${courseId}"
        }
      }
      {
        topic_some:{
          id:"${topicId}"
        }
      }
    ]}) {
      id
      course{
        id
        title
        secondaryCategory
        topics(filter:{status:published}){
          id
          order
          title
          thumbnailSmall{
            uri
          }
        }
      }
      topic {
        id
        title
        order
        thumbnailSmall{
          uri
        }
      }
      sessionStatus
      sessionStartDate
      sessionEndDate
      menteeSession {
        id
        bookingDate
        slot0
        slot1
        slot2
        slot3
        slot4
        slot5
        slot6
        slot7
        slot8
        slot9
        slot10
        slot11
        slot12
        slot13
        slot14
        slot15
        slot16
        slot17
        slot18
        slot19
        slot20
        slot21
        slot22
        slot23
        user {
          id
          name
        }
      }
      mentorSession{
        user{
          id
          name
          gender
          mentorProfile{
            pythonCourseRating5
            pythonCourseRating4
            pythonCourseRating3
            pythonCourseRating2
            pythonCourseRating1
          }
        }
      }
    }
  }`,
  type: 'sessionTopicData/fetch',
  key: 'sessionTopicData',
})

export const fetchUserQuizData = (studentId,topicId) => duck.query({
  query: gql`
  {
    userQuizReports(filter:{
      and:[
      {
        user_some:{
          id: "${studentId}"
        }
      }
      {
        topic_some:{
          id: "${topicId}"
        }
      }
    ]
    }){
      createdAt
      quizReport{
        totalQuestionCount
        correctQuestionCount
        inCorrectQuestionCount
        unansweredQuestionCount
      }
    }
  }
  `,
  type: 'userQuizData/fetch',
  key: 'userQuizData',
})

export const fetchBatchSessionData = (batchId) => duck.query({
  query: gql`
  {
    batchSessions(filter: {and: [{batch_some: {id: "${batchId}"}}]}) {
      id
      createdAt
      topic {
        id
        title
        order
      }
      sessionStartDate
      sessionEndDate
      sessionRecordingLink
      sessionCommentByMentor
      sessionStatus
      bookingDate
      slot0
      slot1
      slot2
      slot3
      slot4
      slot5
      slot6
      slot7
      slot8
      slot9
      slot10
      slot11
      slot12
      slot13
      slot14
      slot15
      slot16
      slot17
      slot18
      slot19
      slot20
      slot21
      slot22
      slot23
      mentorSession{
        user{
          name
          gender
          profilePic{
            uri
          }
          mentorProfile{
            pythonCourseRating5
            pythonCourseRating4
            pythonCourseRating3
            pythonCourseRating2
            pythonCourseRating1
          }
        }
      }
      attendance {
        student {
          id
          user {
            id
            username
            name
          }
        }
        status
      }
    }
  }`
  ,
  type: 'batchSessionData/fetch',
  key: 'batchSessionData',
})

export const fetchPaymentReportsData = async(clientId)=>duck.query({
  query:gql`
  {
    salesOperations(filter: {and: [{leadStatus: won},{client_some: {id: "${clientId}"}}]}, orderBy: createdAt_DESC) {
      id
      createdAt
      leadStatus
      enrollmentType
      course {
        id
        title
      }
      userPaymentPlan {
        id
        salesOperation {
          id
        }
        user {
          id
          name
        }
        userPaymentInstallments(orderBy: dueDate_ASC) {
          id
          userPaymentPlan {
            id
          }
          userPaymentLink {
            id
          }
          amount
          dueDate
          paidDate
          lastPaymentRequestedDate
          paymentRequestedCount
          status
          isPaymentRequested
        }
        sessionsPerMonth
        installmentType
        installmentNumber
        productPrice
        finalSellingPrice
        dateOfEnrollment
        comment
      }
    }
  }
  `,
  type:'paymentReportsData/fetch',
  key:'paymentReportsData'
})