import gql from 'graphql-tag'
import duck from '../../duck'

const DELETE_LEAD_PARTNERS = ({ id }) => gql`
  mutation{
      deleteLeadPartner(id:"${id}"){
        id
      }
  }
`
const deleteLeadPartners = async ({ id }) => duck.query({
  query: DELETE_LEAD_PARTNERS({ id }),
  type: 'leadPartners/delete',
  key: 'leadPartners',
})

export default deleteLeadPartners
