import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import withNav from '../../components/withNav'
import injectProps from '../../components/injectProps'
import CoursePackageSingle from './CoursePackageSingle';

const CoursePackageSingleNav = withNav(CoursePackageSingle)({
  title: 'Course Package Maker',
  activeNavItem: 'Course Package Maker',
  showCourseMakerNavigation: true,
})

const mapStateToProps = (state) => ({
  fetchCoursePackageStatus: state.data.getIn(['coursePackage', 'fetchStatus', 'coursePackage']),
  coursePackage: state.data.getIn(['coursePackage', 'data']),
  updateCoursePackageStatus: state.data.getIn(['coursePackages', 'updateStatus', 'coursePackages']),
  packageUpdateFailure: state.data.getIn(['errors', 'coursePackages/update']),
  deleteCoursePackagesStatus: state.data.getIn(['coursePackages', 'deleteStatus', 'coursePackages']),
  packageDeleteFailure: state.data.getIn(['errors', 'coursePackages/delete']),
  teachers: state.data.getIn(['teachers', 'data']),
  fetchTeachersStatus: state.data.getIn(['teachers', 'fetchStatus', 'teachers']),
  addBatchStatus: state.data.getIn(['batch', 'addStatus', 'batches']),
  updateBatchStatus: state.data.getIn(['batch', 'updateStatus', 'batch']),
  deleteBatchStatus: state.data.getIn(['batch', 'deleteStatus', 'deleteBatch']),
})

const CoursePackageSingleNavWithExtraProps = injectProps({
  notification,
})(CoursePackageSingleNav)

export default connect(mapStateToProps)(withRouter(CoursePackageSingleNavWithExtraProps))
