import gql from 'graphql-tag'
import duck from '../../duck'
import getSlotNames from '../../utils/slots/slot-names'

const getFilters = (userIds, customFilters) => {
  // let filter = '{or:[{batchSessions_some:{
  // batch_exists:true}},{mentorMenteeSessions_some:{menteeSession_exists:true}}]},'
  let filter = ''
  if (customFilters) {
    filter += customFilters
  }
  if (userIds) {
    filter += `{user_some:{id_in:[${userIds}]}}`
  }

  return filter
}

const fetchMentorSessions = async (
  userIds,
  filters = null,
  key = null,
) => {
  let isTeacherTraining = window.location.pathname.includes('/teacherTraining')
  let query = gql`
  query {
    mentorSessions(
      filter: { and: [${getFilters(userIds, filters)}] }
  ) {
          id
          course {
            id
            title
          }
          createdAt
          availabilityDate
          sessionType
          user {
            id
            name
            profile: mentorProfile {
              sessionLink
            }
          }
          ${getSlotNames()}
          batchSessions{
            topic {
              id
              order
              title
              thumbnailSmall {
                uri
              }
            }
            course {
              id
              title
            }
            batch{
                customSessionLink
                id
                type
                code
                currentComponent {
                  enrollmentType
                }
                school {
                  name
                }
                classes {
                  id
                }
                campaign {
                  id
                }
                students {
                  id
                  userData: user {
                    id
                    name
                  }
                }
              }
              id
              sessionStartDate
              sessionEndDate
              sessionRecordingLink
              isFeedbackSubmitted
              bookingDate
              sessionCommentByMentor
              ${getSlotNames()}
              sessionStatus
              attendance{
                student{
                  id
                  mentor { id }
                  user{
                    id
                    name
                  }
                }
                isPresent
                status
              }
          }
          adhocSessions(filter: {
            ${isTeacherTraining ? 'type: teacherTraining' : 'type_not: teacherTraining'}
          }) {
              previousTopic {
                order
                title
                thumbnailSmall {
                  uri
                }
              }
              course {
                id
                title
              }
              title
              batch{
                type
                code
                school {
                  name
                  id
                  code
                }
                students {
                  id
                  userData: user {
                    id
                    name
                  }
                }
              }
              id
              type
              sessionStartDate
              sessionEndDate
              sessionRecordingLink
              bookingDate
              sessionCommentByMentor
              ${getSlotNames()}
              sessionStatus
              attendance{
                student{
                  id
                  user{
                    id
                    name
                  }
                }
                isPresent
                status
              }
          }
          mentorMenteeSessions{
              id
              salesOperationData: salesOperation {
                id
              }
              topic {
                order
                title
                description
                thumbnailSmall {
                  uri
                }
              }
              course {
                id
                title
              }
              sessionRecordingLink
              isFeedbackSubmitted
              hasRescheduled
              sessionStatus
              sendSessionLink
              sessionStartDate
              sessionEndDate
              menteeSession{
                id
                bookingDate
                user {
                    id
                    name
                    studentProfile { id mentor { id } }
                }
                ${getSlotNames()}
              }
          }
  }
}
  `
  if (isTeacherTraining) {
    query = gql`{mentorSessions(
      filter: { and: [${getFilters(userIds, filters)}] }
  ) {
          id
          course {
            id
            title
          }
          createdAt
          availabilityDate
          sessionType
          user {
            id
            name
            profile: mentorProfile {
              sessionLink
            }
          }
          ${getSlotNames()}
          adhocSessions {
              previousTopic {
                order
                title
                thumbnailSmall {
                  uri
                }
              }
              course {
                id
                title
              }
              title
              batch{
                type
                code
                school {
                  name
                  id
                  code
                }
                students {
                  id
                  userData: user {
                    id
                    name
                  }
                }
              }
              id
              type
              sessionStartDate
              sessionEndDate
              sessionRecordingLink
              bookingDate
              sessionCommentByMentor
              ${getSlotNames()}
              sessionStatus
              attendance{
                student{
                  id
                  user{
                    id
                    name
                  }
                }
                isPresent
                status
              }
          }
  }
}`
  }
  return duck.query({
    query: query,
    type: 'mentorSessions/fetch',
    key: key || 'mentorSessions',
    changeExtractedData: (extractedData, originalData) => {
      if (originalData.mentorSessions) {
        extractedData.mentorSessions = originalData.mentorSessions
      }
      if (originalData.mentorFilters) {
        extractedData.mentorFilters = originalData.mentorFilters
      }
      if (originalData.mentorSessions && originalData.mentorSessions.length) {
        return extractedData
      }
      return {
        ...extractedData,
        mentorSessions: [],
      }
    }
  })
}

export default fetchMentorSessions
