import React from "react"

const CrossIcons = () => (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3762 7.94737C14.3762 11.8652 11.2002 15.0412 7.28233 15.0412C3.3645 15.0412 0.188477 11.8652 0.188477 7.94737C0.188477 4.02954 3.3645 0.853516 7.28233 0.853516C11.2002 0.853516 14.3762 4.02954 14.3762 7.94737ZM5.13263 5.79768C5.3404 5.58991 5.67727 5.58991 5.88504 5.79768L7.28231 7.19494L8.67955 5.7977C8.88733 5.58992 9.2242 5.58992 9.43197 5.7977C9.63974 6.00547 9.63974 6.34234 9.43197 6.55011L8.03472 7.94736L9.43196 9.34459C9.63973 9.55237 9.63973 9.88924 9.43196 10.097C9.22418 10.3048 8.88731 10.3048 8.67954 10.097L7.28231 8.69978L5.88506 10.097C5.67728 10.3048 5.34042 10.3048 5.13264 10.097C4.92487 9.88925 4.92487 9.55238 5.13264 9.34461L6.52989 7.94736L5.13263 6.5501C4.92485 6.34232 4.92485 6.00546 5.13263 5.79768Z" fill="#BA7C00"/>
    </svg>
)

export {
    CrossIcons
}