import gql from 'graphql-tag';
import { get } from 'lodash';
import uploadFile from '../../actions/utils/uploadFile';
import duck from '../../duck';

const addCoursePackage = async (input, coursesConnectIds, thumbnailFile, bannerThumbnailFile) => duck.query({
    query: gql`
    mutation($input: CoursePackageInput!){
        addCoursePackage(input: $input, 
            ${coursesConnectIds ? `coursesConnectIds: ${coursesConnectIds}`: ''}
            ){  
                title
                id
                topics{
                    topic{
                      title
                    }
                  }
                  courses{
                    title
                  }
            }
    }`,
    variables: {
        input
    },
    type: 'coursePackage/add',
    key: 'coursePackage',
    changeExtractedData: (extractedData, originalData) => {
        let coursePackage = get(originalData, 'addCoursePackage.id')
        if (coursePackage) {
            (async function imageUpload() {
            try {
                if (thumbnailFile) {
                    await uploadFile(thumbnailFile, {
                        fileBucket: 'python'
                    }, {
                        typeField: 'thumbnail', typeId: coursePackage, type: 'CoursePackage',
                    })
                }
                if (bannerThumbnailFile) {
                    await uploadFile(bannerThumbnailFile, {
                        fileBucket: 'python'
                    }, {
                        typeField: 'bannerThumbnail', typeId: coursePackage, type: 'CoursePackage',
                    })
                }
            } catch (error) {
                console.log(error)
            }
        }())
        }
        return {...extractedData}
    }
})

export default addCoursePackage