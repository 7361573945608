import gql from 'graphql-tag'
import duck from '../../duck'

const addMentorMenteeSessionForDemand = async ({
  menteeSessionConnectId, mentorSessionConnectId,
  courseConnectId, topicConnectId }) => (
  duck.query({
    query: gql`
       mutation {
    addMentorMenteeSession(
        input: { sessionStatus: allotted }
        menteeSessionConnectId: "${menteeSessionConnectId}"
        mentorSessionConnectId: "${mentorSessionConnectId}"
        courseConnectId: "${courseConnectId}"
        topicConnectId: "${topicConnectId}"
    ) {
        id
        sessionCommentByMentor
        createdAt
        salesOperation {
        id
        leadStatus
        enrollmentType
        }
        course {
        id
        title
        thumbnail {
            uri
        }
        secondaryCategory
        topics(filter: { status: published }) {
            id
            order
            title
            thumbnailSmall {
            uri
            }
        }
        }
        topic {
        id
        title
        order
        }
        sessionStatus
        sessionStartDate
        sessionEndDate
        sessionRecordingLink
        isFeedbackSubmitted
        menteeSession {
        id
        bookingDate
        slot0
        slot1
        slot2
        slot3
        slot4
        slot5
        slot6
        slot7
        slot8
        slot9
        slot10
        slot11
        slot12
        slot13
        slot14
        slot15
        slot16
        slot17
        slot18
        slot19
        slot20
        slot21
        slot22
        slot23
        user {
            id
            name
        }
        }
        mentorSession {
            availabilityDate
            sessionType
            user {
              id
              name
              profile: mentorProfile {
                sessionLink
              }
            }
        }
    }
}
     `,
    type: 'mentorSessions/update',
    key: 'updateMentorMenteeSession',
    changeExtractedData: (extractedData) => {
      extractedData.course = []
      extractedData.menteeSession = []
      extractedData.salesOperation = []
      extractedData.topic = []
      extractedData.user = []
      return { ...extractedData }
    },
  })
)

export default addMentorMenteeSessionForDemand
