import gql from 'graphql-tag'
import duck from '../../../duck'

const fetchPracticeQuestions = async (learningObjectiveId) =>
  duck.query({
    query: gql`
    {
        questionBanks(filter: {and: [{learningObjectives_some: {id: "${learningObjectiveId}"}}, {assessmentType: practiceQuestion}]}, orderBy: createdAt_DESC){
            id
            order
            status
            statement
            hint
            questionType
            assessmentType
            questionLayoutType
            blockLayoutType
            difficulty
            answerCodeSnippet
            questionCodeSnippet
            explanation
            mcqOptions {
              statement
              isCorrect
              blocksJSON
              initialXML
              questionBankImage {
                id
                image {
                  uri
                }
              }
            }
            fibBlocksOptions {
              displayOrder
              statement
              correctPositions
            }
            fibInputOptions {
              correctPosition
              answers
            }
            arrangeOptions {
              displayOrder
              statement
              correctPosition
            }
          }
    }
    `,
    type: 'questionBanks/fetch',
    key: 'questionBanks/learningSlides',
  })

export default fetchPracticeQuestions

