import React from 'react'
import { Input } from 'antd'
import MainModal from '../../../../components/MainModal'

function IframePreview({
    openIframePreview = false,
    onClose,
    width,
    onSubmit,
    source,
    handleChange,
    showInput = true,
    fullWidth = false,
}) {
  
  const widthIframe = fullWidth ? '100%' : `${width}px`
  return (
    <MainModal
        visible={openIframePreview}
        title={'Docs Preview'}
        onCancel={onClose}
        maskClosable={false}
        width='80%'
        height='90vh'
        wrapClassName='iframe-preview-modal'
        centered
        destroyOnClose
        footer={null}
      >
        {showInput ? <div style={{ marginBottom: '20px' }}>
              <span>Enter Document width:</span>
              <Input type={'number'} value={width} onChange={({ target:{ value } }) => handleChange(value)} style={{ width: '30%', margin: '0 20px' }} />
            </div> : null
           }
      
      <iframe
        style={{ height: '100%', width: widthIframe,border: 'none' }}
        id='externalDescriptionForPractice'
        src={source}
        title='External description'
      />
    </MainModal>
  )
}

export default IframePreview