import gql from 'graphql-tag'
import duck from '../../duck'

const fetchClassroomCoursePackages = async (grade) =>
  await duck.query({
    query: gql`{
        coursePackages(
            filter: {
              and: [
                { packageType_not: teacherTraining },
                { status: published },
                { and: [{ minGrade_lte: ${grade} }, { maxGrade_gte: ${grade} }] }
              ]
            }
        ) {
            id
            title
            internalName
            packageType
            topicsData: topics {
              topic {
                id
                order
                courses {
                  id
                  title
                }
              }
            }
            coursesData: courses {
                id
                title
                tools { value }
                theory { value }
                programming { value }
            }
        }
    }
    `,
    type: 'coursePackages/fetch',
    key: 'coursePackages',
})

export default fetchClassroomCoursePackages
