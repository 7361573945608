import gql from "graphql-tag";

import duck from "../../duck";

const fetchInspectors = async () =>
	duck.query({
		query: gql`
			query {
				inspectors: users(filter: { role_not_in: [mentee, mentor, parent] }) {
					id
					name
				}
			}
		`,
		type: "inspectors/fetch",
		key: "inspectors",
	});

export default fetchInspectors;
