import gql from 'graphql-tag'
import { get } from 'lodash';
import duck from '../../duck';
import uploadFile from '../utils/uploadFile';
import removeFromEventAction from './removeFromEventAction';


const updateEventJoinReason = async (id, input, imageUrl, prevImageId) => {
    return duck.query({
        query: gql`
           mutation($input: EventJoinReasonUpdate) {
             updateEventJoinReason(id: "${id}", input: $input) {
                id
              }
            }
              `,
        variables: {
            input: input
        },
        type: 'eventJoinReasons/update',
        key: 'eventJoinReasons',
        changeExtractedData: (extracted, original) => {
            const eventJoinReasonId = get(original, 'updateEventJoinReason.id')
            if (imageUrl && typeof imageUrl !== 'string') {
                (async function imageUpload() {
                    try {
                        if (prevImageId) {
                            await removeFromEventAction('', prevImageId, eventJoinReasonId, 'reason')
                        }
                        await uploadFile(imageUrl, { fileBucket: 'python' }, {
                            typeField: 'picture', typeId: eventJoinReasonId, type: 'EventJoinReason'
                        })
                    } catch (error) {
                        console.log(error)
                    }
                }())
            }
            return { ...extracted }
        }
    })
}

export default updateEventJoinReason