import gql from 'graphql-tag'
import duck from '../../duck'
  
const fetchWorkingDaySchedule = async (schoolId) => duck.query({
    query: gql`
    query{
        timetableSchedulesMeta(
            filter: { and: [{ school_some: { id: "${schoolId}" } }, { type: workingDay }] }
        ) {
            count
        }
      }
    `,
    type: 'timeTableSchedulesMeta/fetch',
    key: 'workingDayTimeTableScheduleMeta'
  })
  
export default fetchWorkingDaySchedule
