import React from "react";
import { Popover } from "antd";
const columnInfo = (validations) => {
  return (
    <ul>
      {validations.map((item,index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
};
function HeaderValidation({
  headerTitle,
  validations,

}) {
  return (
    <div className="ant-header-title">
      <span>{headerTitle}</span>
      <Popover overlayClassName="upload-students-table-popover" content={columnInfo(validations)} trigger={["click"]}>
        <svg
          width={15}
          height={15}
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </Popover>
    </div>
  );
}

export default HeaderValidation;
