import React, { useEffect, useState } from 'react'
import { Select} from 'antd'
import { get } from 'lodash'
import { Popconfirm} from 'antd'
import { RemoveContent } from '../../../../../../constants/icons'
import { slideContentType,gridLayoutType } from '../../../../../../constants/learningSlideConstants'
import { Main, SlideContentHolder, GridSlideText,
  StyledSelect, SlideTypeHolder,CrossHolder } from './GridSelector.style'
import Image from '../Components/SelectedComponents/Image/Image'
import Video from '../Components/SelectedComponents/Video/Video'
import CodeSyntax from './SelectedComponents/CodeSyntax/CodeSyntax'
import Text from './SelectedComponents/Text/Text'
import CodeEditor from './SelectedComponents/CodeEditor/CodeEditor'

const { Option } = Select

const GridSelector = (props) => {
  const [slideOption, setSlideOption] = useState('')
  const [selectedContent, setSelectedContent] = useState({})
  
  const contentType = [
    {
      key: 'text',
      title:'Text',
    },
    {
      key: 'image',
      title:'Image',
    },
    {
      key: 'video',
      title:'Video',
    },
    {
      key: 'code',
      title:'Code Editor',
    },
    {
      key: 'codeSyntax',
      title:'Code Syntax',
    },
    // {
    //   key: 'iframeEmbed',
    //   title:'Iframe Embed,
    // },

  ]
  // const findIndex=()=>{
  //   if(props.number==='00') {
  //     return 0
  //   } else if(props.number==='01') {
  //     return 1
  //   } else  if(props.number==='10') {
  //     return 2
  //   } else  if(props.number==='11') {
  //     return 3
  //   }  else if (get(props.learningSlide,'layoutType','')===gridLayoutType.nRowSpan) {
  //     return Number(props.number.split("0")[0])
  //   }
  // }
  useEffect(() => {
    if (props.slideContents && props.slideContents.length>0) {
      const array = props.slideContents
      const content = array.filter(el => get(el, 'gridPlacement') === props.number)[0]
      setSelectedContent(content)
    }
  }, [props.slideContents])
  const selectedOption = () => {
    const Type=get(selectedContent, 'type','')
    if (Type === slideContentType.image) {
      return <Image key={`${get(props.learningSlide, 'order')}${props.number}`} number={props.number} gridPlacement={props.number} learningSlide={props.learningSlide} selectedContent={selectedContent} setSelectedContent={setSelectedContent} slideContents={props.slideContents} Helper={props.Helper}/>
    } else if (Type === slideContentType.video) {
      return <Video key={props.number} number={props.number} learningSlide={props.learningSlide} selectedContent={selectedContent} setSelectedContent={setSelectedContent} slideContents={props.slideContents} Helper={props.Helper}/>
    } else if (Type === slideContentType.codeSyntax) {
      return <CodeSyntax number={props.number}
        key={selectedContent.gridPlacement}
        learningSlide={props.learningSlide}
        selectedContent={selectedContent}
        // update={(value) => {
        //       setSelectedContent({ ...selectedContent, codeInput: value })
        //   }
        //   }
        setSelectedContent={setSelectedContent}
        slideContents={props.slideContents}
        Helper={props.Helper}
      />
    } else if (Type === slideContentType.text) {
      return <Text number={props.number} key={selectedContent.gridPlacement} componentId={props.componentId} learningSlide={props.learningSlide} selectedContent={selectedContent} setSelectedContent={setSelectedContent} slideContents={props.slideContents} Helper={props.Helper}/>
    } else if (Type === slideContentType.code) {
      return <CodeEditor number={props.number} key={selectedContent.gridPlacement} componentId={props.componentId} learningSlide={props.learningSlide} selectedContent={selectedContent} setSelectedContent={setSelectedContent}  slideContents={props.slideContents} Helper={props.Helper}/>
    }
  }
  const handleSlideOption = async(value) => {
    await props.Helper(value,'type','',props.slideContents,props.number)
    if (value === slideContentType.image || value === slideContentType.video) {
    } else if(value === slideContentType.code) {
    }
    setSlideOption(get(selectedContent, 'type'))
  }
  return <Main>
      <SlideContentHolder>
          <GridSlideText>Grid Slide Option</GridSlideText>
                 <StyledSelect onChange={(value) => { handleSlideOption(value) }} value={get(selectedContent, 'type', '')}>
                   {
                     contentType.map((obj)=>{
                       return  <Option value={obj.key}>{obj.title}</Option>
                     })
                   }
                 </StyledSelect>
      </SlideContentHolder>
        <SlideTypeHolder>
          {(get(props,'GridStyle','') && get(props,'GridStyle','') === 'nRowSpan' && get(props,'Index','') !==0) ?
          <Popconfirm
          title='Do you want to delete this Slide?'
          placement='topRight'
          onConfirm={() => { props.InvokeDelete(props.Index, props.SelectedObj) }}
          okText='Yes'
          cancelText='Cancel'
          key='delete'
          overlayClassName='popconfirm-overlay-primary'
        >
         <CrossHolder >
           <RemoveContent/>
           </CrossHolder>
           </Popconfirm> :<></>
          }
              {selectedOption()}
        </SlideTypeHolder>
         </Main>
}
export default GridSelector
