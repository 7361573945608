import React, { useState } from "react";
import {
  Button,
  DatePicker,
  Input,
  Modal,
  Select,
  Typography,
  Table,
} from "antd";
import styled from "styled-components";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import Creatable from "react-select/creatable";

import Label from "../../../../components/Label";
import Stack from "../../../../components/Stack";
import { useFormContext } from "./FormContext";
import getDataFromLocalStorage from "../../../../utils/extract-from-localStorage";
import { get } from "lodash";

const StartInspectionModal = ({
  open,
  handleClose,
  labs,
  inspectors,
  labInspections,
}) => {
  const { activeStep } = useFormContext();

  let content;

  switch (activeStep) {
    case 1:
      content = <Step1 labs={labs} inspectors={inspectors} />;
      break;
    case 2:
      content = <Step2 />;
      break;
    case 3:
      content = (
        <Step3 labInspections={labInspections} handleClose={handleClose} />
      );
      break;
    default:
      content = <Step1 labs={labs} inspectors={inspectors} />;
  }

  return (
    <Modal
      title="Start Inspection"
      visible={open}
      onCancel={handleClose}
      centered
      footer={null}
    >
      <Stack gap={24}>{content}</Stack>
    </Modal>
  );
};

const Step1 = ({ labs, inspectors }) => {
  const { setActiveStep, formData, setFormData } = useFormContext();

  const loggedInUserId = getDataFromLocalStorage("login.id");
  const defaultInspector = inspectors.find((i) => i.id === loggedInUserId);

  return (
    <Formik
      initialValues={{
        lab: "",
        inspector: get(defaultInspector, "id", ""),
        inspectionDate: "",
        // schoolCoordinator: "",
        // coordinatorEmail: "",
      }}
      validationSchema={Yup.object({
        lab: Yup.object().required("Required"),
        inspector: Yup.string().required("Required"),
        inspectionDate: Yup.date().required("Required"),
        // schoolCoordinator: Yup.string().required("Required"),
        // coordinatorEmail: Yup.string()
        //   .email("Invalid email address")
        //   .required("Required"),
      })}
      onSubmit={(values) => {
        const data = { ...formData, ...values };
        setActiveStep(2);
        setFormData(data);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (
        <Form>
          <Stack gap={24}>
            <Stack gap={11}>
              <Label>Select Lab</Label>
              <Creatable
                options={labs}
                isClearable
                onChange={(newValue) => setFieldValue("lab", newValue)}
                value={values.lab}
              />
            </Stack>

            <Stack direction="row" justify="space-between" gap={24}>
              <Stack gap={11} style={{ flex: "1 1 0" }}>
                <Label>Inspection By</Label>
                <Select
                  placeholder="Select Inspector"
                  size="large"
                  name="inspector"
                  onChange={(newValue) => setFieldValue("inspector", newValue)}
                  // defaultValue={{label: defaultInspector.name, value: defaultInspector.id}}
                  value={values.inspector || get(defaultInspector, "id")}
                >
                  {inspectors.map((inspector) => (
                    <Select.Option key={inspector.id} value={inspector.id}>
                      {inspector.name}
                    </Select.Option>
                  ))}
                </Select>
              </Stack>

              <Stack gap={11} style={{ flex: "1 1 0" }}>
                <Label>Inspection Date</Label>
                <DatePicker
                  size="large"
                  name="inspectionDate"
                  onChange={(date) => setFieldValue("inspectionDate", date)}
                  value={values.inspectionDate}
                />
              </Stack>
            </Stack>

            {/* <Stack gap={11}>
              <Label>School Coordinator</Label>
              <Input
                placeholder="School Coordinator"
                size="large"
                name="schoolCoordinator"
                onChange={handleChange}
                value={values.schoolCoordinator}
              />
            </Stack>

            <Stack gap={11}>
              <Label>Coordinator Email</Label>
              <Input
                placeholder="Coordinator Email"
                size="large"
                name="coordinatorEmail"
                onChange={handleChange}
                value={values.coordinatorEmail}
              />
            </Stack> */}

            <Button block type="primary" size="large" htmlType="submit">
              Next
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

const Step2 = () => {
  const {
    setActiveStep,
    setFormData,
    formData,
    addLabInspectionHandler,
    addLabInspectionLoading,
  } = useFormContext();

  return (
    <Formik
      initialValues={{
        totalComputers: "",
        workingComputers: "",
        interactivePanel: "",
        speakers: "",
        powerBackup: "",
        powerBackupType: "",
        internetMode: "",
      }}
      validationSchema={Yup.object({
        totalComputers: Yup.number().required("Required"),
        workingComputers: Yup.number().required("Required"),
        interactivePanel: Yup.string().required("Required"),
        speakers: Yup.string().required("Required"),
        powerBackup: Yup.string().required("Required"),
        powerBackupType: Yup.string().required("Required"),
        internetMode: Yup.string().required("Required"),
      })}
      onSubmit={async (values, { resetForm }) => {
        const data = { ...formData, ...values };
        setFormData(data);
        await addLabInspectionHandler(values);
        resetForm();
        setActiveStep(3);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
      }) => (
        <Form>
          <Stack gap={24}>
            <Stack direction="row" justify="space-between" gap={24}>
              <Stack gap={11} style={{ flex: "1 1 0" }}>
                <Label>Total Number of Computers</Label>
                <Input
                  placeholder="Total Number of Computers"
                  size="large"
                  type="number"
                  name="totalComputers"
                  onChange={handleChange}
                  value={values.totalComputers}
                />
              </Stack>

              <Stack gap={11} style={{ flex: "1 1 0" }}>
                <Label>Number of Working Computers</Label>
                <Input
                  placeholder="Number of working computers"
                  size="large"
                  type="number"
                  name="workingComputers"
                  onChange={handleChange}
                  value={values.workingComputers}
                />
              </Stack>
            </Stack>

            <Stack gap={11}>
              <Label>Project Interactive Panel</Label>
              <Select
                placeholder="Select Project Interactive Panel"
                size="large"
                name="interactivePanel"
                onChange={(value) => setFieldValue("interactivePanel", value)}
                value={values.interactivePanel}
              >
                <Select.Option selected value="smartBoard">
                  Smart Board
                </Select.Option>
                <Select.Option value="projector">Projector</Select.Option>
                <Select.Option value="none">None</Select.Option>
              </Select>
            </Stack>

            <Stack gap={11} style={{ flex: "1 1 0" }}>
              <Label>Speakers</Label>
              <Select
                placeholder="Select Speakers"
                size="large"
                name="speakers"
                onChange={(value) => setFieldValue("speakers", value)}
                value={values.speakers}
              >
                <Select.Option value="centralisedSpeaker">
                  Centralized Speaker
                </Select.Option>
                <Select.Option value="headphones">Headphones</Select.Option>
                <Select.Option value="none">None</Select.Option>
              </Select>
            </Stack>

            <Stack direction="row" justify="space-between" gap={24}>
              <Stack gap={11} style={{ flex: "1 1 0" }}>
                <Label>Power Backup</Label>
                <Select
                  placeholder="Select Power Backup"
                  size="large"
                  name="powerBackup"
                  onChange={(value) => setFieldValue("powerBackup", value)}
                  value={values.powerBackup}
                >
                  <Select.Option value="yes">Yes</Select.Option>
                  <Select.Option value="no">No</Select.Option>
                  <Select.Option value="partial">Partial</Select.Option>
                </Select>
              </Stack>

              <Stack gap={11} style={{ flex: "1 1 0" }}>
                <Label>Power Backup Type</Label>
                <Select
                  placeholder="Select Power Backup Type"
                  size="large"
                  name="powerBackupType"
                  onChange={(value) => setFieldValue("powerBackupType", value)}
                  value={values.powerBackupType}
                >
                  <Select.Option value="centralised">Central</Select.Option>
                  <Select.Option value="individual">Individual</Select.Option>
                  <Select.Option value="none">None</Select.Option>
                </Select>
              </Stack>
            </Stack>

            <Stack gap={11}>
              <Label>Internet Mode</Label>
              <Select
                placeholder="Select Internet Mode"
                size="large"
                name="internetMode"
                onChange={(value) => setFieldValue("internetMode", value)}
                value={values.internetMode}
              >
                <Select.Option value="hotspot">Hotspot</Select.Option>
                <Select.Option value="wifi">WiFi</Select.Option>
                <Select.Option value="lan">LAN</Select.Option>
                <Select.Option value="none">None</Select.Option>
              </Select>
            </Stack>

            <Stack direction="row" gap={10}>
              <Button
                block
                type="secondary"
                size="large"
                onClick={() => setActiveStep(1)}
              >
                Back
              </Button>

              <Button
                block
                type="primary"
                htmlType="submit"
                size="large"
                loading={addLabInspectionLoading}
              >
                Next
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

const Step3 = ({ labInspections, handleClose }) => {
  const { formData, setActiveStep } = useFormContext();

  // extract labName from formData
  const labName = formData.lab.label;

  // extract systems from labInspections based on labName
  let filteredLabInspections;
  [filteredLabInspections] = labInspections.filter(
    (labInspection) => labInspection.labName === labName
  );

  const numberOfWorkingComputers = formData.workingComputers || 0;
  const inspectedComputers = filteredLabInspections
    ? filteredLabInspections.systems.length
    : 0;
  const remainingComputers = numberOfWorkingComputers - inspectedComputers;

  const closeButtonClickHandler = () => {
    setActiveStep(1);
    handleClose();
  };

  return (
    <Stack gap={24}>
      <Stack direction="row" gap={56}>
        <Stack gap={11}>
          <GrayText>Total Computers</GrayText>
          <NumberText>{numberOfWorkingComputers}</NumberText>
        </Stack>

        <Stack gap={11}>
          <GrayText>Inspected</GrayText>
          <NumberText>{inspectedComputers}</NumberText>
        </Stack>

        <Stack gap={11}>
          <GrayText>Remaining</GrayText>
          <NumberText>{remainingComputers}</NumberText>
        </Stack>
      </Stack>

      {/* simple table for serial number and status */}
      <Table
        dataSource={
          filteredLabInspections ? filteredLabInspections.systems : []
        }
        bordered
        pagination={false}
        scroll={{ y: 400 }}
        style={{
          borderRadius: 6,
        }}
        columns={[
          {
            title: "Serial No",
            dataIndex: "serialNo",
            align: "center",
          },
          {
            title: "Status",
            dataIndex: "status",
            align: "center",
            render: (text) => {
              if (text === "compatible") {
                return (
                  <Typography.Text
                    style={{
                      color: "#01AA93",
                      fontWeight: 500,
                    }}
                  >
                    Qualified
                  </Typography.Text>
                );
              }

              return (
                <Typography.Text
                  style={{
                    color: "#D34B57",
                    fontWeight: 500,
                  }}
                >
                  Failed
                </Typography.Text>
              );
            },
          },
        ]}
      />

      <Button
        block
        size="large"
        type="primary"
        onClick={closeButtonClickHandler}
      >
        Close
      </Button>
    </Stack>
  );
};

const GrayText = styled(Typography.Text)`
  color: #858585 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
`;

const NumberText = styled(Typography.Text)`
  color: #333333 !important;
  font-size: 20px !important;
  font-weight: 600 !important;
`;

export default StartInspectionModal;
