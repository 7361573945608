import gql from 'graphql-tag'
import duck from '../../duck'

const addB2B2CBatchSession = async (courseId, bookingDate,
  selectedSlot, mentorSessionConnectId, batchId, firstTopicId) => (
  duck.query({
    query: gql`
      mutation{
        addBatchSession(input: {
          bookingDate: "${bookingDate}",
          ${selectedSlot}: true
        },
          batchConnectId: "${batchId}",
          topicConnectId: "${firstTopicId}",
          ${mentorSessionConnectId ? `mentorSessionConnectId:"${mentorSessionConnectId}"` : ''},
          ${courseId ? `courseConnectId: "${courseId}"` : ''}) {
          id
        }
      }
  `,
    type: 'batchSession/add',
    key: 'batchSession',
  })
)

export default addB2B2CBatchSession
