import {
  UMS,
  CMS,
  SMS,
  UMS_ADMIN,
  ADMIN,
  MENTOR,
  CMS_ADMIN,
  UMS_VIEWER,
  CMS_VIEWER,
  SALES_EXECUTIVE,
  TRANSFORMATION_TEAM,
  TRANSFORMATION_ADMIN,
  SCHOOL_ADMIN,
  COURSE_MAKER,
  CONTENT_MAKER,
  PRE_SALES,
  POST_SALES,
  AUDIT_ADMIN,
  AUDITOR,
  BDE,
  BDE_ADMIN,
  SUPPLY_DEMAND_ADMIN,
  SUPPLY_MANAGER,
  DEMAND_MANAGER,
  LEAD_PARTNER,
  SENSEI,
} from '../../constants/roles'

const roleToSystemMap = {
  [CMS_ADMIN]: [CMS, COURSE_MAKER, CONTENT_MAKER],
  [CMS_VIEWER]: [CMS, COURSE_MAKER, CONTENT_MAKER],
  [ADMIN]: [UMS, CMS, SMS, COURSE_MAKER, CONTENT_MAKER],
  [UMS_ADMIN]: [UMS, SMS],
  [MENTOR]: [UMS, SMS],
  [UMS_VIEWER]: [UMS, SMS],
  [SALES_EXECUTIVE]: [UMS, SMS],
  [TRANSFORMATION_TEAM]: [UMS],
  [TRANSFORMATION_ADMIN]: [UMS],
  [SCHOOL_ADMIN]: [SMS],
  [PRE_SALES]: [UMS],
  [POST_SALES]: [UMS],
  [AUDIT_ADMIN]: [UMS],
  [AUDITOR]: [UMS],
  [BDE]: [SMS],
  [BDE_ADMIN]: [SMS],
  [SUPPLY_DEMAND_ADMIN]: [UMS],
  [SUPPLY_MANAGER]: [UMS],
  [DEMAND_MANAGER]: [UMS],
  [LEAD_PARTNER]: [UMS],
  [SENSEI]: [UMS, SMS]
}

export default roleToSystemMap
