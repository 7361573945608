/* eslint-disable max-len */
import { Pagination, Select, Modal, Button, notification, Tooltip, Popconfirm, Input } from 'antd'
import React, { Component } from 'react'
import moment from 'moment'
import { get, sortBy } from 'lodash'
import addMentorSession from '../../actions/assignTime/addMentorSession'
import withNav from '../../components/withNav'
import checkMentorSession from '../../actions/assignTime/checkMentorSession'
import fetchBatchCodes from '../../actions/assignTime/fetchBatchCodes'
import fetchBatchSessions from '../../actions/assignTime/fetchBatchSession'
import fetchLastSessionOfBatch from '../../actions/assignTime/fetchLastSessionOfBatch'
import fetchTopicId from '../../actions/assignTime/fetchTopicId'
import appConfig from '../../config/appConfig'
import offsetDate from '../../utils/date/date-offset'
import getSlotLabel from '../../utils/slots/slot-label'
import SessionTimeModalStyle from '../SessionManagement/components/SessionTimeModal/SessionTimeModal.style'
import changedatetoisostring from './common-util/changeDateToISOString'
import TableOfAssignTime from './components'
import addBatchSession from '../../actions/assignTime/addBatchSession'
import updateMentorSession from '../../actions/assignTime/updateMentorSession'
import updateBatchSession from '../../actions/assignTime/updateBatchSession'
import getDataFromLocalStorage from '../../utils/extract-from-localStorage'
import { MENTOR, SALES_EXECUTIVE, SENSEI } from '../../constants/roles'
import fetchMentorsList from '../../actions/salesExecProfiles/fetchMentorsList'
import fetchBatchMentor from '../../actions/assignTime/fetchBatchMentor'
import fetchCourses from '../../actions/assignTime/fetchCourses'
import addAdhocSession from '../../actions/assignTime/addAdhocSession'
import AdhocSessionType from '../../constants/adhocSessionTypes'
import updateAdhocSession from '../../actions/assignTime/updateAdhocSession'
import { ALLOWED_ROLES_MANUAL_SESSIONS, TIME_SUB_FOR_SESSION } from '../../constants/manualSessionsConfig'
import { getHoursDiff } from '../../utils/getSlotDifference'
import fetchMentorForSalesExec from '../../actions/sessions/fetchMentorForSales'

class AssignTimeTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      batchName: [],
      paramBatchCode: this.props.match.params.code,
      currentBatchCode: this.props.match.params.code,
      currentBatchId: null,
      modalVisible: false,
      confirmLoadingModal: false,
      actiontype: null,
      batchSessionsData: [],
      adhocSessionsData: [],
      showTimeSlots: false,
      topic: [],
      dateSelected: new Date(),
      slotsSelected: [],
      topicOrder: [],
      topicId: [],
      topicSelected: '',
      adhocSessionTypeSelected: 'revision',
      mentorUserId: '',
      prevslotsinfo: [],
      fetchedMentors: [],
      currentMentorId: null,
      editingSession: false,
      sessionToEdit: {},
      completedsessionerror: false,
      currentPage: 1,
      perPageQueries: 20,
      defaultSelectedValue: null,
      coursesList: [],
      selectedCourse: '',
      orderToFetch: 1,
      selectedMentorId: '',
      title: '',
      isAddingPastSession: false,
      documentType: ''
    }
  }
  componentDidMount() {
    if (this.state.paramBatchCode) {
      fetchBatchSessions(this.state.paramBatchCode, {
        page: this.state.currentPage,
        perPage: this.state.perPageQueries,
      })
    }
    const savedRole = getDataFromLocalStorage('login.role')
    const id = getDataFromLocalStorage('login.id')
    if (getDataFromLocalStorage('login.role') === MENTOR) {
      fetchBatchMentor(this.state.currentBatchCode)
      this.setState(
        {
          mentorUserId: id,
          currentMentorId: id,
        },
        () => fetchBatchCodes(id, '{type : normal}')
      )
    } else if (savedRole && savedRole === SALES_EXECUTIVE) {
      fetchBatchMentor(this.state.currentBatchCode)
      fetchMentorForSalesExec(id, true).then((res) => {
        const mentorsFiltered = res.user.salesExecutiveProfile.mentors.map(({ user }) => user)
        this.setState({
          fetchedMentors: mentorsFiltered,
        })
      })
    } else if (savedRole && savedRole === SENSEI) {
      fetchBatchMentor(this.state.currentBatchCode)
      fetchMentorForSalesExec(id).then((res) => {
        const mentorsFiltered = res.user.senseiProfile.mentors.map(({ user }) => user)
        this.setState({
          fetchedMentors: mentorsFiltered,
        })
      })
    } else {
      fetchBatchMentor(this.state.currentBatchCode)
      fetchMentorsList()
    }
    fetchCourses()
  }
  componentDidUpdate(prevProps) {
    const {
      batchSessionsData,
      adhocSessionsData,
      batches,
      topic,
      topicOrder,
      isFetchingTopicId,
      isFetchingCheckMentorSession,
      previousslotsinfo,
      fetchedCheckMentorSession,
      addFailure,
      addAdhocFailure,
      addErrors,
      addAdhocErrors,
      updateFailure,
      updateAdhocFailure,
      updateErrors,
      updateAdhocErrors,
      mentors,
      mentorSessionId,
      mentorSessionAddFailure,
      mentorSessionAddError,
      mentorOfBatch,
      deleteErrors,
      deleteAdhocErrors,
      deleteFailure,
      deleteAdhocFailure,
      mentorSessionUpdateFailure,
      mentorSessionUpdateError,
      courseFetchingStatus,
      coursePackageTopics,
      batch
    } = this.props
    if (prevProps.batch !== batch) {
      const documentTypeTemp = get(batch.toJS(), '[0].documentType', '')
      this.setState({ documentType: documentTypeTemp })
    }
    if (
      this.state.paramBatchCode &&
      prevProps.isFetchingMentorOfBatch &&
      this.props.hasFetchedMentorOfBatch
    ) {
      const mentorId = !mentorOfBatch
        ? null
        : get(mentorOfBatch.toJS(), '[0].allottedMentor.id')
      const batchId = !mentorOfBatch ? null : mentorOfBatch.toJS()[0].id
      const courseFromBatch = !mentorOfBatch
        ? null
        : get(mentorOfBatch.toJS(), '[0].course.id')
      this.setState(
        {
          mentorUserId: mentorId,
          currentMentorId: mentorId,
          selectedMentorId: mentorId,
          currentBatchId: batchId,
          selectedCourse: courseFromBatch,
        },
        () => fetchLastSessionOfBatch(this.state.currentBatchId)
      )
    }
    if (
      prevProps.isFetchingBatchSessions &&
      this.props.hasFetchedBatchSessions
    ) {
      const batchesFiltered = !batchSessionsData
        ? []
        : batchSessionsData.toJS()
      const adhocSessionsFiltered = !adhocSessionsData ? [] : adhocSessionsData.toJS()
      this.setState({
        batchSessionsData: batchesFiltered,
        adhocSessionsData: adhocSessionsFiltered,
        topic: topic ? topic.toJS() : [],
      })
    }
    if (prevProps.coursePackageTopics !== coursePackageTopics) {
      const coursePackageTopicsAll = this.getCoursePackageTopicsInOrder(coursePackageTopics)
      if (coursePackageTopicsAll && coursePackageTopicsAll.length > 0) {
        this.setState({
          topicId: coursePackageTopicsAll,
          defaultSelectedValue: coursePackageTopicsAll[0].id,
          topicSelected: coursePackageTopicsAll[0].id,
        })
      }
      else {
        this.setState({
          topicId: [],
          defaultSelectedValue: '',
          topicSelected: '',
        })
      }
    }
    if (prevProps.isFetchingBatches && this.props.hasFetchedBatches) {
      this.setState({
        batchName: batches ? batches.toJS() : [],
      })
    }
    if (
      prevProps.isFetchingLastSessionOfBatch &&
      this.props.hasFetchedLastSessionOfBatch
    ) {
      const topicsFiltered = !topicOrder ? [] : topicOrder.toJS()
      this.setState({
        topicOrder: topicsFiltered,
      })
    }
    if (prevProps.isFetchingMentors && this.props.hasFetchedMentors) {
      let mentorsFiltered = !mentors ? [] : mentors.toJS()
      const savedRole = getDataFromLocalStorage('login.role')
      if (savedRole && savedRole === MENTOR) {
        mentorsFiltered = mentorsFiltered.filter(
          (item) => get(item, 'mentorProfile')
        )
        this.setState({
          fetchedMentors: mentorsFiltered,
        })
      } else if (savedRole && savedRole !== SALES_EXECUTIVE && savedRole !== SENSEI) {
        this.setState({
          fetchedMentors: mentorsFiltered,
        })
      }
    }
    if (!isFetchingTopicId && prevProps.isFetchingTopicId) {
      let topicSelected = ''
      const { sessionToEdit, selectedCourse } = this.state
      if ((this.state.actiontype === 'edit' || this.state.actiontype === 'editAdhoc') && selectedCourse === get(sessionToEdit, 'course.id')) {
        topicSelected = get(sessionToEdit, 'topic')
          ? get(sessionToEdit, 'topic.id')
          : get(sessionToEdit, 'previousTopic.id')
      } else {
        topicSelected = this.props.topicId && this.props.topicId.toJS().length ? this.props.topicId.toJS()[0].id : null
      }
      if (coursePackageTopics && coursePackageTopics.toJS().length === 0) {
        this.setState({
          topicId: this.props.topicId ? this.props.topicId.toJS() : [],
          defaultSelectedValue: this.props.topicId
            ? this.props.topicId.toJS()[0].id
            : null,
          topicSelected
        })
      }
    }
    if (
      !isFetchingCheckMentorSession &&
      prevProps.isFetchingCheckMentorSession
    ) {
      this.setState({
        prevslotsinfo: previousslotsinfo ? previousslotsinfo.toJS() : [],
      })
    }
    if (fetchedCheckMentorSession !== prevProps.fetchedCheckMentorSession) {
      if (fetchedCheckMentorSession) {
        this.setState({ showTimeSlots: true })
      }
    }
    if (prevProps.isAddingMentorSession && this.props.hasAddedMentorSession) {
      const {
        editingSession,
        slotsSelected,
        dateSelected,
        sessionToEdit,
      } = this.state
      const slotStatus = []
      for (let i = 0; i < appConfig.timeSlots.length; i += 1) {
        if (editingSession) {
          if (
            this.setHoursZero(dateSelected) > this.setHoursZero(new Date()) ||
            (this.setHoursZero(dateSelected) ===
              this.setHoursZero(new Date()) &&
              appConfig.timeSlots[i] > new Date().getHours())
          ) {
            if (slotsSelected.includes(appConfig.timeSlots[i])) {
              slotStatus.push(true)
            } else if (
              !slotsSelected.includes(appConfig.timeSlots[i]) &&
              sessionToEdit[`slot${appConfig.timeSlots[i]}`] === true
            ) {
              slotStatus.push(false)
            } else {
              slotStatus.push('noAction')
            }
          } else {
            slotStatus.push('noAction')
          }
        } else if (!editingSession) {
          if (slotsSelected.includes(appConfig.timeSlots[i])) {
            slotStatus.push(true)
          } else {
            slotStatus.push('noAction')
          }
        }
      }
      if (this.state.actiontype === 'add' || this.state.actiontype === 'addAdhoc') {
        const slotStatusObj = this.getSlotStatusObject(slotStatus)
        const bookingDate = new Date(dateSelected).toISOString()
        const addInput = {
          bookingDate,
          ...slotStatusObj,
        }
        if (this.state.actiontype === 'addAdhoc') {
          addInput.type = this.state.adhocSessionTypeSelected
          addInput.order = 1
          if (this.state.adhocSessionTypeSelected === 'teacherTraining') {
            addInput.title = this.state.title
            addAdhocSession(
              addInput,
              this.state.currentBatchId,
              null,
              mentorSessionId.toJS()[0].id,
              null,
            )
          } else {
            addAdhocSession(
              addInput,
              this.state.currentBatchId,
              this.state.topicSelected,
              mentorSessionId.toJS()[0].id,
              this.state.selectedCourse,
            )
          }
        } else {
          addBatchSession(
            addInput,
            this.state.currentBatchId,
            this.state.topicSelected,
            mentorSessionId.toJS()[0].id,
            this.state.selectedCourse
          )
        }
      } else if (this.state.actiontype === 'edit' || this.state.actiontype === 'editAdhoc') {
        const { bookingDate } = this.state.sessionToEdit
        const selectedDate = new Date(bookingDate).toISOString()
        const slots = {}
        for (const property in this.state.sessionToEdit) {
          if (property.startsWith('slot')) {
            if (this.state.sessionToEdit[property] === true) {
              slots[property] = true
            } else {
              slots[property] = false
            }
          }
        }
        const editBatchSessionInput = {
          bookingDate: selectedDate,
          ...slots,
        }
        // TODO : connect mentor to update batch call
        if (this.state.actiontype === 'editAdhoc') {
          editBatchSessionInput.type = this.state.adhocSessionTypeSelected
          editBatchSessionInput.title = this.state.title
          updateAdhocSession(
            sessionToEdit.id,
            editBatchSessionInput,
            this.state.topicSelected,
            this.state.selectedCourse,
            mentorSessionId.toJS()[0].id,
          )
        } else {
          updateBatchSession(
            sessionToEdit.id,
            editBatchSessionInput,
            this.state.topicSelected,
            this.state.selectedCourse,
            mentorSessionId.toJS()[0].id
          )
        }
      }
    }
    if (prevProps.isAddingBatchSession && this.props.hasAddedBatchSession) {
      notification.success({
        message: 'Batch session added successfully!',
      })
      this.handleModalCancel()
      this.setState(
        {
          topicSelected: '',
          slotsSelected: [],
        },
        () => {
          fetchBatchSessions(this.state.currentBatchCode, {
            page: this.state.currentPage,
            perPage: this.state.perPageQueries,
          })
          fetchLastSessionOfBatch(this.state.currentBatchId)
        }
      )
    }
    
    if (prevProps.isAddingAdhocSession && this.props.hasAddedAdhocSession) {
      notification.success({
        message: 'Adhoc session added successfully!',
      })
      this.handleModalCancel()
      this.setState(
        {
          topicSelected: '',
          slotsSelected: [],
        },
        () => {
          fetchBatchSessions(this.state.currentBatchCode, {
            page: this.state.currentPage,
            perPage: this.state.perPageQueries,
          })
          fetchLastSessionOfBatch(this.state.currentBatchId)
        }
      )
    }
    if (prevProps.isDeletingBatchSession && this.props.hasDeletedBatchSession) {
      notification.success({
        message: 'Batch session deleted successfully!',
      })
      fetchBatchSessions(this.state.currentBatchCode, {
        page: this.state.currentPage,
        perPage: this.state.perPageQueries,
      })
      fetchLastSessionOfBatch(this.state.currentBatchId)
    }
    if (prevProps.isDeletingAdhocSession && this.props.hasDeletedAdhocSession) {
      notification.success({
        message: 'Adhoc session deleted successfully!'
      })
      fetchBatchSessions(this.state.currentBatchCode, {
        page: this.state.currentPage,
        perPage: this.state.perPageQueries
      })
      fetchLastSessionOfBatch(this.state.currentBatchId)
    }

    if (addFailure !== prevProps.addFailure || addAdhocFailure !== prevProps.addAdhocFailure) {
      if (addFailure) {
        const currentError = addErrors.pop()
        notification.error({
          message:
            currentError.error.errors &&
            currentError.error.errors[0] &&
            currentError.error.errors[0].message,
        })
      } else if (addAdhocFailure) {
        const currentError = addAdhocErrors.pop()
        notification.error({
          message: currentError.error.errors &&
            currentError.error.errors[0] &&
            currentError.error.errors[0].message
        })
      }
    }

    if (prevProps.isUpdatingBatchSession && this.props.hasUpdatedBatchSession) {
      notification.success({
        message: 'Updated Batch Session Successfully',
      })
      this.handleModalCancel()
      fetchBatchSessions(this.state.currentBatchCode, {
        page: this.state.currentPage,
        perPage: this.state.perPageQueries,
      })
      fetchLastSessionOfBatch(this.state.currentBatchId)
    }
    if (prevProps.isUpdatingAdhocSession && this.props.hasUpdatedAdhocSession) {
      notification.success({
        message: 'Updated Adhoc Session Successfully'
      })
      this.handleModalCancel()
      fetchBatchSessions(this.state.currentBatchCode, {
        page: this.state.currentPage,
        perPage: this.state.perPageQueries
      })
      fetchLastSessionOfBatch(this.state.currentBatchId)
    }
    if (updateFailure !== prevProps.updateFailure || updateAdhocFailure !== prevProps.updateAdhocFailure) {
      if (updateFailure) {
        const currentError = updateErrors.pop()
        notification.error({
          message: currentError.error.errors &&
            currentError.error.errors[0] &&
            currentError.error.errors[0].message
        })
      }
      if (updateAdhocFailure) {
        const currentError = updateAdhocErrors.pop()
        notification.error({
          message: currentError.error.errors &&
            currentError.error.errors[0] &&
            currentError.error.errors[0].message
        })
      }
    }
    if (deleteFailure !== prevProps.deleteFailure
      || deleteAdhocFailure !== prevProps.deleteAdhocFailure) {
      if (deleteFailure) {
        const currentError = deleteErrors.pop()
        notification.error({
          message:
            currentError.error.errors &&
            currentError.error.errors[0] &&
            currentError.error.errors[0].message,
        })
      }
      if (deleteAdhocFailure) {
        const currentError = deleteAdhocErrors.pop()
        notification.error({
          message:
            currentError.error.errors &&
            currentError.error.errors[0] &&
            currentError.error.errors[0].message,
        })
      }
    }
    if (mentorSessionAddFailure !== prevProps.mentorSessionAddFailure) {
      if (mentorSessionAddFailure) {
        const currentError = mentorSessionAddError.pop()
        notification.error({
          message: currentError.error.errors &&
            currentError.error.errors[0] &&
            currentError.error.errors[0].message
        })
      }
    }
    if (mentorSessionUpdateFailure !== prevProps.mentorSessionUpdateFailure) {
      if (mentorSessionUpdateFailure) {
        const currentError = mentorSessionUpdateError.pop()
        notification.error({
          message: currentError.error.errors &&
            currentError.error.errors[0] &&
            currentError.error.errors[0].message
        })
      }
    }
    if ((courseFetchingStatus && !get(courseFetchingStatus.toJS(), 'loading')
      && get(courseFetchingStatus.toJS(), 'success') &&
      (prevProps.courseFetchingStatus !== courseFetchingStatus))) {
      this.setState({
        coursesList: this.props.courses && this.props.courses.toJS()
      })
    }
  }

  getCoursePackageTopicsInOrder = (coursePackageTopics) => {
    let finalArray = []
    const allTopics = coursePackageTopics && coursePackageTopics.toJS()
    if (allTopics.length > 0) {
      allTopics.forEach((item, index) => {
        let obj = {}
        if (!item.isRevision) {
          obj.id = get(item, 'topic.id')
          obj.title = get(item, 'topic.title')
          obj.order = item.order
        } else {
          obj.id = `id_${index}`
          obj.title = item.title
          obj.order = item.previousTopicOrder
        }
        finalArray.push(obj)
      })
      return finalArray.sort((a, b) => a.order - b.order)
    } else {
      return []
    }
  }

  onPageChange = (page) => {
    this.setState(
      {
        currentPage: page,
      },
      () =>
        fetchBatchSessions(this.state.currentBatchCode, {
          page: this.state.currentPage,
          perPage: this.state.perPageQueries,
        })
    )
  }

  handleBatchChange = (value) => {
    const batch = this.props.batches
      .toJS()
      .filter((item) => item.code === value)
    this.setState(
      {
        currentBatchCode: value,
        currentBatchId: batch[0].id,
      },
      () => {
        fetchBatchSessions(this.state.currentBatchCode, {
          page: this.state.currentPage,
          perPage: this.state.perPageQueries,
        })
        fetchLastSessionOfBatch(this.state.currentBatchId)
        window.history.pushState({}, '', `/ums/assignTimetable/${value}`)
      }
    )
  }

  handleTopicChange = (value) => {
    this.setState({
      topicSelected: value,
    })
  }
  
  // handle topic change from dropdown in modal, set order
  handleAdhocSessionTypeChange = (value) => {
    this.setState({
      adhocSessionTypeSelected: value,
    })
  }

  handleMentorChange = (value) => {
    this.setState(
      {
        currentMentorId: value,
        batchName: [],
        currentBatchCode: '',
      },
      () => fetchBatchCodes(value, '{type : normal}')
    )
  }

  handleModalCancel = () => {
    this.setState({
      modalVisible: false,
      completedsessionerror: false,
      dateSelected: new Date(),
      prevslotsinfo: [],
      slotsSelected: [],
      topicSelected: '',
      selectedMentorId: '',
      adhocSessionTypeSelected: 'revision',
    })
  }

  showModal = (actiontype, data) => {
    const { batchSessionsData, coursesList } = this.state
    let orderToFetch = 1
    const completedSession = sortBy(batchSessionsData, 'createdAt').filter(session => get(session, 'sessionStatus') === 'completed')
    if (
      actiontype === 'edit' &&
      this.state.topicOrder &&
      this.state.topicOrder[0]
    ) {
      orderToFetch = this.state.topicOrder[0].order
    } else if (
      actiontype === 'add' &&
      this.state.topicOrder &&
      this.state.topicOrder[0]
    ) {
      if (batchSessionsData.length === 0) {
        orderToFetch = this.state.topicOrder[0].order
      } else if (this.state.topicOrder[0].order > get(completedSession.slice(-1)[0], 'topic.order', 0)) {
        orderToFetch = this.state.topicOrder[0].order
      } else {
        orderToFetch = get(completedSession.slice(-1)[0], 'topic.order')
      }
    } else if (actiontype === 'addAdhoc' || actiontype === 'editAdhoc') {
      /** for adding adhoc sessions we have to get completed session topic order */
      orderToFetch = 1
    }
    this.setState({
      orderToFetch
    })
    this.setState(
      {
        actiontype,
        modalVisible: true,
        mentorUserId:
          this.state.batchSessionsData
            && this.state.batchSessionsData[0]
            ? this.state.batchSessionsData[0].batch.allottedMentor.id
            : this.state.currentMentorId,
      },
      () => {
        let date
        if (this.state.actiontype === 'edit' || this.state.actiontype === 'editAdhoc') {
          date = this.state.sessionToEdit.bookingDate
        } else {
          date = this.state.dateSelected
        }
        this.setState({
          dateSelected: new Date(date)
        }, () => checkMentorSession(get(data, 'mentorSession.user.id', this.state.mentorUserId), new Date(date).toISOString()))
      }
    )
    if (actiontype === 'edit' || actiontype === 'editAdhoc') {
      this.setState({
        sessionToEdit: data,
        showTimeSlots: true,
        editingSession: true,
        selectedMentorId: get(data, 'mentorSession.user.id', this.state.currentMentorId)
      }, () => {
        const { sessionToEdit } = this.state
        let courseId = get(coursesList, '[0].id')
        if (get(sessionToEdit, 'course.id')) {
          courseId = get(sessionToEdit, 'course.id')
        }
        const topicSelected = get(sessionToEdit, 'topic')
          ? get(sessionToEdit, 'topic.id')
          : get(sessionToEdit, 'previousTopic.id')
        this.setState({
          selectedCourse: courseId,
          topicSelected,
          adhocSessionTypeSelected: get(sessionToEdit, 'type', '')
        }, () => fetchTopicId(this.state.orderToFetch, this.state.selectedCourse))
      })
    } else {
      const { selectedCourse } = this.state
      let updatedSelectedCourse = ''
      if (selectedCourse) {
        updatedSelectedCourse = selectedCourse
      } else {
        updatedSelectedCourse = get(coursesList, '[0].id')
      }
      this.setState({
        editingSession: false,
        selectedCourse: updatedSelectedCourse,
        selectedMentorId: get(data, 'mentorSession.user.id', this.state.currentMentorId)
      }, () => fetchTopicId(this.state.orderToFetch, this.state.selectedCourse))
    }
  }

  getWeekDay = (day) => {
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat']
    return weekDays[day]
  }

  getMonthName = (month) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ]
    return months[month]
  }

  renderDateTablet = () => {
    const days = []
    for (let i = 0; i < appConfig.bookingDaysCutOff; i += 1) {
      days.push(offsetDate(new Date(), i, 'ADD'))
    }
    if (this.state.actiontype === 'add' || this.state.actiontype === 'addAdhoc') {
      return (
        <div>
          <h3 style={{ textAlign: 'center' }}>TimeTable</h3>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
              gridGap: '15px',
            }}
          >
            {days.map((day) => (
              <SessionTimeModalStyle.DateTablet
                onClick={() =>
                  this.setState(
                    {
                      dateSelected: day,
                      showTimeSlots: false,
                      slotsSelected: [],
                    },
                    () => {
                      checkMentorSession(
                        get(this.state.sessionToEdit, 'mentorSession.user.id', this.state.mentorUserId),
                        new Date(this.state.dateSelected).toISOString()
                      )
                    }
                  )
                }
                selected={
                  this.state.dateSelected.setHours(0, 0, 0, 0) ===
                  day.setHours(0, 0, 0, 0)
                }
              >
                <SessionTimeModalStyle.DateText colored>
                  {this.getWeekDay(day.getDay())}
                </SessionTimeModalStyle.DateText>
                <SessionTimeModalStyle.DateText>
                  {day.getDate()}
                </SessionTimeModalStyle.DateText>
                <SessionTimeModalStyle.DateText>
                  {this.getMonthName(day.getMonth())}
                </SessionTimeModalStyle.DateText>
                <SessionTimeModalStyle.Selected
                  selected={
                    this.state.dateSelected.setHours(0, 0, 0, 0) ===
                    day.setHours(0, 0, 0, 0)
                  }
                />
              </SessionTimeModalStyle.DateTablet>
            ))}
          </div>
        </div>
      )
    } else if (this.state.actiontype === 'edit' || this.state.actiontype === 'editAdhoc') {
      const dates = new Date(new Date(this.state.sessionToEdit.bookingDate).setHours(0, 0, 0, 0))
      return (
        <div>
          <h3 style={{ textAlign: 'center' }}>TimeTable(edit)</h3>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
              gridGap: '15px',
            }}
          >
            {days.map((day) => (
              <SessionTimeModalStyle.DateTablet
                onClick={() =>
                  this.setState(
                    (prevstate) => ({
                      dateSelected: day,
                      showTimeSlots: false,
                      slotsSelected: [],
                      sessionToEdit: {
                        ...prevstate.sessionToEdit,
                        bookingDate: changedatetoisostring(offsetDate(day, 1, 'ADD'))
                      },
                    }),
                    () => {
                      checkMentorSession(
                        get(this.state.sessionToEdit, 'mentorSession.user.id', this.state.mentorUserId),
                        new Date(this.state.dateSelected).toISOString()
                      )
                    }
                  )
                }
                selected={
                  moment(day).isSame(dates)
                }
              >
                <SessionTimeModalStyle.DateText colored>
                  {this.getWeekDay(day.getDay())}
                </SessionTimeModalStyle.DateText>
                <SessionTimeModalStyle.DateText>
                  {day.getDate()}
                </SessionTimeModalStyle.DateText>
                <SessionTimeModalStyle.DateText>
                  {this.getMonthName(day.getMonth())}
                </SessionTimeModalStyle.DateText>
                <SessionTimeModalStyle.Selected
                  selected={
                    moment(day).isSame(dates)
                  }
                />
              </SessionTimeModalStyle.DateTablet>
            ))}
          </div>
        </div>
      )
    } else if (this.state.actiontype === 'delete') {
      return (
        <h1 style={{ textAlign: 'center' }}>Are sure you want delete it?</h1>
      )
    } else if (this.state.completedsessionerror === true) {
      return <h1>Cannot edit completed session</h1>
    }
  }
  isSlotSelected = (slotNumber) => {
    const { sessionToEdit, editingSession } = this.state
    if (sessionToEdit && editingSession) {
      return sessionToEdit[`slot${slotNumber}`] || false
    }
    if (this.state.actiontype === 'add'
      || this.state.actiontype === 'edit'
      || this.state.actiontype === 'addAdhoc'
      || this.state.actiontype === 'editAdhoc') {
      return this.state.slotsSelected.includes(slotNumber) || false
    }
    return false
  }
  isSlotBooked = (slotNumber) => {
    const { prevslotsinfo } = this.state
    if (prevslotsinfo.length > 0) {
      return prevslotsinfo[0][`slot${slotNumber}`] || false
    }
    return false
  }

  handleSlotClick = (slotNumber, isPastSlot) => {
    let curSelectedSlots = this.state.slotsSelected
    if (curSelectedSlots.includes(slotNumber)) {
      curSelectedSlots.splice(curSelectedSlots.indexOf(slotNumber), 1)
    } else {
      curSelectedSlots = []
      curSelectedSlots.push(slotNumber)
    }
    this.setState({
      slotsSelected: curSelectedSlots,
      isAddingPastSession: isPastSlot
    })
    if (this.state.actiontype === 'edit' || this.state.actiontype === 'editAdhoc') {
      const key = `slot${slotNumber}`
      if (key in this.state.sessionToEdit) {
        if (this.state.sessionToEdit[key] === true) {
          this.state.sessionToEdit[key] = false
        } else {
          for (let i = 0; i <= 23; i += 1) {
            this.state.sessionToEdit[`slot${i}`] = false
          }
          this.state.sessionToEdit[key] = true
        }
      }
    }
  }
  renderTimeSlots = () => {
    const renderSlots = []
    let action = 'add'
    let timeDiffValue = 0
    const savedRole = getDataFromLocalStorage('login.role')
    const { batchType, selectedTopicOrder } = this.state
    if (ALLOWED_ROLES_MANUAL_SESSIONS.includes(savedRole)) {
      action = 'subtract'
      timeDiffValue = TIME_SUB_FOR_SESSION
    }
    appConfig.timeSlots.forEach((slot, index) => {
      const sameDate = (this.state.actiontype === 'edit' || this.state.actiontype === 'editAdhoc') ? this.state.sessionToEdit.bookingDate : this.state.dateSelected
      let disableTime = (moment(moment(sameDate).set('hours', appConfig.timeSlots[index])).isBefore(moment()))
      if (batchType === 'b2b2c' && selectedTopicOrder === 1 && action !== 'add') {
        disableTime = (moment(moment(sameDate).set('hours', appConfig.timeSlots[index])).isBefore(moment().subtract(timeDiffValue, 'hour')))
      }
      const isSameDate = new Date().setHours(0, 0, 0, 0) === new Date(this.state.dateSelected).setHours(0, 0, 0, 0)
      const timeDiff = getHoursDiff(appConfig.timeSlots[index], sameDate)
      const isPassSession = (ALLOWED_ROLES_MANUAL_SESSIONS.includes(savedRole)
      && isSameDate && timeDiff)
      renderSlots.push(
        <SessionTimeModalStyle.Slot
          style={{ cursor: disableTime ? 'not-allowed' : 'pointer' }}
          selected={this.isSlotSelected(appConfig.timeSlots[index])}
          onClick={() => disableTime ? null : this.handleSlotClick(appConfig.timeSlots[index], isPassSession)}
          isBooked={this.isSlotBooked(appConfig.timeSlots[index])}
        >
          {`${getSlotLabel(appConfig.timeSlots[index]).startTime}`}
        </SessionTimeModalStyle.Slot>
      )
    })
    return renderSlots
  }
  getSlotStatusObject = (slotStatus) => {
    const slotStatusObj = {}
    for (let i = 0; i < appConfig.timeSlots.length; i += 1) {
      if (slotStatus[i] === true) {
        slotStatusObj[`slot${appConfig.timeSlots[i]}`] = true
      }
    }
    return slotStatusObj
  }
  setHoursZero = (date) => new Date(date).setHours(0, 0, 0, 0)
  onSave = () => {
    const { slotsSelected,
      dateSelected,
      sessionToEdit,
      selectedCourse,
      selectedMentorId,
      adhocSessionTypeSelected
    } = this.state
    const slotStatus = []
    for (let i = 0; i < appConfig.timeSlots.length; i += 1) {
      if (slotsSelected.includes(appConfig.timeSlots[i])) {
        slotStatus.push(true)
      } else {
        slotStatus.push('noAction')
      }
    }
    if (this.state.actiontype === 'add' || this.state.actiontype === 'addAdhoc') {
      const bookingDate = new Date(dateSelected).toISOString()
      if (this.state.prevslotsinfo.length === 0) {
        const slotStatusObj = this.getSlotStatusObject(slotStatus)
        const mentorId = !this.state.mentorUserId ? selectedMentorId : this.state.mentorUserId
        const addMentorSessionInput = {
          availabilityDate: bookingDate,
          ...slotStatusObj,
          sessionType: 'batch',
        }
        addMentorSession(
          addMentorSessionInput,
          mentorId,
          selectedCourse
        )
      } else if (this.state.prevslotsinfo.length > 0) {
        const slotStatusObj = this.getSlotStatusObject(slotStatus)
        const updateMentorSessionInput = {
          availabilityDate: bookingDate,
          ...slotStatusObj,
        }
        const addInput = {
          bookingDate,
          ...slotStatusObj,
        }
        updateMentorSession(
          updateMentorSessionInput,
          this.state.prevslotsinfo[0].id,
          selectedCourse
        ).then((res) => {
          if (res && res.updateMentorSession && res.updateMentorSession.id) {
            if (this.state.actiontype === 'addAdhoc') {
              addInput.type = adhocSessionTypeSelected
              if (adhocSessionTypeSelected === 'teacherTraining') {
                addInput.title = this.state.title
                addAdhocSession(
                  addInput,
                  this.state.currentBatchId,
                  null,
                  this.state.prevslotsinfo.map((d) => d.id)[0],
                  null,
                  this.state.title
                )
              } else {
                addAdhocSession(
                  addInput,
                  this.state.currentBatchId,
                  this.state.topicSelected,
                  this.state.prevslotsinfo.map((d) => d.id)[0],
                  this.state.selectedCourse,
                  this.state.title
                )
              }
            } else {
              addBatchSession(
                addInput,
                this.state.currentBatchId,
                this.state.topicSelected,
                this.state.prevslotsinfo.map((d) => d.id)[0],
                selectedCourse
              )
            }
          }
        })
      }
    } else if (this.state.actiontype === 'edit' || this.state.actiontype === 'editAdhoc') {
      const { bookingDate } = this.state.sessionToEdit
      const selectedDate = new Date(bookingDate).toISOString()
      const mentorId = !selectedMentorId ? this.state.mentorUserId : selectedMentorId
      if (this.state.prevslotsinfo.length === 0) {
        let slotStatusObj
        if (slotsSelected.length > 0) {
          slotStatusObj = this.getSlotStatusObject(slotStatus)
        } else {
          for (const property in this.state.sessionToEdit) {
            if (property.startsWith('slot')) {
              if (this.state.sessionToEdit[property] === true) {
                slotStatusObj = { ...slotStatusObj, [property]: true }
              }
            }
          }
        }
        const addMentorSessionInput = {
          availabilityDate: selectedDate,
          ...slotStatusObj,
          sessionType: 'batch',
        }
        // selectedMentorId is the one selected from modal
        // mentorUserId is the original mentor id
        // console.log(`Adding mentor session with mentor id: ${mentorId}`)
        addMentorSession(addMentorSessionInput, mentorId, selectedCourse)
        // console.log(`Added mentor session with mentor id: ${mentorId}`)
      } else if (this.state.prevslotsinfo.length > 0) {
        const slotStatusObj = this.getSlotStatusObject(slotStatus)
        const updateMentorSessionInput = {
          availabilityDate: selectedDate,
          ...slotStatusObj,
        }
        // console.log(`Updating mentor session with mentor id: ${mentorId} and mentorSessionId: ${this.state.prevslotsinfo[0].id}`)
        updateMentorSession(
          updateMentorSessionInput,
          this.state.prevslotsinfo[0].id,
          selectedCourse
        ).then((resp) => {
          if (resp && resp.updateMentorSession && resp.updateMentorSession.id) {
            const slots = {}
            for (const property in this.state.sessionToEdit) {
              if (property.startsWith('slot')) {
                if (this.state.sessionToEdit[property] === true) {
                  slots[property] = true
                } else {
                  slots[property] = false
                }
              }
            }
            const editBatchSessionInput = {
              bookingDate: selectedDate,
              ...slots,
            }
            // console.log(`updating batch session with mentorSession id: ${resp.updateMentorSession.id}`)
            if (this.state.actiontype === 'editAdhoc') {
              editBatchSessionInput.type = adhocSessionTypeSelected
              editBatchSessionInput.title = this.state.title
              updateAdhocSession(
                sessionToEdit.id,
                editBatchSessionInput,
                this.state.topicSelected,
                selectedCourse,
                resp.updateMentorSession.id
              )
            } else {
              updateBatchSession(
                sessionToEdit.id,
                editBatchSessionInput,
                this.state.topicSelected,
                selectedCourse,
                resp.updateMentorSession.id
              )
            }
          }
        })
      }
    }
  }
  editMentorAction = (id) => {
    const { dateSelected } = this.state
    this.setState({
      selectedMentorId: id
    }, () => checkMentorSession(id, new Date(dateSelected).toISOString()))
  }

  isSaving = () => {
    const { isAddingBatchSession, isUpdatingBatchSession,
      mentorSessionAddStatus, mentorSessionUpdateStatus,
      isAddingAdhocSession, isUpdatingAdhocSession } = this.props
    return isAddingBatchSession || isUpdatingBatchSession
      || isAddingAdhocSession || isUpdatingAdhocSession
      || (mentorSessionAddStatus && get(mentorSessionAddStatus.toJS(), 'loading'))
      || (mentorSessionUpdateStatus && get(mentorSessionUpdateStatus.toJS(), 'loading')) || false
  }
  render() {
    const { Option } = Select
    const {
      isFetchingBatchSessions,
      isAddingBatchSession,
      isDeletingBatchSession,
      isUpdatingBatchSession,
      hasAddedBatchSession,
      hasDeletedBatchSession,
      hasFetchedBatchSessions,
      hasUpdatedBatchSession,
      batchSessionsCount,
      isFetchingTopicId,
      coursePackageTopics
    } = this.props
    const { defaultSelectedValue, currentPage, perPageQueries,
      coursesList, selectedCourse, orderToFetch, selectedMentorId,
      dateSelected } = this.state
    const date = changedatetoisostring(
      offsetDate(dateSelected, 1, 'ADD')
    )
    const adhocSessionTypes = [
      {
        title: 'REVISION',
        value: 'revision'
      },
      {
        title: 'HOMEWORK',
        value: 'homework'
      },
      {
        title: 'TEACHER TRAINING',
        value: 'teacherTraining'
      }
    ]
    return (
      <div>
        <Modal
          title={
            this.state.actionType === 'delete'
              ? 'Delete Session'
              : 'Assign TimeTable'
          }
          visible={this.state.modalVisible}
          confirmLoading={this.state.confirmLoadingModal}
          onCancel={this.handleModalCancel}
          destroyOnClose='true'
          maskClosable={false}
          width='700px'
          footer={[
            (this.state.isAddingPastSession && this.state.slotsSelected.length) ? <Popconfirm
              title={`Are you sure you want to ${this.state.actiontype === 'edit' ? 'update' : 'add'} the session at time ${getSlotLabel(get(this.state.slotsSelected, '[0]')).startTime}`}
              placement='topRight'
              okText='Yes'
              onConfirm={this.onSave}
              cancelText='Cancel'
              key='toggle'
            >
                <Button key='submit'
                  type='primary'
                  loading={this.isSaving()}
                >
                      {' '}
                      {this.isSaving() ? 'Saving...' : 'SAVE'}
                </Button>
                                                                                  </Popconfirm> : <Button key='submit'
                                                                                    type='primary'
                                                                                    onClick={this.onSave}
                                                                                    loading={this.isSaving()}
                                                                                  >
              {' '}
              {this.isSaving() ? 'Saving...' : 'SAVE'}
                                                                                                  </Button>
          ]}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Select
              style={{ width: 200 }}
              defaultValue={defaultSelectedValue}
              value={this.state.topicSelected}
              loading={isFetchingTopicId && isFetchingTopicId}
              onChange={this.handleTopicChange}
              placeholder='Select Topic'
            >
              {this.state.topicId && this.state.topicId.map((option) => (
                <Option key={option.id} value={option.id}>
                  <Tooltip title={option.title}>
                    {option.order}. {option.title}
                  </Tooltip>
                </Option>
              ))}
            </Select>
            {coursePackageTopics && coursePackageTopics.toJS().length === 0 ? (
              <Select
                style={{ width: 200 }}
                showSearch
                placeholder='Select a Course'
                optionFilterProp='children'
                name='selectedCourse'
                value={selectedCourse}
                onChange={(value) => this.setState({ selectedCourse: value },
                  () => fetchTopicId(orderToFetch, this.state.selectedCourse))}
                filterOption={(input, option) =>
                  get(option, 'props.children.props.title')
                    ? get(option, 'props.children.props.title', '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                    : false
                }
              >
                {
                  coursesList && coursesList.map(({ title, id }) =>
                    <Option key={id}
                      value={id}
                    >
                      <Tooltip title={title}>
                        {title}
                      </Tooltip>
                    </Option>
                  )}
              </Select>
            ) : null }
            {getDataFromLocalStorage('login.role') !== MENTOR ? (
              <Select
                showSearch
                placeholder='Mentor Name'
                style={{ width: 200 }}
                value={selectedMentorId}
                onChange={(value) => this.editMentorAction(value)}
                filterOption={(inputValue, option) => (
                  option.props.children &&
                  option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                )}
              >
                {[...this.state.fetchedMentors].map((option) => (
                  <Option
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </Option>
                ))}
              </Select>
            ) : (
              <div />
            )}
            <div>
              <h2>
                {getDataFromLocalStorage('login.role') === MENTOR ? (
                  getDataFromLocalStorage('login.name')
                ) : (
                  <div />
                )}
              </h2>
            </div>
          </div>
          {
            this.state.actiontype === 'addAdhoc' || this.state.actiontype === 'editAdhoc' ?
              <div style={{ display: 'flex', marginTop: 10, marginBottom: 10, justifyContent: 'space-between' }}>
                <Select
                  style={{ width: 200 }}
                  value={this.state.adhocSessionTypeSelected}
                  onChange={this.handleAdhocSessionTypeChange}
                  placeholder='Select Type'
                >
                  {
                    adhocSessionTypes.map(option => (
                      <Option
                        key={option.title}
                        value={option.value}
                      >
                        <Tooltip title={option.title}>
                          {option.title}
                        </Tooltip>
                      </Option>
                    ))
                  }
                </Select>
                {this.state.adhocSessionTypeSelected === 'teacherTraining' && (
                  <>
                    <Input
                      style={{
                        width: 200,
                      }}
                      onChange={event => {
                        this.setState({ title: event.target.value })
                      }}
                      value={this.state.title}
                      title='Title'
                    />
                    <div style={{  width: 200 }} />
                  </>
                )}
              </div> : <div />
          }
          <SessionTimeModalStyle.DateTabletContainer>
            {this.renderDateTablet()}
          </SessionTimeModalStyle.DateTabletContainer>
          <SessionTimeModalStyle.DateTabletContainer>
            {this.state.showTimeSlots && this.renderTimeSlots()}
          </SessionTimeModalStyle.DateTabletContainer>
        </Modal>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '25px',
          }}
        >
          <div style={{ paddingLeft: '5px' }}>
            {getDataFromLocalStorage('login.role') !== MENTOR ? (
              <h4>Select Mentor</h4>
            ) : (
              <div />
            )}
            {getDataFromLocalStorage('login.role') !== MENTOR ? (
              <Select
                showSearch
                optionFilterProp='children'
                style={{ width: 200 }}
                placeholder='Select Mentor'
                value={this.state.currentMentorId}
                onChange={this.handleMentorChange}
                loading={this.props.isFetchingMentors}
                filterOption={(inputValue, option) => (
                  option.props.children &&
                  option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                )}
              >
                {[...this.state.fetchedMentors].map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            ) : (
              <div />
            )}
          </div>
          <div style={{ paddingLeft: '5px' }}>
            <h4>Select Batch</h4>
            <Select
              style={{ width: 200 }}
              placeholder='Select Batch'
              value={this.state.currentBatchCode}
              onChange={this.handleBatchChange}
              loading={this.props.isFetchingBatches}
            >
              {[...this.state.batchName].map((option) => (
                <Option key={option.code} value={option.code}>{option.code}</Option>
              ))}
            </Select>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '60%', marginLeft: 'auto' }}>
          {
            batchSessionsCount > perPageQueries && (
              <Pagination
                total={batchSessionsCount}
                onChange={this.onPageChange}
                current={currentPage}
                defaultPageSize={perPageQueries}
              />
            )
          }
          <h4>Total Sessions {batchSessionsCount || 0}</h4>
        </div>
        <TableOfAssignTime
          currentBatchCode={this.state.currentBatchCode}
          showModal={this.showModal}
          batchSessionsData={this.state.batchSessionsData}
          adhocSessionsData={this.state.adhocSessionsData}
          isFetchingBatchSessions={isFetchingBatchSessions}
          hasFetchedBatchSessions={hasFetchedBatchSessions}
          isAddingBatchSession={isAddingBatchSession}
          hasAddedBatchSession={hasAddedBatchSession}
          isUpdatingBatchSession={isUpdatingBatchSession}
          hasUpdatedBatchSession={hasUpdatedBatchSession}
          isDeletingBatchSession={isDeletingBatchSession}
          hasDeletedBatchSession={hasDeletedBatchSession}
          topic={this.state.topic}
          mentorUserId={this.state.mentorUserId}
          dateSelected={date}
          paramBatchCode={this.state.paramBatchCode}
          documentType={this.state.documentType}
        />
      </div>
    )
  }
}

export default withNav(AssignTimeTable)({
  title: 'Assign Timetable',
  activeNavItem: 'Assign Timetable',
  backRoute: '/ums/batchMapping',
  shouldBack: true,
  showUMSNavigation: true,
})
