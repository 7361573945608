import { PlusCircleOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import styled from 'styled-components'

const EventOverviewWrapper = styled.div`
    background: #FFFFFF;
    padding: 15px;
    overFlow: scroll;
    font-family: Inter;
`

const Content = styled.div`
    h3, h4 {
        color: #575757;
    }
    color: #a3a3a3;
`

const HeadingContainer = styled.div`
    display: flex;
    .heading-icon {
        background: #575757;
        color: #fff;
        border-radius: 50%;
        border: none;
        outline: none;
    }
    h1 {
        color: #575757;
        font-weight: 600;
        margin: 0;
    }
    p {
        font-weight: 100;
    }
`

const TitleInputContainer = styled.div`
    border: 1px solid #EEE;
    padding: 5px;
    width: 50%;
    border-radius: 8px;
    background: #fff;
`

const InputTextArea = styled(Input.TextArea)`
    &&& {
        padding: 0;
        border: none;
        outline: none;
        :focus {
            border: none;
            outline: none;
        }
    }
`

const PlusCircleOutlinedStyle = styled(PlusCircleOutlined)`
    border: none;
    outline: none;
    border-radius: 50%;
    color: #8C61CB;
    font-size: 25px;
    cursor: pointer;
`

const showError = styled.p`
    color: red;
    font-size: 12px;
`

EventOverviewWrapper.HeadingContainer = HeadingContainer
EventOverviewWrapper.Content = Content
EventOverviewWrapper.TitleInputContainer = TitleInputContainer
EventOverviewWrapper.InputTextArea = InputTextArea
EventOverviewWrapper.PlusCircleOutlinedStyle = PlusCircleOutlinedStyle
EventOverviewWrapper.showError = showError

export default EventOverviewWrapper
