import gql from 'graphql-tag'
import { get } from 'lodash'
import { SENSEI } from '../../constants/roles'
import duck from '../../duck'
import getDataFromLocalStorage from '../../utils/extract-from-localStorage'
import getIdArrForQuery from '../../utils/getIdArrForQuery'

const addMentorDemandSlot = async ({ input, slotsConnectIds = [],
  broadCastedMentorsConnectIds = [], mentors = [] }) =>
  duck.query({
    query: gql`
    mutation($input: MentorDemandSlotInput!) {
    addMentorDemandSlot(
        ${slotsConnectIds.length > 0 ? `slotsConnectIds: [${getIdArrForQuery(slotsConnectIds)}]` : ''}
        ${broadCastedMentorsConnectIds.length > 0 ? `broadCastedMentorsConnectIds: [${getIdArrForQuery(broadCastedMentorsConnectIds)}]` : ''}
        input: $input
    ) {
        id
        date
        verticals {
            value
        }
        slots {
        id
        date
        openedBy {
          id
          user {
            id
            name
          }
        }
        verticals {
            value
        }
        slotName
        paySlab {
            id
            title
        }
        countries {
            value
        }
        timezone
        count
        schools {
            id
            name
        }
        campaigns {
            id
            title
            code
        }
        broadCastedMentors {
            id
        }
        ${getDataFromLocalStorage('login.role') === SENSEI && mentors.length ? `mentorSessionsMeta(filter: { user_some: { id_in: [${getIdArrForQuery(mentors)}] } }) {
            count
          }` : `mentorSessionsMeta{
          count
        }`}
        isBroadCasted
        b2b2cCount: batchSessionsMeta(
            filter: {
              and: [
                { batch_some: { type_in: [b2b2c, normal] } }
                { topic_some: { order: 1 } }
              ]
            }
          ) {
            count
          }
          b2bCount: batchSessionsMeta(filter: { batch_some: { type: b2b } }) {
            count
          }
          b2cCount :menteeSessionsMeta(
            filter: { and: [{ topic_some: { order: 1 } }, { source_not: school }] }
          ) {
            count
          }
        }
    }
    }
    `,
    variables: {
      input
    },
    type: 'mentorDemandSlots/add',
    key: 'mentorDemandSlots',
    changeExtractedData: (extractedData, originalData) => {
      extractedData.campaigns = []
      extractedData.schools = []
      extractedData.user = []
      extractedData.mentorAvailabilitySlots = get(originalData, 'addMentorDemandSlot.slots', [])
      return { ...extractedData }
    },
  })

export default addMentorDemandSlot
