import * as Yup from 'yup'

const stringRequired = Yup.string().trim().required('Required')

const addCampaignSchema = Yup.object().shape({
  campaignTitle: stringRequired,
  campaignType: stringRequired
})

export const batchValidation = Yup.object().shape({
  coursePackage: stringRequired,
  allottedMentor: stringRequired,
  classroomTitle: stringRequired,
  description: Yup.string().trim().notRequired(''),
})

export default addCampaignSchema
