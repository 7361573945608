import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../../../duck'
import addEventSpeakerProfile from './addEventSpeakerProfile.js'
import addPictureToEventSpeaker from './addPictureToEventSpeaker'

const addEventSpeakerUser = async (input, speakerInput, thumbNailFile) =>
  duck.query({
    query: gql`
      mutation($input: UserInput!) {
        addUser( input: $input ) {
          id
          name
          role
          phone {
            number
          }
          email
        }
      }
    `,
    variables: {
      input
    },
    type: 'user/add',
    key: 'user',
    changeExtractedData: async (extractedData, originalData) => {
        if (get(originalData, 'addUser')) {
          const userId = get(originalData, 'addUser.id')
          let thumbnailData = null
          if (thumbNailFile) {
            thumbnailData = addPictureToEventSpeaker({ file: thumbNailFile, userId })
          }
          await addEventSpeakerProfile(speakerInput, userId)
        }
        return extractedData
    }
  })

export default addEventSpeakerUser