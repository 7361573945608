/* eslint-disable max-len */

import React,{ useState, useEffect } from 'react'
import { get} from 'lodash'
import hs from '../../../../utils/scale'
import { AddSlide} from '../../../../constants/icons'
import { gridLayoutType,learningSlideType } from '../../../../constants/learningSlideConstants'
import {MainContainer,DivHolder,GridHolder,GridContainerDiv,
  OuterDiv,IconHolder,AddSlideDiv} from './Grid.style'
import deleteLearningSlideContent from '../../../../actions/contentMaker/contentLearningSlides/deleteLearningSlideContent'
import GridSelector from './GridComponents/Components/GridSelector'
import PracticeQuestion from './GridComponents/PracticeQuestion'
import { Button, Input } from 'antd'
import { FlexContainer } from '../../Comic/Comic.styles'
import IframePreview from '../../../AddSessions/components/SessionModal/IframePreview'
import { onAddIframeLink } from '../../contentUtils'

const Grid = (props) => {
  const[List,setList]=useState([])
  const [isPreview, setIsPreview] = useState(false)
  useEffect(()=>{
    if((get(props.learningSlide,'slideContents',[]) || []).length>0) {
      setList(get(props.learningSlide,'slideContents',[]))
    }
  },[get(props.learningSlide,'slideContents',[])])
   const addContentDiv=()=>{
       let newComponent={}
       newComponent={
         ...props.learningSlide,
         slideContents :[
           ...get(props.learningSlide,'slideContents'),
           {
            type: 'codeSyntax',
            gridPlacement: `${get(props.learningSlide,'slideContents').length}0`,
            statement: null,
            url: null,
            codeInput: null,
            codeOutput: null,
            media: null,
            shouldAdd: true,
            showCodeInput:true,
            showCodeOutput:false,
            imageData: null,
            codeEditorConfig: {
              editorMode: 'python',
              layout: 'row',
              executionAccess: null,
            }
          }
         ],
         GridAdditon:true
       }
       props.setLearningSlide(newComponent)
       props.setlistArr([...props.listArr.filter((obj) => obj.order !== newComponent.order), newComponent])
   }
   const handleDelete= async(id,obj) => {
      const updatedList=List.filter((elem,index) => {
        return id !== index;
      })
      if(get(obj,'id')){
        await deleteLearningSlideContent(get(obj,'id'))
      }
      let listNew=[]
      if(updatedList.length>0) {
        for(let i=0;i<updatedList.length;i++) {
          let obj=updatedList[i]
          listNew=[
            ...listNew,
            {
            ...obj,
            gridPlacement:`${i}0`
            }
          ]
        }
      }
      let newComponent={}
      newComponent={
        ...props.learningSlide,
        slideContents :listNew,
      }
      props.setLearningSlide(newComponent)
      props.setlistArr([...props.listArr.filter((obj) => obj.order !== newComponent.order), newComponent])
     }
  const getFlexDirection=(value)=>{
    if(value===gridLayoutType.gridOneByOne || value===gridLayoutType.gridOneByTwo || value===gridLayoutType.gridTwoByTwoMergeLeft) {
      return 'row'
    }  else if(value===gridLayoutType.gridTwoByTwo || value===gridLayoutType.nRowSpan) {
      return 'column'
    }
  }
  const getGridClass = (layoutType) => {
    if (layoutType !== gridLayoutType.gridOneByOne && layoutType !== gridLayoutType.nRowSpan) return 'twoGrid'
    if (layoutType === gridLayoutType.nRowSpan) return 'nRowSpan'
    else return ''
  }
  function confirm(e) {
    message.success('Click on Yes')
  }

  function cancel(e) {
    message.error('Click on No')
  } 
  const InvokeDelete =(index,obj)=>{
    handleDelete(index, obj)
  }
  const getContainerInnerDivs = (gridOption,heightValue) => {
    // if(!get(props.learningSlide,'id','')) {
      if (!props.slideContents || props.slideContents.length === 0) {
        let newComponent={}
        newComponent={
          ...props.learningSlide,
          practiceQuestions :[],
          slideContents :[{
              type: 'codeSyntax',
              gridPlacement: '00',
              statement: null,
              url: null,
              codeInput: null,
              codeOutput: null,
              media: null,
              shouldAdd: true,
              showCodeInput:true,
              showCodeOutput:false,
              imageData: null,
              codeEditorConfig: {
                editorMode: 'python',
                layout: 'row',
                executionAccess: false,
              }
            }],
            SlideAddition:true,
            GridAdditon :false
        }
        props.setLearningSlide(newComponent)
        props.setlistArr([...props.listArr.filter((obj) => obj.order !== newComponent.order), newComponent])
      }
    // }
    if (gridOption  === gridLayoutType.gridOneByOne) {
      return <DivHolder height={heightValue}>
         <GridSelector componentId={props.componentId} learningSlide={props.learningSlide} number='00' slideContents={props.slideContents} Helper={props.Helper} setLearningSlide={props.setLearningSlide}/>
      </DivHolder>
    } else if (gridOption  === gridLayoutType.gridOneByTwo) {
      return <>
         <DivHolder height={heightValue}>
         <GridSelector learningSlide={props.learningSlide} number={'00'} key={'00'} slideContents={props.slideContents} Helper={props.Helper} setLearningSlide={props.setLearningSlide}/>
        </DivHolder>
        <DivHolder height={heightValue}>
         <GridSelector learningSlide={props.learningSlide} number={'01'} key={'01'} slideContents={props.slideContents} Helper={props.Helper} setLearningSlide={props.setLearningSlide}/>
        </DivHolder>
      </>
    } else if (gridOption  === gridLayoutType.gridTwoByTwo) {
      return <>
      <GridHolder>
      <DivHolder height={heightValue}>
                <GridSelector learningSlide={props.learningSlide} number={'00'} slideContents={props.slideContents} Helper={props.Helper} setLearningSlide={props.setLearningSlide}/>
            </DivHolder>
            <DivHolder  height={heightValue}>
                <GridSelector learningSlide={props.learningSlide} number={'01'} slideContents={props.slideContents} Helper={props.Helper} setLearningSlide={props.setLearningSlide}/>
            </DivHolder>
      </GridHolder>
      <GridHolder>
      <DivHolder  height={heightValue}>
                <GridSelector learningSlide={props.learningSlide} number={'10'} slideContents={props.slideContents} Helper={props.Helper} setLearningSlide={props.setLearningSlide}/>
            </DivHolder>
            <DivHolder  height={heightValue}>
                <GridSelector learningSlide={props.learningSlide} number={'11'} slideContents={props.slideContents} Helper={props.Helper} setLearningSlide={props.setLearningSlide}/>
            </DivHolder>
      </GridHolder>
      </>
    }  else if (gridOption  === gridLayoutType.gridTwoByTwoMergeLeft) {
      return <>
      <DivHolder height={hs(1320)}>
      <GridSelector learningSlide={props.learningSlide} number={'00'} slideContents={props.slideContents} Helper={props.Helper} setLearningSlide={props.setLearningSlide}/>
      </DivHolder>
       <GridContainerDiv>
          <DivHolder height={heightValue}>
            <GridSelector learningSlide={props.learningSlide} number={'01'} slideContents={props.slideContents} Helper={props.Helper} setLearningSlide={props.setLearningSlide}/>
          </DivHolder>
          <DivHolder  height={heightValue}>
            <GridSelector learningSlide={props.learningSlide} number={'10'} slideContents={props.slideContents} Helper={props.Helper} setLearningSlide={props.setLearningSlide}/>
          </DivHolder>
       </GridContainerDiv>     
      </>
    }   else if (gridOption  === gridLayoutType.nRowSpan) {
      return <GridContainerDiv>
          {
           List && List.length>0 && List.map((obj,index)=>{
              return  <OuterDiv>
                 <DivHolder height={heightValue}>
              <GridSelector learningSlide={props.learningSlide} number={`${index}0`} key={`${index}0`} slideContents={props.slideContents} Helper={props.Helper} GridStyle={'nRowSpan'} SelectedObj={obj} Index={index} InvokeDelete={InvokeDelete}/>
           </DivHolder>
                 </OuterDiv>
           })
        }
        <IconHolder >
          <AddSlideDiv onClick={()=>{addContentDiv()}} style={{ cursor:'pointer'}}>
          <AddSlide/>
          </AddSlideDiv>
        </IconHolder>
      </GridContainerDiv>
    }

  }
  const getBlockHeight = (layoutType) => {
    if (layoutType === gridLayoutType.nRowSpan) return hs(500)
    else return hs(660)
  }

  const renderSlideContent = () => {
    if (get(props, 'slideType','') === learningSlideType.grid) {
      return (
        <>
          <MainContainer direction={getFlexDirection(props.gridOption)} className={getGridClass(get(props, 'gridOption',''))}>
              {get(props, 'gridOption','') === gridLayoutType.gridOneByOne && getContainerInnerDivs(get(props, 'gridOption',''),getBlockHeight(get(props, 'gridOption','')))}
              {get(props, 'gridOption','') === gridLayoutType.gridOneByTwo && getContainerInnerDivs(get(props, 'gridOption',''),getBlockHeight(get(props, 'gridOption','')))}
              {get(props, 'gridOption','') === gridLayoutType.gridTwoByTwo && getContainerInnerDivs(get(props, 'gridOption',''),getBlockHeight(get(props, 'gridOption','')))}
              {get(props, 'gridOption','') === gridLayoutType.gridTwoByTwoMergeLeft && getContainerInnerDivs(get(props, 'gridOption',''),getBlockHeight(get(props, 'gridOption','')))}
              {get(props, 'gridOption','') === gridLayoutType.nRowSpan && getContainerInnerDivs(get(props, 'gridOption',''),getBlockHeight(get(props, 'gridOption','')))}
          </MainContainer>
        </>
      )
    }
    else if (get(props, 'slideType','') === learningSlideType.practiceQuestion) {
        return (
          <>
              <PracticeQuestion  setLearningSlide={props.setLearningSlide} learningSlide={props.learningSlide} practiceQuestions={props.practiceQuestions} learningSlides={props.learningSlides} SetPracticeData={props.SetPracticeData}></PracticeQuestion>         
          </>
        )


  } 
  else{
    return (
      <>
      <FlexContainer style={{width: '60%', gap: 20}} >
        <Input
          type="text"
          placeholder="Enter Google Slide Link"
          value={
            props.learningSlide.googleSlideLink
          }
          onChange={(e) =>
            props.setGoogleSlideLink(onAddIframeLink(e.target.value))
          }
        />
        <Button
          type='primary'
          onClick={() => setIsPreview(true)}
        
        >
          See Preview
        </Button>
      </FlexContainer>
      </>
    )
  }
} 
return <>
    {
      renderSlideContent()
    }
    {
      isPreview ? <IframePreview
        openIframePreview={isPreview}
        onClose={() => setIsPreview(false)}
        onSubmit={() => {}}
        fullWidth={true}
        showInput={false}
        source={props.learningSlide.googleSlideLink}
        handleChange={() => {}}
      >
      </IframePreview> : null
    }
</>
}
export default Grid
