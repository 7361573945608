import styled from 'styled-components'
import { Radio } from 'antd'
import colors from '../../constants/colors'

const Main = styled.div`
  height: 60px;
  width: 100%;
  align-item:center;
`
const ContainerDiv = styled.div`
  display:flex;
  flex-direction:row;
`
const OptionDiv = styled.div`
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  font-family: 'Inter' ;
  font-style: normal ;
  font-weight: 500 ;
  font-size: 16px 
`
const Underline = styled.div`
height: 4px;
background: #8C61CB;
border-radius: 24px 24px 0px 0px;
width: 88%;
margin:4px 0px 0px 0px;

`
const RadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper-checked {
    background: ${colors.themeColor};
    color: white;
    &:hover {
      color: white;
    }
  }
`
const UpdatedRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper-checked {
    color: white;
    &:hover {
      color: white;
    }
      font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  color: #8C61CB !important;
  font-size: 16px !important;
    background:none !important; 
  }
  .ant-radio-button-wrapper:first-child {
    border-left:none;
  }
  .ant-radio-button-wrapper {
    border:none;
  }
`

Main.RadioGroup = RadioGroup
Main.UpdatedRadioGroup=UpdatedRadioGroup
export default Main;
export {ContainerDiv,OptionDiv,Underline}