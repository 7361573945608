import React, { PureComponent } from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { get } from 'lodash'
import { connect } from 'react-redux'
import DemandCalendarWrapper from '../DemandCalendar.style'
import Styles from '../../DemandSupplyDashboard.style'
import ClassDetail from './ClassDetail'
import MentorDetails from './MentorDetails'
import { fetchSingleSessionDetails } from '../../../../actions/demandSupplyDashboard'
import { filterKey, getSuccessStatus } from '../../../../utils/data-utils'
import PageLoading from '../../components/PageLoading'
import fetchAssignMentorInfo from '../../../../actions/slots/fetchAssignMentorInfo'
import { B2B2C } from '../../../../constants/demandVerticals'

class EventView extends PureComponent {
  state = {
    sessionData: {},
  }
  componentDidMount = () => {
    this.fetchSessionDetails()
  }

  fetchSessionDetails = async () => {
    const { activeEvent } = this.props
    if (get(activeEvent, 'sessionId')) {
      const slotNumber = new Date(get(activeEvent, 'startTime')).getHours()
      const eventView = document.querySelector('.event-view-card')
      if (eventView) {
        eventView.scrollTo({ top: 0, behavior: 'smooth' })
      }
      await fetchSingleSessionDetails({
        sessionType: get(activeEvent, 'recordType'),
        sessionId: get(activeEvent, 'sessionId'),
      })
      const date = new Date(get(activeEvent, 'rawBookingDate')).setHours(0, 0, 0, 0)
      if (get(activeEvent, 'recordType') === 'menteeSession') {
        fetchAssignMentorInfo(date, slotNumber, [get(activeEvent, 'sessionId')], false, true)
      } else {
        let sessionType = 'batch'
        if (get(activeEvent, 'batch.type') === B2B2C && get(activeEvent, 'topic.order', 0) === 1) {
          sessionType = 'trial'
        }
        fetchAssignMentorInfo(date, slotNumber, [], false, true, `batchSession/${date}/${slotNumber}`,
          sessionType)
      }
    }
  }
  componentDidUpdate = (prevProps) => {
    const { activeEvent, sessionDetailForDemandFetchStatus } = this.props
    if (get(prevProps, 'activeEvent.sessionId') !== get(activeEvent, 'sessionId')
      && get(activeEvent, 'sessionId')) {
      this.fetchSessionDetails()
    }
    const currFetchStatus = sessionDetailForDemandFetchStatus
      && sessionDetailForDemandFetchStatus.getIn([`sessionDetailForDemand/${get(activeEvent, 'recordType')}/${get(activeEvent, 'sessionId')}`])
    const prevFetchStatus = prevProps.sessionDetailForDemandFetchStatus
      && prevProps.sessionDetailForDemandFetchStatus.getIn([`sessionDetailForDemand/${get(activeEvent, 'recordType')}/${get(activeEvent, 'sessionId')}`])
    if (getSuccessStatus(currFetchStatus, prevFetchStatus)) {
      this.setSessionData()
    }
  }

  setSessionData = () => {
    let { sessionDetailForDemand } = this.props
    const { activeEvent } = this.props
    sessionDetailForDemand = sessionDetailForDemand
      && filterKey(sessionDetailForDemand, `sessionDetailForDemand/${get(activeEvent, 'recordType')}/${get(activeEvent, 'sessionId')}`).toJS() || []
    this.setState({
      sessionData: {
        ...get(this.props, 'activeEvent'),
        session: get(sessionDetailForDemand, '0', {}),
      }
    })
  }
  render() {
    const { onNavigation, sessionDetailForDemandFetchStatus, activeEvent } = this.props
    const { sessionData } = this.state
    const fetchStatus = sessionDetailForDemandFetchStatus
      && sessionDetailForDemandFetchStatus.getIn([`sessionDetailForDemand/${get(activeEvent, 'recordType')}/${get(activeEvent, 'sessionId')}`])
    return (
      <DemandCalendarWrapper.EventViewContainer>
        <Styles.FlexContainer>
          <DemandCalendarWrapper.NavigateButton onClick={() => onNavigation('prev')}>
            <LeftOutlined />
            Previous Slot
          </DemandCalendarWrapper.NavigateButton>
          <DemandCalendarWrapper.NavigateButton onClick={() => onNavigation('next')}>
            Next Slot
            <RightOutlined />
          </DemandCalendarWrapper.NavigateButton>
        </Styles.FlexContainer>
        <DemandCalendarWrapper.EventViewCard className='event-view-card'
          style={{
          position: fetchStatus && get(fetchStatus.toJS(), 'loading') ? 'relative' : 'unset'
        }}
        >
          <PageLoading isfetching={fetchStatus && get(fetchStatus.toJS(), 'loading')}
            fullPage={false}
          />
          <ClassDetail sessionData={sessionData} />
          {
            !(fetchStatus && get(fetchStatus.toJS(), 'loading')) && <MentorDetails
              sessionData={sessionData}
            />
          }
        </DemandCalendarWrapper.EventViewCard>
      </DemandCalendarWrapper.EventViewContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  sessionDetailForDemandFetchStatus: state.data.getIn(['sessionDetailForDemand', 'fetchStatus']),
  sessionDetailForDemand: state.data.getIn(['sessionDetailForDemand', 'data']),
})

export default connect(mapStateToProps)(EventView)
