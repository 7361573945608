import gql from 'graphql-tag'
import duck from '../../duck'
import updateMentorSession from './updateMentorSession'

const deleteAdhocSession = (adhocSessionId, input, mentorSessionId) =>
  duck.createQuery({
    query: gql`
        mutation {
        deleteAdhocSession(id: "${adhocSessionId}") {
          id
        }
      }
    `,
    variables: {
      callBatchAPI: true
    },
    type: 'adhocSessions/delete',
    key: 'adhocSessions',
    changeExtractedData: (extractedData) => {
      updateMentorSession(input, mentorSessionId)
      return extractedData
    }
  })

export default deleteAdhocSession

