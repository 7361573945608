/* eslint-disable max-len */
import { Pagination, Button } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { get } from "lodash";
import React from "react";
import { CSVLink } from 'react-csv'
import PropTypes from "prop-types";
import { fetchStudentsProfile } from "../../../../actions/SchoolOnboarding";
import MainTable from "../../../../components/MainTable";
import colors from "../../../../constants/colors";
import fetchUserCred from "../../../../actions/userCred/fetchUserCred";
import {
  FlexContainer,
  MDTable,
  StyledButton,
} from "../../SchoolOnBoarding.style";
import GradeCards from "../GradeCards";
import SearchInput from "../SearchInput";
import SearchBox from "../../../ClassProgress/components/SearchBox";
import headerConfig from "./headerConfig";
import userStatusConst from "../../../../constants/userStatusConst";
import requestToGraphql from "../../../../utils/requestToGraphql";
import gql from "graphql-tag";

class StudentsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studentProfiles: [],
      selectedSection: "",
      currentPage: 1,
      perPage: 5,
      skip: 0,
      columns: [],
      searchText: "",
      studentsCredReport: [],
      loading: false,
    };
  }
  downloadCredRef= React.createRef();
  downloadCred = async () => {
    const { academicYearConnectId } = this.props
    this.setState({ loading: true });
    const studentsCount = await requestToGraphql(gql`{
      usersMeta(
        filter: {
          and: [
            { role: mentee }
            {
              studentProfile_some: {
                and:[
                  {
                    school_some: { id: "${this.props.chosenSchool}" }
                  }
                  { academicYears_some: { id: "${academicYearConnectId}" } }
                ]
              }
            }
          ]
        }
      ) {
        count
      }
    }
    `)
    const studentsCountValue = get(studentsCount, 'data.usersMeta.count')
    const chunksCountValues = [];
    const firstCountValue = 200;
    for (let skip = 0; skip <= studentsCountValue; skip+=firstCountValue){
        chunksCountValues.push({ first: firstCountValue, skip })
    }
    const userCredsArray = [];
    for (const chunk of chunksCountValues) {
      const userData = await fetchUserCred(this.props.chosenSchool, chunk.first, chunk.skip, academicYearConnectId)
      if (get(userData, 'userCred', []).length) userCredsArray.push(...get(userData, 'userCred', []))
    }
    this.setState({ loading: false });
    // check if response is fine
    if (userCredsArray && userCredsArray.length > 0) {
    let userCred = userCredsArray;
    userCred = userCred.filter(item => get(item, 'status') === 'active');
    const data = userCred.map(item => {
      const {name,email, savedPassword,studentProfile, id} = item
      const {grade,rollNo,section,parents,studentBatch} = studentProfile
      const parentEmail = get(parents[0], "user.email");
      const parentSavedPassword = get(parents[0], "user.savedPassword");
      const classroomTitle = get(studentBatch, 'classroomTitle') ? (get(studentBatch, 'classroomTitle','').includes('Grade') ? get(studentBatch, 'classroomTitle') : `Grade ${get(studentBatch, 'classroomTitle')}`) : '';
     return {
          name,
          grade,
          section,
          classroomTitle,
          email: email || parentEmail,
          password: savedPassword || parentSavedPassword,
          rollNo,
          userId: id,
        }
    }) 
    // check if username is exists in the any of the student profile, then create field username in the csv file
    if(data.some(item => item.username)){
      headerConfig.push({label: 'Username', key: 'username'})
    }
    this.setState({ studentsCredReport: data });
    this.downloadCredRef.current.link.click();
    
  }
}
  
  fetchStudents = async () => {
    const { perPage, skip, selectedSection } = this.state;
    const { chosenSchool, chosenGrade, academicYearConnectId } = this.props;
    
    await fetchStudentsProfile({
      schoolId: chosenSchool,
      selectedGrade: chosenGrade,
      section: selectedSection,
      studentName: this.state.searchText,
      perPage,
      skip,
      fromStudentsTab: true,
      academicYearConnectId
    });
  };


  componentDidMount = async () => {
    const { classGrades } = this.props;
    if (classGrades.length > 0) {
      this.setState({ selectedSection: "All" });
    }
    
  };
  componentDidUpdate = async (prevProps, prevState) => {
    const {
      chosenGrade,
      chosenSchool,
      studentProfilesFetchStatus,
      classGrades,
      studentProfileUpdateStatus,
      parentSignUpStatus,
      academicYearConnectId
    } = this.props;
    const { selectedSection } = this.state;
    const studentAddingStatus =
      parentSignUpStatus &&
      !get(parentSignUpStatus.toJS(), "loading") &&
      get(parentSignUpStatus.toJS(), "success") &&
      prevProps.parentSignUpStatus !== parentSignUpStatus;
    if (classGrades && classGrades.length > 0) {
      if (prevProps.chosenGrade !== chosenGrade) {
        this.setState({
          selectedSection: !this.state.selectedSection
            ? "All"
            : this.state.selectedSection,
        });
      }
    }
    if (
      prevProps.chosenSchool !== chosenSchool
    ) {
      this.setState({
        selectedSection: '',
      });
    }
    if (
      prevProps.classGrades !== classGrades &&
      classGrades.length > 0 &&
      !selectedSection
    ) {
      this.setState({
        selectedSection: "All",
      });
    }
    if (
      chosenGrade &&
      chosenSchool &&
      prevState.selectedSection !== selectedSection &&
      selectedSection
    ) {
      this.fetchStudents();
    }
    if (
      academicYearConnectId &&
      prevProps.academicYearConnectId !== academicYearConnectId
    ) {
      this.fetchStudents();
    }
    const studentUpdatingStatus =
      studentProfileUpdateStatus &&
      !get(studentProfileUpdateStatus.toJS(), "loading") &&
      get(studentProfileUpdateStatus.toJS(), "success") &&
      prevProps.studentProfileUpdateStatus !== studentProfileUpdateStatus;
    if (studentUpdatingStatus || studentAddingStatus) {
      this.fetchStudents();
    }
    if (
      studentProfilesFetchStatus &&
      !get(studentProfilesFetchStatus.toJS(), "loading") &&
      get(studentProfilesFetchStatus.toJS(), "success") &&
      prevProps.studentProfilesFetchStatus !== studentProfilesFetchStatus
    ) {
      this.convertDataToTable();
    }
    
  };
  convertDataToTable = () => {
    const { onStudentEdit, chosenGrade, onLinkGenerate,studentProfiles } = this.props;
    // make sure that the field is not empty
    const studentProfilesData = studentProfiles.toJS().map((data) => {
      const { studentName, email, rollNo, studentBatch } = data;
      return {
        ...data,
        studentName: studentName || "-",
        classroomTitle: get(studentBatch, 'classroomTitle') || "-",
        email: email || "-",
        rollNo: rollNo || "-",
      };
    })
  

    this.setState(
      {
        // eslint-disable-next-line no-nested-ternary
        studentProfiles: studentProfilesData
          ? chosenGrade !== "All"
            ? studentProfilesData
                .filter((data) => get(data, "grade") === chosenGrade)
            : studentProfilesData
          : [],
      },
      () => {
        const { studentProfiles, selectedSection } = this.state;
        let columns = [];
        if (studentProfiles.length > 0) {
          columns = [
            {
              title: "#",
              dataIndex: "index",
              key: "index",
              align: "center",
            },
            {
              title: "Student Name",
              dataIndex: "studentName",
              key: "studentName",
              align: "center",
            },
            {
              title: "Grade",
              dataIndex: "grade",
              key: "grade",
              align: "center",
            },
            {
              title: "Section",
              dataIndex: "section",
              key: "section",
              align: "center",
            },
            {
              title: "Classroom Title",
              dataIndex: "classroomTitle",
              key: "classroomTitle",
              align: "center",
            },
            {
              title: "Email",
              dataIndex: "email",
              key: "email",
              align: "center",
            },
            {
              title: "Roll No.",
              dataIndex: "rollNo",
              key: "rollNo",
              align: "center",
            },
            {
              title: "Action",
              dataIndex: "action",
              key: "action",
              align: "center",
              render: (row, data) => (
                <>
                  <MainTable.ActionItem.IconWrapper>
                    <MainTable.ActionItem.EditIcon
                      onClick={() => get(data, 'user.status') === userStatusConst.active ? onStudentEdit(data) : null}
                      style={{
                        color: colors.table.editIcon,
                        cursor: get(data, 'user.status') === userStatusConst.active ? 'pointer' : 'not-allowed'
                      }}
                    />
                  </MainTable.ActionItem.IconWrapper>
                  <LinkOutlined
                    onClick={() => get(data, 'user.status') === userStatusConst.active ? onLinkGenerate(data) : null}
                    style={{
                      color: colors.table.editIcon,
                      fontSize: 20,
                      marginLeft: 8,
                      cursor: get(data, 'user.status') === userStatusConst.active ? 'pointer' : 'not-allowed'
                    }}
                  />
                </>
              ),
            },
          ];
          if (selectedSection !== "All") {
            columns = columns.filter(({ title }) => title !== "Section");
          }
          this.setState({
            columns,
          });
        }
      }
    );
  };
  onPageChange = (page) => {
    this.setState(
      {
        currentPage: page,
        skip: page - 1,
      },
      () => this.fetchStudents()
    );
  };
  selectGradeSection = (value) => {
    const { chosenGrade } = this.props;
    if (chosenGrade) {
      this.setState({
        selectedSection: value,
        studentProfiles: [],
        currentPage: 1,
        perPage: 5,
        skip: 0,
      });
    }
  };
  renderGrades = () => {
    const { chosenGrade, classGrades } = this.props;
    const { selectedSection } = this.state;
    const allGrades = {
      grade: "",
      sections: null,
      studentCount: 0,
    };
    classGrades.map((data, idx) => {
      if (idx === 0) {
        allGrades.grade += `${data.grade}`;
      } else {
        allGrades.grade += `, ${data.grade}`;
      }
      allGrades.studentCount += data.studentCount;
    });
    if (chosenGrade && chosenGrade === "All") {
      return (
        <GradeCards
          classData={allGrades}
          gradeAction={null}
          selectGradeSection={this.selectGradeSection}
          selectedSection={selectedSection}
        />
      );
    } else if (chosenGrade) {
      return classGrades
        .filter(({ grade }) => grade === chosenGrade)
        .map((classData) => (
          <GradeCards
            classData={classData}
            key={classData.id}
            gradeAction={null}
            selectGradeSection={this.selectGradeSection}
            selectedSection={selectedSection}
          />
        ));
    }
    return classGrades.map((classData) => (
      <GradeCards
        classData={classData}
        key={classData.id}
        gradeAction={null}
        selectGradeSection={this.selectGradeSection}
        selectedSection={selectedSection}
      />
    ));
  };
  
  renderStudentTable = () => {
    const {
      chosenGrade,
      studentProfilesFetchStatus,
      classGrades,
      studentProfilesMeta,
      onStudentAdd,
      renderStudentdata,
    } = this.props;
    const {
      selectedSection,
      studentProfiles,
      currentPage,
      perPage,
      columns,
    } = this.state; 
    
    if (chosenGrade && selectedSection && classGrades.length > 0) {
      return (
        <>
          <FlexContainer section>
            <h2 style={{ color: "white" }}>Section {selectedSection}</h2>
            <StyledButton
              type="primary"
              onClick={() => onStudentAdd(selectedSection, chosenGrade)}
            >
              Add Students
            </StyledButton>
          </FlexContainer>
          <MDTable
            columns={columns}
            dataSource={studentProfiles}
            loading={
              studentProfilesFetchStatus &&
              get(studentProfilesFetchStatus.toJS(), "loading")
            }
            scroll={{ x: "max-content" }}
            pagination={false}
            rowClassName={(record) => get(record, 'user.status') !== userStatusConst.active ? 'blocked-row' : ''}
          />
          {studentProfilesMeta > perPage && (
            <FlexContainer justify="center">
              <Pagination
                total={studentProfilesMeta || 0}
                onChange={this.onPageChange}
                current={currentPage}
                defaultPageSize={perPage}
              />
            </FlexContainer>
          )}
        </>
      );
    }
    return null;
  };
  chosenSchool
  render() {
    const {
      classGrades,
      chosenGrade,
      selectGrade,
      onGenerateLinks,
      renderStudentdata,
      academicYearConnectId,
    } = this.props;
    const { selectedSection } = this.state;
   
    return (
      <>
        <FlexContainer justify="space-between" noPadding>
          <FlexContainer justify="flex-start">
            <h2 className="studentTab__selectTitle">Choose Grade</h2>
            <SearchInput
              value={chosenGrade}
              placeholder="Select a grade"
              onChange={(value) => {
                this.setState(
                  {
                    selectedSection: "",
                  },
                  () => selectGrade(value)
                );
              }}
              dataArray={classGrades}
              optionGrades
            />
          </FlexContainer>
          <SearchBox
            placeholder="Search by student name"
            value={this.state.searchText}
            onChange={(e) => this.setState({ searchText: e.target.value })}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                this.fetchStudents();
              }
            }}
            style={{ position: "relative", float: "right" }}
          />
          <FlexContainer style={{gap: "0.5rem"}}>
            <StyledButton
            type="primary"
            disabled={!academicYearConnectId}
            loading={this.state.loading}
            onClick={() => this.downloadCred()}
>
            {this.state.loading ? "Getting Student Credentials" : "Download Student Credentials"}
           
          </StyledButton>
             <CSVLink
            style={{display: "none"}}
            filename={`${this.props.schoolName} Credentials.csv`}
            data={this.state.studentsCredReport}
            ref={this.downloadCredRef}
            headers={headerConfig}
            >
            </CSVLink>
          <StyledButton
            type="primary"
            value="Generate Login Links"
            onClick={() => onGenerateLinks(selectedSection, chosenGrade)}
          >
            Generate Login Links
          </StyledButton>
          </FlexContainer>
          
        </FlexContainer>
        <div style={{ position: "relative", float: "right" }}>
          <Button onClick={this.clearFilter} type="primary">
            Clear Filter
          </Button>
        </div>
        <div style={{ marginTop: "8.5vh" }}>{this.renderGrades()}</div>
        {this.renderStudentTable()}
      </>
    );
  }
  clearFilter = () => {
    this.setState(
      {
        searchText: "",
        selectedSection: "All",
        currentPage: 1,
        perPage: 5,
        skip: 0,
      },
      () => this.fetchStudents()
    );
  };
}

StudentsTab.propTypes = {
  chosenSchool: PropTypes.string.isRequired,
  chosenGrade: PropTypes.string.isRequired,
  classGrades: PropTypes.arrayOf({}).isRequired,
  studentProfilesFetchStatus: PropTypes.shape({}).isRequired,
  studentProfileUpdateStatus: PropTypes.shape({}).isRequired,
  onStudentEdit: PropTypes.func.isRequired,
  studentProfiles: PropTypes.arrayOf({}).isRequired,
  studentProfilesMeta: PropTypes.number.isRequired,
  onStudentAdd: PropTypes.func.isRequired,
  selectGrade: PropTypes.func.isRequired,
};

export default StudentsTab;
