import styled from 'styled-components'
import {Button,Input,Select} from 'antd'
const {TextArea} =Input

const LayoutOneDiv = styled.div`
display:flex;
flex-direction:column;
height:78%;
`
const HeadingHolder = styled.div`
width: 100%;
display:flex;
flex-direction:row;
justify-content:space-between;
margin:0px 0px 10px 0px;
`
const TypeHolder = styled.div`
display:flex;
flex-direction:row;
margin:0px 11px 0px 0px;
`
const TerminalText = styled.div`
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 14px;
text-transform: capitalize;
color: #D3D3D3;
justify-content: center;
display: flex;
align-items: center;
margin:0px 0px 0px 10px;
}
`
const ShowChat = styled.div`
font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 13px;
text-transform: capitalize;
color: #000000;
justify-content: center;
display: flex;
align-items: center;
margin: 0px 6px 0px 0px;
}
`

const StyledTextAreaOne = styled(TextArea)`
&.ant-input {
 height:100% !important;
 border-radius:11px;
}
`
const StyledTextAreaTwo = styled(TextArea)`
&.ant-input {
 height:100% !important;
 border-radius:11px;
}
`
const CheckBoxHolder = styled.div`
display: flex;
justify-content: center;
align-items: center;

`
const HeadingDiv = styled.div`
width: 100%;
display:flex;
flex-direction:column;
justify-content:space-between;
margin:10px 0px 11px 0px;
height:50%;
`
export {
    StyledTextAreaOne,
    StyledTextAreaTwo,
    HeadingHolder,
    TypeHolder,
    TerminalText,
    ShowChat,
    CheckBoxHolder,
    LayoutOneDiv,
    HeadingDiv,
    }
    