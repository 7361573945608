import gql from 'graphql-tag'
import duck from '../../duck'

const mentorB2cEarningsQuery = (userId, skip) => `
mentorMenteeSessions(filter: {and: [{mentorSession_some:{user_some:{id_in:["${userId}"]}}},{sessionStatus_in:[completed, started]}, {menteeSession_exists: true}, {source_not: school}, {topic_some: {order_not: 1}}, {studentProfile_some:{batch_exists:false}} ]}, orderBy: sessionStartDate_ASC, first: 5, skip: ${skip}) {
  id
  sessionStartDate
  leadStatus
  sessionStatus
  country
  topic{
    order
    title
  }
  salesOperation{
    userPaymentPlan{
      product{
        type
      }
    }
  }
  mentorSession {
    id
    user {
      id
      name
      username
    }
  }
isFeedbackSubmitted
sessionRecordingLink
  menteeSession {
    id
    bookingDate
    user {
      id
      name
    }
  }
}
mentorMenteeSessionsMeta(filter: {and: [{mentorSession_some:{user_some:{id_in:["${userId}"]}}},{sessionStatus_in:[completed, started]}, {menteeSession_exists: true}, {source_not: school}, {topic_some: {order_not: 1}}, {studentProfile_some:{batch_exists:false}} ]}) {
count
}
`

const fetchMentorB2cEarnings = async (
  userId,
  skip
) => duck.query({
  query: gql`
     query {
       ${mentorB2cEarningsQuery(userId, skip)}
    }`,
  type: 'mentorMenteeSession/fetch',
  key: 'mentorEarningsForMentorDashboardEarnings',
})

export default fetchMentorB2cEarnings
