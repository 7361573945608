import gql from "graphql-tag"
import duck from '../../duck'
import { get } from 'lodash'

const fetchSingleBatch = async (batchId) => duck.query({
  query: gql`
    {
      batch(id: "${batchId}"){
        id
        code
        allottedMentor {
          name
          phone {
            countryCode
            number
          }
        }
        school{
          id
          name
        }
        coursePackageTopicRule {
          topic {
            id
            title
            order
            classType
            description
            chapter {
              id
              title
            }
          }
          isMandatory
          title
          description
          order
          isRevision
          revisionOrder
          previousTopicOrder
        }
        coursePackage {
          id
          title
          courses{
            id
            title
            minGrade
            maxGrade
            category
            topics {
              id
              title
              status
            }
          }
          topics{
            order
            title
            description
            isRevision
            isMandatory
            previousTopicOrder
            revisionOrder
            topic{
              id
              title
              classType
              chapter{
                id
                title
              }
            }
          }
        }
      }
    }
  `,
  type: 'batch/fetch',
  key: 'batch',
  changeExtractedData: (extractedData, originalData) => {
    extractedData.batch = get(originalData, 'batch');
    return { ...extractedData }
  }
})

export default fetchSingleBatch;