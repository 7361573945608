import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const addTrainer = async input => {
  await duck.query({
    query: gql`
        mutation addUser($input: UserInput!) {
          addUser(input: $input) {
              id
              name
              email
              username
              roles
              createdAt
              phone {
              countryCode
              number
              }
          }
        }
      `,
    variables: {
      input
    },
    type: 'users/add',
    key: 'schoolTrainer',
  })
}

export default addTrainer
