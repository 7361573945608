import { LoadingOutlined } from '@ant-design/icons'
import { Checkbox, Icon, Select, Divider, Input, Spin } from 'antd'
import RadioGroup from 'antd/lib/radio/group'
import React from 'react'
import styled from 'styled-components'
import { TekieAmethyst, TekieOrange } from '../../constants/colors'
import { CloseSVG } from '../../constants/icons'

const loadingIcon = <LoadingOutlined style={{ fontSize: 16, marginRight: '8px', color: '#FFF' }} spin />

const DemandSupplyDashboard = styled.div``

const CustomSelect = styled((props) => <Select ref={props.refObj} {...props} />)`
  min-width: 160px;
  font-family: 'Inter';
  letter-spacing: 0px;
  height: 45px;
  ${props => props.verticals ? 'text-transform: uppercase;' : ''}
  & .ant-select-selection-selected-value{
    width: 90%;
  }
  .ant-select-selection {
      border: 1px solid #EEEEEE;
      box-sizing: border-box;
      border-radius: 8px;
      height: 100%;
      display: flex;
      align-items:center;
  }
  & .ant-select-selection__rendered{
    width: 100%;
  }
  .ant-select-selection__placeholder {
      font-family: 'Inter';
      letter-spacing: 0px;
  }
  .ant-select-selection:hover {
      border-color: ${TekieAmethyst} !important;
  }
  .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
    border-color: ${TekieAmethyst} !important;
    box-shadow: 0 0 0 2px rgba(140, 97, 203, 0.2) !important;
  }
  .ant-select-search .ant-select-search__field__wrap input {
    font-family: 'Inter' !important;
    letter-spacing: 0px !important;
    color: #bfbfbf !important;
  }
  .ant-select-open > .ant-select-selection {
    border-color: ${TekieAmethyst} !important;
    box-shadow: 0 0 0 2px rgba(140, 97, 203, 0.2) !important;
  }
  .ant-select-selection--multiple {
    min-height: 44px;
    padding-bottom: 3px;
    cursor: text;
    zoom: 1;
    height: auto !important;
  }
  .ant-select-selection__choice{
    max-width: 90% !important;
  }
  &.ant-select-disabled .ant-select-selection{
    cursor: inherit !important;
    background-color: white !important;
  }
`

const StyledRadioGroup = styled(RadioGroup)`
height: 41px;
width: 100%;
display: grid !important;
grid-template-columns: 50% 50%;
& label{
  height: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
}
& .ant-radio-button-wrapper-checked{
  background-color: ${TekieAmethyst} !important;
  border-color: ${TekieAmethyst} !important;
}
`

const SelectOption = styled(Select.Option)`
  font-family: 'Inter';
  letter-spacing: 0.3;
  display: flex;
  align-items: center;
`

const SelectWrapper = styled.div`
font-family: Inter;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 20px;
color: #333333;
margin:10px;
display: flex;
align-items: center;
h3{
  margin: 0;
  margin-right: 15px;
}
`
const StyledCheckbox = styled(Checkbox)`
margin-right: 10px !important;
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${TekieAmethyst};
    border-color: ${TekieAmethyst};
    color: #fff;
}
.ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border: 1px solid gray;
}
.ant-checkbox-inner::after {
    left: 14%;
    width: 7.5px;
    height: 13px;
    border: 2px solid white !important;
    border-top: 0 !important;
    border-left: 0 !important;
    ${props => props.halfChecked ? 'border-bottom: 0 !important;' : ''}
}
.ant-checkbox-checked::after {
    border: 1px solid black !important;
}
${props => props.halfChecked ? `
.ant-checkbox-checked .ant-checkbox-inner::after {
    -webkit-transform: rotate(90deg) scale(1) translate(-128%, -16%);
    -ms-transform: rotate(90deg) scale(1) translate(-128%, -16%);
    transform: rotate(90deg) scale(1) translate(-128%, -16%);
}
` : ''}
`

const FlexContainer = styled.div`
display: flex;
flex-direction: ${props => props.flexDirection || 'row'};
justify-content: ${props => props.justify ? props.justify : 'space-between'};
align-items: center;
flex-wrap: wrap;
p{
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
margin: 0px;
color: #333333;
}
& .mentor__name {
font-family: Inter;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 18px;
color: #212121;
margin: 0px 8px;
}
`
const TopBarContainer = styled(FlexContainer)`
@media(max-width: 1240px){
  flex-direction: column;
}
`

const CalendarContainer = styled.div`
width: 100%;
flex: 1;
display: grid;
grid-template-columns: ${props => props.isActive ? '40% 60%' : '100%'};
grid-column-gap: 10px;
`

const TopicThumbnail = styled.div`
    background-image: url("${props => props.bgImage || null}");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 110px;
    height: 110px;
    background-color: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 16px;
    margin-right: 10px;
    ${props => props.studentImage ? `height: 120px;
    width: 120px;
    border-radius: 100%;` : ''}
    ${props => props.mentorImage ? `
    width: 160px;
    height: 160px;
    border-radius: 100%;` : ''}
`

const HeaderSessionIndicator = styled.div`
font-family: 'Inter';
background: ${props => props.bgColor ? props.bgColor : TekieOrange};
position: relative;
width: 20px;
height: 20px;
border-radius: 6px;
color: white;
font-size: 10px;
font-weight: 600;
display: flex;
text-transform: Uppercase;
justify-content: center;
align-items: flex-end;
padding-bottom: 2px;`

const PreHeaderText = styled.div`
    font-family: 'Inter';
    text-transform: Uppercase;
    font-weight: 550;
    font-size: 16px;
    color: #111111;
    margin: 0px 14px;
`

const HeaderTag = styled.div`
    font-family: 'Inter';
    font-weight: normal;
    font-size: 9px;
    color: #FFFFFF;
    width: 50px;
    background: ${props => props.bgColor || '#1B7275'};
    border-radius: 6px;
    padding: 4px;
    text-align: center;
    text-transform: uppercase;
`

const StyledIcon = styled(({ isActive, ...rest }) => <Icon {...rest} />)`
  transition: 0.3s all ease-in-out;
  font-size: ${props => props.fontSize ? props.fontSize : '26px'};
  fill: ${props => props.fillSvg ? props.fillSvg : '#666666'};
  margin-right: ${props => props.marginRight ? props.marginRight : '8px'};
`

const ContentClassDetail = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 12px 0px;
    & .classDetailsText {
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        white-space: no-wrap;
        flex: 0 1 20%;
    }
    @media screen and (max-width: 500px) {
        .classDetailsText {
            flex: 0 1 30%;
        }
    }
`

const Text = styled.div`
    font-weight: 550;
    font-size: 14px;
    color: #333333;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${props => props.highLight ? `display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    background: #EEEEEE;
    border-radius: 15px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #616161;
    ` : ''}
    ${props => props.studentDetail ? `font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 16px;
display: flex;
align-items: center;
color: #424242;` : ''}
`

const CustomDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 0 !important;
    margin-top: 20px !important;
  }
`

const SearchInput = styled(Input)`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 6px 12px;
background: #FFFFFF;
border: 1px solid #EEEEEE;
box-sizing: border-box;
border-radius: 8px;
width: ${props => props.width || '350px'} !important;
margin: 10px 0 10px auto !important;
& input{
  flex: 0.9;
  border: 0;
  outline: none;
  font-size: 14px;
  line-height: 20px;
}
& input::placeholder{
font-family: Inter;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
color: #9E9E9E;
}
& .anticon{
  height: 15px;
  font-size: 16px;
  width: 10px;
}
`

const PrimaryButton = styled.button`
    font-family: 'Inter';
    border: none;
    padding: 8px 14px;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    background: ${props => props.disabled ? '#CCCCCC' : TekieAmethyst};
    border-radius: 8px;
    cursor: ${props => props.disabled || props.loading ? 'not-allowed' : 'pointer'};
    pointer-events: ${props => props.disabled || props.loading ? 'none' : 'all'};
    letter-spacing: 0px;
    transistion: all .3s;
    display: flex;
    user-select: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &:hover, &:focus{
        background: ${props => props.disabled ? '#BBBBBB' : '#713EBC'};
    }
    &:focus {
        box-shadow: 0px 2px 8px rgba(140, 97, 203, 0.4);
    }
    ${props => props.reAssign ? `
    width: fit-content;
    margin-left: auto;
    margin-top: 10px;
    ` : ''}
`

const SecondaryButton = styled.button`
    font-family: 'Inter';
    border: none;
    padding: 8px 14px;
    font-weight: 500;
    font-size: 14px;
    color: ${props => props.disabled ? '#CCCCCC' : TekieAmethyst};
    background: #FAF6FF;
    border-radius: 8px;
    cursor: pointer;
    letter-spacing: 0px;

    &:hover, &:focus{
        background: ${props => props.disabled ? '#F5F5F5' : '#F3EBFF'};
    }
    &:focus {
        box-shadow: 0px 2px 8px rgba(113, 62, 188, 0.24);
    }
`

const SecondaryText = styled.div`
    font-weight: 500;
    font-size: 13px;
    color: #333333;
`

const FooterText = styled.div`
    flex: 1 1;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
`

const GridContainer = styled.div`
display: grid;
justify-content: ${props => props.justify ? props.justify : 'space-between'};
align-items: center;
grid-template-columns: 47% 47%;
margin: 10px 0;
p{
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
margin: 0px;
color: #333333;
}
@media(max-width: 780px){
  grid-template-columns: 100%;
}
`

const Spinner = styled(({ isActive, ...rest }) => <Spin indicator={loadingIcon} {...rest} />)`
`

const CustomInput = styled(Input)`
  height: 44px;
  & .ant-input {
    height: 38px;
    font-size: 14px;
    border-radius: 8px;
    border: 1.5px solid #AAAAAA;
    letter-spacing: 0px;
    color: #666666;
    text-align: center !important;
  }
  & .ant-input:hover, & .ant-input:focus {
    border-color: ${TekieAmethyst} !important;
    box-shadow: none;
  }
  /* Chrome, Safari, Edge, Opera */
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  & input[type=number] {
    -moz-appearance: textfield;
  }
  & .anticon{
    font-size: 20px;
    opacity: 0.5;
  }
  .ant-input[disabled] {
    color: black !important;
    background-color: #ffffff !important;
    cursor: inherit !important;
  }
`

const ModalBox = styled.div`
    z-index: 1000;
    width: 520px;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    background: #FFFFFF;
    box-shadow: 0px 6px 48px rgba(51, 51, 51, 0.24);
    border-radius: 16px;
    transition: opacity 200ms ease-in-out, left 300ms ease-in-out, visibility .2s cubic-bezier(0.4,0.0,0.2,1);
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity: ${props => props.visible ? 1 : 0};
    left: ${props => props.visible ? '50%' : '51%'};
    overflow: hidden;
    box-sizing: border-box;
    font-family: 'Inter' !important;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 500px) {
        min-width: 100%;
        height: 100%;
        width: 100%;
    }
`

const Header = styled.div`
    width: auto;
    background: ${props => props.bgColor ? props.bgColor : '#FAF7FF'};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 12px 20px;
    border-radius: 16px;

    @media screen and (max-width: 700px) {
        padding: 0px;
    }
`

const CloseIcon = styled(({ isActive, ...rest }) => <Icon component={CloseSVG} {...rest} />)`
  transition: 0.3s all ease-in-out;
  font-size: ${props => props.fontSize ? props.fontSize : '26px'};
  fill: #666666;
  margin-right: 8px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 14px 10px;
  cursor: pointer;
`
const Content = styled.div`
    padding: 24px 24px 10px;
    overflow: hidden scroll;
    flex: 1;
    ${props => props.isSlotEditing ?
    `min-height: 45vh;
    max-height: 45vh;
    @media screen and (max-width: 700px) {
        min-height: 45vh;
        max-height: 45vh;
    }
    @media screen and (max-width: 500px) {
      min-height: inherit;
      max-height: inherit;
    }` :
    `min-height: 70vh;
    max-height: 70vh;
    @media screen and (max-width: 700px) {
      min-height: 70vh;
      max-height: 70vh;
    }
    @media screen and (max-width: 500px) {
      min-height: inherit;
      max-height: inherit;
    }`}
`
const Footer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #FFF;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 500px) {
        padding-bottom: 30px;
        position: fixed;
        bottom: 0;
    }
`

const InnerFooter = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #FFF;
    justify-content: space-between;
    padding: 16px;
    align-items: flex-start;
    box-shadow: 0px 0px 4px rgba(51, 51, 51, 0.15);
`

const HeaderIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 45px;
    background: ${props => props.bgColor ? props.bgColor : '#fff'};
    border-radius: ${props => props.borderRadius ? props.borderRadius : '10px'};
`


const HeaderDetails = styled.div`
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: Inter;
`
const HeaderTitle = styled.div`
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #000000;
`

const HeaderDescription = styled.div`
font-family: Inter;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 18px;
color: #333333;
opacity: 0.8;
`

const TabContainer = styled.div`
    background: #F5F5F5;
    border-radius: 6px;
    display: grid;
    grid-template-columns: ${props => props.isB2C ? '48% 48%' : '33% 33% 33%'};
    width: 90%;
    padding: 2px;
    max-width: 100%;
    margin: 20px auto 0 auto;
    justify-content: space-between;
    ${props => props.calendarView ? `
    margin: 0;
    width: 200px;
    height: 45px;
    background-color: #eee;
    border-radius: 8px;
` : ''}
`

const TabButton = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 5px 8px;
border-radius: 8px;
flex: none;
order: 1;
flex-grow: 0;
margin: 0px 2px;
cursor: pointer;
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
display: flex;
align-items: center;
background-color: transparent;
${props => props.isActive ? `
color: #8C61CB;
box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
background: #FFFFFF;
` : ''}
&.week,
&.day,
&.list{
  font-size: 0.8rem;
}
`

const StyledProfileIcon = styled(StyledIcon)`
border-radius: 50px;
border: 0.5px solid lightgray;
font-size: 10px;
height: 20px;
width: 20px;
& .anticon{
  height: 20px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
`

DemandSupplyDashboard.Select = CustomSelect
DemandSupplyDashboard.Option = SelectOption
DemandSupplyDashboard.SelectWrapper = SelectWrapper
DemandSupplyDashboard.Checkbox = StyledCheckbox
DemandSupplyDashboard.FlexContainer = FlexContainer
DemandSupplyDashboard.CalendarContainer = CalendarContainer
DemandSupplyDashboard.TopBarContainer = TopBarContainer
DemandSupplyDashboard.TopicThumbnail = TopicThumbnail
DemandSupplyDashboard.HeaderSessionIndicator = HeaderSessionIndicator
DemandSupplyDashboard.PreHeaderText = PreHeaderText
DemandSupplyDashboard.HeaderTag = HeaderTag
DemandSupplyDashboard.Icon = StyledIcon
DemandSupplyDashboard.ContentClassDetail = ContentClassDetail
DemandSupplyDashboard.Text = Text
DemandSupplyDashboard.CustomDivider = CustomDivider
DemandSupplyDashboard.SearchInput = SearchInput
DemandSupplyDashboard.PrimaryButton = PrimaryButton
DemandSupplyDashboard.SecondaryButton = SecondaryButton
DemandSupplyDashboard.SecondaryText = SecondaryText
DemandSupplyDashboard.FooterText = FooterText
DemandSupplyDashboard.GridContainer = GridContainer
DemandSupplyDashboard.Spinner = Spinner
DemandSupplyDashboard.CustomInput = CustomInput
DemandSupplyDashboard.StyledRadioGroup = StyledRadioGroup
DemandSupplyDashboard.ModalBox = ModalBox
DemandSupplyDashboard.Header = Header
DemandSupplyDashboard.CloseIcon = CloseIcon
DemandSupplyDashboard.ModalContent = Content
DemandSupplyDashboard.Footer = Footer
DemandSupplyDashboard.InnerFooter = InnerFooter
DemandSupplyDashboard.HeaderIcon = HeaderIcon
DemandSupplyDashboard.HeaderTitle = HeaderTitle
DemandSupplyDashboard.HeaderDetails = HeaderDetails
DemandSupplyDashboard.HeaderDescription = HeaderDescription
DemandSupplyDashboard.TabContainer = TabContainer
DemandSupplyDashboard.TabButton = TabButton
DemandSupplyDashboard.StyledProfileIcon = StyledProfileIcon

export default DemandSupplyDashboard
