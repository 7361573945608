import React from 'react'
import { StyledSpeakerAndPrizesContainer, StyledAddBtn, StyledButton, StyledBackButton } from "./SpeakerPrizes.style"
import { PlusOutlined } from '@ant-design/icons';
import { Icon, notification, Switch } from 'antd'
import SpeakerFormAndImage from './components/SpeakerFormAndImage';
import PrizeFormAndImage from './components/PrizeFormAndImage';
import styles from './SpeakerPrizes.module.scss'
// import AddSpeakerProfileModal from './components/AddSpeakerProfileModal';
import { StyledFooter } from './SpeakerPrizes.style';
import { Link } from 'react-router-dom';
import EventsStyles, { Event } from '../createEvent.style';
import EventNavigation from '../Components/EventNavigation';
import addEventPrize from '../../../actions/eventDashboard/addEventPrize';
import updateEvent from '../../../actions/eventDashboard/updateEvent';
import { get } from 'lodash';
import SpeakerProfileModal from '../EventSettings/Speakers/SpeakerProfileModal';
import fetchSingleEvent from '../../../actions/eventDashboard/fetchSingleEvent';
import PageLoader from '../Components/PageLoader';
import removeFromEventAction from '../../../actions/eventDashboard/removeFromEventAction';


class SpeakerPrizes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            speakerForms: [{ name: '', email: '', phone: { countryCode: '', number: '' }, linkedInLink: '', about: '', organization: '', roleAtOrganization: '', profilePic: '' }],
            prizeForms: [{ title: '', image: '', minRank: 1, maxRank: '' }],
            allSelectedSpeakers: [],
            allSelectedPrizes: [],
            error: null,
            isFetching: false,
            eventsData: null,
            showPrizes: false,
        }
    }
    componentDidMount = () => {
        const eventIdFromRoute = get(this.props, 'match.params.id')
        if (eventIdFromRoute) {
            this.setState({
                isFetching: true
            })
            fetchSingleEvent(eventIdFromRoute, 'speakerPrizes').then(res => {
                const allSelectedSpeakers = get(res, 'event.speakers', []).map(speaker => get(speaker, 'id'))
                let speakerForms = get(res, 'event.speakers', []).map(speaker => ({ key: speaker }))

                const modifiedSpeakerForms = []
                if (speakerForms.length) {
                    for (let { key } of speakerForms) {
                        modifiedSpeakerForms.push({ name: get(key, 'user.name'), email: get(key, 'user.email'), phone: { countryCode: get(key, 'user.phone.countryCode'), number: get(key, 'user.phone.number') }, linkedInLink: get(key, 'linkedInLink'), about: get(key, 'about'), organization: get(key, 'organization'), roleAtOrganization: get(key, 'roleAtOrganization'), profilePic: get(key, 'user.profilePic.uri') })
                    }
                } else {
                    modifiedSpeakerForms.push({ name: '', email: '', phone: { countryCode: '', number: '' }, linkedInLink: '', about: '', organization: '', roleAtOrganization: '', profilePic: '' })
                }

                let prizeForms = get(res, 'event.prizes', []).map(prize => {
                    return {
                        ...prize, prevImage: get(prize, 'image'), image: get(prize, 'image.uri')
                    }
                })
                if (!speakerForms.length) {
                    speakerForms = [{ name: '', email: '', phone: { countryCode: '', number: '' }, linkedInLink: '', about: '', organization: '', roleAtOrganization: '', profilePic: '' }]
                }
                if (!prizeForms.length) {
                    prizeForms = [{ title: '', image: '', minRank: 1, maxRank: '' }]
                }
                this.setState({
                    isFetching: false,
                    eventsData: get(res, 'event'),
                    allSelectedSpeakers,
                    speakerForms: modifiedSpeakerForms,
                    eventsData: get(res, 'event'),
                    prizeForms,
                    showPrizes: get(res, 'event.prizes', []).length
                })
            })
        }
    }

    setAllSelectedSpeakers = (value, idx) => {
        const { allSelectedSpeakers } = this.state
        const allSpeakersCopy = [...allSelectedSpeakers]
        if (allSelectedSpeakers[idx]) {
            const allSelectedSpeakersCopy = [...allSelectedSpeakers]
            allSelectedSpeakersCopy[idx] = value
            this.setState({
                allSelectedSpeakers: allSelectedSpeakersCopy
            }, () => {
                const { eventsData } = this.state
                const removedSpeaker = allSpeakersCopy.filter(speaker => !this.state.allSelectedSpeakers.includes(speaker))
                const inSpeakers = get(eventsData, 'speakers', []).find(speaker => get(speaker, 'id') === get(removedSpeaker, `[${0}]`))
                if (inSpeakers && get(inSpeakers, 'id')) {
                    removeFromEventAction(get(eventsData, 'id'), '', get(inSpeakers, 'id'), 'speaker')
                }
            })
        } else {
            this.setState({
                allSelectedSpeakers: [...allSelectedSpeakers, value]
            }, () => {
                const { eventsData } = this.state
                const removedSpeaker = allSpeakersCopy.filter(speaker => !this.state.allSelectedSpeakers.includes(speaker))
                const inSpeakers = get(eventsData, 'speakers', []).find(speaker => get(speaker, 'id') === get(removedSpeaker, `[${0}]`))
                if (inSpeakers && get(inSpeakers, 'id')) {
                    removeFromEventAction(get(eventsData, 'id'), '', get(inSpeakers, 'id'), 'speaker')
                }
            })
        }
    }
    setSpeakerForms = (value, idx) => {

        const { about, linkedInLink, organization, roleAtOrganization, user: { name, email, phone, profilePic } } = value
        const updateSpeakerForms = this.state.speakerForms.map((form, index) => {
            if (index === idx) {
                return { name, email, about, linkedInLink, roleAtOrganization, organization, phone: { ...phone }, profilePic: get(profilePic, 'uri') }
            } return form
        })
        this.setState({
            speakerForms: updateSpeakerForms
        })
    }
    setAllPrizes = (value) => {
        this.setState({
            prizeForms: value
        })
    }

    async componentDidUpdate(prevProps, prevState) {
        const { eventSpeakerAddStatus, eventSpeakerAddFailure,
            userAddFailure, userAddStatus } = this.props

        if (eventSpeakerAddStatus && !get(eventSpeakerAddStatus.toJS(), 'loading')
            && get(eventSpeakerAddStatus.toJS(), 'success') &&
            (prevProps.eventSpeakerAddStatus !== eventSpeakerAddStatus)) {
            notification.success({
                message: `Event Speaker added successfully`
            })
            this.closeModal()

        } else if (eventSpeakerAddStatus && !get(eventSpeakerAddStatus.toJS(), 'loading')
            && get(eventSpeakerAddStatus.toJS(), 'failure') &&
            (prevProps.eventSpeakerAddFailure !== eventSpeakerAddFailure)) {
            if (eventSpeakerAddFailure && eventSpeakerAddFailure.toJS().length > 0) {
                const errors = eventSpeakerAddFailure.toJS().pop()
                notification.error({
                    message: get(get(errors, 'error').errors[0], 'message')
                })
            }
        }

        if (userAddStatus && !get(userAddStatus.toJS(), 'loading')
            && get(userAddStatus.toJS(), 'failure') &&
            (prevProps.userAddFailure !== userAddFailure)) {
            if (userAddFailure && userAddFailure.toJS().length > 0) {
                const errors = userAddFailure.toJS().pop()
                notification.error({
                    message: get(get(errors, 'error').errors[0], 'message')
                })
            }
        }
    }

    addNewSpeaker() {
        const { speakerForms } = this.state
        if (!speakerForms[speakerForms.length - 1].name) {
            return notification.error({ message: 'Please fill existing form' })
        }
        if (speakerForms.length < 5) {
            this.setState({
                speakerForms: [...this.state.speakerForms, { name: '', email: '', phone: { countryCode: '', number: '' }, linkedInLink: '', about: '', organization: '', roleAtOrganization: '', profilePic: '' }]
            })
        }
    }

    addNewPrize() {
        const { prizeForms } = this.state
        const isMaxRankLimitReached = prizeForms.find(form => form.maxRank === 100)
        if (isMaxRankLimitReached) {
            this.props.notification.error({ message: 'Max rank limit reached' })
            return
        }
        if (prizeForms.length < 5 && !isMaxRankLimitReached) {
            this.setState({
                prizeForms: [...prizeForms, {
                    title: '', image: '', minRank:
                        prizeForms[prizeForms.length - 1].maxRank ?
                            (prizeForms[prizeForms.length - 1].maxRank + 1) : '', maxRank: ''
                }]
            })
        }
    }

    removeForm = (formIndex, formType) => {

        const { speakerForms, prizeForms, allSelectedSpeakers } = this.state
        if (formType === 'speakerForms') {
            const filteredSpeaker = allSelectedSpeakers.find((form, index) => index === formIndex)

            const filteredSpeakersFormList = speakerForms.filter((form, idx) => idx !== formIndex)

            this.setState({
                speakerForms: filteredSpeakersFormList,
                allSelectedSpeakers: allSelectedSpeakers.filter((form, index) => index !== formIndex)
            }, () => {
                if (filteredSpeaker) {
                    const { eventsData } = this.state
                    const inSpeakers = get(eventsData, 'speakers', []).map(speaker => get(speaker, 'id')).includes(filteredSpeaker)
                    if (inSpeakers) {
                        removeFromEventAction(get(eventsData, 'id'), '', filteredSpeaker, 'speaker')
                    }
                }
            })
        }
        if (formType === 'prizeForms') {
            const filteredPrize = prizeForms.find((form, index) => index === formIndex)
            const filteredPrizeFormList = prizeForms.filter((form, idx) => idx !== formIndex)
            this.setState({
                prizeForms: filteredPrizeFormList
            }, () => {
                if (filteredPrize) {
                    const { eventsData } = this.state
                    const prizeId = get(eventsData, 'prizes', []).map(prize => get(prize, 'id')).includes(get(filteredPrize, 'id'))
                    if (prizeId) {
                        removeFromEventAction(get(eventsData, 'id'), '', get(filteredPrize, 'id'), 'prizeContent')
                    }
                }
            })
        }
    }
    closeModal = () => {
        this.setState({
            visible: false
        })
    }

    isPrizeFormEmpty = (prizeForms) => {
        for (const form of prizeForms) {

            if (!form.title || !form.minRank || !form.maxRank || !form.image) {

                return true
            }
        }
        return false
    }

    validateForm = () => {
        const { allSelectedSpeakers, prizeForms, showPrizes } = this.state
        const isMaxRankPresentBeforeLastForm = prizeForms.find((form, idx) => form.maxRank === 100 && idx !== prizeForms.length - 1)
        if (showPrizes && isMaxRankPresentBeforeLastForm) {
            this.props.notification.error({ message: 'Rank 100 only allowed on last prize' })
            return false
        }
        if ([2, 4].includes(prizeForms.length)) {
            this.props.notification.error({ message: 'Allowed prize can be 1, 3 or 5' })
            return false
        }
        if (allSelectedSpeakers.length === 0) {
            this.setState({ error: true })
            return false
        } else if (showPrizes && this.isPrizeFormEmpty(prizeForms)) {
            this.setState({ error: true })
            return false
        } else {
            this.setState({ error: false })
            return true
        }
    }

    handleFormSubmit = async () => {

        if (this.validateForm()) {
            try {
                const { prizeForms, allSelectedSpeakers, showPrizes } = this.state
                const prizesId = []
                if (showPrizes) {
                    for (let i = 0; i < prizeForms.length; i++) {
                        const prize = prizeForms[i]
                        if (get(prize, 'id')) {
                            const res = await addEventPrize(prizeForms[i], get(prize, 'id'))
                            if (get(res, 'updateEventPrize.id')) prizesId.push(get(res, 'updateEventPrize.id'))
                        } else {
                            const res = await addEventPrize(prizeForms[i])
                            if (get(res, 'addEventPrize.id')) prizesId.push(get(res, 'addEventPrize.id'))
                        }
                    }
                }

                let eventPrizesConnectIds = this.props.fetchedEventPrizes
                eventPrizesConnectIds = eventPrizesConnectIds && eventPrizesConnectIds.toJS()
                eventPrizesConnectIds = eventPrizesConnectIds.map(item => item.id)
                await updateEvent({
                    speakersConnectIds: allSelectedSpeakers, prizesConnectIds: prizesId
                }, { eventId: this.props.match.params.id })
                if (this.props.hasUpdatedEvent) {
                    const updatedEvent = this.props.fetchedEvents.toJS()
                    this.props.history.push(`/eventDashboard/events/${get(updatedEvent[0], 'id')}/tickets`)
                }
                if (this.props.hasUpdatedEventFailed) {
                    notification.error({ message: 'Error Updating Event!' })
                }
            } catch (error) {
                notification.error({ message: 'Error updating event' })
            }
        }
    }

    render() {

        const { isUpdatingEvent, isAddingEventPrize, isUpdatingEventPrize } = this.props
        const { speakerForms, prizeForms,
            visible, allSelectedSpeakers, error, isFetching } = this.state
        const { id } = this.props.match.params
        const currentPath = this.props.match.path.split('/')
        const currentNav = currentPath[currentPath.length - 1]
        return <Event.EventsWrapper>
            <PageLoader isfetching={isFetching} />
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <EventsStyles.PageTitle style={{ marginLeft: '15px' }}>Create Event</EventsStyles.PageTitle>
                {id && <EventNavigation id={id} isCurrent={currentNav} />}
            </div>
            {/* <EventsStyles.PageTitle style={{ marginLeft: '15px' }}>Create Event</EventsStyles.PageTitle> */}
            <StyledSpeakerAndPrizesContainer>
                <div className={styles.titleContainer}>
                    <Icon type='sound' theme='filled' className={styles.soundIcon} />
                    <div style={{ flexGrow: '2' }}>
                        <h1>Select Speaker</h1>
                        <p>Select/Create speaker for this event</p>
                    </div>
                    <StyledButton alignSelfCenter onClick={() => this.setState({ visible: true })}>Create New Speaker</StyledButton>
                </div>
                {
                    !isFetching && speakerForms.map((form, idx) =>
                        <SpeakerFormAndImage error={error}
                            speakersData={this.props.speakersData}
                            removeForm={this.removeForm}
                            speakerForm={speakerForms[idx]}
                            allSelectedSpeakers={allSelectedSpeakers}
                            setAllSelectedSpeakers={this.setAllSelectedSpeakers}
                            form={form} key={idx} location={idx}
                            isFetchingAllSpeakers={this.props.isFetchingAllSpeakers}
                            setSpeakerForms={this.setSpeakerForms}
                        />)
                }

                <div className={styles.addBtnContainer}>

                    <StyledAddBtn onClick={() => this.addNewSpeaker()}>
                        <PlusOutlined style={{ fontSize: '1.2rem', fontWeight: 'bolder' }} />
                    </StyledAddBtn>
                    <span style={{ flexGrow: '2' }}>Add more speakers...(upto 5 can be added)</span>

                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className={styles.titleContainer}>
                        <Icon type='gift' theme='filled' className={styles.soundIcon} />
                        <div>
                            <h1>Prizes</h1>
                            <p>Add the prizes for this event</p>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingLeft: '30px' }}>
                        <div className='toggleText'>OFF</div>
                        <Switch style={this.state.showPrizes ? { backgroundColor: '#0ca789' } : { backgroundColor: 'grey' }}
                            onChange={() => this.setState({ showPrizes: !this.state.showPrizes })}
                            checked={Boolean(this.state.showPrizes)}
                        />
                        <div className='toggleText'>ON</div>
                    </div>
                </div>

                {
                    (this.state.showPrizes && !isFetching) ? prizeForms.map((form, idx) =>
                        <PrizeFormAndImage
                            formError={error}
                            allSelectedPrizes={this.state.allSelectedPrizes}
                            setAllPrizes={this.setAllPrizes}
                            prizeForms={prizeForms}
                            form={form}
                            key={idx}
                            removeForm={this.removeForm}
                            location={idx}
                        />) : null
                }
                {
                    this.state.showPrizes ?
                    <div className={styles.addBtnContainer}>
                        <StyledAddBtn onClick={() => this.addNewPrize()}>
                            <PlusOutlined style={{ fontSize: '1.2rem', fontWeight: 'bolder' }} />
                        </StyledAddBtn>
                        <p>Add more prizes...(upto 5 can be added)</p>
                    </div> : null
                }
                

            </StyledSpeakerAndPrizesContainer>
            <StyledFooter>
                <StyledBackButton mRight onClick={() => this.props.history.goBack()}>
                    Back
                </StyledBackButton>
                <StyledButton
                    loading={isUpdatingEvent || isAddingEventPrize || isUpdatingEventPrize}
                    disabled={isUpdatingEvent || isAddingEventPrize || isUpdatingEventPrize}
                    onClick={this.handleFormSubmit}
                >
                    {(isUpdatingEvent || isAddingEventPrize || isUpdatingEventPrize) ? 'Saving...' : ' Save & Continue'}
                </StyledButton>

            </StyledFooter>

            <SpeakerProfileModal
                visible={this.state.visible}
                closeModal={this.closeModal}
                modalOperation='add'
                speakerData={{}}
            />
        </Event.EventsWrapper>
    }
}
export default SpeakerPrizes