// import React from 'react'
import styled, { css } from 'styled-components'
import { Button, Card } from 'antd'
import colors from '../../../constants/colors'
import antdButtonColor from '../../../utils/mixins/antdButtonColor'
import { Table } from 'antd'
import { SearchOutlined, PlusOutlined } from '@ant-design/icons'
// import Modal from '../components/Modal.styles'
import getFullPath from '../../../utils/getFullPath'
// import { TekieOrange } from '../../../constants/colors'
// import Settings from '.'

const EVENT_BUTTON_COLOR = '#404AB2'

const SettingsTab = styled.div`
    height: 100%;
    width: 100%;
    margin: 0 auto;
    font-family: 'Inter' !important;
`
const TopContainer = styled.div`
    padding-left: 10px;
    padding-right: 10px;
`
const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    width: 100%;
    min-height: 640px;
    padding: 0.5rem 0.25rem 0.5rem 0.25rem;
    margin: 0 auto;
    margin-top: 1rem;
    overflow-x: hidden;
`
const Title = styled.div`
    font-weight: 600;
    font-size: 24px;
    color: #212121;
`
const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
`
const IconCommonCss = css`
    height: 1rem;
    width: 0.75rem;
    z-index: 10;
`
const AddButtonContainer = styled.div`
    display: block;
`
const AddButtonIcon = styled(PlusOutlined)`
    strokeWidth: 100;
    stroke: white;
`
const AddButton = styled(Button)`
    &&& {
        ${antdButtonColor(EVENT_BUTTON_COLOR)}
        padding-top: 2px;
        color: #fff;
        font-size: 11px;
        margin-left: 1.25rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: fit-content;
    }
`
const AllTabsContainer = styled.div`
    width: 100%;
`
const AllTabsList = styled.div`
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`
const TabContainer = styled.div`
    margin: 0.25rem 0.75rem;
    font-size: ${props => props.isActive ? '1.10rem' : '1.15rem'};
    font-weight: ${props => props.isActive ? '750' : '350'};
    color: ${props => props.isActive ? '#2D9AF1' : '#1B1B1A'};

    &:hover {
        cursor: pointer;
    }
`
const TabSeparator = styled.div`
    margin: 0rem 0.20rem;
    font-size: 1.75rem;
`

const SpeakerCardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 0.75rem;
`
const SpeakerCardTab = styled.div`
    overflow-x: scroll;
`
const SpeakerCard = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin: 4px 0px;
`
const SpeakerCardNumber = styled.div`
    padding-left: 0.5rem;
    font-size: 1.25rem;
`
const SpeakerImageContainer = styled.div`
    margin: 0rem 0.5rem;
    min-width: 165px;
    max-width: 165px;
    height: 120px;
    padding: 10px;
`
const SpeakerCardImg = styled.img`
    width: 100%;
    height: 100%;
`
const SpeakerCardDetails = styled.div`
    display: grid;
    grid-column-gap: 2.25rem;
    grid-row-gap: 1.76rem;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto;
`
const SpeakerCardInfoCard = styled.div`
    display: flex;
    min-width: 200px;
    max-width: 200px;
    height: 36px;
    border: 0.2px solid #D1D1D1;
    border-radius: 0.5px;
    padding: 2px 6px 2px 6px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    overflow: hidden
`
const SpeakerCardInfoTitle = styled.div`
    font-family: 'Inter';
    color: #979595;
    font-weight: 750;
    font-size: 12px;
`
const SpeakerCardInfoDetails = styled.div`
    font-family: 'Inter';
    color: rgb(0 0 0);
    font-size: 8px;
    font-weight: bold;
    width: 96%;
    overflow-x: hidden;
    overflow-y: hidden;
`
const SpeakerCardEditButton = styled(Button)`
    &&& {
        ${antdButtonColor(EVENT_BUTTON_COLOR)}
        color: #fff;
        margin-left: 1.75rem;
        font-size: 12px;
        width: fit-content;
    }
`
const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    width: 100%;
`
const TagContainer = styled.div`
    margin-top: 0.75rem;
    
`
const TagTable = styled(Table)`
    & .ant-table-body{
        display: flex;
        justify-content: center;
    }
    & .ant-table-content{
        color: #122b4a;
        background-color: #ffffff;
        border-radius:6px;
    }
    & .ant-table-tbody > tr > td {
        border-bottom: 0px solid #fff:
    }
    & .ant-table-thead > tr > th{
        background-color: #fcfcfc;
        margin: 0 0 78px 1px !important;
        font-weight: 600;
        color: #122b4a;
    }
    & tbody > tr{
        background-color: #fcfcfc;
    }
    & .ant-table-content .ant-table-body .ant-table-tbody .antdTable-child-row > td {
        padding: 8px 0px;
    }
`
const TagNameContainer = styled.div`
    text-align: center;
    padding: 0.25rem;
    border: 1px solid grey;
    border-radius: 4px;
`

const TagTab = styled.div`
`

const StyledVariablesTable = styled(Table)`
& .ant-table-body{
    display: flex;
    justify-content: center;
}

& .ant-table-content{
    color: #122b4a;
    background-color: #ffffff;
    border-radius:6px;
}
& .ant-table-tbody > tr > td {
    border-bottom: 0px solid #fff;
}
& .ant-table-thead > tr > th{
    background-color: rgba (18, 43, 74, 0.17);
    margin: 0 0 78px 1px !important;
    font-weight: 600;
    color: #122b4a;
}
& tbody > tr{
    background-color: #fcfcfc;
}
& .ant-table-content .ant-table-body .ant-table-tbody .antdTable-child-row > td {
    padding: 8px 0px;
}
& tbody > tr .anticon-delete svg{
    width: 16.8px;
    height: 22.2px;
    color: ${colors.table.deleteIcon};
}
`
const StyledVariableName = styled.div`
width:150px;
padding: 0.25rem;
text-align: center;
font-weight: bolder;
border: 1px solid black;
border-radius: 4px;
`
SettingsTab.FlexContainer = FlexContainer
SettingsTab.TopContainer = TopContainer
SettingsTab.Title = Title

AddButtonContainer.AddButton = AddButton
AddButtonContainer.AddButtonIcon = AddButtonIcon

AllTabsContainer.AllTabsList = AllTabsList
AllTabsContainer.TabContainer = TabContainer
AllTabsContainer.TabSeparator = TabSeparator

SpeakerCardTab.SpeakerCard = SpeakerCard
SpeakerCardTab.SpeakerCardNumber = SpeakerCardNumber
SpeakerCardTab.SpeakerCardImg = SpeakerCardImg
SpeakerCardTab.SpeakerImageContainer = SpeakerImageContainer
SpeakerCardTab.SpeakerCardDetails = SpeakerCardDetails
SpeakerCardTab.SpeakerCardInfoCard = SpeakerCardInfoCard
SpeakerCardTab.SpeakerCardInfoTitle = SpeakerCardInfoTitle
SpeakerCardTab.SpeakerCardInfoDetails = SpeakerCardInfoDetails
SpeakerCardTab.SpeakerCardEditButton = SpeakerCardEditButton

TagTab.TagTable = TagTable
TagTab.TagNameContainer = TagNameContainer
TagTab.TagContainer = TagContainer

export {
    SettingsTab,
    InnerContainer,
    AddButtonContainer,
    AddButton,
    AllTabsContainer,
    SpeakerCardsContainer,
    SpeakerCardTab,
    PaginationContainer,
    TagTab,
    TagTable,
    StyledVariablesTable,
    StyledVariableName
}