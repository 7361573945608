import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import withUpdatedDashboardNav from '../../../components/withUpdatedNav'
import { EVENT_DASHBOARD } from '../../../constants/navItemsConst'
import injectProps from '../../../components/injectProps'
import EventDescription from './EventDescription'

const EventDescriptionSideNav = withUpdatedDashboardNav(EventDescription)({
    title: 'Event Overview',
    activeNavItem: 'Events',
    showUMSNavigation: true,
    renderNavItems: EVENT_DASHBOARD,
})

const mapStateToProps = state => ({
    fetchedEventJoinReasons: state.data.getIn(['eventJoinReasons', 'data']),
    isAddingEventJoinReasons: state.data.getIn(['eventJoinReasons', 'addStatus', 'eventJoinReasons', 'loading']),
    hasAddedEventJoinReasons: state.data.getIn(['eventJoinReasons', 'addStatus', 'eventJoinReasons', 'success']),
    hasAddedEventJoinReasonsFailed: state.data.getIn(['eventJoinReasons', 'addStatus', 'eventJoinReasons', 'failure']),
    fetchedEvents: state.data.getIn(['events', 'data']),
    isUpdatingEvent: state.data.getIn(['events', 'updateStatus', 'events', 'loading']),
    hasUpdatedEvent: state.data.getIn(['events', 'updateStatus', 'events', 'success']),
    hasUpdatedEventFailed: state.data.getIn(['events', 'updateStatus', 'events', 'failure']),
    isUpdatingEventJoinReasons: state.data.getIn(['eventJoinReasons', 'updateStatus', 'eventJoinReasons', 'loading']),
})


const EventDescriptionSideNavWithExtraProps = injectProps({
    notification
})(EventDescriptionSideNav)
export default connect(mapStateToProps)(withRouter(EventDescriptionSideNavWithExtraProps))
