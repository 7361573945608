/*eslint-disable*/
import { Select, Radio, Icon, Tooltip } from "antd";
import { Form, Formik } from "formik";
import { get } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { removeFromCourseComponent } from "../../../../../actions/contentMaker";
import {
  decodeBase64,
  encodeBase64,
  isBase64,
} from "../../../../../utils/base64Utility";
import { getSelectedValues } from "../../../../../utils/data-utils";
import { PublishInput } from "../../../ContentLearningObjective/components/LoForms/FormElements";
import {
  AssignmentContainer,
  StyledButton,
  StyledCol,
  StyledRow,
  StyledCodeContainer,
  StyledCodeInput,
} from "../../ContentAssignment.style";
import { DifficultySlider, Input, addAssignmentSchema } from "./FormElements";
import getDecodedStatement from "../../../../../utils/getDecodedStatement";
import { ContentEditor } from "../../../../../components/ContentEditor";
import editors from "../utils/editorModes";
import parseChatStatement from "../../../../../utils/parseStatement";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-dracula";
import StyledModal from "../../../../QuestionBank/CommonSection/CommonSection.style";
import { BlocklyWorkspace } from "tekie-blockly";
import Blockly from "blockly";
import { BLOCKLY_PLAYGROUND_THEME } from "../../../../../utils/blocklyUtils";
const EditAssignmentForm = (props) => {
  const {
    editFormData,
    updateLoading,
    handleEditAssignment,
    coursesList,
    currentComponent,
  } = props;

  const editFormRef = useRef();
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [isInitialCodeNeeded, setIsInitialCodeNeeded] = useState(false);
  const [currentCheckedInput, setCurrentCheckedInput] = useState(1);
  const [xmlData, setXmlData] = useState("");

  const onSelect = (value) => {
    setSelectedCourses([...selectedCourses, value]);
  };

  const onSelectInitialCode = (e) => {
    if (e.target.value === 1) {
      setIsInitialCodeNeeded(true);
      setCurrentCheckedInput(1);
    } else {
      setIsInitialCodeNeeded(false);
      setCurrentCheckedInput(2);
    }
  };

  useEffect(() => {
    const newSelectedCourse = getSelectedValues(
      editFormData,
      coursesList,
      "courses"
    );
    setSelectedCourses(newSelectedCourse);
    if (get(editFormData, "initialCode")) {
      setXmlData(
        isBase64(get(editFormData, "initialCode"))
          ? decodeBase64(get(editFormData, "initialCode"))
          : decodeURIComponent(get(editFormData, "initialCode"))
      );
      setIsInitialCodeNeeded(true);
      setCurrentCheckedInput(1);
    } else setCurrentCheckedInput(2);
  }, [editFormData.id]);

  const onDeselect = (value) => {
    const newCourse = selectedCourses.filter(
      (course) => get(course, "key") !== get(value, "key")
    );
    const addedCourses = get(editFormData, "courses", []).map((course) =>
      get(course, "id")
    );
    if (addedCourses.includes(get(value, "key"))) {
      removeFromCourseComponent(
        get(value, "key"),
        get(editFormData, "id"),
        currentComponent
      );
    }
    setSelectedCourses(newCourse);
  };

  const onSubmit = (value, meta) => {
    handleEditAssignment(value, meta, selectedCourses);
  };

  const checkTextType = (value) => {
    if (isBase64(value)) {
      return getDecodedStatement(value);
    } else return value;
  };

  const getCodeSnippetValue = (value) => {
    if (value) {
      try {
        return decodeURIComponent(value);
      } catch (e) {
        if (e && e.message && e.message.includes("URI malformed"))
          console.log(
            "SAFE CHECK: URL was not encoded, returning string as it is..."
          );
        else console.log(e);
        return value;
      }
    }
    return "";
  };

  const radioStyle = {
    display: "inline",
    height: "30px",
    lineHeight: "30px",
  };

  const iconStyle = {
    padding: "5px",
    borderRadius: "999px",
    cursor: "pointer",
    border: "1px solid black",
    margin: "0 5px",
  };
  const saveXML = (workspace) => {
    const xml = Blockly.Xml.workspaceToDom(Blockly.mainWorkspace);
    const xml_text = Blockly.Xml.domToText(xml);
    return xml_text;
  };

  const workspaceConfiguration = {
    readOnly: false,
    grid: false,
    move: {
      scrollbars: {
        horizontal: true,
        vertical: true,
      },
      drag: true,
      wheel: true,
    },
    zoom: {
      controls: true,
      wheel: true,
      startScale: 1.0,
      maxScale: 2,
      minScale: 0.3,
      scaleSpeed: 1.2,
      pinch: true,
    },
    trashcan: true,
  };

  const filterOption = (input, option) =>
    get(option, "props.children.props.children")
      ? get(option, "props.children.props.children")
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      : false;

  return (
    <Formik
      initialValues={editFormData}
      onSubmit={onSubmit}
      validateOnBlur
      validationSchema={addAssignmentSchema}
      innerRef={editFormRef}
    >
      {({ values, handleChange, setFieldValue, errors, setValues }) => (
        <Form style={{ padding: "0 10px" }} id="form">
          <div>
            <ContentEditor
              id={get(editFormData, "id")}
              setFieldValue={setFieldValue}
              inputRef="statement"
              initialValue={checkTextType(get(editFormData, "statement"))}
              name="statement"
              type="text"
              title="Question statement"
              handleChange={handleChange}
              inputValue={
                (!isBase64(values.statement) && values.statement) || ""
              }
              init={{
                height: 250,
                selector: `QA-${get(editFormData, "id")}`,
                placeholder: "Start adding your question title here...",
              }}
              onChange={(_, editor) => {
                editFormRef.current.setFieldValue(
                  "statement",
                  encodeBase64(editor.getContent())
                );
              }}
            />
          </div>
          {get(editFormData, "questionCodeSnippet", "") && (
            <Input
              label="Question Code Snippet"
              placeholder="Question Code Snippet"
              name="questionCodeSnippet"
              type="text"
              textArea
              value={getCodeSnippetValue(values.questionCodeSnippet)}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              inputRef="questionCodeSnippet"
            />
          )}

          <AssignmentContainer
            style={{ flexDirection: "column", alignItems: "unset" }}
          >
            <h3>Select Editor</h3>
            <Select
              placeholder="Select Editor"
              value={values.editorMode}
              onSelect={(value) => setFieldValue("editorMode", value)}
              onDeselect={() => setFieldValue("editorMode", "")}
              style={{ width: "100%" }}
            >
              {editors.map((item) => (
                <Select.Option value={get(item, "key")} key={get(item, "key")}>
                  {get(item, "name")}
                </Select.Option>
              ))}
            </Select>
          </AssignmentContainer>
          <AssignmentContainer style={{ justifyContent: "flex-start" }}>
            <h3>Add Initial Code </h3>
            <Radio.Group
              onChange={(e) => {
                onSelectInitialCode(e);
                return e.target.value === 2
                  ? setFieldValue("initialCode", "")
                  : null;
              }}
              value={currentCheckedInput}
            >
              <Radio style={radioStyle} value={1}>
                Yes
              </Radio>
              <Radio style={radioStyle} value={2}>
                No
              </Radio>
            </Radio.Group>
          </AssignmentContainer>
          {isInitialCodeNeeded &&
            (values.editorMode === "blockly" ? (
              <div
                style={{ width: "100%", height: "500px", marginBottom: "20px" }}
              >
                <BlocklyWorkspace
                  useDefaultToolbox
                  workspaceConfiguration={workspaceConfiguration}
                  customTheme={BLOCKLY_PLAYGROUND_THEME}
                  onWorkspaceChange={(workspace) => {}}
                  onInject={(e) => {}}
                  onXmlChange={(workspace) => {
                    const xmld = saveXML(workspace);
                    return setFieldValue("initialCode", encodeBase64(xmld));
                  }}
                  initialXml={xmlData}
                />
              </div>
            ) : (
              <StyledRow>
                <StyledCol span={24}>
                  <StyledCodeContainer>
                    <StyledCodeInput>
                      <AceEditor
                        mode={values.editorMode}
                        value={getCodeSnippetValue(get(values, "initialCode"))}
                        setOptions={{
                          showLineNumbers: false,
                          highlightActiveLine: true,
                          wrapBehavioursEnabled: true,
                          highlightGutterLine: true,
                          highlightSelectedWord: true,
                          enableBasicAutocompletion: true,
                          enableSnippets: true,
                          enableLiveAutocompletion: true,
                          showGutter: false,
                          displayIndentGuides: true,
                        }}
                        wrapEnabled
                        theme="dracula"
                        name="editor"
                        readOnly={false}
                        placeholder="//Start  Your  Code from here"
                        onChange={(code) => setFieldValue("initialCode", code)}
                        style={{
                          top: "8px",
                          height: "50%",
                          fontSize: "20px",
                          lineHeight: "12px",
                          backgroundColor: "#002a38",
                          width: "50vw",
                        }}
                        editorProps={{ $blockScrolling: true }}
                      />
                    </StyledCodeInput>
                  </StyledCodeContainer>
                </StyledCol>
              </StyledRow>
            ))}
          {values.editorMode === "blockly" ? (
            <div
              style={{ width: "100%", height: "500px", marginBottom: "40px" }}
            >
              <h3>Answer Code Snippet</h3>
              <BlocklyWorkspace
                useDefaultToolbox
                workspaceConfiguration={workspaceConfiguration}
                customTheme={BLOCKLY_PLAYGROUND_THEME}
                onWorkspaceChange={(workspace) => {}}
                onInject={(e) => {}}
                onXmlChange={(workspace) => {
                  const xmld = saveXML(workspace);
                  return setFieldValue("answerCodeSnippet", encodeBase64(xmld));
                }}
                initialXml={
                  isBase64(get(editFormData, "answerCodeSnippet"))
                    ? decodeBase64(get(editFormData, "answerCodeSnippet"))
                    : get(editFormData, "answerCodeSnippet")
                }
              />
            </div>
          ) : (
            <Input
              label="Answer Code Snippet"
              placeholder="Answer Code Snippet"
              name="answerCodeSnippet"
              type="text"
              textArea
              value={getCodeSnippetValue(values.answerCodeSnippet)}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              inputRef="answerCodeSnippet"
            />
          )}
          <AssignmentContainer
            modalGrid
            style={{ gridTemplateColumns: "35% 35% 20%" }}
          >
            <DifficultySlider
              label="Difficulty"
              value={values.difficulty || ""}
              setFieldValue={setFieldValue}
              errors={errors}
            />
            <Input
              placeholder="Enter Order"
              inputStyles={{ marginBottom: "0" }}
              type="number"
              name="order"
              label="Order"
              value={values.order || ""}
              order
              values={values}
              setFieldValue={setFieldValue}
              onChange={(e) => handleChange(e)}
            />
            <PublishInput
              values={values}
              flex="column"
              setFieldValue={setFieldValue}
            />
          </AssignmentContainer>
          <div>
            <h2>Hints: </h2>
            {values.hints &&
              values.hints.map((hint, ind) => (
                <>
                  <h4>
                    Hint {ind + 1}{" "}
                    <Icon
                      type="close"
                      style={iconStyle}
                      onClick={() => {
                        setValues({
                          ...values,
                          hints: values.hints.filter((item) => item !== hint),
                        });
                      }}
                    />
                  </h4>
                  <Input
                    placeholder="Hint Pretext"
                    name={`hints[${ind}].hintPretext`}
                    type="text"
                    textArea
                    value={hint.hintPretext || ""}
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                    inputRef={`hintPretext${ind}`}
                    isHintRef={true}
                    hintPretextIndex={ind}
                  />
                  <StyledModal.FormItem style={{ marginBottom: "10px" }}>
                    <ContentEditor
                      id={`888hint${ind}`}
                      placeholder="Enter the Hint"
                      name={`hints[${ind}].hint`}
                      type="text"
                      inputRef={`hint${ind}`}
                      setFieldValue={setFieldValue}
                      initialValue={checkTextType(
                        get(editFormData, `hints[${ind}].hint`, "")
                      )}
                      inputValue={hint.hint || ""}
                      handleChange={(e) => {
                        handleChange(e);
                      }}
                      init={{
                        height: 200,
                        selector: `QA-888hint${ind}`,
                        placeholder: "Enter the Hint",
                      }}
                      onChange={(_, editor) => {
                        editFormRef.current.setFieldValue(
                          `hints[${ind}].hint`,
                          encodeBase64(editor.getContent())
                        );
                      }}
                    />
                  </StyledModal.FormItem>
                </>
              ))}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title="Add new Hint">
                <Icon
                  type="plus"
                  style={iconStyle}
                  onClick={() => {
                    setValues({
                      ...values,
                      hints: [...values.hints, { hint: "", hintPretext: "" }],
                    });
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <ContentEditor
              id={`ex-777`}
              placeholder="Enter the Explanation"
              name={"explanation"}
              type="text"
              inputValue={values.explanation || ""}
              inputRef={`explanation`}
              setFieldValue={setFieldValue}
              handleChange={(e) => {
                handleChange(e);
              }}
              init={{
                height: 200,
                selector: `ex-777`,
                placeholder: "Enter the Explanation",
              }}
              onChange={(_, editor) => {
                setFieldValue("explanation", encodeBase64(editor.getContent()));
              }}
            />
          </div>

          <div style={{ marginBottom: "10px" }}>
            <h3>Select Courses</h3>
            <Select
              mode="multiple"
              labelInValue
              placeholder="Select Courses"
              filterOption={filterOption}
              value={selectedCourses}
              disabled
              onSelect={onSelect}
              onDeselect={onDeselect}
              style={{ width: "100%" }}
            >
              {coursesList.map((item) => (
                <Select.Option value={get(item, "id")} key={get(item, "id")}>
                  <Tooltip title={get(item, "title")}>
                    {get(item, "title")}
                  </Tooltip>
                </Select.Option>
              ))}
            </Select>
          </div>
          <AssignmentContainer justify="center">
            <StyledButton
              icon="file"
              id="add-btn"
              htmlType="submit"
              loading={updateLoading && get(updateLoading.toJS(), "loading")}
            >
              Update
            </StyledButton>
          </AssignmentContainer>
        </Form>
      )}
    </Formik>
  );
};

export default EditAssignmentForm;
