/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react'
import { Spin, Checkbox, Radio, notification, Rate, Dropdown, Menu } from 'antd'
import { get, sortBy } from 'lodash'
import { LoadingOutlined, DownOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons'
import gql from 'graphql-tag'
import moment from 'moment'
import fetchMentorMenteeSession from '../../../actions/mentorSessions/fetchMentorMenteeSession'
import fetchBatchSession from '../../../actions/mentorSessions/fetchBatchSession'
import fetchSalesOperationData from '../../../actions/mentorSessions/fetchSalesOperationData'
import addSalesOperationData from '../../../actions/ums/addSalesOperationData'
import updateMentorMenteeSession from '../../../actions/mentorSessions/updateMentorMenteeSessions'
import deleteMentorMenteeSession from '../../../actions/mentorSessions/deleteMentorMenteeSession'
import updateMentorSession from '../../../actions/mentorSessions/updateMentorSession'
import addMentorSession from '../../../actions/mentorSessions/addMentorSessions'
import fetchMentorSessions from '../../../actions/mentorSessions/fetchMentorSessions'
import rescheduleSession from '../../../actions/mentorSessions/rescheduleUpdate'
import addMentorMenteeSession from '../../../actions/mentorSessions/addMentorMenteeSession'
import { loadStateFromLocalStorage } from '../../../utils/localStorage'
import updateSalesOperationData from '../../../actions/ums/updateSalesOperationData'
import requestToGraphql from '../../../utils/requestToGraphql'
import ClassFeedbackStyle from './ClassFeedback.style'
import Modal from '../components/Modal.styles'
import { ClockSVG, TypeSVG, RatioSVG, BatchSVG } from '../../../constants/icons'
import { TekieAmethyst } from '../../../constants/colors'
import { getDuration } from '../../../utils/time'
import { getSlotTime, studentNotesData, iqaTags, b2cPaidFormConstants, b2cPaidFormKeyEveryFive, b2cPaidFormKeyEveryFiveHead } from '../utils'
import appConfig from '../../../config/appConfig'
import getIdArrForQuery from '../../../utils/getIdArrForQuery'
import { MENTOR } from '../../../constants/roles'
import fetchSessionLogs from '../../../actions/mentorSessions/fetchSessionLogs'
import fetchMentorMenteeSessions from '../../../actions/mentorSessions/fetchMentorMenteeSessions'
import updateBatchSession from '../../../actions/mentorSessions/updateBatchSessions'
import createBatchForB2B2C from './helpherMethods'
import BatchRescheduleModal from '../components/BatchRescheduleModal/BatchRescheduleModal'
import updateSchoolBatch from '../../../actions/SchoolOnboarding/updateSchoolBatch'
import deleteStudent from '../../../actions/batchUserMap/deleteStudent'
import deleteBatch from '../../../actions/batchDashboard/deleteBatch'

const loadingIcon = <LoadingOutlined style={{ fontSize: 16, marginRight: '8px', color: TekieAmethyst }} spin />
class MentorDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shouldReschedule: false,
      existingBatchCreated: [],
      demoCompleted: null,
      isSessionRescheduled: null,
      sessionNotConducted: null,
      rescheduledDateProvided: null,
      isFetching: true,
      showError: {},
      generalIssue: [],
      technicalIssue: [],
      otherIssue: [],
      batchSessionData: null,
      isShiftStudentModalVisible: false,
      ongoingRescheduleDetails: null,
      occupiedBatch: null,
      studentNote: '',
      studentNoteModalVisible: false,
      rescheduleHour: '',
      classStatus: [
        {
          label: 'Completed',
          key: 'demoCompleted',
          values: [
            { toShow: 'Yes', toSend: true },
            { toShow: 'No', toSend: false }
          ]
        },
        {
          label: 'Rescheduled',
          key: 'isSessionRescheduled',
          values: [
            { toShow: 'Yes', toSend: true },
            { toShow: 'No', toSend: false }
          ]
        },
        {
          label: 'Incomplete',
          key: 'sessionNotConducted',
          values: [
            { toShow: 'Yes', toSend: true },
            { toShow: 'No', toSend: false }
          ]
        },
      ],
      challenges: [
        {
          label: 'General Issues',
          key: 'generalIssue',
          values: [
            { toShow: 'Internet Issue', toSend: 'internetIssue' },
            { toShow: 'Google Chrome not installed', toSend: 'chromeIssue' },
            { toShow: 'Zoom not installed', toSend: 'zoomIssue' },
            { toShow: 'Power Cut', toSend: 'powerCut' },
            { toShow: 'Laptop Unavailable', toSend: 'laptopIssue' },
          ]
        },
        {
          label: 'Technical Issues',
          key: 'technicalIssue',
          values: [
            { toShow: 'Website loading', toSend: 'webSiteLoadingIssue' },
            { toShow: 'Video Not playing', toSend: 'videoNotLoading' },
            { toShow: 'Login / OTP Error', toSend: 'logInOTPError' },
            { toShow: 'Code Playground', toSend: 'codePlaygroundIssue' },
            { toShow: 'Other', toSend: 'otherTechnicalReason' },
          ]
        },
        {
          label: 'Other Issues',
          key: 'otherIssue',
          values: [
            { toShow: 'Knew Coding Basic', toSend: 'knowCoding' },
            { toShow: 'Looking for advance courses', toSend: 'lookingForAdvanceCourse' },
            { toShow: 'Was not interested', toSend: 'notInterestedInCoding' },
          ]
        },
      ],
      studentPersona: [
        {
          label: 'Learning Speed',
          isRequired: true,
          key: 'learningSpeed',
          values: [
            { toShow: 'Slow', toSend: 'slow' },
            { toShow: 'Average', toSend: 'average' },
            { toShow: 'Fast', toSend: 'fast' }
          ]
        },
        {
          label: 'Prodigy',
          inputType: 'checkbox',
          key: 'prodigyChild',
          values: [
            { toShow: 'Yes', toSend: true }
          ]
        },
        {
          label: 'Personality',
          isRequired: true,
          key: 'personality',
          values: [
            { toShow: 'Extrovert', toSend: 'extrovert' },
            { toShow: 'Introvert', toSend: 'introvert' },
            { toShow: 'Ambivert', toSend: 'ambivert' }
          ]
        },
        {
          label: 'Mother Tounge',
          key: 'languageBarrier',
          inputType: 'dropdown',
          values: [
            { toShow: 'Hindi', toSend: 'hindi' },
            { toShow: 'Tamil', toSend: 'tamil' },
            { toShow: 'Telegu', toSend: 'telegu' },
            { toShow: 'Malayalam', toSend: 'malayalam' },
            { toShow: 'Kannada', toSend: 'kannada' },
            { toShow: 'Gujarati', toSend: 'gujarati' },
            { toShow: 'Bengali', toSend: 'bengali' },
            { toShow: 'Marathi', toSend: 'marathi' },
            { toShow: 'Other Language', toSend: 'otherLanguage' },
          ]
        },
        {
          label: 'Student’s english proficiency',
          isRequired: true,
          key: 'studentEnglishSpeakingSkill',
          values: [
            { toShow: 'Poor', toSend: 'notFluent' },
            { toShow: 'Average', toSend: 'fluent' },
            { toShow: 'Excellent', toSend: 'veryFluent' },
          ]
        },
      ],
      ratings: [
        {
          label: 'Critical thinking',
          isRequired: true,
          key: 'criticalThinking',
          star: 0
        },
        {
          label: 'Logical thinking',
          isRequired: true,
          key: 'logicalThinking',
          star: 0
        },
        {
          label: 'Communication skills',
          isRequired: true,
          key: 'communicationSkills',
          star: 0
        },
        {
          label: 'Probling solving ability',
          isRequired: true,
          key: 'problemSolvingAbility',
          star: 0
        },
        {
          label: 'Creativity skills',
          isRequired: true,
          key: 'creativeSkills',
          star: 0
        },
      ],
      mentorPitch: [
        {
          label: 'Were the parent counseled?',
          key: 'parentCounsellingDone',
          isRequired: true,
          inputType: 'radio',
          values: [
            { toShow: 'Yes', toSend: true },
            { toShow: 'No', toSend: false }
          ],
          renderAdditionalData: {
            values: [
              { toShow: 'Parents were interested', key: 'parentsWereInterested', toSend: true },
              { toShow: 'Explained about course', key: 'explainedAboutCourse', toSend: true },
              { toShow: "Discussed about kid's performance in session", key: 'discussedPerformance', toSend: true }
            ]
          }
        },
        {
          label: 'When should the academic counsellor reach out to them ?',
          inputType: 'datetime',
          condition: { key: 'scheduledCounselCondition', values: ['before', 'during', 'after'] },
          dateFrom: { key: 'scheduledCounselDateFrom' },
          dateTo: { key: 'scheduledCounselDateTo' }
        },
      ],
      rescheduledSession: [
        {
          label: 'Did the kid turn up for the session?',
          key: 'didNotTurnUpInSession',
          inputType: 'radio',
          values: [
            { toShow: 'Yes', toSend: true },
            { toShow: 'No', toSend: false }
          ]
        },
        {
          label: 'Did you tried to reach parent?',
          key: 'triedToReachParent',
          inputType: 'radio',
          values: [
            { toShow: "I didn't try", toSend: 'didNotTriedToReachParent' },
            { toShow: 'Parent pick up', toSend: 'parentPickUp' },
            { toShow: 'Parent responded to the call', toSend: 'parentResponded' }
          ]
        },
        {
          label: 'Student Reschedule',
          key: 'selectedRescheduledDate',
          inputType: 'datetime',
        },
        {
          label: 'Would you like to take this class?',
          key: 'isMentorReadyToTakeClass',
          inputType: 'radio',
          values: [
            { toShow: 'Yes', toSend: true },
            { toShow: 'No', toSend: false }
          ]
        },
      ],
      leftMidWay: {
        label: 'Student left midway',
        key: 'studentLeftMidWay',
        values: [
          { toShow: 'They had other Commitments', toSend: 'hadOtherCommitments' },
          { toShow: 'Student Left Abruptly', toSend: 'turnedUpButLeftAbruptly' },
        ]
      },
      aboutStudentsB2B: [
        {
          label: 'How many students were attentive ?',
          key: 'attentionCount',
          inputType: 'button',
          values: [
            { toShow: 'All of them', toSend: 'all' },
            { toShow: 'Most of them', toSend: 'most' },
            { toShow: 'Half of them', toSend: 'half' },
            { toShow: 'Few of them', toSend: 'few' },
            { toShow: 'None', toSend: 'none' },
          ]
        },
        {
          label: 'How attentive were the students ?',
          key: 'attentionAmount',
          inputType: 'radio',
          values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        },
        {
          label: 'How many students were interactive ?',
          key: 'interactionCount',
          inputType: 'button',
          values: [
            { toShow: 'All of them', toSend: 'all' },
            { toShow: 'Most of them', toSend: 'most' },
            { toShow: 'Half of them', toSend: 'half' },
            { toShow: 'Few fo them', toSend: 'few' },
            { toShow: 'None', toSend: 'none' },
          ]
        },
        {
          label: 'How interactive were the students ?',
          key: 'interactionAmount',
          inputType: 'radio',
          values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        },
        {
          label: "Tell us about the student's behaviour",
          inputType: 'textArea',
          key: 'studentBehaviour',
        }
      ],
      tellMoreAboutContent: [
        {
          label: 'How was the length of content ?',
          key: 'lengthOfContent',
          inputType: 'button',
          values: [
            { toShow: 'Brief', toSend: 'brief' },
            { toShow: 'Concise', toSend: 'concise' },
            { toShow: 'Long', toSend: 'long' },
          ]
        },
        {
          label: "Which part of session student's enjoyed ?",
          key: 'learningObjectiveComponent',
          inputType: 'button',
          values: [
            { toShow: 'Chat', toSend: 'chatbot' },
            { toShow: 'Code', toSend: 'practice' },
            { toShow: 'Comic Strip', toSend: 'comicStrip' },
            { toShow: 'Quiz', toSend: 'project' },
            { toShow: 'Video', toSend: 'video' },
          ]
        },
        {
          label: "Is there anything we can improve from the content perspective ?",
          key: 'contentImprovementSuggestion',
          inputType: 'textArea',
        }
      ],
      commentsB2B: [
        {
          label: 'Is there any functionality we can provide to have taken this class better and more effectively ?',
          key: 'functionalitySuggestion',
        },
        {
          label: 'Is there anything in general you want to let us know ?',
          key: 'generalSuggestion',
        }
      ],
      iqaTags,
      selectedTags: [],
      studentNotesData,
      hour1: '',
      hour2: '',
      batchSessionDataToSend: [],
      batchSessionDataToSendTopicOrder: [],
      salesOperationDone: false,
      updateMentorMenteeSessionDone: false,
      deleteMentorMenteeSessionDone: false
    }
    this.targetScrollContainer = React.createRef()
    this.topScrollContainer = React.createRef()
  }

  resetForm = () => {
    this.setState({
      demoCompleted: null,
      learningSpeed: null,
      prodigyChild: null,
      languageBarrier: null,
      studentEnglishSpeakingSkill: null,
      personality: null,
      parentCounsellingDone: null,
      parentsWereInterested: null,
      explainedAboutCourse: null,
      discussedPerformance: null,
      scheduledCounselCondition: null,
      hour1: '',
      hour2: '',
      scheduledCounselDateFrom: null,
      scheduledCounselDateTo: null,
      generalIssue: [],
      technicalIssue: [],
      otherIssue: [],
      sessionCommentByMentor: null,
      isSessionRescheduled: null,
      rescheduledDateProvided: null,
      otherReasonForChallenges: null,
      isMentorReadyToTakeClass: null,
      notInterestedInCoding: null,
      leadStatus: null,
      payingPower: null,
      parentEnglishSpeakingSkill: null,
      showError: {},
      knowCoding: null,
      lookingForAdvanceCourse: null,
      ageNotAppropriate: null,
      notRelevantDifferentStream: null,
      noPayingPower: null,
      learningAptitudeIssue: null,
      notResponseAndDidNotTurnUp: null,
      turnedUpButLeftAbruptly: null,
      leadNotVerifiedProperly: null,
      otherReasonForReschedule: null,
      existingBatchCreated: [],
      isShiftStudentModalVisible: false,
      ongoingRescheduleDetails: null,
      occupiedBatch: null,
      didNotTurnUpInSession: null,
      studentLeftMidWay: null,
      sessionNotConducted: null,
    })
  }

  async componentDidMount() {
    const { sessionId } = this.props.match.params
    let session = null
    let isSessionFromLog = false
    let salesOperationData = null
    let batchSessionData = null
    if (this.props.location.pathname.includes('batch')) {
      const data = await this.fetchBatchSessionData()
      session = get(data, 'session', null)
      salesOperationData = get(data, 'salesOperationData', null)
      batchSessionData = get(data, 'batchSessionData', null)
    } else if (this.props.location.search.includes('logs')) {
      isSessionFromLog = true
      await fetchSessionLogs(null, `{id: "${sessionId}"}`).then(async res => {
        if (res && res.sessionLogs && res.sessionLogs.length) {
          /* eslint-disable prefer-destructuring */
          session = {
            ...res.sessionLogs[0],
            menteeSessionData: {
              ...res.sessionLogs[0],
              sessionType: 'trial',
              userData: res.sessionLogs[0] && res.sessionLogs[0].client,
              bookingDate: res.sessionLogs[0] && res.sessionLogs[0].sessionDate
            },
            mentorSessionData: {
              sessionType: 'trial',
            }
          }
          if (get(session, 'hasRescheduled', false) && get(session, 'rescheduledDateProvided', false) && get(session, 'salesOperation')) {
            salesOperationData = get(session, 'salesOperation')
          } else {
            await fetchSalesOperationData(`{ and: [{ client_some: { id: "${get(session, 'client.id')}" } }, 
            { course_some: { id: "${get(session, 'course.id')}" } }] }`)
            if (this.props.salesOperation && this.props.salesOperation.toJS()) {
              salesOperationData = this.props.salesOperation && this.props.salesOperation.toJS()[0]
            }
          }
        }
      })
    } else {
      await fetchMentorMenteeSession(sessionId)
      session = this.props.mentorMenteeSession && this.props.mentorMenteeSession.toJS()
      // salesOperationData = get(session, 'salesOperationData')
      await fetchSalesOperationData(`{ and: [{ client_some: { id: "${get(session, 'menteeSessionData.userData.id')}" } }, 
        { course_some: { id: "${get(session, 'course.id')}" } }] }`).then(res => {
        if (res && res.salesOperations && res.salesOperations.length) {
          salesOperationData = res.salesOperations[0] || null
        }
      })
    }
    this.setDefaultValues(session, salesOperationData, isSessionFromLog, batchSessionData)
    this.setState({
      isFetching: false,
      session,
      salesOperationData,
      batchSessionData,
    })
  }

  fetchBatchSessionData = async () => {
    this.setState({
      isFetching: true,
    })
    let session = null
    let salesOperationData = null
    let sessionLogs = []
    const { sessionId, mentorMenteeId } = this.props.match.params
    const batchSessionData = {
      isBatchSession: true
    }
    await fetchBatchSession(sessionId)
    const batchSession = this.props.batchSession && this.props.batchSession.toJS()
    batchSessionData.record = batchSession
    const menteeIds = get(batchSession, 'attendance', []).map(el => get(el, 'student.user.id'))
    /** contruct MentorMenteeSession filter */
    let slots = '{'
    appConfig.timeSlots.forEach(slot => {
      if (get(batchSession, `slot${slot}`, false)) {
        slots += `slot${slot}: true`
      }
    })
    slots += '}'
    let MMSfilters = ''
    let Logsfilters = ''
    const completed = 'completed'
    if (batchSession && get(batchSession, 'bookingDate')) {
      MMSfilters += `{sessionStatus: ${completed}}`
      MMSfilters += `{menteeSession_some:{
        and: [
          ${slots}
          { bookingDate: "${get(batchSession, 'bookingDate')}" },
          {user_some: {
            id_in: [${getIdArrForQuery(menteeIds)}]
          }}
        ]
      }},`
      Logsfilters += `
        ${slots}
        { sessionDate: "${get(batchSession, 'bookingDate')}" },
        {client_some: {
          id_in: [${getIdArrForQuery(menteeIds)}]
        }}
      ,`
    }
    if (batchSession && get(batchSession, 'topic.id')) {
      MMSfilters += `{topic_some:{id: "${get(batchSession, 'topic.id')}"}},`
      Logsfilters += `{topic_some:{id: "${get(batchSession, 'topic.id')}"}},`
    }
    if (batchSession && get(batchSession, 'course.id')) {
      MMSfilters += `{course_some:{id: "${get(batchSession, 'course.id')}"}},`
      Logsfilters += `{course_some:{id: "${get(batchSession, 'course.id')}"}},`
    }
    /** Fetching MentorMenteeSessions for all students */
    await fetchMentorMenteeSessions(MMSfilters)
    await fetchSessionLogs(null, Logsfilters, 'deleteMentorMenteeSession').then(async res => {
      if (res && res.sessionLogs && res.sessionLogs.length) {
        sessionLogs = res.sessionLogs.map(el => ({
          ...el,
          menteeSessionData: {
            ...res.sessionLogs[0],
            sessionType: 'trial',
            userData: res.sessionLogs[0] && res.sessionLogs[0].client,
            bookingDate: res.sessionLogs[0] && res.sessionLogs[0].sessionDate
          },
          mentorSessionData: {
            sessionType: 'trial',
          }
        }))
      }
    })
    let mmSessions = this.props.mentorMenteeSessions && this.props.mentorMenteeSessions.toJS()
    mmSessions = [...(mmSessions || []), ...sessionLogs]
    mmSessions = sortBy(mmSessions, 'menteeSessionData.userData.name') || []
    if (mentorMenteeId) {
      session = mmSessions.filter(el => el.id === mentorMenteeId)[0] || mmSessions[0] || {}
    } else {
      session = mmSessions[0] || {}
    }
    batchSessionData.mmsData = mmSessions
    batchSessionData.selectedSession = session
    batchSessionData.sessionLogs = sessionLogs

    /** Fetching SalesOperation Data for all students */
    const batchStudents = get(batchSession, 'attendance', [])
      .filter(attendance => get(attendance, 'student.user.id', null) !== null)
      .map(attendance => get(attendance, 'student.user.id'))
    await fetchSalesOperationData(`{client_some:{id_in:[${getIdArrForQuery(batchStudents)}]}}`).then(res => {
      if (res && res.salesOperations && res.salesOperations.length) {
        salesOperationData = res.salesOperations.filter(sp => get(sp, 'client.id') === get(session, 'menteeSessionData.userData.id'))[0] || null
        batchSessionData.selectedSalesOperation = salesOperationData
        batchSessionData.salesOperations = res.salesOperations
      }
    })
    return { session, salesOperationData, batchSessionData }
  }

  async componentDidUpdate(prevProps) {
    const { mentorMenteeId } = this.props.match.params
    const { batchSessionData } = this.state
    if (mentorMenteeId !== get(prevProps, 'match.params.mentorMenteeId')) {
      let selectedSession = null
      let salesOperationData = null
      let batchSessionDoc = null
      if (this.state.shouldRefetchBatchData) {
        const data = await this.fetchBatchSessionData()
        selectedSession = get(data, 'session', null)
        salesOperationData = get(data, 'salesOperationData', null)
        batchSessionDoc = get(data, 'batchSessionData', null)
      } else {
        selectedSession = get(batchSessionData, 'mmsData', []).filter(el => el.id === mentorMenteeId)[0] || {}
        salesOperationData = get(batchSessionData, 'salesOperations', []).filter(sp => get(sp, 'client.id') === get(selectedSession, 'menteeSessionData.userData.id'))[0] || null
        batchSessionDoc = {
          ...batchSessionData,
          selectedSession,
          selectedSalesOperation: salesOperationData,
        }
      }
      this.resetForm()
      if (this.topScrollContainer && this.topScrollContainer.current) {
        this.topScrollContainer.current.scrollIntoView({
          behavior: 'smooth',
          inline: 'start'
        })
      }
      this.setState({
        batchSessionData: batchSessionDoc,
        isFetching: false,
        shouldRefetchBatchData: false,
        readOnly: false,
      }, () => {
        this.setDefaultValues(selectedSession,
          salesOperationData, false, batchSessionDoc)
      })
    }
    const updateMentorSessionStatus = this.props.updateMenteeStatus
      && this.props.updateMenteeStatus.toJS()
    const prevUpdateMentorSessionStatus = prevProps.updateMenteeStatus
      && prevProps.updateMenteeStatus.toJS()
    const addBatchSessionStatus = this.props.addBatchSessionStatus
      && this.props.addBatchSessionStatus.toJS()
    const prevAddBatchSessionStatus = prevProps.addBatchSessionStatus
      && prevProps.addBatchSessionStatus.toJS()

    if (get(updateMentorSessionStatus, 'failure')
      && !get(prevUpdateMentorSessionStatus, 'failure')) {
      const failureMessage = this.props.mentorSessionsUpdateFailure
        && this.props.mentorSessionsUpdateFailure.toJS()
      const error = get(get(failureMessage[0], 'error.errors[0]'), 'extensions.exception.name')
      if (error === 'SlotsOccupiedError') {
        notification.error({
          message: 'Slots Already Occupied!'
        })
      } else {
        const errorObj = get(get(failureMessage[0], 'error.errors[0]'), 'message')
        notification.error({
          message: errorObj
        })
      }
    }
    if (get(addBatchSessionStatus, 'failure')
      && !get(prevAddBatchSessionStatus, 'failure')) {
      const failureMessage = this.props.batchSessionAddFailure
        && this.props.batchSessionAddFailure.toJS()
      const error = get(get(failureMessage[0], 'error.errors[0]'), 'extensions.exception.name')
      const errorData = get(get(failureMessage[0], 'error.errors[0]'), 'extensions.exception.data')
      if (error === 'SlotsOccupiedError') {
        notification.error({
          message: 'Slots Already Occupied!'
        })
        if (get(errorData, 'batchInfo.code', null)) {
          // Open Modal to either shift student or change date
          this.setState({
            isShiftStudentModalVisible: true,
            occupiedBatch: get(errorData, 'batchInfo', {}),
          })
        }
      } else {
        const errorObj = get(get(failureMessage[0], 'error.errors[0]'), 'message')
        notification.error({
          message: errorObj
        })
      }
    }
  }

  setDefaultValues = (session, salesOperationData, isSessionFromLog = false, batchSessionData) => {
    const { mentorPitch,
      studentPersona, challenges, ratings, leftMidWay, aboutStudentsB2B, tellMoreAboutContent, commentsB2B } = this.state
    const savedState = loadStateFromLocalStorage()
    let {
      sessionCommentByMentor,
      batchSessionComment
    } = this.state
    /* eslint-disable no-lonely-if */
    if (get(session, 'isFeedbackSubmitted', false)) {
      this.setState({
        readOnly: (isSessionFromLog || get(savedState, 'login.role') === MENTOR)
      })
    }
    if (batchSessionData && get(batchSessionData, 'record.sessionCommentByMentor')) {
      batchSessionComment = get(batchSessionData, 'record.sessionCommentByMentor')
      this.setState({ batchSessionComment })
    }
    const isBatchSessionActive = get(batchSessionData, 'isBatchSession', false)
    const batchType = get(batchSessionData, 'record.batch.type', null)
    if (isBatchSessionActive && batchType === 'normal') {
      const mmsTemp = []
      const topicOrder = get(batchSessionData, 'record.topic.order', null)
      const mmsData = get(batchSessionData, 'mmsData')
      if (mmsData.length > 0) {
        if (topicOrder % 5 === 0) {
          mmsData.map(item => {
            const mmsforEach = {}
            mmsforEach.id = item.id
            b2cPaidFormKeyEveryFive.map(key => {
              mmsforEach[key] = item[key] || 3
            })
            mmsforEach.sessionCommentByMentor = item.sessionCommentByMentor || ''
            mmsTemp.push(mmsforEach)
          })
          this.setState({ batchSessionDataToSendTopicOrder: mmsTemp })
        } else {
          mmsData.map(item => {
            const mmsforEach = {}
            mmsforEach.id = item.id
            mmsforEach.attentionLevel = item.attentionLevel || null
            mmsforEach.previousSessionUnderstandingLevel = item.previousSessionUnderstandingLevel
            || null
            mmsforEach.currentSessionUnderstandingLevel = item.currentSessionUnderstandingLevel
            || null
            mmsTemp.push(mmsforEach)
          })
          this.setState({ batchSessionDataToSend: mmsTemp })
        }
      }
    }
    const b2bStates = {}
    const fetchBatchSessionDataTemp = batchSessionData && batchSessionData.record
    if(isBatchSessionActive && batchType === 'b2b') {
      aboutStudentsB2B.map(item => {
        if(fetchBatchSessionDataTemp[item.key]) {
          b2bStates[item.key] = fetchBatchSessionDataTemp[item.key]
        }
      })
      tellMoreAboutContent.map(item => {
        if(fetchBatchSessionDataTemp[item.key]) {
          b2bStates[item.key] = fetchBatchSessionDataTemp[item.key]
        }
      })
      commentsB2B.map(item => {
        if(fetchBatchSessionDataTemp[item.key]) {
          b2bStates[item.key] = fetchBatchSessionDataTemp[item.key]
        }
      })
      this.setState({ ...b2bStates })
    }
    if (salesOperationData) {
      const isSessionRescheduled =
        get(session, 'hasRescheduled') === null ? false : get(session, 'hasRescheduled')
      const rescheduledDateProvided =
        get(session, 'rescheduledDateProvided') === null ? false : get(session, 'rescheduledDateProvided')
      let demoCompleted = null
      if ((get(session, 'sessionNotConducted') === false || get(session, 'sessionNotConducted') === null) &&
      (get(session, 'hasRescheduled') === false || get(session, 'hasRescheduled') === null)) {
        demoCompleted = true
      } else {
        demoCompleted = false
      }
      const sessionNotConducted =
        get(session, 'sessionNotConducted') === null ? null : get(session, 'sessionNotConducted')
      const triedToReachParent =
        get(session, 'triedToReachParent') === null ? null : get(session, 'triedToReachParent')
      let notResponseAndDidNotTurnUp = null
      let didNotTurnUpInSession = null
      if (demoCompleted === false) {
        notResponseAndDidNotTurnUp = !get(session, 'didNotPickTheCall', true)
        didNotTurnUpInSession = !get(session, 'didNotTurnUpInSession', true)
      }
      let selectedRescheduledDate = null
      let isMentorReadyToTakeClass = null
      const defaultValue = {}
      if (get(session, 'rescheduledDate', null)) {
        selectedRescheduledDate = moment(get(session, 'rescheduledDate'))
        defaultValue.rescheduleHour = moment(get(session, 'rescheduledDate'))
      }
      isMentorReadyToTakeClass = get(salesOperationData, 'isMentorReadyToTakeClass', null)
      sessionCommentByMentor = get(session, 'sessionCommentByMentor')
      defaultValue.didNotTurnUpInSession = get(session, 'didNotTurnUpInSession', null)

      leftMidWay.values.forEach(item => {
        if (session[item.toSend]) {
          defaultValue[item.toSend] = session[item.toSend]
        }
      })
      if (get(session, 'isFeedbackSubmitted', false)) {
        mentorPitch.forEach(item => {
          if (item.key === 'parentCounsellingDone') {
            defaultValue[item.key] = salesOperationData[item.key]
            if (salesOperationData[item.key]) {
              item.renderAdditionalData.values.map(value => {
                if (salesOperationData[value.key]) {
                  defaultValue[value.key] = salesOperationData[value.key]
                }
              })
            }
          }
          if (item.inputType === 'datetime') {
            if (salesOperationData[item.condition.key] &&
              salesOperationData[item.dateFrom.key] &&
              salesOperationData[item.dateTo.key]
            ) {
              defaultValue[item.condition.key] = salesOperationData[item.condition.key]
              defaultValue[item.dateFrom.key] = moment(salesOperationData[item.dateFrom.key])
              defaultValue[item.dateTo.key] = moment(salesOperationData[item.dateTo.key])
              defaultValue.hour1 = moment(salesOperationData[item.dateFrom.key])
              defaultValue.hour2 = moment(salesOperationData[item.dateTo.key])
            }
          }
        })
        studentPersona.forEach(item => {
          if (item.key === 'languageBarrier') {
            if (session[item.key]) {
              defaultValue[item.key] = session[item.key]
            }
          } else if (salesOperationData[item.key]) {
            defaultValue[item.key] = salesOperationData[item.key]
          }
        })
        defaultValue.studentNote = salesOperationData.studentNote
      }
      const items = []
      ratings.forEach(item => {
        items.push({ ...item, star: salesOperationData[item.key] })
      })
      const technicalIssue = []
      const studentLeftMidWay = []
      challenges.forEach(item => {
        if (item.key === 'technicalIssue') {
          item.values.map(el => {
            if (session[el.toSend]) {
              technicalIssue.push(el.toSend)
            }
          })
        } else {
          const temp = []
          item.values.map(el => {
            if (salesOperationData[el.toSend]) {
              temp.push(el.toSend)
            }
          })
          if (temp) {
            defaultValue[item.key] = temp
          }
        }
      })
      if (get(session, 'languageBarrier')) {
        defaultValue.languageBarrier = get(session, 'languageBarrier')
      }
      if (get(session, 'otherLanguageBarrier')) {
        defaultValue.otherLanguageBarrier = get(session, 'otherLanguageBarrier')
        defaultValue.languageBarrier = 'otherLanguage'
      }
      this.setState({
        isSessionRescheduled,
        sessionCommentByMentor,
        rescheduledDateProvided,
        demoCompleted,
        notResponseAndDidNotTurnUp,
        didNotTurnUpInSession,
        studentLeftMidWay,
        technicalIssue,
        selectedRescheduledDate,
        isMentorReadyToTakeClass,
        ...defaultValue,
        isSessionFromLog,
        ratings: items,
        sessionNotConducted,
        triedToReachParent 
      })
    }
  }

  checkIfFieldValueNotExists = (value) => {
    if (value === ''
      || value === null
      || value === undefined) {
      return true
    }
    return false
  }

  validateFields = () => {
    const {
      studentPersona,
      demoCompleted,
      mentorPitch,
      ratings,
      batchSessionData,
      sessionCommentByMentor,
      batchSessionComment,
      commentsB2B
    } = this.state
    const showError = {}
    const isBatchSession = get(batchSessionData, 'isBatchSession', false)
    const batchType = get(batchSessionData, 'record.batch.type', null)
    const topicOrder = get(batchSessionData, 'record.topic.order', null)
    if (demoCompleted === true && !isBatchSession) {
      studentPersona.forEach(persona => {
        if (persona.isRequired) {
          if (this.checkIfFieldValueNotExists(this.state[persona.key])) {
            showError[persona.key] = true
          }
        }
      })
      ratings.forEach(rate => {
        if (rate.isRequired) {
          if (!rate.star) {
            showError[rate.key] = true
          }
        }
      })
      mentorPitch.forEach(pitch => {
        if (pitch.isRequired) {
          if (this.checkIfFieldValueNotExists(this.state[pitch.key])) {
            showError[pitch.key] = true
          }
        }
      })
    }
    if (this.checkIfFieldValueNotExists(sessionCommentByMentor) && !isBatchSession) {
      showError.sessionCommentByMentor = true
    }
    if (this.checkIfFieldValueNotExists(batchSessionComment) && isBatchSession && batchType === 'normal' && topicOrder % 5 !== 0) {
      showError.batchSessionComment = true
    }
    if(isBatchSession && batchType === 'b2b') {
      commentsB2B.forEach(item => {
        if(this.checkIfFieldValueNotExists(this.state[item.key])) {
          showError[item.key] = true
        }
      })
    }
    if (Object.keys(showError).length > 0) {
      this.setState({
        showError
      })
      if (this.targetScrollContainer && this.targetScrollContainer.current) {
        this.targetScrollContainer.current.scrollIntoView({
          behavior: 'smooth',
          inline: 'start'
        })
      }
      return true
    }
    return false
  }

  navigateBack = (isFeedbackSubmitted = false, nextMMSSessionId) => {
    const { mentorId, sessionId } = this.props.match.params
    const { state } = this.props.history.location
    const { batchSessionData } = this.state
    this.setState({
      isLoading: false,
      isShiftStudentModalVisible: false
    })
    if (get(batchSessionData, 'isBatchSession', false) && nextMMSSessionId) {
      this.setState({
        shouldRefetchBatchData: true
      })
      if (mentorId) {
        this.props.history.push({
          pathname: `/mentorDashboard/${mentorId}/classFeedback/batch/${sessionId}/${nextMMSSessionId}`,
          state: {
            ...state,
          }
        })
      } else {
        this.props.history.push({
          pathname: `/mentorDashboard/classFeedback/batch/${sessionId}/${nextMMSSessionId}`,
          state: {
            ...state,
          }
        })
      }
    } else {
      if (mentorId) {
        this.props.history.push({
          pathname: `/mentorDashboard/${mentorId}`,
          state: {
            ...state,
            isFeedbackSubmitted,
            hasRescheduled: get(this.state, 'rescheduledDateProvided', false),
          }
        })
      } else {
        this.props.history.push({
          pathname: '/mentorDashboard',
          state: {
            ...state,
            isFeedbackSubmitted,
            hasRescheduled: get(this.state, 'rescheduledDateProvided', false),
          }
        })
      }
    }
  }

  onSave = async () => {
    const { batchSessionData } = this.state
    const isBatchSessionActive = get(batchSessionData, 'isBatchSession', false)
    let session = this.props.mentorMenteeSession && this.props.mentorMenteeSession.toJS()
    let salesOperationData = get(this.state, 'salesOperationData')
    if (isBatchSessionActive) {
      session = get(batchSessionData, 'selectedSession', {})
      salesOperationData = get(batchSessionData, 'salesOperations', []).filter(sp => get(sp, 'client.id') === get(session, 'menteeSessionData.userData.id'))[0] || {}
    }
    this.submitStudentFeedback(session, salesOperationData, isBatchSessionActive)
  }

  submitStudentFeedback = async (session, salesOperationData, isBatchSession = false) => {
    const actionType = get(salesOperationData, 'id') ? 'EDIT' : 'ADD'
    const topic = get(session, 'topic')
    const userIdToEdit = get(session, 'menteeSessionData.userData.id')
    const savedState = loadStateFromLocalStorage()
    const savedId = get(savedState, 'login.id')
    let input = {}
    const salesStatus = get(session, 'salesOperationData.userVerificationStatus')
    const userResponseStatus = get(session, 'salesOperationData.userResponseStatus')
    const err = await this.validateFields()
    if (err) {
      const errorElement = document.querySelectorAll('.feedback_error')
      if (errorElement.length > 0 && errorElement[0].parentElement
        && errorElement[0].parentElement.parentElement) {
        errorElement[0].parentElement.parentElement.scrollIntoView({ behavior: 'smooth' })
      }
      notification.error({
        message: 'Please fill in all the required fields.'
      })
    }
    if (this.state.selectedRescheduledDate &&
      new Date() > new Date(get(this.state.selectedRescheduledDate, '_d'))) {
      notification.error({
        message: 'please select time greater than current time'
      })
      return
    }
    if (!err) {
      this.setState({
        isLoading: true
      })
      const {
        challenges,
        mentorPitch,
        studentPersona,
        ratings,
        isSessionRescheduled,
        rescheduledDateProvided,
        didNotTurnUpInSession,
        technicalIssue,
        isMentorReadyToTakeClass,
        batchSessionData,
        batchSessionDataToSend,
        batchSessionDataToSendTopicOrder,
        selectedRescheduledDate,
        leftMidWay,
        batchSessionComment,
        sessionCommentByMentor,
        aboutStudentsB2B,
        tellMoreAboutContent,
        commentsB2B,
        sessionNotConducted,
        triedToReachParent,
        rescheduleHour
      } = this.state
      let { demoCompleted } = this.state
      let dataToBeSended = {}
      let reschedulingDataToBeSended = {}
      if (sessionNotConducted) {
        demoCompleted = false
      }
      const batchType = get(batchSessionData, 'record.batch.type', null)
      if (isBatchSession && batchType === 'normal') {
        const topicOrder = get(batchSessionData, 'record.topic.order', null)
        if (topicOrder % 5 === 0) {
          batchSessionDataToSendTopicOrder &&
          batchSessionDataToSendTopicOrder.length > 0 &&
          batchSessionDataToSendTopicOrder.map(item => {
            const obj = {}
            for (const i in item) {
              if (i !== 'id' && item[i] !== null) {
                obj[i] = item[i]
              }
            }
            updateMentorMenteeSession(item.id, {
              ...obj,
            })
          })
          updateBatchSession(get(batchSessionData, 'record.id'), {
            isFeedbackSubmitted: true,
          })
        } else {
          batchSessionDataToSend &&
          batchSessionDataToSend.length > 0 &&
          batchSessionDataToSend.map(item => {
            const obj = {}
            for (const i in item) {
              if (i !== 'id' && item[i] !== null) {
                obj[i] = item[i]
              }
            }
            updateMentorMenteeSession(item.id, {
              ...obj,
            })
          })
          updateBatchSession(get(batchSessionData, 'record.id'), {
            isFeedbackSubmitted: true,
            sessionCommentByMentor: batchSessionComment
          })
        }
      } else if (isBatchSession && batchType === 'b2b') {
        const feedbackData = {
          isFeedbackSubmitted: true,
        }
        aboutStudentsB2B.forEach(item => {
          if(this.state[item.key]) {
            feedbackData[item.key] = this.state[item.key]
          }
        })
        tellMoreAboutContent.forEach(item => {
          if(this.state[item.key]) {
            feedbackData[item.key] = this.state[item.key]
          }
        })
        commentsB2B.forEach(item => {
          if(this.state[item.key]) {
            feedbackData[item.key] = this.state[item.key]
          }
        })
        const batchSessionId = get(batchSessionData, 'record.id', null)
        await updateBatchSession(batchSessionId, { ...feedbackData })
        this.navigateBack(true)
      } else {
        challenges.forEach(item => {
          if (item.key === 'technicalIssue' && technicalIssue) {
            technicalIssue.map(value => {
              reschedulingDataToBeSended[value] = true
            })
          } else {
            if (this.state[item.key]) {
              this.state[item.key].map(value => {
                dataToBeSended[value] = true
              })
            }
          }
        })
        mentorPitch.forEach(item => {
          if (item.key === 'parentCounsellingDone') {
            if (this.state[item.key] !== null) {
              dataToBeSended[item.key] = this.state[item.key]
            }
            item.renderAdditionalData.values.map(value => {
              if (this.state[value.key]) {
                dataToBeSended[value.key] = this.state[value.key]
              }
            })
          }
          if (item.inputType === 'datetime') {
            if (this.state[item.dateFrom.key] &&
              this.state[item.dateTo.key]
            ) {
              dataToBeSended[item.condition.key] = this.state[item.condition.key] || 'before'
              dataToBeSended[item.dateFrom.key] = this.state[item.dateFrom.key]
              dataToBeSended[item.dateTo.key] = this.state[item.dateTo.key]
            }
          }
        })
        studentPersona.forEach(item => {
          if (this.state[item.key] !== null) {
            if (item.key === 'languageBarrier') {
              reschedulingDataToBeSended[item.key] = this.state[item.key]
            } else {
              dataToBeSended[item.key] = this.state[item.key]
            }
          }
        })
        ratings.forEach(item => {
          if (item.star) {
            dataToBeSended[item.key] = item.star
          }
        })
        const didNotPickTheCall = false
        reschedulingDataToBeSended.sessionCommentByMentor = sessionCommentByMentor || ''
        reschedulingDataToBeSended.hasRescheduled = isSessionRescheduled || false
        reschedulingDataToBeSended.rescheduledDateProvided = rescheduledDateProvided || false
        reschedulingDataToBeSended.sessionNotConducted = sessionNotConducted || false
        if (triedToReachParent) {
          reschedulingDataToBeSended.triedToReachParent = triedToReachParent 
        }
        if (reschedulingDataToBeSended.rescheduledDateProvided && selectedRescheduledDate && rescheduleHour) {
          const h1 = moment(this.state.rescheduleHour, ['h:mm a']).format('HH:mm')
          const temph1 = h1.split(':')
          const time1 = moment(selectedRescheduledDate).set({ hour: temph1[0], minute: temph1[1] })
          reschedulingDataToBeSended.rescheduledDate = time1
        }
        if (isMentorReadyToTakeClass !== null) {
          dataToBeSended.isMentorReadyToTakeClass = isMentorReadyToTakeClass
        }
        if (didNotTurnUpInSession !== null) {
          reschedulingDataToBeSended.didNotTurnUpInSession = didNotTurnUpInSession
        }
        leftMidWay.values.forEach(item => {
          if (this.state[item.toSend]) {
            reschedulingDataToBeSended[item.toSend] = this.state[item.toSend]
          }
        })
        if (topic.order === 1) {
          input = {
            ...dataToBeSended
          }
        } else {
          input = {
            userVerificationStatus: salesStatus,
            userResponseStatus
          }
        }
        dataToBeSended = Object.entries(dataToBeSended)
          .reduce((a, [k, v]) => (v == null ? a : (a[k] = v, a)), {})
        reschedulingDataToBeSended = Object.entries(reschedulingDataToBeSended)
          .reduce((a, [k, v]) => (v == null ? a : (a[k] = v, a)), {})
        if (topic.order !== 1) {
          input = {}
          await requestToGraphql(gql`
          query{
            salesOperations(
                  filter: { and: [
                    { client_some: { id: "${userIdToEdit}" } },
                  { course_some: { id: "${get(session, 'course.id')}" } }
                ] }
                ){
              id
            }}
          `).then(async (res) => {
            if (res.data.salesOperations.length > 0) {
              updateSalesOperationData(res.data.salesOperations[0].id, input)
            } else {
              addSalesOperationData(
                userIdToEdit,
                savedId,
                input,
                get(session, 'id'),
                get(session, 'mentorSessionData.userData.id'),
                get(session, 'course.id')
              )
            }
            this.navigateBack(true)
          })
        } else if (actionType === 'ADD') {
          const addInput = {
            leadStatus: get(dataToBeSended, 'leadStatus', 'pipeline'),
            sessionCommentByMentor,
            sessionNotConducted,
            didNotPickTheCall,
            ...reschedulingDataToBeSended,
            isFeedbackSubmitted: true
          }
          Object.assign(input, {
            iqaTags: this.state.selectedTags.map(item => {
              if (item !== undefined) {
                return { value: item }
              }
            })
          })
          if ((demoCompleted === true) && !isBatchSession) {
            addInput.sessionStatus = 'completed'
          }
          input = {
            ...input,
            otherReasonsComment: sessionCommentByMentor
          }
          if (!this.state.salesOperationDone) {
            await requestToGraphql(gql`
            query{
              salesOperations(
                    filter: { and: [
                      { client_some: { id: "${userIdToEdit}" } },
                    { course_some: { id: "${get(session, 'course.id')}" } }
                  ] }
                  ){
                id
              }}
            `).then(async (res) => {
              if (get(res, 'data.salesOperations', []).length) {
                Object.assign(input, {
                  iqaTags: {
                    replace: this.state.selectedTags.map(item => {
                      if (item !== undefined) {
                        return { value: item }
                      }
                    })
                  }
                })
                await updateSalesOperationData(get(res, 'data.salesOperations[0].id'), input).then(async (response) => {
                  if (get(response, 'updateSalesOperation.id')) {
                    this.setState({
                      salesOperationDone: Boolean(response)
                    })
                  }
                })
              } else {
                await addSalesOperationData(
                  userIdToEdit,
                  savedId,
                  input,
                  get(session, 'id'),
                  get(session, 'mentorSessionData.userData.id'),
                  get(session, 'course.id')
                ).then(async (response) => {
                  if (get(response, 'addSalesOperation.id')) {
                    this.setState({
                      salesOperationDone: Boolean(response)
                    })
                  }
                })
              }
            }).catch(err => {
              this.setState({
                salesOperationDone: false
              })
            })
          }
          if (!this.state.updateMentorMenteeSessionDone) {
            await updateMentorMenteeSession(get(session, 'id'), {
              ...addInput
            }).then(async (mmSession) => {
              if (get(mmSession, 'updateMentorMenteeSession.id')) {
                this.setState({
                  updateMentorMenteeSessionDone: Boolean(mmSession)
                })
              }
            })
          }
          if (!this.state.salesOperationDone || !this.state.updateMentorMenteeSessionDone) {
            this.setState({
              isLoading: false
            })
            return notification.error({
              message: 'Unable to submit feedback, please try again!'
            })
          }
          demoCompleted = (get(reschedulingDataToBeSended, 'sessionNotConducted') === false || get(reschedulingDataToBeSended, 'sessionNotConducted') === null)
            && (get(reschedulingDataToBeSended, 'hasRescheduled') === false || get(reschedulingDataToBeSended, 'hasRescheduled') === null)
          if (isBatchSession) {
            await this.rescheduleOrDeleteB2B2CSession(isSessionRescheduled,
              rescheduledDateProvided, demoCompleted, session)
          } else {
            await this.rescheduleOrDeleteSession(isSessionRescheduled,
              rescheduledDateProvided, demoCompleted, session)
          }
        } else if (actionType === 'EDIT') {
          input = {
            ...input,
            otherReasonsComment: sessionCommentByMentor
          }
          const editInput = {
            leadStatus: get(dataToBeSended, 'leadStatus', 'pipeline'),
            sessionCommentByMentor,
            sessionNotConducted,
            didNotPickTheCall,
            ...reschedulingDataToBeSended,
            isFeedbackSubmitted: true
          }
          Object.assign(input, {
            iqaTags: {
              replace: this.state.selectedTags.map(item => {
                if (item !== undefined) {
                  return { value: item }
                }
              })
            }
          })
          if ((demoCompleted === true) && !isBatchSession) {
            editInput.sessionStatus = 'completed'
          }
          if (get(session, 'salesOperation.id')) {
            if (!this.state.salesOperationDone) {
              await updateSalesOperationData(salesOperationData.id, input).then(async (response) => {
                if (get(response, 'updateSalesOperation.id')) {
                  this.setState({
                    salesOperationDone: Boolean(response)
                  })
                }
              })
            }
            if (!this.state.updateMentorMenteeSessionDone) {
              await updateMentorMenteeSession(get(session, 'id'), {
                ...editInput
              }).then(async (mmSession) => {
                if (get(mmSession, 'updateMentorMenteeSession.id')) {
                  this.setState({
                    updateMentorMenteeSessionDone: Boolean(mmSession)
                  })
                }
              })
            }
            if (!this.state.salesOperationDone || !this.state.updateMentorMenteeSessionDone) {
              this.setState({
                isLoading: false
              })
              return notification.error({
                message: 'Unable to submit feedback, please try again!'
              })
            }
            if (isBatchSession) {
              await this.rescheduleOrDeleteB2B2CSession(isSessionRescheduled,
                rescheduledDateProvided, demoCompleted, session)
            } else {
              await this.rescheduleOrDeleteSession(isSessionRescheduled,
                rescheduledDateProvided, demoCompleted, session)
            }
          } else {
            if (!this.state.salesOperationDone) {
              await updateSalesOperationData(salesOperationData.id, input, get(session, 'id')).then(
                async (response) => {
                  if (get(response, 'updateSalesOperation.id')) {
                    this.setState({
                      salesOperationDone: Boolean(response)
                    })
                  }
                }
              )
            }
            if (!this.state.updateMentorMenteeSessionDone) {
              await updateMentorMenteeSession(get(session, 'id'), {
                ...editInput
              }).then(async (mmSession) => {
                if (get(mmSession, 'updateMentorMenteeSession.id')) {
                  this.setState({
                    updateMentorMenteeSessionDone: Boolean(mmSession)
                  })
                }
              })
            }
            if (!this.state.salesOperationDone || !this.state.updateMentorMenteeSessionDone) {
              this.setState({
                isLoading: false
              })
              return notification.error({
                message: 'Unable to submit feedback, please try again!'
              })
            }
            if (isBatchSession) {
              await this.rescheduleOrDeleteB2B2CSession(isSessionRescheduled,
                rescheduledDateProvided, demoCompleted, session)
            } else {
              await this.rescheduleOrDeleteSession(isSessionRescheduled,
                rescheduledDateProvided, demoCompleted, session)
            }
          }
        }
      }
    }
  }


  rescheduleOrDeleteB2B2CSession = async (isSessionRescheduled,
    rescheduledDateProvided, demoCompleted, session) => {
    const { batchSessionData, batchSessionComment } = this.state
    const mentorMenteeSessions = get(batchSessionData, 'mmsData', [])
    let shouldProcess = true
    if (isSessionRescheduled && rescheduledDateProvided && this.state.shouldReschedule) {
      shouldProcess = await this.rescheduleB2B2CSession(session)
      const addBatchSessionStatus = this.props.addBatchSessionStatus
      && this.props.addBatchSessionStatus.toJS()
      if (get(addBatchSessionStatus, 'failure')) {
        shouldProcess = false
      }
    }
    if (shouldProcess) {
      const input = {}
      if (batchSessionComment) {
        input.sessionCommentByMentor = batchSessionComment
      }
      if (demoCompleted === false) {
        /** mark student absent in batch */
        const studentReferenceId = get(get(batchSessionData, 'record.attendance', []).filter(el => {
          if (get(el, 'student.user.id') === get(session, 'menteeSessionData.userData.id')) {
            return true
          }
          return false
        })[0], 'student.id')
        if (studentReferenceId) {
          input.attendance = {
            updateWhere: {
              studentReferenceId,
            },
            updateWith: {
              status: 'absent',
            }
          }
        }
      }
      const filteredSessions = mentorMenteeSessions.filter(el => el.id !== get(session, 'id'))
      const isFeedbackSubmittedForAllStudents = filteredSessions.every(el =>
        get(el, 'isFeedbackSubmitted', false))
      if (isFeedbackSubmittedForAllStudents || filteredSessions.length === 0) {
        input.isFeedbackSubmitted = true
        input.sessionStatus = 'completed'
      }
      if (Object.keys(input).length) {
        await updateBatchSession(get(batchSessionData, 'record.id'), input)
      }
      if (isFeedbackSubmittedForAllStudents || filteredSessions.length === 0) {
        this.navigateBack(true)
      } else {
        this.navigateToNextSession()
      }
    }
    this.setState({
      isLoading: false,
    })
    return true
  }

  navigateToNextSession = () => {
    let nextMMSSessionId = null
    const { batchSessionData } = this.state
    const mentorMenteeSessions = get(batchSessionData, 'mmsData', [])
    const { mentorMenteeId } = this.props.match.params
    if (mentorMenteeId) {
      const filteredSessions = mentorMenteeSessions.filter(el => !get(el, 'isFeedbackSubmitted', false))
      const currentSessionIndex = (filteredSessions || []).findIndex(el =>
        el.id === mentorMenteeId)
      nextMMSSessionId = get(filteredSessions[currentSessionIndex + 1], 'id') || get(filteredSessions[0], 'id')
    } else {
      nextMMSSessionId = get(mentorMenteeSessions[1], 'id') || get(mentorMenteeSessions[0], 'id')
    }
    this.navigateBack(true, nextMMSSessionId)
  }

  rescheduleOrDeleteSession = async (isSessionRescheduled,
    rescheduledDateProvided, demoCompleted, session) => {
    if (isSessionRescheduled && rescheduledDateProvided && this.state.shouldReschedule) {
      await this.rescheduleSession()
    } else {
      if (demoCompleted === false) {
        if (!this.state.deleteMentorMenteeSessionDone) {
          await requestToGraphql(gql`{
            mentorMenteeSession(id: "${get(session, 'id')}") {
              id
            }
          }`).then(async (res) => {
            if (get(res, 'data.mentorMenteeSession.id')) {
              await deleteMentorMenteeSession(get(session, 'id')).then(res => {
                if (get(res, 'data.deleteMentorMenteeSession.id')) {
                  this.setState({
                    deleteMentorMenteeSessionDone: true
                  })
                } else {
                  this.setState({
                    deleteMentorMenteeSessionDone: false
                  })
                }
              }).catch(err => {
                this.setState({
                  deleteMentorMenteeSessionDone: false
                })
              })
            } else {
              this.setState({
                deleteMentorMenteeSessionDone: true
              })
            }
          })
        }
        if (!this.state.deleteMentorMenteeSessionDone) {
          this.setState({
            isLoading: false
          })
          return notification.error({
            message: 'Unable to submit feedback, please try again!'
          })
        }
        const { salesOperationDone, updateMentorMenteeSessionDone, deleteMentorMenteeSessionDone } = this.state
        if (salesOperationDone && updateMentorMenteeSessionDone && deleteMentorMenteeSessionDone) {
          this.navigateBack(true)
          this.setState({
            isLoading: false
          })
          return null
        }
      }
      this.setState({
        isLoading: false
      })
      const { salesOperationDone, updateMentorMenteeSessionDone } = this.state
      if (salesOperationDone && updateMentorMenteeSessionDone) {
        this.navigateBack(true)
      }
    }
  }

  checkIfBatchAlreadyCreated = (bookingDate, slots) => {
    const { existingBatchCreated } = this.state
    let batchId = null
    if (existingBatchCreated && existingBatchCreated.length) {
      existingBatchCreated.forEach(batch => {
        if ((get(batch, 'bookingDate') === bookingDate) &&
          Object.keys(slots).includes(get(batch, 'selectedSlot'))) {
          batchId = get(batch, 'batchId')
        }
      })
    }
    return batchId
  }

  rescheduleB2B2CSession = async (session) => {
    const { batchSessionData } = this.state
    const batchSession = get(batchSessionData, 'record')
    const { selectedRescheduledDate } = this.state
    const slots = await this.getSlotsFromTimeRange()
    const availabilityDate = new Date(
      new Date(selectedRescheduledDate).setHours(0, 0, 0, 0)).toISOString()
    const prevMentorSession = this.props.prevMentorSession && this.props.prevMentorSession.toJS()
    const userId = get(session, 'mentorSessionData.userData.id')
    const classes = get(batchSessionData, 'record.batch.classes', []).map(el => el.id)
    const studentsArr = get(batchSessionData, 'record.batch.students', []).filter(el => get(el, 'userData.id') === get(session, 'menteeSessionData.userData.id')).map(el => get(el, 'id')) || []
    const exisitingBatchId = get(batchSessionData, 'record.batch.id')
    let shouldProcess = true
    this.setState({
      ongoingRescheduleDetails: {
        studentData: get(batchSessionData, 'record.attendance', []).filter(el => get(el, 'student.user.id') === get(session, 'menteeSessionData.userData.id'))[0] || null,
        exisitingBatchId,
        slots,
        availabilityDate,
      }
    })
    const batchId = this.checkIfBatchAlreadyCreated(availabilityDate, slots)
    if (batchId) {
      /** UpdateBatch */
      await updateSchoolBatch({
        batchId,
        studentIds: getIdArrForQuery(studentsArr),
      })
      return shouldProcess
    }
    if (get(this.state, 'isMentorReadyToTakeClass') === false) {
      const data = await createBatchForB2B2C(get(batchSession, 'batch.campaign.id'), get(session, 'course.id'), get(batchSession, 'batch.school.id'), classes, get(batchSession, 'batch.school.code'), null, null, new Date(selectedRescheduledDate), slots, studentsArr, exisitingBatchId)
      const batchInfo = {
        bookingDate: get(data, 'formattedBookingDate'),
        selectedSlot: get(data, 'selectedSlot'),
        batchId: get(data, 'batchId'),
        studentsArr,
      }
      this.setState({
        isLoading: false,
        lastCreatedBatch: batchInfo,
        existingBatchCreated: [
          ...this.state.existingBatchCreated,
          batchInfo
        ]
      })
      return shouldProcess
    }
    if (prevMentorSession && prevMentorSession.length === 0) {
      /** Add Mentor Session */
      const input = {
        availabilityDate,
        sessionType: 'trial',
        ...slots,
      }
      await addMentorSession(input, userId, get(session, 'course.id')).then(async res => {
        /** Update Batch/MMS Session */
        if (res && res.addMentorSession && res.addMentorSession.id) {
          const data = await createBatchForB2B2C(get(batchSession, 'batch.campaign.id'), get(session, 'course.id'), get(batchSession, 'batch.school.id'), classes, get(batchSession, 'batch.school.code'), get(session, 'mentorSessionData.userData.id'), res.addMentorSession.id, new Date(selectedRescheduledDate), slots, studentsArr, exisitingBatchId)
          const batchInfo = {
            bookingDate: get(data, 'formattedBookingDate'),
            selectedSlot: get(data, 'selectedSlot'),
            batchId: get(data, 'batchId'),
            studentsArr,
          }
          this.setState({
            isLoading: false,
            lastCreatedBatch: batchInfo,
            existingBatchCreated: [
              ...this.state.existingBatchCreated,
              batchInfo
            ]
          })
          return true
        }
        shouldProcess = false
      })
    } else if (prevMentorSession && prevMentorSession.length > 0) {
      /** Update Mentor Session  */
      const input = {
        availabilityDate,
        ...slots,
      }
      await updateMentorSession(input, get(prevMentorSession[0], 'id')).then(async res => {
        /** Update Batch/MMS Session */
        if (res && res.updateMentorSession && res.updateMentorSession.id) {
          const data = await createBatchForB2B2C(get(batchSession, 'batch.campaign.id'), get(session, 'course.id'), get(batchSession, 'batch.school.id'), classes, get(batchSession, 'batch.school.code'), get(session, 'mentorSessionData.userData.id'), res.updateMentorSession.id, new Date(selectedRescheduledDate), slots, studentsArr, exisitingBatchId)
          const batchInfo = {
            bookingDate: get(data, 'formattedBookingDate'),
            selectedSlot: get(data, 'selectedSlot'),
            batchId: get(data, 'batchId'),
            studentsArr,
          }
          this.setState({
            isLoading: false,
            lastCreatedBatch: batchInfo,
            existingBatchCreated: [
              ...this.state.existingBatchCreated,
              batchInfo
            ]
          })
          return true
        }
        shouldProcess = false
      })
    }
    return shouldProcess
  }

  updateSessionsBasedOnType = async (mentorSessionRecord = null) => {
    const { selectedRescheduledDate, isMentorReadyToTakeClass } = this.state
    const session = this.props.mentorMenteeSession && this.props.mentorMenteeSession.toJS()
    const bookingDate = new Date(
      new Date(selectedRescheduledDate).setHours(0, 0, 0, 0)).toISOString()
    const slots = this.getSlotsFromTimeRange()
    for (let i = 0; i < appConfig.timeSlots.length; i += 1) {
      if (!slots[`slot${i}`]) {
        slots[`slot${i}`] = false
      }
    }
    /** update MMS Session */
    await rescheduleSession(
      get(session, 'menteeSessionData.id', null),
      { bookingDate, ...slots }
    ).then(async (res) => {
      if (get(res, 'updateMenteeSession.id')) {
        this.setState({
          deleteMentorMenteeSessionDone: true
        })
      }
      if (!this.state.deleteMentorMenteeSessionDone) {
        this.setState({
          isLoading: false
        })
        return notification.error({
          message: 'Unable to submit feedback, please try again!'
        })
      }
      if (res && get(res, 'updateMenteeSession.id') && isMentorReadyToTakeClass) {
        await requestToGraphql(gql`{
            mentorMenteeSession(id: "${get(session, 'id')}") {
              id
            }
          }`).then(async (res) => {
            if (!get(res, 'data.mentorMenteeSession.id')) {
              await addMentorMenteeSession(
                get(session, 'menteeSessionData.id', null),
                get(mentorSessionRecord, 'id', null),
                '{sessionStatus: allotted}',
                get(session, 'topic.id'),
                get(session, 'course.id'),
                isMentorReadyToTakeClass
              )
            }
          })
      }
      this.navigateBack(true)
    })
    this.setState({
      isLoading: false
    })
  }

  getSlotsFromTimeRange = () => {
    const { startHour } = this.state.rescheduledTimeValue
    let endHour = startHour + 1
    endHour = endHour === 0 ? 24 : endHour
    const slots = {}
    for (let i = startHour; i < endHour; i += 1) {
      slots[`slot${i}`] = true
    }
    return slots
  }

  rescheduleSession = async () => {
    const { selectedRescheduledDate } = this.state
    const slots = await this.getSlotsFromTimeRange()
    const availabilityDate = new Date(
      new Date(selectedRescheduledDate).setHours(0, 0, 0, 0)).toISOString()
    const prevMentorSession = this.props.prevMentorSession && this.props.prevMentorSession.toJS()
    const session = this.props.mentorMenteeSession && this.props.mentorMenteeSession.toJS()
    const userId = get(session, 'mentorSessionData.userData.id')
    if (get(this.state, 'isMentorReadyToTakeClass') === false) {
      await this.updateSessionsBasedOnType()
      return true
    }
    if (prevMentorSession && prevMentorSession.length === 0) {
      /** Add Mentor Session */
      const input = {
        availabilityDate,
        sessionType: 'trial',
        ...slots,
      }
      await addMentorSession(input, userId, get(session, 'course.id')).then(async res => {
        /** Update Batch/MMS Session */
        if (res && res.addMentorSession && res.addMentorSession.id) {
          await this.updateSessionsBasedOnType(res.addMentorSession)
        }
      })
    } else if (prevMentorSession && prevMentorSession.length > 0) {
      /** Update Mentor Session  */
      const input = {
        availabilityDate,
        ...slots,
      }
      await updateMentorSession(input, get(prevMentorSession[0], 'id')).then(async res => {
        /** Update Batch/MMS Session */
        if (res && res.updateMentorSession && res.updateMentorSession.id) {
          await this.updateSessionsBasedOnType(
            res.updateMentorSession)
        }
      })
    }
  }

  deletePrevCreatedBatch = async (lastCreatedBatch) => {
    const studentsArr = get(lastCreatedBatch, 'studentsArr')
    const batchId = get(lastCreatedBatch, 'batchId')
    if (studentsArr && studentsArr.length && batchId) {
      await deleteStudent(studentsArr[0], batchId)
    }
    if (batchId) {
      deleteBatch(batchId)
    }
  }

  renderClassDetails = ({ type, value, icon }) => (
    <ClassFeedbackStyle.ContentClassDetail>
      <ClassFeedbackStyle.Icon theme='twoTone'
        component={icon}
      />
      <div style={{ width: '100px' }}><span className='classDetailsText'>{type}</span></div>
      <ClassFeedbackStyle.Text>{ value }</ClassFeedbackStyle.Text>
    </ClassFeedbackStyle.ContentClassDetail>
  )

  renderRequiredAsterisk = (isRequired) => {
    if (isRequired) {
      return (
        <ClassFeedbackStyle.RequiredAsterisk>
          *
        </ClassFeedbackStyle.RequiredAsterisk>
      )
    }
  }

  renderAboutClassStatus = () => (
    <ClassFeedbackStyle.Content style={{ width: '100%' }}>
      <div ref={this.topScrollContainer} />
      <ClassFeedbackStyle.FeedbackTitle>
        Class Status
      </ClassFeedbackStyle.FeedbackTitle>
      <ClassFeedbackStyle.FeedbackContainer style={{ paddingLeft: '170px' }}>
        {this.state.classStatus.map((status) => (
          <ClassFeedbackStyle.ClassStatusButton
            onClick={() => {
              const temp = this.state.classStatus
              temp.map(item => {
                if (item.key !== this.state[status.key]) {
                  this.setState({
                    [item.key]: null
                  })
                }
              })
              this.setState({
                [status.key]: true
              })
            }}
            isSelected={this.state[status.key]}
          >
            {status.label}
          </ClassFeedbackStyle.ClassStatusButton>
        ))}
      </ClassFeedbackStyle.FeedbackContainer>
    </ClassFeedbackStyle.Content>
  )

  renderClassChallenges = () => (
    <>
      <div ref={this.targetScrollContainer} />
      <ClassFeedbackStyle.FeedbackTitle>
        Any Challenges Faced ?
      </ClassFeedbackStyle.FeedbackTitle>
      {this.state.challenges.map(challenge => (
        <ClassFeedbackStyle.FeedbackContainer>
          <ClassFeedbackStyle.FeedbackLayout placement='left'>
            <ClassFeedbackStyle.FeedbackDescription>
              {challenge.label}
            </ClassFeedbackStyle.FeedbackDescription>
          </ClassFeedbackStyle.FeedbackLayout>
          <ClassFeedbackStyle.FeedbackLayout>
            <ClassFeedbackStyle.FlexContainer>
              {challenge.values && challenge.values.map(challengeOption => (
                <ClassFeedbackStyle.FieldSelectorButton
                  onClick={e => {
                    const newArray = this.state[challenge.key]
                    const keyToPush = e.target.value
                    if (newArray && newArray.includes(keyToPush)) {
                      this.setState({
                        [challenge.key]: newArray.filter(key => key !== keyToPush)
                      })
                    } else {
                      this.setState({
                        [challenge.key]: [...newArray, keyToPush],
                      })
                    }
                  }}
                  value={challengeOption.toSend}
                  isSelected={this.state[challenge.key] &&
                    this.state[challenge.key].includes(challengeOption.toSend)}
                  disabled={this.state.readOnly}
                >
                  {challengeOption.toShow}
                </ClassFeedbackStyle.FieldSelectorButton>
              ))}
            </ClassFeedbackStyle.FlexContainer>
          </ClassFeedbackStyle.FeedbackLayout>
        </ClassFeedbackStyle.FeedbackContainer>
      ))}
      {(this.state.showError && this.state.showError.challengesError) && (
        <ClassFeedbackStyle.Error className='feedback_error'>Please Select Atleast One Reason*</ClassFeedbackStyle.Error>
      )}
    </>
  )

  capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

  renderAdditionalDataButton = (additionalData) => (
    <ClassFeedbackStyle.FieldSelectorButton
      onClick={() => {
        if (this.state[additionalData.key]) {
          this.setState({ [additionalData.key]: !additionalData.toSend })
        } else {
          this.setState({ [additionalData.key]: additionalData.toSend })
        }
      }}
      isSelected={this.state[additionalData.key] === additionalData.toSend}
      value={this.state[additionalData.key]}
    >
      {additionalData.toShow}
    </ClassFeedbackStyle.FieldSelectorButton>
  )

  renderCounsellingSection = () => (
    <>
      <ClassFeedbackStyle.FeedbackTitle>
        Parent Counselling
      </ClassFeedbackStyle.FeedbackTitle>
      {this.state.mentorPitch.map(counselling => (
        <ClassFeedbackStyle.FeedbackContainer style={{ paddingBottom: '8px' }}>
          <ClassFeedbackStyle.FeedbackLayout placement='left'>
            <ClassFeedbackStyle.FeedbackDescription style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                {counselling.label}
                {this.renderRequiredAsterisk(counselling.isRequired || false)}
              </div>
              {(this.state.showError && this.state.showError[counselling.key]) && (
                <ClassFeedbackStyle.Error className='feedback_error'>Mandatory*</ClassFeedbackStyle.Error>
              )}
            </ClassFeedbackStyle.FeedbackDescription>
          </ClassFeedbackStyle.FeedbackLayout>
          <ClassFeedbackStyle.FeedbackLayout>
          {(counselling.inputType && counselling.inputType === 'radio') && (
            <>
              <Radio.Group
                disabled={this.state.readOnly}
                onChange={e => {
                  this.setState({
                    [counselling.key]: e.target.value,
                    showError: {
                      ...this.state.showError,
                      [counselling.key]: false
                    }
                  })
                }}
                style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}
                value={this.state[counselling.key]}
              >
                {counselling.values.map(value => (
                  <ClassFeedbackStyle.FlexContainer style={{ width: 'fit-content', marginRight: '5px' }}>
                    <ClassFeedbackStyle.CustomRadio value={value.toSend}>
                      {value.toShow}
                    </ClassFeedbackStyle.CustomRadio>
                  </ClassFeedbackStyle.FlexContainer>
                ))}
              </Radio.Group>
              {this.state[counselling.key] && (
              <>
                <ClassFeedbackStyle.FlexContainer style={{ flexWrap: 'wrap', alignItems: 'flex-start' }}>
                  {this.renderAdditionalDataButton(counselling.renderAdditionalData.values[0])}
                  {this.renderAdditionalDataButton(counselling.renderAdditionalData.values[1])}
                </ClassFeedbackStyle.FlexContainer>
                <ClassFeedbackStyle.FlexContainer>
                  {this.renderAdditionalDataButton(counselling.renderAdditionalData.values[2])}
                </ClassFeedbackStyle.FlexContainer>
              </>
              )}
            </>
          )}
          {(counselling.inputType && counselling.inputType === 'datetime') && (
            <ClassFeedbackStyle.FlexContainer style={{ alignItems: 'flex-end' }}>
            <Dropdown
              overlay={
                <Menu onClick={e => {
                  this.setState({ [counselling.condition.key]: e.key })
                }}
                >
                  {counselling.condition.values.map(value => (
                    <Menu.Item key={value}>
                      {this.capitalize(value)}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <ClassFeedbackStyle.DropDownButton
                disabled={this.state.readOnly}
                style={{ marginRight: '25px' }}
              >
                {this.state[counselling.condition.key] ?
                this.capitalize(this.state[counselling.condition.key]) : 'Before'}
                <DownOutlined />
              </ClassFeedbackStyle.DropDownButton>
            </Dropdown>
            <ClassFeedbackStyle.Content style={{ display: 'flex', flexDirection: 'column' }}>
              <ClassFeedbackStyle.PickerHead>Timings</ClassFeedbackStyle.PickerHead>
              <ClassFeedbackStyle.TimePickerButton
                use12Hours
                format='h:mm a'
                suffixIcon={null}
                style={{ width: '95px' }}
                placeholder='From'
                disabled={this.state.readOnly}
                defaultValue={this.state.hour1}
                onChange={time => {
                  this.setState({ hour1: moment(time).format('h:mm a') })
                }}
              />
            </ClassFeedbackStyle.Content>
            <ClassFeedbackStyle.PickerHead style={{ margin: '8px 18px' }}>to</ClassFeedbackStyle.PickerHead>
            <ClassFeedbackStyle.Content>
              <ClassFeedbackStyle.TimePickerButton
                use12Hours='true'
                format='h:mm a'
                style={{ width: '95px', marginRight: '25px' }}
                placeholder='To'
                disabled={this.state.readOnly}
                disabledHours={() => {
                  const hours = []
                  const h1 = moment(this.state.hour1, ['h a']).format('HH')
                  for (let i = 1; i <= h1; i += 1) {
                    hours.push(i)
                  }
                  return hours
                }}
                defaultValue={this.state.hour2}
                onChange={time => {
                  this.setState({ hour2: moment(time).format('h:mm a') })
                }}
              />
            </ClassFeedbackStyle.Content>
            <ClassFeedbackStyle.Content>
              <ClassFeedbackStyle.PickerHead>Date</ClassFeedbackStyle.PickerHead>
              <ClassFeedbackStyle.DatePickerButton
                suffixIcon={<DownOutlined />}
                disabled={this.state.readOnly}
                format='DD MMM YYYY'
                style={{ width: '180px' }}
                onChange={date => {
                  const h1 = moment(this.state.hour1, ['h:mm a']).format('HH:mm')
                  const h2 = moment(this.state.hour2, ['h:mm a']).format('HH:mm')
                  const temph1 = h1.split(':')
                  const temph2 = h2.split(':')
                  const time1 = moment(date).set({ hour: temph1[0], minute: temph1[1] })
                  const time2 = moment(date).set({ hour: temph2[0], minute: temph2[1] })
                  this.setState({
                    [counselling.dateFrom.key]: time1,
                    [counselling.dateTo.key]: time2
                  })
                }}
                defaultValue={this.state[counselling.dateFrom.key]}
              />
            </ClassFeedbackStyle.Content>
            </ClassFeedbackStyle.FlexContainer>
          )}
          </ClassFeedbackStyle.FeedbackLayout>
        </ClassFeedbackStyle.FeedbackContainer>
      ))}
    </>
  )

  handleRating = (value, key) => {
    const items = [...this.state.ratings]
    const newArray = items.map(rating => rating.key === key ? { ...rating, star: value } : rating)
    this.setState({
      ratings: newArray,
      showError: {
        ...this.state.showError,
        [key]: false
      }
    })
  }

  renderStudentPersonaSection = () => (
    <>
      <ClassFeedbackStyle.FeedbackTitle>
        About the Student
      </ClassFeedbackStyle.FeedbackTitle>
      <ClassFeedbackStyle.FeedbackContainer style={{ alignItems: 'center', paddingBottom: '15px' }}>
      {this.state.studentPersona.map(persona => (
        <>
          {(persona.inputType && persona.inputType === 'dropdown') && (
            <ClassFeedbackStyle.FlexRowPersona justifyContent='center'>
            <p style={{ marginRight: '10px' }}>{persona.label}</p>
            <Dropdown
              overlay={
                <Menu
                  onClick={e => {
                    this.setState({ [persona.key]: e.key })
                  }}
                >
                  {persona.values && persona.values.map(personaOption => (
                    <Menu.Item key={personaOption.toSend}>
                      {this.capitalize(personaOption.toShow)}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <ClassFeedbackStyle.DropDownButton style={{ width: 'fit-content' }}>
                {this.state[persona.key] ? this.capitalize(this.state[persona.key]) :
                'Mother Tounge'} <DownOutlined />
              </ClassFeedbackStyle.DropDownButton>
            </Dropdown>
            </ClassFeedbackStyle.FlexRowPersona>
          )}
          {(persona.inputType && persona.inputType === 'checkbox') && (
            <ClassFeedbackStyle.FlexRowPersona justifyContent='center' alignItems='center'>
            <p>{persona.label}</p>
            <Checkbox.Group
              disabled={this.state.readOnly}
              onChange={(selectedValue) => {
                this.setState({ [persona.key]: selectedValue[0] })
              }}
              value={[this.state[persona.key]]}
            >
              {persona.values && persona.values.map(personaOption => (
                <Modal.CustomCheckbox
                  value={personaOption.toSend}
                  alignItems='flex-end'
                >
                  <ClassFeedbackStyle.CheckboxLabel>
                    {personaOption.toShow}
                  </ClassFeedbackStyle.CheckboxLabel>
                </Modal.CustomCheckbox>
              ))}
            </Checkbox.Group>
            </ClassFeedbackStyle.FlexRowPersona>
          )}
          {!persona.inputType && (
            <>
            <ClassFeedbackStyle.FeedbackLayout placement='left' style={{ marginBottom: '5px' }}>
            <ClassFeedbackStyle.FeedbackDescription style={{ display: 'flex', flexDirection: 'column' }}>
              <p>
                {persona.label}
                {this.renderRequiredAsterisk(persona.isRequired || false)}
              </p>
              <p>
                {(this.state.showError && this.state.showError[persona.key]) && (
                  <ClassFeedbackStyle.Error className='feedback_error'>
                    Fill this field before going further*
                  </ClassFeedbackStyle.Error>
                )}
              </p>
            </ClassFeedbackStyle.FeedbackDescription>
            </ClassFeedbackStyle.FeedbackLayout>
            <Radio.Group
              disabled={this.state.readOnly}
              onChange={e => {
                this.setState({
                  [persona.key]: e.target.value,
                  showError: {
                    ...this.state.showError,
                    [persona.key]: false
                  }
                })
              }}
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: '-8px', flexWrap: 'wrap' }}
              value={this.state[persona.key]}
            >
              {persona.values.map(value => (
                <ClassFeedbackStyle.FlexContainer style={{ width: '80px', marginRight: '24px' }}>
                  <ClassFeedbackStyle.CustomRadio value={value.toSend}>
                    {value.toShow}
                  </ClassFeedbackStyle.CustomRadio>
                </ClassFeedbackStyle.FlexContainer>
              ))}
            </Radio.Group>
            </>
          )}
        </>
      ))}
      </ClassFeedbackStyle.FeedbackContainer>
      {this.state.demoCompleted && (
        <>
          {this.state.ratings.map(rating => (
            <>
              <ClassFeedbackStyle.FeedbackContainer style={{ paddingBottom: '15px' }}>
                <ClassFeedbackStyle.FeedbackLayout placement='left'>
                  <ClassFeedbackStyle.FeedbackDescription style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                      {rating.label}
                      {this.renderRequiredAsterisk(rating.isRequired || false)}
                    </div>
                    {(this.state.showError && this.state.showError[rating.key]) && (
                      <ClassFeedbackStyle.Error className='feedback_error'>
                        Fill this field before going further*
                      </ClassFeedbackStyle.Error>
                    )}
                  </ClassFeedbackStyle.FeedbackDescription>
                </ClassFeedbackStyle.FeedbackLayout>
                <ClassFeedbackStyle.FeedbackLayout>
                  <Rate
                    disabled={this.state.readOnly}
                    value={rating.star}
                    onChange={(value) => this.handleRating(value, rating.key)}
                    style={{ marginTop: '-6px', fontSize: '25px', color: '#ffa200' }}
                  />
                </ClassFeedbackStyle.FeedbackLayout>
              </ClassFeedbackStyle.FeedbackContainer>
            </>
          ))}
          <ClassFeedbackStyle.FeedbackContainer>
          </ClassFeedbackStyle.FeedbackContainer>
        </>
      )}
    </>
  )

  checkRescheduleRenderCondition = (session) => {
    if (this.state.isSessionRescheduled) {
      return true
    } else if (this.state.sessionNotConducted) {
      if (session.key === 'didNotTurnUpInSession' || session.key === 'triedToReachParent') {
        return false
      }
      return true
    }
  }

  renderRescheduledSection = () => (
    <>
      {this.state.rescheduledSession.map(session => (
      <ClassFeedbackStyle.FeedbackContainer style={{ paddingBottom: '8px' }}>
        <>
          {this.checkRescheduleRenderCondition(session) && (
            <ClassFeedbackStyle.FeedbackLayout placement='left'>
              <ClassFeedbackStyle.FeedbackDescription>
                {session.label}
              </ClassFeedbackStyle.FeedbackDescription>
            </ClassFeedbackStyle.FeedbackLayout>
          )}
          <ClassFeedbackStyle.FeedbackLayout>
            {(session.inputType && session.inputType === 'radio' && this.checkRescheduleRenderCondition(session)) && (
            <Radio.Group
              disabled={this.state.readOnly}
              onChange={e => {
                this.setState({
                  [session.key]: e.target.value,
                  showError: {
                    ...this.state.showError,
                    [session.key]: false
                  }
                })
              }}
              style={{ display: 'flex', flexDirection: 'row' }}
              value={this.state[session.key]}
            >
              {session.values.map(value => (
                <ClassFeedbackStyle.FlexContainer style={{ width: 'fit-content', marginRight: '24px' }}>
                  <ClassFeedbackStyle.CustomRadio value={value.toSend}>
                    {value.toShow}
                  </ClassFeedbackStyle.CustomRadio>
                </ClassFeedbackStyle.FlexContainer>
              ))}
            </Radio.Group>
            )}
            {(session.inputType && session.inputType === 'datetime') && (
            <ClassFeedbackStyle.FlexContainer style={{ alignItems: 'flex-end' }}>
            <ClassFeedbackStyle.Content>
              <ClassFeedbackStyle.PickerHead>Timings</ClassFeedbackStyle.PickerHead>
              <ClassFeedbackStyle.TimePickerButton
                use12Hours
                format='h:mm a'
                suffixIcon={<DownOutlined />}
                style={{ width: '95px' }}
                placeholder='From'
                defaultValue={this.state.rescheduleHour}
                onChange={time => {
                  this.setState({
                    rescheduleHour: moment(time).format('h:mm a'),
                    rescheduledTimeValue: { startHour: get(time, '_d').getHours() }
                  })
                }}
              />
            </ClassFeedbackStyle.Content>
            <ClassFeedbackStyle.PickerHead style={{ margin: '10px 15px' }}>on</ClassFeedbackStyle.PickerHead>
            <ClassFeedbackStyle.Content>
              <ClassFeedbackStyle.PickerHead>Date</ClassFeedbackStyle.PickerHead>
              <ClassFeedbackStyle.DatePickerButton
                suffixIcon={<DownOutlined />}
                format='DD MMM YYYY'
                style={{ width: '180px' }}
                onChange={date => {
                  const h1 = moment(this.state.rescheduleHour, ['h:mm a']).format('HH:mm')
                  const temph1 = h1.split(':')
                  const time1 = moment(date).set({ hour: temph1[0], minute: temph1[1] })
                  this.setState({
                    [session.key]: time1,
                    shouldReschedule: true,
                    rescheduledDateProvided: true,
                  }, async () => {
                    let tempSession = null
                    const isBatchSessionActive = get(this.state.batchSessionData, 'isBatchSession', false)
                    if (isBatchSessionActive) {
                      tempSession = get(this.state.batchSessionData, 'selectedSession')
                    } else {
                      tempSession = this.props.mentorMenteeSession
                        && this.props.mentorMenteeSession.toJS()
                    }
                    const userId = get(tempSession, 'mentorSessionData.userData.id')
                    await fetchMentorSessions(getIdArrForQuery([userId]),
                      `{availabilityDate: "${new Date(
                        new Date(date).setHours(0, 0, 0, 0)
                      ).toISOString()}"},{sessionType: trial},`, 'prevMentorSession')
                  })
                }}
                disabled={this.state.rescheduleHour === ''}
                defaultValue={this.state[session.key]}
              />
            </ClassFeedbackStyle.Content>
            </ClassFeedbackStyle.FlexContainer>
          )}
          </ClassFeedbackStyle.FeedbackLayout>
        </>
      </ClassFeedbackStyle.FeedbackContainer>
      ))}
    </>
  )

  renderLeftMidWay = () => (
    <ClassFeedbackStyle.FeedbackContainer>
      <ClassFeedbackStyle.FeedbackLayout placement='left'>
      <ClassFeedbackStyle.FeedbackTitle>
        {this.state.leftMidWay.label}
      </ClassFeedbackStyle.FeedbackTitle>
      </ClassFeedbackStyle.FeedbackLayout>
      <ClassFeedbackStyle.FeedbackLayout>
        {this.state.leftMidWay.values.map(value => (
          <ClassFeedbackStyle.FieldSelectorButton
            onClick={() => {
              this.setState({ [value.toSend]: !this.state[value.toSend] })
            }}
            isSelected={this.state[value.toSend] === true}
          >
            {value.toShow}
          </ClassFeedbackStyle.FieldSelectorButton>
        ))}
      </ClassFeedbackStyle.FeedbackLayout>
    </ClassFeedbackStyle.FeedbackContainer>
  )

  renderB2Cpaid = (batchStudents) => (
    <>
      {b2cPaidFormConstants.map(session => (
        <>
          <ClassFeedbackStyle.FeedbackTitle>
            {session.label}
          </ClassFeedbackStyle.FeedbackTitle>
          {this.state.batchSessionDataToSend.length > 0 && batchStudents && batchStudents.map((batchStudent, index) => (
            <ClassFeedbackStyle.FeedbackContainer style={{ paddingBottom: '15px' }}>
              <ClassFeedbackStyle.FeedbackLayout placement='left'>
                <ClassFeedbackStyle.FeedbackDescription>
                  {get(batchStudent, 'student.user.name', '')}
                </ClassFeedbackStyle.FeedbackDescription>
              </ClassFeedbackStyle.FeedbackLayout>
              <ClassFeedbackStyle.FeedbackLayout>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(rate => (
                    <ClassFeedbackStyle.CustomButtonRate
                      onClick={() => {
                        const mmsTemp = this.state.batchSessionDataToSend
                        mmsTemp[index][session.key] = rate
                        this.setState({
                          batchSessionDataToSend: mmsTemp
                        })
                      }}
                      isSelected={this.state.batchSessionDataToSend[index][session.key] === rate
                      }
                      value={this.state.batchSessionDataToSend[index][session.key]}
                    >
                      {rate}
                    </ClassFeedbackStyle.CustomButtonRate>
                  ))}
              </ClassFeedbackStyle.FeedbackLayout>
            </ClassFeedbackStyle.FeedbackContainer>
          ))}
          <ClassFeedbackStyle.Divider />
        </>
      ))}
    </>
  )

  renderKidSkillsRate = (batchStudents) => (
    <>
      <ClassFeedbackStyle.FeedbackTitle style={{ fontSize: '14px', color: 'red', fontWeight: '100' }}>
        Mention on Scale of 1 - 5
      </ClassFeedbackStyle.FeedbackTitle>
      <ClassFeedbackStyle.FeedbackContainer style={{ paddingBottom: '20px' }}>
        <ClassFeedbackStyle.FeedbackLayout style={{ flex: '0 1 20%' }}></ClassFeedbackStyle.FeedbackLayout>
        <ClassFeedbackStyle.FeedbackLayout style={{ display: 'flex', width: '100%' }}>
          {b2cPaidFormKeyEveryFiveHead.map(value => (
            <ClassFeedbackStyle.TitleText style={{ width: '20%', textAlign: 'center' }}>{value}</ClassFeedbackStyle.TitleText>
          ))}
        </ClassFeedbackStyle.FeedbackLayout>
      </ClassFeedbackStyle.FeedbackContainer>
      {this.state.batchSessionDataToSendTopicOrder.length > 0
      && batchStudents && batchStudents.map((batchStudent, index) => (
        <ClassFeedbackStyle.FeedbackContainer style={{ paddingBottom: '25px' }}>
          <ClassFeedbackStyle.FeedbackLayout style={{ flex: '0 1 20%' }}>
            <ClassFeedbackStyle.TitleText>
            {get(batchStudent, 'student.user.name', '')}
            </ClassFeedbackStyle.TitleText>
          </ClassFeedbackStyle.FeedbackLayout>
          <ClassFeedbackStyle.FeedbackLayout style={{ display: 'flex', width: '100%' }}>
            {b2cPaidFormKeyEveryFive.map(rate => (
              <ClassFeedbackStyle.Content style={{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <ClassFeedbackStyle.CustomButtonRateOrder
                disabled={this.state.batchSessionDataToSendTopicOrder[index][rate] >= 5}
                onClick={() => {
                  const mmsTemp = this.state.batchSessionDataToSendTopicOrder
                  mmsTemp[index][rate] += 1
                  this.setState({
                    batchSessionDataToSendTopicOrder: mmsTemp
                  })
                }}
                style={{ border: 'none', fontSize: '20px', fontWeight: '800' }}
              ><PlusOutlined style={{ color: '#000' }} />
              </ClassFeedbackStyle.CustomButtonRateOrder>
              <ClassFeedbackStyle.CustomButtonRateOrder style={{ margin: '0 10px', width: '30px', height: '30px' }}>
                {this.state.batchSessionDataToSendTopicOrder[index][rate]}
              </ClassFeedbackStyle.CustomButtonRateOrder>
              <ClassFeedbackStyle.CustomButtonRateOrder
                disabled={this.state.batchSessionDataToSendTopicOrder[index][rate] <= 1}
                onClick={() => {
                  const mmsTemp = this.state.batchSessionDataToSendTopicOrder
                  mmsTemp[index][rate] -= 1
                  this.setState({
                    batchSessionDataToSendTopicOrder: mmsTemp
                  })
                }}
                style={{ border: 'none', fontSize: '20px', fontWeight: '800' }}
              ><MinusOutlined style={{ color: '#000' }} />
              </ClassFeedbackStyle.CustomButtonRateOrder>
              </ClassFeedbackStyle.Content>
            ))}
          </ClassFeedbackStyle.FeedbackLayout>
        </ClassFeedbackStyle.FeedbackContainer>
      ))}
    </>
  )

  renderConcludeSectionForBatch = (batchStudents, key = 'sessionCommentByMentor') => (
    <>
      <ClassFeedbackStyle.FeedbackTitle>
        Let parent know more about child ?
      </ClassFeedbackStyle.FeedbackTitle>
      {this.state.batchSessionDataToSendTopicOrder.length > 0 &&
      batchStudents && batchStudents.map((batchStudent, index) => (
      <ClassFeedbackStyle.FeedbackContainer style={{ paddingBottom: '28px' }}>
        <ClassFeedbackStyle.FeedbackLayout placement='left'>
          <ClassFeedbackStyle.FeedbackDescription>
            {get(batchStudent, 'student.user.name', '')}
          </ClassFeedbackStyle.FeedbackDescription>
        </ClassFeedbackStyle.FeedbackLayout>
        <ClassFeedbackStyle.FeedbackLayout>
          <ClassFeedbackStyle.CustomTextArea
            allowClear
            autoSize={{ minRows: 2, maxRows: 6 }}
            style={{ width: '100%', margin: '0px 16px 0px 0px' }}
            disabled={(key === 'sessionCommentByMentor') ? this.state.readOnly :
              get(this.state.batchSessionData, 'record.isFeedbackSubmitted', false)}
            value={this.state.batchSessionDataToSendTopicOrder[index][key]}
            placeholder='Add response here'
            onChange={(e) => {
              const mmsTemp = this.state.batchSessionDataToSendTopicOrder
              mmsTemp[index][key] = e.target.value
              this.setState({
                batchSessionDataToSendTopicOrder: mmsTemp
              })
            }}
          />
        </ClassFeedbackStyle.FeedbackLayout>
      </ClassFeedbackStyle.FeedbackContainer>
      ))}
    </>
  )

  renderFormB2B = () => (
    <>
      <ClassFeedbackStyle.FeedbackTitle>
        About the Students
      </ClassFeedbackStyle.FeedbackTitle>
      {this.state.aboutStudentsB2B && this.state.aboutStudentsB2B.map(student => (
        <>
        {(student.inputType !== 'textArea') ? ( 
          <>
          <ClassFeedbackStyle.FeedbackContainer style={{ paddingBottom: '20px' }}>
          <ClassFeedbackStyle.FeedbackLayout placement='left'>
            <ClassFeedbackStyle.FeedbackDescription>
              {student.label}
            </ClassFeedbackStyle.FeedbackDescription>
          </ClassFeedbackStyle.FeedbackLayout>
          <ClassFeedbackStyle.FeedbackLayout>
            {(student.inputType === 'button') && (
              <>
                {student.values.map(value => (
                  <ClassFeedbackStyle.FieldSelectorButton
                    value={value.toSend}
                    style={{ borderRadius: '15px'}}
                    onClick={e => {
                      this.setState({ [student.key]: e.target.value })
                    }}
                    isSelected={this.state[student.key] === value.toSend}
                  >
                    {value.toShow}
                  </ClassFeedbackStyle.FieldSelectorButton>
                ))}
              </>
            )}
            {(student.inputType === 'radio') && (
              <>
                {student.values.map(value => (
                    <ClassFeedbackStyle.CustomButtonRate
                      value={value}
                      onClick={() => {
                        this.setState({ [student.key]: value })
                      }}
                      isSelected={this.state[student.key] === value}
                    >
                      {value}
                    </ClassFeedbackStyle.CustomButtonRate>
                ))}
              </>
            )}
          </ClassFeedbackStyle.FeedbackLayout>
          </ClassFeedbackStyle.FeedbackContainer>
          {(student.inputType === 'radio') && <ClassFeedbackStyle.Divider /> }
          </>
        ) : (
          <>
          <ClassFeedbackStyle.FeedbackTitle style={{ marginBottom: '20px' }}>
            {student.label}
          </ClassFeedbackStyle.FeedbackTitle>
          <ClassFeedbackStyle.FeedbackContainer style={{ paddingBottom: '28px' }}>
            <ClassFeedbackStyle.FeedbackLayout placement='left'>
              <ClassFeedbackStyle.FeedbackDescription>
                Comments
              </ClassFeedbackStyle.FeedbackDescription>
            </ClassFeedbackStyle.FeedbackLayout>
            <ClassFeedbackStyle.FeedbackLayout>
              <ClassFeedbackStyle.CustomTextArea
                allowClear
                autoSize={{ minRows: 2, maxRows: 6 }}
                style={{ width: '100%', margin: '0px 16px 0px 0px' }}
                value={this.state[student.key]}
                placeholder='Add response here'
                onChange={(e) => {
                  this.setState({ [student.key]: e.target.value })
                }}
              />
            </ClassFeedbackStyle.FeedbackLayout>
          </ClassFeedbackStyle.FeedbackContainer>
          </>
        )}
        </>
      ))}
    </>
  )

  renderTellMoreAboutContent = () => (
    <>
    <ClassFeedbackStyle.FeedbackTitle>
      Tell us something about the content of session
    </ClassFeedbackStyle.FeedbackTitle>
    {this.state.tellMoreAboutContent && this.state.tellMoreAboutContent.map(content => (
      <ClassFeedbackStyle.FeedbackContainer style={{ paddingBottom: '20px' }}>
      <ClassFeedbackStyle.FeedbackLayout placement='left'>
        <ClassFeedbackStyle.FeedbackDescription>
          {content.label}
        </ClassFeedbackStyle.FeedbackDescription>
      </ClassFeedbackStyle.FeedbackLayout>
      <ClassFeedbackStyle.FeedbackLayout>
        {(content.inputType === 'button') && (
          <>
            {content.values.map(value => (
              <ClassFeedbackStyle.FieldSelectorButton
                value={value.toSend}
                style={{ borderRadius: '15px'}}
                onClick={e => {
                  this.setState({ [content.key]: e.target.value })
                }}
                isSelected={this.state[content.key] === value.toSend}
              >
                {value.toShow}
              </ClassFeedbackStyle.FieldSelectorButton>
            ))}
          </>
        )}
        {(content.inputType === 'textArea') && (
          <ClassFeedbackStyle.CustomTextArea
            allowClear
            autoSize={{ minRows: 2, maxRows: 6 }}
            style={{ width: '100%', margin: '0px 16px 0px 0px' }}
            disabled={this.state.readOnly}
            value={this.state[content.key]}
            placeholder='Add response here'
            onChange={(e) => {
              this.setState({
                [content.key]: e.target.value,
              })
            }}
          />
        )}
      </ClassFeedbackStyle.FeedbackLayout>
      </ClassFeedbackStyle.FeedbackContainer>
    ))}
    </>
  )

  renderAdditionalCommentsB2B = () => (
    <>
    {this.state.commentsB2B && this.state.commentsB2B.map(comment => (
      <>
        <ClassFeedbackStyle.FeedbackTitle>
          {comment.label}
        </ClassFeedbackStyle.FeedbackTitle>
        <ClassFeedbackStyle.FeedbackContainer style={{ paddingBottom: '28px' }}>
          <ClassFeedbackStyle.FeedbackLayout placement='left'>
            <ClassFeedbackStyle.FeedbackDescription style={{ width: '100%' }}>
              Comments / Suggestions
              {this.renderRequiredAsterisk(true)}
            </ClassFeedbackStyle.FeedbackDescription>
          </ClassFeedbackStyle.FeedbackLayout>
          <ClassFeedbackStyle.FeedbackLayout>
            <ClassFeedbackStyle.CustomTextArea
              allowClear
              autoSize={{ minRows: 2, maxRows: 6 }}
              style={{ width: '100%', margin: '0px 16px 0px 0px' }}
              value={this.state[comment.key]}
              placeholder='Tell us more...'
              onChange={e => {
                this.setState({
                  [comment.key]: e.target.value
                })
              }}
            />
            {(this.state.showError && get(this.state, `showError.${comment.key}`)) && (
              <ClassFeedbackStyle.Error className='feedback_error'>
                Fill this field before going further*
              </ClassFeedbackStyle.Error>
            )}
          </ClassFeedbackStyle.FeedbackLayout>
        </ClassFeedbackStyle.FeedbackContainer>
        <ClassFeedbackStyle.Divider />
      </>
    ))}
    </>
  )

  renderConcludeSection = (batchSessionActive = false, key = 'sessionCommentByMentor') => (
    <>
      <ClassFeedbackStyle.FeedbackTitle style={{ marginBottom: '20px' }}>
        {this.state.demoCompleted ? 'Conclude' : ''}
        {this.state.isSessionRescheduled ? 'Other Reason/Challenges' : ''}
        {batchSessionActive ? 'Anything else you want us to know ?' : ''}
      </ClassFeedbackStyle.FeedbackTitle>
      <ClassFeedbackStyle.FeedbackContainer style={{ paddingBottom: '28px' }}>
        <ClassFeedbackStyle.FeedbackLayout placement='left'>
          <ClassFeedbackStyle.FeedbackDescription>
            Comments / Suggestions
            {this.renderRequiredAsterisk(true)}
          </ClassFeedbackStyle.FeedbackDescription>
        </ClassFeedbackStyle.FeedbackLayout>
        <ClassFeedbackStyle.FeedbackLayout>
          <ClassFeedbackStyle.CustomTextArea
            allowClear
            autoSize={{ minRows: 2, maxRows: 6 }}
            style={{ width: '100%', margin: '0px 16px 0px 0px' }}
            disabled={this.state.readOnly}
            value={this.state[key]}
            placeholder='Add response here'
            onChange={(e) => {
              this.setState({
                [key]: e.target.value,
                showError: {
                  ...this.state.showError,
                  [key]: false
                }
              })
            }}
            onBlur={() => this.setState({ [key]: get(this.state, key, '').trim() })}
          />
          {(this.state.showError && get(this.state, `showError.${key}`)) && (
            <ClassFeedbackStyle.Error className='feedback_error'>
              Fill this field before going further*
            </ClassFeedbackStyle.Error>
          )}
        </ClassFeedbackStyle.FeedbackLayout>
      </ClassFeedbackStyle.FeedbackContainer>
    </>
  )

  handleSubmitButtonDisabled = () => {
    if (this.state.readOnly || !get(this.state, 'session.id', null) || !get(this.state, 'studentNote', '')) {
      return true
    }
    if (this.state.selectedTags.length < 3) {
      return true
    }
    this.state.ratings.map(rating => {
      if (!rating.star) {
        return true
      }
    })
    return false
  }

  renderFooter = (isBatchSessionActive = false) => (
    <ClassFeedbackStyle.FlexRow justifyContent='space-between' style={{ padding: `${isBatchSessionActive ? '4px 24px' : ''}`, marginTop: 0, marginBottom: '20px' }}>
      <ClassFeedbackStyle.FooterText>
        {!this.state.readOnly && (
          'Please check the responses before submitting the feedback'
        )}
      </ClassFeedbackStyle.FooterText>
      <ClassFeedbackStyle.FlexRow justifyContent='flex-end' style={{ marginTop: 0, flexWrap: 'no-wrap', width: 'fit-content' }}>
        <Modal.SecondaryButton
          onClick={() => {
            this.navigateBack()
          }}
          disabled={this.state.isLoading}
          style={{ marginRight: '10px' }}
        >
          Cancel
        </Modal.SecondaryButton>
        <Modal.PrimaryButton
          disabled={this.state.readOnly}
          onClick={() =>
            this.onSave()
          }
          loading={this.state.isLoading}
        >
          {this.state.isLoading &&
            <Modal.Spinner />}
          Submit Feedback
        </Modal.PrimaryButton>
      </ClassFeedbackStyle.FlexRow>
    </ClassFeedbackStyle.FlexRow>
  )

  getSessionType = (sessionType) => {
    if (sessionType === 'batch') {
      return 'PAID'
    }
    if (sessionType === 'trial') {
      return 'DEMO'
    }
    return sessionType
  }

  getClassDuration = (session) => {
    if (get(session, 'sessionStartDate') && get(session, 'sessionEndDate')) {
      return getDuration(get(session, 'sessionStartDate'), get(session, 'sessionEndDate'))
    }
    return moment(get(session, 'sessionStartDate')).format('DD MMM hh:mm A')
  }

  renderTopSessionBlock = (session) => {
    const { batchSessionData } = this.state
    session = get(batchSessionData, 'record', null) || session
    const isBatchSession = get(batchSessionData, 'isBatchSession', false)
    const isBatchType = get(batchSessionData, 'record.batch.type', null) === 'b2b'
    let sessionDateAndDurationString = ''
    let sessionStudentsNameOrMeta = ''
    if (isBatchSession) {
      sessionDateAndDurationString = `${getSlotTime(session).startTime} - ${getSlotTime(session).endTime}, IST • ${moment(get(session, 'bookingDate')).format('ddd, DD MMM')}`
      sessionStudentsNameOrMeta = get(session, 'attendance', []).length
    } else if (get(session, 'menteeSessionData')) {
      sessionDateAndDurationString = `${getSlotTime(get(session, 'menteeSessionData')).startTime} - ${getSlotTime(get(session, 'menteeSessionData')).endTime}, IST • ${moment(get(session, 'menteeSessionData.bookingDate')).format('ddd, DD MMM')}`
      sessionStudentsNameOrMeta = get(session, 'menteeSessionData.userData.name')
    }
    return (
      <ClassFeedbackStyle.FlexContainer style={{ justifyContent: 'space-between', width: '100%', flexWrap: 'wrap' }}>
        <ClassFeedbackStyle.FlexContainer style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
          <ClassFeedbackStyle.HeaderTitle>Class Feedback</ClassFeedbackStyle.HeaderTitle>
          <ClassFeedbackStyle.HeaderDetailsContainer>
            <ClassFeedbackStyle.TopicThumbnail
              bgImage={get(session, 'topic.thumbnailSmall.uri')}
            />
            <ClassFeedbackStyle.HeaderDetails>
              <ClassFeedbackStyle.FlexContainer style={{ paddingBottom: '12px' }}>
                <ClassFeedbackStyle.HeaderSessionIndicator bgColor={isBatchSession ? '#00ADE6' : '#01AA93'} />
                <ClassFeedbackStyle.PreHeaderText>
                  {isBatchSession && isBatchType ? 'B2B' : 'B2C'}
                </ClassFeedbackStyle.PreHeaderText>
                <ClassFeedbackStyle.HeaderTag
                  bgColor={get(session, 'mentorSessionData.sessionType') === 'trial' ? '#333333' : null}
                >
                  {this.getSessionType(get(session, 'mentorSessionData.sessionType'))}
                </ClassFeedbackStyle.HeaderTag>
              </ClassFeedbackStyle.FlexContainer>
              <ClassFeedbackStyle.Content>
                <ClassFeedbackStyle.HeaderCourse>
                  {get(session, 'course.title', '-')}
                </ClassFeedbackStyle.HeaderCourse>
                <ClassFeedbackStyle.HeaderTopic>
                  {get(session, 'topic.title', '-')}
                </ClassFeedbackStyle.HeaderTopic>
              </ClassFeedbackStyle.Content>
              <ClassFeedbackStyle.HeaderDescription>
                <ClassFeedbackStyle.Icon theme='twoTone' component={ClockSVG} />
                {sessionDateAndDurationString || ''}
              </ClassFeedbackStyle.HeaderDescription>
            </ClassFeedbackStyle.HeaderDetails>
          </ClassFeedbackStyle.HeaderDetailsContainer>
        </ClassFeedbackStyle.FlexContainer>
        <ClassFeedbackStyle.FlexContainer style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
          <ClassFeedbackStyle.SummaryText>Class Summary</ClassFeedbackStyle.SummaryText>
          <div>
            {!this.state.isSessionFromLog && (
              this.renderClassDetails({
                type: 'Duration',
                value: this.getClassDuration(session),
                icon: ClockSVG
              })
            )}
            {isBatchSession && (
              this.renderClassDetails({ type: 'Batch', value: get(session, 'batch.code', ''), icon: BatchSVG })
            )}
            {this.renderClassDetails({ type: 'Students', value: sessionStudentsNameOrMeta, icon: RatioSVG })}
            {this.renderClassDetails({ type: 'Type', value: 'Learning', icon: TypeSVG })}
          </div>
        </ClassFeedbackStyle.FlexContainer>
      </ClassFeedbackStyle.FlexContainer>
    )
  }

  render() {
    const { session, batchSessionData } = this.state
    const isBatchSessionActive = get(batchSessionData, 'isBatchSession', false)
    const batchStudents = get(batchSessionData, 'record.attendance', [])
    const topicOrder = get(batchSessionData, 'record.topic.order', null)
    const batchType = get(batchSessionData, 'record.batch.type', null)
    return (
      <div>
        {this.state.isFetching ? (
          <>
            <div className='loading-container show'>
              <div className='loading-bar-container'>
                <div />
              </div>
            </div>
            <div className='mentor-dashboard-calender-loading-container'>
              <Spin indicator={loadingIcon} />
              <div className='mentor-dashboard-calender-loading'>Fetching Sessions...</div>
            </div>
          </>
        ) : (null)}
        {/* Session Details Block */}
        <ClassFeedbackStyle.Container style={{ padding: '15px 25px' }}>
          {!this.state.isFetching && this.renderTopSessionBlock(session)}
        </ClassFeedbackStyle.Container>
        {/* Class Status Block */}
        {!isBatchSessionActive && (
          <ClassFeedbackStyle.Container>
            {this.renderAboutClassStatus(isBatchSessionActive)}
          </ClassFeedbackStyle.Container>
        )}
        {/* b2c batch session */}
        {isBatchSessionActive && batchType === 'normal' && topicOrder % 5 !== 0 && (
          <ClassFeedbackStyle.Container style={{ width: '100%' }}>
            {this.renderB2Cpaid(batchStudents)}
            {this.renderConcludeSection(isBatchSessionActive, 'batchSessionComment')}
            <ClassFeedbackStyle.Divider />
            {this.renderFooter(isBatchSessionActive)}
          </ClassFeedbackStyle.Container>
        )}
        {isBatchSessionActive && batchType === 'normal' && topicOrder % 5 === 0 && (
          <ClassFeedbackStyle.Container>
            {this.renderKidSkillsRate(batchStudents)}
            <ClassFeedbackStyle.Divider />
            {this.renderConcludeSectionForBatch(batchStudents)}
            <ClassFeedbackStyle.Divider />
            {this.renderFooter(isBatchSessionActive)}
          </ClassFeedbackStyle.Container>
        )}
        {isBatchSessionActive && batchType === 'b2b' && (
          <ClassFeedbackStyle.Container>
            {this.renderFormB2B()}
            <ClassFeedbackStyle.Divider />
            {this.renderTellMoreAboutContent()}
            <ClassFeedbackStyle.Divider />
            {this.renderAdditionalCommentsB2B()}
            {this.renderFooter()}
          </ClassFeedbackStyle.Container>
        )}
        {/* Class Challenges, Counselling, Persona and Conclude Block */}
        {!isBatchSessionActive &&
        (this.state.demoCompleted === true || this.state.isSessionRescheduled === true || this.state.sessionNotConducted === true) && (
        <ClassFeedbackStyle.Container>
          <>
            {this.state.demoCompleted === true && (
              <div style={{ width: `${isBatchSessionActive ? '' : '100%'}` }}>
                {this.renderClassChallenges()}
                <ClassFeedbackStyle.Divider />
                {this.renderStudentPersonaSection()}
                <ClassFeedbackStyle.Divider />
                {this.renderCounsellingSection()}
                <ClassFeedbackStyle.Divider />
                {this.renderConcludeSection(isBatchSessionActive)}
                <ClassFeedbackStyle.Divider />
                {this.renderFooter(isBatchSessionActive)}
              </div>
            )}
            {(this.state.isSessionRescheduled || this.state.sessionNotConducted) && (
              <div style={{ width: `${isBatchSessionActive ? '' : '100%'}` }}>
                {this.renderRescheduledSection()}
                <ClassFeedbackStyle.Divider />
                {this.renderClassChallenges()}
                <ClassFeedbackStyle.Divider />
                {this.renderLeftMidWay()}
                <ClassFeedbackStyle.Divider />
                {this.renderConcludeSection(isBatchSessionActive)}
                <ClassFeedbackStyle.Divider />
                {this.renderFooter(isBatchSessionActive)}
              </div>
            )}
          </>
        </ClassFeedbackStyle.Container>
        )}
        <BatchRescheduleModal
          isModalVisible={this.state.isShiftStudentModalVisible}
          occupiedBatchInfo={this.state.occupiedBatch}
          ongoingRescheduleDetails={this.state.ongoingRescheduleDetails}
          updateBatchStatus={this.props.updateBatchStatus && this.props.updateBatchStatus.toJS()}
          isLoading={this.state.isLoading}
          rescheduleOrDeleteB2B2CSession={async (startHour, selectedDate) => {
            const lastCreatedBatch = this.state.lastCreatedBatch
            this.setState({
              isLoading: true,
            })
            if (startHour && selectedDate) {
              this.setState({
                shouldReschedule: true,
                selectedRescheduledDate: selectedDate,
                rescheduledTimeValue: { startHour },
              }, async () => {
                await this.rescheduleOrDeleteB2B2CSession(true, true, false, get(this.state.batchSessionData, 'selectedSession'))
                this.deletePrevCreatedBatch(lastCreatedBatch)
              })
            } else {
              await this.rescheduleOrDeleteB2B2CSession(false, false, false, get(this.state.batchSessionData, 'selectedSession'))
              this.deletePrevCreatedBatch(lastCreatedBatch)
            }
          }}
          setModalVisibility={() => {
            this.setState({
              isShiftStudentModalVisible: false
            })
          }}
        />
      </div>
    )
  }
}

export default MentorDashboard
