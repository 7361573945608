import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const deleteMentorMenteeSession = async (id, key) => duck.query({
  query: gql`
    mutation{
        deleteMentorMenteeSession(id:"${id}") {
            id
            menteeSession{
              id
            }
        }
    }
  `,
  type: 'mentorMenteeSession/delete',
  key: key || 'mentorMenteeSession',
  changeExtractedData: (extractedData, originalData) => {
    extractedData.menteeSession = []
    extractedData.completedSession = {
      ...get(originalData, 'deleteMentorMenteeSession')
    }
    return extractedData
  }
})

export default deleteMentorMenteeSession
