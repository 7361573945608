import React, { Component } from 'react';
import { Switch } from 'antd';

export default class SwitchConstant extends Component {
  
  render() {
    const { on , off, toggle,switchColor } = this.props;
    return (
      <>
      <div style={{display:"flex"}}>
        <div style={{ color: "#0707078a" }}><b>{off}</b></div>
        <Switch style={{marginLeft:"5px",backgroundColor:switchColor===0?"rgb(12, 167, 137)":"grey"}} defaultChecked onChange={toggle} checked={switchColor===0?true:false}/>
        <div style={{ marginLeft:"5px",color: "#0707078a" }}><b>{on}</b></div>
        </div>
      </>
    )
  }
}
