import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import withUpdatedDashboardNav from '../../../components/withUpdatedNav'
import { EVENT_DASHBOARD } from '../../../constants/navItemsConst'
import injectProps from '../../../components/injectProps'
import CertificateTemplate from './certificateTemplate'

const CertificateTemplateSideNav = withUpdatedDashboardNav(CertificateTemplate)({
  title: 'Event Overview',
  activeNavItem: 'Events',
  showUMSNavigation: true,
  renderNavItems: EVENT_DASHBOARD,
  noPadding: true
})

const mapStateToProps = state => ({
  fetchedEventEmbeds: state.data.getIn([
    'eventCertificateEmbeds',
    'data'
  ]),
  isAddingEventCertificateEmbeds: state.data.getIn(['eventCertificateEmbeds', 'addStatus', 'eventCertificateEmbeds', 'loading']),
  fetchedEvents: state.data.getIn(['events', 'data']),
  commsTags: state.data.getIn(['commsVariables', 'data']),
  eventUpdateStatus: state.data.getIn(['events', 'updateStatus', 'events']),
  eventData: state.data.getIn(['events', 'data']),
  eventDataFetchStatus: state.data.getIn(['events', 'fetchStatus', 'events']),
})


const CertificateTemplateSideNavWithExtraProps = injectProps({
  notification
})(CertificateTemplateSideNav)
export default connect(mapStateToProps)(withRouter(CertificateTemplateSideNavWithExtraProps))
