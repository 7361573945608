import { notification } from 'antd'
import React from 'react'
import { get } from 'lodash'
import createLoginLink from '../../../../actions/loginLink/createLoginLink'
import MainModal from '../../../../components/MainModal'
import {
  CloseIcon, FlexContainer,
} from '../../SchoolOnBoarding.style'
import StudentLinkEditForm from './StudentLinkEditForm'
import copyToClipboard from '../../../../utils/copyToClipboard'
import convertToHours from '../../../../utils/convertToHours'

class StudentLinkLModal extends React.Component {
  state = {
    copyLinkLoading: false,
    loading: false,
  }
  downloadRef = React.createRef()

  handleEmailCredentails = async (values) => {
    try {
      this.setState({ loading: true })
      const { user } = this.props.editStudent
      const { duration, linkVisitLimit = 2 } = values
      const { type } = values
      if (!type && !duration) {
        this.setState({ loading: false })
        return notification.warn({
          message: 'Please enter expiry time'
        })
      }
      const newDuration = convertToHours(duration, type)
      const filterQuery = `input: {linkVisitLimit: ${linkVisitLimit || 2}, userId: "${user.id}", expiresIn: ${newDuration}}`
      const result = await createLoginLink(filterQuery, 1)
      if (result) {
        this.setState({ loading: false })
        notification.success({
          message: 'Mail send successfully'
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  handleCopyLink = async (values) => {
    try {
      this.setState({ copyLinkLoading: true })
      const { user } = this.props.editStudent
      const { duration, linkVisitLimit = 2 } = values
      const { type } = values
      if (!type && !duration) {
        this.setState({ copyLinkLoading: false })
        return notification.warn({
          message: 'Please enter expiry time'
        })
      }
      const newDuration = convertToHours(duration, type)
      const filterQuery = `input: {linkVisitLimit: ${linkVisitLimit || 2}, userId: "${user.id}", expiresIn: ${newDuration},  isDownloadExcel:true}`
      const result = await createLoginLink(filterQuery, 1)
      if (result) {
        if (get(result, 'getMagicLink[0].linkUri')) {
          this.setState({ copyLinkLoading: false })
          return copyToClipboard(get(result, 'getMagicLink[0].linkUri'))
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  render() {
    const {
      visible,
      title,
      editStudent,
      onClose,
      schoolName
    } = this.props
    return (
      <MainModal
        visible={visible}
        onCancel={onClose}
        maskClosable
        bodyStyle={{ padding: 0 }}
        width='650px'
        closable={false}
        centered
        destroyOnClose
        footer={null}
      >
        <FlexContainer noPadding style={{ width: '100%' }}>
          <div style={{ padding: '0.5vw 1.5vw' }}>
            <h1>{title}</h1>
            <h3 style={{ justifyContent: 'center', display: 'flex' }}>
              {schoolName}
            </h3>
            <CloseIcon onClick={onClose} />
          </div>
        </FlexContainer>
        <FlexContainer
          justify='center'
          style={{ flexDirection: 'column', paddingBottom: '1vw' }}
        >
          <StudentLinkEditForm
            loadingStatus={this.state.loading}
            editStudent={editStudent}
            handleEmailCredentails={this.handleEmailCredentails}
            handleCopyLink={this.handleCopyLink}
            onModalClose={onClose}
            copyLinkLoading={this.state.copyLinkLoading}
          />
        </FlexContainer>
      </MainModal>
    )
  }
}

export default StudentLinkLModal
