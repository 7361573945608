import React from 'react'
import Container from './CoursePackageSingle.style'
import { get } from 'lodash'
import { DeleteOutlined, EditOutlined, StarOutlined } from '@ant-design/icons';
import { Switch, Collapse, notification, Spin, Tooltip, Pagination } from 'antd';
import ModalConfirm from '../CoursePackageMaker/components/ModalConfirm/ModalConfirm';
import deleteCoursePackages from '../../actions/CoursePackageMaker/deleteCoursePackages';
import updateCoursePackages from '../../actions/CoursePackageMaker/updateCoursePackages';
import fetchCoursePackage from '../../actions/CoursePackageMaker/fetchCoursePackage';
import { PUBLISHED_STATUS, UNPUBLISHED_STATUS } from '../../constants/questionBank'
import fetchCoursePackageBatches, { fetchCoursePackageBatchesMeta } from './utils';
import './CoursePackageSingle.style.scss'
import AssignTeacherModal from './AssignTeacherModal/AssignTeacherModal';
import { Toggle } from 'rsuite';

const { Panel } = Collapse;

class CoursePackageSingle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            packageDetails: [],
            isPackageSessionActive: true,
            isListOfSessionActive: false,
            packageData: [],
            schoolDetails: [],
            showDeleteModal: false,
            showPublishModal: false,
            showAssignTeacherModal: false,
            currentBatch: null,
            isBatchesLoading: null,
            currentPage: 1,
            perPage: 50,
            skip: 0,
            totalPackageBatches: 0,
            visitedFirstTime: false,
        }
    }

    componentDidMount = async() => {
        const { packageId } = this.props.match.params
        if (packageId) {
            fetchCoursePackage(packageId)
            this.fetchCoursePackageBatches()
            this.fetchCoursePackageBatchesMeta()
            this.setState({visitedFirstTime: true})
        }
    }

    componentDidUpdate = async (prevProps) => {
        const { fetchCoursePackageStatus, coursePackage, updateCoursePackageStatus, packageUpdateFailure,
            deleteCoursePackagesStatus, packageDeleteFailure } = this.props
        if (fetchCoursePackageStatus && !get(fetchCoursePackageStatus.toJS(), 'loading')
        && get(fetchCoursePackageStatus.toJS(), 'success')
        && prevProps.fetchCoursePackageStatus !== fetchCoursePackageStatus) {
            const packages = coursePackage && coursePackage.toJS() || []
            this.getPackageDetails(packages)
        }
        if (updateCoursePackageStatus && !get(updateCoursePackageStatus.toJS(), 'loading')
        && get(updateCoursePackageStatus.toJS(), 'success')
        && prevProps.updateCoursePackageStatus !== updateCoursePackageStatus) {
            fetchCoursePackage(get(this.props.match.params, 'packageId'))
            notification.success({
                message: 'Package updated successfully'
            })
        } else if (updateCoursePackageStatus && !get(updateCoursePackageStatus.toJS(), 'loading')
        && get(updateCoursePackageStatus.toJS(), 'failure')
        && prevProps.packageUpdateFailure !== packageUpdateFailure) {
        if (packageUpdateFailure && packageUpdateFailure.toJS().length > 0) {
          const errors = packageUpdateFailure.toJS().pop()
          notification.error({
            message: get(get(errors, 'error').errors[0], 'message')
          })
        }
        }
        if (deleteCoursePackagesStatus && !get(deleteCoursePackagesStatus.toJS(), 'loading')
        && get(deleteCoursePackagesStatus.toJS(), 'success') &&
        (prevProps.deleteCoursePackagesStatus !== deleteCoursePackagesStatus)) {
            this.props.history.push('/coursePackages')
            notification.success({
                message: 'Package deleted successfully'
            })
        } else if (deleteCoursePackagesStatus && !get(deleteCoursePackagesStatus.toJS(), 'loading')
        && get(deleteCoursePackagesStatus.toJS(), 'failure') &&
        (prevProps.packageDeleteFailure !== packageDeleteFailure)) {
        if (packageDeleteFailure && packageDeleteFailure.toJS().length > 0) {
            const errors = packageDeleteFailure.toJS().pop()
            notification.error({
                message: get(get(errors, 'error').errors[0], 'message')
            })
        }
        }
    }

    fetchCoursePackageBatchesMeta = () => {
        const { packageId } = this.props.match.params
        fetchCoursePackageBatchesMeta(packageId).then(res => this.setState({totalPackageBatches:res}))
    }

    fetchCoursePackageBatches = () => {
        const { packageId } = this.props.match.params
        this.setState({ isBatchesLoading: true })
        fetchCoursePackageBatches(packageId,this.state.perPage,this.state.skip).then(res => {
            const schoolsDataById = {}
            res && res.forEach(item => {
                const schoolName = get(item, 'school.name')
                if (schoolName) {
                    if (schoolsDataById[schoolName]) {
                        schoolsDataById[schoolName] = [...schoolsDataById[schoolName], {...item}]
                    } else {
                        schoolsDataById[schoolName] = [{...item}]
                    }
                }
            })
            let schoolsDataByIdBatch = {}
            for (const i in schoolsDataById) {
                const obj = {}
                schoolsDataById[i].forEach(item => {
                    const batchCode = get(item , 'code')
                    if (obj[batchCode]) {
                        obj[batchCode] = [...obj[batchCode], {...item}]
                    } else {
                        obj[batchCode] = [{...item}]
                    }
                })
                schoolsDataByIdBatch[i] = obj
            }
            this.setState({ schoolDetails: schoolsDataByIdBatch, isBatchesLoading: false })
        })
    }

    getPackageDetails = (result) => {
        if (Object.keys(result).length) {
            this.setState({ packageDetails: result })
            let selectedTopics = get(result, 'topics')
            const courses = get(result, 'courses', [])
            let mapCourseWithTopic = []
            for (let i = 0; i < selectedTopics.length; i++) {
                const targetTopicId = get(selectedTopics[i], 'topic.id', '')
                const chapterTitle = get(selectedTopics[i], 'topic.chapter.title', '')
                const classroomClass = get(selectedTopics,'isMandatory', '')
                const order = get(selectedTopics[i], 'order', '')
                for (let j = 0; j < courses.length; j++) {
                    const topics = get(courses[j], 'topics', '')
                    for (let z = 0; z < topics.length; z++) {
                        if (targetTopicId === get(topics[z], 'id', '')) {
                            const temp = {
                                course: j,
                                topic: z,
                                topicId: targetTopicId,
                                topicTitle: get(topics[z], 'title', ''),
                                courseTitle: get(courses[j], 'title', ''),
                                chapterTitle: chapterTitle,
                                courseCategory: get(courses[j], 'category', ''),
                                order,
                                classroomClass
                            }
                            mapCourseWithTopic.push(temp)
                        }
                    }
                }
            }
            const tempMapCourseWithTopic = mapCourseWithTopic.sort((a, b) => a.order - b.order)
            const revisionArray = []
            selectedTopics.forEach((item, index) => {
                if (item.isRevision) {
                    item.topicId = `id_${index}`
                    item.topicTitle = item.title
                    revisionArray.push(item)
                }
            })
            const newRevisionArray = revisionArray.sort((a, b) => a.previousTopicOrder - b.previousTopicOrder)
            newRevisionArray.forEach((item, index) => {
                tempMapCourseWithTopic.splice(item.previousTopicOrder + index, 0, item)
            })
            this.setState({ packageData: tempMapCourseWithTopic })
        }
    }

    handleDelete = async () => {
        const { packageId } = this.props.match.params
        await deleteCoursePackages(packageId)
        this.setState({ showDeleteModal: false })
    }

    handlePublishStatus = async () => {
        let inputObj = {
            status: UNPUBLISHED_STATUS
        }
        if (get(this.state.packageDetails, 'status') === UNPUBLISHED_STATUS) {
            inputObj = {
                status: PUBLISHED_STATUS,
            }
        }
        const { packageId } = this.props.match.params

        await updateCoursePackages({ packageId, inputObj })
        this.setState({ showPublishModal: false })
    }

    checkStatus = () => get(this.state.packageDetails, 'status') === PUBLISHED_STATUS

    openAssignTeacherModal = (batch) => {
        this.setState({
            showAssignTeacherModal: true,
            currentBatch: batch
        })
    }

    renderPackageListContainer = () => (
        <>
            <h3 style={{ marginBottom: '64px' }}>Session List</h3>
            <Container.SessionListHeadContainer>
                <span style={{ width: '10%' }}>S. NO</span>
                <span>Session Type</span>
                <span>Course</span>
                <span>Chapter</span>
                <span>Topic</span>
                <span>Description</span>
            </Container.SessionListHeadContainer>
            {this.state.packageData && this.state.packageData.length > 0 && this.state.packageData.map((item, i) => (
                <Container.SessionListDataContainer>
                    <span style={{ paddingLeft: '12px', width: '10%'}}>{i + 1}</span>
                    <button className='collapsable-button'><span>{get(item, 'isRevision') ? 'revision' : 'learning'}</span></button>
                    <span>{get(item, 'courseTitle', '-')}</span>
                    <span>{get(item, 'chapterTitle', '-')}</span>
                    <span>{get(item, 'topicTitle', '-')}</span>
                    <span>{get(item, 'description', '-')}</span>
                </Container.SessionListDataContainer>
            ))}
        </>
    )

    renderCoursePackageCourses = (coursePackageCourses) => {
        let courses = ''
        if (coursePackageCourses.length > 0) {
            coursePackageCourses.forEach(item => {
                courses += get(item, 'title') + ', '
            })
        } else {
            const coursePackageCorusesMaster = get(this.state.packageDetails, 'courses', [])
            coursePackageCorusesMaster.forEach(item => {
                courses += get(item, 'title') + ', '
            })
        }
        return courses.substring(0, courses.length-2)
    }

    renderSchoolsContainer = () => {
        const { schoolDetails } = this.state
        return (
        <>
        {(get(this.props.fetchCoursePackageStatus && this.props.fetchCoursePackageStatus.toJS(), 'loading') || (this.state.isBatchesLoading)) ?
                <div style={{
                    width: "100%",
                    height: "58vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                    }}
                >
                <Spin size='large' />
                </div>
            : 
            <><h3 style={{ marginBottom: '11px' }}>List of Batches</h3><Collapse defaultActiveKey={['0']} expandIconPosition='right' accordion>
                        {Object.keys(schoolDetails).length > 0 && Object.keys(schoolDetails).map((school, i) => (
                            <Panel
                                header={<Container.PanelHead>
                                    <h3>{school}</h3>
                                </Container.PanelHead>}
                                key={i}
                            >
                                <Container.BatchesHead>
                                    <p style={{ width: '8%', textAlign: 'center' }}>S.No</p>
                                    <p style={{ width: '10%' }}>Batch</p>
                                    <p style={{ width: '30%' }}>Courses</p>
                                    <p style={{ width: '20%' }}>Teacher Name</p>
                                    <p style={{ width: '10%' }}>Action</p>
                                    <p style={{ width: '10%' }}>Assign Teacher</p>
                                </Container.BatchesHead>
                                {Object.keys(schoolDetails[school]).map((item, index) => (
                                    <div style={{ background: index % 2 == 0 ? '#fff' : '#f9f9f9' }}>
                                        {schoolDetails[school][item].map((temp, idx) => (
                                            <Container.BatchesData>
                                                <p style={{ width: '8%', textAlign: 'center' }}>{idx === 0 ? index + 1 : ''}</p>
                                                <p style={{ width: '10%' }}>{idx === 0 ? (get(temp, 'classroomTitle') || get(temp, 'code')) : ''}</p>
                                                <p style={{ width: '30%' }}>
                                                    {this.renderCoursePackageCourses(get(temp, 'coursePackageCourses', []))}
                                                </p>
                                                <p style={{ width: '20%' }}>{get(temp, 'allottedMentor.name')}</p>
                                                <p style={{ width: '10%' }}>
                                                    <Container.EditButton style={{ color: '#fff', background: '#8C61CB' }}>
                                                        <EditOutlined style={{ margin: '0 5px' }} />
                                                        <span
                                                            style={{ margin: '0 5px 0 3px' }}
                                                            onClick={() => this.props.history.push(`/coursePackages/batch/${get(this.props.match.params, 'packageId')}/${get(temp, 'id')}`)}
                                                        >Edit
                                                        </span>
                                                    </Container.EditButton>
                                                </p>
                                                {idx === 0 ? (
                                                    <p style={{ width: '10%' }}>
                                                        <Container.EditButton style={{ color: '#8C61CB', background: '#FAF5FF' }}>
                                                            <span
                                                                style={{ margin: '0 5px' }}
                                                                onClick={() => this.openAssignTeacherModal(schoolDetails[school][item])}
                                                            >
                                                                Assign
                                                            </span>
                                                        </Container.EditButton>
                                                    </p>) : null}
                                            </Container.BatchesData>
                                        ))}
                                    </div>
                                ))}
                            </Panel>
                        ))}
                    </Collapse>
                    </>
            }
            </>
        )
    }

    renderTopNavigation = () => (
        <Container.TopNavigation>
            <span onClick={() => this.props.history.push('/coursePackages')} style={{ cursor: 'pointer' }}>Course Packages </span>&#62; <span>{get(this.state.packageDetails, 'title', '')}</span>
        </Container.TopNavigation>
    )

    getIndividualSessionTypeCount = () => {
        const obj = {}
        const { packageData } = this.state
        packageData && packageData.length && packageData.forEach(item => {
            if (get(item, 'courseCategory')) {
                if (obj[item.courseCategory] && obj[item.courseCategory][item.courseTitle]) {
                    obj[item.courseCategory][item.courseTitle] += 1
                } else {
                    if (!obj[item.courseCategory]) {
                        obj[item.courseCategory] = {}
                    }
                    obj[item.courseCategory][item.courseTitle] = 1
                }
            }
        })
        return (
            <>
                {obj && Object.keys(obj).map(key => (
                    <Container.IndividualDataContainer>
                        <StarOutlined />
                        <Container.SessionStats>
                            <p style={{ textTransform: 'capitalize' }}>{key}</p>
                            <Container.TagsContainer>
                                {Object.keys(obj[key]).length > 0 && Object.keys(obj[key]).map(course => (
                                    <button>{course} ({obj[key][course]})</button>
                                ))}
                            </Container.TagsContainer>
                        </Container.SessionStats>
                    </Container.IndividualDataContainer>
                ))}
            </>
        )
    }

    onPageChange = (page) => {
        this.setState({
          currentPage: page,
          skip: page - 1
        },this.fetchCoursePackageBatches)
      }
    
    render() {
        const { fetchCoursePackageStatus } = this.props
        const { packageDetails, packageData, isListOfSessionActive, isPackageSessionActive, showDeleteModal, showPublishModal, isBatchesLoading } = this.state
        return (
            <>
            {(get(fetchCoursePackageStatus && fetchCoursePackageStatus.toJS(), 'loading') || (isBatchesLoading && !this.state.visitedFirstTime)) ?
                <div style={{
                    width: "100%",
                    height: "58vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                    }}
                >
                <Spin size='large' />
                </div>
            : (
            <Container>
                {packageDetails && (
                    <>
                        {this.renderTopNavigation()}
                        <Container.DetailsContainer>
                            <Container.PackageDetailsContainer>
                                <Container.NameTheoryCourseContainer>
                                    <Container.NameDescriptionContainer>
                                        <Container.PackageName>
                                            <h3>{get(packageDetails, 'title', '')}</h3>
                                        </Container.PackageName>
                                        <Container.PackageInternalName>{get(packageDetails, 'internalName', '')}</Container.PackageInternalName>
                                        <Container.DescriptionContainer>
                                            Description: <span>{get(packageDetails, 'description', '')}</span>
                                        </Container.DescriptionContainer>
                                    </Container.NameDescriptionContainer>
                                    <Container.SessionsContainer>
                                        {this.getIndividualSessionTypeCount()}
                                        <Container.IndividualDataContainer>
                                            <StarOutlined />
                                            <Container.SessionStats style={{ alignItems: 'center' }}>
                                                <p>Total Sessions</p>
                                                <span>{packageData && packageData.length}</span>
                                            </Container.SessionStats>
                                        </Container.IndividualDataContainer>
                                    </Container.SessionsContainer>
                                </Container.NameTheoryCourseContainer>
                            </Container.PackageDetailsContainer>
                            <Container.FooterContainer>
                                <Container.PublishedContainer>
                                    <Switch defaultChecked checked={this.checkStatus()} onClick={() => this.setState({ showPublishModal: true })} size="small" />
                                    <h3>{get(packageDetails, 'status', '')}</h3>
                                </Container.PublishedContainer>
                                <Container.ButtonContainer>
                                    <Container.StyledButton
                                        onClick={() => this.props.history.push(`/coursePackages/edit/${get(this.props.match.params, 'packageId')}`)}
                                    >
                                        <EditOutlined /><p>Edit Package</p>
                                    </Container.StyledButton>
                                    <Container.StyledButton
                                        onClick={() => this.setState({ showDeleteModal: true })}
                                    >
                                        <DeleteOutlined />
                                        <p>Delete Package</p>
                                    </Container.StyledButton>
                                </Container.ButtonContainer>
                            </Container.FooterContainer>
                        </Container.DetailsContainer>
                        <Container.NavButtonsContainer>
                            <Container.NavButton
                                isSelected={isPackageSessionActive}
                                onClick={() => {
                                    if (Object.keys(this.state.schoolDetails).length != 0) {
                                        this.setState({ isPackageSessionActive: !isPackageSessionActive })
                                        this.setState({ isListOfSessionActive: !isListOfSessionActive })
                                    }
                                }}
                                style={{ cursor: Object.keys(this.state.schoolDetails).length == 0 ? 'default' : 'pointer' }}
                            >Package Sessions</Container.NavButton>
                            {Object.keys(this.state.schoolDetails).length == 0 ? (
                                <Tooltip title='batches not available'>
                                    <Container.NavButton
                                        style={{ cursor: 'default' }}
                                    >List of Batches</Container.NavButton>
                                </Tooltip>
                            ) : (
                                <Container.NavButton
                                    isSelected={isListOfSessionActive}
                                    onClick={() => {
                                        this.setState({ isPackageSessionActive: !isPackageSessionActive })
                                        this.setState({ isListOfSessionActive: !isListOfSessionActive })
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >List of Batches</Container.NavButton>
                            )}
                        </Container.NavButtonsContainer>
                        <Container.PackageListContainer>
                            {isPackageSessionActive ? this.renderPackageListContainer() : this.renderSchoolsContainer()}
                        </Container.PackageListContainer>
                    </>
                )}
                <ModalConfirm
                    showModal={showDeleteModal}
                    onCancel={() => this.setState({ showDeleteModal: false })}
                    heading='Delete Package'
                    main='Do you want to delete this Package ?'
                    confirm='Delete Package'
                    onConfirm={this.handleDelete}
                />
                <ModalConfirm
                    showModal={showPublishModal}
                    onCancel={() => this.setState({ showPublishModal: false })}
                    heading='Change Package Publish Status'
                    main={`Are you sure you want to change publish status of ${get(packageDetails, 'title', '')} ?`}
                    confirm='Change Publish Status'
                    onConfirm={this.handlePublishStatus}
                />
                <AssignTeacherModal
                    showAssignTeacherModal={this.state.showAssignTeacherModal}
                    currentBatch={this.state.currentBatch}
                    packageDetails={this.state.packageDetails}
                    teachers={this.props.teachers}
                    fetchTeachersStatus={this.props.fetchTeachersStatus}
                    handleCancel={() => this.setState({ showAssignTeacherModal: false, currentBatch: null })}
                    addBatchStatus={this.props.addBatchStatus}
                    updateBatchStatus={this.props.updateBatchStatus}
                    deleteBatchStatus={this.props.deleteBatchStatus}
                    fetchCoursePackageBatches={this.fetchCoursePackageBatches}
                />
                {!isPackageSessionActive &&
                <div style={{marginTop:'20px'}}>
                    <Pagination
                        total={this.state.totalPackageBatches}
                        onChange={this.onPageChange}
                        current={this.state.currentPage}
                        defaultPageSize={this.state.perPage}
                    />
                </div>
                }
            </Container>
            )}
            </>
        )
    }
}

export default CoursePackageSingle