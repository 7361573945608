import gql from "graphql-tag"
import requestToGraphql from "../../utils/requestToGraphql"

export const addEbookCoursePackages = async (
    coursePackageConnectIds,
    ebookId,
) => {
    coursePackageConnectIds = coursePackageConnectIds.map((id) => `"${id}"`)
    const query = gql`
        mutation {
            updateEBook(id: "${ebookId}", coursePackageConnectIds: [${coursePackageConnectIds}]){
                id
            }
            
            }
    `
    const response = await requestToGraphql(query)
    return response
}

export const deleteCoursePackageFromEbook = async (

    ebookId,
    coursePackageId
) => {
    const query = gql`
    mutation {
        removeFromEBookCoursePackage(coursePackageId:"${coursePackageId}", eBookId: "${ebookId}"){
          fieldName
        }
      }
    `
    const response = await requestToGraphql(query)
    return response
}
