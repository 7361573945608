import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../../duck'
import getSlotNames from '../../../utils/slots/slot-names'


const fetchBatchSessionForDemand = async (filters) =>
  duck.query({
    query: gql`{
      batchSessions(
            filter: { and:
          [
            ${filters || ''}
            { topic_some:{ order: 1 } }
          ] }
        ) {
            id
            bookingDate
            sessionStatus
            sessionEndDate
            sessionStartDate
            ${getSlotNames()}
            createdAt
            mentorSession {
              id
              user{
                id
                name
                profilePic {
                  id
                  uri
                }
                mentorProfile {
                  id
                  experienceYear
                  pythonCourseRating5
                  pythonCourseRating4
                  pythonCourseRating3
                  pythonCourseRating2
                  pythonCourseRating1
                }
              }
            }
            mentorAvailabilitySlot {
            id
            }
            broadCastedMentors {
              id
            }
            attendance {
              status
            }
            course {
              id
              title
              thumbnail {
                uri
              }
            }
            topic {
              id
              title
              order
            }
            batch {
            id
            code
            type
          }
        }
    }
    `,
    type: 'batchSessions/fetch',
    key: 'mentorMenteeSessionsForDemand',
    changeExtractedData: (extractedData, originalData) => {
      extractedData.batch = []
      extractedData.course = []
      extractedData.topic = []
      extractedData.batchSessions = get(originalData, 'batchSessions', [])
      return { ...extractedData }
    },
  })

export default fetchBatchSessionForDemand

