import React, { useState,useEffect} from 'react'
import { get } from 'lodash'
import { ComponentHolder} from './Text.style'
import { ContentEditor } from '../../../../../../../../components/ContentEditor'
import getDecodedStatement from '../../../../../../../../utils/getDecodedStatement'
import { encodeBase64 } from '../../../../../../../../utils/base64Utility'

const Text =(props)=> {
  const[data,setData]=useState()
  
  useEffect(()=>{
    setData(get(props.selectedContent,'statement'))
  },[props.number,get(props.selectedContent,'statement')])

      const setStatement=(val)=>{
        props.Helper(val,'statement','',props.slideContents,props.number)
        // setData(val)
      }
      
    return <ComponentHolder>
            <ContentEditor
            id= {props.number}
            initialValue={getDecodedStatement(data)}
            // value={getDecodedStatement(get(props.selectedContent,'statement'))}
            ContentLearningSlides
            inputValue={getDecodedStatement(get(props.selectedContent,'statement'))}
            placeholder='Enter text here'
            init={{
              // height: "100",
              selector: `LS-ED-${props.componentId}-${props.number}`,
              placeholder: "Start adding your question title here...",
            }}
            switchBtn={false}
            onBlur={(_, editor) => {
              setStatement(encodeBase64(editor.getContent()))               
            }}
            customHeight={'100%'}
          />

    </ComponentHolder>
}
export default Text;