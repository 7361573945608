import gql from 'graphql-tag'
// import { get } from 'lodash'
import duck from '../../duck'

const FETCH_SENSEI_PROFILE = (id) => gql`
query{
  user(id:"${id}"){
    id
    name
    senseiProfile{
      id
      mentors(orderBy:createdAt_DESC){
        id
        user{
          id
          name
        }
      }
    }
  }
}
`

function fetchSenseiProfile(id) {
  return duck.query({
    query: FETCH_SENSEI_PROFILE(id),
    type: 'users/fetch',
    key: 'usersData',
    changeExtractedData: (originalData, extractedData) => (
      { ...originalData, usersData: extractedData }
    )
  })
}

export default fetchSenseiProfile
