import gql from "graphql-tag";

import duck from "../../duck";

const addComment = async ({
	comment,
	commentType,
	schoolConnectId,
	currentUserId,
}) =>
	duck.query({
		query: gql`
			mutation {
				updateSchool(
					id: "${schoolConnectId}"
					input: {
						comments: {
							push: {
								comment: "${comment}"
								commentDate: "${new Date().toISOString()}"
								commentByConnectId: "${currentUserId}"
								type: ${commentType}
							}
						}
					}
				) {
					id
				}
			}
		`,
		type: "schools/update",
		key: "schools",
	});

export default addComment;
