import React from "react";

const GreenTickIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM15.2243 9.57574C15.4586 9.81005 15.4586 10.1899 15.2243 10.4243L11.2243 14.4243C10.9899 14.6586 10.6101 14.6586 10.3757 14.4243L8.77574 12.8243C8.54142 12.5899 8.54142 12.2101 8.77574 11.9757C9.01005 11.7414 9.38995 11.7414 9.62426 11.9757L10.8 13.1515L12.5879 11.3636L14.3757 9.57574C14.6101 9.34142 14.9899 9.34142 15.2243 9.57574Z"
        fill="#01AA93"
      />
    </svg>
  );
};

export default GreenTickIcon;
