import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const fetchCoursePackagesMeta = async (filterString) => duck.query({
  query: gql`
  {
    coursePackagesMeta(filter:{
      and:[${filterString}]
    }){
      count
    }
  }
  `,
  type: 'coursePackagesMeta/fetch',
  key: 'coursePackagesMeta',
})


export default fetchCoursePackagesMeta
