/* eslint-disable*/
import React,{ useRef } from 'react'
import { Editor } from 'tekie-content-editor'
import { LoadingOutlined } from '@ant-design/icons'
import config from '../../config/serverConfig'
import Input from '../../pages/ContentMaker/ContentAssignment/components/AssignmentForm/FormElements/InputField'
import { StyledTextArea } from '../../pages/AddCourse/AddCourse.styles'
import './contentEditor.scss'
import { BlockIcon, BoldIcon } from '../../pages/ContentMaker/ContentAssignment/ContentAssignment.style'
import { BLOCK, BOLD } from '../../constants/questionBank'

const antIcon = <LoadingOutlined style={{ fontSize: 18, color: '#4a4a4a' }} spin />

const SwitchIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 3H21V8" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 20L21 3" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21 16V21H16" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15 15L21 21" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 4L9 9" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)
const ContentEditorComponent = ({ id, codeInsert = ()=>{}, insertRef=()=>{}, init = {}, bulletsEnabled, switchBtn = false, ...props }) => {
  const [editorInitialized, setEditorInitialized] = React.useState(false) 
  const [initialEditorInjected, setInitialEditorInjected] = React.useState(false) 

  return (
    <div style={{ width: '100%',height:props.customHeight, position: 'relative' }}>
      {(switchBtn && initialEditorInjected) ? (
        <div
          title={`Switch To ${editorInitialized ? 'Old' : 'New'} Editor`}
          className='switch-editor-btn'
          onClick={() => {
            setEditorInitialized(!editorInitialized)
          }}
        >
          <SwitchIcon />
        </div>
      ) : null}
      <div style={{ position: 'relative', display: editorInitialized ? 'block' : 'none',height:props.customHeight }}>
        {props.title && <h3 className={props.practice && 'practice-modal-label'} style={{ marginBottom: '6px' }}>{props.title}: </h3>}
        <Editor
          {...props}
          id={id}
          onInit={() => {
            setEditorInitialized(true)
            setInitialEditorInjected(true)
          }}
          env={process.env.REACT_APP_NODE_ENV || 'staging'}
          urlPrefix={config.cloudFrontBaseUrl || config.fileBaseUrl}
          init={{
            selector: 'textarea#tekie-tms',
            font_formats: 'Nunito; Arial=arial,helvetica,sans-serif; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Helvetica=helvetica; arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times',
            plugins: 'paste searchreplace autolink autosave directionality code fullscreen image link media template codesample table hr toc advlist lists wordcount textpattern noneditable help quickbars',
            // imagetools_cors_hosts: ['picsum.photos'],
            menubar: false,
            // menubar: 'edit insert format tools table help',
            toolbar: 'italic bold underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor | blockquote link codesample | image media | formatselect | fontselect fontsizeselect',
            codesample_languages: [
              { text: 'HTML/XML', value: 'markup' },
              { text: 'JavaScript', value: 'javascript' },
              { text: 'CSS', value: 'css' },
              { text: 'Python', value: 'python' },
              { text: 'Java', value: 'java' },
              { text: 'Output', value: 'bash' },
            ],
            toolbar_sticky: true,
            autosave_ask_before_unload: true,
            autosave_interval: '30s',
            autosave_prefix: '{path}{query}-{id}-',
            autosave_restore_when_empty: false,
            autosave_retention: '2m',
            image_advtab: true,
            importcss_append: true,
            // paste_data_images: true,
            // file_picker_types: 'image media',
            image_caption: true,
            quickbars_selection_toolbar: 'bold italic subscript superscript | quicklink h2 h3 blockquote quicktable',
            quickbars_insert_toolbar: 'quicktable codesample',
            noneditable_noneditable_class: 'mceNonEditable',
            toolbar_mode: 'wrap',
            // contextmenu: 'link image imagetools table',
            contextmenu: 'link table',
            fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt',
            content_style: 'body { font-family:Nunito,Arial,sans-serif; font-size:14pt; }',
            ...init,
          }}
        />
      </div>
      {switchBtn ===true ? <>{!props.contentQuestion &&
        <Input inputStyles={{ display: editorInitialized ? 'none' : 'block' }}
          placeholder={props.placeholder}
          label={props.title}
          name={props.name}
          type={props.type}
          textArea
          value={props.inputValue}
          onChange={props.handleChange}
          setFieldValue={props.setFieldValue}
          inputRef={props.inputRef}
          practice={props.practice}
          bulletsEnabled={bulletsEnabled || false}
        />}
      {props.contentQuestion &&
        <div className='inputContainer' style={{ width: '95%', display: editorInitialized ? 'none' : 'block', position: 'relative' }}>
          <StyledTextArea            
            placeholder = {props.placeholder}
            name={props.name}
            value={props.inputValue}
            onChange={props.handleChange}
            innerRef={insertRef}         
          />
          <div style={{ position: 'absolute', bottom: '5px', right: '12px' }}>
                  <BoldIcon
                    type='bold'
                    onMouseDown={(e) => { e.preventDefault() }}              
                    onClick={() => codeInsert(BOLD)}
                  />
                  <BlockIcon
                    type='bold'
                    onMouseDown={(e) => { e.preventDefault() }}
                    onClick={() => codeInsert(BLOCK)}
                  />
                </div>
        </div>
      }
      </> : <></> }
    </div>
  )
}

export default ContentEditorComponent

/**
 * Hidden plugin
 * - imagetools : crop, filters, ratio etc. 
 */