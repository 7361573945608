import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import withNav from '../../components/withNav'
import Grade from './grade'
import { filterKey } from '../../utils/data-utils'

const GradeNav = withNav(Grade)({
  title: 'Grades',
  activeNavItem: 'Grade',
  showUMSNavigation: true,
  showCountryDropdown: true
})

const mapStateToProps = (state) => (
  {
    todaysBookedSessions: filterKey(state.data.getIn(['menteeSession', 'data']), 'fetchStats'),
    sessionGradeReportsFetchStatus: state.data.getIn(['sessionGradeReports', 'fetchStatus']),
    sessionGradeReports: state.data.getIn(['sessionGradeReports', 'data']),
  }
)

export default connect(mapStateToProps)(withRouter(GradeNav))
