import React, { Component } from 'react'
import MainTable from '../../../../components/MainTable'
import { Table } from '../../../../components/StyledComponents'

class RatingTableRow extends Component {
  render() {
    const { name, course, comment, rating, slot, topic } = this.props.ratings
    return (<>
      <MainTable.Row
        columnsTemplate={this.props.columnsTemplate}
        noBorder={this.props.noBorder}
        minWidth={this.props.minWidth}
        style={{ justifyContent: 'flex-start' }}
      >
        <Table.StickyItem
          style={{ left: 0 }}
        >
          <MainTable.Item style={{ minWidth: 60 }} >{name}</MainTable.Item>
        </Table.StickyItem>

        <Table.Item >
          <MainTable.Item style={{ width: 180 }} >
           {topic}
          </MainTable.Item>
        </Table.Item>
        <Table.Item >
          <MainTable.Item>
         {course}
          </MainTable.Item>
        </Table.Item>
        <Table.Item >
          <MainTable.Item>
         {rating}
          </MainTable.Item>
        </Table.Item>
        <Table.Item >
          <MainTable.Item>
          {slot}
          </MainTable.Item>
        </Table.Item>
        <Table.Item >
          <MainTable.Item>
        -
          </MainTable.Item>
        </Table.Item>
        <Table.Item>
          <MainTable.Item>
        {comment}
          </MainTable.Item>
        </Table.Item>
      </MainTable.Row>
            </>

    )
  }
}


export default RatingTableRow
