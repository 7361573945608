import styled from 'styled-components'

const BatchStyle = styled.div`
    height: 100%;
    width: 100%;
    margin: 0 auto;
    font-family: 'Inter' !important;
`
const Title = styled.div`
    font-weight: 600;
    font-size: 24px;
    color: #212121;
`
const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    background: #FFFFFF;
    border-radius: 8px;
    padding-top: 25px;
    padding-left: 20px;
    padding-bottom: 15px;
`
const BatchLogoDiv = styled.div`
    background: #E0F7FF;
    border-radius: 8px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content:center;
    align-items: center;
`
const Heading = styled.div`
    font-weight: 500;
    font-size: 16px;
    color: #212121;
    padding-left: 15px;
`
const BatchCodeDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 8px;
    background: #F5F5F5;
    border-radius: 1px;
    margin-left: 15px;
`
const BatchCode = styled.div`
    font-weight: 600;
    font-size: 18px;
    color: #000000;
`
const StudentHeading = styled.div`
    font-weight: 500;
    font-size: 14px;
    color: #757575;
    padding-left: 15px;
`
const courseImage = styled.img`
    height: 150px;
    width: 150px;
    margin-left: 20%;
    margin-bottom: 20px;
`
const FontDiv = styled.div`
    font-size: 22px;
    color: black;
    font-weight: bold;
`

BatchStyle.Title = Title
BatchStyle.TopContainer = TopContainer
BatchStyle.BatchLogoDiv = BatchLogoDiv
BatchStyle.Heading = Heading
BatchStyle.BatchCodeDiv = BatchCodeDiv
BatchStyle.BatchCode = BatchCode
BatchStyle.StudentHeading = StudentHeading
BatchStyle.courseImage = courseImage
BatchStyle.FontDiv = FontDiv


export default BatchStyle
