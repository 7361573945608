import gql from 'graphql-tag'
import duck from '../../duck'

const fetchMentors = async () =>
  duck.query({
    query: gql`
    {
        users(filter: {
          and: [
            { role: mentor }{ mentorProfile_exists: true }{ secondaryRole_exists: false }
          ]
        }) {
            id
            name
        }
    }
    `,
    type: 'mentors/fetch',
    key: 'mentors',
  })

export default fetchMentors

