import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const updateCoursePackages = async ({ packageId, inputObj: input, coursesConnectIds }) => duck.query({
  query: gql`
  mutation($input:CoursePackageUpdate){
    updateCoursePackage(id:"${packageId}",input:$input, ${coursesConnectIds ? `coursesConnectIds: ${coursesConnectIds}`: ''}){
    id
    title
    bannerTitle
    bannerDescription
    category
    description
    version
    internalName
    createdAt
    revisionSessionCount
    packageCourses: courses{
      id
      title
    }
    packageTopics: topics{
      topic {
        id
      }
      order
    }
    thumbnail{
      id
      uri
    }
    bannerThumbnail{
      id
      uri
    }
    status
  }
}
  `,
  variables: {
    input,
  },
  type: 'coursePackages/update',
  key: 'coursePackages',
  changeExtractedData: (extractedData, originalData) => {
    extractedData.coursePackages = {
      ...get(originalData, 'updateCoursePackage')
    };
    return { ...extractedData }
  }
})


export default updateCoursePackages
