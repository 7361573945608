const convertToHours = (time, type) => {
  let duration = time
  if (type === 'Minutes') {
    duration /= 60
  }
  if (type === 'Seconds') {
    duration /= 3600
  }
  if (type === 'Days') {
    duration *= 24
  }
  return duration
}

export default convertToHours
