import React, { Component } from 'react'
import RatingTableHead from './RatingTableHead'
import RatingTableBody from './RatingTableBody'
import { Table } from '../../../../components/StyledComponents'

class RatingTable extends Component {
  render() {
    const { savedRole } = this.props
    const columnsTemplate = '300px 200px 150px 100px 160px 160px 140px 140px'
    const minWidth = '1300px'
    const rowLayoutProps = {
      minWidth,
      columnsTemplate
    }
    return (
      <Table style={{ width: '100%', overflowX: 'scroll' }}>
        <RatingTableHead {...rowLayoutProps} savedRole={savedRole} />
        <RatingTableBody
          {...this.props}
          {...rowLayoutProps}
        />
      </Table>
    )
  }
}

export default RatingTable
