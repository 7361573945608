/* eslint-disable */
import React from 'react'
import { get } from 'lodash'
import moment from 'moment'
import { Button, Input, Icon } from 'antd'
import { ClockCircleOutlined, CloudUploadOutlined, CopyOutlined, CaretDownOutlined } from '@ant-design/icons'
import copyText from '../../../../utils/copyText'
import getSlotTime from '../../../../utils/slotTime'
import ClassComponentStyle from './ClassComponent.style'
import getInputObject from '../../utils/getInputObject'
import { FeedBackSvg, TypeSVG } from '../../../../constants/icons'
import startClassIcon from '../../../../assets/startClass.png'
import getFullPath from '../../../../utils/getFullPath'
import studentIcon from '../../../../assets/students.png'
import Modal from '../../../MentorDashboard/components/Modal.styles'
import { getDuration } from '../../../../utils/time'
import updateMentorMenteeSession from '../../../../actions/mentorSessions/updateMentorMenteeSessions'


const { TextArea } = Input

const ClassComponentForMentorMentee = ({ selectedTopic, mentorMenteeSessions,
  currentTopicOrder, menteeSession, userId, courseId,
  studentName, updateSessionStatus }) => {
  const [slot, setSlot] = React.useState(0)
  const [feedback, setFeedback] = React.useState(null)
  const [bookingDate, setBookingDate] = React.useState()
  const [showFeedback, setShowFeedback] = React.useState(false)
  const [enterFeedback, setEnterFeedback] = React.useState(false)
  const [sessionStartDate, setSessionStartDate] = React.useState()
  const [sessionEndDate, setSessionEndDate] = React.useState()
  const [inputFeedback, setInputFeedback] = React.useState('')
  const [inputLink, setInputLink] = React.useState('')
  const [sessionStatus, setSessionStatus] = React.useState('')
  const [sessionRecordingLink, setSessionRecordingLink] = React.useState()
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = React.useState('')
  const [sessionId, setSessionId] = React.useState('')
  const isSessionUpdating = get(updateSessionStatus, 'loading')
  React.useEffect(() => {
    let currentmenteeSession
    let currentMentorMenteeSession
    for (let i = 0; i < mentorMenteeSessions.length; i += 1) {
      if (get(mentorMenteeSessions[i], 'topic.id', '') === get(selectedTopic, 'id')) {
        currentMentorMenteeSession = mentorMenteeSessions[i]
        break
      }
    }
    for (let i = 0; i < menteeSession.length; i += 1) {
      if (get(menteeSession[i], 'id', '') === get(currentMentorMenteeSession, 'menteeSession.id')) {
        currentmenteeSession = menteeSession[i]
        break
      }
    }
    setSessionStartDate(get(currentMentorMenteeSession, 'sessionStartDate', ''))
    setSessionEndDate(get(currentMentorMenteeSession, 'sessionEndDate', ''))
    setBookingDate(moment(get(currentmenteeSession, 'bookingDate', '')).format('ddd, DD MMM'))
    setSessionId(get(currentMentorMenteeSession, 'id'))
    setSessionStatus(get(currentMentorMenteeSession, 'sessionStatus'))
    setIsFeedbackSubmitted(get(currentMentorMenteeSession, 'isFeedbackSubmitted'))
    setSessionRecordingLink(get(currentMentorMenteeSession, 'sessionRecordingLink'))
    setFeedback(get(currentMentorMenteeSession, 'sessionCommentByMentor', null))
    setEnterFeedback(false)
    if (selectedTopic.order > menteeSession.length) {
      setSlot(0)
    } else {
      for (let i = 0; i < 24; i += 1) {
        if (get(currentmenteeSession, `slot${i}`)) {
          setSlot(i)
        }
      }
    }
  }, [selectedTopic, isSessionUpdating, mentorMenteeSessions, menteeSession])

  const updateExistingSessions = async (type, queryType) => {
    if (type === 'status') {
      const input = await getInputObject(queryType)
      await updateMentorMenteeSession(sessionId, input, 'updateMentorSession')
    } else if (type === 'feedback') {
      const input = {}
      input.sessionCommentByMentor = inputFeedback
      input.isFeedbackSubmitted = true
      await updateMentorMenteeSession(sessionId, input, 'updateMentorSession').then(setIsFeedbackSubmitted(true))
      setEnterFeedback(false)
    } else if (type === 'link') {
      const input = {}
      input.sessionRecordingLink = inputLink
      await updateMentorMenteeSession(sessionId, input, 'updateMentorSession')
    }
  }
  return (
    <ClassComponentStyle.Container>
      <ClassComponentStyle.SubContainer>
        <ClassComponentStyle.ImageBorder>
          <img src={getFullPath(selectedTopic.image)} style={{ height: '80px', width: '80px' }} alt='' />
        </ClassComponentStyle.ImageBorder>
        <ClassComponentStyle.FlexBox1>
          <ClassComponentStyle.FlexBox2>
            <div style={{ fontSize: '10px', color: '#9E9E9E' }}>Class<span style={{ color: 'black', fontWeight: '550' }}>  {selectedTopic.order}</span></div>
            <div>
              {currentTopicOrder > selectedTopic.order && (
                <ClassComponentStyle.Completed >Completed</ClassComponentStyle.Completed>
              )}
            </div>
          </ClassComponentStyle.FlexBox2>
          <ClassComponentStyle.Title>{selectedTopic.title}</ClassComponentStyle.Title>
          <ClassComponentStyle.Clock>
            <ClockCircleOutlined style={{ paddingRight: '10px' }} />
            <div>{getSlotTime(slot)}</div>
            <ClassComponentStyle.Dot />
            <div>{bookingDate}</div>
          </ClassComponentStyle.Clock>
        </ClassComponentStyle.FlexBox1>
      </ClassComponentStyle.SubContainer>
      <ClassComponentStyle.Divider />
      <div >
        <div style={{ paddingBottom: '8px' }}>
          <ClassComponentStyle.Detail>Class Details</ClassComponentStyle.Detail>
          <ClassComponentStyle.FlexBox2>
            <img src={studentIcon} alt='' style={{ width: '13px', height: '13px', marginRight: '13px' }} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontSize: '10px', color: '#757575' }}>Student</div>
              <div style={{ fontSize: '12px', color: '#757575' }}>{studentName}</div>
            </div>
            <Icon component={TypeSVG} style={{ width: '13px', height: '13px', marginLeft: '50px', marginRight: '13px', fill: '#9E9E9E' }} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontSize: '10px', color: '#757575' }}>Type</div>
              <div style={{ fontSize: '10px', padding: '2px 8px', background: '#EEEEEE' }}>Learning</div>
            </div>
            {sessionStatus === 'completed' && (
            <div style={{ marginLeft: '6%', display: 'flex' }}>
              <ClockCircleOutlined style={{ width: '13px', height: '13px', marginRight: '13px', color: '#9E9E9E' }} />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ fontSize: '10px', color: '#757575' }}>Duration</div>
                <div style={{ fontSize: '12px', color: '#757575' }}>
                  {getDuration(sessionStartDate, sessionEndDate)}
                </div>
              </div>
            </div>
          )}
          </ClassComponentStyle.FlexBox2>
        </div>
        <ClassComponentStyle.Divider />
        <div>
          {sessionStatus === 'allotted' ? (
            <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ color: 'black', paddingLeft: '10px' }}>Do you want to start the class ?</div>
              <Button
                loading={isSessionUpdating}
                style={{ backgroundColor: '#8C61CB', borderRadius: '8px', color: 'white', height: '32px', marginRight: '10%' }}
                onClick={() => {
                  updateExistingSessions('status', 'Start Class')
                }}
              >
                <img src={startClassIcon} alt='' style={{ color: 'white', paddingRight: '8px' }} />Start Class
              </Button>
            </div>
          ) : null }
        </div>
        <div>
          {sessionStatus === 'started' ? (
            <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', }}>
              <div style={{ color: 'black', marginRight: '140px', fontSize: '12px' }}>Do you want to end the class ?</div>
              <div style={{ fontSize: '10px', paddingRight: '20px' }}><ClockCircleOutlined style={{ paddingRight: '5px' }} />Class Duration: {getDuration(sessionStartDate,
                    new Date())}
              </div>
              {selectedTopic.order === 1 ? (
                <Modal.PrimaryLink
                  to={{
                  pathname: `/mentorDashboard/classFeedback/${sessionId}`,
                  state: {
                    userId,
                    courseId
                  }
                }}
                  style={{ display: 'flex', alignItems: 'center', backgroundColor: '#8C61CB', justifyContent: 'center', width: '150px', borderRadius: '8px', color: 'white', height: '34px', marginRight: '10%' }}
                  onClick={() => {
                  updateExistingSessions('status', 'End Class')
                }}
                >
                  <img src={startClassIcon} alt='' style={{ color: 'white', paddingRight: '8px' }} />End Class
                </Modal.PrimaryLink>
              ) : (
                <Button
                  loading={isSessionUpdating}
                  style={{ backgroundColor: '#8C61CB', borderRadius: '8px', color: 'white', height: '32px', marginRight: '10%' }}
                  onClick={() => {
                  updateExistingSessions('status', 'End Class')
                }}
                >
                  <img src={startClassIcon} alt='' style={{ color: 'white', paddingRight: '8px' }} />End Class
                </Button>
              )}
            </div>
          ) : null }
        </div>
        <div>
          {sessionStatus === 'completed' && selectedTopic.order === 1 ? (
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '10px' }}>
              {isFeedbackSubmitted ? (
                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '' }}>
                  <div style={{ fontSize: '14px', color: 'black' }}>View or edit the feedback</div>
                </div>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '' }}>
                  <div style={{ fontSize: '14px', color: 'black' }}>Please give feedback for the class</div>
                  <div style={{ fontSize: '10px', fontWeight: '400', color: '#757575', paddingTop: '5px' }}>Class is unaccounted until feedback is provided</div>
                </div>
              )}
              <Modal.PrimaryLink
                to={{
                    pathname: `/mentorDashboard/classFeedback/${sessionId}`,
                    state: {
                      userId,
                      courseId
                    }
                  }}
                style={{ marginRight: '10%' }}
              >
                <Modal.StyledIcon
                  component={FeedBackSvg}
                  fillSvg='#FFF'
                />
                {isFeedbackSubmitted ? 'View Feedback' : 'Give Feedback'}
              </Modal.PrimaryLink>
            </div>
          ) : null }
          {sessionStatus === 'completed' && selectedTopic.order !== 1 && isFeedbackSubmitted === false && enterFeedback === false && (
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', marginLeft: '', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '90px' }}>
                  <div style={{ fontSize: '14px', color: '#424242', fontWeight: '500', marginBottom: '5px' }}>Please give feedback for the class</div>
                  <div style={{ fontSize: '10px', fontWeight: '400', color: '#757575' }}>Class is unaccounted until feedback is provided</div>
                </div>
                <Button
                  loading={isSessionUpdating}
                  style={{ backgroundColor: '#8C61CB', borderRadius: '8px', color: 'white', marginTop: '10px', height: '34px', marginBottom: '5px', marginRight: '10%' }}
                  onClick={() => setEnterFeedback(true)}
                >
                  <img src={feedback} alt='' style={{ color: 'white', marginRight: '5px' }} />Give Feedback
                </Button>
              </div>
              <ClassComponentStyle.Divider />
            </div>
          )}
        </div>
        <div >
          { enterFeedback && (
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '2px', marginTop: '20px', justifyContent: 'space-between', paddingBottom: '10px', marginRight: '10%' }}>
              <div style={{ fontSize: '14px', color: 'black', marginBottom: '10px' }}>Please give feedback for the class</div>
              <TextArea
                rows={4}
                value={inputFeedback}
                onChange={e => setInputFeedback(e.target.value)}
              />
              <Button
                loading={isSessionUpdating}
                onClick={() => updateExistingSessions('feedback', '')}
                style={{ backgroundColor: '#8C61CB', borderRadius: '8px', color: 'white', marginTop: '10px', width: '200px' }}
              >Submit Feedback
              </Button>
            </div>
          )}
        </div>
        {sessionStatus === 'completed' && selectedTopic.order !== 1 && isFeedbackSubmitted === true && (
          <div style={{ marginTop: '10px' }}>
            <div style={{ display: ' flex', flexDirection: 'row' }}>
              <div style={{ color: '#424242', fontSize: '14px', fontWeight: '500' }}>Feedback Received</div>
              <CaretDownOutlined style={{ color: '#8C61CB', paddingLeft: '400px' }} onClick={() => setShowFeedback(!showFeedback)} />
            </div>
            {showFeedback && (
              <ClassComponentStyle.FeedbackBox>
                  {feedback}
              </ClassComponentStyle.FeedbackBox>
            )}
          </div>
        )}
        <ClassComponentStyle.Divider />
        <div>
          { sessionStatus === 'completed' && isFeedbackSubmitted && sessionRecordingLink === null && (
          <div style={{ paddingTop: '10px' }}>
            <div style={{ fontSize: '14px', color: 'black', marginBottom: '12px', marginRight: '20px' }}>Please add the link for the class recording.</div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Input style={{ width: '400px' }} value={inputLink} onChange={e => setInputLink(e.target.value)} />
              <Button
                loading={isSessionUpdating}
                onClick={() => updateExistingSessions('link', '')}
                style={{ backgroundColor: '#8C61CB', borderRadius: '8px', color: 'white', marginRight: '10%' }}
              >
                <CloudUploadOutlined style={{ color: 'white' }} />Upload Link
              </Button>
            </div>
          </div>
        ) }
        </div>
        <div>
          {sessionStatus === 'completed' && isFeedbackSubmitted === true && sessionRecordingLink !== null && (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '2px', justifyContent: 'space-between', paddingTop: '10px' }}>
            <div style={{ fontSize: '14px', color: 'black', marginRight: '80px' }}>Want to watch class recording?</div>
            <Button style={{ color: '#8C61CB', marginRight: '15%' }} onClick={() => copyText(sessionRecordingLink)}>
              <CopyOutlined style={{ color: '#8C61CB' }} />
              Copy
            </Button>
          </div>
        )}
        </div>
      </div>
    </ClassComponentStyle.Container>)
}

export default ClassComponentForMentorMentee
