const ReportHeader = [
  {
    label: '##',
    key: 'index',
  },
  {
    label: 'Mentors',
    key: 'mentorName',
  },
  {
    label: 'Trial Sessions (India)',
    key: 'inIndia',
  },
  {
    label: 'Trial Sessions (US)',
    key: 'inNotIndia',
  },
  {
    label: '1:1 Paid Sessions',
    key: 'oneToOne',
  },
  {
    label: '1:2 Paid Sessions',
    key: 'oneToTwo',
  },
  {
    label: '1:3 Paid Sessions',
    key: 'oneToThree',
  },
  {
    label: '1:1 Conversions',
    key: 'conversionBonusoneToOne',
  },
  {
    label: '1:2 Conversions',
    key: 'conversionBonusoneToTwo',
  },
  {
    label: '1:3 Conversions',
    key: 'conversionBonusoneToThree',
  },
  {
    label: 'B2B School Sessions',
    key: 'b2b',
  },
  {
    label: 'B2B2C School Sessions',
    key: 'b2b2c',
  },
  {
    label: 'B2C Earnings',
    key: 'b2cEarnings',
  },
  {
    label: 'B2B Earnings',
    key: 'b2bEarnings',
  },
  {
    label: 'B2B2C Earnings',
    key: 'b2b2cEarnings',
  },
  {
    label: 'Grand Total',
    key: 'totalEarnings',
  },
]

export default ReportHeader
