import gql from 'graphql-tag'
import duck from '../../duck'


const deleteTrainingBatch = async (batchId) =>
  duck.query({
    query: gql`
   mutation {
    deleteBatch(id: "${batchId}") {
        id
    }
    }
    `,
    type: 'batch/delete',
    key: 'trainingBatches',
  })

export default deleteTrainingBatch
