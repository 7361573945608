import gql from "graphql-tag";
import duck from "../../duck";

const addLab = async (input, schoolConnectId) =>
	duck.query({
		query: gql`
    mutation {
      addLabInspection(input: {
          labName: "${input.labName}",
          labNo: ${input.labNo},
          description: "${input.description}"
      },
          schoolConnectId: "${schoolConnectId}"
          ) {
            id
            labName
            description
        }
    }
    `,
		variables: {
			input,
		},
		type: "labInspections/add",
		key: "labInspections",
	});

export default addLab;
