
import { get } from 'lodash'
import {
  ADMIN, AUDITOR, AUDIT_ADMIN, BDE,
  BDE_ADMIN,
  CMS, CMS_ADMIN, CMS_VIEWER,
  MENTOR, POST_SALES, PRE_SALES, SALES_EXECUTIVE, SCHOOL_ADMIN,
  SMS, TRANSFORMATION_ADMIN, TRANSFORMATION_TEAM, UMS, UMS_ADMIN, UMS_VIEWER,
  SUPPLY_DEMAND_ADMIN, SUPPLY_MANAGER, DEMAND_MANAGER, SENSEI, LEAD_PARTNER,
} from '../constants/roles'
import { getUmsBlocks, getSmsBlock } from '../pages/UserDashboard/rolesToRouteMap'

const afterLoginActions = (savedRole, fromRoutes) => {
  if (fromRoutes) {
    const allowedRoutes = getUmsBlocks(savedRole)
    if (allowedRoutes && allowedRoutes.length > 0) {
      const findRouteUMSBlock = allowedRoutes.find(routes => get(routes, 'routes', []).map(route => get(route, 'route')).includes(fromRoutes))
      if (!findRouteUMSBlock) {
        const findRouteSMSBlock = getSmsBlock().find(routes => get(routes, 'routes', []).map(route => get(route, 'route')).includes(fromRoutes))
        if (findRouteSMSBlock) {
          localStorage.setItem('block', get(findRouteSMSBlock, 'blockName'))
        }
      } else {
        localStorage.setItem('block', get(findRouteUMSBlock, 'blockName'))
      }
    }
  }
  if (!localStorage.getItem('country')) localStorage.setItem('country', 'all')
  if (!fromRoutes) {
    localStorage.removeItem('block')
  }
  if (!localStorage.getItem('type')) {
    if (savedRole === ADMIN || savedRole === MENTOR || savedRole === UMS_ADMIN ||
        savedRole === UMS_VIEWER || savedRole === SALES_EXECUTIVE || savedRole === PRE_SALES ||
        savedRole === POST_SALES || savedRole === AUDIT_ADMIN || savedRole === AUDITOR
        || savedRole === SENSEI || savedRole === LEAD_PARTNER) {
      localStorage.setItem('type', UMS)
    } else if (savedRole === CMS_ADMIN || savedRole === CMS_VIEWER) {
      localStorage.setItem('type', CMS)
    } else if (savedRole === TRANSFORMATION_ADMIN || savedRole === TRANSFORMATION_TEAM) {
      localStorage.setItem('type', UMS)
    } else if (savedRole === SCHOOL_ADMIN || savedRole === BDE || savedRole === BDE_ADMIN) {
      localStorage.setItem('type', SMS)
    } else if (savedRole === SUPPLY_DEMAND_ADMIN || savedRole === SUPPLY_MANAGER
        || savedRole === DEMAND_MANAGER) {
      localStorage.setItem('type', UMS)
    }
  }
}

export default afterLoginActions
