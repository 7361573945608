import * as Yup from 'yup'

const addAssignmentSchema = Yup.object().shape({
  summary: Yup.string().min(10, 'Must be 10 characters or more').required('summary is required').trim(),
  overview: Yup.string().min(10, 'Must be 10 characters or more').required('event description is required'),
  eventListingImage: Yup.mixed().required('event listing image is required'),
  cardDataContainer: Yup.array().of(
    Yup.object().shape({
      details: Yup.string().min(10, 'Must be 10 characters or more').required('card description is required').trim(),
      imageUrl: Yup.mixed().required('card image is required'),
    })
  )
})

export default addAssignmentSchema
