import countryAndState from '../constants/CountryAndStates'
import { LEAD_PARTNER } from '../constants/roles'

const getCountryAndStateData = (countries = [], savedRole) => {
  if (savedRole && savedRole === LEAD_PARTNER) {
    return countryAndState.filter(country => countries.includes(country.countryValue))
  }
  return countryAndState
}

export default getCountryAndStateData
