import gql from "graphql-tag"
import { get } from "lodash"
import requestToGraphql from "../../utils/requestToGraphql"

const fetchCoursePackageBatches = async (packageId,perPage = 50, skip = 0) => {
    const coursePackageBatches = await requestToGraphql(gql`{
    batches(filter: {
        and:[
          {coursePackage_some: {
            id:"${packageId}"
          }}
          {type_in: [b2b]}
        ]
    }
    first: ${perPage},
    skip: ${perPage * skip}
    ){
        id
        code
        type
        documentType
        description
        classroomTitle
        inheritedFrom {
          id
        }
        classes {
          id
          grade
        }
        students {
          id
        }
        batchStudents {
          id
        }
        allottedMentor {
          id
          name
        }
        school{
          id
          name
        }
        isTeacherTraining
        currentComponent {
          id
        }
        coursePackageTopicRule {
          order
          topic {
            id
            title
            courses {
              id
              title
            }
          }
        }
        coursePackage {
          id
          title
          courses {
            id
            title
          }
          topics {
            order
            topic {
              id
              title
              courses {
                id
                title
              }
            }
          }
        }
        coursePackageCourses {
          id
          title
        }
    }
}`)
    return get(coursePackageBatches, 'data.batches')
}

export const fetchCoursePackageBatchesMeta = async(packageId) => {
  const coursePackageBatchesMeta = await requestToGraphql(gql`{
    batchesMeta(
      filter: {
        and: [
          { coursePackage_some: { id: "${packageId}" } }
          { type_in: [b2b] }
        ]
      }
    ) {
      count
    }
  }`)
  return get(coursePackageBatchesMeta, 'data.batchesMeta.count')
}

export default fetchCoursePackageBatches;