import styled from 'styled-components'
import { Button } from 'antd'
import antdButtonColor from '../../utils/mixins/antdButtonColor'
import colors from '../../constants/colors'

const FlexContainer = styled.div`
    display:flex;
    justify-content: ${props => props.justify ? props.justify : 'space-between'};
    padding-bottom:20px;
    align-items: center;
    ${props => props.modalGrid ? `
    display: grid;
    grid-template-columns: 30% 65%;
    justify-content: space-between;
    padding: 0;
    align-items: flex-start;
    ` : ''}
    & h1, & h2, & h3, & h4, & h5, & h6, & p{
      margin: 0;
      margin-right: 10px;
    }
`

const TopContainer = styled.div`
    display:flex;
    justify-content:flex-end;
    padding-bottom:20px;
`
const StyledButton = styled(Button)`
  &&& {
    ${antdButtonColor(colors.subThemeColor)}
  }
`
export default { FlexContainer, TopContainer, StyledButton }
