import React, { useState } from 'react'
import { Popconfirm, Select } from 'antd'
import { ChapterFlex } from '../../../AddChapters/AddChapter.style'
import { updateEventTag, updateEventCategory } from '../../../../actions/eventDashboard/eventSettings'

const ARCHIVE_STATUS = "archive"
const ACTIVE_STATUS = "active"

const Status = (props) => {
  const { id, status, tabType } = props
  const [isSubmitting, setSubmitting] = useState(false)
  const [showPopup, setShowPopup] = useState(false)

  const updateStatus = async () => {
    setSubmitting(true)
    if (tabType === 'Tag') {
      const input = {
        tagStatus: status === 'archive' ? 'active':'archive'
      }
      const res = await updateEventTag({ id, input })
      if (res && res.id) {
        setShowPopup(false)
        setSubmitting(false)
      }
    }
    if (tabType === 'Category') {
      const input = {
        status: status === 'archive' ? 'active':'archive'
      }
      const res = await updateEventCategory({ id, input })
      if (res && res.id) {
        setShowPopup(false)
        setSubmitting(false)
      }
    }
    setShowPopup(false)
    setSubmitting(false)
  }

  const checkStatus = () => {
    if (status === ARCHIVE_STATUS) {
      return "Active"
    }
    if (status === ACTIVE_STATUS) {
      return "Archive"
    }
  }

  const PopupText = (
    <div style={{ alignItems: 'center' }}>
      <h4 style={{ fontWeight: 'bold' }}>Are you Sure ?</h4>
      <p>{`You want to move this ${tabType} to "${checkStatus()}"?`}</p>
      <p>This may affect the events listing Page.</p>
    </div>
  )

  return (
    <ChapterFlex style={{ justifyContent: 'center', padding: '0px 30px' }}>
      <Popconfirm
        title={PopupText}
        visible={showPopup}
        onConfirm={updateStatus}
        icon=""
        onCancel={() => setShowPopup(!showPopup)}
        placement='bottom'
        okText={`Move to ${checkStatus()}`}
        okType={checkStatus() === "Archive" ? 'danger': 'primary'}
        okButtonProps={{ loading: isSubmitting }}
        cancelText='Cancel'
        key='toggle'
      >
        <Select
          style={{ width: '100px' }}
          value={status}
          onSelect={(value, _option) => {
            if (value !== status) {
              setShowPopup(true)
            }
          }}
        >
          <Select.Option className="sad" value="archive"><span style={{ fontWeight: 'bold' }}>Archive</span></Select.Option>
          <Select.Option value="active"><span style={{ color: 'green', fontWeight: 'bold' }}>Active</span></Select.Option>
        </Select>
      </Popconfirm>
    </ChapterFlex>
  )
}

export default Status