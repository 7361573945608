import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../../duck'


const fetchMentorMenteeSessionsForDemand = async (menteeSessionIds) =>
  duck.query({
    query: gql`{
      mentorMenteeSessionsForDemand: mentorMenteeSessions(
        filter: { menteeSession_some: { id_in: [${menteeSessionIds}] } }
      ) {
        id
        sessionStartDate
        sessionEndDate
        sessionStatus
        mentorSession {
          id
          user{
            id
            name
            profilePic {
              id
              uri
            }
            mentorProfile {
              id
              experienceYear
              pythonCourseRating5
              pythonCourseRating4
              pythonCourseRating3
              pythonCourseRating2
              pythonCourseRating1
            }
          }
        }
        menteeSession {
          id
        }
      }
    }
    `,
    type: 'mentorMenteeSessionsForDemand/fetch',
    key: 'mentorMenteeSessionsForDemand',
    changeExtractedData: (extractedData, originalData) => {
      extractedData.menteeSession = []
      extractedData.user = []
      extractedData.mentorMenteeSessionsForDemand = get(originalData, 'mentorMenteeSessionsForDemand', [])
      return { ...extractedData }
    },
  })

export default fetchMentorMenteeSessionsForDemand

