import styled from 'styled-components'

const ComponentHolder = styled.div`
text-align: center;
width: 100%;
height: 100%;
border-radius: 11px;
.tox-tinymce {
    height:100% !important;
}
`
export {
    ComponentHolder,
    }
    