const PRINCIPAL = 'principal'
const DEAN = 'dean'
const VICEPRINCIPAL = 'vicePrincipal'
const HOD = 'HOD'
const TEACHER = 'teacher'
const OWNER = 'owner'
const TRUSTEE = 'trustee'
const DIRECTOR = 'director'

export {
  PRINCIPAL,
  DEAN,
  VICEPRINCIPAL,
  HOD,
  TEACHER,
  OWNER,
  TRUSTEE,
  DIRECTOR
}
