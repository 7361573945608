import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const fetchCoursePackages = async ({ filterString, perPage = 20, skip = 0 }) => duck.query({
  query: gql`
  {
    coursePackages(
      filter:{
        and:[
          ${filterString}
        ]
      }
       first: ${perPage},
       skip: ${perPage * skip}
    orderBy : createdAt_DESC
    ){
      id
      title
      bannerTitle
      bannerDescription
      category
      description
      version
      internalName
      createdAt
      packageCourses: courses{
        id
        title
      }
      packageTopics: topics{
        order
      }
      thumbnail{
        id
        uri
      }
      bannerThumbnail{
        id
        uri
      }
      status
    }
  }
  `,
  type: 'coursePackages/fetch',
  key: 'coursePackages',
  changeExtractedData: (extractedData, originalData) => {
    extractedData.coursePackages = get(originalData, 'coursePackages');
    return { ...extractedData }
  }
})


export default fetchCoursePackages
