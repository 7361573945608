import gql from 'graphql-tag'
import duck from '../../../duck'
import getDataFromLocalStorage from '../../../utils/extract-from-localStorage'

const addEventTag = async (input) =>
  duck.query({
    query: gql`
      mutation($input: ContentTagInput!) {
        addContentTag(
          input: $input
          createdByConnectId: "${getDataFromLocalStorage('login.id')}" 
        ) {
            id
            title
            displayOnWebsite
            createdAt
            tagStatus
            createdBy {
              name
            }
            eventsMeta {
              count
            }
        }
      }
    `,
    variables: {
      input
    },
    type: 'contentTags/add',
    key: 'contentTags',
  })

export default addEventTag
