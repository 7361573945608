import gql from "graphql-tag"
import duck from '../../../duck'

const updateEventCategory = async ({ id, input }) => {
    await duck.query({
      query: gql`
        mutation($input: EventCategoryUpdate) {
          updateEventCategory(
            id: "${id}"
            input: $input
          ) {
              id
              title
              displayOnWebsite
              createdAt
              status
              createdBy {
                name
              }
              eventsMeta {
                count
              }
          }
        }
    `,
      variables: {
        input
      },
      type: 'eventCategories/update',
      key: 'eventCategories'
    })
  }
  
  export default updateEventCategory