import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'
import { TekieAmethyst } from '../../../constants/colors'

const loadingIcon = <LoadingOutlined style={{ fontSize: 16, marginRight: '8px', color: TekieAmethyst }} spin />

const PageLoading = ({ isfetching = false, fullPage = true }) => isfetching ? (
  <>
    <div className='supply-calendar-loading-container show'>
      <div className='loading-bar-container'>
        <div />
      </div>
    </div>
    {
      fullPage && (
      <div className='supply-dashboard-calender-loading-container'>
        <Spin indicator={loadingIcon} />
        <div className='supply-dashboard-calender-loading'>Fetching Sessions...</div>
      </div>
      )
    }
  </>
) : null

export default PageLoading
