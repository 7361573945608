import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const fetchPrizes = async (id) => duck.query({
  query: gql`
  {
    eventPrizes(filter:{events_some:{id:"${id}"}}){
      id
      title
      minRank
      maxRank
    }
  }
  `,
  type: 'eventPrizes/fetch',
  key: 'eventPrizes',
  changeExtractedData: (extractedData, originalData) => {
    extractedData.eventPrizes = get(originalData, 'eventPrizes');
    return { ...extractedData }
  }
})


export default fetchPrizes
