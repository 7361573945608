import gql from "graphql-tag";
import duck from "../../duck";

const fetchLabs = async (schoolId) =>
    duck.query({
        query: gql`
        query{
            labs: labInspections(filter:{
              and: [
                {
                  school_some:{
                    id: "${schoolId}"
                  }
                }
              ]
            }) {
              id
              labName
              labNo
            }
          }
    `,
        type: "labs/fetch",
        key: "labs",
    });

export default fetchLabs;
