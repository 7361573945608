import gql from "graphql-tag"
import { get } from "lodash"
import duck from '../../duck'

const fetchCoursePackage = async (packageId) => duck.query({
    query: gql`
    {
        coursePackage(id:"${packageId}"){
            id
            title
            internalName
            version
            secondaryCategory
            minGrade
            maxGrade
            packageType
            thumbnail{
            id
            uri
            }
            category
            status
            courses{
            id
            title
            minGrade
            maxGrade
            category
            topics {
                id
                title
                status
                order
            }
            }
            bannerThumbnail{
            id
            uri
            }
            description
            topics{
            order
            title
            description
            isMandatory
            isRevision
            previousTopicOrder
            revisionOrder
            topic{
                id
                title
                chapter{
                id
                title
                }
                classType
                courses {
                id
                title
                }
            }
            }
            revisionSessionCount
        }
    }
    `,
    type: 'coursePackage/fetch',
    key: 'coursePackage',
    changeExtractedData: (extractedData, originalData) => {
        extractedData.coursePackage = get(originalData, 'coursePackage');
        return { ...extractedData }
    }
})

export default fetchCoursePackage;
