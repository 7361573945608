
import gql from 'graphql-tag'
import duck from '../../duck';


const fetchEventCategories = async () => {

    await duck.query({
        query: gql`
       query {
            eventCategories(
              filter: { and: [{ status: active }] }
            ) {
              id
              title
            }
          }
              `,
        type: 'eventCategories/fetch',
        key: 'eventCategories',

    })
}

export default fetchEventCategories
