const ratingHeaderConfig = [
  {
    label: 'Mentor',
    key: 'name',
  },
  {
    label: '5 Star',
    key: 'rating5',
  },
  {
    label: '4 Star',
    key: 'rating4',
  },
  {
    label: '3 Star',
    key: 'rating3',
  },
  {
    label: '2 Star',
    key: 'rating2',
  },
  {
    label: '1 Star',
    key: 'rating1',
  },
  {
    label: 'Overall Rating',
    key: 'overall',
  },
]

export default ratingHeaderConfig

