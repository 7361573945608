import { notification, Spin } from 'antd'
import gql from 'graphql-tag'
import { get } from 'lodash'
import React from 'react'
import { addComicStrip, addLearningObjective, addQuestionBank, updateLearningObjective } from '../../../../actions/contentMaker'
import addComicImage from '../../../../actions/contentMaker/comic/addComicImage'
import addMessage from '../../../../actions/contentMaker/contentLearningObjective/addMessage'
import addLearningSlide from '../../../../actions/contentMaker/contentLearningSlides/addLearningSlide'
import MainModal from '../../../../components/MainModal'
import { UNPUBLISHED_STATUS } from '../../../../constants/questionBank'
import getIdArrForQuery from '../../../../utils/getIdArrForQuery'
import requestToGraphql from '../../../../utils/requestToGraphql'
import AddLoForm from './LoForms/AddLoForm'
import EditLoForm from './LoForms/EditLoForm'

class LoModal extends React.Component {
  state = {
    title: '',
    description: '',
    pqStory: '',
    status: UNPUBLISHED_STATUS
  }
  handleAddLo = async (value, thumbnailFile, pqStoryImageFile, selectedCourses = []) => {
    const { closeModal } = this.props
    await addLearningObjective({
      input: value,
      selectedCourses: selectedCourses.map(course => get(course, 'key')),
      thumbnailFile,
      pqStoryImageFile
    }).then(async res => {
      if (res.addLearningObjective && res.addLearningObjective.id) {
        closeModal()
      }
    })
  }

  handleEditLo = async (value, thumbnailFile, pqStoryImageFile, selectedCourses = []) => {
    const { editData, closeModal } = this.props
    const { title, description, pqStory, order, status } = value
    await updateLearningObjective({
      input: {
        title,
        description: description || '',
        pqStory: pqStory || '',
        order,
        status
      },
      selectedCourses: selectedCourses.map(course => get(course, 'key')),
      loId: get(editData, 'id'),
      thumbnailFile,
      pqStoryImageFile
    }).then(async res => {
      if (res.updateLearningObjective && res.updateLearningObjective.id) {
        closeModal()
      }
    })
  }

  handleDuplicateLo = async (value, thumbnailFile, pqStoryImageFile, duplicateLoComponentsData, selectedCourses=[], addMessages, addQuestionBanks, addComicStrips, addLearningSlide) => {
    const { closeModal } = this.props
    const { title, description, pqStory, order, status } = value

    const coursesConnectIds = selectedCourses.map(course => get(course, 'key'))

    await addLearningObjective({
      input: {
        title,
        description: description || '',
        pqStory: pqStory || '',
        order,
        status
      },
      selectedCourses: coursesConnectIds,
      inheritedFromConnectId: get(value, 'id'),
      thumbnailConnectId: !thumbnailFile ? get(value, 'thumbnail.id', null) : null,
      pqStoryImageConnectId: !pqStoryImageFile ? get(value, 'pqStoryImage.id', null) : null,
      thumbnailFile,
      pqStoryImageFile
    }).then(async res => {
      if (res.addLearningObjective && res.addLearningObjective.id) {
        const topicConnectId = get(res.addLearningObjective, 'topic.id', null)
        const topicsConnectIds = get(res.addLearningObjective, 'topics', []).map(item => get(item, 'id'))
        const learningObjectiveConnectId = res.addLearningObjective.id
        if (addMessages) {
          await this.addMessageQueries(learningObjectiveConnectId, coursesConnectIds, duplicateLoComponentsData, topicConnectId, topicsConnectIds)
        }
        if (addQuestionBanks) {
          await this.addQuestionBankQueries(learningObjectiveConnectId, coursesConnectIds, duplicateLoComponentsData, addMessages, addLearningSlide, topicConnectId, topicsConnectIds)
        }
        if (addLearningSlide) {
          await this.addLearningSlidesQueries(learningObjectiveConnectId, coursesConnectIds, duplicateLoComponentsData, topicConnectId, topicsConnectIds) 
        }
        if (addComicStrips) {
          await this.addComicStripQueries(learningObjectiveConnectId, coursesConnectIds, duplicateLoComponentsData)
        }
        notification.success({
          message: 'Lo duplicated successfully'
        })
        closeModal()
        this.props.history.push(`comic/${learningObjectiveConnectId}`)
      }
    })
  }

  addMessageQueries = async(learningObjectiveConnectId, coursesConnectIds, duplicateLoComponentsData, topicConnectId, topicsConnectIds) => {
    let addMessageQuery = ''
    const messages = get(duplicateLoComponentsData, 'messages', [])
    const messageWithoutQuestion = messages.filter(message => get(message, 'question') === null)
    const messageWithQuestion = messages.filter(message => get(message, 'question') !== null)

    messageWithoutQuestion.length && messageWithoutQuestion.forEach((message, i) => {
      let inputStr = ''
      for (const item in message) {
        if (item != 'id' && message[item] && typeof message[item] !== 'object') {
          if (this.isItemExist(['statement', 'terminalInput', 'terminalOutput'], item)) {
            inputStr += `${item}: "${message[item]}" `
          } else {
            inputStr += `${item}: ${message[item]} `
          }
        }
      }
      const stickerConnectId = get(message, 'sticker.id', null)
      const emojiConnectIds = get(message, 'emoji', []).map(item => get(item, 'id'))
      const imageConnectId = get(message, 'image.id', null)

      addMessageQuery += `message${i}: addMessage(
        input: { ${inputStr} }
        ${stickerConnectId ? `stickerConnectId: "${stickerConnectId}"` : ''}
        ${emojiConnectIds.length > 0 ? `emojiConnectIds: [${getIdArrForQuery(emojiConnectIds)}]` : ''}
        ${imageConnectId ? `imageConnectId: "${imageConnectId}"` : ''}
        learningObjectiveConnectId: "${learningObjectiveConnectId}"
      ) {
        id
      }`
    })
    if (addMessageQuery) {
      addMessageQuery = gql`mutation{ ${addMessageQuery} }`;
      await requestToGraphql(addMessageQuery)
    }

    messageWithQuestion.length && messageWithQuestion.forEach(async(message, i) => {
      let input = {}
      for (const item in message) {
        if (item != 'id' && message[item] && typeof message[item] !== 'object') {
          input[item] = message[item]
        }
      }
      const stickerConnectId = get(message, 'sticker.id', null)
      const emojiConnectIds = get(message, 'emoji', []).map(item => get(item, 'id'))
      const imageConnectId = get(message, 'image.id', null)
      const questionConnectId = get(message, 'question.id', null)

      await addMessage({
        input,
        stickerConnectId,
        emojiConnectIds,
        imageConnectId,
        learningObjectiveConnectId,
      }).then(res => {
        const messageConnectId = get(res, 'addMessage.id')
        const questions = get(duplicateLoComponentsData, 'questionBank', [])
        const filteredQuestion = questions.filter(question => get(question, 'id') === questionConnectId)
        if (filteredQuestion.length) {
          const questionBankToAdd = get(filteredQuestion, '[0]')
          let input = {}
          for (const item in questionBankToAdd) {
            if (item != 'id' && questionBankToAdd[item] && typeof questionBankToAdd[item] !== 'object') {
              input[item] = questionBankToAdd[item]
            }
            if (['hints', 'mcqOptions', 'fibBlocksOptions', 'fibInputOptions', 'arrangeOptions'].includes(item)
              && questionBankToAdd[item] && questionBankToAdd[item].length) {
              input[item] = questionBankToAdd[item]
            }
          }
          const tagsConnectIds = get(questionBankToAdd, 'tags', []).map(item => get(item, 'id'))
          const learningSlidesConnectIds = get(questionBankToAdd, 'learningSlides', []).map(item => get(item, 'id'))

          addQuestionBank({
            learningObjectiveConnectId: [learningObjectiveConnectId],
            input,
            key: null,
            coursesId: coursesConnectIds,
            tagsConnectIds,
            topicConnectId,
            topicsConnectIds,
            messageConnectId,
            learningSlidesConnectIds
          })
        }
      })
    })
  }

  addQuestionBankQueries = async(learningObjectiveConnectId, coursesConnectIds, duplicateLoComponentsData, addMessages, addLearningSlide, topicConnectId, topicsConnectIds) => {
    let questions = get(duplicateLoComponentsData, 'questionBank', [])
    const questionsWithoutMessageLo = questions.filter(question => (get(question, 'message') === null && get(question, 'learningSlides', []).length === 0))
    let questionsToAdd = questionsWithoutMessageLo
    if (!addMessages && !addLearningSlide) {
      questionsToAdd = questions
    }
    questionsToAdd.length && questionsToAdd.forEach((question, i) => {
      let input = {}
      for (const item in question) {
        if (item != 'id' && question[item] && typeof question[item] !== 'object') {
          input[item] = question[item]
        }
        if (['hints', 'mcqOptions', 'fibBlocksOptions', 'fibInputOptions', 'arrangeOptions'].includes(item) && question[item] && question[item].length) {
          input[item] = question[item]
        }
      }
      const tagsConnectIds = get(question, 'tags', []).map(item => get(item, 'id'))
      const learningSlidesConnectIds = get(question, 'learningSlides', []).map(item => get(item, 'id'))

      addQuestionBank({
        learningObjectiveConnectId: [learningObjectiveConnectId],
        input,
        key: null,
        coursesId: coursesConnectIds,
        tagsConnectIds,
        topicConnectId,
        topicsConnectIds,
        learningSlidesConnectIds
      })
    })
  }

  addLearningSlidesQueries = async(learningObjectiveConnectId, coursesConnectIds, duplicateLoComponentsData, topicConnectId, topicsConnectIds) => {
    let addLearningSlidesQueryGrid = ''
    const learningSlides = get(duplicateLoComponentsData, 'learningSlides', [])
    const learningSlidesGrid = learningSlides.filter(item => get(item, 'type') === 'grid')
    const learningSlidesPq = learningSlides.filter(item => get(item, 'type') === 'practiceQuestion')

    learningSlidesGrid.length && learningSlidesGrid.forEach((slide, i) => {
      let inputStrGrid = ''
      for (const item in slide) {
        if (item != 'id' && slide[item] && typeof slide[item] !== 'object') {
          if (item === 'name') {
            inputStrGrid += `${item}: "${slide[item]}" `
          } else {
            inputStrGrid += `${item}: ${slide[item]} `
          }
        }
      }
      const slideContentsConnectIds = get(slide, 'slideContents', []).map(item => get(item, 'id'))
      const learningObjectivesConnectIds = [learningObjectiveConnectId]

      if (slide.type === 'grid') {
        addLearningSlidesQueryGrid += `slide${i}: addLearningSlide(
          input: { ${inputStrGrid} }
          ${slideContentsConnectIds.length > 0 ? `slideContentsConnectIds: [${getIdArrForQuery(slideContentsConnectIds)}]` : ''}
          ${topicsConnectIds.length > 0 ? `topicsConnectIds: [${getIdArrForQuery(topicsConnectIds)}]` : ''}
          ${coursesConnectIds.length > 0 ? `coursesConnectIds: [${getIdArrForQuery(coursesConnectIds)}]` : ''}
          ${learningObjectivesConnectIds.length > 0 ? `learningObjectivesConnectIds: [${getIdArrForQuery(learningObjectivesConnectIds)}]` : ''}
        ) {
          id
        }`
      }
    })
    if (addLearningSlidesQueryGrid) {
      addLearningSlidesQueryGrid = gql`mutation{ ${addLearningSlidesQueryGrid} }`;
      await requestToGraphql(addLearningSlidesQueryGrid)
    }

    learningSlidesPq.length && learningSlidesPq.forEach(async(slide, i) => {
      let input = {}
      for (const item in slide) {
        if (item != 'id' && slide[item] && typeof slide[item] !== 'object') {
          input[item] = slide[item]
        }
      }
      const slideContentsConnectIds = get(slide, 'slideContents', []).map(item => get(item, 'id'))
      const practiceQuestionsConnectIds = get(slide, 'practiceQuestions', []).map(item => get(item, 'id'))
      const learningObjectivesConnectIds = [learningObjectiveConnectId]

      await addLearningSlide(
        input,
        learningObjectivesConnectIds,
        practiceQuestionsConnectIds,
        topicsConnectIds,
        coursesConnectIds,
        slideContentsConnectIds,
      ).then(res => {
        const learningSlideConnectId = get(res, 'addLearningSlide.id')
        const questions = get(duplicateLoComponentsData, 'questionBank', [])
        const filteredQuestion = questions.filter(question => get(question, 'id') === get(practiceQuestionsConnectIds, '[0]'))
        if (filteredQuestion.length) {
          const questionBankToAdd = get(filteredQuestion, '[0]')
          let input = {}
          for (const item in questionBankToAdd) {
            if (item != 'id' && questionBankToAdd[item] && typeof questionBankToAdd[item] !== 'object') {
              input[item] = questionBankToAdd[item]
            }
            if (['hints', 'mcqOptions', 'fibBlocksOptions', 'fibInputOptions', 'arrangeOptions'].includes(item)
              && questionBankToAdd[item] && questionBankToAdd[item].length) {
              input[item] = questionBankToAdd[item]
            }
          }
          const tagsConnectIds = get(questionBankToAdd, 'tags', []).map(item => get(item, 'id'))
          const learningSlidesConnectIds = [learningSlideConnectId]
          const messageConnectId = null

          addQuestionBank({
            learningObjectiveConnectId: [learningObjectiveConnectId],
            input,
            key: null,
            coursesId: coursesConnectIds,
            tagsConnectIds,
            topicConnectId,
            topicsConnectIds,
            messageConnectId,
            learningSlidesConnectIds
          })
        }
      })
    })
  }

  addComicStripQueries = async(learningObjectiveConnectId, coursesConnectIds, duplicateLoComponentsData) => {
    const comicStrip = get(duplicateLoComponentsData, 'comicStrips[0]', {})
    const comicStripImages = get(comicStrip, 'comicImages', [])
    let comicImagesConnectIds = []
    if (comicStripImages.length > 0) {
      let addComicImageQuery = ''
      for (let i=0; i<comicStripImages.length; i++) {
        const imageConnectId = get(comicStripImages[i], 'image.id')
        addComicImageQuery += `image${i}: addComicImage(
          input: { order: ${get(comicStripImages[i], 'order')} }
          ${imageConnectId ? `imageConnectId: "${imageConnectId}"` : ''}
        ) {
          id
        }`
      }
      if (addComicImageQuery) {
        addComicImageQuery = gql`mutation{ ${addComicImageQuery} }`;
        await requestToGraphql(addComicImageQuery).then(res => {
          const images = get(res, 'data')
          if (images && Object.keys(images).length) {
            for (const image in images) {
              if (get(images, `${image}.id`)) {
                comicImagesConnectIds.push(get(images, `${image}.id`))
              }
            }
          }
        })
      }
    }
    let input = {
      title: get(comicStrip, 'title'),
      status: get(comicStrip, 'status'),
    }
    if (get(comicStrip, 'description')) {
      input.description = get(comicStrip, 'description')
    }
    await addComicStrip({ input, loId: learningObjectiveConnectId, comicImagesConnectIds, coursesConnectIds })
  }

  isItemExist = (arr, item) => {
    return arr.includes(item)
  }

  render() {
    const { openModal, closeModal, operation,
      learningObectiveUpdateStatus, learningObjectivesMeta,
      learningObectiveAddStatus, editData, coursesList, messageAddStatus,
      questionBanksAddStatus, learningSlidesAddStatus, comicStripsAddStatus,
      duplicateLoComponentsData, learningObjectiveDuplicateFetchStatus } = this.props
    const spinning = operation === 'add' ? learningObectiveAddStatus && get(learningObectiveAddStatus.toJS(), 'loading', false)
      : learningObectiveUpdateStatus && get(learningObectiveUpdateStatus.toJS(), 'loading', false)
    return (
      <MainModal
        visible={openModal}
        title={operation === 'add' ? 'Add Learning Objective' : (operation === 'edit' ? 'Edit Learning Objective' : 'Duplicate Learning Objective')}
        onCancel={() => (learningObectiveAddStatus && get(learningObectiveAddStatus.toJS(), 'loading')
            || learningObectiveUpdateStatus && get(learningObectiveUpdateStatus.toJS(), 'loading')) ? null : closeModal()
          } 
        maskClosable={false}
        width='750px'
        centered
        destroyOnClose
        footer={null}
      >
        <Spin spinning={Boolean(spinning)}>
          {
          operation === 'add' ? (
            <AddLoForm
              handleAddLo={this.handleAddLo}
              addFormData={{
                ...this.state,
                order: learningObjectivesMeta + 1
              }}
              coursesList={coursesList}
              learningObectiveAddStatus={learningObectiveAddStatus}
            />
          ) : (
            <EditLoForm
              editFormData={operation === 'duplicateLo' ? {
                ...editData,
                order: learningObjectivesMeta + 1
              } : editData}
              handleEditLo={this.handleEditLo}
              handleDuplicateLo={this.handleDuplicateLo}
              coursesList={coursesList}
              learningObectiveAddStatus={learningObectiveAddStatus}
              learningObectiveUpdateStatus={learningObectiveUpdateStatus}
              messageAddStatus={messageAddStatus}
              questionBanksAddStatus={questionBanksAddStatus}
              learningSlidesAddStatus={learningSlidesAddStatus}
              comicStripsAddStatus={comicStripsAddStatus}
              duplicateLoComponentsData={duplicateLoComponentsData}
              learningObjectiveDuplicateFetchStatus={learningObjectiveDuplicateFetchStatus}
              operation={operation}
            />
          )
        }
        </Spin>
      </MainModal>
    )
  }
}

export default LoModal
