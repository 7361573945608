import gql from 'graphql-tag'
import duck from '../../duck';
import getIdArrForQuery from '../../utils/getIdArrForQuery';

const updateEventComms = async (id, input, { ticketName = '', ticketregistrationLimit = '', commsVariablesConnectIds = [] }) => {
  const updatedInput = {
    eventCommsRule: {
      replace: [...input]
    },
  }
  if (ticketName) updatedInput.ticketName = ticketName
  if (ticketregistrationLimit) updatedInput.ticketregistrationLimit = ticketregistrationLimit
  await duck.query({
    query: gql`
        mutation updateEvent ($input:EventUpdate) {
            updateEvent(
              id:"${id}"
              input: $input
              ${commsVariablesConnectIds.length > 0 ? `commsVariablesConnectIds: [${getIdArrForQuery(commsVariablesConnectIds)}]` : ''}
            ) {
              id
              name
            }
          }
        `,
    variables: {
      input: updatedInput
    },
    type: 'event/update',
    key: 'event',
  })
}

export default updateEventComms