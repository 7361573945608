import gql from 'graphql-tag'
import duck from '../../../duck'

const fetchLearningObjective = async (learningObjectiveId, operation) =>
  duck.query({
    query: gql`
    {
        learningObjective(id:"${learningObjectiveId}"){
            id
            topics {
               id
            }
            courses {
               id
            }
            ${operation === 'duplicateLo' ? `
            messages {
               id
               order
               type
               statement
               sticker {
                   id
               }
               emoji {
                   id
               }
               image {
                   id
                   name
                   size
                   usageCount
                   uri
                   signedUri
               }
               question {
                   id
               }
               terminalInput
               terminalOutput
               codeEditorConfig {
                   editorMode
                   layout
                   executionAccess
               }
               alignment
               emojiMessagesCount: emojiMeta(filter: { type: emoji }) {
                   count
               }
           }
           questionBank: questionBank(
           filter: {
               and: [{ assessmentType: practiceQuestion }]
           }
           ) {
               id
               order
               statement
               hint
               hints {
                   hint
                   hintPretext
               }
               questionType
               questionLayoutType
               blockLayoutType
               difficulty
               assessmentType
               questionCodeSnippet
               answerCodeSnippet
               explanation
               mcqOptions {
                   statement
                   isCorrect
                   initialXML
                   blocksJSON
               }
               fibBlocksOptions {
                   displayOrder
                   statement
                   correctPositions
               }
               fibInputOptions {
                   correctPosition
                   answers
               }
               arrangeOptions {
                   displayOrder
                   statement
                   correctPosition
                   correctPositions
               }
               topic {
                   id
               }
               topics {
                   id
               }
               status
               courses {
                   id
               }
               tags {
                   id
               }
               message {
                   id
               }
               learningSlides {
                   id
               }
               mcqType
           }
           learningSlides {
               id
               name
               type
               layoutType
               order
               status
               slideContents {
                   id
               }
               practiceQuestions {
                   id
               }
               topics {
                   id
               }
               courses {
                   id
               }
           }
           comicStrips {
               id
               title
               description
               comicImages {
                   id
                   order
                   image {
                       id
                   }
               }
               status
               courses {
                   id
               }
           }
            ` : ''}
         }
    }
    `,
    type: 'learningObjective/fetch',
    key: 'learningObjective',
    changeExtractedData: (extractedData, originalData) => {
      let data = []
      if (originalData && originalData.learningObjective
        && Object.keys(originalData.learningObjective).length > 0) {
        data = originalData.learningObjective
      }
      extractedData.learningObjective = data
      return { ...extractedData }
    },
  })

export default fetchLearningObjective

