import styled from "styled-components"
import { Modal, Button } from "antd"

const ConfirmModal = styled(Modal)`
    &&& {
    width: 500px;
    @media screen and (max-width: 500px) {
        width: 350px;
    }
    .ant-modal-content {
        border-radius: 16px;
    }
    .ant-modal-body {
        padding: 0
    }
    }
`

const ModalTopContainer = styled.div`
    width: 100%;
    min-height: 88px;
    background: #FAF6FF;
    display: flex;
    align-items: center;
    border-radius: 16px;
    > span {
    width: 24px;
    height: 24px;
    margin: 0 30px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    @media screen and (max-width: 500px) {
        margin: 0 15px;
    }
    }
    > h4 {
    margin: 0;
    color: #000;
    font-size: 18px;
    line-height: 22px;
    font-family: Inter;
    font-weight: 500;
    }
`


const ModalContentContainer = styled.div`
    width: 460px;
    color: #424242;
    font-size: 14px;
    line-height: 18px;
    margin: 18px;
    font-family: Inter;
    font-weight: 500;
    display: flex;
    letter-spacing: 0em;
    align-items: center;
    @media screen and (max-width: 500px) {
    width: 320px;
    margin: 15px;
    }
`

const ModalButtonsContainer = styled.div`
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 18px;
    @media screen and (max-width: 500px) {
    width: 350px;
    }
`


const ModalCancelButton = styled(Button)`
  &&& {
    color: #504F4F;
    font-size: 12px;
    line-height: 18px;
    font-family: Inter;
    font-weight: 500;
    background: #F3EFFA;
    padding: 7px 16px 7px 12px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-right: 30px;
    border: none;
    outline: none;
  }
`

const ModalConfirmButton = styled(Button)`
  &&& {
    width: 372px;
    color: #FFFFFF;
    border: none;
    outline: none;
    font-size: 12px;
    line-height: 18px;
    font-family: Inter;
    font-weight: 500;
    background: #8C61CB;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 7px 16px 7px 12px;
    @media screen and (max-width: 500px) {
      width: 250px;
    }
  }
`

ConfirmModal.ModalTopContainer = ModalTopContainer
ConfirmModal.ModalContentContainer = ModalContentContainer
ConfirmModal.ModalButtonsContainer = ModalButtonsContainer
ConfirmModal.ModalCancelButton = ModalCancelButton
ConfirmModal.ModalConfirmButton = ModalConfirmButton

export default ConfirmModal