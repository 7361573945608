const renderFreshDesk = (shouldOpen = false) => {
  if (typeof window.FreshworksWidget !== 'undefined') {
    window.FreshworksWidget('authenticate', {
      token: `${process.env.REACT_APP_FRESH_DESK_TOKEN}`
    })
    window.FreshworksWidget('show')
    if (shouldOpen) {
      window.FreshworksWidget('open')
    }
  }
}

export default renderFreshDesk
