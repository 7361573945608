
import gql from 'graphql-tag'
import { get } from 'lodash';
import duck from '../../duck';
import uploadFile from '../utils/uploadFile';
import removeFromEventAction from './removeFromEventAction';


const addEventPrize = async (input, prizeId) => {
    const updatedInput = {}
    updatedInput.title = input.title
    updatedInput.minRank = input.minRank
    updatedInput.maxRank = input.maxRank

    return duck.query({
        query: prizeId ? gql`mutation($input: EventPrizeUpdate) {
        updateEventPrize(id: "${prizeId}", input: $input) {
            id
        }
        }
        ` : gql`
           mutation addEventPrize($input: EventPrizeInput!) {
                addEventPrize(input: $input) {
                  id
                }
              }
              `,
        variables: {
            input: updatedInput
        },
        type: `eventPrizes/${prizeId ? 'update' : 'add'}`,
        key: 'eventPrizes',
        changeExtractedData: (extracted, original) => {
            const eventPrizeId = prizeId ? get(original, 'updateEventPrize.id') : get(original, 'addEventPrize.id')
            if (input.image && typeof input.image !== 'string') {
                (async function imageUpload() {
                    try {
                        if (get(input, 'prevImage.id')) {
                            await removeFromEventAction('', get(input, 'prevImage.id'), eventPrizeId, 'prize')
                        }
                        await uploadFile(input.image, {
                            fileBucket: 'python'
                        }, {
                            typeField: 'image', typeId: eventPrizeId, type: 'EventPrize',
                        })
                    } catch (error) {
                        console.log(error)
                    }
                }())
            }

            return { ...extracted }
        }
    })
}

export default addEventPrize
