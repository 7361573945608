const getSlotTime = (slot) => {
  let time = ''
  if (slot > 0 && slot < 9) {
    time = `0${slot}:00 am - 0${slot + 1}:00 am`
  } else if (slot === 9) {
    time = `0${slot}:00 am - 10:00 am`
  } else if (slot === 10) {
    time = `${slot}:00 am - 11:00 am`
  } else if (slot === 11) {
    time = '11:00 am - 12:00 pm'
  } else if (slot === 12) {
    time = '12:00 pm - 01:00 pm'
  } else if (slot === 10) {
    time = `${slot}:00 am - 11:00 am`
  } else if (slot > 12 && slot < 23) {
    time = `${slot - 12}:00 pm - ${slot - 11}:00 pm`
  } else if (slot === 23) {
    time = '11:00 pm - 00:00 am'
  } else {
    time = 'Session not created for this topic'
  }
  return time
}
export default getSlotTime
