import React, { useState, useEffect, useRef } from 'react'
import { Redirect } from 'react-router-dom';
import { Button, Pagination, Tooltip, Alert } from 'antd';
import moment from 'moment';
import { EditFilled, InfoOutlined } from '@ant-design/icons';
import { Table } from 'antd'
import BatchStyle from './BatchContainer.styles'
import { Link, useHistory } from 'react-router-dom';
import { StyledButton } from '../SpeakerPrizes/SpeakerPrizes.style'
import fetchEvents from '../../../actions/eventDashboard/fetchEvents';
import { get } from 'lodash';
import getFullPath from '../../../utils/getFullPath';
import CopyEventsButton from './CopyEventsButton';
import DownloadEventButton from './DownloadEventsButton';
import InputGroupAddon from 'rsuite/lib/InputGroup/InputGroupAddon';

const columns = [
  {
    title: 'Event Title',
    dataIndex: 'event',
    key: 'event',
    width: 300
  },
  {
    title: 'Dates',
    dataIndex: 'date',
    key: 'date',
    width: 200
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: 150
  },
  {
    title: 'Registrations',
    dataIndex: 'registration',
    key: 'registration',
    width: 150
  },
  {
    title: 'Speaker',
    dataIndex: 'speaker',
    key: 'speaker',
    width: 150
  },
  {
    title: 'Action',
    dataIndex: 'actions',
    key: 'actions',
    width: 150
  },
  {
    title: 'User`s',
    dataIndex: 'DownloadUsers',
    key: 'DownloadUsers',
    width: 80
  },
  {
    title: 'UTM`s',
    dataIndex: 'DownloadUTMS',
    key: 'DownloadUTMS',
    width: 80
  },
]

const eventTypeMap = {
  'oneday': 'One day',
  'recurring': 'Recurring',
  'workshop': 'Workshop',
  'offline': 'Offline'
}

const BatchContainer = ({ upcoming, all = false, calanderFilterStartDate, calanderFilterEndDate, eventData, callCalender, ...props }) => {
  const [batch, setBatch] = useState(props.fetchEvents);
  const [boldUpcomingFont, setBoldUpcomingFont] = useState(true);
  const [boldCompletedFont, setBoldCompletedFont] = useState(false);
  const [boldDraftFont, setBoldDraftFont] = useState(false);
  const [boldAllFont, setBoldAllFont] = useState(false);
  const [currentPage, setCurrPage] = useState(1);
  const perPageQueries = 10;
  const history = useHistory()

  const [a, setA] = useState(calanderFilterStartDate)
  const [b, setB] = useState(calanderFilterEndDate)
  // 
  useEffect(() => {
    if (calanderFilterStartDate !== a || calanderFilterEndDate !== b) {
      setA(calanderFilterStartDate);
      setB(calanderFilterEndDate);
      let d = new Date()
      d = d.toISOString()
      setCurrPage(1)
      if (boldUpcomingFont) {
        fetchEvents({ curDateGte: d, startDate: calanderFilterStartDate, endDate: calanderFilterEndDate, page: 1, perPage: perPageQueries })
      } else if (boldCompletedFont) {
        fetchEvents({ curDateLte: d, startDate: calanderFilterStartDate, endDate: calanderFilterEndDate, page: 1, perPage: perPageQueries })
      } else if (boldDraftFont) {
        fetchEvents({ startDate: calanderFilterStartDate, endDate: calanderFilterEndDate, unpublished: true, page: 1, perPage: perPageQueries })
      } else if (boldAllFont) {
        fetchEvents({ startDate: calanderFilterStartDate, endDate: calanderFilterEndDate, page: 1, perPage: perPageQueries })
      }
    }
  }, [calanderFilterStartDate, calanderFilterEndDate]);

  const getTableData = () => {
    const data = []
    const { fetchEvents } = props
    if (fetchEvents && fetchEvents.length) {
      const studentsLength = fetchEvents.length
      for (let i = 0; i < studentsLength; i += 1) {
        const element = fetchEvents[i]
        const startDate = moment(get(element, 'eventStartTime')).format('ll')
        const endDate = moment(get(element, 'eventEndTime')).format('ll')
        let currentDate = moment().format('ll')
        const status = get(element, 'status')
        let checkStatus = 'default'
        let pathName = (status && status === 'published') ? `/eventDashboard/events/edit/${get(element, 'id')}` : `/eventDashboard/events/${get(element, 'id')}/basicDetails`
        if (moment(currentDate).isAfter(moment(endDate))) {
          checkStatus = 'completed'
          pathName = `/eventDashboard/events/completed/${get(element, 'id')}`
        } else if (status === 'published' || status === 'unpublished' || status === 'cancelled') {
          checkStatus = status
        }
        const speakers = get(element, 'speakers', []).map((speaker, idx) => {
          if (get(element, 'speakers', []).length === 1) {
            return `${get(speaker, 'user.name')}`
          }
          if (idx === get(element, 'speakers', []).length - 1) {
            return `${get(speaker, 'user.name')}`
          }
          return `${get(speaker, 'user.name')},`
        })
        data.push({
          event: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {get(element, 'listingImage') && (
                <img style={{
                  height: '50px', width: '90px', marginRight: '15px', objectFit: 'contain'
                }} src={getFullPath(get(element, 'listingImage.uri'))} alt='' />
              )}
              <span
                style={{ cursor: 'pointer', color: '#00c0ff', fontWeight: '500' }}
                onClick={() => history.push({
                  pathname: pathName,
                  state: { urlFrom: '/eventDashboard/events' }
                })}>{get(element, 'name')}</span>
            </div>
          ),
          date: <div>{`${startDate} > ${endDate}`}</div>,
          type: <div>{eventTypeMap[get(element, "dateType")] || "-"}</div>,
          registration: (
            <div style={{ paddingLeft: "2.5rem" }}>
              {get(element, "registeredUsersMeta.count", 0)}
            </div>
          ),
          speaker: (
            <div>{get(element, "speakers", []).length ? speakers : "-"}</div>
          ),
          actions: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Link
                to={{
                  pathname: pathName,
                  state: { urlFrom: "/eventDashboard/events" },
                }}
              >
                <EditFilled style={{ marginRight: "10px" }} />
              </Link>
              
             { get(element, "dateType") === "oneday" && <CopyEventsButton eventId={get(element, 'id')} />}
              <Tooltip title={checkStatus === "default" ? null : checkStatus}>
                <BatchStyle.AuditStatus status={checkStatus} />
              </Tooltip>
            </div>
          ),
          DownloadUsers: <DownloadEventButton eventId={get(element, 'id')} checkStatus={checkStatus} />,
          DownloadUTMS: <DownloadEventButton eventId={get(element, 'id')} checkStatus={checkStatus} isUTM />
        });
      }
    }
    return data
  }

  function onPageChange(page) {
    setCurrPage(page);
    let d = new Date()
    d = d.toISOString()
    if (boldUpcomingFont) {
      fetchEvents({ curDateGte: d, startDate: calanderFilterStartDate, endDate: calanderFilterEndDate, page: page, perPage: perPageQueries })
    } else if (boldCompletedFont) {
      fetchEvents({ curDateLte: d, startDate: calanderFilterStartDate, endDate: calanderFilterEndDate, page: page, perPage: perPageQueries })
    } else if (boldDraftFont) {
      fetchEvents({ startDate: calanderFilterStartDate, endDate: calanderFilterEndDate, unpublished: true, page: page, perPage: perPageQueries })
    } else if (boldAllFont) {
      fetchEvents({ startDate: calanderFilterStartDate, endDate: calanderFilterEndDate, page: page, perPage: perPageQueries })
    }
  }

  const upcomingData = () => {
    setBoldUpcomingFont(true)
    setBoldCompletedFont(false)
    setBoldDraftFont(false)
    setBoldAllFont(false)
    let d = new Date()
    d = d.toISOString()
    setCurrPage(1)
    fetchEvents({ curDateGte: d, startDate: calanderFilterStartDate, endDate: calanderFilterEndDate, page: 1, perPage: perPageQueries })
  }

  const completedData = () => {
    setBoldUpcomingFont(false)
    setBoldCompletedFont(true)
    setBoldDraftFont(false)
    setBoldAllFont(false)
    let d = new Date()
    d = d.toISOString()
    setCurrPage(1)
    fetchEvents({ curDateLte: d, startDate: calanderFilterStartDate, endDate: calanderFilterEndDate, page: 1, perPage: perPageQueries })
  }

  const allEventData = () => {
    setBoldUpcomingFont(false)
    setBoldCompletedFont(false)
    setBoldDraftFont(false)
    setBoldAllFont(true)
    setCurrPage(1)
    let d = new Date()
    d = d.toISOString()
    fetchEvents({ startDate: calanderFilterStartDate, endDate: calanderFilterEndDate, page: 1, perPage: perPageQueries })
  }

  const draftData = () => {
    setBoldUpcomingFont(false)
    setBoldCompletedFont(false)
    setBoldDraftFont(true)
    setBoldAllFont(false)
    let d = new Date()
    d = d.toISOString()
    setCurrPage(1)
    fetchEvents({ startDate: calanderFilterStartDate, endDate: calanderFilterEndDate, unpublished: true, page: 1, perPage: perPageQueries })
  }
  return (
    <div style={{ background: 'white', borderRadius: '8px', paddingBottom: '25px' }}>

      <Link to={'/eventDashboard/events/add/basicDetails'}>
        <StyledButton icon='plus' style={{ margin: '1rem 1.5rem' }}>
          Create Event
        </StyledButton>
      </Link>
      {!all &&
        <BatchStyle.TopContainer style={{ marginTop: '-20px' }}>
          <BatchStyle.Heading>
            <Button type="link" style={{ color: 'black', fontWeight: boldUpcomingFont && 'bold' }} onClick={() => upcomingData()}>
              Upcoming Events
            </Button>
          </BatchStyle.Heading>
          <BatchStyle.Devider />
          <BatchStyle.Heading>
            <Button type="link" style={{ color: 'black', fontWeight: boldCompletedFont && 'bold' }} onClick={() => completedData()}>
              Completed Events
            </Button>
          </BatchStyle.Heading>
          <BatchStyle.Devider />
          <BatchStyle.Heading>
            <Button type="link" style={{ color: 'black', fontWeight: boldDraftFont && 'bold' }} onClick={() => draftData()}>
              Draft Events
            </Button>
          </BatchStyle.Heading>
          <BatchStyle.Devider />
          <BatchStyle.Heading>
            <Button type="link" style={{ color: 'black', fontWeight: boldAllFont && 'bold' }} onClick={() => allEventData()}>
              All Events
            </Button>
          </BatchStyle.Heading>
        </BatchStyle.TopContainer>
      }
      <Table
        columns={columns}
        dataSource={getTableData()}
        pagination={false}
        scroll={{ x: 'max-content', y: 580 }}
        loading={props.tableLoading || false}
      />
      <div style={{ margin: '10px auto', width: 'fit-content' }}>
        {
          props.eventsCount > perPageQueries && (<
            Pagination total={
              props.eventsCount
            }
            onChange={
              onPageChange
            }
            current={
              currentPage
            }
            defaultPageSize={
              perPageQueries
            }
          />
          )
        }
      </div>
    </div>
  )
}

export default BatchContainer
