import React from "react";

export default function StatusTab({
  type,
  label,
  status,
  index,
  tabIndex,
  handleTabActive,
}) {
  return (
    <>
      <div
        className={`status ${tabIndex === index ? "tab-active" : ""}`}
        data-type={type}
        onClick={() => {
          handleTabActive(index);
        }}
      >
        {type !== "total" && <div className="icon-upload icon"></div>}
        <span>{status ? ` ${status}` : "0"}</span>
        <p>{label}</p>
      </div>
    </>
  );
}
