import uploadFile from "../utils/uploadFile"
import removeCertificatePdf from "./removeCertificatePdf"

const uploadCertificatePdf = async (pdf, eventId, prevPdfId) => {
    let data = null;
    try {
        if (pdf) {
            if (prevPdfId) {
                await removeCertificatePdf(eventId, prevPdfId)
                .then(async () => {
                    await 
                    uploadFile(pdf, { fileBucket: 'python' }, { typeField: 'baseCertificate', typeId: eventId, type: 'Event', })
                    .then(res => data = res)
                })
            } else {
                await 
                uploadFile(pdf, { fileBucket: 'python' }, { typeField: 'baseCertificate', typeId: eventId, type: 'Event', })
                .then(res => data = res)
            }
        }
    } catch (error) {
        console.log(error)
    }
}

export default uploadCertificatePdf