import gql from 'graphql-tag'
import duck from '../../duck'

const addMentorToSensei = async (mentorQuery) =>
  duck.query({
    query: gql`
      mutation{
        ${mentorQuery}
      }
    `,
    type: 'mentors/update',
    key: 'addMentor',
    changeExtractedData: (extracted) => ({
      ...extracted
    })
  })

export default addMentorToSensei

