import React, { Component } from 'react'
import { get } from 'lodash'
import { Link } from 'react-router-dom'
import momentTZ from 'moment-timezone'
import gql from 'graphql-tag'
import { Button, Input, Modal, Table, notification, Pagination, Popconfirm, Select } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import getDataFromLocalStorage from '../../utils/extract-from-localStorage'
import { ADMIN, UMS_ADMIN, UMS_VIEWER } from '../../constants/roles'
import fetchLeadPartners from '../../actions/leadPartner/fetchLeadPartners'
import addLeadPartners from '../../actions/leadPartner/addLeadPartner'
import updateLeadPartners from '../../actions/leadPartner/updateLeadPartner'
import deleteLeadPartners from '../../actions/leadPartner/deleteLeadPartner'
import fetchLeadPartnerusers from '../../actions/leadPartner/fetchLeadPartnerUsers'
import fetchLeadPartnerAgent from './leadPartner-utils'
import removeOrDeleteLeadPartner from '../../actions/leadPartner/removeOrDeleteLeadPartner'
import countryAndState from '../../constants/CountryAndStates'
import MainTable from '../../components/MainTable'
import requestToGraphql from '../../utils/requestToGraphql'

class LeadPartner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      leadPartner: '',
      addOrUpdate: '',
      lPid: '',
      loading: true,
      bloading: false,
      childColumn: [],
      currentPage: 1,
      perPage: 10,
      total: 0,
      leadPartnerUsers: [],
      groupedData: {},
      columns: [],
      selectedAdmins: [],
      selectedAgents: [],
      selectedCountry: [],
      selectedTimezone: [],
      showCountryTimezoneModal: false,
      updateLeadPartnerId: '',
      dayWiseBooking: 0,
      monthlyBooking: 0,
      dayWiseConduction: 0,
      monthlyConduction: 0
    }
  }

  async componentDidMount() {
    fetchLeadPartnerusers().then(res => {
      this.setState({
        leadPartnerUsers: get(res, 'users', [])
      })
    })
    const p = await fetchLeadPartners({
      first: this.state.perPage,
      skip: this.state.currentPage - 1
    })
    this.setState({
      total: this.props.leadPartnersMeta.toJS().count
    })
    this.loadPartners(p.leadPartners)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isAddingLeadPartners && this.props.hasAddedLeadPartners) {
      notification.success({
        message: 'Lead partner added successfully',
      })
      this.setState({
        visible: false,
        loading: false,
        bloading: false,
        total: this.props.leadPartnersMeta.toJS().count
      })
    }
    if (prevProps.isUpdatingLeadPartners && this.props.hasUpdatedLeadPartners) {
      notification.success({
        message: 'Lead partner updated successfully',
      })
      this.setState({
        visible: false,
        loading: false,
        bloading: false,
        total: this.props.leadPartnersMeta.toJS().count
      })
    }
    if (prevProps.isDeletingLeadPartners && this.props.hasDeletedLeadPartners) {
      notification.success({
        message: 'Lead partner deleted successfully',
      })
    }
    if (prevProps.isAddingLeadPartners && this.props.hasAddingLeadPartnersFailed) {
      const errorArray = this.props.addLeadPartnerError.toJS()
      notification.error({
        message: get(errorArray, '[0].error.errors[0].message', 'Error adding lead partner'),
      })
    }
    if (prevProps.isUpdatingLeadPartners && this.props.hasUpdatingLeadPartnersFailed) {
      const errorArray = this.props.updateLeadPartnerError.toJS()
      notification.error({
        message: get(errorArray, '[0].error.errors[0].message', 'Error updating lead partner'),
      })
    }
    if (prevProps.isDeletingLeadPartners && this.props.hasDeletingLeadPartnersFailed) {
      const errorArray = this.props.deleteLeadPartnerError.toJS()
      notification.error({
        message: get(errorArray, '[0].error.errors[0].message', 'Error deleting lead partner'),
      })
    }
  }

  cancel = () => {

  }
  expandedRow = row => {
    const { groupedData, childColumn } = this.state
    if (groupedData[row].length > 0) {
      return (
      <Table
        bordered
        columns={childColumn}
        dataSource={groupedData[row]}
        pagination={false}
        showHeader={false}
        rowClassName={() => 'antdTable-row antdTable-child-row'}
      />
      )
    }
    return null
  }
  getUserData = (data, type) => {
    if (type === 'name') {
      if (get(data, 'isAdmin')) {
        return get(data, 'name') ? <Link to={`/ums/dashboard/${get(data, 'id')}`}>{get(data, 'name')} (Admin)</Link> : '-'
      }
      return get(data, 'agent.name') ? <Link to={`/ums/dashboard/${get(data, 'agent.id')}`}>{get(data, 'agent.name')}</Link> : '-'
    } else if (type === 'email') {
      if (get(data, 'isAdmin')) {
        return get(data, 'email') || '-'
      }
      return get(data, 'agent.email') || '-'
    } else if (type === 'phone') {
      if (get(data, 'isAdmin')) {
        return get(data, 'phone.number') ? `${get(data, 'phone.countryCode')}-${get(data, 'phone.number')}` : '-'
      }
      return get(data, 'agent.phone.number') ? `${get(data, 'agent.phone.countryCode')}-${get(data, 'agent.phone.number')}` : '-'
    } else if (type === 'country' && !get(data, 'isAdmin')) {
      return (
        <MainTable.ActionItem.IconWrapper>
        <MainTable.ActionItem.EditIcon onClick={() => this.showCountryModal(data)} />
        </MainTable.ActionItem.IconWrapper>
      )
    }
    return null
  }
  showCountryModal = (data) => {
    const {
      countries = [], timezones = [], id: agentId,
      dayWiseBooking = 0, dayWiseConduction = 0,
      monthlyBooking = 0, monthlyConduction = 0
    } = data
    const selectedCountry = []
    const selectedTimezone = []
    countries.forEach(country => {
      const findCountry = countryAndState.find(co => co.countryValue === get(country, 'value'))
      if (findCountry) {
        selectedCountry.push({ label: findCountry.country, key: findCountry.countryValue })
      }
    })
    timezones.forEach(time => {
      if (time) {
        selectedTimezone.push({ label: time.value, key: time.value })
      }
    })
    this.setState({
      selectedCountry,
      selectedTimezone,
      showCountryTimezoneModal: true,
      updateLeadPartnerId: agentId,
      dayWiseBooking,
      dayWiseConduction,
      monthlyBooking,
      monthlyConduction
    })
  }
  updateLeadParnterCountry = async () => {
    const { selectedCountry, selectedTimezone,
      updateLeadPartnerId, dayWiseBooking, monthlyBooking,
      dayWiseConduction, monthlyConduction } = this.state
    this.setState({
      loading: true,
      bloading: true
    })
    let countries = '{ replace: ['
    selectedCountry.forEach(country => {
      countries += `{ value: ${get(country, 'key')} }`
    })
    countries += ']}'
    let timezones = '{ replace: ['
    selectedTimezone.forEach(time => {
      timezones += `{ value: "${get(time, 'key')}" }`
    })
    timezones += ']}'
    if (selectedTimezone.length && selectedCountry.length && updateLeadPartnerId) {
      await requestToGraphql(gql`mutation {
      updateLeadPartnerAgent(
        id: "${updateLeadPartnerId}"
        input: {
          ${countries ? `countries: ${countries}` : ''}
          ${timezones ? `timezones: ${timezones}` : ''}
          ${dayWiseBooking ? `dayWiseBooking: ${dayWiseBooking}` : ''}
          ${monthlyBooking ? `monthlyBooking: ${monthlyBooking}` : ''}
          ${dayWiseConduction ? `dayWiseConduction: ${dayWiseConduction}` : ''}
          ${monthlyConduction ? `monthlyConduction: ${monthlyConduction}` : ''}
        }
      ) {
        id
      }
    }
    `)
      this.setState({
        loading: false,
        bloading: false
      }, this.closeCountryModal)
      const p = await fetchLeadPartners({
        first: this.state.perPage,
        skip: this.state.currentPage - 1
      })
      this.loadPartners(p.leadPartners)
    }
  }
  loadPartners = (p = []) => {
    const groupedData = p.reduce((accumulator, currentValue) => {
      accumulator[currentValue.title] = accumulator[currentValue.title] || []
      accumulator[currentValue.title].push(...get(currentValue, 'admins', []).map(admin => ({ ...admin, isAdmin: true })), ...get(currentValue, 'agents', []))
      return accumulator
    }, {})
    const columns = [
      {
        title: 'S. No.',
        dataIndex: 'srno',
        key: 'srno',
        width: 20,
        render: () => ({ props: { colSpan: 0 } })
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 100,
        render: () => ({ props: { colSpan: 0 } })
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 100,
        align: 'center',
        render: (text, row) => ({
          props: {
            colSpan: 4
          },
          children: (
            <div style={{ display: 'grid', gridTemplateColumns: '30% 30% 30%', alignItems: 'center' }}>
              <span>
                {row}
              </span>
              <Button style={{ width: 'fit-content' }}
                onClick={() => {
                  const findData = p.find(data => get(data, 'title') === row)
                  if (findData) {
                    this.showModal('edit', get(findData, 'title'))
                    this.setState({
                      leadPartner: get(findData, 'title'),
                      selectedAdmins: get(findData, 'admins', []).map(admin => ({
                        label: get(admin, 'name'), key: get(admin, 'id')
                      })),
                      selectedAgents: get(findData, 'agents', []).map(agent => ({
                        label: get(agent, 'agent.name'),
                        key: get(agent, 'agent.id'),
                        agentId: get(agent, 'id')
                      })),
                      addOrUpdate: 'Update',
                      lPid: get(findData, 'id'),
                    })
                  }
              }}
              >
                Add Admin/Agent
              </Button>
              <Popconfirm
                title='Are you sure you want to delete lead partner?'
                okText='Yes'
                cancelText='No'
                onConfirm={() => {
                  const findData = p.find(data => get(data, 'title') === row)
                  if (findData) {
                    this.deleteLP(get(findData, 'id'))
                  }
                }}
                onCancel={this.cancel}
              >
              <DeleteOutlined
                style={{ fontSize: '25px', color: '#ED494C' }}
              />
              </Popconfirm>
            </div>
          )
        }),
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        width: 100,
        render: () => ({ props: { colSpan: 0 } })
      },
    ]
    const childColumn = [{
      title: 'S. No.',
      dataIndex: 'srno',
      key: 'srno',
      width: 10,
      render: (_, data, index) => index + 1
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      render: (data, row) => this.getUserData(row, 'name')
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 100,
      align: 'center',
      render: (data, row) => this.getUserData(row, 'email')
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 100,
      render: (data, row) => this.getUserData(row, 'phone')
    }, {
      title: 'Add Countries/Timezones',
      dataIndex: 'country',
      key: 'country',
      width: 100,
      render: (data, row) => this.getUserData(row, 'country')
    },
    ]
    this.setState({
      loading: false,
      groupedData,
      columns,
      childColumn
    })
  }

  showModal = (type, value) => {
    if (type === 'add') {
      this.setState({
        visible: true,
        leadPartner: value
      })
    } else if (type === 'edit') {
      this.setState({
        visible: true,
        leadPartner: value
      })
    }
  }

  cancelModal = () => {
    this.setState({
      visible: false,
      selectedAgents: [],
      selectedAdmins: [],
      leadPartner: ''
    })
  }

  onSave = async () => {
    let leadPartnerTitle = this.state.leadPartner
    const { leadPartner, selectedAgents, selectedAdmins } = this.state
    leadPartnerTitle = leadPartnerTitle.trim()
    if (leadPartnerTitle.length > 0) {
      if (this.state.addOrUpdate === 'Add') {
        this.setState({
          loading: true,
          bloading: true
        })
        await addLeadPartners({
          title: leadPartner,
          agentsConnectIds: selectedAgents.map(agent => get(agent, 'key')),
          adminsConnectIds: selectedAdmins.map(admin => get(admin, 'key'))
        })
        this.setState({
          loading: false,
          bloading: false
        })
        const p = await fetchLeadPartners({
          first: this.state.perPage,
          skip: this.state.currentPage - 1
        })
        this.loadPartners(p.leadPartners)
      } else if (this.state.addOrUpdate === 'Update') {
        this.setState({
          loading: true,
          bloading: true
        })
        await updateLeadPartners({
          id: this.state.lPid,
          title: leadPartner,
          agentsConnectIds: selectedAgents,
          adminsConnectIds: selectedAdmins.map(admin => get(admin, 'key'))
        })
        this.setState({
          loading: false,
          bloading: false
        })
        const p = await fetchLeadPartners({
          first: this.state.perPage,
          skip: this.state.currentPage - 1
        })
        this.loadPartners(p.leadPartners)
      }
    } else {
      notification.error({
        message: 'Title not provided',
      })
    }
  }

  deleteLP = async (id) => {
    this.setState({
      loading: true
    })
    await deleteLeadPartners({ id })
    const p = await fetchLeadPartners({
      first: this.state.perPage,
      skip: this.state.currentPage - 1
    })
    if (p.leadPartners && !p.leadPartners.length) {
      this.onPageChange(this.state.currentPage - 1)
    } else {
      this.loadPartners(p.leadPartners)
      this.setState({
        loading: false,
        total: this.props.leadPartnersMeta.toJS().count
      })
    }
  }


  onPageChange = page => {
    if (page === 0) {
      page = 1
    }
    this.setState({
      loading: true
    })
    this.setState({
      currentPage: page,
    }, async () => {
      this.setState({
        total: this.props.leadPartnersMeta.toJS().count
      })
      const p = await fetchLeadPartners({
        first: this.state.perPage,
        skip: this.state.currentPage - 1
      })
      this.loadPartners(p.leadPartners)
      this.setState({
        loading: false
      })
    })
  }

  onSelectAdmin = (data) => {
    const { selectedAdmins } = this.state
    this.setState({
      selectedAdmins: [...selectedAdmins, data]
    })
  }
  onDeselectAdmin = (data) => {
    const { selectedAdmins, addOrUpdate, lPid } = this.state
    if (addOrUpdate === 'Update') {
      removeOrDeleteLeadPartner({ leadPartnerId: lPid, userId: get(data, 'key') })
    }
    this.setState({
      selectedAdmins: [...selectedAdmins].filter(admin => get(admin, 'key') !== get(data, 'key'))
    })
  }
  onSelectAgent = (data) => {
    const { selectedAgents } = this.state
    this.setState({
      selectedAgents: [...selectedAgents, data]
    })
  }
  onDeselectAgent = async (data) => {
    const { selectedAgents, addOrUpdate } = this.state
    const findAgent = selectedAgents.find(agent => get(agent, 'key') === get(data, 'key'))
    if (addOrUpdate === 'Update' && get(findAgent, 'agentId')) {
      const utmDetails = await fetchLeadPartnerAgent(get(findAgent, 'agentId'))
      if (utmDetails.length > 0) {
        notification.error({
          message: 'Cannot delete agent as Utm details are already there.'
        })
        return
      } else if (utmDetails.length === 0) {
        removeOrDeleteLeadPartner({ leadPartnerId: get(findAgent, 'agentId') })
      }
    }
    this.setState({
      selectedAgents: [...selectedAgents].filter(agent => get(agent, 'key') !== get(data, 'key'))
    })
  }
  selectedDeselectCountry = (country) => {
    const { selectedCountry } = this.state
    let newCountries = [...selectedCountry]
    const isCountryExist = newCountries.find(c => get(c, 'key') === get(country, 'key'))
    if (isCountryExist) {
      newCountries = newCountries.filter(c => get(c, 'key') !== get(country, 'key'))
    } else {
      newCountries = [...newCountries, country]
    }
    this.setState({
      selectedCountry: newCountries
    })
  }
  selectedDeselectTimezone = (timezone) => {
    const { selectedTimezone } = this.state
    let newTimezones = [...selectedTimezone]
    const isCountryExist = newTimezones.find(t => get(t, 'key') === get(timezone, 'key'))
    if (isCountryExist) {
      newTimezones = newTimezones.filter(t => get(t, 'key') !== get(timezone, 'key'))
    } else {
      newTimezones = [...newTimezones, timezone]
    }
    this.setState({
      selectedTimezone: newTimezones
    })
  }
  closeCountryModal = () => {
    this.setState({
      selectedCountry: [],
      selectedTimezone: [],
      showCountryTimezoneModal: false,
      dayWiseBooking: 0,
      monthlyBooking: 0,
      dayWiseConduction: 0,
      monthlyConduction: 0
    })
  }
  onInputChange = (event) => {
    const { name, value } = event.target
    if (value > 0) {
      this.setState({
        [name]: value
      })
    }
  }
  render() {
    const savedRole = getDataFromLocalStorage('login.role')
    if (savedRole && !(savedRole === ADMIN
      || savedRole === UMS_ADMIN
      || savedRole === UMS_VIEWER)) {
      return (<div>Not Found</div>)
    }
    const { dayWiseBooking, monthlyBooking,
      monthlyConduction, dayWiseConduction } = this.state
    const gridStyle = { display: 'grid', gridTemplateColumns: '48% 48%', justifyContent: 'space-between', marginTop: 10 }
    return (
      <>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => { this.showModal('add', ''); this.setState({ addOrUpdate: 'Add' }) }} type='primary'>
            Add Lead Partner Type
          </Button>
        </div>
        <br />
        <Modal
          visible={this.state.showCountryTimezoneModal}
          onCancel={this.closeCountryModal}
          footer={null}
          title='Add Countries and Timezones'
        >
          <span>Countries</span>
          <Select
            mode='multiple'
            labelInValue
            value={this.state.selectedCountry}
            placeholder='Select Country'
            filterOption={(input, option) =>
                get(option, 'props.children')
                    ? get(option, 'props.children')
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                    : false
                }
            style={{ width: '100%' }}
            onSelect={this.selectedDeselectCountry}
            onDeselect={this.selectedDeselectCountry}
          >
            {countryAndState.map(country =>
              <Select.Option key={country.countryValue}
                value={country.countryValue}
              >{country.country}
              </Select.Option>)}
          </Select>
          <span style={{ marginTop: '10px' }}>Timezones:{' '}</span>
            <Select
              mode='multiple'
              labelInValue
              value={this.state.selectedTimezone}
              placeholder='Select timezones'
              filterOption={(input, option) =>
                get(option, 'props.children')
                    ? get(option, 'props.children')
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                    : false
                }
              style={{ width: '100%' }}
              onSelect={this.selectedDeselectTimezone}
              onDeselect={this.selectedDeselectTimezone}
            >
              {momentTZ.tz.names().map(timezone => (
                <Select.Option value={timezone} label={timezone}>
                  {timezone}
                </Select.Option>
              ))}
            </Select>
          <div style={gridStyle}>
            <div>
              <span>Day Wise Bookings:{' '}</span>
              <Input
                type='number'
                name='dayWiseBooking'
                value={dayWiseBooking}
                onChange={this.onInputChange}
              />
            </div>
            <div>
              <span>Monthly Bookings:{' '}</span>
              <Input type='number'
                name='monthlyBooking'
                value={monthlyBooking}
                onChange={this.onInputChange}
              />
            </div>
          </div>
          <div style={gridStyle}>
            <div>
              <span>Day Wise Conduction:{' '}</span>
              <Input type='number'
                name='dayWiseConduction'
                value={dayWiseConduction}
                onChange={this.onInputChange}
              />
            </div>
            <div>
              <span>Monthly Conduction:{' '}</span>
              <Input
                type='number'
                name='monthlyConduction'
                value={monthlyConduction}
                onChange={this.onInputChange}
              />
            </div>
          </div>
          <Button
            type='primary'
            style={{ width: '100%', margin: '10px 0' }}
            onClick={() => { this.updateLeadParnterCountry() }}
            loading={this.state.bloading}
          >
            Save
          </Button>
        </Modal>
        <Modal
          visible={this.state.visible}
          onCancel={this.cancelModal}
          footer={null}
          title={`${this.state.addOrUpdate} Lead Partner Type`}
        >
          <span>Title</span>
          <Input value={this.state.leadPartner}
            name='leadPartner'
            onChange={(e) => { this.setState({ leadPartner: e.target.value }) }}
            addonBefore='Lead Partner Title'
            disabled={this.state.addOrUpdate === 'Update'}
          />
          {this.state.addOrUpdate === 'Update' && (
            <div style={{ width: '100%', marginTop: '20px' }}>
            <span>Admin</span>
            <Select
              mode='multiple'
              labelInValue
              value={this.state.selectedAdmins}
              placeholder='Select Admin'
              filterOption={(input, option) =>
                get(option, 'props.children')
                    ? get(option, 'props.children')
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                    : false
                }
              style={{ width: '100%' }}
              onSelect={this.onSelectAdmin}
              onDeselect={this.onDeselectAdmin}
            >
            {this.state.leadPartnerUsers.map(user =>
              <Select.Option key={get(user, 'id')}
                value={get(user, 'id')}
              >{get(user, 'name')}
              </Select.Option>)}
            </Select>
            </div>
          )}
          {this.state.addOrUpdate === 'Update' && (
          <div style={{ width: '100%', marginTop: '20px' }}>
            <span>Agent</span>
            <Select
              mode='multiple'
              labelInValue
              value={this.state.selectedAgents}
              placeholder='Select Agent'
              filterOption={(input, option) =>
                get(option, 'props.children')
                    ? get(option, 'props.children')
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                    : false
                }
              style={{ width: '100%' }}
              onSelect={this.onSelectAgent}
              onDeselect={this.onDeselectAgent}
            >
            {this.state.leadPartnerUsers.map(user =>
              <Select.Option key={get(user, 'id')}
                value={get(user, 'id')}
              >{get(user, 'name')}
              </Select.Option>)}
            </Select>
          </div>
          )}
          <Button
            type='primary'
            style={{ width: '100%', margin: '10px 0' }}
            onClick={() => { this.onSave() }}
            loading={this.state.bloading}
          >
            Save
          </Button>
        </Modal>
        <Table
          dataSource={
              this.state.groupedData ?
              Object.keys(this.state.groupedData) : []
            }
          columns={this.state.columns}
          bordered
          pagination={false}
          defaultExpandAllRows={false}
          expandIconAsCell={false}
          expandedRowRender={this.expandedRow}
          rowKey={record => record}
          expandedRowKeys={this.state.groupedData ? Object.keys(this.state.groupedData) : []}
          loading={this.state.loading}
          scroll={{ x: 'max-content' }}
          rowClassName={() => 'antdTable-row'}
        />
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '10px' }}>
          <Pagination
            total={this.state.total}
            onChange={this.onPageChange}
            current={this.state.currentPage}
            defaultPageSize={this.state.perPage}
          />
        </div>
      </>
    )
  }
}

export default LeadPartner
