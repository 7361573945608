import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'
import getIdArrForQuery from '../../utils/getIdArrForQuery'

const fetchMentorAvailabilitySlots = async (filters, mentorsIds = []) =>
  duck.query({
    query: gql`
      {
        mentorAvailabilitySlots(filter:{ and: [ ${filters || ''} ] }) {
          id
          date
          verticals {
            value
          }
          openedBy {
            id
            user {
              id
              name
            }
          }
          slotName
          paySlab {
            id
            title
          }
          countries {
            value
          }
          timezone
          count
          schools {
            id
            name
          }
          campaigns {
            id
            title
            code
          }
          broadCastedMentors {
            id
          }
          ${mentorsIds.length > 0 ? `mentorSessionsMeta(filter: { user_some: { id_in: [${getIdArrForQuery(mentorsIds)}] } }) {
              count
            }` : `mentorSessionsMeta{
            count
          }`}
          isBroadCasted
          batchSessions{
            id
            batch{
              id
              type
            }
          }
          b2cCount :menteeSessionsMeta {
            count
          }
        }
      }
    `,
    type: 'mentorAvailabilitySlots/fetch',
    key: 'mentorAvailabilitySlots',
    changeExtractedData: (extractedData, originalData) => {
      extractedData.campaigns = []
      extractedData.schools = []
      extractedData.user = []
      extractedData.batchSessions = []
      extractedData.batch = []
      extractedData.mentorAvailabilitySlots = get(originalData, 'mentorAvailabilitySlots', [])
      extractedData.mentorAvailabilitySlots.forEach(slot => {
        const b2b2cSession = get(slot, 'batchSessions', []).filter(session => get(session, 'batch.type') === 'b2b2c') || []
        const b2bSession = get(slot, 'batchSessions', []).filter(session => get(session, 'batch.type') === 'b2b') || []
        slot.b2b2cCount = {
          count: b2b2cSession.length
        }
        slot.b2bCount = {
          count: b2bSession.length
        }
      })
      return { ...extractedData }
    },
  })

export default fetchMentorAvailabilitySlots

