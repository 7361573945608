import React from 'react'
import { Form, Formik } from 'formik'
import { addAssignmentSchema } from '../Components/FormElements'
import { InfoCircleOutlined, QuestionCircleOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'
import Dropzone from '../../../components/Dropzone'
import { encodeBase64 } from '../../../utils/base64Utility'
import { ContentEditor } from '../../../components/ContentEditor'
import EventOverviewWrapper from '../EventOverview/EventOverview.style'
import { StyledAddBtn, StyledBackButton, StyledButton, StyledFooter } from '../SpeakerPrizes/SpeakerPrizes.style'
import EventsStyles from '../CreateEvent/createEvent.style'
import addEventJoinReason from '../../../actions/eventDashboard/addEventJoinReason'
import updateEventJoinReason from '../../../actions/eventDashboard/updateEventJoinReason'
import fetchSingleEvent from '../../../actions/eventDashboard/fetchSingleEvent'
import updateEvent from '../../../actions/eventDashboard/updateEvent'
import { get } from 'lodash'
import { Icon, notification } from 'antd'
import { Event } from '../createEvent.style'
import PageLoader from '../Components/PageLoader'
import { checkTextType } from '../event-utils'
import removeFromEventAction from '../../../actions/eventDashboard/removeFromEventAction'
import getFullPath from '../../../utils/getFullPath'
import EventNavigation from '../Components/EventNavigation'


class EventDescription extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modalVisible: false,
            rescheduleEvent: false,
            cancelEvent: false,
            summary: '',
            overview: '',
            eventListingImage: '',
            cardImagesErrors: [
                {error:'Recommended Size: 60 x 60', key:'card1', showError: false},
                {error:'Recommended Size: 60 x 60', key:'card2', showError: false},
                {error:'Recommended Size: 60 x 60', key:'card3', showError: false},
            ],
            isFetching: false,
            eventsData: null
        }
    }
    componentDidMount = () => {
        const eventIdFromRoute = get(this.props, 'match.params.id')
        if (eventIdFromRoute) {
            this.setState({
                isFetching: true
            })
            fetchSingleEvent(eventIdFromRoute, 'description').then(res => {
                this.setState({
                    isFetching: false,
                    eventsData: get(res, 'event')
                })
            })
        }
    }

    onDropImage = (index, file, fieldName, setFieldError, setFieldValue) => {
        if (file && typeof file !== 'string') {
            const img = new Image()
            img.src = window.URL.createObjectURL(file)
            img.onload = () => {
                if (fieldName === 'eventListingImage') {
                    if (img && (parseInt(img.width) === 312)
                        && parseInt(img.height) === 215) {
                        setFieldError(`eventListingImage`, null)
                        setFieldValue(`eventListingImage`, file)
                    } else {
                        setFieldError(`eventListingImage`, 'Recommended Size: 410 x 470')
                    }
                }
                if (fieldName==='cardDataContainer') {
                    if (img && (parseInt(img.width) === 60 || parseInt(img.width) === 61)
                        && (parseInt(img.height) === 60 || parseInt(img.height) === 61)) {
                        setFieldError(`cardDataContainer.${index}.imageUrl`, null)
                        setFieldValue(`cardDataContainer.${index}.imageUrl`, file)
                    } else {
                        setFieldError(`cardDataContainer.${index}.imageUrl`, 'Recommended Size: 60 x 60')
                    }
                }
            }
        }
    }

    handleFormSubmit = async (values) => {
        const { cardDataContainer } = values
        const addedJoinReason = []
        for (let i = 0; i < cardDataContainer.length; i++) {
            const joinReason = cardDataContainer[i]
            if (get(joinReason, 'reasonId')) {
                const res = await updateEventJoinReason(get(joinReason, 'reasonId'),
                    { title: get(joinReason, 'details') },
                    get(joinReason, 'imageUrl'),
                    get(joinReason, 'prevImageId')
                )
                if (get(res, 'updateEventJoinReason.id')) addedJoinReason.push(get(res, 'updateEventJoinReason.id'))
            } else {
                const res = await addEventJoinReason(cardDataContainer[i])
                if (get(res, 'addEventJoinReason.id')) addedJoinReason.push(get(res, 'addEventJoinReason.id'))
                if (this.props.hasAddedEventJoinReasonsFailed) {
                    return notification.error({ message: 'Error Adding Join Reason' })
                }
            }
        }
        let eventJoinReasonIds = this.props.fetchedEventJoinReasons
        eventJoinReasonIds = eventJoinReasonIds && eventJoinReasonIds.toJS()
        eventJoinReasonIds = eventJoinReasonIds.map(item => item.id)
        await updateEvent({
            summary: values.summary, overview: values.overview,
            eventListingImage: values.eventListingImage,
            eventJoinReasonsConnectIds: addedJoinReason,
            prevImageId: get(this.state.eventsData, 'listingImage.id')
        }, { eventId: this.props.match.params.id })
        if (this.props.hasUpdatedEvent) {
            const { match: { params }, history } = this.props
            const updatedEvent = this.props.fetchedEvents.toJS()
            history.push(`/eventDashboard/events/${get(params, 'id')}/speakers-prizes`)
        }
        if (this.props.hasUpdatedEventFailed) {
            notification.error({ message: 'Error Updating Event!' })
        }
    }
    gotoEditEvent = () => {
        const { match: { params }, history } = this.props
        if (get(params, 'id')) {
            history.push(`/eventDashboard/events/${get(params, 'id')}/basicDetails`)
        }
    }
    getDefaultReasons = () => {
        const { eventsData } = this.state
        let reasons = []
        get(eventsData, 'eventJoinReasons', []).forEach((reason, index) => {
            reasons.push({
                reasonId: get(reason, 'id'),
                details: get(reason, 'title'),
                prevImageId: get(reason, 'picture.id'),
                imageUrl: getFullPath(get(reason, 'picture.uri')),
                key: get(reason, 'id'),
                label: `Card ${index + 1}*`,
                imageTitle: `Insert card ${index + 1} image here`
            })
        })
        if (!reasons.length) {
            reasons = [
                {
                    label: 'Card 1*',
                    key: 'card1',
                    details: '',
                    imageTitle: 'Insert card 1 image here',
                    imageUrl: null,
                },
                {
                    label: 'Card 2*',
                    key: 'card2',
                    details: '',
                    imageTitle: 'Insert card 2 image here',
                    imageUrl: null,
                },
                {
                    label: 'Card 3*',
                    key: 'card3',
                    details: '',
                    imageTitle: 'Insert card 3 image here',
                    imageUrl: null,
                },
            ]
        }
        return reasons
    }
    renderForm = () => {
        const { isUpdatingEvent, isAddingEventJoinReasons, isUpdatingEventJoinReasons } = this.props
        const { eventsData } = this.state
        return (
            <Formik
                    initialValues={{
                        summary: get(eventsData, 'summary') || '',
                        overview: get(eventsData, 'overview') || '',
                        eventListingImage: getFullPath(get(eventsData, 'listingImage.uri', '')) || '',
                        cardDataContainer: this.getDefaultReasons()
                    }}
                    onSubmit={(values) => {
                        this.handleFormSubmit(values)
                    }}
                validationSchema={addAssignmentSchema}
                >
                    {({ values, handleChange, setFieldValue, errors, setErrors, setFieldError }) => (
                        <Form style={{ padding: '0 10px', height: '100%' }} id='form'>
                            <EventOverviewWrapper style={{ height: '100%' }}>
                                <EventOverviewWrapper.HeadingContainer>
                                    <h1>
                                        <InfoCircleOutlined className='heading-icon' />
                                    </h1>
                                    <EventOverviewWrapper.Content style={{ marginLeft: '15px' }}>
                                        <h1>Event Description</h1>
                                        <p>Add more details to your event</p>
                                    </EventOverviewWrapper.Content>
                                </EventOverviewWrapper.HeadingContainer>
                                <EventOverviewWrapper.Content style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 40px' }}>
                                    <EventOverviewWrapper.Content style={{ width: '70%' }}>
                                        <EventOverviewWrapper.TitleInputContainer 
                                            style={{
                                                width: '100%', position: 'relative'
                                            }}
                                        >
                                            <h3>Summary</h3>
                                            <EventOverviewWrapper.InputTextArea
                                                placeholder='Provide details about the event'
                                                rows={2}
                                                bordered={false}
                                                value={values.summary || ''}
                                                onChange={handleChange}
                                                setFieldValue={setFieldValue}
                                                name='summary'
                                                type='text'
                                                inputRef='summary'
                                                className='summary'
                                            />
                                            <Icon
                                                type='bold'
                                            style={{
                                                position: 'absolute',
                                                bottom: 0,
                                                right: '10px',
                                                backgroundColor: '#77baf6',
                                                padding: '6px',
                                                color: 'white',
                                                borderRadius: '5px',
                                                }}
                                                onMouseDown={(e) => { e.preventDefault() }}
                                                onClick={() => {
                                                    const inputs = document.querySelector(`.summary`)
                                                    if (inputs) {
                                                        const { selectionStart, selectionEnd } = inputs
                                                        const { summary } = values
                                                        const nextTextValue =
                                                            `${summary.slice(0, selectionStart)}<br/>${summary.slice(selectionStart, selectionEnd)}${summary.slice(selectionEnd)}`
                                                        setFieldValue('summary', nextTextValue)
                                                        inputs.focus()
                                                    }
                                                }}
                                            />
                                        </EventOverviewWrapper.TitleInputContainer>
                                        {errors && errors.summary && (
                                            <EventOverviewWrapper.showError>{errors.summary}</EventOverviewWrapper.showError>
                                        )}
                                        <EventOverviewWrapper.Content style={{ marginTop: '20px' }}>
                                            <h3 style={{ marginBottom: '15px' }}>About Event</h3>
                                            <ContentEditor
                                                id='3874'
                                                setFieldValue={setFieldValue}
                                                inputRef='overview'
                                                placeholder='description about event'
                                                name='overview'
                                                type='text'
                                                inputValue={values.overview || ''}
                                                initialValue={checkTextType(get(eventsData, 'overview'))}
                                                handleChange={(e) => {
                                                    handleChange(e)
                                                }}
                                                init={{
                                                    height: 250,
                                                    selector: `QA-3874`,
                                                    placeholder: "Start adding your description here...",
                                                }}
                                                onChange={(_, editor) => {
                                                    setFieldValue('overview', encodeBase64(editor.getContent()))
                                                }}
                                            />
                                        </EventOverviewWrapper.Content>
                                        {errors && errors.overview && (
                                            <EventOverviewWrapper.showError>{errors.overview}</EventOverviewWrapper.showError>
                                        )}
                                    </EventOverviewWrapper.Content>
                                    <EventOverviewWrapper.Content style={{ width: '25%' }}>
                                        <p style={{ marginBottom: '5px', color: '#4A4A4A', fontStyle: 'normal', fontWeight: '700', }}>
                                            Insert event listing image here</p>
                                        <Dropzone
                                            style={{ height: '80%', width: '80%', marginBottom: '15px' }}
                                            name='eventListingImage'
                                            defaultFile={values.eventListingImage}
                                            onClose={() => {
                                                setFieldValue('eventListingImage', null)
                                                setFieldError('eventListingImage', 'event listing image is required')
                                            }}
                                            getDropzoneFile={file => {
                                                this.onDropImage(null, file, 'eventListingImage', setFieldError, setFieldValue)
                                            }}
                                            defaultImage={values.eventListingImage}
                                            setFieldValue={setFieldValue}
                                            inputRef='eventListingImage'
                                        />
                                        {errors && errors.eventListingImage && (
                                            <EventOverviewWrapper.showError>{errors.eventListingImage}</EventOverviewWrapper.showError>
                                        )}
                                    </EventOverviewWrapper.Content>
                                </EventOverviewWrapper.Content>
                                <EventOverviewWrapper.HeadingContainer>
                                    <h1>
                                        <QuestionCircleOutlined className='heading-icon' />
                                    </h1>
                                    <EventOverviewWrapper.Content style={{ marginLeft: '15px' }}>
                                        <h1>Why should you join?</h1>
                                        <p>List the benefits of joining (upto 5 cards)</p>
                                    </EventOverviewWrapper.Content>
                                </EventOverviewWrapper.HeadingContainer>
                                <EventOverviewWrapper.Content style={{ display: 'flex', flexDirection: 'column', marginLeft: '40px' }}>
                                    {values && values.cardDataContainer.map((item, index) => (
                                        <EventOverviewWrapper.Content style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <EventOverviewWrapper.TitleInputContainer>
                                                <h3>{item.label}</h3>
                                                <EventOverviewWrapper.InputTextArea
                                                    placeholder='Provide details about the event'
                                                    rows={2}
                                                    bordered={false}
                                                    value={item.details || ''}
                                                    name={`cardDataContainer.${index}.details`}
                                                    onChange={handleChange}
                                                    setFieldValue={setFieldValue}
                                                />
                                                {errors && errors.cardDataContainer && errors.cardDataContainer[index] && errors.cardDataContainer[index].details && (
                                                    <EventOverviewWrapper.showError>{errors.cardDataContainer[index].details}</EventOverviewWrapper.showError>
                                                )}
                                            </EventOverviewWrapper.TitleInputContainer>
                                            <EventOverviewWrapper.Content style={{ marginRight: '100px', width: '220px' }}>
                                                <EventOverviewWrapper.Content style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                                                    <p style={{ color: '#4A4A4A', fontStyle: 'normal', fontWeight: '700', }}>{item.imageTitle}</p>
                                                    {index >= 3 && (
                                                        <CloseOutlined
                                                            onClick={() => {
                                                                const tempState = values.cardDataContainer
                                                                const removedPrice = tempState.find(temp => temp.key === item.key)
                                                                const newState = tempState.filter(temp => temp.key !== item.key)
                                                                const newCardImageErrors = this.state.cardImagesErrors.filter(temp => temp.key !== item.key)
                                                                const eventsPrice = get(eventsData, 'eventJoinReasons', []).map(reason => get(reason, 'id'))
                                                                if (eventsPrice.includes(get(removedPrice, 'key')) && eventsData) {
                                                                    removeFromEventAction(get(eventsData, 'id'), '', get(removedPrice, 'key'), 'reasonContent')
                                                                }
                                                                this.setState({
                                                                    cardImagesErrors: newCardImageErrors
                                                                })
                                                                setFieldValue('cardDataContainer', newState)
                                                            }}
                                                            style={{ fontSize: '20px', fontWeight: '600', cursor: 'pointer' }}
                                                        />
                                                    )}
                                                </EventOverviewWrapper.Content>
                                                <Dropzone
                                                    style={{ height: '150px', width: '100%', marginBottom: '15px' }}
                                                    getDropzoneFile={(file) => {
                                                        this.onDropImage(index, file, 'cardDataContainer', setFieldError, setFieldValue)
                                                    }}
                                                    onClose={() => {
                                                        setFieldValue(`cardDataContainer.${index}.imageUrl`, null)
                                                        setFieldError(`cardDataContainer.${index}.imageUrl`, 'card image is required')
                                                    }}
                                                    setFieldValue={setFieldValue}
                                                    defaultFile={item.imageUrl}
                                                    name={`cardDataContainer.${index}.imageUrl`}
                                                    defaultImage={item.imageUrl}
                                                />
                                                {errors && errors.cardDataContainer && errors.cardDataContainer[index] && errors.cardDataContainer[index].imageUrl && (
                                                    <EventOverviewWrapper.showError>{errors.cardDataContainer[index].imageUrl}</EventOverviewWrapper.showError>
                                                )}
                                            </EventOverviewWrapper.Content>
                                        </EventOverviewWrapper.Content>
                                    ))}
                                    {values && values.cardDataContainer.length < 5 && (
                                        <EventOverviewWrapper.Content style={{ display: 'flex', alignItems: 'center' }}>
                                            <StyledAddBtn onClick={() => {
                                                const tempState = values.cardDataContainer
                                                if (tempState.length < 5) {
                                                    const tempLength = tempState.length
                                                    const obj = {
                                                        label: tempLength === 3 ? 'Card 4*' : 'Card 5*',
                                                        key: tempLength === 3 ? 'card4' : 'card5',
                                                        details: '',
                                                        imageTitle: tempLength === 3 ? 'Insert card 4 image here' : 'Insert card 5 image here',
                                                        imageUrl: null,
                                                    }
                                                    tempState.push(obj)
                                                    this.setState({
                                                        cardImagesErrors: [
                                                            ...this.state.cardImagesErrors, 
                                                            {error:'Recommended Size: 60 x 60', 
                                                            key: tempState.length === 4 ? 'card4' : 'card5',
                                                            showError: false,
                                                            }
                                                        ]
                                                    })
                                                    setFieldValue('cardDataContainer', tempState)
                                                }
                                            }}>
                                                <PlusOutlined style={{ fontSize: '1.2rem', fontWeight: 'bolder' }} />
                                            </StyledAddBtn>
                                            <p style={{ marginLeft: '15px' }}>Add more cards (upto 5)</p>
                                        </EventOverviewWrapper.Content>
                                    )}
                                </EventOverviewWrapper.Content>
                            </EventOverviewWrapper>
                            <StyledFooter>
                                <StyledBackButton mRight onClick={this.gotoEditEvent}>
                                    Back
                                </StyledBackButton>
                                <StyledButton htmlType='submit' loading={isUpdatingEvent || isAddingEventJoinReasons || isUpdatingEventJoinReasons}>
                                    {(isUpdatingEvent || isAddingEventJoinReasons || isUpdatingEventJoinReasons) ? 'Saving...' : ' Save and Continue'}
                                </StyledButton>

                            </StyledFooter>
                        </Form>
                    )}
                </Formik>
        )
    }
    render() {
        const { isFetching } = this.state
        const { id } = this.props.match.params
        const currentPath = this.props.match.path.split('/')
        const currentNav = currentPath[currentPath.length -1]
        return (
            <Event.EventsWrapper>
                <PageLoader isfetching={isFetching} />
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>                    <EventsStyles.PageTitle>Create Event</EventsStyles.PageTitle>
                    { id && <EventNavigation id={id} isCurrent={currentNav}/>}
                </div>
                {!isFetching && this.renderForm()}
            </Event.EventsWrapper>
        )
    }
}

export default EventDescription

