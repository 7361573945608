import gql from "graphql-tag";
import duck from "../../duck";

const fetchStudentsCount = async ({ schoolId = "", academicYearId = "" }) =>
  duck.query({
    query: gql`
      query {
        getSchoolStudentsCount(
          schoolId: "${schoolId}"
          academicYearId: "${academicYearId}"
        )
      }
    `,
    type: "studentsCount/fetch",
    key: "studentsCount",

    changeExtractedData: (extractedData, originalData) => {
      return extractedData;
    },
  });

export default fetchStudentsCount;
