import styled from 'styled-components'
import { DatePicker } from 'antd'
import { TekieAmethyst } from '../../../../constants/colors'

const StyledDatePicker = styled(DatePicker.MonthPicker)`
  &&& {
    div {
      input {
        &:hover {
          border-color: ${TekieAmethyst}
        }
        &:focus {
          border-color: ${TekieAmethyst}
        }
      }
    }
  }
`

const GenerateReportStyle = {}
GenerateReportStyle.DatePicker = StyledDatePicker

export default GenerateReportStyle
