import gql from 'graphql-tag'
import duck from '../../duck'

const FETCH_MENTORS_LIST = (filterQuery) => gql`
  query {
    users(filter:{
      and:[
        ${!filterQuery ? '' : filterQuery}
        {
          role:mentor
        }{ secondaryRole_exists: false }
      ]
    }) {
      id
      role
      name
      email
      phone {
        countryCode
        number
      }
      mentorProfile {
        id
        senseiProfile {
          id
        }
      }
    }
  }
`;

const fetchMentorsList = async (filterQuery) =>
  duck.query({
    query: FETCH_MENTORS_LIST(filterQuery),
    type: 'users/fetch',
    key: 'senseiProfiles'
  })

export default fetchMentorsList
