export function swapKeysAndValues(obj) {
  //  {'a': 'b', 'c': 'd'}
  // swapping keys and values
  const swapped = Object.entries(obj).map(
    ([key, value]) => [value, key]
  );
  //  {'b: 'a', 'd': 'c'}
  // It transforms a list of key-value pairs into an object.
  return Object.fromEntries(swapped);
}
