import { Icon } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { TekieOrange } from '../../constants/colors'

const FullCalendarStyle = styled.div``

const SupplyEvent = styled.div`
height: 100%;
width: 100%;
background: ${props => props.isSlotSelected ? 'rgba(140, 97, 203, 0.3)' : 'white'};
font-size: 15px;
display: flex;
justify-content: ${props => props.justify ? props.justify : 'center'};
align-items: center;
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
display: flex;
align-items: center;
text-align: center;
color: #8C61CB;
position: relative;
@media (max-width: 780px) {
    font-size: 12px;
    line-height: 15px;
}
& .plus__icon{
    position: absolute;
    top: 4px;
    right: 4px;
    font-size: 14px;
    @media (max-width: 780px) {
        font-size: 10px;
    }
}
`

const EditSupplyEvent = styled.div`
background: ${props => props.bgColor || '#FFFFFF'};
height: 100%;
width: 100%;
display: flex;
justify-content: ${props => props.justify ? props.justify : 'center'};
align-items: center;
font-family: Inter;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
color: #8C61CB;
@media (max-width: 780px) {
    font-size: 12px;
    line-height: 15px;
}
@media (max-width: 580px) {
    font-size: 8.5px;
    line-height: 10px;
}
& .leftSlot__class{
    position: absolute;
    bottom: 0.5px;
    right: 4px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 12px;
    text-align: right;
    ${props => props.overFlow ? 'color: white;' : 'color: #8C61CB;'}
    @media (max-width: 580px) {
        font-size: 5.5px;
        line-height: 9px;
    }
}
${props => props.overFlow ? `
color: white;
& svg path{
    stroke:white;
}
` : `
&:hover{
background: #F4F0FA;
border: 1px solid #EEEEEE;
}
`}
& .rightSlot__class{
    position: absolute;
    bottom: 0.5px;
    left: 4px !important;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 12px;
    text-align: right;
    ${props => props.overFlow ? 'color: white;' : 'color: #8C61CB;'}
    @media (max-width: 580px) {
        font-size: 5.5px;
        line-height: 9px;
    }
}
`

const SlotVerticals = styled.div`
position: absolute;
right: 1px;
top: 1px;
display: grid;
justify-content: flex-end;
width: 100%;
height: 100%;
grid-template-rows: 10px 10px 10px;
& .b2bSlotIcon circle{
stroke: #FAAD14;
}
& .b2b2cSlotIcon circle{
stroke: #2593E2;
}
& .b2cSlotIcon circle{
stroke: #01AA93;
}
`

const StyledIcon = styled(({ isActive, ...rest }) => <Icon {...rest} />)`
  transition: 0.3s all ease-in-out;
  font-size: ${props => props.fontSize ? props.fontSize : '26px'};
  fill: ${props => props.fillSvg ? props.fillSvg : '#666666'};
  margin-right: ${props => props.marginRight ? props.marginRight : '8px'};
  @media (max-width: 580px) {
    display:none !important;
}
`

const VerticalIndicator = styled.div`
font-family: 'Inter';
background: ${props => props.bgColor ? props.bgColor : TekieOrange};
position: relative;
width: 20px;
height: 20px;
border-radius: 6px;
color: white;
font-size: 10px;
font-weight: 600;
display: flex;
text-transform: Uppercase;
justify-content: center;
align-items: flex-end;
padding-bottom: 2px;`

const PreHeaderText = styled.div`
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 14px;
text-align: center;
text-transform: uppercase;
margin-left: 8px;
color: #333333;
opacity: 0.45;
`

FullCalendarStyle.SupplyEvent = SupplyEvent
FullCalendarStyle.EditSupplyEvent = EditSupplyEvent
FullCalendarStyle.SlotVerticals = SlotVerticals
FullCalendarStyle.StyledIcon = StyledIcon
FullCalendarStyle.VerticalIndicator = VerticalIndicator
FullCalendarStyle.PreHeaderText = PreHeaderText

export default FullCalendarStyle

