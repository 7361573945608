import gql from 'graphql-tag'
import duck from '../../duck'

const updateBatchCurrentComponentStatus = async (id, currentTopicConnectId) => duck.query({
  query: gql`
    mutation {
        updateBatchCurrentComponentStatus(
          id: "${id}",
          ${currentTopicConnectId ? `currentTopicConnectId: "${currentTopicConnectId}"` : ''}
        ) {
          id
        }
    }
    `,
  type: 'updateBatchCurrentComponentStatus/update',
  key: 'updateBatchCurrentComponentStatus',
})

export default updateBatchCurrentComponentStatus
