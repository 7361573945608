const spokenLanguages = () => {

    const language = {
        'English':'english',
        'Hindi':'hindi',
        'Marathi':'marathi',
        'Kannada':'kannada',
        'Tamil':'tamil',
        'Malayalam':'malayalam',
        'Telugu':'telugu',
        'Bengali':'bengali',
        'Oaia':'odia',
        'Punjabi':'punjabi',
        'Assamese':'assamese',
    }
    return{
        language,
    }
}

export default spokenLanguages