/* eslint-disable no-console */
import React, { Component } from 'react'
import { get } from 'lodash'
import { Pagination, Select, Input, message } from 'antd'
import Main from './Upload.style'
import UploadTable from './components/UploadTable'
import UploadModal from './components/UploadModal'
import fetchUploadFiles from '../../actions/upload/fetchUploadFile'
import MainTable from '../../components/MainTable'
import SVGIcon from '../../assets/icons'

class Uploads extends Component {
  state = {
    uploadFiles: null,
    shouldUploadFileVisible: false,
    filterType: false,
    bucketType: false,
    selectType: false,
    secondSelect: false,
    perPage: 15,
    skip: 0,
    currentPage: 1,
    fileType: ['None', 'Filebucket', 'Type', 'Name'],
    fileData: {
      None: [''],
      Filebucket: ['Python', 'Email', 'Landing Page', 'Editor', 'Web Development', 'CodeOrg', 'Blockly', 'Temporary', 'Topic Thumbnail'],
      Type: ['video', 'image', 'subtitle', 'lottie'],
      Name: ['']
    },
    fileBucketMapping: {
      Python: 'python',
      Email: 'email',
      'Landing Page': 'landingPage',
      Editor: 'editor',
      'Web Development': 'webDevelopment',
      CodeOrg: 'codeOrg',
      Blockly: 'blockly',
      Temporary: 'temp',
      'Topic Thumbnail': 'topicThumbnail'
    },
    camelCaseBucket: ['python', 'email', 'landingPage', 'editor', 'webDevelopment', 'codeOrg', 'blockly', 'temp', 'topicThumbnail'],
    types: [],
    fileDefault: [],
    bucket: [],
    type: [],
    name: []
  }
  componentDidMount() {
    const { perPage, skip, bucket, type, name } = this.state
    if (this.state.uploadFiles === null) {
      fetchUploadFiles(perPage, skip, bucket, type, name)
    }
  }

  componentDidUpdate(prevProps) {
    const prevUploadFiles = get(prevProps, 'uploadFiles')
    const uploadFiles = get(this.props, 'uploadFiles')
    if (prevUploadFiles !== uploadFiles) {
      this.setState({
        uploadFiles: uploadFiles.toJS()
      })
    }
    const prevUpdateStatus = get(prevProps, 'updateStatus')
    const updateStatus = get(this.props, 'updateStatus')
    if (prevUpdateStatus !== updateStatus) {
      this.updatedFile()
    }
    const prevDeleteStatus = get(prevProps, 'deletedStatus')
    const deleteStatus = get(this.props, 'deletedStatus')
    if (prevDeleteStatus !== deleteStatus) {
      this.deletedFile()
    }
  }

  deletedFile = () => {
    const { deletedStatus } = this.props
    if (deletedStatus && deletedStatus.toJS()) {
      const status = deletedStatus.toJS()
      if (status.loading) {
        message.loading('Deleting...', 1)
      } else if (status.success) {
        message.success('File Deleted')
        this.fetchPages()
      } else if (status.failure && !status.loading) {
        message.error('Error in Deleting File')
      }
    }
  }

  updatedFile = () => {
    const { updateStatus } = this.props
    if (updateStatus && updateStatus.toJS()) {
      const status = updateStatus.toJS()
      if (status.success) {
        message.success('File updated')
        this.fetchPages()
      } else if (status.failure && !status.loading) {
        const failureMessage = this.props.fileUpdateFailure
        && this.props.fileUpdateFailure.toJS()
        const errorObj = failureMessage.pop()
        const errorName = get(get(errorObj, 'error.errors[0]'), 'extensions.exception.name')
        if (errorName === 'FileNameAlreadyExists') message.error('File with same name already exists.')
        else message.error('File not updated')
      }
    }
  }

  openAddTopic = () => {
    this.setState({ shouldUploadFileVisible: true })
  }

  closeAddTopic = () => {
    this.setState({ shouldUploadFileVisible: false })
  }

  onPageChange = (page) => {
    this.setState({
      currentPage: page,
      skip: page - 1
    }, () => this.fetchPages())
  }

  fetchPages = () => {
    const { perPage, skip, bucket, type, name } = this.state
    fetchUploadFiles(perPage, skip, bucket, type, name)
  }

  homePage = () => {
    this.setState({
      fileDefault: '',
      currentPage: 1,
    }, () => fetchUploadFiles(20, 0, '', '', ''))
  }

  filesSelect = (value) => {
    this.setState({
      types: this.state.fileData[value],
      fileDefault: '',
      currentPage: 1
    })
    if (value === 'None') {
      this.setState({
        secondSelect: false,
        filterType: false,
        name: [],
        type: [],
        bucket: [],
      }, () => this.fetchPages())
    }
    if (value === 'Filebucket') {
      this.setState({
        bucketType: true,
        filterType: false,
        selectType: false,
        secondSelect: true,
        fileDefault: '',
      })
    }
    if (value === 'Type') {
      this.setState({
        selectType: true,
        bucketType: false,
        filterType: false,
        secondSelect: true,
        fileDefault: '',
      })
    }
    if (value === 'Name') {
      this.setState({
        filterType: true,
        secondSelect: false,
      })
    }
  }

  fetchFiles = (value) => {
    const { selectType, bucketType } = this.state
    if (selectType) {
      this.setState({
        fileDefault: value,
        type: value,
        name: [],
        currentPage: 1,
        skip: 0,
        bucket: [],
      }, () => this.fetchPages())
    }
    if (bucketType) {
      this.setState({
        fileDefault: value,
        bucket: value,
        currentPage: 1,
        skip: 0,
        type: [],
        name: []
      }, () => this.fetchPages())
    }
  }

  search = (value) => {
    this.setState({
      name: value,
      type: [],
      bucket: [],
      currentPage: 1,
      skip: 0
    }, () => this.fetchPages())
  }

  render() {
    const { Option } = Select
    const { Search } = Input
    const {
      uploadFiles,
      perPage,
      currentPage,
      filterType,
      secondSelect,
      types,
      fileType,
      fileDefault,
      camelCaseBucket,
      selectType,
      bucketType
    } = this.state
    const fetchStatus = this.props.filesStatus
    && this.props.filesStatus.toJS().uploadFiles
    const { filesCount } = this.props
    const deleteStatus = this.props.deletedStatus && this.props.deletedStatus.toJS().uploadFiles
    return (
      <div>
        <Main.Path>
          <div style={{ flex: 2 }}>
            <MainTable.ActionItem.IconWrapper onClick={this.homePage}>
             Tekie Uploader
            </MainTable.ActionItem.IconWrapper>
          </div>
          <Pagination
            total={!filesCount ? 0 : filesCount}
            onChange={this.onPageChange}
            current={currentPage}
            defaultPageSize={perPage}
            hideOnSinglePage
            style={{ flex: 4 }}
          />
        </Main.Path>
        <Main.TopContainer>
          <div style={{ flex: 1 }}>
            <Select defaultValue={fileType[0]} placeholder='Filters Select' style={{ minWidth: '160px' }} onChange={this.filesSelect}>
              {fileType.map(type => (
                <Option key={type}>{type}</Option>
                ))
            }
            </Select>
            {secondSelect &&
            <Select value={fileDefault} style={{ minWidth: '160px', marginLeft: '15px' }} onChange={this.fetchFiles}>
              {selectType &&
                types.map(value => (<Option key={value}>{value ? `${value[0].toUpperCase()}${value.slice(1)}` : ''}</Option>))
              }
              {bucketType &&
                types.map((value, index) =>
                (<Option key={camelCaseBucket[index]}>{value}</Option>))
              }
            </Select>
            }
            {filterType && <Search
              style={{ width: 250, marginLeft: '15px' }}
              placeholder='Search Files By Name'
              allowClear
              enterButton
              onSearch={this.search}
            />
            }
          </div>
          <Main.Upload
            type='primary'
            shape='round'
            style={{ marginRight: '10px', display: 'flex', justifyContent: 'space-evenly' }}
            onClick={this.openAddTopic}
            wide
          >
          <SVGIcon.UploadIcon />
          <div>
            UPLOAD FILE
          </div>
          </Main.Upload>
        </Main.TopContainer>
        <UploadTable
          uploadFiles={uploadFiles}
          fetchStatus={fetchStatus}
          deleteStatus={deleteStatus}
        />
        <UploadModal
          closeUpload={this.closeAddTopic}
          fetchPages={this.fetchPages}
          fileBucket={this.state.fileData[fileType[1]]}
          fileBucketMapping={this.state.fileBucketMapping}
          visible={this.state.shouldUploadFileVisible}
        />
      </div>
    )
  }
}

export default Uploads
