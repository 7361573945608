import gql from "graphql-tag";
import duck from "../../duck";

const fetchUserCred = async (schoolId, first, skip, academicYearConnectId) => {
  return duck.query({
    query: gql`
        query{
        userCred: users(
    filter: {
      and: [
        { role: mentee }
        {
          studentProfile_some: {
            and:[
              {
                school_some: { id: "${schoolId}" }
              }
              { academicYears_some: { id: "${academicYearConnectId}" } }
            ]
          }
        }
      ]
    }
    ${first ? `first:${first}` : ''}
    ${skip ? `skip:${skip}` : ''}
  ) {
    id
    name
    email
    status
    savedPassword
    username
    studentProfile {
      grade
      section
      rollNo
      parents {
        user { email savedPassword username}
      }
      studentBatch: batch {
        classroomTitle
      }
    }
  }
        }
    `,
    variables: {
        callScriptApi: true,
    },
    type: 'userCred/fetch',
    key: 'userCred',
  });
}
  
export default fetchUserCred;