import uploadFile from '../utils/uploadFile'
import removePosterFromCampaign from './removePosterFromCampaign'

const addPosterToCampaign = async ({
  file,
  typeField,
  campaignId,
  prevFileId
}) => {
  let data = {}
  if (campaignId) {
    if (file) {
      const mappingInfo = file && {
        typeId: campaignId,
        type: 'Campaign',
        typeField
      }
      const fileInfo = {
        fileBucket: 'python'
      }
      if (file) {
        if (prevFileId) {
          if (typeField === 'posterMobile') {
            await removePosterFromCampaign(campaignId, prevFileId, typeField).then(async () => {
              await uploadFile(file, fileInfo, mappingInfo).then(res => data = res)
            })
          } else {
            await removePosterFromCampaign(campaignId, prevFileId).then(async () => {
              await uploadFile(file, fileInfo, mappingInfo).then(res => data = res)
            })
          }
        } else {
          await uploadFile(file, fileInfo, mappingInfo).then(res => data = res)
        }
      }
    }
  }
  return data
}

export default addPosterToCampaign
