import { Select } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { StyledSelect } from '../SchoolOnBoarding.style'
// search field for school
const { Option } = Select
const SearchInput = (props) => {
  const { value, placeholder, onChange, dataArray = [], optionGrades,
    onSearch = () => { }, loading = false } = props
  const grades = []
  if (optionGrades) {
    grades.push('All')
    dataArray.map((data) => {
      grades.push(data.grade)
    })
  }
  return (
    <StyledSelect
      showSearch
      value={value}
      placeholder={placeholder}
      optionFilterProp='children'
      onChange={onChange}
      onSearch={onSearch}
      loading={loading}
      filterOption={(input, option) =>
        get(option, 'props.children')
          ? get(option, 'props.children')
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
          : false
      }
    >
      {
       optionGrades ? (
         grades.map((grade) => <Option key={grade} value={grade}>{grade}</Option>)
       ) : (
         dataArray.map((school) =>
           <Option value={get(school, 'id')} key={get(school, 'id')}>{get(school, 'name')}</Option>)
       )
      }
    </StyledSelect>
  )
}


SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dataArray: PropTypes.arrayOf({}).isRequired,
  optionGrades: PropTypes.arrayOf({}).isRequired,
}

export default SearchInput
