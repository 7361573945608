import config from '../config/serverConfig'

const getFullPath = (file, pathConfig) => {
  let filePath = file;
  if (pathConfig && pathConfig.replaceRootPath) filePath = filePath.replace(pathConfig.replaceRootPath.where, pathConfig.replaceRootPath.with);
  if (file) {
    /** if the file already has the fileBaseUrl information i.e,
     * in the case of a updated file simply return the uri else add the fileBaseUrl */
    if (filePath.includes(config.fileBaseUrl) || filePath.includes(config.cloudFrontBaseUrl)) {
      return filePath;
    }
    /** Not appending Date here helps in caching of files */
    return `${config.fileBaseUrl}/${filePath}`
  }
  return null
}

export default getFullPath
