import { notification } from 'antd'
import { format } from 'date-fns'
import { debounce, get } from 'lodash'
import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import addMentorMenteeSessionForDemand from '../../../../actions/mentorSessions/addMentorMenteeSessionForDemand'
import addMentorSession from '../../../../actions/mentorSessions/addMentorSessions'
import updateMentorSession from '../../../../actions/mentorSessions/updateMentorSession'
import { TekieGreen } from '../../../../constants/colors'
import { ClockSVG, RatioSVG, StudentsSvg, TypeSVG } from '../../../../constants/icons'
import { MENTOR } from '../../../../constants/roles'
import getDataFromLocalStorage from '../../../../utils/extract-from-localStorage'
import { getMentorSessionData } from '../../utils'
import Modal from '../Modal.styles'
import DemandAcceptModalStyle from './DemandAcceptModal.style'

const currencies = { RS: '₹', USD: '$' }

const DemandAcceptModal = ({
  isModalVisible,
  setModalVisibility,
  selectedSession,
  updateLocalEvent
}) => {
  const [loading, setLoading] = useState(false)
  //   const location = useLocation()
  const resetFields = () => {
    setLoading(false)
  }
  const location = useLocation()
  const history = useHistory()
  const savedRole = getDataFromLocalStorage('login.role')
  const closeModal = (shouldFetch = false) => {
    resetFields()
    const query = new URLSearchParams(location.search)
    const slotId = query.get('session')
    if (slotId && savedRole === MENTOR) {
      history.push('/mentorDashboard')
    }
    setModalVisibility(false, shouldFetch)
  }
  const onConfirm = async () => {
    setLoading(true)
    const sessionId = get(selectedSession, 'sessionId')
    const savedId = getDataFromLocalStorage('login.id')
    if (sessionId && savedRole === MENTOR) {
      const availabilityDate = new Date(new Date(get(selectedSession, 'startTime')).setHours(0, 0, 0, 0)).toISOString()
      const time = new Date(get(selectedSession, 'startTime')).getHours()
      const mentorSessionData = await getMentorSessionData(availabilityDate, savedId)
      const mentorSessionObj = get(mentorSessionData, 'data.mentorSessions[0]', null)
      const mentorSessionInput = {
        availabilityDate
      }
      mentorSessionInput[`slot${time}`] = true
      if (mentorSessionObj) {
        if (get(selectedSession, 'mentorAvailabilitySlotId')) {
          mentorSessionInput.acceptanceObjects = {
            replace: [{
              slotName: `slot${time}`,
              date: availabilityDate,
              mentorAvailabilitySlotId: get(selectedSession, 'mentorAvailabilitySlotId'),
              menteeSessionId: sessionId,
              requestType: 'demand'
            }]
          }
        }
        await updateMentorSession(mentorSessionInput, get(mentorSessionObj, 'id'))
          .then(async (resp) => {
            if (resp && resp.updateMentorSession) {
              addMentorMenteeSessionForDemand({
                menteeSessionConnectId: sessionId,
                mentorSessionConnectId: get(resp, 'updateMentorSession.id'),
                topicConnectId: get(selectedSession, 'record.topic.id'),
                courseConnectId: get(selectedSession, 'record.course.id')
              }).then(mmSession => {
                if (mmSession && mmSession.addMentorMenteeSession
                            && mmSession.addMentorMenteeSession.id) {
                  updateLocalEvent(mmSession.addMentorMenteeSession)
                  closeModal(true)
                  notification.success({
                    message: 'Session Assigned SuccessFully'
                  })
                }
              })
            } else {
              closeModal(false)
            }
          })
      } else {
        if (get(selectedSession, 'mentorAvailabilitySlotId')) {
          mentorSessionInput.acceptanceObjects = [{
            slotName: `slot${time}`,
            date: availabilityDate,
            mentorAvailabilitySlotId: get(selectedSession, 'mentorAvailabilitySlotId'),
            menteeSessionId: sessionId,
            requestType: 'demand'
          }]
        }
        await addMentorSession(mentorSessionInput, savedId).then(async (response) => {
          if (response && response.addMentorSession) {
            addMentorMenteeSessionForDemand({
              menteeSessionConnectId: sessionId,
              mentorSessionConnectId: get(response, 'addMentorSession.id'),
              topicConnectId: get(selectedSession, 'record.topic.id'),
              courseConnectId: get(selectedSession, 'record.course.id')
            }).then(mmSession => {
              if (mmSession && mmSession.addMentorMenteeSession
                          && mmSession.addMentorMenteeSession.id) {
                updateLocalEvent(mmSession.addMentorMenteeSession)
                closeModal(true)
                notification.success({
                  message: 'Session Assigned SuccessFully'
                })
              }
            })
          } else {
            closeModal(false)
          }
        })
      }
    }
  }
  const renderSessionDateAndSlot = (sessionData, iconComponent, style = {}) => (
    <div style={style}>
      <DemandAcceptModalStyle.Icon theme='twoTone' component={iconComponent} />
      {get(sessionData, 'startTime') && format(get(sessionData, 'startTime'), 'hh:mm a')}
      {get(sessionData, 'endTime') && ` - ${format(get(sessionData, 'endTime'), 'hh:mm a')}`}
      {' '} &bull; {' '}
      {get(sessionData, 'startTime') && get(sessionData, 'startTime').toDateString()}
    </div>
  )
  /** Render Methods */
  const renderHeaderSection = () => (
    <>
      <DemandAcceptModalStyle.Header bgColor={`${get(selectedSession, 'bgMuted')}`}>
        <DemandAcceptModalStyle.FlexContainer>
          <DemandAcceptModalStyle.HeaderSessionIndicator
            bgColor='#8C61CB'
          />
          <DemandAcceptModalStyle.PreHeaderText>
            Slot Request
          </DemandAcceptModalStyle.PreHeaderText>
          <DemandAcceptModalStyle.HeaderTag
            bgColor='#8C61CB'
          >
            REQUEST
          </DemandAcceptModalStyle.HeaderTag>
          <DemandAcceptModalStyle.CloseIcon theme='twoTone'
            onClick={() => closeModal()}
          />
        </DemandAcceptModalStyle.FlexContainer>
      </DemandAcceptModalStyle.Header>
      <DemandAcceptModalStyle.HeaderDetailsContainer>
        <DemandAcceptModalStyle.TopicThumbnail
          bgImage={get(selectedSession, 'record.topic.thumbnailSmall.uri')}
        />
        <DemandAcceptModalStyle.HeaderDetails>
        <DemandAcceptModalStyle.FlexContainer>
            <DemandAcceptModalStyle.HeaderSessionIndicator
              bgColor={TekieGreen}
            />
            <DemandAcceptModalStyle.PreHeaderText>
            B2C
            </DemandAcceptModalStyle.PreHeaderText>
        </DemandAcceptModalStyle.FlexContainer>
        <DemandAcceptModalStyle.FlexContainer>
        <DemandAcceptModalStyle.HeaderTitle>
            {get(selectedSession, 'course')}{' '}{get(selectedSession, 'topic')}
        </DemandAcceptModalStyle.HeaderTitle>
        </DemandAcceptModalStyle.FlexContainer>
          <DemandAcceptModalStyle.HeaderDescription>
            {renderSessionDateAndSlot(selectedSession, ClockSVG)}
          </DemandAcceptModalStyle.HeaderDescription>
          <DemandAcceptModalStyle.HeaderTimestamp>
            {/* Weekly on Mon from 1 Apr - 30 Jun, 2021 */}
          </DemandAcceptModalStyle.HeaderTimestamp>
        </DemandAcceptModalStyle.HeaderDetails>
      </DemandAcceptModalStyle.HeaderDetailsContainer>
      <DemandAcceptModalStyle.Divider />
    </>
  )

  const getCurrency = (currency) => currency === 'RS' ? currencies.RS : currencies.USD
  const renderModalFooter = () => (
    <DemandAcceptModalStyle.FlexContainer acceptMsg style={{ alignItems: 'center', width: '100%', padding: 0, justifyContent: 'space-between' }}>
        <DemandAcceptModalStyle.HeaderTitle acceptMsg style={{ marginTop: 0, flex: '0.9', lineHeight: '1.4' }}>
            Do you accept to take the class?
        </DemandAcceptModalStyle.HeaderTitle>
      <Modal.FlexContainer style={{ padding: 0 }}>
        <Modal.SecondaryButton
          onClick={() => closeModal()}
          style={{ marginRight: '10px' }}
        >
        Cancel
        </Modal.SecondaryButton>
      <Modal.PrimaryButton
        loading={loading}
        onClick={debounce(onConfirm, 500)}
      >
        {loading && <Modal.Spinner />}
        Accept and Earn {`${getCurrency(get(selectedSession, 'record.paySlabObj.currency', 'RS'))} ${get(selectedSession, 'record.paySlabObj.amount')}`}
      </Modal.PrimaryButton>
      </Modal.FlexContainer>
    </DemandAcceptModalStyle.FlexContainer>
  )
  const renderClassDetails = ({ type, value, icon }) => (
    <DemandAcceptModalStyle.ContentClassDetail>
      <DemandAcceptModalStyle.Icon theme='twoTone'
        component={icon}
      />
      <span className='classDetailsText'>{type}</span>
      <DemandAcceptModalStyle.Text>{ value }</DemandAcceptModalStyle.Text>
    </DemandAcceptModalStyle.ContentClassDetail>
  )
  const gradeNumber = (grade = '') => grade.replace('Grade', '') || '-'
  return (
        <Modal.WithBackdrop visible={isModalVisible}>
      <DemandAcceptModalStyle.ModalBox visible={isModalVisible}>
        {renderHeaderSection()}
        <Modal.Collapse defaultActiveKey={['SlotDetails']} collapsible='header'>
          <Modal.Panel
            key='SlotDetails'
            header={
              <>
                <DemandAcceptModalStyle.ContentText>
                  Slot Details
                </DemandAcceptModalStyle.ContentText>
              </>
            }
          >
            <DemandAcceptModalStyle.Content>
            <DemandAcceptModalStyle.FlexContainer style={{ flexDirection: 'column' }}>
            {renderClassDetails({ type: 'Student\'s Name', value: get(selectedSession, 'record.user.name'), icon: StudentsSvg })}
            {renderClassDetails({ type: 'Grade', value: gradeNumber(get(selectedSession, 'record.user.studentProfile.grade')) || '-', icon: RatioSVG })}
            {renderClassDetails({ type: 'Type', value: get(selectedSession, 'type'), icon: TypeSVG })}
            </DemandAcceptModalStyle.FlexContainer>
            </DemandAcceptModalStyle.Content>
          </Modal.Panel>
        </Modal.Collapse>
        <DemandAcceptModalStyle.FlexRow alignItems='flex-start' style={{ flexDirection: 'column', marginTop: 8 }} />
        {/* Modal Footer */}
        <Modal.Footer bgColor='#F9F9F9' padding='16px'>
          {renderModalFooter()}
        </Modal.Footer>
      </DemandAcceptModalStyle.ModalBox>
        </Modal.WithBackdrop>
  )
}

export default DemandAcceptModal
