import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import withUpdatedDashboardNav from '../../../components/withUpdatedNav'
import { EVENT_DASHBOARD } from '../../../constants/navItemsConst'
import injectProps from '../../../components/injectProps'
import Tickets from './Tickets'

const TicketsSideNav = withUpdatedDashboardNav(Tickets)({
    title: 'Event Overview',
    activeNavItem: 'Events',
    showUMSNavigation: true,
    renderNavItems: EVENT_DASHBOARD,
})

const mapStateToProps = state => ({
    commsTags: state.data.getIn(['commsVariables', 'data']),
    eventUpdateStatus: state.data.getIn(['event', 'updateStatus', 'event']),
    commsMessage: state.data.getIn(['commsMessage', 'data']),
    eventData: state.data.getIn(['events', 'data']),
    eventDataFetchStatus: state.data.getIn(['events', 'fetchStatus', 'events']),
})


const TicketsSideNavWithExtraProps = injectProps({
    notification
})(TicketsSideNav)
export default connect(mapStateToProps)(withRouter(TicketsSideNavWithExtraProps))
