import gql from 'graphql-tag'
import duck from '../../duck'

const addAdhocSession = (input, batchConnectId, topicConnectId,
  mentorSessionConnectId, courseConnectId) => (
  duck.query({
    query: gql`
      mutation ($input: AdhocSessionInput!) {
        addAdhocSession(
          input: $input,
          batchConnectId: "${batchConnectId}",
          ${topicConnectId ? `previousTopicConnectId: "${topicConnectId}"` : ''}
          mentorSessionConnectId: "${mentorSessionConnectId}",
          ${courseConnectId ? `courseConnectId: "${courseConnectId}"` : ''}
        ) {
          id
        }
      }`,
    variables: {
      input,
      callBatchAPI: true
    },
    type: 'adhocSessions/add',
    key: 'adhocSessions'
  })
)

export default addAdhocSession
