import gql from 'graphql-tag'
import duck from '../../duck'
import getIdArrForQuery from '../../utils/getIdArrForQuery'


const updateTrainingBatch = async ({ input,
    schoolConnectId,
    batchId,
    allottedMentorConnectId,
    coursePackageConnectId,
    courseConnectId, academicYearConnectId, studentIds, batchStudentsConnectIds }) =>
  duck.query({
    query: gql`
   mutation($input: BatchUpdate) {
  updateBatch(
    input: $input
    id: "${batchId}"
    schoolConnectId: "${schoolConnectId}"
    ${allottedMentorConnectId ? `allottedMentorConnectId: "${allottedMentorConnectId}"` : ''}
    ${coursePackageConnectId ? `coursePackageConnectId: "${coursePackageConnectId}"` : ''}
    ${courseConnectId ? `courseConnectId: "${courseConnectId}"` : ''}
    ${academicYearConnectId ? `academicYearConnectId: "${academicYearConnectId}"` : ''}
    ${(batchStudentsConnectIds && batchStudentsConnectIds.length) ? `batchStudentsConnectIds: [${getIdArrForQuery(batchStudentsConnectIds)}]` : ''}
    ${(studentIds && studentIds.length) ? `studentsConnectIds: [${getIdArrForQuery(studentIds)}]` : ''}
  ) {
    id
    code
    classroomTitle
    createdAt
    description
    classes {
      id
      grade
      section
    }
    allottedMentor {
      id
      name
    }
    studentsMeta {
      count
    }
    coursePackage {
      id
      title
      internalName
    }
    coursePackageTopicRule {
      topic {
        courses {
          id
          title
        }
      }
    }
  }
  }
    `,
    variables: {
      input,
    },
    type: 'batch/update',
    key: 'trainingBatches',
  })

export default updateTrainingBatch
