import styled from 'styled-components'
import {Button,Input,Select} from 'antd'
const {TextArea} =Input

const LayoutTwoDiv = styled.div`
display:flex;
flex-direction:row;
height:78%;
`
const HeadingDiv = styled.div`
width: 100%;
height:95%;
display:flex;
flex-direction:column;
justify-content:space-between;
margin:10px 0px 11px 7px;
`
const TypeHolder = styled.div`
display:flex;
flex-direction:row;
width:50%;
`
const TerminalText = styled.div`
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 13px;
text-transform: capitalize;
color: #D3D3D3;
justify-content: center;
display: flex;
align-items: center;
margin:0px 0px 0px 0px;
}
`
const ShowChat = styled.div`
font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 8px;
text-transform: capitalize;
color: #000000;
justify-content: center;
display: flex;
align-items: center;
margin: 0px 6px 0px 0px;
}
`

const StyledTextArea = styled(TextArea)`
&.ant-input {
 height:100% !important;
 border-radius:11px;
}
`
const CheckBoxHolder = styled.div`
display: flex;
justify-content: center;
align-items: center;

`
const HeadingHolder = styled.div`
display: flex;
justify-content: space-between;
flex-direction:row;
margin:0px 0px 10px 0px;
`
export {
    StyledTextArea,
    HeadingDiv,
    TypeHolder,
    TerminalText,
    ShowChat,
    CheckBoxHolder,
    LayoutTwoDiv,
    HeadingHolder
    }
    