import React, { useState } from 'react'
import Container from './Package.style'
import { StarOutlined } from '@ant-design/icons';
import { Tooltip } from 'react-bootstrap';
import updateCoursePackages from '../../../../actions/CoursePackageMaker/updateCoursePackages';
import { get } from 'lodash';
import { PUBLISHED_STATUS, UNPUBLISHED_STATUS } from '../../../../constants/questionBank';
import { useHistory } from 'react-router-dom';
import ModalConfirm from '../ModalConfirm/ModalConfirm';

const Package = ({ packageInfo, packageId, status, ...props }) => {
    const [showPublishModal, setShowPublishModal] = useState(false)
    const history = useHistory()
    const handlePublishStatus = async () => {
        let inputObj = {
            status: UNPUBLISHED_STATUS
        }
        if (status === UNPUBLISHED_STATUS) {
            inputObj = {
                status: PUBLISHED_STATUS,
            }
        }
        await updateCoursePackages({ packageId, inputObj })
        setShowPublishModal(false)
    }
    const checkStatus = () => status === PUBLISHED_STATUS
    const getTooltipOrText = (type) => {
        let substrNum = 30
        if (type === 'description') substrNum = 35
        if (get(packageInfo, type)) {
            if (get(packageInfo, type).length < substrNum) {
                return get(packageInfo, type)
            }
            return (
                <Tooltip placement='left' title={get(packageInfo, type)}>{get(packageInfo, type).substring(0, substrNum)}...</Tooltip>
            )
        }
    }
    const getCourseText = () => {
        if (get(packageInfo, 'packageCourses', []).length > 3) {
            return (
                <Tooltip title={get(packageInfo, 'packageCourses', []).map(course => get(course, 'title'))} >
                    {get(packageInfo, 'packageCourses', []).filter((course, ind) => ind <= 3 && course).map(course => (
                        <Container.CourseButton key={get(course, 'id')}>{get(course, 'title')}</Container.CourseButton>
                    ))}
                </Tooltip>
            )
        }
        return get(packageInfo, 'packageCourses', []).map(course => (
            <Container.CourseButton key={get(course, 'id')}>{get(course, 'title')}</Container.CourseButton>
        ))
    }
    const handleSwitchClick = () => {
        setShowPublishModal(true)
    }
    return (
        <Container>
            <Container.FlexContainer justify='space-between'>
                <Container.FlexContainer
                    align='center'
                    onClick={() => history.push(`/coursePackages/${get(packageInfo, 'id')}`)}
                    style={{ cursor: 'pointer', flexGrow: '1', padding: '15px 0 0 15px' }}
                >
                    <Container.FlexContainer direction='column'>
                        <Container.InternalName>
                            {getTooltipOrText('internalName')}
                        </Container.InternalName>
                        <Container.PackageName>
                            {getTooltipOrText('title')}
                        </Container.PackageName>
                    </Container.FlexContainer>
                </Container.FlexContainer>
                <Tooltip title='change publish status'>
                    <Container.SwitchButton
                        defaultChecked
                        checked={checkStatus()}
                        onClick={() => handleSwitchClick()}
                        size="small"
                        style={{ margin: '15px 15px 0 5px' }}
                    />
                </Tooltip>
            </Container.FlexContainer>
            <Container.FlexContainer
                direction='column'
                onClick={() => history.push(`/coursePackages/${get(packageInfo, 'id')}`)}
                style={{ cursor: 'pointer', padding: '15px', minHeight: '300px' }}
            >
                <Container.InfoContainer>
                    <StarOutlined className='star info-constant' />
                    <div>
                        <p className='info-constant'>Description</p>
                        <p className='info-variable'>
                            {getTooltipOrText('description')}
                        </p>
                    </div>
                </Container.InfoContainer>
                <Container.InfoContainer>
                    <StarOutlined className='star info-constant' />
                    <div>
                        <p className='info-constant'>Total Sessions</p>
                        <p>{get(packageInfo, 'packageTopics', []).length}</p>
                    </div>
                </Container.InfoContainer>
                <Container.InfoContainer>
                    <StarOutlined className='star info-constant' />
                    <div>
                        <p className='info-constant'>Courses</p>
                        <Container.FlexContainer>
                            {getCourseText()}
                        </Container.FlexContainer>
                    </div>
                </Container.InfoContainer>
            </Container.FlexContainer>
            <ModalConfirm 
                showModal={showPublishModal}
                onCancel={() => setShowPublishModal(false)}
                heading='Change Package Publish Status'
                main={`Are you sure you want to change publish status of ${get(packageInfo, 'title', '')} ?`}
                confirm='Change Publish Status'
                onConfirm={handlePublishStatus}
            />
        </Container>
    )
}

export default Package
