import gql from 'graphql-tag'
import duck from '../../duck';
import moment from 'moment';
import { get } from 'lodash';

const fetchEventPreview = async (id) => {
    await duck.query({
        query: gql`
            query {
                event(id:"${id}") {
                  id
                  name
                  status
                  timeZone
                  dateType
                  shouldSendCanceledComms
                  shouldSendRescheduledComms
                  rescheduledReason
                  cancellationReason
                  isEmailCommsEnabled
                  name
                  summary
                  overview
                  eventBanner {
                    id
                    uri
                  }
                  speakers{
                      id
                  }
                  prizes {
                    id
                    image {
                        id
                        uri
                    }
                    minRank
                    maxRank
                    title
                  }
                  eventJoinReasons {
                    id
                    picture {
                        id
                        uri
                    }
                    title
                  }
                  listingImage {
                    id
                    uri
                  }
                  tags(filter: { isEventTag: true }) {
                    id
                    title
                  }
                  category {
                    id
                    title
                  }
                  registeredUsersMeta {
                    count
                  }
                  eventTimeTableRule {
                        slot0
                        slot1
                        slot2
                        slot3
                        slot4
                        slot5
                        slot6
                        slot7
                        slot8
                        slot9
                        slot10
                        slot11
                        slot12
                        slot13
                        slot14
                        slot15
                        slot16
                        slot17
                        slot18
                        slot19
                        slot20
                        slot21
                        slot22
                        slot23
                    }
                    eventCommsRule {
                        templateName
                        isTested
                        attendanceFilter
                        unit
                        value
                        condition
                        isTested
                        isPassed
                        isEmailRule
                        commsVariables {
                            dataField
                            whatsappVariableName
                            emailVariableName
                        }
                    }
                    commsVariables {
                        id
                        whatsappVariableName
                        emailVariableName
                        dataField
                    }
                    ticketName
                    ticketregistrationLimit
                    embeds {
                        id
                        xDim
                        yDim
                        red
                        green
                        blue
                        rotate
                        textSize
                        fontFamily
                        variableName
                    }
                    utm {
                        utmTerm
                        utmSource
                        utmCampaign
                        utmMedium
                        utmContent
                        webUrl
                    }
                    baseCertificate {
                        id
                        uri
                    }
                }
                sameEvent: event(id:"${id}") {
                    id
                    eventTimeTableRule {
                        startDate
                        endDate
                        monday
                        tuesday
                        wednesday
                        thursday
                        friday
                        saturday
                        sunday
                    }
                } 
            }
        `,
        type: 'events/fetch',
        key: 'events',
        changeExtractedData: (extracted, original) => {
            if (get(original, 'event.id')) {
                const slots = get(original, 'event.eventTimeTableRule')
                const dateType = get(original, 'event.dateType')
                const slotsLength = Object.keys(slots).length
                let startTime
                let endTime
                let startHour
                let endHour
                extracted.commsVariables = []
                for (let i = 0; i < slotsLength; i++) {
                    if (slots[`slot${i}`]) {
                        startTime = moment().set('hour', i)
                        endTime = moment(startTime).add(1, 'hour')
                        startHour = i
                        endHour = (i === 23) ? 0 : i + 1
                        break
                    }
                }
                const dateAndWeekTable = get(original, 'sameEvent.eventTimeTableRule')
                const selectedDateRange = {
                    startDate: moment(get(dateAndWeekTable, 'startDate')),
                    endDate: moment(get(dateAndWeekTable, 'endDate'))
                }
                const timeValues = { startTime, endTime }
                const selectedTimeRange = { startHour, endHour }
                let selectedWeekDays = []
                for (let key in dateAndWeekTable) {
                    if ((key !== 'startDate' || key !== 'endDate') && dateAndWeekTable[key]) {
                        if (key === 'monday') selectedWeekDays.push('M')
                        if (key === 'tuesday') selectedWeekDays.push('T')
                        if (key === 'wednesday') selectedWeekDays.push('W')
                        if (key === 'thursday') selectedWeekDays.push('Th')
                        if (key === 'friday') selectedWeekDays.push('F')
                        if (key === 'saturday') selectedWeekDays.push('Sa')
                        if (key === 'sunday') selectedWeekDays.push('S')
                    }
                }
                const joinReasons = get(original, 'event.eventJoinReasons')
                extracted.events = {
                    ...extracted.events,
                    eventJoinReasons: joinReasons,
                    selectedDateRange,
                    selectedTimeRange,
                    selectedWeekDays,
                    timeValues,
                    recurring: dateType === "recurring" ? true : false,
                    oneDay: dateType === "recurring" ? false : true,
                    commsVariables: get(original, 'event.commsVariables')
                }
            }
            return { ...extracted }
        }
    })
}

export default fetchEventPreview