import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const fetchTeachers = async (schoolId) => duck.query({
  query: gql`
  {
    users(
      filter:{
        and: [
            {role: mentor},
            {secondaryRole: schoolTeacher}
          	{mentorProfile_some:{schools_some: {id: "${schoolId}"}}}
        ]
      }
    ){
      id
      name
    }
  }
  `,
  type: 'teachers/fetch',
  key: 'teachers',
  changeExtractedData: (extractedData, originalData) => {
    extractedData.teachers = get(originalData, 'users');
    return { ...extractedData }
  }
})

export default fetchTeachers
