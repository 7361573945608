import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import withNav from '../../components/withNav'
import injectProps from '../../components/injectProps'
import CoursePackageMaker from './CoursePackageMaker'
const CoursePackageMakerNav = withNav(CoursePackageMaker)({
  title: 'Course Packages',
  activeNavItem: 'Course Packages',
  showCourseMakerNavigation: true,
})
const CoursePackageMakerNavWithExtraProps = injectProps({
  notification,
})(CoursePackageMakerNav)
const mapStateToProps = (state) => ({
  fetchCoursePackages: state.data.getIn(['coursePackages', 'fetchStatus', 'coursePackages']),
  coursePackagesData: state.data.getIn(['coursePackages', 'data']),
  fetchCoursePackagesMeta: state.data.getIn(['coursePackagesMeta', 'fetchStatus', 'coursePackagesMeta']),
  coursePackagesMetaData: state.data.getIn(['coursePackagesMeta', 'data', 'count']),
  updateCoursePackagesStatus: state.data.getIn(['coursePackages', 'updateStatus', 'coursePackages']),
  packageUpdateFailure: state.data.getIn(['errors', 'coursePackages/update']),
  deleteCoursePackagesStatus: state.data.getIn(['coursePackages', 'deleteStatus', 'coursePackages']),
  packageDeleteFailure: state.data.getIn(['errors', 'coursePackages/delete']),

})
export default connect(mapStateToProps)(withRouter(CoursePackageMakerNavWithExtraProps))
