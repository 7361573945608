import React from 'react'
import Modal from 'react-modal'
import SVGIcon from '../../../../assets/icons'
import Main from '../../Upload.style'
import deleteFile from '../../../../actions/upload/deleteFile'

Modal.setAppElement('#root')
const DeleteModal = ({ id, closeModal, file }) => {
  const FileDelete = async () => {
    await deleteFile(id)
    closeModal(false)
  }

  return (
  <Modal
    isOpen
    onRequestClose={() => closeModal(false)}
    style={
        {
            overlay: {
                background: 'rgba(0, 23, 31, 0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            content: {
                color: 'white',
                width: '600px',
                height: '190px',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                padding: '0px',
                borderRadius: '15px',
                overflow: 'hidden'
            }
        }
    }
  >
    <Main.Title>
      <SVGIcon.DeleteIcon color='white' />
        DELETE ALERT
    </Main.Title>
    <Main.Paragraph>
        Are you sure you wanna delete the file name“{file.slice(0, 30)}”
    </Main.Paragraph>
    <Main.SaveButton>
      <Main.ConfirmButton
        style={{ color: '#fff' }}
        onClick={FileDelete}
      >CONFIRM DELETE
      </Main.ConfirmButton>,
      <Main.Cancel type='primary' onClick={() => closeModal(false)} ghost>CANCEL</Main.Cancel>
    </Main.SaveButton>
  </Modal>
  )
}
export default DeleteModal
