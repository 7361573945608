import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const addUtmDetails = async (leadPartnerAgentConnectId, utmDetails) => duck.query({
  query: gql`
  mutation {
    addUtmDetail(leadPartnerAgentConnectId: "${leadPartnerAgentConnectId}", input: { 
    ${get(utmDetails, 'source') ? `source: "${get(utmDetails, 'source')}"` : ''},
    ${get(utmDetails, 'content') ? `content: "${get(utmDetails, 'content')}"` : ''},
    ${get(utmDetails, 'campaign') ? `campaign: "${get(utmDetails, 'campaign')}"` : ''},
    ${get(utmDetails, 'term') ? `term: "${get(utmDetails, 'term')}"` : ''},
    ${get(utmDetails, 'medium') ? `medium: "${get(utmDetails, 'medium')}"` : ''}
  }) {
      source
      content
      campaign
      term
      medium
    }
  }  
    `,
  type: 'utm/add',
  key: 'utm',
})

export default addUtmDetails
