import gql from 'graphql-tag'
import duck from '../../../duck'

const fetchCourses = async (filterString="") =>{
  return duck.query({
    query: gql`
    {
        courses(
          filter:{
            and:[
              ${filterString}
            ]
          }
        ) {
            id
            title
            description
            category
            status
            order
            minGrade
            maxGrade
            bannerTitle
            bannerDescription
            secondaryCategory
            documentationUrl
            contentType
            theory{
              value
            }
            programming{
              value
            }
            tools{
              value
            }
            codingLanguages {
              value
            }
            theme {
              primaryColor
              secondaryColor
              backdropColor
            }
            targetGroup {
              type
            }
            courseComponentRule {
              componentName
              order
              min
              max
            }
            defaultLoComponentRule {
              componentName
              order
            }
            thumbnail {
              id
              uri
            }
            bannerThumbnail {
              id
              uri
            }
            thumbnailSmall {
              id
              uri
            }
            createdAt
            javaEditorUrl
        }
        coursesMeta(
          filter:{
            and:[
              ${filterString}
            ]
          }
        ) {
          count
        }
    }
    `,
    type: 'courses/fetch',
    key: 'courses',
  })
}

export default fetchCourses

