import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import withNav from '../../components/withNav'
import injectProps from '../../components/injectProps'
import MentorRating from './MentorRating'

const MentorRatingNav = withNav(MentorRating)({
  title: 'Mentor Rating',
  activeNavItem: 'Mentor Rating',
  showUMSNavigation: true,
})

const mapStateToProps = (state) => ({
  mentorRatings: state.data.getIn(['mentorRatingsData', 'data']),
  hasRatingsFetched: state.data.getIn([
    'mentorRatings',
    'fetchStatus',
    'mentorRatings',
    'success',
  ]),
  isRatingsFetching: state.data.getIn([
    'mentorRatings',
    'fetchStatus',
    'mentorRatings',
    'loading',
  ])
})

const MentorRatingNavWithExtraProps = injectProps({
  notification,
})(MentorRatingNav)

export default connect(mapStateToProps)(
  withRouter(MentorRatingNavWithExtraProps)
)
