import React from "react";

const RedCrossIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM9.5757 9.57572C9.81002 9.34141 10.1899 9.34141 10.4242 9.57572L12 11.1515L13.5757 9.57574C13.81 9.34142 14.1899 9.34142 14.4242 9.57574C14.6585 9.81005 14.6585 10.19 14.4242 10.4243L12.8485 12L14.4242 13.5757C14.6585 13.81 14.6585 14.1899 14.4242 14.4242C14.1899 14.6585 13.81 14.6585 13.5757 14.4242L12 12.8485L10.4242 14.4242C10.1899 14.6586 9.81003 14.6586 9.57572 14.4242C9.3414 14.1899 9.3414 13.81 9.57572 13.5757L11.1514 12L9.5757 10.4242C9.34139 10.1899 9.34139 9.81003 9.5757 9.57572Z"
        fill="#D34B57"
      />
    </svg>
  );
};

export default RedCrossIcon;
