import { LoadingOutlined } from '@ant-design/icons'
import { get } from 'lodash'
import React from 'react'
import { addSchoolClass, addTrainingBatch, deleteSchoolClasses, fetchBatchesForClass, fetchStudentsProfile, updateTrainingBatch } from '../../../../actions/SchoolOnboarding'
import sections from '../../../../constants/sections'
import getGrades from '../../../../utils/getGrades'
import AddGradeSections from './components/AddGradeSections'
import Class from './components/Class'
import { FlexContainer, PrimaryButton } from './Classrooms.styles'
import { notification } from 'antd'
import EditClassroom from './components/EditClassroom'
import getTrainingBatchCode from '../school-utils/getTrainingBatchCode'
import updateBatchCurrentComponentStatus from '../../../../actions/CoursePackageMaker/updateBatchCurrentComponentStatus'
import removeFromBatchCoursePackageCourses from '../../../../actions/CoursePackageMaker/removeFromBatchCoursePackageCourses'
import removeBatchStudents from '../../../../actions/CoursePackageMaker/removeBatchStudents'
import deleteBatch from '../../../../actions/batchDashboard/deleteBatch'
import fetchClassroomGrades from '../../../../actions/SchoolOnboarding/fetchClassroomGrades'
import fetchClassroomBatches from '../../../../actions/SchoolOnboarding/fetchClassroomBatches'
import fetchClassroomCoursePackages from '../../../../actions/SchoolOnboarding/fetchClassroomCoursePackages'
import fetchClassroomTeachers from '../../../../actions/SchoolOnboarding/fetchClassroomTeachers'
import removeCoursePackageCoursesFromBatch from '../../../../actions/SchoolOnboarding/removeCoursePackageCoursesFromBatch.js'

class Classrooms extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        addGradeModalVisible: false,
        isMultiSelect: false,
        addSectionFor: null,
        editClassroomModalVisible: false,
        selectedGradeSection: {},
        coursePackages: [],
        schoolTrainers: [],
        classStudents: [],
        addBatchLoading: false,
        classroomBatchesData: [],
        classGradeList: [],
        isLoadingData: false,
        classroomGradesData: [],
        allSections: [],
      }
    }

    componentDidMount() {
        this.fetchBatchesData()
    }

    componentDidUpdate(prevProps, prevState) {
        const { editClassroomModalVisible, coursePackages, schoolTrainers, classStudents, selectedGradeSection,
            classroomBatchesData, addBatchLoading, classroomGradesData,  } = this.state
        const { classroomCoursePackagesFetchStatus, classroomTeachersFetchStatus, schoolId, studentProfilesFetchStatus,
            studentProfiles, classroomBatchesFetching, classroomBatches, classroomGradesFetchStatus,
            classroomGrades, schoolClassesDeleteStatus, schoolClassesAddStatus, classroomTeachers,
            academicYearConnectId, schoolClassAddFailure } = this.props
        if (classroomBatchesData !== prevState.classroomBatchesData || classroomGradesData !== prevState.classroomGradesData) {
            this.setState({ isLoadingData: true })
            this.setClassList()
            this.setState({ isLoadingData: false })
        }
        if (classroomBatchesFetching && !get(classroomBatchesFetching.toJS(), 'loading')
            && get(classroomBatchesFetching.toJS(), 'success') &&
            (prevProps.classroomBatchesFetching !== classroomBatchesFetching)) {
                const classroomBatchesDataTemp = classroomBatches && classroomBatches.toJS()
                this.setState({ classroomBatchesData: classroomBatchesDataTemp })
        }
        if (classroomCoursePackagesFetchStatus && !get(classroomCoursePackagesFetchStatus.toJS(), 'loading')
            && get(classroomCoursePackagesFetchStatus.toJS(), 'success') &&
              (prevProps.classroomCoursePackagesFetchStatus !== classroomCoursePackagesFetchStatus)) {
            let { coursePackagesData } = this.props
            coursePackagesData = coursePackagesData && coursePackagesData.toJS()
            if (coursePackagesData && Array.isArray(coursePackagesData)){
                this.setState({ coursePackages: coursePackagesData })
            }
        }
        if (classroomTeachersFetchStatus && !get(classroomTeachersFetchStatus.toJS(), 'loading')
            && get(classroomTeachersFetchStatus.toJS(), 'success') &&
                (prevProps.classroomTeachersFetchStatus !== classroomTeachersFetchStatus)) {
            const classroomTeachersTemp = classroomTeachers && classroomTeachers.toJS()
            if (classroomTeachersTemp && Array.isArray(classroomTeachersTemp)){
                this.setState({ schoolTrainers: classroomTeachersTemp })
            }
        }
        if (studentProfilesFetchStatus && !get(studentProfilesFetchStatus.toJS(), "loading") &&
            get(studentProfilesFetchStatus.toJS(), "success") &&
            prevProps.studentProfilesFetchStatus !== studentProfilesFetchStatus
          ) {
            let studentProfileTemp = studentProfiles && studentProfiles.toJS()
            const { grade, section } = selectedGradeSection
            studentProfileTemp = studentProfileTemp.filter(item => (get(item, 'grade') === grade && get(item, 'section') === get(section, 'section')))
            const studentProfilesData = studentProfileTemp.map((data) => {
                const { studentName, parentName, phone, email, rollNo } = data;
                return {
                  ...data,
                  studentName: studentName || "-",
                  parentName: parentName || "-",
                  phone: phone || "-",
                  email: email || "-",
                  rollNo: rollNo || "-",
                };
            })
            this.setState({ classStudents: studentProfilesData })
          }
        if (prevState.editClassroomModalVisible !== editClassroomModalVisible && editClassroomModalVisible && selectedGradeSection !== prevState.selectedGradeSection) {
            if (!coursePackages.length) {
                const grade = this.getGrade(get(selectedGradeSection, 'grade'))
                fetchClassroomCoursePackages(grade)
            }
            if (!schoolTrainers.length) {
                fetchClassroomTeachers({ schoolId })
            }
            if (!classStudents.length) {
                const chosenGrade = get(selectedGradeSection, 'grade')
                const selectedSection = get(selectedGradeSection, 'section.section')
                fetchStudentsProfile({
                    schoolId: schoolId,
                    selectedGrade: chosenGrade,
                    section: selectedSection,
                    academicYearConnectId
                });
            }
        }
        if ((schoolId !== prevProps.schoolId) || (academicYearConnectId !== prevProps.academicYearConnectId)) {
            this.fetchBatchesData()
        }
        if (classroomGradesFetchStatus && !get(classroomGradesFetchStatus.toJS(), 'loading')
            && get(classroomGradesFetchStatus.toJS(), 'success') &&
            (prevProps.classroomGradesFetchStatus !== classroomGradesFetchStatus)) {
                const classroomGradesTemp = classroomGrades && classroomGrades.toJS()
                this.setState({ classroomGradesData: classroomGradesTemp })
        }
        if (schoolClassesAddStatus && !get(schoolClassesAddStatus.toJS(), 'loading')
            && get(schoolClassesAddStatus.toJS(), 'success') &&
            (prevProps.schoolClassesAddStatus !== schoolClassesAddStatus)) {
            notification.success({
                message: 'Grade Section added successfully'
            })
            this.fetchBatchesData()
        } else if (schoolClassesAddStatus && !get(schoolClassesAddStatus.toJS(), 'loading')
            && get(schoolClassesAddStatus.toJS(), 'failure') &&
            (prevProps.schoolClassAddFailure !== schoolClassAddFailure)) {
            if (schoolClassAddFailure && schoolClassAddFailure.toJS().length > 0) {
                notification.error({
                    message: get(get(schoolClassAddFailure.toJS()[0], 'error').errors[0], 'message')
                })
            }
        }
        if (schoolClassesDeleteStatus && !get(schoolClassesDeleteStatus.toJS(), 'loading')
            && get(schoolClassesDeleteStatus.toJS(), 'success') &&
            (prevProps.schoolClassesDeleteStatus !== schoolClassesDeleteStatus)) {
            notification.success({
                message: 'Section deleted successfully'
            })
            this.fetchBatchesData()
        }
        if (addBatchLoading !== prevState.addBatchLoading) {
            if (editClassroomModalVisible && !addBatchLoading) {
                this.setState({ editClassroomModalVisible: false, classStudents: [], coursePackages: [] }, () => {
                    this.fetchBatchesData()
                })
            }
        }
    }

    getGrade = (grade) => {
        return grade.length > 5 && Number(grade.slice(5))
    }

    getSection = (section) => {
        return sections.indexOf(section)
    }

    getSortedList = (grades) => {
        grades.sort((a, b) => {
            if (this.getGrade(get(a, 'grade')) < this.getGrade(get(b, 'grade'))) {
              return -1;
            }
            if (this.getGrade(get(a, 'grade')) > this.getGrade(get(b, 'grade'))) {
              return 1;
            }
            return 0;
        });
        const sorted = []
        grades.forEach(grade => {
            const sections = get(grade, 'sections', [])
            sections.sort((a, b) => {
                if (this.getSection(get(a, 'section')) < this.getSection(get(b, 'section'))) {
                  return -1;
                }
                if (this.getSection(get(a, 'section')) > this.getSection(get(b, 'section'))) {
                  return 1;
                }
                return 0;
            });
            sorted.push(grade)
        })
        return sorted
    }

    setClassList = () => {
        const { classroomBatchesData, classroomGradesData } = this.state
        const classroomBatchesDataTemp = [...classroomBatchesData]
        const fileteredclassroomBatches = classroomBatchesDataTemp.filter(item => get(item, 'classes', []).length)
        const tempClassGrades = [...classroomGradesData]
        if (fileteredclassroomBatches.length) {
            const finalTempGrade = tempClassGrades.length > 0 ? [...tempClassGrades] : []
            fileteredclassroomBatches.forEach(batches => {
                const classes = get(batches, 'classes', [])
                classes.length && classes.forEach(item => {
                    const grade = get(item, 'grade')
                    const sectionId = get(item, 'id')
                    tempClassGrades.length && tempClassGrades.forEach((classGrade, index) => {
                        if (get(classGrade, 'grade') === grade) {
                            const sections = get(classGrade, 'sections', [])
                            sections.length && sections.forEach((section, sectionIndex) => {
                                if (get(section, 'id') === sectionId) {
                                    const batchesData = get(finalTempGrade[index].sections[sectionIndex], 'batchData', [])
                                    const obj = {
                                        ...section,
                                        batchData: [...batchesData, batches]
                                    }
                                    finalTempGrade[index].sections[sectionIndex] = obj
                                }
                            })
                        }
                    })
                })
            })
            if(finalTempGrade.length > 0) {
                const classGradeList = this.getSortedList(finalTempGrade)
                this.setState({ classGradeList: classGradeList }, () => this.setState({ isLoadingData: false }))
            }
        } else {
            const classGradeList = this.getSortedList(classroomGradesData)
            this.setState({ classGradeList: classGradeList }, () => this.setState({ isLoadingData: false }))
        }
    }

    fetchBatchesData = async () => {
        const { schoolId, academicYearConnectId } = this.props
        if (schoolId && academicYearConnectId) {
            await fetchClassroomBatches({ schoolId, academicYearConnectId })
            await fetchClassroomGrades(schoolId, academicYearConnectId)
        }
    }

    doesGradeAlreadyExist = (gradesSections) => {
        const { classroomGradesData } = this.state
        for (let i=0; i<gradesSections.length; i++) {
            const doesExist = classroomGradesData.find(item => get(item, 'grade') === `Grade${get(gradesSections[i], 'grade')}`)
            if (doesExist) {
                return true
            }
        }
    }

    addGradeSections = async({ gradesSections, addSectionFor }) => {
        const { academicYearConnectId } = this.props
        const academicYearsConnectIds = [academicYearConnectId]
        if (addSectionFor) {
            this.addSection({ gradesSections })
        } else {
            const { schoolId } = this.props
            let addSchoolClassQuery = ''
            let querycount = 0
            if (this.doesGradeAlreadyExist(gradesSections)) {
                notification.error({ message: 'Grade Already Exist' })
            } else {
                gradesSections.forEach(gradesSection => {
                    const classGrade = get(gradesSection, 'grade')
                    const classSection = get(gradesSection, 'section')
                    const start = get(classSection, 'start')
                    const end = get(classSection, 'end')
                    const startIndex = sections.indexOf(start)
                    const endIndex = sections.indexOf(end)
                    const fileteredSections = sections.slice(startIndex, endIndex+1)
                    const grades = getGrades()
                    fileteredSections.forEach(data => {
                        querycount += 1
                        let input = `grade: ${grades[classGrade-1]}, section: ${data},`
                        addSchoolClassQuery += `
                        addSchoolClassQuery_${querycount}: 
                        addSchoolClass(
                            input: { ${input} },
                            schoolConnectId: "${schoolId}"
                            academicYearsConnectIds: "${academicYearsConnectIds}"
                        ) {
                            id
                            grade
                            section
                            gradeDisplayName
                            sectionDisplayName
                        }`
                    })
                })
                await addSchoolClass(addSchoolClassQuery)
                this.setState({ addGradeModalVisible: false, isMultiSelect: false })
            }
        }
    }

    doesSectionAlreadyExist = (grade, section) => {
        const { classroomGradesData } = this.state
        const doesExist = classroomGradesData.find(item => get(item, 'grade') === `Grade${grade}`)
        const classSections = get(doesExist, 'sections', [])
        for (let i=0; i<classSections.length; i++) {
            if (get(classSections[i], 'section') === section) {
                return true
            }
        }
    }

    addSection = async({ gradesSections }) => {
        const { academicYearConnectId } = this.props
        const academicYearsConnectIds = [academicYearConnectId]
        const section = get(gradesSections, '[0].section')
        const grade = get(gradesSections, '[0].grade')
        if (this.doesSectionAlreadyExist(grade, section)) {
            notification.error({ message: 'Section Already Exist' })
        } else {
            const { schoolId } = this.props
            const grades = getGrades()
            const addSchoolClassQuery = `
                addSchoolClass(
                    input: { grade: ${grades[grade-1]}, section: ${section} },
                    schoolConnectId: "${schoolId}",
                    academicYearsConnectIds: "${academicYearsConnectIds}"
                ) {
                    id
                    grade
                    section
            }`
            await addSchoolClass(addSchoolClassQuery)
            this.setState({
                addSectionFor: null,
                addGradeModalVisible: false,
            })
        }
    }

    handleAddSection = ({ grade }) => {
        this.setState({
            addSectionFor: grade,
            addGradeModalVisible: true,
        })
    }

    openEditClassroom = ({ grade, section, sections }) => {
        const obj = {
            grade,
            section
        }
        this.setState({
            selectedGradeSection: obj,
            editClassroomModalVisible: true,
            allSections: sections,
        })
    }

    handleDeleteSection = async() => {
        const { selectedGradeSection } = this.state
        const { section } = selectedGradeSection
        const classId = get(section, 'id')
        const batchesData = get(selectedGradeSection, 'section.batchData', [])
        let doesStudentsExist = false
        for (let i=0; i<batchesData.length; i++) {
            const batch = batchesData[i]
            if (get(batch, 'students') > 0) {
                doesStudentsExist = true
                break
            }
        }
        if (doesStudentsExist) {
            notification.error({
                message: 'Please remove students to delete classroom'
            })
        } else {
            for (let i=0; i<batchesData.length; i++) {
                const batch = batchesData[i]
                await deleteBatch(batch.id)
            }
            const res = await deleteSchoolClasses([classId])
            if (get(res, 'deleteSchoolClasses', []).length) {
                this.handleCloseEditClassroom()
            } else {
                notification.error({ message: 'Something went wrong' })
            }
        }
    }

    handleCloseEditClassroom = () => {
        this.setState({
            editClassroomModalVisible: false,
            classStudents: [],
            coursePackages: [],
        })
    }

    getTeachersArray = (courseMap) => {
        const teacherMap = {}
        courseMap.length && courseMap.forEach(item => {
            const teacher = get(item, 'teacher')
            const course = get(item, 'course')
            if (teacherMap[teacher]) {
                teacherMap[teacher] = [...teacherMap[teacher], course]
            } else {
                teacherMap[teacher] = [course]
            }
        })
        return teacherMap
    }

    handleRemainingSections = async({ classDetails, multipleTeacherMapInitial, coursePackageCourses }) => {
        const { allSections, selectedGradeSection } = this.state
        const { coursePackageSameForAllClasses, teachersSameForAllClasses } = classDetails;
        const { section } = selectedGradeSection
        if (coursePackageSameForAllClasses || teachersSameForAllClasses) {
            for (let classSection of allSections) {
                if (get(section, 'id') !== get(classSection, 'id')) {
                    const batchData = get(classSection, 'batchData', [])
                    if (!batchData.length) {
                        await this.submitClassDetails({ classDetails, multipleTeacherMapInitial, coursePackageCourses, section: classSection, coursePackageSameForAllClasses, teachersSameForAllClasses })
                    }
                }
            }
        }
    }

    handleEditClassroomDetails = async({ classDetails, multipleTeacherMapInitial, coursePackageCourses }) => {
        const { selectedGradeSection } = this.state
        const { section } = selectedGradeSection
        this.setState({ addBatchLoading: true })
        await this.submitClassDetails({ classDetails, multipleTeacherMapInitial, coursePackageCourses, section })
        await this.handleRemainingSections({ classDetails, multipleTeacherMapInitial, coursePackageCourses })
        this.setState({ addBatchLoading: false })
    }

    submitClassDetails = async({ classDetails, multipleTeacherMapInitial, coursePackageCourses, section, coursePackageSameForAllClasses, teachersSameForAllClasses  }) => {
        const { schoolId, schoolCode } = this.props
        const { coursePackages, selectedGradeSection } = this.state
        const { displayName, coursePackage, mentor, students, batchId, multipleTeacherMap } = classDetails;
        const selectedCoursePackage = coursePackages.find(cp => get(cp, 'id') === coursePackage)
        const courseConnectId = get(selectedCoursePackage, 'coursesData[0].id')
        const batchData = get(section, 'batchData', [])
        if (batchData.length) {
            //if batches created
            let getMasterBatch = batchData.find(item => get(item, 'inheritedFrom') === null)
            if (getMasterBatch) {
                getMasterBatch = batchData[0]
            }
            if (get(getMasterBatch, 'coursePackage.id') !== coursePackage) {
                // if batches are created and multiple teacher are assigned and we change the course package
                let newTeacherCourseMapping = this.getTeachersArray(multipleTeacherMap)
                if (Object.keys(newTeacherCourseMapping).length === 0) {
                    await this.updateClassroomBatch(classDetails, null, null, null)
                }
                else if (Object.keys(newTeacherCourseMapping).length === 1) {
                    // if only one teacher is selected then we update master batch with selected course package and delete all previously created batches
                    const teacher = Object.keys(newTeacherCourseMapping)[0]
                    //changed loop format here
                    if (batchData.length) {
                        for (const batch of batchData) {
                            if (get(batch, 'id') === get(getMasterBatch, 'id')) {
                                const courses = newTeacherCourseMapping[teacher]
                                const mentorId = this.getTeacherId(teacher)
                                const coursePackageCoursesConnectIds = this.getCoursesId(courses, coursePackage)
                                await this.updateClassroomBatch(classDetails, mentorId, coursePackageCoursesConnectIds, getMasterBatch)
                            } else {
                                await removeBatchStudents(batch.id)
                                await deleteBatch(batch.id)
                            }
                        }
                    }
                } else {
                    /* 
                        if multiple teachers are selected then we update the master batch with selected course topics,
                        and create new batches for other selected teacher and delete all previously created batches
                    */
                    let position = 0
                    if (Object.keys(newTeacherCourseMapping).length) {
                        for (const teacher in newTeacherCourseMapping) {
                            const courses = newTeacherCourseMapping[teacher]
                            const mentorId = this.getTeacherId(teacher)
                            const coursePackageCoursesConnectIds = this.getCoursesId(courses, coursePackage)
                            if (position==0) {
                                await this.updateClassroomBatch(classDetails, mentorId, coursePackageCoursesConnectIds, getMasterBatch)
                            } else {
                                await this.addClassroomBatch(classDetails, mentorId, coursePackageCoursesConnectIds, getMasterBatch, section)
                            }
                            position += 1
                        }
                    }
                    if (batchData.length) {
                        for(const batch of batchData) {
                            if (get(batch, 'id') !== get(getMasterBatch, 'id')) {
                                await removeBatchStudents(batch.id)
                                await deleteBatch(batch.id)
                            }
                        }
                    }
                }
            } else {
                //if course package is not upadted then we create old teacher to course mapping and new teacher to course mapping and perform add update or delete batches operation
                if (batchData.length === 1) {
                    if (multipleTeacherMap.length) {
                        if (multipleTeacherMap.length === 1) {
                            await this.updateClassroomBatch(classDetails)
                        } else {
                            let newTeacherCourseMapping = this.getTeachersArray(multipleTeacherMap)
                            let oldTeacherCourseMapping = this.getTeachersArray(multipleTeacherMapInitial)
                            const firstTeacher = multipleTeacherMapInitial[0].teacher
                            if (newTeacherCourseMapping[firstTeacher]) {
                                for (const teacher in newTeacherCourseMapping) {
                                    const courses = newTeacherCourseMapping[teacher]
                                    const mentorId = this.getTeacherId(teacher)
                                    const coursePackageCoursesConnectIds = this.getCoursesId(courses, coursePackage)
                                    if (oldTeacherCourseMapping[teacher]) {
                                        if (oldTeacherCourseMapping[teacher].sort().toString() !== newTeacherCourseMapping[teacher].sort().toString()) {
                                            const currentBatch = this.getBatch(teacher, null, section)
                                            await this.updateClassroomBatch(classDetails, mentorId, coursePackageCoursesConnectIds, currentBatch)
                                        }
                                    } else {
                                        const currentBatch = this.getBatchFromBatchId(batchId, section)
                                        await this.addClassroomBatch(classDetails, mentorId, coursePackageCoursesConnectIds, currentBatch, section)
                                    }
                                }
                            } else {
                                let position = 0
                                for (const teacher in newTeacherCourseMapping) {
                                    const courses = newTeacherCourseMapping[teacher]
                                    const mentorId = this.getTeacherId(teacher)
                                    const coursePackageCoursesConnectIds = this.getCoursesId(courses, coursePackage)
                                    if (position==0) {
                                        const currentBatch = this.getBatch(teacher, null, section)
                                        await this.updateClassroomBatch(classDetails, mentorId, coursePackageCoursesConnectIds, currentBatch)
                                    } else {
                                        const currentBatch = this.getBatchFromBatchId(batchId, section)
                                        await this.addClassroomBatch(classDetails, mentorId, coursePackageCoursesConnectIds, currentBatch, section)
                                    }
                                    position += 1
                                }
                            }
                        }
                    } else {
                        await this.updateClassroomBatch(classDetails)
                    }
                } else {
                    const oldCourseMapMultiple = this.getTeachersArrayMultipleBatch(multipleTeacherMapInitial)
                    const newCourseMapMultiple = this.getTeachersArrayMultipleBatch(multipleTeacherMap)
                    if (multipleTeacherMapInitial.length && !multipleTeacherMap.length) {
                        return 
                    }
                    for (const teacher in newCourseMapMultiple) {
                        const courses = newCourseMapMultiple[teacher].courses
                        const coursePackageCoursesConnectIds = this.getCoursesId(courses, coursePackage)
                        const mentorId = this.getTeacherId(teacher)
                        const oldCourseMapMultipleString = oldCourseMapMultiple[teacher] && oldCourseMapMultiple[teacher].courses.sort().toString()
                        const oldTeacherName = this.getOldTeacherName(oldCourseMapMultiple)
                        if (newCourseMapMultiple[teacher].inherited == null) {
                            if (oldCourseMapMultipleString !== newCourseMapMultiple[teacher].courses.sort().toString() || oldTeacherName !== teacher) {
                                const teacherName = this.getOldTeacherName(oldCourseMapMultiple)
                                const currentBatch = this.getBatch(teacherName, null, section)
                                await this.updateClassroomBatch(classDetails, mentorId, coursePackageCoursesConnectIds, currentBatch)
                            }
                        } else if (oldCourseMapMultiple[teacher]) {
                            if (oldCourseMapMultipleString !== newCourseMapMultiple[teacher].courses.sort().toString()) {
                                const currentBatch = this.getBatch(teacher, oldTeacherName, section)
                                await this.updateClassroomBatch(classDetails, mentorId, coursePackageCoursesConnectIds, currentBatch)
                            }
                        } else {
                            const inherited = newCourseMapMultiple[teacher].inherited
                            const currentBatch = this.getBatchFromBatchId(inherited, section)
                            await this.addClassroomBatch(classDetails, mentorId, coursePackageCoursesConnectIds, currentBatch, section)
                        }
                    }
                    for (const teacher in oldCourseMapMultiple) {
                        if ((!newCourseMapMultiple[teacher] && oldCourseMapMultiple[teacher].inherited !== null) || (this.checkBatchWithNoCourse(teacher, oldCourseMapMultiple, newCourseMapMultiple))) {
                            await this.deleteBatchQuery(teacher, section)
                        }
                    }
                }
            }
        } else {
            // if batches are not yet created
            if (multipleTeacherMap.length) {
                if (multipleTeacherMap.length === 1) {
                    await this.addClassroomBatch(classDetails, null, null, null, section)
                } else {
                    let teacherCourseMapping = this.getTeachersArray(multipleTeacherMap)
                    let masterBatch
                    let index = 0
                    if (Object.keys(teacherCourseMapping).length) {
                        for (const teacher in teacherCourseMapping) {
                            const courses = teacherCourseMapping[teacher]
                            const mentorId = this.getTeacherId(teacher)
                            const coursePackageCoursesConnectIds = this.getCoursesId(courses, coursePackage)
                            if (index === 0) {
                                const res = await this.addClassroomBatch(classDetails, mentorId, coursePackageCoursesConnectIds, null, section)
                                masterBatch = get(res, 'addBatch')
                            } else {
                                await this.addClassroomBatch(classDetails, mentorId, coursePackageCoursesConnectIds, masterBatch, section)
                            }
                            index += 1
                        }
                    }
                }
            } else {
                await this.addClassroomBatch(classDetails, null, null, null, section)
            }
        }
    }

    deleteBatchQuery = async (teacher, section) => {
        const batch = this.getBatch(teacher, null, section)
        if (batch.id) {
            await removeBatchStudents(batch.id)
            await deleteBatch(batch.id)
        }
    }

    checkBatchWithNoCourse = (teacher, oldCourseMapMultiple, newCourseMapMultiple) => {
        if (newCourseMapMultiple[teacher] && newCourseMapMultiple[teacher].inherited === null && oldCourseMapMultiple[teacher].inherited !== null) {
            const prevCourse = oldCourseMapMultiple[teacher].courses
            if (prevCourse.length === 1) {
                const presentCourses = newCourseMapMultiple[teacher].courses
                return presentCourses.includes(get(prevCourse, '[0]'))
            }
        }
    }

    getOldTeacherName = (teacherMap) => {
        const filetedTeacher = Object.keys(teacherMap).filter(item => get(teacherMap[item], 'inherited') === null)
        return filetedTeacher && filetedTeacher.length > 0 ? filetedTeacher[0] : null
    }

    getTeachersArrayMultipleBatch = (courseMap) => {
        const teacherMap = {}
        courseMap.length && courseMap.forEach(item => {
            const teacher = get(item, 'teacher')
            const course = get(item, 'course')
            let inherited = get(item, 'inherited')
            if (teacherMap[teacher]) {
                const tempTeacherMap = teacherMap[teacher]
                const newInherited = tempTeacherMap.inherited === null ? null : inherited
                const newCourses = [...tempTeacherMap.courses, course]
                teacherMap[teacher] = { courses: newCourses, inherited: newInherited }
            } else {
                teacherMap[teacher] = { courses: [course], inherited } 
            }
        })
        return teacherMap
    }

    getTeacherId = (teacher) => {
        const { schoolTrainers } = this.state
        const trainer = schoolTrainers.find(item => get(item, 'name') === teacher)
        return get(trainer, 'id')
    }

    getCoursesId = (courses, coursePackage) => {
        const { coursePackages } = this.state
        const selectedCoursePackage = coursePackages.length && coursePackages.find(item => get(item, 'id') === coursePackage)
        const { coursesData } = selectedCoursePackage
        const courseIds = []
        courses.length && courses.forEach(course => {
            coursesData.length && coursesData.forEach(coursePackage => {
                if (course === get(coursePackage, 'title')) {
                    courseIds.push(get(coursePackage, 'id'))
                }
            })
        })
        return courseIds
    }

    getBatch = (teacher, oldTeacherName, section) => {
        const batchData = get(section, 'batchData', [])
        const filteredBatch = batchData.length && batchData.find(item => get(item, 'allottedMentor.name') === teacher)
        if (filteredBatch) {
            return filteredBatch
        } else {
            const filteredBatchByOldTeacher = batchData.length && batchData.find(item => get(item, 'allottedMentor.name') === oldTeacherName)
            if (filteredBatchByOldTeacher) {
                return filteredBatchByOldTeacher
            }
        }
    }

    getTopicsByOrder = (courses, coursePackage) => {
        const { coursePackages } = this.state
        const selectedCoursePackage = coursePackages.find(item => get(item, 'id') === coursePackage)
        let coursePackageTopicRule = []
        let topics = []
        if (selectedCoursePackage) {
            topics = get(selectedCoursePackage, 'topicsData', [])
        }
        for(const topic of topics) {
            const course = get(topic, 'topic.courses[0].id')
            if (courses && courses.includes(course)) {
                let obj = {}
                obj.topicConnectId = get(topic, 'topic.id')
                obj.order = get(topic, 'topic.order')
                coursePackageTopicRule.push(obj)
            }
        }
        return coursePackageTopicRule
    }

    getBatchFromBatchId = (batchId, section) => {
        const batchData = get(section, 'batchData', [])
        const filteredBatch = batchData.length && batchData.find(item => get(item, 'id') === batchId)
        return filteredBatch
    }

    addClassroomBatch = async (classDetails, mentorConnectId, coursePackageCoursesConnectIds=[], currentBatch, section) => {
        const { schoolId, schoolCode, academicYearConnectId } = this.props
        const { coursePackages } = this.state
        const { displayName, coursePackage, mentor, students, batchId, multipleTeacherMap,
            coursePackageSameForAllClasses, teachersSameForAllClasses, isSameForAllSelected } = classDetails;
        let batchCode
        let classroomTitle
        const inheritedFromConnectId = get(currentBatch, 'id')
        const classesConnectIds = [get(section, 'id')]
        const selectedCoursePackage = coursePackages.find(cp => get(cp, 'id') === coursePackage)
        const courseConnectId = get(selectedCoursePackage, 'coursesData[0].id')
        if (inheritedFromConnectId) {
            batchCode = get(currentBatch, 'code')
            classroomTitle = get(currentBatch, 'classroomTitle')
        } else {
            batchCode = await getTrainingBatchCode(schoolCode, true)
            classroomTitle = displayName
        }
        let mentorId = mentor
        if (mentorConnectId) {
            mentorId = mentorConnectId
        }
        const addBatchInput = {
            code: batchCode,
            documentType: 'classroom',
            type: 'b2b',
        }
        const coursePackageTopicRule = this.getTopicsByOrder(coursePackageCoursesConnectIds, coursePackage);
        if (coursePackageTopicRule.length) {
            addBatchInput.coursePackageTopicRule = coursePackageTopicRule
        }
        let coursePackageConnectId = coursePackage
        const { section: selectedSection, grade } = this.state.selectedGradeSection
        if (classroomTitle) {
            if (get(section, 'id') !== get(selectedSection, 'id')) {
                addBatchInput.classroomTitle = `${grade}${get(section, 'section')}`
            } else {
                addBatchInput.classroomTitle = classroomTitle
            }
        }
        let studentIds = students
        let batchStudentsConnectIds = students
        if (isSameForAllSelected && get(section, 'id') !== get(selectedSection, 'id')) {
            studentIds = null
            batchStudentsConnectIds = null
            if (!coursePackageSameForAllClasses) {
                coursePackageConnectId = null
            }
            if (!teachersSameForAllClasses) {
                mentorId = null
            }
        }
        if (inheritedFromConnectId) {
            studentIds = []
        }
        return await addTrainingBatch({
            input: addBatchInput,
            schoolConnectId: schoolId,
            allottedMentorConnectId: mentorId,
            courseConnectId,
            coursePackageConnectId,
            studentIds,
            classesConnectIds,
            coursePackageCoursesConnectIds,
            inheritedFromConnectId,
            academicYearConnectId,
            batchStudentsConnectIds
        })
    }

    updateClassroomBatch = async (classDetails, mentorConnectId, coursePackageCoursesConnectIds=[], currentBatch) => {
        const { schoolId, academicYearConnectId } = this.props
        const { coursePackages, selectedGradeSection } = this.state
        const { displayName, coursePackage, mentor, students, batchId, multipleTeacherMap } = classDetails;
        let batchIdToUpdate
        const currentBatchId = get(currentBatch, 'id')
        let editBatchInput = {}
        let coursePackageCoursesConnectIdsToSend
        let courseConnectId
        const { section } = selectedGradeSection
        if (currentBatchId) {
            batchIdToUpdate = currentBatchId
            const courseIdsToAdd = []
            const courseIdsToDelete = []
            const coursePackageCourses = get(currentBatch, 'coursePackageCourses', [])
            const fetchedCourses = coursePackageCourses.map(item => get(item, 'id'))
            fetchedCourses.forEach(item => {
                if (!coursePackageCoursesConnectIds.includes(item)) {
                    courseIdsToDelete.push(item)
                }
            })
            coursePackageCoursesConnectIds.forEach(item => {
                if (!fetchedCourses.includes(item)) {
                    courseIdsToAdd.push(item)
                }
            })
            const topicsToUpdate = this.getTopicsByOrder(coursePackageCoursesConnectIds, coursePackage)
            const { section } = selectedGradeSection
            const batchData = get(section, 'batchData', [])
            const input = {}
            if (get(classDetails, 'coursePackage') !== get(batchData, '[0].coursePackage.id')) {
                input.coursePackageTopicRule = { replace: [] }
                await removeCoursePackageCoursesFromBatch(batchIdToUpdate)
            } else {
                input.coursePackageTopicRule = { replace: topicsToUpdate }
            }
            editBatchInput = input
            coursePackageCoursesConnectIdsToSend = courseIdsToAdd
            const currentComponentId = get(currentBatch, 'currentComponent.id', null)
            if (currentComponentId) {
                await updateBatchCurrentComponentStatus(currentComponentId, get(topicsToUpdate, '[0].topicConnectId'))
            }
            for (let i=0; i<courseIdsToDelete.length; i++) {
                await removeFromBatchCoursePackageCourses(batchId, null, courseIdsToDelete[i])
            }
        } else {
            batchIdToUpdate = batchId
            const selectedCoursePackage = coursePackages.find(cp => get(cp, 'id') === coursePackage)
            courseConnectId = get(selectedCoursePackage, 'coursesData[0].id')
            const batchData = get(section, 'batchData', [])
            if (get(classDetails, 'coursePackage') !== get(batchData, '[0].coursePackage.id')) {
                editBatchInput.coursePackageTopicRule = { replace: [] }
                await removeCoursePackageCoursesFromBatch(batchIdToUpdate)
            }
            if (displayName) {
                editBatchInput.classroomTitle = displayName
            }
        }
        let mentorId = mentor
        if (mentorConnectId) {
            mentorId = mentorConnectId
        }
        const filterBatch = this.getBatchFromBatchId(batchIdToUpdate, section)
        if (get(filterBatch, 'allottedMentor.id') === mentorId) {
            mentorId = null
        }
        let coursePackageIdToSend = coursePackage
        if (get(filterBatch, 'coursePackage.id') === coursePackage) {
            coursePackageIdToSend = null
        }
        const studentList = get(filterBatch, 'studentsList', [])
        const batchStudentsIds = studentList.length ? studentList.map(item => get(item, 'id')) : []
        const studentsToSend = this.getNewStudents(students, batchStudentsIds)
        await updateTrainingBatch({
            input: editBatchInput,
            allottedMentorConnectId: mentorId,
            courseConnectId,
            schoolConnectId: schoolId,
            coursePackageConnectId: coursePackageIdToSend,
            batchId: batchIdToUpdate,
            coursePackageCoursesConnectIds: coursePackageCoursesConnectIdsToSend,
            academicYearConnectId,
            studentIds: studentsToSend,
            batchStudentsConnectIds: studentsToSend
        })
    }

    getNewStudents = (students, batchStudentsIds) => {
        const idsToSend = []
        students && students.length && students.forEach(item => {
            if (!batchStudentsIds.includes(item)) {
                idsToSend.push(item)
            }
        })
        return idsToSend
    }

    renderEmptyClassroom = () => {
        return (
            <FlexContainer justify='center' align='center' width='100%'>
                <FlexContainer direction='column' justify='center' align='center' gap='16px' width='400px'>
                    <h3>No Classrooms Created</h3>
                    <p>There are currently no Classrooms created, start by creating Grades and Sections for this school.</p>
                    <PrimaryButton
                        onClick={() => {
                            if (!get(this.props, 'academicYearConnectId')) {
                                return notification.warn({ message: 'Please select Academic Year to proceed further' })
                            } else {
                                this.setState({
                                    addGradeModalVisible: true,
                                    isMultiSelect: true
                                })
                            }
                        }}
                    >Add Classrooms
                    </PrimaryButton>
                </FlexContainer>
            </FlexContainer>
        )
    }

    getClassroomCount = () => {
        const { classGradeList } = this.state
        let count = 0
        classGradeList.length && classGradeList.forEach(item => {
            const sections = get(item, 'sections', [])
            count += sections.length
        })
        return count
    }

    renderClasses = () => {
        const { classGradeList } = this.state
        return (
            <FlexContainer direction='column'>
                <FlexContainer justify='flex-end' align='center' width='100%' style={{ marginBottom: '20px' }}>
                    <p style={{ marginRight: '17px' }}>Total Classrooms: <strong>{this.getClassroomCount()}</strong></p>
                    <PrimaryButton
                        onClick={() => this.setState({ addGradeModalVisible: true })}
                    >
                        Add Grade
                    </PrimaryButton>
                </FlexContainer>
                {classGradeList.map((classGrade) => (
                    <Class
                        classGrade={classGrade}
                        handleAddSection={this.handleAddSection}
                        openEditClassroom={this.openEditClassroom}
                    />
                ))}
            </FlexContainer>
        )
    }

    render() {
        const { schoolId, schoolClassesAddStatus, classroomBatchesFetching,
             classroomGradesFetchStatus, schoolClassesDeleteStatus, classroomCoursePackagesFetchStatus, classroomTeachersFetchStatus,
             studentProfilesFetchStatus} = this.props
        const { addGradeModalVisible, isMultiSelect, addSectionFor, editClassroomModalVisible, selectedGradeSection,
            coursePackages, schoolTrainers, classStudents, isLoadingData, classGradeList, addBatchLoading } = this.state

        const isClassroomBatchesLoading = classroomBatchesFetching && get(classroomBatchesFetching.toJS(), 'loading')
        const isClassroomGradesFetching = classroomGradesFetchStatus && get(classroomGradesFetchStatus.toJS(), 'loading')
        const showLoader = isClassroomGradesFetching || isClassroomBatchesLoading || !schoolId || isLoadingData
        return (
            <>
                {showLoader ? (
                    <FlexContainer direction='column' justify='center' align='center'>
                        <LoadingOutlined />
                    </FlexContainer>
                ) : null}
                {(!showLoader && !classGradeList.length) ? (
                    this.renderEmptyClassroom()
                ) : null}
                {(!showLoader && classGradeList.length) ? (
                    this.renderClasses()
                ) : null}
                {addGradeModalVisible ? (
                    <AddGradeSections
                        addGradeSections={this.addGradeSections}
                        schoolClassesAddStatus={schoolClassesAddStatus}
                        addGradeModalVisible={addGradeModalVisible}
                        handleClose={() => this.setState({ addGradeModalVisible: false, isMultiSelect: false, addSectionFor: null })}
                        multiSelect={isMultiSelect}
                        addSectionFor={addSectionFor}
                    />
                ) : null}
                {editClassroomModalVisible ? (
                    <EditClassroom
                        editClassroomModalVisible={editClassroomModalVisible}
                        handleClose={() => this.handleCloseEditClassroom()}
                        handleDeleteSection={this.handleDeleteSection}
                        coursePackages={coursePackages}
                        schoolTrainers={schoolTrainers}
                        selectedGradeSection={selectedGradeSection}
                        classStudents={classStudents}
                        handleEditClassroomDetails={this.handleEditClassroomDetails}
                        addBatchLoading={addBatchLoading}
                        schoolClassesDeleteStatus={schoolClassesDeleteStatus && get(schoolClassesDeleteStatus.toJS(), 'loading')}
                        fetchingCoursePackage={classroomCoursePackagesFetchStatus && get(classroomCoursePackagesFetchStatus.toJS(), 'loading')}
                        fetchingClassroomTeachers={classroomTeachersFetchStatus && get(classroomTeachersFetchStatus.toJS(), 'loading')}
                        fetchingStudentProfile={studentProfilesFetchStatus && get(studentProfilesFetchStatus.toJS(), 'loading')}
                        classGradeList={classGradeList}
                    />
                ) : null}
            </>
        )
    }
}

export default Classrooms
