import gql from "graphql-tag"
import { get } from "lodash"
import requestToGraphql from "../../utils/requestToGraphql"

const fetchBatchSessions = async (batchId) => {
    const getBatchSession = await requestToGraphql(gql`{
    batchSessions(filter: {
        and: [
            {batch_some: {
                id: "${batchId}"
            }}
        ]
    }){
    id
    topic{
        id
        title
        courses{
            id
            title
        }
        chapter{
            id
            title
        }
    }
    sessionStatus
    }
}`)
    return get(getBatchSession, 'data')
}

export default fetchBatchSessions;