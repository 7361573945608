import gql from 'graphql-tag'
import duck from '../../../duck'

const removeFromUserBankDetailDocumentFile = async (userBankDetailId, fileId) => {
    return duck.query({
        query: gql`
        mutation {
            removeFromUserBankDetailDocumentFile(userBankDetailId: "${userBankDetailId}", fileId: "${fileId}") {
              userBankDetail {
                id
              }
            }
          }
        `,
        type: 'userBankDetail/delete',
        key: 'userBankDetail',
    })
}

export default removeFromUserBankDetailDocumentFile