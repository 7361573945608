import React, { Component } from 'react';
import { AddTEacherNameStyle, SchoolNameStyle } from '../TeachersTab.styles';


export default class NameANdImageHeader extends Component {
  render() {
    const { schoolName, Loader, Loader1, Loader2, Loader3, BImage, Line, onNext, goBackOnNext, maxTabAccesed, modal2Visible = false } = this.props;
    return (
      <div>
        <AddTEacherNameStyle>{modal2Visible ? 'Add' : 'Edit'} Teacher</AddTEacherNameStyle>
        <SchoolNameStyle>{`Under ${schoolName}`}</SchoolNameStyle>
        {/* <div style={{
          width: "100%", marginTop: "20px", display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }} >
          <img src={Loader1} alt="logo" style={{
            width: "35px",
            cursor: "pointer"
          }}
            onClick={() => { maxTabAccesed >= 0 ? goBackOnNext(0) : '' }}
          />
          <img src={Line} alt="logo" style={{ width: "36%" }} />
          <img src={Loader2} alt="logo" style={{ width: "8%", cursor: "pointer" }} onClick={() => { maxTabAccesed >= 1 ? goBackOnNext(1) : '' }} />
          <img src={Line} alt="logo" style={{ width: "35%" }} />
          <img src={Loader3} alt="logo" style={{ width: "8%", cursor: "pointer" }} onClick={() => { maxTabAccesed >= 2 ? goBackOnNext(2) : '' }} />
        </div> */}


      </div>
    );
  }
}
