import styled from "styled-components";

const Container = styled.div`
    font-family: 'Inter';
    letter-spacing: 0.03em;
    margin: 0px 48px 24px 24px;
    .sessionData {
        width: 15%;
        font-size: 14px;
        line-height: 21px;
        color: #212121;
        padding: 21px 0;
    }
    .collapsable-button {
        width: 15%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: none;
        outline: none;
        padding: 0;
        background: none;
        cursor: pointer;
        >span {
            padding: 4px 8px;
            height: 24px;
            color: #757575;
            background: #F2F2F2;
            border-radius: 4px;
            font-size: 12px;
            line-height: 16px;
        }
    }
`

const TopNavigation = styled.div`
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #282828;
`

const FlexContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${props => props.direction ? props.direction : 'row'};
    justify-content: ${props => props.justify ? props.justify : 'flex-start'};
    align-items: ${props => props.align ? props.align : 'flex-start'};
`

const BatchDetailsContainer = styled.div`
    margin-bottom: 24px;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
`

const BatchNameContainer = styled.div`
    margin-top: 40px;
    margin-left: 54px;
    display: flex;
    flex-direction: column;
    > h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        color: #000000;
    }
    > p {
        font-size: 12px;
        line-height: 16px;
        color: #757575;
    }
`

const DetailsContainer = styled.div`
    margin: 26px 52px 32px 0;
    max-width: 516px;
    > h3 {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
    }
    .detailsType {
        font-size: 10px;
        line-height: 12px;
        color: #757575;
        margin-bottom: 5px;
    }
    .detailsData {
        font-size: 12px;
        line-height: 16px;
        color: #424242;
    }
`

const TagsContainer = styled.div`
    display: flex;
    > button {
        padding: 2px 8px;
        background: #EEEEEE;
        border-radius: 15px;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #616161;
        margin-right: 8px;
        outline: none;
        border: none;
    }
`

const PackageContainer = styled.div`
    border: 1px solid #000000;
    box-sizing: border-box;
`

const PackageHeadContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 24px 51px 0 24px;
    > h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
    }
    > h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #212121;
    }
`

const SessionHead = styled.div`
    background: #F5F5F5;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-top: 78px;
    > span {
        width: 15%;
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;
        color: #212121;
        padding: 14px 0;
    }
`

const SessionData = styled.div`
    box-shadow: inset 0px -1px 0px #EEEEEE;
    display: flex;
    align-items: center;
    > span {
        font-size: 14px;
        line-height: 21px;
        color: #212121;
        padding: 21px 0;
    }
`

Container.FlexContainer = FlexContainer
Container.TopNavigation = TopNavigation
Container.BatchDetailsContainer = BatchDetailsContainer
Container.BatchNameContainer = BatchNameContainer
Container.DetailsContainer = DetailsContainer
Container.TagsContainer = TagsContainer
Container.PackageContainer = PackageContainer
Container.PackageHeadContainer = PackageHeadContainer
Container.SessionHead = SessionHead
Container.SessionData = SessionData

export default Container