/* eslint-disable import/first */
import React, { Component } from 'react'
import GradeTopContainer from './Grade.styles'
import { Button, Switch } from 'antd'
import DateRangePicker from '../../components/FromToDatePicker/DateRangePicker'
import moment from 'moment'
import GradeOperationReport from '../GradeAnalytics/GradeOperationReport'
import { debounce, get } from 'lodash'
import { filterKey } from '../../utils/data-utils'
import RenderCards from '../Stats/Components/Cards/Cards'
import fetchGradeReport from '../../actions/grade/fetchGradeReport'
import grades from '../../constants/gradeConstants'

export default class Grade extends Component {
  constructor(props) {
    if (!localStorage.getItem('country')) {
      localStorage.setItem('country', 'india')
    }
    super(props)
    this.state = {
      fromDate: null,
      toDate: null,
      dateRanges: [
        { label: 'Yesterday', subtract: { duration: '1', unit: 'd' } },
        { label: '3D', subtract: { duration: '3', unit: 'd' } },
        { label: '4D', subtract: { duration: '4', unit: 'd' } },
        { label: '5D', subtract: { duration: '5', unit: 'd' } },
        { label: '6D', subtract: { duration: '6', unit: 'd' } },
        { label: '1W', subtract: { duration: '7', unit: 'd' } },
        { label: '2W', subtract: { duration: '14', unit: 'd' } },
        { label: '3W', subtract: { duration: '21', unit: 'd' } },
        { label: '1M', subtract: { duration: '1', unit: 'M' } }
      ],
      selectedRange: '{"duration":"7","unit":"d"}',
      country: localStorage.getItem('country'),
      vertical: 'b2c',
      showDatePicker: false,
      analyticsMeta: {},
      sessionGradeReports: [],
      fetchCount: 0
    }
  }

  componentDidMount = () => {
    this.handleDateRange(this.state.selectedRange)
    window.dispatchEvent(new Event('storage'))
    window.addEventListener('storage', () => {
      if (this.state.country !== localStorage.getItem('country')) {
        this.setState({
          country: localStorage.getItem('country')
        })
      }
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { sessionGradeReportsFetchStatus } = this.props
    if (this.state.country !== prevState.country || this.state.vertical !== prevState.vertical) {
      this.fetchStatsData()
    }
    const fetchStatus = sessionGradeReportsFetchStatus.getIn([`sessionGradeReports/${this.state.fetchCount}`])
    const prevFetchStatus = prevProps.sessionGradeReportsFetchStatus.getIn([
      `sessionGradeReports/${this.state.fetchCount}`
    ])
    if (
      fetchStatus &&
      !get(fetchStatus.toJS(), 'loading') &&
      get(fetchStatus.toJS(), 'success') &&
      prevFetchStatus !== fetchStatus
    ) {
      this.convertSessionData()
    }
  }
  convertSessionData = () => {
    let { sessionGradeReports } = this.props
    const { fetchCount } = this.state
    sessionGradeReports =
      (sessionGradeReports && filterKey(sessionGradeReports, `sessionGradeReports/${fetchCount}`).toJS()) || []
    let totalBooked = 0
    let totalConvertedUsers = 0
    let totalDemoCompleted = 0
    let totalRegisteredUsers = 0
    let totalVerifiedUsers = 0
    sessionGradeReports.forEach(sReport => {
      grades.map((str) => {
        const grade = str.toLowerCase()
        totalBooked += get(sReport[grade], 'booked', 0)
        totalConvertedUsers += get(sReport[grade], 'converted', 0)
        totalDemoCompleted += get(sReport[grade], 'demoCompleted', 0)
        totalRegisteredUsers += get(sReport[grade], 'registered', 0)
        totalVerifiedUsers += get(sReport[grade], 'registeredSameDay.phoneVerified', 0)
      })
    })
    this.setState({
      analyticsMeta: {
        totalBooked,
        totalConvertedUsers,
        totalDemoCompleted,
        totalRegisteredUsers,
        totalVerifiedUsers
      },
      sessionGradeReports
    })
  }

  fetchStatsData = async () => {
    const { fromDate, toDate, country, fetchCount, vertical } = this.state
    let filterQuery = ''
    filterQuery += fromDate ? `{ date_gte: "${moment(fromDate).startOf('day')}" }` : ''
    filterQuery += toDate ? ` { date_lte: "${moment(toDate).endOf('day')}" }` : ''
    filterQuery += country && country !== 'all' ? `{ country: ${country} }` : ''
    if (vertical && vertical === 'all') {
      filterQuery += '{vertical_in: [b2c, b2b2c]}'
    } else if (vertical && vertical !== 'all') {
      filterQuery += `{vertical: ${vertical}}`
    }
    this.setState({
      fetchCount: fetchCount + 1
    })
    debounce(() => {
      fetchGradeReport(filterQuery, this.state.fetchCount)
    }, 500)()
  }

  getDateDiff = () => {
    let dateDiff = 0
    let { fromDate, toDate } = this.state
    if (fromDate && toDate) {
      fromDate = moment(fromDate).startOf('day')
      toDate = moment(toDate).endOf('day')
      dateDiff = moment(toDate).diff(moment(fromDate), 'days')
    }
    return dateDiff
  }

  handleDateRange = rangeInString => {
    const range = JSON.parse(rangeInString)
    this.setState(
      {
        selectedRange: rangeInString
      },
      () => {
        this.handleDateChange(
          [moment().subtract(range.duration, range.unit), moment()],
          'rangeSelector'
        )
      }
    )
  }

  handleDateChange = (event, type) => {
    if (type === 'rangeSelector') {
      const dates = event
      this.setState(
        {
          fromDate: dates && dates[0] ? new Date(dates[0]).toDateString() : null,
          toDate: dates && dates[1] ? new Date(dates[1]).toDateString() : null
        },
        this.fetchStatsData
      )
    } else if (type === 'from') {
      this.setState(
        {
          fromDate: event && new Date(event).toDateString()
        },
        this.fetchStatsData
      )
    } else if (type === 'to') {
      this.setState(
        {
          toDate: event && new Date(event).toDateString()
        },
        this.fetchStatsData
      )
    }
  }
  handleCountryChange = (value) => {
    this.setState({
      country: value
    })
    localStorage.setItem('country', value)
    window.dispatchEvent(new Event('storage'))
  }
  handleVerticalChange = (value) => {
    this.setState({
      vertical: value
    })
  }
  handleVerticalChange = (value) => {
    this.setState({
      vertical: value
    })
  }

  render() {
    // eslint-disable-next-line max-len
    const {
      showDatePicker,
      fromDate,
      toDate,
      dateRanges,
      selectedRange,
      analyticsMeta,
      fetchCount,
      sessionGradeReports
    } = this.state
    const { sessionGradeReportsFetchStatus } = this.props
    const fetchStatus = sessionGradeReportsFetchStatus.getIn([`sessionGradeReports/${fetchCount}`])

    const countriesArray = [
      {
        countryValue: 'india',
        country: 'INDIA'
      },
      {
        countryValue: 'uae',
        country: 'UAE'
      }
    ]
    const verticalsArray = [
      {
        verticalValue: 'all',
        vertical: 'ALL'
      },
      {
        verticalValue: 'b2c',
        vertical: 'B2C'
      },
      {
        verticalValue: 'b2b2c',
        vertical: 'B2B2C'
      }
    ]
    return (
      <div>
        <GradeTopContainer>
          <GradeTopContainer.InnerContainer>
            <span>
              Filter by Date Range :
              <Switch
                checked={showDatePicker}
                onChange={checked => this.setState({ showDatePicker: checked })}
                size='small'
              />
            </span>
          </GradeTopContainer.InnerContainer>
          <div>
            <div style={{ marginBottom: '10px' }}>
              <div style={{ display: 'flex' }}>
                {showDatePicker && (
                  <DateRangePicker
                    fromDate={fromDate}
                    toDate={toDate}
                    handleDateChange={this.handleDateChange}
                  />
                )}
                <div style={{ marginLeft: '15px' }}>
                  {dateRanges.map(range => (
                    <Button
                      type={
                        JSON.stringify(range.subtract) === selectedRange ? 'primary' : 'default'
                      }
                      shape={range.label === 'Yesterday' ? 'round' : 'circle'}
                      onClick={() => this.handleDateRange(JSON.stringify(range.subtract), 'user')}
                      style={{
                        margin: '0 5px'
                      }}
                    >
                      {range.label}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </GradeTopContainer>

        <GradeTopContainer>
          <div style={{ display: 'flex' }}>
            {countriesArray.map(c => (
              <GradeTopContainer.StyledTab
                checked={c.countryValue === localStorage.getItem('country')}
                onClick={() => this.handleCountryChange(c.countryValue)}
              >
                {c.country}
              </GradeTopContainer.StyledTab>
            ))}
          </div>
          <div style={{ display: 'flex' }}>
            {verticalsArray.map(c => (
              <GradeTopContainer.StyledTab
                checked={c.verticalValue === this.state.vertical}
                onClick={() => this.handleVerticalChange(c.verticalValue)}
              >
                {c.vertical}
              </GradeTopContainer.StyledTab>
            ))}
          </div>
        </GradeTopContainer>
        <RenderCards
          analyticsMeta={analyticsMeta}
          fetchStatus={fetchStatus}
          dateDiff={this.getDateDiff()}
        />
        <GradeOperationReport
          salesOperationReport={this.props.sessionGradeReports}
          analyticsMeta={analyticsMeta}
          fetchStatus={fetchStatus}
          sessionGradeReports={sessionGradeReports}
          dateDiff={this.getDateDiff()}
          type='grade'
        />
      </div>
    )
  }
}
