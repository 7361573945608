import gql from "graphql-tag"
import requestToGraphql from "../../utils/requestToGraphql"

export const fetchCoursePackages = async () => {
    const query = gql`
        query {
            coursePackages{
                id
                title
            }
        }
    `
    const response = await requestToGraphql(query)
    return response
}