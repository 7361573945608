import React from 'react'
import { Pagination } from 'antd'

const TabPagination = (props) => {

    return (
        <Pagination
            style={{ margin:'0 auto', fontWeight: 'bolder' }}
            total={props.count}
            perPage={props.perPage}
            current={props.currentPage}
            onChange={(page) => props.onPageChange(page)}
            defaultPageSize={props.perPage}
        />
    )
}

export default TabPagination