import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import withNav from '../../../components/withNav'
import injectProps from '../../../components/injectProps'
import { HOMEWORK_PRACTICE } from '../../../constants/CourseComponents'
import { filterKey } from '../../../utils/data-utils'
import ContentPractice from '../ContentPractice/ContentPractice'

const ContentHomeworkPracticeNav = withNav(ContentPractice)({
  title: 'Homework Practice',
  activeNavItem: 'Homework Practice',
  showContentMakerNavigation: true,
})

const mapStateToProps = state => ({
  blockBasedPracticeMeta: state.data.getIn(['blockBasedProjectsMeta', 'data', 'count']),
  practiceFetchingStatus: state.data.getIn(['blockBasedProjects', 'fetchStatus', HOMEWORK_PRACTICE]),
  practiceData: filterKey(state.data.getIn(['blockBasedProjects', 'data']), HOMEWORK_PRACTICE),
  practiceAddStatus: state.data.getIn(['blockBasedProjects', 'addStatus', HOMEWORK_PRACTICE]),
  practiceAddFailure: state.data.getIn(['errors', 'blockBasedProjects/add']),
  practiceUpdateStatus: state.data.getIn(['blockBasedProjects', 'updateStatus', HOMEWORK_PRACTICE]),
  practiceUpdateFailure: state.data.getIn(['errors', 'blockBasedProjects/update']),
  practiceDeleteStatus: state.data.getIn(['blockBasedProjects', 'deleteStatus', HOMEWORK_PRACTICE]),
  practiceDeleteFailure: state.data.getIn(['errors', 'blockBasedProjects/delete']),

  coursesData: state.data.getIn(['course', 'data']),
  coursesFetchStatus: state.data.getIn(['courses', 'fetchStatus', 'courses']),
})

const ContentHomeworkPracticeNavWithExtraProps = injectProps({
  notification,
})(ContentHomeworkPracticeNav)

export default connect(mapStateToProps)(withRouter(ContentHomeworkPracticeNavWithExtraProps))
