import React, { Component } from 'react'
import OtpInput from 'react-otp-input'
import { get } from 'lodash'
import { Redirect, Link } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import { notification } from 'antd'
import 'react-phone-input-2/lib/style.css'
import { updateHasLogin } from '../../actions/login'
import Card from './Forget.style'
import Form from './ForgetPasswordForm.style'
import roleToSystemMap from '../Login/roleToSystemMap'
import store from '../../reducers'
import { queryValidateOTP, queryEmailLink, queryOTP, queryResent } from './forgetPasswordQuery'
import {
  UMS,
  CMS,
  ADMIN,
  MENTOR,
  UMS_ADMIN,
  UMS_VIEWER,
  TRANSFORMATION_ADMIN,
  TRANSFORMATION_TEAM
} from '../Login/../../constants/roles'
import removeFreshChat from '../../utils/freshchat/removeFreshchat'
import hideFreshDesk from '../../utils/freshDesk/hideFreshDesk'

const emailRegExp = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/


class ForgetPassword extends Component {
  state={
    currentScreen: 'loginSignup',
    email: '',
    phone: '',
    otp: '',
    rawphone: '',
    countryCode: '91',
    isEmailDisabled: false,
    isPhoneDisabled: false,
    password: '',
    resetpassword: '',
    username: '',
    oldpassword: '',
    authToken: '',
    loading: false,
    loading_resent: false,
    hasLoginAccess: true
  }

  getAuthToken = async () => {
    const { location } = this.props.history
    const query = new URLSearchParams(location.search).get('authToken')
    return query
  }
  async componentDidMount() {
    if (typeof window.fcWidget !== 'undefined') {
      removeFreshChat()
    }
    hideFreshDesk()
    const token = await this.getAuthToken()
    if (token !== null) {
      this.setState({ currentScreen: 'resetPassword', authToken: token })
    }
    document.title = `TMS-${process.env.REACT_APP_NODE_ENV} | Forgot Password`
  }
  handleChange = (otp) => this.setState({ otp });

  updateEmail = async (event) => {
    this.setState({ isPhoneDisabled: true, email: event.target.value })
    if (event.target.value.match(emailRegExp)) {
      this.setState({ email: event.target.value })
    }
    if (this.state.phone.replace(this.state.countryCode, '').length === 0
      && event.target.value.length === 0) {
      this.setState({ isPhoneDisabled: false })
    }
  }
  updateResetPassword = (event) => {
    this.setState({ resetpassword: event.target.value })
  }
  updatePassword = (event) => {
    this.setState({ password: event.target.value })
  }
  updatePhone = (event, data) => {
    this.setState({ email: '' })
    if (this.state.email.length === 0) {
      this.setState({ isEmailDisabled: true, countryCode: data.dialCode, phone: event, rawphone: event.replace(data.dialCode, '') })
      if (event.length === data.dialCode.length && this.state.email.length === 0) {
        this.setState({ isEmailDisabled: false })
      }
    }
  }
  validateOTP = async () => {
    this.setState({ loading: true })
    if (await queryValidateOTP(this.state.rawphone,
      this.state.countryCode,
      this.state.otp) === true) {
      this.setState({ loading: false, currentScreen: 'resetPassword' })
    } else {
      this.setState({ loading: false })
    }
  }
  resendotp = async () => {
    this.setState({ loading_resent: true })
    if (await queryResent(this.state.rawphone, this.state.countryCode) === true) {
      this.setState({ loading_resent: false })
      notification.info({ message: 'OTP Sent' })
    } else {
      this.setState({ loading: false })
    }
  }
  handleForgot =async () => {
    this.setState({ loading: true })
    if (this.state.password === this.state.resetpassword) {
      const { username, oldpassword, rawphone, countryCode, password, resetpassword,
        authToken } = this.state
      const { login } = this.props
      await login(username, oldpassword, rawphone, countryCode, password, resetpassword, authToken)
      const hasLoginAccess = this.hasLoginAccess(roleToSystemMap[this.props.role])
      if (!hasLoginAccess) {
        this.setState({ loading: false, hasLoginAccess })
      }
    } else if (this.state.password !== this.state.resetpassword) {
      setTimeout(() => {
        this.setState({ loading: false })
      }, 1000)
      notification.error({ message: 'password and resetpassword missmatch' })
    }
  }
  handelScreen = async () => {
    this.setState({ loading: true })
    if (this.state.rawphone.length === 0) {
      const { phone } = this.state
      this.setState({
        phone,
        countryCode: phone
      })
      if (await queryEmailLink(this.state.email) === true) {
        notification.info({ message: 'Reset Password link has been mailed to you' })
      }
      this.setState({ loading: false })
    } else if (this.state.email.length === 0 && this.state.rawphone.length === 10
      && await queryOTP(this.state.rawphone, this.state.countryCode) === true) {
      this.setState({ loading: false, currentScreen: 'otpScreen' })
    } else if (this.state.email.length === 0 && this.state.rawphone.length < 10
      && await queryOTP(this.state.rawphone, this.state.countryCode) === false) {
      this.setState({ phone: '', loading: false })
      notification.error({ message: queryOTP(this.state.rawphone, this.state.countryCode) })
    } else {
      this.setState({ loading: false })
    }
  }
  renderForgot = () => (
  <Form>
    <Form.Item>
        <PhoneInput
          placeholder='phone'
          country='in'
          inputStyle={{
            height: '3rem',
          }}
          copyNumbersOnly
          countryCodeEditable={false}
          onEnterKeyPress={() => {
            this.handelScreen()
          }}
          autoComplete='off'
          value={this.state.phone}
          disabled={this.state.isPhoneDisabled}
          onChange={this.updatePhone}
        />
    </Form.Item>
    <p>OR</p>
    <Form.Item>
        <Form.Input
          placeholder='email'
          autoComplete='off'
          disabled={this.state.isEmailDisabled}
          onChange={e => { this.updateEmail(e) }}
        />
    </Form.Item>

    <Form.Button loading={this.state.loading} onClick={this.handelScreen} htmlType='submit'>
      Submit
    </Form.Button>
      <Form.ForgotText><Link to='/login'>Login</Link></Form.ForgotText>
  </Form>
  )
    hasLoginAccess = (systemViewPrivileges) => {
      if (systemViewPrivileges && systemViewPrivileges.length > 0) {
        return true
      }
      return false
    }
    shouldRedirectDirectly = (systemViewPrivileges) => {
      if (systemViewPrivileges && systemViewPrivileges.length > 1) {
        return false
      }
      return true
    }
    getRedirectDirectlyPage = (systemViewPrivileges) => {
      let redirectRoute = ''
      const userRole = this.props.role
      if (systemViewPrivileges[0] === UMS) {
        if (userRole === ADMIN || userRole === UMS_ADMIN || userRole === UMS_VIEWER) {
          redirectRoute = '/ums/dashboard'
        } else if (userRole === MENTOR) {
          redirectRoute = '/ums/sessions'
        }
      } else if (systemViewPrivileges[0] === CMS) {
        redirectRoute = '/'
      }
      return redirectRoute
    }
  renderResetPassword = () => (
  <Form>
    <h1> Reset Password </h1>
    <Form.Item>
        <Form.Input
          placeholder='Password'
          autoComplete='off'
          type='password'
          value={this.state.password}
          onChange={e => { this.updatePassword(e) }}
        />
    </Form.Item>
    <Form.Item>
        <Form.Input
          placeholder='Reset Password'
          autoComplete='off'
          type='password'
          value={this.state.resetpassword}
          onEnterKeyPress={() => {
            this.handleForgot()
          }}
          onChange={e => { this.updateResetPassword(e) }}
        />
    </Form.Item>
    <Form.Button loading={this.state.loading}
      onClick={this.handleForgot}
      htmlType='submit'
    >
      Submit
    </Form.Button>
      <Form.ForgotText><Link to='/login'>Login</Link></Form.ForgotText>
      {
                !this.state.hasLoginAccess
                    ? <Card.NoLoginAccess>No Login Access!</Card.NoLoginAccess>
                    : <div />
              }
  </Form>
  )
  renderOTP = () => (
    <Form>
      <h1> Enter OTP </h1>
  <OtpInput
    value={this.state.otp}
    onChange={this.handleChange}
    separator={
      <span>
        <strong>.</strong>
      </span>
    }
    onEnterKeyPress={() => {
      this.validateOTP()
    }}
    isInputNum
    inputStyle={{
      width: '3rem',
      height: '3rem',
      margin: '2rem 1rem',
      fontSize: '2rem',
      borderRadius: 4,
      border: '1px solid rgba(0,0,0,0.3)',
    }}
  />
    <Form.Button loading={this.state.loading} onClick={this.validateOTP} htmlType='submit'>
      Submit
    </Form.Button>
    <Form.Button loading={this.state.loading_resent} onClick={this.resendotp} htmlType='submit'>
      Resend OTP
    </Form.Button>
    <Form.ForgotText><Link to='/login'>Login</Link></Form.ForgotText>
    </Form>

  )
  render() {
    const hasLoginAccess = this.props.role
      ? this.hasLoginAccess(roleToSystemMap[this.props.role])
      : true
    if (!hasLoginAccess) {
      store.dispatch(updateHasLogin(false))
    }
    if (this.props.hasLogin) {
      if (hasLoginAccess) {
        if (localStorage && !localStorage.getItem('country')) {
          localStorage.setItem('country', 'all')
        }
        const { role } = this.props
        if (role && role === TRANSFORMATION_ADMIN || role === TRANSFORMATION_TEAM) {
          if (localStorage && !localStorage.getItem('type')) {
            localStorage.setItem('type', UMS)
          }
        }
        const shouldRedirectDirectly = this.shouldRedirectDirectly(roleToSystemMap[this.props.role])
        if (shouldRedirectDirectly) {
          const redirectRoute = this.getRedirectDirectlyPage(roleToSystemMap[this.props.role])
          return <Redirect to={redirectRoute} />
        }
        const { location } = this.props
        if (get(location, 'state.from')) {
          return <Redirect to={get(location, 'state.from')} />
        }
        return <Redirect to='/' />
      }
    }
    return (
      <div>
        <Card.Container>
          <Card>
            <Card.Head>
              <Card.CircleBG />
              <Card.LogoWrapper>
                <img src='images/logo.png' alt='Tekie Logo' />
                <Card.AppText>Tekie Admin</Card.AppText>
              </Card.LogoWrapper>
            </Card.Head>
            <Card.Body>
            {this.state.currentScreen === 'loginSignup' && this.renderForgot()}
            {(this.state.currentScreen === 'otpScreen') && this.renderOTP()}
            {(this.state.currentScreen === 'resetPassword') && this.renderResetPassword()}
            </Card.Body>
          </Card>
        </Card.Container>
      </div>
    )
  }
}

export default ForgetPassword
