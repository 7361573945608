import { connect } from 'react-redux'
import Upload from './Upload'
import withNav from '../../components/withNav'


const UploadWithNav = withNav(Upload)({
  title: 'Upload',
  activeNavItem: 'Upload',
  showCMSNavigation: true,
  breadCrumbPath: [{ name: 'Upload', route: '/upload' }]
})

const mapStateToProps = state => ({
  uploadFiles: state.data.getIn([
    'uploadFiles',
    'data'
  ]),
  filesCount: state.data.getIn([
    'filesMeta',
    'data',
    'count'
  ]),
  filesStatus: state.data.getIn([
    'uploadFiles',
    'fetchStatus'
  ]),
  deletedStatus: state.data.getIn([
    'uploadFiles',
    'deleteStatus',
    'uploadFiles'
  ]),
  updateStatus: state.data.getIn([
    'uploadFiles',
    'updateStatus',
    'uploadFiles'
  ]),
  fileUpdateFailure: state.data.getIn(['errors', 'uploadFiles/update']),
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(UploadWithNav)
