import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { get, sortBy } from 'lodash'
import { List } from 'immutable'
import { notification } from 'antd'
import withNav from '../../../components/withNav'
import injectProps from '../../../components/injectProps'
import ContentLearningSlides from './ContentLearningSlides'
import { CONTENT_MAKER } from '../../../constants/roles'
import { filterKey } from '../../../utils/data-utils'


const ContentLearningSlidesNav = withNav(ContentLearningSlides)({
  title: 'Learning Slides',
  activeNavItem: 'Learning Objective',
  titlePath: 'topicTitle',
  showContentMakerNavigation: true,
  blockType: CONTENT_MAKER,
  breadCrumbPath: [{ name: 'Learning Objective', route: '/content-learningObjective' },
    { path: 'topicTitle', route: '/content-learningObjective' }, { name: 'Learning Slides', route: '/content-learningSlides/' }]
})

const setLearningObjectives = (state, props) =>
  sortBy(state.data.getIn(['learningObjectives', 'data']).toJS(), 'order').find(learningObjective => learningObjective.id === props.match.params.learningObjectiveId)

// .filter(comic => get(comic, 'learningObjectives', [])
// .map(loId => loId).includes(props.match.params.learningObjectiveId))

const mapStateToProps = (state, props) => ({
  topicTitle: setLearningObjectives(state, props) ? get(setLearningObjectives(state, props), 'title') : '',
  learningSlides: filterKey(state.data.getIn(['learningSlides', 'data']), 'learningSlides'),
  learningObjective: state.data.getIn(['learningObjective', 'data']),
  learningSlideContents: filterKey(state.data.getIn(['learningSlideContents', 'data']), 'learningSlideContents'),
  questionBanks: filterKey(state.data.getIn(['questionBanks', 'data']), 'questionBanks/learningSlides'),
  isSlidesContentFetched:
      state.data.getIn(['learningSlideContents', 'fetchStatus', 'learningSlideContents', 'success']),
  isSlidesContentFetchLoading:
    state.data.getIn(['learningSlideContents', 'fetchStatus', 'learningSlideContents', 'loading']),
  isSlidesContentFetchFail:
    state.data.getIn(['learningSlideContents', 'fetchStatus', 'learningSlideContents', 'failure']),
  isSlidesContentUpdated:
    state.data.getIn(['updateLearningSlideContent', 'updateStatus', 'updateLearningSlideContent', 'success']),
  isSlidesContentUpdateLoading:
    state.data.getIn(['updateLearningSlideContent', 'updateStatus', 'updateLearningSlideContent', 'loading']),
  isSlidesContentUpdateFail:
    state.data.getIn(['updateLearningSlideContent', 'updateStatus', 'updateLearningSlideContent', 'failure']),
  isSlidesFetched:
      state.data.getIn(['learningSlides', 'fetchStatus', 'learningSlides', 'success']),
  isSlidesFetchLoading:
    state.data.getIn(['learningSlides', 'fetchStatus', 'learningSlides', 'loading']),
  isSlidesFetchFail:
    state.data.getIn(['learningSlides', 'fetchStatus', 'learningSlides', 'failure']),
  isSlidesUpdated:
    state.data.getIn(['updateLearningSlides', 'updateStatus', 'updateLearningSlides', 'success']),
  isSlidesUpdateLoading:
    state.data.getIn(['updateLearningSlides', 'updateStatus', 'updateLearningSlides', 'loading']),
  isSlidesUpdateFail:
    state.data.getIn(['updateLearningSlides', 'updateStatus', 'updateLearningSlides', 'failure']),
  isPracticeQuestionsFetched:
      state.data.getIn(['questionBanks', 'fetchStatus', 'questionBanks/learningSlides', 'success']),
  isPracticeQuestionsFetchLoading:
    state.data.getIn(['questionBanks', 'fetchStatus', 'questionBanks/learningSlides', 'loading']),
  isPracticeQuestionsFetchFail:
    state.data.getIn(['questionBanks', 'fetchStatus', 'questionBanks/learningSlides', 'failure']),
  isLearningObjectiveFetched:
      state.data.getIn(['learningObjective', 'fetchStatus', 'learningObjective', 'success']),
  isLearningObjectiveFetchLoading:
    state.data.getIn(['learningObjective', 'fetchStatus', 'learningObjective', 'loading']),
  isLearningObjectiveFetchFail:
    state.data.getIn(['learningObjective', 'fetchStatus', 'learningObjective', 'failure']),
  isSlideDataAdded:
    state.data.getIn(['addLearningSlides', 'addStatus', 'addLearningSlides', 'success']),
  isSlideDataAddLoading:
    state.data.getIn(['addLearningSlides', 'addStatus', 'addLearningSlides', 'loading']),
  isSlideDataAddFail:
    state.data.getIn(['addLearningSlides', 'addStatus', 'addLearningSlides', 'failure']),
  isSlideContentDataAdded:
    state.data.getIn(['addLearningSlideContent', 'addStatus', 'addLearningSlideContent', 'success']),
  isSlideContentDataAddLoading:
    state.data.getIn(['addLearningSlideContent', 'addStatus', 'addLearningSlideContent', 'loading']),
  isSlideContentDataAddFail:
    state.data.getIn(['addLearningSlideContent', 'addStatus', 'addLearningSlideContent', 'failure']),
  isSlideContentDataDeleted:
    state.data.getIn(['deleteLearningSlideContent', 'deleteStatus', 'deleteLearningSlideContent', 'success']),
  isSlideContentDataDeletedLoading:
    state.data.getIn(['deleteLearningSlideContent', 'deleteStatus', 'deleteLearningSlideContent', 'loading']),
  isSlideContentDataDeletedFail:
    state.data.getIn(['deleteLearningSlideContent', 'deleteStatus', 'deleteLearningSlideContent', 'failure'])
  ,
})

const ContentLearningSlidesNavWithExtraProps = injectProps({
  notification,
})(ContentLearningSlidesNav)

export default connect(mapStateToProps)(withRouter(ContentLearningSlidesNavWithExtraProps))
