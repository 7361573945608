import React, { Component } from 'react'
import { get } from 'lodash'
import { Icon, Spin } from 'antd'
import { Table } from '../../../../components/StyledComponents'
import MainTable from '../../../../components/MainTable'
import RatingTableRow from './RatingTableRow'

class RatingTableBody extends Component {
  state = {
    filteredRatings: []
  }

  groupBy = (mentorRatings, bookingDate) => mentorRatings.reduce((element, object) => {
    const key = object[bookingDate]
    if (!element[key]) {
      element[key] = []
    }
    element[key].push(object)
    return element
  }, {})

  setFilteredRatings = () => {
    const mentorRatingsData = get(this.props, 'particularMentorRatings').toJS().data
    const mentorRatingsTableData = []
    mentorRatingsData.map((data) => {
      mentorRatingsTableData.push({
        name: get(data, 'menteeName', '-'),
        course: get(data, 'menteeCourse', 'No course') || 'No course',
        comment: get(data, 'menteeComment', 'No comment') || 'No comment',
        rating: get(data, 'rating', 0) || 0,
        slot: get(data, 'slot', 'No slot') || 'No slot',
        topic: get(data, 'menteeTopic', 'No Topic') || 'No Topic',
        date: get(data, 'bookingDate', '-')
      })
    })
    const groupedRatings = this.groupBy(mentorRatingsTableData, 'date')
    this.setState({
      filteredRatings: groupedRatings
    })
  }

  componentDidUpdate = (prevProps) => {
    const { isParticularMentorRatingsFetching, hasParticularMentorRatingsFetched } =
    this.props
    if (!isParticularMentorRatingsFetching && hasParticularMentorRatingsFetched) {
      if (get(prevProps, 'particularMentorRatings') !==
        get(this.props, 'particularMentorRatings')) {
        this.setFilteredRatings()
      }
    }
  }

  render() {
    const { fetchStatus } = this.props
    const { filteredRatings } = this.state

    if (fetchStatus && fetchStatus.loading) {
      const loadingIcon = <Icon type='loading' style={{ fontSize: 24 }} spin />
      return (
        <div style={{ width: '100%', padding: '15px' }}>
          <Spin indicator={loadingIcon} />
        </div>
      )
    }

    if (fetchStatus && fetchStatus.failure) {
      const errorText = 'An unexpected error occurred while fetching sessions.'
      return (
        <MainTable.EmptyTable>
          {errorText}
        </MainTable.EmptyTable>
      )
    }

    const filteredRatingsKeys = filteredRatings ? Object.keys(filteredRatings) : null

    if (fetchStatus && fetchStatus.success && !filteredRatings) {
      const emptyText = 'No ratings found.'
      return (
        <MainTable.EmptyTable>
          {emptyText}
        </MainTable.EmptyTable>
      )
    }

    if (fetchStatus && fetchStatus.success && filteredRatings &&
      Object.keys(filteredRatings).length === 0) {
      const emptyText = 'No ratings found.'
      return (
        <MainTable.EmptyTable>
          {emptyText}
        </MainTable.EmptyTable>
      )
    }

    return filteredRatingsKeys && filteredRatingsKeys.map((date) => (
      <div key={date}>
        <MainTable.Row
          columnsTemplate='1fr'
          minWidth={this.props.minWidth}
          backgroundColor='#f5f5f5'
          style={{ '&:hover': 'background-color: #f5f5f5 !important' }}
        >
          <Table.Item backgroundColor='#f5f5f5'><MainTable.Item>{`${date} (${filteredRatings[date].length})`}</MainTable.Item></Table.Item>
        </MainTable.Row>
        {
          filteredRatings[date].map((ratings) => (
            <RatingTableRow
              minWidth={this.props.minWidth}
              columnsTemplate={this.props.columnsTemplate}
              ratings={ratings}
            />
          ))}
      </div>
    ))
  }
}

export default RatingTableBody
