/*eslint-disable*/
import { Button, message, notification, Popconfirm } from 'antd'
import { get, sortBy } from 'lodash'
import moment from 'moment'
import React from 'react'
import { deleteContentProject } from '../../../../../actions/contentMaker'
import { PreviewEditor } from '../../../../../components/ContentEditor'
import MainTable from '../../../../../components/MainTable'
import { HOMEWORK_PRACTICE, PRACTICE } from '../../../../../constants/CourseComponents'
import { isBase64 } from '../../../../../utils/base64Utility'
import { getFailureStatus, getSuccessStatus } from '../../../../../utils/data-utils'
import getDecodedStatement from '../../../../../utils/getDecodedStatement'
import parseChatStatement from '../../../../../utils/parseStatement'
import AssignModal from '../../../AssignModal'
import AssignedView from '../../../AssignModal/AssignedView'
import IframePreview from '../../../IframePreview/IframePreview'
import { MDTable } from '../../ContentPractice.styles'
import PracticeView from './PracticeView'
import PublishSwitcher from './PublishSwitcher'
class PracticeTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tableData: [],
      columns: [],
      openAssignModal: false,
      assignModalData: null,
      isProjectDescViewModalVisible: false,
      isAnswerViewModalVisible: false,
      iframeViewData: null,
    }
  }
  componentDidUpdate = (prevProps) => {
    const { practiceFetchingStatus, practiceAddStatus,
      practiceUpdateStatus, practiceUpdateFailure,
      practiceDeleteStatus, practiceDeleteFailure,
      fetchProjectCount, practiceAddFailure } = this.props
    if (getSuccessStatus(practiceFetchingStatus, prevProps.practiceFetchingStatus)) {
      this.convertDataToTable()
    }

    if (getSuccessStatus(practiceAddStatus, prevProps.practiceAddStatus)) {
      this.convertDataToTable()
      fetchProjectCount()
      notification.success({
        message: 'Practice added successfully'
      })
    } else if (practiceAddStatus && !get(practiceAddStatus.toJS(), 'loading')
      && get(practiceAddStatus.toJS(), 'failure') &&
      (prevProps.practiceAddFailure !== practiceAddFailure)) {
      if (practiceAddFailure && practiceAddFailure.toJS().length > 0) {
        notification.error({
          message: get(get(practiceAddFailure.toJS()[0], 'error').errors[0], 'message')
        })
      }
    }

    if (getSuccessStatus(practiceUpdateStatus, prevProps.practiceUpdateStatus)) {
      notification.success({
        message: 'Practice updated successfully'
      })
      this.convertDataToTable()
    } else {
      getFailureStatus(practiceUpdateStatus, practiceUpdateFailure, prevProps.practiceUpdateFailure)
    }
    if (getSuccessStatus(practiceDeleteStatus, prevProps.practiceDeleteStatus)) {
      notification.success({
        message: 'Practice deleted successfully'
      })
      fetchProjectCount()
      this.convertDataToTable()
    } else {
      getFailureStatus(practiceDeleteStatus,
        practiceDeleteFailure, prevProps.practiceDeleteFailure)
    }
  }

  onOpenAssignModal = (data) => {
    this.setState({
      assignModalData: data,
      openAssignModal: true
    })
  }

  onCloseAssignModal = () => {
    this.setState({
      assignModalData: null,
      openAssignModal: false
    })
  }
  isTopicExist = (assignmentId) => {
    console.log(this.isTopicExist, 'topicExist')
    const {tableData} = this.state
    const assignment = tableData.find(assign => assign.id === assignmentId)
    let topics = get(assignment, 'topics', [])
    return topics.length > 0
  }

  convertDataToTable = () => {
    const { opneEditModal, practiceData,
      selectedCourse, searchKey,
      selectedTopic } = this.props
    let practicesData = practiceData && practiceData.toJS()
      ? practiceData.toJS() : []
    if (searchKey === 'course' && selectedCourse) {
      practicesData = practicesData.filter(practice =>
        get(practice, 'courses', []).map(course => course.id).includes(selectedCourse))
    } else if (searchKey === 'topic' && selectedTopic) {
      practicesData = practicesData.filter(practice =>
        get(practice, 'topics', []).map(topic => topic.id).includes(selectedTopic))
    }
    this.setState({
      tableData: sortBy(practicesData, 'createdAt').reverse()
    }, () => {
      let columns = []
      const descStyle = { height: '150px', overflow: 'auto' }
      if (this.state.tableData.length > 0) {
        columns = [
          {
            title: 'Order',
            dataIndex: 'order',
            key: 'order',
            align: 'center',
          },
          {
            title: 'Practice Name',
            dataIndex: 'title',
            key: 'title',
            align: 'center',
            width: 200,
          },
          {
            title: 'Practice Description',
            dataIndex: 'projectDescription',
            key: 'projectDescription',
            align: 'center',
            width: 400,
            render: (projectDescription, project) => {
              if (isBase64(projectDescription)) {
                return (
                  <PreviewEditor
                    value={getDecodedStatement(projectDescription)}
                    init={{
                      selector: `PR-PRT-${get(project, 'id')}`
                    }}
                  />
                )
              } else {
                return (
                  <span>{parseChatStatement({ statement: projectDescription })}</span>
                )
              }
            }
          },
          {
            title: 'Platform Thumbnail',
            dataIndex: 'title',
            key: 'title',
            align: 'center',
            width: 200,
            render: (_, record) => <PracticeView record={record} type='platform' />
          },
          {
            title: 'Create Description',
            dataIndex: 'projectCreationDescription',
            key: 'projectCreationDescription',
            align: 'center',
            width: 200,
            render: (projectCreationDescription, project) => {
              if (projectCreationDescription) {
                if (isBase64(projectCreationDescription)) {
                  return (
                    <PreviewEditor
                      value={getDecodedStatement(projectCreationDescription)}
                      init={{
                        selector: `PR-PRTCD-${get(project, 'id')}`
                      }}
                    />
                  )
                }
                if (get(project, 'externalDescriptionEnabled', false)) {
                  return <Button onClick={() => this.setState({
                    isProjectDescViewModalVisible: true, iframeViewData: {
                      id: get(project, 'id'),
                      projectCreationDescription,
                      embedViewHeight: get(project, 'embedViewHeight'),
                      key: 'projectCreationDescription'
                    }
                  })}>Preview Doc</Button>
                }
                return (
                  <span>{parseChatStatement({ statement: projectCreationDescription })}</span>
                )
              }
              return '-'
            }
          },
          {
            title: 'Answer Description',
            dataIndex: 'answerFormatDescription',
            key: 'answerFormatDescription',
            align: 'center',
            width: 200,
            render: (answerFormatDescription, project) => {
              if (answerFormatDescription) {
                if (get(project, 'answerFormat') === 'answerGoogleEmbedLink') {
                  return <Button onClick={() => this.setState({
                    isAnswerViewModalVisible: true, iframeViewData: {
                      id: get(project, 'id'),
                      answerFormatDescription,
                      answerFormatViewHeight: get(project, 'answerFormatViewHeight'),
                      key: 'answerFormatDescription'
                    }
                  })}>Preview Doc</Button>
                } else if (get(project, 'answerFormat') === 'answerContent') {
                  if (isBase64(answerFormatDescription)) {
                    return (
                      <PreviewEditor
                        value={getDecodedStatement(answerFormatDescription)}
                        init={{
                          selector: `PR-PRTCD-${get(project, 'id')}`
                        }}
                      />
                    )
                  } else return <span>{parseChatStatement({ statement: answerFormatDescription })}</span>
                }
              }
              return "-"
            }
          },
          {
            title: 'Create Link',
            dataIndex: 'externalPlatformLink',
            key: 'externalPlatformLink',
            align: 'center',
            render: (_, record) => <PracticeView record={record} type='externalPlatformLink' />
          },
          {
            title: 'Submit Description',
            dataIndex: 'answerDescription',
            key: 'answerDescription',
            align: 'center',
            width: 200,
            render: (answerDescription) => (
              <div style={descStyle}>
                {parseChatStatement({ statement: answerDescription || '' })}
              </div>
            )
          },
          {
            title: 'Is Submit Answer',
            dataIndex: 'isSubmitAnswer',
            key: 'isSubmitAnswer',
            align: 'center',
            width: 200,
            render: (isSubmitAnswer) => isSubmitAnswer === true ? 'Yes' : 'No'
          },
          {
            title: 'Assign Course & Topic',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 400,
            render: (_, record) => (
              <AssignedView
                record={record}
                onAssignClick={() => this.onOpenAssignModal(record)}
              />
            )
          },
          {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            width: 150,
            render: (createdAt) => moment(createdAt).format('ll')
          },
          {
            title: 'Publish Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (status, record) => (
              <PublishSwitcher status={status}
                isTopicExist={this.isTopicExist}
                isHomeworkActive={this.props.isHomeworkActive}
                projectId={record.id}
              />
            )
          },
          {
            title: 'Edit',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            render: (_, record) => (
              <MainTable.ActionItem.IconWrapper>
                <MainTable.ActionItem.EditIcon onClick={() => opneEditModal(record)} />
              </MainTable.ActionItem.IconWrapper>
            )
          },
          {
            title: 'Delete',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            render: (id) => (
              <MainTable.ActionItem.IconWrapper>
                <Popconfirm
                  title='Do you want to delete this Practice ?'
                  placement='topRight'
                  onConfirm={async () => {
                    if (this.isTopicExist(id)) {
                        return message.error('This seems to be linked to a topic. Remove the topic and try again.')
                    }
                    else {
                      await deleteContentProject({
                        projectId: id,
                        key: this.props.isHomeworkActive ? 'homeworkPractice' : 'practice',
                      })
                    }
                  }}
                  okText='Yes'
                  cancelText='Cancel'
                  key='delete'
                  overlayClassName='popconfirm-overlay-primary'
                >
                  <MainTable.ActionItem.IconWrapper>
                    <MainTable.ActionItem.DeleteIcon />
                  </MainTable.ActionItem.IconWrapper>
                </Popconfirm>
              </MainTable.ActionItem.IconWrapper>
            )
          }
        ]
        this.setState({
          columns
        })
      }
    })
  }
  onDescModalClose = () => {
    this.setState({
      isProjectDescViewModalVisible: false,
      iframeViewData: null,
    })
  }
  onAnswerModalClose = () => {
    this.setState({
      isAnswerViewModalVisible: false,
      iframeViewData: null,
    })
  }
  render() {
    const { columns, tableData, openAssignModal, assignModalData, isProjectDescViewModalVisible, iframeViewData, isAnswerViewModalVisible } = this.state
    let changedColumn = columns
    if(columns.length>0){
      changedColumn[2].render = (projectDescription, project) => {
        if (isBase64(projectDescription)) {
          return (
            <PreviewEditor
              value={getDecodedStatement(projectDescription)}
              init={{
                selector: `PR-PRT-${get(project, 'id')}`
              }}
            />
          )
        } else {
          return (
            <span>{parseChatStatement({ statement: projectDescription })}</span>
          )
        }
      }
    }

    const { practiceFetchingStatus, practiceUpdateStatus,
      coursesList, coursesFetchStatus, tableLoading } = this.props
     let arr = tableData.length>0 && tableData.map(tableItem=>{
      (Object.keys(tableItem).map((key) => {
        if(key === 'projectDescription'){
          tableItem[key] = tableItem[key]
        }
      }))
      return tableItem
     })
    return (
      <>
        <AssignModal
          openAssignModal={openAssignModal}
          assignModalData={assignModalData}
          coursesList={coursesList}
          saveLoading={practiceUpdateStatus && get(practiceUpdateStatus.toJS(), 'loading')}
          coursesFetchStatus={coursesFetchStatus && get(coursesFetchStatus.toJS(), 'loading')}
          componentName={this.props.isHomeworkActive ? HOMEWORK_PRACTICE : PRACTICE}
          onCloseAssignModal={this.onCloseAssignModal}
        />
        {isProjectDescViewModalVisible && <IframePreview
          openIframePreview={isProjectDescViewModalVisible}
          iframeViewData={iframeViewData}
          onCloseModal={this.onDescModalClose}
          key={'projectCreationDescription'}
          usedFor={'projectCreationDescription'}
          keyValue={this.props.isHomeworkActive ? 'homeworkPractice' : 'practice'}
        />}
        {isAnswerViewModalVisible && <IframePreview
          openIframePreview={isAnswerViewModalVisible}
          iframeViewData={iframeViewData}
          onCloseModal={this.onAnswerModalClose}
          key={'answerFormatDescription'}
          usedFor={'answerFormatDescription'}
          keyValue={this.props.isHomeworkActive ? 'homeworkPractice' : 'practice'}
        />}
        <MDTable
          columns={columns}
          dataSource={arr}
          loading={tableLoading && practiceFetchingStatus && get(practiceFetchingStatus.toJS(), 'loading')}
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      </>
    )
  }
}

export default PracticeTable
