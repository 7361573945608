import gql from 'graphql-tag'
import { get } from 'lodash'
import { notification } from 'antd'
import requestToGraphql from '../../utils/requestToGraphql'


const queryOTP = async (phone, countryCode) => {
  const otpvalue = await requestToGraphql(gql`
    mutation {
    sendForgotPasswordOTP(input: { number: "${phone}", countryCode: "+${countryCode}" }) {
      result
    }
  }`).catch((error) => {
    const errorMessage = get(error, 'errors[0].message')
    notification.error({ message: errorMessage })
  })
  return get(otpvalue, 'data.sendForgotPasswordOTP.result')
}

const queryEmailLink = async (email) => {
  const emailvalue = await requestToGraphql(gql`
    mutation {
      sendForgotPasswordLink(email: "${email}") {
        result
      }
    }`).catch((error) => {
    const errorMessage = get(error, 'errors[0].message')
    notification.error({ message: errorMessage })
  })
  return get(emailvalue, 'data.sendForgotPasswordLink.result')
}

const queryResent = async (phone, countryCode) => {
  const otpresentvalue = await requestToGraphql(gql`
      mutation{
        resendForgotPasswordOTP(input:{number:"${phone}", countryCode:"+${countryCode}"}){
          result
        }
      }`).catch((error) => {
    const errorMessage = get(error, 'errors[0].message')
    notification.error({ message: errorMessage })
  })
  return get(otpresentvalue, 'data.resendForgotPasswordOTP.result')
}

const queryValidateOTP = async (phone, countryCode, otp) => {
  const otpvalidatevalue = await requestToGraphql(gql`
      mutation {
        validateForgotPasswordOTP(
          input: { number: "${phone}", countryCode: "+${countryCode}" }
          phoneOtp:${otp}
        ) {
          result
        }
      }`).catch((error) => {
    const errorMessage = get(error, 'errors[0].message')
    notification.error({ message: errorMessage })
  })
  return get(otpvalidatevalue, 'data.validateForgotPasswordOTP.result')
}

export { queryValidateOTP, queryEmailLink, queryOTP, queryResent }
