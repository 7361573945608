import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../../duck'
import getIdArrForQuery from '../../../utils/getIdArrForQuery'
import getSlotNames from '../../../utils/slots/slot-names'

const updateMenteeSessionForDemand = async (
  { sessionId, broadCastedMentorsConnectIds = [], input }
) => duck.query({
  query: gql`mutation($input: MenteeSessionUpdate) {
  updateMenteeSession(id: "${sessionId}",
  ${broadCastedMentorsConnectIds.length > 0 ?
    `broadCastedMentorsConnectIds: [${getIdArrForQuery(broadCastedMentorsConnectIds)}]`
    : ''},
  input: $input) {
    id
        user{
        id
        studentProfile{
            batch{
            id
            }
        }
        }
        course {
        id
        title
        thumbnail {
            uri
        }
        }
        topic {
        id
        title
        order
        }
    bookingDate
    createdAt
    ${getSlotNames()}
    mentorAvailabilitySlot {
        id
    }
    broadCastedMentors {
        id
    }
  }
}
`,
  variables: {
    input,
  },
  type: 'menteeSessionsForDemand/update',
  key: 'menteeSessionsForDemand',
  changeExtractedData: (extractedData, originalData) => {
    if (originalData && get(originalData, 'updateMenteeSession')) {
      extractedData.batch = []
      extractedData.course = []
      extractedData.mentorSessions = []
      extractedData.topic = []
      extractedData.user = []
      extractedData.menteeSessionsForDemand = {
        ...get(originalData, 'updateMenteeSession')
      }
      return { ...extractedData }
    }
    return { ...extractedData }
  }
})

export default updateMenteeSessionForDemand
