import { Select, Spin, Tooltip } from 'antd'
import { get } from 'lodash'
import React from 'react'

const AssignSelectInput = (props) => {
  const { placeholder, value, loading, onSelect, dataArray } = props
  const divStyle = {
    margin: '8px 0'
  }
  const filterOption = (input, option) => (
    get(option, 'props.children.props.children')
      ? get(option, 'props.children.props.children')
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
      : false
  )
  const getLoaderData = () => {
    if (loading) return <Spin size='small' />
    return <p>No Data found</p>
  }
  return (
    <div style={divStyle}>
      <h3>{placeholder}</h3>
      <Select
        showSearch
        placeholder={placeholder}
        filterOption={filterOption}
        notFoundContent={getLoaderData()}
        value={value}
        loading={loading}
        onSelect={onSelect}
        style={{ width: '100%' }}
      >
        {
          dataArray.map(item =>
            <Select.Option
              value={get(item, 'id')}
              key={get(item, 'id')}
            >
              <Tooltip title={get(item, 'title')}>{get(item, 'title')}</Tooltip>
            </Select.Option>
          )
        }
      </Select>
    </div>
  )
}

export default AssignSelectInput
