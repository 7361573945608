import gql from 'graphql-tag'
import duck from '../../duck';


const fetchCommsVariables = async () => {
    await duck.query({
        query: gql`
       query {
        commsVariables {
          id
          emailVariableName
          whatsappVariableName
          dataField
        }
      }`,
        type: 'commsVariables/fetch',
        key: 'commsVariables',

    })
}

export default fetchCommsVariables