import { Switch } from 'antd';
import React, { Component } from 'react';
import { DivFlexStyle } from '../TeachersTab.styles';


export default class CourseControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AccessCourseControl: 0,
            CreateTests: 0,
            EvaluateTests: 0,
            AddToQBanks: 0,
        }
    }
    render() {
        const { schoolName, mentorProfileRowData, setMentorProfileRowData } = this.props;
        const buttonType = {
            type1: '',
            type2: '',
            type3: 'primary',
            type4: '',
        }
        return (
            <>
                <div style={{ margin: "20px" }}>
                    <DivFlexStyle>
                        <div>Access Course Control</div>
                        <Switch onChange={() => {
                            const obj = {
                                Name: mentorProfileRowData.Name,
                                Email: mentorProfileRowData.Email,
                                Phone: mentorProfileRowData.Phone,
                                setPassword: mentorProfileRowData.setPassword,
                                confirmPassword: mentorProfileRowData.confirmPassword,
                                SelectGrades: mentorProfileRowData.SelectGrades,
                                SelectGradesId:mentorProfileRowData.SelectGradesId,
                                AccessType: mentorProfileRowData.AccessType,
                                displayName: mentorProfileRowData.displayName,
                                CountryCode:mentorProfileRowData.CountryCode,
                                SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
                                SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
                                ReschedulingClass: mentorProfileRowData.ReschedulingClass,
                                SchedulingClass: mentorProfileRowData.SchedulingClass,
                                DeleteSessions: mentorProfileRowData.DeleteSessions,

                                CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
                                SendNotices: mentorProfileRowData.SendNotices,
                                AddInviteStudent: mentorProfileRowData.AddInviteStudent,
                                AccessRecordings: mentorProfileRowData.AccessRecordings,
                                LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

                                AccessCourseControl: mentorProfileRowData.AccessCourseControl === 0 ? 1 : 0,
                                CreateTests: mentorProfileRowData.CreateTests,
                                EvaluateTests: mentorProfileRowData.EvaluateTests,
                                AddToQBanks: mentorProfileRowData.AddToQBanks,

                                AccessReports: mentorProfileRowData.AccessReports,
                                DownloadReports: mentorProfileRowData.DownloadReports,
                                ShareReports: mentorProfileRowData.ShareReports,
                            }
                            setMentorProfileRowData(obj)
                        }}
                            checked={mentorProfileRowData.AccessCourseControl === 0 ? true : false}
                        />
                    </DivFlexStyle>
                    <DivFlexStyle>
                        <div>Create Tests</div>
                        <Switch onChange={() => {
                            const obj = {
                                Name: mentorProfileRowData.Name,
                                Email: mentorProfileRowData.Email,
                                Phone: mentorProfileRowData.Phone,
                                setPassword: mentorProfileRowData.setPassword,
                                confirmPassword: mentorProfileRowData.confirmPassword,
                                SelectGrades: mentorProfileRowData.SelectGrades,
                                SelectGradesId:mentorProfileRowData.SelectGradesId,
                                AccessType: mentorProfileRowData.AccessType,
                                displayName: mentorProfileRowData.displayName,
                                CountryCode:mentorProfileRowData.CountryCode,
                                SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
                                SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
                                ReschedulingClass: mentorProfileRowData.ReschedulingClass,
                                SchedulingClass: mentorProfileRowData.SchedulingClass,
                                DeleteSessions: mentorProfileRowData.DeleteSessions,

                                CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
                                SendNotices: mentorProfileRowData.SendNotices,
                                AddInviteStudent: mentorProfileRowData.AddInviteStudent,
                                AccessRecordings: mentorProfileRowData.AccessRecordings,
                                LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

                                AccessCourseControl: mentorProfileRowData.AccessCourseControl,
                                CreateTests: mentorProfileRowData.CreateTests === 0 ? 1 : 0,
                                EvaluateTests: mentorProfileRowData.EvaluateTests,
                                AddToQBanks: mentorProfileRowData.AddToQBanks,

                                AccessReports: mentorProfileRowData.AccessReports,
                                DownloadReports: mentorProfileRowData.DownloadReports,
                                ShareReports: mentorProfileRowData.ShareReports,
                            }
                            setMentorProfileRowData(obj)
                        }}
                            checked={mentorProfileRowData.CreateTests === 0 ? true : false}
                        />
                    </DivFlexStyle>
                    <DivFlexStyle>
                        <div>Evaluate Tests</div>
                        <Switch onChange={() => {
                            const obj = {
                                Name: mentorProfileRowData.Name,
                                Email: mentorProfileRowData.Email,
                                Phone: mentorProfileRowData.Phone,
                                setPassword: mentorProfileRowData.setPassword,
                                confirmPassword: mentorProfileRowData.confirmPassword,
                                SelectGrades: mentorProfileRowData.SelectGrades,
                                SelectGradesId:mentorProfileRowData.SelectGradesId,
                                AccessType: mentorProfileRowData.AccessType,
                                displayName: mentorProfileRowData.displayName,
                                CountryCode:mentorProfileRowData.CountryCode,
                                SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
                                SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
                                ReschedulingClass: mentorProfileRowData.ReschedulingClass,
                                SchedulingClass: mentorProfileRowData.SchedulingClass,
                                DeleteSessions: mentorProfileRowData.DeleteSessions,

                                CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
                                SendNotices: mentorProfileRowData.SendNotices,
                                AddInviteStudent: mentorProfileRowData.AddInviteStudent,
                                AccessRecordings: mentorProfileRowData.AccessRecordings,
                                LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

                                AccessCourseControl: mentorProfileRowData.AccessCourseControl,
                                CreateTests: mentorProfileRowData.CreateTests,
                                EvaluateTests: mentorProfileRowData.EvaluateTests === 0 ? 1 : 0,
                                AddToQBanks: mentorProfileRowData.AddToQBanks,

                                AccessReports: mentorProfileRowData.AccessReports,
                                DownloadReports: mentorProfileRowData.DownloadReports,
                                ShareReports: mentorProfileRowData.ShareReports,
                            }
                            setMentorProfileRowData(obj)
                        }}
                            checked={mentorProfileRowData.EvaluateTests === 0 ? true : false}
                        />
                    </DivFlexStyle>
                    <DivFlexStyle>
                        <div>Add to Q Banks</div>
                        <Switch onChange={() => {
                            const obj = {
                                Name: mentorProfileRowData.Name,
                                Email: mentorProfileRowData.Email,
                                Phone: mentorProfileRowData.Phone,
                                setPassword: mentorProfileRowData.setPassword,
                                confirmPassword: mentorProfileRowData.confirmPassword,
                                SelectGrades: mentorProfileRowData.SelectGrades,
                                SelectGradesId:mentorProfileRowData.SelectGradesId,
                                AccessType: mentorProfileRowData.AccessType,
                                displayName: mentorProfileRowData.displayName,
                                CountryCode:mentorProfileRowData.CountryCode,
                                SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
                                SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
                                ReschedulingClass: mentorProfileRowData.ReschedulingClass,
                                SchedulingClass: mentorProfileRowData.SchedulingClass,
                                DeleteSessions: mentorProfileRowData.DeleteSessions,

                                CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
                                SendNotices: mentorProfileRowData.SendNotices,
                                AddInviteStudent: mentorProfileRowData.AddInviteStudent,
                                AccessRecordings: mentorProfileRowData.AccessRecordings,
                                LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

                                AccessCourseControl: mentorProfileRowData.AccessCourseControl,
                                CreateTests: mentorProfileRowData.CreateTests,
                                EvaluateTests: mentorProfileRowData.EvaluateTests,
                                AddToQBanks: mentorProfileRowData.AddToQBanks === 0 ? 1 : 0,

                                AccessReports: mentorProfileRowData.AccessReports,
                                DownloadReports: mentorProfileRowData.DownloadReports,
                                ShareReports: mentorProfileRowData.ShareReports,
                            }
                            setMentorProfileRowData(obj)
                        }}
                            checked={mentorProfileRowData.AddToQBanks === 0 ? true : false}
                        />
                    </DivFlexStyle>
                </div>
            </>
        )
    }
}
