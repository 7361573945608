import gql from 'graphql-tag'
import duck from '../../duck'
import getIdArrForQuery from '../../utils/getIdArrForQuery'


const addTrainingBatch = async ({ input, schoolConnectId,
    allottedMentorConnectId, coursePackageConnectId,
    courseConnectId, studentIds, classesConnectIds, inheritedFromConnectId,
    coursePackageCoursesConnectIds, batchStudentsConnectIds, academicYearConnectId }) =>
  duck.query({
    query: gql`
   mutation($input: BatchInput!) {
  addBatch(
    input: $input
    schoolConnectId: "${schoolConnectId}"
    ${allottedMentorConnectId ? `allottedMentorConnectId: "${allottedMentorConnectId}"` : ''}
    ${coursePackageConnectId ? `coursePackageConnectId: "${coursePackageConnectId}"` : ''}
    academicYearConnectId: "${academicYearConnectId}"
    ${courseConnectId ? `courseConnectId: "${courseConnectId}"` : ''}
    ${(studentIds && studentIds.length) ? `studentsConnectIds: [${getIdArrForQuery(studentIds)}]` : ''}
    ${(classesConnectIds && classesConnectIds.length) ? `classesConnectIds: [${getIdArrForQuery(classesConnectIds)}]` : ''}
    ${inheritedFromConnectId ? `inheritedFromConnectId: "${inheritedFromConnectId}"` : ''}
    ${(coursePackageCoursesConnectIds && coursePackageCoursesConnectIds.length) ? `coursePackageCoursesConnectIds: [${getIdArrForQuery(coursePackageCoursesConnectIds)}]` : ''}
    ${(batchStudentsConnectIds && batchStudentsConnectIds.length) ? `batchStudentsConnectIds: [${getIdArrForQuery(batchStudentsConnectIds)}]` : ''}
  ) {
    id
    code
    classroomTitle
    createdAt
    description
    classes {
      id
      grade
      section
    }
    allottedMentor {
      id
      name
    }
    studentsMeta {
      count
    }
    coursePackage {
      id
      title
      internalName
    }
    inheritedFrom {
      id
    }
  }
  }
    `,
    variables: {
      input,
    },
    type: 'batch/add',
    key: 'trainingBatches',
  })

export default addTrainingBatch
