import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'
import getActionsError from '../../utils/getActionsError'
import requestToGraphql from '../../utils/requestToGraphql'

const fetchThroughNumber = async (phNo) => duck.query({
  query: gql`
    {
      studentProfiles(
        filter: {
          parents_some: { user_some: { phone_number_subDoc_contains: "${phNo}" } }
        }
      ) {
        id
        user {
          id
          name
          profilePic {
            id
            uri
          }
        }
        parents {
          id
          user {
            id
            phone {
              countryCode
              number
            }
          }
        }
      }
    }
  `,
  type: 'studentProfiles/fetch',
  key: 'studentProfiles',
  changeExtractedData: (extractedData, originalData) => {
    extractedData.studentProfiles=get(originalData,'studentProfiles');
    return { ...extractedData }
  }
})


export default fetchThroughNumber


