import gql from 'graphql-tag'
import duck from '../../duck'

const addAcademicYear = async ({ input, schoolConnectId }) =>
  duck.query({
    query: gql`
    mutation($input: AcademicYearInput!) {
        addAcademicYear(input: $input,
          ${schoolConnectId ? `schoolConnectId: "${schoolConnectId}"` : ''}
          ) {
            id
        }
    }
    `,
    variables: {
      input,
    },
    type: 'academicYear/add',
    key: 'academicYear',
  })

export default addAcademicYear
