import gql from 'graphql-tag'
import duck from '../../duck'

const mentorBatchEarningsQuery = (userId, skip) => `
batchSessions(orderBy: createdAt_DESC, first: 5, skip: ${skip}, filter: {and: [{batch_some:{allottedMentor_some: {id_in: ["${userId}"]}}}, {sessionStatus_in: [completed]}, {topic_some: {order_not: 1}}]}) {
    id
    sessionStartDate
    sessionStatus
    bookingDate
    topic {
      title
      order
    }
    attendance {
      isPresent
    }
    sessionCommentByMentor
    sessionRecordingLink
    BatchInfo: batch {
      id
      code
      type
      allottedMentor {
        id
        username
        name
      }
      students {
        id
      }
    }
  }
  batchSessionsMeta(filter: {and: [{batch_some:{allottedMentor_some: {id_in: ["${userId}"]}}}, {sessionStatus_in: [completed]}, {topic_some: {order_not: 1}}]}){
    count
  }
`

const fetchMentorBatchEarnings = async (
  userId,
  skip
) => duck.query({
  query: gql`
     query {
       ${mentorBatchEarningsQuery(userId, skip)}
    }`,
  type: 'mentorBatchEarnings/fetch',
  key: 'mentorEarningsForMentorDashboardEarnings',
})

export default fetchMentorBatchEarnings
