/* eslint-disable*/
import { get } from 'lodash'
import React, { useState, useRef, useEffect } from 'react'
import getFullPath from '../../../../../utils/getFullPath'
import getConvertedDate from '../../../utils/getDate'
import { databaseIcon, pendingSvg, successSvg } from '../../CoursesCard/assets'
import '../payments.scss'

export default function PaymentCard(props) {
    const { paymentReport } = props
    const [showPaymentDetails, setShowPaymentDetails] = useState(false)
    const progressRef = useRef()
    const databaseLogo = databaseIcon()
    const pendingLogo = pendingSvg()
    const successLogo = successSvg()
        
    const showPaymentDetailsHandler = () => {
        setShowPaymentDetails(!showPaymentDetails)
    }

    useEffect(() => {        
        if(paymentReport){           
            let numberOfPaidInstallments = 0
            get(paymentReport, 'userPaymentPlan.userPaymentInstallments').map((singleInstallment) => {
                if (get(singleInstallment,'status') === 'paid') {
                    numberOfPaidInstallments++
                }
            })
            if(numberOfPaidInstallments === 0){
                progressRef.current.style.width = '0%'
            } else {
                progressRef.current.style.width = `${(numberOfPaidInstallments / get(paymentReport, 'userPaymentPlan.userPaymentInstallments.length')) * 100}%`
            }
        }
    }, [paymentReport])

    const paidInstallmentsHandler = () => {
        if (paymentReport) {
            let paidInstallments = 0
            get(paymentReport, 'userPaymentPlan.userPaymentInstallments').map((singleInstallment) => {
                if (get(singleInstallment, 'status') === 'paid') {
                    paidInstallments++
                }
            })
            return paidInstallments
        }
    }

    const paymentActualDateHandler = (singleInstallment) => {
        if(paymentReport){
            if(get(singleInstallment, 'status') === 'paid'){
                return getConvertedDate(get(singleInstallment, 'paidDate'))
            }
            else {
                if(get(singleInstallment, 'userPaymentLink.id')){
                    return 'Link Sent'
                }
                else{
                    return 'Link not sent'
                }
            }
        }
    }
    
    const nextPaymentDateHandler = () => {
        let paidInstallments = []
        if(paymentReport){
            get(paymentReport,'userPaymentPlan.userPaymentInstallments').map((singleInstallment,index) => {
            if(get(singleInstallment, 'status') === 'paid'){
                paidInstallments.push({singleInstallment, index})
            }            
            })
            if (paidInstallments.length === 0){
                return getConvertedDate(get(paymentReport, 'userPaymentPlan.userPaymentInstallments[0].dueDate'))
            }
            else if(paidInstallments.length > 0 && paidInstallments.length < get(paymentReport, 'userPaymentPlan.userPaymentInstallments').length){
                let lastPaidIndex = get(paidInstallments[paidInstallments.length - 1], 'index')
                return getConvertedDate(get(paymentReport,`userPaymentPlan.userPaymentInstallments[${lastPaidIndex}].dueDate`))
            }
            else if(paidInstallments.length === get(paymentReport, 'userPaymentPlan.userPaymentInstallments').length){
                return 'Not Applicable'
            }
        }
    }

    return (
        <div className="paymentsSectionContainer">
            <div className="paymentsTopSectionWrapper">
                <div className="paymentsTopSectionImgContainer">
                    <img src={getFullPath(get(paymentReport,'course.thumbnail.uri'))} alt="" />
                </div>
                <div className="paymentsTopSectionTextContainer">
                    <h3>{getFullPath(get(paymentReport,'course.title'))}</h3>
                    <p style={{ fontSize: "12px", color: "#757575" }}>Next payment date: <span style={{ color: "#333333", fontSize: "14px" }}>{nextPaymentDateHandler()}</span></p>
                </div>
                {get(paymentReport, 'userPaymentPlan.userPaymentInstallments').length>1 && <button className="downloadReportBtn" onClick={showPaymentDetailsHandler}>
                    {databaseLogo}
                    {showPaymentDetails ? 'Hide Details' : 'See Details'}
                </button>}
            </div>
            <hr className="paymentsSectionSeperator" />
            <div className="installmentStatusAndProgressStatus">
                <p>Installment Complete <span className="installmentCompleteText">{paidInstallmentsHandler()}/{get(paymentReport, 'userPaymentPlan.installmentNumber')}</span></p>
                <div className="installmentProgressBar">
                    <div className="installmentProgressBarInner" ref={progressRef}>
                    </div>
                </div>
            </div>  
            {get(paymentReport, 'userPaymentPlan.userPaymentInstallments').length === 1 && <table className="paymentsSectionTable">
                <tr>
                    <th>Scheduled</th>
                    <th>Actual Date</th>
                    <th>Status</th>
                    <th>Payment Mode</th>
                    <th>Payment type</th>
                </tr>
                {get(paymentReport, 'userPaymentPlan.userPaymentInstallments').map((singleInstallment, index) => (
                <tr>
                    <td>{get(singleInstallment, 'status') === 'pending'?getConvertedDate(get(singleInstallment,'dueDate')):getConvertedDate(get(singleInstallment, 'paidDate'))}</td>
                    <td>{paymentActualDateHandler(singleInstallment)}</td>
                    <td>
                        {get(singleInstallment, 'status') === 'pending'?<div className="paymentStatusContainer">
                            {pendingLogo}
                            <p>{get(singleInstallment, 'status')}</p>
                        </div>:
                        <div className="paymentStatusContainer">
                        {successLogo}
                        <p>{get(singleInstallment, 'status')}</p>
                    </div>}                       
                    </td>
                    <td>
                        <div className="paymentModeContainer">
                            <p>PayU</p>
                        </div>
                    </td>
                    <td>One Time</td>
                </tr>                                 
                ))}                
            </table>}          
            {showPaymentDetails && get(paymentReport, 'userPaymentPlan.userPaymentInstallments').length > 1 && <table className="paymentsSectionTable">
                <tr>
                    <th>Scheduled</th>
                    <th>Actual Date</th>
                    <th>Status</th>
                    <th>Payment Mode</th>
                    <th>Payment type</th>
                </tr>
                {paymentReport && get(paymentReport, 'userPaymentPlan.userPaymentInstallments').map((singleInstallment, index) => (
                <tr>
                    <td>{get(singleInstallment, 'status') === 'pending'?getConvertedDate(get(singleInstallment, 'dueDate')):getConvertedDate(get(singleInstallment, 'paidDate'))}</td>
                    <td>{paymentActualDateHandler(singleInstallment)}</td>
                    <td>
                        {get(singleInstallment, 'status') === 'pending'?<div className="paymentStatusContainer">
                            {pendingLogo}
                            <p>{get(singleInstallment, 'status')}</p>
                        </div>:
                        <div className="paymentStatusContainer">
                        {successLogo}
                        <p>{get(singleInstallment, 'status')}</p>
                    </div>}
                    </td>
                    <td>
                        <div className="paymentModeContainer">
                            <p>PayU</p>
                        </div>
                    </td>
                    <td>Installment</td>
                    
                </tr>                                 
                ))}                
            </table>}
        </div>
    )
}
