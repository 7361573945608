import gql from 'graphql-tag'
import duck from '../../duck'

const createLoginLink = async (filterQuery, fetchCount) =>
  duck.query({
    query: gql`
    {
      getMagicLink(${filterQuery}) {
      linkToken
      expiresIn
      linkUri
      }
    }
  `,
    type: 'createLoginLink/fetch',
    key: `createLoginLink/${fetchCount}`
  })


export default createLoginLink
