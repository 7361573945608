import React, { Component, createRef } from 'react';
import fetchThroughNumber from '../../../../actions/eventCompletion/fetchThroughNumber';
import Dropzone from "../../../../components/Dropzone";
import {
    CenterDiv, FlexContainer, NameStyle, PrizeInputStyle,
    PhoneNumberInputStyle,
    Errorline,
    RequiredStyle,
    PhotoUploadError,
    IndexDiv
} from '../../EventCompletion.style';
import { withRouter } from "react-router-dom";
import { CloseOutlined } from '@ant-design/icons';
import getFullPath from '../../../../utils/getFullPath';
import { debounce, get } from 'lodash';
import { Form, Input, notification, Select, Spin } from 'antd';

class WinnerDetails extends Component {
    constructor(props) {
        super(props);
        this.dropzoneRef = createRef(null);
        this.state = {
            nameErrorRequired: 0,
            PrizeErrorRequired: 0,
            NumberErrorRequired: 0,
            webUrl: '',
            webError: '',
            webImage: '',
            hover: false,
            searchNum: '',
            phoneNumberDataArray: [],
            phoneNumberDataId: [],
            isPhoneNumberLoading:false,
        };
    }
    componentDidMount = () => {
        const { prizeDatas, ToggleWhenPrizeNull, winnerList, phoneNumberData } = this.props;
        if ((prizeDatas === null || (prizeDatas !== null && prizeDatas.length === 0))) {
            ToggleWhenPrizeNull()
        }
    }
    componentDidUpdate = async (prevProps, prevState) => {
        const { phoneNumberData, phoneNumberDataLoading, winnerList,index } = this.props;
        const { phoneNumberDataArray, phoneNumberDataId } = this.state;
        if(prevProps.index!==index){
            this.setState({
                phoneNumberDataArray:[],
            })
        }
        if (phoneNumberDataLoading && !get(phoneNumberDataLoading, 'studentProfiles.loading')
            && get(phoneNumberDataLoading, 'studentProfiles.success')
            && prevProps.phoneNumberDataLoading !== phoneNumberDataLoading) {
                this.setState({
                    isPhoneNumberLoading:true,
                })
            // const existingWinnerName = phoneNumberDataId.includes(id, 0);
            let filterPhoneNumberId = [];
            filterPhoneNumberId=winnerList.map((wData) => {

                return wData.studentProfileId
            })
            let filterPhoneNumberData =[];
            filterPhoneNumberData= phoneNumberData.filter((phNo) => {
                return !filterPhoneNumberId.includes(phNo.id)
            })
            this.setState({
                phoneNumberDataArray: filterPhoneNumberData,
            })
            setTimeout(()=>{
                this.setState({
                    isPhoneNumberLoading:false,
                })
            },1000)
            

        }
     
    }

    // nameChange = (name, winnerList, index, updateOrAddWinnerDetails, prizeConnectId) => {
    //     const { fetchCount } = this.props;
    //     const winnerObject = {
    //         winnerName: name,
    //         eventWinnerId: winnerList[index].eventWinnerId,
    //         prizeWonId: winnerList[index].prizeWonId,
    //         changesMadeState: 1,
    //         prizeWon: winnerList[index].prizeWon,
    //         registeredPhoneNumber: winnerList[index].registeredPhoneNumber,
    //         prevUploadImageId: winnerList[index].prevUploadImageId,
    //         uploadImageLink: winnerList[index].uploadImageLink,
    // prevUploadImageLink: winnerList[index].prevUploadImageLink,
    //         uploadImageFileObject: winnerList[index].uploadImageFileObject,
    //         imageSizeError: winnerList[index].imageSizeError,
    //         studentProfileId: winnerList[index].studentProfileId,
    //     }
    //     const updateName = winnerList.map((list, i) => {
    //         if (i === index) {
    //             return winnerObject;
    //         }
    //         else
    //             return list;
    //     })
    //     updateOrAddWinnerDetails(updateName);
    //     if (name.length === 0) {
    //         this.setState({ nameErrorRequired: 1 })
    //         fetchCount(1);
    //     }
    //     else {
    //         this.setState({ nameErrorRequired: 0 })
    //         fetchCount(0);
    //     }
    // }

    prizeWonChange = (prizeId) => {
        const { winnerList, fetchCount, index, updateOrAddWinnerDetails, prizeDatas, prizeConnectId, mutationState, updateMutationState } = this.props;
        const prizeConnectIdArray = prizeDatas.filter((prize) => {
            return prize.id === prizeId;
        })
        let c = 0;
        for (var i = 0; i < winnerList.length; i++) {
            if (winnerList[i].prizeWonId === prizeId)
                c++;
        }
        const maxOccurence = get(prizeConnectIdArray, '[0].maxRank') - get(prizeConnectIdArray, '[0].minRank') + 1;
        if (c >= maxOccurence && winnerList[index].prizeWonId!==prizeId) {
            notification.info({
                message: `Error`,
                description:
                    `${get(prizeConnectIdArray, '[0].title')} cannot be given to more than ${maxOccurence} people....!!!!`,
            });
        }
        const winnerObject = {
            winnerName: winnerList[index].winnerName,
            eventWinnerId: winnerList[index].eventWinnerId,
            prizeWonId: c < maxOccurence ? get(prizeConnectIdArray, '[0].id') :winnerList[index].prizeWonId,
            changesMadeState: 1,
            prizeWon: c < maxOccurence ? get(prizeConnectIdArray, '[0].title') : winnerList[index].prizeWon,
            registeredPhoneNumber: winnerList[index].registeredPhoneNumber,
            prevUploadImageId: winnerList[index].prevUploadImageId,
            uploadImageLink: winnerList[index].uploadImageLink,
            prevUploadImageLink: winnerList[index].prevUploadImageLink,
            uploadImageFileObject: winnerList[index].uploadImageFileObject,
            imageSizeError: winnerList[index].imageSizeError,
            studentProfileId: winnerList[index].studentProfileId,
        }
        const UpdatePrize = winnerList.map((list, i) => {
            if (i === index) {
                return winnerObject;
            }
            else
                return list;
        })
        updateOrAddWinnerDetails(UpdatePrize);

        prizeConnectId(prizeConnectIdArray.length !== 0 ? prizeConnectIdArray[0].id : "");
        const mutationobj = {
            prizeConnectIdArray: get(prizeConnectIdArray, '[0].id'),
            studentProfileId: mutationState[index].studentProfileId !== undefined ? mutationState[index].studentProfileId : "",
            eventConnectId: mutationState[index].eventConnectId,
        }
        const mObj = mutationState.map((mState, i) => {
            if (i === index) {
                return mutationobj;
            }
            else
                return mState;
        })
        updateMutationState(mObj);
        if (prizeId.length === 0) {
            this.setState({ PrizeErrorRequired: 1 })
            fetchCount(1);
        }
        else {
            this.setState({ PrizeErrorRequired: 0 })
            fetchCount(0);
        }


    }
    registeredPhoneNumberChange = (id) => {
        const { phoneNumberDataId } = this.state;
        const { winnerList, index, updateOrAddWinnerDetails, phoneNumberData } = this.props;
        const studentProfile = phoneNumberData && phoneNumberData.find((student) => get(student, 'id') === id)
        if (studentProfile) {
            const data = {
                studentProfileId: id,
                changesMadeState:1,
                winnerName: get(studentProfile, 'user.name'),
                registeredPhoneNumber: get(studentProfile, 'parents[0].user.phone.number')
            }
            const newWinnersData = winnerList.map((winnerData, i) => {
                if (i === index) {
                    return { ...winnerData, ...data };
                }
                return winnerData;
            })
            updateOrAddWinnerDetails(newWinnersData);


        }
     
    }
    searchStudent = (phoneNumber) => {
        if (isNaN(phoneNumber)) return false
        this.setState({
            searchNum: phoneNumber,
            isPhoneNumberLoading:true,
        }, () => {
            if (phoneNumber.length >= 3) {
                debounce(() => {
                    fetchThroughNumber(phoneNumber)
                }, 800)()
            }
        })
    }
    deleteWinner = (winnerList, index, updateOrAddWinnerDetails, mutationState, updateMutationState, deleteEventWinnersDetails) => {
        const winnerObject = {
            winnerName: winnerList[index].winnerName,
            eventWinnerId: winnerList[index].eventWinnerId,
            prizeWonId: winnerList[index].prizeWonId,
            changesMadeState: 1,
            prizeWon: winnerList[index].prizeWon,
            registeredPhoneNumber: winnerList[index].registeredPhoneNumber,
            prevUploadImageId: winnerList[index].prevUploadImageId,
            prevUploadImageLink: winnerList[index].prevUploadImageLink,
            uploadImageLink: null,
            uploadImageFileObject: null,
            imageSizeError: winnerList[index].imageSizeError,
            studentProfileId: winnerList[index].studentProfileId,
        }
        const Updateobj = winnerList.map((list, i) => {
            if (i === index) {
                return winnerObject;
            }
            else
                return list;
        })
        updateOrAddWinnerDetails(Updateobj);
        const deleteWinnerObj = Updateobj.filter((wdata, i) => {
            return (i !== index);
        })
        updateOrAddWinnerDetails(deleteWinnerObj);
        const deleteMutationObj = mutationState.filter((mState, i) => {
            return (i !== index);
        })
        updateMutationState(deleteMutationObj);
        deleteEventWinnersDetails(index);
    
    }
    onDropWeb = (file, winnerList, index, updateOrAddWinnerDetails) => {
        const { webUrl }=this.state;
        if (file) {
            const File = file;
            const img = new Image()
            img.src = window.URL.createObjectURL(File)
            img.onload = () => {
                if (img && img.width === 200 && img.height === 200) {
                    this.setState({
                        webError: '',
                        webImage: File,
                    })
                } else {
                    this.setState({
                        webError: 'Recommended Size: 200 x 200',
                        webImage: File,
                    })
                }
                const winnerObject = {
                    winnerName: winnerList[index].winnerName,
                    eventWinnerId: winnerList[index].eventWinnerId,
                    prizeWonId: winnerList[index].prizeWonId,
                    changesMadeState: 1,
                    prizeWon: winnerList[index].prizeWon,
                    registeredPhoneNumber: winnerList[index].registeredPhoneNumber,
                    prevUploadImageId: winnerList[index].prevUploadImageId,
                    uploadImageLink: img.src,
                    uploadImageFileObject: File,
                    studentProfileId: winnerList[index].studentProfileId,
                    imageSizeError: img.width === 200 && img.height === 200 ? '' : 'error',
                }
                const UpdateFileObj = winnerList.map((list, i) => {
                    if (i === index) {
                        return winnerObject;
                    }
                    else
                        return list;
                })
                updateOrAddWinnerDetails(UpdateFileObj);
            }
        }
        else{
            const winnerObject = {
                winnerName: winnerList[index].winnerName,
                eventWinnerId: winnerList[index].eventWinnerId,
                prizeWonId: winnerList[index].prizeWonId,
                changesMadeState: 1,
                prizeWon: winnerList[index].prizeWon,
                registeredPhoneNumber: winnerList[index].registeredPhoneNumber,
                prevUploadImageId: winnerList[index].prevUploadImageId,
                uploadImageLink: '',
                uploadImageFileObject: null,
                studentProfileId: winnerList[index].studentProfileId,
                imageSizeError: '',
            }
            const UpdateFileObj = winnerList.map((list, i) => {
                if (i === index) {
                    return winnerObject;
                }
                else
                    return list;
            })
            updateOrAddWinnerDetails(UpdateFileObj);
        }
    }

    render() {
        const { index, winnerList, updateOrAddWinnerDetails, phoneNumberDataLoading, phoneNumberData, prizeDatas, prizeConnectId, mutationState, updateMutationState, fetchedDataCount, deleteEventWinnersDetails } = this.props;
        const { webError,webUrl, webImage, hover, phoneNumberDataId, phoneNumberDataArray,isPhoneNumberLoading } = this.state;
        return (
            <div >
                <FlexContainer>
                    <div style={{ display: "flex" }}>
                        <IndexDiv>
                            {index + 1}
                        </IndexDiv>
                        <CenterDiv style={{ marginTop: "26px" }}>
                            <div ><b>Registered Phone Number</b></div>
                            <Form>
                                <Form.Item >
                                    <Select style={{ width: "100%" }}
                                        placeholder={`Enter Mobile Number`}
                                        value={get(winnerList, `[${index}].registeredPhoneNumber`) === '' ? 'Enter Number' : get(winnerList, `[${index}].registeredPhoneNumber`)}
                                        filterOption={false}
                                        // loading={get(phoneNumberDataLoading, 'studentProfiles.loading')}
                                        onSearch={this.searchStudent}
                                        notFoundContent={isPhoneNumberLoading ? <Spin size='small' /> : null}
                                        showSearch
                                        onChange={this.registeredPhoneNumberChange}
                                        disabled={index<=fetchedDataCount?true:false}
                                    >
                                        {phoneNumberDataArray.map((data, i) => {
                                            return <Select.Option
                                                key={i}
                                                value={get(data,'id')}
                                            >
                                                {get(data,'user.name')}
                                            </Select.Option>

                                        })}
                                    </Select>
                                </Form.Item>
                            </Form>
                            {this.state.NumberErrorRequired === 1 ? (<>
                                <Errorline style={{
                                    width: "91%"
                                }}></Errorline>
                                <RequiredStyle style={{ marginLeft: "2px" }}>*required</RequiredStyle>
                            </>) : null}
                        </CenterDiv>
                        <CenterDiv>
                            <div><b>Winner Name</b></div>
                            <NameStyle type="text" placeholder="Winner Name" value={get(winnerList, `[${index}].winnerName`)} disabled />
                            {this.state.nameErrorRequired === 1 ? (<>
                                <Errorline></Errorline>
                                <RequiredStyle style={{ marginLeft: "2px" }}>*required</RequiredStyle>
                            </>) : null}
                        </CenterDiv>
                        <CenterDiv style={{ width: "15%" }}>
                            <div className='mb-3'><b>Prize Won</b></div>
                            <Select placeholder="Enter Prize" style={{ width: "100%" }} value={winnerList[index].prizeWon} onChange={this.prizeWonChange}>
                                {prizeDatas.length != 0 ? <Select.Option key="" value="">Enter Winner Prize</Select.Option> : null}
                                {
                                    prizeDatas.map((prizeData, i) => {
                                        return <Select.Option key={prizeData.id} >{prizeData.title}</Select.Option>
                                    })
                                }
                            </Select>
                            {this.state.PrizeErrorRequired === 1 ? (<>
                                <Errorline style={{
                                    width: "99%",
                                    height: "2px",
                                }}></Errorline>
                                <RequiredStyle style={{ marginLeft: "2px" }}>*required</RequiredStyle>
                            </>) : null}
                        </CenterDiv>
                        <div >
                            <div style={{ paddingLeft: "9px" }}>{`#${index + 1} prize image here`}</div>
                            <Dropzone
                                style={{ margin: '0 10px', width: '210px' }}
                                getDropzoneFile={(e) => this.onDropWeb(e, winnerList, index, updateOrAddWinnerDetails)}
                                ref={this.dropzoneRef}
                                defaultImage={winnerList[index].uploadImageLink === null ? '' : winnerList[index].uploadImageLink}
                                defaultFile={webImage}
                                onImageUrl={(imgUrlOne) => {
                                    this.setState({ webUrl: imgUrlOne })
                                }}
                                uploadImageLink={winnerList[index].uploadImageLink}
                            >Click or drag to attach
                            </Dropzone>
                            <PhotoUploadError><b>{webUrl?webError:''}</b></PhotoUploadError>
                        </div>
                        <div style={{ width: "16px" }}>
                            <CloseOutlined className='cross'
                                style={{ fontSize: "15px", float: "right", backgroundColor: hover === true ? "grey" : "white", color: hover === false ? "black" : "white", cursor: "pointer" }}
                                onMouseEnter={() => this.setState({ hover: true })}
                                onMouseLeave={() => this.setState({ hover: false })}
                                onClick={() => { this.deleteWinner(winnerList, index, updateOrAddWinnerDetails, mutationState, updateMutationState, deleteEventWinnersDetails) }}
                            />
                        </div>
                    </div>
                </FlexContainer>
            </div>

        )
    }
}

export default withRouter(WinnerDetails);