import React, { useEffect, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import { get } from 'lodash'
import { Button, Checkbox, notification, Select, Spin, Tooltip } from 'antd'
import { Input, PublishInput } from './FormElements'
import Dropzone from '../../../../../components/Dropzone'
import { FlexContainer } from '../../ContentLearningObjective.styles'
import getFullPath from '../../../../../utils/getFullPath'
import { getSelectedValues } from '../../../../../utils/data-utils'
import { commonFormValidation } from '../../../contentUtils'

const EditLoForm = (props) => {
  const { handleEditLo, editFormData,
    learningObectiveUpdateStatus, orderInUse,
    coursesFetchStatus, coursesList, operation, handleDuplicateLo, 
    learningObectiveAddStatus, messageAddStatus, questionBanksAddStatus, 
    learningSlidesAddStatus, comicStripsAddStatus, learningObjectiveDuplicateFetchStatus, duplicateLoComponentsData } = props
  const thumbnailRef = useRef()
  const [thumbnailFile, setThumbnailFile] = useState(null)
  const [thumbnailUrl, setThumbnailUrl] = useState(null)
  const pqStoryImageRef = useRef()
  const [pqStoryImageFile, setPqStoryImageFile] = useState(null)
  const [pqStoryImageUri, setPqStoryImageUri] = useState(null)
  const [selectedCourses, setSelectedCourses] = useState([])
  const [addMessages, setAddMessages] = useState(null)
  const [addQuestionBanks, setAddQuestionBanks] = useState(null)
  const [addComicStrips, setAddComicStrips] = useState(null)
  const [addLearningSlide, setAddLearningSlide] = useState(null)

  useEffect(() => {
    if (operation !== 'duplicateLo') {
      const newSelectedCourse = getSelectedValues(editFormData, coursesList, 'courses')
      setSelectedCourses(newSelectedCourse)
    }
  }, [editFormData.id])

  const onDropThumbnail = (file) => {
    setThumbnailFile(file)
  }

  const onDropPqStoryImage = (file) => {
    setPqStoryImageFile(file)
  }

  const handleLoading = () => {
    return operation === 'duplicateLo' ? (
      (learningObectiveAddStatus && get(learningObectiveAddStatus.toJS(), 'loading')) ||
      (messageAddStatus && get(messageAddStatus.toJS(), 'loading')) ||
      (questionBanksAddStatus && get(questionBanksAddStatus.toJS(), 'loading')) ||
      (learningSlidesAddStatus && get(learningSlidesAddStatus.toJS(), 'loading')) ||
      (comicStripsAddStatus && get(comicStripsAddStatus.toJS(), 'loading'))) :
      (learningObectiveUpdateStatus && get(learningObectiveUpdateStatus.toJS(), 'loading'))
  }

  const handleSubmit = (value) => {
    if (operation === 'duplicateLo') {
      handleDuplicateLo(value, thumbnailFile, pqStoryImageFile, duplicateLoComponentsData, selectedCourses, addMessages, addQuestionBanks, addComicStrips, addLearningSlide)
    } else {
      handleEditLo(value, thumbnailFile, pqStoryImageFile, selectedCourses)
    }
  }

  const onSelect = (value) => {
    setSelectedCourses([...selectedCourses, value])
  }

  const onDeselect = (value) => {
    const newCourse = selectedCourses.filter(course =>
      get(course, 'key') !== get(value, 'key'))
    setSelectedCourses(newCourse)
  }
  const spinning = learningObjectiveDuplicateFetchStatus && get(learningObjectiveDuplicateFetchStatus.toJS(), 'loading', false)

  const filterOption = (input, option) => (
    get(option, 'props.children.props.children')
      ? get(option, 'props.children.props.children')
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
      : false
  )

  return (
    <Formik
      initialValues={editFormData}
      onSubmit={handleSubmit}
      validateOnBlur
      validationSchema={commonFormValidation}
    >
      {({ values, handleChange, setFieldValue }) => (
        <Form style={{ padding: '0 10px' }} id='form'>
        <Spin spinning={Boolean(spinning)}>
          <FlexContainer justify='space-between' modalGrid>
            <div>
              <h3>Thumbnail</h3>
              <Dropzone
                style={{ height: '200px', width: '100%', marginBottom: '15px' }}
                getDropzoneFile={onDropThumbnail}
                ref={thumbnailRef}
                defaultImage={getFullPath(get(editFormData, 'thumbnail.uri')) || thumbnailUrl}
                defaultFile={thumbnailFile}
                onImageUrl={imgUrl => setThumbnailUrl(imgUrl)}
              >Click or drag to attach
              </Dropzone>
            </div>
            <div>
              <h3>Pq StoryImage</h3>
              <Dropzone
                style={{ height: '200px', width: '100%', marginBottom: '15px' }}
                getDropzoneFile={onDropPqStoryImage}
                ref={pqStoryImageRef}
                defaultImage={getFullPath(get(editFormData, 'pqStoryImage.uri')) || pqStoryImageUri}
                defaultFile={pqStoryImageFile}
                onImageUrl={imgUrl => setPqStoryImageUri(imgUrl)}
              >Click or drag to attach
              </Dropzone>
            </div>
          </FlexContainer>
          <FlexContainer modalGrid>
            <div>
              <Input
                label='Add Lo Title'
                placeholder='Add Lo Title'
                name='title'
                type='text'
                value={values.title || ''}
                onChange={handleChange}
                setFieldValue={setFieldValue}
              />
              <Input
                placeholder='Enter Order'
                type='number'
                name='order'
                label='Order'
                value={values.order || ''}
                order
                values={values}
                orderInUse={orderInUse}
                setFieldValue={setFieldValue}
                onChange={handleChange}
              />
            </div>
            <div style={{ flex: '0.8' }}>
              <Input
                label='Add Lo Description'
                placeholder='Add Lo Description'
                name='description'
                type='text'
                value={values.description || ''}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                textArea='textArea'
              />
            </div>
          </FlexContainer>
          <FlexContainer modalGrid>
            <div>
              <h3>Select Courses</h3>
              <Select
                mode='multiple'
                labelInValue
                placeholder='Select Courses'
                loading={coursesFetchStatus && !get(coursesFetchStatus.toJS(), 'loading')}
                filterOption={filterOption}
                value={selectedCourses}
                onSelect={onSelect}
                disabled={operation === 'duplicateLo' ? false : true}
                onDeselect={onDeselect}
                style={{ width: '100%' }}
              >
                {
                  coursesList.map(item =>
                    <Select.Option
                      value={get(item, 'id')}
                      key={get(item, 'id')}
                    >
                      <Tooltip title={get(item, 'title')}>{get(item, 'title')}</Tooltip>
                    </Select.Option>
                  )
                }
              </Select>
              <div style={{ marginTop: 10 }}>
                <PublishInput
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>
            <Input
              label='Add PQ Story'
              placeholder='Add PQ Story'
              name='pqStory'
              type='text'
              value={values.pqStory || ''}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              textArea='textArea'
            />
          </FlexContainer>
          {operation === 'duplicateLo' && (
            <>
            {(get(duplicateLoComponentsData, 'messages', []).length > 0 ||
            get(duplicateLoComponentsData, 'questionBank', []).length > 0 ||
            get(duplicateLoComponentsData, 'comicStrips', []).length > 0 ||
            get(duplicateLoComponentsData, 'learningSlides', []).length > 0) && (
              <h3>Also Add :</h3>
            )}
            <FlexContainer modalGrid>
              {get(duplicateLoComponentsData, 'messages', []).length > 0 && (
                <div style={{ marginTop: '15px' }}>
                  <Checkbox
                    checked={addMessages}
                    onChange={e => {
                      setAddMessages(e.target.checked)
                      const messages = get(duplicateLoComponentsData, 'messages', [])
                      const messagesWithQuestion = messages && messages.filter(item => get(item, 'question') !== null)
                      if (messagesWithQuestion.length) {
                        setAddQuestionBanks(e.target.checked)
                      }
                    }}
                  >Chat/Chatbot</Checkbox>
                </div>
              )}
              {(get(duplicateLoComponentsData, 'questionBank', []).length > 0 || addMessages || addLearningSlide) && (
                <div style={{ marginTop: '15px' }}>
                  <Checkbox
                    checked={addQuestionBanks}
                    onChange={e => setAddQuestionBanks(e.target.checked)}
                  >Practice Question</Checkbox>
                </div>
              )}
              {get(duplicateLoComponentsData, 'comicStrips', []).length > 0 && (
                <div style={{ marginTop: '15px' }}>
                  <Checkbox
                    checked={addComicStrips}
                    onChange={e => setAddComicStrips(e.target.checked)}
                  >Comic Strips</Checkbox>
                </div>
              )}
              {get(duplicateLoComponentsData, 'learningSlides', []).length > 0 && (
                <div style={{ marginTop: '15px' }}>
                  <Checkbox
                    checked={addLearningSlide}
                    onChange={e => {
                      setAddLearningSlide(e.target.checked)
                      const learningSlides = get(duplicateLoComponentsData, 'learningSlides', [])
                      const learningSlidesWithQuestion = learningSlides && learningSlides.filter(item => get(item, 'practiceQuestions', []).length)
                      if (learningSlidesWithQuestion.length) {
                        setAddQuestionBanks(e.target.checked)
                      }
                    }}
                  >Learning Slides</Checkbox>
                </div>
              )}
            </FlexContainer>
            </>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '10px' }}>
            <Button
              type='primary'
              icon='file'
              id='add-btn'
              htmlType='submit'
              loading={handleLoading()}
            >
              {operation === 'edit' ? 'Update' : 'Duplicate'}
            </Button>
          </div>
          </Spin>
        </Form>
    )}
    </Formik>
  )
}

export default EditLoForm
