import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const fetchStudentsProfile = async ({
  schoolId, selectedGrade, section, perPage, studentName, skip, academicYearConnectId, fromStudentsTab = false
}) =>
  duck.query({
    query: gql`
    {
        studentProfiles(
            filter: {
              and: [
                { school_some: { id: "${schoolId}" } }
                ${studentName ? `{ user_some: { name_contains: "${studentName}" } }` : ''}
                ${selectedGrade && selectedGrade !== 'All' ? `{ grade: ${selectedGrade} }` : ''}
                ${section && section !== 'All' ? `{ section: ${section} }` : ''}
                ${academicYearConnectId ? `{ academicYears_some: { id: "${academicYearConnectId}" } }` : ''}
              ]
            }
            ${perPage ? `first: ${perPage}` : ''}
            ${skip ? `skip: ${perPage * skip}` : ''}
        ) {
            id
            ${fromStudentsTab ? `studentBatch: batch {
              classroomTitle
            }` : ''}
            user {
            id
            name
            status
            }
            rollNo
            grade
            section
            parents {
            id
            user {
                id
                name
                phone {
                  countryCode
                  number
                }
                email
            }
            }
        }
        studentProfilesMeta(
          filter: {
            and: [
                { school_some: { id: "${schoolId}" } }
                ${studentName ? `{ user_some: { name_contains: "${studentName}" } }` : ''}
                ${selectedGrade && selectedGrade !== 'All' ? `{ grade: ${selectedGrade} }` : ''}
                ${section && section !== 'All' ? `{ section: ${section} }` : ''}
                ${academicYearConnectId ? `{ academicYears_some: { id: "${academicYearConnectId}" } }` : ''}
            ]
          }
        ) {
          count
      }
    }
    `,
    type: 'studentProfiles/fetch',
    key: 'studentProfiles',
    changeExtractedData: (extractedData, originalData) => {
      const studentProfiles = []
      const getParentData = (data) => get(data, 'parents', []).length > 0 && get(data, 'parents[0].user') ? get(data, 'parents[0].user') : {}
      get(originalData, 'studentProfiles', []).forEach((student, i) => {
        studentProfiles.push({
          index: i + 1,
          studentName: get(student, 'user.name'),
          parentName: get(getParentData(student), 'name'),
          phone: `${(get(getParentData(student), 'phone.countryCode') && get(getParentData(student), 'phone.number'))
            ? get(getParentData(student), 'phone.countryCode') : '-'}${get(getParentData(student), 'phone.number') || '-'}`,
          email: get(getParentData(student), 'email') || '-',
          ...student
        })
      })
      extractedData.studentProfiles = studentProfiles
      return { ...extractedData }
    }
  })

export default fetchStudentsProfile

