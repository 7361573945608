import React from 'react'
import { Table } from '../../components/StyledComponents'
import MainTable from '../../components/MainTable'

const UtmDetailsTableHeader = (props) => (

  <Table.Row columnsTemplate={props.columnsTemplate} minWidth={props.minWidth} >
    <Table.StickyItem
      style={{ left: 0 }}
    >
        <MainTable.Title style={{ fontSize: 14, width: 50 }}>#</MainTable.Title>
        <MainTable.Title style={{ fontSize: 14, width: 1000 }}>UTM URL</MainTable.Title>
    </Table.StickyItem>
    <Table.Item>
      <MainTable.Title style={{ fontSize: 14 }}>Source</MainTable.Title>
    </Table.Item>
    <Table.Item>
      <MainTable.Title style={{ fontSize: 14 }}>Campaign</MainTable.Title>
    </Table.Item>
    <Table.Item>
      <MainTable.Title style={{ fontSize: 14 }}>Term</MainTable.Title>
    </Table.Item>
    <Table.Item>
      <MainTable.Title style={{ fontSize: 14 }}>Content</MainTable.Title>
    </Table.Item>
    <Table.Item>
      <MainTable.Title style={{ fontSize: 14 }}>Medium</MainTable.Title>
    </Table.Item>
    <Table.Item>
      <MainTable.Title style={{ fontSize: 14 }}>Actions</MainTable.Title>
    </Table.Item>

  </Table.Row>


)

export default UtmDetailsTableHeader
