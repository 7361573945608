import gql from 'graphql-tag'
import duck from '../../duck';

const removeFromEventAction = async (eventId, fileId, contentId, actionFor) => {
    return duck.query({
        query: gql`
            mutation {
                ${actionFor === 'banner' ?
                `removeFromEventBannerEvent(eventId: "${eventId}", fileId: "${fileId}") {
                event {
                id
                }
            }` : ''}
            ${actionFor === 'listingImage' ? `removeFromListingImageEvent(eventId: "${eventId}", fileId: "${fileId}") {
                event {
                id
                }
            }` : ''}
            ${actionFor === 'prize' ? `removeFromEventPrizeFile(eventPrizeId: "${contentId}", fileId: "${fileId}") {
                eventPrize {
                id
                }
            }` : ''}
            ${actionFor === 'reason' ? `removeFromEventJoinReasonPicture(eventJoinReasonId: "${contentId}", fileId: "${fileId}") {
                eventJoinReason {
                id
                }
            }` : ''}
            ${actionFor === 'contentTag' ? `removeFromContentTagEvent(contentTagId: "${contentId}", eventId: "${eventId}") {
                event {
                id
                }
            }` : ''}
            ${actionFor === 'category' ? `removeFromEventCategoryEvent(eventId: "${eventId}", eventCategoryId: "${contentId}") {
                event {
                id
                }
            }` : ''}
            ${actionFor === 'prizeContent' ? `removeFromEventPrizeEvent(eventId: "${eventId}", eventPrizeId: "${contentId}") {
                event {
                id
                }
            }` : ''}
            ${actionFor === 'reasonContent' ? `removeFromEventJoinReasonEvent(eventId: "${eventId}", eventJoinReasonId: "${contentId}") {
                event {
                id
                }
            }` : ''}
            ${actionFor === 'comms' ? `removeFromCommsVariableEvent(eventId: "${eventId}", commsVariableId: "${contentId}") {
                event {
                id
                }
            }` : ''}
            ${actionFor === 'embed' ? `removeFromEventCertificateEmbedEvent(
                eventId: "${eventId}"
                eventCertificateEmbedId: "${contentId}"
            ) {
                event {
                id
                }
            }` : ''}
            ${actionFor === 'certificate' ? `removeFromBaseCertificateEvent(eventId: "${eventId}", fileId: "${fileId}") {
                event {
                id
                }
            }` : ''}
            ${actionFor === 'speaker' ? `removeFromEventSpeakerProfileEvent(eventId: "${eventId}", eventSpeakerProfileId: "${contentId}") {
                event {
                id
                }
            }` : ''}
            ${actionFor === 'mobileBanner' ? `removeFromEventMobileBannerEvent(eventId: "${eventId}", fileId: "${fileId}") {
                event {
                id
                }
            }` : ''}
            ${actionFor === 'eventWinner' ? `removeFromEventWinnerImage(eventWinnerId: "${eventId}", fileId: "${fileId}") {
                eventWinner {
                  id
                }
              }` : ''}
            }
        `,
        type: 'events/delete',
        key: 'events',
    })
}

export default removeFromEventAction