import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import withUpdatedDashboardNav from '../../../components/withUpdatedNav'
import { EVENT_DASHBOARD } from '../../../constants/navItemsConst'
import injectProps from '../../../components/injectProps'
import CreateEvent from './CreateEvent'

const EventOverviewSideNav = withUpdatedDashboardNav(CreateEvent)({
  title: 'Create Event',
  activeNavItem: 'Events',
  showUMSNavigation: true,
  renderNavItems: EVENT_DASHBOARD,
  noPadding: true
})

const mapStateToProps = state => ({
  fetchedEvents: state.data.getIn(['events', 'data']),
  fetchedCategories: state.data.getIn(['eventCategories', 'data']),
  fetchedTags: state.data.getIn(['contentTags', 'data']),
  isFetchingTags: state.data.getIn(
    ['contentTags',
      'fetchStatus',
      'contentTags',
      'loading']
  ),
  isAddingEvent: state.data.getIn([
    'events',
    'addStatus',
  ]),
  hasAddedEvent: state.data.getIn([
    'events',
    'addStatus',
  ]),
  hasAddedEventFailed: state.data.getIn([
    'events',
    'addStatus',
  ]),
  isUpdatingEvent: state.data.getIn(['events', 'updateStatus', 'events', 'loading']),
  hasUpdatedEvent: state.data.getIn(['events', 'updateStatus', 'events', 'success']),
  hasUpdatedEventFailed: state.data.getIn(['events', 'updateStatus', 'events', 'failure']),
  updatedFailure: state.data.getIn(['errors', 'events/update']),
  addFailure: state.data.getIn(['errors', 'events/add'])
})


const EventOverviewSideNavWithExtraProps = injectProps({
  notification
})(EventOverviewSideNav)
export default connect(mapStateToProps)(withRouter(EventOverviewSideNavWithExtraProps))
