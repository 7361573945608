import React from 'react'
import styled from 'styled-components'
import { Button, Checkbox, Divider, Input, Radio,Select, Table } from 'antd'
import {
    CloseOutlined, CopyOutlined, LeftOutlined,
    LinkOutlined, SettingOutlined, UserOutlined
} from '@ant-design/icons'

const FlexContainer = styled.div`
margin:10px 0px 0px 30px;
`

const CenterDiv = styled.div`
display: flex;
justify-content:center;
flex-direction: column;
margin-right: 60px;
`
const IndexDiv = styled.div`
color: #0095ff;
align-items: center;
display: flex;
margin-top: 21px; 
margin-right: 60px;
`
const NameStyle = styled((props) => <Input {...props} />)`
    height: 33px;
    width: 107%;
    border-radius:8px;
    border-color: #80808059;
`
const PrizeInputStyle = styled((props) => <Select {...props} />)`
height: 30px;
width: 168px;
border-radius: 4px;
border-color: #80808059;
`
const PhoneNumberInputStyle = styled.input`
border-radius: 4px;
border-color: #80808059;
height: 35px;
width: 90%;
`
const AddbuttonStyle = styled.div`
    margin-left: 100px;
    font-size: 10px;
    cursor:pointed;
`
const PlusStyle = styled.div`
height: 50px;
width: 50px;
border-radius: 50%;
background-color: rgb(217,219,240);
display: flex;
justify-content: center;
align-items: center;
cursor:pointer;
`
const UploadMomentMargin=styled.div`
margin-top:61px;
margin-left:43px;
`
const WrapupMomentMargin=styled.div`
margin-top:61px;
margin-left:43px;
`
const YoutubeLinkInput=styled((props) => <Input {...props} />)`
    margin-left: 45px;
    width: 47%;
    height: 35px;
    border-color: #80808096;
`
const ButtonStyle=styled.div`
display:flex;
width: 30%;
float: right;
height: 4vh;
`
const StyledButton = styled(Button)`
&&& {
    
    background-color:rgb(65, 75, 178);
    color: white;
    border: none;
    cursor: pointer;
    &:hover{
        background-color:rgb(51, 61, 161);
       
    }
    &:disabled{
        color: white;
    }
    ${props => props.alignSelfCenter && css`
   align-self: center;
  `}
}
`
const StyledBackButton = styled(Button)`
&&& {
    
    min-width: 130px;
    background-color:rgb(128,128,128);
    color: white;
    border: none;
    cursor: pointer;
    &:hover{
        background-color:rgb(99, 99, 99);
    }
    ${props => props.alignSelfCenter && css`
   align-self: center;
  `}
  ${props => props.mRight && css`
   margin-right:2rem;
  `}
}
`

const Errorline=styled.div`
background-color: red;
max-width: 99%;
height: 2px;
`
const RequiredStyle=styled.div`
color: red;
font-size: 9px;
`
const PhotoUploadError=styled.p`
    font-size: 11px;
    color: red;
    margin-left: 16px;
    width: 136%;    
`
const OuterDiv=styled.div`
max-height: 80vh;
background: #FFFFFF;
padding: 15px;
overFlow: scroll;
font-family: Inter;
margin: 0px 25px;
`
export {
    FlexContainer,
    CenterDiv,
    IndexDiv,
    NameStyle,
    PrizeInputStyle,
    PhoneNumberInputStyle,
    AddbuttonStyle,
    PlusStyle,
    UploadMomentMargin,
    YoutubeLinkInput,
    WrapupMomentMargin,
    ButtonStyle,
    StyledButton,
    StyledBackButton,
    Errorline,
    RequiredStyle,
    PhotoUploadError,
    OuterDiv,
}