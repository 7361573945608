import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const fetchUserSavedCodes = async ({ id }) =>
  duck.query({
    query: gql`
        query {
          userApprovedCodes(filter: {
            user_some:{id: "${id}"}
          }){
            id
            totalReactionCount
          }
          userSavedCodes(filter: {
            user_some:{id: "${id}"}
          }){
            id
          }
          userPracticeQuestionReportsMeta(filter: {
            user_some:{id: "${id}"}
          }){
            count
          }
          userQuizReportsMeta(filter: {
            user_some: {id: "${id}"}
          }){
            count
          }
      }
    `,
    type: 'userSnapshotMetaData/fetch',
    key: 'userSnapshotMetaData',
    changeExtractedData: (extractedData, originalData) => {
      const combineObj = {}
      combineObj.userQuizReportsMeta = get(originalData, 'userQuizReportsMeta', {})
      combineObj.userPracticeQuestionReportsMeta = get(originalData, 'userPracticeQuestionReportsMeta', {})
      combineObj.userSavedCodes = get(originalData, 'userSavedCodes', [])
      combineObj.userApprovedCodes = get(originalData, 'userApprovedCodes', [])
      extractedData.userSnapshotMetaData = combineObj
      return { ...extractedData }
    },
  })

export default fetchUserSavedCodes
