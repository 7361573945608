import gql from 'graphql-tag'
import duck from '../../duck';
import requestToGraphql from '../../utils/requestToGraphql';


const sendCommsMessage = async (input) => await requestToGraphql(gql`{
        sendCommsMessage(
            input: ${input}
        ) {
          result
          error
        }
      }`)

export default sendCommsMessage
