import { Button, Input, Modal, Select, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import Stack from '../../components/Stack/Stack'
import Label from '../../components/Label/Label'
import TextArea from 'antd/lib/input/TextArea'
import requestToGraphql from '../../utils/requestToGraphql'
import gql from 'graphql-tag'
import { Field, Form, Formik } from 'formik'
import { get, set } from 'lodash'
import { addEbookChapter } from './updateEbookChapter'
import { addEbookCoursePackages, deleteCoursePackageFromEbook } from './updateEbookCoursePackage'
import { Spin, Icon } from 'antd';
import { updateEbook } from './updateEbook'
import "./style.css"

const LoadingIcon = <Icon type="loading" style={{ fontSize: 24, color: '#fff' }}spin />

const addEbook = async (input) => {
    const query =  gql`
    mutation($input:EBookInput!){
      addEBook(input:$input){
          id
      }
    }`
    const res = await requestToGraphql(query,{input})
    return res

}


const CustomButton = (
    {
        isLoading,
        label = "Save & Next",
    }
) => {
    return (
        <>
            <button
                type="submit" 
                style={{width: 'fit-content',marginLeft: 'auto', background: "#1890ff", border: 'none',color: '#fff',borderRadius: '6px', marginTop: 'auto', padding: '7px 15px', cursor: 'pointer'}}
            >
                {!isLoading ? label : <Spin indicator={LoadingIcon} />}
            </button>
        </>
    )
}

const grades = [
    'Grade1',
    'Grade2',
    'Grade3',
    'Grade4',
    'Grade5',
    'Grade6',
    'Grade7',
    'Grade8',
    'Grade9',
    'Grade10',
    'Grade11',
    'Grade12',
  ];

export const AddEbook = ({onClose,ebookId,showModal,ebook,coursePackages,updateEbook}) => {
    const [step,setStep] = useState(0)
    const [isEdited,setIsEdited] = useState(false)
    const [ebooksData,setEbooksData] = useState([])
    const title = showModal === 'edit' ? "Edit E-Book" : "Add E-Book"
    
    useEffect(() => {
       setEbooksData(ebook)
    } , [ebook])


    const configureSteps = [
        {
            title: title,
            content: (
                <FirstStep
                    setStep={setStep}
                    isEdit={showModal === "edit"}
                    ebook={ebooksData}
                    setEbooksData={setEbooksData}
                />
            ),
            footer: (
                null
            )
        },
        {
            title,
            content: (
                <SecondStep 
                    coursePackages={coursePackages}
                    ebook={ebooksData}
                    setEbooksData={setEbooksData}

                />
            ),
            footer: (
                <Stack gap="20" direction="row">
                    <Button
                        style={{marginLeft: 'auto'}}
                        onClick={() => {
                            setStep( prev => prev - 1)
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        onClick={() => {
                            setStep( prev => prev + 1)
                        }}
                        type='primary'
                        >
                         Next
                    </Button>
                </Stack>
            )
        },
        {
            title,
            content: (
                <ThirdStep 
                    coursePackages={coursePackages}
                    setStep={setStep}
                    ebook={ebooksData}
                    updateEbook={updateEbook}
                    onClose={onClose}
                    setEbooksData={setEbooksData}

                />
            ),
            footer: (
                null
            )
        }
    ]
    const currentContent = configureSteps[step].content
    const currentFooter = configureSteps[step].footer
    return (
            <Modal
                title={title}
                visible={true}
                onOk={() => {}}
                onCancel={onClose}
                okText="Add"
                footer={currentFooter}
                cancelText="Cancel"

            >   
                {currentContent}
            </Modal>

        )
}
const MyInput = ({ field, form, ...props }) => {
    return <Input required={props.required} {...field} {...props} />;
  };
const MyTextArea = ({ field, form, ...props }) => {
return <TextArea {...field} {...props} />;
};
const FirstStep = ({setStep,ebook,isEdit,setEbooksData}) => {
    const [isLoading,setIsLoading] = useState(false)
    // filter the grades which has value null
    const gradesData = isEdit && ebook.grades.filter((item) => {
        return item.value !== null
    })


    return (
        <>
            <Formik
                initialValues={
                    {
                       title: get(ebook,'title',''),
                       internalName: get(ebook,'internalName',''),
                       description: get(ebook,'description',''),
                       resourceURL: get(ebook,'resourceURL',''),
                       grades: gradesData,
                    }
                }
                
                onSubmit={async (values) => {
                    // check if values are changes or not
                    const checkIfChanges = Object.keys(values).map((key) => {
                        return values[key] === ebook[key]
                    })
                    const isChanges = checkIfChanges.includes(false)
                    setIsLoading(true)
                    setEbooksData({
                        ...ebook,
                        ...values
                    })
                    if(!isEdit){
                        const res = await addEbook(values)
                        const id = get(res,'data.addEBook.id','')
                        setEbooksData({
                            ...ebook,
                            ...values,
                            id
                        })

                    }
                    else{
                        await updateEbook(values,get(ebook,'id',''))
                    }
                    setIsLoading(false)
                    setStep(1)
                }}
            >
            {(props) => (
                <>
                    <Form>
                        <Stack gap="20">
                        <Stack gap="10" direction="column">
                            <Label htmlFor='title'>E-Book Name</Label>
                            <Field name="title" placeholder="Title" required={true} component={MyInput} />
                        </Stack>
                        <Stack gap="10" direction="column">
                            <Label htmlFor='internalName'>E-Book Internal Name</Label>
                            <Field name="internalName" placeholder="internalName" required={true} component={MyInput} />
                        </Stack>
                        <Stack gap="10" direction="column">
                            <Label htmlFor='internalName'>Grades</Label>
                            <Select
                                size='medium'
                                mode='multiple'
                                
                                defaultValue={
                                    props.initialValues.grades.length > 0
                                    ? props.initialValues.grades.map((grade) => grade.value)
                                    : undefined 
                                }
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                onChange={(value) => {
                                    const grades = value.map((item) => {
                                        return {
                                            value: item
                                        }
                                    })
                                    props.setFieldValue('grades',grades)
                                }}
                            >
                            {grades.map((grade) => (
                                <Option
                                    value={grade}
                                    label={grade}
                                    
                                    >{grade}</Option>
                            ))}
                            </Select>
                        </Stack>
                        <Stack gap="10" direction="column">
                            <Label htmlFor='description'>Discription</Label>
                            <Field name="description" placeholder="description" required={true} component={MyTextArea} />
                        </Stack>
                        <Stack gap="10" direction="column" required={true}>
                            <Label htmlFor='resourceURL'>E-Book HyperLink</Label>
                            <Field name="resourceURL" placeholder="resourceURL" required={true} component={MyInput} />

                        </Stack>
                            <button
                                type="submit" 
                                style={{width: 'fit-content',marginLeft: 'auto', background: "#1890ff", border: 'none',color: '#fff',borderRadius: '6px', marginTop: 'auto', padding: '7px 15px', cursor: 'pointer'}}
                            >
                                {!isLoading ? "Save & Next": <Spin indicator={LoadingIcon} />}
                            </button>
                      
                        </Stack>


                    </Form>   
                       
                    
                </>
            )}
             
            </Formik>
        </>
    )
}
const ThirdStep = ({coursePackages,ebook,setStep,setEbooksData,onClose = () => {},updateEbook}) => {
    const [updateCoursePackages, setUpdateCoursePackages] = useState([])
    const [ebookCoursePackages,setEbookCoursePackages] = useState([])
    const [loading,setLoading] = useState(false)

    useEffect(() => {
        setEbookCoursePackages(get(ebook,'packages',[]))
        setUpdateCoursePackages(get(ebook,'packages',[]))
    }, [ebook])

    const coursePackageData = ebookCoursePackages.map((item) => {
        return {
            ...item,
            key: item.id
        }
    })
    return (
        <>
            <Stack gap="20">
                <div>
                    <Label htmlFor="coursePackage">Add coursePackage</Label>
                    {/* dropdown */}
                    <Select
                        size='medium'
                        key={ebookCoursePackages}
                        mode='multiple'
                        style={{ width: '100%' }}
                        placeholder="Please select"
                      
                        onDeselect={(option) => {
                            const temArr = updateCoursePackages.filter((item) => {
                                return item.id !== option
                            })
                            setUpdateCoursePackages(temArr)
                            // setEbookCoursePackages(temArr)
                            // setEbooksData({
                            //     ...ebook,
                            //     packages: temArr
                            // })
                        }
                        }
                        onSelect={(option,value) => {
                            const temArr = [...updateCoursePackages,value.props.item]
                            setUpdateCoursePackages(temArr)
                            // setEbookCoursePackages(temArr)
                            // setEbooksData({
                            //     ...ebook,
                            //     packages: temArr
                            // })
                        }}
                        defaultValue={
                            ebookCoursePackages && ebookCoursePackages.map((item) => {
                                return item.id
                            }
                            )
                        }
                    >
                        {coursePackages.map((item) => (
                            
                            <Option
                                value={get(item,'id','')}
                                label={get(item,'title','')}
                                item={item}
                                
                                >{get(item,'title','')}
                            </Option>
                        ))}
                    </Select>
                </div> 
                <div>
                    <Table
                        pagination={false}
                        loading={loading}
                        columns={[
                            
                            {
                                title: 'Title',
                                dataIndex: 'title',
                                key: 'title',
                            },
                            {
                                title: '',
                                dataIndex: 'action',
                                key: 'action',
                                render: (text, record) => (
                                  <span>
                                    <Button
                                      type="default"
                                      onClick={async() => {
                                        setLoading(true)
                                        const id = record.id
                                        const temArr = updateCoursePackages.filter((item) => {
                                            return item.id !== id
                                        })
                                     
                                        await deleteCoursePackageFromEbook(
                                            get(ebook,'id',''),
                                            id
                                        )
                                        setEbooksData({
                                            ...ebook,
                                            packages: temArr
                                        })
                                        setEbookCoursePackages(temArr)
                                        setUpdateCoursePackages(temArr)
                                        setLoading(false)


                                      }}
                                    >
                                        
                                   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.67247 6.0028C4.08446 5.95995 4.45318 6.2592 4.49604 6.67119L5.8398 19.5906C5.86211 19.7715 5.94952 19.9381 6.08577 20.0592C6.22307 20.1813 6.40041 20.2488 6.58414 20.2488H17.4165C17.6002 20.2488 17.7775 20.1813 17.9148 20.0592C18.0511 19.9381 18.1385 19.7715 18.1608 19.5906L19.5041 6.67122C19.5469 6.25922 19.9156 5.95996 20.3276 6.0028C20.7396 6.04564 21.0389 6.41435 20.996 6.82634L19.6521 19.7517C19.6518 19.755 19.6514 19.7584 19.651 19.7617C19.5866 20.3092 19.3235 20.8139 18.9116 21.1802C18.4997 21.5464 17.9677 21.7488 17.4165 21.7488H6.58411C6.03291 21.7488 5.5009 21.5464 5.08901 21.1802C4.67711 20.8139 4.414 20.3092 4.34957 19.7617C4.34927 19.7591 4.34898 19.7565 4.3487 19.7539L4.34846 19.7517L3.00408 6.82637C2.96123 6.41438 3.26048 6.04565 3.67247 6.0028Z" fill="#333333"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.75 3.75C0.75 2.92157 1.42157 2.25 2.25 2.25H21.75C22.5784 2.25 23.25 2.92157 23.25 3.75V6C23.25 6.82843 22.5784 7.5 21.75 7.5H2.25C1.42157 7.5 0.75 6.82843 0.75 6V3.75ZM21.75 3.75H2.25V6H21.75V3.75Z" fill="#333333"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1553 10.7197C15.4482 11.0126 15.4482 11.4874 15.1553 11.7803L9.90533 17.0303C9.61244 17.3232 9.13756 17.3232 8.84467 17.0303C8.55178 16.7374 8.55178 16.2626 8.84467 15.9697L14.0947 10.7197C14.3876 10.4268 14.8624 10.4268 15.1553 10.7197Z" fill="#333333"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84467 10.7197C9.13756 10.4268 9.61244 10.4268 9.90533 10.7197L15.1553 15.9697C15.4482 16.2626 15.4482 16.7374 15.1553 17.0303C14.8624 17.3232 14.3876 17.3232 14.0947 17.0303L8.84467 11.7803C8.55178 11.4874 8.55178 11.0126 8.84467 10.7197Z" fill="#333333"/>
                                    </svg>
                                </Button>
                                  </span> 
                                ),
                            },
                        ]}
                        dataSource={coursePackageData}
                    >

                    </Table>
                </div>
                <Stack gap="20" direction="row" align="center">
                    <Button 
                        type='default'
                        style={{marginLeft: 'auto'}}
                        onClick={() => {
                            setStep(1)
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={ async() => {
                            setLoading(true)
                            const ids = updateCoursePackages.map((item) => {
                                return item.id
                            })
                            await addEbookCoursePackages(
                                ids,
                                get(ebook,'id','')
                            )
                            onClose()
                            // update ebooks to ebooks
                            const updateEbookData = {
                                ...ebook,
                                packages: updateCoursePackages

                            }
                            // updateEbook(updateEbookData)
                            setLoading(false)
                         }}
                        >
                            Add Course Packages
                         </Button>
                    </Stack>

            </Stack>
        </>
    )
}
const SecondStep = ({coursePackages,ebook,setEbooksData}) => {
    const [isLoading,setIsLoading] = useState(false)
    const [ebookChapters,setEbookChapters] = useState([])
    const ebookId = get(ebook,'id','')
    const chapterData = ebookChapters.map((chapter) => {
        return {
            ...chapter,
            key: chapter.id
        }
    })

    useEffect(() => {
        setEbookChapters(get(ebook,'ebookChapters',[]))
    } , [ebook])
    return (
        <Stack gap="20">
            <Stack gap="20">
                <Table
                    pagination={false}
                    
                    loading={isLoading}
                    columns={[
                        {
                            title: 'Order No',
                            dataIndex: 'order',
                            key: 'order',
                        },
                        {
                            title: 'Title',
                            dataIndex: 'title',
                            key: 'title',
                        },
                        {
                            title: 'E-Book Page No',
                            dataIndex: 'pageNumber',
                            key: 'pageNumber',
                        }
                    ]}
                    dataSource={
                        chapterData   
                    }
                >

                </Table>
            </Stack>
           
            <Stack gap="10">
            <form onSubmit={
                async(e) => {
                        e.preventDefault()
                        const title = e.target.chapterName.value
                        const order = e.target.order.value
                        const pageNumber = e.target.pageNumber.value
                        const input = {
                            title,
                            order,
                            pageNumber
                        }
                        setIsLoading(true)
                        await addEbookChapter(input,ebookId)
                        // add new chapter to ebookChapters
                        const newChapter = {
                            title,
                            order,
                            pageNumber,
                            id: Math.random()
                        }
                        const newEbookChapters = [...ebookChapters,newChapter]
                        setEbookChapters(newEbookChapters)
                        setEbooksData(
                            {
                                ...ebook,
                                ebookChapters: newEbookChapters
                            }
                        )
                        setIsLoading(false)
                    }
                }>
                <Stack gap="20" direction='row' align="center">
                
                    <div>
                        <Label>Order</Label>
                        <Input required name='order' type="number" id="order" />
                    </div>
                    <div>
                        <Label>Chapter Name</Label>
                        <Input required name="chapterName" type="text" id="chapterName" />
                    </div>
                    <div>
                        <Label>E-Book Page No</Label>
                        <Input required type="number" name="pageNumber" id="pageNumber" />
                    </div>
                   <CustomButton isLoading={isLoading} label={"Add"} />
                </Stack>
                </form>

            </Stack>
            
        </Stack>
    )
}
