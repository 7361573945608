import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../../duck'
import getIdArrForQuery from '../../../utils/getIdArrForQuery'

const updateBatchSessionForDemand = async (
  sessionId,
  mentorSessionId,
  broadCastedMentorsConnectIds = [],
  rawBookingDate
) =>
  duck.query({
    query: gql`mutation {
  updateBatchSession(id: "${sessionId}",
  ${mentorSessionId ? `mentorSessionConnectId: "${mentorSessionId}"` : ''}
  ${broadCastedMentorsConnectIds.length > 0
    ? `broadCastedMentorsConnectIds: [${getIdArrForQuery(
      broadCastedMentorsConnectIds
    )}]`
    : ''},
     ${rawBookingDate ? `input:{ bookingDate: "${rawBookingDate}" }` : ''}
     ${broadCastedMentorsConnectIds.length > 0 ? 'input:{ isBroadCastedSession: true }' : ''}
  ) {
    id
    bookingDate
    sessionStatus
    sessionEndDate
    sessionStartDate
    createdAt
    mentorSession {
      id
      user {
        id
        name
        profilePic {
          id
          uri
        }
        mentorProfile {
          id
          experienceYear
          pythonCourseRating5
          pythonCourseRating4
          pythonCourseRating3
          pythonCourseRating2
          pythonCourseRating1
        }
      }
    }
    mentorAvailabilitySlot {
      id
    }
    broadCastedMentors {
      id
    }
    attendance {
      status
    }
    course {
      id
      title
      thumbnail {
        uri
      }
    }
    topic {
      id
      title
      order
    }
    batch {
      id
      code
      type
    }
  }
}
`,
    type: 'batchSessions/update',
    key: 'mentorMenteeSessionsForDemand',
    changeExtractedData: (extractedData, originalData) => {
      if (originalData && get(originalData, 'updateBatchSession')) {
        extractedData.user = []
        extractedData.batch = []
        extractedData.course = []
        extractedData.mentorSessions = []
        extractedData.topic = []
        extractedData.batchSessions = {
          ...get(originalData, 'updateBatchSession'),
        }
        return { ...extractedData }
      }
      return { ...extractedData }
    },
  })

export default updateBatchSessionForDemand
