import getDataFromLocalStorage from '../extract-from-localStorage'

const prefillFreshDesk = () => {
  if (typeof window.FreshworksWidget !== 'undefined') {
    window.FreshworksWidget('identify', 'ticketForm', {
      name: getDataFromLocalStorage('login.name'),
      email: getDataFromLocalStorage('login.email'),
    })
  }
}

export default prefillFreshDesk

