import React from 'react'
import { Spin } from 'antd'
import { get } from 'lodash'
import { Table } from '../../../../components/StyledComponents'
import SVGIcon from '../../../../assets/icons'
import Main from '../../Upload.style'
import MainTable from '../../../../components/MainTable'
import deleteFile from '../../../../actions/upload/deleteFile'

const TableFileInfo = ({ File, uploading, error, loading, setFile, onDeleteFile }) => {
  const Size = (num) => {
    if (num < 1024) {
      return `${num}bytes`
    } else if (num >= 1024 && num < 1048576) {
      return `${(num / 1024).toFixed(0)}KB`
    } else if (num >= 1048576) {
      return `${(num / 1048576).toFixed(1)}MB`
    }
  }
  const fileDelete = (file) => {
    if (uploading || error) {
      deleteFile(File.id)
    } else {
      onDeleteFile(file)
    }
  }
  const props = {
    columnsTemplate: '2% 54% 140px 12% 4%',
    minWidth: '95%',
    borRad: '',
    noBorder: '',
    bord: ''
  }
  if (File.length) {
    return <Main.StyledTableWrapper>
      {File.map(file => {
        return (
          <div>
            <Main.FileInfo>
              <Table.UploadRow {...props} >
                <Table.Item>
                  <SVGIcon.FileIcon />
                </Table.Item>
                <Table.Item justifyContent='left' >
                  <Main.FileName>
                  {get(file, 'name', '')}
                  </Main.FileName>
                </Table.Item>
                <Table.Item>
                {loading ? <Spin /> : ''}
                {uploading ?
                <Main.Size color='#E7FFFC' font='#04AB94'>
                  Success
                </Main.Size>
                :
                  ''
              }
              {error ?
                <Main.Size color='rgba(255, 87, 68, 0.2)' font='#FF5744'>
                  Error
                </Main.Size> :
                  ''
              }
                </Table.Item>
                <Table.Item>
                <Main.Size>
                  {file ? Size(file.size) : ''}
                </Main.Size>
                </Table.Item>
                <Table.Item>
                <MainTable.ActionItem.IconWrapper onClick={() => fileDelete(file)}>
                <SVGIcon.DeleteIcon color='#FF5744' />
                </MainTable.ActionItem.IconWrapper>
                </Table.Item>
              </Table.UploadRow>
            </Main.FileInfo>
          </div>
        )
      })}
    </Main.StyledTableWrapper>
  }
  return <></>
}
export default TableFileInfo
