import styled from 'styled-components'
import { Button, Checkbox, Divider, Form, Input, Radio, Select, Table } from 'antd'
import {
  CloseOutlined, CopyOutlined, LeftOutlined,
  LinkOutlined, SettingOutlined, UserOutlined
} from '@ant-design/icons'

const AddTEacherNameStyle = styled.p`
font-size: 17px;
font-weight: bold;
color:black;
`
const SchoolNameStyle = styled.p`
font-size: 10px;
margin-left: 6px;
font-weight: bold;
color: black;
`
const DivFlexStyle = styled.div`
display:flex;
justify-content: space-between;
align-items: center;
margin-bottom:22px;
`
const StyledInput = styled(Input)`
width: 100%;
`
const StyledSelect = styled(Select)`
width: 100%;
`
const StyledInputDiv = styled.div`
width:65%;
`
const StyledFormItem = styled(Form.Item)`
width:65%;
`
const SMReviewDiv = styled.div`
background-color: rgb(128 128 128 / 13%);
border: 2px solid #0f0f0f24;
height: 35px;
display: flex;
align-items: center;
justify-content: space-between;
border-radius: 6px;
padding-right: 8px;
`
const ReviewBodyDiv = styled.div`
margin: -16px -5px -16px -5px;
    padding-top: 22px;
    padding-bottom: 1px;
    padding-left: 13px;
    padding-right: 13px;
    background-color: rgb(128 128 128 / 13%);
    border-radius: 0px 0px 8px 15px;
    border: 1px solid rgb(128 128 128 / 13%);
`
const Errorline=styled.div`
background-color: red;
    max-width: 64%;
    height: 1px;
    margin-left: 36%;
`
const RequiredStyle=styled.div`
color: red;
font-size: 9px;
margin-left: 36%;
margin-bottom: 22px;
`
export {
  AddTEacherNameStyle,
  SchoolNameStyle,
  DivFlexStyle,
  StyledInput,
  StyledSelect,
  StyledInputDiv,
  StyledFormItem,
  SMReviewDiv,
  ReviewBodyDiv,
  Errorline,
  RequiredStyle,
}