/* eslint-disable max-len */
import React, { Component, Fragment } from 'react'
import { Pagination } from 'antd'
import { get } from 'lodash'
import { ADMIN, SENSEI, UMS_ADMIN, UMS_VIEWER } from '../../constants/roles'
import getDataFromLocalStorage from '../../utils/extract-from-localStorage'
import SearchBox from './components/SearchBox'
import SenseiMentorStyle from './SenseiMentor.style'
import SenseiMentorTable from './components/SenseiMentorTable'
import fetchMentors from '../../actions/senseiProfiles/fetchMentors'


export default class SenseiMentor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      senseiMentorData: [],
      senseiToDisplay: 0,
      currentPage: 1,
      perPage: 2,
      allMentorsData: [],
      searchKey: 'All',
      searchValue: '',
      senseiFilter: null
    }
  }

  async componentDidMount() {
    const role = getDataFromLocalStorage('login.role')

    if (role === ADMIN || role === UMS_ADMIN || role === UMS_VIEWER) {
      await fetchMentors({ first: this.state.perPage, skip: this.state.currentPage - 1 })
    } else {
      await fetchMentors({ first: 1, skip: 0, senseiFilter: `{user_some: {id: "${getDataFromLocalStorage('login.id')}"}}` })
      this.setState({
        senseiFilter: `{user_some: {id: "${getDataFromLocalStorage('login.id')}"}}`
      })
    }

    this.setState({
      senseiMentorData: this.props.fetchedMentors ? this.props.fetchedMentors.toJS() : [],
      allMentorsData: this.props.fetchedAllMentors ? this.props.fetchedAllMentors.toJS() : []
    })
  }

  componentDidUpdate(prevProps) {
    const { fetchedMentors, fetchedAllMentors } = this.props
    const { perPage, currentPage, senseiFilter } = this.state
    if (prevProps.isFetchingSenseiMentor && this.props.hasSenseiMentorFetched) {
      this.setState({
        senseiMentorData: fetchedMentors ? fetchedMentors.toJS() : [],
      })
    }
    const { notification } = this.props
    if (prevProps.isDeletingMentor && this.props.hasDeletedMentor) {
      fetchMentors({
        first: perPage,
        skip: currentPage - 1,
        senseiFilter
      })
      this.setState({
        senseiMentorData: fetchedMentors ? fetchedMentors.toJS() : [],
      })
      notification.success({
        message: 'Mentor deleted successfully!'
      })
    }
    if (prevProps.isMentorGroupStatusUpdating && this.props.hasMentorGroupStatusUpdated) {
      fetchMentors({
        first: perPage,
        skip: currentPage - 1,
        senseiFilter
      })
      this.setState({
        senseiMentorData: fetchedMentors ? fetchedMentors.toJS() : [],
      })
      notification.success({
        message: 'Mentors status updated successfully!'
      })
    }
    if ((prevProps.isMentorStatusUpdating && this.props.hasMentorStatusUpdated) ||
      (prevProps.isUpdatingMentor && this.props.hasUpdatedMentor)) {
      fetchMentors({
        first: perPage,
        skip: currentPage - 1,
        senseiFilter
      })
      this.setState({
        senseiMentorData: fetchedMentors ? fetchedMentors.toJS() : [],
      })
      notification.success({
        message: 'Mentor updated successfully!'
      })
    }
    if (prevProps.isFetchingAllMentors && this.props.hasFetchedAllMentors) {
      this.setState({
        allMentorsData: fetchedAllMentors ? fetchedAllMentors.toJS() : []
      })
    }
    if (prevProps.isAddingMentor && this.props.hasAddedMentor) {
      fetchMentors({
        first: perPage,
        skip: currentPage - 1,
        senseiFilter
      })

      this.setState({
        senseiMentorData: fetchedMentors ? fetchedMentors.toJS() : [],
      })
      notification.success({
        message: 'Mentor added successfully!'
      })
    }

    if (prevProps.isUpdatingSenseiTeam && this.props.hasUpdatedSenseiTeam) {
      fetchMentors({
        first: perPage,
        skip: currentPage - 1,
        senseiFilter
      })
      this.setState({
        senseiMentorData: fetchedMentors ? fetchedMentors.toJS() : [],
      })
    }

    if (prevProps.isAddingSenseiTeam && this.props.hasAddedSenseiTeam) {
      fetchMentors({
        first: perPage,
        skip: currentPage - 1,
        senseiFilter
      })
      this.setState({
        senseiMentorData: fetchedMentors ? fetchedMentors.toJS() : [],
      })
    }
  }
  setFilters = (state) => {
    this.setState({
      ...state
    }, function callbackToSearch() {
      this.handleSearchButton()
    })
  }

  handleSearchButton = () => {
    const { searchKey } = this.state
    let { searchValue } = this.state
    searchValue = searchValue.trim()
    switch (searchKey) {
      case 'Sensei Name':
        this.setState(
          {
            senseiFilter: `{user_some:{name_contains: "${searchValue}"}}`
          },
          this.callFetchQueryForFiltering
        )
        break
      case 'Mentor Name':
        this.setState(
          {
            senseiFilter: `{mentors_some: {user_some: {name_contains: "${searchValue}"}}}`
          },
          this.callFetchQueryForFiltering
        )
        break
      case 'Email':
        this.setState(
          {
            senseiFilter: `{user_some: {email_contains:"${searchValue}"}} 
            `
          },
          this.callFetchQueryForFiltering
        )
        break
      case 'Phone':
        this.setState(
          {
            senseiFilter: `
            {user_some:
              {phone_number_subDoc_contains:"${searchValue}"}
            }`
          },
          this.callFetchQueryForFiltering
        )
        break
      default:
        this.setState(
          {
            senseiFilter: null
          },
          this.callFetchQueryForFiltering
        )
        break
    }
  }

  callFetchQueryForFiltering = () => {
    const {
      searchKey,
      senseiFilter,
      searchValue,
    } = this.state
    if (searchKey === 'All') {
      this.setState({
        searchKey: 'All',
        searchValue: '',
        senseiFilter: null,
        currentPage: 1
      }, async () => fetchMentors({
        first: this.state.perPage,
        skip: this.state.currentPage - 1
      })
      )
    } else if (searchKey !== 'All' && searchValue !== '') {
      this.setState({
        currentPage: 1
      }, async () => fetchMentors({
        first: this.state.perPage,
        skip: this.state.currentPage - 1,
        senseiFilter
      })
      )
    }
  }

  onPageChange = page => {
    this.setState({
      currentPage: page,
      senseiToDisplay: page
    }, async () => {
      fetchMentors({
        first: this.state.perPage,
        skip: this.state.currentPage - 1,
        senseiFilter: this.state.senseiFilter
      })
    })
  }

  render() {
    const { senseiMentorData, currentPage, perPage,
      senseiToDisplay, allMentorsData } = this.state
    const { fetchedSenseiCount } = this.props
    const savedRole = getDataFromLocalStorage('login.role')
    // const savedId = getDataFromLocalStorage('login.id')
    const isAdmin = savedRole === ADMIN
    const isUmsAdmin = savedRole === UMS_ADMIN
    const isUmsViewer = savedRole === UMS_VIEWER
    const isSensei = savedRole === SENSEI
    if (savedRole && !(isAdmin || isUmsAdmin || isUmsViewer || isSensei)) {
      return <div>Not Found</div>
    }

    return (
      <Fragment>
        {
          (isAdmin || isUmsAdmin || isUmsViewer) && (
            <SenseiMentorStyle.TopContainer>
              <SearchBox innerFilterOptions={['Sensei Name', 'Mentor Name', 'Email', 'Phone']} filterDropdownOptions={['All', 'Sensei Name', 'Mentor Name', 'Email', 'Phone']}savedRole={savedRole} setFilters={this.setFilters} />
              <div
                style={{
                  margin: '0px 40px 30px 50px',
                  display: 'block',
                  justifyContent: 'flex-start',
                  width: '100%'
                }}
              >
                {fetchedSenseiCount && get(fetchedSenseiCount.toJS(), 'count') > 1 && (
                  <Pagination
                    total={fetchedSenseiCount && get(fetchedSenseiCount.toJS(), 'count')
                      ? get(fetchedSenseiCount.toJS(), 'count') : 0}
                    onChange={this.onPageChange}
                    current={currentPage}
                    defaultPageSize={perPage}
                  />
                )}
              </div>
            </SenseiMentorStyle.TopContainer>
          )
        }
        <SenseiMentorTable
          {...this.props}
          mentorsData={senseiMentorData}
          filters={this.state}
          savedRole={savedRole}
          // setTotalCount={() => this.setTotalCount}
          fetchStatus={this.props.hasSenseiMentorFetched}
          senseiToDisplay={senseiToDisplay}
          allMentorsData={allMentorsData}
        />
      </Fragment>
    )
  }
}
