import React from 'react';
import { Input, notification, Spin, Button } from 'antd';
import {
  SettingsTab,
  InnerContainer,
  AddButtonContainer,
  AddButton,
  AllTabsContainer,
  PaginationContainer
} from './EventSettings.style';
import TabPagination from './components/Pagination';
import SelectDropdown from './components/SelectDropdown';
import Speakers from './Speakers/Speakers';
import Tags from './Tags/Tags';
import Categories from './Categories/Categories';
import ViewModal from './Tags/ViewModal';
import Variables from './Variables/Variables';
import SpeakerProfileModal from './Speakers/SpeakerProfileModal';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  fetchEventTags, fetchEventCategoriesForSettings,
  fetchEventVariables, fetchEventSpeakerProfiles
} from '../../../actions/eventDashboard/eventSettings';
import { get, sortBy } from 'lodash';

const renderTabList = [
  { label: 'Speakers', value: 'Speaker' },
  { label: 'Tags', value: 'Tag' },
  { label: 'Categories', value: 'Category' },
  { label: 'Variable', value: 'Variable' },
];

const addSpeakerModalData = {
  name:'',
  email:'',
  phone:'',
  countryCode: '+91',
  roleAtOrganization:'',
  organization:'',
  about:'',
  linkedInLink:''
}

class EventSettings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lastTabCheck: renderTabList.length,
      defaultFilterSelection: { value: 'all', label: 'All' },
      modalOperation: 'add',
      defaultTab: 'Speaker',
      activeTab: '',
      filterSelection: '',
      searchQuery: '',
      isModalVisible: false,
      isSpeakerModal: false,
      isLoading: false,
      currentPage: 1,
      perPage: 7,
      perSpeakerPage: 4,
      skip: 0,
      data: [],
      currentSpeakerData: {},
    }
  }

  async componentDidMount() {
    this.setState({
      activeTab: this.state.defaultTab,
      filterSelection: this.state.defaultfilterSelection,
      currentSpeakerData: {},
      currentPage: 1,
      searchQuery: '',
      isLoading: true,
    })
  }

  async componentDidUpdate(prevProps, prevState) {
    const { activeTab, filterSelection, perPage } = this.state
    const { eventTagsData, eventTagFetchStatus, eventTagAddStatus, 
      eventTagUpdateStatus, eventCategoriesData, eventCategoryUpdateStatus, 
      eventCategoryAddStatus, eventCategoryFetchStatus, eventVariablesData, 
      eventVariableAddStatus, eventVariableFetchStatus, eventSpeakerData,
      eventSpeakerAddStatus, eventSpeakerFetchStatus, eventSpeakerUpdateStatus,
      userAddStatus, userAddFailure, userUpdateStatus, userUpdateFailure, 
      eventSpeakerAddFailure, eventTagAddFailure, eventCategoryAddFailure,
      eventVariableAddFailure, eventSpeakerUpdateFailure, eventTagUpdateFailure,
      eventCategoryUpdateFailure } = this.props

    if (prevState.activeTab != this.state.activeTab) {
      this.onChangeTabHandler()
    }

    if (get(filterSelection, 'value') !== get(prevState.filterSelection, 'value')) {
      this.onChangeTabHandler()
    }
    
    if (eventTagAddStatus && !get(eventTagAddStatus.toJS(), 'loading') 
      && get(eventTagAddStatus.toJS(), 'success') &&
      (prevProps.eventTagAddStatus !== eventTagAddStatus)) {
        const eventsData = eventTagsData && eventTagsData.toJS()
        const newData = sortBy(eventsData, 'createdAt').slice(0, perPage)
        this.setState({
          data: newData
        }, () => this.setState({ isLoading: false }))
        this.closeModalHandler()
        notification.success({
          message: `${activeTab} added successfully`
        })
    } else if (eventTagAddStatus && !get(eventTagAddStatus.toJS(), 'loading')
    && get(eventTagAddStatus.toJS(), 'failure') &&
    (prevProps.eventTagAddFailure !== eventTagAddFailure)) {
      console.log('Failed to Add Event Speaker')
    if (eventTagAddFailure && eventTagAddFailure.toJS().length > 0) {
      const errors = eventTagAddFailure.toJS().pop()
        notification.error({
          message: get(get(errors, 'error').errors[0], 'message')
        })
      }
    }

    if (eventTagFetchStatus && !get(eventTagFetchStatus.toJS(), 'loading') 
      && get(eventTagFetchStatus.toJS(), 'success') &&
      (prevProps.eventTagFetchStatus !== eventTagFetchStatus)) {
        const eventsData = eventTagsData && eventTagsData.toJS()
        this.setState({
          data: sortBy(eventsData, 'createdAt')
        }, () => this.setState({ isLoading: false }))
    }
    
    if (eventTagUpdateStatus && !get(eventTagUpdateStatus.toJS(), 'loading') 
      && get(eventTagUpdateStatus.toJS(), 'success') &&
      (prevProps.eventTagUpdateStatus !== eventTagUpdateStatus)) {
        const eventsData = eventTagsData && eventTagsData.toJS()
        let newData = [...eventsData]
        console.log(eventsData, newData)
        if (get(this.state.filterSelection, 'value') !== 'all') {
          newData = newData.filter((item) => item.tagStatus === get(this.state.filterSelection, 'value'))
        }
        console.log(newData)
        this.setState({
          data: sortBy(newData, 'createdAt')
        }, () => this.setState({ isLoading: false }))
        this.closeModalHandler()
        notification.success({
          message: `${activeTab} updated successfully`
        })
    } else if (eventTagUpdateStatus && !get(eventTagUpdateStatus.toJS(), 'loading')
    && get(eventTagUpdateStatus.toJS(), 'failure') &&
    (prevProps.eventTagUpdateFailure !== eventTagUpdateFailure)) {
      console.log('Failed to Add Event Speaker')
    if (eventTagUpdateFailure && eventTagUpdateFailure.toJS().length > 0) {
      const errors = eventTagUpdateFailure.toJS().pop()
        notification.error({
          message: get(get(errors, 'error').errors[0], 'message')
        })
      }
    }

    if (eventCategoryAddStatus && !get(eventCategoryAddStatus.toJS(), 'loading') 
      && get(eventCategoryAddStatus.toJS(), 'success') &&
      (prevProps.eventCategoryAddStatus !== eventCategoryAddStatus)) {
        const eventsData = eventCategoriesData && eventCategoriesData.toJS()
        const newData = sortBy(eventsData, 'createdAt').slice(0, perPage)
        this.setState({
          data: newData
        }, () => this.setState({ isLoading: false }))
        this.closeModalHandler()
        notification.success({
          message: `${activeTab} added successfully`
        })
    } else if (eventCategoryAddStatus && !get(eventCategoryAddStatus.toJS(), 'loading')
    && get(eventCategoryAddStatus.toJS(), 'failure') &&
    (prevProps.eventCategoryAddFailure !== eventCategoryAddFailure)) {
      console.log('Failed to Add Event Speaker')
    if (eventCategoryAddFailure && eventCategoryAddFailure.toJS().length > 0) {
      const errors = eventCategoryAddFailure.toJS().pop()
        notification.error({
          message: get(get(errors, 'error').errors[0], 'message')
        })
      }
    }

    if (eventCategoryFetchStatus && !get(eventCategoryFetchStatus.toJS(), 'loading') 
      && get(eventCategoryFetchStatus.toJS(), 'success') &&
      (prevProps.eventCategoryFetchStatus !== eventCategoryFetchStatus)) {
        const eventsData = eventCategoriesData && eventCategoriesData.toJS()
        this.setState({
          data: sortBy(eventsData, 'createdAt')
        }, () => this.setState({ isLoading: false }))
    }

    if (eventCategoryUpdateStatus && !get(eventCategoryUpdateStatus.toJS(), 'loading') 
      && get(eventCategoryUpdateStatus.toJS(), 'success') &&
      (prevProps.eventCategoryUpdateStatus !== eventCategoryUpdateStatus)) {
        const eventsData = eventCategoriesData && eventCategoriesData.toJS()
        let newData = [...eventsData]
        if (get(this.state.filterSelection, 'value') !== 'all') {
          newData = newData.filter((item) => item.status === get(this.state.filterSelection, 'value'))
        }
        this.setState({
          data: sortBy(newData, 'createdAt')
        }, () => this.setState({ isLoading: false }))
        this.closeModalHandler()
        notification.success({
          message: `${activeTab} updated successfully`
        })
    } else if (eventCategoryUpdateStatus && !get(eventCategoryUpdateStatus.toJS(), 'loading')
    && get(eventCategoryUpdateStatus.toJS(), 'failure') &&
    (prevProps.eventCategoryUpdateFailure !== eventCategoryUpdateFailure)) {
      console.log('Failed to Add Event Speaker')
    if (eventCategoryUpdateFailure && eventCategoryUpdateFailure.toJS().length > 0) {
      const errors = eventCategoryUpdateFailure.toJS().pop()
        notification.error({
          message: get(get(errors, 'error').errors[0], 'message')
        })
      }
    }

    if (eventVariableAddStatus && !get(eventVariableAddStatus.toJS(), 'loading') 
      && get(eventVariableAddStatus.toJS(), 'success') &&
      (prevProps.eventVariableAddStatus !== eventVariableAddStatus)) {
        const eventsData = eventVariablesData && eventVariablesData.toJS()
        const newData = sortBy(eventsData, 'createdAt').slice(0, perPage)
        this.setState({
          data: newData
        }, () => this.setState({ isLoading: false }))
        this.closeModalHandler()
        notification.success({
          message: `${activeTab} added successfully`
        })
    } else if (eventVariableAddStatus && !get(eventVariableAddStatus.toJS(), 'loading')
    && get(eventVariableAddStatus.toJS(), 'failure') &&
    (prevProps.eventVariableAddFailure !== eventVariableAddFailure)) {
      console.log('Failed to Add Event Speaker')
    if (eventVariableAddFailure && eventVariableAddFailure.toJS().length > 0) {
      const errors = eventVariableAddFailure.toJS().pop()
        notification.error({
          message: get(get(errors, 'error').errors[0], 'message')
        })
      }
    }

    if (eventVariableFetchStatus && !get(eventVariableFetchStatus.toJS(), 'loading') 
      && get(eventVariableFetchStatus.toJS(), 'success') &&
      (prevProps.eventVariableFetchStatus !== eventVariableFetchStatus)) {
      const eventsData = eventVariablesData && eventVariablesData.toJS()
      console.log(eventsData)
        this.setState({
          data: sortBy(eventsData, 'createdAt')
        }, () => this.setState({ isLoading: false }))
    }

    if (eventSpeakerAddStatus && !get(eventSpeakerAddStatus.toJS(), 'loading') 
      && get(eventSpeakerAddStatus.toJS(), 'success') &&
      (prevProps.eventSpeakerAddStatus !== eventSpeakerAddStatus)) {
        const eventsData = eventSpeakerData && eventSpeakerData.toJS()
        this.setState({
          data: sortBy(eventsData, 'createdAt')
        }, () => this.onChangeTabHandler())
        this.closeModalHandler()
        notification.success({
          message: `${activeTab} added successfully`
        })
      this.closeModalHandler()
    } else if (eventSpeakerAddStatus && !get(eventSpeakerAddStatus.toJS(), 'loading') 
    && get(eventSpeakerAddStatus.toJS(), 'failure') &&
    (prevProps.eventSpeakerAddFailure !== eventSpeakerAddFailure)) {
      console.log('Failed to Add Event Speaker')
    if (eventSpeakerAddFailure && eventSpeakerAddFailure.toJS().length > 0) {
      const errors = eventSpeakerAddFailure.toJS().pop()
        notification.error({
          message: get(get(errors, 'error').errors[0], 'message')
        })
      }
    }

    if (userAddStatus && !get(userAddStatus.toJS(), 'loading') 
    && get(userAddStatus.toJS(), 'failure') &&
    (prevProps.userAddFailure !== userAddFailure)) {
      console.log('Failed to Add Event Speaker')
    if (userAddFailure && userAddFailure.toJS().length > 0) {
      const errors = userAddFailure.toJS().pop()
        notification.error({
          message: get(get(errors, 'error').errors[0], 'message')
        })
      }
    }

    if (userUpdateStatus && !get(userUpdateStatus.toJS(), 'loading') 
    && get(userUpdateStatus.toJS(), 'failure') &&
    (prevProps.userUpdateFailure !== userUpdateFailure)) {
      console.log('Failed to Add Event Speaker')
    if (userUpdateFailure && userUpdateFailure.toJS().length > 0) {
      const errors = userUpdateFailure.toJS().pop()
        notification.error({
          message: get(get(errors, 'error').errors[0], 'message')
        })
      }
    }

    if (eventSpeakerFetchStatus && !get(eventSpeakerFetchStatus.toJS(), 'loading') 
      && get(eventSpeakerFetchStatus.toJS(), 'success') &&
      (prevProps.eventSpeakerFetchStatus !== eventSpeakerFetchStatus)) {
        const eventsData = eventSpeakerData && eventSpeakerData.toJS()
        this.setState({
          data: sortBy(eventsData, 'createdAt')
        }, () => this.setState({ isLoading: false }))
    }

    if (eventSpeakerUpdateStatus && !get(eventSpeakerUpdateStatus.toJS(), 'loading') 
      && get(eventSpeakerUpdateStatus.toJS(), 'success') &&
      (prevProps.eventSpeakerUpdateStatus !== eventSpeakerUpdateStatus)) {
        const eventsData = eventSpeakerData && eventSpeakerData.toJS()
        this.setState({
          data: sortBy(eventsData, 'createdAt')
        }, () => this.onChangeTabHandler())
        notification.success({
          message: `${activeTab} updated successfully`
        })
      this.closeModalHandler()
    } else if (eventSpeakerUpdateStatus && !get(eventSpeakerUpdateStatus.toJS(), 'loading') 
    && get(eventSpeakerUpdateStatus.toJS(), 'failure') &&
    (prevProps.eventSpeakerUpdateFailure !== eventSpeakerUpdateFailure)) {
      console.log('Failed to Add Event Speaker')
    if (eventSpeakerUpdateFailure && eventSpeakerUpdateFailure.toJS().length > 0) {
      const errors = eventSpeakerUpdateFailure.toJS().pop()
        notification.error({
          message: get(get(errors, 'error').errors[0], 'message')
        })
      }
    }
  }

  openErrorNotification = (message) => {
    notification.error({
      message: message,
    })
  }

  openModalHandler = () => {
    if (this.state.activeTab === 'Tag' ||
      this.state.activeTab === 'Category' ||
      this.state.activeTab === 'Variable') {
      this.setState({
        isModalVisible: true
      })
    }
    if (this.state.activeTab === 'Speaker') {
      this.setState({
        isSpeakerModal: true
      })
    }
  }

  closeModalHandler = () => {
    if (this.state.activeTab === 'Tag' ||
      this.state.activeTab === 'Category' ||
      this.state.activeTab === 'Variable') {
      this.setState({
        isModalVisible: false,
      })
    }
    if (this.state.activeTab === 'Speaker') {
      this.setState({
        modalOperation: 'add',
        isSpeakerModal: false,
        currentSpeakerData: {}
      })
    }
  }

  editModalHandler = (speakerData) => {
    this.setState({
      modalOperation: 'edit',
      currentSpeakerData: speakerData,
    }, () => this.openModalHandler())
  }

  setLoadingState = (state) => {
    this.setState({
      loading: state
    })
  }

  filteredListHandler = (filterValue) => {
    this.setState({
      isLoading: true,
      currentPage: 1,
      skip: 0,
    }, () => this.setState({ 
      filterSelection: filterValue,
    }))
  }

  checkSelectedTabComponent = (tab) => {
    if (this.state.activeTab !== tab.value) {
      this.setState({
        isLoading: true,
        currentPage: 1,
        skip: 0,
        searchQuery: '',
        currentSpeakerData: {}
      }, () => this.setState({
        activeTab: tab.value,
        filterSelection: this.state.defaultFilterSelection,
        data: [],
      }))
    }
  }

  onPageChange = (page) => {
    this.setState({
      currentPage: page,
      skip: page - 1,
      data: [],
      isLoading: true
    }, () => this.onChangeTabHandler())
  }

  onChangeTabHandler = async () => {
    const { activeTab, filterSelection, perPage, perSpeakerPage, skip, searchQuery } = this.state
    let filterQuery=''
    let createdSearchQuery=''
    // Add Speakers tab
    if (activeTab === 'Speaker') {
      if (searchQuery) {
        createdSearchQuery = `user_some: { name_contains: "${searchQuery}" }` 
      }
      await fetchEventSpeakerProfiles(perSpeakerPage, skip, createdSearchQuery)
    }
    if (activeTab === 'Tag') {
      createdSearchQuery = `title_contains: "${searchQuery}"`
      if (get(filterSelection, 'value') !== 'all') {
        filterQuery = `tagStatus: ${get(filterSelection, 'value')}`
        await fetchEventTags(perPage, skip, filterQuery, createdSearchQuery)
      } else {
        await fetchEventTags(perPage, skip, filterQuery, createdSearchQuery)
      }
    }
    if (activeTab === 'Category') {
      createdSearchQuery = `title_contains: "${searchQuery}"`
      if (get(filterSelection, 'value') !== 'all') {
        filterQuery = `status: ${get(filterSelection, 'value')}`
        await fetchEventCategoriesForSettings(perPage, skip, filterQuery, searchQuery)
      } else {
        await fetchEventCategoriesForSettings(perPage, skip, filterQuery, createdSearchQuery)
      }
    }
    if (activeTab === 'Variable') {
      createdSearchQuery = `whatsappVariableName_contains: "${searchQuery}"`
      await fetchEventVariables(perPage, skip, createdSearchQuery)
    }
  }

  renderActiveTabComponent = (activeTab) => {
    const { eventTagFetchStatus, eventCategoryFetchStatus, 
      eventVariableFetchStatus, eventSpeakerFetchStatus } = this.props
    const pageIndex = (this.state.perPage * (this.state.currentPage - 1))
    const perSpeakerPageIndex = (this.state.perSpeakerPage * (this.state.currentPage - 1))
    if (activeTab === 'Speaker') {
      return (
      <Speakers 
        data={this.state.data}
        page={perSpeakerPageIndex}
        isLoading={eventSpeakerFetchStatus && get(eventSpeakerFetchStatus.toJS(), 'loading')}
        editSpeaker={this.editModalHandler} 
      />
      )
    }
    if (activeTab === 'Tag') {
      return (
      <Tags 
        data={this.state.data}
        page={pageIndex}
        isLoading={eventTagFetchStatus && get(eventTagFetchStatus.toJS(), 'loading')}
      />
      )
    }
    if (activeTab === 'Category') {
      return (
      <Categories 
        data={this.state.data}
        page={pageIndex}
        filter={get(this.state.filterSelection, 'value')}
        isLoading={eventCategoryFetchStatus && get(eventCategoryFetchStatus.toJS(), 'loading')}
      />
      )
    }
    if (activeTab === 'Variable') {
      return (
      <Variables 
        data={this.state.data}
        page={pageIndex}
        isLoading={eventVariableFetchStatus && get(eventVariableFetchStatus.toJS(), 'loading')}
      />
      )
    }
  }

  getActiveTabCount = () => {
    const { activeTab } = this.state
    const { eventTagsCount, eventCategoriesCount, eventVariablesCount, eventSpeakersCount } = this.props
    if (activeTab === 'Speaker') {
      return eventSpeakersCount
    }
    if (activeTab === 'Tag') {
      return eventTagsCount
    }
    if (activeTab === 'Category') {
      return eventCategoriesCount
    }
    if (activeTab === 'Variable') {
      return eventVariablesCount
    }
    return 0
  }

  render() {

    return (
      <div>
        <SettingsTab.TopContainer>
          <SettingsTab.FlexContainer>
            <SettingsTab.Title>Settings</SettingsTab.Title>
            <div>
              <Input
                placeholder={`Search ${this.state.activeTab} By Name`}
                style={{ width: '200px'}}
                value={this.state.searchQuery}
                onChange={(e) => { this.setState({ searchQuery: e.target.value })}}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.setState({
                      isLoading: true,
                      skip: 0,
                      currentPage: 1,
                    }, () => this.onChangeTabHandler())
                  }
                }}
              />
            </div>
          </SettingsTab.FlexContainer>
          <Spin spinning={this.state.isLoading}>
          <InnerContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <AddButtonContainer.AddButton onClick={() => this.openModalHandler()}>
                <AddButtonContainer.AddButtonIcon style={{ strokeWidth: '100px', stroke: 'white' }}/>
                {`Create ${this.state.activeTab}`}
              </AddButtonContainer.AddButton>
              <div>
                {(this.state.activeTab === 'Tag' || this.state.activeTab === 'Category')
                  ? (
                    <div style={{ marginRight: '1rem' }}>
                      <SelectDropdown filter={this.state.filterSelection} filteredListHandler={this.filteredListHandler} />
                    </div>
                  )
                  : (<></>)}
              </div>
            </div>
            <AllTabsContainer.AllTabsList>
              {
                renderTabList && renderTabList.map((tab, idx) => (
                  <div style={{ display: 'flex', alignItems: 'center' }} key={tab.value}>
                    <AllTabsContainer.TabContainer
                      isActive={(tab.value === this.state.activeTab) ? true : false}
                      onClick={() => this.checkSelectedTabComponent(tab)}
                    >
                      {tab.label}
                    </AllTabsContainer.TabContainer>
                    <AllTabsContainer.TabSeparator>
                      {((idx + 1) < this.state.lastTabCheck ? '|' : '')}
                    </AllTabsContainer.TabSeparator>
                  </div>
                ))
              }
            </AllTabsContainer.AllTabsList>
            {!this.state.isLoading
              && this.renderActiveTabComponent(this.state.activeTab)}
              {!this.state.isLoading &&
                <PaginationContainer>
                {
                  this.state.activeTab === 'Speaker' && this.getActiveTabCount() > this.state.perSpeakerPage && (
                    <TabPagination
                      count={this.getActiveTabCount()}
                      perPage={this.state.perSpeakerPage}
                      currentPage={this.state.currentPage}
                      onPageChange={this.onPageChange}
                    />
                  )
                }
                {
                  this.state.activeTab !== 'Speaker' && this.getActiveTabCount() > this.state.perPage && (
                    <TabPagination
                      count={this.getActiveTabCount()}
                      perPage={this.state.perPage}
                      currentPage={this.state.currentPage}
                      onPageChange={this.onPageChange}
                    />
                  )
                }
                </PaginationContainer>
              }
          </InnerContainer>
          </Spin>
        </SettingsTab.TopContainer>

        {
          this.state.isModalVisible && (
            <ViewModal
              visible={this.state.isModalVisible}
              closeModal={this.closeModalHandler}
              tabType={this.state.activeTab}
            />
          )
        }
        {
          this.state.isSpeakerModal && (
            <SpeakerProfileModal 
              visible={this.state.isSpeakerModal}
              closeModal={this.closeModalHandler} 
              modalOperation={this.state.modalOperation}
              userAddFailure={this.props.userAddStatus && !get(this.props.userAddStatus.toJS(), 'loading') && get(this.props.userAddStatus.toJS(), 'failure')}
              userUpdateFailure={this.props.userUpdateStatus && !get(this.props.userUpdateStatus.toJS(), 'loading') && get(this.props.userUpdateStatus.toJS(), 'failure')}
              speakerData={(this.state.modalOperation === 'edit') ? this.state.currentSpeakerData : addSpeakerModalData}
            />
          )
        }
      </div>
    )
  }
}

export default EventSettings