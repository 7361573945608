import gql from 'graphql-tag'
import duck from '../../duck'

const fetchTrainingCoursePackages = async () =>
  await duck.query({
    query: gql`{
        coursePackages(
          filter: { and: [{ packageType: teacherTraining }, { status: published }] }
        ) {
            id
            title
            internalName
            packageType
            coursesData: courses {
              id
              title
              tools { value }
              theory { value }
              programming { value }
            }
        }
    }
    `,
    type: 'coursePackages/fetch',
    key: 'coursePackages',
  })

export default fetchTrainingCoursePackages
