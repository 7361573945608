import gql from 'graphql-tag'
import duck from '../../duck'

const fetchMentorRatings = async (first, skip, filterQuery) => duck.query({
  query: gql`
  query{
      mentorProfiles( orderBy: createdAt_DESC, 
        ${first ? `first:${first}` : ''}, 
        ${skip ? `skip:${first * skip}` : ''}, 
        ${filterQuery ? `filter:{ and: [
          ${filterQuery}
        ] }` : ''}
        ) {
        user {
          name,
          id,
        },
        pythonCourseRating5,
        pythonCourseRating4,
        pythonCourseRating3,
        pythonCourseRating2,
        pythonCourseRating1
      },
      mentorProfilesMeta(filter:{ and: [
        ${filterQuery}
      ] }) {
        count
      }
    }
  `,
  type: 'mentorRatings/fetch',
  key: 'mentorRatings',
  changeExtractedData: (extractedData, originalData) => ({
    mentorRatingsData: originalData
  })
})

export default fetchMentorRatings
