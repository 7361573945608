const TRIAL = 'trial'
const PAID = 'paid'
const BATCH = 'batch'

const SESSION_TYPES = [TRIAL, PAID, BATCH]

export {
  TRIAL,
  PAID,
  BATCH,
  SESSION_TYPES
}
