import React from "react";
import { Modal, Input, Radio, Button } from "antd";
import { Formik, Form } from "formik";

import Label from "../../../../components/Label";
import Stack from "../../../../components/Stack";
import { useFormContext } from "./FormContext";

const AddCommentModal = ({ open, handleClose }) => {
	const { commentLoading, commentSubmitHandler } = useFormContext();

	return (
		<Modal
			title="Add Comment"
			visible={open}
			onCancel={handleClose}
			centered
			footer={null}
		>
			<Formik
				initialValues={{
					commentType: "general",
					comment: "",
				}}
				onSubmit={(values, { resetForm }) =>
					commentSubmitHandler(values, resetForm)
				}
			>
				{({ values, handleChange, handleBlur }) => (
					<Form>
						<Stack gap={24}>
							<Stack gap={11}>
								<Label>Comment Type</Label>
								<Radio.Group
									name="commentType"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.commentType}
								>
									<Radio.Button value="general">General</Radio.Button>
									<Radio.Button value="lab">Lab</Radio.Button>
								</Radio.Group>
							</Stack>

							<Stack gap={11}>
								<Label>Comment</Label>
								<Input.TextArea
									name="comment"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.comment}
									placeholder="Type your comment here..."
									rows={4}
								/>
							</Stack>

							<Button
								type="primary"
								htmlType="submit"
								size="large"
								loading={commentLoading}
								disabled={commentLoading || !values.comment}
							>
								Post Comment
							</Button>
						</Stack>
					</Form>
				)}
			</Formik>
		</Modal>
	);
};

export default AddCommentModal;
