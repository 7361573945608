import gql from 'graphql-tag'
import { get } from 'lodash'
import moment from 'moment'
import duck from '../../duck'
import { MENTEE, MENTOR, PARENT, PARENT_MENTEE } from '../../constants/roles'

const getGradeFilter = (searchGrade, role) => {
  if (searchGrade) {
    if (role === MENTEE) {
      return `{ studentProfile_some: { grade: ${searchGrade} } }`
    } else if (role === PARENT) {
      return `{
        parentProfile_some: {
          children_some: { grade: ${searchGrade} }
        }
      }`
    }
    return ''
  }
  return ''
}

const getUserCountQuery = async (role, usersFilter, country, fromDate,
  toDate, verifiedAndBookedUser, searchGrade, utmFilters, isLeadPartner = false) => {
  let query = ''
  if (isLeadPartner && !utmFilters) {
    utmFilters = '{ utmTerm: "" }'
  }
  const extraMetaCounts = `verifiedUser: usersMeta(filter: {
            and: [
              ${utmFilters ? `${utmFilters}` : ''}
              {role: ${role === PARENT_MENTEE ? 'mentee' : role}}
              ${role === PARENT ? `{
                parentProfile_some: {
                  children_some: {
                    user_some: { and: [{ verificationStatus: verified }] }
                  }
                }
              }` : '{ verificationStatus: verified }'}
              ${!usersFilter ? '' : usersFilter}
              ${country === 'all' ? '' : `{country:${country}}`}
              ${role === PARENT_MENTEE || role === MENTEE ? '{source_not: school}' : ''}
              ${fromDate ? `{ createdAt_gte: "${moment(fromDate).startOf('day').toDate()}" }` : ''}
              ${toDate ? `{ createdAt_lte: "${moment(toDate).endOf('day').toDate()}" }` : ''}
              ${getGradeFilter(searchGrade, role)}
         ]
        }){
          count
        }
        verifiedAndBookedUser: menteeSessionsMeta(filter: {
        and:[
            {topic_some:{order:1}}
            {
              user_some: {
                and: [
                  ${utmFilters ? `${utmFilters}` : ''}
                  {role: mentee}
                  { verificationStatus: verified }
                  ${!usersFilter ? '' : usersFilter}
                 ${country === 'all' ? '' : `{country:${country}}`}
                  ${fromDate ? `{ createdAt_gte: "${moment(fromDate).startOf('day').toDate()}" }` : ''}
                  ${toDate ? `{ createdAt_lte: "${moment(toDate).endOf('day').toDate()}" }` : ''}
                  ${searchGrade ? `{ studentProfile_some: { grade: ${searchGrade} } }` : ''}
                ]
              }
            }
          ]
      }) {
        count
      }
      bookedUser: menteeSessionsMeta(filter: {
        and:[
            {topic_some:{order:1}}
            {
              user_some: {
                and: [
                  ${utmFilters ? `${utmFilters}` : ''}
                  {role: mentee}
                  ${!usersFilter ? '' : usersFilter}
                  ${country === 'all' ? '' : `{country:${country}}`}
                  ${fromDate ? `{ createdAt_gte: "${moment(fromDate).startOf('day').toDate()}" }` : ''}
                  ${toDate ? `{ createdAt_lte: "${moment(toDate).endOf('day').toDate()}" }` : ''}
                  ${searchGrade ? `{ studentProfile_some: { grade: ${searchGrade} } }` : ''}
                ]
              }
            }
          ]
      }) {
        count
      }
        `
  if (verifiedAndBookedUser) {
    query = gql`{
      menteeSessionsMeta(filter: {
        and:[
            {topic_some:{order:1}}
            {
              user_some: {
                and: [
                  ${utmFilters ? `${utmFilters}` : ''}
                  {role: mentee}
                  ${!usersFilter ? '' : usersFilter}
                  { verificationStatus: verified }
                  ${country === 'all' ? '' : `{country:${country}}`}
                  ${fromDate ? `{ createdAt_gte: "${moment(fromDate).startOf('day').toDate()}" }` : ''}
                  ${toDate ? `{ createdAt_lte: "${moment(toDate).endOf('day').toDate()}" }` : ''}
                  ${searchGrade ? `{ studentProfile_some: { grade: ${searchGrade} } }` : ''}
                ]
              }
            }
          ]
      }) {
        count
      }
      ${extraMetaCounts}
    }`
  } else {
    query = gql`{
        usersMeta(filter: {
            and: [
              ${utmFilters && (role === PARENT || role === MENTEE) ? `${utmFilters}` : ''}
              {role: ${role === PARENT_MENTEE ? 'mentee' : role}}
              ${!usersFilter ? '' : usersFilter}
              ${role === MENTOR ? '{secondaryRole_exists: false }' : ''}
              ${country === 'all' ? '' : `{country:${country}}`}
              ${role === PARENT_MENTEE || role === MENTEE ? '{source_not: school}' : ''}
              ${fromDate ? `{ createdAt_gte: "${moment(fromDate).startOf('day').toDate()}" }` : ''}
              ${toDate ? `{ createdAt_lte: "${moment(toDate).endOf('day').toDate()}" }` : ''}
              ${getGradeFilter(searchGrade, role)}
            ]
        }){
          count
        }
        ${(role === PARENT || role === MENTEE) ? extraMetaCounts : ''}
  }
  `
  }
  return query
}

const fetchUsersCount = async ({ role, filterQuery: { usersFilter }, country = 'india',
  fromDate, toDate, verifiedAndBookedUser, searchGrade, utmFilters, isLeadPartner }) =>
  duck.query({
    query: await getUserCountQuery(role, usersFilter, country, fromDate, toDate,
      verifiedAndBookedUser, searchGrade === 'All' ? '' : `Grade${searchGrade}`,
      utmFilters, isLeadPartner),
    type: 'userMeta/fetch',
    key: 'userMeta',
    changeExtractedData: (extractedData, originalData) => {
      extractedData.userMeta = {
        bookedUser: get(originalData, 'bookedUser.count', 0),
        usersMeta: get(originalData, 'usersMeta.count', 0),
        verifiedUser: get(originalData, 'verifiedUser.count', 0),
        verifiedAndBookedUser: get(originalData, 'verifiedAndBookedUser.count', 0),
      }
      if (verifiedAndBookedUser) {
        extractedData.userMeta.usersMeta = get(extractedData, 'menteeSessionsMeta.count', 0)
      }
      return { ...extractedData }
    }
  })

export default fetchUsersCount
