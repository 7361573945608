import { DatePicker, TimePicker } from 'antd'
import styled from 'styled-components'
import { TekieAmethyst } from '../../../constants/colors'


const SupplyCalendarWrapper = styled.div`
height: 100%;
width: 100%;
letter-spacing: 0.4px;
padding: 0px 10px;
& a:hover {
  color: #605959;
}
.supply-calendar-loading-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background: white;
  &.show {
    opacity: 0.6;
    pointer-events: initial;
  }
  pointer-events: none;
  opacity: 0;
  transition: 0.2s all ease-in-out;
}
.supply-dashboard-calender-loading-container {
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
position: absolute;
bottom: 0;
left: 0;
z-index: 999;
padding: 12px 20px;
}
.supply-dashboard-calender-loading{
font-size: 16px;
font-family: 'Inter';
font-weight: 450;
color: $accentColor;
}
`

const InfoBox = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
padding: 15px 24px;
width: 209.93px;
height: 146px;
background: #FFFFFF;
border-radius: 16px;
margin: 15px;
h3{
font-family: Inter;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 24px;
color: #212121;
margin: 24px 0px;
}
h1{
font-family: Inter;
font-style: normal;
font-weight: 600;
font-size: 32px;
line-height: 72px;
color: #212121;
margin: 0px;
}
`

const AddSupply = styled.div`
z-index: 90;
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 42px;
    padding: 12px 14px;
    background: ${TekieAmethyst};
    border-radius: 100%;
    cursor: pointer;
    box-shadow: 0px 2px 8px rgba(113, 62, 188, 0.24);
    transition: 0.2s all ease-in-out;
    border: none;
    
    @media screen and (max-width: 500px) {
        margin: 18px;
    }
    &:focus {
        box-shadow: 0px 2px 8px rgba(140, 97, 203, 0.4);
    }
    &:hover, &:focus {
        background: #713EBC;
        transform: scale(1.01);
        box-shadow: 0px 2px 8px rgba(113, 62, 188, 0.5);
    }
    &:active {
        transform: scale(.92);
    }
`

const SelectedSlotsBox = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
padding: 13px 16px;
height: 58px;
background: #FFFFFF;
box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25), 0px 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 10px;
margin: 32px 0px;
p{
font-family: Inter;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
display: flex;
align-items: center;
color: #333333;
margin: 0;
}
& .slotsCount{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 4px 10px;
width: 30px;
height: 28px;
background: #8C61CB;
border-radius: 55px;
margin-left: 10px;

font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #FFFFFF;
}
`

const CustomTimePicker = styled(TimePicker)`
    &.ant-time-picker {
        width: 100px;
    }
    .ant-time-picker-input {
        font-family: 'Inter';
        color: #333333;
        font-weight: normal;
        letter-spacing: 0px;
        border-radius: 8px;
        border: 1.5px solid #AAAAAA;
        height: 42px;
    }
    .ant-time-picker-panel-combobox .ant-time-picker-panel-select li:focus {
        color: ${TekieAmethyst} !important;
    }
    .ant-time-picker-input:hover {
        border-color: ${TekieAmethyst};
    }
`

const CustomDatePicker = styled(DatePicker)`
    &.ant-calendar-picker {
        width: 100px;
    }
    &.ant-calendar-picker:hover {
        &.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
            border-color: ${TekieAmethyst};
        }
    }
    & .ant-calendar-today-btn {
        color: ${TekieAmethyst};
    }
    .ant-calendar {
        border-radius: 8px !important;
    }
    .ant-calendar-today > div {
        border-color: ${TekieAmethyst} !important;
        color: ${TekieAmethyst} !important;
    }
    .ant-calendar-selected-day .ant-calendar-date {
        background: ${TekieAmethyst} !important;
        color: white !important;
    }
    .ant-calendar-picker-input.ant-input {
        font-family: 'Inter';
        color: #333333;
        font-weight: normal;
        letter-spacing: 0px;
        border-radius: 8px;
        border: 1.5px solid #AAAAAA;
        height: 42px;
    }
    .ant-time-picker-panel-combobox .ant-time-picker-panel-select li:focus {
        color: ${TekieAmethyst} !important;
    }
`

const TabDot = styled.div`
width: 16px;
height: 16px;
background: ${props => props.isActive ? '#8C61CB' : 'rgba(140, 97, 203, 0.3)'};
border-radius: 50px;
cursor: pointer;
&:nth-of-type(2){
  margin: 0 10px;
}
`

const MentorProfile = styled.div`
width: 20px;
height: 20px;
background: url(${props => props.profilePic || 'none'});
border-radius: 50px;
border: 0.5px solid lightgray;
`

const WithBackdrop = styled.div`
    font-family: 'Inter' ;
    z-index: 999;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    transition: filter 200ms ease-in-out, visibility .1s cubic-bezier(0.4,0.0,0.2,1), backdrop-filter .3s ease-in-out;
    background: rgba(0,0,0,0.6);
    backdrop-filter: ${props => props.visible ? 'blur(5px)' : 'blur(0px);'};
`

SupplyCalendarWrapper.InfoBox = InfoBox
SupplyCalendarWrapper.AddSupply = AddSupply
SupplyCalendarWrapper.SelectedSlotsBox = SelectedSlotsBox
SupplyCalendarWrapper.CustomTimePicker = CustomTimePicker
SupplyCalendarWrapper.CustomDatePicker = CustomDatePicker
SupplyCalendarWrapper.TabDot = TabDot
SupplyCalendarWrapper.MentorProfile = MentorProfile
SupplyCalendarWrapper.WithBackdrop = WithBackdrop

export default SupplyCalendarWrapper
