import { notification } from 'antd'
import { withRouter } from 'react-router-dom'
import withNav from '../../components/withNav'
import injectProps from '../../components/injectProps'
import UtmDetails from './UtmDashboard'

const UmsDashboardNav = withNav(UtmDetails)({
  title: 'UTM Details',
  activeNavItem: 'UTM',
  showUMSNavigation: true,
})

const UmsDashboardNavWithExtraProps = injectProps({
  notification
})(UmsDashboardNav)

export default (withRouter(UmsDashboardNavWithExtraProps))
