import gql from 'graphql-tag'
import duck from '../../duck'
import requestToGraphql from '../../utils/requestToGraphql'

const deleteMentorMenteeSession = async (id) => {
  return await requestToGraphql(gql`
        mutation {
          deleteMentorMenteeSession(id:"${id}") {
            id
          }
        }
     `)
}

export default deleteMentorMenteeSession
