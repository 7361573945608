import gql from 'graphql-tag'
import duck from '../../../duck'

const removeSlideContentFile = async (learningContentId,FileID) => duck.query({
  query: gql`
  mutation {
    removeFromLearningSlideContentFile(learningSlideContentId:"${learningContentId}",fileId:"${FileID}") {
      learningSlideContent {
        id
      }
  }
}
  `,
  type: 'removeSlideContent/delete',
  key: `removeSlideContent`,
})

export default removeSlideContentFile
