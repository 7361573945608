import gql from "graphql-tag"
import duck from "../../../../duck"

const fetchEventSpeakerProfiles = async (first, skip, searchQuery) => {
    await duck.query({
      query: gql`
        query {
          eventSpeakerProfiles (
            filter: { and: [ ${searchQuery ? `{ ${searchQuery} }`:'' } ] }
            orderBy: createdAt_ASC
            first: ${first}
            skip: ${first * skip}
          ) {
            id
            user {
              id
              name
              role
              email
              phone {
                number
                countryCode
              }
              profilePic {
                id
                uri
              }
            }
            roleAtOrganization
            organization
            about
            linkedInLink
          }

          eventSpeakerProfilesMeta(
            filter: { and: [ ${searchQuery ? `{ ${searchQuery} }`:'' } ] }
          ) {
            count
          }
        }
        `,
      type: 'eventSpeakerProfiles/fetch',
      key: 'eventSpeakerProfiles',
      changeExtractedData: (_extractedData, originalData) => {
        return originalData
      }
    })
  }
  
  export default fetchEventSpeakerProfiles