import gql from 'graphql-tag'
import duck from '../../duck'
import { get } from 'lodash'

const updateCampaignBatches = async (id, value, campaignId) =>
    duck.query({
        query: gql`
        mutation updateCampaignBatches($input:BatchUpdate){
                updateBatch(id: "${id}", input: $input) {
                 id
                code
                customSessionLink
                createdAt
                classes {
                    id
                    grade
                    section
                }
                campaign {
                    id
                    type
                }
                studentsMeta {
                    count
                }
                course {
                    id
                    title
                }
                allottedMentor {
                    id
                    name
                }
        }
            
        }`,
        variables: {
            input: { customSessionLink: value }
        },
        type: 'campaignBatches/update',
        key: 'campaignBatches',
        changeExtractedData: (extractedData, originalData) => {
            const batches = []
            const batch = get(originalData, 'updateBatch')
            const grades = [...new Set(get(batch, 'classes', []).map(({ grade }) => grade))]
            const sections = [...new Set(get(batch, 'classes', []).map(({ section }) => section))]
            const session = get(batch, 'b2b2ctimeTable')
            let bookingDate, slot
            for (const property in session) {
                if (property.startsWith('slot')) {
                    if (session[property] === true) {
                        slot = property
                        bookingDate = get(session, 'bookingDate')
                    }
                }
            }
            extractedData.batches = { ...{
                ...batch,
                grade: grades,
                section: sections,
                students: get(batch, 'studentsMeta.count', 0),
                allottedMentor: get(batch, 'allottedMentor'),
                course: get(batch, 'course.title'),
                customSessionLink: get(batch, 'customSessionLink'),
                slot,
                bookingDate
            }}
            return { ...extractedData }
    }
})


export default updateCampaignBatches