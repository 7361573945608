import { CaretDownOutlined } from '@ant-design/icons';
import React, { Component } from 'react';

export default class DownArrowIcon extends Component {
    render() {
        return(
        <div style={{backgroundColor:"Black",borderRadius:"0px",height: "17px",cursor: "pointer"}}>
            <CaretDownOutlined style={{color:"white",fontSize:"18px"}}/>
        </div>
        )
    }
}
