import gql from 'graphql-tag'
import duck from '../../../duck'
import getIdArrForQuery from '../../../utils/getIdArrForQuery'

const addComicStrip = async ({ input, loId, comicImagesConnectIds=[], coursesConnectIds=[] }) =>
  duck.query({
    query: gql`
    mutation($input: ComicStripInput!) {
    addComicStrip(
      input: $input,
      learningObjectivesConnectIds: "${loId}",
      ${comicImagesConnectIds.length > 0 ? `comicImagesConnectIds: [${getIdArrForQuery(comicImagesConnectIds)}]` : ''}
      ${coursesConnectIds.length > 0 ? `coursesConnectIds: [${getIdArrForQuery(coursesConnectIds)}]` : ''}
    ) {
        id
        title
        description
        status
        learningObjectives {
          id
        }
        comicImages {
          id
          image {
            id
            uri
          }
          order
        }
    }
    }
    `,
    variables: {
      input
    },
    type: 'comicStrips/add',
    key: 'comicStrips',
  })

export default addComicStrip
