import gql from "graphql-tag"
import requestToGraphql from "../../utils/requestToGraphql"

export const addEbookChapter = async (
    input,
    ebookId,
) => {
    const { order, pageNumber, title } = input
    const query = gql`
            mutation {
                addEbookChapter( input: {
                        order: ${order},
                        pageNumber: ${pageNumber},
                        title: "${title}",
                    }
                    ebookConnectId:"${ebookId}"
                ){
                    id
                }
            }
    `
    const response = await requestToGraphql(query)
    return response
}