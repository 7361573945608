import gql from 'graphql-tag'
import duck from '../../duck'

const deleteEventWinners = async (eventId) =>
  duck.query({
    query: gql`
    mutation {
        deleteEventWinner(id: "${eventId}") {
          id
        }
      }
    `,
    type: 'deleteEventWinner/delete',
    key: 'deleteEventWinner',
  })

export default deleteEventWinners


