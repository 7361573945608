import React from 'react'
import SupplyCalendarWrapper from '../SupplyCalendar.style'
import Styles from '../../DemandSupplyDashboard.style'

const EditSlotTabs = (props) => {
  const { isGeneralActive, onGeneralClick, isStudentActive,
    onStudentClick, isMentorActive, onMentorClick,
    isSlotEditing, isB2C } = props
  if (isSlotEditing) {
    return (
      <Styles.TabContainer isB2C={isB2C}>
        <Styles.TabButton className='general-btn' isActive={isGeneralActive} onClick={onGeneralClick}>
          General
        </Styles.TabButton>
        {
            !isB2C && (
              <Styles.TabButton className='student' isActive={isStudentActive} onClick={onStudentClick}>
                Student
              </Styles.TabButton>
            )
        }
        <Styles.TabButton className='mentor' isActive={isMentorActive} onClick={onMentorClick}>
          Mentor
        </Styles.TabButton>
      </Styles.TabContainer>
    )
  }
  return (
    <Styles.FlexContainer style={{ padding: '15px', width: '100%' }} justify='center'>
      <SupplyCalendarWrapper.TabDot
        isActive={isGeneralActive}
        onClick={onGeneralClick}
      />
      <SupplyCalendarWrapper.TabDot
        isActive={isStudentActive}
        onClick={onStudentClick}
      />
      <SupplyCalendarWrapper.TabDot
        isActive={isMentorActive}
        onClick={onMentorClick}
      />
    </Styles.FlexContainer>
  )
}

export default EditSlotTabs
