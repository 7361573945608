import gql from 'graphql-tag'
import duck from '../../duck'

const fetchSchoolList = async (firstFiveSchool, filterString="") =>
  duck.query({
    query: gql`
    {
      ${filterString ? `schools(filter: ${filterString}) {
        id
        name
      }` : `schools {
        id
        name
      }`}
    }
    `,
    type: 'schools/fetch',
    key: 'schools',
  })

export default fetchSchoolList
