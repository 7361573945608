import { get } from 'lodash'

const filterOption = (input, option) => get(option, 'props.value')
  ? get(option, 'props.value')
    .toLowerCase()
    .indexOf(input.toLowerCase()) >= 0
  : false

const filterLabel = (input, option) => get(option, 'props.label')
  ? get(option, 'props.label')
    .toLowerCase()
    .indexOf(input.toLowerCase()) >= 0
  : false


export {
  filterOption,
  filterLabel
}
