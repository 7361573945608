import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../../duck'

const fetchTopicsForChapters = async (courseId) =>
  duck.query({
    query: gql`
        {
        topics(filter: { courses_some: { id: "${courseId}" } }) {
          id
          title
          courses {
            id
            title
          }
        }
      }
    `,
    type: 'topics/fetch',
    key: `topics/${courseId}`,
    changeExtractedData: (extractedData, originalData) => {
      extractedData.topic = get(originalData, 'topics')
      return extractedData
    },
  })

export default fetchTopicsForChapters

