import { connect } from 'react-redux'
import TeachersTab from './TeachersTab'

const mapStateToProps = (state) => ({
    AddUserStatus: state.data.getIn(['users','addStatus','users']),
    AddUserData: state.data.getIn(['user','data']),
    AddUserError: state.data.getIn(['errors','users/add']),
    AddMentorStatus: state.data.getIn(['addMentorProfile','addStatus','addMentorProfile']),
    AddMentorData: state.data.getIn(['addMentorProfile','data']),
    AddMentorError: state.data.getIn(['errors','addMentorProfile/add',]),
    UpdateUserStatus: state.data.getIn(['users','updateStatus','users']),
    UpdateUserError: state.data.getIn(['errors','users/update']),
    DeleteUserStatus: state.data.getIn(['users','deleteStatus','users']),
})

export default connect(mapStateToProps)(TeachersTab)

