import gql from 'graphql-tag'
import { get } from 'lodash';
import duck from '../../duck';


const fetchSpeakers = async (searchValue) => {
    await duck.query({
        query: gql`
       query {
        eventSpeakerProfiles(filter: { user_some: { name_contains: "${searchValue}" } }) {
            id
            linkedInLink
            roleAtOrganization
            organization
            about
            user{
              id
              name
              email
              profilePic{
                  id
                  uri
              }
              phone{
                  countryCode
                  number
              }
            }
          }
          }
          `,
        type: 'eventSpeakerProfiles/fetch',
        key: 'eventSpeakerProfiles',
        changeExtractedData: (extracted, original) => {

            return { ...extracted, eventSpeakerProfiles: get(original, 'eventSpeakerProfiles', []) }
        }
    })
}

export default fetchSpeakers