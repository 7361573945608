import { Checkbox, Modal, Select } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const FlexContainer = styled.div`
    display: flex;
    align-items: ${props => props.align ? props.align : 'flex-start'};
    justify-content: ${props => props.justify ? props.justify : 'flex-start'};
    flex-direction: ${props => props.direction ? props.direction : 'row'};
    flex-wrap: wrap;
    gap: ${props => props.gap ? props.gap : ''};
    width: ${props => props.width ? props.width : 'auto'};
`
const PrimaryButton = styled.button`
    padding: 9px 18px;
    background: ${props => props.disabled ? '#eee' : '#8C61CB'};
    border-radius: 7px;
    border: none;
    outline: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    white-space: nowrap;
    color: ${props => props.disabled ? '#000' : '#FFF'};
    cursor: ${props => props.disabled ? 'disabled' : 'pointer'};
    width: ${props => props.width ? props.width : 'auto'};
`
const GradeSectionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 21px;
    box-sizing: border-box;
    width: 100%;
`

const GradeSectionBoxContainer = styled.div`
    max-height: 350px;
    overflow: auto;
    width: 100%;
`

const GradeContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0;
    gap: 10px;
    border-top: 1px solid #E2E2E2;
    border-bottom: 1px solid #E2E2E2;
    width: 100%;
`

const GradeButton = styled.button`
    width: 51px;
    height: 37px;
    border-radius: 10px;
    border: ${props => props.isSelected ? '1.33725px solid #8C61CB' : '1.3px solid #DCDCDC'};
    background: ${props => props.isSelected ? 'rgba(140, 97, 203, 0.2)' : '#FFF'};
    color: ${props => props.isSelected ? '#8C61CB' : '#000'};
    cursor: pointer;
`

const StyledSelect = styled(Select)`
    width:23.125vw;
    & .ant-select-selection{
        height: 40.4px;
        border: 1.33725px solid #E2E2E2;
        border-radius: 5.34902px;
    }
    & .ant-select-selection__rendered{
        height: 100%;
        display: flex;
        align-items: center;
    }
    & .ant-select-selection-selected-value{
        font-size: 0.90vw;
        line-height: 1.25vw;
        color: black;
    }
    & input{
        font-size: 0.90vw !important;
        line-height: 1.25vw !important;
        color: black;
    }
    & .ant-select-arrow{
        right: ${props => props.selectCampaign ? '1.56vw' : '11px'};
    }
    & .ant-select-arrow-icon{
        font-size: 1.0546875vw;
        color: black;
    }
`
const VerticalLine = styled.div`
    height: 1px;
    background: #000;
    width: 75%;
    opacity: 0.2;
    margin: 0 16px;
`

const AntModal = styled(Modal)`
    &&& {
        &.ant-modal {
            min-width: 430px !important;
        }
        & .ant-modal-content {
            border-radius: 16px;
        }
        & .ant-modal-header {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            padding: 15px 24px 0 24px !important;
            border-bottom: none !important;
        }
        & .ant-modal-body {
            min-height: 530px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
`

const SectionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 18px 24px;
    gap: 30px;
    background: #FFFFFF;
    border: 1.4px solid #E2E2E2;
    border-radius: 23.9739px;
    min-width: 450px;
    max-width: 450px;
`

const NotMapped = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5.3px 10.6px;
    gap: 5.32px;
    background: rgba(250, 173, 20, 0.12);
    border-radius: 5.3px;
    width: fit-content;
    h6 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        color: #BA7C00;
        margin: 0;
    }
`

const SectionMappingContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    width: 170px;
    span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #757575;
        margin-bottom: 11px;
    }
    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: #212121;
    }
`

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
    input {
        border: 1.375px solid #E2E2E2;
        border-radius: 11px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #121212;
        padding: 10px 16px;
        width: 100%;
    }
`

const InputLabel = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #666666;
    margin: 0;
    margin-bottom: 8px;
`

const CheckBoxTextContainer = styled(Checkbox)`
    & .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        display: flex;
        gap: 8px;
        margin-top: 8px !important;
    }
    span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        color: #858585;
    }
`

const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        color: #858585;
    }
`

const CurrentClassName = styled.div`
    display: flex;
    align-items: center;
    background: rgba(220, 220, 220, 0.6);
    border-radius: 11px;
    padding: 7px 14px;
    gap: 5.5px;
    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #666666;
    }
`

const StudentsList = styled.div`
    display: flex;
    flex-direction: column;
    border: 1.3px solid #E2E2E2;
    border-radius: 11px;
    width: 100%;
    margin-bottom: 20px;
    .listHeader {
        display: flex;
        width: 100%;
        height: 50px;
        div {
            display: flex;
            justify-centent: center;
            align-items: center;
            height: 100%;
        }
        label {
            width: 100%
        }
        .ant-checkbox-wrapper {
            width: 100%;
            display: flex;
            justify-centent: center;
        }
    }
    .listContainer {
        max-height: 270px;
        overflow: auto;
    }
    .checkboxContainer {
        border-right: 1.3px solid #E2E2E2;
        width: 93px;
        display: flex;
        justify-content: center;
    }
    h4 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        margin-bottom: 0;
        text-align: center;
        width: 100%;
    }
    .rollNo {
        width: 126px;
        border-right: 1.3px solid #E2E2E2;
    }
    .studentName {
        flex: 1;
    }
`

const MappedLink = styled(Link)`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #8C61CB;
    :hover {
        text-decoration-line: underline;
        color: #8C61CB;
    }
`

export {
    FlexContainer,
    PrimaryButton,
    GradeSectionsContainer,
    GradeSectionBoxContainer,
    GradeContainer,
    GradeButton,
    SectionsContainer,
    StyledSelect,
    VerticalLine,
    AntModal,
    NotMapped,
    SectionMappingContainer,
    InputContainer,
    InputLabel,
    CheckBoxTextContainer,
    ToggleContainer,
    CurrentClassName,
    StudentsList,
    MappedLink,
}
