import { notification } from 'antd'
import React from 'react'
import Styles from '../DemandSupplyDashboard.style'
import '../demandSupplyStyles.scss'

const customNotification = (message, description, Icon, type) => {
  notification[type]({
    message,
    icon: (Icon &&
    <>
      <Styles.Icon theme='twoTone'
        fillSvg='white'
        marginRight='0px'
        component={Icon}
      />
    </>),
    description,
    className: Icon ? 'demand-supply-className' : 'demand-supply-withIcon-className',
  })
}

export default customNotification
