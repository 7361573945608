import gql from 'graphql-tag'
import duck from '../../duck';
import moment from 'moment';
import { get } from 'lodash';
import requestToGraphql from '../../utils/requestToGraphql'

const updateEventPreview = async (id, input, eventStatus) => {
  let updatedInput = {}
  if (eventStatus !== 'cancelled' && Object.keys(input).length>1) {
    const { selectedDateRange: { startDate, endDate },
      selectedTimezone, rescheduleReason, sendRescheduleComms,
      selectedWeekDays, status, oneDay } = input
    let { timeValues: { startTime, endTime } } = input
    startTime = parseInt(moment(startTime).format('HH'))
    endTime = parseInt(moment(endTime).format('HH'))

    const slots = [{ slot0: false },
    { slot1: false },
    { slot2: false },
    { slot3: false },
    { slot4: false },
    { slot5: false },
    { slot6: false },
    { slot7: false },
    { slot8: false },
    { slot9: false },
    { slot10: false },
    { slot11: false },
    { slot12: false },
    { slot13: false },
    { slot14: false },
    { slot15: false },
    { slot16: false },
    { slot17: false },
    { slot18: false },
    { slot19: false },
    { slot20: false },
    { slot21: false },
    { slot22: false },
    { slot23: false },]
  
    for (let i = startTime; i <= endTime; i++) {
      if (endTime - startTime === 1) {
        if (i === startTime) {
          slots[i][`slot${startTime}`] = true
        }
      } else if (startTime === endTime) {
        for (let j = 0; j < slots.length; j++) {
          slots[j][`slot${j}`] = true
        }
      } else {
        for (let k = startTime; k < endTime; k++) {
          slots[k][`slot${k}`] = true
        }
      }
    }
  
    const eventTimeTableRule = {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    }
    for (let i = 0; i < slots.length; i++) {
      eventTimeTableRule[`slot${i}`] = false
    }
    eventTimeTableRule[`slot${startTime}`] = true

    const weekDaysMap = {
      S: 'sunday',
      M: 'monday',
      T: 'tuesday',
      W: 'wednesday',
      Th: 'thursday',
      F: 'friday',
      Sa: 'saturday'
    }
    for (let key in weekDaysMap) {
      if (selectedWeekDays.includes(key)) {
        eventTimeTableRule[weekDaysMap[key]] = true
      } else {
        eventTimeTableRule[weekDaysMap[key]] = false
      }
    }
    updatedInput = {
      eventTimeTableRule: eventTimeTableRule,
      timeZone: selectedTimezone,
      shouldSendRescheduledComms: sendRescheduleComms === 'yes' ? true : false,
      rescheduledReason: rescheduleReason || '',
      status,
      dateType: oneDay ? 'oneday' : 'recurring'
    }
  } else if (eventStatus === 'cancelled') {
    const { cancellationReason, cancellationComms, status } = input 
    updatedInput = {
      cancellationReason: cancellationReason || '',
      shouldSendCanceledComms: cancellationComms === 'yes' ? true : false,
      status
    }
  } else {
    updatedInput = {
      status: get(input, 'updateData.status')
    }
  }
  return await requestToGraphql(gql`mutation ($input:EventUpdate) {
            updateEvent(
              id:"${id}"
              input: $input
            ) {
              id
              name
              rescheduledReason
              eventTimeTableRule {
                startDate
                endDate
                slot0
                slot1
              }
              timeZone
              status
            }
          }`, { input: updatedInput })
}

export default updateEventPreview