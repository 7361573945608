import gql from 'graphql-tag'
import duck from '../../duck'

const fetchAcademicYears = async (schoolId) =>
  duck.query({
    query: gql`
    {
        academicYears(
            filter: { school_some: { id: "${schoolId}" } }
            orderBy: endDate_ASC
        ) {
            id
            startDate
            endDate
        }
    }
    `,
    type: 'academicYears/fetch',
    key: 'academicYears',
  })

export default fetchAcademicYears
