const dimensions = {
  headerHeight: '56px',
  sideNavWidth: '64px',
  updatedSideNavWidth: '280px',
  phone: {
    width: '310px',
    height: '550px'
  }
}

export default dimensions
