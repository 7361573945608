export const transformCase = (str,type) => {
    if (type === 'lowercase'){
        return str.toLowerCase()
    }
    if (type === 'uppercase'){
        return str.toUpperCase()
    }
    if (type === 'capitalize'){
        return str.replace(
    /\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

}