// eslint-disable-next-line max-len
export const mentorSessionStatus = (sessionCommentByMentor, sessionRecordingLink) => {
  if (sessionCommentByMentor !== null && sessionRecordingLink !== null) {
    return 'Successfull'
  }
  return 'Error'
}
const price = {
  1: 200,
  2: 250,
  3: 300,
  4: 350,
  11: 400
}

export const getSessionPrice = (totalStudents) => {
  if (totalStudents) {
    if (totalStudents < 4) {
      return totalStudents * price[totalStudents]
    }
    if (totalStudents >= 4 && totalStudents <= 10) {
      return totalStudents * price[4]
    }
    return totalStudents * price[11]
  }
  return 0
}

export const getFormattedDate = (date) => {
  const dateArray = date.split(' ')
  return `${dateArray[2].substr(0, dateArray[2].length - 1)} ${dateArray[1]}, ${dateArray[3]} ${dateArray[4]}${dateArray[5]}`
}

export const getBatchType = (batchType) => {
  if (batchType) {
    if (batchType === 'normal') {
      return 'B2C'
    }
    if (batchType === 'b2b') {
      return 'B2B'
    }
    if (batchType === 'b2b2c') {
      return 'B2B2C'
    }
  }
  return ''
}
