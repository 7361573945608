import gql from "graphql-tag"
import { get } from "lodash"
import requestToGraphql from "../../../../utils/requestToGraphql"

const fetchLastTrainingBatch = async (schoolCode) => {
    const batch = await requestToGraphql(gql`{
      schoolBatchCodes: batches(filter:{
        code_contains: "TB-${schoolCode}-"
      }, orderBy: createdAt_DESC, first: 2) {
        id
        code
      }
    }`)
    return get(batch, 'data.schoolBatchCodes', [])
}

const fetchLastClassroomBatch = async (schoolCode) => {
    const batch = await requestToGraphql(gql`{
      schoolBatchCodes: batches(filter:{
        code_contains: "CR-${schoolCode}-"
      }, orderBy: createdAt_DESC, first: 2) {
        id
        code
      }
    }`)
    return get(batch, 'data.schoolBatchCodes', [])
}

  const countDigits = (n) => {
    return (4 - Math.floor(Math.log10(n) + 1))
  }

  const getSchoolBatchCode = (input, schoolBatchCode, schoolBatchPrefix, schoolCode) => {
    let codeNumber = Number(input)
    codeNumber += 1
    const numZeroesInString = countDigits(codeNumber)
    if (numZeroesInString > 0) {
      let codeNumberString = '0'
      for (let i = 0; i < numZeroesInString - 1; i++) {
        codeNumberString += '0'
      }
      codeNumberString += `${codeNumber}`
      schoolBatchCode += `${schoolBatchPrefix}-${schoolCode}-${codeNumberString}`
    } else {
      schoolBatchCode += `${schoolBatchPrefix}-${schoolCode}-${codeNumber}`
    }
    return schoolBatchCode
  }

const getTrainingBatchCode = async (schoolCode, forClassrooms = false) => {
      schoolCode = schoolCode.toUpperCase()
      let schoolBatchPrefix = 'TB'
      if (forClassrooms) schoolBatchPrefix = 'CR'
      let schoolBatchCode = ''
      let codeNumber = 1
      let batches = []
      if (forClassrooms) batches = await fetchLastClassroomBatch(schoolCode);
      else batches = await fetchLastTrainingBatch(schoolCode);
      if (batches && batches.length) {
        let schoolCodeArr = get(batches, '[0].code').split('-')
        let lastElem = schoolCodeArr.length - 1
        if (!isNaN(Number(schoolCodeArr[lastElem]))) {
          schoolBatchCode = getSchoolBatchCode(schoolCodeArr[lastElem], schoolBatchCode, schoolBatchPrefix, schoolCode)
        } else if (batches.length > 1) {
          schoolCodeArr = get(batches, '[1].code').split('-')
          let lastElem = schoolCodeArr.length - 1
          if (!isNaN(Number(schoolCodeArr[lastElem]))) {
            schoolBatchCode = getSchoolBatchCode(schoolCodeArr[lastElem], schoolBatchCode, schoolBatchPrefix, schoolCode)
          } else {
            schoolBatchCode += `${schoolBatchPrefix}-${schoolCode}-000${codeNumber}`
          }
        }
      } else {
        schoolBatchCode += `${schoolBatchPrefix}-${schoolCode}-000${codeNumber}`
      }
    return schoolBatchCode;
}

export default getTrainingBatchCode;
