import gql from 'graphql-tag'
import duck from '../../duck'

const FETCH_LEAD_PARTNERS = ({ first, skip }) => gql`
  query{
    leadPartners(
      first: ${first}
      skip: ${skip * first} 
    ){
      id
      title
      admins {
        id
        name
        email
        phone{
          number
          countryCode
        }
      }
      agents {
        id
        dayWiseBooking
        monthlyBooking
        dayWiseConduction
        monthlyConduction
        countries {
          value
        }
        timezones{
          value
        }
        agent {
          id
          name
          email
          phone {
            number
            countryCode
          }
        }
      }
    }
    leadPartnersMeta{
      count
    }
  }
`
const fetchLeadPartners = async ({ first, skip }) => duck.query({
  query: FETCH_LEAD_PARTNERS({ first, skip }),
  type: 'leadPartners/fetch',
  key: 'leadPartners',
  changeExtractedData: (extractedData, originalData) => {
    extractedData.leadPartners = originalData.leadPartners
    extractedData.leadPartnersMeta = originalData.leadPartnersMeta
    return { ...extractedData }
  }
})

export default fetchLeadPartners
