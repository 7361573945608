import React from 'react'
import moment from 'moment'
import { get } from 'lodash'
import SupplyCalendarWrapper from './SupplyCalendar.style'
import Styles from '../DemandSupplyDashboard.style'
import DateSelector from '../components/DateSelector'
import Calendar from '../../../components/FullCalendar/FullCalendar'
import { PlusSvg } from '../../../constants/icons'
import getDateRangeArray from '../demandSupply-utils/getDateRangeArray'
import appConfig from '../../../config/appConfig'
import { getFailureStatus, getSuccessStatus } from '../../../utils/data-utils'
import { fetchMentorAvailabilitySlots, fetchMentorSupplyPaySlab } from '../../../actions/demandSupplyDashboard'
import BulkAddModal from './components/BulkAddModal'
import verticals from '../demandSupply-utils/verticals'
import SuppyDashboardStats from './components/SuppyDashboardStats'
import PageLoading from '../components/PageLoading'
import fetchSupplyStatsData from '../demandSupply-utils/fetchSupplyStatsData'
import getDataFromLocalStorage from '../../../utils/extract-from-localStorage'
import { SENSEI } from '../../../constants/roles'
import fetchSenseiProfile from '../../../actions/sessions/fetchSenseiProfile'

class SupplyCalendar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedDate: null,
      fromDate: null,
      toDate: null,
      selectedVertical: 'All',
      selectedPaySlab: 'All',
      // selectedStage: 'All',
      fetchedEvents: [],
      isAddingSlots: false,
      selectedSlots: [],
      paySlabArray: [{
        title: 'All', id: 'All'
      }],
      bulkAddVisible: false,
      isSlotEditing: false,
      supplyStatsData: null,
      totalSlotsOpened: 0,
      mentors: []
    }
  }

  componentDidMount = async () => {
    this.setState({
      selectedDate: new Date()
    })
    await fetchMentorSupplyPaySlab()
  }

  fetchMentorAvailabilitySlotsQuery = async () => {
    const { fromDate, toDate, selectedPaySlab,
      selectedVertical } = this.state
    const savedRole = getDataFromLocalStorage('login.role')
    let filters = ''
    if (fromDate) filters += `{ date_gte: "${moment(fromDate).startOf('day')}" }`
    if (toDate) filters += `{ date_lte: "${moment(toDate).subtract(1, 'day').endOf('day')}" }`
    if (selectedPaySlab && selectedPaySlab !== 'All') {
      filters += `{ paySlab_some: { id: "${selectedPaySlab}" } }`
    }
    if (selectedVertical && selectedVertical !== 'All') {
      filters += `{verticals_value_subDoc:${selectedVertical}}`
    }
    if (savedRole && savedRole === SENSEI && this.state.mentors.length === 0) {
      let mentorsId = null
      const senseiId = getDataFromLocalStorage('login.id')
      await fetchSenseiProfile(senseiId).then(res => {
        mentorsId = get(res, 'user.senseiProfile.mentors', []).map(({ user }) => get(user, 'id'))
      })
      this.setState({
        mentors: mentorsId
      })
    }
    fetchSupplyStatsData(fromDate, moment(toDate).subtract(1, 'day').endOf('day')).then(res => {
      this.setState({
        supplyStatsData: {
          totalSlotsOpened: this.state.totalSlotsOpened,
          ...res
        }
      })
    })
    await fetchMentorAvailabilitySlots(filters, this.state.mentors)
  }

  componentDidUpdate = (prevProps) => {
    const { mentorSupplyPaySlabsFetchStatus, mentorAvailabilitySlotsFetchStatus,
      mentorDemandSlotsAddStatus, mentorDemandSlotsAddFailure,
      mentorDemandSlotsUpdateStatus, mentorDemandSlotsUpdateFailure } = this.props
    if (getSuccessStatus(mentorSupplyPaySlabsFetchStatus,
      prevProps.mentorSupplyPaySlabsFetchStatus)) {
      const fetchedPaySlab = this.props.mentorSupplyPaySlabs
        && this.props.mentorSupplyPaySlabs.toJS() || []
      const newPaySlab = [{
        title: 'All', id: 'All'
      }, ...fetchedPaySlab]
      this.setState({
        paySlabArray: newPaySlab
      }, this.convertDatatoEvents)
    }

    if (getSuccessStatus(mentorAvailabilitySlotsFetchStatus,
      prevProps.mentorAvailabilitySlotsFetchStatus)) {
      this.convertDatatoEvents()
    }
    getFailureStatus(mentorDemandSlotsAddStatus,
      mentorDemandSlotsAddFailure,
      prevProps.mentorDemandSlotsAddFailure)

    getFailureStatus(mentorDemandSlotsUpdateStatus,
      mentorDemandSlotsUpdateFailure,
      prevProps.mentorDemandSlotsUpdateFailure)
  }
  handleStateChange = (name, value) => {
    this.setState({
      [name]: value
    }, () => {
      const filterOptions = ['selectedStage', 'selectedPaySlab', 'selectedVertical']
      if (filterOptions.includes(name)) {
        this.fetchMentorAvailabilitySlotsQuery()
      }
    })
  }

  onDateChange = (type) => {
    const { selectedDate } = this.state
    if (type === 'prev') {
      this.setState({
        selectedDate: new Date(moment(selectedDate).subtract(7, 'day'))
      })
    } else {
      this.setState({
        selectedDate: new Date(moment(selectedDate).add(7, 'day'))
      })
    }
  }

  convertDatatoEvents = (addingEvents = false) => {
    let { mentorAvailabilitySlots } = this.props
    const { paySlabArray } = this.state
    mentorAvailabilitySlots = mentorAvailabilitySlots && mentorAvailabilitySlots.toJS() || []
    const events = []
    let paySlabs = []
    if (paySlabArray && paySlabArray.length > 0) {
      const newPaySlab = [...paySlabArray]
      newPaySlab.shift()
      paySlabs = [...newPaySlab]
    }
    let openedSlots = 0
    mentorAvailabilitySlots.forEach((mentorAvailabilitySlot, ind) => {
      const { date, id: singleSlotId, verticals: selectedVertical,
        broadCastedMentors: demandBroadCastedMentors,
        slotName, schools, paySlab,
        countries, count, campaigns } = mentorAvailabilitySlot
      openedSlots += get(mentorAvailabilitySlot, 'mentorSessionsMeta.count', 0)
      const slotNumber = Number(get(slotName.split('slot'), '[1]'))
      const startTime = moment(date).set('hours', slotNumber)
      const endTime = moment(startTime).add(1, 'hour')
      let isEditable = false
      if (moment(startTime).isAfter(moment())) {
        isEditable = true
      }
      const slotPaySlabValue = paySlabs.find(slab => get(slab, 'id') === get(paySlab, 'id'))
      const paySlabObj = {
        ...paySlab,
        verticals,
        amountValue: get(slotPaySlabValue, slotName, {})
      }
      events.push({
        slotId: `slot${ind}`,
        title: 'Edit',
        allDay: false,
        date: new Date(startTime),
        end: new Date(endTime),
        backgroundColor: '#fff',
        extendedProps: {
          theme: {},
          clickable: true,
          isEditable,
          totalCount: count,
          demandBroadCastedMentors,
          verticals: selectedVertical || [],
          id: singleSlotId,
          paySlab: paySlabObj,
          schools,
          slotName,
          campaigns,
          count,
          countries,
          startTime: new Date(startTime),
          endTime: new Date(endTime),
          mentorDemandSlotDate: new Date(date),
          b2b2cCount: get(mentorAvailabilitySlot, 'b2b2cCount.count'),
          b2cCount: get(mentorAvailabilitySlot, 'b2cCount.count'),
          b2bCount: get(mentorAvailabilitySlot, 'b2bCount.count'),
          openedBy: get(mentorAvailabilitySlot, 'openedBy'),
          mentorSessionsMeta: get(mentorAvailabilitySlot, 'mentorSessionsMeta.count', 0)
        }
      })
    })
    let addEvents = []
    if (addingEvents) {
      addEvents = this.getAddEvents(events)
    }
    this.setState({
      fetchedEvents: [...events, ...addEvents],
      totalSlotsOpened: openedSlots,
    })
  }

  getAddEvents = (fetchedEvents = []) => {
    const { fromDate, toDate } = this.state
    const dateRangeArray = getDateRangeArray(fromDate, toDate)
    const notAddedEvents = []
    const addedTimeSlots = []
    fetchedEvents.forEach(slots => {
      if (get(slots, 'date')) {
        const slotEventNotExist = addedTimeSlots.find(foundDate =>
          moment(foundDate).isSame(moment(get(slots, 'date'))))
        if (!slotEventNotExist) addedTimeSlots.push(get(slots, 'date'))
      }
    })
    appConfig.timeSlots.forEach(slot => {
      dateRangeArray.forEach(dateValue => {
        const newDateValue = new Date(dateValue.date).setHours(slot, 0, 0, 0)
        const slotEventNotExist = addedTimeSlots.find(foundDate =>
          moment(foundDate).isSame(moment(newDateValue)))
        // check if the date is not present in any demandSlots and if it is after the current time
        if (!slotEventNotExist && moment(newDateValue).isAfter(moment())) {
          notAddedEvents.push(new Date(newDateValue))
        }
      })
    })
    const events = []
    notAddedEvents.forEach((dateSlots, ind) => {
      const startTime = dateSlots
      const endTime = moment(dateSlots).add(1, 'hour')
      events.push({
        slotId: `slot${ind}`,
        title: 'Add',
        allDay: false,
        date: new Date(startTime),
        end: new Date(endTime),
        backgroundColor: '#fff',
        extendedProps: {
          theme: {},
          clickable: true,
          startTime: new Date(startTime),
          endTime: new Date(endTime),
        },
      })
    })
    return events
  }
  onCreateAddSlot = () => {
    this.setState({
      isAddingSlots: true
    })
    const { fetchedEvents } = this.state
    const events = this.getAddEvents(fetchedEvents)
    this.setState({
      fetchedEvents: [...fetchedEvents, ...events]
    })
  }
  onCancelSelectSlots = () => {
    const { fetchedEvents } = this.state
    this.setState({
      isAddingSlots: false,
      fetchedEvents: fetchedEvents.filter(event => get(event, 'title') === 'Edit'),
      selectedSlots: [],
      isSlotEditing: false
    })
  }

  handleEventClick = (args) => {
    const { selectedSlots, isAddingSlots } = this.state
    const newSelectedSlots = [...selectedSlots]
    const addedSlots = newSelectedSlots.map(slot => get(slot, 'slotId'))
    const eventTitle = get(args, 'event.title')
    const { clickable, slotId } = get(args, 'event.extendedProps')
    if (eventTitle === 'Add') {
      const { startTime, endTime } = get(args, 'event.extendedProps')
      if (startTime && clickable) {
        if (!addedSlots.includes(slotId)) {
          const slotObj = {
            date: moment(startTime).set('hours', 0),
            startTime,
            endTime,
            slotId,
            count: 1
          }
          const slot = new Date(startTime).getHours()
          slotObj[`slot${slot}`] = true
          newSelectedSlots.push(slotObj)
          this.setState({
            selectedSlots: newSelectedSlots
          })
        }
      }
    } else if (eventTitle === 'Edit' && !isAddingSlots && clickable) {
      const { campaigns = [], count, countries,
        demandBroadCastedMentors = [], endTime,
        id: singleSlotId, paySlab, schools = [],
        slotName, startTime, verticals: selectedVerticals,
        openedBy, isEditable = false } = get(args, 'event.extendedProps')
      const slotObj = {
        date: moment(startTime).set('hours', 0),
        startTime,
        endTime,
        slotId,
        count,
        selectedVertical: selectedVerticals,
        slotName,
        singleSlotId,
        schools,
        paySlab,
        countries,
        campaigns,
        singleSlotMentors: demandBroadCastedMentors,
        openedBy,
        isEditable
      }
      newSelectedSlots.push(slotObj)
      this.setState({
        selectedSlots: newSelectedSlots,
        bulkAddVisible: true,
        isSlotEditing: true
      })
    }
  }

  onAddMinusEventClick = (slotId, type) => {
    const { selectedSlots } = this.state
    let newSelectedSlots = [...selectedSlots]
    const findInd = newSelectedSlots.findIndex(slot => get(slot, 'slotId') === slotId)
    if (findInd !== -1) {
      if (type === 'add') {
        newSelectedSlots[findInd].count += 1
      } else if (type === 'minus') {
        newSelectedSlots[findInd].count -= 1
        if (newSelectedSlots[findInd].count <= 0) {
          newSelectedSlots = newSelectedSlots.filter(slot =>
            get(slot, 'slotId') !== slotId)
        }
      }
      this.setState({
        selectedSlots: newSelectedSlots
      })
    }
  }
  render() {
    const { selectedDate, selectedVertical, selectedPaySlab,
      isAddingSlots, selectedSlots, fetchedEvents,
      paySlabArray, bulkAddVisible, isSlotEditing, supplyStatsData } = this.state
    const { mentorAvailabilitySlotsFetchStatus } = this.props
    const isfetching = mentorAvailabilitySlotsFetchStatus && get(mentorAvailabilitySlotsFetchStatus.toJS(), 'loading')
    return (
      <SupplyCalendarWrapper id='supply-calendar-dashboard-container'>
        <PageLoading isfetching={isfetching} />
        <Styles.TopBarContainer>
          <DateSelector selectedDate={selectedDate} onDateChange={this.onDateChange} />
          <Styles.FlexContainer>
            <Styles.SelectWrapper>
              <h3>Vertical</h3>
              <Styles.Select
                style={{ width: '100%' }}
                value={selectedVertical}
                verticals
                placeholder='Vertical'
                name='selectedVertical'
                onChange={(value) => this.handleStateChange('selectedVertical', value)}
              >
                {['All', ...verticals].map(verticalType => (
                  <Styles.Option className='custom-selectOption' style={{ textTransform: 'uppercase' }} value={verticalType}>
                    <Styles.Checkbox checked={verticalType === selectedVertical} />
                    <span>{verticalType}</span>
                  </Styles.Option>
                ))}
              </Styles.Select>
            </Styles.SelectWrapper>
            <Styles.SelectWrapper>
              <h3>PaySlab</h3>
              <Styles.Select
                style={{ width: '100%' }}
                value={selectedPaySlab}
                placeholder='PaySlab'
                name='paySlab'
                onChange={(value) => this.handleStateChange('selectedPaySlab', value)}
              >
                {paySlabArray.map(paySlab => (
                  <Styles.Option className='custom-selectOption' value={get(paySlab, 'id')}>
                    <Styles.Checkbox checked={get(paySlab, 'id') === selectedPaySlab} />
                    <span>{get(paySlab, 'title')}</span>
                  </Styles.Option>
                ))}
              </Styles.Select>
            </Styles.SelectWrapper>
            {/* <Styles.SelectWrapper>
              <h3>Stages</h3>
              <Styles.Select
                style={{ width: '100%' }}
                value={selectedStage}
                placeholder='Stages'
                name='selectedStage'
                onChange={(value) => this.handleStateChange('selectedStage', value)}
              >
                {stages.map(stageFilter => (
                  <Styles.Option className='custom-selectOption' value={stageFilter.title}>
                    <Styles.Checkbox checked={stageFilter.title === selectedStage} />
                    <span>{stageFilter.title}</span>
                  </Styles.Option>
            ))}
              </Styles.Select>
            </Styles.SelectWrapper> */}
          </Styles.FlexContainer>
        </Styles.TopBarContainer>
        <SuppyDashboardStats supplyStatsData={supplyStatsData} />
        {
          isAddingSlots && (
            <SupplyCalendarWrapper.SelectedSlotsBox>
              <Styles.FlexContainer>
                <p>Total slots selected:</p>
                <span className='slotsCount'>{selectedSlots.length}</span>
              </Styles.FlexContainer>
              <Styles.FlexContainer>
                <Styles.SecondaryButton
                  onClick={() => this.onCancelSelectSlots()}
                  style={{ marginRight: '10px' }}
                >
                  Cancel
                </Styles.SecondaryButton>
                <Styles.PrimaryButton disabled={selectedSlots.length === 0}
                  onClick={() => this.handleStateChange('bulkAddVisible', true)}
                >
                  Bulk Create
                </Styles.PrimaryButton>
              </Styles.FlexContainer>
            </SupplyCalendarWrapper.SelectedSlotsBox>
          )
        }
        <Calendar
          supplyCalendar
          navLinks={false}
          customViews={{
            timeGridWeek: {
              dayHeaderFormat: { weekday: 'short', day: 'numeric' },
              dayHeaderContent: ({ date }) => (
                <>
                  <div className='fullcalendar-timeGrid-header-weekday'>{date.toLocaleDateString('en', { weekday: 'short' })}</div>
                  <div className='fullcalendar-timeGrid-header-date'>{date.getDate()}</div>
                </>
              )
            },
            timeGridDay: {
              dayHeaderFormat: { weekday: 'short', day: 'numeric' },
              dayHeaderContent: ({ date }) => (
                <>
                  <div className='fullcalendar-timeGrid-header-date'>{date.toLocaleDateString('en', { weekday: 'short' })} {date.getDate()}</div>
                </>
              )
            }
          }}
          customHeaderToolBar={{
            start: '',
            right: ''
          }}
          customDateToNavigate={selectedDate}
          datesSet={(args) => {
            const { fromDate, toDate } = this.state
            if (fromDate !== args.startStr || toDate !== args.endStr) {
              this.setState({
                fromDate: args.startStr,
                toDate: args.endStr,
              }, async () => {
                await this.fetchMentorAvailabilitySlotsQuery()
                if (isAddingSlots) {
                  this.onCreateAddSlot()
                }
              })
            }
          }}
          handleEventClick={this.handleEventClick}
          fetchedEvents={fetchedEvents}
          selectedSlots={selectedSlots}
          onAddMinusEventClick={this.onAddMinusEventClick}
        />
        <BulkAddModal
          visible={bulkAddVisible}
          selectedSlots={selectedSlots}
          onClose={() => {
            this.handleStateChange('bulkAddVisible', false)
          }}
          paySlabArray={paySlabArray}
          onCancelSelectSlots={() => {
            this.onCancelSelectSlots()
            this.convertDatatoEvents()
          }}
          isSlotEditing={isSlotEditing}
        />
        {
          !isAddingSlots && (
            <SupplyCalendarWrapper.AddSupply
              onClick={() => this.onCreateAddSlot()}
            >
              <Styles.Icon theme='twoTone'
                marginRight='0px'
                component={PlusSvg}
              />
            </SupplyCalendarWrapper.AddSupply>
          )
        }
      </SupplyCalendarWrapper>
    )
  }
}

export default SupplyCalendar
