import moment from 'moment'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import React, { useEffect, memo, useState } from 'react'
import MainModal from '../../components/MainModal'

const EndActiveSessionsModal = (props) => {
    const {visible, activeSessions} = props
    const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(null)
    const [sessionId, setSessionId] = useState('')
    const [sessionStartDate, setSessionStartDate] = useState('')
    const [isRedirecting, setIsRedirecting] = useState(false)
   
     const redirect = () => {
       let redirectRoute;
       if(!isFeedbackSubmitted){
          redirectRoute = `/mentorDashboard/classFeedback/${sessionId}`
       } else{
          redirectRoute = `/mentorDashboard?endSessionId=${sessionId}&startDate=${sessionStartDate}`
       }
        
       return <Redirect to={redirectRoute} />
     }

  return (
    <MainModal
      visible={true}
      title='End Active Sessions'
      maskClosable={true}
      width='568px'
      centered
      destroyOnClose
      footer={null}
      closeable="false"
    >
        <div style={{display: 'flex', justifyContent: 'space-between', width:'70%', marginBottom: '15px'}}>
            <span style={{width: "35%"}}><MainModal.TextItemBold>Topic</MainModal.TextItemBold></span>
            <span style={{width: "35%"}}><MainModal.TextItemBold>Kid Name</MainModal.TextItemBold></span>
            <span style={{width: "25%"}}><MainModal.TextItemBold>Start Time</MainModal.TextItemBold></span>
        </div>
      {
          activeSessions.map((session) => (
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '20px', alignItems: 'center'}}>
                <span style={{width: "22%"}}><MainModal.TextItem style={{marginBottom: '0px'}}>{session.topic.title}</MainModal.TextItem></span>
                <span style={{width: "22%", wordBreak:'break-all'}}><MainModal.TextItem style={{marginBottom: '0px'}}>{session.menteeSession.user.name}</MainModal.TextItem></span>
                <span style={{width: "20%"}}><MainModal.TextItem style={{marginBottom: '0px'}}>{moment(session.sessionStartDate).format('lll')}</MainModal.TextItem></span>
                <MainModal.SaveButton
                    type='primary'
                    htmlType='submit'
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                        setIsFeedbackSubmitted(session.isFeedbackSubmitted)
                        setSessionId(session.id)
                        setSessionStartDate(session.sessionStartDate)
                        setIsRedirecting(true)
                      }
                    }
                >End Session
                </MainModal.SaveButton>  
              </div>
          ))
      }
      {sessionId !== '' && sessionId !== undefined && redirect()}
    </MainModal>
  )
}

EndActiveSessionsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
}

export default memo(EndActiveSessionsModal)
