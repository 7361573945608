import styled from 'styled-components'
import { Button, Select, Input, Table } from 'antd'
import antdButtonColor from '../../utils/mixins/antdButtonColor'
import colors from '../../constants/colors'

const MentorRatingAnalysisStyle = styled.div`
    height: 100%;
    width: 100%;
    margin: 0 auto;
`

const TopContainer = styled.div`
    display:flex;
    justify-content:space-between;
    padding-bottom:20px;
    padding-top:10px;
    align-items:flex-start;
`

const StyledButton = styled(Button)`
  &&& {
    ${antdButtonColor(colors.subThemeColor)}
  }
`

const StyledSelect = styled(Select)`
    min-width: 200px;
    //display: flex;
    align-items: left;
`
const StyledOption = styled(Select.Option)``

const StyledInput = styled(Input)`
    min-width: 200px;
    display: flex;
    align-items: left;
    border-width: 0 0 2px 0 !important;
`

const SearchIcon = styled.div`
    opacity: 0.5;
    margin-top: 8px;
`


const ClearButton = styled.button`
    display: inline;
    text-align: right;
    padding: 5px 24px;
    background-color: #f2f2f2;
    border-radius: 5px;
    border: 1px solid grey;
    float: right;
    &:hover {
      cursor: pointer
    } 
`

const statusIcon = styled.span`
width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: ${props => props.color};
  border-radius: 50%;
`
const MDTable = styled(Table)`
 & .ant-table-thead > tr > th{
   padding:0px;
 }
 & .ant-table-thead > tr > th{
   font-weight:700 !important;
 }
 & .ant-table-thead > tr > th:nth-child(4), .ant-table-thead > tr > th:nth-child(5),
 .ant-table-thead > tr > th:nth-child(6), .ant-table-thead > tr > th:nth-child(7){
 }
`
const PaginationHolder = styled.div`
  display: flex;
  justify-content: space-between;
  width:60%;
  margin-left:auto;
`

MentorRatingAnalysisStyle.TopContainer = TopContainer
MentorRatingAnalysisStyle.StyledButton = StyledButton
MentorRatingAnalysisStyle.Select = StyledSelect
MentorRatingAnalysisStyle.Option = StyledOption
MentorRatingAnalysisStyle.StyledInput = StyledInput
MentorRatingAnalysisStyle.SearchIcon = SearchIcon
MentorRatingAnalysisStyle.ClearButton = ClearButton
MentorRatingAnalysisStyle.StatusIcon = statusIcon
MentorRatingAnalysisStyle.MDTable = MDTable
MentorRatingAnalysisStyle.PaginationHolder = PaginationHolder
export default MentorRatingAnalysisStyle
