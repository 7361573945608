import gql from 'graphql-tag'
import duck from '../../duck'
  
const fetchTimeTableSchedule = async (schoolId, selectedLevel) => duck.query({
    query: gql`
    query{
        timetableSchedules(
          filter: {${selectedLevel === 'school' ? `school_some:{id:"${schoolId}"}` : `batch_some:{school_some:{id:"${schoolId}"}}`}}
        ){
          id
          type
          eventType
          sunday
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          startDate
          endDate
          slot0
          slot1
          slot2
          slot3
          slot4
          slot5
          slot6
          slot7
          slot8
          slot9
          slot10
          slot11
          slot12
          slot13
          slot14
          slot15
          slot16
          slot17
          slot18
          slot19
          slot20
          slot21
          slot19
          slot20
          slot21
          slot22
          slot23 
        }
      }
    `,
    type: 'timeTableSchedule/fetch',
    key: 'timeTableSchedules'
  })
  
  export default fetchTimeTableSchedule
  