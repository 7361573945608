/* eslint-disable */
import React,{ useState,useEffect } from 'react'
import StudentProfileLeftbar from '../StudentProfileLeftbar'
import './studentProfile.scss'
import { phoneSvg, mailSvg, profileSvg } from '../StudentProfileLeftbar/assets'
import CoursesCard from './components/CoursesCard'
import Payments from './components/payments'
import { fetchStudentData, fetchPaymentReportsData } from './fetchData'
import { Spin } from 'antd'
import ReportCards from './components/reports/components'
import { get } from 'lodash'
import getDate from './utils/getDate'
import getFullPath from '../../utils/getFullPath'
import { useParams } from 'react-router'

export default function StudentProfile() {
    const { studentId } = useParams()
    const [userData, setUserData] = useState({})
    const [loading, setLoading] = useState(false)    
    const phoneLogo = phoneSvg()
    const mailLogo = mailSvg()
    const profileLogo = profileSvg()
    const [currentTab, setCurrentTab] = useState('profile')
    const [paymentReports, setPaymentReports] = useState([])

    const profileSelectorHandler = () => {
       setCurrentTab('profile')
    }
     const reportsSelectorHandler = () => {
        setCurrentTab('Reports')
     }
     const paymentsSelectorHandler = () => {
        setCurrentTab('Payments')
     }

      useEffect(() => {
        setLoading(true)
        const fetchData = async (studentId) => {
        const res = await fetchStudentData(studentId)
        if(res) setLoading(false)
        setUserData(get(res, 'userCourses[0]'))
          }
          fetchData(studentId)
      }, [])
      
    const courseTypeHandler = (courseId) => {
    if(get(userData, 'user.studentProfile.batch')!==null) {
        if(get(userData, 'user.studentProfile.batch.course.id') === courseId) {
            return 'batch session'
        }
        else return 'mentor mentee session'
    }
    else return 'mentor mentee session'
    }

    const fetchPaymentData = async(clientId) => {
        const res = await fetchPaymentReportsData(clientId)        
        setPaymentReports(res.salesOperations)        
    }
    
    useEffect(() => {        
        if(userData && userData.user) {
            fetchPaymentData(get(userData, 'user.id'))
        }
        
    },[userData])

    return (
        <div className="studentProfileContainer">
            <div className="leftbarContainer">
            <StudentProfileLeftbar />
            </div>
            <section className="ProfileContainer">
              <div className="profileTopSection">
                <div className="profileTopSectionWrapper">
                <div className="userImageAndDetailsContainer">
                    <img src = {getFullPath(get(userData, 'user.profiePic.uri'))} alt= ""/>
                    <div className="userAccountDetailsContainer">
                    <h3 className="studentName">{get(userData, 'user.name')}</h3>
                    <p className="accountCreationDataText">Created On: {getDate(get(userData, 'user.createdAt'))}</p>
                    <span className="statusWrapper">Status<p className="statusText">Onboarding</p></span>
                    </div>
                    </div>   
                    <div className="userParentDetailsContainer">
                     <div className ="userParentDetailsWrapper">
                     <h4 style={{color:"black",fontWeight:"bold",letterSpacing:"-0.0001px",fontSize:"14px",marginBottom:"10px"}} className="parentsDetailsText">Parent Details</h4>
                     <div className="userParentDetailsflexWrapper">
                     <div className="parentNameDetails">
                    {profileLogo}
                    <span className="parentNameContainer">
                        <p>Parent's Name</p>
                        <h4>{get(userData, 'user.studentProfile.parents[0].user.name')}</h4>
                    </span>
                    </div>
                    <div className="parentPhoneDetails">
                    {phoneLogo}
                    <span className="parentPhoneContainer">
                        <p>Parent's Phone</p>
                        <h4>+91 {get(userData, 'user.studentProfile.parents[0].user.phone.number')}</h4>
                    </span>
                    </div> 
                    <div className="parentEmailDetails">
                    {mailLogo}
                    <span className="parentEmailContainer">
                        <p>Parent's Email</p>
                        <h4>{get(userData, 'user.studentProfile.parents[0].user.email')}</h4>
                    </span>
                    </div>                                                                   
                    </div>
                    </div>                             
                    </div>                 
                    </div>
              </div>
              <div className="CoursesDetailsContainer">
            <ul className="profileTabListContainer">
                <li className={`singleTabOption ${currentTab==='profile' && 'active'}`} onClick={profileSelectorHandler}>Profile</li>            
                <li className={`singleTabOption ${currentTab==='Reports' && 'active'}`} onClick={reportsSelectorHandler}>Reports</li>
                <li className={`singleTabOption ${currentTab==='Payments' && 'active'}`} onClick={paymentsSelectorHandler}>Payments</li>
            </ul>
            <hr className="seperator"/>
            </div>
            {currentTab==='profile' && <div className="coursesCardContainer">
                <h3>Enrolled Courses</h3>
                <div className="cardsContainer">
                    {loading && <Spin className="preloaderPostSales"/>}
                    {(!loading && userData && get(userData, 'courses')) && get(userData, 'courses').map((course) => (
                  <CoursesCard studentCourseTitle={get(course, 'title')} coursesCount={get(course, 'topicsMeta.count')} courseSessions={get(course, 'topics')} studentId={get(userData, 'user.id')} courseId={get(course, 'id')} courseDetails={course} courseStart={get(course, 'createdAt')} courseThumbnail={get(course, 'thumbnail.uri')} courseType={courseTypeHandler(course.id)} batchSessions={get(userData, 'user.studentProfile.batch')} setCurrentTab={setCurrentTab} />
                    ))}
                </div>
            </div>}
            {currentTab === "Reports" && userData && get(userData, 'courses') &&
            get(userData, 'courses').map(course => (
            <ReportCards courseTitle={get(course,'title')} studentId={get(userData, 'user.id')} batchSessions={get(userData, 'user.studentProfile.batch')} studentName={get(userData, 'user.name')} courseDetailId={get(course, 'id')} coursesCount={get(course, 'topicsMeta.count')} courseType={courseTypeHandler(course.id)} courseThumbnail={get(course, 'thumbnail.uri')} courseTopics={get(course, 'topics')} paymentReports={paymentReports} courseCreationDate={get(course,'createdAt')}/>
            ))}            
            {currentTab === "Payments" && <Payments studentId={get(userData, 'user.id')} paymentReports={paymentReports}/>}
            </section>
        </div>
    )
}
