import gql from 'graphql-tag'
import duck from '../../duck';


const fetchSchools = async (searchValue) => {
  await duck.query({
    query: gql`
       query {
            schools(filter: {and:[{ name_contains: "${searchValue}"}] }) {
              id
              name
            }
          }
          `,
    type: 'schools/fetch',
    key: 'schools',
  })
}

export default fetchSchools