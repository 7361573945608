import React, { useEffect, useState } from "react";
import { StyledError } from "../SpeakerPrizes/SpeakerPrizes.style";
import { Icon, Input } from "antd";
import './certificateTemplate.css'
import { get } from "lodash";

const UtmCodeForm = ({ item, index, initialLink, utmCodes, setUtmCodes, formError, removeUtmForm }) => {
    const [localLink, setLocalLink] = useState(initialLink);

    const myChangeHandler_source = (event, idx) => {
        let value = event.target.value;
        const updatedUtmCodes = utmCodes.map((code, index) => {
            if (index === idx) {
                return { ...code, utmSource: value };
            }
            return code;
        });
        initialLink += value;
        setUtmCodes(updatedUtmCodes);
    };

    const myChangeHandler_campaign = (event, idx) => {
        const updatedUtmCodes = utmCodes.map((code, index) => {
            if (index === idx) {
                return { ...code, utmCampaign: event.target.value };
            }
            return code;
        });
        setUtmCodes(updatedUtmCodes);
    };

    const myChangeHandler_term = (event, idx) => {
        const updatedUtmCodes = utmCodes.map((code, index) => {
            if (index === idx) {
                return { ...code, utmTerm: event.target.value };
            }
            return code;
        });
        setUtmCodes(updatedUtmCodes);
    };

    const myChangeHandler_medium = (event, idx) => {
        const updatedUtmCodes = utmCodes.map((code, index) => {
            if (index === idx) {
                return { ...code, utmMedium: event.target.value };
            }
            return code;
        });
        setUtmCodes(updatedUtmCodes);
    };
    const myChangeHandler_content = (event, idx) => {
        const updatedUtmCodes = utmCodes.map((code, index) => {
            if (index === idx) {
                return { ...code, utmContent: event.target.value };
            }
            return code;
        });
        setUtmCodes(updatedUtmCodes);
    };
    const updateLocalLink = (item, link) => {
        if (get(item, 'utmSource') && get(item, 'utmSource', '').trimStart()) {
            link += "utm_source=" + item.utmSource.replace(/ /g, '');
        }
        if (get(item, 'utmCampaign') && get(item, 'utmCampaign', '').trimStart()) {
            if (item.utmSource) {
                link += "&utm_campaign=" + item.utmCampaign.replace(/ /g, '');
            } else {
                link += "utm_campaign=" + item.utmCampaign.replace(/ /g, '');
            }
        }
        if (get(item, 'utmTerm') && get(item, 'utmTerm', '').trimStart()) {
            if (item.utmCampaign || item.utmSource) {
                link += "&utm_term=" + item.utmTerm.replace(/ /g, '');
            } else {
                link += "utm_term=" + item.utmTerm.replace(/ /g, '');
            }
        }
        if (get(item, 'utmMedium') && get(item, 'utmMedium', '').trimStart()) {
            if (item.utmTerm || item.utmCampaign || item.utmSource) {
                link += "&utm_medium=" + item.utmMedium.replace(/ /g, '');
            } else {
                link += "utm_medium=" + item.utmMedium.replace(/ /g, '');
            }
        }
        if (get(item, 'utmContent') && get(item, 'utmContent', '').trimStart()) {
            if (item.utmTerm || item.utmCampaign || item.utmSource || item.utmMedium) {
                link += "&utm_content=" + item.utmContent.replace(/ /g, '');
            } else {
                link += "utm_content=" + item.utmContent.replace(/ /g, '');
            }
        }

        setLocalLink(link);
    };
    const updateLinkInFormObject = () => {
        const updatedUtmCodes = utmCodes.map((code, idx) => {
            if (idx === index) {
                return { ...code, webUrl: localLink }
            } return code
        })
        setUtmCodes(updatedUtmCodes)
    }

    useEffect(() => {
        let link = initialLink;
        updateLocalLink(item, link);
    }, [item, index]);

    useEffect(() => {
        updateLinkInFormObject()
    }, [localLink])

    return (
        <>
            <div key={index} className="content">
                <div>
                    <div className="v-row">
                        <div className="inp-parent">
                            UTM Source {index + 1}
                            <Input
                                style={{ border: 'none' }}
                                placeholder="Internal"
                                value={item.utmSource}
                                onChange={(e) => myChangeHandler_source(e, index)}
                                name={"utm_source"}
                                defaultValue={item.utmSource}
                            />
                            {/* {(formError && !item.utmSource.trimStart()) && <StyledError>Required*</StyledError>} */}
                        </div>
                        <div className="inp-parent">
                            UTM Campaign {index + 1}
                            <Input
                                style={{ border: 'none' }}
                                placeholder="Radiostreet"
                                value={item.utmCampaign}
                                onChange={(e) => myChangeHandler_campaign(e, index)}
                                name={"utm_campaign"}
                                defaultValue={item.utmCampaign}
                            />
                            {/* {(formError && !item.utmCampaign.trimStart()) && <StyledError>Required*</StyledError>} */}

                        </div>
                    </div>
                    <div className="v-row">
                        <div className="inp-parent">
                            UTM Term {index + 1}
                            <Input
                                style={{ border: 'none' }}
                                placeholder="term1"
                                value={item.utmTerm}
                                onChange={(e) => myChangeHandler_term(e, index)}
                                name={"utm_term"}
                                defaultValue={item.utmTerm}
                            />
                            {/* {(formError && !item.utmTerm.trimStart()) && <StyledError>Required*</StyledError>} */}

                        </div>
                        <div className="inp-parent">
                            UTM Medium {index + 1}
                            <Input
                                style={{ border: 'none' }}
                                placeholder="medium1"
                                value={item.utmMedium}
                                onChange={(e) => myChangeHandler_medium(e, index)}
                                name={"utm_medium"}
                                defaultValue={item.utmMedium}
                            />
                            {/* {(formError && !item.utmMedium.trimStart()) && <StyledError>Required*</StyledError>} */}
                        </div>
                    </div>
                    <div className="v-row">

                        <div className="inp-parent">
                            UTM Content {index + 1}
                            <Input
                                style={{ border: 'none' }}
                                placeholder="content1"
                                value={item.utmContent}
                                onChange={(e) => myChangeHandler_content(e, index)}
                                name={"utm_content"}
                                defaultValue={item.utmContent}
                            />
                            {/* {(formError && !item.utmContent.trimStart()) && <StyledError>Required*</StyledError>} */}
                        </div>
                    </div>
                </div>
                {index > 0 && <Icon onClick={() => removeUtmForm(index)} type='close' className={'removeForm'} />}
            </div>
            <div className="v-row">
                <div className="inp-parent" style={{ width: "630px", wordBreak: "break-all" }}>
                    Event Page Link
                    <div>
                        <a className='utc-inp'>
                            {localLink}
                        </a>
                    </div>
                </div>
            </div>

        </>
    );
};
export default UtmCodeForm;
