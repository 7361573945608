import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../../duck'

const getQuery = ({ sessionId, sessionType }) => {
  let query = ''
  if (sessionType === 'menteeSession') {
    query = gql`{
       sessionDetailForDemand: menteeSession(id: "${sessionId}") {
        id
        user {
        id
        name
        createdAt
        studentProfile {
            id
            grade
            school {
            id
            name
            }
            parents {
            id
            user {
                id
                name
                email
                phone {
                number
                countryCode
                }
            }
            }
        }
        }
        bookingDate
        broadCastedMentors {
          id
          pythonCourseRating5
          pythonCourseRating4
          pythonCourseRating3
          pythonCourseRating2
          pythonCourseRating1
          codingLanguages {
            value
          }
          user {
            id
            name
            gender
            createdAt
            profilePic {
              id
              uri
            }
          }
        }
      }
    }`
  } else if (sessionType === 'batchSession') {
    query = gql`{
      sessionDetailForDemand: batchSession(id: "${sessionId}") {
        id
        bookingDate
        sessionStatus
        sessionEndDate
        sessionStartDate
        broadCastedMentors {
          id
          pythonCourseRating5
          pythonCourseRating4
          pythonCourseRating3
          pythonCourseRating2
          pythonCourseRating1
          codingLanguages {
            value
          }
          user {
            id
            name
            gender
            createdAt
            profilePic {
              id
              uri
            }
          }
        }
        batch {
          id
          code
          type
          school {
            id
            name
          }
          allottedMentor {
            id
            name
            mentorProfile {
              id
            }
          }
          students {
            id
            grade
            user{
              id
              name
              profilePic{
                id
                uri
              }
              createdAt
            }
            parents {
              id
              user {
                id
                name
                email
                phone {
                  number
                  countryCode
                }
              }
            }
          }
        }
      }
    }`
  }
  return query
}

const fetchSingleSessionDetails = async ({ sessionId, sessionType }) =>
  duck.query({
    query: getQuery({ sessionId, sessionType }),
    type: 'sessionDetailForDemand/fetch',
    key: `sessionDetailForDemand/${sessionType}/${sessionId}`,
    changeExtractedData: (extractedData, originalData) => {
      extractedData.user = []
      extractedData.batch = []
      extractedData.menteeSession = []
      extractedData.schools = []
      extractedData.batchSessions = []
      get(originalData, 'sessionDetailForDemand.broadCastedMentors', []).forEach(mentor => {
        mentor.mentorProfile = {
          ...mentor
        }
        mentor.id = get(mentor, 'user.id')
        mentor.name = get(mentor, 'user.name')
        mentor.gender = get(mentor, 'user.gender')
        mentor.createdAt = get(mentor, 'user.createdAt')
        mentor.profilePic = get(mentor, 'user.profilePic')
      })
      extractedData.sessionDetailForDemand = [get(originalData, 'sessionDetailForDemand')]
      return { ...extractedData }
    },
  })

export default fetchSingleSessionDetails

