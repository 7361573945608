import React, { useEffect, useRef, useState } from 'react'
import { Modal, Select, Input, Button } from 'antd'
import { addEventCategory, addEventTag, addEventVariable } from '../../../../actions/eventDashboard/eventSettings';
import { Form, Formik } from 'formik';
import * as yup from 'yup'
import { get } from 'lodash';

const variableOptions = [
  { value: 'studentName', label: 'Student Name' },
  { value: 'parentName', label: 'Parent Name' },
  { value: 'studentGrade', label: 'Student Grade' },
  { value: 'eventDate', label: 'Event Date' },
  { value: 'eventName', label: 'Event Name' },
  { value: 'speakerName', label: 'Speaker Name' },
  { value: 'parentEmail', label: 'Parent Email' },
  { value: 'parentPhone', label: 'Parent Phone' },
  { value: 'eventTime', label: 'Event Time' },
  { value: 'eventCertificateLink', label: 'Event Certificate Link' },
  { value: 'meetingId', label: 'Meeting Id' },
  { value: 'meetingLink', label: 'Meeting Link' },
  { value: 'meetingPassword', label: 'Meeting Password' },
  { value: 'geoLocation', label: 'Geo Location' },
  { value: 'address', label: 'Address' },
  { value: 'summary', label: 'Summary' },
  { value: 'description', label: 'Description' },
  { value: 'paymentLink', label: 'Payment Link' },
  { value: 'eventRegistrationLink', label: 'Event Registration Link' },
  { value: 'sessionLink', label: 'Session Link' },
]

const ViewModal = (props) => {

  const [isSubmitting, setSubmitting] = useState(false)
  const setDataInForm = () => {
    const tagData = {
      title: '',
      status: 'active'
    }
    const variableData = {
      whatsappVariableName: '',
      emailVariableName:'',
      dataField: ''
    }
    if (props.tabType === 'Tag' || props.tabType === 'Category') {
      return tagData
    }
    if (props.tabType === 'Variable') {
      return variableData
    }
    return {}
  }

  const handleSubmit = async (value) => {
    setSubmitting(true)
    if (props.tabType === 'Tag') {
      const input = {
        title: value.title,
        displayOnWebsite: false,
        isEventTag: true,
        tagStatus: value.status
      }
      await addEventTag(input)
    }
    if (props.tabType === 'Category') {
      const input = {
        title: value.title,
        displayOnWebsite: false,
        status: value.status
      }
      await addEventCategory(input)
    }
    if (props.tabType === 'Variable') {
      const input = {
        whatsappVariableName: value.whatsappVariableName,
        emailVariableName: value.emailVariableName,
        dataField: value.dataField,
      }
      await addEventVariable(input)
    }
    setSubmitting(false)
  }

  const handleCancel = () => {
    setSubmitting(false)
    props.closeModal()
  }
  const formRef = useRef()
  useEffect(() => {
    if (get(props, 'visible')) {
      setSubmitting(false)
      if (formRef && formRef.current) {
        formRef.current.resetForm(setDataInForm())
      }
    }
  }, [get(props, 'visible')])

  const validationSchema = yup.object().shape({
    title: yup.string().required('Input required').min(3, 'input (Min. 3 characters)').max(30, 'input (Max. 30 characters)').trim(),
  })

  const variableValidationSchema = yup.object().shape({
    whatsappVariableName: yup.string().required('Input required').matches(/^[a-z0-9_]+$/i, 'No spaces and special characters allowed').min(3, 'Input (Min. 3 characters)').max(30, 'Input (Max. 30 characters)'),
    emailVariableName: yup.string().required('Input required').matches(/^[a-z0-9]+$/i, 'No spaces and special characters allowed').min(3, 'Input (Min. 3 characters)').max(30, 'Input (Max. 30 characters)'),
    dataField: yup.string().required('Data Field Required')
  })

  return (
    <>
      <Modal title={[<div style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 550 }}>{`Add new ${props.tabType}`}</div>]}
        visible={props.visible}
        footer={null}
        closable={false}
        onCancel={handleCancel}
        maskClosable='true'
      >
        <Formik
          initialValues={setDataInForm()}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validationSchema={props.tabType === 'Variable' ? variableValidationSchema : validationSchema}
          innerRef={formRef}
        >
          {({ values, handleChange, errors, touched }) => (
            <Form>
               { (props.tabType === 'Variable') ? (
              <div style={{ display: 'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'flex-start' }}>
                <label style={{ margin: '1rem 0rem 0.25rem 0rem', fontSize: '16px', color: '#818081', fontWeight: 600 }}>{`${props.tabType} Name (WATI)`}</label>
                <Input 
                  value={values.whatsappVariableName}
                  placeholder='Input the exact variable used in WATI (Case Sensitive)'
                  name='whatsappVariableName'
                  style={{ width:'380px' }}
                  onChange={handleChange}
                />
                {errors.whatsappVariableName ? (<div style={{ color: 'red', fontSize: '0.75rem' }}>{errors.whatsappVariableName}</div>) : null}
                <label style={{ margin: '1rem 0rem 0.25rem 0rem', fontSize: '16px', color: '#818081', fontWeight: 600 }}>{`${props.tabType} Name (Email)`}</label>
                <Input 
                  value={values.emailVariableName}
                  placeholder='Input the exact variable used in WATI (Case Sensitive)'
                  name='emailVariableName'
                  style={{ width:'380px' }}
                  onChange={handleChange}
                />
                {errors.emailVariableName ? (<div style={{ color: 'red', fontSize: '0.75rem' }}>{errors.emailVariableName}</div>) : null}
                <label style={{ margin: '1rem 0rem 0.25rem 0rem', fontSize: '16px', color: '#818081', fontWeight: 600 }}>Data Field in System</label>
                <Select 
                  style={{ width: '220px' }}
                  name='dataField'
                  value={values.dataField}
                  placeholder='---'
                  onChange={(value, _option) => handleChange('dataField')(value)}
                >
                  {
                    variableOptions.map((elem) => {
                      return (
                      <Select.Option key={elem.label} value={elem.value}>
                        {elem.label}
                      </Select.Option>
                      )
                    })
                  }
                </Select>
                {errors.dataField ? (<div style={{ color: 'red', fontSize: '0.75rem' }}>{errors.dataField}</div>) : null}
              </div>
              ) : (
              <div style={{ display: 'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'flex-start' }}>
                <label style={{ margin: '1rem 0rem 0.25rem 0rem', fontSize: '16px', color: '#818081', fontWeight: 600 }}>{`${props.tabType} Name`}</label>
                <Input 
                  value={values.title}
                  name='title'
                  style={{ width:'220px' }}
                  onChange={handleChange}
                />
                {errors.title ? (<div style={{ color: 'red', fontSize: '0.75rem' }}>{errors.title}</div>) : null}
                <label style={{ margin: '1rem 0rem 0.25rem 0rem', fontSize: '16px', color: '#818081', fontWeight: 600 }}>Status</label>
                <Select
                  name='status'
                  style={{ width: '130px' }}
                  value={values.status}
                  onSelect={(value, _option) => handleChange('status')(value)}
                >
                  <Select.Option key='active' value='active'><span style={{ color: 'green', fontWeight: 'bold' }}>Active</span></Select.Option>
                  <Select.Option key='archive' value='archive'><span style={{ fontWeight: 'bold' }}>Archive</span></Select.Option>
                </Select>
              </div>
            ) }
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2.5rem' }}>
              <Button
                  id='cancel-btn'
                  onClick={() => handleCancel()}
                  style={{ backgroundColor:'gray', color:'white', marginRight:'1.5rem', height: '40px', padding: '0 40px' }}
                >
                  Cancel
                </Button>
                <Button
                  type='primary'
                  id='add-btn'
                  htmlType='submit'
                  style={{ marginLeft:'1.5rem', height: '40px', padding: '0 40px' }}
                  loading={isSubmitting}
                >
                  {`Create ${props.tabType}`}
                </Button>
              </div>
          </Form>
          )} 
        </Formik>
      </Modal>
    </>
  );
}

export default ViewModal