import { DeleteFilled } from '@ant-design/icons'
import { Input, notification, Pagination, Popconfirm, Tooltip } from 'antd'
import { get, sortBy } from 'lodash'
import React from 'react'
import addMentorsAsTrainer from '../../../actions/manageTrainers/addMentorsAsTrainer'
import fetchInternalMentors from '../../../actions/manageTrainers/fetchInternalMentors'
import fetchTeacherBatches from '../../../actions/manageTrainers/fetchTeacherBatches'
import fetchTrainers from '../../../actions/manageTrainers/fetchTrainers'
import updateTrainer from '../../../actions/manageTrainers/updateTrainer'
import MainTable from '../../../components/MainTable'
import { MENTOR, SCHOOL_TRAINER } from '../../../constants/roles'
import ManageTrainerModal from './components/ManageTrainerModal'
import ViewAssignedBatchesModal from './components/ViewAssignedBatchesModal'
import ManageTrainerStyle from './ManageTrainers.style'

class ManageTrainers extends React.PureComponent{
    constructor(props) {
        super(props)
        this.state = {
            trainers: [],
            tableData: [],
            columns: [],
            perPage: 20,
            skip: 0,
            currentPage: 1,
            searchByNameValue: '',
            openModal: false,
            operation: 'add',
            editData: {},
            removingTrainer: false,
            addingTrainerType: 'newTrainer',
            existingMentors: [],
            selectedMentors: [],
            isAddingMentorAsTrainer: false,
            openAssignedBatchesModal: false,
            teacherBatchMap: {}
        }
    }

    fetchSchoolTrainers = async () => {
        const { perPage, skip, searchByNameValue } = this.state
        let filterString = '';
        if (searchByNameValue && searchByNameValue.trim()) {
            filterString += `{ name_contains: "${searchByNameValue.trim()}" }`
        }
        await fetchTrainers({ first: perPage, skip, filterString })
    }
    fetchInternalMentorsData = async () => {
        await fetchInternalMentors().then((res) => {
            if (res && Array.isArray(res)) this.setState({ existingMentors: res })
        })
    }
    componentDidMount = async () => {
        await this.fetchSchoolTrainers()
        this.fetchInternalMentorsData()
    }
    onSelectionChange = (event) => {
        this.setState({ addingTrainerType: event.target.value })
    }
    renderAction = (id, data) => (
        <div
        style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            padding: '8px 0'
        }}
        >
        <MainTable.ActionItem.IconWrapper>
            <Tooltip title='View Assigned Batches'>
                <MainTable.ActionItem.EyeIcon onClick={() => this.openBatchesAssignedModal(id)} />
            </Tooltip>
        </MainTable.ActionItem.IconWrapper>
        <MainTable.ActionItem.IconWrapper>
            <MainTable.ActionItem.EditIcon onClick={() => this.openEditModal(data)} />
        </MainTable.ActionItem.IconWrapper>
        <div>
            <Popconfirm
            title='Do you want to remove this trainer?'
            placement='topRight'
            onConfirm={async () => {
                this.setState({ removingTrainer: true })
                await updateTrainer(id, {
                    roles: {
                        updateWith: MENTOR,
                        updateWhere: {
                            this: SCHOOL_TRAINER
                        }
                    }
                })
            }}
            okText='Yes'
            cancelText='Cancel'
            key='delete'
            overlayClassName='popconfirm-overlay-primary'
            >
            <MainTable.ActionItem.IconWrapper>
                <DeleteFilled />
            </MainTable.ActionItem.IconWrapper>
            </Popconfirm>
        </div>
        </div>
    )
    openEditModal = (data) => {
        const editData = { ...data, phoneNumber: get(data, 'phone.number') || '' }
        this.setState({
            openModal: true,
            operation: 'edit',
            editData
        })
    }
    setTrainers = () => {
        let { trainers } = this.props;
        trainers = trainers && trainers.toJS()
        if (trainers && Array.isArray(trainers)) {
            trainers = sortBy(trainers, 'createdAt')
            let trainersData = trainers.filter((trainer) => get(trainer, 'roles', []).includes(SCHOOL_TRAINER))
            trainersData = trainersData.map((trainer, ind) => (
                {
                    srNo: ind + 1,
                    ...trainer
                }
            ))
            const columns = [
                {
                    title: 'Sr. No',
                    dataIndex: 'srNo',
                    key: 'srNo',
                    align: 'center',
                    width: 100
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    align: 'center',
                },
                {
                    title: "User name",
                    dataIndex:'username',
                    key: 'username',
                    align: 'center'
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                    align: 'center',
                },
                {
                    title: 'Phone number',
                    dataIndex: 'phone',
                    key: 'phone',
                    align: 'center',
                    render: (data) => {
                        if (get(data, 'number')) return `${get(data, 'countryCode')} ${get(data, 'number')}`
                        return '-'
                    }
                },
                {
                    title: 'Actions',
                    dataIndex: 'id',
                    key: 'actions',
                    align: 'center',
                    width: 120,
                    render: (id, data) => this.renderAction(id, data)
                }
            ]
            this.setState({ tableData: trainersData, columns })
        }
    }
    componentDidUpdate = async (prevProps, prevState) => {
        const { manageTrainersFetchStatus,
            manageTrainersAddStatus,
            manageTrainersUpdateStatus,
            addTrainerError,
            updateTrainerError, teacherBatchesFetchStatus, teacherBatches } = this.props
        const { removingTrainer } = this.state
        if (((manageTrainersFetchStatus && !get(manageTrainersFetchStatus.toJS(), 'loading')
      && get(manageTrainersFetchStatus.toJS(), 'success') &&
            (prevProps.manageTrainersFetchStatus !== manageTrainersFetchStatus))) || 
            ((manageTrainersAddStatus && !get(manageTrainersAddStatus.toJS(), 'loading')
      && get(manageTrainersAddStatus.toJS(), 'success') &&
                (prevProps.manageTrainersAddStatus !== manageTrainersAddStatus))) || 
            ((manageTrainersUpdateStatus && !get(manageTrainersUpdateStatus.toJS(), 'loading')
      && get(manageTrainersUpdateStatus.toJS(), 'success') &&
            (prevProps.manageTrainersUpdateStatus !== manageTrainersUpdateStatus)))) {
            this.setTrainers()
      }
      if ((manageTrainersAddStatus && !get(manageTrainersAddStatus.toJS(), 'loading')
        && get(manageTrainersAddStatus.toJS(), 'success') &&
        (prevProps.manageTrainersAddStatus !== manageTrainersAddStatus))) {
        notification.success({ message: 'Trainer Added Successfully' })
        this.onCloseModal()
      } else if (manageTrainersAddStatus && !get(manageTrainersAddStatus.toJS(), 'loading')
            && get(manageTrainersAddStatus.toJS(), 'failure') &&
            (prevProps.addTrainerError !== addTrainerError)) {
            if (addTrainerError && addTrainerError.toJS().length > 0) {
                const currentError = addTrainerError.toJS().pop()
                const errorMessage = (get(get(currentError, 'error'), 'errors[0].message', '') || '').replace('User', 'Trainer')
                notification.error({
                    message: errorMessage
                })
            }
      }
      if ((manageTrainersUpdateStatus && !get(manageTrainersUpdateStatus.toJS(), 'loading')
        && get(manageTrainersUpdateStatus.toJS(), 'success') &&
        (prevProps.manageTrainersUpdateStatus !== manageTrainersUpdateStatus))) {
        if (!removingTrainer) {
            notification.success({ message: 'Trainer Updated Successfully' })
            this.onCloseModal()
        } else {
            notification.success({ message: 'Trainer removed Successfully' })
            this.fetchInternalMentorsData()
            this.setState({ removingTrainer: false })
        }
      } else if (manageTrainersUpdateStatus && !get(manageTrainersUpdateStatus.toJS(), 'loading')
            && get(manageTrainersUpdateStatus.toJS(), 'failure') &&
            (prevProps.updateTrainerError !== updateTrainerError)) {
          if (updateTrainerError && updateTrainerError.toJS().length > 0) {
              const currentError = updateTrainerError.toJS().pop()
                const errorMessage = (get(get(currentError, 'error'), 'errors[0].message', '') || '').replace('User', 'Trainer')
                notification.error({
                    message: errorMessage
                })
            }
      }
      if (teacherBatchesFetchStatus && !get(teacherBatchesFetchStatus.toJS(), 'loading')
        && get(teacherBatchesFetchStatus.toJS(), 'success') && prevProps.teacherBatchesFetchStatus !== teacherBatchesFetchStatus) {
            const teacherBatchesData = teacherBatches && teacherBatches.toJS()
            const teacherBatchMap = {}
            if (teacherBatchesData && teacherBatchesData.length) {
                teacherBatchesData.forEach(item => {
                    const checkIfExist = teacherBatchMap[get(item, 'school.name')] || []
                    if (checkIfExist.length) {
                        const obj = {
                            srNo: teacherBatchMap[get(item, 'school.name')].length + 1,
                            title: get(item, 'classroomTitle'),
                            code: get(item, 'code'),
                            students: get(item, 'studentsMeta.count'),
                        }
                        const arr = [...teacherBatchMap[get(item, 'school.name')], {...obj}]
                        teacherBatchMap[get(item, 'school.name')] = arr
                    } else {
                        const obj = {
                            srNo: 1,
                            title: get(item, 'classroomTitle'),
                            code: get(item, 'code'),
                            students: get(item, 'studentsMeta.count'),
                        }
                        teacherBatchMap[get(item, 'school.name')] = [obj]
                    }
                })
            }
            this.setState({ teacherBatchMap: teacherBatchMap })
        }
    }
    openAddModal = () => {
        this.setState({
        openModal: true,
        operation: 'add'
        })
    }
    onCloseModal = () => {
        const { openModal } = this.state
        if (openModal) {
            this.setState({
                openModal: false,
                operation: '',
                editData: {},
                addingTrainerType: 'newTrainer',
                selectedMentors: [],
                isAddingMentorAsTrainer: false,
            })
        }
    }
    onMentorSelect = (value) => {
        const { selectedMentors } = this.state
        this.setState({
            selectedMentors: [...selectedMentors, value]
        })
    }
    onMentorDeselect = (value) => {
        const { selectedMentors } = this.state
        this.setState({
            selectedMentors: [...selectedMentors].filter(mentor => get(mentor, 'key') !== get(value, 'key'))
        })
    }
    onAddMentor = async () => {
        const { selectedMentors } = this.state
        let updateMentorStr = '';
        selectedMentors.forEach((mentor) => {
            updateMentorStr += `{ id: "${get(mentor, 'key')}", fields: { roles: { push: ${SCHOOL_TRAINER} } } }`
        })
        if (updateMentorStr) {
            this.setState({ isAddingMentorAsTrainer: true })
            await addMentorsAsTrainer(updateMentorStr)
            this.onCloseModal()
            notification.success({ message: `Trainer${selectedMentors.length > 1 ? 's' : ''} Added Successfully` })
            this.fetchSchoolTrainers()
            this.fetchInternalMentorsData()
        }
    }
    onPageChange = (page) => {
        this.setState(
        {
            currentPage: page,
            skip: page - 1,
        },
        () => this.fetchSchoolTrainers()
        )
    }
    fetchTeacherBatches = (id) => {
        fetchTeacherBatches({ mentorId: id })
    }
    closeAssignedBatchesModal = () => {
        const { openAssignedBatchesModal } = this.state
        if (openAssignedBatchesModal) {
            this.setState({
                openAssignedBatchesModal: false
            })
        }
    }
    openBatchesAssignedModal = (id) => {
        this.setState({ openAssignedBatchesModal: true }, this.fetchTeacherBatches(id))
    }
    render() {
        const { manageTrainersFetchStatus, trainersMeta, teacherBatchesFetchStatus } = this.props
        const {
            searchByNameValue, openModal, operation,
            editData, tableData, columns, addingTrainerType,
            existingMentors, selectedMentors, isAddingMentorAsTrainer,
            currentPage, perPage, openAssignedBatchesModal, teacherBatchMap
        } = this.state;
        return (
            <>
                <ManageTrainerStyle.TopContainer>
                    <Input
                        style={{ width: 200 }}
                        placeHolder='Search by name'
                        value={searchByNameValue}
                        onChange={(e) => this.setState({ searchByNameValue: e.target.value })}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') { this.fetchSchoolTrainers() }
                        }}
                    />
                    <ManageTrainerStyle.StyledButton
                        type='primary' icon='plus' style={{ marginRight: '10px' }}
                        onClick={this.openAddModal}
                    >Add Trainer</ManageTrainerStyle.StyledButton>
                </ManageTrainerStyle.TopContainer>
                {
                    trainersMeta > perPage && (
                        <div style={{ display: 'flex', justifyContent: 'center', margin: '15px auto'  }}>
                            <Pagination
                                total={trainersMeta || 0}
                                onChange={this.onPageChange}
                                current={currentPage}
                                defaultPageSize={perPage}
                            />
                        </div>
                    )
                }
                <ManageTrainerModal
                    openModal={openModal}
                    operation={operation}
                    editData={editData}
                    closeModal={this.onCloseModal}
                    onSelectionChange={this.onSelectionChange}
                    addingTrainerType={addingTrainerType}
                    existingMentors={existingMentors}
                    selectedMentors={selectedMentors}
                    onMentorSelect={this.onMentorSelect}
                    onMentorDeselect={this.onMentorDeselect}
                    onAddMentor={this.onAddMentor}
                    isAddingMentorAsTrainer={isAddingMentorAsTrainer}
                    {...this.props}
                />
                <ManageTrainerStyle.MDTable
                    dataSource={tableData}
                    columns={columns}
                    rowClassName={() => 'antdTable-row'}
                    loading={
                        manageTrainersFetchStatus && get(manageTrainersFetchStatus.toJS(), 'loading')
                    }
                    scroll={{ x: 'max-content' }}
                    pagination={false}
                />
                <ViewAssignedBatchesModal
                    openAssignedBatchesModal={openAssignedBatchesModal}
                    closeAssignedBatchesModal={this.closeAssignedBatchesModal}
                    isLoading={teacherBatchesFetchStatus && get(teacherBatchesFetchStatus.toJS(), 'loading')}
                    teacherBatchMap={teacherBatchMap}
                />
            </>
        )
    }
}

export default ManageTrainers;
