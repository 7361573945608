import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import withUpdatedDashboardNav from '../../../components/withUpdatedNav'
import { EVENT_DASHBOARD } from '../../../constants/navItemsConst'
import injectProps from '../../../components/injectProps'
import EventOverview from './EventOverview'
import { filterKey } from '../../../utils/data-utils'

const EventOverviewSideNav = withUpdatedDashboardNav(EventOverview)({
  title: 'Events',
  activeNavItem: 'Events',
  showUMSNavigation: true,
  renderNavItems: EVENT_DASHBOARD,
})

const mapStateToProps = state => ({
  eventsData: filterKey(state.data.getIn([
    'events',
    'data'
  ]), 'eventsData'),
  eventsLoading: state.data.getIn([
    'events',
    'fetchStatus',
    'eventsData',
  ]),
  eventsMeta: state.data.getIn([
    'eventsMeta',
    'data',
    'count'
  ])
})


const EventOverviewSideNavWithExtraProps = injectProps({
  notification
})(EventOverviewSideNav)
export default connect(mapStateToProps)(withRouter(EventOverviewSideNavWithExtraProps))
