import React from 'react'
import styled from 'styled-components'

// stack the children vertically, accepts gap, wrap, align, justify and direction props
const Stack = styled.div`
    display: flex;
    flex-direction: ${props => props.direction || 'column'};
    align-items: ${props => props.align || 'stretch'};
    justify-content: ${props => props.justify || 'flex-start'};
    flex-wrap: ${props => props.wrap || 'nowrap'};
    gap: ${props => `${props.gap}px` || '0'};
`


export default Stack