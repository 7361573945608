import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import { filterKey } from 'duck-state/lib/State'
import ClassFeedback from './ClassFeedback'
import withMentorDashboardNav from '../../../components/withUpdatedNav'
import injectProps from '../../../components/injectProps'
import { MENTOR_DASHBOARD } from '../../../constants/navItemsConst'

const ClassFeedbackSideNav = withMentorDashboardNav(ClassFeedback)({
  title: 'Class Feedback',
  activeNavItem: 'Feedback',
  renderNavItems: MENTOR_DASHBOARD,
})

const mapStateToProps = state => ({
  mentorMenteeSession: state.data.getIn([
    'mentorMenteeSession',
    'data'
  ]),
  mentorMenteeSessions: state.data.getIn([
    'mentorMenteeSessions',
    'data'
  ]),
  batchSession: state.data.getIn([
    'batchSession',
    'data'
  ]),
  updateSessionStatus: state.data.getIn([
    'mentorSessions',
    'updateStatus',
    'updateMentorMenteeSession',
  ]),
  prevMentorSession: filterKey(
    state.data.getIn([
      'mentorSessions',
      'data'
    ]), 'prevMentorSession'
  ),
  salesOperation: filterKey(
    state.data.getIn([
      'salesOperation',
      'data'
    ]), 'salesOperations'
  ),
  isPrevMentorSessionsLoading: state.data.getIn([
    'mentorSessions',
    'fetchStatus',
    'prevMentorSession',
    'loading'
  ]),
  updateMenteeStatus: state.data.getIn([
    'mentorSessions',
    'updateStatus',
    'updateMenteeSession',
  ]),
  updateBatchStatus: state.data.getIn([
    'batch',
    'updateStatus',
    'batchesData',
  ]),
  addBatchSessionStatus: state.data.getIn([
    'batchSession',
    'addStatus',
    'batchSession',
  ]),
  fetchBatchSession: state.data.getIn([
    'batchSession',
    'data',
  ]),
  mentorSessionsUpdateFailure: state.data.getIn(['errors', 'mentorSessions/update']),
  batchSessionAddFailure: state.data.getIn(['errors', 'batchSession/add']),
})


const ClassFeedbackSideNavWithExtraProps = injectProps({
  notification
})(ClassFeedbackSideNav)
export default connect(mapStateToProps)(withRouter(ClassFeedbackSideNavWithExtraProps))
