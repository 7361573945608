const timeFieldNames = [
    {value:'Minute(s)', fieldName:'minute'},
    {value:'Hour(s)', fieldName:'hour'},
    {value:'Day(s)', fieldName:'day'},
    {value:'Week(s)', fieldName:'week'},
    {value:'Month(s)', fieldName:'month'},
    {value:'Year(s)', fieldName:'year'},
]

const dateFieldNames = [
    {value:'Before the event', fieldName:'before'},
    {value:'After the event', fieldName:'after'},
    {value:'After the Registeration', fieldName:'afterRegistration'},
]

const attendeesFieldNames = [
    {value:'To attendees', fieldName:'attendees'},
    {value:'To non-attendees', fieldName:'nonAttendees'},
    {value:'To all', fieldName:'allUser'},

]

const variableFieldNames = {
    studentName: 'Student Name',
    parentName: 'Parent Name',
    eventName: 'Event Name',
    speakerName: 'Parent Name',
    parentPhone: 'Parent Phone',
    parentEmail: 'Parent Email',
    eventDate: 'Event Date',
    studentGrade: 'Student Grade',
}

const DEFAULT_TEMPLATE_DATA = {
    templateName: '',
    commsVariables: [],
    templateFieldData: [],
    condition: 'before',
    attendanceFilter: 'attendees',
    showAttendee: false,
    unit: 'hour', //Duration
    value: 1,
    isTested: false,
    isPassed: false,
}

export {
    DEFAULT_TEMPLATE_DATA,
    timeFieldNames,
    variableFieldNames,
    dateFieldNames,
    attendeesFieldNames
}