import { Icon, Divider } from 'antd'
import styled from 'styled-components'
import React from 'react'
import { TekieAmethyst, TekieOrange } from '../../../../constants/colors'
import { CloseSVG } from '../../../../constants/icons'
import getFullPath from '../../../../utils/getFullPath'

const DemandAcceptModalStyle = styled.div`
    font-family: 'Inter' !important;
    height: 100%;
    width: 100%;
    margin: 0 auto;
`

const HeaderIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 45px;
    background: ${props => props.bgColor ? props.bgColor : '#fff'};
    border-radius: ${props => props.borderRadius ? props.borderRadius : '10px'};
`

const HeaderTop = styled.div`
width: 450px;
height: 48px;
background: #FAF6FF;
border: 1px dashed #8C61CB;
box-sizing: border-box;
border-radius: 16px 16px 0px 0px;
`

const HeaderDetails = styled.div`
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: Inter;
`

const SecondaryText = styled.div`
    font-weight: 500;
    font-size: 13px;
    color: #333333;
    padding-bottom: 12px;
`

const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${props => props.acceptMsg ? `
    @media (max-width: 580px) {
        flex-direction: column;
    }
    ` : ''}
`

const Header = styled.div`
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12px 20px;
    background: #FAF6FF;
    border: 1px dashed #8C61CB;
    box-sizing: border-box;
    border-radius: 16px 16px 0px 0px;
`
const HeaderSessionIndicator = styled.div`
    font-family: 'Inter';
    background: ${props => props.bgColor ? props.bgColor : TekieOrange};
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    color: white;
    font-size: 10px;
    font-weight: 600;
    display: flex;
    text-transform: Uppercase;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 2px;
`

const PreHeaderText = styled.div`
    font-family: 'Inter';
    font-weight: 550;
    font-size: 16px;
    color: #111111;
    margin: 0px 14px;
`

const HeaderTag = styled.div`
    font-family: 'Inter';
    font-weight: normal;
    font-size: 9px;
    color: #FFFFFF;
    width: 50px;
    background: ${props => props.bgColor || '#1B7275'};
    border-radius: 6px;
    padding: 4px 6px;
    text-align: center;
    text-transform: uppercase;
    box-sizing: content-box;
`

const CloseIcon = styled(({ isActive, ...rest }) => <Icon component={CloseSVG} {...rest} />)`
  transition: 0.3s all ease-in-out;
  font-size: ${props => props.fontSize ? props.fontSize : '26px'};
  fill: #666666;
  margin-right: 8px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px 14px;
  cursor: pointer;
`

const HeaderDetailsContainer = styled.div`
    padding: 14px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: Inter;
`

const TopicThumbnail = styled.div`
    background-image: url("${props => getFullPath(props.bgImage) || null}");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
    background-color: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 16px;
`

const HeaderCourse = styled.div`
    font-family: 'Inter';
    font-weight: 500;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    color: ${TekieAmethyst};
`

const HeaderTitle = styled.div`
    font-weight: 550;
    font-size: 16px;
    color: #13343F;
    margin-top: 10px;
    line-height: 1;
    text-transform: capitalize;
    @media (max-width: 580px) {
        font-size: 14px;
        ${props => props.acceptMsg ? 'margin-bottom: 6px;' : `
        margin-top: 5px;
        `}
    }
`

const HeaderDescription = styled.div`
    display: inline-flex;
    align-items: center;
    font-weight: 550;
    font-size: 12px;
    padding: 16px 0px 2px 0px;
    color: #666666;
    letter-spacing: 0px;
    line-height: 1;
    @media (max-width: 580px) {
        padding: 8px 0px 2px 0px;
        font-size: 9px;
    }
`

const HeaderTimestamp = styled.div`
    font-weight: normal;
    font-size: 10px;
    padding: 4px 0px 0px 18px;
    color: #AAAAAA;
`

const CustomDivider = styled(Divider)`
    &.ant-divider-horizontal {
        margin: 0px
    }
`

const StyledIcon = styled(({ isActive, ...rest }) => <Icon {...rest} />)`
  transition: 0.3s all ease-in-out;
  font-size: ${props => props.fontSize ? props.fontSize : '26px'};
  fill: ${props => props.fillSvg ? props.fillSvg : '#666666'};
  margin-right: ${props => props.marginRight ? props.marginRight : '8px'};
`

const ModalBox = styled.div`
    z-index: 9999;
    width: 520px;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    background: #FFFFFF;
    box-shadow: 0px 6px 48px rgba(51, 51, 51, 0.24);
    border-radius: 16px;
    transition: opacity 200ms ease-in-out, left 300ms ease-in-out, visibility .2s cubic-bezier(0.4,0.0,0.2,1);
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    opacity: ${props => props.visible ? 1 : 0};
    left: ${props => props.visible ? '50%' : '51%'};
    overflow: hidden;
    box-sizing: border-box;
    font-family: 'Inter' !important;

    @media screen and (max-width: 500px) {
        min-width: 100%;
        height: 100%;
        width: 100%;
    }
`

const Content = styled.div`
    background-color: white;
`
const ContentClassDetail = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 10px 130px auto;
    grid-column-gap: 12px;
    align-items: center;
    margin-top:12px;
    & .classDetailsText {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        white-space: no-wrap;
        padding: 0px 0px 0px 12px;
        flex: 0 1 20%;
    }

    @media screen and (max-width: 500px) {
        .classDetailsText {
            flex: 0 1 30%;
        }
    }
`

const ContentText = styled.div`
    font-weight: 500;
    font-size: ${props => props.fontSize || '14px'};
    color: #666666;

    @media screen and (max-width: 500px) {
        flex: 1 1 100%;
    }
`

const Text = styled.div`
    font-weight: 550;
    font-size: 14px;
    color: #333333;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const FlexRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: ${props => props.justifyContent || 'flex-start'};
    align-items: ${props => props.alignItems || 'center'};
    margin-top: 12px;
    flex-wrap: wrap;
`

DemandAcceptModalStyle.HeaderIcon = HeaderIcon
DemandAcceptModalStyle.HeaderDetails = HeaderDetails
DemandAcceptModalStyle.SecondaryText = SecondaryText
DemandAcceptModalStyle.HeaderTop = HeaderTop
DemandAcceptModalStyle.FlexContainer = FlexContainer
DemandAcceptModalStyle.Header = Header
DemandAcceptModalStyle.HeaderSessionIndicator = HeaderSessionIndicator
DemandAcceptModalStyle.PreHeaderText = PreHeaderText
DemandAcceptModalStyle.HeaderTag = HeaderTag
DemandAcceptModalStyle.CloseIcon = CloseIcon
DemandAcceptModalStyle.HeaderDetailsContainer = HeaderDetailsContainer
DemandAcceptModalStyle.TopicThumbnail = TopicThumbnail
DemandAcceptModalStyle.HeaderCourse = HeaderCourse
DemandAcceptModalStyle.HeaderTitle = HeaderTitle
DemandAcceptModalStyle.HeaderDescription = HeaderDescription
DemandAcceptModalStyle.HeaderTimestamp = HeaderTimestamp
DemandAcceptModalStyle.Divider = CustomDivider
DemandAcceptModalStyle.Icon = StyledIcon
DemandAcceptModalStyle.ModalBox = ModalBox
DemandAcceptModalStyle.Content = Content
DemandAcceptModalStyle.ContentClassDetail = ContentClassDetail
DemandAcceptModalStyle.ContentText = ContentText
DemandAcceptModalStyle.Text = Text
DemandAcceptModalStyle.FlexRow = FlexRow
export default DemandAcceptModalStyle

