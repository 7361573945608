/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { Popconfirm, Modal, Button, Select, Input, message } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { get } from 'lodash'
import { Table } from '../../components/StyledComponents'
import MainTable from '../../components/MainTable'
import updateUtmDetails from '../../actions/utm/updateUtmDetails'
import deleteUtmDetails from '../../actions/utm/deleteUtmDetails'

const UtmDetailsTableRow = (props) => {
  const {
    columnsTemplate,
    minWidth,
    index,
    fetchUtmData,
    utmDetails,
    partnerTypes,
    groupedLeads
  } = props // const editUtmDetailsHandler = () => {

  // }
  // const deleteUtmDetailsHandler = () => {

  // }
  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [partnerTypeFilter, setPartnerTypeFilter] = React.useState(props.partnerTypeFilter)
  const [signUpUrl, setSignUpUrl] = React.useState(`${utmDetails.source ? `utm_source=${utmDetails.source}` : ''}${utmDetails.medium ? `&utm_medium=${utmDetails.medium}` : ''}${utmDetails.campaign ? `&utm_campaign=${utmDetails.campaign}` : ''}${utmDetails.term ? `&utm_term=${utmDetails.term}${utmDetails.content ? `&utm_content=${utmDetails.content}` : ''}` : ''}`)
  const [utmData, setUtmData] = React.useState({
    id: utmDetails.id,
    source: utmDetails.source,
    campaign: utmDetails.campaign,
    term: utmDetails.term,
    content: utmDetails.content,
    medium: utmDetails.medium,
  })
  const [editUtmDetails, setEditUtmDetails] = React.useState({
    id: utmDetails.id ? utmDetails.id : '',
    source: utmDetails.source ? utmDetails.source : '',
    campaign: utmDetails.campaign ? utmDetails.campaign : '',
    term: utmDetails.term ? utmDetails.term : '',
    content: utmDetails.content ? utmDetails.content : '',
    medium: utmDetails.medium ? utmDetails.medium : '',
    signUpUrl: signUpUrl || '',
    partnerType: get(utmDetails, 'leadPartnerAgent.leadPartner.title'),
    selectedAgent: get(utmDetails, 'leadPartnerAgent.id')
  })
  const [editPartnerTypeFilter, setEditPartnerTypeFilter] = React.useState(props.partnerTypeFilter)
  const mapPartnerTypeToId = (partnerType) => {
    if (partnerType && partnerType !== 'All') {
      if (!partnerTypes) { return '' }
      const partnerTypeId = partnerTypes.filter((item) => item.title === partnerType)[0].id
      return partnerTypeId
    }
    return ''
  }
  const [editPartnerTypeId, setEditPartnerTypeId] = React.useState(
    mapPartnerTypeToId(props.partnerTypeFilter))
  const copySignUpLink = () => {
    setSignUpUrl(`${utmDetails.source ? `utm_source=${utmDetails.source}` : ''}${utmDetails.medium ? `&utm_medium=${utmDetails.medium}` : ''}${utmDetails.campaign ? `&utm_campaign=${utmDetails.campaign}` : ''}${utmDetails.term ? `&utm_term=${utmDetails.term}${utmDetails.content ? `&utm_content=${utmDetails.content}` : ''}` : ''}`)
    navigator.clipboard.writeText(signUpUrl)
    message.success('Link copied to clipboard')
  }
  const showModal = () => {
    setIsModalVisible(true)
  }

  const provideVal = (url, i) => {
    const len = url.length
    const obj = {
      key: '',
      value: '',
      index: i
    }
    while (url[i] !== '_' && i < len) { i += 1 }
    i += 1
    while (url[i] !== '=' && i < len) {
      obj.key += url[i]; i += 1
    }
    i += 1
    while (i < len && url[i] !== '&') {
      obj.value += url[i]
      i += 1
    }
    i += 1
    obj.index = i
    return obj
  }
  const giveParam = (url) => {
    let i = 0
    const len = url.length
    const obj = {
      source: '',
      medium: '',
      campaign: '',
      id: '',
      term: '',
      content: ''
    }
    while (i < len) {
      const tem = provideVal(url, i)
      obj[tem.key] = tem.value
      i = tem.index
    }
    return obj
  }

  const saveEditUtmDetailsHandler = () => {
    setIsModalVisible(false)
    setIsLoading(true)
    setEditPartnerTypeId(mapPartnerTypeToId(editPartnerTypeFilter))
    updateUtmDetails(editUtmDetails, editUtmDetails.selectedAgent).then((res) => {
      setUtmData(utmDetails)
      fetchUtmData(mapPartnerTypeToId(partnerTypeFilter))
      setIsLoading(false)
    })
  }

  const deleteUtmDetailsHandler = () => {
    setIsLoading(true)
    deleteUtmDetails(utmData.id).then((res) => {
      fetchUtmData(mapPartnerTypeToId(partnerTypeFilter))
      setIsLoading(false)
      setUtmData(utmDetails)
    })
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setEditUtmDetails({
      id: utmDetails.id,
      source: utmDetails.source,
      campaign: utmDetails.campaign,
      term: utmDetails.term,
      content: utmDetails.content,
      medium: utmDetails.medium,
      signUpUrl: signUpUrl || '',
    })
  }

  const handleUtmDetailsChange = (e) => {
    if (e.target.name === 'signUpUrl' && e.target.value !== '') {
      const utmParams = giveParam(e.target.value)
      setEditUtmDetails({
        ...editUtmDetails,
        source: utmParams.source,
        medium: utmParams.medium,
        campaign: utmParams.campaign,
        term: utmParams.term,
        content: utmParams.content,
        signUpUrl: e.target.value,
      })
    } else {
      setEditUtmDetails({ ...editUtmDetails, [e.target.name]: e.target.value })
    }
  }
  useEffect(() => {
    setUtmData(utmDetails)
  }, [utmDetails])
  const renderEditUtmDetailsForm = () => (
    <div style={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: 15
      }}
    >
        <div style={{ display: 'grid', gridTemplateColumns: '48% 48%', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>Partner Type:{' '}</span>
          <Select
            value={editUtmDetails.partnerType}
            style={{ width: '100%' }}
            onChange={val => {
                  setEditUtmDetails({
                    ...editUtmDetails, partnerType: val, selectedAgent: get(groupedLeads, `${val}[0].id`)
                  })
                }}
          >
                {Object.keys(groupedLeads).map(partnerType => (
                  <Select.Option
                    value={partnerType}
                    label={partnerType}
                  >
                    {partnerType}
                  </Select.Option>
                ))}
          </Select>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>Agent:{' '}</span>
          <Select
            value={editUtmDetails.selectedAgent}
            style={{ width: '100%' }}
            onChange={val => {
                  setEditUtmDetails({
                    ...editUtmDetails, selectedAgent: val
                  })
                }}
          >
                        {get(groupedLeads, editUtmDetails.partnerType, []).map(partner => (
                        <Select.Option
                          value={get(partner, 'id')}
                          label={get(partner, 'agent.name')}
                        >
                            {get(partner, 'agent.name')}
                        </Select.Option>
                        ))}
          </Select>
        </div>
        </div>
      <Input
        value={editUtmDetails.signUpUrl}
        onChange={handleUtmDetailsChange}
        placeholder='Paste the Sign Up URL'
        name='signUpUrl'
        addonBefore='Sign Up Url: '
        style={{ marginBottom: '15px' }}
      />
        <Input
          value={editUtmDetails.source}
          onChange={handleUtmDetailsChange}
          placeholder='Type Source'
          name='source'
          addonBefore='Source: '
        />
        <Input
          value={editUtmDetails.campaign}
          onChange={handleUtmDetailsChange}
          placeholder='Type Campaign'
          name='campaign'
          addonBefore='Campaign: '
        />
        <Input
          value={editUtmDetails.term}
          onChange={handleUtmDetailsChange}
          placeholder='Type Term'
          name='term'
          addonBefore='Term: '
        />
        <Input
          value={editUtmDetails.content}
          onChange={handleUtmDetailsChange}
          placeholder='Type Content'
          name='content'
          addonBefore='Content: '
        />
        <Input
          value={editUtmDetails.medium}
          onChange={handleUtmDetailsChange}
          placeholder='Type Mediume'
          name='medium'
          addonBefore='Medium: '
        />
    </div>
  )
  return (
        <div>
           <MainTable.Row
             columnsTemplate={columnsTemplate}
             minWidth={minWidth}
             style={{
              height: 'fit-content',
              border: 'none',
            }}
             onClick={(event) => event.stopPropagation()}
           >
            <Table.StickyItem style={{ left: 0 }}>
              <MainTable.Item style={{ width: 50, }}>{index + 1}</MainTable.Item>
              <MainTable.Item style={{ width: 1000, height: '100%' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <a href={signUpUrl} target='_blank' rel='noopener noreferrer' style={{ textAlign: 'left', paddingTop: '2.5vh', paddingBottom: '2.5vh' }}>
                      {signUpUrl}
                    </a>
                    <div style={{ marginLeft: '2vw' }}>
                      {/* <Button onClick={copySignUpLink} type='primary' > */}
                        <CopyOutlined onClick={copySignUpLink} style={{ fontSize: '2.5vh' }} />
                      {/* </Button> */}
                    </div>
                  </div>
              </MainTable.Item>
            </Table.StickyItem>
            <Table.Item>
              <MainTable.Item>
                {get(utmDetails, 'leadPartnerAgent.agent.name', '')}
              </MainTable.Item>
            </Table.Item>
            <Table.Item>
              <MainTable.Item>
                {get(utmData, 'source') || '-'}
              </MainTable.Item>
            </Table.Item>

            <Table.Item>
              <MainTable.Item>
                {get(utmData, 'campaign') || '-'}
              </MainTable.Item>
            </Table.Item>

            <Table.Item>
              <MainTable.Item>
                {get(utmData, 'term') || '-'}
              </MainTable.Item>
            </Table.Item>

            <Table.Item>
              <MainTable.Item>
                {get(utmData, 'content') || '-'}
              </MainTable.Item>
            </Table.Item>

            <Table.Item>
              <MainTable.Item>
                {get(utmData, 'medium') || '-'}
              </MainTable.Item>
            </Table.Item>

            <Table.Item>
              <MainTable.Item>
                <div>
                  <MainTable.ActionItem.IconWrapper style={{ marginRight: '10px' }}>
                    <MainTable.ActionItem.EditIcon onClick={showModal} />
                    <Modal
                      title='EDIT UTM DETAILS'
                      visible={isModalVisible}
                      onOk={saveEditUtmDetailsHandler}
                      onCancel={handleCancel}
                      width='50%'
                      footer={[
                        <Button key='cancel' onClick={handleCancel}>
                            Cancel
                        </Button>,
                        <Button key='submit' type='primary' loading={isLoading} onClick={saveEditUtmDetailsHandler}>
                            Save
                        </Button>,
                    ]}
                    >
                    {renderEditUtmDetailsForm()}
                    </Modal>
                  </MainTable.ActionItem.IconWrapper>
                  <MainTable.ActionItem.IconWrapper>
                      <Popconfirm
                        title='Do you want to delete this utm entry ?'
                        placement='topRight'
                        onConfirm={deleteUtmDetailsHandler}
                        okText='Yes'
                        cancelText='Cancel'
                        key='delete'
                        overlayClassName='popconfirm-overlay-primary'
                      >
                      <MainTable.ActionItem.IconWrapper>
                        <MainTable.ActionItem.DeleteIcon />
                      </MainTable.ActionItem.IconWrapper>
                      </Popconfirm>
                  </MainTable.ActionItem.IconWrapper>
                </div>
              </MainTable.Item>
            </Table.Item>
           </MainTable.Row>
        </div>
  )
}

export default UtmDetailsTableRow
