import React, { Component } from 'react'
import { Select, Icon, Radio, Button, InputNumber, DatePicker, Form, notification, Popover, Popconfirm } from 'antd'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import { get } from 'lodash'
import EventsStyles, { SessionCard } from './createEvent.style'
import { Event } from '../createEvent.style'
import Dropzone from '../../../components/Dropzone'
import {
    InfoCircleFilled,
    EnvironmentFilled,
    CalendarFilled,
    LockFilled,
    IdcardOutlined,
    PaperClipOutlined
}
    from '@ant-design/icons';
import Calendar from '../../../components/FullCalendar';
import getDateRangeArray from '../../DemandSupplyDashboard/demandSupply-utils/getDateRangeArray';
import { StyledBackButton, StyledButton, StyledFooter } from '../SpeakerPrizes/SpeakerPrizes.style'
import addNewEvent from '../../../actions/eventDashboard/addNewEvent'
import fetchEventCategories from '../../../actions/eventDashboard/fetchEventCategories'
import fetchEventTags from '../../../actions/eventDashboard/fetchEventTags'
import { filterKey } from 'duck-state/lib/State'
import indianStates from '../event-utils'
import fetchSingleEvent from '../../../actions/eventDashboard/fetchSingleEvent'
import PageLoader from '../Components/PageLoader'
import getFullPath from '../../../utils/getFullPath'
import updateEventAction from '../../../actions/eventDashboard/updateEventAction'
import removeFromEventAction from '../../../actions/eventDashboard/removeFromEventAction'
import EventNavigation from '../Components/EventNavigation'
import PRE_EVENT_CREATION_TIME from '../../../constants/eventCreationTime'

const invalidChars = [
    43, 45, 101, 69
];

const eventLocationMap = {
    online: ['sessionLink', 'meetingId', 'meetingPassword'],
    venue: ['venueName', 'address', 'state', 'city', 'pincode'],
    toBeAnnounced: [],
    allFormFields: ['venueName', 'address', 'state', 'city', 'pincode', 'sessionLink', 'meetingId', 'meetingPassword']
}

const eventTypeMap = {
    "One Day": "oneday",
    "Recurring": "recurring",
    "Workshop": "workshop",
    "Offline": "offline"
}


class CreateEvent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Type: ['One Day', 'Recurring', 'Workshop', 'Offline'],
            timeZone: 'Asia/Kolkata',
            WeekRange: ['S', 'M', 'T', 'W', 'Th', 'F', 'Sa'],
            selectedRange: [{ S: false, M: false, T: false, W: false, Th: false, F: false, Sa: false }],
            imageFile: null,
            imageUrl: null,
            error: '',
            Week: [],
            location: 'venue',
            date: 'oneday',
            startTime: '',
            endTime: '',
            startDate: '',
            endDate: '',
            startHour: '',
            endHour: '',
            events: [],
            need: true,
            isEditing: false,
            eventBasicInfo: {
                title: '', organiser: '', type: '', category: '', tags: []
            },
            eventLocation: {
                venue: { venueName: '', address: '', city: '', state: '', pincode: '' },
                online: { sessionLink: '', meetingId: '', meetingPassword: '' },
                toBeAnnounced: {}
            },
            eventDateAndTime: {},
            isFormIncomplete: false,
            allMandatoryFields: {
                title: { content: '', isAbsent: false },
                organiser: { content: '', isAbsent: false },
                type: { content: '', isAbsent: false },
                category: { content: '', isAbsent: false },
                tags: { content: [], isAbsent: false },
                venueName: { content: '', isAbsent: false },
                address: { content: '', isAbsent: false },
                city: { content: '', isAbsent: false },
                state: { content: '', isAbsent: false },
                pincode: { content: '', isAbsent: false },
                sessionLink: { content: '', isAbsent: false },
                meetingId: { content: '', isAbsent: false },
                meetingPassword: { content: '', isAbsent: false },
                startTime: { content: '', isAbsent: false },
                endTime: { content: '', isAbsent: false },
                startDate: { content: '', isAbsent: false },
                endDate: { content: '', isAbsent: false },
                timeZone: { content: 'Asia/Kolkata', isAbsent: false },
                imageFile: { content: null, isAbsent: false },
                mobileBannerFile: { content: null, isAbsent: false }
            },
            isSaving: false,
            isFetching: false,
            eventsData: null,
            isPastEvent: false,
            mobileBannerFile: null,
            mobileBannerUrl: null,
            mobileWebError: '',
            selectedRangeError: ''
        }
    }

    async componentDidMount() {
        const { match: { params } } = this.props
        if (get(params, 'id') === 'add') {
            await fetchEventCategories()
            await fetchEventTags()
            this.setState({
                isEditing: false
            })
        } else if (get(params, 'id')) {
            this.setState({
                isFetching: true,
                isEditing: true
            }, async () => {
                await fetchEventCategories()
                await fetchEventTags()
                fetchSingleEvent(get(params, 'id'), 'createPage').then(res => {
                    const { event: {
                        address, category, city, geoLocation, locationType, organizer,
                        name, type, tags = [], pincode, state, eventBanner,
                        meetingPassword, meetingId, sessionLink, timeZone,
                        dateType, eventMobileBanner,
                        eventTimeTableRule: {
                             startDate, endDate, ...slots
                        }
                    } } = res
                    let startHour
                    let endHour
                    const slotsLength = Object.keys(slots).length
                    for (let i = 0; i < slotsLength; i++) {
                        if (slots[`slot${i}`]) {
                            startHour = i
                            endHour = (i === 23) ? 0 : i + 1
                            break
                        }
                    }
                    const selectedWeeks = [{ S: false, M: false, T: false, W: false, Th: false, F: false, Sa: false }]
                    Object.keys(slots).forEach(key => {
                        if (slots[key] && !key.includes('slot') && key) {
                            if (key === 'sunday') {
                                selectedWeeks[0].S = true
                            }
                            if (key === 'monday') {
                                selectedWeeks[0].M = true
                            }
                            if (key === 'tuesday') {
                                selectedWeeks[0].T = true
                            }
                            if (key === 'wednesday') {
                                selectedWeeks[0].W = true
                            }
                            if (key === 'thursday') {
                                selectedWeeks[0].Th = true
                            }
                            if (key === 'friday') {
                                selectedWeeks[0].F = true
                            }
                            if (key === 'saturday') {
                                selectedWeeks[0].Sa = true
                            }
                        }
                    })
                    const eventBasicInfo = {
                        category: get(category, 'id'),
                        organiser: organizer,
                        tags: tags.map(tag => get(tag, 'id')),
                        title: name,
                        type
                    }
                    const eventLocation = {
                        venue: {
                            venueName: geoLocation, address, city, state, pincode
                        },
                        online: {
                            sessionLink, meetingId, meetingPassword
                        },
                        toBeAnnounced: {}
                    }
                    this.setState({
                        isFetching: false,
                        eventBasicInfo,
                        eventLocation,
                        date: dateType,
                        location: locationType,
                        imageUrl: getFullPath(get(eventBanner, 'uri', '')),
                        mobileBannerUrl: getFullPath(get(eventMobileBanner, 'uri', '')),
                        timeZone,
                        startDate: moment(startDate),
                        endDate: moment(endDate),
                        startHour,
                        endHour,
                        startTime: moment(new Date().setHours(startHour || 0, 0, 0)),
                        endTime: moment(new Date().setHours(startHour + 1 || 0, 0, 0)),
                        eventsData: get(res, 'event'),
                        selectedRange: selectedWeeks,
                        isPastEvent: moment().isAfter(moment(startDate).add(startHour, 'hour')),
                        allMandatoryFields: {
                            title: { content: name, isAbsent: false },
                            organiser: { content: organizer, isAbsent: false },
                            type: { content: type, isAbsent: false },
                            category: { content: get(category, 'id'), isAbsent: false },
                            tags: { content: tags.map(tag => get(tag, 'id')), isAbsent: false },
                            venueName: { content: geoLocation, isAbsent: false },
                            address: { content: address, isAbsent: false },
                            city: { content: city, isAbsent: false },
                            state: { content: state, isAbsent: false },
                            pincode: { content: pincode, isAbsent: false },
                            sessionLink: { content: sessionLink, isAbsent: false },
                            meetingId: { content: meetingId, isAbsent: false },
                            meetingPassword: { content: meetingPassword, isAbsent: false },
                            startTime: { content: moment().add(startHour || 0, 'hours'), isAbsent: false },
                            endTime: { content: moment().add(endHour || 0, 'hours'), isAbsent: false },
                            startDate: { content: startDate, isAbsent: false },
                            endDate: { content: endDate, isAbsent: false },
                            timeZone: { content: timeZone, isAbsent: false },
                            imageFile: { content: getFullPath(get(eventBanner, 'uri', '')), isAbsent: false },
                            mobileBannerFile: { content: getFullPath(get(eventMobileBanner, 'uri', '')), isAbsent: false }
                        },
                    })
                })
            })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const { startTime, endTime, startDate, endDate } = this.state
        if (prevState.startDate !== startDate || prevState.endDate !== endDate
            || prevState.startTime !== startTime || prevState.endTime !== endTime) {
            this.getAddEvents()
        }
    }

    handleChange = (e) => {
        const { eventBasicInfo } = this.state
        if (eventBasicInfo.type === 'workshop' || eventBasicInfo.type === 'offline') {
            this.setState({
                date: e.target.value,
            })
        } else {
            this.setState({
                eventBasicInfo: {
                    ...this.state.eventBasicInfo,
                    type: e.target.value,
                }, allMandatoryFields: this.setMandatoryFields('type', e.target.value)
            })
        }
        if (e.target.value === 'oneday' || e.target.value === 'recurring') {
            const { startDate, endDate } = this.state
            const startDateValue = new Date(startDate).getDate()
            const endDateValue = new Date(endDate).getDate()
            if (e.target.value === 'oneday' && startDate) {
                this.setState({ date: e.target.value, endDate: startDate })
            } else if (e.target.value === 'recurring'
                && startDateValue === endDateValue) {
                this.setState({
                    date: e.target.value,
                    endDate: moment(startDate).add(1, 'day')
                })
            } else {
                this.setState({ date: e.target.value })
            }
        }
    }

    handleLocationChange = (e) => {
        this.setState({
            location: e.target.value,
        })
    }
    dropzoneRef = React.createRef(null)
    mobileDropzoneRef = React.createRef(null)
    onDropWebBanner = (file) => {
        this.setState({
            error: ''
        })
        if (file) {
            const img = new Image()
            img.src = window.URL.createObjectURL(file)
            img.onload = () => {
                if (img && parseInt(img.width) === 1920 && parseInt(img.height) === 563) {
                    this.setState({
                        imageFile: file,
                        error: '', allMandatoryFields: this.setMandatoryFields('imageFile', file)
                    })
                } else {
                    this.setState({ error: 'Recommended Size: 563 x 1920' })
                }
            }
        }
    }

    onDropMobileBanner = (file) => {
        this.setState({
            mobileWebError: ''
        })
        if (file) {
            const img = new Image()
            img.src = window.URL.createObjectURL(file)
            img.onload = () => {
                if (img && parseInt(img.width) === 360
                    && (parseInt(img.height) === 476 || parseInt(img.height) === 477)) {
                    this.setState({
                        mobileBannerFile: file,
                        mobileWebError: '', allMandatoryFields: this.setMandatoryFields('mobileBannerFile', file)
                    })
                } else {
                    this.setState({ mobileWebError: 'Recommended Size: 360 X 476' })
                }
            }
        }
    }

    getDateChange = (event) => {
        const { allMandatoryFields, endDate, startDate, endTime, startTime, endHour, startHour } = this.state
        const flexStyle = { display: 'flex', flexDirection: 'column', marginBottom: '20px' }
        return (
            <>
                <div style={{ display: 'flex', marginBottom: '20px', }}>
                    <div style={flexStyle}>
                        <SessionCard.datePicker
                            onChange={(value) => {
                                let dateRange = {}
                                if (event === 'oneday') {
                                    dateRange = {
                                        startDate: value,
                                        endDate: value,
                                    }
                                } else {
                                    dateRange = {
                                        startDate: moment(value),
                                        endDate: moment(value).add(1, 'day'),
                                    }
                                }
                                this.setState({
                                    startDate: dateRange.startDate,
                                    endDate: dateRange.endDate,
                                    allMandatoryFields: this.setMandatoryFields('startDate', value)
                                }, () => {
                                    if (event === 'oneday') {
                                        this.setState({
                                            allMandatoryFields: this.setMandatoryFields('endDate', value)
                                        })
                                    } else {
                                        this.setState({
                                            allMandatoryFields: this.setMandatoryFields('endDate', moment(value).add(1, 'day'))
                                        })
                                    }
                                })
                            }}
                            size='large'
                            value={startDate}
                            disabledDate={(current) => current &&
                                current < new Date().setDate((new Date().getDate()) - 1)
                            }
                            format='DD MMMM YYYY'
                            placeholder='Start Date'
                            style={{ marginRight: '20px', width: '75%' }}
                        />
                        {allMandatoryFields.startDate.isAbsent && <SessionCard.error>
                            Required*
                        </SessionCard.error>}
                        {this.state.isEditing && this.state.isPastEvent && <SessionCard.error>
                            Event Date cannot be of past*
                        </SessionCard.error>}
                    </div>
                    <div style={flexStyle}>
                        <SessionCard.timePicker
                            allowClear={false}
                            value={startTime}
                            disabled={!startDate}
                            disabledHours={() => {
                                if (new Date().setHours(0, 0, 0, 0)
                                    === new Date(startDate).setHours(0, 0, 0, 0)) {
                                    return [...Array(new Date().getHours() + 1 + PRE_EVENT_CREATION_TIME).keys()].slice(1)
                                }
                            }}
                            format='hh a'
                            onChange={(value) => {
                                if (value && get(value, '_d')) {
                                    const selectedRange = {
                                        startHour: get(value, '_d').getHours(),
                                        endHour: get(value, '_d').getHours() + 1
                                    }
                                    if (get(value, '_d').getHours() === 23) {
                                        this.setState({
                                            startHour: selectedRange.startHour,
                                            endHour: selectedRange.endHour,
                                            startTime: value,
                                            endTime: moment(value).add(1, 'hour'),
                                            allMandatoryFields: this.setMandatoryFields('startTime', value)
                                        }, () => this.setState({
                                            allMandatoryFields: this.setMandatoryFields('endTime', moment(value).add(1, 'hour'))
                                        }))
                                    } else {
                                        this.setState({
                                            startHour: selectedRange.startHour,
                                            endHour: selectedRange.endHour,
                                            startTime: value,
                                            endTime: moment(value).add(1, 'hour'),
                                            allMandatoryFields: this.setMandatoryFields('startTime', value)
                                        }, () => this.setState({
                                            allMandatoryFields: this.setMandatoryFields('endTime', moment(value).add(1, 'hour'))
                                        }))
                                    }
                                }
                            }}
                            size='large'
                            placeholder='Start Time'
                            style={{ width: '75%' }}
                        />
                        {allMandatoryFields.startTime.isAbsent && <SessionCard.error>
                            Required*
                        </SessionCard.error>}

                    </div>
                </div>
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                    <div style={flexStyle}>
                        <SessionCard.datePicker
                            disabledDate={(current) => this.onDisabledDates(current)}
                            onChange={(value) => {
                                this.setState({
                                    endDate: value,
                                    allMandatoryFields: this.setMandatoryFields('endDate', value)
                                })
                            }}
                            placeholder='End Date'
                            allowClear={false}
                            value={endDate}
                            disabled={event === 'oneday'}
                            size='large'
                            style={{ marginRight: '20px', width: '75%' }}
                            format='DD MMMM YYYY'
                        />
                        {allMandatoryFields.endDate.isAbsent && <SessionCard.error>
                            Required*
                        </SessionCard.error>}
                    </div>
                    <div style={flexStyle}>
                        <SessionCard.timePicker
                            value={endTime}
                            allowClear={false}
                            disabledHours={() =>
                                [...Array(startHour + 1).keys()].slice(1)
                            }
                            disabled={true}
                            format='hh a'
                            onChange={(value) => {
                                this.setState({
                                    endTime: value,
                                    allMandatoryFields: this.setMandatoryFields('endTime', value)
                                })
                            }}
                            size='large'
                            placeholder='End Time'
                            style={{ width: '75%' }}
                        />
                        {allMandatoryFields.endTime.isAbsent && <SessionCard.error>
                            Required*
                        </SessionCard.error>}
                    </div>
                </div>
                <div style={flexStyle}>
                    <Select
                        showSearch
                        value={this.state.timeZone}
                        onChange={(val) =>
                            this.setState({
                                timeZone: val,
                                allMandatoryFields: this.setMandatoryFields('timeZone', val)
                            })}
                        size='large' placeholder='TimeZone' style={{ width: '40%' }}
                    >
                        {momentTZ.tz.names().map((timezone) => (
                            <Option key={timezone} value={timezone}>{timezone}</Option>
                        ))}
                    </Select>
                    {allMandatoryFields.timeZone.isAbsent && <SessionCard.error>
                        Required*
                    </SessionCard.error>}
                </div>
                {
                    event === 'recurring' && (
                        <div>
                            <SessionCard.InfoTitle>Custom recurrence</SessionCard.InfoTitle>
                            <SessionCard.Heading>Repeat on</SessionCard.Heading>
                            <SessionCard.Heading>
                                {
                                    this.state.WeekRange.map(range =>
                                        <Button
                                            type={this.state.selectedRange[0][range] ? 'primary' : 'default'}
                                            shape="circle"
                                            onClick={() => {
                                                const temp = this.state.selectedRange
                                                temp[0][range] = !temp[0][range]
                                                this.setState({
                                                    selectedRange: temp
                                                }, this.getAddEvents)
                                            }}
                                            style={{
                                                margin: '0 5px'
                                            }}
                                        >
                                            {range}
                                        </Button>
                                    )
                                }
                            </SessionCard.Heading>
                            {this.state.selectedRangeError && <SessionCard.error>
                                {this.state.selectedRangeError}*
                        </SessionCard.error>}
                        </div>
                    )
                }
            </>
        )
    }
    getLocation = (loc) => {
        const { eventLocation, allMandatoryFields } = this.state
        const flexStyle = { display: 'flex', flexDirection: 'column', marginBottom: '20px' }
        if (loc === 'venue') {
            return (
                <div>
                    <div style={flexStyle}>
                        <SessionCard.input
                            onBlur={this.onBlurChanges}
                            value={get(eventLocation, 'venue.venueName')}
                            onChange={(e) => this.setState({
                                eventLocation: { ...eventLocation, venue: { ...eventLocation.venue, venueName: e.target.value } },
                                allMandatoryFields: this.setMandatoryFields('venueName', e.target.value)
                            })} placeholder='Search for venue or location' size='large' prefix={<Icon type='search' style={{ marginRight: '10px' }} />} />
                        {allMandatoryFields.venueName.isAbsent && <SessionCard.error>
                            Venue Required*
                        </SessionCard.error>}
                    </div>
                    <div style={flexStyle}>
                        <SessionCard.input
                            onBlur={this.onBlurChanges}
                            value={get(eventLocation, 'venue.address')}
                            onChange={(e) => this.setState({
                                eventLocation: {
                                    ...eventLocation,
                                    venue: {
                                        ...eventLocation.venue, address: e.target.value
                                    }
                                },
                                allMandatoryFields: this.setMandatoryFields('address', e.target.value)
                            })}
                            placeholder='Enter Address Line' size='large'
                        />
                        {allMandatoryFields.address.isAbsent && <SessionCard.error>
                            Address Required*
                        </SessionCard.error>}
                    </div>
                    <div style={{ margin: '0 0 20px 0', display: 'flex', justifyContent: 'flex-start' }}>
                        <div style={{ width: '30%', marginRight: '0.5rem' }}>
                            <SessionCard.input
                                onBlur={this.onBlurChanges}
                                value={get(eventLocation, 'venue.city')}
                                onChange={(e) => this.setState({
                                    eventLocation: {
                                        ...eventLocation,
                                        venue: { ...eventLocation.venue, city: e.target.value }
                                    }, allMandatoryFields: this.setMandatoryFields('city', e.target.value)
                                })}
                                placeholder='City' size='large' style={{ width: '100%' }} />
                            {allMandatoryFields.city.isAbsent && <SessionCard.error>
                                City Required*
                            </SessionCard.error>}
                        </div>
                        <div style={{ width: '30%', marginRight: '0.5rem' }}>
                            <SessionCard.select
                                value={get(eventLocation, 'venue.state')}
                                onChange={(val) => this.setState({
                                    eventLocation: {
                                        ...eventLocation,
                                        venue: { ...eventLocation.venue, state: val }
                                    }, allMandatoryFields: this.setMandatoryFields('state', val)
                                })} placeholder='Choose State' size='large' style={{ height: '42px', width: '100%' }}
                            >
                                {indianStates.map(state => <Option value={state}>{state}</Option>
                                )}
                            </SessionCard.select>
                            {allMandatoryFields.state.isAbsent && <SessionCard.error>
                                State Required*
                            </SessionCard.error>}
                        </div>

                        <div style={{ width: '33%' }}>
                            <SessionCard.input type='number'
                                onBlur={this.onBlurChanges}
                                value={get(eventLocation, 'venue.pincode')}
                                onKeyPress={(event) =>
                                    invalidChars.includes(event.which) &&
                                    event.preventDefault()}
                                onChange={(e) => e.target.value.length < 7 && this.setState({
                                    eventLocation: {
                                        ...eventLocation,
                                        venue: { ...eventLocation.venue, pincode: e.target.value }
                                    },
                                    allMandatoryFields: this.setMandatoryFields('pincode', e.target.value)
                                })}
                                placeholder='Pincode' size='large' style={{ width: '100%' }} />
                            {allMandatoryFields.pincode.isAbsent && <SessionCard.error>
                                Pincode Required*
                            </SessionCard.error>}
                        </div>
                    </div>
                </div>
            )
        } else if (loc === 'online') {
            return (
                <div>
                    <SessionCard.input
                        style={{ marginBottom: '1rem' }}
                        value={get(eventLocation, 'online.sessionLink')}
                        onBlur={this.onBlurChanges}
                        onChange={(e) =>
                            this.setState({
                                eventLocation: {
                                    ...eventLocation,
                                    online: { ...eventLocation.online, sessionLink: e.target.value }
                                }, allMandatoryFields: this.setMandatoryFields('sessionLink', e.target.value)
                            })}
                        placeholder='Insert zoom/gmeet Link' size='large' prefix={<PaperClipOutlined />} />
                    {allMandatoryFields.sessionLink.isAbsent && <SessionCard.error>
                        Link Required*
                    </SessionCard.error>}
                    <SessionCard.input
                        style={{ marginBottom: '1rem' }}
                        value={get(eventLocation, 'online.meetingId')}
                        onBlur={this.onBlurChanges}
                        onChange={(e) => this.setState({
                            eventLocation: {
                                ...eventLocation,
                                online: { ...eventLocation.online, meetingId: e.target.value }
                            },
                            allMandatoryFields: this.setMandatoryFields('meetingId', e.target.value)
                        })} placeholder='Meeting ID' size='large' prefix={<IdcardOutlined />} />
                    {allMandatoryFields.meetingId.isAbsent && <SessionCard.error>
                        Meeting Id Required*
                    </SessionCard.error>}
                    <SessionCard.input
                        value={get(eventLocation, 'online.meetingPassword')}
                        onBlur={this.onBlurChanges}
                        onChange={(e) => this.setState({
                            eventLocation: {
                                ...eventLocation,
                                online: { ...eventLocation.online, meetingPassword: e.target.value }
                            },
                            allMandatoryFields: this.setMandatoryFields('meetingPassword', e.target.value)
                        })} placeholder='Meeting Password' size='large' prefix={<LockFilled />}
                    />
                    {allMandatoryFields.meetingPassword.isAbsent && <SessionCard.error>
                        Meeting Password Required*
                    </SessionCard.error>}
                </div>
            )
        } else if (loc === 'tba') {
            return (
                <div>
                    To be announced
                </div>
            )
        }
    }

    getAddEvents = () => {
        const { startDate, endDate, startTime, endTime, date, selectedRange } = this.state
        const dateRangeArray = getDateRangeArray(startDate, endDate)
        const addedTimeSlots = []
        const addedWeekDays = []
        selectedRange.forEach(range => {
            if (range.S) addedWeekDays.push(0)
            if (range.M) addedWeekDays.push(1)
            if (range.T) addedWeekDays.push(2)
            if (range.W) addedWeekDays.push(3)
            if (range.Th) addedWeekDays.push(4)
            if (range.F) addedWeekDays.push(5)
            if (range.Sa) addedWeekDays.push(6)
        })
        if (startTime && startDate && endTime && endDate && date === 'recurring') {
            const startTimeValue = moment(startTime).get('hours')
            const times = []
            for (let i = startTimeValue; i <= startTimeValue; i += 1) {
                times.push(i)
            }
            times.forEach(slot => {
                dateRangeArray.forEach(dateValue => {
                    const newDateValue = new Date(dateValue.date).setHours(slot, 0, 0, 0)
                    const slotEventNotExist = addedTimeSlots.find(foundDate =>
                        moment(foundDate).isSame(moment(newDateValue)))
                    // check if the date is not present in any demandSlots and if it is after the current time
                    if (!slotEventNotExist && moment(newDateValue).isAfter(moment())) {
                        const weekDay = new Date(newDateValue).getDay()
                        if (addedWeekDays.includes(weekDay)) {
                            addedTimeSlots.push(new Date(newDateValue))
                        }
                    }
                })
            })
            const events = []
            addedTimeSlots.forEach((dateSlots, ind) => {
                const startTime = dateSlots
                const endTime = moment(dateSlots).add(1, 'hour')
                events.push({
                    slotId: `slot${ind}`,
                    title: 'Event',
                    allDay: false,
                    date: new Date(startTime),
                    end: new Date(endTime),
                    backgroundColor: '#00ADE6',
                    extendedProps: {
                        theme: {},
                        clickable: true,
                        startTime: new Date(startTime),
                        endTime: new Date(endTime),
                    },
                })
            })
            this.setState({
                events
            })
        }
    }
    onDisabledDates = (current) => {
        const { startDate } = this.state
        if (this.state.date === 'oneDay') {
            return (
                current && current < new Date().setDate((new Date(startDate || new Date()).getDate()) - 1)
            )
        }
        if (this.state.date === 'recurring') {
            return (
                current && current < new Date().setDate((new Date(startDate || new Date()).getDate()))
            )
        }
    }
    disabledDate(current) {
        let customDate = moment().format("YYYY-MM-DD");
        return current && current < moment(customDate, "YYYY-MM-DD");
    }
    getDisabledHours = () => {
        let hours = [];
        let currentDate = moment().format("YYYY-MM-DD");
        if (!this.state.startDate > moment(currentDate, "YYYY-MM-DD")) {
            for (let i = 0; i < moment().hour(); i++) {
                hours.push(i);
            }
        }
        return hours;
    }

    validateForm = async (e) => {
        e.preventDefault()
        const { allMandatoryFields, location, error, mobileWebError, selectedRange, date, selectedRangeError } = this.state
        const isFieldEmpty = (field) => {
            if (field.content === null || field.content === undefined || field.content.length === 0 || (typeof (field.content) === 'String' && field.content.trim() === '')) {
                return true
            } return false
        }

        if (error) return false
        if (mobileWebError) return false

        const newAllMandatoryFields = { ...allMandatoryFields }

        let formIsIncomplete = false;

        for (const field in allMandatoryFields) {
            if (isFieldEmpty(allMandatoryFields[field])) {
                if (eventLocationMap.allFormFields.includes(field) && !eventLocationMap[location].includes(field)) {
                    continue
                }
                newAllMandatoryFields[field].isAbsent = true
                formIsIncomplete = true
            }
        }
        if (get(selectedRange, '[0]') && date === 'recurring') {
            let selectedWeekCount = 0
            for (const day in selectedRange[0]) {
                if (get(selectedRange, `[0][${day}]`)) {
                    selectedWeekCount += 1
                }
            }
            if (!selectedWeekCount) {
                this.setState({
                    selectedRangeError: 'Please select day/s of week'
                })
                formIsIncomplete = true
            }
        }
        this.setState({ allMandatoryFields: newAllMandatoryFields })
        this.setState({ isFormIncomplete: formIsIncomplete }, () => this.createEvent(e))
    }
    onBlurChanges = () => {
        const { eventBasicInfo, eventLocation, allMandatoryFields } = this.state
        const newEventLocation = {
            ...eventLocation,
            venue: {
                venueName:
                    get(eventLocation, 'venue.venueName', '') ? get(eventLocation, 'venue.venueName', '').trim() : '',
                address: get(eventLocation, 'venue.address', '') ? get(eventLocation, 'venue.address', '').trim() : '',
                city: get(eventLocation, 'venue.city', '') ? get(eventLocation, 'venue.city', '').trim() : '',
                state: get(eventLocation, 'venue.state', '') ? get(eventLocation, 'venue.state', '').trim() : '',
                pincode: get(eventLocation, 'venue.pincode', '') ? get(eventLocation, 'venue.pincode', '').trim() : '',
            },
            online: {
                sessionLink: get(eventLocation, 'online.sessionLink', '') ? get(eventLocation, 'online.sessionLink', '').trim() : '',
                meetingId: get(eventLocation, 'online.meetingId', '') ? get(eventLocation, 'online.meetingId', '').trim() : '',
                meetingPassword: get(eventLocation, 'online.meetingPassword', '') ? get(eventLocation, 'online.meetingPassword', '').trim() : ''
            }
        }
        this.setState({
            eventBasicInfo: {
                ...eventBasicInfo,
                title: get(eventBasicInfo, 'title', '') ? get(eventBasicInfo, 'title', '').trim() : '',
            },
            eventLocation: {
                ...newEventLocation,
            },
            allMandatoryFields: {
                ...allMandatoryFields,
                title: {
                    content: get(eventBasicInfo, 'title', '') ? get(eventBasicInfo, 'title', '').trim() : '', isAbsent: false
                },
                venueName: {
                    content: get(eventLocation, 'venue.venueName', '') ? get(eventLocation, 'venue.venueName', '').trim() : '', isAbsent: false
                },
                address: {
                    content: get(eventLocation, 'venue.address', '') ? get(eventLocation, 'venue.address', '').trim() : '', isAbsent: false
                },
                city: {
                    content: get(eventLocation, 'venue.city', '') ? get(eventLocation, 'venue.city', '').trim() : '', isAbsent: false
                },
                pincode: {
                    content: get(eventLocation, 'venue.pincode', '') ? get(eventLocation, 'venue.pincode', '').trim() : '', isAbsent: false
                },
                sessionLink: {
                    content: get(eventLocation, 'online.sessionLink', '') ? get(eventLocation, 'online.sessionLink', '').trim() : '', isAbsent: false
                },
                meetingId: {
                    content: get(eventLocation, 'online.meetingId', '') ? get(eventLocation, 'online.meetingId', '').trim() : '', isAbsent: false
                },
                meetingPassword: {
                    content: get(eventLocation, 'online.meetingPassword', '') ? get(eventLocation, 'online.meetingPassword', '').trim() : '', isAbsent: false
                },
            },
        })
    }
    checkMandataoryFormFieldsForLocationType = (mandatoryFormFields, key) => {
        for (const field in mandatoryFormFields) {
            if (field.isAbsent) {
                return true
            }
        }
        return false
    }


    createEvent = async (e) => {
        e.preventDefault()
        const { isFormIncomplete } = this.state
        if (!isFormIncomplete) {
            this.setState({ isSaving: true })
            const eventIdFromRoute = get(this.props, 'match.params.id')
            if (this.state.isEditing && eventIdFromRoute) {
                const { startDate, endDate,
                    eventLocation: { venue: {
                        address, city, venueName, pincode, state
                    }, online: {
                        meetingId, meetingPassword, sessionLink
                    } }, location, timeZone, date, eventBasicInfo: {
                        title, organiser, type, tags, category
                    }, imageFile, eventsData, selectedRange, startTime } = this.state
                const eventTimeTableRule = {
                    startDate: new Date(new Date(startDate).setHours(0, 0, 0, 0)).toISOString(),
                    endDate: new Date(new Date(endDate).setHours(0, 0, 0, 0)).toISOString()
                }
                const weekDaysMap = {
                    S: 'sunday',
                    M: 'monday',
                    T: 'tuesday',
                    W: 'wednesday',
                    Th: 'thursday',
                    F: 'friday',
                    Sa: 'saturday'
                }
                for (let key in selectedRange[0]) {
                    if (selectedRange[0][key]) {
                        eventTimeTableRule[weekDaysMap[key]] = true
                    } else {
                        eventTimeTableRule[weekDaysMap[key]] = false
                    }
                }
                for (let slot in get(eventsData, 'eventTimeTableRule')) {
                    if (slot && slot.includes('slot') && get(eventsData, 'eventTimeTableRule')[slot]) {
                        eventTimeTableRule[slot] = false
                    }
                }
                const startHourValue = new Date(startTime).getHours()
                eventTimeTableRule[`slot${startHourValue}`] = true
                const input = {
                    address: address || '',
                    city: city || '',
                    dateType: date,
                    eventTimeTableRule: {
                        ...eventTimeTableRule
                    },
                    geoLocation: venueName || '',
                    locationType: location || '',
                    meetingId: meetingId || '',
                    meetingPassword: meetingPassword || '',
                    name: title || '',
                    organizer: organiser || '',
                    pincode: (pincode && parseInt(pincode)) || 0,
                    sessionLink: sessionLink || '',
                    state: state || '',
                    timeZone: timeZone || 'Asia/Kolkata',
                    type
                }
                await updateEventAction(eventIdFromRoute, input, {
                    tags, category,
                    prevBannerId: get(eventsData, 'eventBanner.id'), bannerImage: imageFile,
                    eventMobileImage: this.state.mobileBannerFile,
                    prevMobileImage: get(eventsData, 'eventMobileBanner.id')
                })
                if (this.props.hasUpdatedEvent) {
                    this.props.history.push(`/eventDashboard/events/${eventIdFromRoute}/eventDescription`)
                }
                if (this.props.hasUpdatedEventFailed) {
                    if (this.props.updatedFailure && this.props.updatedFailure.toJS()
                        && this.props.updatedFailure.toJS().length) {
                        const error = this.props.updatedFailure.toJS().pop()
                        if (get(error, 'error.errors[0].message')) {
                            notification.error({
                                message: get(error, 'error.errors[0].message')
                            })
                        } else {
                            notification.error({ message: 'Error Updating Event!' })
                        }
                    }
                    this.setState({ isSaving: false })
                }
            } else {
                const currentEventCount = localStorage.getItem('currentEventCount') || 0
                const { eventBasicInfo, eventLocation, startDate, endDate, location, imageFile, startTime, endTime, selectedRange, timeZone, date } = this.state
                const res = await addNewEvent({ eventBasicInfo, eventLocation, locationType: location, startDate, endDate, startTime, endTime, imageFile, selectedRange, timeZone, keyCount: currentEventCount, date, eventMobileBanner: this.state.mobileBannerFile })
                const addStatus = this.props.hasAddedEvent && get(this.props.hasAddedEvent.toJS(), `events${currentEventCount}.success`, false)
                const failedStatus = this.props.hasAddedEventFailed && get(this.props.hasAddedEventFailed.toJS(), `events${currentEventCount}.failure`, false)
                if (addStatus) {
                    localStorage.setItem('currentEventCount', currentEventCount + 1)
                    const addedEvent = this.props.fetchedEvents && filterKey(this.props.fetchedEvents, `events${currentEventCount}`) && filterKey(this.props.fetchedEvents, `events${currentEventCount}`).toJS() || []
                    this.props.history.push(`/eventDashboard/events/${get(addedEvent[0], 'id')}/basicDetails`)
                    this.props.history.push(`/eventDashboard/events/${get(addedEvent[0], 'id')}/eventDescription`)
                    this.setState({ isSaving: false })
                }
                if (failedStatus) {
                    if (this.props.addFailure && this.props.addFailure.toJS().length) {
                        const error = this.props.addFailure.toJS().pop()
                        if (get(error, 'error.errors[0].message')) {
                            notification.error({
                                message: get(error, 'error.errors[0].message')
                            })
                        }
                    } else {
                        notification.error({ message: 'Error Creating Event!' })
                    }
                    this.setState({ isSaving: false })
                }
            }
        }
    }

    setMandatoryFields = (key, value) => {
        return { ...this.state.allMandatoryFields, [key]: { ...this.state.allMandatoryFields[key], content: value, isAbsent: false } }
    }

    chooseType = (val) => {
        this.setState({
            eventBasicInfo: {
                ...this.state.eventBasicInfo, type: val
            }, allMandatoryFields: this.setMandatoryFields('type', val)
        })
        if (val === 'oneday' || val === 'recurring') {
            this.setState({ date: val })
        }
    }

    chooseTags = (val) => {
        const { eventBasicInfo, isEditing, eventsData } = this.state
        if (val.length < 11) {
            this.setState({
                eventBasicInfo: {
                    ...this.state.eventBasicInfo, tags: val
                }, allMandatoryFields: this.setMandatoryFields('tags', val)
            }, () => {
                const eventIdFromRoute = get(this.props, 'match.params.id')
                if (isEditing && eventIdFromRoute) {
                    const removedTagId = get(eventsData, 'tags', []).map(tag => get(tag, 'id')).filter(tag =>
                        !this.state.eventBasicInfo.tags.includes(tag))
                    if (removedTagId && removedTagId.length) {
                        removedTagId.forEach(tagId => removeFromEventAction(eventIdFromRoute, '', tagId, 'contentTag'))
                    }
                }
            })
        }
    }

    render() {
        let { fetchedEvents, fetchedCategories, fetchedTags } = this.props
        fetchedEvents = (fetchedEvents && fetchedEvents.toJS()) || []
        fetchedCategories = (fetchedCategories && fetchedCategories.toJS()) || []
        fetchedTags = (fetchedTags && fetchedTags.toJS()) || []
        const { Option } = Select
        const { Type, location, date, error, allMandatoryFields,
            eventsData, isEditing } = this.state
        const eventIdFromRoute = get(this.props, 'match.params.id')

        const { id } = this.props.match.params
        const currentPath = this.props.match.path.split('/')
        const currentNav = currentPath[currentPath.length - 1]
        return (
            <>
                <Event.EventsWrapper style={{ padding: '15px' }}>
                    <PageLoader isfetching={this.state.isFetching} />
                    <EventsStyles>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}><EventsStyles.PageTitle>Create Event</EventsStyles.PageTitle>
                            {(id && id !== 'add') && <EventNavigation id={id} isCurrent={currentNav} />}
                        </div>
                        <Form onSubmit={this.validateForm}>
                            <EventsStyles.Container>
                                <SessionCard.InfoContainer>
                                    <SessionCard.InfoTitle>
                                        <InfoCircleFilled style={{ marginRight: '10px' }} />
                                        Basic Information
                                        <SessionCard.Subtitle>
                                            Name your event and tell event-goers why they should coma and mention key highlights
                                        </SessionCard.Subtitle>
                                    </SessionCard.InfoTitle>
                                    <SessionCard style={{ justifyContent: 'space-between', }}>
                                        <SessionCard.Card>
                                            <Form.Item
                                                style={{ display: 'flex', flexDirection: 'column' }}
                                            >
                                                <SessionCard.Span>Event Title</SessionCard.Span>
                                                <SessionCard.input
                                                    onBlur={this.onBlurChanges}
                                                    value={this.state.eventBasicInfo.title} onChange={(e) => {
                                                        this.setState({
                                                            eventBasicInfo: {
                                                                ...this.state.eventBasicInfo, title: e.target.value
                                                            }, allMandatoryFields: this.setMandatoryFields('title', e.target.value)
                                                        })
                                                    }} placeholder='Title...' size='large' />
                                                {allMandatoryFields.title.isAbsent && <SessionCard.error>
                                                    Title Required*
                                                </SessionCard.error>}
                                            </Form.Item>
                                            <Form.Item
                                                style={{ display: 'flex', flexDirection: 'column' }}
                                            >
                                                <SessionCard.Span>Organizer</SessionCard.Span>
                                                <SessionCard.select
                                                    value={get(this.state, 'eventBasicInfo.organiser')}
                                                    onChange={(val) => this.setState({
                                                        eventBasicInfo: { ...this.state.eventBasicInfo, organiser: val },
                                                        allMandatoryFields: this.setMandatoryFields('organiser', val)
                                                    })} placeholder='Organiser' size='large' style={{ height: 'unset' }}
                                                    prefix={<SessionCard.Span>Event Title</SessionCard.Span>} >
                                                    <Option value="tekie">Tekie</Option>
                                                    <Option value="radioStreet">Radio street</Option>
                                                    <Option value="bookMyShow">Book my show</Option>
                                                </SessionCard.select>
                                                {allMandatoryFields.organiser.isAbsent && <SessionCard.error>
                                                    Organiser Required*
                                                </SessionCard.error>}
                                            </Form.Item>
                                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
                                                    <SessionCard.Span>Type</SessionCard.Span>
                                                    <Select
                                                        value={get(this.state, 'eventBasicInfo.type')}
                                                        onChange={(val) => this.chooseType(val)}
                                                        style={{ marginTop: '10px' }}
                                                        placeholder='Type' size='large' >
                                                        {Type.map((value) => (
                                                            <Option key={eventTypeMap[value]}>{value}</Option>
                                                        ))}
                                                    </Select>
                                                    {allMandatoryFields.type.isAbsent && <SessionCard.error>
                                                        Type Required*
                                                    </SessionCard.error>}
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
                                                    <SessionCard.Span>Category</SessionCard.Span>
                                                    <Select onChange={(val) =>
                                                        this.setState({
                                                            eventBasicInfo: {
                                                                ...this.state.eventBasicInfo, category: val
                                                            }, allMandatoryFields: this.setMandatoryFields('category', val)
                                                        }, () => {
                                                            if (isEditing && eventIdFromRoute) {
                                                                removeFromEventAction(eventIdFromRoute, '', get(eventsData, 'category.id'), 'category')
                                                            }
                                                        })} placeholder='Category' size='large'
                                                        value={this.state.eventBasicInfo.category}
                                                        style={{ marginTop: '10px' }}
                                                    >
                                                        {fetchedCategories.map((value) => (
                                                            <Option key={value.id} value={get(value, 'id')}>{value.title}</Option>
                                                        ))}
                                                    </Select>
                                                    {allMandatoryFields.category.isAbsent && <SessionCard.error>
                                                        Category Required*
                                                    </SessionCard.error>}
                                                </div>
                                            </div>
                                            <SessionCard.Span>Tags</SessionCard.Span>
                                            <Select
                                                value={this.state.eventBasicInfo.tags || []}
                                                onChange={(val) => this.chooseTags(val)}
                                                placeholder='Tags' mode='multiple' allowClear size='large'
                                                style={{ width: '100%', overflowY: 'scroll', marginTop: '10px' }} >
                                                {fetchedTags.map((value) => (
                                                    <Option key={value.id} value={get(value, 'id')}>{value.title}</Option>
                                                ))}
                                            </Select>
                                            {allMandatoryFields.tags.isAbsent && <SessionCard.error>
                                                Tags Required*
                                            </SessionCard.error>}
                                            <h5 style={{ marginBottom: '1rem' }}>{`   `}{this.state.eventBasicInfo.tags.length}/10 tags selected</h5>
                                        </SessionCard.Card>
                                        <div>
                                            <SessionCard.Card style={{
                                            height: '200px',
                                            width: '330px',
                                            display: 'flex',
                                            flexDirection: 'column-reverse',
                                            marginRight: '5%',
                                            marginBottom: '20px'
                                        }}>
                                            <Dropzone
                                                width='100%'
                                                height='100%'
                                                onClose={() => this.setState({ imageFile: null, allMandatoryFields: this.setMandatoryFields('imageFile', null) })}
                                                style={{ margin: '0' }}
                                                getDropzoneFile={(file) => this.onDropWebBanner(file)}
                                                ref={this.dropzoneRef}
                                                defaultImage={this.state.imageUrl}
                                                defaultFile={this.state.imageFile}
                                                onImageUrl={imageUrl => this.setState({ imageUrl })}
                                            >Click or drag to attach
                                            </Dropzone>
                                            {allMandatoryFields.imageFile.isAbsent && <SessionCard.error>
                                                Web Banner Required*
                                            </SessionCard.error>}
                                            <SessionCard.InsertBannerText>Insert Web Banner Here</SessionCard.InsertBannerText>
                                            <span style={{ fontSize: 'small', color: 'red' }}>{error}</span>
                                        </SessionCard.Card>
                                        <SessionCard.Card style={{
                                            height: '200px',
                                            width: '330px',
                                            display: 'flex',
                                            flexDirection: 'column-reverse',
                                            marginRight: '5%'
                                        }}>
                                            <Dropzone
                                                width='100%'
                                                height='100%'
                                                style={{ margin: '0' }}
                                                onClose={() => this.setState({ mobileBannerFile: null, allMandatoryFields: this.setMandatoryFields('mobileBannerFile', null) })}
                                                getDropzoneFile={(file) => this.onDropMobileBanner(file)}
                                                ref={this.mobileDropzoneRef}
                                                defaultImage={this.state.mobileBannerUrl}
                                                defaultFile={this.state.mobileBannerFile}
                                                onImageUrl={imageUrl => this.setState({ mobileBannerUrl: imageUrl })}
                                            >Click or drag to attach
                                            </Dropzone>
                                            {allMandatoryFields.mobileBannerFile.isAbsent && <SessionCard.error>
                                                Mobile Banner Required*
                                            </SessionCard.error>}
                                            <SessionCard.InsertBannerText>Insert Mobile Banner Here</SessionCard.InsertBannerText>
                                            <span style={{ fontSize: 'small', color: 'red', marginTop: '10px' }}>{this.state.mobileWebError}</span>
                                        </SessionCard.Card>
                                        </div>
                                    </SessionCard>
                                </SessionCard.InfoContainer>
                                <SessionCard.InfoContainer>
                                    <SessionCard.InfoTitle style={{ marginTop: '1rem' }}>
                                        <EnvironmentFilled style={{ marginRight: '10px' }} />
                                        Location
                                        <SessionCard.Subtitle>
                                            Tell event-goers where's the party at
                                        </SessionCard.Subtitle>
                                    </SessionCard.InfoTitle>
                                    <div style={{ padding: '25px 25px' }} >
                                        <SessionCard.Card>
                                            <Radio.Group value={location} onChange={this.handleLocationChange} style={{ marginBottom: '20px' }}>
                                                <Radio.Button value="venue" size='large' style={{ marginRight: '20px', width: '403', minWidth: '120px', textAlign: 'center' }} >Venue</Radio.Button>
                                                <Radio.Button value="online" size='large' style={{ marginRight: '20px', width: '403', minWidth: '120px', textAlign: 'center' }} >Online</Radio.Button>
                                                <Radio.Button value="toBeAnnounced" size='large'>To be announced</Radio.Button>
                                            </Radio.Group>
                                            {location && this.getLocation(location)}
                                        </SessionCard.Card>
                                    </div>
                                </SessionCard.InfoContainer>
                                <EventsStyles.CalendarFlexContainer>
                                    <SessionCard.InfoContainer style={{ width: "50%" }}>
                                        <SessionCard.InfoTitle>
                                            <CalendarFilled style={{ marginRight: '10px' }} />
                                            Date {`&`} Time
                                            <SessionCard.Subtitle>
                                                Tell event-goers when's the party on
                                            </SessionCard.Subtitle>
                                        </SessionCard.InfoTitle>
                                        <div style={{ padding: '25px 25px' }} >
                                            <Radio.Group value={date} onChange={this.handleChange} style={{ marginBottom: '20px' }}>
                                                <Radio.Button value="oneday" size='large' style={{ marginRight: '20px', width: '703', minWidth: '120px', textAlign: 'center' }} >One-Day</Radio.Button>
                                                <Radio.Button value="recurring" size='large' style={{ marginRight: '20px', width: '703' }} >Recurring Event</Radio.Button>
                                            </Radio.Group>
                                            {date && this.getDateChange(date)}
                                        </div>
                                    </SessionCard.InfoContainer>
                                    {
                                        date === 'recurring' &&
                                        (
                                            <div style={{ height: '500px' }}>
                                                <Calendar
                                                    initialCalendarView='timeGridWeek'
                                                    navLinks
                                                    customViews={{
                                                        timeGridWeek: {
                                                            dayHeaderFormat: { weekday: 'short', day: 'numeric' },
                                                            dayHeaderContent: ({ date }) => (
                                                                <>
                                                                    <div className='fullcalendar-timeGrid-header-weekday'>{date.toLocaleDateString('en', { weekday: 'short' })}</div>
                                                                    <div className='fullcalendar-timeGrid-header-date'>{date.getDate()}</div>
                                                                </>
                                                            )
                                                        },
                                                        timeGridDay: {
                                                            dayHeaderFormat: { weekday: 'short', day: 'numeric' },
                                                            dayHeaderContent: ({ date }) => (
                                                                <>
                                                                    <div className='fullcalendar-timeGrid-header-date'>{date.toLocaleDateString('en', { weekday: 'long' })} {date.getDate()}</div>
                                                                </>
                                                            )
                                                        }
                                                    }}
                                                    customButtons={{
                                                        datePicker: {
                                                            text: (
                                                                <DatePicker
                                                                    style={{ width: '100%' }}
                                                                    onChange={(date, dateString) => {
                                                                        this.setState({
                                                                            navigateToAvailabilityDateGte: dateString
                                                                        })
                                                                    }}
                                                                />
                                                            )
                                                        },
                                                    }}
                                                    customHeaderToolBar={{
                                                        start: 'prev,next title today',
                                                        right: 'timeGridWeek,dayGridMonth,timeGridDay'
                                                    }}
                                                    fetchedEvents={this.state.events}
                                                />

                                            </div>
                                        )
                                    }
                                </EventsStyles.CalendarFlexContainer>
                            </EventsStyles.Container>
                            <StyledFooter>
                                <StyledBackButton mRight onClick={() => this.props.history.goBack()}>
                                    Discard
                                </StyledBackButton>
                                <StyledButton htmlType="submit" loading={this.state.isSaving} >
                                    {(this.state.isSaving) ? 'Saving...' : ' Save & Continue'}
                                </StyledButton>
                            </StyledFooter>
                        </Form>
                    </EventsStyles>
                </Event.EventsWrapper></>
        )
    }
}

export default CreateEvent