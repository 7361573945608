/*eslint-disable */
import React from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'
import DemandCalendarWrapper from '../../DemandCalendar.style'
import Styles from '../../../DemandSupplyDashboard.style'
import AssignMentor from './AssignMentor'
import BroadcastMentor from './BroadcastMentor'
import AssignedMentor from './AssignedMentorDetails'
import { checkIfNotOldSlot, getMentorMenteeSession } from '../../../../SlotsInfo/component/UsersTable/slots-utils'
import { getMentorDataFromBatchSession } from '../../../demandSupply-utils'
import {
  deleteMentorMenteeSession, addMentorMenteeSession,
  deleteBatchSession, updateBatchSessionForDemand
} from '../../../../../actions/demandSupplyDashboard'
import customNotification from '../../../components/CustomNotification'
import { filterKey, getSuccessStatus } from '../../../../../utils/data-utils'
import { MENTOR } from '../../../../../constants/roles'
import { B2B2C } from '../../../../../constants/demandVerticals'
import getDataFromLocalStorage from '../../../../../utils/extract-from-localStorage'
import { ALLOWED_ROLES_MANUAL_SESSIONS } from '../../../../../constants/manualSessionsConfig'
import { Popconfirm } from 'antd'
import getSlotLabel from '../../../../../utils/slots/slot-label'
import { getHoursDiff } from '../../../../../utils/getSlotDifference'

const ASSIGN_MENTOR = 'assignMentor'

const BROADCAST_MENTOR = 'broadcastMentor'

class MentorDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: ASSIGN_MENTOR,
      showSessionExist: false,
      sessionExistData: null,
      batchSession: null,
      selectedMentorId: '',
      selectedMentorSessionId: '',
      defaultMentorId: '',
      mentorMenteeSessionId: '',
      buttonLoading: '',
      assignedMentor: null,
      isBatchSession: false,
      selectedMentors: [],
    }
  }

  onTabChange = (name, value) => {
    const { sessionData } = this.props
    this.setState({
      [name]: value
    }, () => {
      if (get(sessionData, 'session.broadCastedMentors', []).length > 0
        && this.state.selectedMentors.length === 0) {
        this.setState({
          selectedMentors: get(sessionData, 'session.broadCastedMentors', [])
        })
      }
    })
  }

  onStateChange = (name, value) => {
    this.setState({
      [name]: value
    })
  }
  componentDidUpdate = (prevProps, prevState) => {
    const { sessionData, mentorSessionFetchStatus } = this.props
    if (prevProps.sessionData !== sessionData) {
      let defaultMentorId = ''
      let selectedMentorSessionId = ''
      if (get(sessionData, 'recordType') === 'batchSession') {
        defaultMentorId = get(sessionData, 'mentorSession.user.id')
        selectedMentorSessionId = get(sessionData, 'mentorSession.id')
      } else {
        defaultMentorId = get(sessionData, 'mentorMenteeSession.mentorSession.user.id')
        selectedMentorSessionId = get(sessionData, 'mentorMenteeSession.mentorSession.id')
      }
      this.setState({
        defaultMentorId,
        selectedMentorSessionId,
        mentorMenteeSessionId: get(sessionData, 'mentorMenteeSession.id'),
        assignedMentor: get(sessionData, 'mentorDetail'),
        isBatchSession: get(sessionData, 'recordType') === 'batchSession'
      })
    }
    const { showSessionExist, buttonLoading } = this.state
    if (prevState.showSessionExist !== showSessionExist && buttonLoading) {
      this.setState({
        buttonLoading: ''
      })
    }
    const slotNumber = new Date(get(sessionData, 'startTime')).getHours()
    const date = new Date(get(sessionData, 'rawBookingDate')).setHours(0, 0, 0, 0)
    const key = `mentorSession/${date}/${slotNumber}`
    const currMentorSessionFetchStatus = mentorSessionFetchStatus
      && mentorSessionFetchStatus.getIn([key])
    const prevMentorSessionFetchStatus = prevProps.mentorSessionFetchStatus
      && prevProps.mentorSessionFetchStatus.getIn([key])
    if (getSuccessStatus(currMentorSessionFetchStatus, prevMentorSessionFetchStatus)) {
      const mentorsList = this.getMentorsList()
      if (mentorsList && mentorsList.length === 0) {
        this.setState({
          activeTab: BROADCAST_MENTOR
        })
      } else if (mentorsList && mentorsList.length > 0) {
        this.setState({
          activeTab: ASSIGN_MENTOR
        })
      }
    }
  }
  renderAssignButton = () => {
    const batchData = this.getBatchWithEmptyStudent()
    const { selectedMentorId, selectedMentorSessionId,
      buttonLoading, mentorMenteeSessionId, isBatchSession } = this.state
    const isMentorAssigned = isBatchSession ? selectedMentorSessionId : mentorMenteeSessionId
    const { sessionData } = this.props
    const { rawBookingDate } = sessionData
    const slotNumber = new Date(get(sessionData, 'startTime')).getHours()
    const savedRole = getDataFromLocalStorage('login.role')
    const isSameDate = new Date().setHours(0, 0, 0, 0) === new Date(rawBookingDate).setHours(0, 0, 0, 0)
    const timeDiff = getHoursDiff(slotNumber, rawBookingDate)
      const isPassSession = (ALLOWED_ROLES_MANUAL_SESSIONS.includes(savedRole)
      && isSameDate && timeDiff)
    if (isPassSession) {
      if (!selectedMentorSessionId || !selectedMentorId) {
          return (
            <Styles.PrimaryButton reAssign
            disabled={!selectedMentorSessionId || !selectedMentorId}
          >
            {buttonLoading === 'ASSIGN' && <Styles.Spinner />}
            {isMentorAssigned ? 'Re-assign Mentor' : 'Assign Mentor'}
          </Styles.PrimaryButton>
          )
        }
      return (
      <Popconfirm
        title={`Are you sure you want to ${this.state.mentorMenteeSessionId ? 'update' : 'book'} session at ${getSlotLabel(slotNumber).startTime} ?`}
        placement='topRight'
        okText='Yes'
        onConfirm={async () => {
              if (!buttonLoading) {
                if (get(batchData, 'batchExistWithEmptyStudent')) {
                  this.setState({
                    showSessionExist: true,
                    batchSession: batchData
                  })
                } else if (isBatchSession) {
                  await this.onAssignBatchClick(true)
                } else {
                  await this.onAssignClick(true)
                }
              }
            }}
        okButtonProps={{ loading: buttonLoading === 'ASSIGN' }}
        cancelText='Cancel'
        key='toggle'
        >
        <Styles.PrimaryButton reAssign
            disabled={!selectedMentorSessionId || !selectedMentorId}
          >
            {buttonLoading === 'ASSIGN' && <Styles.Spinner />}
            {isMentorAssigned ? 'Re-assign Mentor' : 'Assign Mentor'}
          </Styles.PrimaryButton>
      </Popconfirm>
      )
    }
    return (
      <Styles.PrimaryButton reAssign
        disabled={!selectedMentorSessionId || !selectedMentorId}
        onClick={async () => {
          if (!buttonLoading) {
            if (get(batchData, 'batchExistWithEmptyStudent')) {
              this.setState({
                showSessionExist: true,
                batchSession: batchData
              })
            } else if (isBatchSession) {
              await this.onAssignBatchClick()
            } else {
              await this.onAssignClick()
            }
          }
        }}
      >
        {buttonLoading === 'ASSIGN' && <Styles.Spinner />}
        {isMentorAssigned ? 'Re-assign Mentor' : 'Assign Mentor'}
      </Styles.PrimaryButton>
    )
  }

  onAssignBatchClick = async (assignPastSlot = false) => {
    const { sessionData } = this.props
    const { rawBookingDate, sessionId, sessionStatus } = sessionData
    const slotNumber = new Date(get(sessionData, 'startTime')).getHours()
    const { selectedMentorId, selectedMentorSessionId } = this.state
    if (selectedMentorSessionId && selectedMentorId) {
      if (sessionStatus && sessionStatus !== 'allotted') {
        this.setState({
          selectedMentorSessionId: get(sessionData, 'mentorSession.id'),
          defaultMentorId: get(sessionData, 'mentorSession.user.id'),
          selectedMentorId: ''
        }, () => customNotification('Session already started/completed!', '', null, 'error'))
      } else if (checkIfNotOldSlot(rawBookingDate, slotNumber, assignPastSlot)) {
        this.setState({
          buttonLoading: 'ASSIGN'
        })
        await updateBatchSessionForDemand(sessionId, selectedMentorSessionId, [],
          rawBookingDate).then(resp => {
          if (resp && resp.updateBatchSession && resp.updateBatchSession.id) {
            this.setState({
              buttonLoading: '',
              defaultMentorId: this.state.selectedMentorId,
              selectedMentorId: '',
              assignedMentor: get(resp, 'updateBatchSession.mentorSession.user')
            })
          } else {
            this.setState({
              selectedMentorSessionId: get(sessionData, 'mentorSession.id'),
              defaultMentorId: get(sessionData, 'mentorSession.user.id'),
              selectedMentorId: '',
              buttonLoading: '',
            })
          }
        })
      } else {
        this.setState({
          selectedMentorSessionId: get(sessionData, 'mentorSession.id'),
          defaultMentorId: get(sessionData, 'mentorSession.user.id'),
          selectedMentorId: ''
        }, () => customNotification('Cannot assign mentor for old batch slots!', '', null, 'error'))
      }
    }
  }

  onSelectMentor = (mentorData) => {
    const { selectedMentors } = this.state
    const addedMentors = selectedMentors.map(mentor => get(mentor, 'id'))
    if (addedMentors.includes(get(mentorData, 'id'))) {
      this.setState({
        selectedMentors: selectedMentors.filter(mentor => get(mentor, 'id') !== get(mentorData, 'id'))
      })
    } else {
      this.setState({
        selectedMentors: [...selectedMentors, mentorData]
      })
    }
  }
  getLoadingProps = () => {
    const { isBatchSession } = this.state
    const { mentorSessionFetchStatus, sessionData } = this.props
    const slotNumber = new Date(get(sessionData, 'startTime')).getHours()
    const date = new Date(get(sessionData, 'rawBookingDate')).setHours(0, 0, 0, 0)
    const key = isBatchSession ? `batchSession/${date}/${slotNumber}` : `mentorSession/${date}/${slotNumber}`
    const currMentorSessionFetchStatus = mentorSessionFetchStatus
      && mentorSessionFetchStatus.getIn([key])
    return currMentorSessionFetchStatus && get(currMentorSessionFetchStatus.toJS(), 'loading')
  }
  renderTabs = () => {
    const { activeTab, selectedMentorId, defaultMentorId,
      selectedMentorSessionId, selectedMentors, assignedMentor } = this.state
    const { sessionData } = this.props
    const mentorsList = this.getMentorsList()
    if (activeTab === ASSIGN_MENTOR && !this.getLoadingProps() && mentorsList.length > 0) {
      const { rawBookingDate } = sessionData
      const slotNumber = new Date(get(sessionData, 'startTime')).getHours()
      const isSameDate = new Date().setHours(0, 0, 0, 0) === new Date(rawBookingDate).setHours(0, 0, 0, 0)
      const savedRole = getDataFromLocalStorage('login.role')
      const timeDiff = getHoursDiff(slotNumber, rawBookingDate)
      const isPassSession = (ALLOWED_ROLES_MANUAL_SESSIONS.includes(savedRole)
      && isSameDate && timeDiff)
      return (
        <AssignMentor
          mentorsList={this.getMentorsList()}
          onStateChange={this.onStateChange}
          selectedMentorId={selectedMentorId}
          defaultMentorId={defaultMentorId}
          activeTab={activeTab}
          sessionData={sessionData}
          isMentorLoading={this.getLoadingProps()}
          selectedMentorSessionId={selectedMentorSessionId}
          byPassAssignment={isPassSession}
        >
          {this.renderAssignButton()}
        </AssignMentor>
      )
    } else if (activeTab === BROADCAST_MENTOR) {
      return (
        <BroadcastMentor
          sessionData={sessionData}
          mentorsList={this.getMentorsList()}
          selectedMentors={selectedMentors}
          onSelectMentor={this.onSelectMentor}
          assignedMentor={assignedMentor}
        />
      )
    }
    return null
  }
  closeSessionModal = () => {
    this.setState({
      showSessionExist: false,
      sessionExistData: null,
      batchSession: null,
      buttonLoading: ''
    })
  }
  renderExistModal = () => {
    const { showSessionExist, sessionExistData, batchSession,
      buttonLoading, isBatchSession } = this.state
    const existUser = get(sessionExistData, '[0].mentorSession.user.name')
    return (
      <Styles.ModalBox visible={showSessionExist}>
        <Styles.Header>
          <Styles.CloseIcon theme='twoTone'
            onClick={() => this.closeSessionModal()}
          />
          <Styles.HeaderDetails>
            <Styles.HeaderTitle>
              Session Already Exist
            </Styles.HeaderTitle>
          </Styles.HeaderDetails>
        </Styles.Header>
        <Styles.ModalContent style={{ minHeight: '150px' }}>
          {
          batchSession ? (
            <div style={{ textAlign: 'center' }}>
              <h3>{get(batchSession, 'mentor.name')} is assigned to batch{' '}
                {get(batchSession, 'batchExistWithEmptyStudent.batch.code')} with 0 students,
              </h3>
              <h3>
                <Styles.PrimaryButton
                  style={{ display: 'initial' }}
                  onClick={async () => {
                    if (!buttonLoading) {
                      await deleteBatchSession(get(batchSession, 'batchExistWithEmptyStudent.id'))
                      this.setState({
                        showSessionExist: false,
                        batchSession: null
                      })
                      if (isBatchSession) {
                        await this.onAssignBatchClick()
                      } else {
                        await this.onAssignClick()
                      }
                    }
                  }}
                >{buttonLoading === 'PROCEED' && <Styles.Spinner />}
                  Click
                </Styles.PrimaryButton> here to countinue.
              </h3>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <h3>session is already assigned to {existUser}</h3>
              <h3>
                <Styles.PrimaryButton
                  style={{ display: 'initial' }}
                  onClick={async () => {
                    if (!buttonLoading) {
                      await this.onProceedClick()
                    }
                  }}
                >
                  {buttonLoading === 'PROCEED' && <Styles.Spinner />}
                  Click
                </Styles.PrimaryButton> here to update the mentor, or{' '}
                <Styles.SecondaryButton onClick={() => window.location.reload()} icon='reload'>
                  Refresh
                </Styles.SecondaryButton> the page to see latest mentors.
              </h3>
            </div>
          )
        }
        </Styles.ModalContent>
      </Styles.ModalBox>
    )
  }

  onProceedClick = async () => {
    const { selectedMentorId, selectedMentorSessionId,
      mentorMenteeSessionId } = this.state
    const { sessionData } = this.props
    const { sessionId } = sessionData
    if (sessionId && mentorMenteeSessionId && selectedMentorId) {
      this.setState({
        buttonLoading: 'PROCEED'
      })
      await deleteMentorMenteeSession(mentorMenteeSessionId).then(async () => {
        await addMentorMenteeSession(selectedMentorSessionId, sessionId,
          { sessionStatus: 'allotted' }, get(sessionData, 'topic.id'),
          get(sessionData, 'course.id')).then(resp => {
          if (resp && resp.addMentorMenteeSession && resp.addMentorMenteeSession.id) {
            this.setState({
              buttonLoading: '',
              mentorMenteeSessionId: get(resp, 'addMentorMenteeSession.id'),
              defaultMentorId: this.state.selectedMentorId,
              selectedMentorId: '',
              assignedMentor: get(resp, 'addMentorMenteeSession.mentorSession.user')
            })
          }
        })
      })
    } else if (selectedMentorSessionId && selectedMentorId) {
      await addMentorMenteeSession(selectedMentorSessionId, sessionId,
        { sessionStatus: 'allotted' }, get(sessionData, 'topic.id'),
        get(sessionData, 'course.id')).then(resp => {
        if (resp && resp.addMentorMenteeSession && resp.addMentorMenteeSession.id) {
          this.setState({
            buttonLoading: '',
            mentorMenteeSessionId: get(resp, 'addMentorMenteeSession.id'),
            defaultMentorId: this.state.selectedMentorId,
            selectedMentorId: '',
            assignedMentor: get(resp, 'addMentorMenteeSession.mentorSession.user')
          })
        }
      })
    }
    this.setState({
      showSessionExist: false,
      sessionExistData: null,
    })
  }

  getMentorBgAndStatus = (mentor) => {
    const { sessionData } = this.props
    const { isBatchSession } = this.state
    const time = new Date(get(sessionData, 'startTime')).getHours()
    const batchSessions = get(mentor, 'batchSessions', [])
    const { batchExistWithEmptyStudent, showMentor } =
      getMentorDataFromBatchSession(batchSessions, time, isBatchSession ? sessionData : null)
    let batchExistMessage = ''
    if (batchExistWithEmptyStudent) {
      batchExistMessage = `${get(mentor, 'name')} is assigned to batch ${get(batchExistWithEmptyStudent, 'batch.code')} with 0 students, but you can select and assign it anyway.`
    }
    return {
      batchExistWithEmptyStudent,
      batchExistMessage,
      showMentor
    }
  }
  getBatchWithEmptyStudent = () => {
    const { selectedMentorId, selectedMentorSessionId } = this.state
    const findMentor = this.getMentorsList().find(mentor => get(mentor, 'id') === selectedMentorId
      && get(mentor, 'sessionId') === selectedMentorSessionId)
    if (findMentor) {
      return {
        mentor: findMentor,
        batchExistWithEmptyStudent: get(this.getMentorBgAndStatus(findMentor), 'batchExistWithEmptyStudent')
      }
    }
    return null
  }
  onAssignClick = async (assignPastSlot = false) => {
    const { sessionData } = this.props
    const { rawBookingDate, sessionId, sessionStatus } = sessionData
    const slotNumber = new Date(get(sessionData, 'startTime')).getHours()
    const { selectedMentorId, selectedMentorSessionId,
      defaultMentorId, mentorMenteeSessionId } = this.state
    if (sessionId && mentorMenteeSessionId && selectedMentorId) {
      if (sessionStatus && sessionStatus !== 'allotted') {
        this.setState({
          selectedMentorSessionId: get(sessionData, 'mentorMenteeSession.mentorSession.id'),
          defaultMentorId: get(sessionData, 'mentorMenteeSession.mentorSession.user.id'),
          selectedMentorId: ''
        }, () => customNotification('Session already started/completed!', '', null, 'error'))
      } else if (checkIfNotOldSlot(rawBookingDate, slotNumber, assignPastSlot)) {
        this.setState({
          buttonLoading: 'ASSIGN'
        })
        await getMentorMenteeSession(sessionId).then(async (response) => {
          if (get(response, 'data.mentorMenteeSessions[0].mentorSession.user.id') === selectedMentorId) {
            this.setState({
              buttonLoading: '',
            })
            customNotification('Session is Already assigned to mentor', '', null, 'error')
          } else if (get(response, 'data.mentorMenteeSessions', []).length > 0
              && get(response, 'data.mentorMenteeSessions[0].mentorSession.user.id') !== defaultMentorId) {
            this.setState({
              buttonLoading: '',
              showSessionExist: true,
              sessionExistData: get(response, 'data.mentorMenteeSessions', []),
            })
          } else {
            await deleteMentorMenteeSession(mentorMenteeSessionId).then(async () => {
              await addMentorMenteeSession(selectedMentorSessionId, sessionId,
                { sessionStatus: 'allotted' }, get(sessionData, 'topic.id'),
                get(sessionData, 'course.id')).then(resp => {
                if (resp && resp.addMentorMenteeSession && resp.addMentorMenteeSession.id) {
                  this.setState({
                    buttonLoading: '',
                    mentorMenteeSessionId: get(resp, 'addMentorMenteeSession.id'),
                    defaultMentorId: this.state.selectedMentorId,
                    selectedMentorId: '',
                    assignedMentor: get(resp, 'addMentorMenteeSession.mentorSession.user')
                  })
                }
              })
            })
          }
        })
      } else {
        this.setState({
          selectedMentorSessionId: get(sessionData, 'mentorMenteeSession.mentorSession.id'),
          defaultMentorId: get(sessionData, 'mentorMenteeSession.mentorSession.user.id'),
          selectedMentorId: ''
        }, () => customNotification('Cannot update old slots!', '', null, 'error'))
      }
    } else if (selectedMentorSessionId && selectedMentorId) {
      if (checkIfNotOldSlot(rawBookingDate, slotNumber, assignPastSlot)) {
        this.setState({
          buttonLoading: 'ASSIGN'
        })
        await getMentorMenteeSession(sessionId).then(async (response) => {
          if (get(response, 'data.mentorMenteeSessions', []).length > 0) {
            this.setState({
              buttonLoading: '',
              showSessionExist: true,
              sessionExistData: get(response, 'data.mentorMenteeSessions', []),
            })
          } else {
            await addMentorMenteeSession(selectedMentorSessionId, sessionId,
              { sessionStatus: 'allotted' }, get(sessionData, 'topic.id'),
              get(sessionData, 'course.id')).then(resp => {
              if (resp && resp.addMentorMenteeSession && resp.addMentorMenteeSession.id) {
                this.setState({
                  buttonLoading: '',
                  mentorMenteeSessionId: get(resp, 'addMentorMenteeSession.id'),
                  defaultMentorId: this.state.selectedMentorId,
                  selectedMentorId: '',
                  assignedMentor: get(resp, 'addMentorMenteeSession.mentorSession.user')
                })
              }
            })
          }
        })
      } else if (sessionStatus && sessionStatus !== 'allotted') {
        this.setState({
          selectedMentorSessionId: get(sessionData, 'mentorMenteeSession.mentorSession.id'),
          defaultMentorId: get(sessionData, 'mentorMenteeSession.mentorSession.user.id'),
          selectedMentorId: ''
        }, () => customNotification('Session already started/completed!', '', null, 'error'))
      } else {
        this.setState({
          selectedMentorSessionId: '',
          defaultMentorId: '',
          selectedMentorId: ''
        }, () => customNotification('Cannot assign mentor for old slots!', '', null, 'error'))
      }
    }
  }

  getMentorsList = () => {
    const { usersData, sessionData } = this.props
    const { isBatchSession } = this.state
    const slotNumber = new Date(get(sessionData, 'startTime')).getHours()
    const date = new Date(get(sessionData, 'rawBookingDate')).setHours(0, 0, 0, 0)
    const key = isBatchSession ? `batchSession/${date}/${slotNumber}` : `mentorSession/${date}/${slotNumber}`
    let mentors = usersData && filterKey(usersData, key) && filterKey(usersData, key).toJS() || []
    let sessionType = 'trial'
    if (isBatchSession) {
      sessionType = 'batch'
      if (get(sessionData, 'batch.type') === B2B2C && get(sessionData, 'topic.order', 0) === 1) {
        sessionType = 'trial'
      }
    }
    mentors = mentors.filter(mentor => get(mentor, 'role') === MENTOR && get(mentor, 'sessionType') === sessionType
      && this.getMentorBgAndStatus(mentor).showMentor)
    return mentors
  }
  render() {
    const { activeTab, assignedMentor } = this.state
    const mentorsList = this.getMentorsList()
    return (
      <DemandCalendarWrapper.EventCard>
        <h1>Mentor Details</h1>
        <div style={{ margin: '20px 0' }} />
        <Styles.FlexContainer justify='flex-start'>
          {
            !this.getLoadingProps() && mentorsList.length > 0 && (
              <DemandCalendarWrapper.TabButton
                style={{ marginRight: 20 }}
                isActive={activeTab === ASSIGN_MENTOR}
                onClick={() => this.onTabChange('activeTab', ASSIGN_MENTOR)}
              >
                Assign Mentor
              </DemandCalendarWrapper.TabButton>
            )
          }
          <DemandCalendarWrapper.TabButton
            onClick={() => this.onTabChange('activeTab', BROADCAST_MENTOR)}
            isActive={activeTab === BROADCAST_MENTOR}
          >
                Broadcast Mentor
          </DemandCalendarWrapper.TabButton>
        </Styles.FlexContainer>
        {this.renderExistModal()}
        {this.renderTabs()}
        <AssignedMentor assignedMentor={assignedMentor} />
      </DemandCalendarWrapper.EventCard>
    )
  }
}


const mapStateToProps = (state) => ({
  mentorSessionFetchStatus: state.data.getIn(['mentorSession', 'fetchStatus']),
  usersData: state.data.getIn(['user', 'data']),
})

export default connect(mapStateToProps)(MentorDetails)
