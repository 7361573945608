/* eslint-disable no-nested-ternary */
import React from 'react'
import EarningsStyle from './Earnings.style'
import ErrorIcon from './Assests/error.svg'

export const recentBatchClassesColumns = [
  {
    title: 'Model',
    dataIndex: 'model',
    key: 'model',
  },
  {
    title: 'Batch',
    dataIndex: 'batch',
    key: 'batch',
  },
  {
    title: 'Ratio',
    dataIndex: 'ratio',
    key: 'ratio',
  },
  {
    title: 'Date & Time',
    dataIndex: 'dateTime',
    key: 'dateTime',
  },
  {
    title: 'Topic',
    dataIndex: 'topic',
    key: 'topic',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: type => (
      <>
        <EarningsStyle.StyledTag key={type}>
          {type}
        </EarningsStyle.StyledTag>
      </>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Class Status',
    dataIndex: 'classStatus',
    key: 'classStatus',
    render: classStatus => (
      <>
        <EarningsStyle.StyledTag status={classStatus} key={classStatus}>
          {classStatus === 'Successfull' ?
            <div>
              <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.801 4.88073L6.507 5.58673L10.74 1.35373L11.447 2.06073L6.507 7.00073L3.325 3.81873L4.032 3.11173L5.0945 4.17423L5.801 4.88023V4.88073ZM5.802 3.46673L8.278 0.990234L8.983 1.69523L6.507 4.17173L5.802 3.46673ZM4.3885 6.29423L3.682 7.00073L0.5 3.81873L1.207 3.11173L1.9135 3.81823L1.913 3.81873L4.3885 6.29423Z" fill="#01AA93"/>
              </svg>
            </div> :
            classStatus === 'Pending' ? <div>
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 10C2.2385 10 0 7.7615 0 5C0 2.2385 2.2385 0 5 0C7.7615 0 10 2.2385 10 5C10 7.7615 7.7615 10 5 10ZM5 9C6.06087 9 7.07828 8.57857 7.82843 7.82843C8.57857 7.07828 9 6.06087 9 5C9 3.93913 8.57857 2.92172 7.82843 2.17157C7.07828 1.42143 6.06087 1 5 1C3.93913 1 2.92172 1.42143 2.17157 2.17157C1.42143 2.92172 1 3.93913 1 5C1 6.06087 1.42143 7.07828 2.17157 7.82843C2.92172 8.57857 3.93913 9 5 9ZM4.5 6.5H5.5V7.5H4.5V6.5ZM4.5 2.5H5.5V5.5H4.5V2.5Z" fill="#FAAD14"/>
              </svg>
            </div> :
              <svg><ErrorIcon /></svg>}
          {classStatus}
        </EarningsStyle.StyledTag>
      </>
    ),
  },
]
export const recentMentorMenteeClassesColumns = [
  {
    title: 'Model',
    dataIndex: 'model',
    key: 'model',
  },
  {
    title: 'Student Name',
    dataIndex: 'studentName',
    key: 'studentName',
  },
  {
    title: 'Ratio',
    dataIndex: 'ratio',
    key: 'ratio',
  },
  {
    title: 'Date & Time',
    dataIndex: 'dateTime',
    key: 'dateTime',
  },
  {
    title: 'Topic',
    dataIndex: 'topic',
    key: 'topic',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: type => (
      <>
        <EarningsStyle.StyledTag key={type}>
          {type}
        </EarningsStyle.StyledTag>
      </>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Class Status',
    dataIndex: 'classStatus',
    key: 'classStatus',
    render: classStatus => (
      <>
        <EarningsStyle.StyledTag status={classStatus} key={classStatus}>
          {classStatus === 'Successfull' ?
            <SuccessfullIcon /> :
            classStatus === 'Pending' ? <PendingIcon /> :
              <ErrorIcon />}
          {classStatus}
        </EarningsStyle.StyledTag>
      </>
    ),
  },
]
export const priceBreakdownTitleContainer = [
  {
    title: 'Earnings Avenue',
    dataIndex: 'EarningsAvenue',
    key: 'EarningsAvenue',
  },
  {
    title: 'Ratio',
    dataIndex: 'Ratio',
    key: 'Ratio',
  },
  {
    title: 'Completed',
    dataIndex: 'Completed',
    key: 'Completed',
  }
]
export const priceBreakdownDataSource = [
  {
    key: 1,
    EarningsAvenue: 'India Trial',
    Ratio: '1:1',
    Completed: '₹200',
  },
  {
    key: 2,
    EarningsAvenue: 'Paid',
    Ratio: '1:1',
    Completed: '₹200',
  },
  {
    key: 2,
    Ratio: '1:2',
    Completed: '₹250',
  },
  {
    key: 2,
    Ratio: '1:3',
    Completed: '₹300',
  },
  {
    key: 3,
    EarningsAvenue: 'School (B2B+B2B2C)',
    Ratio: '1:1',
    Completed: '₹200',
  },
  {
    key: 3,
    Ratio: '1:2',
    Completed: '₹250',
  },
  {
    key: 3,
    Ratio: '1:3',
    Completed: '₹300',
  },
  {
    key: 3,
    Ratio: '4-10',
    Completed: '₹350',
  },
  {
    key: 3,
    Ratio: '10+',
    Completed: '₹400',
  },
  {
    key: 4,
    EarningsAvenue: 'Training',
    Ratio: 'Demo 1',
    Completed: '₹200',
  },
  {
    key: 4,
    Ratio: 'Demo 2',
    Completed: '₹100',
  },
  {
    key: 4,
    Ratio: 'Supervision',
    Completed: '₹200',

  },
  {
    key: 5,
    EarningsAvenue: 'Auditing',
    Ratio: ' ',
    Completed: '₹150',
  },
  {
    key: 6,
    EarningsAvenue: 'Bonus',
    Ratio: '1:1',
    Completed: '₹1000',

  },
  {
    key: 6,
    Ratio: '1:2',
    Completed: '₹750',
  },
  {
    key: 6,
    Ratio: '1:3',
    Completed: '₹500',
  },
  {
    key: 7,
    EarningsAvenue: 'Referrals',
    Ratio: '1:1',
    Completed: '₹2000',
  },
  {
    key: 7,
    Ratio: '1:2',
    Completed: '₹1500',
  },
  {
    key: 7,
    Ratio: '1:3',
    Completed: '₹1000',
  },
  {
    key: 8,
    EarningsAvenue: 'US Trial',
    Ratio: '1:1',
    Completed: '₹250',
  },
  {
    key: 9,
    EarningsAvenue: 'No Show',
    Ratio: ' ',
    Completed: '₹100',
  },
  {
    key: 10,
    EarningsAvenue: 'UAE Trial',
    Ratio: '1:1',
    Completed: '₹250',
  },
]
