import { FolderOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import moment from 'moment'
import React from 'react'
import { Redirect } from 'react-router-dom'
import UmsSmsSelector from '../../components/UmsSmsSelector'
import countryAndState from '../../constants/CountryAndStates'
import { AUDITOR, AUDIT_ADMIN, BDE,
  BDE_ADMIN,
  CMS, CMS_ADMIN, CMS_VIEWER,
  CONTENT_MAKER, COURSE_MAKER, POST_SALES,
  SMS, TRANSFORMATION_ADMIN, TRANSFORMATION_TEAM, UMS, LEAD_PARTNER, MENTOR
} from '../../constants/roles'
import afterLoginActions from '../../utils/afterLoginActions'
import getDataFromLocalStorage from '../../utils/extract-from-localStorage'
import roleToSystemMap from '../Login/roleToSystemMap'
import { getUmsBlocks, getSmsBlock } from './rolesToRouteMap'
import UserDashboardStyle from './UserDashboard.style'
import { getMentorActiveSessions } from '../MentorDashboard/utils'

const { Option } = Select
class UserDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isEndSessionModalVisible: false,
      activeSessions: [],
      pageType: '',
    }
  }
  async componentDidMount() {
    const savedRole = getDataFromLocalStorage('login.role')
    const savedId = getDataFromLocalStorage('login.id')
    afterLoginActions(savedRole)
    if (savedRole === MENTOR) {
      const activeSessions = await getMentorActiveSessions(savedId)
      const activeSessionsArray = []
      activeSessions.forEach((session) => {
        if (moment().diff(session.sessionStartDate, 'H') > 4) {
          activeSessionsArray.push(session)
        }
      })

      if (activeSessionsArray.length>0) {
        this.setState({
          activeSessions: activeSessionsArray,
          isEndSessionModalVisible: true,
        })
      }
    }
    if (localStorage.getItem('type')) {
      this.setState({ pageType: localStorage.getItem('type') })
    }
  }
  renderBoxes = () => {
    const savedRole = getDataFromLocalStorage('login.role')
    const allowedRoles = roleToSystemMap[savedRole]
    if ((savedRole === CMS_ADMIN || savedRole === CMS_VIEWER) && allowedRoles.includes(CMS)) {
      return (
        <UserDashboardStyle>
          <UserDashboardStyle.IconContainer to='/dashboard' >
            <FolderOutlined
              style={{
                fontSize: 100,
                color: 'white'
              }}
            />
          </UserDashboardStyle.IconContainer>
          <UserDashboardStyle.TextContainer>
            <UserDashboardStyle.Text>CMS</UserDashboardStyle.Text>
          </UserDashboardStyle.TextContainer>
        </UserDashboardStyle>
      )
    } else if ((savedRole === TRANSFORMATION_ADMIN || savedRole === TRANSFORMATION_TEAM)
      && allowedRoles.includes(UMS)) {
      return this.renderUmsBoxes()
    }
    return this.renderUmsBoxes()
  }
  renderUmsBoxes = () => {
    const savedRole = getDataFromLocalStorage('login.role')
    const allowedRoles = getUmsBlocks(savedRole)
    return allowedRoles.map(({ blockName, routes, Icon, withUpdatedDesign, beta = true }) => {
      if (withUpdatedDesign) {
        return (
          <UserDashboardStyle>
            <UserDashboardStyle.BlockContainer
              onClick={() => localStorage.setItem('block', blockName)}
              to={routes[0].route}
            >
              <Icon
                style={{
                  fontSize: 100,
                  color: '#8C61CB'
                }}
              />
              {beta && <UserDashboardStyle.BetaTag>BETA</UserDashboardStyle.BetaTag>}
            </UserDashboardStyle.BlockContainer>
            <UserDashboardStyle.TextContainer>
              <UserDashboardStyle.Label>{blockName}</UserDashboardStyle.Label>
            </UserDashboardStyle.TextContainer>
          </UserDashboardStyle>
        )
      }
      return (
        <UserDashboardStyle>
          <UserDashboardStyle.IconContainer onClick={() => localStorage.setItem('block', blockName)} to={routes[0].route} >
            <Icon
              style={{
                fontSize: 100,
                color: 'white'
              }}
            />
          </UserDashboardStyle.IconContainer>
          <UserDashboardStyle.TextContainer>
            <UserDashboardStyle.Text>{blockName}</UserDashboardStyle.Text>
          </UserDashboardStyle.TextContainer>
        </UserDashboardStyle>
      )
    })
  }
  handleCountryChange = (value) => {
    localStorage.setItem('country', value)
  }
  renderSmsBoxes = () => getSmsBlock().map(({ blockName, routes }) => (
    <UserDashboardStyle>
      <UserDashboardStyle.IconContainer onClick={() => localStorage.setItem('block', blockName)} to={routes[0].route} >
        <FolderOutlined
          style={{
            fontSize: 100,
            color: 'white'
          }}
        />
      </UserDashboardStyle.IconContainer>
      <UserDashboardStyle.TextContainer>
        <UserDashboardStyle.Text>{blockName}</UserDashboardStyle.Text>
      </UserDashboardStyle.TextContainer>
    </UserDashboardStyle>
  ))
  renderCmsBoxes = () => (
    <UserDashboardStyle>
      <UserDashboardStyle.IconContainer to='/dashboard' >
        <FolderOutlined
          style={{
            fontSize: 100,
            color: 'white'
          }}
        />
      </UserDashboardStyle.IconContainer>
      <UserDashboardStyle.TextContainer>
        <UserDashboardStyle.Text>CMS</UserDashboardStyle.Text>
      </UserDashboardStyle.TextContainer>
    </UserDashboardStyle>
  )

  renderCourseMakerBox = () => (
    <UserDashboardStyle>
      <UserDashboardStyle.IconContainer to='/addCourse' >
        <FolderOutlined
          style={{
            fontSize: 100,
            color: 'white'
          }}
        />
      </UserDashboardStyle.IconContainer>
      <UserDashboardStyle.TextContainer>
        <UserDashboardStyle.Text>{COURSE_MAKER}</UserDashboardStyle.Text>
      </UserDashboardStyle.TextContainer>
    </UserDashboardStyle>
  )

  renderContentMakerBox = () => (
    <UserDashboardStyle>
      <UserDashboardStyle.IconContainer to='/content-video' >
        <FolderOutlined
          style={{
            fontSize: 100,
            color: 'white'
          }}
        />
      </UserDashboardStyle.IconContainer>
      <UserDashboardStyle.TextContainer>
        <UserDashboardStyle.Text>{CONTENT_MAKER}</UserDashboardStyle.Text>
      </UserDashboardStyle.TextContainer>
    </UserDashboardStyle>
  )
  render() {
    const type = localStorage.getItem('type')
    const savedRole = getDataFromLocalStorage('login.role')
    const allowedType = roleToSystemMap[savedRole]
    if (savedRole === TRANSFORMATION_TEAM || savedRole === TRANSFORMATION_ADMIN) {
      const redirectRoute = '/ums/dashboard'
      return <Redirect to={redirectRoute} />
    }
    if (savedRole === POST_SALES
      || savedRole === AUDIT_ADMIN || savedRole === AUDITOR) {
      const redirectRoute = '/audit'
      return <Redirect to={redirectRoute} />
    }
    if (savedRole === BDE || savedRole === BDE_ADMIN) {
      const redirectRoute = '/bde-management'
      return <Redirect to={redirectRoute} />
    }
    if (savedRole === LEAD_PARTNER) {
      const redirectRoute = '/ums/dashboard'
      return <Redirect to={redirectRoute} />
    }
    return (
      <div>
        <UserDashboardStyle.DropdownContainer >
          {allowedType.length > 1 && <UmsSmsSelector />}
          <UserDashboardStyle.Dropdown
            placeholder='Select Country'
            defaultValue={localStorage.getItem('country')}
            onChange={this.handleCountryChange}
          >
            {
              [{ countryValue: 'all', country: 'All' }, ...countryAndState].map((country) => (
                <Option key={country.countryValue} value={country.countryValue}>
                  {country.country}
                </Option>
              ))
            }
          </UserDashboardStyle.Dropdown>
        </UserDashboardStyle.DropdownContainer>
        <div style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}
        >
          {type === UMS && this.renderBoxes()}
          {type === SMS && this.renderSmsBoxes()}
          {type === CMS && this.renderCmsBoxes()}
          {type === COURSE_MAKER && this.renderCourseMakerBox()}
          {type === CONTENT_MAKER && this.renderContentMakerBox()}
          {/* {this.state.activeSessions!== undefined && this.state.activeSessions.length > 0 && <EndActiveSessionsModal activeSessions={this.state.activeSessions} />} */}
        </div>
      </div>
    )
  }
}

export default UserDashboard
