/*eslint-disable*/
import { get } from 'lodash'
import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight'
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { PreviewEditor } from '../../../../../../components/ContentEditor'
import { isBase64 } from '../../../../../../utils/base64Utility'
import CodeTagParser from '../../../../../../utils/CodeTagParser'
import getDecodedStatement from '../../../../../../utils/getDecodedStatement'
import parseChatStatement from '../../../../../../utils/parseStatement'
import { CloseIcon } from '../../../../AddSessions.styles'
import { ProjectDescription } from './Assignment.styles'
import { AssignmentCard } from './Assignment.styles'
import editors from '../../../../../ContentMaker/ContentAssignment/components/utils/editorModes'
const AssignmentView = (props) => {
  const { assignmentData, onDelete, isReordering } = props
  const inputCodeStyles = {
    height: 'fit-content',
    borderRadius: '3px',
    backgroundColor: '#013d4e',
    marginTop: '11px',
    marginBottom: '12px',
    marginHorizontal: 0,
    paddingVertical: '12px',
    paddingHorizontal: 0,
    textAign: 'left'
  }
  const getEditorName = (editorMode) => {
    const editorModes = editors.filter(editor => editor.key === editorMode);
    return editorModes.length > 0 ? editorModes[0].name : 'python'
  }
  const getDecodedUri = (uri) => {
    try {
      return decodeURIComponent(uri)
    }
    catch (e) { 
      return uri
    }  
  }
  return (
    
    <AssignmentCard>
      <CloseIcon onClick={onDelete} />
      <ProjectDescription>
      {isBase64(get(assignmentData, 'statement')) ?
      <PreviewEditor         
        value={getDecodedStatement(get(assignmentData, 'statement'))}
        init={{
        selector: `AVPD-${get(assignmentData, 'id')}`
        }} />
        :
        <h3 style={{width:'80%'}}>{parseChatStatement({ statement: get(assignmentData, 'statement') })}</h3>
      }
      </ProjectDescription>
      {
        !isReordering && (
          <>
           {get(assignmentData, 'editorMode') && (
             <h4>Editor: {getEditorName(get(assignmentData, 'editorMode'))}</h4>
           )}
           {get(assignmentData, 'initialCode')  &&  (
              <>
                <h4>Initial Code: </h4>
                <div style={{ width: '100%' }} >
                  <SyntaxHighlighter
                    language='python'
                    style={darcula}
                    customStyle={inputCodeStyles}
                    codeTagProps={{ style: { marginHorizontal: 15, fontFamily: 'monaco' } }}
                    fontSize={16}
                    fontFamily='Monaco'
                    highlighter='prism'
                  >
                    {getDecodedUri(get(assignmentData, 'initialCode'))}
                  </SyntaxHighlighter>
                </div>
              </>
            )}
            {get(assignmentData, 'questionCodeSnippet') && (
              <>
              <h4>Question code: </h4>
              <SyntaxHighlighter
                language='python'
                style={darcula}
                customStyle={inputCodeStyles}
                codeTagProps={{ style: { marginHorizontal: 15, fontFamily: 'monaco' } }}
                fontSize={16}
                fontFamily='Monaco'
                highlighter='prism'
              >
                {getDecodedUri(get(assignmentData, 'questionCodeSnippet'))}
              </SyntaxHighlighter>
              </>
            )}
            {get(assignmentData, 'answerCodeSnippet')  && (
              <>
                <h4>Answer: </h4>
                <div style={{ width: '100%' }} >
                  <SyntaxHighlighter
                    language='python'
                    style={darcula}
                    customStyle={inputCodeStyles}
                    codeTagProps={{ style: { marginHorizontal: 15, fontFamily: 'monaco' } }}
                    fontSize={16}
                    fontFamily='Monaco'
                    highlighter='prism'
                  >
                    {getDecodedUri(get(assignmentData, 'answerCodeSnippet'))}
                  </SyntaxHighlighter>
                </div>
              </>
            )}
            <h3>Hint: {CodeTagParser(get(assignmentData, 'hint'))}</h3>
          </>
        )
      }
    </AssignmentCard>
  )
}

export default AssignmentView
