import { DatePicker, Modal, notification } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import addAcademicYear from '../../../../actions/SchoolOnboarding/addAcademicYear'
import MainModal from '../../../../components/MainModal'
import { CloseIcon, DateInputStyle, FlexContainer, StyledButton, StyledDivider } from '../../SchoolOnBoarding.style'

export default class AcademicYearModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            academicYearStartDate: null,
            academicYearEndDate: null,
            title: '',
            selectedDate: ''
        }
    }

    componentDidMount() {
        this.setInitialDate()
    }

    componentDidUpdate(prevProps, prevState) {
        const { academicYears, nextAcademicYear } = this.props
        if (academicYears !== prevProps.academicYears) {
            this.setInitialDate()
        }
        if (nextAcademicYear !== prevProps.nextAcademicYear) {
            this.setInitialDate()
        }
    }

    formatDateMonth = (dateMonth) => {
        return dateMonth >= 10 ? dateMonth : `0${dateMonth}` 
    }

    setInitialDate = () => {
        const { academicYears, nextAcademicYear } = this.props
        if (academicYears && academicYears.length) {
            const { startDate, endDate } = nextAcademicYear
            const updatedStartDate = new Date(startDate)
            const updatedEndDate = new Date(endDate)
            const startDateToSend = `${updatedStartDate.getFullYear()}-${this.formatDateMonth(updatedStartDate.getMonth()+1)}-${this.formatDateMonth(updatedStartDate.getDate())}`
            const endDateToSend = `${updatedEndDate.getFullYear()}-${this.formatDateMonth(updatedEndDate.getMonth()+1)}-${this.formatDateMonth(updatedEndDate.getDate())}`
            const title = `Add Academic Year ${updatedStartDate.getFullYear()}-${updatedEndDate.getFullYear().toString().slice(2)}`
            this.setState({
                academicYearStartDate: startDateToSend,
                academicYearEndDate: endDateToSend,
                title,
            })
        } else {
            const currentYear = new Date().getFullYear()
            const startDate = `${currentYear}-05-01`
            const endDate = `${currentYear+1}-04-30`
            let nextYear = currentYear+1
            nextYear = nextYear.toString().slice(2)
            const title = `Add Academic Year ${currentYear}-${nextYear}`
            this.setState({
                academicYearStartDate: startDate,
                academicYearEndDate: endDate,
                title,
            })
        }
    }

    setStartEndDate = (value, startDateChanged) => {
        let endDate = new Date(value)
        endDate.setFullYear(endDate.getFullYear() + 1)
        endDate.setDate(endDate.getDate() - 1);
        const endDateToSend = `${endDate.getFullYear()}-${this.formatDateMonth(endDate.getMonth()+1)}-${this.formatDateMonth(endDate.getDate())}`
        this.setState({
            academicYearStartDate: startDateChanged,
            academicYearEndDate: endDateToSend
        })
    }

    onStartDateChange = (e) => {
        const { academicYears, nextAcademicYear } = this.props
        const startDateChanged = e.target.value
        if (new Date().getFullYear() === new Date(startDateChanged).getFullYear()) {
            if (academicYears.length) {
                const { endDate } = nextAcademicYear
                const updatedEndDate = new Date(endDate)
                if (new Date(startDateChanged) >= updatedEndDate) {
                    this.setStartEndDate(e.target.value, startDateChanged)
                } else {
                    notification.error({ message: 'Select date after last academic year date' })
                }
            } else {
                this.setStartEndDate(e.target.value, startDateChanged)
            }
        }
        else {
            notification.error({ message: 'Please select current year' })
        }
    }

    handleSubmit = async() => {
        const { schoolId } = this.props
        const { academicYearStartDate, academicYearEndDate } = this.state
        const finalStartDate = new Date(academicYearStartDate)
        finalStartDate.setHours(0, 0, 0, 0)
        finalStartDate.toISOString()
        const finalEndDate = new Date(academicYearEndDate)
        finalEndDate.setHours(0, 0, 0, 0)
        finalEndDate.toISOString()
        const input = {
            startDate: finalStartDate,
            endDate: finalEndDate,
        }
        await addAcademicYear({ input, schoolConnectId: schoolId })
    }

    handleModalClose = () => {
        const { onClose } = this.props
        onClose()
    }

    render() {
        const { visible, academicYears, addingAcademicYear } = this.props
        const { academicYearStartDate, academicYearEndDate, title } = this.state
        return (
            <MainModal
                visible={visible}
                onCancel={this.handleModalClose}
                maskClosable
                width='450px'
                bodyStyle={{ padding: 0 }}
                closable={false}
                centered
                destroyOnClose
                footer={null}
            >
                <FlexContainer noPadding style={{ width: '100%', padding: '8px 12px' }}>
                    <h3 style={{ fontWeight: '600' }}>{title}</h3>
                    {(academicYears && academicYears.length) ? <CloseIcon onClick={this.handleModalClose} isNotAbsolute /> : null}
                </FlexContainer>
                <StyledDivider style={{ marginBottom: '1vw' }} />
                <FlexContainer noPadding style={{ padding: '8px 18px 18px 18px', flexDirection: 'column' }} justify='flex-start' align='flex-start'>
                    <FlexContainer noPadding style={{ marginBottom: '22px', gap: '18px' }} justify='flex-start' align='flex-start'>
                        <DateInputStyle>
                            <p>Start Date</p>
                            <input
                                type="date"
                                value={academicYearStartDate}
                                onChange={this.onStartDateChange}
                            />
                        </DateInputStyle>
                        <DateInputStyle>
                            <p>End Date</p>
                            <input
                                type="date"
                                value={academicYearEndDate}
                                disabled={true}
                            />
                        </DateInputStyle>
                    </FlexContainer>
                    <StyledButton
                        width='100%'
                        height='40px'
                        fontSize='16px'
                        type='primary'
                        onClick={this.handleSubmit}
                        size='large'
                        block='true'
                        disabled={!academicYearStartDate || !academicYearEndDate}
                        loading={addingAcademicYear}
                    >
                        Add Academic Year
                    </StyledButton>
                </FlexContainer>
            </MainModal>
        )
    }
}
