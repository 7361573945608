import gql from 'graphql-tag'
import duck from '../../../duck'

const removeLearningSlideQuestion = async (learningSlideId,questionBankId) => duck.query({
  query: gql`
  mutation {
    removeFromLearningSlideQuestionBank(learningSlideId:"${learningSlideId}", questionBankId:"${questionBankId}") {
      learningSlide {
        id
      }
    }
  }
  `,
  type: 'removeLearningSlideQuestion/delete',
  key: `removeLearningSlideQuestion`,
})

export default removeLearningSlideQuestion
