import React, { useState, useEffect } from "react";
import { notification, Select, Spin } from "antd";
import { get } from "lodash";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import "./SelectCourses.styles.scss";
import {
  MainContainer,
  StyledFormContainer,
  AddRemoveCourseButton,
  SelectContainer,
  SingleContainer,
  MainContainerHeading,
} from "./SelectCourses.style";
import fetchCourses from "../../../../actions/coursePackage/fetchCourses";
import ModalConfirm from "../../../CoursePackageMaker/components/ModalConfirm/ModalConfirm";

const { Option } = Select;

const SelectCourses = ({
  fetchedCourses,
  choosenCourses,
  handleCourses,
  setSelectedCourse,
  selectedCourse,
  isTeacherTraining = false,
  setIsTopicsChanged,
}) => {
  const [step, setStep] = useState(1);
  const [allCourses, setAllCourses] = useState([]);
  const [isAddNewTrue, setIsAddNewTrue] = useState(false);
  const [courseNames, setCourseNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState({
    category: "",
    grade: "",
    title: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  // useEffect(() => {
  //     if(fetchedCourses.length > 0){
  //         let fetchedCoursesTemp = fetchedCourses.filter(item => get(item, 'category') == get(course, 'category') && (get(item, 'minGrade') <= get(course, 'grade') && get(item, 'maxGrade') >= get(course, 'grade')))
  //         if (isTeacherTraining) {
  //             fetchedCoursesTemp = fetchedCoursesTemp.filter(item => get(item, 'contentType') === 'teacherTraining')
  //         } else {
  //             fetchedCoursesTemp = fetchedCoursesTemp.filter(item => get(item, 'contentType') === 'normal')
  //         }
  //         setAllCourses(fetchedCoursesTemp)
  //         let tempNames = []
  //         for(let i = 0; i < fetchedCoursesTemp.length; i++){
  //             tempNames.push(get(fetchedCoursesTemp[i], 'title'))
  //         }
  //         setCourseNames([...new Set(tempNames)])
  //     }
  // }, [fetchedCourses])
  const getCourses = () => {
    if (fetchedCourses.length > 0) {
      let fetchedCoursesTemp = fetchedCourses.filter(
        (item) =>
          get(item, "category") == get(course, "category") &&
          get(item, "minGrade") <= get(course, "grade") &&
          get(item, "maxGrade") >= get(course, "grade")
      );
      if (isTeacherTraining) {
        fetchedCoursesTemp = fetchedCoursesTemp.filter(
          (item) => get(item, "contentType") === "teacherTraining"
        );
      } else {
        fetchedCoursesTemp = fetchedCoursesTemp.filter(
          (item) => get(item, "contentType") === "normal"
        );
      }
      let tempNames = [];
      for (let i = 0; i < fetchedCoursesTemp.length; i++) {
        tempNames.push(get(fetchedCoursesTemp[i], "title"));
      }
      return {
        courseNames: [...new Set(tempNames)],
        allCourses: fetchedCoursesTemp,
      };
    }
    return {
      courseNames: [],
      allCourses: [],
    };
  };

  const handleDropdownChange = async (event, type) => {
    if (type === "category") {
      setLoading(true);
      setCourse({ ...course, category: event });
      setCourseNames([]);
      if (get(course, "grade") != "")
        await fetchCourses(event, get(course, "grade"), isTeacherTraining);
      setLoading(false);
      setStep(2);
    }
    if (type === "grade") {
      setLoading(true);
      setCourseNames([]);
      setCourse({ ...course, grade: event });
      await fetchCourses(get(course, "category"), event, isTeacherTraining);
      setLoading(false);
      setStep(3);
    }
    if (type === "title") {
      let reqCourse = getCourses().allCourses.filter(
        (item) =>
          get(item, "category") == get(course, "category") &&
          get(item, "title") == event &&
          get(item, "choosenGrade") == get(course, "grade")
      );
      let exist = choosenCourses.filter(
        (item) => item.id == get(reqCourse, "[0].id")
      );
      if (get(exist, "[0].id")) {
        notification.warn({
          message: "course already selected",
        });
        setCourse({ category: "", grade: "", title: "" });
        setIsAddNewTrue(false);
        setStep(1);
      } else {
        handleCourses(reqCourse, "add");
        setCourse({ category: "", grade: "", title: "" });
        setSelectedCourse([...selectedCourse, event]);
        setIsAddNewTrue(false);
        setStep(1);
      }
    }
  };
  const handleDelete = () => {
    const tempCourse = choosenCourses.filter(
      (item, index) => index !== currentIndex
    );
    handleCourses(tempCourse, "delete");
    setShowModal(false);
  };

  return (
    <MainContainer>
      <MainContainerHeading>Add Courses</MainContainerHeading>
      {choosenCourses.map((course, index) => {
        return (
          <StyledFormContainer>
            <SelectContainer>
              <SingleContainer>
                <h3>Course Type</h3>
                <Select
                  style={{ width: 217 }}
                  placeholder="Course Type"
                  value={course.category}
                  disabled
                  onChange={(e) => handleDropdownChange(e, "category")}
                ></Select>
              </SingleContainer>
              <SingleContainer>
                <h3>Grade</h3>
                <Select
                  style={{ width: 217 }}
                  placeholder="Grade"
                  value={course.choosenGrade}
                  disabled
                  onChange={(e) => handleDropdownChange(e, "grade")}
                ></Select>
              </SingleContainer>
              <SingleContainer>
                <h3>Course Name</h3>
                <Select
                  style={{ width: 217 }}
                  placeholder="Course Name"
                  value={course.title}
                  disabled
                  onChange={(e) => handleDropdownChange(e, "title")}
                ></Select>
              </SingleContainer>
            </SelectContainer>
            <AddRemoveCourseButton
              onClick={() => {
                setCurrentIndex(index);
                setShowModal(true);
                setIsTopicsChanged(true);
              }}
            >
              <DeleteOutlined /> Remove Course
            </AddRemoveCourseButton>
            <ModalConfirm
              showModal={showModal}
              onCancel={() => setShowModal(false)}
              heading="Remove Course"
              main="Are you sure you want to remove the course ?"
              confirm="Yes, Remove Course"
              onConfirm={() => handleDelete()}
            />
          </StyledFormContainer>
        );
      })}
      {choosenCourses.length === 0 || isAddNewTrue ? (
        <StyledFormContainer step={step}>
          <SelectContainer>
            <SingleContainer>
              <h3>Course Type</h3>
              <Select
                style={{ width: 217 }}
                placeholder="Course Category"
                onChange={(e) => handleDropdownChange(e, "category")}
              >
                <Option value="tools">tools</Option>
                <Option value="theory">theory</Option>
                <Option value="programming">programming</Option>
              </Select>
            </SingleContainer>
            {course.category ? (
              <SingleContainer>
                <h3>Grade</h3>
                <Select
                  style={{ width: 217 }}
                  placeholder="Grade"
                  onChange={(e) => handleDropdownChange(e, "grade")}
                >
                  <Option value={1}>1</Option>
                  <Option value={2}>2</Option>
                  <Option value={3}>3</Option>
                  <Option value={4}>4</Option>
                  <Option value={5}>5</Option>
                  <Option value={6}>6</Option>
                  <Option value={7}>7</Option>
                  <Option value={8}>8</Option>
                  <Option value={9}>9</Option>
                  <Option value={10}>10</Option>
                  <Option value={11}>11</Option>
                  <Option value={12}>12</Option>
                </Select>
              </SingleContainer>
            ) : null}

            {course.grade ? (
              <SingleContainer>
                <h3>Course Name</h3>
                <Select
                  style={{ width: 217 }}
                  placeholder="Course Name"
                  notFoundContent={loading ? <Spin /> : "No courses found"}
                  onSelect={(e) => handleDropdownChange(e, "title")}
                >
                  {getCourses().courseNames &&
                    getCourses().courseNames.length > 0 &&
                    getCourses().courseNames.map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                </Select>
              </SingleContainer>
            ) : null}
          </SelectContainer>
        </StyledFormContainer>
      ) : null}
      {choosenCourses.length > 0 ? (
        <AddRemoveCourseButton
          onClick={() => {
            setIsAddNewTrue(!isAddNewTrue);
          }}
        >
          <PlusCircleOutlined /> Add Another Course
        </AddRemoveCourseButton>
      ) : null}
    </MainContainer>
  );
};

export default SelectCourses;
