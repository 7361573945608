import { Button } from 'antd'
import gql from 'graphql-tag'
import { get } from 'lodash'
import React, { useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import requestToGraphql from '../../../utils/requestToGraphql'
import ratingHeaderConfig from './ratingsHeaderConfig'
import getOverallRating from '../utils'

const mentorRatingsFields = (first, skip, filterQuery) => gql`query{
    mentorProfiles( orderBy: createdAt_DESC, ${first ? `first:${first}` : ''}, ${skip ? `skip:${first * skip}` : ''},
    filter:{ and: [
        ${filterQuery}
      ] }) {
      user {
        name,
        id,
      },
      pythonCourseRating5,
      pythonCourseRating4,
      pythonCourseRating3,
      pythonCourseRating2,
      pythonCourseRating1
    }
  }
  `

const DownloadReport = (props) => {
  const [loading, setLoading] = useState(false)
  const [ratingsData, setRatingsData] = useState([])
  const ratingRef = useRef()

  const fetchRatingsData = async () => {
    setLoading(true)
    const mentorRatingFieldsData = []
    const data = await requestToGraphql(mentorRatingsFields(props.perPage, props.currentPage,
      props.query))
    if (get(data.data, 'mentorProfiles', []).length > 0) {
      const mentorProfilesData = (get(data.data, 'mentorProfiles', []))
      mentorProfilesData.forEach((element) => {
        mentorRatingFieldsData.push({
          name: get(element, 'user.name', '-'),
          rating5: get(element, 'pythonCourseRating5', 0) || 0,
          rating4: get(element, 'pythonCourseRating4', 0) || 0,
          rating3: get(element, 'pythonCourseRating3', 0) || 0,
          rating2: get(element, 'pythonCourseRating2', 0) || 0,
          rating1: get(element, 'pythonCourseRating1', 0) || 0,
          overall: getOverallRating(element)
        })
      })
    }

    setRatingsData(mentorRatingFieldsData)
    setLoading(false)
    if (ratingRef && ratingRef.current) ratingRef.current.link.click()
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
      <Button
        type='primary'
        icon='download'
        onClick={fetchRatingsData}
        loading={loading}
      >Download Mentor Report Ratings
      </Button>
      <CSVLink
        style={{ display: 'none' }}
        filename='MentorRatings.csv'
        data={ratingsData}
        headers={ratingHeaderConfig}
        ref={ratingRef}
      />
    </div>
  )
}

export default DownloadReport
