import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import withNav from '../../../components/withNav'
import { filterKey } from '../../../utils/data-utils'
import ManageTrainers from './ManageTrainers'

const ManageTrainersNav = withNav(ManageTrainers)({
  title: 'Manage Trainers',
  activeNavItem: 'Manage Trainers',
  showSMSNavigation: true,
})
const mapStateToProps = (state) => (
  {
    manageTrainersFetchStatus: state.data.getIn(['users', 'fetchStatus', 'schoolTrainer']),
    manageTrainersAddStatus: state.data.getIn(['users', 'addStatus', 'schoolTrainer']),
    manageTrainersUpdateStatus: state.data.getIn(['users', 'updateStatus', 'schoolTrainer']),
    trainers: filterKey(state.data.getIn(['user', 'data']), 'schoolTrainer'),
    trainersMeta: state.data.getIn(['trainersMeta', 'data', 'count']),
    addTrainerError: state.data.getIn(['errors','users/add']),
    updateTrainerError: state.data.getIn(['errors','users/update']),
    teacherBatches: state.data.getIn(['teacherBatches', 'data']),
    teacherBatchesFetchStatus: state.data.getIn(['teacherBatches', 'fetchStatus', 'teacherBatches']),
  }
)

export default connect(mapStateToProps)(withRouter(ManageTrainersNav))
