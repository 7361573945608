import React from "react";
export default function SelectChevronIcon () {
  return (
    <>
    <svg
      width="14px"
      height="14px"
      className="ant-select-suffix"
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="3"
    >
      <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
    </>
  );
};
