/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import moment from 'moment'
import Modal from '../components/Modal.styles'
import { MENTOR, ADMIN, UMS_ADMIN, SALES_EXECUTIVE } from '../../../constants/roles'
import EarningsStyle from './Earnings.style'
import fetchMentorBatchEarnings from '../../../actions/mentorDashboardEarnings/fetchMentorBatchEarnings'
import fetchMentorB2cEarnings from '../../../actions/mentorDashboardEarnings/fetchMentorB2cEarnings'
import getDataFromLocalStorage from '../../../utils/extract-from-localStorage'
import '../customStyles.scss'
import { mentorSessionStatus, getSessionPrice, getFormattedDate, getBatchType } from './utils'
import { recentBatchClassesColumns, recentMentorMenteeClassesColumns, priceBreakdownTitleContainer, priceBreakdownDataSource } from './constants'
import { TekieAmethyst } from '../../../constants/colors'
import GenerateReportModal from '../components/GenetrateReport/GenerateReport'

const loadingIcon = <LoadingOutlined style={{ fontSize: 16, marginRight: '8px', color: TekieAmethyst }} spin />

const Earnings = () => {
  const [isBatchesFetching, setIsBatchesFetching] = React.useState(false)
  const [isNormalSessionFetching, setIsNormalSessionFetching] = React.useState(false)
  const [batchSessions, setBatchSessions] = React.useState(null)
  const [mentorMenteeSessions, setMentorMenteeSessions] = React.useState(null)
  const [totalBatchSessions, setTotalBatchSessions] = React.useState(0)
  const [totalMentorMenteeSessions, setTotalMentorMenteeSessions] = React.useState(0)
  const [recentBatchClassesDataSource, setRecentBatchClassesDataSource] = React.useState([])
  const [recentMentorMenteeClassesDataSource, setRecentMentorMenteeClassesDataSource] = React.useState([])
  const [currentBatchSessionTablePage, setCurrentBatchSessionTablePage] = React.useState(1)
  const [currentNormalSessionTablePage, setCurrentNormalSessionTablePage] = React.useState(1)
  const [GenerateReport, GenerateReportState] = React.useState(false)

  const fetchMentorBatchEarningsData = async (userId, skipCount) => {
    setIsBatchesFetching(true)
    const response = await fetchMentorBatchEarnings(userId, skipCount)
    if (response.batchSessions && response.batchSessions.length >= 0) {
      setIsBatchesFetching(false)
      setBatchSessions(response.batchSessions)
      setTotalBatchSessions(response.batchSessionsMeta.count)
    } else {
      setIsBatchesFetching(false)
    }
  }
  const fetchMentorMenteeSessionsEarningsData = async (userId, skipCount) => {
    setIsNormalSessionFetching(true)
    const response = await fetchMentorB2cEarnings(userId, skipCount)
    if (response.mentorMenteeSessions && response.mentorMenteeSessions.length >= 0) {
      setIsNormalSessionFetching(false)
      setMentorMenteeSessions(response.mentorMenteeSessions)
      setTotalMentorMenteeSessions(response.mentorMenteeSessionsMeta.count)
    } else {
      setIsNormalSessionFetching(false)
    }
  }
  const { mentorId: mentorIdParam } = useParams()
  React.useEffect(() => {
    const mentorId = getDataFromLocalStorage('login.id')
    if (mentorId && getDataFromLocalStorage('login.role') === MENTOR) {
      fetchMentorBatchEarningsData(`${mentorId}`, (currentBatchSessionTablePage - 1) * 5)
    }

    if (mentorIdParam && (getDataFromLocalStorage('login.role') === ADMIN || getDataFromLocalStorage('login.role') === UMS_ADMIN || getDataFromLocalStorage('login.role') === SALES_EXECUTIVE)) {
      fetchMentorBatchEarningsData(`${mentorIdParam}`, (currentBatchSessionTablePage - 1) * 5)
    }
  }, [currentBatchSessionTablePage, mentorIdParam])
  React.useEffect(() => {
    const mentorId = getDataFromLocalStorage('login.id')
    if (mentorId && getDataFromLocalStorage('login.role') === MENTOR) {
      fetchMentorMenteeSessionsEarningsData(`${mentorId}`, (currentNormalSessionTablePage - 1) * 5)
    }
    if (mentorIdParam && (getDataFromLocalStorage('login.role') === ADMIN || getDataFromLocalStorage('login.role') === UMS_ADMIN || getDataFromLocalStorage('login.role') === SALES_EXECUTIVE)) {
      fetchMentorMenteeSessionsEarningsData(`${mentorIdParam}`, (currentNormalSessionTablePage - 1) * 5)
    }
  }, [currentNormalSessionTablePage, mentorIdParam])
  React.useEffect(() => {
    if (batchSessions && batchSessions.length >= 0) {
      const batchSessionsLength = batchSessions.length
      const recentBatchesDataSource = batchSessions.map((batch, index) => ({
        key: index + batchSessionsLength + 1,
        model: getBatchType(get(batch, 'BatchInfo.type', null)),
        batch: get(batch, 'BatchInfo.code'),
        ratio: `1:${batch.BatchInfo.students.length}`,
        dateTime: getFormattedDate(moment(get(batch, 'sessionStartDate')).format('llll')),
        topic: get(batch, 'topic.title'),
        type: 'Learning',
        amount: `₹${getSessionPrice(batch.BatchInfo.students.length)}`,
        classStatus: mentorSessionStatus(get(batch, 'sessionCommentByMentor'), get(batch, 'sessionRecordingLink')),
      }))
      setRecentBatchClassesDataSource(recentBatchesDataSource)
    }
  }, [batchSessions])
  React.useEffect(() => {
    if (mentorMenteeSessions && mentorMenteeSessions.length >= 0) {
      const mentorMenteeSessionsLength = mentorMenteeSessions.length
      const recentMentorMenteeClassessDataSource = mentorMenteeSessions.map((session, index) => ({
        key: index + mentorMenteeSessionsLength + 1,
        model: 'B2C',
        studentName: get(session, 'menteeSession.user.name'),
        ratio: '1:1',
        dateTime: getFormattedDate(moment(get(session, 'sessionStartDate')).format('llll')),
        topic: get(session, 'topic.title'),
        type: 'Learning',
        amount: '₹200',
        classStatus: mentorSessionStatus(get(session, 'isFeedbackSubmitted'), get(session, 'sessionRecordingLink')),
      }))
      setRecentMentorMenteeClassesDataSource(recentMentorMenteeClassessDataSource)
    }
  }, [mentorMenteeSessions])
  const userRole = getDataFromLocalStorage('login.role')
  const isAdmin = (userRole === ADMIN ||
    userRole === UMS_ADMIN || userRole === SALES_EXECUTIVE)
  return (
    <div id='mentor-dashboard-calender-container'>
      {(isBatchesFetching || isNormalSessionFetching) ? (
        <>
          <div className='loading-container show'>
            <div className='loading-bar-container'>
              <div />
            </div>
          </div>
          <div className='mentor-dashboard-calender-loading-container'>
            <Spin indicator={loadingIcon} />
            <div className='mentor-dashboard-calender-loading'>Fetching Sessions...</div>
          </div>
        </>
      ) :
        <>
          <Modal.PrimaryButton
            type='primary'
            icon='file'
            style={{
              right: '0',
              margin: '0 40px 0 auto',

              content: ''
            }}
            onClick={() => GenerateReportState(true)}
          >Generate Report
          </Modal.PrimaryButton>
          <GenerateReportModal
            visible={GenerateReport}
            onClose={() => GenerateReportState(false)}
            mentorIdParam={mentorIdParam}
          />
            {((mentorIdParam && isAdmin) || !isAdmin) ?
              <>
                <EarningsStyle.GraphAndTableContainer>
                  <EarningsStyle.DropdownTitleContainer>
                    <EarningsStyle.ContainerTitle>Recent Batch Sessions</EarningsStyle.ContainerTitle>
                  </EarningsStyle.DropdownTitleContainer>
                  <EarningsStyle.StyledTable
                    dataSource={recentBatchClassesDataSource}
                    columns={recentBatchClassesColumns}
                    loading={isBatchesFetching}
                    pagination={{
                      current: currentBatchSessionTablePage,
                      total: totalBatchSessions,
                      pageSize: 5,
                      onChange: (page) => {
                        setCurrentBatchSessionTablePage(page)
                      }
                    }}
                  />
                </EarningsStyle.GraphAndTableContainer>
                <EarningsStyle.GraphAndTableContainer>
                  <EarningsStyle.DropdownTitleContainer>
                    <EarningsStyle.ContainerTitle>Recent Normal Sessions</EarningsStyle.ContainerTitle>
                  </EarningsStyle.DropdownTitleContainer>
                  <EarningsStyle.StyledTable
                    dataSource={recentMentorMenteeClassesDataSource}
                    columns={recentMentorMenteeClassesColumns}
                    loading={isNormalSessionFetching}
                    pagination={{
                      current: currentNormalSessionTablePage,
                      total: totalMentorMenteeSessions,
                      pageSize: 5,
                      onChange: (page) => {
                        setCurrentNormalSessionTablePage(page)
                      }
                    }}
                  />
                </EarningsStyle.GraphAndTableContainer>
              </>
              : null
          }
          <EarningsStyle.GraphAndTableContainer>
            <EarningsStyle.PriceBreakdownTitleContainer>
              <EarningsStyle.ContainerTitle>Price Breakdown</EarningsStyle.ContainerTitle>
            </EarningsStyle.PriceBreakdownTitleContainer>
            <EarningsStyle.StyledTable
              dataSource={priceBreakdownDataSource}
              columns={priceBreakdownTitleContainer}
              pagination={false}
            />
          </EarningsStyle.GraphAndTableContainer>
        </>
      }
      {/* <EarningsStyle.ShortInfoCardsContainer>
        <EarningsStyle.ShortInfoEarningsCard>
          <EarningsStyle.EarningsCardInfo>
            <EarningsStyle.EarningsCardTitleContainer>
              <EarningsStyle.TotalEarningsText>Total Earnings</EarningsStyle.TotalEarningsText>
              <EarningsStyle.EarningsMonth>July 2021</EarningsStyle.EarningsMonth>
            </EarningsStyle.EarningsCardTitleContainer>
            <EarningsStyle.EarningsCardButton>View Details</EarningsStyle.EarningsCardButton>
          </EarningsStyle.EarningsCardInfo>
          <EarningsStyle.TotalEarnings>
            <img src={CircleVector} alt='circle' />
            <p>₹30640</p>
            <img src={Infoicon} alt='info' />
          </EarningsStyle.TotalEarnings>
          <EarningsStyle.CurrentMonthEarningsContainer>
            <EarningsStyle.CurrentMonthEarnings>₹24987</EarningsStyle.CurrentMonthEarnings>
            <EarningsStyle.PercentageIncreaseContainer>
              <img src={PercentageIncreaseIcon} alt='percentageIncreaseIcon' />
              <EarningsStyle.PercentageIncrease>18%</EarningsStyle.PercentageIncrease>
              <img src={Infoicon} alt='info' />
            </EarningsStyle.PercentageIncreaseContainer>
          </EarningsStyle.CurrentMonthEarningsContainer>
        </EarningsStyle.ShortInfoEarningsCard> */}
      {/* </EarningsStyle.ShortInfoCardsContainer>
      <EarningsStyle.GraphAndTableContainer>
        <EarningsStyle.DropdownTitleContainer>
          <EarningsStyle.ContainerTitle>Overview</EarningsStyle.ContainerTitle>
          <form>
            <label htmlFor='years'>Financial Year</label>
            <select name='years' id='years'>
              <option value='2020-2021'>2020-2021</option>
              <option value='2019-2020'>2019-2020</option>
            </select>
          </form>
        </EarningsStyle.DropdownTitleContainer>
        <ResponsiveContainer
          width='100%'
          height={400}
        >
          <AreaChart
            data={data}
            margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0
      }}
          >
            <defs>
              <linearGradient id='color' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='5%' stopColor='#aaa8e6' stopOpacity={0.7} />
                <stop offset='95%' stopColor='#aaa8e6' stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} opacity={0.5} />
            <XAxis dataKey='month' axisLine={false} tickLine={false} />
            <YAxis axisLine={false} tickLine={false} tickCount={8} />
            <Tooltip />
            <Area type='monotone' dataKey='amt' strokeWidth={3} stroke='#8C61CB' fill='url(#color)' />
          </AreaChart>
        </ResponsiveContainer>
      </EarningsStyle.GraphAndTableContainer> */}
      <br />
    </div>
  )
}

export default Earnings
