
import gql from 'graphql-tag'
import duck from '../../duck';

const addEventCertificateEmbed = async ({ x_c,
    y_c,
    text_size,
    font_family,
  variable,
  red,
  blue,
  green,
  rotate,
}) => {
    const updatedInput = {}
    updatedInput.xDim = parseInt(x_c)
    updatedInput.yDim = parseInt(y_c)
  updatedInput.textSize = parseInt(text_size)
  updatedInput.red = parseInt(red)
  updatedInput.blue = parseInt(blue)
  updatedInput.green = parseInt(green)
  updatedInput.rotate = parseInt(rotate)
    updatedInput.fontFamily = font_family
    updatedInput.variableName = variable
    return duck.query({
        query: gql`
           mutation addEventCertificateEmbed($input: EventCertificateEmbedInput!) {
                addEventCertificateEmbed(input: $input) {
                  id
                }
              }
              `,
        variables: {
            input: updatedInput
        },
        type: 'eventCertificateEmbeds/add',
        key: 'eventCertificateEmbeds',
    })
}

export default addEventCertificateEmbed
