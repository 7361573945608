import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'
import uploadFile from '../utils/uploadFile'

const addSenseiMentorTeam = async (props, nameOfTeam, thumbnailFile) => {
  duck.query({
    query: gql`
          mutation{
              addSenseiMentorTeam(input:{name:"${nameOfTeam}"},senseiProfileConnectId:"${props.senseiId}"){
                  id
                  name
                  teamProfilePic{
                    id
                    uri
                  }
              }
          }
          `,
    type: 'senseiTeams/add',
    key: 'senseiTeams',
    changeExtractedData: (extracted, original) => {
      const teamId = get(original, 'addSenseiMentorTeam.id')
      if (thumbnailFile) {
        (async function imageUpload() {
          await uploadFile(thumbnailFile, { fileBucket: 'python' }, { typeField: 'teamProfilePic', typeId: teamId, type: 'SenseiMentorTeam', })
        }())
      }
      return {
        ...extracted
      }
    }
  })
}

export default addSenseiMentorTeam
