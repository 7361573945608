import gql from 'graphql-tag'
import duck from '../../duck';
import getSlotNames from '../../utils/slots/slot-names';

const fetchSingleEvent = async (id, fromPage = '') => {
    return duck.query({
        query: gql`
            query {
                event(id:"${id}") {
                  id
                  ${fromPage === 'createPage' ? `name
                  timeZone
                  tags(filter: { isEventTag: true }) {
                    id
                    title
                  }
                  eventBanner {
                    id
                    uri
                  }
                  category {
                    id
                    title
                  }
                  id
                  name
                  organizer
                  type
                  dateType
                  locationType
                  geoLocation
                  address
                  city
                  state
                  pincode
                  sessionLink
                  meetingId
                  meetingPassword
                  eventMobileBanner{
                    id
                    uri
                  }
                  eventTimeTableRule {
                        startDate
                        endDate
                        monday
                        tuesday
                        wednesday
                        thursday
                        friday
                        saturday
                        sunday
                        ${getSlotNames()}
                    }` : ''}
                  ${fromPage === 'description' ? `summary
                overview
                listingImage {
                  id
                  uri
                }
                eventJoinReasons {
                  id
                  title
                  picture {
                    id
                    uri
                  }
                }` : ''}
                ${fromPage === 'speakerPrizes' ? `speakers {
                  id
                  linkedInLink
                  roleAtOrganization
                  organization
                  about
                  user {
                    id
                    name
                    email
                    profilePic {
                      uri
                    }
                    phone {
                      countryCode
                      number
                    }
                  }
                }
                prizes {
                  id
                  title
                  minRank
                  maxRank
                  image {
                    id
                    uri
                  }
                }` : ''}
                ${fromPage === 'whatsappPage' ? `ticketName
                  ticketregistrationLimit
                  eventCommsRule {
                    templateName
                    isEmailRule
                    commsVariables {
                      dataField
                      whatsappVariableName
                      emailVariableName
                    }
                    condition
                    attendanceFilter
                    unit
                    value
                    isTested
                    isPassed
                  }
                  commsVariables {
                    id
                    whatsappVariableName
                    emailVariableName
                    dataField
                  }` : ''}
                }
            }
        `,
        type: 'events/fetch',
      key: 'events',
        changeExtractedData: (extracted, original) => {
          extracted.commsVariables = []
            return { ...extracted }
        }
    })
}

export default fetchSingleEvent