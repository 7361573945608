import { message } from 'antd'

// eslint-disable-next-line import/prefer-default-export
const copyText = (text) => {
  if (navigator && navigator.clipboard) {
    navigator.clipboard.writeText(text).then(() => {
      message.success('Copied!')
    })
  }
}

export default copyText

