import gql from 'graphql-tag'
import duck from '../../duck'

const filterUploadedFile = (bucket, type, name) => {
  let filter = ''
  if (bucket.length > 0) {
    filter += `{fileBucket: ${bucket}}`
  }
  if (type.length > 0) {
    filter += `{type: ${type}}`
  }
  if (name.length > 0) {
    filter += `{name_contains: "${name}"}`
  }
  return filter
}

const fetchUploadFile = async (first, skip, bucket, type, name) => {
  const fetchFileType = filterUploadedFile(bucket, type, name)
  duck.query({
    query: gql`
        query{
            files(orderBy: createdAt_DESC, first: ${first}, skip: ${first * skip}, filter: {and: [${fetchFileType}]}) {
              id
              name
              uri
              fileBucket
              mimeType
              size
              usageCount
            }
            filesMeta(filter: {and: [${fetchFileType}]}) {
              count
            }
        }
    `,
    type: 'uploadFiles/fetch',
    key: 'uploadFiles'
  })
}

export default fetchUploadFile
