import { Tooltip } from 'antd'
import gql from 'graphql-tag'
import React from 'react'
import { get } from 'lodash'
import moment from 'moment'
import requestToGraphql from '../../../utils/requestToGraphql'

const getUtmuserDetails = async (adminId) => {
  const user = await requestToGraphql(gql`{
  leadPartners(filter: { admins_some: { id: "${adminId}" } }) {
    id
    agents {
      id
      agent{
        id
        name
      }
      countries{
        value
      }
      timezones{
        value
      }
      utmDetails {
        id
        source
        campaign
        medium
        term
        content
      }
    }
  }
}
`)
  return get(user, 'data.leadPartners', [])
}

export const getLeadPartnerAgent = async (agentId) => {
  const user = await requestToGraphql(gql`{
  leadPartnerAgents(filter: { agent_some: { id: "${agentId}" } }) {
    id
    countries{
      value
    }
    timezones{
        value
      }
    utmDetails {
      id
      source
      term
      content
      medium
      campaign
    }
  }
}
`)
  return get(user, 'data.leadPartnerAgents', [])
}

export const fetchLeadAnalyticsData = async (bookingAgentId) => {
  const startOfMonth = moment().startOf('M').startOf('day').toISOString()
  const endOfMonth = moment().endOf('M').endOf('day').toISOString()
  const startOfDay = moment().startOf('day').toISOString()
  const endOfDay = moment().endOf('day').toISOString()
  const data = await requestToGraphql(gql`{
  dayWiseBookings: menteeSessionsMeta(
    filter: {
      and: [
        { createdAt_gte: "${startOfDay}" }
        { createdAt_lte: "${endOfDay}" }
        { user_some: { verificationStatus: verified } }
        { bookingAgent_some: { id: "${bookingAgentId}" } }
        { topic_some: { order: 1 } }
      ]
    }
  ) {
    count
  }
  monthWiseBookings: menteeSessionsMeta(
    filter: {
      and: [
        { createdAt_gte: "${startOfMonth}" }
        { createdAt_lte: "${endOfMonth}" }
        { user_some: { verificationStatus: verified } }
        { bookingAgent_some: { id: "${bookingAgentId}" } }
        { topic_some: { order: 1 } }
      ]
    }
  ) {
    count
  }
  dayWiseConductions: mentorMenteeSessionsMeta(
    filter: {
      and: [
        { createdAt_gte: "${startOfDay}" }
        { createdAt_lte: "${endOfDay}" }
        { bookingAgent_some: { id: "${bookingAgentId}" } }
        { topic_some: { order: 1 } }
        { sessionStatus: completed }
      ]
    }
  ) {
    count
  }
  monthWiseConductions: mentorMenteeSessionsMeta(
    filter: {
      and: [
        { createdAt_gte: "${startOfMonth}" }
        { createdAt_lte: "${endOfMonth}" }
        { bookingAgent_some: { id: "${bookingAgentId}" } }
        { topic_some: { order: 1 } }
        { sessionStatus: completed }
      ]
    }
  ) {
    count
  }
  leadPartnerAgents(filter:{ agent_some:{ id:"${bookingAgentId}" } }){
    id
    dayWiseBooking
    dayWiseConduction
    monthlyBooking
    monthlyConduction
  }
}`)
  const analyticsData = get(data, 'data', {})
  get(data, 'data.leadPartnerAgents', []).forEach(agent => {
    if ((get(agent, 'dayWiseBooking') || get(agent, 'dayWiseConduction')
      || get(agent, 'monthlyBooking') || get(agent, 'monthlyConduction'))
      && !get(analyticsData, 'agent')) {
      analyticsData.agent = agent
    }
  })
  return analyticsData
}

export const calculatePercentage = (num, den = 0) => {
  if (den === 0) {
    return {
      value: 0,
      color: 'rgb(253, 52, 90)',
      actualValue: 0,
    }
  }
  const value = ((num / den) * 100)
  let color = ''
  if (value > 80) {
    color = '#d6f1b7'
  } else if (value < 80 && value > 30) {
    color = 'rgb(255, 242, 217)'
  } else {
    color = 'rgb(253, 52, 90)'
  }
  return {
    value: `${value.toFixed(1)} %`,
    actualValue: value,
    color,
  }
}

export const getUtmDetails = async () => {
  const utmDetails = await requestToGraphql(gql`{
  utmDetails(orderBy: createdAt_DESC) {
    id
    source
    content
    campaign
    term
    medium
  }
}
`)
  return get(utmDetails, 'data.utmDetails', [])
}

export const statusKeysFromSession = [
  {
    name: 'knowCoding',
    displayName: 'Already knows programming basics',
  },
  {
    name: 'lookingForAdvanceCourse',
    displayName: 'Was looking for advance course',
  },
  {
    name: 'ageNotAppropriate',
    displayName: 'Age not appropriate',
  },
  {
    name: 'notRelevantDifferentStream',
    displayName: 'Not relevant',
  },
  {
    name: 'noPayingPower',
    displayName: 'No paying power'
  },
  {
    name: 'notInterestedInCoding',
    displayName: 'Not interested in coding(just for sake)',
  },
  {
    name: 'learningAptitudeIssue',
    displayName: 'Severe aptitude issues(struggling to learn, unfit)',
  }
]

const rescheduledReasons = [
  { name: 'internetIssue', displayName: 'Slow/Unstable internet',  },
  { name: 'zoomIssue', displayName: 'Zoom not installed',  },
  { name: 'laptopIssue', displayName: 'No laptop - Joined over the phone',  },
  { name: 'chromeIssue', displayName: 'Google chrome not installed',  },
  { name: 'powerCut', displayName: 'Power Cut',  },
  {
    name: 'notResponseAndDidNotTurnUp',
    displayName: "No response and didn't turn up",
    
  },
  {
    name: 'turnedUpButLeftAbruptly',
    displayName: 'Turned up but left abruptly',
    
  },
  {
    name: 'leadNotVerifiedProperly',
    displayName: 'Lead is not verified properly',
    
  },
  { name: 'otherReasonForReschedule', displayName: 'Other reasons',  }
]

export const getKeysValue = (bookingDetails = {}, checkFromSales = false, courseTitle) => {
  const keysValue = []
  Object.keys(bookingDetails).forEach(key => {
    let findKey = rescheduledReasons.find(status => status.name === key)
    if (checkFromSales) {
      findKey = statusKeysFromSession.find(status => status.name === key)
    }
    if (findKey && bookingDetails[findKey.name]) {
      keysValue.push(findKey.displayName)
    }
  })
  return {
    Element: getToolTips(keysValue, courseTitle),
    valueLength: keysValue.length
  }
}

export const getToolTips = (keys = [], courseTitle = '') => {
  const pStyle = { margin: 0, width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block' }
  const courseValue = courseTitle ? <span style={{ fontStyle: 'bold' }} >{courseTitle}</span> : ''
  keys = courseValue ? [courseValue, ...keys] : keys
  if (keys.length <= 3) {
    return keys.map(key => <span style={pStyle}>{key},</span>)
  } else if (keys.length > 3) {
    return (
      <Tooltip title={keys.map(key => <span style={pStyle}>{key},</span>)} placement='left' >
        {keys.slice(0, 3).map(key => <span style={pStyle}>{key},</span>)}
      </Tooltip>
    )
  }
}

export const getMentorRating = (mentorInfo) => {
  let ratingNum = 0;
  let ratingDen = 0;
  if (mentorInfo) {
    Object.keys(mentorInfo).forEach((key) => {
      if (key.includes('pythonCourseRating') && mentorInfo[key] > 0) {
        const ratingValue = key.split('pythonCourseRating')[1];
        ratingNum += ratingValue * mentorInfo[key];
        ratingDen += mentorInfo[key];
      }
    });
    if (ratingNum > 0 && ratingDen > 0) {
      return (ratingNum / ratingDen).toFixed(2)
    }
    return 'NA'
  }
  return 'NA'
}


export default getUtmuserDetails
