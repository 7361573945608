import React, { useEffect, useState } from 'react'
import ModalContainer from './FilterModal.style'
import filterSvg from '../../../../assets/filter.svg'
import fetchCoursesWithFilter from '../../../../actions/CoursePackageMaker/fetchCoursesWithFilter'
import { Input } from 'antd'
const { Search } = Input;

const filters = [
    {
        lable: 'Min Grade',
        key: 'minGrade',
        children: ['Select All', 'Grade 1', 'Grade 2', 'Grade 3', 'Grade 4', 'Grade 5', 'Grade 6', 'Grade 7', 'Grade 8', 'Grade 9', 'Grade 10', 'Grade 11', 'Grade 12']
    },
    {
        lable: 'Max Grade',
        key: 'maxGrade',
        children: ['Select All', 'Grade 1', 'Grade 2', 'Grade 3', 'Grade 4', 'Grade 5', 'Grade 6', 'Grade 7', 'Grade 8', 'Grade 9', 'Grade 10', 'Grade 11', 'Grade 12']
    },
    {
        lable: 'Course Type',
        key: 'courseType',
        children: ['Select All', 'Theory', 'Tools', 'Programming']
    },
    {
        lable: 'Courses',
        key: 'courses',
        children: ['Select All', 'Python', 'Java', 'Web Development']
    },
    // {
    //     lable: 'School',
    //     key: 'school'
    // },
]

function FilterModal(props) {
    const { showFilterModal } = props
    const [currentFilter, setCurrentFilter] = useState(filters[0])
    const [allFilters, setAllFilters] = useState({})
    const [courseSearchString, setCourseSearchString] = useState('')
    const [fetchedCourses, setFetchedCourses] = useState([])
    const [noCourse, setNoCourse] = useState('')

    useEffect(() => {
        setInitialState()
    }, [])

    const setInitialState = () => {
        const updateFilter = {}
        filters.forEach(filter => {
            updateFilter[filter.key] = []
        })
        setAllFilters(updateFilter)
    }

    const handleFilterChange = (filter) => {
        const updateFilter = allFilters
        const key = currentFilter.key
        if (filter === 'Select All') {
            if (updateFilter[key].includes(filter)) {
                updateFilter[key] = []
            } else {
                updateFilter[key] = key === 'courses' ? fetchedCourses : currentFilter.children
            }
        } else {
            if (updateFilter[key].includes(filter)) {
                updateFilter[key] = updateFilter[key].filter(item => item !== filter)
            } else {
                updateFilter[key] = [...allFilters[key], filter]
            }
        }
        setAllFilters({...updateFilter})
    }

    const handleFilterCheck = (filter) => {
        if (Object.keys(allFilters).length > 0 && allFilters[currentFilter.key].length > 0) {
            return allFilters[currentFilter.key].includes(filter)
        }
    }

    const onCourseSearch = async () => {
        if (courseSearchString === '') {
            setFetchedCourses([])
        } else {
            await fetchCoursesWithFilter(courseSearchString).then(res => {
                if(res && res.length > 0) {
                    const tempArray = ['Select All']
                    res.forEach(item => {
                        tempArray.push(item.title)
                    })
                    setFetchedCourses(tempArray)
                    setNoCourse('')
                } else {
                    setFetchedCourses([])
                    setNoCourse('No Course Found')
                }
            })
        }
    }

    return (
        <ModalContainer
            visible={showFilterModal}
            onCancel={() => props.closeFilterModal()}
            footer={null}
        >
            <ModalContainer.ModalTopContainer>
                <span style={{ backgroundImage: `url(${filterSvg})` }}></span>
                <h4>Select Filter</h4>
            </ModalContainer.ModalTopContainer>
            <ModalContainer.ModalFiltersContainer>
                <ModalContainer.ModalFiltersLeft>
                    {filters.map(filter => (
                        <h4
                            style={{ backgroundColor: filter.key === currentFilter.key ? '#fff' : null }}
                            onClick={() => {
                                setCurrentFilter(filter)
                            }}
                        >{filter.lable}</h4>
                    ))}
                </ModalContainer.ModalFiltersLeft>
                <ModalContainer.ModalFiltersRight>
                    {(currentFilter.key !== 'school' && currentFilter.key !== 'courses') ? currentFilter.children.map(filter => (
                        <ModalContainer.FilterSelect
                            onChange={() => handleFilterChange(filter)}
                            checked={handleFilterCheck(filter)}
                        >
                        {filter}</ModalContainer.FilterSelect>
                    )) : (
                        <>
                        {currentFilter.key === 'courses' && (
                            <>
                                <Search
                                    placeholder="search courses"
                                    style={{ width: 230, marginBottom: '20px' }}
                                    onSearch={() => onCourseSearch()}
                                    onChange={(e) => setCourseSearchString(e.target.value)}
                                />
                                {noCourse && noCourse.length &&
                                <p
                                    style={{ textAlign: 'center', marginRight: '20px', color: '#999' }}
                                >
                                    {noCourse}
                                </p>}
                                {fetchedCourses && fetchedCourses.length > 0 && fetchedCourses.map(filter => (
                                    <ModalContainer.FilterSelect
                                        onChange={() => handleFilterChange(filter)}
                                        checked={handleFilterCheck(filter)}
                                    >
                                    {filter}</ModalContainer.FilterSelect>
                                ))}
                            </>
                        )}
                        {/* {currentFilter.key === 'school' && (
                            <p>school</p>
                        )} */}
                        </>
                    )}
                </ModalContainer.ModalFiltersRight>
            </ModalContainer.ModalFiltersContainer>
            <ModalContainer.ModalButtonsContainer>
                <ModalContainer.ModalClearButton
                    onClick={() => setInitialState()}
                >Clear Filters</ModalContainer.ModalClearButton>
                <ModalContainer.ModalConfirmButton
                    onClick={() => props.applyFilters(allFilters)}
                >Apply</ModalContainer.ModalConfirmButton>
            </ModalContainer.ModalButtonsContainer>
        </ModalContainer>
    )
}

export default FilterModal