import { Button, Input } from 'antd'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { updateContentProject } from '../../../actions/contentMaker'
import MainModal from '../../../components/MainModal'
import restrictedNumverValues from '../../../constants/restrictedNumberValues'

const IframePreview = ({ openIframePreview = false, onCloseModal = () => { }, iframeViewData, keyValue, usedFor }) => {
  const isUsedForAnswerFormat = usedFor && usedFor === 'answerFormatDescription'
  const [embedViewHeight, setEmbedViewHeight] = useState(100)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const onModalClose = () =>{
    setEmbedViewHeight(100)
    onCloseModal()
  }
  useEffect(() =>{
    if (openIframePreview && (get(iframeViewData, 'embedViewHeight') || get(iframeViewData, 'answerFormatViewHeight'))) {
      if (isUsedForAnswerFormat) setEmbedViewHeight(get(iframeViewData, 'answerFormatViewHeight'))
      else setEmbedViewHeight(get(iframeViewData, 'embedViewHeight'))
    }
  }, [openIframePreview])
  const onInputKeyDown = (e) => {
    if (restrictedNumverValues.includes(e.key)) {
      e.preventDefault()
    }
  }
  const handleSubmit = async () =>{
    if (embedViewHeight) {
      setIsSubmiting(true)
      const input = {}
      if (isUsedForAnswerFormat) input['answerFormatViewHeight'] = Number(embedViewHeight)
      else input['embedViewHeight'] = Number(embedViewHeight)
      await updateContentProject({
        input,
        key: keyValue,
        projectId: get(iframeViewData, 'id'),
      })
      setIsSubmiting(false)
      onModalClose()
    }
  }
  return (
    <MainModal
        visible={openIframePreview}
        title={'Docs Preview'}
        onCancel={onModalClose}
        maskClosable={false}
        width='80%'
        centered
        destroyOnClose
        footer={null}
      >
      <div style={{ marginBottom: '20px' }}>
        <span>Enter Document Height:</span>
        <Input type={'number'} value={embedViewHeight} onKeyDown={onInputKeyDown} onChange={({ target:{ value } }) => setEmbedViewHeight(value)} style={{ width: '30%', margin: '0 20px' }} />
        <Button type='primary' onClick={handleSubmit} loading={isSubmiting}>Submit</Button>
      </div>
      <iframe
        style={{ height: `${embedViewHeight}px`, width: '75vw' }}
        id='externalDescriptionForPractice'
        src={isUsedForAnswerFormat ? get(iframeViewData, 'answerFormatDescription') : get(iframeViewData, 'projectCreationDescription')}
        title='External description'
      />
    </MainModal>
  )
}

export default IframePreview