/* eslint-disable no-unused-vars */
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import Earnings from './Earnings'
import withMentorDashboardNav from '../../../components/withUpdatedNav'
import injectProps from '../../../components/injectProps'
import { MENTOR_DASHBOARD } from '../../../constants/navItemsConst'

const EarningsSideNav = withMentorDashboardNav(Earnings)({
  title: 'Mentor Dashboard',
  activeNavItem: 'Earnings',
  showUMSNavigation: true,
  renderNavItems: MENTOR_DASHBOARD
})

// const mapStateToProps = state => ({
//   ismentorBatchesEarningsFetched: state.data.getIn([
//     'mentorBatchEarnings',
//     'fetchStatus',
//     'mentorEarningsForMentorDashboardEarnings',
//     'success'
//   ])
// })


const EarningsSideNavWithExtraProps = injectProps({
  notification
})(EarningsSideNav)
export default connect()(withRouter(EarningsSideNavWithExtraProps))
