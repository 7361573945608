import React from 'react';
import ReactDOM from 'react-dom';
import {get} from 'lodash';
import { Button } from 'antd';
import moment from 'moment';
import FullCalendar from '../../../../components/FullCalendar';
import  {CalendarContainer, ModalContainer, StyledWrapper} from './scheduleTab.style';
import ScheduleWokingOrEventDay from './component/ScheduleWokingOrEventDay';
import fetchBatchCodes from './../../../../actions/assignTime/fetchBatchCodes';
import fetchTimeTableSchedule from './../../../../actions/timeTableSchedule/fetchTimeTableSchedule';
import { getSelectedSlots } from './../../../DemandSupplyDashboard/demandSupply-utils/index';
import { getSlotLabel } from './../../../DemandSupplyDashboard/demandSupply-utils/timeFunctions';

const getTimeRangeFromSession = (bookingDate, sessionStartingTime) => {
  bookingDate = new Date(bookingDate).toDateString()
  const startTime = `${bookingDate}, ${sessionStartingTime}`
  return { startTime }
}
const ScheduleTab = ({chosenSchoolId, 
  schoolsList=[],
  addTimeTableScheduleSuccessStatus,
  addTimeTableScheduleLoadingStatus,
  addTimeTableSchedulErrorStatus,
  addTimeTableScheduleErrorMessage,
  updateTimeTableScheduleSuccessStatus,
  updateTimeTableScheduleLoadingStatus,
  updateTimeTableSchedulErrorStatus,
  updateTimeTableScheduleErrorMessage}) => {
  const [showModal,setShowModal] = React.useState(false)
  const [selectedLevel,setSelectedLevel] = React.useState('school');
  const [isEventBtnClicked, setIsEventBtnClicked] = React.useState(false)
  const [batchCodes,setBatchCodes] = React.useState([]);
  const [fetchedEvents, setFetchedEvents] = React.useState([]);
  const [allScheduledEvents, setAllScheduledEvents] = React.useState([]);
  const chosenSchoolName = schoolsList.find(school => school.id === chosenSchoolId);
  React.useEffect(() => {
      const fetchBatchCodesAction = async () => {
        const res = await fetchBatchCodes('', `{school_some:{
          id:"${chosenSchoolId}"
        }}`);
        const batches = res.batches.map(batch => ({id:batch.id, code:batch.code}))
        setBatchCodes(batches);
      }
      if (chosenSchoolId) {
        fetchBatchCodesAction();
      }
  },[chosenSchoolId]);
  // effect to fetch school's existing schedule
  const fetchSchoolTimeTableSchedule = async () => {
    const res  = await fetchTimeTableSchedule(chosenSchoolId, selectedLevel);
    const scheduledEvents = get(res, 'timetableSchedules', [])
    setAllScheduledEvents(scheduledEvents)
    const events = [];
    scheduledEvents.forEach(event => {
      const type = get(event, 'type', '');
      const slots = getSelectedSlots(event);
      if (type=="workingDay"){
        const startDate = get(event, 'startDate', '');
        const endDate = get(event, 'endDate', '');
        const currentMoment = moment(startDate);
        const endMoment = moment(endDate).add(1, 'days');
        while (currentMoment.isBefore(endMoment, 'day')) {
          if (get(event, `${moment(currentMoment).format('dddd').toLowerCase()}`,false)) {
            if (slots.length > 0) {
              const { startTime } = getSlotLabel(slots[0].split('slot')[1]);
              const {startTime:sessionStartTime} = getTimeRangeFromSession(currentMoment, startTime);
              events.push({
                id: `${moment(currentMoment).format('YYYY-MM-DD')}`,
                title: 'Working Day',
                date: new Date(sessionStartTime),
                color: '#40A9FF', 
                textColor: 'white',
                allDay: false
              });
            }
          }
          currentMoment.add(1, 'days');
        }
      }
      if (type=="event"){
        const startDate = get(event, 'startDate', '');
        const endDate = get(event, 'endDate', '');
        const currentMoment = moment(startDate);
        const endMoment = moment(endDate).add(1, 'days');
        while (currentMoment.isBefore(endMoment, 'day')) {
          if (get(event, `${moment(currentMoment).format('dddd').toLowerCase()}`,false)) {
            if (slots.length > 0) {
              const { startTime } = getSlotLabel(slots[0].split('slot')[1]);
              const {startTime:sessionStartTime} = getTimeRangeFromSession(currentMoment, startTime);
              events.push({
                id: `${moment(currentMoment).format('YYYY-MM-DD')}`,
                title: get(event, 'eventType', 'Event'),
                date: new Date(sessionStartTime),
                color: 'grey', 
                textColor: 'white',
                allDay: false 
              });
            }
          }
          currentMoment.add(1, 'days');
        }
      }
    })
    setFetchedEvents(events);
  }
  React.useEffect(() => {
      if (chosenSchoolId) {
        fetchSchoolTimeTableSchedule()
      }
  },[chosenSchoolId]);

  React.useEffect(() => {
      if (chosenSchoolId && addTimeTableScheduleSuccessStatus) {
        fetchSchoolTimeTableSchedule()
      }
  },[addTimeTableScheduleSuccessStatus]);
  React.useEffect(() => {
      if (chosenSchoolId && updateTimeTableScheduleSuccessStatus) {
        fetchSchoolTimeTableSchedule()
      }
  },[updateTimeTableScheduleSuccessStatus]);
  React.useEffect(() => {
    fetchSchoolTimeTableSchedule()
  },[selectedLevel]);
    return (
      <>
        <Button type={selectedLevel==="school" ? "primary":""} style={{ marginRight: '30px' }}
          onClick={() => setSelectedLevel("school")}>School Level</Button>
        <Button type={selectedLevel==="batch" ? "primary":""}
          onClick={() => setSelectedLevel("batch")}>Batch Level</Button>
        <CalendarContainer>
          <StyledWrapper>
            <FullCalendar
              calendarHeight="600px"
              initialCalendarView="dayGridMonth"
              customButtons={{
                workingDays: {
                    text: 'Working Days  +',
                    click: ()=>{
                      setShowModal(true)
                      setIsEventBtnClicked(false)
                    },
                },
                addEvents: {
                    text: 'Add Event  +',
                    click: ()=>{
                      setShowModal(true)
                      setIsEventBtnClicked(true)
                    },
                },
            }}
            fetchedEvents={[...fetchedEvents,{timeFormat: 'H(:mm)'}]}
            customHeaderToolBar = {{
              start: 'prev,next title today',
              center: 'timeGridWeek,dayGridMonth,timeGridDay,listMonth',
              right: 'workingDays,addEvents',
            }}
            />
          </StyledWrapper>
        </CalendarContainer>


         <ScheduleWokingOrEventDay
            closeModal={()=>{
                setShowModal(false)
                setIsEventBtnClicked(false)
            }}
            showModal={showModal}
            chosenSchool={chosenSchoolName ? chosenSchoolName.name : ''}
            batchCodes={batchCodes}
            chosenSchoolId={chosenSchoolId}
            isModalEventType={isEventBtnClicked}
            selectedLevel={selectedLevel}
            fetchStatus={{
              loading:addTimeTableScheduleLoadingStatus,
              success:addTimeTableScheduleSuccessStatus,
              error:addTimeTableSchedulErrorStatus,
              errorMessage:addTimeTableScheduleErrorMessage
            }}
            updateStatus={{
              loading:updateTimeTableScheduleLoadingStatus,
              success:updateTimeTableScheduleSuccessStatus,
              error:updateTimeTableSchedulErrorStatus,
              errorMessage:updateTimeTableScheduleErrorMessage
            }}
            allScheduledEvents={allScheduledEvents}
        /> 

      </>
    );
}
export default ScheduleTab;