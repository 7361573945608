import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../../duck'

const addMentorMenteeSession = async (
  mentorSessionConnectedId,
  menteeSessionConnectedId,
  input,
  topicConnectedId,
  courseConnectId,
  mentorAvailabilitySlotConnectId,
) => duck.query({
  query: gql`
    mutation($input:MentorMenteeSessionInput!) {
      addMentorMenteeSession(input: $input, mentorSessionConnectId: "${mentorSessionConnectedId}", 
      menteeSessionConnectId: "${menteeSessionConnectedId}",topicConnectId: "${topicConnectedId}",
      ${courseConnectId ? `courseConnectId: "${courseConnectId}"` : ''}
      ${mentorAvailabilitySlotConnectId ? `mentorAvailabilitySlotConnectId:"${mentorAvailabilitySlotConnectId}"` : ''}
      ) {
        id
        sessionStartDate
        sessionEndDate
        sessionStatus
        studentProfile{
          id
        }
        mentorSession {
          id
          user{
            id
            name
            profilePic {
              id
              uri
            }
            mentorProfile {
              id
              experienceYear
              pythonCourseRating5
              pythonCourseRating4
              pythonCourseRating3
              pythonCourseRating2
              pythonCourseRating1
            }
          }
        }
        menteeSession {
          id
        }
      }
    }`,
  variables: {
    input,
  },
  type: 'mentorMenteeSessionsForDemand/add',
  key: 'mentorMenteeSessionsForDemand',
  changeExtractedData: (extractedData, originalData) => {
    if (originalData && get(originalData, 'addMentorMenteeSession')) {
      extractedData.menteeSession = []
      extractedData.user = []
      extractedData.completedSession = []
      extractedData.mentorMenteeSessionsForDemand = {
        ...get(originalData, 'addMentorMenteeSession')
      }
      return { ...extractedData }
    }
    return { ...extractedData }
  }
})

export default addMentorMenteeSession
