const MENTOR_DASHBOARD = 'mentorDashboard'
const TEACHER_TRAINING_DASHBOARD = 'teacherTrainingDashboard'

const DEMAND_SUPPLY_DASHBOARD = 'demandSupplyDashboard'

const EVENT_DASHBOARD = 'eventDashboard'


export {
  MENTOR_DASHBOARD,
  DEMAND_SUPPLY_DASHBOARD,
  EVENT_DASHBOARD,
  TEACHER_TRAINING_DASHBOARD
}
