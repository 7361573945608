import gql from 'graphql-tag'
import { MENTOR } from '../../constants/roles'
import duck from '../../duck'

const fetchClassroomTeachers = async ({ schoolId }) =>
    duck.query({
        query: gql`{
        users(
            filter: {
                and: [
                    { role: ${MENTOR} }
                    { secondaryRole: schoolTeacher }
                    { mentorProfile_some: { schools_some: { id: "${schoolId}" } } }
                ]
            }
        ) {
            id
            name
        }
    }`,
        type: 'users/fetch',
        key: 'classroomTeachers',
    })

export default fetchClassroomTeachers
