import React, { Component } from 'react';
import { DivFlexStyle, Errorline, RequiredStyle, StyledInput, StyledInputDiv, StyledSelect } from '../TeachersTab.styles';
import NameANdImageHeader from '../Constant/NameANdImageHeader';
import Loader1 from "../../../../../assets/Loader1.png"
import NotDone from "../../../../../assets/NotDone.png"
import BImage from "../../../../../assets/BImage.png"
import Line from "../../../../../assets/Line.png"
import { Form, notification, Select } from 'antd';
import COUNTRY_CODES from '../../../../../constants/countryCodes';
export default class TeacherDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            NameError: 0,
            EmailError: 0,
            PhoneError: 0,
            setPasswordError: 0,
            confirmPasswordError: 0,
            SelectGradesError: 0,
            AccessTypeError: 0,
            CountryCodeError: 0,
            gradesOptions: [],
        }
    }
    componentDidMount = () => {
        const {  classGrades } = this.props;
        let gOption = [];
        let c = 0;
        for (let i = 0; i < classGrades.length; i++) {
            if (classGrades[i].sections !== undefined) {
                for (let j = 0; j < classGrades[i].sections.length; j++) {
                    gOption = [...gOption, { index: c++, id: classGrades[i].sections[j].id, data: classGrades[i].grade + ' ' + classGrades[i].sections[j].section }]
                }
            }
            else {
                    gOption= [...gOption,{index: c++, id: classGrades[i].id, data: classGrades[i].grade + ' ' + classGrades[i].section}]
            }
        }
        this.setState({
            gradesOptions: gOption,
        })
    }
    componentDidUpdate = async (prevProps) => {
        const { ErrorObject, mentorProfileRowData } = this.props;

        if (prevProps.ErrorObject !== ErrorObject) {
            this.setState({
                NameError: ErrorObject.NameError,
                EmailError: ErrorObject.EmailError,
                PhoneError: ErrorObject.PhoneError,
                setPasswordError: ErrorObject.setPasswordError,
                confirmPasswordError: ErrorObject.confirmPasswordError,
                SelectGradesError: ErrorObject.SelectGradesError,
                AccessTypeError: ErrorObject.AccessTypeError,
                NameError: mentorProfileRowData.Name === '' ? 1 : 0,
                EmailError: mentorProfileRowData.Email === '' ? 1 : 0,
                PhoneError: mentorProfileRowData.Phone === '' ? 1 : 0,
                setPasswordError: mentorProfileRowData.setPassword === '' ? 1 : 0,
                confirmPasswordError: mentorProfileRowData.confirmPassword === '' ? 1 : 0,
                SelectGradesError: mentorProfileRowData.SelectGrades.length===0 ? 1 : 0,
                AccessTypeError: mentorProfileRowData.AccessType === '' ? 1 : 0,
            })
        }
       
    }
    NameChange = (name) => {
        const { schoolName, onNext, maxTabAccesed, goBackOnNext, classGrades, setMentorProfileRowData, mentorProfileRowData } = this.props;

        const obj = {
            Name: name,
            Email: mentorProfileRowData.Email,
            Phone: mentorProfileRowData.Phone,
            setPassword: mentorProfileRowData.setPassword,
            confirmPassword: mentorProfileRowData.confirmPassword,
            SelectGradesId: mentorProfileRowData.SelectGradesId,
            SelectGrades: mentorProfileRowData.SelectGrades,
            AccessType: mentorProfileRowData.AccessType,
            CountryCode: mentorProfileRowData.CountryCode,
            displayName: mentorProfileRowData.displayName,

            SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
            SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
            ReschedulingClass: mentorProfileRowData.ReschedulingClass,
            SchedulingClass: mentorProfileRowData.SchedulingClass,
            DeleteSessions: mentorProfileRowData.DeleteSessions,

            CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
            SendNotices: mentorProfileRowData.SendNotices,
            AddInviteStudent: mentorProfileRowData.AddInviteStudent,
            AccessRecordings: mentorProfileRowData.AccessRecordings,
            LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

            AccessCourseControl: mentorProfileRowData.AccessCourseControl,
            CreateTests: mentorProfileRowData.CreateTests,
            EvaluateTests: mentorProfileRowData.EvaluateTests,
            AddToQBanks: mentorProfileRowData.AddToQBanks,

            AccessReports: mentorProfileRowData.AccessReports,
            DownloadReports: mentorProfileRowData.DownloadReports,
            ShareReports: mentorProfileRowData.ShareReports,
        }
        setMentorProfileRowData(obj)
        if (name.length === 0) {
            this.setState({
                NameError: 1,
            })
        }
        else {
            this.setState({
                NameError: 0,
            })
        }

    }
    EmailChange = (email) => {
        const {  setMentorProfileRowData, mentorProfileRowData } = this.props;
        const obj = {
            Name: mentorProfileRowData.Name,
            Email: email,
            Phone: mentorProfileRowData.Phone,
            setPassword: mentorProfileRowData.setPassword,
            confirmPassword: mentorProfileRowData.confirmPassword,
            SelectGradesId: mentorProfileRowData.SelectGradesId,
            SelectGrades: mentorProfileRowData.SelectGrades,
            AccessType: mentorProfileRowData.AccessType,
            CountryCode: mentorProfileRowData.CountryCode,
            displayName: mentorProfileRowData.displayName,

            SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
            SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
            ReschedulingClass: mentorProfileRowData.ReschedulingClass,
            SchedulingClass: mentorProfileRowData.SchedulingClass,
            DeleteSessions: mentorProfileRowData.DeleteSessions,

            CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
            SendNotices: mentorProfileRowData.SendNotices,
            AddInviteStudent: mentorProfileRowData.AddInviteStudent,
            AccessRecordings: mentorProfileRowData.AccessRecordings,
            LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

            AccessCourseControl: mentorProfileRowData.AccessCourseControl,
            CreateTests: mentorProfileRowData.CreateTests,
            EvaluateTests: mentorProfileRowData.EvaluateTests,
            AddToQBanks: mentorProfileRowData.AddToQBanks,

            AccessReports: mentorProfileRowData.AccessReports,
            DownloadReports: mentorProfileRowData.DownloadReports,
            ShareReports: mentorProfileRowData.ShareReports,
        }
        setMentorProfileRowData(obj)
        setMentorProfileRowData(obj)
        if (email.length === 0) {
            this.setState({
                EmailError: 1,
            })
        }
        else {
            this.setState({
                EmailError: 0,
            })
        }
    }
    PhoneChange = (phone) => {
        const {  setMentorProfileRowData, mentorProfileRowData } = this.props;
        let PhoneValidatedObj='';
        for(var i=0;i<10;i++){
            if(!isNaN(phone[i])){
                PhoneValidatedObj=PhoneValidatedObj+phone[i];
            }
        }
        const obj = {
            Name: mentorProfileRowData.Name,
            Email: mentorProfileRowData.Email,
            Phone: PhoneValidatedObj,
            setPassword: mentorProfileRowData.setPassword,
            confirmPassword: mentorProfileRowData.confirmPassword,
            SelectGradesId: mentorProfileRowData.SelectGradesId,
            SelectGrades: mentorProfileRowData.SelectGrades,
            AccessType: mentorProfileRowData.AccessType,
            CountryCode: mentorProfileRowData.CountryCode,
            displayName: mentorProfileRowData.displayName,

            SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
            SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
            ReschedulingClass: mentorProfileRowData.ReschedulingClass,
            SchedulingClass: mentorProfileRowData.SchedulingClass,
            DeleteSessions: mentorProfileRowData.DeleteSessions,

            CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
            SendNotices: mentorProfileRowData.SendNotices,
            AddInviteStudent: mentorProfileRowData.AddInviteStudent,
            AccessRecordings: mentorProfileRowData.AccessRecordings,
            LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

            AccessCourseControl: mentorProfileRowData.AccessCourseControl,
            CreateTests: mentorProfileRowData.CreateTests,
            EvaluateTests: mentorProfileRowData.EvaluateTests,
            AddToQBanks: mentorProfileRowData.AddToQBanks,

            AccessReports: mentorProfileRowData.AccessReports,
            DownloadReports: mentorProfileRowData.DownloadReports,
            ShareReports: mentorProfileRowData.ShareReports,
        }
        setMentorProfileRowData(obj)
        if (phone.length === 0) {
            this.setState({
                PhoneError: 1,
            })
        }
        else {
            this.setState({
                PhoneError: 0,
            })
        }
    }
    SetPasswordChange = (password) => {
        const {  setMentorProfileRowData, mentorProfileRowData } = this.props;
        const obj = {
            Name: mentorProfileRowData.Name,
            Email: mentorProfileRowData.Email,
            Phone: mentorProfileRowData.Phone,
            setPassword: password,
            confirmPassword: mentorProfileRowData.confirmPassword,
            SelectGradesId: mentorProfileRowData.SelectGradesId,
            SelectGrades: mentorProfileRowData.SelectGrades,
            AccessType: mentorProfileRowData.AccessType,
            CountryCode: mentorProfileRowData.CountryCode,
            displayName: mentorProfileRowData.displayName,

            SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
            SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
            ReschedulingClass: mentorProfileRowData.ReschedulingClass,
            SchedulingClass: mentorProfileRowData.SchedulingClass,
            DeleteSessions: mentorProfileRowData.DeleteSessions,

            CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
            SendNotices: mentorProfileRowData.SendNotices,
            AddInviteStudent: mentorProfileRowData.AddInviteStudent,
            AccessRecordings: mentorProfileRowData.AccessRecordings,
            LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

            AccessCourseControl: mentorProfileRowData.AccessCourseControl,
            CreateTests: mentorProfileRowData.CreateTests,
            EvaluateTests: mentorProfileRowData.EvaluateTests,
            AddToQBanks: mentorProfileRowData.AddToQBanks,

            AccessReports: mentorProfileRowData.AccessReports,
            DownloadReports: mentorProfileRowData.DownloadReports,
            ShareReports: mentorProfileRowData.ShareReports,
        }
        setMentorProfileRowData(obj)
        if (password.length === 0) {
            this.setState({
                setPasswordError: 1,
            })
        }
        else {
            this.setState({
                setPasswordError: 0,
            })
        }
    }
    ConfirmPasswordChange = (ConfirmPassword) => {
        const {  setMentorProfileRowData, mentorProfileRowData } = this.props;
        const obj = {
            Name: mentorProfileRowData.Name,
            Email: mentorProfileRowData.Email,
            Phone: mentorProfileRowData.Phone,
            setPassword: mentorProfileRowData.setPassword,
            confirmPassword: ConfirmPassword,
            SelectGradesId: mentorProfileRowData.SelectGradesId,
            SelectGrades: mentorProfileRowData.SelectGrades,
            AccessType: mentorProfileRowData.AccessType,
            CountryCode: mentorProfileRowData.CountryCode,
            displayName: mentorProfileRowData.displayName,

            SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
            SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
            ReschedulingClass: mentorProfileRowData.ReschedulingClass,
            SchedulingClass: mentorProfileRowData.SchedulingClass,
            DeleteSessions: mentorProfileRowData.DeleteSessions,

            CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
            SendNotices: mentorProfileRowData.SendNotices,
            AddInviteStudent: mentorProfileRowData.AddInviteStudent,
            AccessRecordings: mentorProfileRowData.AccessRecordings,
            LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

            AccessCourseControl: mentorProfileRowData.AccessCourseControl,
            CreateTests: mentorProfileRowData.CreateTests,
            EvaluateTests: mentorProfileRowData.EvaluateTests,
            AddToQBanks: mentorProfileRowData.AddToQBanks,

            AccessReports: mentorProfileRowData.AccessReports,
            DownloadReports: mentorProfileRowData.DownloadReports,
            ShareReports: mentorProfileRowData.ShareReports,
        }
        setMentorProfileRowData(obj);
        if (ConfirmPassword.length === 0) {
            this.setState({
                confirmPasswordError: 1,
            })
        }
        else {
            this.setState({
                confirmPasswordError: 0,
            })
        }
    }
    SelectGradesChange = (selectGradesArray) => {
        const {  setMentorProfileRowData, mentorProfileRowData } = this.props;
        const { gradesOptions } = this.state;

        let selectGradeId = [], selectGrades = [];
        selectGradesArray.map((gOption) => {
                selectGradeId = [...selectGradeId,gOption.key ];
                selectGrades = [...selectGrades, gOption.label];
        })

        let selectGradeIdUnique=[...new Set(selectGradeId)];
        let selectGradesUnique=[...new Set(selectGrades)];

        const obj = {
            Name: mentorProfileRowData.Name,
            Email: mentorProfileRowData.Email,
            Phone: mentorProfileRowData.Phone,
            setPassword: mentorProfileRowData.setPassword,
            confirmPassword: mentorProfileRowData.confirmPassword,
            SelectGradesId: selectGradeIdUnique,
            SelectGrades: selectGradesUnique,
            AccessType: mentorProfileRowData.AccessType,
            CountryCode: mentorProfileRowData.CountryCode,
            displayName: mentorProfileRowData.displayName,

            SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
            SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
            ReschedulingClass: mentorProfileRowData.ReschedulingClass,
            SchedulingClass: mentorProfileRowData.SchedulingClass,
            DeleteSessions: mentorProfileRowData.DeleteSessions,

            CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
            SendNotices: mentorProfileRowData.SendNotices,
            AddInviteStudent: mentorProfileRowData.AddInviteStudent,
            AccessRecordings: mentorProfileRowData.AccessRecordings,
            LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

            AccessCourseControl: mentorProfileRowData.AccessCourseControl,
            CreateTests: mentorProfileRowData.CreateTests,
            EvaluateTests: mentorProfileRowData.EvaluateTests,
            AddToQBanks: mentorProfileRowData.AddToQBanks,

            AccessReports: mentorProfileRowData.AccessReports,
            DownloadReports: mentorProfileRowData.DownloadReports,
            ShareReports: mentorProfileRowData.ShareReports,
        }
        setMentorProfileRowData(obj);
        if (selectGradesArray.length === 0) {
            this.setState({
                SelectGradesError: 1,
            })
        }
        else {
            this.setState({
                SelectGradesError: 0,
            })
        }
    }
    AccessTypeChange = (accessType) => {
        const {  setMentorProfileRowData, mentorProfileRowData } = this.props;
        const obj = {
            Name: mentorProfileRowData.Name,
            Email: mentorProfileRowData.Email,
            Phone: mentorProfileRowData.Phone,
            setPassword: mentorProfileRowData.setPassword,
            confirmPassword: mentorProfileRowData.confirmPassword,
            SelectGradesId: mentorProfileRowData.SelectGradesId,
            SelectGrades: mentorProfileRowData.SelectGrades,
            AccessType: accessType,
            CountryCode: mentorProfileRowData.CountryCode,
            displayName: mentorProfileRowData.displayName,

            SchedulingLearningClass: accessType === 'teacher' ? 0 : mentorProfileRowData.SchedulingLearningClass,
            SchedulingAdHocClass: accessType === 'teacher' ? 0 : mentorProfileRowData.SchedulingAdHocClass,
            ReschedulingClass: accessType === 'teacher' ? 0 : mentorProfileRowData.ReschedulingClass,
            SchedulingClass: accessType === 'teacher' ? 0 : mentorProfileRowData.SchedulingClass,
            DeleteSessions: accessType === 'teacher' ? 1 : 0,

            CreatingAClassroom: accessType === 'teacher' ? 1 : 0,
            SendNotices: accessType === 'teacher' ? 0 : mentorProfileRowData.SendNotices,
            AddInviteStudent: accessType === 'teacher' ? 1 : 0,
            AccessRecordings: accessType === 'teacher' ? 0 : mentorProfileRowData.AccessRecordings,
            LiveSessionProgress: accessType === 'teacher' ? 0 : mentorProfileRowData.LiveSessionProgress,

            AccessCourseControl: accessType === 'teacher' ? 0 : mentorProfileRowData.AccessCourseControl,
            CreateTests: accessType === 'teacher' ? 0 : mentorProfileRowData.CreateTests,
            EvaluateTests: accessType === 'teacher' ? 0 : mentorProfileRowData.EvaluateTests,
            AddToQBanks: accessType === 'teacher' ? 0 : mentorProfileRowData.AddToQBanks,

            AccessReports: accessType === 'teacher' ? 0 : mentorProfileRowData.AccessReports,
            DownloadReports: accessType === 'teacher' ? 0 : mentorProfileRowData.DownloadReports,
            ShareReports: accessType === 'teacher' ? 0 : mentorProfileRowData.ShareReports,
        }
        setMentorProfileRowData(obj);
        if (accessType === '') {
            this.setState({
                AccessTypeError: 1,
            })
        }
        else {
            this.setState({
                AccessTypeError: 0,
            })
        }
    }
    CountryCodeChange = (dial_code_index) => {
        const {  setMentorProfileRowData, mentorProfileRowData } = this.props;
        const obj = {
            Name: mentorProfileRowData.Name,
            Email: mentorProfileRowData.Email,
            Phone: mentorProfileRowData.Phone,
            setPassword: mentorProfileRowData.setPassword,
            confirmPassword: mentorProfileRowData.confirmPassword,
            SelectGradesId: mentorProfileRowData.SelectGradesId,
            SelectGrades: mentorProfileRowData.SelectGrades,
            AccessType: mentorProfileRowData.AccessType,
            CountryCode: COUNTRY_CODES[dial_code_index].dial_code,
            displayName: mentorProfileRowData.displayName,

            SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
            SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
            ReschedulingClass: mentorProfileRowData.ReschedulingClass,
            SchedulingClass: mentorProfileRowData.SchedulingClass,
            DeleteSessions: mentorProfileRowData.DeleteSessions,

            CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
            SendNotices: mentorProfileRowData.SendNotices,
            AddInviteStudent: mentorProfileRowData.AddInviteStudent,
            AccessRecordings: mentorProfileRowData.AccessRecordings,
            LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

            AccessCourseControl: mentorProfileRowData.AccessCourseControl,
            CreateTests: mentorProfileRowData.CreateTests,
            EvaluateTests: mentorProfileRowData.EvaluateTests,
            AddToQBanks: mentorProfileRowData.AddToQBanks,

            AccessReports: mentorProfileRowData.AccessReports,
            DownloadReports: mentorProfileRowData.DownloadReports,
            ShareReports: mentorProfileRowData.ShareReports,
        }
        setMentorProfileRowData(obj);
        if (dial_code_index === '') {
            this.setState({
                CountryCodeError: 1,
            })
        }
        else {
            this.setState({
                CountryCodeError: 0,
            })
        }
    }
    DisplayNameChange = (displayName) => {
        const {  setMentorProfileRowData, mentorProfileRowData } = this.props;
        const obj = {
            Name: mentorProfileRowData.Name,
            Email: mentorProfileRowData.Email,
            Phone: mentorProfileRowData.Phone,
            setPassword: mentorProfileRowData.setPassword,
            confirmPassword: mentorProfileRowData.confirmPassword,
            SelectGradesId: mentorProfileRowData.SelectGradesId,
            SelectGrades: mentorProfileRowData.SelectGrades,
            AccessType: mentorProfileRowData.AccessType,
            CountryCode: mentorProfileRowData.CountryCode,
            displayName: displayName,

            SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
            SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
            ReschedulingClass: mentorProfileRowData.ReschedulingClass,
            SchedulingClass: mentorProfileRowData.SchedulingClass,
            DeleteSessions: mentorProfileRowData.DeleteSessions,

            CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
            SendNotices: mentorProfileRowData.SendNotices,
            AddInviteStudent: mentorProfileRowData.AddInviteStudent,
            AccessRecordings: mentorProfileRowData.AccessRecordings,
            LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

            AccessCourseControl: mentorProfileRowData.AccessCourseControl,
            CreateTests: mentorProfileRowData.CreateTests,
            EvaluateTests: mentorProfileRowData.EvaluateTests,
            AddToQBanks: mentorProfileRowData.AddToQBanks,

            AccessReports: mentorProfileRowData.AccessReports,
            DownloadReports: mentorProfileRowData.DownloadReports,
            ShareReports: mentorProfileRowData.ShareReports,
        }
        setMentorProfileRowData(obj)
        setMentorProfileRowData(obj)
    }
    render() {
        const { schoolName, onNext, maxTabAccesed, goBackOnNext,  mentorProfileRowData,passWordValidationOnEdit, modal2Visible = false } = this.props;

        return (
            <>
                <NameANdImageHeader schoolName={schoolName} Loader={Loader1} Loader1={NotDone} Loader2={NotDone} Loader3={NotDone} BImage={BImage} Line={Line} onNext={onNext} goBackOnNext={value => goBackOnNext(value)} maxTabAccesed={maxTabAccesed} modal2Visible={modal2Visible} />
                <div style={{ margin: "20px 0px" }}>
                    <DivFlexStyle style={{ marginBottom: this.state.NameError === 1 ? "3px" : "22px" }}>
                        <div>Enter Name</div>
                        <StyledInputDiv><StyledInput name="name" type="text" placeholder='Enter Name' value={mentorProfileRowData.Name} pattern='[a-zA-Z ]{3,}' required onChange={(e) => { this.NameChange(e.target.value) }} autoComplete="off"></StyledInput></StyledInputDiv>
                    </DivFlexStyle>
                    {this.state.NameError === 1 ? (<>
                        <Errorline></Errorline>
                        <RequiredStyle >*required</RequiredStyle>
                    </>) : ''}
                    <DivFlexStyle style={{ marginBottom: this.state.EmailError === 1 ? "3px" : "22px" }}>
                        <div>Email</div>
                        <StyledInputDiv><StyledInput type="email" placeholder='Enter Email' value={mentorProfileRowData.Email} onChange={(e) => { this.EmailChange(e.target.value) }} autoComplete="off"></StyledInput></StyledInputDiv>
                    </DivFlexStyle>
                    {this.state.EmailError === 1 ? (<>
                        <Errorline></Errorline>
                        <RequiredStyle >*required</RequiredStyle>
                    </>) : ''}
                    <DivFlexStyle style={{ marginBottom: this.state.PhoneError === 1 ? "3px" : "22px" }}>
                        <div>Phone</div>
                        <StyledInputDiv style={{ display: 'flex' }}>
                            <StyledSelect style={{ width: "37%" }}
                                value={mentorProfileRowData.CountryCode}
                                onChange={(e) => { this.CountryCodeChange(e) }}>
                                {
                                    COUNTRY_CODES.map((country, i) => (
                                        <Select.Option key={i} value={i}>
                                            {country.dial_code}
                                        </Select.Option>
                                    ))
                                }
                            </StyledSelect>
                            <StyledInput style={{
                                width: "60%",
                                marginLeft: "9px",
                            }} type="tel" placeholder='Enter Phone' value={mentorProfileRowData.Phone} onChange={(e) => { this.PhoneChange(e.target.value) }} autoComplete="off"></StyledInput>
                        </StyledInputDiv>
                    </DivFlexStyle>
                    {this.state.PhoneError === 1 ? (<>
                        <Errorline style={{ marginLeft: "61.5%" }}></Errorline>
                        <RequiredStyle style={{ marginLeft: "61.5%" }}>*required</RequiredStyle>
                    </>) : ''}
                    <DivFlexStyle style={{ marginBottom: this.state.setPasswordError === 1 && passWordValidationOnEdit===0 ? "3px" : "22px" }}>
                        <div>Set Password</div>
                        <StyledInputDiv><StyledInput type="password" placeholder='Enter Password' value={mentorProfileRowData.setPassword} onChange={(e) => { this.SetPasswordChange(e.target.value) }} autoComplete="new-password"></StyledInput></StyledInputDiv>
                    </DivFlexStyle>
                    {this.state.setPasswordError === 1 && passWordValidationOnEdit===0? (<>
                        <Errorline></Errorline>
                        <RequiredStyle >*required</RequiredStyle>
                    </>) : ''}
                    <DivFlexStyle style={{ marginBottom: this.state.confirmPasswordError === 1 && passWordValidationOnEdit===0? "3px" : "22px" }}>
                        <div>Confirm Password</div>
                        <StyledInputDiv><StyledInput type="password" placeholder='Re-enter Password' value={mentorProfileRowData.confirmPassword} onChange={(e) => { this.ConfirmPasswordChange(e.target.value) }} autoComplete="off"></StyledInput></StyledInputDiv>
                    </DivFlexStyle>
                    {this.state.confirmPasswordError === 1 && passWordValidationOnEdit===0? (<>
                        <Errorline></Errorline>
                        <RequiredStyle >*required</RequiredStyle>
                    </>) : ''}
                    <DivFlexStyle style={{ marginBottom: this.state.SelectGradesError === 1 ? "3px" : "22px" }}>
                        <div>Select Grades</div>
                        <StyledInputDiv><StyledSelect labelInValue mode='multiple'  placeholder='Select Grades' 
                        value={mentorProfileRowData.SelectGrades.map((grades,i)=>{
                            return {key:mentorProfileRowData.SelectGradesId[i] , label: mentorProfileRowData.SelectGrades[i]}
                        })} 
                        onChange={(e) => { this.SelectGradesChange(e) }} autoComplete="off">
                            {
                                this.state.gradesOptions.map((go, i) => {
                                    return <Select.Option key={go.id} value={go.id}>{go.data}</Select.Option>
                                })
                            }
                        </StyledSelect></StyledInputDiv>
                    </DivFlexStyle>
                    {this.state.SelectGradesError === 1 ? (<>
                        <Errorline></Errorline>
                        <RequiredStyle >*required</RequiredStyle>
                    </>) : ''}
                    <DivFlexStyle style={{ marginBottom: this.state.AccessTypeError === 1 ? "3px" : "22px" }}>
                        <div>Access Type</div>
                        <StyledInputDiv><StyledSelect placeholder='Select Type' value={mentorProfileRowData.AccessType === '' ? 'Select Type' : mentorProfileRowData.AccessType} onChange={(e) => { this.AccessTypeChange(e) }} autoComplete="off">
                            <Select.Option value="teacher">Teacher</Select.Option>
                            <Select.Option value="custom">Custom</Select.Option>
                        </StyledSelect></StyledInputDiv>
                    </DivFlexStyle>
                    {this.state.AccessTypeError === 1 ? (<>
                        <Errorline></Errorline>
                        <RequiredStyle >*required</RequiredStyle>
                    </>) : ''}
                    <DivFlexStyle style={{ marginBottom: "22px" }}>
                        <div>Display Name</div>
                        <StyledInputDiv><StyledInput type="text" placeholder='Enter Display Name' value={mentorProfileRowData.displayName} onChange={(e) => { this.DisplayNameChange(e.target.value) }} autoComplete="off"></StyledInput></StyledInputDiv>
                    </DivFlexStyle>
                </div>
            </>
        )
    }
}
