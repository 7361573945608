import gql from 'graphql-tag'
import { get } from 'lodash'
import moment from 'moment'
import requestToGraphql from '../../../../utils/requestToGraphql'
import getSlotNames from '../../../../utils/slots/slot-names'

const getMentorMenteeSessionData = async (date) => {
  const data = await requestToGraphql(gql`
    {
    availableSlots(filter: { and: [{ date: "${date}" }] }, orderBy: date_ASC) {
      date
      ${getSlotNames()}
    }
    }`)
  return data
}

export const getMentorAvailabilitySlotsForDemand = async (date) => {
  const data = await requestToGraphql(gql`{
  mentorAvailabilitySlots(filter: {
    and:[
      { date_gte: "${moment(date).startOf('day').toISOString()}" }
      { date_lte: "${moment(date).endOf('day').toISOString()}" }
    ]
  }) {
    id
    slotName
    date
    count
    menteeSessionsMeta {
      count
    }
    mentorSessionsMeta {
      count
    }
    batchSessions {
      id
      batch {
        id
        type
      }
    }
  }
}
`)
  let availability = {}
  get(data, 'data.mentorAvailabilitySlots').forEach(slot => {
    const batchSessions = get(slot, 'batchSessions', []).filter(session => get(session, 'batch') && get(session, 'batch.type') !== 'b2b') || []
    const occupied = batchSessions.length + get(slot, 'menteeSessionsMeta.count', 0)
    if (get(slot, 'count', 0) > occupied) {
      availability = {
        ...availability,
        [get(slot, 'slotName')]: get(slot, 'count', 0) - occupied
      }
    }
  })
  return availability
}

export const getMentorSessions = async (date, sessionType, userId) => {
  const data = await requestToGraphql(gql`
    {
    mentorSessions(
      filter: { and: [
      { availabilityDate: "${date}" },
      { sessionType: ${sessionType} }
      { user_some: { id: "${userId}" } }] }
    ) {
      id
    }
  }
  `)
  return get(data, 'data.mentorSessions')
}


export default getMentorMenteeSessionData
