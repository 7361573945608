import { ADMIN, SUPPLY_DEMAND_ADMIN, UMS_ADMIN } from './roles'

const TIME_SUB_FOR_SESSION = 1

const TIME_ADD_FOR_LEAD_PARTNER = 3

const ALLOWED_ROLES_MANUAL_SESSIONS = [ADMIN, UMS_ADMIN, SUPPLY_DEMAND_ADMIN]

export {
  ALLOWED_ROLES_MANUAL_SESSIONS,
  TIME_SUB_FOR_SESSION, TIME_ADD_FOR_LEAD_PARTNER
}
