import gql from 'graphql-tag'
import duck from '../../duck'
import getIdArrForQuery from '../../utils/getIdArrForQuery'

const addMentorToSenseiTeam = async (teamId, mentorIds = []) => {
  duck.query({
    query: gql`
    mutation{
      updateSenseiMentorTeam(id:"${teamId}", ${mentorIds.length > 0 ? `mentorsConnectIds: [${getIdArrForQuery(mentorIds)}]` : ''}){
        id
      }
    }
    `,
    type: 'senseiTeams/update',
    key: 'senseiTeams',
    changeExtractedData: (extracted) => ({
      ...extracted
    })
  })
}

export default addMentorToSenseiTeam
