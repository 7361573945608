import styled from 'styled-components'

const ClassContentStyle = styled.div`
    height: 100%;
    width: 100%;
    margin: 0 auto;
    font-family: 'Inter' !important;
`
const TopContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-bottom: 30px;
`
const Classes = styled.div`
    color: #212121
    padding-right: 20px;
    font-size: 16px;
`
const TotalClasses = styled.div`
    background-color: #F2F2F2;
    font-size: 12px;
    color: #616161;
    padding: 4px 8px;
    border-radius: 4px;
`
const TopicImageDiv = styled.div`
    margin-right: 25px;
    border-radius: 8px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EEEEEE;
`

const CheckBox = styled.div`
    border: 1px solid #cec4c4;
    margin-right: 25px;
    border-radius: 8px;
    width: 70px;
    height: 70px;
    position: relative;
`

const Tickmark = styled.img`
    width: 33px;
    height: 33px;
    left: 25%;
    top: 25%;
    position: absolute;
`

const GreyShade = styled.div`
    position: absolute;
    background-color: #212121;
    opacity: 64%;
    width: 100%;
    height: 100%;
    border-radius: 8px;
`
const TopicImage = styled.img`
    width: 46px;
    heigth: 52px;
`
const chapterList = styled.div`
    overflow-y: scroll;
    height: ${props => props.teacherTraining ? '70vh' : '300px'};
    & ::-webkit-scrollbar {
        width: 10px;
      }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; 
      }
    &::-webkit-scrollbar-thumb {
        background: #8C61CB;
        border-radius: 10px;
      }
    &::-webkit-scrollbar-thumb:hover {
        background: #621dc9;
        border-radius: 10px;
      }
`

const Box = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    padding: 10px;
    margin: 20px;
    border-radius: 8px;
    background: ${props => (props.active === true ? '#FFFFFF' : '#FAFAFA')};
    box-shadow: ${props => (props.active === true ? '0px 0px 12px rgba(208, 208, 208, 0.6);' : '')};
    cursor: pointer;
`

ClassContentStyle.TopicImageDiv = TopicImageDiv
ClassContentStyle.CheckBox = CheckBox
ClassContentStyle.Tickmark = Tickmark
ClassContentStyle.GreyShade = GreyShade
ClassContentStyle.TopicImage = TopicImage
ClassContentStyle.TopContainer = TopContainer
ClassContentStyle.Classes = Classes
ClassContentStyle.TotalClasses = TotalClasses
ClassContentStyle.chapterList = chapterList
ClassContentStyle.Box = Box


export default ClassContentStyle
