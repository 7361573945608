import gql from "graphql-tag";

import duck from "../../duck";

const addLabInspection = async (input, inspectionByConnectId, schoolConnectId) => {
    return duck.query({
        query: gql`
        mutation {
        addLabInspection(
            input: {
            labConfiguration: {
                totalNumberOfComputers: ${input.totalNumberOfComputers}
                totalNumberOfWorkingComputers: ${input.totalNumberOfWorkingComputers}
                projectInteractivePanel: ${input.projectInteractivePanel}
                speakers: ${input.speakers}
                powerBackup: ${input.powerBackup}
                powerBackupType: ${input.powerBackupType}
                internetConnection: ${input.internetConnection}
            }
            labName: "${input.labName}"
            inspectionDate: "${input.inspectionDate}"
            }
            inspectionByConnectId: "${inspectionByConnectId}"
            schoolConnectId: "${schoolConnectId}"
        ) {
            id
            labName
        }
        }
    `,
        variables: input,
        type: "labInspections/add",
        key: "labInspections",
    })
};

export default addLabInspection;