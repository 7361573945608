import gql from 'graphql-tag'
import duck from '../../duck'

const deleteUtmDetails = async (id) => duck.query({
  query: gql`
  mutation {
    deleteUtmDetail(id: "${id}") {
      id
    }
  }  
    `,
  type: 'utm/delete',
  key: 'utm',
})

export default deleteUtmDetails
