/* eslint-disable react/jsx-pascal-case */
import React, { Component } from 'react'
import { sortBy, get } from 'lodash'
import tickmark from '../../../assets/tickmark.png'
import topicIcon from '../../../assets/topicIcon.png'
import ClassContentStyle from './ClassContent.style'
import getFullPath from '../../../utils/getFullPath'


class ClassContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      topics: []
    }
  }
  componentDidUpdate= (prevProps) => {
    if (prevProps !== this.props) {
      this.getTopics()
    }
  }

  getTopics = () => {
    let { topics } = this.props
    topics = sortBy(topics, 'order')
    this.setState({ topics })
  }
  handleClick = (topic) => {
    this.props.handleSelectedTopic(topic)
  }
  render() {
    const { topics } = this.state
    const { currentTopicOrder } = this.props
    return (
      <div style={{ background: 'white', marginTop: '30px', width: '40%' }}>
        
        <ClassContentStyle.TopContainer >
          <ClassContentStyle.Classes>Classes &nbsp;</ClassContentStyle.Classes>
          <ClassContentStyle.TotalClasses>
            <img src={topicIcon} alt='' style={{ paddingRight: '10px' }} />Total Classes {topics.length}
          </ClassContentStyle.TotalClasses>
        </ClassContentStyle.TopContainer >

        <ClassContentStyle.chapterList teacherTraining={this.props.teacherTraining}>
          {topics.map((t, key) => {
            const url = (get(t, 'thumbnailSmall')) === null ? '' : getFullPath(get(t, 'thumbnailSmall.uri'))
            return (
              <ClassContentStyle.Box
                onClick={() => { this.handleClick(t) }}
                active={t.order === currentTopicOrder}
                role='presentation'
                style={t.order === this.props.selectedTopicOrder ? {
                  backgroundColor: 'rgba(140, 97, 203, 0.1)',
                  border: '2px solid rgb(140, 97, 203)',
                  transition: '0.3s all ease-in-out',
                } : {
                  border: '2px solid rgba(140, 97, 203, 0)',
                  transition: '0.2s all ease-in-out',
                }}
              >
                { t.order < currentTopicOrder ? (
                  <ClassContentStyle.CheckBox>
                    <img src={url} alt='' style={{ width: '46px', height: '52px', left: '18%', top: '13%', position: 'absolute' }} />
                    <ClassContentStyle.GreyShade />
                    <ClassContentStyle.Tickmark src={tickmark} alt='' />
                  </ClassContentStyle.CheckBox>
                ) : (
                  <ClassContentStyle.TopicImageDiv style={{ backgroundImage: url }}>
                    <ClassContentStyle.TopicImage src={url} alt='' />
                  </ClassContentStyle.TopicImageDiv>
                )}
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                  <div style={{ fontSize: '12px ' }}>Class <span style={{ color: 'black', fontWeight: '550' }} >{key + 1}</span></div>
                  <div style={{ color: 'black' }}>{t.title}</div>
                </div>
              </ClassContentStyle.Box >
            )
              }
          )
        }
        </ClassContentStyle.chapterList>
      </div>
    )
  }
}
export default ClassContent
