import gql from 'graphql-tag'
import duck from '../../duck'

const fetchMentorDashboardCourses = async () =>
  duck.query({
    query: gql`
    {
        courses(filter:{
          and:[
            {
              status: published
            }
            {
              documentationUrl_exists: true
            }
          ]
        }) {
          id
          title
          description
          status
          order
          minGrade
          maxGrade
          secondaryCategory
          documentationUrl
          thumbnail {
            id
            uri
          }
          createdAt
          chaptersMeta{
            count
          }
          topicsMeta{
            count
          }
        }
    }
    `,
    type: 'mentorDashboardCourses/fetch',
    key: 'fetchMentorDashboardCourses',
  })

export default fetchMentorDashboardCourses

