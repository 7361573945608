/* eslint-disable*/
import React from 'react'
import './payments.scss'
import PaymentCard from './components/paymentCard'

export default function Payments({paymentReports}) {
    return (
        <>
        {paymentReports.length > 0 && paymentReports.map((paymentReport, index) => (
           <PaymentCard paymentReport={paymentReport}/>
        ))}
        </>
    )
}
