import React from 'react'
import moment from 'moment'
import { get } from 'lodash'
import { TagTab } from '../EventSettings.style'
import { StyledVariablesTable } from "../EventSettings.style"

class Variables extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      columns: [],
      tabType:'Variable'
    }
  }

  componentDidMount = () => {
    this.sessionOnMount()
  }
  
  sessionOnMount = () => {
    const columns = [
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        render: (_id, _record, index) => (this.props.page + index + 1)
      },
      {
        title: 'Variable Name (WATI)',
        dataIndex: 'whatsappVariableName',
        key: 'whatsappVariableName',
        align: 'center',
        render: (whatsappVariableName) => (
          <TagTab.TagNameContainer>{whatsappVariableName}</TagTab.TagNameContainer>
        )
      },
      {
        title: 'Variable Name (Email)',
        dataIndex: 'emailVariableName',
        key: 'emailVariableName',
        align: 'center',
        render: (emailVariableName) => ( emailVariableName && 
          <TagTab.TagNameContainer>{emailVariableName}</TagTab.TagNameContainer>
        )
      },
      {
        title: 'Data Field',
        dataIndex: 'dataField',
        key: 'dataField',
        align: 'center',
        render: (dataField) => (dataField)
      },
      {
        title: 'Created On',
        dataIndex: 'createdAt',
        key: 'createdAt',
        align: 'center',
        render: (createdOn) => moment(createdOn).format('Do MMM YY') + `'`
      },
      {
        title: 'Created By',
        dataIndex: 'createdBy',
        key: 'createdBy',
        align: 'center',
        render: (createdBy) => get(createdBy,'name')
      },
      {
        title: 'Events Used In',
        dataIndex: 'eventsMeta',
        key: 'eventsMeta',
        align: 'center',
        render: (eventsMeta) => get(eventsMeta, 'count') || 0
      }
    ]
    this.setState({ 
      columns: columns
    })
  }

  render() {
    const { columns } = this.state
    const { data, isLoading } = this.props
    return <>
      <StyledVariablesTable
        columns={columns}
        dataSource={data}
        rowKey={"id"}
        bordered={false}
        loading={isLoading}
        scroll={{ x: 'max-content' }}
        pagination={false}
      />
    </>
  }
}

export default Variables