import React, {useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CommentOutlined } from "@ant-design/icons";
import { get } from "lodash";
import { Button, Table, Typography } from "antd";
import { SectionButton } from "../../SchoolOnBoarding.style";
import fetchLabInspections from "../../../../actions/SchoolOnboarding/fetchLabInspections";
import Stack from "../../../../components/Stack";
import NoComputer from "./NoComputer";
import AddLabModal from "./AddLabModal";
import StartInspectionModal from "./StartInspectionModal";
import fetchInspectors from "../../../../actions/SchoolOnboarding/fetchInspectors";
import { FormContextProvider } from "./FormContext";
import CommentsModal from "./CommentsModal";
import fetchComments from "../../../../actions/SchoolOnboarding/fetchComments";
import GreenTickIcon from "./GreenTickIcon";
import RedCrossIcon from "./RedCrossIcon";
import fetchLabs from "../../../../actions/SchoolOnboarding/fetchLabs";
import UploadFileModal from "./UploadFileModal";
import DownLoadLabInspectionReport from "./DownLoadLabInspectionReport";

const LabInspection = (props) => {
	const params = useParams();

	// active lab state
	const [activeLab, setActiveLab] = useState("");

	// add lab modal open state
	const [addLabModalOpen, setAddLabModalOpen] = useState(false);

	// start inspection modal open state
	const [startInspectionModalOpen, setStartInspectionModalOpen] = useState(
		false
	);

	// comments modal
	const [commentsModalOpen, setCommentsModalOpen] = useState(false);
	const [uploadFileModalOpen ,setUploadFileModalOpen] = useState(false);
	// lab inspection modal
	const [openLabInspectionDownloadModal, setOpenLabInspectionDownloadModal] = useState(false);
	// convert to JS
	let labInspections = props.labInspections && props.labInspections.toJS();

	const labInspectionsFetchStatus =
		props.labInspectionsFetchStatus && props.labInspectionsFetchStatus.toJS();
	
	const fetchData = async () => {
		await fetchLabInspections(params.schoolId);
		await fetchInspectors();
		await fetchComments(params.schoolId);
		await fetchLabs(params.schoolId);
	};
	useEffect(() => {
		if (!params.schoolId) return;
		fetchData();
	}, [params.schoolId]);

	let content = null;
	if (!labInspections || !labInspectionsFetchStatus) return null;

	labInspections.sort((a, b) => {
		return new Date(b.createdAt) - new Date(a.createdAt);
	});

	// loop over labInspections, for each labInspection, make an array of systems
	const systems = [];
	labInspections.forEach((labInspection) => {
		if (!labInspection.systems) return;
		const labInspectionId = labInspection.id;
		const labInspectionName = labInspection.labName;
		const labInspectionlabNo = labInspection.labNo;
		labInspection.systems.forEach((system) => {
			systems.push({
				...system,
				labInspectionId,
				labInspectionName,
				labInspectionlabNo,
			});
		});
	});

	labInspections = labInspections.map((labInspection) => { 
		if (labInspection && labInspection.labConfiguration) {
			return {
				...labInspection,
				...labInspection.labConfiguration
			};
		}
		return labInspection;
	});

	// for each system, loop over inspectionChecks, for each inspectionCheck, create add it to basicChecks or inspectionChecks
	let inspectionChecks = [];
	let basicChecks = [];
	systems.forEach((system) => {
		system.inspectionChecks.forEach((inspectionCheck) => {
			const columnExists =
				inspectionChecks.find((e) => e.name === inspectionCheck.name) ||
				basicChecks.find((e) => e.name === inspectionCheck.name);
			if (!columnExists) {
				if (inspectionCheck.type === "basic") {
					basicChecks.push(inspectionCheck);
				} else {
					inspectionChecks.push(inspectionCheck);
				}
			}
		});
	});

	// make nestedColumns for the table using the inspectionChecks and basicChecks
	let nestedColumns = [...basicChecks, ...inspectionChecks].map(
		(inspectionCheck) => ({
			title: inspectionCheck.name,
			key: inspectionCheck.name,
			width: 200,
			align: "center",
			render: (text, record) => {
				const recordDetails = record.inspectionChecks.find(
					(e) => e.name === inspectionCheck.name
				);
				if (!recordDetails) return "-";

				if (
					recordDetails.type === "firewall" ||
					recordDetails.type === "softwareApplication" ||
					recordDetails.type === "software" ||
					recordDetails.type === "manual"
				) {
					if (recordDetails.status === "compatible") {
						return <GreenTickIcon />;
					}
					return <RedCrossIcon />;
				}

				// check for Google Chrome
				if (recordDetails.name === 'chrome') {
					if (recordDetails.status === "incompatible") { return <RedCrossIcon />; }
					else {
						return (
							<div style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}>
								{recordDetails && recordDetails.spec ? recordDetails.spec : ""}
								<GreenTickIcon />
							</div>
						)
					}
				}

				return (
					<Typography.Text>
						{recordDetails && recordDetails.spec ? recordDetails.spec : "-"}
					</Typography.Text>
				);
			},
		})
	);

	// add the nestedColumns for the serialNo and status
	nestedColumns.unshift({
		title: "Date",
		key: "updatedAt",
		dataIndex: "updatedAt",
		width: 175,
		align: "center",
		render: (date) => {
			return new Date(date) ? new Date(date).toLocaleString() : date;
		}
	});
	nestedColumns.unshift({
		title: "Lab Name",
		key: "labInspectionName",
		dataIndex: "labInspectionName",
		width: 150,
		align: "center",
	});
	nestedColumns.unshift({
		title: "Sr. No.",
		key: "serialNo",
		dataIndex: "serialNo",
		width: 75,
		align: "center",
	});

	nestedColumns.push({
		title: "Inspection Mode",
		key: "inspectionMode",
		width: 150,
		dataIndex: "inspectionMode",
	});
	nestedColumns.push({
		title: "Comments",
		key: "comment",
		width: 150,
		dataIndex: "comment",
		render: (text) => {
			try {
				return decodeURIComponent(text);
			} catch {
				return text;
			}
		}
	});
	nestedColumns.push({
		title: "Unique System Id",
		key: "uniqueDeviceId",
		width: 150,
		dataIndex: "uniqueDeviceId",
	});
	nestedColumns.push({
		title: "Mac Address",
		key: "systemInformation",
		width: 150,
		dataIndex: "systemInformation",
		render: (text) => {
			try {
				const info = JSON.parse(decodeURIComponent(text))
				const macAddress = get(info, 'uuid.macs', []).join(', ')
				console.log("asfasf", macAddress);
				return macAddress || '-'
			} catch {
				return '-';
			}
		}
	});

	nestedColumns.push({
		title: "Status",
		key: "status",
		width: 110,
		fixed: "right",
		dataIndex: "status",
		align: "center",
		render: (text) => {
			if (text === "compatible") {
				return (
					<Typography.Text
						style={{
							color: "#01AA93",
							fontWeight: 500,
						}}
					>
						Qualified
					</Typography.Text>
				);
			}

			return (
				<Typography.Text
					style={{
						color: "#D34B57",
						fontWeight: 500,
					}}
				>
					Failed
				</Typography.Text>
			);
		},
	});

	const columns = [
    {
      title: "Lab No",
      key: "labNo",
      dataIndex: "labNo",
      width: 110,
    },
    {
      title: "Lab Name",
      key: "labName",
      dataIndex: "labName",
      width: 210,
    },
    {
      title: "Total Systems Recorded (Automatic)",
      key: "totalSystems",
      dataIndex: "totalSystems",
      width: 210,
      render: (_, record) => {
        return record && record.systems ? record.systems.length : 0;
      },
    },
    {
      title: "Total Number Of Computers (Manual)",
      key: "totalNumberOfComputers",
      dataIndex: "totalNumberOfComputers",
      width: 310,
    },
    {
      title: "Total Number Of Working Computers (Manual)",
      key: "totalNumberOfWorkingComputers",
      dataIndex: "totalNumberOfWorkingComputers",
      width: 310,
    },
    {
      title: "Project Interactive Panel (Manual)",
      key: "projectInteractivePanel",
      dataIndex: "projectInteractivePanel",
      width: 310,
    },
    {
      title: "Speakers (Manual)",
      key: "speakers",
      dataIndex: "speakers",
      width: 310,
    },
    {
      title: "PowerBackup (Manual)",
      key: "powerBackup",
      dataIndex: "powerBackup",
      width: 310,
    },
    {
      title: "Power Backup Type (Manual)",
      key: "powerBackupType",
      dataIndex: "powerBackupType",
      width: 310,
    },
    {
      title: "internetConnection (Manual)",
      key: "internetConnection",
      dataIndex: "internetConnection",
      width: 310,
    },
    {
      title: "serviceProviderType (Manual)",
      key: "serviceProviderType",
      dataIndex: "serviceProviderType",
      width: 310,
    },
    {
      title: "Internet Speed (MBPS) (Manual)",
      key: "internetSpeed",
      dataIndex: "internetSpeed",
      width: 210,
    },
    {
      title: "Inspection Date",
      key: "inspectionDate",
      dataIndex: "inspectionDate",
      width: 310,
	  render: (_, record) => {
		if (record.inspectionDate && new Date(record.inspectionDate)) {
			return (
				<>
					{new Date(record.inspectionDate).toString()}
				</>
			);
		}
		return <>-</>;
	  }
    },
  ]; 
	
	content = (
		<>
			{
				activeLab && (
					<>
						<h3>Lab Details</h3>
						<Table
							bordered
							columns={columns}
							dataSource={labInspections && labInspections.filter(e => e.labName === activeLab)}
							pagination={false}
							// expandedRowRender={expandedRowRender}
							scroll={{ x: "max-content", y: 580 }}
						/>
					</>
				)
			}
			<h3>Lab Systems</h3>
			<Table
				bordered
				columns={nestedColumns}
				dataSource={(systems && systems.length && activeLab) ? systems.filter(e => get(e, 'labInspectionName') === activeLab) : systems}
				pagination={false}
				// expandedRowRender={expandedRowRender}
				scroll={{ x: "max-content", y: 580 }}
			/>
		</>
	);

	if (labInspectionsFetchStatus.loading) {
		content = <div>Loading...</div>;
	}

	if (labInspectionsFetchStatus.failure) {
		content = <div>Error</div>;
	}

	if (labInspectionsFetchStatus.success && labInspections.length === 0) {
		content = <NoComputer />;
	}
	
	const labs = get(props,'labs',[]).toJS().map(l => ({ label: `${l.labName ? `${l.labName} ` : ''}${l.labNo}`, value: l.id, labNo: l.labNo, labName: l.labName  }))
	return (
		<FormContextProvider>
			<Stack gap={20}>
				<Stack justify="space-between" direction="row" gap={20}>
					{/* LAB FILTER BUTTONS */}
					<Stack direction="row" wrap="wrap" gap={4}>
						<SectionButton
							type={activeLab === "" ? "primary" : "default"}
							onClick={() => {
								setActiveLab("");
							}}
						>
							All
						</SectionButton>
						{labs.map((l) => (
							<SectionButton
								type={
									activeLab === l.labName ? "primary" : "default"
								}
								onClick={() => {
									setActiveLab(l.labName);
								}}
							>
								{l.label.split(' ').join('-')}
							</SectionButton>
						))}
					</Stack>
					<Stack direction="row" gap={10} style={{ alignItems: 'center' }}>
						{labInspections && labInspections.length ? (
							<div>
								Total Labs: {labInspections.length}
							</div>
						) : null}
						{(systems && systems.length) ? (
							<div>
								Total Systems: {systems.length}
							</div>
						) : null}
					</Stack>
					{/* ACTION BUTTONS */}
					<Stack direction="row" gap={10}>
						{/* <SectionButton
							type="tertiary"
							onClick={() => setCommentsModalOpen(true)}abs



						>
							<CommentOutlined />
						</SectionButton> */}
						<Button
							type="tertiary"
							onClick={() => setAddLabModalOpen(true)}
						>
							Add Lab
						</Button>

						<Button
						  	disabled={labInspectionsFetchStatus.loading ||labs.length < 1}
							onClick={() => setUploadFileModalOpen(true)}
							type="primary">
							Upload CSV file
						</Button>
						{/* <SectionButton
							type="primary"
							onClick={() => {
								setStartInspectionModalOpen(true);
							}}
						>
							Inspect Labs
						</SectionButton> */}
						{labInspections && labInspections.length && get(get(props, "school").toJS(), "name") ? (
							<Button
								type="primary"
								onClick={() => setOpenLabInspectionDownloadModal(true)}
							>
								Download Report
							</Button>
						) : null}
					</Stack>
				</Stack>

				{content}
			</Stack>

			<AddLabModal
				open={addLabModalOpen}
				labs={labs}
				handleClose={() => setAddLabModalOpen(false)}
				
			/>
			<UploadFileModal
				open={uploadFileModalOpen}
				openAddModal={() => setAddLabModalOpen(true)}
				handleClose={() => setUploadFileModalOpen(false)}
				labs={labs}
				getSchoolName={props.getSchoolName}
				labInspections={labInspections}
				fetchStatus= {labInspectionsFetchStatus}
				fetchData={fetchData}
			>
				
			</UploadFileModal>

			<StartInspectionModal
				open={startInspectionModalOpen}
				handleClose={() => setStartInspectionModalOpen(false)}
				labs={get(props, "labs", []).toJS().map(l => ({ label: l.labName, value: l.id }))}
				inspectors={get(props, "inspectors", []).toJS()}
				labInspections={labInspections}
			/>

			<CommentsModal
				comments={get(get(props, "school").toJS(), "comments", [])}
				open={commentsModalOpen}
				handleClose={() => setCommentsModalOpen(false)}
			/>
			{get(props, 'schoolsList', []).length ? (
				<DownLoadLabInspectionReport
					open={openLabInspectionDownloadModal}
					handleClose={() => setOpenLabInspectionDownloadModal(false)}
					labInspections={labInspections}
					schoolsList={get(props, 'schoolsList')}
				/>
			) : null}
		</FormContextProvider>
	);
};

export default LabInspection;
