/*eslint-disable*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import Main from './Dropzone.style'
import deleteQuestionBankImage from '../../actions/image/deleteQuestionBankImage'
import questionBank from '../../reducers/questionBank'

class Dropzone extends Component {
  static propTypes = {
    defaultImage: PropTypes.string,
    onDrop: PropTypes.func,
    onClose: PropTypes.func,
    getDropzoneFile: PropTypes.func,
    defaultFile: PropTypes.shape({}),
    selectedImage: PropTypes.object,
    setSelectedImage: PropTypes.func,
    imageUploaded: PropTypes.bool,
    setImageUploaded: PropTypes.func,
    questionBankConnectIds: PropTypes.array,
    setQuestionBankConnectIds: PropTypes.func,
    imageId: PropTypes.string,
    onShouldImage: PropTypes.func,
    onImageUrl: PropTypes.func,
    id: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]).isRequired,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    removeDefaultIcon: PropTypes.bool
  }
  static defaultProps = {
    onDrop: () => { },
    onClose: () => { },
    getDropzoneFile: () => { },
    defaultImage: null,
    defaultFile: null,
    onShouldImage: () => { },
    onImageUrl: () => { },
    id: null,
  }

  state = {
    shouldImage: false,
    imageUrl: '',
    file: null,
    isDefaultImage: false
  }

  setDefaultImage(imageUrl) {
    if (typeof imageUrl === 'object' && imageUrl) {
      imageUrl = window.URL.createObjectURL(imageUrl)
    }
    if (this.props.UploadImageLink === null) {
      this.setState({
        shouldImage: false,
        imageUrl:'',
        isDefaultImage: false
      })
    }
    else {
      this.setState({
        shouldImage: true,
        imageUrl,
        isDefaultImage: true
      })
    }
  }
  componentDidMount() {

    this.onOpen()
    this.imageContainerClassName = this.props.id
      ? this.props.id
      : 'upload-image-container'
  }

  componentDidUpdate(prevProps, prevState) {
    const { getDropzoneFile, onImageUrl, onShouldImage, defaultImage } = this.props
    const isDropzoneChanged =
      this.state.file !== prevState.file ||
      this.state.shouldImage !== prevState.shouldImage
    if (isDropzoneChanged && getDropzoneFile) {
      getDropzoneFile(this.state.file, this.state.shouldImage)
    }
    if (this.state.shouldImage !== prevState.shouldImage) {
      onShouldImage(this.state.shouldImage)
    }
    if ((defaultImage !== prevProps.defaultImage) && this.props.type === 'McqImage') {
      this.onOpen()
    }
    if ((defaultImage !== prevProps.defaultImage) && this.props.type === 'SpeakerImage') {
      if (!defaultImage) {
        this.resetDropzone()
        this.props.resetImage()
      }
    }
    if (this.state.imageUrl !== prevState.imageUrl) {
      onImageUrl(this.state.imageUrl)
    }
    if (prevProps.defaultImage !== this.props.defaultImage
      && this.props.defaultImage) {
      this.setDefaultImage(this.props.defaultImage)
    }
  }

  onOpen = () => {
    const { defaultImage, defaultFile } = this.props
    if (defaultImage) {
      this.setDefaultImage(defaultImage)
    } else {
      this.resetDropzone()
    }

    if (defaultFile) {
      this.setState({ file: defaultFile })
    }
  }

  resetDropzone() {
    this.setState({
      shouldImage: false,
      imageUrl: '',
      file: null,
      isDefaultImage: false
    })
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { onDrop } = this.props
    if (acceptedFiles[0]) {
      this.setState({
        shouldImage: true,
        file: acceptedFiles[0],
        imageUrl: URL.createObjectURL(acceptedFiles[0])
      })
    }
    if (onDrop) {
      onDrop(acceptedFiles, rejectedFiles)
    }
  }

  uploadMouseOut = () => {
    const image =
      document.querySelector(`.${this.imageContainerClassName}`)
    if (image) {
      image.classList.remove('blur')
    }
  }

  uploadMouseOver = () => {
    const image =
      document.querySelector(`.${this.imageContainerClassName}`)
    if (image) {
      image.classList.add('blur')
    }
  }
  onCloseClick = e => {
    const { onDrop, selectedImage, setSelectedImage, imageUploaded, imageId, setImageUploaded, questionBankConnectIds, setQuestionBankConnectIds } = this.props
    this.setState({
      shouldImage: false,
      imageUrl: '',
      file: null,
      isDefaultImage: false,
    })
    if (imageUploaded) {
      console.log(questionBankConnectIds)
      //   let filteredArr = questionBankConnectIds
      //   let removeIndex
      //  filteredArr.map(item=>Object.values(item).map(key=>{
      //     if(key === imageId){
      //       removeIndex = filteredArr.indexOf(item)
      //     }
      //  })) 
      //  filteredArr = filteredArr.filter(item => filteredArr.indexOf(item) !== removeIndex)
      // setQuestionBankConnectIds(filteredArr)
      deleteQuestionBankImage(imageId)
      setImageUploaded(false)
    }
    if (selectedImage) setSelectedImage('')
    if (onDrop) {
      onDrop([], [])
    }
    e.stopPropagation()
    this.props.onClose()
  }

  render() {
    const { onDrop, defaultImage, imageUploaded, removeDefaultIcon, ...rest } = this.props
    const { shouldImage, imageUrl, isDefaultImage } = this.state
    // console.log(imageUrl);
    return (
      <Main {...rest}
        activeClassName='active'
        accept='image/png,image/jpeg,image/gif,image/webp'
        onDrop={this.onDrop}
        shouldImage={shouldImage}
        width={this.props.width}
        height={this.props.height}
      >
        {shouldImage &&
          <Main.ImageContainer imageUrl={imageUrl}
            isDefaultImage={isDefaultImage}
            className={this.imageContainerClassName}
          />}
        {shouldImage &&
          <Main.CloseImage onClick={this.onCloseClick}>
            <Icon type='close' />
          </Main.CloseImage>
        }
        <Main.UploadContainer
          shouldImage={shouldImage}
          onMouseOver={this.uploadMouseOver}
          onMouseOut={this.uploadMouseOut}
        >
          {!removeDefaultIcon && <Main.UploadIcon type='picture' ><svg  fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="#5C55E7">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
            </svg>
          </Main.UploadIcon>}
          {!imageUploaded && <Main.UploadText>{
            this.props.children
              ? this.props.children
              : 'Click to browse or drag and drop your files'
          }
          </Main.UploadText>}
          {imageUploaded && <Main.UploadText>{
            this.props.children
              ? this.props.children
              : 'Click or drag to edit'
          }
          </Main.UploadText>}
        </Main.UploadContainer>
      </Main>
    )
  }
}

export default Dropzone
