import gql from 'graphql-tag'
import duck from '../../duck'
import getPaySlabSlots from '../../utils/getPaySlabSlots'

const fetchMentorSupplyPaySlab = async () =>
  duck.query({
    query: gql`
      {
        mentorSupplyPaySlabs {
            id
            title
            description
            vertical
            ${getPaySlabSlots()}
        }
      }
    `,
    type: 'mentorSupplyPaySlabs/fetch',
    key: 'mentorSupplyPaySlabs',
  })

export default fetchMentorSupplyPaySlab

