import { Tooltip } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import React from 'react'

const calculatePercentage = (num, den = 0) => {
  if (den === 0) {
    return 0
  }
  return ((num / den) * 100).toFixed(1)
}
export const getMentorRating = (mentorProfile) => {
  let ratingNum = 0
  let ratingDen = 0
  if (mentorProfile) {
    Object.keys(mentorProfile).forEach((key) => {
      if (key.includes('pythonCourseRating') && mentorProfile[key] > 0) {
        const ratingValue = key.split('pythonCourseRating')[1]
        ratingNum += ratingValue * mentorProfile[key]
        ratingDen += mentorProfile[key]
      }
    })
    if (ratingNum > 0 && ratingDen > 0) {
      return (ratingNum / ratingDen).toFixed(2)
    }
    return 0
  }
  return 0
}

const preDefLanguages = {
  Python: 'Python',
  JavaScript: 'JavaScript',
  Java: 'Java',
  Csharp: 'C#',
  Swift: 'Swift',
  Cplusplus: 'C++',
}

export const renderCodingLanguage = (mentorProfile) => {
  if (mentorProfile) {
    const dataList = []
    if (get(mentorProfile, 'codingLanguages', []).length > 0) {
      let valueCount = 0
      get(mentorProfile, 'codingLanguages', []).forEach(language => {
        valueCount += 1
        dataList.push(
          <span key={get(preDefLanguages, get(language, 'value'))}>{`${get(preDefLanguages, get(language, 'value'))},`}</span>
        )
      })
      if (valueCount > 3) {
        return (
          <Tooltip
            overlayClassName='styledTooltip-demandEvent'
            title={get(mentorProfile, 'codingLanguages', []).map(language =>
              `${get(preDefLanguages, get(language, 'value'))},`)}
          >
            {dataList.slice(0, 3)}
          </Tooltip>
        )
      } else if (dataList.length === 0) {
        return '-'
      }
      return dataList
    }
  }
  return 'N/A'
}

export const getSelectedSlots = (session) => {
  const selectedSlots = []
  for (const property in session) {
    if (property.startsWith('slot')) {
      if (session[property] === true) {
        selectedSlots.push(property)
      }
    }
  }
  return selectedSlots
}


export const getMentorDataFromBatchSession = (batchSessions, time, sessionData = null) => {
  let showMentor = true
  let batchExistWithEmptyStudent = null
  if (batchSessions && batchSessions.length > 0) {
    batchSessions.forEach(bSession => {
      if (sessionData && get(bSession, 'batch.id') !== get(sessionData, 'batch.id')) {
        const selectedSlots = getSelectedSlots(bSession)
        if (selectedSlots.length > 0 && selectedSlots.includes(`slot${time}`)) {
          const slotTime = moment().set('hours', time).set('minutes', 0)
          let slotTimeDiff = 0
          if (moment(slotTime).isAfter(moment(new Date()))) {
            slotTimeDiff = moment(slotTime).diff(moment(new Date()))
          }
          const duration = moment.duration(moment(slotTimeDiff))
          const hoursValue = Math.floor(duration.asHours())
          if (hoursValue > 2
          || (hoursValue < 2 && get(bSession, 'batch.studentsMeta.count', 0) > 0)) {
            showMentor = false
          } else if (hoursValue < 2 && get(bSession, 'batch.studentsMeta.count', 0) === 0) {
            batchExistWithEmptyStudent = bSession
          }
        }
      } else if (!sessionData) {
        const selectedSlots = getSelectedSlots(bSession)
        if (selectedSlots.length > 0 && selectedSlots.includes(`slot${time}`)) {
          const slotTime = moment().set('hours', time).set('minutes', 0)
          let slotTimeDiff = 0
          if (moment(slotTime).isAfter(moment(new Date()))) {
            slotTimeDiff = moment(slotTime).diff(moment(new Date()))
          }
          const duration = moment.duration(moment(slotTimeDiff))
          const hoursValue = Math.floor(duration.asHours())
          if (hoursValue > 2
          || (hoursValue < 2 && get(bSession, 'batch.studentsMeta.count', 0) > 0)) {
            showMentor = false
          } else if (hoursValue < 2 && get(bSession, 'batch.studentsMeta.count', 0) === 0) {
            batchExistWithEmptyStudent = bSession
          }
        }
      }
    })
  }
  return { showMentor, batchExistWithEmptyStudent }
}

export const getDateDiff = (fromDate, toDate, checkLimit = 1) =>
  moment.duration(moment(toDate).diff(moment(fromDate))).asDays() === checkLimit

export default calculatePercentage
