import gql from 'graphql-tag'
import duck from '../../duck';


const fetchEvents = async ({startDate, endDate, unpublished, curDateLte, curDateGte, perPage, page}) => {
    await duck.query({
        query: gql`
           query {
               eventsMeta(
                filter: {
                 and: [
                     ${startDate ? `{ createdAt_gte: "${startDate}" }` : ""}
                     ${endDate ? `{ createdAt_lte: "${endDate}" }` : ""}
                     ${unpublished ? `{ status: unpublished }` : ""}
                     ${curDateLte ? `{ eventEndTime_lte: "${new Date().toISOString()}" }` : ""}
                     ${curDateGte ? `{ eventStartTime_gte: "${new Date().toISOString()}" }` : ""}
                   ]
                }
            ){
                count
            },
               events(
                   filter: {
                    and: [
                        ${startDate ? `{ createdAt_gte: "${startDate}" }` : ""}
                        ${endDate ? `{ createdAt_lte: "${endDate}" }` : ""}
                        ${unpublished ? `{ status: unpublished }` : ""}
                        ${curDateLte ? `{ eventEndTime_lte: "${new Date().toISOString()}" }` : ""}
                        ${curDateGte ? `{ eventStartTime_gte: "${new Date().toISOString()}" }` : ""}
                      ]
                   }
                   first: ${perPage},
                   skip: ${perPage * (page - 1)},
                   orderBy: createdAt_DESC
               ) {
                   id
                   name
                   type
                   dateType
                   status
                   eventName
                   createdAt
                   eventEndTime
                   eventStartTime
                   speakers {
                       id
                       user {
                           id
                           name
                       }
                   }
                   registeredUsersMeta {
                       count
                   }
                   eventTimeTableRule {
                       startDate
                       endDate
                   }
                   listingImage {
                       uri
                       id
                   }
               }
           }
              `,
        type: 'events/fetch',
        key: 'eventsData',
      })
  
}

export default fetchEvents
