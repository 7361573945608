/* eslint-disable*/
import format from "date-fns/format"

const getConvertedDate = (date) => {
    if(date){
    let day = (date.split('-')[2]).split('T')[0]
    let month = (format(date, 'MMMM'))
    let year = date.split('-')[0]
    let covertedDate = `${day} ${month} ${year}`
    return covertedDate
    }
}
export default getConvertedDate