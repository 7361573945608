import gql from 'graphql-tag'
import duck from '../../duck'
import getDataFromLocalStorage from '../../utils/extract-from-localStorage'
import { ADMIN, UMS_ADMIN, UMS_VIEWER, MENTOR } from '../../constants/roles'

const fetchBatchSessions = async (first, skip, student = '', isStudentSearched) => {
  const role = getDataFromLocalStorage('login.role')
  const mentorId = getDataFromLocalStorage('login.id')
  let filterStatement
  if (role === ADMIN || role === UMS_ADMIN || role === UMS_VIEWER) {
    if (isStudentSearched) {
      filterStatement = `{and:[{students_exists: true} {students_some: {user_some:{name: "${student}"}}}]}`
    } else {
      filterStatement = '{students_exists: true}'
    }
  } else if (role === MENTOR) {
    if (isStudentSearched) {
      filterStatement = `{and:[{ allottedMentor_some: { id: "${mentorId}" }} {students_exists: true} {students_some: {user_some:{name: "${student}"}}}]}`
    } else {
      filterStatement = `{and:[{ allottedMentor_some: { id: "${mentorId}" }} {students_exists: true}]}`
    }
  }
  let filterStatement2
  if (role === ADMIN || role === UMS_ADMIN || role === UMS_VIEWER) {
    if (isStudentSearched) {
      filterStatement2 = `{and:[{menteeSession_exists: true},{menteeSession_some : {user_some: {name: "${student}"}}},{source_not: school},{topic_some:{order:1}}]}`
    } else {
      filterStatement2 = '{and:[{menteeSession_exists: true},{source_not: school},{topic_some:{order:1}}]}'
    }
  } else if (role === MENTOR) {
    if (isStudentSearched) {
      filterStatement2 = `{and:[{menteeSession_exists: true},{source_not: school},{menteeSession_some : {user_some: {name: "${student}"}}},{mentorSession_some:{user_some:{id: "${mentorId}"}}},{topic_some:{order:1}}]}`
    } else {
      filterStatement2 = `{and:[{menteeSession_exists: true},{source_not: school},{mentorSession_some:{user_some:{id: "${mentorId}"}}},{topic_some:{order:1}}]}`
    }
  }
  return duck.query({
    query: gql`
    {
        batches(
          filter: ${filterStatement},skip: ${skip}, first :${first},orderBy: createdAt_DESC
        ){
            id
            createdAt
            code
            students {
              id
              grade
              user {
                id
                name
                createdAt
                profilePic {
                  uri
                }
              }
              parents {
                id
                user {
                  id
                  name
                  phone {
                    countryCode
                    number
                  }
                  email
                }
              }
            }
          }
          batchesMeta(filter: ${filterStatement}){
            count
          }
          mentorMenteeSessions(filter:${filterStatement2}, orderBy: sessionStartDate_DESC, skip: ${skip}, first :${first}){
            id
            createdAt
            courseData: course {
              id
              title
              secondaryCategory
              topicsMeta {
                count
              }
              thumbnail {
                uri
              }
            }
            currentTopic: topic {
              order
            }
            sessionStatus
            salesOperation {
              leadStatus
            }
            menteeInfo: menteeSession {
              id
              user {
                id
                name
              }
            }
          }
          mentorMenteeSessionsMeta(filter:${filterStatement2}){
            count
          }
      }
      `,
    type: 'batch/fetch',
    key: 'batchesForTable'
  })
}

export default fetchBatchSessions
