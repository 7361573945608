import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { message } from 'antd'
import MainTable from '../../../../components/MainTable'
import { Table } from '../../../../components/StyledComponents'
import CopyIcon from '../CopyIcon'
import SVGIcon from '../../../../assets/icons'
import Main from '../../Upload.style'
import getFullPath from '../../../../utils/getFullPath'

const UploadTableRow = ({
  id,
  name,
  uri,
  size,
  mimeType,
  deleteStatus,
  usageCount = 0,
  columnsTemplate,
  minWidth,
}) => {
  const [isHovering, setIsHovering] = useState(false)

  const Size = (num) => {
    if (num < 1024) {
      return `${num}bytes`
    } else if (num >= 1024 && num < 1048576) {
      return `${(num / 1024).toFixed(0)}KB`
    } else if (num >= 1048576) {
      return `${(num / 1048576).toFixed(1)}MB`
    }
  }

  const onMouseEnter = () => {
    setIsHovering(true)
  }

  const onMouseLeave = () => {
    setIsHovering(false)
  }

  const copyLink = () => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(getFullPath(uri)).then(() => {
        message.success('Link Copied!')
      })
    }
  }

  return (
    <Table.Row
      columnsTemplate={columnsTemplate}
      minWidth={minWidth}
      isHovering={isHovering}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Table.Item>
        <MainTable.Item >
          {(mimeType === 'pdf' || mimeType === 'octet-stream' || mimeType === 'mpeg' || mimeType === 'mp4' || mimeType === 'json') ?
          <SVGIcon.FileIcon />
          :
            <img src={getFullPath(uri)}
              alt='Thumbnail'
              style={{ height: '40px', width: '100%', borderRadius: '6px' }}
            />
          }
        </MainTable.Item>
      </Table.Item>
      <Table.Item justifyContent='left'>
        <MainTable.Item>
        <MainTable.FileName size='' height='' >
          {name}
        </MainTable.FileName>
        </MainTable.Item>
      </Table.Item>
      <Table.Item>
        <MainTable.Item>
        <MainTable.FileName>
          {mimeType}
        </MainTable.FileName>
        </MainTable.Item>
      </Table.Item>
      <Table.Item>
        <MainTable.Item>
          <Main.Size>
            {Size(size)}
          </Main.Size>
        </MainTable.Item>
      </Table.Item>
      <Table.Item>
        <MainTable.Item>
          <CopyIcon
            id={id}
            deleteStatus={deleteStatus}
            file={name}
            uri={uri}
            mimeType={mimeType}
            usageCount={usageCount}
          />
        </MainTable.Item>
      </Table.Item>
      <Table.Item>
        <MainTable.ActionItem.IconWrapper onClick={copyLink} >
          <SVGIcon.CopyIcon />
        </MainTable.ActionItem.IconWrapper>
      </Table.Item>
    </Table.Row>
  )
}
UploadTableRow.propTypes = {
  columnsTemplate: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  mimeType: PropTypes.string.isRequired,
  minWidth: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export default UploadTableRow
