import React, { Component } from 'react'
import { debounce, get, sortBy } from 'lodash'
import { LoadingOutlined, SyncOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { Button, notification, Tooltip } from 'antd'
import {
  fetchSchoolList, fetchSchoolGrades, fetchCampaigns
} from '../../actions/SchoolOnboarding'
import {
  AcademicYearText,
  FlexContainer, SchoolDetails, StyledButton, StyledDivider, StyledSelect,
} from './SchoolOnBoarding.style'
import {
  SchoolSectionNav, DashboardCounts,
  AddStudentModal,
  GradesTab, StudentsTab,
  SearchInput, CampaignTab, BatchesTab, BatchActionTabs, GenerateLinkModal, StudentLinkLModal
} from './components'
import getFullPath from '../../utils/getFullPath'
import copyToClipboard from '../../utils/copyToClipboard'
import TeachersTab from './components/TeachersTab'
import fetchMentorProfileData from '../../actions/SchoolOnboarding/FetchMentorProfileData'
import FetchMentorProfilesMeta from '../../actions/SchoolOnboarding/FetchMentorProfileMeta'
import ScheduleTab from './components/ScheduleTab/ScheduleTab'
import UploadStudentsTab from './components/UploadStudentsTab/UploadStudentsTab'
import LabInspection from './components/LabInspection'
import Classrooms from './components/Classrooms'
import AcademicYearModal from './components/AcademicYearModal'
import fetchAcademicYears from '../../actions/SchoolOnboarding/fetchAcademicYears'
import fetchStudentsCount from "../../actions/SchoolOnboarding/fetchStudentsCount";

class SchoolOnboarding extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chosenSchool: '',
      chosenGrade: '',
      activeTab: get(props, 'match.path'),
      showBatches: false,
      schoolsList: [],
      classGrades: [],
      campaigns: [],
      addStudentModal: false,
      generateLinkModal: false,
      studentLinkModal: false,
      modalTitle: '',
      editStudent: null,
      sectionToAdd: '',
      gradeToAdd: '',
      chosenCampaign: 'all',
      completeCampaign: [],
      loading: false,
      coursesList: [],
      dashboardCount: {},
      data: null,
      isAcademicYearModalVisible: false,
      academicYears: [],
      selectedAcademicYear: '',
      studentsCount: 0,
      nextAcademicYear: {
        startDate: '',
        endDate: ''
      }
    }
  }
  showBatchesAction = () => {
    const { match } = this.props
    const activeRoute = get(match, 'path')
    const tabsArray = [
      '/sms/school-dashboard/grade',
      '/sms/school-dashboard/:schoolId/grade',
      '/sms/school-dashboard/:schoolId/students',
      '/sms/school-dashboard/:schoolId/campaigns',
      '/sms/school-dashboard/:schoolId/batches',
      '/sms/school-dashboard/:schoolId/teacher',
      '/sms/school-dashboard/:schoolId/schedule',
      '/sms/school-dashboard/:schoolId/upload-students',
      '/sms/school-dashboard/:schoolId/lab-inspection',
      '/sms/school-dashboard/:schoolId/classrooms',
      '/sms/school-dashboard/:schoolId/teacher-training',
    ]
    if (!tabsArray.includes(activeRoute)) {
      return true
    }
    return false
  }

  setChosenSchool = () => {
    const { schoolsList } = this.state
    const { history, match: { params } } = this.props
    this.loadData();
    if (params.schoolId) {
      this.setState({
        chosenSchool: params.schoolId,
      })
    } else if (schoolsList.length > 0) {
      this.setState({
        chosenSchool: get(schoolsList[0], 'id'),
      },
        () => history.push(`/sms/school-dashboard/${this.state.chosenSchool}/classrooms`))
    }
    this.setState({
      showBatches: this.showBatchesAction()
    })
  }

  // getSchoolList = async() => {
  //   const firstFiveSchool = true
  //   const { history, match: { params } } = this.props
  //   if (params.schoolId) {
  //     const filterString = `{
  //       id: "${params.schoolId}"
  //     }`
  //     const { schools } = await fetchSchoolList(null, filterString)
  //     this.setState({ schoolsList: schools || [] }, () => this.setChosenSchool())
  //   } else {
  //     const { schools } = await fetchSchoolList(firstFiveSchool)
  //     this.setState({ schoolsList: schools || [] }, () => this.setChosenSchool())
  //   }
  // }

  async componentDidMount() {
    const { schools } = await fetchSchoolList()
    this.setState({ schoolsList: schools || [] }, () => this.setChosenSchool())
  }
  loadData() {
    this.setState({
      data: "rendered"
    });
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { chosenSchool, activeTab, campaigns, isAcademicYearModalVisible, academicYears, selectedAcademicYear } = this.state
    const { schoolClassGradesFetchStatus, match,
      schoolClassesAddStatus, schoolClassesDeleteStatus,
      campaignsFetchStatus, campaignsUpdateStatus, campaignsAddStatus,
      campaignsUpdateBatchStatus, campaignsDeleteStatus,
      academicYearAddStatus, academicYearsData, academicYearsFetchStatus,
      parentSignUpStatus, courseFetchingStatus, MentorProfilesStatus, MentorProfilesData, userSecondaryRole } = this.props
    if (userSecondaryRole === 'teacherTrainer' && chosenSchool && activeTab && !activeTab.includes('teacher')) {
      this.changeTab('/sms/school-dashboard/:schoolId/teacher');
    }
    if (get(match, 'path') !== get(prevProps, 'match.path')) {
      this.setState({
        activeTab: get(this.props, 'match.path'),
        showBatches: this.showBatchesAction()
      })
    }

    if (prevState.chosenSchool !== chosenSchool && chosenSchool) {
      if (activeTab === '/sms/school-dashboard/:schoolId/campaigns' ||
        activeTab === '/sms/school-dashboard/:schoolId/batches') {
        await fetchCampaigns(chosenSchool)
      }
      await fetchSchoolGrades(chosenSchool)

    }
    if (prevState.chosenSchool !== chosenSchool && chosenSchool) {
      if (activeTab === '/sms/school-dashboard/:schoolId/teacher') {

        await fetchMentorProfileData(chosenSchool, 5, 0)
        await FetchMentorProfilesMeta(chosenSchool, 5, 0)
        await fetchSchoolGrades(chosenSchool)
      }
    }

    if (prevState.chosenSchool !== chosenSchool && chosenSchool) {
      fetchAcademicYears(chosenSchool)
    }

    if ((academicYearsFetchStatus && !get(academicYearsFetchStatus.toJS(), 'loading')
      && get(academicYearsFetchStatus.toJS(), 'success') &&
      (prevProps.academicYearsFetchStatus !== academicYearsFetchStatus))) {
      const academicYearsDataTemp = (academicYearsData && academicYearsData.toJS()) || []
      this.setState({ academicYears: academicYearsDataTemp }, () => {
        const currentYear = new Date()
        this.state.academicYears.length && this.state.academicYears.forEach(academicYear => {
          if (new Date(get(academicYear, 'startDate')) <= currentYear && new Date(get(academicYear, 'endDate')) >= currentYear) {
            this.setState({ selectedAcademicYear: get(academicYear, 'id') })
          }
        })
        if (!this.state.selectedAcademicYear && this.state.academicYears.length) {
          this.state.academicYears.length && this.state.academicYears.forEach(academicYear => {
            if (new Date(get(academicYear, 'startDate')).getFullYear() === currentYear.getFullYear()) {
              this.setState({ selectedAcademicYear: get(academicYear, 'id') })
            }
          })
        }
        if (this.state.academicYears.length) {
          const latestAcademicYear = this.state.academicYears[this.state.academicYears.length-1]
          let previousYearEndDate = get(latestAcademicYear, 'endDate')
          if (previousYearEndDate) {
            previousYearEndDate = new Date(previousYearEndDate)
            const previousFullYear = previousYearEndDate.getFullYear()
            const firstFebDate = `${previousFullYear}-02-01`
            const currentDate = new Date()
            if (currentDate >= previousYearEndDate || currentDate >= new Date(firstFebDate)) {
              const nexYearStartDate = new Date(previousYearEndDate)
              nexYearStartDate.setDate(nexYearStartDate.getDate()+1)
              const newYearEndDate = new Date(nexYearStartDate)
              newYearEndDate.setFullYear(newYearEndDate.getFullYear() + 1)
              newYearEndDate.setDate(newYearEndDate.getDate() - 1);
              const obj = {
                startDate: nexYearStartDate,
                endDate: newYearEndDate
              }
              this.setState({
                nextAcademicYear: obj
              })
            }
          }
        } else if (!this.state.academicYears.length) {
          const currentYear = new Date().getFullYear()
          const startDate = new Date(`${currentYear}-05-01`)
          const endDate = new Date(`${currentYear + 1}-04-30`)
          this.setState({
            nextAcademicYear: {
              startDate,
              endDate
            }
          })
        }
        setTimeout(async () => {
          if (!this.state.selectedAcademicYear && this.state.academicYears.length) {
            this.setState({
              selectedAcademicYear: get(this.state.academicYears, `[${this.state.academicYears.length - 1}].id`)
            })
          }
          const studentsCountRes = await fetchStudentsCount({
            schoolId: chosenSchool,
            academicYearId: this.state.selectedAcademicYear,
          });
          this.setState({
            studentsCount: get(studentsCountRes, 'getSchoolStudentsCount'),
          });
        })
      })
    }

    if ((academicYearAddStatus && !get(academicYearAddStatus.toJS(), 'loading')
      && get(academicYearAddStatus.toJS(), 'success') &&
      (prevProps.academicYearAddStatus !== academicYearAddStatus))) {
        if (isAcademicYearModalVisible) {
          await fetchAcademicYears(chosenSchool)
          this.setState({ isAcademicYearModalVisible: false })
        }
    }

    if(prevState.activeTab !== activeTab )[
      this.setState({
        chosenCampaign : 'all'
      })
    ]

    if (prevState.activeTab !== activeTab &&
      (activeTab === '/sms/school-dashboard/:schoolId/campaigns' ||
        activeTab === '/sms/school-dashboard/:schoolId/batches') &&
      campaigns.length === 0 && chosenSchool) {
      fetchCampaigns(chosenSchool)
    }
    if ((schoolClassGradesFetchStatus && !get(schoolClassGradesFetchStatus.toJS(), 'loading')
      && get(schoolClassGradesFetchStatus.toJS(), 'success') &&
      (prevProps.schoolClassGradesFetchStatus !== schoolClassGradesFetchStatus))) {
      this.setState({
        classGrades:
          this.props.schoolClassesGrades && sortBy(this.props.schoolClassesGrades.toJS(), 'order'),
        dashboardCount: this.props.schoolDashboardCount && this.props.schoolDashboardCount.toJS(),
        chosenGrade: ''
      }, () => {
        const { classGrades } = this.state
        if (classGrades.length > 0) {
          this.setState({
            chosenGrade:
              prevState.chosenGrade ?
                prevState.chosenGrade :
                get(classGrades[0], 'grade')
          })
        }
      })
    }


    const campaignFetchingStatus = campaignsFetchStatus && !get(campaignsFetchStatus.toJS(), 'loading')
      && get(campaignsFetchStatus.toJS(), 'success') && (prevProps.campaignsFetchStatus !== campaignsFetchStatus)
    const campaignUpdatingStatus = campaignsUpdateStatus && !get(campaignsUpdateStatus.toJS(), 'loading')
      && get(campaignsUpdateStatus.toJS(), 'success') && (prevProps.campaignsUpdateStatus !== campaignsUpdateStatus)
    const campaignAddingStatus = campaignsAddStatus && !get(campaignsAddStatus.toJS(), 'loading')
      && get(campaignsAddStatus.toJS(), 'success') && (prevProps.campaignsAddStatus !== campaignsAddStatus)
    const campaignsUpdatingBatchStatus = campaignsUpdateBatchStatus && !get(campaignsUpdateBatchStatus.toJS(), 'loading')
      && get(campaignsUpdateBatchStatus.toJS(), 'success') && (prevProps.campaignsUpdateBatchStatus !== campaignsUpdateBatchStatus)
    const campaignDeletingStatus = campaignsDeleteStatus && !get(campaignsDeleteStatus.toJS(), 'loading')
      && get(campaignsDeleteStatus.toJS(), 'success') && (prevProps.campaignsDeleteStatus !== campaignsDeleteStatus)
    if (campaignUpdatingStatus
      || campaignAddingStatus
      || campaignDeletingStatus
      || campaignsUpdatingBatchStatus) {
      await fetchSchoolGrades(chosenSchool)
      this.getCampaigns()
    }

    if (campaignFetchingStatus) {
      this.getCampaigns()
    }


    const schoolAddingStatus = schoolClassesAddStatus && !get(schoolClassesAddStatus.toJS(), 'loading')
      && get(schoolClassesAddStatus.toJS(), 'success') && (prevProps.schoolClassesAddStatus !== schoolClassesAddStatus)
    const schoolDeletingStatus = schoolClassesDeleteStatus && !get(schoolClassesDeleteStatus.toJS(), 'loading')
      && get(schoolClassesDeleteStatus.toJS(), 'success') && (prevProps.schoolClassesDeleteStatus !== schoolClassesDeleteStatus)
    const studentAddingStatus = parentSignUpStatus && !get(parentSignUpStatus.toJS(), 'loading')
      && get(parentSignUpStatus.toJS(), 'success') && (prevProps.parentSignUpStatus !== parentSignUpStatus)
    if (schoolAddingStatus || schoolDeletingStatus || studentAddingStatus) {
      await fetchSchoolGrades(chosenSchool)
    }


    if ((courseFetchingStatus && !get(courseFetchingStatus.toJS(), 'loading')
      && get(courseFetchingStatus.toJS(), 'success') &&
      (prevProps.courseFetchingStatus !== courseFetchingStatus))) {
      this.setState({
        coursesList: this.props.courses && this.props.courses.toJS()
      })
    }
  }

  getCampaigns = () => {
    const { chosenSchool } = this.state
    this.setState({
      campaigns: this.props.campaigns ? this.props.campaigns.toJS().filter((cam) => get(cam, 'school.id') === chosenSchool) : []
    }, () => {
      if (this.state.campaigns) {
        this.setState({
          completeCampaign:
            this.state.campaigns.filter(({ batchCreationStatus }) => batchCreationStatus === 'complete'),
        })
      }
    })
  }
  getSchoolName = (id) => {
    const { schoolsList, chosenSchool } = this.state
    let schoolName = ''
    if (schoolsList && schoolsList.length > 0 && chosenSchool) {
      schoolName = get(schoolsList.find((school) => get(school, 'id') === id), 'name')
    }
    return schoolName
  }
  renderStudnetTable() {
    this.setState({
      renderStudentdata: "StudentDataRendered"
    });
  }
  onSchoolChange = () => {
    let newActiveTab = ''
    const { history } = this.props
    const { chosenSchool, activeTab } = this.state
    if (activeTab.includes('grade')) {
      newActiveTab = `/sms/school-dashboard/${chosenSchool}/grade`
    }
    if (activeTab.includes('students')) {
      newActiveTab = `/sms/school-dashboard/${chosenSchool}/students`
    }
    if (activeTab.includes('campaigns')) {
      newActiveTab = `/sms/school-dashboard/${chosenSchool}/campaigns`
    }
    if (activeTab.includes('batches')) {
      newActiveTab = `/sms/school-dashboard/${chosenSchool}/batches`
    }
    if (activeTab.includes('teacher')) {
      newActiveTab = `/sms/school-dashboard/${chosenSchool}/teacher`
    }
    if (activeTab.includes('schedule')) {
      newActiveTab = `/sms/school-dashboard/${chosenSchool}/schedule`
    }
    if(activeTab.includes('upload')){
      newActiveTab = `/sms/school-dashboard/${chosenSchool}/upload-students`
    }
    if (activeTab.includes('inspection')) {
      newActiveTab = `/sms/school-dashboard/${chosenSchool}/lab-inspection`
    }
    if(activeTab.includes('teacher-training')){
      newActiveTab = `/sms/school-dashboard/${chosenSchool}/teacher-training`
    }
    if(activeTab.includes('classrooms')){
      newActiveTab = `/sms/school-dashboard/${chosenSchool}/classrooms`
    }
    history.push(newActiveTab)
  }


  changeTab = (value, selectedGrade) => {
    const { history } = this.props
    const { chosenSchool, academicYears, selectedAcademicYear } = this.state
    if (selectedGrade) {
      this.setState({
        activeTab: `/sms/school-dashboard/${chosenSchool}/students`,
        chosenGrade: selectedGrade,
      }, () => history.push(this.state.activeTab))
    } else {
      let newActiveTab = ''
      if (value.includes('students') || value.includes('batches')
        || value.includes('upload') || value.includes('classrooms')
        || value.includes('teacher-training')) {
        if (!academicYears.length) {
          return notification.warn({ message: 'Please Add Academic Year to proceed further' })
        }
        if (!selectedAcademicYear) {
          return notification.warn({ message: 'Please select Academic Year to proceed further' })
        }
      }
      if (value.includes('grade')) {
        newActiveTab = `/sms/school-dashboard/${chosenSchool}/grade`
      }
      if (value.includes('students')) {
        newActiveTab = `/sms/school-dashboard/${chosenSchool}/students`
      }
      if (value.includes('campaigns')) {
        newActiveTab = `/sms/school-dashboard/${chosenSchool}/campaigns`
      }
      if (value.includes('batches')) {
        newActiveTab = `/sms/school-dashboard/${chosenSchool}/batches`
      }
      if (value.includes('teacher')) {
        newActiveTab = `/sms/school-dashboard/${chosenSchool}/teacher`
      }
      if (value.includes('schedule')) {
        newActiveTab = `/sms/school-dashboard/${chosenSchool}/schedule`
      }
      if (value.includes('upload')) {
        newActiveTab = `/sms/school-dashboard/${chosenSchool}/upload-students`
      }
      if (value.includes('inspection')) {
        newActiveTab = `/sms/school-dashboard/${chosenSchool}/lab-inspection`
      }
      if(value.includes('teacher-training')){
        newActiveTab = `/sms/school-dashboard/${chosenSchool}/teacher-training`
      }
      if(value.includes('classrooms')){
        newActiveTab = `/sms/school-dashboard/${chosenSchool}/classrooms`
      }
      
      this.setState({
        activeTab: newActiveTab
      }, () => history.push(newActiveTab))
    }
  }


  selectCampaignBatch = (value) => {
    const { history } = this.props
    const { chosenSchool } = this.state
    this.setState({
      activeTab: `/sms/school-dashboard/${chosenSchool}/batches`,
      chosenCampaign: value,
    }, () => history.push(this.state.activeTab))
  }

  openStudentModal = (title, data, section, grade) => {
    const { academicYears, selectedAcademicYear } = this.state
    if (!academicYears.length) {
      return notification.warn({ message: 'Please Add Academic Year to proceed further' })
    }
    if (!selectedAcademicYear) {
      return notification.warn({ message: 'Please select Academic Year to proceed further' })
    }
    if (section && grade && grade !== 'All') {
      this.setState({
        addStudentModal: true,
        modalTitle: title,
        sectionToAdd: section,
        gradeToAdd: grade
      })
    } else {
      this.setState({
        addStudentModal: true,
        modalTitle: title,
        editStudent: data
      })
    }
  }
  openLinkModal = (title, data, section, grade) => {
    if (section && grade) {
      this.setState({
        studentLinkModal: true,
        modalTitle: title,
        sectionToAdd: section,
        gradeToAdd: grade
      })
    } else {
      this.setState({
        studentLinkModal: true,
        modalTitle: title,
        editStudent: data
      })
    }
  }

  openGenerateLinkModal = (title, data, section, grade) => {
    if (section && grade) {
      this.setState({
        generateLinkModal: true,
        modalTitle: title,
        sectionToAdd: section,
        gradeToAdd: grade
      })
    } else {
      this.setState({
        generateLinkModal: true,
        modalTitle: title,
        editStudent: data
      })
    }
  }


  closesLinkModal = () => {
    this.setState({
      studentLinkModal: false,
      modalTitle: '',
      sectionToAdd: '',
      gradeToAdd: '',
      editStudent: null
    })
  }

  closeStudentModal = () => {
    this.setState({
      addStudentModal: false,
      modalTitle: '',
      sectionToAdd: '',
      gradeToAdd: '',
      editStudent: null
    })
  }

  closeGenerateLinkModal = () => {
    this.setState({
      generateLinkModal: false,
      modalTitle: '',
      sectionToAdd: '',
      gradeToAdd: '',
      editStudent: null
    })
  }

  closeAcademicYearModal = () => {
    this.setState({
      isAcademicYearModalVisible: false,
    })
  }

  getRemainingGrades = () => {
    const { campaigns, classGrades } = this.state
    if (campaigns.length > 0) {
      const classArray = []
      campaigns.forEach(({ classes = [] }) => {
        classes.forEach(cls => {
          classArray.push(cls)
        })
      })
      const gradesArray = [...new Set(classArray.map(({ grade }) => grade))]
      const remainingGrades = []
      classGrades.forEach(classGrade => {
        if (!gradesArray.includes(get(classGrade, 'grade'))) {
          remainingGrades.push(classGrade)
        }
      })
      return remainingGrades
    }
    return classGrades
  }


  refreshCampaigns = async () => {
    const { chosenSchool } = this.state
    if (chosenSchool) {
      this.setState({ loading: true })
      fetchSchoolGrades(chosenSchool)
      await fetchCampaigns(chosenSchool)
      this.setState({ loading: false })
    }
  }


  renderTabs = () => {
    const {
      activeTab, classGrades, chosenGrade, chosenSchool,
      campaigns, chosenCampaign, completeCampaign,
      coursesList, renderStudentdata, dashboardCount, selectedAcademicYear
    } = this.state
    const { history, MentorProfileuser, MentorProfilesData, MentorProfilesStatus,
      MentorProfilesMetaData, MentorProfilesMetaStatus } = this.props
    const datas = this.getRemainingGrades()
    if (activeTab === '/sms/school-dashboard/:schoolId/grade') {
      return (
        <GradesTab
          viewStudents={this.changeTab}
          classGrades={classGrades}
          schoolName={this.getSchoolName(chosenSchool)}
          schoolId={chosenSchool}
        />
      )
    }
    if (activeTab === '/sms/school-dashboard/:schoolId/students') {
      return (
        <StudentsTab
          renderStudentdata={renderStudentdata}
          schoolName={this.getSchoolName(chosenSchool)}
          classGrades={classGrades}
          chosenGrade={chosenGrade}
          chosenSchool={chosenSchool}
          selectGrade={(value) => this.setState({ chosenGrade: value })}
          onStudentEdit={data => this.openStudentModal('update Student', data)}
          onLinkGenerate={data => this.openLinkModal('Generate Login Links', data)}
          onStudentAdd={(section, grade) => this.openStudentModal('Add Student', null, section, grade)}
          onGenerateLinks={(grade) => this.openGenerateLinkModal('Generate Login Links', null, null, grade)}
          academicYearConnectId={selectedAcademicYear}
        />
      )
    }
    if (activeTab === '/sms/school-dashboard/:schoolId/campaigns') {
      return (
        <CampaignTab
          classGrades={datas}
          schoolName={this.getSchoolName(chosenSchool)}
          schoolId={chosenSchool}
          campaigns={campaigns}
          selectCampaignBatch={this.selectCampaignBatch}
          coursesList={coursesList}
        />
      )
    }
    if (activeTab === '/sms/school-dashboard/:schoolId/batches') {
      return (
        <BatchesTab
          history={history}
          chosenCampaign={chosenCampaign}
          campaigns={completeCampaign}
          schoolId={chosenSchool}
          selectCampaign={(value) => this.setState({ chosenCampaign: value })}
        />
      )
    }
    if (activeTab === '/sms/school-dashboard/:schoolId/teacher') {
      return (
        <TeachersTab
          classGrades={classGrades}
          schoolName={this.getSchoolName(chosenSchool)}
          schoolId={chosenSchool}
          MentorProfileuser={MentorProfileuser}
          MentorProfilesData={MentorProfilesData}
          MentorProfilesStatus={MentorProfilesStatus}
          MentorProfilesMetaData={MentorProfilesMetaData}
          MentorProfilesMetaStatus={MentorProfilesMetaStatus}
        />
      )
    }
    if (activeTab === '/sms/school-dashboard/:schoolId/upload-students') {
      return (
        <UploadStudentsTab
          studentsUploadStatus={
            this.props.schoolDashboardCount.toJS().studentsUploadStatus
          }
          schoolDashboardCount={this.props.schoolDashboardCount.toJS()}
          schoolName={this.getSchoolName(chosenSchool)}
          schoolId={chosenSchool}
          key={this.props.match.params.schoolId}
          academicYearConnectId={selectedAcademicYear}
            ></UploadStudentsTab>
        )
    }
    if (activeTab === '/sms/school-dashboard/:schoolId/lab-inspection') {
      return (
        <LabInspection
          schoolName={this.getSchoolName(chosenSchool)}
          getSchoolName={this.getSchoolName}
          schoolsList={this.state.schoolsList}
        />
      )
    }
    if (activeTab === '/sms/school-dashboard/:schoolId/teacher-training') {
      return <BatchesTab
          history={history}
          chosenCampaign={chosenCampaign}
          campaigns={completeCampaign}
          schoolId={chosenSchool}
          selectCampaign={(value) => this.setState({ chosenCampaign: value })}
          isTeacherTrainingBatches={true}
          schoolCode={get(dashboardCount, 'code')}
          academicYearConnectId={selectedAcademicYear}
        />
    }
    if (activeTab === '/sms/school-dashboard/:schoolId/classrooms') {
      return (
        <Classrooms
          classGrades={classGrades}
          schoolName={this.getSchoolName(chosenSchool)}
          schoolCode={get(dashboardCount, 'code')}
          schoolId={chosenSchool}
          academicYearConnectId={selectedAcademicYear}
        />
      )
    }
    if (activeTab === '/sms/school-dashboard/:schoolId/schedule') {
      const {timeTableScheduleStatus,addtimeTableScheduleError,updateTimeTableScheduleStatus,updatetimeTableScheduleError} = this.props
      const addTimeTableScheduleSuccessStatus = timeTableScheduleStatus && get(timeTableScheduleStatus.toJS(), 'success')
      const addTimeTableScheduleLoadingStatus = timeTableScheduleStatus && get(timeTableScheduleStatus.toJS(), 'loading')
      const addTimeTableSchedulErrorStatus = timeTableScheduleStatus && get(timeTableScheduleStatus.toJS(), 'failure')
      const addTimeTableScheduleErrorMessage = addtimeTableScheduleError && get(addtimeTableScheduleError.toJS(), '[0].error.status')
      const updateTimeTableScheduleSuccessStatus = updateTimeTableScheduleStatus && get(updateTimeTableScheduleStatus.toJS(), 'success')
      const updateTimeTableScheduleLoadingStatus = updateTimeTableScheduleStatus && get(updateTimeTableScheduleStatus.toJS(), 'loading')
      const updateTimeTableSchedulErrorStatus = updateTimeTableScheduleStatus && get(updateTimeTableScheduleStatus.toJS(), 'failure')
      const updateTimeTableScheduleErrorMessage = updatetimeTableScheduleError && get(updatetimeTableScheduleError.toJS(), '[0].error.status')

      return(
       <ScheduleTab 
       chosenSchoolId={this.state.chosenSchool} 
       schoolsList={this.state.schoolsList} 
       addTimeTableScheduleSuccessStatus={addTimeTableScheduleSuccessStatus} 
       addTimeTableScheduleLoadingStatus={addTimeTableScheduleLoadingStatus} 
       addTimeTableSchedulErrorStatus={addTimeTableSchedulErrorStatus}
       addTimeTableScheduleErrorMessage={addTimeTableScheduleErrorMessage}
       updateTimeTableScheduleSuccessStatus={updateTimeTableScheduleSuccessStatus} 
       updateTimeTableScheduleLoadingStatus={updateTimeTableScheduleLoadingStatus} 
       updateTimeTableSchedulErrorStatus={updateTimeTableSchedulErrorStatus}
       updateTimeTableScheduleErrorMessage={updateTimeTableScheduleErrorMessage}
       />
     )
    }
    return null
  }
  getSchoolCode = (code) => {
    let link = ''
    if (process.env.REACT_APP_NODE_ENV === 'staging') {
      link = `https://tekie-web-staging.herokuapp.com/login?schoolCode=${code}`
    } else {
      link = `https://www.tekie.in/login?schoolCode=${code}`
    }
    return link
  }

  handleAcademicYearChange = async (value) => {
    if (value !== 'add') {
      this.setState({ selectedAcademicYear: value })
       this.setState({ studentsCount: 0 });
       const studentsCountRes = await fetchStudentsCount({
        schoolId: this.state.chosenSchool,
        academicYearId: value,
      });
      this.setState({ studentsCount: get(studentsCountRes, 'getSchoolStudentsCount') });
    }
  }

  getSchoolCodeLink = () => {
    const { schoolDashboardCount } = this.props
    if (schoolDashboardCount && schoolDashboardCount.toJS()
      && schoolDashboardCount.toJS().schoolCampaignCode) {
      return (
        <p>School Campaign Code:
          <a href={this.getSchoolCode(get(schoolDashboardCount.toJS(), 'schoolCampaignCode'))} target='_blank' rel='noopener noreferrer' >
            {this.getSchoolCode(get(schoolDashboardCount.toJS(), 'schoolCampaignCode'))}
          </a>
          <Tooltip title='Copy'>
            <Button icon='copy'
              style={{ marginLeft: '10px' }}
              onClick={() =>
                copyToClipboard(this.getSchoolCode(get(schoolDashboardCount.toJS(), 'schoolCampaignCode')))}
            />
          </Tooltip>
        </p>
      )
    }
    return null
  }

  renderStartEndYear = (start, end) => {
    const startYear = new Date(start).getFullYear()
    const endYear = new Date(end).getFullYear().toString().slice(2)
    return (
      <AcademicYearText>
        <p>AY</p>
        <h4>{startYear}-{endYear}</h4>
      </AcademicYearText>
    )
  }

  getNextYearButtonText = () => {
    const { nextAcademicYear } = this.state
    const { startDate, endDate } = nextAcademicYear
    const startYear = new Date(startDate).getFullYear()
    const endYear = new Date(endDate).getFullYear().toString().slice(2)
    return `+ Add AY ${startYear}-${endYear}`
  }

  handleSchoolSearch = async(value) => {
    if (value && value.trim()) {
      this.setState({ isSearchingForSchool: true })
      const filterString = `{
        name_contains: "${value}"
      }`
      const { schools } = await fetchSchoolList(null, filterString)
      const { schoolsList } = this.state
      const newSchools = [...schoolsList, ...schools]
      const uniqueSchoolsList = []
      newSchools.forEach(school => {
        const alreadyInList = uniqueSchoolsList.find(sData => get(sData, 'id') === get(school, 'id'))
        if (!alreadyInList) uniqueSchoolsList.push(school)
      })
      this.setState({ schoolsList: uniqueSchoolsList, isSearchingForSchool: false })
    }
  }
  onSchoolSearch = (value) => {
    const { schoolsList } = this.state
    const schoolDetail = schoolsList.find(school => get(school, 'name', '').toLowerCase().includes((value || '').toLowerCase()))
    if (!schoolDetail) {
      debounce(() => this.handleSchoolSearch(value), 3000)()
    }
  }
  renderSchoolOnBoarding = () => {
    const {
      chosenSchool, schoolsList, activeTab,
      generateLinkModal, studentLinkModal,
      addStudentModal, modalTitle, editStudent,
      sectionToAdd, gradeToAdd, showBatches,
      loading, dashboardCount, classGrades,
      academicYears, selectedAcademicYear, nextAcademicYear,
      isSearchingForSchool
    } = this.state
    const { schoolDashboardCount, history, match } = this.props

    return (
      <div>
        <GenerateLinkModal
          visible={generateLinkModal}
          title={modalTitle}
          schoolName={this.getSchoolName(chosenSchool)}
          schoolId={chosenSchool}
          onClose={this.closeGenerateLinkModal}
          editStudent={editStudent}
          gradeToAdd={gradeToAdd}
          sectionToAdd={sectionToAdd}
          classGrades={classGrades}
        />
        <StudentLinkLModal
          visible={studentLinkModal}
          title={modalTitle}
          subTitle='NPS Gottigere'
          schoolName={this.getSchoolName(chosenSchool)}
          schoolId={chosenSchool}
          onClose={this.closesLinkModal}
          editStudent={editStudent}
          gradeToAdd={gradeToAdd}
          sectionToAdd={sectionToAdd}
          classGrades={classGrades}
        />
        <AddStudentModal
          visible={addStudentModal}
          title={modalTitle}
          schoolName={this.getSchoolName(chosenSchool)}
          schoolId={chosenSchool}
          onClose={this.closeStudentModal}
          editStudent={editStudent}
          gradeToAdd={gradeToAdd}
          sectionToAdd={sectionToAdd}
          academicYearConnectId={selectedAcademicYear}
        />
          {
            showBatches ? (
              <BatchActionTabs
                history={history}
                match={match}
                schoolId={chosenSchool}
              />
            ) : (
              <>
                <FlexContainer>
                  <SearchInput
                    value={chosenSchool}
                    placeholder='Select a School'
                    onChange={(value) => this.setState({
                      chosenSchool: value, campaigns: [], selectedAcademicYear: '', academicYears: [],
                      nextAcademicYear: {
                        startDate:"",endDate:""
                      }, studentsCount: 0
                    },
                      () => this.onSchoolChange())}
                    dataArray={schoolsList}
                    onSearch={this.onSchoolSearch}
                    loading={isSearchingForSchool}
                  />
                  <StyledSelect
                    value={selectedAcademicYear}
                    style={{ width: 150 }}
                    onChange={this.handleAcademicYearChange}
                    placeholder='Add a Academic Year'
                  >
                    {academicYears && academicYears.length && academicYears.map(academicYear =>
                      <Option
                        key={get(academicYear, 'id')}
                        value={get(academicYear, 'id')}
                      >
                        {this.renderStartEndYear(get(academicYear, 'startDate'), get(academicYear, 'endDate'))}
                      </Option>
                    )}
                    {(get(nextAcademicYear, 'startDate') && get(nextAcademicYear, 'endDate')) ? (
                      <StyledButton
                        key='add'
                        value='add'
                        style={{ color: '#8C61CB', background: '#8c61cb3d' }}
                        onClick={() => this.setState({ isAcademicYearModalVisible: true })}
                      >
                        {this.getNextYearButtonText()}
                      </StyledButton>
                    ) : null}
                  </StyledSelect>
                  </FlexContainer>
                  {/* {(!activeTab || !activeTab.includes('lab-inspection')) && (
                  <SchoolDetails>
                    <FlexContainer>
                      <div className='schoolOnBoarding___schoolName'>
                        <h1>{schoolDashboardCount
                          && schoolDashboardCount.toJS()
                          && schoolDashboardCount.toJS().logo
                          && <img
                            style={{ height: '2vw', width: '2vw', marginRight: '8px', objectFit: 'contain' }}
                            src={getFullPath(schoolDashboardCount.toJS().logo)}
                            alt='schoolLogo'
                          />}
                          {this.getSchoolName(chosenSchool)}
                        </h1>
                        <p>{schoolDashboardCount
                          && schoolDashboardCount.toJS()
                          && schoolDashboardCount.toJS().hubspotId &&
                          <span>Hubspot Id: {schoolDashboardCount.toJS().hubspotId}</span>}
                        </p>
                        {this.getSchoolCodeLink()}
                      </div>
                      <StyledButton type='primary' onClick={() => this.openStudentModal('Add Student')}>
                        Add Students
                      </StyledButton>
                    </FlexContainer>
                      
                        <DashboardCounts
                          dashboardCount={dashboardCount}
                        />
                  </SchoolDetails>
                  )} */}
                <StyledDivider />
                <div style={{ padding: '0 1.5vw' }}>
                  <FlexContainer buttonGroup noPadding>
                    <SchoolSectionNav
                      userSecondaryRole={this.props.userSecondaryRole}
                      activeTab={activeTab}
                      changeTab={this.changeTab}
                      data={this.state.data}
                      studentsCount={this.state.studentsCount}
                    />
                    {activeTab && activeTab.includes('campaigns') &&
                      <SyncOutlined
                        style={{ fontSize: '1.5vw', marginRight: '10px' }}
                        onClick={loading ? null : this.refreshCampaigns}
                        spin={loading}
                      />}
                  </FlexContainer>
                  {this.renderTabs()}
                </div>
              </>
            )
          }
        </div>
      )
  }

  render() {
    const { chosenSchool, isAcademicYearModalVisible, academicYears , nextAcademicYear} = this.state
    const { academicYearAddStatus } = this.props
    const isLoading = (!chosenSchool)
    return (
      <div style={{ width: '100%', height: '100%' }}>
        {isLoading ? (
          <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <LoadingOutlined />
          </div>
        ) : this.renderSchoolOnBoarding()}
        <AcademicYearModal
          visible={isAcademicYearModalVisible}
          academicYears={academicYears}
          schoolId={chosenSchool}
          nextAcademicYear={nextAcademicYear}
          addingAcademicYear={academicYearAddStatus && get(academicYearAddStatus.toJS(), 'loading')}
          onClose={this.closeAcademicYearModal}
        />
      </div>
    )
  }
}

SchoolOnboarding.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    params: PropTypes.shape({
      schoolId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({}).isRequired,
  schoolClassGradesFetchStatus: PropTypes.shape({}).isRequired,
  schoolClassesAddStatus: PropTypes.shape({}).isRequired,
  schoolClassesDeleteStatus: PropTypes.shape({}).isRequired,
  campaignsFetchStatus: PropTypes.shape({}).isRequired,
  campaignsUpdateStatus: PropTypes.shape({}).isRequired,
  campaignsAddStatus: PropTypes.shape({}).isRequired,
  campaignsUpdateBatchStatus: PropTypes.shape({}).isRequired,
  campaignsDeleteStatus: PropTypes.shape({}).isRequired,
  schoolClassesGrades: PropTypes.arrayOf({}).isRequired,
  campaigns: PropTypes.arrayOf({}).isRequired
}

export default SchoolOnboarding
