/* eslint-disable indent */
/* eslint-disable max-len */
import { Button, notification, Radio, Select, Switch } from 'antd'
import { get } from 'lodash'
import React from 'react'
import addTrainer from '../../../../actions/manageTrainers/addTrainer'
import updateTrainer from '../../../../actions/manageTrainers/updateTrainer'
import MainModal from '../../../../components/MainModal'
import { MENTOR, SCHOOL_TRAINER } from '../../../../constants/roles'
import AddTrainerForm from './AddTrainerForm'

class ManageTrainerModal extends React.Component {
  state = {
    name: '',
    email: '',
    username: '',
    password: '',
    phoneNumber: '',
    confirmPassword: '',
  }
  handleAddTrainer = async (value,
    { setErrors }) => {
    const { closeModal } = this.props
    const { name, email, username, password, phoneNumber } = value
    if (!email && !phoneNumber) {
        setErrors({ phoneNumber: 'Either email or phone number is required' })
    } else {
        const addInput = { name, email, username, password, role: MENTOR, roles: [SCHOOL_TRAINER] }
        if (phoneNumber) Object.assign(addInput, { phone: { countryCode: '+91', number: `${phoneNumber}` } })
        if (email) Object.assign(addInput, { email })
        await addTrainer(addInput)
    }
  }
  handleEditTrainer = async (value,
    { setErrors }) => {
    const { name, email, username, password, phoneNumber } = value
    const { closeModal, editData } = this.props
    if (!email && !phoneNumber) {
        setErrors({ phoneNumber: 'Either email or phone number is required' })
    } else {
        const editInput = { name, email, username }
        if (phoneNumber) Object.assign(editInput, { phone: { countryCode: '+91', number: `${phoneNumber}` } })
        if (email) Object.assign(editInput, { email })
        if (password) Object.assign(editInput, { password, savedPassword: password })
        await updateTrainer(get(editData, 'id'), editInput)
    }
  }
  render() {
    const { openModal, closeModal, editData, 
        addingTrainerType, onSelectionChange = () => { },
        selectedMentors, onMentorSelect, onMentorDeselect
    } = this.props
    const { operation, manageTrainersAddStatus,
        manageTrainersUpdateStatus, existingMentors = [],
        onAddMentor, isAddingMentorAsTrainer } = this.props
    return (
      <MainModal
        visible={openModal}
        title={operation === 'add' ? 'Add Trainer' : 'Edit Trainer'}
        onCancel={closeModal}
        maskClosable={false}
        width='550px'
        centered
        destroyOnClose
        footer={null}
      >
        {operation === 'add' ? <div style={{ margin: '10px auto', textAlign: 'center' }}>
            <Radio.Group value={addingTrainerType} onChange={onSelectionChange} style={{ marginBottom: 16 }}>
                <Radio.Button value="newTrainer">Add a new Trainer</Radio.Button>
                <Radio.Button value="existingMentor">Select from existing Mentor</Radio.Button>
            </Radio.Group>
        </div> : null}
        {
            addingTrainerType === 'newTrainer' ? (
                <AddTrainerForm
                    initialState={operation === 'add' ? { ...this.state } : { ...editData }}
                    handleSubmit={operation === 'add' ? this.handleAddTrainer : this.handleEditTrainer}
                    trainerAddUpdateStatus={operation === 'add' ? manageTrainersAddStatus : manageTrainersUpdateStatus}
                    operation={operation}
                />
            ) : <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Select
                    mode='multiple'
                    labelInValue
                    placeholder='Type min 3 characters'
                    style={{ width: '100%', margin: '10px 0' }}
                    filterOption={(input, option) => {
                        return get(option, 'props.children')
                            ? get(option, 'props.children')
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                            : false
                    }}
                    value={selectedMentors}
                    onSelect={onMentorSelect}
                    onDeselect={onMentorDeselect}
                >
                    {existingMentors.map((item) => (
                        <Select.Option value={get(item, 'id')} key={get(item, 'id')}>
                            {get(item, 'name')}
                        </Select.Option>
                    ))}
                </Select>
                <Button
                    disabled={!selectedMentors.length} 
                    type='primary' icon='file'
                    onClick={onAddMentor} 
                    loading={isAddingMentorAsTrainer || false}
                >
                    Add
                </Button>
            </div>
        }
      </MainModal>
    )
  }
}

export default ManageTrainerModal
