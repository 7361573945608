import gql from 'graphql-tag'
import duck from '../../duck'
import getSlotNames from '../../utils/slots/slot-names'

const batchSession = async (batchSessionId) => (
  duck.query({
    query: gql`
  query{ 
    batchSession(id:"${batchSessionId}"){
      id
      course {
        id
        title
      }
      topic {
        id
        order
        title
        thumbnailSmall {
          uri
        }
      }
      batch{
        id
        type
        code
        school {
          id
          code
        }
        classes {
          id
        }
        campaign {
          id 
        }
        students {
          id
          userData: user {
            id
            name
            profilePic {
              uri
            }
          }
        }
      }
      sessionStartDate
      sessionEndDate
      sessionRecordingLink
      bookingDate
      isFeedbackSubmitted
      attentionCount
      attentionAmount
      interactionCount
      interactionAmount
      studentBehaviour
      lengthOfContent
      learningObjectiveComponent
      contentImprovementSuggestion
      functionalitySuggestion
      generalSuggestion
      isFeedbackSubmitted
      sessionCommentByMentor
      ${getSlotNames()}
      sessionStatus
      attendance{
        student{
          id
          user{
            id
            name
          }
        }
        isPresent
        status
      }
      mentorSessionData: mentorSession{
        id
        sessionType
        userData: user {
          id
        }
      }
    }
  }
  `,
    type: 'batchSession/fetch',
    key: 'batchSession',
    changeExtractedData: (extractedData, originalData) => {
      if (originalData.batchSession) {
        extractedData.batchSession = originalData.batchSession
      }
      if (originalData.batchSession) {
        return extractedData
      }
      return {
        ...extractedData,
        batchSession: null
      }
    }
  })
)

export default batchSession
