import gql from 'graphql-tag'
import duck from '../../duck'

const deleteTimeTableSchedule = async (scheduleId) => await duck.query({
  query: gql`
    mutation{
        deleteTimetableSchedule(id: "${scheduleId}"){
          id
        }
    }
  `,
  type: 'timeTableSchedule/delete',
  key: 'deleteTimeTableSchedule'
})

export default deleteTimeTableSchedule