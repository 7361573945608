import { Button, Switch } from 'antd';
import React, { Component } from 'react';
import NameANdImageHeader from '../Constant/NameANdImageHeader';
import ScheduleControlReporting from '../Constant/ScheduleControlReporting';
import { AddTEacherNameStyle, DivFlexStyle, SchoolNameStyle } from '../TeachersTab.styles';
import Loader1 from "../../../../../assets/Loader1.png"
import ClassRoomControl from './ClassRoomControl';
import CourseControl from './CourseControl';
import Reporting from './Reporting';
import NotDone from "../../../../../assets/NotDone.png"
import Done from "../../../../../assets/Done.png"
import BImage from "../../../../../assets/BImage.png"
import Line from "../../../../../assets/Line.png"
export default class ScheduleManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SchedulingLearningClass: 0,
            SchedulingAdHocClass: 0,
            ReschedulingClass: 0,
            SchedulingClass: 0,
            DeleteSessions: 0,

            componentToggle: 0,
        };
    }
    ToggleComponentButtons = (num) => {
        this.setState({
            componentToggle: num,
        })
    }
    render() {
        const { schoolName, setMentorProfileRowData, mentorProfileRowData, onNext, goBackOnNext, maxTabAccesed } = this.props;
        const { componentToggle, SchedulingLearningClass, SchedulingAdHocClass, ReschedulingClass, SchedulingClass, DeleteSessions } = this.state;
        const buttonType = {
            type1: componentToggle === 0 ? 'primary' : '',
            type2: componentToggle === 1 ? 'primary' : '',
            type3: componentToggle === 2 ? 'primary' : '',
            type4: componentToggle === 3 ? 'primary' : '',
        }

        const obj = {
            Name: mentorProfileRowData.Name,
            Email: mentorProfileRowData.Email,
            Phone: mentorProfileRowData.Phone,
            setPassword: mentorProfileRowData.setPassword,
            confirmPassword: mentorProfileRowData.confirmPassword,
            SelectGrades: mentorProfileRowData.SelectGrades,
            AccessType: mentorProfileRowData.AccessType,
            CountryCode:mentorProfileRowData.CountryCode,
            displayName: mentorProfileRowData.displayName,
            SchedulingLearningClass: SchedulingLearningClass,
            SchedulingAdHocClass: SchedulingAdHocClass,
            ReschedulingClass: ReschedulingClass,
            SchedulingClass: SchedulingClass,
            DeleteSessions: DeleteSessions,

            CreatingAClassroom: 0,
            SendNotices: 0,
            AddInviteStudent: 0,
            AccessRecordings: 0,
            LiveSessionProgress: 0,

            AccessCourseControl: 0,
            CreateTests: 0,
            EvaluateTests: 0,
            AddToQBanks: 0,

            AccessReports: 0,
            DownloadReports: 0,
            ShareReports: 0,
        }
        return (
            <>
                <NameANdImageHeader schoolName={schoolName} Loader={Loader1} Loader1={Done} Loader2={NotDone} Loader3={NotDone} BImage={BImage} Line={Line} onNext={onNext} goBackOnNext={value => goBackOnNext(value)} maxTabAccesed={maxTabAccesed} />

                <div style={{ margin: "20px 0px" }}>
                    <ScheduleControlReporting
                        buttonType={buttonType}
                        ToggleComponentButtons={(num) => this.ToggleComponentButtons(num)}
                    ></ScheduleControlReporting>
                    {componentToggle === 0 ?
                        <div style={{ margin: "20px" }}>
                            <DivFlexStyle>
                                <div>Scheduling Learning Class</div>
                                <Switch
                                    onChange={() => {
                                        const obj = {
                                            Name: mentorProfileRowData.Name,
                                            Email: mentorProfileRowData.Email,
                                            Phone: mentorProfileRowData.Phone,
                                            setPassword: mentorProfileRowData.setPassword,
                                            confirmPassword: mentorProfileRowData.confirmPassword,
                                            SelectGradesId:mentorProfileRowData.SelectGradesId,
                                            SelectGrades: mentorProfileRowData.SelectGrades,
                                            AccessType: mentorProfileRowData.AccessType,
                                            CountryCode:mentorProfileRowData.CountryCode,
                                            displayName: mentorProfileRowData.displayName,
                                            SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass === 0 ? 1 : 0,
                                            SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
                                            ReschedulingClass: mentorProfileRowData.ReschedulingClass,
                                            SchedulingClass: mentorProfileRowData.SchedulingClass,
                                            DeleteSessions: mentorProfileRowData.DeleteSessions,

                                            CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
                                            SendNotices: mentorProfileRowData.SendNotices,
                                            AddInviteStudent: mentorProfileRowData.AddInviteStudent,
                                            AccessRecordings: mentorProfileRowData.AccessRecordings,
                                            LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

                                            AccessCourseControl: mentorProfileRowData.AccessCourseControl,
                                            CreateTests: mentorProfileRowData.CreateTests,
                                            EvaluateTests: mentorProfileRowData.EvaluateTests,
                                            AddToQBanks: mentorProfileRowData.AddToQBanks,

                                            AccessReports: mentorProfileRowData.AccessReports,
                                            DownloadReports: mentorProfileRowData.DownloadReports,
                                            ShareReports: mentorProfileRowData.ShareReports,
                                        }
                                        setMentorProfileRowData(obj)
                                    }}
                                    checked={mentorProfileRowData.SchedulingLearningClass === 0 ? true : false}
                                />
                            </DivFlexStyle>
                            <DivFlexStyle>
                                <div>Scheduling Ad-Hoc Class</div>
                                <Switch
                                    onChange={() => {
                                        const obj = {
                                            Name: mentorProfileRowData.Name,
                                            Email: mentorProfileRowData.Email,
                                            Phone: mentorProfileRowData.Phone,
                                            setPassword: mentorProfileRowData.setPassword,
                                            confirmPassword: mentorProfileRowData.confirmPassword,
                                            SelectGradesId:mentorProfileRowData.SelectGradesId,
                                            SelectGrades: mentorProfileRowData.SelectGrades,
                                            AccessType: mentorProfileRowData.AccessType,
                                            CountryCode:mentorProfileRowData.CountryCode,
                                            displayName: mentorProfileRowData.displayName,
                                            SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
                                            SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass === 0 ? 1 : 0,
                                            ReschedulingClass: mentorProfileRowData.ReschedulingClass,
                                            SchedulingClass: mentorProfileRowData.SchedulingClass,
                                            DeleteSessions: mentorProfileRowData.DeleteSessions,

                                            CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
                                            SendNotices: mentorProfileRowData.SendNotices,
                                            AddInviteStudent: mentorProfileRowData.AddInviteStudent,
                                            AccessRecordings: mentorProfileRowData.AccessRecordings,
                                            LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

                                            AccessCourseControl: mentorProfileRowData.AccessCourseControl,
                                            CreateTests: mentorProfileRowData.CreateTests,
                                            EvaluateTests: mentorProfileRowData.EvaluateTests,
                                            AddToQBanks: mentorProfileRowData.AddToQBanks,

                                            AccessReports: mentorProfileRowData.AccessReports,
                                            DownloadReports: mentorProfileRowData.DownloadReports,
                                            ShareReports: mentorProfileRowData.ShareReports,
                                        }

                                        setMentorProfileRowData(obj)
                                    }}
                                    checked={mentorProfileRowData.SchedulingAdHocClass === 0 ? true : false}
                                />
                            </DivFlexStyle>
                            <DivFlexStyle>
                                <div>Rescheduling Class</div>
                                <Switch
                                    onChange={() => {
                                        const obj = {
                                            Name: mentorProfileRowData.Name,
                                            Email: mentorProfileRowData.Email,
                                            Phone: mentorProfileRowData.Phone,
                                            setPassword: mentorProfileRowData.setPassword,
                                            confirmPassword: mentorProfileRowData.confirmPassword,
                                            SelectGradesId:mentorProfileRowData.SelectGradesId,
                                            SelectGrades: mentorProfileRowData.SelectGrades,
                                            AccessType: mentorProfileRowData.AccessType,
                                            CountryCode:mentorProfileRowData.CountryCode,
                                            displayName: mentorProfileRowData.displayName,
                                            SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
                                            SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
                                            ReschedulingClass: mentorProfileRowData.ReschedulingClass === 0 ? 1 : 0,
                                            SchedulingClass: mentorProfileRowData.SchedulingClass,
                                            DeleteSessions: mentorProfileRowData.DeleteSessions,

                                            CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
                                            SendNotices: mentorProfileRowData.SendNotices,
                                            AddInviteStudent: mentorProfileRowData.AddInviteStudent,
                                            AccessRecordings: mentorProfileRowData.AccessRecordings,
                                            LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

                                            AccessCourseControl: mentorProfileRowData.AccessCourseControl,
                                            CreateTests: mentorProfileRowData.CreateTests,
                                            EvaluateTests: mentorProfileRowData.EvaluateTests,
                                            AddToQBanks: mentorProfileRowData.AddToQBanks,

                                            AccessReports: mentorProfileRowData.AccessReports,
                                            DownloadReports: mentorProfileRowData.DownloadReports,
                                            ShareReports: mentorProfileRowData.ShareReports,
                                        }
                                        setMentorProfileRowData(obj)
                                    }}
                                    checked={mentorProfileRowData.ReschedulingClass === 0 ? true : false}
                                />
                            </DivFlexStyle>
                            <DivFlexStyle>
                                <div>Scheduling Class</div>
                                <Switch
                                    onChange={() => {
                                        const obj = {
                                            Name: mentorProfileRowData.Name,
                                            Email: mentorProfileRowData.Email,
                                            Phone: mentorProfileRowData.Phone,
                                            setPassword: mentorProfileRowData.setPassword,
                                            confirmPassword: mentorProfileRowData.confirmPassword,
                                            SelectGradesId:mentorProfileRowData.SelectGradesId,
                                            SelectGrades: mentorProfileRowData.SelectGrades,
                                            AccessType: mentorProfileRowData.AccessType,
                                            CountryCode:mentorProfileRowData.CountryCode,
                                            displayName: mentorProfileRowData.displayName,
                                            SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
                                            SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
                                            ReschedulingClass: mentorProfileRowData.ReschedulingClass,
                                            SchedulingClass: mentorProfileRowData.SchedulingClass === 0 ? 1 : 0,
                                            DeleteSessions: mentorProfileRowData.DeleteSessions,

                                            CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
                                            SendNotices: mentorProfileRowData.SendNotices,
                                            AddInviteStudent: mentorProfileRowData.AddInviteStudent,
                                            AccessRecordings: mentorProfileRowData.AccessRecordings,
                                            LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

                                            AccessCourseControl: mentorProfileRowData.AccessCourseControl,
                                            CreateTests: mentorProfileRowData.CreateTests,
                                            EvaluateTests: mentorProfileRowData.EvaluateTests,
                                            AddToQBanks: mentorProfileRowData.AddToQBanks,

                                            AccessReports: mentorProfileRowData.AccessReports,
                                            DownloadReports: mentorProfileRowData.DownloadReports,
                                            ShareReports: mentorProfileRowData.ShareReports,
                                        }
                                        setMentorProfileRowData(obj)
                                    }}
                                    checked={mentorProfileRowData.SchedulingClass === 0 ? true : false}
                                />
                            </DivFlexStyle>
                            <DivFlexStyle>
                                <div>Delete Sessions</div>
                                <Switch
                                    onChange={() => {
                                        const obj = {
                                            Name: mentorProfileRowData.Name,
                                            Email: mentorProfileRowData.Email,
                                            Phone: mentorProfileRowData.Phone,
                                            setPassword: mentorProfileRowData.setPassword,
                                            confirmPassword: mentorProfileRowData.confirmPassword,
                                            SelectGradesId:mentorProfileRowData.SelectGradesId,
                                            SelectGrades: mentorProfileRowData.SelectGrades,
                                            AccessType: mentorProfileRowData.AccessType,
                                            CountryCode:mentorProfileRowData.CountryCode,
                                            displayName: mentorProfileRowData.displayName,
                                            SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
                                            SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
                                            ReschedulingClass: mentorProfileRowData.ReschedulingClass,
                                            SchedulingClass: mentorProfileRowData.SchedulingClass,
                                            DeleteSessions: mentorProfileRowData.DeleteSessions === 0 ? 1 : 0,

                                            CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
                                            SendNotices: mentorProfileRowData.SendNotices,
                                            AddInviteStudent: mentorProfileRowData.AddInviteStudent,
                                            AccessRecordings: mentorProfileRowData.AccessRecordings,
                                            LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

                                            AccessCourseControl: mentorProfileRowData.AccessCourseControl,
                                            CreateTests: mentorProfileRowData.CreateTests,
                                            EvaluateTests: mentorProfileRowData.EvaluateTests,
                                            AddToQBanks: mentorProfileRowData.AddToQBanks,

                                            AccessReports: mentorProfileRowData.AccessReports,
                                            DownloadReports: mentorProfileRowData.DownloadReports,
                                            ShareReports: mentorProfileRowData.ShareReports,
                                        }
                                        setMentorProfileRowData(obj)
                                    }}
                                    checked={mentorProfileRowData.DeleteSessions === 0 ? true : false}
                                />
                            </DivFlexStyle>
                        </div> : componentToggle === 1 ? <ClassRoomControl mentorProfileRowData={mentorProfileRowData} setMentorProfileRowData={setMentorProfileRowData} /> : componentToggle === 2 ? <CourseControl mentorProfileRowData={mentorProfileRowData} setMentorProfileRowData={setMentorProfileRowData} /> : <Reporting mentorProfileRowData={mentorProfileRowData} setMentorProfileRowData={setMentorProfileRowData} />
                    }
                </div>
            </>
        );
    }
}
