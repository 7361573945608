// import { DatePicker } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import React from 'react'
import { CSVLink } from 'react-csv'
import Modal from '../Modal.styles'
import ReportHeader from '../../../../utils/generateReportHeaders'
import getDataFromLocalStorge from '../../../../utils/extract-from-localStorage'
import { fetchMentorMenteeEarnings, fetchBatchSessionEarnings } from '../../../../actions/mentorDashboardEarnings/fetchMentorMenteeSeccion'
import { MENTOR, ADMIN, UMS_ADMIN, SALES_EXECUTIVE } from '../../../../constants/roles'
import GenerateReportStyle from './GenerateReport.style'

/* eslint-disable */
class GenerateReportModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fromDate: null,
      toDate: null,
      loading: false,
      reportData: [],
      mentorIdParam: get(props, 'mentorIdParam')
    }
  }

  componentDidUpdate = (prevProps) => {
    const { visible } = this.props
    if (prevProps.visible !== visible) {
      this.handleDateChange(moment())
    }
  }
  fetchReportData = async () => {
    const userid = getDataFromLocalStorge('login.id')
    const role = getDataFromLocalStorge('login.role')
    const { mentorIdParam } = this.state
    let filter = '{and:['
    if (role === MENTOR) {
      filter += `{mentorSession_some: {user_some: {id_in: ["${userid}"]}}},`
    } else if (mentorIdParam && (role === ADMIN || role === UMS_ADMIN || role === SALES_EXECUTIVE)) {
      filter += `{mentorSession_some: {user_some: {id_in: ["${mentorIdParam}"]}}},`
    }
    this.setState({ loading: true })
    const { toDate, fromDate } = this.state
    let skip = 0
    let mmsDataCount
    let batchDataCount
    const mmsDataCombined = []
    const batchDataCombined = []
    do {
      const data = get(await fetchMentorMenteeEarnings(filter, skip * 20, toDate, fromDate), 'mentorMenteeSessions', [])
      mmsDataCount = data.length
      skip += 1
      mmsDataCombined.push(...data)
    } while (mmsDataCount === 20)
    skip = 0
    do {
      const dataBatch = get(await fetchBatchSessionEarnings(filter, skip * 20, toDate, fromDate), 'batchSessions', [])
      batchDataCount = dataBatch.length
      skip += 1
      batchDataCombined.push(...dataBatch)
    } while (batchDataCount === 20)
    this.generateReport(mmsDataCombined, batchDataCombined)
  }

  getDates = (startDate, stopDate) => {
    const dateArray = []
    let currentDate = moment(startDate).endOf('M')
    while (currentDate <= moment(stopDate).endOf('M')) {
      dateArray.push({
        date: moment(currentDate).endOf('M').format('ll'),
        month: moment(currentDate).month()
      })
      currentDate = moment(currentDate).add(1, 'M')
    }
    return dateArray
  }

  getChannelType = (referral, source) => {
    if (referral) {
      return 'Referral'
    } else if (source === 'website') return 'Organic'
    return source
  }

  generateReport = (mentorMenteeSessions, batchSessions) => {
    const reportData = []
    const reportObj = {}
    if (mentorMenteeSessions.length > 0) {
      mentorMenteeSessions.forEach((event, index) => {
        const mentorId = get(event, 'mentorSession.user.id', '')
        const topicOrder = get(event, 'topic.order', 1)
        const country = get(event, 'country', 'india')
        const leadWon = get(event, 'salesOperation.leadStatus', '')
        const sessionRatio = get(event, 'salesOperation.userPaymentPlan.product.type', 'oneToOne')
        if (reportObj[`${mentorId}`]) {
          reportObj[`${mentorId}`].mentorMenteeSessionCount = get(reportObj, `${mentorId}.mentorMenteeSessionCount`, 0) + 1
          if (topicOrder === 1) {
            reportObj[`${mentorId}`].trialSessions = get(reportObj, `${mentorId}.trialSessions`, 0) + 1
            if (leadWon === 'won') {
              reportObj[`${mentorId}`][`conversionBonus${sessionRatio}`] = get(reportObj, `${mentorId}.conversionBonus${sessionRatio}`, 0) + 1
              if (sessionRatio === 'oneToOne') {
                reportObj[`${mentorId}`].b2cEarnings = get(reportObj, `${mentorId}.b2cEarnings`, 0) + 1000
              } else if (sessionRatio === 'oneToTwo') {
                reportObj[`${mentorId}`].b2cEarnings = get(reportObj, `${mentorId}.b2cEarnings`, 0) + 750
              } else {
                reportObj[`${mentorId}`].b2cEarnings = get(reportObj, `${mentorId}.b2cEarnings`, 0) + 500
              }
            }
            if (country === 'india') {
              reportObj[`${mentorId}`].inIndia = get(reportObj, `${mentorId}.inIndia`, 0) + 1
              reportObj[`${mentorId}`].b2cEarnings = get(reportObj, `${mentorId}.b2cEarnings`, 0) + 200
            } else {
              reportObj[`${mentorId}`].inNotIndia = get(reportObj, `${mentorId}.inNotIndia`, 0) + 1
              reportObj[`${mentorId}`].b2cEarnings = get(reportObj, `${mentorId}.b2cEarnings`, 0) + 250
            }
          } else {
            reportObj[`${mentorId}`].paidSessions = get(reportObj, `${mentorId}.paidSessions`, 0) + 1
            if (sessionRatio === 'oneToOne') {
              reportObj[`${mentorId}`].b2cEarnings = get(reportObj, `${mentorId}.b2cEarnings`, 0) + 200
            } else if (sessionRatio === 'oneToTwo') {
              reportObj[`${mentorId}`].b2cEarnings = get(reportObj, `${mentorId}.b2cEarnings`, 0) + 250
            } else {
              reportObj[`${mentorId}`].b2cEarnings = get(reportObj, `${mentorId}.b2cEarnings`, 0) + 300
            }
            reportObj[`${mentorId}`][`${sessionRatio}`] = get(reportObj, `${mentorId}.${sessionRatio}`, 0) + 1
          }
          if (leadWon === 'won') {
            reportObj[`${mentorId}`][`conversionBonus${sessionRatio}`] = 1
          }
        } else {
          reportObj[`${mentorId}`] = {}
          reportObj[`${mentorId}`].mentorMenteeSessionCount = 1
          reportObj[`${mentorId}`].batchSessions = 0
          reportObj[`${mentorId}`].b2b = 0
          reportObj[`${mentorId}`].b2b2c = 0
          reportObj[`${mentorId}`].b2cEarnings = 0
          reportObj[`${mentorId}`].b2bEarnings = 0
          reportObj[`${mentorId}`].b2b2cEarnings = 0
          reportObj[`${mentorId}`].mentorName = get(event, 'mentorSession.user.name', '')
          if (topicOrder === 1) {
            reportObj[`${mentorId}`].trialSessions = 1
            if (leadWon === 'won') {
              reportObj[`${mentorId}`][`conversionBonus${sessionRatio}`] = 1
              if (sessionRatio === 'oneToOne') {
                reportObj[`${mentorId}`].b2cEarnings += 1000
              } else if (sessionRatio === 'oneToTwo') {
                reportObj[`${mentorId}`].b2cEarnings += 750
              } else {
                reportObj[`${mentorId}`].b2cEarnings += 500
              }
            }
            reportObj[`${mentorId}`].paidSessions = 0
            if (country === 'india') {
              reportObj[`${mentorId}`].inIndia = 1
              reportObj[`${mentorId}`].b2cEarnings += 200
              reportObj[`${mentorId}`].inNotIndia = 0
            } else {
              reportObj[`${mentorId}`].inNotIndia = 1
              reportObj[`${mentorId}`].b2cEarnings += 250
              reportObj[`${mentorId}`].inIndia = 0
            }
          } else {
            reportObj[`${mentorId}`].trialSessions = 0
            reportObj[`${mentorId}`].paidSessions = 1
            if (sessionRatio === 'oneToOne') {
              reportObj[`${mentorId}`].b2cEarnings += 200
            } else if (sessionRatio === 'oneToTwo') {
              reportObj[`${mentorId}`].b2cEarnings += 250
            } else {
              reportObj[`${mentorId}`].b2cEarnings += 300
            }
            reportObj[`${mentorId}`][`${sessionRatio}`] = 1
            reportObj[`${mentorId}`].inIndia = 0
            reportObj[`${mentorId}`].inNotIndia = 0
          }
        }
        reportObj[`${mentorId}`].totalEarnings = reportObj[`${mentorId}`].b2cEarnings + reportObj[`${mentorId}`].b2b2cEarnings + reportObj[`${mentorId}`].b2bEarnings
      })
    }
    if (batchSessions.length > 0) {
      batchSessions.forEach((event, index) => {
        const batchType = get(event, 'batch.type', '')
        const mentorId = get(event, 'batch.allottedMentor.id', '')
        if (reportObj[`${mentorId}`]) {
          reportObj[`${mentorId}`].batchSessions = get(reportObj, `${mentorId}.batchSessions`, 0) + 1
          if (batchType === 'b2b') {
            reportObj[`${mentorId}`].b2b = get(reportObj, `${mentorId}.b2b`, 0) + 1
            reportObj[`${mentorId}`].b2bEarnings = get(reportObj, `${mentorId}.b2bEarnings`, 0) + 200
          } else {
            reportObj[`${mentorId}`].b2b2c = get(reportObj, `${mentorId}.b2b2c`, 0) + 1
            reportObj[`${mentorId}`].b2b2cEarnings = get(reportObj, `${mentorId}.b2b2cEarnings`, 0) + 200
          }
        } else {
          reportObj[`${mentorId}`] = {}
          reportObj[`${mentorId}`].mentorName = get(event, 'batch.allottedMentor.name', '')
          reportObj[`${mentorId}`].batchSessions = 1
          reportObj[`${mentorId}`].trialSessions = 0
          reportObj[`${mentorId}`].inIndia = 0
          reportObj[`${mentorId}`].inNotIndia = 0
          reportObj[`${mentorId}`].paidSessions = 0
          reportObj[`${mentorId}`].b2cEarnings = 0
          reportObj[`${mentorId}`].b2bEarnings = 0
          reportObj[`${mentorId}`].b2b2cEarnings = 0
          if (batchType === 'b2b') {
            reportObj[`${mentorId}`].b2b = 1
            reportObj[`${mentorId}`].b2bEarnings += 200
          } else {
            reportObj[`${mentorId}`].b2b2c = 1
            reportObj[`${mentorId}`].b2b2cEarnings += 200
          }
        }
        reportObj[`${mentorId}`].totalEarnings = reportObj[`${mentorId}`].b2cEarnings + reportObj[`${mentorId}`].b2b2cEarnings + reportObj[`${mentorId}`].b2bEarnings
      })
    }
    let index = 1
    for (const key in reportObj) {
      reportObj[`${key}`].index = index
      reportData.push(reportObj[`${key}`])
      index += 1
    }
    if (reportData.length > 0) {
      this.setState({
        reportData
      }, () => setTimeout(() => {
        this.reportRef.current.link.click()
      }))
    } else {
      notification.warn({
        message: 'No data available to download'
      })
    }
    this.setState({
      loading: false
    })
    console.log('loading', this.state.loading)
  }

  onCloseModal = () => {
    this.setState({
      fromDate: null,
      toDate: null,
      loading: false
    }, this.props.onClose)
  }

  handleDateChange = (month) => {
    this.setState({
      toDate: month ? moment(month).startOf('month') : '',
      fromDate: month ? moment(month).endOf('month') : '',
    })
  }
  disabledDate = (current) => {
    return current && moment(current).startOf('month') > moment();
  }
  reportRef = React.createRef()
  render() {
    const { visible } = this.props
    const { loading, reportData } = this.state
    return (
      <Modal.WithBackdrop visible={visible}>
        <Modal.ModalBox visible={visible} minWidth='300px'>
          <Modal.CloseIcon theme='twoTone'
            onClick={this.onCloseModal}
          />
          {/* Modal Header */}
          <Modal.Header>
            <Modal.FlexContainer style={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Modal.HeaderTitle>
                Generate Report
              </Modal.HeaderTitle>
            </Modal.FlexContainer>
          </Modal.Header>
          {/* Modal Contents */}
          <Modal.Content>
            <CSVLink
              style={{ display: 'none' }}
              filename='Report.csv'
              data={reportData}
              headers={ReportHeader}
              ref={this.reportRef}
            />
            <Modal.FlexContainer flexDirection='row' style={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <GenerateReportStyle.DatePicker
                defaultValue={moment(new Date())}
                format='MM/YYYY'
                onChange={this.handleDateChange}
                disabledDate={this.disabledDate}
              />
            </Modal.FlexContainer>
          </Modal.Content>
          {/* Modal Footer */}
          <Modal.Footer>
            <Modal.FlexContainer flexDirection='row' style={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <Modal.PrimaryButton
                type='primary'
                icon='download'
                onClick={this.fetchReportData}
                loading={loading}
              >
                {loading && <Modal.Spinner />}
                Download Reports
              </Modal.PrimaryButton>
            </Modal.FlexContainer>
          </Modal.Footer>
        </Modal.ModalBox>
      </Modal.WithBackdrop>
    )
  }
}

export default GenerateReportModal
