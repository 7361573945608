import styled from 'styled-components'
import { Table, Tag } from 'antd'

const EarningsStyle = styled.div`
    height: 100%;
    width: 100%;
    margin: 0 auto;
    font-family: 'Inter' !important;
    font-style: normal;
`
// top info cards container
const ShortInfoCardsContainer = styled.div`
    font-family: Inter;
    display: flex;
    justify-content: space-evenly;
`
const ShortInfoEarningsCard = styled.div`
    display: flex;
    background: #FFFFFF;
    border-radius: 16px;
    width: 45%;
    padding: 16px;
    flex-direction: column;
`
const EarningsCardInfo = styled.div`
    display: flex;
    justify-content: space-between;
`
const EarningsCardTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 40%;
`
const TotalEarningsText = styled.p`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #212121;
`
const EarningsMonth = styled.p`
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #757575;
    padding-top: 7px;
`
const EarningsCardButton = styled.button`
   width: 23%;
   background: #8C61CB;
   border-radius: 8px;
   border: none;
   color: #fff;
   padding: 0px;
   font-weight: 500;
   font-size: 12px;
   line-height: 18px;
`
const TotalEarnings = styled.div`
    display: flex;
    img:first-child {
        height: fit-content;
        padding-top:2px;
    }
    img:last-child {
        height: fit-content;
        padding-top:4px;
    }
    p {
        padding-right: 6px;
        padding-left: 7px;
    }
`
const CurrentMonthEarningsContainer = styled.div`
    display: flex;
`
const CurrentMonthEarnings = styled.p`
    font-weight: 600;
    font-size: 56px;
    line-height: 72px;
    color: #212121;
    margin-bottom: 0px;
`
const PercentageIncreaseContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: 20px;
    img {
        height: fit-content;
    }
`
const PercentageIncrease = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #01AA93;
    padding-top: 15px;
    padding-left: 3px;
    padding-right: 10px;
`

// graphs and table container
const GraphAndTableContainer = styled.div`
    background-color: #ffffff;
    border-radius: 16px;
    width: 94%;
    margin: auto;
    margin-top: 25px;
    padding: 16px;
`
const DropdownTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    select {
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        box-sizing: border-box;
        border-radius: 8px;
        width: 60%;
        padding: 9px 8px 9px 12px;
    }

    form {
        width: 25%;
    }
    label {
        line-height: 16px;
        color: #212121;
        margin-right: 10px;
    }
`
const ContainerTitle = styled.p`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #212121;
`

const RecentTransactionsTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const StyledTable = styled(Table)`
    .ant-table-thead {
        & > tr { 
            & > th { 
                color: white;
                background: #F5F5F5 !important;
                color: #212121;
                font-weight: 600;
            }
        }
    }
    .ant-table-tbody {
        & > tr { 
            & > td { 
                background: #ffffff !important;
                line-height: 21px;
                font-weight: normal;
                color: #212121;
                font-style: normal;
                border-bottom: none;
                border-top: 1px solid #e8e8e8;
            }
            & > td:empty {
                border-bottom: none;
                border-top: none;
            }
        }
    }
`
const StyledTag = styled(Tag)`
        border: none !important;
        border-radius: 4px !important;
        padding: 2px 10px !important;
        color: ${props => {
    if (props.status === 'Successfull') {
      return '#01AA93 !important'
    } else if (props.status === 'Pending') {
      return '#FAAD14 !important'
    } else if (props.status === 'Error') {
      return '#D34B57 !important'
    }
    return '#616161 !important'
  }};
        background-color: ${props => {
    if (props.status === 'Successfull') {
      return '#E6FFFB !important'
    } else if (props.status === 'Pending') {
      return '#FEF6E6 !important'
    } else if (props.status === 'Error') {
      return '#FAEBEC !important'
    }
    return '#EEEEEE !important'
  }};
        background-color: ${props => (props.isSelected ? 'black' : '#C4C4C4')};
        span {
            padding: 4px 6px !important;
        }
        svg {
            margin-right: 5px;
        }
`
const TransactionsStyledButton = styled.button`
    background: #8C61CB;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    width: 14%;
    padding: 0px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
`
const PriceBreakdownTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`

EarningsStyle.ShortInfoCardsContainer = ShortInfoCardsContainer

EarningsStyle.ShortInfoEarningsCard = ShortInfoEarningsCard
EarningsStyle.EarningsCardButton = EarningsCardButton
EarningsStyle.EarningsCardInfo = EarningsCardInfo
EarningsStyle.EarningsCardTitleContainer = EarningsCardTitleContainer
EarningsStyle.TotalEarningsText = TotalEarningsText
EarningsStyle.EarningsMonth = EarningsMonth
EarningsStyle.TotalEarnings = TotalEarnings
EarningsStyle.CurrentMonthEarningsContainer = CurrentMonthEarningsContainer
EarningsStyle.CurrentMonthEarnings = CurrentMonthEarnings
EarningsStyle.PercentageIncreaseContainer = PercentageIncreaseContainer
EarningsStyle.PercentageIncrease = PercentageIncrease

EarningsStyle.GraphAndTableContainer = GraphAndTableContainer
EarningsStyle.ContainerTitle = ContainerTitle
EarningsStyle.DropdownTitleContainer = DropdownTitleContainer
EarningsStyle.RecentTransactionsTitleContainer = RecentTransactionsTitleContainer
EarningsStyle.TransactionsStyledButton = TransactionsStyledButton

EarningsStyle.PriceBreakdownTitleContainer = PriceBreakdownTitleContainer

EarningsStyle.StyledTable = StyledTable
EarningsStyle.StyledTag = StyledTag
export default EarningsStyle
