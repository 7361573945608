import React from 'react'
import { DatePicker, TimePicker, Select } from 'antd'
import { get } from 'lodash'
import {
  AddGradeButton, CloseIcon, FlexContainer,
  SectionButton, StyledDivider
} from '../../../SchoolOnBoarding.style'
import moment from 'moment'
import {DropDownContainer} from './ScheduleWokingOrEventDay.style'
import MainModal from './../../../../../components/MainModal/';
import addTimeTableSchedule from './../../../../../actions/timeTableSchedule/addTimetableSchedule';
import updateTimeTableSchedule from './../../../../../actions/timeTableSchedule/updateTimeTableSchedule';
import deleteTimeTableSchedule from './../../../../../actions/timeTableSchedule/deleteTimeTableSchedule';
import toastrMessage from './../../../../../utils/messages/toastrMessage';

const { Option } = Select;
const TimePickerFormat = 'hh mm a'

const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

const divStyle = { width: '80%', marginBottom: '2vw' }

const ScheduleWokingOrEventDay = ({showModal,
  selectedLevel,
  closeModal,
  chosenSchool,
  batchCodes,
  chosenSchoolId,
  isModalEventType,
  fetchStatus,
  updateStatus,
  allScheduledEvents}) => {
  const [selectedTimeRange, setSelectedTimeRange] = React.useState({
      startHour: null,
      endHour: null
    })
    const [selectedDateRange, setSelectedDateRange] = React.useState({
      startDate: null,
      endDate: null
    })
    const [timeValues, setTimeValues]= React.useState({
      startTime: null,
      endTime: null
    })
    const [selectedWeekDays, setSelectedWeekDays] = React.useState([])
    const [selectedBatches, setSelectedBatches] = React.useState([])
    const [selectedEventType, setSelectedEventType] = React.useState('')

    const onModalClose=()=> {
        closeModal()
        setSelectedTimeRange({
            startHour: null,
            endHour: null
        })
        setSelectedDateRange({
            startDate: null,
            endDate: null
        })
        setTimeValues({
            startTime: null,
            endTime: null
        })
        setSelectedWeekDays([]) 
          }
    const firstUpdate = React.useRef(true);
    React.useEffect(()=>{
      if (fetchStatus.success) {
        onModalClose()
      }
    },[fetchStatus.success])
    React.useEffect(()=>{
      if (updateStatus.success) {
        onModalClose()
      }
    },[updateStatus.success])
    React.useEffect(()=>{
      if (firstUpdate.current) {
        firstUpdate.current = false;
        return;
      }
      if (fetchStatus.error) {
        toastrMessage(fetchStatus.error, 'error', 'error', fetchStatus.errorMessage)
      }
    },[fetchStatus.error])
    React.useEffect(()=>{
      if (updateStatus.error) {
        toastrMessage(updateStatus.error, 'error', 'error', updateStatus.errorMessage)
      }
    },[updateStatus.error])

    const addSelectedWeekDays = (weekDay) => {
        const newDays = [...selectedWeekDays]
        const isExist = newDays.find(day => day === weekDay)
        if(!isExist) {
            setSelectedWeekDays([...newDays, weekDay])
        }
        else {
            setSelectedWeekDays(newDays.filter(day => day !== weekDay))
        }
      }
    const onConfirm = async () => {
        const batchConnectIds = batchCodes.map(batch=>batch.id)
        let filteredScheduleEvents = []
        filteredScheduleEvents = allScheduledEvents.length > 0 && allScheduledEvents.filter(item => {
          if ((get(item, 'type') === 'workingDay' && !isModalEventType) || (get(item, 'type') === 'event' && isModalEventType)) {
            const startTemp = moment(item.startDate).format("YYYY-MM-DD")
            const endTemp = moment(item.endDate).format("YYYY-MM-DD")
            const sStartTemp = moment(selectedDateRange.startDate).format("YYYY-MM-DD")
            const sEndTemp = moment(selectedDateRange.endDate).format("YYYY-MM-DD")
            const ssd = new Date(startTemp)
            const sed = new Date(endTemp)
            const sd = new Date(sStartTemp)
            const ed = new Date(sEndTemp)
            return (sd >= ssd && sd <=sed) || (ed >= ssd && ed <=sed) || (sd <= ssd && ed >=sed)
          }
        })
        let minDate = new Date(selectedDateRange.startDate)
        let maxDate = new Date(selectedDateRange.endDate)
        if (filteredScheduleEvents.length > 0) {
          for(const item of filteredScheduleEvents) {
            const tempMinDate = new Date(item.startDate)
            const tempMaxDate = new Date(item.endDate)
            if (minDate >= tempMinDate && maxDate <= tempMaxDate) {
              break
            }
            if (tempMinDate < minDate) {
              minDate = tempMinDate
            }
            if (tempMaxDate > maxDate) {
              maxDate = tempMaxDate
            }
          }
        }
        const input = {
          type: isModalEventType ? 'event' : 'workingDay',
          startDate: moment(minDate).set({hour:0,minute:0,second:0,millisecond:0}).toISOString(),
          endDate: moment(maxDate).set({hour:0,minute:0,second:0,millisecond:0}).toISOString()
        }
        if(isModalEventType) {
            input['eventType'] = selectedEventType
        }
        weekDays.forEach(weekDay => {
          if (selectedWeekDays.includes(weekDay)) {
            input[weekDay] = true
          } else {
            input[weekDay] = false
          }
        })
        Array.from(Array(24).keys()).forEach(i => {
          if (selectedTimeRange.startHour <=i && i<= selectedTimeRange.endHour) {
            input[`slot${i}`] = true;
          } else {
            input[`slot${i}`] = false;
          }
        })
        if (filteredScheduleEvents.length > 0) {
          const remainingSchedule = filteredScheduleEvents.slice(1)
          if (remainingSchedule.length > 0) {
            for(const item of remainingSchedule) {
              await deleteTimeTableSchedule(item.id)
            }
          }
          const id = get(filteredScheduleEvents, '[0].id')
          if (selectedLevel === 'school') {
            await updateTimeTableSchedule(id, input, chosenSchoolId, null)
          } else {
            await updateTimeTableSchedule(id, input, null, selectedBatches)
          }
        } else {
          if (selectedLevel === 'school') {
            await addTimeTableSchedule(input, chosenSchoolId, null)
          } else {
            console.log('select ', selectedBatches)
            await addTimeTableSchedule(input, null, selectedBatches)
          }
        }
      }
    const disableConfirm = () => {
        const {startHour, endHour} = selectedTimeRange
        const {startDate, endDate} = selectedDateRange
        if ((!startDate && !endDate) ||
          (!startHour && !endHour) ||
          !selectedWeekDays.length) {
          return true
        }
        return false
      }

    const { startHour } = selectedTimeRange
    let { endHour } = selectedTimeRange
    let totalSlots = 0
    endHour = endHour === 0 ? 24 : endHour
    if (startHour === null || endHour === null || endHour < startHour) {
      totalSlots = 0
    } else {
      totalSlots = endHour - startHour
    }

    return (
        <MainModal
        visible={showModal}
        onCancel={onModalClose}
        maskClosable
        bodyStyle={{ padding: 0 }}
        closable={false}
        width='650px'
        centered
        destroyOnClose
        footer={null}
      >
        <FlexContainer noPadding style={{ width: '100%' }}>
          <div style={{ padding: '0.5vw 1.5vw' }}>
            <h1>Schedule a Recurring Slot</h1>
            <h3 style={{ opacity: '0.7' }}>{chosenSchool}</h3>
            <CloseIcon onClick={onModalClose} />
          </div>
        </FlexContainer>
        <StyledDivider
          style={{ marginBottom: '1vw' }}
        />
        <FlexContainer
          noPadding
          justify='center'
          style={{ flexDirection: 'column', padding: '1vw' }}
        >
        {
            isModalEventType ? (<>
                <DropDownContainer style={divStyle}>
            <h3>Event Type</h3>
            <Select style={{ width: 280 }} allowClear onChange={(e)=>setSelectedEventType(e)}>
                <Option value="exam">Exam</Option>
                <Option value="holiday">Holiday</Option>
            </Select>
        </DropDownContainer>
            </>) : null
        }
        {
            selectedLevel==='batch' ? (
              <DropDownContainer style={divStyle}>
                  <h3>Select Batches</h3>
                  <Select
                    style={{ width: 280 }} 
                    mode="multiple" 
                    maxTagCount={2}
                    allowClear
                    onChange={(e)=>setSelectedBatches(e)}
                    >
                    {batchCodes.map(batch =>(<Option value={batch.id} key={batch.id}>{batch.code}</Option>))}
                  </Select>
              </DropDownContainer>
            ) : null
        }
          <div style={divStyle}>
            <h3>Date</h3>
            <FlexContainer justify='flex-start' noPadding>
              <DatePicker
                onChange={(value) => {
                  const dateRange = { ...selectedDateRange, startDate: value }
                  setSelectedDateRange(dateRange)
                }}
                placeholder='Start Date'
                allowClear={false}
                value={selectedDateRange.startDate}
                disabledDate={(current) => current &&
                  current < new Date().setDate((new Date().getDate()) - 1)
                }
                format='DD MMMM YYYY'
                style={{ width: '100%', maxWidth: '200px' }}
              />
              <span style={{ margin: '0 8px' }}><strong>To</strong></span>
              <DatePicker
                onChange={(value) => {
                  const dateRange = { ...selectedDateRange, endDate: value }
                    setSelectedDateRange(dateRange)
                }}
                placeholder='End Date'
                allowClear={false}
                value={selectedDateRange.endDate}
                disabled={!selectedDateRange.startDate}
                disabledDate={(current) => (
                  current &&
                    current < new Date(selectedDateRange.startDate)
                )}
                format='DD MMMM YYYY'
                style={{ width: '100%', maxWidth: '200px' }}
              />
            </FlexContainer>
          </div>
          <div style={divStyle}>
            <h3>Timing</h3>
            <FlexContainer justify='flex-start' noPadding>
              <TimePicker
                format={TimePickerFormat}
                allowClear={false}
                value={timeValues.startTime}
                placeholder='Start'
                disabled={selectedDateRange.startDate === null || selectedDateRange.endDate === null}
                disabledHours={() => {
                  if (new Date().setHours(0, 0, 0, 0)
                    === new Date(selectedDateRange.startDate).setHours(0, 0, 0, 0)) {
                    return [...Array(new Date().getHours() + 1).keys()]
                  }
                }}
                onChange={(value) => {
                  if (value && get(value, '_d')) {
                    const selectedRange = {
                      ...selectedTimeRange,
                      startHour: get(value, '_d').getHours(),
                    }
                    setSelectedTimeRange(selectedRange)
                    setTimeValues({
                      ...timeValues,
                        startTime: value,
                    })
                  }
                }}
                style={{ width: '100%', maxWidth: '200px' }}
              />
              <span style={{ margin: '0 8px' }}><strong>To</strong></span>
              <TimePicker
                format={TimePickerFormat}
                allowClear={false}
                value={timeValues.endTime}
                placeholder='End'
                disabled={selectedTimeRange.startHour === null}
                disabledHours={() =>
                  [...Array(selectedTimeRange.startHour + 1).keys()]
                }
                onChange={(value) => {
                  const endTime = value
                  if (value && value._d) {
                    const selectedRange = {
                      ...selectedTimeRange,
                      endHour: get(value, '_d').getHours(),
                    }
                    value = get(value, '_d').getHours() === 0 ? 24 : get(value, '_d').getHours()
                    if (value > selectedTimeRange.startHour) {
                        setSelectedTimeRange(selectedRange)
                        setTimeValues({
                          ...timeValues,
                            endTime
                        })
                    }
                  }
                }}
                style={{ width: '100%', maxWidth: '200px' }}
              />
            </FlexContainer>
          </div>
          <div style={divStyle}>
            <h3>Select Days</h3>
            <FlexContainer noPadding justify='flex-start'>
              <>
                {weekDays.map(weekDay => (
                  <AddGradeButton
                    type={selectedWeekDays.includes(weekDay) ? 'primary' : ''}
                    onClick={() => addSelectedWeekDays(weekDay)}
                    shape='circle'
                    style={{
                      margin: '0 5px',
                      textTransform: 'capitalize'
                    }}
                  >
                    {weekDay.slice(0, 2)}
                  </AddGradeButton>
                ))}
              </>
            </FlexContainer>
          </div>
          <div style={{ ...divStyle, textAlign: 'center' }}>
            <h3>Total Slots Chosen: {totalSlots}</h3>
            <StyledDivider
              style={{ width: '100%' }}
            />
          </div>
          <FlexContainer style={{ width: '40%' }}>
            <SectionButton
              campaign
              style={{ margin: '0 10px' }}
              type='primary'
              disabled={disableConfirm()}
              onClick={onConfirm}
              loading={fetchStatus.loading || updateStatus.loading}
            >Confirm Details
            </SectionButton>
          </FlexContainer>
        </FlexContainer>
      </MainModal>
  )
}

export default ScheduleWokingOrEventDay 