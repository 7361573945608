import uploadFile from "../../../utils/uploadFile"
import removePictureFromEventUser from "./removePictureFromEventUser"

const addPictureToEventSpeaker = async ({
    file,
    userId,
    prevFileId,
  }) => {
    let data = {}
    if (userId) {
      if (file) {
        const mappingInfo = file && {
          typeId: userId,
          type: 'User',
          typeField: 'profilePic'
        }
        const fileInfo = {
          fileBucket: 'python'
        }
        if (file) {
          if (prevFileId) {
            await removePictureFromEventUser(userId, prevFileId).then(async () => {
              await uploadFile(file, fileInfo, mappingInfo).then(res => data = res)
            })
          } else {
            await uploadFile(file, fileInfo, mappingInfo).then(res => data = res)
          }
        }
      }
    }
    return data
  }
  
  export default addPictureToEventSpeaker