import React, { useEffect, useState } from 'react'
import { get, update } from 'lodash'
import { ComponentHolder,StyledTextArea} from './CodeSyntax.style'

const CodeSyntax =(props)=> {
    const[value,setValue]=useState('')
    const handleValue=(val)=>{
        setValue(val)
        update(val)
        props.Helper(val,'codeInput','',props.slideContents,props.number)
    }
    useEffect(()=>{
        setValue(get(props.selectedContent,'codeInput',''))
    },[props.number,get(props.selectedContent,'codeInput','')])
    return <ComponentHolder key={props.number}>
        <StyledTextArea placeholder='Text Here' onChange={(e)=>{handleValue(e.target.value)}} value={value} defaultValue={value}>
        </StyledTextArea>
    </ComponentHolder>
}
export default CodeSyntax; 
