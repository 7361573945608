import gql from 'graphql-tag'
import duck from '../../duck'

const updateTrainer = async (id, input) => {
    await duck.query({
        query: gql`
            mutation updateUser($input: UserUpdate!) {
                updateUser(id:"${id}",input: $input) {
                    id
                    name
                    email
                    username
                    roles
                    phone {
                    countryCode
                    number
                    }
                }
            }
            `,
        variables: {
            input
        },
        type: 'users/update',
        key: 'schoolTrainer',
    })
}

export default updateTrainer
