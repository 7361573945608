import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import withUpdatedDashboardNav from '../../../components/withUpdatedNav'
import { EVENT_DASHBOARD } from '../../../constants/navItemsConst'
import EventSettings from './EventSettings'
import injectProps from '../../../components/injectProps'

const EventSettingsSideNav = withUpdatedDashboardNav(EventSettings)({
    title: 'Event Overview',
    activeNavItem: 'Settings',
    showUMSNavigation: true,
    renderNavItems: EVENT_DASHBOARD,
  })
  
  const mapStateToProps = state => ({
    eventTagsData: state.data.getIn(['contentTags', 'data']),
    eventTagFetchStatus: state.data.getIn(['contentTags', 'fetchStatus', 'contentTags']),
    eventTagAddStatus: state.data.getIn(['contentTags', 'addStatus', 'contentTags']),
    eventTagAddFailure: state.data.getIn(['errors', 'contentTags/add']),
    eventTagUpdateStatus: state.data.getIn(['contentTags', 'updateStatus', 'contentTags']),
    eventTagUpdateFailure: state.data.getIn(['errors', 'contentTags/update']),
    eventTagsCount: state.data.getIn(['contentTagsMeta', 'data', 'count']),
    
    eventCategoriesData: state.data.getIn(['eventCategories', 'data']),
    eventCategoryFetchStatus: state.data.getIn(['eventCategories', 'fetchStatus', 'eventCategories']),
    eventCategoryAddStatus: state.data.getIn(['eventCategories', 'addStatus', 'eventCategories']),
    eventCategoryAddFailure: state.data.getIn(['errors', 'eventCategories/add']),
    eventCategoryUpdateStatus: state.data.getIn(['eventCategories', 'updateStatus', 'eventCategories']),
    eventCategoryUpdateFailure: state.data.getIn(['errors', 'eventCategories/update']),
    eventCategoriesCount: state.data.getIn(['eventCategoriesMeta', 'data', 'count']),

    eventVariablesData: state.data.getIn(['eventVariables', 'data']),
    eventVariableFetchStatus: state.data.getIn(['eventVariables', 'fetchStatus', 'eventVariables']),
    eventVariableAddStatus: state.data.getIn(['commsVariables', 'addStatus', 'commsVariables']),
    eventVariableAddFailure: state.data.getIn(['errors',  'commsVariables/add']),
    eventVariablesCount: state.data.getIn(['commsVariablesMeta', 'data', 'count']),

    eventSpeakerData: state.data.getIn(['eventSpeakerProfiles', 'data']),
    eventSpeakerFetchStatus: state.data.getIn(['eventSpeakerProfiles', 'fetchStatus', 'eventSpeakerProfiles']),
    eventSpeakerAddStatus: state.data.getIn(['eventSpeakerProfiles', 'addStatus', 'eventSpeakerProfiles']),
    eventSpeakerUpdateStatus: state.data.getIn(['eventSpeakerProfiles', 'updateStatus', 'eventSpeakerProfiles']),
    eventSpeakerAddFailure: state.data.getIn(['errors', 'eventSpeakerProfiles/add']),
    eventSpeakerUpdateFailure: state.data.getIn(['errors', 'eventSpeakerProfiles/update']),
    userAddStatus: state.data.getIn(['user', 'addStatus', 'user']),
    userUpdateStatus: state.data.getIn(['user', 'updateStatus', 'user']),
    userAddFailure: state.data.getIn(['errors', 'user/add']),
    userUpdateFailure: state.data.getIn(['errors', 'user/update']),
    eventSpeakersCount: state.data.getIn(['eventSpeakerProfilesMeta', 'data', 'count'])
  })
  
  const EventSettingsSideNavWithExtraProps = injectProps({
    notification
  })(EventSettingsSideNav)
  export default connect(mapStateToProps)(withRouter(EventSettingsSideNavWithExtraProps))
  