import { Formik, Form } from 'formik'
import React, { useState, useRef, useEffect } from 'react'
import { get } from 'lodash';
import getFullPath from '../../../../utils/getFullPath';
import MainModal from '../../../../components/MainModal';
import { Form as AntForm, Button, Select, Input } from "antd";
import COUNTRY_CODES from '../../../../constants/countryCodes';
import Dropzone from '../../../../components/Dropzone';
import * as yup from 'yup'
import styles from '../../SpeakerPrizes/SpeakerPrizes.module.scss'
import { addEventSpeakerUser, updateEventSpeakerUser } from '../../../../actions/eventDashboard/eventSettings';

const SpeakerProfileModal = ({ visible, modalOperation, speakerData, closeModal, userAddFailure, userUpdateFailure }) => {
  const thumbnailRef = useRef()
  const [isSubmitting, setSubmitting] = useState(false)
  const [thumbnailFile, setThumbnailFile] = useState(null)
  const [thumbnailUrl, setThumbnailUrl] = useState(null)
  const [thumbnailError, setThumbnailError] = useState('')

  const setDataInForm = () => {
    let data = {}
    if (checkOperation()) {
      data = { ...speakerData }
    } else {
      data = {
        name: get(speakerData, 'user.name'),
        email: get(speakerData, 'user.email'),
        phone: parseInt(get(speakerData, 'user.phone.number')),
        countryCode: get(speakerData, 'user.phone.countryCode'),
        roleAtOrganization: get(speakerData, 'roleAtOrganization'),
        organization: get(speakerData, 'organization'),
        about: get(speakerData, 'about'),
        linkedInLink: get(speakerData, 'linkedInLink'),
      }
    }
    return data
  }

  useEffect(() => {
    if (!checkOperation() && get(speakerData, 'user.profilePic.uri')) {
      setThumbnailUrl(`${getFullPath(get(speakerData, 'user.profilePic.uri'))}`)
    } else {
      setThumbnailUrl(null)
    }
    if (!visible) {
      if (formRef && formRef.current) {
        formRef.current.resetForm(setDataInForm())
      }
    }
  }, [visible])

  const handleSubmit = async (value, meta) => {
    const { name, email, phone, roleAtOrganization, countryCode,
      organization, about, linkedInLink } = value
    let userInput = {}
    let speakerInput = {}
    setSubmitting(true)
    if (!thumbnailError) {
      if (checkOperation()) {
        userInput = {
          phone: {
            number: phone.toString(),
            countryCode: countryCode,
          },
          role: 'eventSpeaker',
          name,
          email
        }
        speakerInput = {
          roleAtOrganization,
          organization,
          about,
          linkedInLink
        }
        await addEventSpeakerUser(userInput, speakerInput, thumbnailFile)
      } else {
        userInput = {
          phone: {
            number: phone.toString(),
            countryCode: countryCode,
          },
          name,
          email
        }
        speakerInput = {
          roleAtOrganization: roleAtOrganization ? roleAtOrganization : '',
          organization: organization ? organization : '',
          about: about ? about : '',
          linkedInLink: linkedInLink ? linkedInLink : ''
        }
        const userId = get(speakerData, 'user.id')
        const speakerId = get(speakerData, 'id')
        await updateEventSpeakerUser(userInput, speakerInput, thumbnailFile, userId, speakerId)
      }
    }
    setSubmitting(false)
  }

  const onDropThumbnail = (file) => {
    setThumbnailError('')
    if (file) {
      const img = new Image()
      img.src = window.URL.createObjectURL(file)
      img.onload = () => {
        setThumbnailFile(file)
          setThumbnailError('')
        // if (img && parseInt(img.width) === 268 && parseInt(img.height) === 243) {
        //   setThumbnailFile(file)
        //   setThumbnailError('')
        // } else {
        //   setThumbnailError('Recommended Size: 268 x 243')
        // }
      }
    }
  }
  const formRef = useRef()
  const onCloseModal = () => {
    setThumbnailUrl(null)
    setThumbnailFile(null)
    setThumbnailError('')
    closeModal()
  }

  useEffect(() => {
  }, [thumbnailFile])

  const checkOperation = () => modalOperation === 'add'

  const renderCountryCodes = (countryCode, handleChange) => (
    <Select
      style={{ width: '200px' }}
      showSearch
      label='Country Code'
      placeholder='Country Code'
      name='countryCode'
      value={countryCode}
      onChange={(value) => {
        handleChange('countryCode')(value)
      }}
    >
      {
        COUNTRY_CODES
          .map((country) => (
            <Select.Option key={country.code} value={country.dial_code}>
              {`${country.dial_code} ${country.name} `}
            </Select.Option>
          ))
      }
    </Select>
  )

  const formValidationSchema = yup.object().shape({
    name: yup.string().required('Name required').matches(/^[0-9a-zA-Z].*[0-9a-zA-Z]$/, 'Invalid Format').min(3, 'name (Min. 3 characters)').max(30, 'name (Max. 30 characters)').trim(),
    phone: yup.string().required('Phone (Not Found)').matches(/^[6-9]\d{9}$/, 'Phone (Invalid format)'),
    email: yup.string().required('Email Required').email('Email (Invalid format)'),
  })
  return (
    <MainModal
      visible={visible}
      title={[
        <div>
          <div style={{ textAlign: 'center' }}>
            {`${checkOperation() ? 'Create' : 'Update'} Speaker Profile`}
          </div>
        </div>]}
      onCancel={onCloseModal}
      maskClosable='true'
      width='480px'
      footer={null}
    >
      <div className={styles.addSpeakerImageContainer}>
        <Dropzone
          style={{ margin: '12px 10px', width: '310px' }}
          getDropzoneFile={onDropThumbnail}
          ref={thumbnailRef.current}
          defaultImage={thumbnailUrl}
          defaultFile={thumbnailFile}
          onImageUrl={imgUrl => !thumbnailError && setThumbnailUrl(imgUrl)}
          resetImage={() => {
            setThumbnailUrl(null)
            setThumbnailFile(null)
            setThumbnailError('')
          }}
          type="SpeakerImage"
        >
          Click or drag to attach
        </Dropzone>
        {thumbnailError && (<p style={{ color: 'red', fontSize: '0.6rem' }}>{thumbnailError}</p>)}
      </div>
      <Formik
        initialValues={setDataInForm()}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={formValidationSchema}
        innerRef={formRef}
      >
        {({ values, handleChange, errors, touched }) => (
          <Form
            labelCol={{ span: 21 }}
            wrapperCol={{ span: 21 }}
            layout="vertical"
            initialValues={{ size: 'default' }}
            size={'default'}
          >
            <div className={styles.addSpeakerProfileForm}>
              <div className={styles.speakerForm}>
                <AntForm.Item label='Name' >
                  <Input
                    placeholder='Name'
                    style={{ width: '160px' }}
                    name='name'
                    type='text'
                    value={values.name}
                    onChange={handleChange}
                  />
                  {errors.name ? (<div style={{ color: 'red', fontSize: '0.6rem', margin: '0px', lineHeight: '8px' }}>{errors.name}</div>) : null}
                </AntForm.Item>
                <AntForm.Item label='Email' >
                  <Input
                    placeholder='Email'
                    style={{ width: '160px' }}
                    name='email'
                    type='text'
                    value={values.email || ''}
                    onChange={handleChange}
                  />
                  {errors.email ? (<div style={{ color: 'red', fontSize: '0.6rem', margin: '0px', lineHeight: '8px' }}>{errors.email}</div>) : null}
                </AntForm.Item>
              </div>
              <div styles={{ width: '100%' }}>
                <AntForm.Item>
                  <Input
                    addonBefore={renderCountryCodes(values.countryCode, handleChange)}
                    placeholder='Phone Number'
                    style={{ width: '360px' }}
                    name='phone'
                    type='number'
                    value={values.phone}
                    onChange={(e) => {
                      handleChange('phone')(e.target.value.substr(0, 10))
                    }}
                  />
                  {errors.phone ? (<div style={{ color: 'red', fontSize: '0.6rem', margin: '0px', lineHeight: '8px' }}>{errors.phone}</div>) : null}
                </AntForm.Item>
              </div>
              <div className={styles.speakerForm}>
                <AntForm.Item label='Role at organisation' >
                  <Input
                    placeholder='Role at organisation'
                    style={{ width: '160px' }}
                    name='roleAtOrganization'
                    type='text'
                    value={values.roleAtOrganization}
                    onChange={handleChange}
                  />
                </AntForm.Item>
                <AntForm.Item label='Organisation' >
                  <Input
                    placeholder='Organisation'
                    style={{ width: '160px' }}
                    name='organization'
                    type='text'
                    value={values.organization}
                    onChange={handleChange}
                  />
                </AntForm.Item>
              </div>
              <div className={styles.speakerForm}>
                <AntForm.Item label='LinkedIn Profile URL' >
                  <Input
                    placeholder='LinkedIn Profile URL'
                    style={{ width: '160px' }}
                    name='linkedInLink'
                    type='text'
                    value={values.linkedInLink}
                    onChange={handleChange}
                  />
                </AntForm.Item>
                <AntForm.Item label='About the Speaker'>
                  <Input.TextArea
                    autosize={{ minRows: 2, maxRows: 4 }}
                    placeholder='About the Speaker'
                    style={{ width: '160px' }}
                    name='about'
                    value={values.about}
                    onChange={handleChange}
                  />
                </AntForm.Item>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '10px' }}>
              <Button
                type='primary'
                icon='file'
                id='add-btn'
                htmlType='submit'
                loading={isSubmitting}
              >
                {checkOperation() ? 'Save' : 'Update Details'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </MainModal>
  )
}

export default SpeakerProfileModal