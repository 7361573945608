/* eslint-disable quotes */
import React from 'react'
import styled from 'styled-components'
import { DatePicker, Input, Select, TimePicker } from 'antd'
import { TekieRed } from '../../../constants/colors'
// import { SearchOutlined } from '@ant-design/icons'
// import getFullPath from '../../../utils/getFullPath'
// import { TekieOrange } from '../../../constants/colors'


const EventsStyles = styled.div`
    height: 100%;
    width: 100%;
    margin: 0 auto;
    font-family: 'Inter' !important;
`

const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    .ant-progress-bg{
        background: rgba(1, 170, 147, 1) !important;
    }
`
const Container = styled.div`
    width: 100%;
    height: 85vh;
    background: #FFFFFF;
    padding: 15px;
    overFlow: scroll;
`
const SessionCard = styled.div`
    width: 100%;
    padding: 25px 25px;
    display: flex;
`

const Card = styled.div`
    width: 48%;
    height: 50%;
`
const InsertBannerText = styled.p`
    color: #4A4A4A;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    margin-top: -9px;
    font-weight: 600;
    margin-bottom: 3px;
`

const Grid = styled.div`
    display: grid;
    grid-template-areas: "img img""img img";
`

const input = styled((props) => <Input {...props} />)`
    width: 100%;
    height: 50px;
`

const datePicker = styled((props) => <DatePicker {...props} />)`
    width: 30%;
    margin-right: 20px;
`

const timePicker = styled((props) => <TimePicker {...props} />)`
    width: 35%;
`
const select = styled((props) => <Select {...props} />)`
    width: 100%;
    height: 50px;
    &&&{
        margin: ${props => props.nomargin && 0}
    }
`

const InfoContainer = styled.div`
    width: 100%;
`
const Error = styled.small`
    font-family: 'Inter';
    font-weight: normal;
    font-size: 12px;
    color: ${TekieRed};
    padding: 0;
    letter-spacing: 0px;
`
const PageTitle = styled.div`
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0px;
    color: #212121;
    padding: 6px 10px 18px;
`
const Subtitle = styled.div`
    font-family: Inter;    
    font-style: normal;
    font-size: 13px;
    letter-spacing: 0px;
    color: #424242;
    padding: 0px 35px;
`
const Heading = styled.div`
    font-family: Inter;    
    font-style: normal;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #424242;
    padding: 0px 35px;
`
const Span = styled.span`
    font-family: Inter;    
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    color: #424242;
    background: #fff;
    width: 100px;
`
const InfoTitle = styled.div`
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 0px;
    color: #212121;
    padding: 6px 10px 10px;
`
const CalendarFlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`

EventsStyles.FlexContainer = FlexContainer
EventsStyles.Container = Container
EventsStyles.PageTitle = PageTitle
EventsStyles.CalendarFlexContainer = CalendarFlexContainer

SessionCard.InfoContainer = InfoContainer
SessionCard.InfoTitle = InfoTitle
SessionCard.Subtitle = Subtitle
SessionCard.Heading = Heading
SessionCard.Card = Card
SessionCard.input = input
SessionCard.select = select
SessionCard.Span = Span
SessionCard.Grid = Grid
SessionCard.datePicker = datePicker
SessionCard.timePicker = timePicker
SessionCard.error = Error
SessionCard.InsertBannerText = InsertBannerText

export { SessionCard }
export default EventsStyles
