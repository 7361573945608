import { Input, Select } from 'antd'
import { get } from 'lodash'
import React from 'react'
import { fetchCourseCount, fetchCourses } from '../../actions/courseMaker'
import {
  FlexContainer,
  StyledButton, TopContainer
} from './AddCourse.styles'
import { CourseModal, CourseTable } from './components'
const { Search } = Input;
const { Option } = Select

export const coursesTabs = [
  {
    label: 'All Courses',
    key: 'allCourses'
  },
  {
    label: 'Student Courses',
    key: 'normal'
  },
  {
    label: 'Teacher Training Courses',
    key: 'teacherTraining'
  },
]
class AddCourse extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: null,
      operation: '',
      editData: null,
      tableLoading: true,
      searchString: '',
      filterOptions: coursesTabs,
      searchKey: coursesTabs[0].key,
    }
  }
  componentDidMount = async () => {
    await fetchCourses()
    fetchCourseCount()
  }

  fetchCoursesWithFilter = () => {
    const { perPage, skip, searchString, searchKey, filterOptions } = this.state
    let filterString = ''
    if (searchString) filterString += `{
      title_contains: "${searchString}"
    }`
    if (searchKey !== 'allCourses') {
      if (searchKey === filterOptions[1].key) {
        filterString += `{
          contentType_not: ${filterOptions[2].key}
        }`
      } else {
        filterString += `{
          contentType: ${filterOptions[2].key}
        }`
      }
    }
    fetchCourses(filterString, perPage, skip)
    fetchCourseCount(filterString)
  }

  openAddModal = () => {
    this.setState({
      openModal: true,
      operation: 'add'
    })
  }
  openEditModal = (data) => {
    this.setState({
      openModal: true,
      operation: 'edit',
      editData: data
    })
  }
  searchByFilter = async (shouldFetch = true) => {
    this.setState({
      tableLoading: false
    })
    if (shouldFetch) await fetchCourses()
    await fetchCourseCount()
    this.setState({
      tableLoading: true
    })
  }

  onSearch = (result) => {
    if (result.length) {
      this.setState({
        searchString: result
      }, this.fetchCoursesWithFilter)
    }
  }

  handleSearchKeyChange = (value) => {
    this.setState({
      searchKey: value,
    }, this.fetchCoursesWithFilter)
  }

  render() {
    const { openModal, operation, editData, tableLoading, searchKey, filterOptions } = this.state
    const { coursesMeta, coursesFetchStatus } = this.props
    return (
      <>
        <TopContainer justify='space-between'>
          <CourseModal
            contentType={searchKey}
            openModal={openModal}
            operation={operation}
            editData={editData}
            searchByFilter={this.searchByFilter}
            closeModal={() => this.setState({ openModal: false, operation: null, editData: null })}
            {...this.props}
          />
          <FlexContainer>
            <Select
              style={{ minWidth: '200px' }}
              value={searchKey}
              onChange={this.handleSearchKeyChange}
            >
              {
                filterOptions.map(option => (
                  <Option
                    key={option.key}
                  >{option.label}
                  </Option>
                ))
              }
            </Select>
            <Search
              placeholder="search course"
              style={{ width: 250, marginRight: '20px' }}
              onSearch={(e) => { this.onSearch(e) }}
              onChange={(e) => {
                if (e.target.value === '') {
                  this.setState({
                    searchString: ''
                  }, this.fetchCoursesWithFilter)
                }
              }}
            />
          </FlexContainer>
          <TopContainer>
            <h4>Total Course: {coursesMeta || 0}</h4>
            <StyledButton
              icon='plus'
              id='add-btn'
              onClick={this.openAddModal}
              disabled={coursesFetchStatus && get(coursesFetchStatus.toJS(), 'loading')}
            >
              ADD COURSE
            </StyledButton>
          </TopContainer>
        </TopContainer>
        <CourseTable
          openEditModal={this.openEditModal}
          searchByFilter={this.searchByFilter}
          searchKey={searchKey}
          tableLoading={tableLoading || (coursesFetchStatus && get(coursesFetchStatus.toJS(), 'loading'))}
          {...this.props}
        />
      </>
    )
  }
}

export default AddCourse
