/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react'
import './reports.scss'
import { batchesSvg, studentSvg, presentStatusSvg, AbsentStatusSvg, notApplicableStatusSvg, clockSvg, genderSvg } from '../CoursesCard/assets'
import { ratingsSvg } from '../../../StudentProfileLeftbar/assets'
import { fetchBatchSessionData, fetchSessionReportsData, fetchUserQuizData } from '../../fetchData'
import { format } from 'date-fns'
import getFullPath from '../../../../utils/getFullPath'
import { get } from 'lodash'

export default function Reports({ studentId, courseDetailId, courseTitle, courseType, studentName, coursesCount, completedSessions, showDetails, courseThumbnail, batchSessions, paymentReports, courseCreationDate }) {
    const batchLogo = batchesSvg()
    const studentLogo = studentSvg()
    const presentStatus = presentStatusSvg()
    const absentStatus = AbsentStatusSvg()
    const notApplicableStatus = notApplicableStatusSvg()
    const clock = clockSvg()
    const genderLogo = genderSvg()
    const pieChartDisplay = useRef()
    const [userQuizData, setUserQuizData] = useState('')
    const [mapMonthsSessionStartDate, setMapMonthsSessionStartDate] = useState([])
    const [currentClassDetailsIndex, setCurrentClassDetailsIndex] = useState(0)
    const [currentAttemptIndex, setCurrentAttemptIndex] = useState(0)
    const [viewMoreAttempts, setViewMoreAttempts] = useState(false)
    const [batchSessionIndex, setBatchSessionIndex] = useState(0)
    const [batchSessionsData, setBatchSessionsData] = useState([])
    const [courseSessionReports, setCourseSessionReports] = useState([])
    const [absent, setAbsent] = useState(false)

    let monthsArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    let monthWeeks = [1, 2, 3, 4]
    useEffect(() => {
        if (courseSessionReports.length > 0) {
            if (courseSessionReports[currentClassDetailsIndex].sessionEndDate) {
                let time = (((courseSessionReports[currentClassDetailsIndex].sessionEndDate.split('T')[1]).split('.')[0])).split(':')[1] - (((courseSessionReports[currentClassDetailsIndex].sessionStartDate.split('T')[1]).split('.')[0])).split(':')[1]
                let deg = (Math.floor(Math.floor((time / 60) * 100)) * (360 / 100))
                pieChartDisplay.current.style.background = `conic-gradient(#eeeeee 0deg ${360 - deg}deg,#01AA93 45deg ${deg}deg)`
            }
            else {
                pieChartDisplay.current.style.background = `conic-gradient(#eeeeee 0deg ${360}deg,#01AA93 45deg ${0}deg)`
            }
        }
    }, [courseSessionReports, currentClassDetailsIndex])

    useEffect(() => {
        let firstSession = []
        if (courseSessionReports.length > 0) {
            courseSessionReports.map((report, index) => {
                if (report.sessionStatus === 'started' || report.sessionStatus === 'completed') {
                    firstSession.push({ report, index })
                }
            })
            if (firstSession.length > 0) setCurrentClassDetailsIndex(get(firstSession[0], 'index'))
        }
    }, [courseSessionReports])

    useEffect(() => {
        if (batchSessionsData.length > 0) {
            let studentPresent = false
            if (get(batchSessionsData[batchSessionIndex], 'attendance.status') === 'present') {
                studentPresent = true
            }
            else {
                studentPresent = false
            }
            setAbsent(studentPresent)
        }
    }, [batchSessionsData, batchSessionIndex])

    useEffect(() => {
        const fetchData = async () => {
            if (courseType !== 'batch session') {
                const res = await fetchSessionReportsData(studentId, courseDetailId)
                setCourseSessionReports(get(res, 'mentorMenteeSessions').sort((course1, course2) => {
                    return new Date(course1.sessionStartDate) - new Date(course2.sessionStartDate)
                }))
                if (res.mentorMenteeSessions.length > 0) {
                    if (get(res, 'mentorMenteeSessions[0].sessionStatus') === 'completed' || get(res,'mentorMenteeSessions[0].sessionStatus') === 'started') {
                        let firstSessionMonthIndex = monthsArr.indexOf((format(new Date(get(res, 'mentorMenteeSessions[0].sessionStartDate')), 'MMMM')))
                        setMapMonthsSessionStartDate(monthsArr.slice(firstSessionMonthIndex))
                    }
                    else {
                        let firstSessionMonthIndex = monthsArr.indexOf((format(new Date(get(res, 'mentorMenteeSessions[0].menteeSession.bookingDate')), 'MMMM')))
                        setMapMonthsSessionStartDate(monthsArr.slice(firstSessionMonthIndex))
                    }
                }
            }

            else {
                if (batchSessions.id === courseDetailId) {
                    const res = await fetchBatchSessionData(get(batchSessions, 'id'))
                    setBatchSessionsData(get(res, 'batchSessions'))
                    if (get(res, 'batchSessions[0].sessionStatus') === 'completed' || (res, 'batchSessions[0].sessionStatus') === 'started') {
                        let firstSessionMonthIndex = monthsArr.indexOf((format(new Date(get(res, 'batchSessions[0].sessionStartDate')), 'MMMM')))
                        setMapMonthsSessionStartDate(monthsArr.slice(firstSessionMonthIndex))
                    }
                }
            }
        }
        fetchData()
    }, [])

    const progressPercentageHandler = () => {
        let ProgressPercentage = 0
        if (completedSessions === 0) {
            ProgressPercentage = 0
        }
        else {
            ProgressPercentage = ((completedSessions / coursesCount) * 100).toFixed(2)
        }
        return ProgressPercentage
    }

    const timeHandler = (time) => {
        if (time <= 12) return 'am'
        else if (time > 12) return 'pm'
    }

    const classTimeHandler = () => {
        let classTime
        if (courseType !== 'batch session') {
            if (courseSessionReports.length > 0) {
                for (let key in courseSessionReports[currentClassDetailsIndex].menteeSession) {
                    if (courseSessionReports[currentClassDetailsIndex].menteeSession[key] === true) {
                        let slotNumber = key.split('t')[1]
                        classTime = key.split('t')[1]
                        if (classTime > 12) classTime = classTime - 12
                        let timeRange = parseInt(classTime) + 1
                        classTime = `${classTime}:00 ${timeHandler(slotNumber)}`
                        classTime = `${classTime}-${timeRange}:00 ${timeHandler(slotNumber)}`
                    }
                }
                return classTime
            }
        }
        else {
            if (batchSessionsData.length > 0) {
                for (let key in batchSessionsData[batchSessionIndex].menteeSession) {
                    if (batchSessionsData[batchSessionIndex][key] === true) {
                        let slotNumber = key.split('t')[1]
                        classTime = key.split('t')[1]
                        if (classTime > 12) classTime = classTime - 12
                        let timeRange = parseInt(classTime) + 1
                        classTime = `${classTime}:00 ${timeHandler(slotNumber)}`
                        classTime = `${classTime}-${timeRange}:00 ${timeHandler(slotNumber)}`
                    }
                }
                return classTime
            }
        }
    }
    const classDateHandler = (session) => {
        if (courseType !== 'batch session') {
            if (session) {
                if (get(session, 'sessionStatus') === 'completed' || get(session, 'sessionStatus') === 'started') {
                    let classDate = get(session, 'sessionStartDate')
                    if (classDate > 28) return 3
                    else {
                        let classWeek
                        classDate = (classDate.split('-')[2]).split('T')[0]
                        let remainderCheck = classDate % 7
                        classWeek = Math.floor(classDate / 7)
                        if (remainderCheck !== 0) return classWeek
                        else return classWeek - 1
                    }
                }
                else {
                    let classDate = get(session, 'menteeSession.bookingDate')
                    if (classDate > 28) return 3
                    else {
                        let classWeek
                        classDate = (classDate.split('-')[2]).split('T')[0]
                        let remainderCheck = classDate % 7
                        classWeek = Math.floor(classDate / 7)
                        if (remainderCheck !== 0) return classWeek
                        else return classWeek - 1
                    }
                }
            }
        }
        else {
            if (session) {
                if (session.sessionStatus === 'completed') {
                    let classDate = get(session, 'sessionStartDate')
                    if (classDate > 28) return 3
                    else {
                        let classWeek
                        classDate = (classDate.split('-')[2]).split('T')[0]
                        let remainderCheck = classDate % 7
                        classWeek = Math.floor(classDate / 7)
                        if (remainderCheck !== 0) return classWeek
                        else return classWeek - 1
                    }
                }
            }
            else {
                let classDate = get(session, 'bookingDate')
                let classWeek
                classDate = (classDate.split('-')[2]).split('T')[0]
                if (classDate > 28) {
                    return 3
                }
                else {
                    let remainderCheck = classDate % 7
                    classWeek = Math.floor(classDate / 7)
                    if (remainderCheck !== 0) return classWeek
                    else return classWeek - 1
                }
            }
        }
    }
    const sessionChangeHandler = (index) => {
        if (courseType !== 'batch session') {
            setCurrentClassDetailsIndex(index)
        }
        else {
            setBatchSessionIndex(index)
        }
    }

    useEffect(() => {
        const fetchUserQuiz = async (studentId, topicId) => {
            const res = await fetchUserQuizData(studentId, topicId)
            setUserQuizData(res.userQuizReports)
        }
        if (courseSessionReports.length > 0) fetchUserQuiz(studentId, get(courseSessionReports[currentClassDetailsIndex],'topic.id'))
    }, [courseSessionReports])

    const attemptsDisplayHandler = (attemptNumber) => {
        if (attemptNumber === 0) return '1st'
        else if (attemptNumber === 1) return '2nd'
        else if (attemptNumber === 2) return '3rd'
        else return `${attemptNumber + 1}th`
    }

    const batchCodeHandler = (id) => {
        if (batchSessions.course.id === id) return get(batchSessions, 'code')
    }

    const batchSessionTypeHandler = (id) => {
        if (batchSessions.course.id === id) return get(batchSessions, 'type')
    }

    const ratingHandler = (data) => {
        if (data) {
            let rating = false
            for (let key in data.mentorSession.user.mentorProfile) {
                if (data.mentorSession.user.mentorProfile[key] !== null) {
                    rating = true
                }
            }
            if (!rating) return
        }
    }
    const ratingLogoHandler = (data) => {
        if (data) {
            let rating = false
            for (let key in get(data, 'mentorSession.user.mentorProfile')) {
                if (data.mentorSession.user.mentorProfile[key] !== null) {
                    rating = true
                }
            }
            if (!rating) return
            else {
                const ratingLogo = ratingsSvg()
                return ratingLogo
            }
        }
    }

    const joiningTimeHandler = (data) => {
        let hours = 0
        let fixedHours = (((data.split('T')[1]).split('.')[0])).split(':')[0]
        if (fixedHours > 12) hours = parseInt(fixedHours - 12)
        let minutes = (((data.split('T')[1]).split('.')[0])).split(':')[1]
        return `${hours}:${minutes} ${timeHandler(fixedHours)}`
    }
    const leavingTimeHandler = (data) => {
        if (data) {
            let hours = 0
            let fixedHours = (((data.split('T')[1]).split('.')[0])).split(':')[0]
            if (fixedHours > 12) hours = parseInt(fixedHours - 12)
            let minutes = (((data.split('T')[1]).split('.')[0])).split(':')[1]
            return `${hours}:${minutes} ${timeHandler(fixedHours)}`
        }
        else return '-'
    }
    const classNumberInWeekHandler = (data) => {
        if (data) {
            if (get(data,'sessionStatus') === 'completed' || get(data,'sessionStatus') === 'started') {
                let classDate = get(data, 'sessionStartDate')
                let classesInWeek = []
                let weekMultiplier
                classDate = (classDate.split('-')[2]).split('T')[0]
                let remainderCheck = classDate % 7
                if (remainderCheck !== 0) {
                    weekMultiplier = Math.floor(classDate / 7)
                }
                else weekMultiplier = Math.floor(classDate / 7) - 1
                if (classDate >= (weekMultiplier * 7) + 1 && classDate <= (weekMultiplier + 1) * 7) {
                    courseSessionReports.map((session) => {
                        if ((get(session, 'sessionStartDate').split('-')[2].split('T')[0] >= (weekMultiplier * 7) + 1) && (get(session, 'sessionStartDate').split('-')[2].split('T')[0] <= (weekMultiplier + 1) * 7)) {
                            classesInWeek.push(session.id)
                        }
                    })
                }
                let index = classesInWeek.indexOf(data.id)
                return index
            }
        }
    }
    const activeClassDetailsHandler = (index) => {
        if (index === currentClassDetailsIndex) {
            return true
        }
    }
    const paymentStatusHandler = () => {
        if (paymentReports.length > 0) {
            paymentReports.map((paymentReportCourseData) => {
                if (paymentReportCourseData.course !== null) {
                    if (get(paymentReportCourseData, 'course.id') === courseId) {
                        if (get(paymentReportCourseData, 'leadStatus') === 'won') {
                            if (paymentReportCourseData.userPaymentInstallments[paymentReportCourseData.userPaymentInstallments.length - 1].status === 'paid') {
                                return 'PAID'
                            }
                            else {
                                return 'PENDING'
                            }
                        }
                        else return 'UNPAID'
                    }
                }
                else return 'UNPAID'
            })
        }
        else return 'UNPAID'
    }
    const yearHandler = (date) => {
        if ((get(date, 'sessionStatus') === 'completed') || get(date, 'sessionStatus') === 'started') {
            let startingSessionYear = new Date(get(date, 'sessionStartDate')).getFullYear()
            let year = new Date(date.sessionStartDate).getFullYear()
            if (startingSessionYear + 1 === year) {
                return true
            }
            else false
        }
    }
    const nextYearRenderHandler = () => {
        if (courseSessionReports.length > 0) {
            let LastSessionYear
            let lastSessionIndex = courseSessionReports.length - 1
            if (get(courseSessionReports[lastSessionIndex], 'sessionStatus') === 'completed' || get(courseSessionReports[lastSessionIndex], 'sessionStatus') === 'started') {
                LastSessionYear = new Date(get(courseSessionReports[lastSessionIndex], 'sessionStartDate')).getFullYear()
            }
            else {
                LastSessionYear = new Date(get(courseSessionReports[lastSessionIndex], 'menteeSession.bookingDate')).getFullYear()
            }
            let year = new Date(get(courseSessionReports[0], 'sessionStartDate')).getFullYear()
            if (LastSessionYear === year + 1) {
                return true
            }
            else false
        }
    }
    const thisYearHandler = (data) => {
        if (courseSessionReports.length > 0) {
            if (get(data, 'sessionStatus') === 'completed' || get(data, 'sessionStatus') === 'started') {
                let currentSessionYear = new Date(get(data, 'sessionStartDate')).getFullYear()
                let year = new Date(get(courseSessionReports[0], 'sessionStartDate')).getFullYear()
                if (currentSessionYear === year) {
                    return true
                }
                else false
            }
        }
    }
    return (
        <>
            <div className="reportsContainer">
                <div className="reportsWrapper">
                    <div className="reportsLeftSide">
                        <div className="reportsImageContainer">
                            <img src={getFullPath(courseThumbnail)} alt="" className="courseThumbnail" />
                        </div>
                    </div>
                    <div className="reportDetailsDesc">
                        <p className="reportDate">{`Created on: ${courseSessionReports.length > 0 && get(courseSessionReports[0], 'menteeSession.bookingDate').split('-')[1]} ${courseSessionReports.length > 0 && format(get(courseSessionReports[0], 'menteeSession.bookingDate'), 'MMMM')} ${courseSessionReports.length > 0 && get(courseSessionReports[0], 'menteeSession.bookingDate').split('-')[0]}`}</p>
                        <div className="studentMethod">
                            <div className="methodDenotion">

                            </div>
                            {courseType === 'batch session' && <h3>{batchSessionTypeHandler(courseDetailId)}</h3>}
                            <p className="paymentStatus">{paymentStatusHandler()}</p>
                        </div>
                        <p className="programmingBasisDesc">
                            {courseSessionReports.length > 0 && get(courseSessionReports[0],'course.secondaryCategory')}
                        </p>
                        <h3 className="reportCourseTitle">{courseTitle}</h3>
                        {courseType === 'batch session' && <div className="batchAndStudentDetailsDesc">
                            <div className="batchDetailsWrapper">
                                {batchLogo}
                                <div className="batchDescriptionText">
                                    <p className="DescriptionTitle">Batch</p>
                                    {courseType === 'batch session' && <p className="batchNameText">{batchCodeHandler(courseDetailId)}</p>}
                                </div>
                            </div>
                            <div className="studentDetailsWrapper">
                                {studentLogo}
                                <div className="batchDescriptionText">
                                    <p className="DescriptionTitle">Students</p>
                                    <p className="batchNameText">{get(batchSessionsData[batchSessionIndex], 'attendance.length')}</p>
                                </div>
                            </div>
                        </div>}
                        {courseType === 'mentor mentee session' && <p className="menteeUserName">Student Name: {studentName}</p>}
                        <div className="courseProgressBarInReportContainer">
                            <p style={{ fontSize: "12px" }}>Course Progress</p>
                            <div className="courseProgressBar">
                                <div className="courseProgressBarInner" style={{ width: `${progressPercentageHandler()}%` }}>
                                </div>
                            </div>
                            <p style={{ color: "#424242" }}>{`${progressPercentageHandler()}%`}</p>
                        </div>
                    </div>
                </div>
                <div className="classesCalendarContainer">
                    <div className="classStudentStatus">
                        <div className="studentPresentStatus">
                            {presentStatus}
                            <p>Present</p>
                        </div>
                        <div className="studentAbsentStatus">
                            {absentStatus}
                            <p>Absent</p>
                        </div>
                        <div className="studentNotApplicableStatus">
                            {notApplicableStatus}
                            <p>Not Applicable</p>
                        </div>
                    </div>
                    <div className="classCalendarReportWrapper">
                        <div className="weeksContainer">
                            <p>Week 1</p>
                            <p>Week 2</p>
                            <p>Week 3</p>
                            <p>Week 4</p>
                        </div>
                        <table className="classesInReportCalendarContainer">
                            <tr>
                                {
                                    mapMonthsSessionStartDate.map((month, index) => (
                                        <th>
                                            {month}
                                        </th>
                                    ))
                                }
                            </tr>
                            {monthWeeks.map((month, indexx) => (
                                <>
                                    <tr>
                                        {mapMonthsSessionStartDate.map((week, weekIndex) => (
                                            <>
                                                <td>
                                                    {courseType !== 'batch session' && <div className="classStatusIndicatorContainer">
                                                        <div className="classStatusIndicator" >
                                                            {courseSessionReports.length > 0 && courseSessionReports.map((courseSessionReport, index) => (
                                                                (classDateHandler(courseSessionReport)) === indexx && week === (get(courseSessionReport, 'sessionStatus') === 'completed' ? format(get(courseSessionReport, 'sessionStartDate'), 'MMMM') : format(get(courseSessionReport, 'menteeSession.bookingDate'), 'MMMM')) && classNumberInWeekHandler(courseSessionReport) === 0 && thisYearHandler(courseSessionReport) && <p onClick={() => { sessionChangeHandler(index) }} className={activeClassDetailsHandler(index) ? "activeClassDenoter" : "classDenoter"} >{`${index + 1}`}</p>
                                                            ))}
                                                        </div>
                                                        <div className="classStatusIndicator">
                                                            {courseSessionReports.length > 0 && courseSessionReports.map((courseSessionReport, index) => (
                                                                (classDateHandler(courseSessionReport)) === indexx && week === (courseSessionReport.sessionStatus === 'completed' ? format(get(courseSessionReport, 'sessionStartDate'), 'MMMM') : format(get(courseSessionReport,'menteeSession.bookingDate'), 'MMMM')) && classNumberInWeekHandler(courseSessionReport) === 1 && thisYearHandler(courseSessionReport) && <p onClick={() => { sessionChangeHandler(index) }} className={activeClassDetailsHandler(index) ? "activeClassDenoter" : "classDenoter"}>{`${index + 1}`}</p>
                                                            ))}
                                                        </div>
                                                        <div className="classStatusIndicator">
                                                            {courseSessionReports.length > 0 && courseSessionReports.map((courseSessionReport, index) => (
                                                                (classDateHandler(courseSessionReport)) === indexx && week === (courseSessionReport.sessionStatus === 'completed' ? format(get(courseSessionReport, 'sessionStartDate'), 'MMMM') : format(get(courseSessionReport, 'menteeSession.bookingDate'), 'MMMM')) && classNumberInWeekHandler(courseSessionReport) === 2 && thisYearHandler(courseSessionReport) && <p onClick={() => { sessionChangeHandler(index) }} className={activeClassDetailsHandler(index) ? "activeClassDenoter" : "classDenoter"} >{`${index + 1}`}</p>
                                                            ))}
                                                        </div>
                                                    </div>}
                                                    {courseType === 'batch session' && <div className="classStatusIndicatorContainer">
                                                        <div className="classStatusIndicator" >
                                                            {batchSessionsData.length > 0 && batchSessionsData.map((batchSessionReport, index) => (
                                                                (classDateHandler(batchSessionReport)) === indexx && week === (get(batchSessionReport, 'sessionStatus') === 'completed' ? format(get(batchSessionReport, 'sessionStartDate'), 'MMMM') : format(get(batchSessionReport, 'bookingDate'), 'MMMM')) && classNumberInWeekHandler(batchSessionReport) === 0 && <p onClick={() => { sessionChangeHandler(index) }} className={activeClassDetailsHandler(index) ? !absent ? "activeClassDenoter" : "activeAbsentClassDenoter" : !absent ? "classDenoter" : "absentClassDenoter"}>{`C${index + 1}`}</p>
                                                            ))}
                                                        </div>
                                                        <div className={!absent ? "classStatusIndicator" : "absentClassStatusIndicator"}>
                                                            {batchSessionsData.length > 0 && batchSessionsData.map((batchSessionReport, index) => (
                                                                (classDateHandler(batchSessionReport)) === indexx && week === (get(batchSessionReport, 'sessionStatus') === 'completed' ? format(get(batchSessionReport, 'sessionStartDate'), 'MMMM') : format(get(batchSessionReport, 'bookingDate'), 'MMMM')) && classNumberInWeekHandler(batchSessionReport) === 1 && <p onClick={() => { sessionChangeHandler(index) }} className={activeClassDetailsHandler(index) ? !absent ? "activeClassDenoter" : "activeAbsentClassDenoter" : !absent ? "classDenoter" : "absentClassDenoter"}>{`C${index + 1}`}</p>
                                                            ))}
                                                        </div>
                                                        <div className={!absent ? "classStatusIndicator" : "absentClassStatusIndicator"}>
                                                            {batchSessionsData.length > 0 && batchSessionsData.map((batchSessionReport, index) => (
                                                                (classDateHandler(batchSessionReport)) === indexx && week === (get(batchSessionReport, 'sessionStatus') === 'completed' ? format(get(batchSessionReport, 'sessionStartDate'), 'MMMM') : format(get(batchSessionReport, 'bookingDate'), 'MMMM')) && classNumberInWeekHandler(batchSessionReport) === 2 && <p onClick={() => { sessionChangeHandler(index) }} className={activeClassDetailsHandler(index) ? !absent ? "activeClassDenoter" : "activeAbsentClassDenoter" : !absent ? "classDenoter" : "absentClassDenoter"}>{`C${index + 1}`}</p>
                                                            ))}
                                                        </div>
                                                    </div>}
                                                </td>
                                            </>
                                        ))}
                                    </tr>
                                </>
                            ))}
                        </table>
                        <p className="nextYearText">{courseSessionReports.length > 0 && new Date(get(courseSessionReports[0], 'sessionStartDate')).getFullYear() + 1}</p>
                        <table className='nextYearTableContainer'>
                            <tr>
                                {monthsArr.map((month, index) => (
                                    <th>
                                        {month}
                                    </th>
                                ))}
                            </tr>
                            {monthWeeks.map((month, indexx) => (
                                <>
                                    <tr>
                                        {monthsArr.map((week, weekIndex) => (
                                            <>
                                                <td>
                                                    {courseType !== 'batch session' && <div className="classStatusIndicatorContainer">
                                                        <div className="classStatusIndicator" >
                                                            {nextYearRenderHandler() && courseSessionReports.length > 0 && courseSessionReports.map((courseSessionReport, index) => (
                                                                (classDateHandler(courseSessionReport)) === indexx && week === (get(courseSessionReport, 'sessionStatus') === 'completed' ? format(get(courseSessionReport, 'sessionStartDate'), 'MMMM') : format(get(courseSessionReport, 'menteeSession.bookingDate'), 'MMMM')) && classNumberInWeekHandler(courseSessionReport) === 0 && yearHandler(courseSessionReport) && <p onClick={() => { sessionChangeHandler(index) }} className={activeClassDetailsHandler(index) ? "activeClassDenoter" : "classDenoter"} >{`${index + 1}`}</p>
                                                            ))}
                                                        </div>
                                                        <div className="classStatusIndicator">
                                                            {nextYearRenderHandler() && courseSessionReports.length > 0 && courseSessionReports.map((courseSessionReport, index) => (
                                                                (classDateHandler(courseSessionReport)) === indexx && week === (get(courseSessionReport, 'sessionStatus') === 'completed' ? format(get(courseSessionReport, 'sessionStartDate'), 'MMMM') : format(get(courseSessionReport, 'menteeSession.bookingDate'), 'MMMM')) && classNumberInWeekHandler(courseSessionReport) === 1 && (new Date(get(courseSessionReports[0], 'sessionStartDate')).getFullYear() + 1 === new Date(get(courseSessionReport, 'sessionStartDate').getFullYear())) && <p onClick={() => { sessionChangeHandler(index) }} className={activeClassDetailsHandler(index) ? "activeClassDenoter" : "classDenoter"}>{`${index + 1}`}</p>
                                                            ))}
                                                        </div>
                                                        <div className="classStatusIndicator">
                                                            {nextYearRenderHandler() && courseSessionReports.length > 0 && courseSessionReports.map((courseSessionReport, index) => (
                                                                (classDateHandler(courseSessionReport)) === indexx && week === (get(courseSessionReport, 'sessionStatus') === 'completed' ? format(get(courseSessionReport, 'sessionStartDate'), 'MMMM') : format(get(courseSessionReport, 'menteeSession.bookingDate'), 'MMMM')) && classNumberInWeekHandler(courseSessionReport) === 2 && (new Date(get(courseSessionReports[0], 'sessionStartDate')).getFullYear() + 1 === new Date(get(courseSessionReport, 'sessionStartDate').getFullYear())) && <p onClick={() => { sessionChangeHandler(index) }} className={activeClassDetailsHandler(index) ? "activeClassDenoter" : "classDenoter"} >{`${index + 1}`}</p>
                                                            ))}
                                                        </div>
                                                    </div>}
                                                    {courseType === 'batch session' && <div className="classStatusIndicatorContainer">
                                                        <div className={!absent ? "classStatusIndicator" : "absentClassStatusIndicator"} >
                                                            {batchSessionsData.length > 0 && batchSessionsData.map((batchSessionReport, index) => (
                                                                (classDateHandler(batchSessionReport)) === indexx && week === (get(batchSessionReport, 'sessionStatus') === 'completed' ? format(get(batchSessionReport, 'sessionStartDate'), 'MMMM') : format(get(batchSessionReport, 'bookingDate'), 'MMMM')) && classNumberInWeekHandler(batchSessionReport) === 0 && <p onClick={() => { sessionChangeHandler(index) }} className={activeClassDetailsHandler(index) ? !absent ? "activeClassDenoter" : "activeAbsentClassDenoter" : !absent ? "classDenoter" : "absentClassDenoter"}>{`C${index + 1}`}</p>
                                                            ))}
                                                        </div>
                                                        <div className={!absent ? "classStatusIndicator" : "absentClassStatusIndicator"}>
                                                            {batchSessionsData.length > 0 && batchSessionsData.map((batchSessionReport, index) => (
                                                                (classDateHandler(batchSessionReport)) === indexx && week === (get(batchSessionReport, 'sessionStatus') === 'completed' ? format(get(batchSessionReport, 'sessionStartDate'), 'MMMM') : format(get(batchSessionReport, 'bookingDate'), 'MMMM')) && classNumberInWeekHandler(batchSessionReport) === 1 && <p onClick={() => { sessionChangeHandler(index) }} className={activeClassDetailsHandler(index) ? !absent ? "activeClassDenoter" : "activeAbsentClassDenoter" : !absent ? "classDenoter" : "absentClassDenoter"}>{`C${index + 1}`}</p>
                                                            ))}
                                                        </div>
                                                        <div className={!absent ? "classStatusIndicator" : "absentClassStatusIndicator"}>
                                                            {batchSessionsData.length > 0 && batchSessionsData.map((batchSessionReport, index) => (
                                                                (classDateHandler(batchSessionReport)) === indexx && week === (get(batchSessionReport, 'sessionStatus') === 'completed' ? format(get(batchSessionReport, 'sessionStartDate'), 'MMMM') : format(get(batchSessionReport, 'bookingDate'), 'MMMM')) && classNumberInWeekHandler(batchSessionReport) === 2 && <p onClick={() => { sessionChangeHandler(index) }} className={activeClassDetailsHandler(index) ? !absent ? "activeClassDenoter" : "activeAbsentClassDenoter" : !absent ? "classDenoter" : "absentClassDenoter"}>{`C${index + 1}`}</p>
                                                            ))}
                                                        </div>
                                                    </div>}
                                                </td>
                                            </>
                                        ))}
                                    </tr>
                                </>
                            ))}
                        </table>
                    </div>
                </div>
                <div className="classDetailsInReportFlexContainer">
                    <div className="classDetailsInReportContainer">
                        <p className="classDetailsText">Class Details</p>
                        <div className="classDetailsInReportTopSection">
                            <img src={courseSessionReports.length > 0 && getFullPath(get(courseSessionReports[currentClassDetailsIndex], 'topic.thumbnailSmall.uri'))} alt="" className="topicThumbnailImage" />
                            <div className="classDetailsInReportTopSectionDesc">
                                <p style={{ fontSize: "10px", color: "#9E9E9E" }}>Class <span style={{ color: "black" }}>1</span></p>
                                <h4 style={{ fontWeight: "bold" }}>{courseSessionReports.length > 0 && get(courseSessionReports[currentClassDetailsIndex], 'topic.title')}</h4>
                                {courseType !== 'batch session' && <div className="classDetailsInReportTimeDesc">
                                    {clock}
                                    <p>{classTimeHandler()}</p>
                                    <p>{courseSessionReports.length > 0 && get(courseSessionReports[currentClassDetailsIndex], 'sessionStatus') === "completed" ? `${courseSessionReports.length > 0 && get(courseSessionReports[currentClassDetailsIndex], 'sessionStartDate').split('-')[1]} ${courseSessionReports.length > 0 && format(get(courseSessionReports[currentClassDetailsIndex], 'sessionStartDate'), 'MMMM').split(format(get(courseSessionReports[currentClassDetailsIndex], 'sessionStartDate'), 'MMMM')[3])[0]}` : `
                                ${courseSessionReports.length > 0 && get(courseSessionReports[currentClassDetailsIndex], 'menteeSession.bookingDate').split('-')[1]} ${courseSessionReports.length > 0 && format(get(courseSessionReports[currentClassDetailsIndex], 'menteeSession.bookingDate'), 'MMMM')}`}</p>
                                </div>}
                                {courseType === 'batch session' && <div className="classDetailsInReportTimeDesc">
                                    {clock}
                                    <p>{classTimeHandler()}</p>
                                    <p>{batchSessions.length > 0 && get(batchSessionsData[batchSessionIndex],'sessionStatus') === "completed" ? `${batchSessionsData.length > 0 && get(batchSessionsData[batchSessionIndex], 'sessionStartDate').split('-')[1]} ${batchSessionsData.length > 0 && format(get(batchSessionsData[batchSessionIndex], 'sessionStartDate'), 'MMMM').split(format(batchSessionsData[batchSessionIndex].sessionStartDate, 'MMMM')[3])[0]} ${batchSessionsData.length > 0 && batchSessionsData[batchSessionIndex].sessionStartDate.split('-')[0]}` : `
                                ${batchSessionsData.length > 0 && get(batchSessionsData[currentClassDetailsIndex], 'bookingDate').split('-')[1]} ${batchSessionsData.length > 0 && format(get(batchSessionsData[batchSessionIndex], 'bookingDate'), 'MMMM')} ${courseSessionReports.length > 0 && get(batchSessionsData[batchSessionIndex], 'bookingDate').split('-')[0]}`}</p>
                                </div>}
                            </div>
                        </div>
                        <div className="classDetailsInReportProgressDetails">
                            <p>Class Progress</p>
                            <p>{courseSessionReports.length > 0 && get(courseSessionReports[currentClassDetailsIndex], 'sessionStatus') === 'completed' ? `100%` : `0%`}</p>
                        </div>
                        <div className="classDetailsInReportProgressBarOuter">
                            <div className="classDetailsInReportProgressBarInner" style={{ width: courseSessionReports.length > 0 && get(courseSessionReports[currentClassDetailsIndex], 'sessionStatus') === 'completed' ? `100%` : `0%` }}>
                            </div>
                        </div>
                        <div className="mentorAssignedDetails">
                            <p className="mentorAssignedText">Mentor Assigned</p>
                            <div className="mentorDetails">
                                {courseType !== 'batch session' && <img className="mentorProfilePic" src={getFullPath(get(courseSessionReports[currentClassDetailsIndex], 'mentorSession.user.profilePic.uri'))} alt="" />}
                                {courseType === 'batch session' && <img className="mentorProfilePic" src={getFullPath(get(batchSessionsData[batchSessionIndex], 'mentorSession.user.profilePic.uri'))} alt="" />}
                                {courseType !== 'batch session' && <h4 className="mentorAssignedName">{get(courseSessionReports[currentClassDetailsIndex], 'mentorSession.user.name')}</h4>}
                                {courseType === 'batch session' && <h4 className="mentorAssignedName">{get(batchSessionsData[batchSessionIndex], 'mentorSession.user.name')}</h4>}
                                {courseType !== 'batch session' && <div className="AssignedMentorRating">
                                    {ratingLogoHandler()}
                                    <p className="assignedMentorRatingText">{ratingHandler(courseSessionReports[currentClassDetailsIndex])}</p>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="attendanceAndHomworkCardContainer">
                        <div className="attendanceCardContainer">
                            <p className="attendanceCardText">Attendance</p>
                            <div className="attendanceCardDescAndPieChartContainer">
                                <div className="attendanceCardDesc">
                                    <div className="attendanceDetailsInReportTimeDesc">
                                        {clock}
                                        <p>{classTimeHandler()}</p>
                                    </div>
                                    <div className="attendanceDetailsJoinDesc">
                                        <p>Joining Time</p>
                                        {courseType === 'batch session' && <p>{get(batchSessionsData[currentClassDetailsIndex], 'sessionStartDate')}</p>}
                                        {courseType !== 'batch session' && <p>{courseSessionReports.length > 0 && joiningTimeHandler(get(courseSessionReports[currentClassDetailsIndex], 'sessionStartDate'))}</p>}
                                    </div>
                                    <div className="attendanceDetailsLeaveDesc">
                                        <p>Leaving Time</p>
                                        {courseType === 'batch session' && <p>{batchSessionsData[currentClassDetailsIndex].sessionEndDate}</p>}
                                        {courseType !== 'batch session' && <p>{courseSessionReports.length > 0 && leavingTimeHandler(get(courseSessionReports[currentClassDetailsIndex], 'sessionEndDate'))}</p>}
                                    </div>
                                </div>
                                <div className="pieChartContainer">
                                    <div className="pieChart" ref={pieChartDisplay}>
                                        {courseType !== 'batch session' && courseSessionReports.length > 0 && courseSessionReports[currentClassDetailsIndex].sessionEndDate && <h3>{(((get(courseSessionReports[currentClassDetailsIndex], 'sessionEndDate').split('T')[1]).split('.')[0])).split(':')[1] - (((get(courseSessionReports[currentClassDetailsIndex], 'sessionStartDate').split('T')[1]).split('.')[0])).split(':')[1]} Mins</h3>}
                                        {courseType === 'batch session' && batchSessionsData.length > 0 && <h3>{(((batchSessionsData[batchSessionIndex].sessionEndDate.split('T')[1]).split('.')[0])).split(':')[1] - (((get(batchSessionsData[batchSessionIndex], 'sessionStartDate').split('T')[1]).split('.')[0])).split(':')[1]} Mins</h3>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="homeworkCardContainer">
                            <div className="homeworkCardWrapper">
                                <p className="homeworkCardText">Homework</p>
                                <div className="attemptsDetails">
                                    {clock}
                                    <p>Attemps taken: {userQuizData.length}</p>
                                </div>
                                {userQuizData.length > 0 && userQuizData.map((userQuizReport, index) => (
                                    <>
                                        {index <= 4 && <div className="attemptsDetailDesc" style={{ marginTop: "1.2vh" }}>
                                            <p onClick={() => { setCurrentAttemptIndex(index) }}>{attemptsDisplayHandler(index)} Attempt taken</p>
                                            <p>{userQuizReport.createdAt}</p>
                                        </div>}
                                        {index > 4 && viewMoreAttempts &&
                                            <div className="attemptsDetailDesc" style={{ marginTop: "1.2vh" }}>
                                                <p onClick={() => { setCurrentAttemptIndex(index) }}>{attemptsDisplayHandler(index)} Attempt taken</p>
                                                <p>{userQuizReport.createdAt}</p>
                                            </div>
                                        }
                                    </>
                                ))}
                                {userQuizData.length === 0 &&
                                    <div className="noAttemptsDetailDesc">
                                        <p>No Attempts</p>
                                    </div>
                                }
                                {userQuizData.length > 4 && <button className="viewMoreBtn" onClick={() => {
                                    setViewMoreAttempts(!viewMoreAttempts)
                                }}>{viewMoreAttempts ? 'View Less' : 'View More'}</button>}
                            </div>
                            {userQuizData.length > 0 && <div className="homeworkCardProgressContainer">
                                <div className="totalQuestionsContainer">
                                    <div className="totalQuestionsText">
                                        <p>Total Questions</p>
                                        <p>{get(userQuizData[currentAttemptIndex], 'totalQuestionCount')}</p>
                                    </div>
                                    <div className="totalQuestionsProgressBar">
                                        <div className="totalQuestionsProgressBarInner">
                                        </div>
                                    </div>
                                </div>
                                <div className="CorrectAnswersContainer">
                                    <div className="CorrectAnswersText">
                                        <p>Correct</p>
                                        <p>{get(userQuizData[currentAttemptIndex], 'correctQuestionCount')}</p>
                                    </div>
                                    <div className="CorrectAnswersProgressBar">
                                        <div className="CorrectAnswersProgressBarInner">
                                        </div>
                                    </div>
                                </div>
                                <div className="inorrectAnswersContainer">
                                    <div className="inorrectAnswersText">
                                        <p>Incorrect</p>
                                        <p>{get(userQuizData[currentAttemptIndex], 'inCorrectQuestionCount')}</p>
                                    </div>
                                    <div className="inorrectAnswersProgressBar">
                                        <div className="inorrectAnswersProgressBarInner">
                                        </div>
                                    </div>
                                </div>
                                <div className="unansweredQuestionsContainer">
                                    <div className="unansweredQuestionsText">
                                        <p>Unanswered</p>
                                        <p>{get(userQuizData[currentAttemptIndex], 'unansweredQuestionCount')}</p>
                                    </div>
                                    <div className="unansweredQuestionsProgressBar">
                                        <div className="unansweredQuestionsProgressBarInner">
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
