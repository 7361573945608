import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import withNav from '../../components/withNav'
import injectProps from '../../components/injectProps'
import MentorRatingAnalysis from './MentorRatingAnalysis'

const MentorRatingAnalysisNav = withNav(MentorRatingAnalysis)({
  title: 'Mentor Rating Analysis',
  activeNavItem: 'Mentor Rating',
  showUMSNavigation: true,
})

const mapStateToProps = (state) => ({
  particularMentorRatings: state.data.getIn(['particularMentorRatingsData', 'data']),
  hasParticularMentorRatingsFetched: state.data.getIn([
    'particularMentorRatings',
    'fetchStatus',
    'particularMentorRatings',
    'success',
  ]),
  isParticularMentorRatingsFetching: state.data.getIn([
    'particularMentorRatings',
    'fetchStatus',
    'particularMentorRatings',
    'loading',
  ]),
  ratingsFetchStatus: state.data.getIn([
    'particularMentorRatings',
    'fetchStatus'
  ]),
})

const MentorRatingAnalysisNavWithExtraProps = injectProps({
  notification,
})(MentorRatingAnalysisNav)

export default connect(mapStateToProps)(
  withRouter(MentorRatingAnalysisNavWithExtraProps)
)
