import styled from 'styled-components'
import colors from '../../../constants/colors'

const BatchStyle = styled.div`
    height: 100%;
    width: 100%;
    margin: 0 auto;
    font-family: 'Inter' !important;
`
const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    background: #FFFFFF;
    border-radius: 8px;
    padding-top: 25px;
    padding-left: 20px;
    padding-bottom: 15px;
`
const Heading = styled.div`
    font-weight: 500;
    font-size: 16px;
    color: #212121;
    width: auto;
`
const Devider = styled.div`
    height: 30px;
    width: 2px;
    background: #606061;
    margin-left: 10px;
    margin-right: 10px;
`

const AuditStatus = styled.div`
width: 12px;
height: 12px;
background: ${props => colors.status[props.status]};
border-radius: 50%;
display: inline-block;
margin-left: 20px;
`


BatchStyle.TopContainer = TopContainer
BatchStyle.Heading = Heading
BatchStyle.Devider = Devider
BatchStyle.AuditStatus = AuditStatus


export default BatchStyle
