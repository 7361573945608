import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  MainContainer,
  HeadingContainer,
  MainContainerHeading,
  ReshuffleContainer,
  ReshuffleSwitch,
  TableHeadingContainer,
} from "./SessionSequence.style";
import { get, sortBy } from "lodash";
import { MenuOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { withRouter } from "react-router-dom";

const reorder = (list, startIndex, endIndex, isTeacherTraining) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  let courseTitlesArray = new Set();
  for (let i = 0; i < result.length; i++) {
    courseTitlesArray.add(result[i].courseTitle);
  }
  if (isTeacherTraining == false || courseTitlesArray.size !== 1) {
    for (let i = 0; i < result.length; i++) {
      for (let j = i; j < result.length; j++) {
        if (
          result[i].courseTitle === result[j].courseTitle &&
          result[i].order > result[j].order
        ) {
          notification.warn({
            message: "topic in same course should always be in order",
          });
          return;
        }
      }
    }
  }
  let revisionIndex = 1;
  for (let i = 0; i < result.length; i++) {
    if (result[i].isRevision) {
      if (i == 0) {
        notification.warn({
          message: "can not have revision session on first position",
        });
        return;
      }
      result[i].revisionOrder = revisionIndex++;
      for (let j = i - 1; j >= 0; j--) {
        if (!result[j].isRevision) {
          result[i].previousTopicOrder = result[j].order;
          break;
        }
      }
    }
  }
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  width: "100%",
  display: "flex",
  ...draggableStyle,
});

class SessionSequence extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: this.props.sessionArray,
      dragableDisabled: true,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount = () => {
    const { packageId } = this.props.match.params;
    const { items } = this.state;
    const { mapCourseWithTopic, revisionSessionArray } = this.props;
    const newItems = [...items];
    for (let i = 0; i < newItems.length; i++) {
      for (let j = 0; j < mapCourseWithTopic.length; j++) {
        if (newItems[i].id === mapCourseWithTopic[j].id) {
          newItems[i].order = mapCourseWithTopic[j].order;
        }
      }
    }
    const tempArray = newItems.sort((a, b) => a.order - b.order);
    const tempRevisionArray = [...revisionSessionArray];
    if (
      packageId &&
      tempRevisionArray.length &&
      get(tempRevisionArray, "[0].previousTopicOrder")
    ) {
      const newTempRevisionArray = tempRevisionArray.sort(
        (a, b) => a.previousTopicOrder - b.previousTopicOrder
      );
      newTempRevisionArray.forEach((item, index) => {
        tempArray.splice(item.previousTopicOrder + index, 0, item);
      });
      this.getCourseTopicOrder(tempArray);
    } else {
      tempRevisionArray.length &&
        tempRevisionArray.forEach((item, index) => {
          item.previousTopicOrder = tempArray[tempArray.length - 1].order;
          item.revisionOrder = index + 1;
        });
      const newTempArray = [...tempArray, ...tempRevisionArray];
      this.getCourseTopicOrder(newTempArray);
    }
  };

  getCourseTopicOrder = (tempArray) => {
    const { choosenCourses } = this.props;
    choosenCourses &&
      choosenCourses.length > 0 &&
      choosenCourses.map((course) => {
        course.topics &&
          course.topics.length > 0 &&
          (sortBy(get(course, "topics", []), "order") || []).forEach(
            (item, i) => {
              tempArray.forEach((arr) => {
                if (item.id == arr.id) {
                  arr.courseOrder = i + 1;
                }
              });
            }
          );
      });
    this.setState({ items: tempArray });
  };

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index,
      this.props.isTeacherTraining
    );

    if (items !== undefined) {
      this.setState({
        items,
      });
      this.props.handleSetSessionArray(items);
    }
  }
  render() {
    return (
      <MainContainer>
        <HeadingContainer>
          <MainContainerHeading>
            Default sequence of session
          </MainContainerHeading>
          <ReshuffleContainer>
            <h3>Reshuffle Sequence</h3>
            <ReshuffleSwitch
              onChange={() => {
                this.setState({
                  dragableDisabled: !this.state.dragableDisabled,
                });
                this.props.setIsTopicsChanged(true);
              }}
            />
          </ReshuffleContainer>
        </HeadingContainer>
        <TableHeadingContainer>
          <p
            style={{
              textAlign: this.state.dragableDisabled ? "center" : "left",
              width: this.state.dragableDisabled ? "10%" : "5%",
              marginLeft: !this.state.dragableDisabled ? "5%" : null,
            }}
          >
            #
          </p>
          <p>Session Type</p>
          <p>Course Name</p>
          <p>Course Type</p>
          <p>Chapter</p>
          <p>Session Title</p>
          <p>Description</p>
        </TableHeadingContainer>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {this.state.items.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={get(item, "id", "")}
                    index={index}
                    isDragDisabled={this.state.dragableDisabled}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div
                          className="table-data-container"
                          style={{
                            cursor: !this.state.dragableDisabled
                              ? "pointer"
                              : "default",
                          }}
                        >
                          {!this.state.dragableDisabled && (
                            <MenuOutlined style={{ width: "5%" }} />
                          )}
                          <p
                            className="name-number-style"
                            style={{
                              textAlign: this.state.dragableDisabled
                                ? "center"
                                : "left",
                              width: this.state.dragableDisabled ? "10%" : "5%",
                            }}
                          >
                            {index + 1}
                          </p>
                          <button className="collapsable-button">
                            <span>
                              {get(item, "isRevision")
                                ? "revision"
                                : "learning"}
                            </span>
                          </button>
                          <p className="name-number-style">
                            {get(item, "courseTitle", "-")}
                          </p>
                          <button className="collapsable-button">
                            <span>{get(item, "courseCategory", "-")}</span>
                          </button>
                          <p className="chapter-title-style">
                            {get(item, "chapterName", "-")}
                          </p>
                          <p
                            className="chapter-title-style"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <button
                              className="collapsable-button"
                              style={{ marginRight: "10px" }}
                            >
                              <span>
                                {get(item, "isRevision")
                                  ? get(item, "revisionOrder")
                                  : get(item, "courseOrder")}
                              </span>
                            </button>
                            {get(item, "title", "-")}
                          </p>
                          <p className="chapter-title-style">
                            {get(item, "description", "-")}
                          </p>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </MainContainer>
    );
  }
}

export default withRouter(SessionSequence);
