import moment from 'moment'
import React from 'react'
import { TagTab, PaginationContainer } from '../EventSettings.style'
import { Pagination } from 'antd'
import DisplayStatus from './DisplayStatus'
import Status from './Status'
import { get } from 'lodash'

class Tags extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: [],
      tabType: 'Tag',
      showStatusPopup: false
    }
  }

  componentDidMount = () => {
    this.sessionOnMount()
  }

  sessionOnMount = () => {
    const columns = [
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        render: (_id, _record, index) => (this.props.page + index + 1)
      },
      {
        title: 'Tag Name',
        dataIndex: 'title',
        key: 'title',
        align: 'center',
        render: (tagName) => (
          <TagTab.TagNameContainer>{tagName}</TagTab.TagNameContainer>
        )
      },
      {
        title: 'Display on Website',
        dataIndex: 'displayOnWebsite',
        key: 'displayOnWebsite',
        align: 'center',
        render: (displayOnWebsite, record) => (
          <DisplayStatus
            shouldBeDisabled={record.tagStatus === 'archive'}
            status={displayOnWebsite}
            id={record.id}
            tabType={this.state.tabType}
            updateDisplayStatus={this.updateDisplayStatus}
          />
        )
      },
      {
        title: 'Created On',
        dataIndex: 'createdAt',
        key: 'createdAt',
        align: 'center',
        render: (createdOn) => moment(createdOn).format('Do MMM YY') + `'`
      },
      {
        title: 'Created By',
        dataIndex: 'createdBy',
        key: 'createdBy',
        align: 'left',
        render: (createdBy) => get(createdBy, 'name') || '-'
      },
      {
        title: 'Events Used In',
        dataIndex: 'eventsMeta',
        key: 'eventsMeta',
        align: 'center',
        render: (eventsMeta) => get(eventsMeta, 'count') || 0
      },
      {
        title: 'Status',
        dataIndex: 'tagStatus',
        key: 'tagStatus',
        align: 'center',
        render: (tagStatus, record) => (
          <Status
            status={tagStatus}
            id={record.id}
            tabType={this.state.tabType}
            updateStatus={this.updateStatus}
          />
        )
      }
    ]
    this.setState({
      columns: columns
    })
  }

  render() {
    const { columns } = this.state
    const { data, isLoading } = this.props
    return (
      <TagTab.TagContainer>
        <TagTab.TagTable
          columns={columns}
          dataSource={data}
          rowKey={"id"}
          bordered={false}
          loading={isLoading}
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      </TagTab.TagContainer>
    )
  }
}

export default Tags