import { connect } from 'react-redux'

import { filterKey } from '../../../../utils/data-utils'
import LabInspection from './LabInspection'

const mapStateToProps = (state) => ({
  studentProfiles: filterKey(state.data.getIn(['studentProfiles', 'data']), 'studentProfiles'),
  userCred: filterKey(state.data.getIn(['userCred', 'data']), 'userCred'),
  studentProfilesFetchStatus: state.data.getIn(['studentProfiles', 'fetchStatus', 'studentProfiles']),
  studentProfilesMeta: state.data.getIn(['studentProfilesMeta', 'data', 'count']),
  studentProfileUpdateStatus: state.data.getIn(['studentProfiles', 'updateStatus', 'studentProfiles']),
  parentSignUpStatus: state.data.getIn([
    'parentChildSignUp', 'addStatus', 'addParentSignUp'
  ]),

  labInspections: state.data.getIn(['labInspections', 'data']),
  labInspectionsFetchStatus: state.data.getIn(['labInspections', 'fetchStatus', 'labInspections']),
  labInspectionAddStatus: state.data.getIn(['labInspections', 'addStatus', 'labInspections']),
  inspectors: state.data.getIn(['inspectors', 'data']),
  school: state.data.getIn(['school', 'data']),
  labs: state.data.getIn(['labs', 'data']),
});


export default connect(mapStateToProps)(LabInspection);
