/* eslint-disable no-console */
import React, { useState } from 'react'
import Modal from 'react-modal'
import { Upload, Select } from 'antd'
import fileUpload from '../../../../actions/upload/fileUpload'
import SVGIcon from '../../../../assets/icons'
import Main from '../../Upload.style'
import TableInfo from './TableFileInfo'
import { get } from 'lodash'

const { Dragger } = Upload

const UploadFileModal = ({ visible, closeUpload, fileBucket,
  fetchPages, fileBucketMapping }) => {
  const [File, setFile] = useState([])
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [bucket, setBucket] = useState(fileBucket[0])
  const { Option } = Select

  const closeUploadModal = () => {
    setTimeout(() => {
      setFile([])
      closeUpload()
      setBucket(fileBucket[0])
    }, 1000)
  }

  const addFile = async (e) => {
    e.persist()
    if (File.length && !loading) {
      setLoading(true)
      for (const file of File) {
        const status = await fileUpload({
          file: file,
          bucket: fileBucketMapping[bucket] || 'python'
        })
      }
      setFile([])
      setUploading(true)
      setLoading(false)
      closeUploadModal()
      fetchPages()
    }
  }
  const props = {
    name: 'file',
    accept: '.mp4, .png, .jpeg, .jpg, .gif, .json, .octet-stream, .pdf, .webp, .mdb, .accdb, .accdt, .sb3, .aia',
    multiple: true,
    onChange(info) {
      const { file } = info
      if (file) {
        setFile(prevFiles => [...prevFiles, file])
      }
      setUploading(false)
      setError(false)
    },
    showUploadList: false,
    beforeUpload: file => {
      return false
    },
    File,
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    }
  }
  const onDeleteFile = (file) => {
    setFile(prevFiles => [...prevFiles].filter(fileData => get(fileData, 'uid') !== get(file, 'uid')))
  }
  const fileBucketSelect = (value) => {
    setBucket(value)
    setError(false)
    setUploading(false)
    setLoading(false)
  }
  return (
    <Modal
      isOpen={visible}
      onRequestClose={() => {
        closeUpload()
        setFile([])
      }}
      style={
        {
            overlay: {
                background: 'rgba(0, 23, 31, 0.5)'
            },
            content: {
                color: 'white',
                width: '70%',
                height: '500px',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                overflow: 'hidden'
            }
        }
    }
    >
      <Main.TopContainer>
        <Select defaultValue={fileBucket[0]} value={bucket} style={{ width: 160, marginRight: '15px' }} onChange={fileBucketSelect} >
          {fileBucket.map(value => (<Option key={value}>{value}</Option>))}
        </Select>
        <Main.Upload
          type='primary'
          shape='round'
          style={{ marginRight: '10px', display: 'flex', justifyContent: 'space-evenly' }}
          onClick={addFile}
        >
          <SVGIcon.UploadIcon />
          <div style={{ textShadow: 'initial' }} >
            UPLOAD
          </div>
        </Main.Upload>
        <Main.Cancel
          type='primary'
          shape='round'
          onClick={() => {
             closeUpload()
            setFile([])
            setLoading(false)
            setUploading(false)
            }}
          ghost
        > CANCEL
        </Main.Cancel>
      </Main.TopContainer>
      <Main.UploadContainer>
        <div>
        <Dragger
          {...props}
          style={{ height: '50%', background: 'rgba(230, 247, 253, 0.4)', borderRadius: '24px', padding: '20px' }}
        >
          <Main.DragFile>
          Click to browse or <br /> drag and drop your files
          </Main.DragFile>
        </Dragger>
        </div>
        {File.length ?
          <TableInfo
            File={File}
            setFile={setFile}
            uploading={uploading}
            loading={loading}
            error={error}
            onDeleteFile={onDeleteFile}
          /> : null
        }
      </Main.UploadContainer>
    </Modal>
  )
}
export default UploadFileModal
