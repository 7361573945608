import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../../duck'

const fetchSlides = async (learningObjectiveId) =>
  duck.query({
    query: gql`
    {
        learningSlides(filter: {and: [{learningObjectives_some: {id: "${learningObjectiveId}"}}]}){
            id
            name
            type
            googleSlideLink
            layoutType
            order
            status
            createdAt
            practiceQuestions {
              id
              order
              status
              statement
              hint
              questionType
              assessmentType
              questionLayoutType
              blockLayoutType
              difficulty
              answerCodeSnippet
              questionCodeSnippet
              explanation
              mcqOptions {
                statement
                isCorrect
                blocksJSON
                initialXML
                questionBankImage {
                  id
                  image {
                    uri
                  }
                }
              }
              fibBlocksOptions {
                displayOrder
                statement
                correctPositions
              }
              fibInputOptions {
                correctPosition
                answers
              }
              arrangeOptions {
                displayOrder
                statement
                correctPosition
              }
            }
      }
    }
    `,
    type: 'learningSlides/fetch',
    key: 'learningSlides',
  })

export default fetchSlides

