import gql from 'graphql-tag'
import duck from '../../../duck'

const fetchSlideContents = async (learningSlideId) =>
  duck.query({
    query: gql`
    {
        learningSlideContents(filter:{learningSlides_some:{id:"${learningSlideId}"}}){
              id
              type
              gridPlacement
              codeInput
              codeOutput
              type
              statement
              url
              connectedls:learningSlides{
                id
              }
              media{
                id
                uri
              }
              codeEditorConfig {
                editorMode
                layout
                executionAccess
              }
          }
    }
    `,
    type: 'learningSlideContents/fetch',
    key: 'learningSlideContents',
  })

export default fetchSlideContents

