/*eslint-disable*/
import gql from "graphql-tag";
import duck from "../../duck";

const deleteQuestionBankImage = async (questionBankImageId) => {
    const res = duck.query({
        query:gql`
        mutation{
            deleteQuestionBankImage(id:"${questionBankImageId}") {
            id
          }
        }`,
        type:"deleteQuestionBankImage/delete",
        key:"deleteQuestionBankImage",
 })
 return res
}

export default deleteQuestionBankImage;