import gql from "graphql-tag"
import duck from "../../../duck"
import getDataFromLocalStorage from "../../../utils/extract-from-localStorage"

const addEventCategory = async (input) => {
    await duck.query({
      query: gql`
        mutation($input: EventCategoryInput!) {
            addEventCategory(
                input: $input
                createdByConnectId: "${getDataFromLocalStorage('login.id')}" 
                ) {
                    id
                    title
                    displayOnWebsite
                    createdAt
                    status
                    createdBy {
                        name
                    }
                    eventsMeta {
                        count
                    }
                }
            }
        `,
        variables: {
            input
        },
        type: 'eventCategories/add',
        key: 'eventCategories'
        })
    }
  
  export default addEventCategory