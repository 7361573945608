import gql from 'graphql-tag'
import duck from '../../duck'

const updateFile = async (id, filename) => {
  duck.query({
    query: gql`
          mutation{
              updateFile(id:"${id}", input:{ name: "${filename}" }) {
                  id
                  name
              }
          }
      `,
    type: 'uploadFiles/update',
    key: 'uploadFiles',
  })
}

export default updateFile
