import gql from 'graphql-tag'
import duck from '../../duck'

const fetchUtmDetails = async (leadFilter = '') => duck.query({
  query: gql`
  {
    utmDetails(${leadFilter || ''}, orderBy: createdAt_DESC) {
      id
      source
      content
      campaign
      term
      medium
      leadPartnerAgent {
        id
        leadPartner {
          id
          title
        }
        agent {
          id
          name
        }
      }
    }
  }  
    `,
  type: 'utm/fetch',
  key: 'utm',
})

export default fetchUtmDetails
