import styled from 'styled-components'

const StudentTabStyle = styled.div`
    height: 100%;
    width: 100%;
    margin: 0 auto;
    font-family: 'Inter' !important;
`
const Title = styled.div`
    font-weight: 600;
    font-size: 24px;
    color: #212121;
`
const TopContainer = styled.div`
    padding-left: 10px;
    padding-right: 10px;
`
const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
`
const ErrorMessage = styled.div`
    font-size: 30px;
    display: flex;
    justify-content:center;
    align-items: center;
    font-color: #78678C;
    color: #78678C;
`
StudentTabStyle.Title = Title
StudentTabStyle.FlexContainer = FlexContainer
StudentTabStyle.TopContainer = TopContainer
StudentTabStyle.ErrorMessage = ErrorMessage

export default StudentTabStyle
