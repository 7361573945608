import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'
import { TekieAmethyst } from '../../../constants/colors'

const loadingIcon = <LoadingOutlined style={{ fontSize: 16, marginRight: '8px', color: TekieAmethyst }} spin />

const PageLoader = ({ isfetching = false }) => isfetching ? (
  <>
    <div className='event-calendar-loading-container show'>
      <div className='loading-bar-container'>
        <div />
      </div>
    </div>
  </>
) : null

export default PageLoader
