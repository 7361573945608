import { Select, Input } from 'antd'
import { FileExcelOutlined, ShareAltOutlined } from '@ant-design/icons'
import { Form, Formik } from 'formik'
import React from 'react'
import { get } from 'lodash'
import { FlexContainer, SectionButton, StyledSelect } from '../../SchoolOnBoarding.style'
import studentValidationSchema from '../school-utils/studentValidationSchema'
import type from '../../../../constants/types'
import timeDuration from '../../../../constants/timeDuration'
import restrictedNumverValues from '../../../../constants/restrictedNumberValues'
import sections from '../../../../constants/sections'
const { Option } = Select

const GenerateLinkEditForm = (props) => {
  const { loadingStatus, editStudent, emailLoading, onDownloadExcel,
    onEmailCredentials,
    classGrades } = props

  return (
    <Formik
      initialValues={{
        section: get(editStudent, 'section'),
        type: null,
        duration: null
      }}
      validateOnBlur
      validationSchema={studentValidationSchema}
    >
      {({ values, setFieldValue}) => (

        <Form style={{ padding: '0 10px', width: '100%' }}>

          <>
            <FlexContainer noPadding justify='flex-start' style={{ marginBottom: '1vw' }}>
              <h4 style={{ marginBottom: 0 }}>Select Grade: </h4>
              {values.section !== 'ALL' ? (<>
                <StyledSelect
                  placeholder='Grade Select'
                  value={values.grade || 'Grade Select'}
                  style={{ width: 200, marginLeft: '10px' }}
                  onChange={value => {
                    setFieldValue('grade', value)


                    if (value !== 'ALL')
                      setFieldValue('section', undefined)
                    else
                      setFieldValue('section', 'ALL')
                    
                    
                  }}
                >

                  {<Option value={'ALL'}>{'ALL'}</Option>}
                  {classGrades.map(s => <Option key={s.grade} value={s.grade}>{`${s.grade}`}</Option>)}
                </StyledSelect>
              </>) :
                (
                  <>
                    <StyledSelect
                      placeholder='Grade Select'
                      value={values.grade || 'Grade Select'}
                      style={{ width: 200, marginLeft: '10px' }}
                      onChange={(value) => {
                        setFieldValue('grade', value)
                        setFieldValue('section', 'ALL')
                        if (value !== 'ALL')
                          setFieldValue('section', undefined)
                        
                        
                      }}
                    >

                      {<Option value={'ALL'}>{'ALL'}</Option>}
                      {classGrades.map(s => <Option key={s.grade} value={s.grade}>{`${s.grade}`}</Option>)}
                    </StyledSelect>
                  </>
                )
              }
            </FlexContainer>
            <FlexContainer noPadding justify='flex-start' style={{ marginBottom: '1vw' }}>
              <h4 style={{ marginBottom: 0 }}>Expiry Period: </h4>
              <StyledSelect
                placeholder='Type'
                value={values.type || 'Type'}
                style={{ width: 200, marginLeft: '10px' }}
                onChange={value => {
                  setFieldValue('type', value)
                  
                }}
              >
                {type.map(s => <Option key={s} value={s}>{`${s}`}</Option>)}
              </StyledSelect>
              <StyledSelect
                placeholder='Enter'
                value={values.duration || 'Enter'}
                style={{ width: 200, marginLeft: '10px' }}
                onChange={value => setFieldValue('duration', value)}
              >
                {timeDuration.map(s => <Option key={s} value={s}>{`${s}`}</Option>)}
              </StyledSelect>
            </FlexContainer>

            <FlexContainer noPadding justify='flex-start' style={{ marginBottom: '1vw' }}>
              <h4 style={{ marginBottom: 0 }}>Select Section: </h4>

              <StyledSelect
                value={values.section || 'Section'}
                style={{ width: 200, marginLeft: '10px' }}
                onChange={value => {
                  setFieldValue('section', value)
            
                }}
                placeholder='Select Section'
              >

                <Option value={'ALL'}>{'ALL'}</Option>
                {classGrades.map(entry => {
                  if (values.grade && (entry.grade === values.grade)) {

                    return (

                      entry.sections.map(g =>
                        <Option key={g.section} value={g.section}>{g.section}</Option>)
                    )
                  }
                })}
              </StyledSelect>


            </FlexContainer>

            <FlexContainer noPadding justify='flex-start' style={{ marginBottom: '1vw' }}>
              <h4 style={{ marginBottom: 0 }}>Link Visit Limit: </h4>
              <Input
                placeholder='Enter Limit'
                value={values.linkVisitLimit || ''}
                style={{ width: 200, marginLeft: '10px' }}
                type={'number'}
                onKeyDown={(e) => {
                  if (restrictedNumverValues.includes(e.key)) {
                    e.preventDefault()
                  }
                }}
                onChange={value => setFieldValue('linkVisitLimit', value.target.value)}
              />
            </FlexContainer>
            <FlexContainer style={{ width: '60%', margin: '0 auto' }}>
              <ShareAltOutlined style={{ backgroundColor: 'black', color: 'white', padding: 6, borderRadius: 4, fontSize: 20 }} />
              <SectionButton
                campaign
                onClick={() => onEmailCredentials(values)}
                style={{ margin: '0 10px' }}
                type='primary'
                loading={emailLoading}
                htmlType='submit'
              >Email Credentials
              </SectionButton>
              <FileExcelOutlined style={{ backgroundColor: 'black', color: 'white', padding: 6, borderRadius: 4, fontSize: 20 }} />
              <SectionButton
                campaign
                onClick={() => onDownloadExcel(values)}
                loading={loadingStatus}
                style={{ margin: '0 10px' }}
                type='primary'
              >Download Excel
              </SectionButton>
            </FlexContainer>
          </>
        </Form>
      )}
    </Formik>
  )
}

export default GenerateLinkEditForm
