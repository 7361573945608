import moment from 'moment'
import React, { useEffect } from 'react'
import { TagTab, PaginationContainer } from '../EventSettings.style'
import DisplayStatus from '../Tags/DisplayStatus'
import { Pagination } from 'antd'
import Status from '../Tags/Status'
import { get } from 'lodash'

class Categories extends React.Component {
  state = {
    columns: [],
    tabType: 'Category',
  }

  componentDidMount = () => {
    this.sessionOnMount()
  }

  sessionOnMount = () => {
    const columns = [
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        render: (_id, _record, index) => (this.props.page + index + 1)
      },
      {
        title: 'Category Name',
        dataIndex: 'title',
        key: 'title',
        align: 'center',
        render: (title) => (
          <TagTab.TagNameContainer>{title}</TagTab.TagNameContainer>
        )
      },
      {
        title: 'Display on Website',
        dataIndex: 'displayOnWebsite',
        key: 'displayOnWebsite',
        align: 'center',
        render: (displayOnWebsite, record) => (
          <DisplayStatus
            shouldBeDisabled={record.status === 'archive'}
            status={displayOnWebsite}
            id={record.id}
            tabType={this.state.tabType}
            updateDisplayStatus={this.updateDisplayStatus}
          />
        )
      },
      {
        title: 'Created On',
        dataIndex: 'createdAt',
        key: 'createdAt',
        align: 'center',
        render: (createdAt) => moment(createdAt).format('Do MMM YY') + `'`
      },
      {
        title: 'Created By',
        dataIndex: 'createdBy',
        key: 'createdBy',
        align: 'left',
        render: (createdBy) => get(createdBy, 'name') || '-'
      },
      {
        title: 'Events Used In',
        dataIndex: 'eventsMeta',
        key: 'eventsMeta',
        align: 'center',
        render: (eventsMeta) => get(eventsMeta, 'count', 0)
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: (status, record) => (
          <Status
            status={status}
            id={record.id}
            tabType={this.state.tabType}
            updateStatus={this.updateStatus}
          />
        )
      }
    ]
    this.setState({
      columns: columns
    })
  }

  render() {
    const { columns } = this.state
    const { data, isLoading } = this.props
    return (
      <TagTab.TagContainer>
        <TagTab.TagTable
          columns={columns}
          rowKey={"id"}
          dataSource={data}
          bordered={false}
          loading={isLoading}
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      </TagTab.TagContainer>
    )
  }
}

export default Categories