import styled from 'styled-components'
import {Button,Input,Select} from 'antd'
const {TextArea} =Input

const ComponentHolder = styled.div`
text-align: center;
width: 100%;
height: 100%;
border-radius: 11px;
`
const HeadingDiv = styled.div`
width: 100%;
display:flex;
flex-direction:row;
justify-content:space-between;
margin: 0px 0px 0px 7px;
`
const TypeHolder = styled.div`
display:flex;
flex-direction:row;
width:50%;
`
const PlaygroundHolder = styled.div`
display:flex;
flex-direction:row;
`
const TerminalType = styled.div`
font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 15px;
text-transform: capitalize;
color: #000000;
justify-content: center;
display: flex;
align-items: center;
margin: 0px 5px 0px 0px;
}
`
const PlaygroundOption = styled.div`
font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 15px;
text-transform: capitalize;
color: #000000;
justify-content: center;
display: flex;
align-items: center;
margin: 0px 5px 0px 9px;
}
`
const TerminalText = styled.div`
font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 16px;
text-transform: capitalize;
color: #D3D3D3;
justify-content: center;
display: flex;
align-items: center;
}
`
const ShowChat = styled.div`
font-family: 'Nunito';
font-style: normal;
font-weight: 400;
font-size: 14px;
text-transform: capitalize;
color: #000000;
justify-content: center;
display: flex;
align-items: center;
}
`

const StyledTextArea = styled(TextArea)`
&.ant-input {
 height:100% !important;
 border-radius:11px;
}
`
const AddImageButton = styled(Button)`
&.ant-btn {
    background: #8C61CB !important;
    color: #FFFFFF !important;
    font-family: 'Inter'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    border-radius: 8px !important;
    padding: 7px 16px 7px 12px !important;
}
`
const StyledSelect = styled(Select)`
width: 150px !important;
min-height: 38px !important; 
.ant-select-selection{
    border-radius:8px !important;
    border: 1px solid #AAAAAA !important;
}
.ant-select-selection--single{
    height:40px !important;
}
.ant-select-selection:hover {
    border-color:none !important;
}
.ant-select-selection-selected-value {
    margin-top: 4%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}
`
const SvgDiv = styled.div`
display: flex;
flex-direction:row;
justify-content: center;
align-items: center;
`
const SvgHolder = styled.div`
margin:0px 16px 0px 0px;
position:relative;
opacity:${ props => props.ticked ? `1` : `0.7`};
`
const CheckBoxHolder = styled.div`
display: flex;
justify-content: center;
align-items: center;

`
export {
    ComponentHolder,
    AddImageButton,
    StyledTextArea,
    HeadingDiv,
    TypeHolder,
    TerminalType,
    StyledSelect,
    SvgDiv,
    SvgHolder,
    TerminalText,
    ShowChat,
    PlaygroundOption,
    CheckBoxHolder,
    PlaygroundHolder
    }
    