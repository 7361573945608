import gql from "graphql-tag"
import requestToGraphql from "../../utils/requestToGraphql"

export const deleteEbook = (id) => {
    const query = gql`
        mutation {
            deleteEBook(id: "${id}"){
                id
            }
        }
    `
    const response = requestToGraphql(query)
    return response
}