import React, { useState } from "react";
import { Col, Modal, Row, Typography, Button, Divider as AntDivider } from "antd";
import {format} from 'date-fns';
import styled from "styled-components";

import Stack from "../../../../components/Stack";
import AddCommentModal from "./AddCommentModal";
import { useFormContext } from "./FormContext";

const { Text } = Typography;

const Title = styled(Text)`
	font-size: 16px;
	font-weight: ${(props) => props.weight || 600}};
`;

const CustomText = styled(Text)`
	color: #504f4f;
	letter-spacing: 0.2px;
`;

const CommentsModal = ({ open, handleClose, comments }) => {
	const { isAddCommentModalOpen, openAddCommentModal, closeAddCommentModal } = useFormContext();

	return (
		<>
			<Modal
				title="Comments"
				visible={open}
				onCancel={handleClose}
				centered
				footer={null}
			>
				<Stack>
					{comments.map((comment) => (
						<CommentItem key={comment.id} {...comment} />
					))}

					<Row gutter={8}>
						<Col span={12}>
							<Button size="large" block onClick={handleClose}>
								Close
							</Button>
						</Col>

						<Col span={12}>
							<Button
								size="large"
								type="primary"
								block
								onClick={openAddCommentModal}
							>
								Add Comment
							</Button>
						</Col>
					</Row>
				</Stack>
			</Modal>

			<AddCommentModal
				open={isAddCommentModalOpen}
				handleClose={closeAddCommentModal}
			/>
		</>
	);
};

const CommentItem = ({commentBy, comment, commentDate}) => {
	return (
		<Stack gap={8}>
			<Stack direction="row" gap={12}>
				<Title strong>{commentBy.name}</Title>
				<Title type="secondary" weight={500}>
					{/* date in numeric and month in short */}
					{format(new Date(commentDate), 'd MMM')}
				</Title>
			</Stack>

			<CustomText>
				{comment}
			</CustomText>

			<Divider />
		</Stack>
	);
};

const Divider = styled(AntDivider)`
	margin: 16px 0 !important;
`;

export default CommentsModal;
