import React, { useState } from "react"
import { Select } from "antd"

const options = [
  {value: "all", label: "All"},
  {value: "archive", label:"Archived"},
  {value: "active", label:"Active"}
]

const SelectDropdown = ({ filter, filteredListHandler }) => {
  
  const selectedOptionHandler = (value) => {
    const selectedFilter = options.find((option) => option.value === value)
    console.log(selectedFilter)
    filteredListHandler(selectedFilter)
  }

  return (
    <Select
      style={{ width: '180px' }}
      value={filter.label}
      onSelect={(value, _option) => {
        if (value !== filter.value) {
          selectedOptionHandler(value)
        }
      }}
    >
      {
        options.map((option) => (
            <Select.Option
              value={option.value}
              key={option.value}
            >
              {`${option.label}`}
            </Select.Option>
        ))
      }
    </Select>
  )
}

export default SelectDropdown