import React from 'react';
import styled from 'styled-components'
import { Button, Modal } from 'antd'

const MainContainer = styled.div`
    margin-top: 27px;
    color:#000000
`
const MainContainerHeading = styled.div`
    letter-spacing: normal;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #282828;
`
const StyledFormContainer = styled.div`
    display: flex;
    padding-top: 24px;
    justify-content: space-between;
`
const SelectContainer = styled.div`
    display: flex;
`
const SingleContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    > h3 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #333333;
        margin: 8px 0;
        :after {
            content:" *";
            color: #D34B57;
        }
    }
    .ant-select-selection--single {
        position: relative;
        height: 42px;
        cursor: pointer;
        padding: 4px 8px 4px 12px;
        border-radius: 8px;
        border: 1px solid #AAAAAA;
    }
`
const AddRemoveCourseButton = styled.p`
    letter-spacing: -0.02em;
    text-align: left;
    margin-top: 40px;
    cursor: pointer;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #000000;
    display: flex;
    align-items: center;
    > span {
        margin-right: 8px;
    }
`

export {
    MainContainer,
    MainContainerHeading,
    StyledFormContainer,
    AddRemoveCourseButton,
    SelectContainer,
    SingleContainer,
}
