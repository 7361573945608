import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

// const getAdminDetails = (admin) => {
//   if (admin) {
//     return ' admins {id username name email phone{ countryCode number } }'
//   }
//   return ''
// }

const fetchSchoolStudents = ({ schoolId, page, skip, userName }) =>
  duck.query({
    query: gql`
      query {
        studentProfiles(
          filter: {
            and: [
              {user_some:{source:school}}
              ${schoolId ? `{school_some: {id: "${schoolId}"}}` : ''}
              ${userName ? `{user_some: { name_contains: "${userName}"}}` : ''}
            ]
          },
          first: ${page},
          skip: ${(skip - 1) * (page)}
          ) {
          id
          user {
            id
            name
          }
          grade
          section
          batch {
            code
            id
          }
          school {
            name
          }
          parents {
            id
            user {
              id
              name
              phone {
                countryCode
                number
              }
              email
            }
          }
        }
        studentProfilesMeta(
          filter: {
            and: [
              {user_some:{source:school}}
              ${schoolId ? `{school_some: {id: "${schoolId}"}}` : ''}
              ${userName ? `{user_some: { name_contains: "${userName}"}}` : ''}
            ]
          }
        ) {
          count
        }
      }
    `,
    type: 'studentProfiles/fetch',
    key: 'studentProfiles',
    changeExtractedData: (extractedData, originalData) => ({
      // schoolAdmin: admin ? get(originalData, 'studentsOfSchool.admins') : [],
      studentProfiles: get(originalData, 'studentProfiles'),
      studentProfilesMeta: get(originalData, 'studentProfilesMeta')
    })
  })

export default fetchSchoolStudents
// students
