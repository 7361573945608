import styled from 'styled-components'
import {Button} from 'antd'
const ComponentHolder = styled.div`
text-align: center;
width: 100%;
height: 95%;
border-radius: 11px;
display: flex;
justify-content: center;
align-items: center;
`

const AddImageButton = styled(Button)`
&.ant-btn {
    background: #8C61CB !important;
    color: #FFFFFF !important;
    font-family: 'Inter'!important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    border-radius: 8px !important;
    padding: 7px 16px 7px 12px !important;
    display:flex;
    gap:10px;
}
`
const StyledLabel = styled.label`
    background: #8C61CB;
    color: #FFFFFF;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    border-radius: 8px;
    padding: 7px 16px 7px 12px;
    display:flex;
    gap:10px;
    cursor:pointer;
`

const MessageText = styled.div`
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 10px;
color:#8C61CB;
`
const StyledInput = styled.input`
display:none;
`
const IconHolder = styled.div`
display: flex;
justify-content: center;
align-items: center;
`
const DataHolder = styled.div`
height: 100%;
width: 100%;
`
const StyledImageTag = styled.img`
height: 90%;
width: 50%;
border-radius: 16px;
box-shadow: 1px 1px 1px 1px grey
`
const ButtonHolder = styled.div`
display: flex;
flex-direction:column;
justify-content: center;
align-items: center;
`
const ImageHolder = styled.div`
width: 100%;
height: 70%;
`
const ImageHolderUpload = styled.div`
height: 50%;
`
const UploadDiv = styled.div`

`
const ReUploadDiv = styled.div`
`
export {
    ComponentHolder,
    AddImageButton,
    IconHolder,
    DataHolder,
    StyledImageTag,
    ButtonHolder,
    ImageHolder,
    StyledLabel,
    StyledInput,
    MessageText,
    UploadDiv,
    ReUploadDiv,
    ImageHolderUpload
    }
    