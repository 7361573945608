import React from 'react'
import ModalWrapper from './RescheduleModal.style'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Input, Radio, Select, Popconfirm, notification } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import { CalendarOutlined, StopOutlined } from '@ant-design/icons'
import momentTZ from 'moment-timezone'
import PRE_EVENT_CREATION_TIME from '../../../../constants/eventCreationTime'
const { Option } = Select

const weekDays = ['M', 'T', 'W', 'Th', 'F', 'Sa', 'S']
const TimePickerFormat = 'hh a'

class RescheduleModal extends React.Component {
    constructor(props) {
        super(props)
        const { eventData } = this.props
        this.state = {
            oneDay: get(eventData, 'oneDay'),
            recurringDay: get(eventData, 'recurring'),
            selectedTimeRange: {
                startHour: get(eventData, 'selectedTimeRange.startHour') || null,
                endHour: get(eventData, 'selectedTimeRange.endHour') || null,
            },
            selectedDateRange: {
                startDate: get(eventData, 'selectedDateRange.startDate') || null,
                endDate: get(eventData, 'selectedDateRange.endDate') || null,
            },
            timeValues: {
                startTime: get(eventData, 'timeValues.startTime') || null,
                endTime: get(eventData, 'timeValues.endTime') || null,
            },
            selectedTimezone: get(eventData, 'timeZone') || null,
            rescheduleReason: get(eventData, 'rescheduledReason') || null,
            sendRescheduleComms: get(eventData, 'shouldSendRescheduledComms') === true ? 'yes'
                : 'no',

            cancellationReason: get(eventData, 'cancellationReason') || null,
            cancellationComms: get(eventData, 'shouldSendCanceledComms') === true ? 'yes'
                : 'no',

            selectedWeekDays: get(eventData, 'selectedWeekDays', []) || [],
            showOnSavePopup: false,
            rescheduleValidationErrors: [],
            cancellationValidationErrors: [],
        }
    }

    addSelectedWeekDays = (weekDay) => {
        const { selectedWeekDays } = this.state
        const newDays = [...selectedWeekDays]
        const isExist = newDays.find(day => day === weekDay)
        if (!isExist) this.setState({ selectedWeekDays: [...newDays, weekDay] })
        else {
            this.setState({
                selectedWeekDays: newDays.filter(day => day !== weekDay)
            })
        }
    }

    closeModal = () => {
        this.setState({
            oneDay: true,
            recurringDay: null,
            selectedTimeRange: {
                startHour: null,
                endHour: null
            },
            selectedDateRange: {
                startDate: null,
                endDate: null
            },
            timeValues: {
                startTime: null,
                endTime: null
            },
            selectedTimezone: '',
            rescheduleReason: '',
            sendRescheduleComms: null,
            cancellationReason: '',
            cancellationComms: null,
            selectedWeekDays: [],
            showOnSavePopup: false,
            rescheduleValidationErrors: [],
            cancellationValidationErrors: [],
        })
        this.props.closeModal()
    }

    onRescheduleValidation = () => {
        const { oneDay, recurringDay, selectedTimeRange, selectedDateRange, timeValues, selectedTimezone,
            rescheduleReason, sendRescheduleComms, selectedWeekDays } = this.state
        let errors = []
        const regexCheck = new RegExp('/^[a-z0-9]+$/i')
        const emptyCheck = new RegExp('^\\s+$')

        if (!selectedDateRange.startDate || !selectedDateRange.endDate) {
            errors.push('Please select event start and end date')
        }
        if (!selectedTimeRange.startHour || !selectedTimeRange.endHour) {
            errors.push('Please select event start and end time (set hours)')
        }
        if (selectedDateRange.startDate > selectedDateRange.endDate) {
            errors.push('Start Date cannot be greater than End Date')
        }
        // if (selectedDateRange.startDate && selectedTimeRange.startHour
        //     && moment(selectedDateRange.startDate).set(selectedTimeRange.startHour, 'hours').isBefore(moment())) {
        //     errors.push('Please choose start date greater current date')
        // }
        if (!oneDay) {
            if (!selectedWeekDays.length) {
                errors.push('Please choose atleast one day to repeat event on')
            }
        }
        if (!selectedTimezone) {
            errors.push('Please select TimeZone')
        }
        if (sendRescheduleComms === 'yes') {
            if (!rescheduleReason || rescheduleReason.length < 6) {
                errors.push('Reschedule reason should be greater than 5 characters')
            }
            if (rescheduleReason && rescheduleReason.length > 5) {
                const reasonString = rescheduleReason.slice(0, 5)
                if (regexCheck.test(reasonString) || emptyCheck.test(reasonString)) {
                    errors.push('Starting 5 characters of reason cannot be Spaces')
                }
            }
        }
        if (errors.length) {
            this.setState({
                rescheduleValidationErrors: []
            }, () => this.setState({ rescheduleValidationErrors: errors }))
        } else {
            const data = {
                oneDay,
                recurringDay,
                selectedDateRange,
                timeValues,
                selectedTimezone,
                rescheduleReason,
                sendRescheduleComms,
                selectedWeekDays
            }
            this.props.updateEvent(this.props.eventStatus, data)
            this.closeModal()
        }
        this.setState({ showOnSavePopup: false })
    }

    onCancelValidation = () => {
        const { cancellationReason, cancellationComms } = this.state
        let errors = []
        const regexCheck = new RegExp('/^[a-z0-9]+$/i')
        const emptyCheck = new RegExp('^\\s+$')
        if (cancellationComms === 'yes') {

            if (!cancellationReason || cancellationReason.length < 6) {
                errors.push('cancellation reason should be greater than 5 characters')
            }
            if (cancellationReason && cancellationReason.length > 5) {
                const reasonString = cancellationReason.slice(0, 5)
                if (regexCheck.test(reasonString) || emptyCheck.test(reasonString)) {
                    errors.push('Starting 5 characters of reason cannot be Spaces')
                }
            }
        }
        if (errors.length) {
            this.setState({
                cancellationValidationErrors: []
            }, () => this.setState({ cancellationValidationErrors: errors }))
        } else {
            const data = {
                cancellationReason, cancellationComms
            }
            this.props.updateEvent(this.props.eventStatus, data)
            this.closeModal()
        }
        this.setState({ showOnSavePopup: false })
    }

    onDisabledDates = (current) => {
        const { oneDay, recurringDay, selectedDateRange } = this.state
        if (oneDay) {
            return (
                current && current < new Date().setDate((new Date(selectedDateRange.startDate || new Date()).getDate()) - 1)
            )
        }
        if (recurringDay) {
            return (
                current && current < new Date().setDate((new Date(selectedDateRange.startDate || new Date()).getDate()))
            )
        }
    }

    handleDateTypeChanges = () => {
        const { selectedDateRange: { startDate, endDate }, oneDay, recurringDay } = this.state
        const startDateValue = new Date(startDate).getDate()
        const endDateValue = new Date(endDate).getDate()
        if (oneDay && startDate) {
            this.setState({selectedDateRange:{ ...this.state.selectedDateRange, endDate: startDate } })
        } else if (recurringDay
            && startDateValue === endDateValue) {
            this.setState({
                selectedDateRange: {
                    ...this.state.selectedDateRange,
                    endDate: moment(startDate).add(1, 'day')
                }
            })
        }
    }

    render() {
        const { oneDay, recurringDay, selectedTimeRange, selectedDateRange, timeValues, selectedTimezone,
            rescheduleReason, sendRescheduleComms, cancellationReason, cancellationComms,
            selectedWeekDays, showOnSavePopup, rescheduleValidationErrors,
            cancellationValidationErrors } = this.state
        const { visible, rescheduleEvent, cancelEvent } = this.props
        return (
            <ModalWrapper
                visible={visible}
                onCancel={this.closeModal}
                width='700px'
                maskClosable={false}
                style={{ display: 'flex', flexDirection: 'column' }}
                footer={[
                    <ModalWrapper.styledButton onClick={this.closeModal} color='#fff' background='#a3a3a3'>Cancel</ModalWrapper.styledButton>,
                    <Popconfirm
                        title={(
                            <div style={{ alignItems: 'center' }}>
                                <h4 style={{ fontWeight: 'bold' }}>Are you Sure ?</h4>
                                <p>{`Do you really want to ${rescheduleEvent ? 'Reschedule' : 'Cancel'} this Event ?`}</p>
                                <p>This will change the listing details as well.</p>
                            </div>
                        )}
                        icon=""
                        visible={showOnSavePopup}
                        onConfirm={() => rescheduleEvent ? this.onRescheduleValidation() : this.onCancelValidation()}
                        onCancel={() => this.setState({ showOnSavePopup: false })}
                        placement='bottom'
                        okText='Confirm'
                        okType='danger'
                        cancelText='Cancel'
                        key='toggle'
                    >
                        <ModalWrapper.styledButton
                            background='#40a9ff'
                            color='#fff'
                            onClick={() => {
                                this.setState({
                                    showOnSavePopup: true
                                })
                            }}
                        >
                            {rescheduleEvent ? 'Reschedule' : 'Cancel Event'}
                        </ModalWrapper.styledButton>
                    </Popconfirm>

                ]}
            >
                {rescheduleEvent && (
                    <>
                        <ModalWrapper.HeadingContainer>
                            <h1>
                                <CalendarOutlined className='heading-icon' />
                            </h1>
                            <ModalWrapper.Content style={{ marginLeft: '15px' }}>
                                <h1>Rescheduled Date & Time</h1>
                                <p>Update event-goers when's the party on</p>
                            </ModalWrapper.Content>
                        </ModalWrapper.HeadingContainer>
                        <ModalWrapper.Content style={{ marginLeft: '40px' }}>
                            <ModalWrapper.Content>
                                <ModalWrapper.styledButton
                                    isSelected={oneDay}
                                    style={{ marginRight: '10px' }}
                                    onClick={() => {
                                        this.setState({ recurringDay: false, oneDay: true }, this.handleDateTypeChanges)
                                    }}
                                >
                                    One-Day
                                </ModalWrapper.styledButton>
                                <ModalWrapper.styledButton
                                    isSelected={recurringDay}
                                    onClick={() => {
                                        this.setState({
                                            recurringDay: true,
                                            oneDay: false
                                        }, this.handleDateTypeChanges)
                                    }}
                                >
                                    Recurring Event
                                </ModalWrapper.styledButton>
                            </ModalWrapper.Content>
                            <ModalWrapper.Content>
                                <ModalWrapper.DatePickerButton
                                    onChange={(value) => {
                                        let dateRange = {}
                                        if (oneDay) {
                                            dateRange = { ...selectedDateRange, startDate: value, endDate: value }
                                        } else {
                                            dateRange = { startDate: value, endDate: moment(value).add(1, 'day') }
                                        }
                                        this.setState({ selectedDateRange: dateRange })
                                    }}
                                    placeholder='Start Date'
                                    allowClear={false}
                                    value={selectedDateRange.startDate}
                                    disabledDate={(current) => current &&
                                        current < new Date().setDate((new Date().getDate()) - 1)
                                    }
                                    format='DD MMMM YYYY'
                                />
                                <ModalWrapper.TimePickerButton
                                    format={TimePickerFormat}
                                    allowClear={false}
                                    value={timeValues.startTime}
                                    disabled={!selectedDateRange.startDate}
                                    placeholder='Start Time'
                                    disabledHours={() => {
                                        if (new Date().setHours(0, 0, 0, 0)
                                            === new Date(selectedDateRange.startDate).setHours(0, 0, 0, 0)) {
                                            return [...Array(new Date().getHours() + 1 + PRE_EVENT_CREATION_TIME).keys()].slice(1)
                                        }
                                    }}
                                    onChange={(value) => {
                                        if (value && get(value, '_d')) {
                                            const selectedRange = {
                                                ...selectedTimeRange,
                                                startHour: get(value, '_d').getHours(),
                                                endHour: get(value, '_d').getHours() + 1
                                            }
                                            if (get(value, '_d').getHours() === 23) {
                                                this.setState({
                                                    selectedTimeRange: selectedRange,
                                                    timeValues: {
                                                        startTime: value,
                                                        endTime: moment(value).add(1, 'hour')
                                                    }
                                                })
                                            } else {
                                                this.setState({
                                                    selectedTimeRange: selectedRange,
                                                    timeValues: {
                                                        startTime: value,
                                                        endTime: moment(value).add(1, 'hour')
                                                    }
                                                })
                                            }
                                        }
                                    }}
                                />
                            </ModalWrapper.Content>
                            <ModalWrapper.Content>
                                <ModalWrapper.DatePickerButton
                                    onChange={(value) => {
                                        const dateRange = { ...selectedDateRange, endDate: value }
                                        this.setState({ selectedDateRange: dateRange })
                                    }}
                                    placeholder='End Date'
                                    allowClear={false}
                                    value={selectedDateRange.endDate}
                                    disabled={oneDay}
                                    disabledDate={(current) => this.onDisabledDates(current)}
                                    format='DD MMMM YYYY'
                                />
                                <ModalWrapper.TimePickerButton
                                    format={TimePickerFormat}
                                    allowClear={false}
                                    value={timeValues.endTime}
                                    placeholder='End Time'
                                    disabled={true}
                                    disabledHours={() =>
                                        [...Array(selectedTimeRange.startHour + 1).keys()].slice(1)
                                    }
                                    onChange={(value) => {
                                        const endTime = value
                                        if (value && value._d) {
                                            const selectedRange = {
                                                ...selectedTimeRange,
                                                endHour: get(value, '_d').getHours(),
                                                startHour: get(value, '_d').getHours() - 1
                                            }
                                            value = get(value, '_d').getHours() === 0 ? 24 : get(value, '_d').getHours()
                                            if (value > selectedTimeRange.startHour
                                                && (value - selectedTimeRange.startHour) === 1) {
                                                this.setState({
                                                    selectedTimeRange: selectedRange,
                                                    timeValues: {
                                                        startTime: moment(value).subtract(1, 'hour'),
                                                        endTime
                                                    }
                                                })
                                            }
                                        }
                                    }}
                                />
                            </ModalWrapper.Content>
                            {recurringDay && (
                                <ModalWrapper.Content>
                                    <ModalWrapper.Content>
                                        <h4>Repeat on</h4>
                                        {weekDays.map(weekDay => (
                                            <ModalWrapper.tagButton
                                                type={selectedWeekDays.includes(weekDay) ? 'primary' : ''}
                                                onClick={() => this.addSelectedWeekDays(weekDay)}
                                                shape='circle'
                                            >
                                                {weekDay}
                                            </ModalWrapper.tagButton>
                                        ))}
                                    </ModalWrapper.Content>
                                </ModalWrapper.Content>
                            )}
                            <ModalWrapper.Content>
                                <Select
                                    required
                                    style={{ width: 180 }}
                                    placeholder='Select Timezone'
                                    onChange={(value) => this.setState({ selectedTimezone: value })}
                                    value={selectedTimezone || undefined}
                                    showSearch
                                >
                                    {momentTZ.tz.names().map(timezone =>
                                        <Option key={timezone}
                                            value={timezone}
                                        >{timezone}
                                        </Option>
                                    )}
                                </Select>
                            </ModalWrapper.Content>
                            <ModalWrapper.TitleInputContainer style={{ width: '100%' }}>
                                <h4>Reschedule Reason</h4>
                                <Input.TextArea
                                    required
                                    placeholder='Tell your event goers why the event was rescheduled'
                                    rows={2}
                                    bordered={false}
                                    value={rescheduleReason}
                                    onChange={e => {
                                        this.setState({ rescheduleReason: e.target.value })
                                    }}
                                />
                            </ModalWrapper.TitleInputContainer>
                            <ModalWrapper.Content style={{ display: 'flex' }}>
                                <Radio.Group
                                    required
                                    value={sendRescheduleComms}
                                    onChange={e => {
                                        this.setState({ sendRescheduleComms: e.target.value })
                                    }}
                                >
                                    <Radio value='no'>No</Radio>
                                    <Radio value='yes'>Yes</Radio>
                                </Radio.Group>
                                <h4>Send Reschedule Comms?</h4>
                            </ModalWrapper.Content>
                            <ModalWrapper.Content>
                                {rescheduleValidationErrors && rescheduleValidationErrors.length > 0
                                    && (
                                        <ModalWrapper.Content>
                                            <div style={{ color: 'red', fontWeight: '700', margin: '0' }}>*{rescheduleValidationErrors[0]}</div>
                                        </ModalWrapper.Content>
                                    )}
                            </ModalWrapper.Content>
                        </ModalWrapper.Content>
                    </>
                )}
                {cancelEvent && (
                    <>
                        <ModalWrapper.HeadingContainer>
                            <h1>
                                <StopOutlined className='heading-icon' />
                            </h1>
                            <ModalWrapper.Content style={{ marginLeft: '15px' }}>
                                <h1>Cancel Event</h1>
                                <p>cancel the event</p>
                            </ModalWrapper.Content>
                        </ModalWrapper.HeadingContainer>
                        <ModalWrapper.Content style={{ marginLeft: '40px' }}>
                            <ModalWrapper.TitleInputContainer style={{ width: '100%' }}>
                                <h4>Cancellation Reason</h4>
                                <Input.TextArea
                                    placeholder='Tell your event goers why the event was rescheduled'
                                    rows={2}
                                    bordered={false}
                                    value={cancellationReason}
                                    onChange={e => {
                                        this.setState({ cancellationReason: e.target.value })
                                    }}
                                />
                            </ModalWrapper.TitleInputContainer>
                            <ModalWrapper.Content style={{ display: 'flex', alignItems: 'center' }}>
                                <Radio.Group
                                    value={cancellationComms}
                                    onChange={e => {
                                        this.setState({ cancellationComms: e.target.value })
                                    }}
                                >
                                    <Radio value='no'>No</Radio>
                                    <Radio value='yes'>Yes</Radio>
                                </Radio.Group>
                                <h3>Send Cancellation Comms?</h3>
                            </ModalWrapper.Content>
                            {cancellationValidationErrors && cancellationValidationErrors.length > 0
                                && (
                                    <ModalWrapper.Content>
                                        <div style={{ color: 'red', fontSize: '0.8rem', fontWeight: '500', margin: '0' }}>*{cancellationValidationErrors[0]}</div>
                                    </ModalWrapper.Content>
                                )}
                        </ModalWrapper.Content>
                    </>
                )}
            </ModalWrapper>
        )
    }
}

export default RescheduleModal