import { Button, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { fetchEbooks } from './fetchEbooks'
import withNav from '../../components/withNav'
import { AddEbook } from './AddEbookModal'
import { useHistory } from 'react-router-dom'
import { fetchCoursePackages } from './fetchCoursePackages'
import { deleteEbook } from './deleteEbook'

export const EbooksWithNav = (props) => {
  const [ebooks, setEbooks] = useState(props.eBooks.toJS())
  const [coursePackages, setCoursePackages] = useState([])
  const [ebook, setEbook] = useState()
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const history = useHistory()
  const {eBooks} = props
  const ebookId = new URLSearchParams(history.location.search).get('ebookId')
  useEffect(() => {
    getCoursePackages()
    getEbooks()
  }, [])
  useEffect(() => {
    setEbooks(eBooks.toJS())
  }, [eBooks])
  async function getEbooks() {
    setLoading(true)
    const {data} = await fetchEbooks()
    setLoading(false)
  }
  
  const updateEbook = (data) => {
    duck.merge(() => ({
      eBooks: {
        ...eBooks.toJS(),
        ...data,
      }
    }))
  }
  async function getCoursePackages() {
    const {data} = await fetchCoursePackages()
    setCoursePackages(data.coursePackages)
  }
//  get columns from ebooks
    let columns = []  
    let data = []
    if(ebooks.length > 0){
      columns = [
        {
          title: "Order",
          render: (text, record, index) => index + 1,          
        },
        {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',

        },
        {
          title: 'Internal Name',
          dataIndex: 'internalName',
          key: 'internalName',

        },
        {
          title: 'Link',
          dataIndex: 'resourceURL',
          key: 'resourceURL',
        },
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          render: (text, record) => (
            <span>
              <Button
                type="default"
                onClick={() => {
                  const ebookId = record.id
                  history.push({
                    search: `?ebookId=${ebookId}`
                  })
                  setEbook(record)
                  setShowModal('edit')
                }}
              >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M20 14.66V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H4C3.46957 22 2.96086 21.7893 2.58579 21.4142C2.21071 21.0391 2 20.5304 2 20V6C2 5.46957 2.21071 4.96086 2.58579 4.58579C2.96086 4.21071 3.46957 4 4 4H9.34" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M18 2L22 6L12 16H8V12L18 2Z" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg></Button>
            </span> 
          ),
        },
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          render: (text, record) => (
            <span>
              <Button
                type="default"
                onClick={async() => {
                  const ebookId = record.id
                  setLoading(true)
                  const filteredEbooks = ebooks.filter(ebook => ebook.id !== ebookId)
                  await deleteEbook(ebookId)
                  setEbooks(filteredEbooks)
                  setLoading(false)
                }}
              >
             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.67247 6.0028C4.08446 5.95995 4.45318 6.2592 4.49604 6.67119L5.8398 19.5906C5.86211 19.7715 5.94952 19.9381 6.08577 20.0592C6.22307 20.1813 6.40041 20.2488 6.58414 20.2488H17.4165C17.6002 20.2488 17.7775 20.1813 17.9148 20.0592C18.0511 19.9381 18.1385 19.7715 18.1608 19.5906L19.5041 6.67122C19.5469 6.25922 19.9156 5.95996 20.3276 6.0028C20.7396 6.04564 21.0389 6.41435 20.996 6.82634L19.6521 19.7517C19.6518 19.755 19.6514 19.7584 19.651 19.7617C19.5866 20.3092 19.3235 20.8139 18.9116 21.1802C18.4997 21.5464 17.9677 21.7488 17.4165 21.7488H6.58411C6.03291 21.7488 5.5009 21.5464 5.08901 21.1802C4.67711 20.8139 4.414 20.3092 4.34957 19.7617C4.34927 19.7591 4.34898 19.7565 4.3487 19.7539L4.34846 19.7517L3.00408 6.82637C2.96123 6.41438 3.26048 6.04565 3.67247 6.0028Z" fill="#333333"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.75 3.75C0.75 2.92157 1.42157 2.25 2.25 2.25H21.75C22.5784 2.25 23.25 2.92157 23.25 3.75V6C23.25 6.82843 22.5784 7.5 21.75 7.5H2.25C1.42157 7.5 0.75 6.82843 0.75 6V3.75ZM21.75 3.75H2.25V6H21.75V3.75Z" fill="#333333"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1553 10.7197C15.4482 11.0126 15.4482 11.4874 15.1553 11.7803L9.90533 17.0303C9.61244 17.3232 9.13756 17.3232 8.84467 17.0303C8.55178 16.7374 8.55178 16.2626 8.84467 15.9697L14.0947 10.7197C14.3876 10.4268 14.8624 10.4268 15.1553 10.7197Z" fill="#333333"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84467 10.7197C9.13756 10.4268 9.61244 10.4268 9.90533 10.7197L15.1553 15.9697C15.4482 16.2626 15.4482 16.7374 15.1553 17.0303C14.8624 17.3232 14.3876 17.3232 14.0947 17.0303L8.84467 11.7803C8.55178 11.4874 8.55178 11.0126 8.84467 10.7197Z" fill="#333333"/>
              </svg>
          </Button>
            </span> 
          ),
        }
        
        
      ]
    
      data = ebooks.map((ebook) => {
        return {
          ...ebook,
          key: ebook.id,
        }
      })
    }


  return (
    <>
    <Button 
      style={{
        marginBottom: '20px',
        marginLeft: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

      }}
      type="primary" 
      onClick={() => {
        setShowModal('add')
        setEbook({})
      }}>
        Add E-Book
    </Button>
    {
      ebooks.length > 0 && (
        <>
          <Table 
            columns={columns} 
            dataSource={data} 
            loading={loading}
          />
        </>
      )
    }
    {
      showModal && (
        <>
          <AddEbook
            updateEbook={updateEbook}
            onClose={
              () => {
                setShowModal(false)
                getEbooks()
            }
            }
            coursePackages={coursePackages}
            ebook={ebook}
            showModal={showModal}
            ebookId={ebookId}
          />
        </>
      )
    }
    </>
  )
}
export const Ebooks = withNav(EbooksWithNav)({
  title: 'ebooks',
  activeNavItem: 'ebooks',
  showCMSNavigation: true,
})

