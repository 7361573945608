import gql from 'graphql-tag'
import duck from '../../duck'

const fetchLastBatchCode = async (type, schoolId) => (
  duck.query({
    query: gql`
  query{ 
    batches(filter:{and:[
    {type:${type}},
    { documentType_not: classroom },
    ${schoolId ? `{school_some:{id: "${schoolId}"}}` : ''}
    ]},first:1, orderBy:createdAt_DESC){
      code
    }
  }
  `,
    type: 'batches/fetch',
    key: 'batches',
  })
)

export default fetchLastBatchCode
