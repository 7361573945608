const PYTHON = 'Python'
const JAVASCRIPT = 'JavaScript'
const JAVA = 'Java'
const CSHARP = 'Csharp'
const SWIFT = 'Swift'
const CPLUSPLUS = 'Cplusplus'
const BLOCKLY = 'Blockly'
const CODEORG = 'Code.org'
const CODEDOTORG = 'CodeOrg'

const CODING_LANGUAGES = [
  {
    key: PYTHON,
    label: PYTHON
  },
  {
    key: JAVASCRIPT,
    label: JAVASCRIPT
  },
  {
    key: JAVA,
    label: JAVA
  },
  {
    key: CSHARP,
    label: CSHARP
  },
  {
    key: SWIFT,
    label: SWIFT
  },
  {
    key: CPLUSPLUS,
    label: CPLUSPLUS
  },
  {
    key: BLOCKLY,
    label: BLOCKLY
  },
  {
    key: CODEDOTORG,
    label: CODEORG
  },
]

export default CODING_LANGUAGES
