import gql from 'graphql-tag'
import duck from '../../duck'

const updateBatch = async (input, id, allotedMentorId, currentComponentId, topicId, courseConnectId = '', componentInput, coursePackageConnectId = '', schoolConnectId) => {
  const variables =  {
    input,
    callBatchAPI: true
  }
  if (componentInput && Object.keys(componentInput).length > 0) {
    variables.componentInput = componentInput
  }
  return (
    duck.query({
      query: gql`
      mutation(
        $input: BatchUpdate,
        ${currentComponentId ? `$componentInput: BatchCurrentComponentStatusUpdate` : ''}
      ) {
      updateBatch(input: $input, id: "${id}", ${allotedMentorId ? `allottedMentorConnectId: "${allotedMentorId}"` : ''},
        ${courseConnectId ? `courseConnectId: "${courseConnectId}"` : ''}
        ${coursePackageConnectId ? `coursePackageConnectId: "${coursePackageConnectId}"` : ''}
        ${schoolConnectId ? `schoolConnectId: "${schoolConnectId}"` : ''}
    ) {
      id
      course {
        createdAt
        updatedAt
      }
      code
      type
      description
      isTeacherTraining
      coursePackage {
        id
        title
      }
      studentsMeta {
        count
      }
      allottedMentor {
        name
      }
      currentComponent {
        currentTopic {
          title
          order
        }
      }
    }
    ${currentComponentId ? `
      updateBatchCurrentComponentStatus(input: $componentInput, id: "${currentComponentId}",
        ${topicId ? `currentTopicConnectId: "${topicId}"` : ''},
        ${courseConnectId ? `currentCourseConnectId: "${courseConnectId}"` : ''}
        ) {
        id
        currentTopic {
          id
          title
          order
        }
      }
    ` : ''}
  }
      `,
      variables,
      type: 'batch/update',
      key: 'batches',
    })
  )
}

export default updateBatch
