/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react'
export default function PyodideInterpreter({
  pythonCode,
  loadingMessage = 'loading…',
  evaluatingMessage = 'evaluating…',
  clearInterpretor = () => { },
  clearLoading = () => { },
}) {
  const indexURL = 'https://tekie-backend.s3.amazonaws.com/pyodideBundle/v18.1/'
  const pyodide = useRef(null)
  let codeOutput = ''
  const [isPyodideLoading, setIsPyodideLoading] = useState(true)
  const [pyodideOutput, setPyodideOutput] =
    useState(evaluatingMessage)
  // load pyodide wasm module and initialize it
  useEffect(() => {
    (async function () {
      try {
        pyodide.current = await globalThis.loadPyodide({ indexURL })
        const namespace = pyodide.current.globals.get('dict')()
        pyodide.current.runPython(`
        import sys
        import io
        sys.stdout = io.StringIO()
        `, namespace)
        namespace.destroy()
        setIsPyodideLoading(false)
        clearLoading()
        if (codeOutput) {
          codeOutput = ''
          clearInterpretor()
        }
      } catch (e) {
        console.warn({ e })
        if (e && e.message && e.message.includes('already loading')) {
          if (window && window.pyodide) {
            pyodide.current = window.pyodide
          } else if (window && window.__pyodide_module) {
            pyodide.current = window.__pyodide_module
          }
          setIsPyodideLoading(false)
          clearLoading()
        }
      }
    }())
  }, [pyodide, isPyodideLoading])
  // evaluate python code with pyodide and set output
  useEffect(() => {
    if (!isPyodideLoading) {
      const evaluatePython = async (pyodide, pythonCode) => {
        try {
          await pyodide.runPythonAsync(pythonCode)
          const output = pyodide.runPython(`
            import sys
            sys.stdout.getvalue()
          `) || ''
          // outputRef.clear()
          codeOutput = ''
          clearInterpretor()
          if (output.indexOf('\n') === -1) {
            // outputRef.write(output);
            codeOutput += output
          } else {
            output.split('\n').forEach((outputText, i) => {
              // outputRef.write(outputText);
              codeOutput += outputText
              if (i !== output.split('\n').length - 1) {
                // outputRef.write('\r\n');
                codeOutput += '\r\n<end>'
              }
            })
          }
          const finalOutput = codeOutput.split('<end>')
          console.log({ codeOutput, finalOutput })
          return finalOutput[finalOutput.length - 2]
        } catch (error) {
          const errorMessage = error.message || 'Some Error Occurred'
          const blacklist = [
            'asyncio',
            'raise self._exception',
            'result = coro.send(None)',
            'exec_code',
            'eval_code_async',
            'CodeRunner',
            'run_async',
            'coroutine',
            'ast.parse',
            'return compile',
            'File "/lib/python3.8/ast.py"',
          ]
          if (errorMessage.indexOf('\n') === -1) {
            // outputRef.write(errorMessage);
            codeOutput = errorMessage
          } else {
            errorMessage.split('\n').forEach((outputText, i) => {
              for (const blockedText of blacklist) {
                if (outputText.includes(blockedText)) return
              }
              // outputRef.write(`\x1b[38;5;203m${outputText}\x1b[0m`);
              codeOutput += outputText
              if (i !== errorMessage.split('\n').length - 1) {
                // outputRef.write(`\r\n`);
                codeOutput += '\r\n'
              }
            })
          }
          return codeOutput
        }
      }
      (async function () {
        setPyodideOutput(await evaluatePython(pyodide.current,
          pythonCode))
      }())
    }
  }, [isPyodideLoading, pyodide, pythonCode])
  // return [isPyodideLoading, pyodideOutput];
  return <>
    {isPyodideLoading ? loadingMessage : <>
      {pyodideOutput || ''}
    </>}
  </>
}
