/* eslint-disable max-len */
import React from 'react'
import { get } from 'lodash'
import fetchMentorDashboardCourses from '../../../actions/mentorDashboardCourses/fetchMentorDashboardCourses'
import {
  CourseClassesSvg,
  CourseChaptersSvg
} from '../../../constants/icons'
import CoursesStyle from './Courses.style'

class Courses extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      courses: [],
      isFetching: true,
      fetchError: ''
    }
  }
  componentDidMount() {
    fetchMentorDashboardCourses()
      .then(res => {
        this.setState({
          courses: get(res, 'courses'),
          isFetching: false
        })
      })
      .catch(() => {
        this.setState({
          fetchError: 'something went wrong, please reload the page',
          isFetching: false
        })
      })
  }
  handleCourseClick = (uri) => {
    const id = uri.split('-').pop()
    this.props.history.push(`/mentorDashboard/course/${id}`)
  }
  render() {
    return (
      <>
        <CoursesStyle.PageTitleContainer>
          <CoursesStyle.PageTitle>All Courses</CoursesStyle.PageTitle>
        </CoursesStyle.PageTitleContainer>
        {this.state.isFetching ? (
          <div className='loading-container show'>
            <div className='loading-bar-container'>
              <div />
            </div>
          </div>
        ) : null}
        {this.state.fetchError !== '' && (
          <CoursesStyle.FetchError>{this.state.fetchError}</CoursesStyle.FetchError>
        )}
        <CoursesStyle.FlexContainer>
        {this.state.courses && this.state.courses.map(course => (
          <CoursesStyle.CourseContainer onClick={() => this.handleCourseClick(get(course, 'documentationUrl'))}>
            <CoursesStyle.CourseThumbnail bgImage={get(course, 'thumbnail.uri')} />
            <CoursesStyle.CourseDataContainer>
              {
                get(course, 'secondaryCategory') && (
                  <CoursesStyle.CourseTypeText>{get(course, 'secondaryCategory')}</CoursesStyle.CourseTypeText>
                )
              }
              <CoursesStyle.CourseTitle>{get(course, 'title')}</CoursesStyle.CourseTitle>
              {
                get(course, 'minGrade') && get(course, 'maxGrade') && (
                  <CoursesStyle.CourseClassText>Class {get(course, 'minGrade')} - {get(course, 'maxGrade')}</CoursesStyle.CourseClassText>
                )
              }
              <CoursesStyle.CourseDescription>{get(course, 'description')}</CoursesStyle.CourseDescription>
              <CoursesStyle.CourseDurationContainer>
                {
                  get(course, 'chaptersMeta.count') && (
                  <CoursesStyle.CourseChapterClass>
                    <CoursesStyle.CourseChapterClassName>
                      <CourseChaptersSvg className='svg' />
                      <p className='name'>Chapters</p>
                    </CoursesStyle.CourseChapterClassName>
                    <CoursesStyle.CourseChapterClassStats>{get(course, 'chaptersMeta.count')}</CoursesStyle.CourseChapterClassStats>
                  </CoursesStyle.CourseChapterClass>
                  )
                }
                {
                  get(course, 'topicsMeta.count') && (
                  <CoursesStyle.CourseChapterClass>
                    <CoursesStyle.CourseChapterClassName>
                      <CourseClassesSvg className='svg' />
                      <p className='name'>Classes</p>
                    </CoursesStyle.CourseChapterClassName>
                    <CoursesStyle.CourseChapterClassStats>{get(course, 'topicsMeta.count')}</CoursesStyle.CourseChapterClassStats>
                  </CoursesStyle.CourseChapterClass>
                  )
                }
              </CoursesStyle.CourseDurationContainer>
            </CoursesStyle.CourseDataContainer>
          </CoursesStyle.CourseContainer>
        ))}
        </CoursesStyle.FlexContainer>
      </>
    )
  }
}

export default Courses
