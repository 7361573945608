import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import SenseiMentor from './SenseiMentor'
import withNav from '../../components/withNav'
import injectProps from '../../components/injectProps'
import { filterKey } from '../../utils/data-utils'

const SenseiMentorNav = withNav(SenseiMentor)({
  title: 'Sensei - Mentor',
  activeNavItem: 'Sensei Mentor',
  showUMSNavigation: true,
})
// type.status.key.loadingState
// filterKey(state.data.getIn(['schools', 'data']), 'school')
const mapStateToProps = state => ({
  fetchedMentors: filterKey(state.data.getIn(['senseiProfiles', 'data']), 'senseiProfiles'),
  // fetchedMentors: state.data.getIn([
  //   'senseiProfiles',
  //   'data'
  // ]),
  hasSenseiMentorFetched: state.data.getIn([
    'senseiProfiles',
    'fetchStatus',
    'senseiProfiles',
    'success'
  ]),
  isFetchingSenseiMentor: state.data.getIn([
    'senseiProfiles',
    'fetchStatus',
    'senseiProfiles',
    'loading'
  ]),
  isDeletingMentor: state.data.getIn([
    'mentors',
    'deleteStatus',
    'deleteMentor',
    'loading'
  ]),
  hasDeletedMentor: state.data.getIn([
    'mentors',
    'deleteStatus',
    'deleteMentor',
    'success'
  ]),
  isMentorStatusUpdating: state.data.getIn([
    'userProfile',
    'updateStatus',
    'mentorProfileUpdate',
    'loading'
  ]),
  hasMentorStatusUpdated: state.data.getIn([
    'userProfile',
    'updateStatus',
    'mentorProfileUpdate',
    'success'
  ]),
  isMentorGroupStatusUpdating: state.data.getIn([
    'mentorProfiles',
    'updateStatus',
    'mentorProfiles',
    'loading'
  ]),
  hasMentorGroupStatusUpdated: state.data.getIn([
    'mentorProfiles',
    'updateStatus',
    'mentorProfiles',
    'success'
  ]),
  fetchedSenseiCount: state.data.getIn([
    'senseiProfilesMeta',
    'data',
  ]),
  isFetchingSenseiCount: state.data.getIn([
    'senseiMeta',
    'fetchStatus',
    'senseiMeta',
    'loading'
  ]),
  hasFetchedSenseiCount: state.data.getIn([
    'senseiMeta',
    'fetchStatus',
    'senseiMeta',
    'success'
  ]),
  fetchedAllMentors: filterKey(state.data.getIn([
    'user',
    'data'
  ]), 'senseiProfiles'),
  isFetchingAllMentors: state.data.getIn([
    'users',
    'fetchStatus',
    'senseiProfiles',
    'loading'
  ]),
  hasFetchedAllMentors: state.data.getIn([
    'users',
    'fetchStatus',
    'senseiProfiles',
    'success'
  ]),
  isAddingMentor: state.data.getIn([
    'mentors',
    'updateStatus',
    'addMentor',
    'loading'
  ]),
  hasAddedMentor: state.data.getIn([
    'mentors',
    'updateStatus',
    'addMentor',
    'success'
  ]),
  isAddingSenseiTeam: state.data.getIn([
    'senseiTeams',
    'addStatus',
    'senseiTeams',
    'loading'
  ]),
  hasAddedSenseiTeam: state.data.getIn([
    'senseiTeams',
    'addStatus',
    'senseiTeams',
    'success'
  ]),
  isUpdatingSenseiTeam: state.data.getIn([
    'senseiTeams',
    'updateStatus',
    'senseiTeams',
    'loading'
  ]),
  hasUpdatedSenseiTeam: state.data.getIn([
    'senseiTeams',
    'updateStatus',
    'senseiTeams',
    'success'
  ]),
  isUpdatingMentor: state.data.getIn(['mentors', 'updateStatus', 'updateMentor', 'loading']),
  hasUpdatedMentor: state.data.getIn(['mentors', 'updateStatus', 'updateMentor', 'success']),
})

const SenseiMentorNavWithExtraProps = injectProps({
  notification
})(SenseiMentorNav)

export default connect(mapStateToProps)(withRouter(SenseiMentorNavWithExtraProps))
