/* eslint-disable indent */
/* eslint-disable max-len */
import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'
import MainModal from '../../../../components/MainModal'
import ManageTrainerStyle from '../ManageTrainers.style'

class ViewAssignedBatchesModal extends React.Component {

    renderTable = (batches) => {
        const columns = [
            {
                title: 'Sr. No',
                dataIndex: 'srNo',
                key: 'srNo',
                align: 'center',
                width: 100
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                align: 'center',
            },
            {
                title: 'Code',
                dataIndex:'code',
                key: 'code',
                align: 'center'
            },
            {
                title: 'Students',
                dataIndex: 'students',
                key: 'students',
                align: 'center',
            },
        ]
        return (
            <ManageTrainerStyle.MDTable
                dataSource={batches}
                columns={columns}
                // rowClassName={() => 'antdTable-row'}
                // loading={
                //     manageTrainersFetchStatus && get(manageTrainersFetchStatus.toJS(), 'loading')
                // }
                // scroll={{ x: 'max-content' }}
                pagination={false}
            />
        )
    }
    renderBatchSchoolMapping = () => {
        const { teacherBatchMap } = this.props
        const mapBatchSchool = Object.keys(teacherBatchMap)
        if (mapBatchSchool.length) {
            return (
                <ManageTrainerStyle.FlexContainer direction='column' style={{ height: '600px', overflow: 'auto' }}>
                    {mapBatchSchool.map((key, index) => {
                        const batches = teacherBatchMap[key]
                        return (
                            <ManageTrainerStyle.FlexContainer direction='column'>
                                <h3 style={{ whiteSpace: 'nowrap', marginTop: '15px', marginBottom: '0' }}>{key}</h3>
                                <ManageTrainerStyle.FlexContainer>
                                    {this.renderTable(batches)}
                                </ManageTrainerStyle.FlexContainer>
                            </ManageTrainerStyle.FlexContainer>
                        )
                    })}
                </ManageTrainerStyle.FlexContainer>
            )
        } else {
            return 'No data found'
        }
    }
  
    render() {
        const { openAssignedBatchesModal, closeAssignedBatchesModal, isLoading } = this.props
        return (
        <MainModal
            visible={openAssignedBatchesModal}
            title={'Assigned Batches'}
            onCancel={closeAssignedBatchesModal}
            maskClosable={false}
            width='650px'
            centered
            destroyOnClose
            footer={null}
        >
            <ManageTrainerStyle.FlexContainer justify='center' align='center'>
                {isLoading ? (
                    <LoadingOutlined />
                ) : (
                    this.renderBatchSchoolMapping()
                )}
            </ManageTrainerStyle.FlexContainer>
        </MainModal>
        )
    }
}

export default ViewAssignedBatchesModal
