import { CalendarOutlined, LinkOutlined, EditFilled, CopyFilled } from '@ant-design/icons'
import { get, sortBy } from 'lodash'
import React from 'react'
import { Pagination, Modal, Button, Input, Popconfirm, notification } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'
import { deleteTrainingBatch, fetchCampaignBatches, fetchTrainingCoursePackages } from '../../../../actions/SchoolOnboarding'
import {
  BatchName,
  FlexContainer, MDTable, SectionButton,
  StudentModalBox, StyledButton, UserIcon
} from '../../SchoolOnBoarding.style'
import getSlotLabel from '../../../../utils/slots/slot-label'
import campaignTypes from '../../../../constants/campaignType'
import copyToClipboard from '../../../../utils/copyToClipboard';
import updateCampaignBatches from '../../../../actions/SchoolOnboarding/updateCampaignBatches'
import fetchTrainingBatches from '../../../../actions/SchoolOnboarding/fetchTrainingBatches'
import MainTable from '../../../../components/MainTable'
import TrainingBatchModal from './TrainingBatchModal'
import fetchTrainers from '../../../../actions/manageTrainers/fetchTrainers'

const { b2b2cEvent, b2b } = campaignTypes

class BatchesTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: [],
      batchData: [],
      currentPage: 1,
      perPage: 20,
      skip: 0,
      sessionLinkModal: false,
      customSessionLink: "",
      IDwithcustomSessionLink: "",
      batchCodeforModal: "",
      filter: 'all',
      openModal: false,
      operation: '',
      editData: {},
      schoolTrainers: [],
      coursePackages: []
    }
  }
  fetchBatchesData = async () => {
    const { perPage, skip, filter } = this.state
    const { chosenCampaign, schoolId, isTeacherTrainingBatches } = this.props
    if (isTeacherTrainingBatches) {
      await fetchTrainingBatches({
        schoolId,
        perPage,
        skip
      })
    } else await fetchCampaignBatches({
      campaignId: chosenCampaign,
      schoolId: schoolId,
      filter: filter,
      perPage,
      skip
    })
  }
  componentDidMount = async () => {
    const { chosenCampaign, campaigns, schoolId } = this.props
    if (chosenCampaign && schoolId) {
      this.fetchBatchesData()
    }
  }
  componentDidUpdate = async (prevProps, prevState) => {
    const { chosenCampaign, campaignBatchesFetching, schoolId,
      campaignBatchesUpdate, selectCampaign, trainingBatchesFetching,
      isTeacherTrainingBatches, manageTrainersFetchStatus,
      trainingCoursePackagesFetchStatus, trainingBatchesAdd,
      trainingBatchesUpdate, trainingBatchesDelete,
      trainingBatchesAddFailure, trainingBatchesUpdateFailure,
      trainingBatchesDeleteFailure
    } = this.props
    if (prevProps.schoolId !== schoolId && schoolId) {
      selectCampaign('all')
      this.setState({
        currentPage: 1,
        perPage: 20,
        skip: 0,
        batchData: [],
        filter: 'all'
      }, () => this.fetchBatchesData())
    }
    if (prevProps.chosenCampaign !== chosenCampaign && chosenCampaign) {
      this.setState({
        currentPage: 1,
        perPage: 20,
        skip: 0,
        batchData: []
      }, () => this.fetchBatchesData())
    }
    if (!isTeacherTrainingBatches && (campaignBatchesUpdate && !get(campaignBatchesUpdate.toJS(), 'loading')
      && get(campaignBatchesUpdate.toJS(), 'success') &&
      (prevProps.campaignBatchesUpdate !== campaignBatchesUpdate))) {
        this.setState({ sessionLinkModal: false})
        this.convertDataToTable();
    }
    if (!isTeacherTrainingBatches && (campaignBatchesFetching && !get(campaignBatchesFetching.toJS(), 'loading')
      && get(campaignBatchesFetching.toJS(), 'success') &&
      (prevProps.campaignBatchesFetching !== campaignBatchesFetching))) {
      this.convertDataToTable()
    }
    if (isTeacherTrainingBatches && ((trainingBatchesFetching && !get(trainingBatchesFetching.toJS(), 'loading')
      && get(trainingBatchesFetching.toJS(), 'success') &&
      (prevProps.trainingBatchesFetching !== trainingBatchesFetching)) ||
      (trainingBatchesAdd && !get(trainingBatchesAdd.toJS(), 'loading')
      && get(trainingBatchesAdd.toJS(), 'success') &&
      (prevProps.trainingBatchesAdd !== trainingBatchesAdd)) || 
      (trainingBatchesUpdate && !get(trainingBatchesUpdate.toJS(), 'loading')
      && get(trainingBatchesUpdate.toJS(), 'success') &&
        (prevProps.trainingBatchesUpdate !== trainingBatchesUpdate)) ||
      (trainingBatchesDelete && !get(trainingBatchesDelete.toJS(), 'loading')
      && get(trainingBatchesDelete.toJS(), 'success') &&
      (prevProps.trainingBatchesDelete !== trainingBatchesDelete))
      )) {
      this.convertDataToTable()
    }
    if (isTeacherTrainingBatches && (manageTrainersFetchStatus && !get(manageTrainersFetchStatus.toJS(), 'loading')
      && get(manageTrainersFetchStatus.toJS(), 'success') &&
        (prevProps.manageTrainersFetchStatus !== manageTrainersFetchStatus))) {
        let { trainers } = this.props
        trainers = trainers && trainers.toJS()
        if (trainers && Array.isArray(trainers)){
          this.setState({ schoolTrainers: trainers })
        }
    }
    if (isTeacherTrainingBatches && (trainingCoursePackagesFetchStatus &&
      !get(trainingCoursePackagesFetchStatus.toJS(), 'loading')
      && get(trainingCoursePackagesFetchStatus.toJS(), 'success') &&
        (prevProps.trainingCoursePackagesFetchStatus !== trainingCoursePackagesFetchStatus))) {
        let { coursePackagesData } = this.props
        coursePackagesData = coursePackagesData && coursePackagesData.toJS()
        if (coursePackagesData && Array.isArray(coursePackagesData)){
          this.setState({ coursePackages: coursePackagesData })
        }
    }
    const { openModal, schoolTrainers, coursePackages } = this.state
    if (prevState.openModal !== openModal && openModal) {
      if (!schoolTrainers.length) {
        fetchTrainers({ fromSchoolOnboarding: true })
      }
      if (!coursePackages.length) {
        fetchTrainingCoursePackages();
      }
    }
    if ((trainingBatchesAdd && !get(trainingBatchesAdd.toJS(), 'loading')
      && get(trainingBatchesAdd.toJS(), 'success') &&
      (prevProps.trainingBatchesAdd !== trainingBatchesAdd))) {
      this.convertDataToTable()
      notification.success({ message: 'Batch Added Successfully' })
      this.onCloseModal()
    } else if (trainingBatchesAdd && !get(trainingBatchesAdd.toJS(), 'loading')
        && get(trainingBatchesAdd.toJS(), 'failure') &&
        (prevProps.trainingBatchesAddFailure !== trainingBatchesAddFailure)) {
        if (trainingBatchesAddFailure && trainingBatchesAddFailure.toJS().length > 0) {
            const currentError = trainingBatchesAddFailure.toJS().pop()
            const errorMessage = (get(get(currentError, 'error'), 'errors[0].message', '') || '').replace('User', 'Trainer')
            notification.error({
              message: errorMessage
            })
        }
    }

    if ((trainingBatchesUpdate && !get(trainingBatchesUpdate.toJS(), 'loading')
      && get(trainingBatchesUpdate.toJS(), 'success') &&
      (prevProps.trainingBatchesUpdate !== trainingBatchesUpdate))) {
      notification.success({ message: 'Batch Updated Successfully' })
      this.onCloseModal()
    } else if (trainingBatchesUpdate && !get(trainingBatchesUpdate.toJS(), 'loading')
        && get(trainingBatchesUpdate.toJS(), 'failure') &&
        (prevProps.trainingBatchesUpdateFailure !== trainingBatchesUpdateFailure)) {
        if (trainingBatchesUpdateFailure && trainingBatchesUpdateFailure.toJS().length > 0) {
            const currentError = trainingBatchesUpdateFailure.toJS().pop()
            const errorMessage = (get(get(currentError, 'error'), 'errors[0].message', '') || '').replace('User', 'Trainer')
            notification.error({
              message: errorMessage
            })
        }
    }
    if ((trainingBatchesDelete && !get(trainingBatchesDelete.toJS(), 'loading')
      && get(trainingBatchesDelete.toJS(), 'success') &&
      (prevProps.trainingBatchesDelete !== trainingBatchesDelete))) {
      notification.success({ message: 'Batch Deleted Successfully' })
    } else if (trainingBatchesDelete && !get(trainingBatchesDelete.toJS(), 'loading')
        && get(trainingBatchesDelete.toJS(), 'failure') &&
        (prevProps.trainingBatchesDeleteFailure !== trainingBatchesDeleteFailure)) {
        if (trainingBatchesDeleteFailure && trainingBatchesDeleteFailure.toJS().length > 0) {
            const currentError = trainingBatchesDeleteFailure.toJS().pop()
            const errorMessage = (get(get(currentError, 'error'), 'errors[0].message', '') || '').replace('User', 'Trainer')
            notification.error({
              message: errorMessage
            })
        }
    }
  }
  onEditCourseClick = (data) => {
    const { history, schoolId } = this.props
    if (!get(data, 'coursePackage.id')) {
      notification.warn({ message: 'No Course Package assigned' })
      return
    }
    return history.push({
      pathname: `/sms/school-dashboard/${schoolId}/batches/${get(data, 'id')}/coursepackage`,
      state: {
        batchData: data
      }
    })
  }
  renderTableContent = (type, content, data) => {
    const { history, schoolId, isTeacherTrainingBatches } = this.props
    if (type === 'scheduling') {
      return (
        <SectionButton
          type='primary'
          disabled={get(data, 'bookingDate') && get(data, 'slot')}
          onClick={() => {
            if (this.getCampaignType() === b2b2cEvent) {
              localStorage.setItem('eventView', true)
            }
            history.push({
              pathname: `/sms/school-dashboard/${schoolId}/batches/${data.id}/scheduling`,
              state: {
                batchData: data
              }
            })
          }
          }
        >
          {get(data, 'bookingDate') && get(data, 'slot') ?
            `${moment(get(data, 'bookingDate')).format('L')} : 
            ${getSlotLabel(Number(get(data, 'slot').replace('slot', ''))).startTime}` :
            'Add Schedule'}<CalendarOutlined />
        </SectionButton>
      )
    }
    if (type === 'mentors') {
      if (isTeacherTrainingBatches) return get(data, 'allottedMentor.name')
      return (
        <SectionButton
          type='primary'
          onClick={() => {
            if (this.getCampaignType() === b2b2cEvent) {
              localStorage.setItem('eventView', true)
            }
            history.push({
              pathname: `/sms/school-dashboard/${schoolId}/batches/${data.id}/mentors`,
              state: {
                batchData: data
              }
            })
          }
          }
        >
          {get(data, 'allottedMentor.id') ? get(data, 'allottedMentor.name') : 'Choose Mentor'}<UserIcon />
        </SectionButton>
      )
    }
    if (type === 'students') {
      return (
        <BatchName
          onClick={() => {
            if (this.getCampaignType() === b2b2cEvent) {
              localStorage.setItem('eventView', true)
            }
            if (isTeacherTrainingBatches) {
              return history.push({
                pathname: `/sms/school-dashboard/${schoolId}/batches/${data.id}/training-students`,
                state: {
                  batchData: data
                }
              })
            }
            history.push({
              pathname: `/sms/school-dashboard/${schoolId}/batches/${data.id}/students`,
              state: {
                batchData: data
              }
            })
          }
          }
        >
          {content}
        </BatchName>)
    }
    if (type === 'links') {
      return (
        <SectionButton
          type='primary'
          onClick={() => {
            this.setState({
              sessionLinkModal: true,
              IDwithcustomSessionLink: get(data, 'id'),
              batchCodeforModal: get(data, 'code'),
              customSessionLink: get(data, 'customSessionLink')
            })
          }}
        >
          {`${get(data, 'customSessionLink') ? 'Edit' : 'Add'} Link`} <LinkOutlined />
        </SectionButton>
      )
    }
    if (type === 'teacherTrainingAction') {
      return (
        <>
          <span
            style={{ marginRight: 10, cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => this.onEditCourseClick(data)}
          >
            Edit Sessions
          </span>
          <MainTable.ActionItem.IconWrapper>
            <MainTable.ActionItem.EditIcon onClick={() => this.openEditModal(data)} />
          </MainTable.ActionItem.IconWrapper>
          <Popconfirm
            title='Do you want to delete this batch?'
            placement='topRight'
            onConfirm={async () => {
              await deleteTrainingBatch(get(data, 'id'))
            }}
            okText='Yes'
            cancelText='Cancel'
            key='delete'
            overlayClassName='popconfirm-overlay-primary'
          >
            <MainTable.ActionItem.IconWrapper>
              <MainTable.ActionItem.DeleteIcon />
            </MainTable.ActionItem.IconWrapper>
          </Popconfirm>
        </>
      )
    }
    return null
  }
  gradeNumber = (grade) => grade.replace('Grade', '')
  renderClass = (data, type) => {
    if (type === 'grade') {
      return (
        data && data.length > 0 ? data.map((g, i) => i === data.length - 1 ? `${this.gradeNumber(g)}` : `${this.gradeNumber(g)},`) : '-'
      )
      /* eslint-disable no-else-return */
    } else {
      return (
        data && data.length > 0 ? data.map((s, i) => i === data.length - 1 ? `${s}` : `${s},`) : '-'
      )
    }
  }
  convertDataToTable = () => {
    const { filter } = this.state
    const { isTeacherTrainingBatches } = this.props
    let batchData = (this.props.campaignBatches && sortBy(this.props.campaignBatches.toJS(), 'code')) || []
    if (isTeacherTrainingBatches) {
      const batchesData = (this.props.trainingBatches && sortBy(this.props.trainingBatches.toJS(), 'code')) || []
      batchData = sortBy(batchesData, 'createdAt').map((batch, index) => {
        const grades = [...new Set(get(batch, 'classes', []).map(({ grade }) => grade))]
        const sections = [...new Set(get(batch, 'classes', []).map(({ section }) => section))]
       return {
          ...batch,
          index: index + 1,
          grade: grades,
          section: sections,
          students: get(batch, 'studentsMeta.count', 0),
          allottedMentor: get(batch, 'allottedMentor'),
        }
      })
    }
      this.setState({
      batchData
      }, () => {
      let columns = []
      if (this.state.batchData.length > 0) {
        columns = [
          {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            render: (index) => index
          },
          {
            title: 'Batch Code',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            render: (content, data) => this.renderTableContent('students', content, data)
          },
          {
            title: 'Classroom',
            dataIndex: 'classroomTitle',
            key: 'classroomTitle',
            align: 'center',
            render: (classroomTitle) => classroomTitle
          },
          {
            title: 'Grade',
            dataIndex: 'grade',
            key: 'grade',
            align: 'center',
            render: (grade) => this.renderClass(grade, 'grade')
          },
          {
            title: 'Sections',
            dataIndex: 'section',
            key: 'section',
            align: 'center',
            render: (section) => this.renderClass(section)
          },
          {
            title: 'Students',
            dataIndex: 'students',
            key: 'students',
            align: 'center',
          },
          {
            title: 'Allot Mentor',
            dataIndex: 'allotedMentor',
            key: 'allotedMentor',
            align: 'center',
            render: (_, data) => this.renderTableContent('mentors', null, data)
          },
          {
            title: 'Course',
            dataIndex: 'course',
            key: 'course',
            align: 'center',
          },
          {
            title: 'Schedule',
            dataIndex: 'Schedule',
            key: 'Schedule',
            align: 'center',
            render: (_, data) => this.renderTableContent('scheduling', null, data)
          },
        ]
        
        if (this.getCampaignType() === b2b && !isTeacherTrainingBatches) {
          columns.push(
            {
              title: 'Session Link',
              dataIndex: 'customSessionLink',
              key: 'customSessionLink',
              align: 'center',
              render: (_, data) => this.renderTableContent('links', null, data)
            })
        }
        if (isTeacherTrainingBatches) {
          columns = columns.filter(column =>
            get(column, 'key') !== 'Schedule' && get(column, 'key') !== 'course'
            && get(column, 'key') !== 'grade' && get(column, 'key') !== 'section'
          )
          columns.push(
            {
              title: 'Action',
              dataIndex: 'id',
              key: 'id',
              align: 'center',
              render: (_, data) => this.renderTableContent('teacherTrainingAction', null, data)
            }
          )
        }
        this.setState({
          columns
        })
      }
    })
  }
  onPageChange = (page) => {
    this.setState(
      {
        currentPage: page,
        skip: page - 1,
      },
      () => this.fetchBatchesData()
    )
  }
  getCampaignType = () => {
    let type = ''
    const { chosenCampaign, campaigns } = this.props
    if (chosenCampaign && campaigns.length > 0) {
      type = get(campaigns.find((campaign) => get(campaign, 'id') === chosenCampaign), 'type')
    }
    return type
  }
  openEditModal = (data) => {
    const { academicYearConnectId } = this.props
    if (!academicYearConnectId) {
      return notification.warn({ message: 'Please select Academic Year to proceed further' })
    }
    const editData = {
      ...data,
      coursePackage: get(data, 'coursePackage.id'),
      allottedMentor: get(data, 'allottedMentor.id'),
      classroomTitle: get(data, 'classroomTitle'),
      description: get(data, 'description'),
    }
    this.setState({
      openModal: true,
      operation: 'edit',
      editData
    })
  }
  openAddModal = () => {
    const { schoolCode, academicYearConnectId } = this.props
    if (!schoolCode) return
    if (!academicYearConnectId) {
      return notification.warn({ message: 'Please select Academic Year to proceed further' })
    }
    this.setState({
      openModal: true,
      operation: 'add'
    })
  }
  onCloseModal = () => {
    const { openModal } = this.state
    if (openModal) {
      this.setState({
        openModal: false,
        operation: '',
        editData: {},
      })
    }
  }
  render() {
    const { chosenCampaign, campaigns, selectCampaign,
      campaignBatchesFetching, batchesMeta, campaignBatchesUpdate,
      isTeacherTrainingBatches = false, trainingBatchesFetching,
      trainingBatchesMeta, trainingBatchesUpdate, trainingBatchesAdd,
      schoolCode, schoolId, academicYearConnectId
    } = this.props
    const { columns, batchData, perPage, currentPage, filter,
      openModal, operation, editData, coursePackages, schoolTrainers } = this.state
    let tableLoading = campaignBatchesFetching && get(campaignBatchesFetching.toJS(), 'loading');
    let batchesMetaCount = batchesMeta
    if (isTeacherTrainingBatches) {
      tableLoading = trainingBatchesFetching && get(trainingBatchesFetching.toJS(), 'loading');
      batchesMetaCount = trainingBatchesMeta
    }
    return (
      <>
        {!isTeacherTrainingBatches ? (
           <FlexContainer justify='flex-start' style={{ flexWrap: 'wrap' }}>
           <SectionButton
              type={filter === 'all' ? 'primary' : 'default'}
              onClick={() => {
                selectCampaign('all')
                this.setState({filter: 'all'})
              }}
              style={{ margin: '1vw' }}
            >All
            </SectionButton>
            <SectionButton
              type={filter === "classroom" ? 'primary' : 'default'}
              onClick={() => {
                selectCampaign('classroom')
                this.setState({filter: 'classroom'})
              }}
              style={{ margin: '1vw' }}
            >Classroom
            </SectionButton>
          {
            campaigns.map(({ id, title }) => (
              <SectionButton
                type={chosenCampaign === id ? 'primary' : 'default'}
                onClick={() => {
                  selectCampaign(id)
                  this.setState({filter: ''})
                }}
                style={{ margin: '1vw' }}
              >{title}
              </SectionButton>
            ))
          }
        </FlexContainer>
          ) : null}
        <FlexContainer justify={isTeacherTrainingBatches ? 'space-between' : 'center'}>
          <StudentModalBox style={{ width: 'auto' }}>
            {!isTeacherTrainingBatches ? <h4>Model: {this.getCampaignType()}</h4> : null}
            <h4 style={{ marginLeft: '10px' }}>No. of batches: {batchesMetaCount || 0}</h4>
          </StudentModalBox>
          {isTeacherTrainingBatches ? (
            <StyledButton type='primary' onClick={this.openAddModal}>
              Add Batch
            </StyledButton>
          ) : null}
        </FlexContainer>
        <MDTable
          columns={columns}
          dataSource={batchData}
          loading={tableLoading}
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
        <Modal
          visible={this.state.sessionLinkModal}
          centered
          onCancel={() => { this.setState({ sessionLinkModal: false }) }}
          footer={[
            <Button type='primary' key='saveLink' loading={campaignBatchesUpdate
              && get(campaignBatchesUpdate.toJS(), 'loading')
              && !get(campaignBatchesUpdate.toJS(), 'success')}

              onClick={() => {
                updateCampaignBatches(this.state.IDwithcustomSessionLink, this.state.customSessionLink, this.props.chosenCampaign);
              }}>
              Save
            </Button>
          ]}
        >
          <h2>Add Session Link</h2>
          <h4>For {this.state.batchCodeforModal}</h4>
          <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
            <Input placeholder='Session Link' value={this.state.customSessionLink} onChange={(e) => this.setState({ customSessionLink: e.target.value })} />
            <Button type='link' size='small' >
              <EditFilled style={{ fontSize: '20px', color: '#08c', marginLeft: '10px', marginRight: '5px' }} />
            </Button>
            <Button type='link'
              onClick={() => { copyToClipboard(this.state.customSessionLink) }} size='small'>
              <CopyFilled style={{ fontSize: '20px', color: '#08c', marginLeft: '5px', marginRight: '10px' }} />
            </Button>
          </div>
        </Modal>
        <TrainingBatchModal
          openModal={openModal}
          operation={operation}
          closeModal={this.onCloseModal}
          editData={editData}
          trainingBatchesUpdate={trainingBatchesUpdate}
          trainingBatchesAdd={trainingBatchesAdd}
          coursePackages={coursePackages}
          schoolTrainers={schoolTrainers}
          schoolCode={schoolCode}
          schoolId={schoolId}
          academicYearConnectId={academicYearConnectId}
        />
        {
          batchesMetaCount > perPage && (
            <FlexContainer justify='center'>
              <Pagination
                total={batchesMetaCount || 0}
                onChange={this.onPageChange}
                current={currentPage}
                defaultPageSize={perPage}
              />
            </FlexContainer>
          )
        }
      </>
    )
  }
}

BatchesTab.propTypes = {
  campaigns: PropTypes.arrayOf({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  schoolId: PropTypes.string.isRequired,
  campaignBatches: PropTypes.arrayOf({}).isRequired,
  chosenCampaign: PropTypes.string.isRequired,
  selectCampaign: PropTypes.string.isRequired,
  campaignBatchesFetching: PropTypes.shape({}).isRequired,
  campaignBatchesUpdate: PropTypes.shape({}).isRequired,
  batchesMeta: PropTypes.number.isRequired,
}

export default BatchesTab
