/* eslint-disable max-len */
import gql from 'graphql-tag'
import duck from '../../duck'
import getIdArrForQuery from '../../utils/getIdArrForQuery'

const fetchFiltersForMentorDasboardClasses = async (
  userId,
  isAdmin,
) => {
  const isTeacherTraining = window.location.pathname.includes('/teacherTraining')
  const userIds = getIdArrForQuery(userId)
  if (isAdmin) {
    return duck.query({
      query: gql`
              query {
          batches(filter: {
            ${isTeacherTraining ? 'isTeacherTraining: true' : ''}
          }) {
            id
            code 
            createdAt
          }
          schools{
            id
            name
          }
          courses(filter: {status: published}) {
            id
            title
          }
        }
            `,
      type: 'mentorDashboardClassesFilters/fetch',
      key: 'filtersForMentorDashboardClasses',
    })
  }
  return duck.query({
    query: gql`
            query {
        batches(filter: {
          allottedMentor_some: {id_in: [${userIds}]}
          ${isTeacherTraining ? 'isTeacherTraining: true' : ''}
        }) {
          id
          code
          createdAt 
        }
        schools(filter: {
              students_some:{
                batch_some:{
                  allottedMentor_some:{
                    id_in: [${userIds}]
                  }
                }
              }
            }){
          id
          name
        }
        courses(filter: {status: published}) {
          id
          title
        }
      }
          `,
    type: 'mentorDashboardClassesFilters/fetch',
    key: 'filtersForMentorDashboardClasses',
  })
}
export default fetchFiltersForMentorDasboardClasses
