import { CopyOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import { get, toUpper } from 'lodash';
import React, { Component } from 'react'
import updateMentorProfileLinks from '../../../actions/userProfile/updateMentorProfileLinks';
import copyToClipboard from '../../../utils/copyToClipboard';
import toastrMessage from '../../../utils/messages';
import spokenLanguages from '../../../utils/spokenLanguages/spokenLanguages';

export default class RenderMentorProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mentorLinksLoading: false,
      sessionLink: '',
      meetingId: '',
      meetingPassword: '',
      googleMeetLink: '',
      languageKey:[],
    }
  }
  getKeyByValue=(object, value)=>{
    return Object.keys(object).find(key => object[key] === value);
  }
  componentDidMount = () => {

    const { addMentor }= this.props;
    const spokenLanguage = spokenLanguages().language;
    let keyLanguageArray=[];
    (get(addMentor,'language')||[]).map((language)=>{
      keyLanguageArray=[...keyLanguageArray,this.getKeyByValue(spokenLanguage,language.value)]
    })
      this.setState({
        sessionLink: get(addMentor, 'sessionLink', '-') || '',
        meetingId: get(addMentor, 'meetingId') || '',
        meetingPassword: get(addMentor, 'meetingPassword')|| '',
        googleMeetLink: get(addMentor, 'googleMeetLink')|| '',
        languageKey: keyLanguageArray,
      })
  }
  componentDidUpdate = (prevProps) => {
    const { addMentor }= this.props;
    
    if (prevProps.visible !== this.props.visible) {
      const spokenLanguage = spokenLanguages().language;

      let keyLanguageArray=[];
      (get(addMentor,'language')||[]).map((language)=>{
        keyLanguageArray=[...keyLanguageArray,this.getKeyByValue(spokenLanguage,language.value)]
      })
      this.setState({
        sessionLink: get(addMentor, 'sessionLink', '-')|| '',
        meetingId: get(addMentor, 'meetingId')|| '',
        meetingPassword: get(addMentor, 'meetingPassword')|| '',
        googleMeetLink: get(addMentor, 'googleMeetLink')|| '',
        languageKey: keyLanguageArray,
      })
    }

    if (this.props.mentorProfileUpdateStatus && !get(this.props.mentorProfileUpdateStatus.toJS(), 'loading') && get(this.props.mentorProfileUpdateStatus.toJS(), 'success')
      && (prevProps.mentorProfileUpdateStatus !== this.props.mentorProfileUpdateStatus)) {
      this.setState({
        changedGoogleMeetLink: false,
        changedSessionLink: false,
        changedMeetingId: false,
        changedMeetingPassword: false,
        sessionLink: '',
        meetingId: '',
        meetingPassword: '',
        googleMeetLink: '',
      })
    }
  }
  viewMentorProfileLanguageChange = (languageObjArray) => {

    let newLanguageKeyArray = [];
    languageObjArray.map((languageObjArray) => {
      newLanguageKeyArray = [...newLanguageKeyArray,languageObjArray.label]
    })
    this.setState({
      languageKey: newLanguageKeyArray,
    })
  }
  render() {
    const {  sessionLink, mentorLinksLoading,
      meetingId, meetingPassword, googleMeetLink, language,languageKey } = this.state;
    const { addMentor, handleCancel,setVisibleForEditMentorProfileModal }= this.props;
    const divStyle = { display: 'grid', gridTemplateColumns: '35% auto', marginBottom: '10px' }
    const spokenLanguage = spokenLanguages().language;
    return (
      <>
        <div style={{ wordBreak: 'break-all' }}>
          <div style={divStyle}>
            <h3>Mentor Rating : </h3>
            <h3>{get(addMentor, 'mentorRating') || '-'}</h3>
          </div>
          <div style={divStyle}>
            <h3>Zoom Link : </h3>
            <div style={{ display: 'flex' }}>
              <Input value={sessionLink }
              // === '' ? get(addMentor, 'sessionLink', '-')|| '' : sessionLink}
                onChange={(e) => {
                  this.setState({ changedSessionLink: true, sessionLink: e.target.value })
                }} style={{ width: '200px' }}
              />
              <Button type='primary' style={{ marginLeft: '20px' }} onClick={() => window.open(sessionLink === '' ? get(addMentor, 'sessionLink', '-') : sessionLink, '_blank')}><LinkOutlined /></Button>
              <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => copyToClipboard(sessionLink === '' ? get(addMentor, 'sessionLink', '-') : sessionLink)}><CopyOutlined /></Button>
            </div>
          </div>
          <div style={divStyle}>
            <h3>Meeting ID : </h3>
            <Input value={meetingId}
            // === '' ? get(addMentor, 'meetingId')|| '' : meetingId}
              onChange={(e) => {
                this.setState({ changedMeetingId: true, meetingId:e.target.value })
              }} style={{ width: '200px' }}
            />
          </div>
          <div style={divStyle}>
            <h3>Meeting Password : </h3>
            <Input value={meetingPassword }
            // === '' ? get(addMentor, 'meetingPassword')|| '' : meetingPassword}
              onChange={(e) => {
                this.setState({ changedMeetingPassword: true, meetingPassword: e.target.value })
              }} style={{ width: '200px' }}
            />
          </div>
          <div style={divStyle}>
            <h3>Google Meet Link : </h3>
            <div style={{ display: 'flex' }}>
              <Input value={googleMeetLink}
              // === '' ? get(addMentor, 'googleMeetLink')|| '' : googleMeetLink}
                onChange={(e) => {
                  this.setState({ changedGoogleMeetLink: true, googleMeetLink: e.target.value })
                }} style={{ width: '200px' }}
              />
              <Button type='primary' style={{ marginLeft: '20px' }} onClick={() => window.open(googleMeetLink === '' ? get(addMentor, 'googleMeetLink', '-') : googleMeetLink, '_blank')}><LinkOutlined /></Button>
              <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => copyToClipboard(googleMeetLink === '' ? get(addMentor, 'googleMeetLink', '-') : googleMeetLink)}><CopyOutlined /></Button>
            </div>
          </div>
          <div style={divStyle}>
            <h3>Language : </h3>
            <div style={{ display: 'flex' }}>
              <Select
                style={{ width: "56%" }}
                labelInValue
                mode="multiple"
                value={ languageKey?languageKey.map((language) => { return { key: language, value: language } }):[] }
                onChange={(e) => { this.viewMentorProfileLanguageChange(e) }}
              >
                {
                  Object.keys(spokenLanguage).map((language, i) => {
                    return <Select.Option key={language}>{language}</Select.Option>
                  })
                }
              </Select>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Button 
            type='primary' 
            loading={mentorLinksLoading} 
            onClick={() => {
              if (get(addMentor, 'googleMeetLink', '-') !== googleMeetLink || get(addMentor, 'sessionLink', '-') !== googleMeetLink.sessionLink || get(addMentor, 'meetingId', '-') !== meetingId || get(addMentor, 'meetingPassword', '-') !== meetingPassword) {
                this.setState({ mentorLinksLoading: true }, async () => {
                  let input = {}

                  let gMeetLink, sLink, meetId, meetPassword
                  if (googleMeetLink) gMeetLink = googleMeetLink.trim()
                  if (!gMeetLink || !googleMeetLink) {
                    toastrMessage('error', '', 'error', 'Enter Valid Google Meet Link')
                    this.setState({
                      mentorLinksLoading: false,
                    })
                    
                    return
                  }
              
                  if (sessionLink) sLink = sessionLink.trim()
                  if (!sLink || !sessionLink) {
                    toastrMessage('error', '', 'error', 'Enter Valid Zoom Link')
                    this.setState({
                      mentorLinksLoading: false,
                    })
                    
                    return
                  }
               
                  if (meetingId) meetId = meetingId.trim()
                  if (!meetId || !meetingId) {
                    toastrMessage('error', '', 'error', 'Enter Valid Meeting Id')
                    this.setState({
                      mentorLinksLoading: false,
                    })
                    
                    return
                  }
                  
                  if (meetingPassword) meetPassword = meetingPassword.trim()
                  if (!meetPassword || !meetingPassword) {
                    toastrMessage('error', '', 'error', 'Enter Valid Meeting Password')
                    this.setState({
                      mentorLinksLoading: false,
                    })
                    
                    return
                  }
                
                  if (languageKey.length === 0) {
                    toastrMessage('error', '', 'error', 'Select atleast one Language')
                    this.setState({
                      mentorLinksLoading: false,
                    })
                    
                    return
                  }
                 
                  input.googleMeetLink = googleMeetLink ? googleMeetLink.trim() : get(addMentor, 'googleMeetLink')
                  input.sessionLink = sessionLink ? sessionLink.trim() : get(addMentor, 'sessionLink', '-')
                  input.meetingId = meetingId ? meetingId.trim() : get(addMentor, 'meetingId')
                  input.meetingPassword = meetingPassword ? meetingPassword.trim() : get(addMentor, 'meetingPassword')

                  input = {
                    ...input, knownLanguages: {
                      replace: languageKey.map((language) => { return { value: spokenLanguage[language] } }) 
                    }

                  }
                  await updateMentorProfileLinks(get(addMentor, 'mentorId'), input).then(() => {
                    this.setState({
                      mentorLinksLoading: false,
                    });
                    toastrMessage('success', '', 'success', 'Details updated successfully ')
                    handleCancel()
                    setVisibleForEditMentorProfileModal();
                  })
                })
              }
            }}>
              Update
            </Button>
          </div>
        </div>
      </>
    )
  }
}