import { SearchOutlined, StarFilled, UserOutlined } from '@ant-design/icons'
import { debounce, get, sortBy } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  fetchMentorsForBroadcast, updateBatchSessionForDemand,
  updateMenteeSessionForDemand
} from '../../../../../actions/demandSupplyDashboard'
import getFullPath from '../../../../../utils/getFullPath'
import Styles from '../../../DemandSupplyDashboard.style'
import DemandCalendarWrapper from '../../DemandCalendar.style'
import { FemaleSvg, MaleSvg } from '../../../../../constants/icons'
import { getMentorRating, renderCodingLanguage } from '../../../demandSupply-utils'
import customNotification from '../../../components/CustomNotification'

class BroadcastMentor extends React.Component {
  state = {
    searchText: ''
  }
  onSearchFetch = (e) => {
    this.setState({
      searchText: get(e, 'target.value') || ''
    }, () => {
      if (this.state.searchText.length > 0) {
        debounce(() => fetchMentorsForBroadcast(`{ name_contains: "${this.state.searchText}" }`), 800)()
      }
    })
  }
  // onAllCheckClick = (checked) => {
  //   let { mentorsForBroadcast } = this.props
  //   mentorsForBroadcast = mentorsForBroadcast && mentorsForBroadcast.toJS() || []
  //   if (checked) {
  //     this.setState({
  //       selectedMentors: mentorsForBroadcast
  //     })
  //   } else {
  //     this.setState({
  //       selectedMentors: []
  //     })
  //   }
  // }
  checkIfCanBroadCast = () => {
    const { sessionData, assignedMentor } = this.props
    const startTime = get(sessionData, 'startTime')
    const isBatchSession = get(sessionData, 'recordType') === 'batchSession'
    const isMentorAssigned = get(sessionData, 'mentorMenteeSession', false) || get(assignedMentor, 'id')
    return !isBatchSession && !isMentorAssigned && moment().isBefore(moment(startTime))
  }
  getTableColumn = () => {
    const { onSelectMentor, selectedMentors } = this.props
    const columns = [
      {
        title: 'Mentor',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        render: (name, record) => (
          <Styles.FlexContainer justify='flex-start'>
            {getFullPath(get(record, 'profilePic.uri'))
              ? <DemandCalendarWrapper.MentorProfile style={{ marginRight: 10 }} profilePic={getFullPath(get(record, 'profilePic.uri'))} />
              : <Styles.StyledProfileIcon component={UserOutlined} />}
            {name}
            {get(record, 'gender', 'female')
              && (get(record, 'gender', 'female').toLowerCase() === 'female'
                  ? <FemaleSvg /> : <MaleSvg />)}
          </Styles.FlexContainer>
        )
      },
      {
        title: 'Rating',
        dataIndex: 'rating',
        key: 'rating',
        align: 'center',
        width: 90,
        render: (_, record) => (
          <DemandCalendarWrapper.MentorRatingBox style={{ margin: '0 auto' }}>
            <StarFilled />
            {getMentorRating(get(record, 'mentorProfile'))}
          </DemandCalendarWrapper.MentorRatingBox>
        )
      },
      {
        title: 'Langauges',
        dataIndex: 'languages',
        key: 'languages',
        align: 'center',
        width: 250,
        render: (_, record) => renderCodingLanguage(get(record, 'mentorProfile'))
      },
      {
        title: 'Select',
        // title: () => (
        //   <Styles.Checkbox
        //     checked={(selectedMentors.length > 0 &&
        //       selectedMentors.length < menterDetails.length)
        //       || (selectedMentors.length > 0 && selectedMentors.length === menterDetails.length)}
        //     halfChecked={selectedMentors.length > 0 &&
        //       selectedMentors.length < menterDetails.length}
        //     onChange={(event) => this.onAllCheckClick(event.target.checked)}
        //   />
        // ),
        dataIndex: 'checked',
        key: 'checked',
        align: 'center',
        width: 80,
        render: (_, record) => (
          <Styles.Checkbox
            onChange={() =>
              onSelectMentor(record)}
            checked={selectedMentors.map(mentor =>
              get(mentor, 'id')).includes(get(record, 'id'))}
          />
        )
      }
    ]
    return columns
  }
  onBroadCastClick = () => {
    const { sessionData, selectedMentors } = this.props
    if (!this.isBroadcasting() && selectedMentors.length > 0) {
      const broadCastedMentorsConnectIds = selectedMentors.map(mentor => get(mentor, 'mentorProfile.id'))
      if (get(sessionData, 'recordType') === 'menteeSession') {
        const input = {
          bookingDate: get(sessionData, 'rawBookingDate'),
        }
        if (get(sessionData, 'record')) {
          for (const slot in get(sessionData, 'record')) {
            if (slot && slot.startsWith('slot') && get(sessionData, 'record')[slot]) {
              input[slot] = true
            }
          }
        }
        updateMenteeSessionForDemand({
          sessionId: get(sessionData, 'sessionId'),
          broadCastedMentorsConnectIds,
          input
        })
      } else {
        updateBatchSessionForDemand(get(sessionData, 'sessionId'), null,
          broadCastedMentorsConnectIds).then(res => {
          if (res && res.updateBatchSession && res.updateBatchSession.id) {
            customNotification('Mentors broadcasted successfully', '', null, 'success')
          }
        })
      }
    }
  }

  isBroadcasting = () => {
    const { batchSessionsUpdateStatus, menteeSessionUpdateStatus } = this.props
    return menteeSessionUpdateStatus && get(menteeSessionUpdateStatus.toJS(), 'loading') ||
              batchSessionsUpdateStatus && get(batchSessionsUpdateStatus.toJS(), 'loading') || false
  }

  getMentorsList = () => {
    const { mentorsForBroadcast, mentorsList: propsMentorsList, selectedMentors } = this.props
    let assignedMentors = propsMentorsList || []
    assignedMentors = assignedMentors.map(mentor => get(mentor, 'id'))
    const { searchText } = this.state
    let mentorsForBroadcastList = mentorsForBroadcast && mentorsForBroadcast.toJS() || []
    mentorsForBroadcastList = mentorsForBroadcastList.filter(mentor =>
      !selectedMentors.map(ment => get(ment, 'id')).includes(get(mentor, 'id'))
      && !assignedMentors.includes(get(mentor, 'id')))
    mentorsForBroadcastList = mentorsForBroadcastList.filter(mentor =>
      get(mentor, 'name', '').toLowerCase().includes(searchText.toLowerCase()))
    const mentorsList = [...selectedMentors]
    if (searchText) mentorsList.unshift(...mentorsForBroadcastList)
    return sortBy(mentorsList, 'createdAt')
  }
  render() {
    const { mentorsForBroadcastFetchStatus, selectedMentors } = this.props
    const { searchText } = this.state
    return (
      <>
        <Styles.SearchInput
          suffix={<SearchOutlined />}
          value={searchText}
          onChange={this.onSearchFetch}
          placeholder='Search for mentor'
          disabled={!this.checkIfCanBroadCast()}
        />
        <DemandCalendarWrapper.DemandSupplyTable
          dataSource={this.getMentorsList()}
          columns={this.getTableColumn()}
          scroll={{ x: 'max-content', y: 400 }}
          loading={mentorsForBroadcastFetchStatus
            && get(mentorsForBroadcastFetchStatus.toJS(), 'loading')}
          pagination={false}
        />
        <div style={{ margin: '15px 0' }} />
        <Styles.FlexContainer justify='flex-end'>
          <h3 style={{ margin: '0 15px' }}>
            Selected Mentors :{selectedMentors.length}
          </h3>
          <Styles.PrimaryButton
            disabled={selectedMentors.length === 0 || !this.checkIfCanBroadCast()}
            onClick={this.onBroadCastClick}
          >
            {this.isBroadcasting() && <Styles.Spinner />}
            Broadcast now
          </Styles.PrimaryButton>
        </Styles.FlexContainer>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  mentorsForBroadcastFetchStatus: state.data.getIn(['mentorsForBroadcast', 'fetchStatus', 'mentorsForBroadcast']),
  mentorsForBroadcast: state.data.getIn(['mentorsForBroadcast', 'data']),
  menteeSessionUpdateStatus: state.data.getIn(['menteeSessionsForDemand', 'updateStatus', 'menteeSessionsForDemand']),
  batchSessionsUpdateStatus: state.data.getIn(['batchSessions', 'updateStatus', 'mentorMenteeSessionsForDemand']),
})

export default connect(mapStateToProps)(BroadcastMentor)
