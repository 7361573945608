
import gql from 'graphql-tag'
import duck from '../../duck';


const fetchContentTags = async () => {

    await duck.query({
        query: gql`
       query {
        contentTags(filter: { and: [{ tagStatus: active } {isEventTag:true}] }) {
            id
            title
          }
          }
              `,
        type: 'contentTags/fetch',
        key: 'contentTags',

    })
}

export default fetchContentTags
