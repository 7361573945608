import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { calculatePercentage, fetchLeadAnalyticsData } from '../common-util/ums-utils'
import UmsDashboardStyle from '../UmsDashboard.style'

const LeadAnalyticsTable = ({ leadAgentId }) => {
  const [analytics, setAnalytics] = useState({})
  useEffect(() => {
    if (leadAgentId) {
      fetchLeadAnalyticsData(leadAgentId).then(res => {
        setAnalytics(res)
      })
    }
  }, [leadAgentId])
  const {
    value: dayWiseBookingNumber,
    color: dayWiseBookingColor,
    actualValue: dayWiseBookingActualValue
  } = calculatePercentage(get(analytics, 'dayWiseBookings.count', 0), get(analytics, 'agent.dayWiseBooking', 0))
  const {
    value: monthlyBookingNumber,
    color: monthlyBookingColor,
    actualValue: monthlyBookingActualValue
  } = calculatePercentage(get(analytics, 'monthWiseBookings.count', 0), get(analytics, 'agent.monthlyBooking', 0))
  const {
    value: dayWiseConductionsNumber,
    color: dayWiseConductionsColor,
    actualValue: dayWiseConductionsActualValue
  } = calculatePercentage(get(analytics, 'dayWiseConductions.count', 0), get(analytics, 'agent.dayWiseConduction', 0))
  const {
    value: monthWiseConductionsNumber,
    color: monthlyConductionColor,
    actualValue: monthWiseConductionsActualValue
  } = calculatePercentage(get(analytics, 'monthWiseConductions.count', 0), get(analytics, 'agent.monthlyConduction', 0))
  return (
    <UmsDashboardStyle.LeadAnalyticsTable cellPadding='5'>
        <tr>
            <th />
            <th scope='col'>Benchmark</th>
            <th scope='col'>Achieved</th>
            <th scope='col'>Difference</th>
        </tr>
        <tr>
            <th scope='row'>Day Wise Bookings</th>
            <td>{get(analytics, 'agent.dayWiseBooking', 0)}</td>
            <td>{get(analytics, 'dayWiseBookings.count', 0)}</td>
                <td style={{
                    backgroundColor: dayWiseBookingColor,
                    color: dayWiseBookingActualValue < 30 ? 'white' : 'black'
                }}
                >{dayWiseBookingNumber}
                </td>
        </tr>
      <tr>
            <th scope='row'>Day Wise Conductions</th>
            <td>{get(analytics, 'agent.dayWiseConduction', 0)}</td>
            <td>{get(analytics, 'dayWiseConductions.count', 0)}</td>
                <td style={{
                    backgroundColor: dayWiseConductionsColor,
                    color: dayWiseConductionsActualValue < 30 ? 'white' : 'black'
                }}
                >
                    {dayWiseConductionsNumber}
                </td>
      </tr>
        <tr>
            <th scope='row'>Monthly Bookings</th>
            <td>{get(analytics, 'agent.monthlyBooking', 0)}</td>
            <td>{get(analytics, 'monthWiseBookings.count', 0)}</td>
                <td style={{
                    backgroundColor: monthlyBookingColor,
                    color: monthlyBookingActualValue < 30 ? 'white' : 'black'
                }}
                >{monthlyBookingNumber}
                </td>
        </tr>
        <tr>
            <th scope='row'>Monthly Conductions</th>
            <td>{get(analytics, 'agent.monthlyConduction', 0)}</td>
            <td>{get(analytics, 'monthWiseConductions.count', 0)}</td>
                <td style={{
                    backgroundColor: monthlyConductionColor,
                    color: monthWiseConductionsActualValue < 30 ? 'white' : 'black'
                }}
                >
                    {monthWiseConductionsNumber}
                </td>
        </tr>
    </UmsDashboardStyle.LeadAnalyticsTable>
  )
}

export default LeadAnalyticsTable
