import gql from "graphql-tag"
import duck from '../../../duck'

const fetchEventCategoriesForSettings = async (first, skip, filterQuery, searchQuery) => {
    await duck.query({
      query: gql`
         query {
                eventCategories(
                    filter:{ and :[
                        ${filterQuery ? `{ ${filterQuery} }`: ''}
                        ${searchQuery ? `{ ${searchQuery} }`: ''}
                    ]} 
                    orderBy: createdAt_ASC
                    first: ${first}
                    skip: ${first * skip}
                    ) {
                        id
                        title
                        displayOnWebsite
                        createdAt
                        status
                        createdBy {
                            name
                        }
                        eventsMeta {
                            count
                        }
                    }
                    eventCategoriesMeta(
                        filter:{ and :[
                            ${filterQuery ? `{ ${filterQuery} }`: ''}
                            ${searchQuery ? `{ ${searchQuery} }`: ''}
                        ]} 
                    ) {
                        count
                    }
                }
            `,
      type: 'eventCategories/fetch',
      key: 'eventCategories'
    })
  }
  
  export default fetchEventCategoriesForSettings