import React, {useEffect, useState } from 'react'
import { get} from 'lodash'
import { ComponentHolder,DataHolder,
StyledImageTag,IconHolder,ButtonHolder,ImageHolder,
StyledLabel,StyledInput,MessageText,
ReUploadDiv,ImageHolderUpload } from './Image.style'
import {Add} from '../../../../../../../../constants/icons'
import getFullPath from '../../../../../../../../utils/getFullPath'

const Image =(props)=> {
    const[showMsg,setShowMsg]=useState(false)
    const[url,setUrl]=useState('')
    const handleUpload=(event)=>{
      const fileObj=event.target.files[0]
      if(get(fileObj,'type','')) {
        const exp=fileObj.type
        const ans=exp.split('/')[0]
        if(ans==='image') {
          const file=event.target.files
          const objectURL = URL.createObjectURL(file[0])
          setUrl(objectURL)
          if(props.learningSlide) {
            props.Helper(file,'imageData','',props.slideContents,props.number)
          }
          setShowMsg(false)
        } else {
          setShowMsg(true)
        }
      }
    }
    const handleReUpload=(event)=>{
      const fileObj=event.target.files[0]
      if(get(fileObj,'type','')) {
        const exp=fileObj.type
        const ans=exp.split('/')[0]
        if(ans==='image') {
          const file=event.target.files
          const objectURL = URL.createObjectURL(file[0])
          setUrl(objectURL)
          if(props.learningSlide) {
            props.Helper(file,'imageData','',props.slideContents,props.number)
          }
          setShowMsg(false)
        } else {
          setShowMsg(true)
        }
      }
    }
    useEffect(()=>{
        if(get(props.selectedContent,'imageData',[])) {
          if(get(props.selectedContent,'imageData',[]).length>0) {
            const fileObj=get(props.selectedContent,'imageData',[])[0]
            const exp=fileObj.type
            const ans=exp.split('/')[0]
            if(ans==='image') {
              const file=get(props.selectedContent,'imageData',[])
              const objectURL = URL.createObjectURL(file[0])
              setUrl(objectURL)
            }
          }
          }
    },[props.slideContents])
    return <ComponentHolder key={`${get(props.learningSlide,'order')}${props.number}`}>
      {
        get(props.selectedContent,'media') === null ? <DataHolder>
          <ImageHolderUpload>
           {url!==''?<StyledImageTag src={url} alt=""/>:''}
          </ImageHolderUpload>
          <ButtonHolder>
             <ReUploadDiv>
             <StyledLabel key={props.key} htmlFor={`${get(props.learningSlide,'order')}${props.number}`}>
              <IconHolder>
                <Add/>
              </IconHolder> 
              <StyledInput  type="file" id={`${get(props.learningSlide,'order')}${props.number}`} accept="image/*" onChange={(e)=>{handleUpload(e)}}/>
                Add Image
             </StyledLabel>
             </ReUploadDiv>
             {showMsg === true ?<MessageText>Please Select The Correct Image</MessageText>:""}
          </ButtonHolder>
          </DataHolder>
        :<DataHolder>
        <ImageHolder>
        {url===''?<StyledImageTag src={getFullPath(get(props.selectedContent.media,'uri',''))} alt=""/>:<StyledImageTag src={url} alt=""/>}
        </ImageHolder>
        <ButtonHolder>
          <ReUploadDiv>
          <StyledLabel key={props.key} htmlFor={`${get(props.learningSlide,'order')}${props.number}`}>
            <IconHolder>
               <Add/>
            </IconHolder> 
            <StyledInput type="file" id={`${get(props.learningSlide,'order')}${props.number}`} accept="image/*" onChange={(e)=>{handleReUpload(e)}}/>
              Re-Upload New Image
            </StyledLabel>
          </ReUploadDiv>
          {showMsg === true ?<MessageText>Please Select The Correct Image</MessageText>:""}
        </ButtonHolder>
      </DataHolder>
      }
    </ComponentHolder>
}
export default Image;