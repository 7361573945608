import React, { Component } from 'react'
import { Input, Spin } from 'antd'
import { sortBy } from 'lodash'
import { SearchOutlined, LoadingOutlined, CloseCircleOutlined } from '@ant-design/icons'
import StudentTabStyle from './StudentTab.styles'
import fetchBatchSessions from '../../actions/studentTab/fetchBatchSessions'
import BatchContainer from './Components/BatchContainer'
import MenteeContainer from './Components/MenteeContainer'
import { TekieAmethyst } from '../../constants/colors'
import ClassesStyle from '../MentorDashboard/Classes/Classes.style'

const loadingIcon = <LoadingOutlined style={{ fontSize: 16, marginRight: '8px', color: TekieAmethyst }} spin />

class StudentTab extends Component {
  loadingRef
  constructor(props) {
    super(props)
    this.loadingRef = React.createRef()
    this.state = {
      fetchedEvents: [],
      skip: 0,
      isFetchingFromIntersectionObserver: false,
      serachedStudent: '',
      firstFetch: true,
      searched: false,
      isFetching: false
    }
  }
  async componentDidMount() {
    // const { totalBatches, totalMentorMenteeSessions } = this.props
    this.intersectionObserver = new IntersectionObserver(entries => {
      const ratio = entries[0].intersectionRatio
      const { skip } = this.state
      if (ratio > 0 && !this.state.isFetchingFromIntersectionObserver) {
        this.setState({
          skip: skip + 10,
          isFetchingFromIntersectionObserver: true,
        }, () => this.fetchBatchQuery(true)
        )
      }
    })
    this.intersectionObserver.observe(this.loadingRef.current)
    this.fetchBatchQuery(false, true)
  }
  setSearchedStudent = (e) => {
    this.setState({ serachedStudent: e.target.value })
  }

  componentWillUnmount() {
    this.intersectionObserver.disconnect()
  }
  fetchBatchQuery = async (isFromInfiniteScroll = false, firstRender = false) => {
    const { totalBatches, totalMentorMenteeSessions } = this.props
    this.setState({ isFetching: true })
    const skip = isFromInfiniteScroll ? this.state.skip : 0
    const student = this.state.serachedStudent
    const ss = student !== ''
    if (firstRender || totalBatches + totalMentorMenteeSessions > skip) {
      await fetchBatchSessions(10, skip, student, ss)
      const events = isFromInfiniteScroll ? this.state.fetchedEvents : []
      const batches = this.props.batches && this.props.batches.toJS()
      const mentorMenteeSessions = this.props.mentorMenteeSessions &&
    this.props.mentorMenteeSessions.toJS()
      batches.forEach(batch => {
        events.push(batch)
      })
      mentorMenteeSessions.forEach(batch => {
        events.push(batch)
      })
      const sortedEvents = sortBy(events, 'createdAt')
      this.setState({ fetchedEvents: sortedEvents, firstFetch: false, })
    }
    this.setState({
      isFetchingFromIntersectionObserver: false,
      isFetching: false })
  }
  searchStudent = () => {
    this.setState({ skip: 0, searched: true })
    this.fetchBatchQuery(false, true)
  }
  clearSearch = () => {
    this.setState({
      serachedStudent: '',
      searched: false
    }, () => this.fetchBatchQuery(false, true)
    )
  }
  render() {
    const { fetchedEvents, serachedStudent, searched,
      isFetching, isFetchingFromIntersectionObserver, firstFetch } = this.state
    const { totalBatches, totalMentorMenteeSessions } = this.props
    return (
      <StudentTabStyle.TopContainer>
        {(isFetching && !isFetchingFromIntersectionObserver) || firstFetch ? (
          <>
            <div className='loading-container show'>
              <div className='loading-bar-container'>
                <div />
              </div>
            </div>
            <div className='mentor-dashboard-calender-loading-container'>
              <Spin indicator={loadingIcon} />
              <div className='mentor-dashboard-calender-loading'>Fetching Sessions...</div>
            </div>
          </>
        ) : (null)}
        <StudentTabStyle.FlexContainer>
          <StudentTabStyle.Title>All Students</StudentTabStyle.Title>
          <Input value={serachedStudent}
            onKeyPress={(e) => {
              if (e.key === 'Enter') { this.searchStudent() }
            }}
            onChange={this.setSearchedStudent}
            placeholder='Search Student'
            suffix={!searched ? <SearchOutlined onClick={() => this.searchStudent()} /> :
            <CloseCircleOutlined onClick={() => this.clearSearch()} />}
            style={{ flex: '0.5' }}
          />
        </StudentTabStyle.FlexContainer>
        <div>
        {(fetchedEvents && fetchedEvents.length > 0) && this.state.fetchedEvents.map(batch => <div style={{ paddingTop: '40px' }}>{!batch.courseData ? <BatchContainer batch={batch} /> : <MenteeContainer session={batch} />}</div>)}
        <div
          ref={this.loadingRef}
          style={ClassesStyle.loadingCSS}
        >
        {(fetchedEvents && fetchedEvents.length > 0 && fetchedEvents.length > 3 &&
        fetchedEvents.length <= (totalBatches + totalMentorMenteeSessions) &&
        (totalBatches + totalMentorMenteeSessions) > 20) ? (
                <><Spin indicator={loadingIcon} /><div style={{ background: 'white' }} className='mentor-dashboard-calender-loading'>Fetching More Sessions...</div></>
              ) : null}
        </div>
        </div>
        {(totalBatches + totalMentorMenteeSessions) === 0 &&
        (<StudentTabStyle.ErrorMessage>No student found !!!!</StudentTabStyle.ErrorMessage>)}
      </StudentTabStyle.TopContainer>
    )
  }
}

export default StudentTab
