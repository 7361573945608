import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import { filterKey } from '../../utils/data-utils'
// eslint-disable-next-line import/no-named-as-default
import StudentTab from './StudentTab'
import withMentorDashboardNav from '../../components/withUpdatedNav'
import injectProps from '../../components/injectProps'
import { MENTOR_DASHBOARD } from '../../constants/navItemsConst'

const MentorDashboardSideNav = withMentorDashboardNav(StudentTab)({
  title: 'Students',
  activeNavItem: 'Students',
  showUMSNavigation: false,
  renderNavItems: MENTOR_DASHBOARD
})

const mapStateToProps = state => ({
  batches: filterKey(
    state.data.getIn([
      'batches',
      'data'
    ]), 'batchesForTable'
  ),
  mentorMenteeSessions: filterKey(
    state.data.getIn([
      'completedSession',
      'data'
    ]), 'batchesForTable'
  ),
  user: filterKey(
    state.data.getIn([
      'user',
      'data'
    ]), 'batch'
  ),
  totalBatches: state.data.getIn([
    'batchesMeta',
    'data',
    'count'
  ]),
  totalMentorMenteeSessions: state.data.getIn([
    'mentorMenteeSessionsMeta',
    'data',
    'count'
  ])
})


const MentorDashboardSideNavWithExtraProps = injectProps({
  notification
})(MentorDashboardSideNav)
export default connect(mapStateToProps)(withRouter(MentorDashboardSideNavWithExtraProps))
