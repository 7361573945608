import React, { Component } from 'react'
import { Table } from '../../../../components/StyledComponents'
import MainTable from '../../../../components/MainTable'

class RatingTableHead extends Component {
  render() {
    return (
      <Table.Row style={{ justifyContent: 'flex-start' }} columnsTemplate={this.props.columnsTemplate} minWidth={this.props.minWidth}>
        <Table.StickyItem
          style={{ left: 0 }}
        >
          <MainTable.Title style={{ width: 180 }} >Student Name</MainTable.Title>
        </Table.StickyItem>
        <Table.Item><MainTable.Title>Topic</MainTable.Title></Table.Item>
        <Table.Item><MainTable.Title>Course</MainTable.Title></Table.Item>
        <Table.Item><MainTable.Title>Ratings</MainTable.Title></Table.Item>
        <Table.Item><MainTable.Title>Slot</MainTable.Title></Table.Item>
        <Table.Item><MainTable.Title>Tags</MainTable.Title></Table.Item>
        <Table.Item><MainTable.Title>Comment</MainTable.Title></Table.Item>
      </Table.Row>
    )
  }
}

export default RatingTableHead
