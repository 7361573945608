import React from 'react'
import { get } from 'lodash'
import Calendar from '../../../components/FullCalendar'
import BatchContainer from './BatchContainer'

class EventOverview extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            calanderFilterStartDate: '',
            calanderFilterEndDate: '',
            isFetching: false,
            callCalender: false,
            fetchEvents: []
        }
    }


    componentDidUpdate = (prevProps) => {
        const { eventsLoading } = this.props
        if (eventsLoading && !get(eventsLoading.toJS(), 'loading')
            && get(eventsLoading.toJS(), 'success') &&
            (prevProps.eventsLoading !== eventsLoading)) {
            this.setState({
                fetchEvents: this.props.eventsData && this.props.eventsData.toJS() || []
            })
            }
    }

    render() {
        return <div className='eventOverview'>
            <Calendar
                initialCalendarView='dayGridMonth'
                customHeaderToolBar = {{
                    start: 'prev,next title today',
                    right: 'timeGridWeek,dayGridMonth,timeGridDay'
                }}
                datesSet={(args) => {
                    this.setState({
                        calanderFilterStartDate: args.startStr,
                        calanderFilterEndDate: args.endStr
                    })
                    this.setState({
                        isFetching: true
                    })
                    if (!this.state.isFetching) {
                        this.setState({
                            isFetching: false,
                            callCalender: true
                        })
                    }
                }}
            />
            <BatchContainer
                fetchEvents={this.state.fetchEvents} eventsCount={this.props.eventsMeta}
                tableLoading={this.props.eventsLoading && get(this.props.eventsLoading.toJS(), 'loading')}
             calanderFilterStartDate={this.state.calanderFilterStartDate} calanderFilterEndDate={this.state.calanderFilterEndDate}
             eventData={this.props.eventsData} callCalender={this.state.callCalender} {...this.props} />
        </div>

    }

}

export default EventOverview
