import gql from 'graphql-tag'
import duck from '../../../duck'
import getIdArrForQuery from '../../../utils/getIdArrForQuery'

const addMessage = async ({ input, stickerConnectId, emojiConnectIds, imageConnectId, learningObjectiveConnectId, questionConnectId }) =>
  duck.query({
    query: gql`
    mutation($input: MessageInput!) {
      addMessage(input: $input,
        ${stickerConnectId ? `stickerConnectId: "${stickerConnectId}"` : ''}
        ${emojiConnectIds.length > 0 ? `emojiConnectIds: [${getIdArrForQuery(emojiConnectIds)}]` : ''}
        ${imageConnectId ? `imageConnectId: "${imageConnectId}"` : ''}
        ${learningObjectiveConnectId ? `learningObjectiveConnectId: "${learningObjectiveConnectId}"` : ''}
        ${questionConnectId ? `questionConnectId: "${questionConnectId}"` : ''}
      ) {
        id
      }
    }
    `,
    variables: {
      input
    },
    type: 'message/add',
    key: 'message',
  })

export default addMessage
