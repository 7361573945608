import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'
import getIdArrForQuery from '../../utils/getIdArrForQuery'

const updateMentorAvailabilitySlot = async ({ id, input, schoolsConnectIds = [],
  broadCastedMentorsConnectIds = [], campaignsConnectIds = [], paySlabConnectId }) =>
  duck.query({
    query: gql`
    mutation($input: MentorAvailabilitySlotUpdate) {
      updateMentorAvailabilitySlot(
        id: "${id}"
        input: $input
        ${paySlabConnectId ? `paySlabConnectId: "${paySlabConnectId}"` : ''}
        ${schoolsConnectIds.length > 0 ? `schoolsConnectIds: [${getIdArrForQuery(schoolsConnectIds)}]` : ''}
        ${broadCastedMentorsConnectIds.length > 0 ? `broadCastedMentorsConnectIds: [${getIdArrForQuery(broadCastedMentorsConnectIds)}]` : ''}
        ${campaignsConnectIds.length > 0 ? `campaignsConnectIds: [${getIdArrForQuery(campaignsConnectIds)}]` : ''}
      ) {
        id
        date
        openedBy {
          id
          user {
            id
            name
          }
        }
        verticals {
          value
        }
        slotName
        paySlab {
        id
        title
        }
        countries {
        value
        }
        timezone
        count
        schools {
        id
        name
        }
        campaigns {
        id
        title
        code
        }
        broadCastedMentors {
        id
        }
        isBroadCasted
        mentorSessionsMeta{
          count
        }
        b2b2cCount: batchSessionsMeta(
          filter: {
            and: [
              { batch_some: { type_in: [b2b2c, normal] } }
              { topic_some: { order: 1 } }
            ]
          }
        ) {
          count
        }
        b2bCount: batchSessionsMeta(filter: { batch_some: { type: b2b } }) {
          count
        }
        b2cCount :menteeSessionsMeta(
          filter: { and: [{ topic_some: { order: 1 } }, { source_not: school }] }
        ) {
          count
        }
      }
    }
    `,
    variables: {
      input
    },
    type: 'mentorDemandSlots/update',
    key: 'mentorDemandSlots',
    changeExtractedData: (extractedData, originalData) => {
      extractedData.campaigns = []
      extractedData.schools = []
      extractedData.user = []
      extractedData.mentorAvailabilitySlots = {
        ...get(originalData, 'updateMentorAvailabilitySlot')
      }
      return { ...extractedData }
    },
  })

export default updateMentorAvailabilitySlot
