/* eslint-disable */
import React from "react";
import '../studentProfileLeftbar.scss' 

export const notificationsSvg = () => {
    return (
    <svg width="18" height="18" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1679 10.667H0.501282V9.50032H1.08462V5.43507C1.08462 2.52541 3.43545 0.166992 6.33462 0.166992C9.23378 0.166992 11.5846 2.52541 11.5846 5.43507V9.50032H12.1679V10.667ZM2.25128 9.50032H10.4179V5.43507C10.4179 3.16999 8.58978 1.33366 6.33462 1.33366C4.07945 1.33366 2.25128 3.16999 2.25128 5.43507V9.50032ZM4.87628 11.2503H7.79295C7.79295 11.6371 7.6393 12.008 7.36581 12.2815C7.09232 12.555 6.72139 12.7087 6.33462 12.7087C5.94784 12.7087 5.57691 12.555 5.30342 12.2815C5.02993 12.008 4.87628 11.6371 4.87628 11.2503Z" fill="black" />
    </svg>
    )
}

export const dashboardSvg = () => {
    return(
<svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.544556 0.682227C0.544556 0.323241 0.835571 0.0322266 1.19456 0.0322266H5.94601C6.305 0.0322266 6.59601 0.323241 6.59601 0.682227V6.6819C6.59601 7.04089 6.305 7.3319 5.94601 7.3319H1.19456C0.835571 7.3319 0.544556 7.04089 0.544556 6.6819V0.682227ZM1.84456 1.33223V6.0319H5.29601V1.33223H1.84456ZM7.7931 0.682227C7.7931 0.323241 8.08411 0.0322266 8.4431 0.0322266H13.1946C13.5535 0.0322266 13.8446 0.323241 13.8446 0.682227V4.19068C13.8446 4.54967 13.5535 4.84068 13.1946 4.84068H8.4431C8.08411 4.84068 7.7931 4.54967 7.7931 4.19068V0.682227ZM9.0931 1.33223V3.54068H12.5446V1.33223H9.0931ZM7.7931 6.68255C7.7931 6.32357 8.08411 6.03255 8.4431 6.03255H13.1946C13.5535 6.03255 13.8446 6.32357 13.8446 6.68255V12.6822C13.8446 13.0412 13.5535 13.3322 13.1946 13.3322H8.4431C8.08411 13.3322 7.7931 13.0412 7.7931 12.6822V6.68255ZM9.0931 7.33255V12.0322H12.5446V7.33255H9.0931ZM0.544556 9.17327C0.544556 8.81429 0.835571 8.52327 1.19456 8.52327H5.94601C6.305 8.52327 6.59601 8.81429 6.59601 9.17327V12.6817C6.59601 13.0407 6.305 13.3317 5.94601 13.3317H1.19456C0.835571 13.3317 0.544556 13.0407 0.544556 12.6817V9.17327ZM1.84456 9.82327V12.0317H5.29601V9.82327H1.84456Z" fill="black"/>
</svg>)
}

export const ratingsSvg = () => {
return(
    <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.33461 6.08699L1.98361 7.40299L2.50861 4.76033L0.530273 2.93099L3.20594 2.61366L4.33461 0.166992L5.46327 2.61366L8.13894 2.93099L6.16061 4.76033L6.68561 7.40299L4.33461 6.08699Z" fill="#F2C94C"/>
</svg>
)
}

export const studentSvg = () => {
return (
    <svg width="18" height="18" viewBox="0 0 17 16" fill="black" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.3346 14.667H13.6679V13.3337C13.6679 12.4496 13.3168 11.6018 12.6916 10.9766C12.0665 10.3515 11.2187 10.0003 10.3346 10.0003H6.33461C5.45056 10.0003 4.60271 10.3515 3.97759 10.9766C3.35247 11.6018 3.00128 12.4496 3.00128 13.3337V14.667H4.33461V13.3337C4.33461 12.8032 4.54533 12.2945 4.9204 11.9194C5.29547 11.5444 5.80418 11.3337 6.33461 11.3337H10.3346C10.865 11.3337 11.3738 11.5444 11.7488 11.9194C12.1239 12.2945 12.3346 12.8032 12.3346 13.3337V14.667ZM6.80388 8.36251C7.28918 8.56353 7.80933 8.66699 8.33461 8.66699C9.39548 8.66699 10.4129 8.24556 11.163 7.49542C11.9132 6.74527 12.3346 5.72786 12.3346 4.66699C12.3346 3.60613 11.9132 2.58871 11.163 1.83857C10.4129 1.08842 9.39548 0.666992 8.33461 0.666992C7.80933 0.666992 7.28918 0.770455 6.80388 0.971474C6.31858 1.17249 5.87762 1.46713 5.50619 1.83857C5.13475 2.21 4.84012 2.65096 4.6391 3.13626C4.43808 3.62156 4.33461 4.1417 4.33461 4.66699C4.33461 5.19228 4.43808 5.71242 4.6391 6.19773C4.84012 6.68303 5.13475 7.12398 5.50619 7.49542C5.87762 7.86685 6.31858 8.16149 6.80388 8.36251ZM10.2202 6.55261C9.72014 7.05271 9.04186 7.33366 8.33461 7.33366C7.62737 7.33366 6.94909 7.05271 6.449 6.55261C5.9489 6.05251 5.66795 5.37424 5.66795 4.66699C5.66795 3.95975 5.9489 3.28147 6.449 2.78137C6.94909 2.28128 7.62737 2.00033 8.33461 2.00033C9.04186 2.00033 9.72014 2.28128 10.2202 2.78137C10.7203 3.28147 11.0013 3.95975 11.0013 4.66699C11.0013 5.37424 10.7203 6.05251 10.2202 6.55261ZM15.3346 16.0003H3.33461V17.4003H15.3346V16.0003Z" fill="black"/>
<path opacity="0.5" d="M11.3346 3L7.83459 8L10.8346 7L11.3346 3Z" fill="black"/>
<path opacity="0.5" d="M6.83459 14.5H13.3346L12.8346 12L10.8346 11L6.83459 14.5Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="16" height="16" fill="white" transform="translate(0.334595)"/>
</clipPath>
</defs>
</svg>
)
}

export const profileSvg = () => {
return (
<svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.295204 14.6104C0.295204 13.1959 0.857107 11.8393 1.8573 10.8391C2.8575 9.83892 4.21405 9.27702 5.62854 9.27702C7.04303 9.27702 8.39958 9.83892 9.39977 10.8391C10.4 11.8393 10.9619 13.1959 10.9619 14.6104H9.62854C9.62854 13.5495 9.20711 12.5321 8.45696 11.7819C7.70682 11.0318 6.6894 10.6104 5.62854 10.6104C4.56767 10.6104 3.55026 11.0318 2.80011 11.7819C2.04996 12.5321 1.62854 13.5495 1.62854 14.6104H0.295204ZM5.62854 8.61035C3.41854 8.61035 1.62854 6.82035 1.62854 4.61035C1.62854 2.40035 3.41854 0.610352 5.62854 0.610352C7.83854 0.610352 9.62854 2.40035 9.62854 4.61035C9.62854 6.82035 7.83854 8.61035 5.62854 8.61035ZM5.62854 7.27702C7.10187 7.27702 8.2952 6.08368 8.2952 4.61035C8.2952 3.13702 7.10187 1.94368 5.62854 1.94368C4.1552 1.94368 2.96187 3.13702 2.96187 4.61035C2.96187 6.08368 4.1552 7.27702 5.62854 7.27702Z" fill="#BDBDBD"/>
</svg>
)
}

export const mailSvg = () => {
return (
    <svg width="15" height="13" style={{zIndex:"99"}} className="mailLogo" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.62854 0.943359H13.6285C13.8054 0.943359 13.9749 1.0136 14.0999 1.13862C14.225 1.26365 14.2952 1.43322 14.2952 1.61003V12.2767C14.2952 12.4535 14.225 12.6231 14.0999 12.7481C13.9749 12.8731 13.8054 12.9434 13.6285 12.9434H1.62854C1.45173 12.9434 1.28216 12.8731 1.15714 12.7481C1.03211 12.6231 0.961876 12.4535 0.961876 12.2767V1.61003C0.961876 1.43322 1.03211 1.26365 1.15714 1.13862C1.28216 1.0136 1.45173 0.943359 1.62854 0.943359ZM12.9619 3.76869L7.67654 8.50203L2.29521 3.75403V11.61H12.9619V3.76869ZM2.63588 2.27669L7.66921 6.71803L12.6299 2.27669H2.63588Z" fill="#BDBDBD"/>
</svg>
)
}

export const phoneSvg = () => {
return (
<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.87254 6.06469C5.4981 7.16368 6.40822 8.0738 7.50721 8.69936L8.09654 7.87403C8.19131 7.74132 8.33144 7.64795 8.49041 7.61158C8.64938 7.57522 8.81616 7.59838 8.95921 7.67669C9.90209 8.19198 10.9433 8.50188 12.0145 8.58603C12.1817 8.59928 12.3378 8.67505 12.4516 8.79824C12.5654 8.92143 12.6286 9.08298 12.6285 9.25069V12.2254C12.6286 12.3904 12.5674 12.5496 12.4568 12.6722C12.3462 12.7947 12.1941 12.8719 12.0299 12.8887C11.6765 12.9254 11.3205 12.9434 10.9619 12.9434C5.25521 12.9434 0.62854 8.31669 0.62854 2.61003C0.62854 2.25136 0.64654 1.89536 0.683207 1.54203C0.700043 1.37782 0.777196 1.22571 0.899733 1.11512C1.02227 1.00452 1.18148 0.943324 1.34654 0.943359H4.32121C4.48891 0.943338 4.65047 1.00653 4.77366 1.12033C4.89685 1.23413 4.97262 1.39018 4.98587 1.55736C5.07002 2.62855 5.37992 3.66981 5.89521 4.61269C5.97352 4.75574 5.99668 4.92252 5.96032 5.08149C5.92395 5.24046 5.83058 5.38059 5.69787 5.47536L4.87254 6.06469ZM3.19121 5.62669L4.45787 4.72203C4.0984 3.94609 3.85211 3.12259 3.72654 2.27669H1.96854C1.96454 2.38736 1.96254 2.49869 1.96254 2.61003C1.96187 7.58069 5.99121 11.61 10.9619 11.61C11.0732 11.61 11.1845 11.608 11.2952 11.6034V9.84536C10.4493 9.71979 9.62581 9.4735 8.84987 9.11403L7.94521 10.3807C7.58098 10.2392 7.22721 10.0721 6.88654 9.88069L6.84787 9.85869C5.54026 9.11451 4.45739 8.03164 3.71321 6.72403L3.69121 6.68536C3.49981 6.34469 3.33273 5.99092 3.19121 5.62669Z" fill="#BDBDBD"/>
</svg>
)
}
