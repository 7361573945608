const getInputObject = (queryType) => {
  const input = {}
  switch (queryType) {
    case 'Link Sent':
      input.sendSessionLink = true
      break
    case 'Start Class':
      input.sessionStatus = 'started'
      break
    case 'End Class':
      input.sessionStatus = 'completed'
      break
    case 'Upload Link':
      input.sessionRecordingLink = ''
      break
    default:
      break
  }
  return input
}

export default getInputObject
