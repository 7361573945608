import styled from 'styled-components'
import { Button, Modal, Checkbox } from 'antd'
import { TekieAmethyst } from '../../../../constants/colors'

const ModalContainer = styled(Modal)`
  &&& {
    width: 500px;
    @media screen and (max-width: 500px) {
      width: 350px;
    }
    .ant-modal-content {
      border-radius: 16px;
    }
    .ant-modal-body {
      padding: 0
    }
  }
`

const ModalTopContainer = styled.div`
  width: 100%;
  min-height: 72px;
  background: #F3EFFA;
  display: flex;
  align-items: center;
  border-radius: 16px;
  > span {
    width: 24px;
    height: 24px;
    margin: 0 30px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    @media screen and (max-width: 500px) {
      margin: 0 15px;
    }
  }
  > h4 {
    margin: 0;
    color: #000;
    font-size: 18px;
    line-height: 22px;
    font-family: Inter;
    font-weight: 500;
  }
`

const ModalFiltersContainer = styled.div`
  width: 100%;
  height: 276px;
  display: flex;
`

const ModalFiltersLeft = styled.div`
  background: #F3EFFA;
  > h4 {
    width: 213px;
    padding: 8px 0px 8px 24px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400
    font-family: Inter;
    color: #333333;
    height: 36px;
    margin: 0;
    letter-spacing: 0em;
    cursor: pointer;
  }
`

const ModalFiltersRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 45px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${TekieAmethyst};
    outline: 1px solid ${TekieAmethyst};
    border-radius: 5px;
  }
`

const FilterSelect = styled(Checkbox)`
  &&& {
    margin-bottom: 20px;
    margin-left: 0px;
    width: 100%;
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${TekieAmethyst};
      border-color: ${TekieAmethyst};
    }
  }
`

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 18px;
  @media screen and (max-width: 500px) {
    width: 350px;
  }
`


const ModalClearButton = styled(Button)`
    &&& {
      width: 97px;
      height: 36px;
      color: #504F4F;
      font-size: 12px;
      line-height: 18px;
      font-family: Inter;
      font-weight: 500;
      background: #F3EFFA;
      padding: 7px 16px 7px 12px;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      border: none;
      outline: none;
    }
`

const ModalConfirmButton = styled(Button)`
    &&& {
      color: #FFFFFF;
      border: none;
      outline: none;
      font-size: 12px;
      line-height: 18px;
      font-family: Inter;
      font-weight: 500;
      background: ${TekieAmethyst};
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      padding: 7px 16px 7px 12px;
    }
`


ModalContainer.ModalTopContainer = ModalTopContainer
ModalContainer.ModalFiltersContainer = ModalFiltersContainer
ModalContainer.ModalFiltersLeft = ModalFiltersLeft
ModalContainer.ModalFiltersRight = ModalFiltersRight
ModalContainer.FilterSelect = FilterSelect
ModalContainer.ModalButtonsContainer = ModalButtonsContainer
ModalContainer.ModalClearButton = ModalClearButton
ModalContainer.ModalConfirmButton = ModalConfirmButton

export default ModalContainer
