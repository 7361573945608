import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const eventUpdate = async (input,id) => duck.query({
  query: gql`
  mutation($input: EventUpdate) {
    updateEvent(id: "${id}", input: $input) {
      id
    }
  }
  `,
  variables:{
     input, 
  },
  type: 'updateEvent/update',
  key: 'updateEvent',
  changeExtractedData: (extractedData, originalData) => {
    extractedData.updateEvent=get(originalData,'updateEvent');
    return { ...extractedData }
  }
})


export default eventUpdate
