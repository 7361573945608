import React, { Component } from 'react';
import { UploadMomentMargin, YoutubeLinkInput } from '../../EventCompletion.style';
import UploadTitle from './UploadTitle';

export default class UploadMoment extends Component {
    render() {
        const { toggle, eventMomentUploadLink,switchColor, eventLink } = this.props;
        return (
            <>
                <UploadTitle toggle={toggle} switchColor={switchColor}/>
                {switchColor === 0 ? (<>
                    <div>
                        <UploadMomentMargin >
                            <b>{`Youtube Video Link`}</b>
                        </UploadMomentMargin>
                        <div style={{
                            marginLeft: "45px",
                            width: "47%"
                        }}>
                            <YoutubeLinkInput value={eventLink} placeholder="Insert the link after uploading it in youtube here" onChange={(e) => { eventMomentUploadLink(e.target.value) }}></YoutubeLinkInput>
                        </div>
                    </div>
                </>) : null}


            </>
        )
    }
}
