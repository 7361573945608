import React, { Component } from 'react'
import { Pagination } from 'antd'
import { get } from 'lodash'
import { Link } from 'react-router-dom'
import DownloadReport from './components/DownloadRatings'
import fetchMentorRatings from '../../actions/mentorRating/fetchMentorRating'
import fetchMentorsList from '../../actions/mentorReport/fetchMentorsList'
import getDataFromLocalStorage from '../../utils/extract-from-localStorage'
import { UMS_ADMIN, UMS_VIEWER, ADMIN, SALES_EXECUTIVE, SENSEI } from '../../constants/roles'
import MentorRatingStyle from './MentorRating.style'
import SearchInput from '../MentorReport/components/SearchInput'
import getOverallRating from './utils'

class MentorRating extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchKey: 'All',
      searchName: '',
      searchEmail: '',
      searchPhone: '',
      perPage: 20,
      currentPage: 1,
      mentorArray: [],
      mentorNames: [],
      mentorEmails: [],
      mentorPhone: [],
      filterQuery: '',
      totalCount: 0,
      columns: [],
      filterDropdownOptions: [
        'Search By',
        'All',
        'Mentor\'s name',
        'Mentor\'s email',
        'Mentor\'s number',
      ],
    }
  }

  searchByFilter = (key) => {
    const {
      searchKey,
      searchName,
      searchEmail,
      searchPhone,
      savedRole } = this.state
    if (savedRole &&
      (savedRole === ADMIN || savedRole === UMS_ADMIN || savedRole === UMS_VIEWER ||
        savedRole === SALES_EXECUTIVE || savedRole === SENSEI)) {
      let filteredQuery = ''
      if (searchKey === 'All') {
        filteredQuery = ''
      }
      if (searchName !== '' && key === 'name') {
        filteredQuery = `{user_some:{id_in:[${JSON.stringify(searchName)}]}}`
      }
      if (searchEmail !== '' && key === 'email') {
        filteredQuery = `{user_some:{id_in:[${JSON.stringify(searchEmail)}]}}`
      }
      if (searchPhone !== '' && key === 'number') {
        filteredQuery = `{user_some:{id_in:[${JSON.stringify(searchPhone)}]}}`
      }
      this.setState({
        filterQuery: filteredQuery
      }, () => fetchMentorRatings(this.state.totalCount, 0,
        this.state.filterQuery))
    }
  }

  componentDidMount() {
    const saveRole = getDataFromLocalStorage('login.role')
    if (saveRole &&
      (saveRole === ADMIN || saveRole === UMS_ADMIN || saveRole === UMS_VIEWER ||
        saveRole === SALES_EXECUTIVE || saveRole === SENSEI)) {
      this.setState({
        savedRole: saveRole,
      })

      fetchMentorRatings(this.state.perPage, 0, this.state.filterQuery).then(res => {
        this.setState({
          totalCount: res.mentorProfilesMeta.count,
        })
      })

      fetchMentorsList().then(res =>
        this.setState({ mentorArray: res.users }, () => {
          const mentorNames = [...new Set(this.state.mentorArray.map(({ id, name }) => (
            {
              id,
              name
            }
          )))]
          const mentorEmails = [...new Set(this.state.mentorArray.map(({ id, email }) => (
            {
              id,
              name: email
            }
          )))]
          const mentorPhone = [...new Set(this.state.mentorArray.map(({ id, phone }) => (
            {
              id,
              name: phone.countryCode + phone.number
            }
          )
          ))]
          this.setState({
            mentorNames,
            mentorEmails,
            mentorPhone
          })
        }))
    }
  }

  onPageChange = (page) => {
    this.setState({
      currentPage: page
    }, () => fetchMentorRatings(this.state.perPage, this.state.currentPage - 1,
      this.state.filterQuery))
  }

  createTableFromData = () => {
    const { savedRole } = this.state
    if (savedRole &&
      (savedRole === ADMIN || savedRole === UMS_ADMIN || savedRole === UMS_VIEWER ||
        savedRole === SALES_EXECUTIVE || savedRole === SENSEI)) {
      const mentorRatings = get(this.props, 'mentorRatings').toJS().mentorProfiles
      const ratingsArray = []
      mentorRatings.forEach((element) => {
        ratingsArray.push({
          id: get(element, 'user.id', ''),
          name: get(element, 'user.name', '-'),
          rating5: get(element, 'pythonCourseRating5', 0) || 0,
          rating4: get(element, 'pythonCourseRating4', 0) || 0,
          rating3: get(element, 'pythonCourseRating3', 0) || 0,
          rating2: get(element, 'pythonCourseRating2', 0) || 0,
          rating1: get(element, 'pythonCourseRating1', 0) || 0,
          overall: getOverallRating(element)
        })
      })
      this.setState({
        tableData: ratingsArray
      }, () => this.setTableHeader())
    }
  }

  componentDidUpdate = (prevProps) => {
    const { savedRole } = this.state
    const { isRatingsFetching, hasRatingsFetched } =
    this.props
    if (savedRole === ADMIN || savedRole === UMS_ADMIN || savedRole === UMS_VIEWER ||
      savedRole === SALES_EXECUTIVE || savedRole === SENSEI) {
      if (!isRatingsFetching && hasRatingsFetched) {
        if (get(prevProps, 'mentorRatings') !==
        get(this.props, 'mentorRatings')) {
          this.createTableFromData()
        }
      }
    }
  }

  renderColumnTitle = (title, width) => (
    <div style={{ width }}>
      {title}
    </div>
  )
  fetchMentorFilteredData = (key) => {
    const { searchKey } = this.state
    if (searchKey === 'Mentor\'s name' || searchKey === 'Mentor\'s email' ||
      searchKey === 'Mentor\'s number') {
      this.searchByFilter(key)
    }
  }

  handleValueSelect = (value, type) => {
    if (type === 'Mentor\'s name') {
      this.setState({
        searchName: value
      }, () => this.fetchMentorFilteredData('name'))
    }
    if (type === 'Mentor\'s email') {
      this.setState({
        searchEmail: value
      }, () => this.fetchMentorFilteredData('email'))
    }
    if (type === 'Mentor\'s number') {
      this.setState({
        searchPhone: value
      }, () => this.fetchMentorFilteredData('number'))
    }
  }

  renderSearchInputs = () => {
    const {
      searchKey,
      mentorNames,
      mentorPhone,
      mentorEmails,
    } = this.state
    if (searchKey === 'Mentor\'s name') {
      return (
        <SearchInput
          datasArray={mentorNames}
          handleValueSelect={(value) => this.handleValueSelect(value, searchKey)}
          searchByFilter={() => this.fetchMentorFilteredData('name')}
          placeholder='Search by Mentor name'
          onChange={(value) => this.setState({
            searchName: value
          })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.fetchMentorFilteredData('name')
            }
          }}
        />
      )
    } else if (searchKey === 'Mentor\'s email') {
      return (
        <SearchInput
          datasArray={mentorEmails}
          handleValueSelect={(value) => this.handleValueSelect(value, searchKey)}
          searchByFilter={() => this.fetchMentorFilteredData('email')}
          placeholder='Search by Mentor Email'
          onChange={(value) => this.setState({
            searchEmail: value
          })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.fetchMentorFilteredData('email')
            }
          }}
        />
      )
    } else if (searchKey === 'Mentor\'s number') {
      return (
        <SearchInput
          datasArray={mentorPhone}
          handleValueSelect={(value) => this.handleValueSelect(value, searchKey)}
          searchByFilter={() => this.fetchMentorFilteredData('number')}
          placeholder='Search by Mentor number'
          onChange={(value) => this.setState({
            searchPhone: value
          })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.fetchMentorFilteredData('number')
            }
          }}
        />
      )
    }
    return null
  }

  renderColumnData = (name, bgColor) => (
    {
      props: {
        style: {
          background: bgColor
        },
      },
      children: name === 0 ? '-' : name
    }
  )

  setMentorLink = (name, code) =>
      <Link rel='noopener noreferrer' to={`/ums/mentorRating/${code}`}>
        {name}
      </Link >

  setTableHeader = () => {
    const { savedRole } = this.state
    let mentorRatings = []
    if (savedRole === ADMIN || savedRole === UMS_ADMIN || savedRole === UMS_VIEWER ||
      savedRole === SALES_EXECUTIVE || savedRole === SENSEI) {
      mentorRatings = get(this.props, 'mentorRatings').toJS().mentorProfiles
    }
    let columns = []
    if (mentorRatings.length > 0) {
      columns = [
        {
          title: () => this.renderColumnTitle('Mentor', '100px'),
          dataIndex: 'name',
          key: 'name',
          width: 150,
          align: 'center',
          render: (name, record) => this.setMentorLink(name, record.id)
        },
        {
          title: () => this.renderColumnTitle('5 star', '100px'),
          dataIndex: 'rating5',
          key: 'rating5',
          width: 150,
          align: 'center',
          render: (rating5) => this.renderColumnData(rating5)
        },
        {
          title: () => this.renderColumnTitle('4 Star', '100px'),
          dataIndex: 'rating4',
          key: 'rating4',
          width: 150,
          align: 'center',
          render: (rating4) => this.renderColumnData(rating4)
        },
        {
          title: () => this.renderColumnTitle('3 Star', '100px'),
          dataIndex: 'rating3',
          key: 'rating3',
          width: 150,
          align: 'center',
          render: (rating3) => this.renderColumnData(rating3)
        },
        {
          title: () => this.renderColumnTitle('2 Star', '100px'),
          dataIndex: 'rating2',
          key: 'rating2',
          width: 150,
          align: 'center',
          render: (rating2) => this.renderColumnData(rating2)
        },
        {
          title: () => this.renderColumnTitle('1 Star', '100px'),
          dataIndex: 'rating1',
          key: 'rating1',
          width: 150,
          align: 'center',
          render: (rating1) => this.renderColumnData(rating1)
        },
        {
          title: () => this.renderColumnTitle('Overall Rating', '100px'),
          dataIndex: 'overall',
          key: 'overall',
          width: 150,
          align: 'center',
          render: (overall) => this.renderColumnData(overall)
        },
      ]
    }
    this.setState(({
      columns
    }))
  }

  renderPagination = () => {
    const {
      savedRole,
      perPage,
      currentPage,
      searchKey,
      totalCount,
    } = this.state
    if ((savedRole === UMS_ADMIN || savedRole === UMS_VIEWER || savedRole === ADMIN ||
      savedRole === SALES_EXECUTIVE || savedRole === SENSEI) &&
    (searchKey !== 'Mentor\'s name' &&
    searchKey !== 'Mentor\'s email' && searchKey !== 'Mentor\'s number')) {
      return (
        <MentorRatingStyle.PaginationHolder>
          {totalCount > perPage && (
            <Pagination
              total={totalCount}
              onChange={this.onPageChange}
              current={currentPage}
              pageSize={perPage}
            />
          )}
          <div style={{ marginLeft: 'auto' }} >
            <MentorRatingStyle.Select
              value={perPage}
              onChange={(value) => this.setState({
                currentPage: 1,
                perPage: value
              }, () => fetchMentorRatings(this.state.perPage, this.state.currentPage - 1,
                this.state.filterQuery))}
            >
              {
                [10, 20, 50, 100].map((option) =>
                  <MentorRatingStyle.Option
                    key={option}
                    value={option}
                  >{option}
                  </MentorRatingStyle.Option>
                )
              }
            </MentorRatingStyle.Select>
          </div>
        </MentorRatingStyle.PaginationHolder>
      )
    } return null
  }

  render() {
    const {
      searchKey,
      perPage,
      savedRole,
    } = this.state
    return (
    <>
    <MentorRatingStyle.TopContainer>
      {(savedRole &&
      (savedRole === ADMIN || savedRole === UMS_ADMIN || savedRole === UMS_VIEWER ||
        savedRole === SALES_EXECUTIVE || savedRole === SENSEI
        )) && (
        <div
          style={{
            marginRight: '20px',
            minWidth: '438px',
            display: 'flex',
            alignItems: 'flex-start'
          }}
        >
          <MentorRatingStyle.Select
            value={searchKey}
            onChange={(value) => this.setState({
              searchKey: value,
            }, () => {
              if (this.state.searchKey === 'All') {
                this.setState({
                  searchEmail: '',
                  searchName: '',
                  searchPhone: '',
                  currentPage: 1
                }, () => {
                  this.setState({
                    filterQuery: ''
                  })
                 return fetchMentorRatings(perPage, this.state.currentPage - 1, '')
                })
              }
            })}
          >
            {
              this.state.filterDropdownOptions.map((option) =>
                <MentorRatingStyle.Option
                  key={option}
                  value={option}
                >{option}
                </MentorRatingStyle.Option>
              )
            }
          </MentorRatingStyle.Select>
          {this.renderSearchInputs()}
        </div>
      )}
        {this.renderPagination()}
    </MentorRatingStyle.TopContainer>
    <DownloadReport
      perPage={this.state.perPage}
      currentPage={this.state.currentPage - 1}
      query={this.state.filterQuery}
    />
    <MentorRatingStyle.MDTable
      dataSource={this.state.tableData}
      columns={this.state.columns}
      loading={
      this.props.isRatingsFetching && this.props.isRatingsFetching
    }
      scroll={{ x: 'max-content', y: 580 }}
      pagination={false}
    />
    </>
    )
  }
}

export default MentorRating
