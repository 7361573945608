const constraints = {
  topics: {
    maxInChapter: 10,
  },
  learningObjectives: {
    maxOrder: 6
  }
}

export default constraints
