import gql from 'graphql-tag'
import duck from '../../duck'
import getIdArrForQuery from '../../utils/getIdArrForQuery'

const DeleteUser = async (id) => duck.query({
  query: gql`
  mutation{
    deleteUser(
      id:"${id}"
    ){
        id
    }
  }
  `,
  type: 'users/delete',
  key: 'users',
})

export default DeleteUser


