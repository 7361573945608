import gql from 'graphql-tag'
import duck from '../../duck'
import getIdArrForQuery from '../../utils/getIdArrForQuery'


const addBatch = async (input, courseConnectId, allottedMentorConnectId, coursePackageConnectId, schoolConnectId, batchStudentsConnectIds, classesConnectIds, inheritedFromConnectId, coursePackageCoursesConnectIds) =>
  duck.query({
    query: gql`
      mutation($input: BatchInput!) {
        addBatch(
          input: $input
          ${courseConnectId ? `courseConnectId: "${courseConnectId}"` : ''}
          ${coursePackageConnectId ? `coursePackageConnectId: "${coursePackageConnectId}"` : ''}
          allottedMentorConnectId: "${allottedMentorConnectId}"
          ${schoolConnectId ? `schoolConnectId: "${schoolConnectId}"` : ''}
          ${(batchStudentsConnectIds && batchStudentsConnectIds.length) ? `batchStudentsConnectIds: [${getIdArrForQuery(batchStudentsConnectIds)}]` : ''}
          ${(classesConnectIds && classesConnectIds.length) ? `classesConnectIds: [${getIdArrForQuery(classesConnectIds)}]` : ''}
          ${inheritedFromConnectId ? `inheritedFromConnectId: "${inheritedFromConnectId}"` : ''}
          ${(coursePackageCoursesConnectIds && coursePackageCoursesConnectIds.length) ? `coursePackageCoursesConnectIds: [${getIdArrForQuery(coursePackageCoursesConnectIds)}]` : ''}
        ) {
          id
          isTeacherTraining
          coursePackage {
            id
            title
          }
          course {
            createdAt
            updatedAt
          }
          code
          type
          school {
            id
            name
          }
          description
          studentsMeta {
            count
          }
          allottedMentor {
            name
          }
          currentComponent {
            currentTopic {
              title
              order
            }
          }
        }
      }
    `,
    variables: {
      input,
      callBatchAPI: true
    },
    type: 'batch/add',
    key: 'batches',
  })

export default addBatch
