import React, { useEffect, useState } from "react";
import { Modal, Input, Button,message } from "antd";
import { useParams } from "react-router-dom";

import addLab from "../../../../actions/SchoolOnboarding/addLab";
import Stack from "../../../../components/Stack";
import Label from "../../../../components/Label";
import fetchLabs from "../../../../actions/SchoolOnboarding/fetchLabs";

const AddLabModal = ({ open, handleClose,labs }) => {
	const params = useParams();
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [addLabLoading, setAddLabLoading] = useState(false);
	
	const handleAddLab = async () => {
		if(checkIfNameExists(name)) return message.error("Lab Name already exists")
		if (!params.schoolId) return;
		if (!name || !description) return;
		// check empty string
		if (name.trim() === "" || description.trim() === "") return;
		setAddLabLoading(true);
		await addLab({ labName: name, labNo:parseInt(labNo), description }, params.schoolId);
		setAddLabLoading(false);
		handleClose();
		await fetchLabs(params.schoolId);
	};
	const checkIfNameExists = (name) => {
		const lab = labs.find((lab) => 
			(lab.labName && (lab.labName.trim().toLowerCase() === name.trim().toLowerCase()))
		);
		if (lab) return true;
		return false;
	}
	const labNo = labs.length > 0 ? Math.max(...labs.map((lab) => lab.labNo)) + 1 : 1;

	return (
		<Modal
			title="Add Lab"
			visible={open}
			onCancel={handleClose}
			centered
			footer={null}
		>
			<Stack gap={20}>
				<Stack gap={11}>
					<Label htmlFor="lab-name">Lab Name</Label>
					<Input
						id="lab-name"
						placeholder="Lab Name"
						size="large"
						value={name}
						onChange={(e) => {
							setName(e.target.value);
						}}
					/>
					{checkIfNameExists(name) && (
						<div className="error">Lab name already exists</div>
					)}
				</Stack>
				
				<Stack gap={11}>
					<Label htmlFor="labno">Lab No</Label>
					<Input
						disabled={true}
						id="lab-no"
						placeholder="Lab No"
						size="large"
						value={labNo}
						onChange={(e) => setLabNo(e.target.value)}
					/>
				</Stack>

				<Stack gap={11}>
					<Label htmlFor="lab-description">Lab Description</Label>
					<Input
						id="lab-description"
						placeholder="Lab Description"
						size="large"
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
				</Stack>
				<Button
					type="primary"
					block
					size="large"
					onClick={handleAddLab}
					loading={addLabLoading}
				>
					Add
				</Button>
			</Stack>
		</Modal>
	);
};

export default AddLabModal;
