const headerConfig = [
  {
    label: 'Student name',
    key: 'studentName',
  },
  {
    label: 'Parent name',
    key: 'parentName',
  },
  {
    label: 'Phone No.',
    key: 'phone',
  },
  {
    label: 'NPS Score',
    key: 'score',
  },
  {
    label: 'Mentor',
    key: 'mentor',
  },
  {
    label: 'Rating',
    key: 'rating',
  },
  {
    label: 'Created At',
    key: 'createdAt',
  },
]

export default headerConfig
