import gql from 'graphql-tag'
import { get } from 'lodash';
import duck from '../../duck'

const deleteCoursePackages = async (id) =>
  duck.query({
    query: gql`
    mutation {
        deleteCoursePackage(id:"${id}") {
          id
        }
      }
    `,
    type: 'coursePackages/delete',
    key: 'coursePackages',
  })

export default deleteCoursePackages


