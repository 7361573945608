import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../../duck'

const deleteBatchSession = async (batchSessionId) => duck.query({
  query: gql`
    mutation {
      deleteBatchSession(id: "${batchSessionId}") {
        id
      }
    }
  `,
  type: 'mentorMenteeSessionsForDemand/delete',
  key: 'mentorMenteeSessionsForDemand',
  changeExtractedData: (extractedData, originalData) => {
    extractedData.batchSessions = {
      ...get(originalData, 'deleteBatchSession')
    }
    return { ...extractedData }
  },
})

export default deleteBatchSession

