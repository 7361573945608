import React,{useState,useEffect} from 'react'
import { get} from 'lodash'
import getFullPath from '../../../../../../../../utils/getFullPath'
import { ComponentHolder,IconHolder,VideoHolder,
  DataHolder,StyledVideoTag,ButtonHolder,StyledLabel,StyledInput,UploadDiv,ReUploadDiv,MessageText,VideoHolderUpload } from './Video.style'
import {Add} from '../../../../../../../../constants/icons'


const Video =(props)=> {
  const[showMsg,setShowMsg]=useState(false)
  const[url,setUrl]=useState('')
  const handleUpload=(event)=>{
    const fileObj=event.target.files[0]
    if(get(fileObj,'type','')) {
      const exp=fileObj.type
      const ans=exp.split('/')[0]
      if(ans==='video') {
        const file=event.target.files
        const objectURL = URL.createObjectURL(file[0])
        setUrl(objectURL)
        if(props.learningSlide) {
          props.Helper(file,'VideoData','',props.slideContents,props.number)
        }
        setShowMsg(false)
      } else {
        setShowMsg(true)
      }
    }
  }
  const handleReUpload=(event)=>{
    const fileObj=event.target.files[0]
    if(get(fileObj,'type','')) {
      const exp=fileObj.type
      const ans=exp.split('/')[0]
      if(ans==='video') {
        const file=event.target.files
        const objectURL = URL.createObjectURL(file[0])
        setUrl(objectURL)
        if(props.learningSlide) {
          props.Helper(file,'VideoData','',props.slideContents,props.number)
        }
        setShowMsg(false)
      } else {
        setShowMsg(true)
      }
    }
  }
  useEffect(()=>{
    if(get(props.selectedContent,'VideoData',[])) {
    if(get(props.selectedContent,'VideoData',[]).length>0) {
      const fileObj=get(props.selectedContent,'VideoData',[])[0]
      const exp=fileObj.type
      const ans=exp.split('/')[0]
      if(ans==='video') {
        const file=get(props.selectedContent,'VideoData',[])
        const objectURL = URL.createObjectURL(file[0])
        setUrl(objectURL)
      }
    }
    }
  },[props.slideContents])
    return <ComponentHolder>
        { get(props.selectedContent,'media') === null ? <DataHolder>
          <VideoHolderUpload>
            {url!==''?<StyledVideoTag controls autoplay muted>
              <source src={url} type="video/ogg"/>
            </StyledVideoTag>:''}
          </VideoHolderUpload>
          <ButtonHolder>
             <ReUploadDiv>
                <StyledLabel key={props.key} htmlFor={props.number}>
                  <IconHolder>
                    <Add/>
                  </IconHolder>
                  Add Video
                  <StyledInput type="file" id={props.number} accept="video/*" onChange={(e)=>{handleUpload(e)}}/>
              </StyledLabel>
             </ReUploadDiv>
             {showMsg === true ?<MessageText>Please Select The Correct Video</MessageText>:""}
          </ButtonHolder>
        </DataHolder>
        :<DataHolder>
          <VideoHolder>
          {url ? <StyledVideoTag controls autoplay muted>
              <source src={url} type="video/ogg"/>
            </StyledVideoTag>:null}
          {!url?<StyledVideoTag controls autoplay muted>
          <source src={getFullPath(get(props.selectedContent.media,'uri',''))} type="video/ogg"/>
          </StyledVideoTag>:null}  
          </VideoHolder>         
          <ButtonHolder>
            <ReUploadDiv>
              <StyledLabel key={props.key} htmlFor={props.number}>
              <IconHolder>
                <Add/>
              </IconHolder>
              Re-upload New Video
              <StyledInput type="file" id={props.number} accept="video/*" onChange={(e)=>{handleReUpload(e)}}/>
              </StyledLabel>
            </ReUploadDiv>
            {showMsg === true ?<MessageText>Please Select The Correct Video</MessageText>:""}
          </ButtonHolder>
      </DataHolder>
      }
    </ComponentHolder>
}
export default Video;