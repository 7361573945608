/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
import { chain, get, sumBy } from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import { Radio } from 'antd'
import 'antd/dist/antd.css'
import CanvasJSReact from '../../assets/canvasjs.react'
import SalesOperationReportStyle from './GradeOperationReport.style'
import { lastNdates } from '../../utils/date/date-format'
import { createColumn } from './GradeOperationReport-utils'
import grades from '../../constants/gradeConstants'

const { CanvasJSChart } = CanvasJSReact
class GradeOperationReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      registrationTableData: [],
      completedUsersTableData: [],
      conversionTableData: [],
      registeredUsersColumn: [],
      convertedUsersColumn: [],
      completedUsersColumn: [],
      bookedUsersTableData: [],
      registeredUsers: 'Table',
      convertedUsers: 'Table',
      completedUsers: 'Table',
      bookedUsers: 'Table'
    }
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      if (this.state.bookedUsers === 'Table') {
        this.createBookedUsersTable()
      }
      if (this.state.registeredUsers === 'Table') {
        this.createRegisteredUsersTable()
      }
      if (this.state.convertedUsers === 'Table') {
        this.createConversionUsersTable()
      }
      if (this.state.completedUsers === 'Table') {
        this.createCompletedUsersTable()
      }
    }
  }
  mergeRegisteredBasedOnDate = (data) => {
    const result = chain(data)
      .groupBy('date')
      .map((objects, date) => {
        const obj = {}
        obj.date = date
        obj.grade1 = sumBy(objects, 'grade1.registered') || 0
        obj.grade2 = sumBy(objects, 'grade2.registered') || 0
        obj.grade3 = sumBy(objects, 'grade3.registered') || 0
        obj.grade4 = sumBy(objects, 'grade4.registered') || 0
        obj.grade5 = sumBy(objects, 'grade5.registered') || 0
        obj.grade6 = sumBy(objects, 'grade6.registered') || 0
        obj.grade7 = sumBy(objects, 'grade7.registered') || 0
        obj.grade8 = sumBy(objects, 'grade8.registered') || 0
        obj.grade9 = sumBy(objects, 'grade9.registered') || 0
        obj.grade10 = sumBy(objects, 'grade10.registered') || 0
        obj.grade11 = sumBy(objects, 'grade11.registered') || 0
        obj.grade12 = sumBy(objects, 'grade12.registered') || 0
        return obj
      })
      .value()
    return result
  }
  mergeBookedBasedOnDate = (data) => {
    const result = chain(data)
      .groupBy('date')
      .map((objects, date) => {
        const obj = {}
        obj.date = date
        obj.grade1 = sumBy(objects, 'grade1.booked') || 0
        obj.grade2 = sumBy(objects, 'grade2.booked') || 0
        obj.grade3 = sumBy(objects, 'grade3.booked') || 0
        obj.grade4 = sumBy(objects, 'grade4.booked') || 0
        obj.grade5 = sumBy(objects, 'grade5.booked') || 0
        obj.grade6 = sumBy(objects, 'grade6.booked') || 0
        obj.grade7 = sumBy(objects, 'grade7.booked') || 0
        obj.grade8 = sumBy(objects, 'grade8.booked') || 0
        obj.grade9 = sumBy(objects, 'grade9.booked') || 0
        obj.grade10 = sumBy(objects, 'grade10.booked') || 0
        obj.grade11 = sumBy(objects, 'grade11.booked') || 0
        obj.grade12 = sumBy(objects, 'grade12.booked') || 0
        return obj
      })
      .value()
    return result
  }
  mergeCompletedBasedOnDate = (data) => {
    const result = chain(data)
      .groupBy('date')
      .map((objects, date) => {
        const obj = {}
        obj.date = date
        obj.grade1 = sumBy(objects, 'grade1.demoCompleted') || 0
        obj.grade2 = sumBy(objects, 'grade2.demoCompleted') || 0
        obj.grade3 = sumBy(objects, 'grade3.demoCompleted') || 0
        obj.grade4 = sumBy(objects, 'grade4.demoCompleted') || 0
        obj.grade5 = sumBy(objects, 'grade5.demoCompleted') || 0
        obj.grade6 = sumBy(objects, 'grade6.demoCompleted') || 0
        obj.grade7 = sumBy(objects, 'grade7.demoCompleted') || 0
        obj.grade8 = sumBy(objects, 'grade8.demoCompleted') || 0
        obj.grade9 = sumBy(objects, 'grade9.demoCompleted') || 0
        obj.grade10 = sumBy(objects, 'grade10.demoCompleted') || 0
        obj.grade11 = sumBy(objects, 'grade11.demoCompleted') || 0
        obj.grade12 = sumBy(objects, 'grade12.demoCompleted') || 0
        return obj
      })
      .value()
    return result
  }
  mergeConversionBasedOnDate = (data) => {
    const result = chain(data)
      .groupBy('date')
      .map((objects, date) => {
        const obj = {}
        obj.date = date
        obj.grade1 = sumBy(objects, 'grade1.converted') || 0
        obj.grade2 = sumBy(objects, 'grade2.converted') || 0
        obj.grade3 = sumBy(objects, 'grade3.converted') || 0
        obj.grade4 = sumBy(objects, 'grade4.converted') || 0
        obj.grade5 = sumBy(objects, 'grade5.converted') || 0
        obj.grade6 = sumBy(objects, 'grade6.converted') || 0
        obj.grade7 = sumBy(objects, 'grade7.converted') || 0
        obj.grade8 = sumBy(objects, 'grade8.converted') || 0
        obj.grade9 = sumBy(objects, 'grade9.converted') || 0
        obj.grade10 = sumBy(objects, 'grade10.converted') || 0
        obj.grade11 = sumBy(objects, 'grade11.converted') || 0
        obj.grade12 = sumBy(objects, 'grade12.converted') || 0
        return obj
      })
      .value()
    return result
  }
  // Registered
  createRegisteredUsersTable = () => {
    const data = this.props.sessionGradeReports
    const { dateDiff } = this.props
    const registrationTableData = grades.map((str, index) => {
      const obj = {
        key: `${index}`,
        srNo: `${index + 1}`,
        Grade: grades[index],
      }
      const mergedDataBasedOnDate = this.mergeRegisteredBasedOnDate(data)
      mergedDataBasedOnDate.forEach(element => {
        obj[`${moment(get(element, 'date')).format('DD MMM YYYY')}`] = get(element, str.toLocaleLowerCase()) || 0
      })
      lastNdates(dateDiff).forEach(date => {
        if (!obj[date]) {
          obj[date] = 0
        }
      })
      return obj
    })
    this.setState(
      {
        registrationTableData,
      },
      () => this.setRegisteredUsersTableHeader()
    )
  }
  setRegisteredUsersTableHeader = () => {
    const data = this.props.sessionGradeReports
    const { dateDiff } = this.props
    let registeredUsersColumn = []
    if (data.length > 0) {
      registeredUsersColumn = createColumn('Grade')
      lastNdates(dateDiff).forEach((date) => {
        registeredUsersColumn.push({
          title: date,
          dataIndex: date,
          key: date,
          align: 'center',
          width: dateDiff > 7 ? 100 : null,
        })
      })
    }
    this.setState({
      registeredUsersColumn,
    })
  }
  // Complete Registered
  // Conversion Start
  createConversionUsersTable = () => {
    const data = this.props.sessionGradeReports
    const { dateDiff } = this.props
    const conversionTableData = grades.map((str, index) => {
      const obj = {
        key: `${index}`,
        srNo: `${index + 1}`,
        Grade: grades[index],
      }
      const mergedDataBasedOnDate = this.mergeConversionBasedOnDate(data)
      mergedDataBasedOnDate.forEach(element => {
        obj[`${moment(get(element, 'date')).format('DD MMM YYYY')}`] = get(element, str.toLocaleLowerCase(), 0)
      })
      lastNdates(dateDiff).forEach(date => {
        if (!obj[date]) {
          obj[date] = 0
        }
      })
      return obj
    })
    this.setState(
      {
        conversionTableData,
      },
      () => this.setConversionUsersTableHeader()
    )
  }
  setConversionUsersTableHeader = () => {
    const data = this.props.sessionGradeReports
    const { dateDiff } = this.props
    let convertedUsersColumn = []
    if (data.length > 0) {
      convertedUsersColumn = createColumn('Grade')
      lastNdates(dateDiff).forEach((date) => {
        convertedUsersColumn.push({
          title: date,
          dataIndex: date,
          key: date,
          align: 'center',
          width: dateDiff > 7 ? 100 : null,
        })
      })
    }
    this.setState({
      convertedUsersColumn,
    })
  }
  // Conversion End
  // Completed Start
  createCompletedUsersTable = () => {
    const data = this.props.sessionGradeReports
    const { dateDiff } = this.props
    const completedUsersTableData = grades.map((str, index) => {
      const obj = {
        key: `${index}`,
        srNo: `${index + 1}`,
        Grade: grades[index],
      }
      const mergedDataBasedOnDate = this.mergeCompletedBasedOnDate(data)
      mergedDataBasedOnDate.forEach((element) => {
        obj[`${moment(get(element, 'date')).format('DD MMM YYYY')}`] = get(element, str.toLocaleLowerCase()) || 0
      })
      lastNdates(dateDiff).forEach((date) => {
        if (!obj[date]) {
          obj[date] = 0
        }
      })
      return obj
    })
    this.setState(
      {
        completedUsersTableData,
      },
      () => this.setTableHeader()
    )
  }
  createBookedUsersTable = () => {
    const data = this.props.sessionGradeReports
    const { dateDiff } = this.props
    const bookedUsersTableData = grades.map((str, index) => {
      const obj = {
        key: `${index}`,
        srNo: `${index + 1}`,
        Grade: grades[index],
      }
      const mergedDataBasedOnDate = this.mergeCompletedBasedOnDate(data)
      mergedDataBasedOnDate.forEach((element) => {
        obj[`${moment(get(element, 'date')).format('DD MMM YYYY')}`] = get(element, str.toLocaleLowerCase()) || 0
      })
      lastNdates(dateDiff).forEach((date) => {
        if (!obj[date]) {
          obj[date] = 0
        }
      })
      return obj
    })
    this.setState(
      {
        bookedUsersTableData,
      },
      () => this.setTableHeader()
    )
  }
  setTableHeader = () => {
    const data = this.props.sessionGradeReports
    const { dateDiff } = this.props
    let completedUsersColumn = []
    if (data.length > 0) {
      completedUsersColumn = createColumn('Grade')
      lastNdates(dateDiff).forEach((date) => {
        completedUsersColumn.push({
          title: date,
          dataIndex: date,
          key: date,
          align: 'center',
          width: dateDiff > 7 ? 100 : null,
        })
      })
    }
    this.setState({
      completedUsersColumn,
    })
  }
  onRegisteredUsersChange = e => {
    this.setState({
      registeredUsers: e.target.value ? e.target.value : this.state.registeredUsers,
    },
    () => {
      if (this.state.registeredUsers === 'Table') {
        this.createRegisteredUsersTable()
      }
    }
    )
  };
  onCompletedUsersChange = e => {
    this.setState({
      completedUsers: e.target.value ? e.target.value : this.state.completedUsers,
    },
    () => {
      if (this.state.completedUsers === 'Table') {
        this.createCompletedUsersTable()
      }
    }
    )
  };
  onConversionUsersChange = e => {
    this.setState({
      convertedUsers: e.target.value ? e.target.value : this.state.convertedUsers,
    },
    () => {
      if (this.state.convertedUsers === 'Table') {
        this.createConversionUsersTable()
      }
    }
    )
  };
  onBookedUsersChange = e => {
    this.setState({
      bookedUsers: e.target.value ? e.target.value : this.state.bookedUsers,
    },
    () => {
      if (this.state.bookedUsers === 'Table') {
        this.createBookedUsersTable()
      }
    }
    )
  };
  render() {
    const { sessionGradeReports, dateDiff } = this.props
    if (sessionGradeReports.length) {
      const regDataPoints = []
      const bookedDataPoints = []
      const demoCompletedDataPoints = []
      const firstSessionBookedDataPoints = []
      const firstSessionCompletedDataPoints = []
      const convertedUsersDataPoint = []

      sessionGradeReports.forEach((obj) => {
        const date = moment(get(obj, 'date')).format('DD-MM-YYYY')
        let registeredUsers = 0
        let bookedUsers = 0
        let demoCompletedUsers = 0
        let bookedSession = 0
        let completedSession = 0
        let convertedUsersData = 0
        grades.map((str) => {
          registeredUsers += get(obj[str.toLowerCase()], 'registered')
          bookedUsers += get(obj[str.toLowerCase()], 'booked')
          demoCompletedUsers += get(obj[str.toLowerCase()], 'demoCompleted')
          bookedSession += get(obj[str.toLowerCase()], 'booked')
          completedSession += get(obj[str.toLowerCase()], 'completed')
          convertedUsersData += get(obj[str.toLowerCase()], 'converted')
        })
        regDataPoints.push({
          y: registeredUsers,
          label: date
        })
        bookedDataPoints.push({
          y: bookedUsers,
          label: date
        })
        demoCompletedDataPoints.push({
          y: demoCompletedUsers,
          label: date
        })
        firstSessionBookedDataPoints.push({
          y: bookedSession,
          label: date
        })
        firstSessionCompletedDataPoints.push({
          y: completedSession,
          label: date
        })
        convertedUsersDataPoint.push({
          y: convertedUsersData,
          label: date
        })
      })
      const options4 = {
        animationEnabled: true,
        backgroundColor: '#F7F7F9',
        title: {
          text: `Conversion Data (${dateDiff === 1 ? `${dateDiff} day` : `${dateDiff} days`})`
        },
        axisY: {
          title: 'Users/Sessions'
        },
        toolTip: {
          shared: true
        },
        data: [
          {
            type: 'spline',
            name: 'Registered',
            showInLegend: true,
            dataPoints: regDataPoints
          },
          {
            type: 'spline',
            name: 'Booked',
            showInLegend: true,
            dataPoints: bookedDataPoints
          },
          {
            type: 'spline',
            name: 'Converted',
            showInLegend: true,
            dataPoints: convertedUsersDataPoint
          },
        ]
      }
      const options3 = {
        animationEnabled: true,
        backgroundColor: '#F7F7F9',
        title: {
          text: `Completed (${dateDiff === 1 ? `${dateDiff} day` : `${dateDiff} days`})`
        },
        axisY: {
          title: 'Users/Sessions'
        },
        toolTip: {
          shared: true
        },
        data: [
          {
            type: 'spline',
            name: 'Registered',
            showInLegend: true,
            dataPoints: regDataPoints
          },
          {
            type: 'spline',
            name: 'Booked',
            showInLegend: true,
            dataPoints: bookedDataPoints
          },
          {
            type: 'spline',
            name: 'Demo Completed',
            showInLegend: true,
            dataPoints: demoCompletedDataPoints
          },
        ]
      }
      const options2 = {
        animationEnabled: true,
        backgroundColor: '#F7F7F9',
        title: {
          text: `Booked Data (${dateDiff === 1 ? `${dateDiff} day` : `${dateDiff} days`})`
        },
        axisY: {
          title: 'Users/Sessions'
        },
        toolTip: {
          shared: true
        },
        data: [
          {
            type: 'spline',
            name: 'Booked',
            showInLegend: true,
            dataPoints: bookedDataPoints
          },
          {
            type: 'spline',
            name: 'Converted Users',
            showInLegend: true,
            dataPoints: convertedUsersDataPoint
          },
        ]
      }
      const options1 = {
        animationEnabled: true,
        backgroundColor: '#F7F7F9',
        title: {
          text: `Registered Users Data (${dateDiff === 1 ? `${dateDiff} day` : `${dateDiff} days`})`
        },
        axisY: {
          title: 'Users/Sessions'
        },
        toolTip: {
          shared: true
        },
        data: [
          {
            type: 'spline',
            name: 'Registration',
            showInLegend: true,
            dataPoints: regDataPoints
          },
          {
            type: 'spline',
            name: 'Trial Session Booked',
            showInLegend: true,
            dataPoints: firstSessionBookedDataPoints
          },
          {
            type: 'spline',
            name: 'Trial Session Completed',
            showInLegend: true,
            dataPoints: firstSessionCompletedDataPoints
          }
        ]
      }

      const { registeredUsers, convertedUsers, bookedUsers, completedUsers } = this.state
      return (
      <div>
        <div style={{ padding: '20px', paddingBottom: '40px' }}>
          <SalesOperationReportStyle.TableDiv>
            <SalesOperationReportStyle.RadioDiv>
              <Radio.Group
                onChange={this.onRegisteredUsersChange}
                value={registeredUsers}
                buttonStyle='solid'
              >
                <Radio.Button value='Table'>Table</Radio.Button>
                <Radio.Button value='Graph'>Graph</Radio.Button>
              </Radio.Group>
            </SalesOperationReportStyle.RadioDiv>
            {this.state.registeredUsers === 'Graph' ? <CanvasJSChart options={options1} /> :
              <>
                <SalesOperationReportStyle.H1>
                  {`Registration [${dateDiff === 1 ? `${dateDiff} day` : `${dateDiff} days`}]`}
                </SalesOperationReportStyle.H1>
                <SalesOperationReportStyle.SalsesOperationReportTable
                  dataSource={this.state.registrationTableData}
                  columns={this.state.registeredUsersColumn}
                  scroll={{ x: 'max-content' }}
                  pagination={false}
                />
              </>}
          </SalesOperationReportStyle.TableDiv>
        </div>
        <div style={{ padding: '20px', paddingBottom: '40px' }}>
          <SalesOperationReportStyle.TableDiv>
            <SalesOperationReportStyle.RadioDiv>
              <Radio.Group
                onChange={this.onBookedUsersChange}
                value={bookedUsers}
                buttonStyle='solid'
              >
                <Radio.Button value='Table'>Table</Radio.Button>
                <Radio.Button value='Graph'>Graph</Radio.Button>
              </Radio.Group>
            </SalesOperationReportStyle.RadioDiv>
            {this.state.bookedUsers === 'Graph' ? <CanvasJSChart options={options2} /> :
              <>
                <SalesOperationReportStyle.H1>
                  {`Booked Session [${dateDiff === 1 ? `${dateDiff} day` : `${dateDiff} days`}]`}
                </SalesOperationReportStyle.H1>
                <SalesOperationReportStyle.SalsesOperationReportTable
                  dataSource={this.state.bookedUsersTableData}
                  columns={this.state.convertedUsersColumn}
                  scroll={{ x: 'max-content' }}
                  pagination={false}
                />
              </>}
          </SalesOperationReportStyle.TableDiv>
        </div>
        {/* Same day stats start */}
        <div style={{ padding: '20px', paddingBottom: '40px' }}>
          <SalesOperationReportStyle.TableDiv>
            <SalesOperationReportStyle.RadioDiv>
              <Radio.Group
                onChange={this.onCompletedUsersChange}
                value={completedUsers}
                buttonStyle='solid'
              >
                <Radio.Button value='Table'>Table</Radio.Button>
                <Radio.Button value='Graph'>Graph</Radio.Button>
              </Radio.Group>
            </SalesOperationReportStyle.RadioDiv>
            {this.state.completedUsers === 'Graph' ? <CanvasJSChart options={options3} /> :
              <>
                <SalesOperationReportStyle.H1>
                  {`Completed Session [${dateDiff === 1 ? `${dateDiff} day` : `${dateDiff} days`}]`}
                </SalesOperationReportStyle.H1>
                <SalesOperationReportStyle.SalsesOperationReportTable
                  dataSource={this.state.completedUsersTableData}
                  columns={this.state.completedUsersColumn}
                  scroll={{ x: 'max-content' }}
                  pagination={false}
                />
              </>}
          </SalesOperationReportStyle.TableDiv>
        </div>
        <div style={{ padding: '20px', paddingBottom: '40px' }}>
          <SalesOperationReportStyle.TableDiv>
            <SalesOperationReportStyle.RadioDiv>
              <Radio.Group
                onChange={this.onConversionUsersChange}
                value={convertedUsers}
                buttonStyle='solid'
              >
                <Radio.Button value='Table'>Table</Radio.Button>
                <Radio.Button value='Graph'>Graph</Radio.Button>
              </Radio.Group>
            </SalesOperationReportStyle.RadioDiv>
            {this.state.convertedUsers === 'Graph' ? <CanvasJSChart options={options4} /> :
              <>
                <SalesOperationReportStyle.H1>
                  {`Conversion [${dateDiff === 1 ? `${dateDiff} day` : `${dateDiff} days`}]`}
                </SalesOperationReportStyle.H1>
                <SalesOperationReportStyle.SalsesOperationReportTable
                  dataSource={this.state.conversionTableData}
                  columns={this.state.completedUsersColumn}
                  scroll={{ x: 'max-content' }}
                  pagination={false}
                />
              </>}
          </SalesOperationReportStyle.TableDiv>
        </div>
      </div>
      )
    }
    return null
  }
}

export default GradeOperationReport
