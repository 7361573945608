import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import withUpdatedDashboardNav from '../../../components/withUpdatedNav'
import { DEMAND_SUPPLY_DASHBOARD } from '../../../constants/navItemsConst'
import injectProps from '../../../components/injectProps'
import DemandCalendar from './DemandCalendar'
import { filterKey } from '../../../utils/data-utils'

const DemandCalendarSideNav = withUpdatedDashboardNav(DemandCalendar)({
  title: 'Demand Calendar',
  activeNavItem: 'Demand Calendar',
  showUMSNavigation: true,
  renderNavItems: DEMAND_SUPPLY_DASHBOARD
})

const mapStateToProps = state => ({
  menteeSessionsForDemand: state.data.getIn(['menteeSessionsForDemand', 'data']),
  menteeSessionsForDemandFetchStatus: state.data.getIn(['menteeSessionsForDemand', 'fetchStatus', 'menteeSessionsForDemand']),
  mentorMenteeSessionsForDemand: state.data.getIn(['mentorMenteeSessionsForDemand', 'data']),
  mentorMenteeSessionsForDemandFetchStatus: state.data.getIn(['mentorMenteeSessionsForDemand', 'fetchStatus', 'mentorMenteeSessionsForDemand']),
  batchSessionsFetchStatus: state.data.getIn(['batchSessions', 'fetchStatus', 'mentorMenteeSessionsForDemand']),
  batchSessions: filterKey(state.data.getIn(['batchSessions', 'data']), 'mentorMenteeSessionsForDemand'),
  mentorMenteeSessionsForDemandDeleteStatus: state.data.getIn(['mentorMenteeSessionsForDemand', 'deleteStatus', 'mentorMenteeSessionsForDemand']),
  mentorMenteeSessionsForDemandAddStatus: state.data.getIn(['mentorMenteeSessionsForDemand', 'addStatus', 'mentorMenteeSessionsForDemand']),
  batchSessionsUpdateStatus: state.data.getIn(['batchSessions', 'updateStatus', 'mentorMenteeSessionsForDemand']),
  menteeSessionUpdateStatus: state.data.getIn(['menteeSessionsForDemand', 'updateStatus', 'menteeSessionsForDemand']),
  menteeSessionUpdateError: state.data.getIn(['errors', 'menteeSessionsForDemand/update']),
  batchSessionsUpdateStatusFailure: state.data.getIn(['errors', 'batchSessions/update']),
  mentorMenteeSessionsForDemandAddFailure: state.data.getIn(['errors', 'mentorMenteeSessionsForDemand/add'])
})


const DemandCalendarSideNavWithExtraProps = injectProps({
  notification
})(DemandCalendarSideNav)
export default connect(mapStateToProps)(withRouter(DemandCalendarSideNavWithExtraProps))
