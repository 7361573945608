import {MenuOutlined, StarOutlined } from '@ant-design/icons'
import { Switch, Spin, notification, Button, Checkbox, Radio } from 'antd'
import React from 'react'
import Container from './CoursePackageBatch.style'
import fetchBatchSessions from '../../actions/CoursePackageMaker/fetchBatchSessions'
import { get } from 'lodash'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import fetchSingleBatch from '../../actions/CoursePackageMaker/fetchSingleBatch'
import updateCoursePackagesBatch from '../../actions/CoursePackageMaker/updateCoursePackageBatch'

const actionTypeValue = {
    selectSessions: 'selectSessions',
    shuffleSessions: 'shuffleSessions'
}

class CoursePackageBatch extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            batch: {},
            packageDetails: [],
            packageData: [],
            batchSessionData: [],
            dragDisabled: true,
            initialPackageData: [],
            isSaveButtonVisible: false,
            selectedTopics: [],
            allTopics: [],
            fromSchoolOnboardingScreen: get(this.props, 'fromSchoolOnboardingScreen', false),
            actionType: actionTypeValue.selectSessions,
        }
    }

    componentDidMount = async() => {
        const { batchId } = this.props.match.params
        const { fromSchoolOnboardingScreen } = this.state
        if (batchId) {
            fetchBatchSessions(batchId).then(res => {
                this.setState({ batchSessionData: get(res, 'batchSessions') })
            })
            fetchSingleBatch(batchId)
        }
        if (fromSchoolOnboardingScreen) this.setState({ actionType: actionTypeValue.selectSessions })
        else this.setState({ actionType: actionTypeValue.shuffleSessions })
    }

    componentDidUpdate = async(prevProps) => {
        const { batchId } = this.props.match.params
        const { batchFetchStatus, batchUpdateStatus, batchUpdateFailure, batch } = this.props
        const { fromSchoolOnboardingScreen } = this.state
        if (batchFetchStatus && !get(batchFetchStatus.toJS(), 'loading')
        && get(batchFetchStatus.toJS(), 'success')
        && prevProps.batchFetchStatus !== batchFetchStatus) {
            const batchData = batch && batch.toJS() || []
            if (batchData.length > 0) {
                this.getPackageDetails(batchData[0])
            }
        }
        if (batchUpdateStatus && !get(batchUpdateStatus.toJS(), 'loading')
        && get(batchUpdateStatus.toJS(), 'success')
        && prevProps.batchUpdateStatus !== batchUpdateStatus) {
            fetchSingleBatch(batchId)
            notification.success({
                message: 'Course Package Batch Sessions updated successfully'
            })
            if (fromSchoolOnboardingScreen) {
                const batchData = batch && batch.toJS() || []
                if (batchData.length > 0) {
                    this.getPackageDetails(batchData[0])
                }
            }
        } else if (batchUpdateStatus && !get(batchUpdateStatus.toJS(), 'loading')
        && get(batchUpdateStatus.toJS(), 'failure')
        && prevProps.packageUpdateFailure !== packageUpdateFailure) {
        if (batchUpdateFailure && batchUpdateFailure.toJS().length > 0) {
          const errors = batchUpdateFailure.toJS().pop()
          notification.error({
            message: get(get(errors, 'error').errors[0], 'message')
          })
        }
        }
    }
    mapTopicsToCourses = (selectedTopics = [], courses = []) => {
        const { batchSessionData } = this.state
        let mapCourseWithTopic = []
        for (let i = 0; i < selectedTopics.length; i++) {
                const targetTopicId = get(selectedTopics[i], 'topic.id', '')
                const chapterTitle = get(selectedTopics[i], 'topic.chapter.title', '')
                const classType = get(selectedTopics[i],'topic.classType', '')
                const order = get(selectedTopics[i], 'order', '')
                let status = 'allotted'
            
                batchSessionData && batchSessionData.length && batchSessionData.forEach(item => {
                    if (get(item, 'topic.id') === targetTopicId) {
                        status = get(item, 'sessionStatus')
                    }
                })
                for (let j = 0; j < courses.length; j++) {
                    const topics = get(courses[j], 'topics', '')
                    const courseCategory = get(courses[j], 'category', '')
                    const isMandatory = (courseCategory === 'tools' || courseCategory === 'programming') && classType === 'theory'
                    for (let z = 0; z < topics.length; z++) {
                        if (targetTopicId === get(topics[z], 'id', '')) {
                            const temp = {
                                course: j,
                                topic: z,
                                topicId: targetTopicId,
                                topicTitle: get(topics[z], 'title', ''),
                                courseTitle: get(courses[j], 'title', ''),
                                chapterTitle: chapterTitle,
                                courseCategory: courseCategory,
                                isMandatory: isMandatory,
                                order,
                                status,  
                                checked: get(selectedTopics[i], 'checked', 'checked')                             
                                
                            }
                            mapCourseWithTopic.push(temp)
                        }
                    }
                }
        }
        return mapCourseWithTopic
    }
  
    uniqueTopics = (coursePackageTopics,courseTopicComponentRule) => {
        const topics = coursePackageTopics.map(item => {
            const topicId = get(item, 'topic.id')
            // if topic id includes in courseTopicComponentRule
            const isTopicIncluded = courseTopicComponentRule.find(t => get(t, 'topic.id') === topicId)
            if (isTopicIncluded) {
                return {
                    ...item,
                    checked: 'checked'
                }
            }
            return {

                ...item,
                checked: 'unchecked'
            }

        })
        return topics

      }
      
    getPackageDetails = (result) => {
        const { actionType } = this.state
        if (get(result, 'id')) {
            this.setState({
                batch: result,
                packageDetails: get(result, 'coursePackage'),
            })
            let selectedTopics = []
            // if (get(result, 'coursePackageTopicRule', []).length > 0) {
            //     selectedTopics = get(result, 'coursePackageTopicRule')
            // } else {
            //     selectedTopics = get(result, 'coursePackage.topics', [])
            // }
            selectedTopics = this.uniqueTopics(get(result, 'coursePackage.topics', []), get(result, 'coursePackageTopicRule', []))
            
            const courses = get(result, 'coursePackage.courses', [])
            let mapCourseWithTopic = this.mapTopicsToCourses(selectedTopics, courses)
            const allTopics = this.mapTopicsToCourses(get(result, 'coursePackage.topics', []), courses)
            const tempMapCourseWithTopic = mapCourseWithTopic.sort((a, b) => a.order - b.order)
            const revisionArray = []
            selectedTopics.forEach((item, index) => {
                if (item.isRevision) {
                    item.topicId = `id_${index}`
                    item.topicTitle = item.title
                    revisionArray.push(item)
                }
            })
            const newRevisionArray = revisionArray.sort((a, b) => a.previousTopicOrder - b.previousTopicOrder)
            newRevisionArray.forEach((item, index) => {
                tempMapCourseWithTopic.splice(item.previousTopicOrder + index, 0, item)
            })
            let newActiveTab = actionType
            if (newActiveTab === actionTypeValue.selectSessions && this.state.selectedTopics.length) {
                newActiveTab = actionTypeValue.shuffleSessions
            }  
            this.setState({
                packageData: tempMapCourseWithTopic,
                initialPackageData: tempMapCourseWithTopic,
                allTopics,
                selectedTopics: [],
                isSaveButtonVisible: false,
                actionType: newActiveTab,
                dragDisabled: true
            })
        }
    }

    getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        width: "100%",
        ...draggableStyle
    });

    getTopicsByOrder = (topics) => {
        const { selectedTopics, fromSchoolOnboardingScreen, allTopics } = this.state
        let topicsToAdd = []
        if (selectedTopics.length && fromSchoolOnboardingScreen) {
            allTopics.forEach(topic => {
                const isSelected = selectedTopics.find(tId => tId === get(topic, 'topicId'))
                if (isSelected) topicsToAdd.push(topic)
            })
        } else topicsToAdd = topics
        const arr = []
        let orderValue = 1
        topicsToAdd.forEach(item => {
            if (item.isRevision) {
                const tempObj = {}
                tempObj.title = get(item, 'title')
                tempObj.description = get(item, 'description')
                tempObj.isRevision = get(item, 'isRevision')
                tempObj.previousTopicOrder = get(item, 'previousTopicOrder')
                tempObj.revisionOrder = get(item, 'revisionOrder')
                arr.push(tempObj)
            } else {
                const obj = {}
                obj.order = orderValue++
                obj.isMandatory = get(item, 'isMandatory')
                obj.topicConnectId = item.topicId
                arr.push(obj)
            }
        })
        return arr
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        const { fromSchoolOnboardingScreen } = this.state
        result.splice(endIndex, 0, removed);
        for (let i=0; i<result.length; i++) {
            for (let j=i; j<result.length; j++) {
                if (result[i].courseTitle === result[j].courseTitle && result[i].order > result[j].order) {
                    if (!fromSchoolOnboardingScreen) {
                        notification.warn({
                            message: 'topic in same course should always be in order'
                        })
                        return
                    }
                }
            }
        }
        let revisionIndex = 1
        for (let i=0; i<result.length; i++) {
            if (result[i].isRevision) {
                if (i==0) {
                    notification.warn({
                        message: 'can not have revision session on first position'
                    })
                    return
                }
                result[i].revisionOrder = revisionIndex++
                for (let j=i-1; j>=0; j--) {
                    if (!result[j].isRevision) {
                        result[i].previousTopicOrder = result[j].order
                        break
                    }
                }
            }
        }
        return result;
    };

    onDragEnd = (result) => {
        const { packageData, initialPackageData } = this.state
        if (!result.destination) return
        if (packageData[result.destination.index].status === 'completed') return

        const items = this.reorder(
            packageData,
            result.source.index,
            result.destination.index
        );
        if (items !== undefined) {
            this.setState({
                packageData: items,
                isSaveButtonVisible: false
            })
            items.forEach((item, index) => {
                if (item.topicId !== initialPackageData[index].topicId) {
                    this.setState({ isSaveButtonVisible: true })
                    return
                }
            })
        }
    }

    handleSave = async () => {
        const { batchId } = this.props.match.params
        const { packageData } = this.state
        // filter the topics which status are checked
        const updatedPackageData = packageData.filter(item => item.checked === 'checked')
        const topics = this.getTopicsByOrder(packageData)
        if (topics.length ) {
            const inputObj = {
                coursePackageTopicRule: { replace: this.getTopicsByOrder(updatedPackageData) }
            }
            await updateCoursePackagesBatch(batchId, null, null, inputObj)
        } else {
            notification.warn({ message: 'Please select the Sessions to add' })
        }
    }
    handleCheckboxChange = (id, isChecked) => {
        const {packageData,updateTopics } = this.state
        const updatedPackageData = packageData.map(item => {
            if (item.topicId === id) {
                item.checked = isChecked ? 'checked' : 'unchecked'
            }
            return item
        })
        this.setState({ packageData: updatedPackageData,isSaveButtonVisible: true })

    };
    getCompletedSession = () => {
        const { packageData } = this.state
        return packageData && packageData.length && packageData.filter(item => item.status === 'completed').length
    }

    renderTopNavigation = () => {
        const { batch, packageDetails, fromSchoolOnboardingScreen } = this.state
        const { packageId } = this.props.match.params
        if (fromSchoolOnboardingScreen) return null;
        return (
            <Container.TopNavigation>
                <span onClick={() => this.props.history.push('/coursePackages')} style={{ cursor: 'pointer' }}>Course Packages </span>&#62; <span onClick={() => this.props.history.push(`/coursePackages/${packageId}`)} style={{ cursor: 'pointer' }}>{get(packageDetails, 'title')}</span>{get(batch, 'school.name') && <span>&#62; {get(batch, 'school.name')}</span>} <span>&#62; {get(batch, 'code')}</span>
            </Container.TopNavigation>
        )
    }
    onCheckSession = (e, topicId) => {
        const { selectedTopics } = this.state
        const isAlreadyAdded = selectedTopics.find(tId => topicId === tId)
        if (isAlreadyAdded) {
            this.setState({ selectedTopics: [...selectedTopics].filter(tId => tId !== topicId) })
        } else this.setState({ selectedTopics: [...selectedTopics, topicId] })
    }
    onSelectionChange = (event) => {
        this.setState({ actionType: event.target.value })
    }
    renderActions = () => {
        const { actionType, isSaveButtonVisible, packageData, dragDisabled,
            selectedTopics, allTopics, fromSchoolOnboardingScreen } = this.state
        const { batchUpdateStatus } = this.props
        let topics = [] 
        if (fromSchoolOnboardingScreen && selectedTopics.length && allTopics.length) {
            packageData.forEach(topic => {
                const isSelected = selectedTopics.find(tId => tId === get(topic, 'topicId'))
                if (isSelected) topics.push(topic)
            })
        } else {
            topics = (packageData && packageData.length && packageData) || []
        }
        switch (actionType) {
            case actionTypeValue.shuffleSessions:
                return <Container.PackageContainer>
                    <Container.PackageHeadContainer>
                        <h2>Package Name</h2>
                        <Container.FlexContainer>
                            <h3>Reshuffle Sequence</h3>
                            <Switch style={{ marginLeft: '15px' }} onChange={() => this.setState({ dragDisabled: !this.state.dragDisabled })} />
                        </Container.FlexContainer>
                    </Container.PackageHeadContainer>
                    {(isSaveButtonVisible || selectedTopics.length) ? (
                        <Container.FlexContainer justify='flex-end' style={{ marginTop: '15px' }}>
                            <Button
                                campaign
                                loading={batchUpdateStatus && get(batchUpdateStatus.toJS(), 'loading')}
                                style={{ marginRight: '51px' }}
                                type='primary'
                                htmlType='submit'
                                onClick={this.handleSave}
                            >Save</Button>
                        </Container.FlexContainer>
                    ) : null}
                    <Container.SessionHead>
                        <span style={{ width: '10%', textAlign: 'center' }}>S.No</span>
                        <span>Session Type</span>
                        <span>Course</span>
                        <span>Chapter</span>
                        <span>Session Name</span>
                        <span>Description</span>
                        <span>Status</span>
                    </Container.SessionHead>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {topics && topics.length > 0 && topics.map((item, i) => (
                                        <Draggable
                                            key={item.topicId}
                                            draggableId={item.topicId}
                                            index={i}
                                            isDragDisabled={dragDisabled || get(item, 'status') === 'completed'}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={this.getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <div style={{ display: 'flex', alignItems: 'center', boxShadow: 'inset 0px -1px 0px #EEEEEE', cursor: !dragDisabled ? 'pointer' : 'default' }}>
                                                        <input
                                                            style={{
                                                                'left': '17px',
                                                                'position': 'relative',
                                                            }}
                                                            checked={get(item, 'checked') === 'checked'}
                                                            onChange={
                                                                (e) => this.handleCheckboxChange(
                                                                    get(item, 'topicId'),
                                                                    e.target.checked
                                                                )
                                                            }
                                                            type="checkbox"
                                                            defaultChecked
                                                        />
                                                        {!dragDisabled && <MenuOutlined style={{ width: '5%' }} />}
                                                        <span className='sessionData' style={{ width: !dragDisabled ? '5%' : '10%', textAlign: !dragDisabled ? 'left' : 'center' }}>{i + 1}</span>
                                                        <button className='collapsable-button'><span>{get(item, 'isRevision') ? 'revision' : 'learning'}</span></button>
                                                        <span className='sessionData'>{get(item, 'courseTitle', '-')}</span>
                                                        <span className='sessionData'>{get(item, 'chapterTitle', '-')}</span>
                                                        <span className='sessionData'>{get(item, 'topicTitle', '-')}</span>
                                                        <span className='sessionData'>{get(item, 'description', '-')}</span>
                                                        <span className='sessionData'>{get(item, 'status')}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Container.PackageContainer>
            case actionTypeValue.selectSessions:
                return <Container.PackageContainer>
                    <h2>Select Sessions</h2>
                    {(isSaveButtonVisible || selectedTopics.length) ? (
                        <Container.FlexContainer justify='flex-end' style={{ marginTop: '15px' }}>
                            <Button
                                campaign
                                loading={batchUpdateStatus && get(batchUpdateStatus.toJS(), 'loading')}
                                style={{ marginRight: '51px' }}
                                type='primary'
                                htmlType='submit'
                                onClick={this.handleSave}
                            >Save</Button>
                        </Container.FlexContainer>
                    ) : null}
                    <Container.SessionHead>
                        <span style={{ width: '10%', textAlign: 'center' }}></span>
                        <span style={{ width: '10%', textAlign: 'center' }}>S.No</span>
                        <span>Session Type</span>
                        <span>Course</span>
                        <span>Chapter</span>
                        <span>Session Name</span>
                    </Container.SessionHead>
                    {allTopics.map((item, i) => {
                        return <div style={{ display: 'flex', alignItems: 'center', boxShadow: 'inset 0px -1px 0px #EEEEEE', cursor: !dragDisabled ? 'pointer' : 'default' }}>
                        <span style={{ width: '10%', textAlign: 'center' }}>
                            <Checkbox checked={selectedTopics.includes(get(item, 'topicId'))} onChange={e => this.onCheckSession(e,get(item, 'topicId'))}  />
                        </span>
                        <span className='sessionData' style={{ width: !dragDisabled ? '5%' : '10%', textAlign: !dragDisabled ? 'left' : 'center' }}>{i + 1}</span>
                        <button className='collapsable-button'><span>{get(item, 'isRevision') ? 'revision' : 'learning'}</span></button>
                        <span className='sessionData'>{get(item, 'courseTitle', '-')}</span>
                        <span className='sessionData'>{get(item, 'chapterTitle', '-')}</span>
                        <span className='sessionData'>{get(item, 'topicTitle', '-')}</span>
                    </div>
                    })}
                </Container.PackageContainer>
            default:
                return null;
        }
    }

    render() {
        const { batchFetchStatus } = this.props
        const { batch, packageData, packageDetails,
            fromSchoolOnboardingScreen, actionType } = this.state
        return (
            <>
            {get(batchFetchStatus && batchFetchStatus.toJS(), 'loading') ?
                <div style={{
                    width: "100%",
                    height: "58vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                    }}
                >
                <Spin size='large' />
                </div>
            : (
            <Container>
                {this.renderTopNavigation()}
                <Container.BatchDetailsContainer>
                    <Container.BatchNameContainer>
                        <h3>{get(batch, 'code')}</h3>
                        <p>{get(batch, 'school.name')}</p>
                    </Container.BatchNameContainer>
                    <Container.DetailsContainer>
                        <h3>Details</h3>
                        <Container.FlexContainer>
                            <Container.FlexContainer style={{ marginTop: '17px', minWidth: '150px' }}>
                                <StarOutlined />
                                <Container.FlexContainer direction='column' style={{ margin: '0 13px' }}>
                                    <span className='detailsType'>Batch Mentor</span>
                                    <p className='detailsData'>{get(batch, 'allottedMentor.name')}</p>
                                </Container.FlexContainer>
                            </Container.FlexContainer>
                            <Container.FlexContainer style={{ marginTop: '17px', minWidth: '150px' }}>
                                <StarOutlined />
                                <Container.FlexContainer direction='column' style={{ margin: '0 13px' }}>
                                    <span className='detailsType'>Completed Sessions</span>
                                    <p className='detailsData'>{this.getCompletedSession()}/{packageData && packageData.length}</p>
                                </Container.FlexContainer>
                            </Container.FlexContainer>
                            <Container.FlexContainer style={{ marginTop: '17px', minWidth: '150px' }}>
                                <StarOutlined />
                                <Container.FlexContainer direction='column' style={{ margin: '0 13px' }}>
                                    <span className='detailsType'>Course Sessions</span>
                                    <Container.TagsContainer>
                                    {packageDetails.courses && packageDetails.courses.length > 0 && packageDetails.courses.map(course => (
                                        <button>{course.title}</button>
                                    ))}
                                    </Container.TagsContainer>
                                </Container.FlexContainer>
                            </Container.FlexContainer>
                            <Container.FlexContainer style={{ marginTop: '17px', minWidth: '150px' }}>
                                <StarOutlined />
                                <Container.FlexContainer direction='column' style={{ margin: '0 13px' }}>
                                    <span className='detailsType'>Batch Mentor Contact</span>
                                    <p className='detailsData'>{get(batch, 'allottedMentor.phone.countryCode')} {get(batch, 'allottedMentor.phone.number')}</p>
                                </Container.FlexContainer>
                            </Container.FlexContainer>
                        </Container.FlexContainer>
                    </Container.DetailsContainer>
                </Container.BatchDetailsContainer>
                {fromSchoolOnboardingScreen ? (
                    <Radio.Group value={actionType} onChange={this.onSelectionChange} style={{ marginBottom: 16 }}>
                        <Radio.Button value={actionTypeValue.selectSessions}>Select Sessions</Radio.Button>
                        <Radio.Button value={actionTypeValue.shuffleSessions}>Reshuffle Sequence</Radio.Button>
                    </Radio.Group>
                ) : null}
                {this.renderActions()}
            </Container>
            )}
            </>
        )
    }
}

export default CoursePackageBatch