import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import withNav from '../../components/withNav'
import injectProps from '../../components/injectProps'
import CoursePackageBatch from './CoursePackageBatch'

const CoursePackageBatchNav = withNav(CoursePackageBatch)({
  title: 'Course Package Maker',
  activeNavItem: 'Course Package Maker',
  showCourseMakerNavigation: true,
})

const mapStateToProps = (state) => ({
  batch: state.data.getIn(['batch', 'data']),
  batchFetchStatus: state.data.getIn(['batch', 'fetchStatus', 'batch']),
  batchUpdateStatus: state.data.getIn(['batch', 'updateStatus', 'batch']),
  batchUpdateFailure: state.data.getIn(['errors', 'batch/update']),
})

const CoursePackageBatchNavWithExtraProps = injectProps({
  notification,
})(CoursePackageBatchNav)

const CoursePackageBatchWithExtraProps = injectProps({
  notification,
})(CoursePackageBatch)

export const CoursePackage = connect(mapStateToProps)(withRouter(CoursePackageBatchWithExtraProps))

export default connect(mapStateToProps)(withRouter(CoursePackageBatchNavWithExtraProps))
