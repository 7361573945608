import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import withUpdatedDashboardNav from '../../../components/withUpdatedNav'
import { EVENT_DASHBOARD } from '../../../constants/navItemsConst'
import injectProps from '../../../components/injectProps'
import SpeakerPrizes from "./SpeakerPrizes"

const SpeakerPrizesSideNav = withUpdatedDashboardNav(SpeakerPrizes)({
  title: 'Speaker Prizes',
  activeNavItem: 'Events',
  showUMSNavigation: true,
  renderNavItems: EVENT_DASHBOARD,
  noPadding: true
})

const mapStateToProps = state => ({
  speakersData: state.data.getIn(['eventSpeakerProfiles', 'data']),
  fetchedEvents: state.data.getIn(['events', 'data']),
  fetchedEventPrizes: state.data.getIn(['eventPrizes', 'data']),
  isFetchingAllSpeakers: state.data.getIn([
    'eventSpeakerProfiles',
    'fetchStatus',
    'eventSpeakerProfiles',
    'loading'
  ]),
  hasFetchedAllSpeakers: state.data.getIn([
    'eventSpeakerProfiles',
    'fetchStatus',
    'eventSpeakerProfiles',
    'success'
  ]),
  isUpdatingEvent: state.data.getIn([
    'events',
    'updateStatus',
    'events',
    'loading'
  ]),
  hasUpdatedEvent: state.data.getIn([
    'events',
    'updateStatus',
    'events',
    'success'
  ]),
  hasUpdatedEventFailed: state.data.getIn([
    'events',
    'updateStatus',
    'events',
    'failure'
  ]),
  isAddingEventPrize: state.data.getIn([
    'eventPrizes',
    'addStatus',
    'eventPrizes',
    'loading'
  ]),
  isUpdatingEventPrize: state.data.getIn([
    'eventPrizes',
    'updateStatus',
    'eventPrizes',
    'loading'
  ]),
  eventSpeakerAddStatus: state.data.getIn(['eventSpeakerProfiles', 'addStatus', 'eventSpeakerProfiles']),
  eventSpeakerAddFailure: state.data.getIn(['errors', 'eventSpeakerProfiles/add']),
  userAddStatus: state.data.getIn(['user', 'addStatus', 'user']),
  userAddFailure: state.data.getIn(['errors', 'user/add']),
})


const SpeakerPrizesSideNavWithExtraProps = injectProps({
  notification
})(SpeakerPrizesSideNav)
export default connect(mapStateToProps)(withRouter(SpeakerPrizesSideNavWithExtraProps))