import React from "react"
import { Link } from "react-router-dom"


const paths = [
    { path: '/basicDetails', label: 'Basic Details' },
    { path: '/eventDescription', label: 'Event Description' },
    { path: '/speakers-prizes', label: 'Speakers-Prizes' },
    { path: '/tickets', label: 'Tickets' },
    { path: '/certificateTemplate', label: 'Certificate Template' },
    { path: '/eventPreview', label: 'Event Preview' }
]

const EventNavigation = (props) => {

    const basePath = '/eventDashboard/events/'

    const renderNavPath = (path, label, index) => {
        const linkColor = path.includes(props.isCurrent) ? 'black' : '#5FBBF9'
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '2px 2px 12px 0px'
                }}
            >
                <Link to={basePath + `${props.id}${path}`}>
                    <span style={{ color: linkColor }}>{label}</span>
                </Link>
                <span style={{ margin: '0 20px' }}>{index === paths.length - 1 ? '' : '/'}</span>
            </div>
        )
    }
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '30px' }}>
            {
                paths.map((item, index) => {
                    return renderNavPath(item.path, item.label, index)
                })
            }
        </div>
    )
}

export default EventNavigation