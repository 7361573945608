import React, { useState } from 'react';
import { Button } from 'antd';
import { CopyFilled } from '@ant-design/icons';
import requestToGraphql from '../../../utils/requestToGraphql';
import { get } from 'lodash';
import gql from 'graphql-tag';
import toastrMessage from '../../../utils/messages';
import getIdArrForQuery from '../../../utils/getIdArrForQuery';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import getSlotNames from '../../../utils/slots/slot-names';

const CopyEventsButton = (props) => {
   const history = useHistory();
   const [loading, setLoading] = useState(false);
    const fetchEventDetailsData = async (eventId) => {
    await requestToGraphql(gql`
        {
            eventDetailsData: event(id: "${eventId}") {
            id
            name
            type
            geoLocation
            dateType
            organizer
            locationType
            ticketPrice
            address
            city
            state
            pincode
            timeZone
            sessionLink
            meetingId
            meetingPassword
            summary
            ticketName
            ticketregistrationLimit
            ticketPrice
            utm {
                utmTerm
                utmContent
                utmCampaign
                utmMedium
                utmSource
                webUrl
            }
            eventCommsRule {
                templateName
                commsVariables {
                whatsappVariableName
                emailVariableName
                dataField
                }
                condition
                attendanceFilter
                unit
                value
                isTested
                isPassed
                isSend
                isEmailRule
            }
            eventTimeTableRule {
              startDate
              endDate
              ${getSlotNames()}
            }
            prizes {
                id
            }
            tags {
                id
            }
            commsVariables {
                id
            }
            embeds {
                id
            }
            baseCertificate {
                id
            }
            category {
                id
            }
            eventJoinReasons {
                id
            }
            eventMobileBanner {
                id
            }
            listingImage {
                id
            }
            schools {
                id
            }
            eventBanner {
                id
            }
            speakers {
                id
            }
            overview
            }
        }
        `).then(async res => {
        const eventDetailsData = get(res, 'data.eventDetailsData')
        let input = {}
        if(get(eventDetailsData, 'name')) input.name = get(eventDetailsData, 'name')
        if(get(eventDetailsData, 'type')) input.type = get(eventDetailsData, 'type')
        if(get(eventDetailsData, 'geoLocation')) input.geoLocation = get(eventDetailsData, 'geoLocation')
        if(get(eventDetailsData, 'dateType')) input.dateType = get(eventDetailsData, 'dateType')
        if(get(eventDetailsData, 'organizer'))input.organizer = get(eventDetailsData, 'organizer')
        if(get(eventDetailsData, 'locationType')) input.locationType = get(eventDetailsData, 'locationType')
        if(get(eventDetailsData, 'ticketPrice')) input.ticketPrice = get(eventDetailsData, 'ticketPrice')
        if(get(eventDetailsData, 'address')) input.address = get(eventDetailsData, 'address')
        if(get(eventDetailsData, 'city')) input.city = get(eventDetailsData, 'city')
        if(get(eventDetailsData, 'state')) input.state = get(eventDetailsData, 'state')
        if(get(eventDetailsData, 'pincode')) input.pincode = get(eventDetailsData, 'pincode')
        if(get(eventDetailsData, 'timeZone')) input.timeZone = get(eventDetailsData, 'timeZone') 
        if(get(eventDetailsData, 'sessionLink')) input.sessionLink = get(eventDetailsData, 'sessionLink')
        if(get(eventDetailsData, 'meetingId')) input.meetingId = get(eventDetailsData, 'meetingId')
        if(get(eventDetailsData, 'meetingPassword')) input.meetingPassword = get(eventDetailsData, 'meetingPassword')
        if(get(eventDetailsData, 'summary')) input.summary = get(eventDetailsData, 'summary')
        if(get(eventDetailsData, 'ticketName')) input.ticketName = get(eventDetailsData, 'ticketName')
        if(get(eventDetailsData, 'ticketregistrationLimit')) input.ticketregistrationLimit = get(eventDetailsData, 'ticketregistrationLimit')
        if(get(eventDetailsData, 'ticketPrice')) input.ticketPrice = get(eventDetailsData, 'ticketPrice')
        if(get(eventDetailsData, 'overview')) input.overview = get(eventDetailsData, 'overview')
        if(get(eventDetailsData, 'utm') && get(eventDetailsData, 'utm').length > 0) {
          input.utm = []
          for(let i = 0; i < get(eventDetailsData, 'utm').length; i++){
            let currUtm = {}
            const term = get(eventDetailsData, 'utm')[i]
              if(term.utmTerm) currUtm.utmTerm = term.utmTerm
              if(term.utmContent) currUtm.utmContent = term.utmContent
              if(term.utmSource) currUtm.utmSource = term.utmSource
              if(term.utmMedium) currUtm.utmMedium = term.utmMedium
              if(term.utmCampaign) currUtm.utmCampaign = term.utmCampaign
              if(term.webUrl) currUtm.webUrl = term.webUrl 
            input.utm.push(currUtm)
          }
        }
        if(get(eventDetailsData, 'eventCommsRule') && get(eventDetailsData, 'eventCommsRule').length > 0) {
          input.eventCommsRule = []
          for(let i = 0; i < get(eventDetailsData, 'eventCommsRule').length; i++){
            let currRule = {}
            const term = get(eventDetailsData, 'eventCommsRule')[i]
              if(term.templateName) currRule.templateName = term.templateName
              if(term.condition) currRule.condition = term.condition
              if(term.attendanceFilter) currRule.attendanceFilter = term.attendanceFilter
              if(term.unit) currRule.unit = term.unit
              if(term.isTested != null) currRule.isTested = term.isTested
              if(term.isPassed != null) currRule.isPassed = term.isPassed
              if(term.isSend != null) currRule.isSend = false
              if(term.isEmailRule != null) currRule.isEmailRule = term.isEmailRule
              if(term.commsVariables.length > 0){
                let variables = []
                for(let j = 0; j < term.commsVariables.length; j++){
                  let currVariable = {}
                  if(term.commsVariables[j].whatsappVariableName) currVariable.whatsappVariableName = term.commsVariables[j].whatsappVariableName
                  if(term.commsVariables[j].emailVariableName) currVariable.emailVariableName = term.commsVariables[j].emailVariableName
                  if(term.commsVariables[j].dataField) currVariable.dataField = term.commsVariables[j].dataField
                  variables.push(currVariable)
                }
                currRule.commsVariables = variables
              }  
            input.eventCommsRule.push(currRule)
          }
        }
        if(get(eventDetailsData, 'eventTimeTableRule')){
          let { startDate, endDate, ...slots } = get(eventDetailsData, 'eventTimeTableRule')
          startDate= moment().add(1, 'w').toISOString(false)
          endDate = moment().add(1, 'w').toISOString(false)
          input.eventTimeTableRule = { startDate, endDate, ...slots }
        }
        const categoryConnectId= get(eventDetailsData, 'category.id')
        const speakersConnectIds= get(eventDetailsData, 'speakers') && get(eventDetailsData, 'speakers').length > 0 && `[${getIdArrForQuery(get(eventDetailsData, 'speakers').map((speaker) => speaker.id))}]`
        const eventJoinReasonsConnectIds= get(eventDetailsData, 'eventJoinReasons') && get(eventDetailsData, 'eventJoinReasons').length > 0 && `[${getIdArrForQuery(get(eventDetailsData, 'eventJoinReasons').map((reason) => reason.id))}]`
        const embedsConnectIds= get(eventDetailsData, 'embeds') && get(eventDetailsData, 'embeds').length > 0 && `[${getIdArrForQuery(get(eventDetailsData, 'embeds').map((embed) => embed.id))}]`
        const baseCertificateConnectId= get(eventDetailsData, 'baseCertificate.id')
        const prizesConnectIds= get(eventDetailsData, 'prizes') && get(eventDetailsData, 'prizes').length > 0 && `[${getIdArrForQuery(get(eventDetailsData, 'prizes').map((prize) => prize.id))}]`
        const tagsConnectIds= get(eventDetailsData, 'tags') && get(eventDetailsData, 'tags').length > 0 && `[${getIdArrForQuery(get(eventDetailsData, 'tags').map((tag) => tag.id))}]`
        const commsVariablesConnectIds= get(eventDetailsData, 'commsVariables') && get(eventDetailsData, 'commsVariables').length > 0 && `[${getIdArrForQuery(get(eventDetailsData, 'commsVariables').map((variable) => variable.id))}]`
        const eventBannerConnectId= get(eventDetailsData, 'eventBanner.id')
        const eventMobileBannerConnectId= get(eventDetailsData, 'eventMobileBanner.id')
        const listingImageConnectId= get(eventDetailsData, 'listingImage.id')
        const schoolsConnectIds= get(eventDetailsData, 'schools') && get(eventDetailsData, 'schools').length > 0 && `[${getIdArrForQuery(get(eventDetailsData, 'schools').map((school) => school.id))}]`
        
          await requestToGraphql(gql`
          mutation($input: EventInput!) {
          addEvent(input: $input,
          ${categoryConnectId ? `categoryConnectId: "${categoryConnectId}"` : ''}
          ${speakersConnectIds ? `speakersConnectIds: ${speakersConnectIds}` : ''}
          ${eventJoinReasonsConnectIds ? `eventJoinReasonsConnectIds: ${eventJoinReasonsConnectIds}` : ''}
          ${embedsConnectIds ? `embedsConnectIds: ${embedsConnectIds}` : ''}
          ${baseCertificateConnectId ? `baseCertificateConnectId: "${baseCertificateConnectId}"` : ''}
          ${prizesConnectIds ? `prizesConnectIds: ${prizesConnectIds}` : ''}
          ${tagsConnectIds ? `tagsConnectIds: ${tagsConnectIds}` : ''}
          ${commsVariablesConnectIds ? `commsVariablesConnectIds: ${commsVariablesConnectIds}` : ''}
          ${eventBannerConnectId ? `eventBannerConnectId: "${eventBannerConnectId}"` : ''}
          ${eventMobileBannerConnectId ? `eventMobileBannerConnectId: "${eventMobileBannerConnectId}"` : ''}
          ${listingImageConnectId ? `listingImageConnectId: "${listingImageConnectId}"` : ''}
          ${schoolsConnectIds ? `schoolsConnectIds: ${schoolsConnectIds}` : ''}
          ) {
          id
        }
      }
    `, {input}).then(res => {
      setLoading(false);
       history.push(`/eventDashboard/events/${get(res, 'data.addEvent.id')}/basicDetails`)
    }).catch(err => {
      setLoading(false);
      toastrMessage('alert', '', 'error', get(err, 'errors[0].message'));
    })
  }).catch(err => {
      setLoading(false);
      toastrMessage('alert', '', 'error', get(err, 'errors[0].message'));
  })
}

    return (
      <>
        <Button
          loading={loading}
          onClick={() => {
            fetchEventDetailsData(props.eventId)
            setLoading(true);
            }}
          style={{
            border: 'none',
            backgroundColor: 'transparent',
          }}
        >
          <CopyFilled />
        </Button>
      </>
    );
};

export default CopyEventsButton