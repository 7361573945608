import React from 'react';
import { get } from 'lodash';
import getFullPath from '../../../utils/getFullPath';

const PrizeCard = (props) => {
    return (
        <>
            <div className="PrizeCard-Data" 
                style={{
                    backgroundImage: '',
                    background: `${props.index === 0 ? '#34e4ea': 'white'}`,
                    position: 'relative',
                    border: `${props.index === 0 ? '0px': '1px solid #dbdbdb'}`
                }}
                >
                <div 
                    style={{ 
                        backgroundImage: `${`url(${getFullPath(get(props,'data.image.uri'))})`}`, 
                        backgroundRepeat:'no-repeat',
                        backgroundSize:'contain',
                        height: '90px',
                        width: '110px',
                        position: 'absolute',
                        padding: '0px',
                        top: '-16px',
                        right: '-45px',
                        transform: 'rotate(-20deg)',
                    }}
                >
                </div>
                {
                    props.index === 0 ? (
                        <h1 className="PrizePos-active">{`${get(props,'data.minRank')} - ${get(props,'data.maxRank')}`}<span className="PrizePrefix"> Rank</span></h1>
                    ) : (
                        <h1 className="PrizePos">{`${get(props,'data.minRank')} - ${get(props,'data.maxRank')}`}<span className="PrizePrefix"> Rank</span></h1>
                    )
                }
                {
                    props.index === 0 ? (
                        <p className="PrizeDesc-active">{get(props,'data.title')}</p>
                    ) : (
                        <p className="PrizeDesc">{get(props,'data.title')}</p>                      
                    )
                }
                
            </div>
        </>
    );
}

export default PrizeCard;