import gql from 'graphql-tag'
import duck from '../../../duck'
import getIdArrForQuery from '../../../utils/getIdArrForQuery'


const updateLearningSlideContent = async (SlideContenttId,input,learningSlideIds=[]) =>
  duck.query({
    query: gql`
    mutation($input: LearningSlideContentUpdate) {
        updateLearningSlideContent(id:"${SlideContenttId}",input: $input) {
        id
    }
    }
    `,
    variables: {
      input
    },
    type: 'updateLearningSlideContent/update',
    key: 'updateLearningSlideContent',
  })

export default updateLearningSlideContent
