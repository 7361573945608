// import { Tooltip } from 'antd'
import { get } from 'lodash'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { B2B, B2B2C, B2C } from '../../../constants/demandVerticals'
import { EditDemandIcon, PriceSvg, VerticalIcon } from '../../../constants/icons'
import FullCalendarStyle from '../FullCalendar.style'

const currencies = { RS: '₹', USD: '$' }

const EditEvent = ({ args }) => {
  const { count, paySlab, verticals = [],
    // totalLeft = 0,
    b2b2cCount = 0,
    b2bCount = 0,
    b2cCount = 0,
    mentorSessionsMeta = 0,
    shouldHover = true
  } = get(args, 'event.extendedProps')
  const totalCount = b2b2cCount + b2bCount + b2cCount
  // not show as we are only doing for trial
  // const getTooltipContent = () => {
  //   const content = []
  //   verticals.forEach(vertical => {
  //     let indicatorColor = ''
  //     if (get(vertical, 'value') === B2C) {
  //       indicatorColor = '#01AA93'
  //     } else if (get(vertical, 'value') === B2B) {
  //       indicatorColor = '#FAAD14'
  //     } else if (get(vertical, 'value') === B2B2C) {
  //       indicatorColor = '#2593E2'
  //     }
  //     content.push(
  //       <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
  //         <div style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
  //           <FullCalendarStyle.VerticalIndicator bgColor={indicatorColor} />
  //           <FullCalendarStyle.PreHeaderText>
  //             {get(vertical, 'value')}
  //           </FullCalendarStyle.PreHeaderText>
  //         </div>
  //         <span style={{ fontSize: 'x-small',
  // color: 'red'
  // }}> {`${totalLeft} ${totalLeft === 1 ? 'slot' : 'slots'} left`}</span >
  //       </div>
  //     )
  //   })
  //   return <div style={{ display: 'flex', alignItems: 'center' }}>{content}</div>
  // }
  const [hovered, setHovered] = useState(false)
  const verticalsValue = verticals.map(vertical => get(vertical, 'value'))
  const location = useLocation()
  const isSupplyDashboard = get(location, 'pathname') === '/supplyDashboard'
  const getBgColor = () => {
    let color = ''
    if (isSupplyDashboard) {
      if (count === totalCount) color = '#fff2d9'
      if (count < totalCount) color = '#fd345a'
    }
    return color
  }
  return (
    <FullCalendarStyle.EditSupplyEvent
      onMouseEnter={() => shouldHover && setHovered(true)}
      onMouseLeave={() => shouldHover && setHovered(false)}
      bgColor={getBgColor()}
      overFlow={isSupplyDashboard && count < totalCount}
    >
      {isSupplyDashboard && <span className='rightSlot__class'>{mentorSessionsMeta}</span>}
      <FullCalendarStyle.StyledIcon component={hovered ? EditDemandIcon : PriceSvg} />
      <>{`${get(paySlab, 'amountValue.currency', 'RS') === 'RS' ? currencies.RS : currencies.USD}${get(paySlab, 'amountValue.amount', 0)}`}</>
      <FullCalendarStyle.SlotVerticals>
        {
            verticalsValue.includes(B2B) && (
            <FullCalendarStyle.StyledIcon component={VerticalIcon}
              marginRight='0px'
              fontSize='10px'
              className='b2bSlotIcon'
            />
            )
          }
        {
            verticalsValue.includes(B2B2C) && (
              <FullCalendarStyle.StyledIcon component={VerticalIcon}
                marginRight='0px'
                fontSize='10px'
                className='b2b2cSlotIcon'
              />
            )
          }
        {
            verticalsValue.includes(B2C) && (
              <FullCalendarStyle.StyledIcon component={VerticalIcon}
                marginRight='0px'
                fontSize='10px'
                className='b2cSlotIcon'
              />
            )
          }
      </FullCalendarStyle.SlotVerticals>
      <span className='leftSlot__class'>{isSupplyDashboard ? `${totalCount}/${count}` : `${mentorSessionsMeta}/${count}`}</span>
    </FullCalendarStyle.EditSupplyEvent>
  )
}

export default EditEvent
