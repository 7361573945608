import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import { filterKey } from '../../../utils/data-utils'
import Classes from './Classes'
import withMentorDashboardNav from '../../../components/withUpdatedNav'
import injectProps from '../../../components/injectProps'
import { MENTOR_DASHBOARD } from '../../../constants/navItemsConst'

const ClassesSideNav = withMentorDashboardNav(Classes)({
  title: 'Mentor Dashboard',
  activeNavItem: 'Classes',
  showUMSNavigation: true,
  renderNavItems: MENTOR_DASHBOARD
})

const mapStateToProps = state => ({
  mentorSessions: filterKey(
    state.data.getIn([
      'completedSession',
      'data'
    ]), 'mentorSessionsForMentorDashboardClasses'
  ),
  mentors: filterKey(
    state.data.getIn([
      'user',
      'data'
    ]), 'user'
  ),
  mentorBatches: filterKey(state.data.getIn([
    'batches',
    'data'
  ]), 'mentorSessionsForMentorDashboardClasses'),
  mentorFilters: state.data.getIn([
    'mentorFilters',
    'data'
  ]),
  schools: filterKey(state.data.getIn([
    'schools',
    'data'
  ]), 'filtersForMentorDashboardClasses'),
  courses: filterKey(state.data.getIn([
    'course',
    'data'
  ]), 'filtersForMentorDashboardClasses'),
  batchCodes: filterKey(state.data.getIn([
    'batches',
    'data'
  ]), 'filtersForMentorDashboardClasses'),
  totalBatches: state.data.getIn([
    'batchesMeta',
    'data',
    'count'
  ]),
  totalMentorMenteeSessions: state.data.getIn([
    'mentorMenteeSessionsMeta',
    'data',
    'count'
  ])
})


const ClassesSideNavWithExtraProps = injectProps({
  notification
})(ClassesSideNav)
export default connect(mapStateToProps)(withRouter(ClassesSideNavWithExtraProps))
