import gql from 'graphql-tag'
import { get } from 'immutable'
import duck from '../../duck'

const fetchSchoolsAndCampaign = async () =>
  duck.query({
    query: gql`
      {
        schools {
          id
          name
        }
        campaigns {
          id
          title
          school {
            id
            name
          }
        }
      }
    `,
    type: 'schools/fetch',
    key: 'schools',
    changeExtractedData: (extractedData, originalData) => {
      extractedData.schools = get(originalData, 'schools', [])
      extractedData.campaigns = get(originalData, 'campaigns', [])
      return { ...extractedData }
    },
  })

export default fetchSchoolsAndCampaign

