import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'
import withNav from '../../components/withNav'
import injectProps from '../../components/injectProps'
import CoursePackage from './CoursePackage';

const CoursePackageNav = withNav(CoursePackage)({
  title: 'Course Package Maker',
  activeNavItem: 'Course Package Maker',
  showCourseMakerNavigation: true,
})

const mapStateToProps = (state) => ({
  fetchedCourses: state.data.getIn(['course', 'data']),
  fetchCourseStatus: state.data.getIn(['course', 'fetchStatus', 'course']),
  coursePackage: state.data.getIn(['coursePackage', 'data']),
  updateCoursePackageStatus: state.data.getIn(['coursePackage', 'updateStatus', 'coursePackage']),
  addCoursePackageStatus: state.data.getIn(['coursePackage', 'addStatus', 'coursePackage']),
  fetchCoursePackageStatus: state.data.getIn(['coursePackage', 'fetchStatus', 'coursePackage']),
})

const CoursePackageNavWithExtraProps = injectProps({
  notification,
})(CoursePackageNav)

export default connect(mapStateToProps)(withRouter(CoursePackageNavWithExtraProps))
