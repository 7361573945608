import React from 'react'
import { Icon, Spin } from 'antd'
import PropTypes from 'prop-types'
import UploadTableRow from './UploadTableRow'

const UploadTableBody = ({
  fetchStatus,
  uploadFiles,
  columnsTemplate,
  minWidth,
  isVideoLOMapping,
  deleteStatus,
  ...rest
}) => {
  if ((fetchStatus && fetchStatus.loading) || !uploadFiles) {
    const loadingIcon = <Icon type='loading' style={{ fontSize: 24 }} spin />
    return (
      <div style={{ width: '100%', padding: '15px' }}>
        <Spin indicator={loadingIcon} />
      </div>
    )
  }
  if (fetchStatus && !fetchStatus.loading && !fetchStatus.success) {
    return <p style={{ padding: '10px 0px', textAlign: 'center' }}>No data</p>
  }
  if (uploadFiles.length === 0) {
    return <p style={{ padding: '10px 0px', textAlign: 'center' }}>No data</p>
  }
  return (
    <div>
      {uploadFiles.map((file) => (
        <UploadTableRow
          {...file}
          {...rest}
          deleteStatus={deleteStatus}
          fetchStatus={fetchStatus}
          key={file.id}
          columnsTemplate={columnsTemplate}
          minWidth={minWidth}
          isVideoLOMapping={isVideoLOMapping}
        />
      ))}
    </div>
  )
}

UploadTableBody.propTypes = {
  uploadFiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnsTemplate: PropTypes.string.isRequired,
  minWidth: PropTypes.string.isRequired,
  fetchStatus: PropTypes.objectOf(PropTypes.bool).isRequired
}

export default UploadTableBody
