/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Icon, Button, Divider, notification } from 'antd'
import SenseiMentorTableRow from './SenseiMentorTableRow'
import MainTable from '../../../../components/MainTable'
import SenseiMentorStyle from '../../SenseiMentor.style'
import UpdateSenseiProfilesModal from '../UpdateMentorsModal/UpdateSenseiProfilesModal'
import AddMentorsModal from '../UpdateMentorsModal/AddMentorsModal'
import AddTeamsModal from '../UpdateMentorsModal/AddTeamsModal'
import getFullPath from '../../../../utils/getFullPath'


export default class SenseiMentorTableBody extends React.Component {
  state = {
    visible: false,
    confirmLoading: false,
    confirmLoadingAddMentor: false,
    senseiName: '',
    senseiEmail: '',
    senseiPhoneNumber: '',
    mentorDetails: [],
    senseiId: '',
    senseiIdForAddMentorsModal: '',
    senseiIdForTeamModal: ''
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isMentorGroupStatusUpdating && this.props.hasMentorGroupStatusUpdated) {
      this.setState({
        visible: false
      })
    }
    if ((prevProps.isAddingMentor && this.props.hasAddedMentor)
      || (prevProps.isUpdatingMentor && this.props.hasUpdatedMentor)) {
      this.setState({
        senseiId: ''
      })
    }
  }
  showModal = (sensei) => {
    if (get(sensei, 'mentors', []).length === 0) {
      return notification.warn({
        message: 'Please add mentors to update details.'
      })
    }
    this.setState({
      senseiName: get(sensei, 'user.username'),
      senseiEmail: get(sensei, 'user.email'),
      senseiPhoneNumber: get(sensei, 'user.phone.number'),
      mentorDetails: get(sensei, 'mentors'),
      senseiId: get(sensei, 'id'),
      visible: true
    })
  }

  handleOk = () => {
    this.setState({
      confirmLoading: true
    })
  }

  handleCancel = () => {
    this.setState({
      visible: false
    })
  }

  setSenseiValue = (sensei) => {
    this.setState({
      senseiId: sensei.id,
      senseiIdForAddMentorsModal: sensei.id,
      mentorDetails: sensei.mentors
    })
  }

  showModalAddMentor = (sensei) => {
    this.setSenseiValue(sensei)
  }

  handleOkAddMentor = () => {
    this.setState({ confirmLoadingAddMentor: true })
  }

  handleCancelAddMentor = () => {
    this.setState({ senseiIdForAddMentorsModal: '', senseiId: '' })
  }

  handleCannotAddMentor = () => {
    notification.error({
      message: 'Mentor already under Sensei!'
    })
    // this.setState({ senseiId: '' })
  }


  render() {
    const { mentorsData, isMentorStatusUpdating, hasMentorStatusUpdated, columnsTemplate, minWidth, isMentorGroupStatusUpdating, hasMentorGroupStatusUpdated, allMentorsData, isFetchingAllMentors, hasFetchedAllMentors, isAddingMentor, hasAddedMentor, fetchingSenseiMentorError, hasSenseiMentorFetched, hasDeletedMentor, hasUpdatedMentor, isAddingSenseiTeam, hasAddedSenseiTeam, isUpdatingSenseiTeam, hasUpdatedSenseiTeam } = this.props
    const { visible, confirmLoading, confirmLoadingAddMentor, senseiName, senseiEmail, senseiId, senseiPhoneNumber, mentorDetails, senseiIdForAddMentorsModal, senseiIdForTeamModal } = this.state

    if (!hasSenseiMentorFetched && !hasMentorGroupStatusUpdated && !hasMentorStatusUpdated && !hasAddedMentor && !hasDeletedMentor && !hasUpdatedMentor) {
      return (
        <MainTable.Item justifyContent='flex-start'>
          <Icon type='loading' style={{ fontSize: 18 }} spin />
        </MainTable.Item>
      )
    }
    if (hasSenseiMentorFetched && mentorsData.length === 0) {
      const emptyText = 'No Data found!'
      return <MainTable.EmptyTable>{emptyText}</MainTable.EmptyTable>
    }

    if (fetchingSenseiMentorError) {
      const emptyText = `Error: ${fetchingSenseiMentorError}`
      return <MainTable.EmptyTable>{emptyText}</MainTable.EmptyTable>
    }

    if (mentorsData && mentorsData.length > 0) {
      return (

        mentorsData.map((sensei) => ((
          <React.Fragment key={get(sensei, 'user.id')}>
            <SenseiMentorStyle.PaginationHolder>
              <Divider>
                <Button
                  type='primary'
                  onClick={() => this.showModal(sensei)}
                >
                  {get(sensei, 'user.name')} ({get(sensei, 'mentors', []).length})
                </Button>
                <Button
                  type='default'
                  onClick={() => this.showModalAddMentor(sensei)}
                  style={{ marginLeft: '10px' }}
                >
                  Add Mentors
                </Button>
                <div style={{ marginLeft: '2rem', display: 'inline' }}>
                  <span style={{ marginLeft: '10px' }}>{get(sensei, 'senseiMentorTeams[0].name')}</span>
                  <Divider orientation='right' style={{ display: 'inline' }}>
                    <img src={get(sensei, 'senseiMentorTeams[0].teamProfilePic.uri') ? `${getFullPath(
                      get(sensei, 'senseiMentorTeams[0].teamProfilePic.uri')
                    )}` : '/images/userPic.jpg'}
                      height='35'
                      width='35'
                      style={{ marginLeft: '20px', borderRadius: '50%' }}
                      alt='pro-pic'
                    />
                  </Divider>
                  <Button
                    type='default'
                    style={{ marginLeft: '20px' }}
                    onClick={() => this.setState({ senseiIdForTeamModal: get(sensei, 'id') })}
                  >
                    {get(sensei, 'senseiMentorTeams[0].id') ? 'Edit Team name & image' : 'Add Team name & image'}
                  </Button>
                </div>
              </Divider>
            </SenseiMentorStyle.PaginationHolder>
            {
              get(sensei, 'mentors', []).map((mentor, index) => ((
                <div style={{ marginTop: '10px' }} key={get(mentor, 'user.id')}>
                  <SenseiMentorTableRow
                    isMentorStatusUpdating={isMentorStatusUpdating}
                    hasMentorStatusUpdated={hasMentorStatusUpdated}
                    mentorName={get(mentor, 'user.name')}
                    status={mentor.status}
                    isMentorActive={mentor.isMentorActive}
                    emailID={get(mentor, 'user.email')}
                    phoneNo={get(mentor, 'user.phone.number')}
                    id={mentor.id}
                    columnsTemplate={columnsTemplate}
                    minWidth={minWidth}
                    order={index + 1}
                    serialNo={mentorsData.indexOf(mentor)}
                    senseiId={sensei.id}
                  />
                </div>
              )))
            }
            <UpdateSenseiProfilesModal
              title={senseiName}
              visible={visible}
              onOk={this.handleOk}
              confirmLoading={confirmLoading}
              onCancel={this.handleCancel}
              senseiEmail={senseiEmail}
              senseiPhoneNumber={senseiPhoneNumber}
              mentorDetails={mentorDetails}
              senseiId={senseiId}
              isMentorGroupStatusUpdating={isMentorGroupStatusUpdating}
              hasMentorGroupStatusUpdated={hasMentorGroupStatusUpdated}
            />
            <AddMentorsModal
              title='Add Mentors'
              visible={get(sensei, 'id') === senseiIdForAddMentorsModal}
              onOk={this.handleOkAddMentor}
              confirmLoading={confirmLoadingAddMentor}
              onCancel={this.handleCancelAddMentor}
              allMentorsData={allMentorsData}
              senseiId={senseiId}
              senseiProfile={sensei}
              mentorsUnderSensei={mentorDetails}
              isFetchingAllMentors={isFetchingAllMentors}
              hasFetchedAllMentors={hasFetchedAllMentors}
              isAddingMentor={isAddingMentor}
              hasAddedMentor={hasAddedMentor}
              handleCannotAddMentor={this.handleCannotAddMentor}
            />
            <AddTeamsModal
              key={get(sensei, 'id')}
              title={get(sensei, 'senseiMentorTeams[0].id') ? 'Edit Team' : 'Add Team'}
              visible={get(sensei, 'id') === senseiIdForTeamModal}
              senseiId={get(sensei, 'id')}
              senseiProfile={sensei}
              onCancel={() => this.setState({ senseiIdForTeamModal: '' })}
              isAddingSenseiTeam={isAddingSenseiTeam}
              hasAddedSenseiTeam={hasAddedSenseiTeam}
              isUpdatingSenseiTeam={isUpdatingSenseiTeam}
              hasUpdatedSenseiTeam={hasUpdatedSenseiTeam}
            />
          </React.Fragment >
        )))
      )
    }
    return <div />
  }
}

SenseiMentorTableBody.propTypes = {
  columnsTemplate: PropTypes.string.isRequired,
  minWidth: PropTypes.string.isRequired,
}
