/* eslint-disable */
import React from 'react'
import { Button, Input, Tooltip, Icon } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import { CaretDownOutlined, CloudUploadOutlined, ClockCircleOutlined, CopyOutlined } from '@ant-design/icons'
import { TypeSVG } from '../../../../constants/icons'
import copyText from '../../../../utils/copyText'
import ClassComponentStyle from './ClassComponent.style'
import getInputObject from '../../utils/getInputObject'
import { getDuration } from '../../../../utils/time'
import getFullPath from '../../../../utils/getFullPath'
import studentIcon from '../../../../assets/students.png'
import getSlotTime from '../../../../utils/slotTime'
import feedback from '../../../../assets/feedback.png'
import startClassIcon from '../../../../assets/startClass.png'
import AttendanceStatus from '../../../../constants/attendanceStatus'
import Attendancemodal from '../Attendancemodal/Attendancemodal'
import updateBatchSession from '../../../../actions/batch/updateBatchSession'

const { TextArea } = Input

const ClassComponent = ({ selectedTopic, batchSessions, students,
  classLink, updateSessionStatus, updateBatchSessionStatus, currentTopicOrder }) => {
  const [sessionData, setSessionData] = React.useState({
    sessionCreated: false,
    sessionStatus: '',
    batchSessionId: '',
    feedback: null,
    sessionRecordingLink: null,
    attendance: [],
    batch: [],
    id: '',
    sessionStartDate: null,
    sessionEndDate: null,
    bookingDate: '',
  })
  const [slot, setSlot] = React.useState()
  const isSessionUpdating = get(updateSessionStatus, 'loading') || get(updateBatchSessionStatus, 'loading')
  const [inputFeedback, setInputFeedback] = React.useState('')
  const [enterFeedback, setEnterFeedback] = React.useState(false)
  const [showFeedback, setShowFeedback] = React.useState(false)
  const [inputLink, setInputLink] = React.useState('')
  const [isAttendanceModalVisible, setIsAttendanceModalVisible] = React.useState(false)
  const fetchSession = async () => {
    let sessionFound = false
    for (let i = 0; i < batchSessions.length; i += 1) {
      if (get(batchSessions[i], 'topic.id', '') === get(selectedTopic, 'id', '')) {
        sessionFound = true
        setSessionData(prevState => ({
          ...prevState,
          sessionCreated: true,
          batchSessionId: get(batchSessions[i], 'id', ''),
          sessionStatus: get(batchSessions[i], 'sessionStatus', ''),
          feedback: get(batchSessions[i], 'sessionCommentByMentor', null),
          sessionRecordingLink: get(batchSessions[i], 'sessionRecordingLink', null),
          attendance: get(batchSessions[i], 'attendance', []),
          batch: get(batchSessions[i], 'batch', []),
          id: get(selectedTopic, 'id', null),
          sessionStartDate: get(batchSessions[i], 'sessionStartDate', null),
          sessionEndDate: get(batchSessions[i], 'sessionEndDate', null),
          bookingDate: moment((get(batchSessions[i], 'bookingDate', ''))).format('ddd, DD MMM'),
        }))
        let j = 0
        for (j = 0; j < 24; j += 1) {
          if (get(batchSessions[0], `slot${j}`)) {
            setSlot(j)
          }
        }
        if (j === 24) {
          setSlot(-1)
        }
      }
      if (sessionFound === false) {
        setSessionData(prevState => ({
          ...prevState,
          sessionCreated: true,
          batchSessionId: '',
          sessionStatus: '',
          feedback: null,
          sessionRecordingLink: null,
          attendance: [],
          batch: [],
          id: null,
          sessionStartDate: null,
          sessionEndDate: null,
          bookingDate: '',
        }))
        setSlot(-1)
      }
    }
  }
  const updateExistingSessions = async (type, queryType) => {
    if (type === 'status') {
      const input = await getInputObject(queryType)
      await updateBatchSession(sessionData.batchSessionId, input)
    } else if (type === 'feedback') {
      const input = {}
      input.sessionCommentByMentor = inputFeedback
      await updateBatchSession(sessionData.batchSessionId, input)
      setEnterFeedback(false)
    } else if (type === 'link') {
      const input = {}
      input.sessionRecordingLink = inputLink
      await updateBatchSession(sessionData.batchSessionId, input)
    }
  }

  const checkIfAttendanceSubmitted = () => {
    if (get(sessionData, 'attendance') && get(sessionData, 'attendance').length > 0) {
      let attendanceCompleted = true
      get(sessionData, 'attendance', []).forEach(attendance => {
        if (get(attendance, 'status') === AttendanceStatus.NOTASSIGNED) {
          attendanceCompleted = false
        }
      })
      return attendanceCompleted
    }
    return true
  }

  React.useEffect(() => {
    fetchSession()
    setEnterFeedback(false)
  }, [selectedTopic, isSessionUpdating, batchSessions])
  return (
    <ClassComponentStyle.Container>
      <ClassComponentStyle.SubContainer>
        <ClassComponentStyle.ImageBorder>
          <img src={getFullPath(selectedTopic.image)} style={{ height: '80px', width: '80px' }} alt='' />
        </ClassComponentStyle.ImageBorder>
        <ClassComponentStyle.FlexBox1>
          <ClassComponentStyle.FlexBox2>
            <div style={{ fontSize: '10px', color: '#9E9E9E' }}>Class<span style={{ color: 'black', fontWeight: '550' }} >  {selectedTopic.order}</span></div>
            <div>
              {currentTopicOrder > selectedTopic.order && (
                <ClassComponentStyle.Completed>Completed</ClassComponentStyle.Completed>
              )}
            </div>
          </ClassComponentStyle.FlexBox2>
          <ClassComponentStyle.Title>{selectedTopic.title}</ClassComponentStyle.Title>
          <ClassComponentStyle.Clock>
            <ClockCircleOutlined style={{ paddingRight: '10px' }} />
            <div>{getSlotTime(slot)}</div>
            <ClassComponentStyle.Dot />
            <div>{sessionData.bookingDate}</div>
          </ClassComponentStyle.Clock>
        </ClassComponentStyle.FlexBox1>
      </ClassComponentStyle.SubContainer>
      <ClassComponentStyle.Divider />
      <div style={{ marginLeft: '10px' }}>
        <ClassComponentStyle.Detail>Class Details</ClassComponentStyle.Detail>
        <ClassComponentStyle.FlexBox2>
          <img src={studentIcon} alt='' style={{ width: '13px', height: '13px', marginRight: '13px' }} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: '10px', color: '#757575' }}>Students</div>
            <div style={{ fontSize: '12px', color: '#757575' }}>{students.length}</div>
          </div>
          <Icon component={TypeSVG} style={{ width: '13px', height: '13px', marginLeft: '50px', marginRight: '13px', fill: '#9E9E9E' }} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: '10px', color: '#757575' }}>Type</div>
            <div style={{ fontSize: '10px', padding: '2px 8px', background: '#EEEEEE' }}>Learning</div>
          </div>
          {sessionData.sessionStatus === 'completed' && (
            <div style={{ marginLeft: '6%', display: 'flex' }}>
              <ClockCircleOutlined style={{ width: '13px', height: '13px', marginRight: '13px', color: '#9E9E9E' }} />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ fontSize: '10px', color: '#757575' }}>Duration</div>
                <div style={{ fontSize: '12px', color: '#757575' }}>{getDuration(get(sessionData, 'sessionStartDate'),
                    get(sessionData, 'sessionEndDate'))}
                </div>
              </div>
            </div>
          )}
        </ClassComponentStyle.FlexBox2>
      </div>
      <ClassComponentStyle.Divider />
      <div>
        {sessionData.sessionStatus === 'allotted' ? (
          <div style={{ marginLeft: '16px', marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ color: '#424242', marginRight: '', fontSize: '14px', fontWeight: '500' }}>Do you want to start the class ?</div>
            <Button
              loading={isSessionUpdating}
              style={{ backgroundColor: '#8C61CB', borderRadius: '8px', color: 'white', height: '32px', marginRight: '10%' }}
              onClick={() => {
                if (classLink !== null && classLink !== '') {
                  window.open(classLink, '_blank')
                }
                updateExistingSessions('status', 'Start Class')
              }}
            >
              <img src={startClassIcon} alt='' style={{ color: 'white', paddingRight: '8px' }} />Start Class
            </Button>
          </div>
        ) : null }
      </div>
      {sessionData.sessionCreated && sessionData.sessionStatus === 'completed' && sessionData.feedback === null && enterFeedback === false && (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', marginLeft: '10px', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '90px' }}>
            <div style={{ fontSize: '14px', color: '#424242', fontWeight: '500', marginBottom: '5px' }}>Please give feedback for the class</div>
            <div style={{ fontSize: '10px', fontWeight: '400', color: '#757575' }}>Class is unaccounted until feedback is provided</div>
          </div>
          <Button
            style={{ backgroundColor: '#8C61CB', borderRadius: '8px', color: 'white', marginTop: '10px', height: '34px', marginBottom: '5px', marginRight: '10%' }}
            onClick={() => setEnterFeedback(true)}
          >
            <img src={feedback} alt='' style={{ color: 'white', marginRight: '5px' }} />Give Feedback
          </Button>
        </div>
      )}
      { enterFeedback && (
        <div style={{ marginRight: '10%' }}>
          <TextArea
            rows={4}
            placeholder='Enter Feedback Here'
            value={inputFeedback}
            onChange={e => setInputFeedback(e.target.value)}
          />
          <Button
            loading={isSessionUpdating}
            onClick={() => updateExistingSessions('feedback', '')}
            style={{ backgroundColor: '#8C61CB', borderRadius: '8px', color: 'white', marginTop: '10px' }}
          >Submit Feedback
          </Button>
        </div>
        )}
      {sessionData.sessionCreated && sessionData.sessionStatus === 'completed' && sessionData.feedback !== null && (
        <div style={{ marginTop: '10px' }}>
          <div style={{ display: ' flex', flexDirection: 'row', marginLeft: '10px' }}>
            <div style={{ color: '#424242', fontSize: '14px', fontWeight: '500' }}>Feedback Received</div>
            <CaretDownOutlined style={{ color: '#8C61CB', paddingLeft: '400px' }} onClick={() => setShowFeedback(!showFeedback)} />
          </div>
          {showFeedback && (
            <ClassComponentStyle.FeedbackBox>
              {sessionData.feedback}
            </ClassComponentStyle.FeedbackBox>
          )}
          <ClassComponentStyle.Divider />
        </div>
      )}
      { sessionData.sessionCreated && sessionData.sessionStatus === 'completed' && sessionData.feedback !== null && sessionData.sessionRecordingLink === null && (
        <div style={{ marginLeft: '10px', marginTop: '10px' }}>
          <div style={{ fontSize: '14px', color: 'black', marginBottom: '12px' }}>Please add the link for the class recording.</div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Input style={{ width: '400px' }} value={inputLink} onChange={e => setInputLink(e.target.value)} />
            <Button
              loading={isSessionUpdating}
              onClick={() => updateExistingSessions('link', '')}
              style={{ backgroundColor: '#8C61CB', borderRadius: '8px', color: 'white', marginRight: '10%' }}
            >
              <CloudUploadOutlined style={{ color: 'white' }} />Upload Link
            </Button>
          </div>
        </div>
      ) }
      {sessionData.sessionCreated && sessionData.sessionStatus === 'started' && (
        <div style={{ marginLeft: '10px', marginTop: '20px' }}>
          <div style={{ fontSize: '14px', color: '#424242', fontWeight: '500', paddingBottom: '20px' }}>Please take attendance before ending the class</div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Button
              style={{ color: '#8C61CB', borderColor: '#8C61CB', marginRight: '100px' }}
              onClick={() => {
                setIsAttendanceModalVisible(true)
              }}
            >Take attendance
            </Button>
            <div style={{ marginRight: '10%', display: 'flex', alignItems: 'center' }}>
              <div style={{ fontSize: '10px', paddingRight: '20px' }}><ClockCircleOutlined style={{ paddingRight: '5px' }} />Class Duration: {getDuration(get(sessionData, 'sessionStartDate'),
                    new Date())}
              </div>
              {checkIfAttendanceSubmitted() ? (
                <Button
                  loading={isSessionUpdating}
                  style={{ backgroundColor: '#8C61CB', borderRadius: '8px', color: 'white' }}
                  onClick={() => {
                    updateExistingSessions('status', 'End Class')
                  }}
                >End Class
                </Button>
                ) : (
                  <Tooltip title='Please update attendance first' style={{ backgroundColor: 'white' }}>
                    <Button
                      loading={isSessionUpdating}
                      disabled={!checkIfAttendanceSubmitted()}
                      style={{ borderRadius: '8px', color: '#8C61CB', border: '1px solid #8C61CB' }}
                    >End Class
                    </Button>
                  </Tooltip>
              )}
            </div>
          </div>
        </div>
      )}
      {sessionData.sessionCreated && sessionData.sessionStatus === 'completed' && sessionData.feedback !== null && sessionData.sessionRecordingLink !== null && (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginLeft: '10px', marginTop: '10px' }}>
          <div style={{ fontSize: '14px', color: 'black' }}>Want to watch class recording?</div>
          <Button style={{ color: '#8C61CB', marginRight: '15%' }} onClick={() => copyText(get(sessionData, 'sessionRecordingLink'))}>
            <CopyOutlined style={{ color: '#8C61CB' }} />
            Copy
          </Button>
        </div>
      )}
      <Attendancemodal
        selectedTopic={sessionData}
        setIsAttendanceModalVisible={setIsAttendanceModalVisible}
        batchStudents={students}
        isModalVisible={isAttendanceModalVisible}
        updateBatchSessionStatus={updateBatchSessionStatus}
      />
    </ClassComponentStyle.Container>
  )
}


export default ClassComponent
