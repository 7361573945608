import React, { useState, useEffect } from 'react'
import { Switch, Popconfirm } from 'antd'
import requestToGraphql from '../../../utils/requestToGraphql';
import gql from 'graphql-tag';
import moment from 'moment';
import updateMentorActiveStatus from '../../../actions/ums/updateMentorActiveStatus'

const MentorActiveSwitch = ({ isMentorActiveStatus, mentorId, userId }) => {
    const [loading, setLoading] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [isMentorActive, setisMentorActive] = useState(isMentorActiveStatus)
    const [submitting, setSubmitting] = useState(false)
    useEffect(() => {
      setisMentorActive(isMentorActiveStatus)
    }, [userId])
    

    const handleClick = async () => {
        setLoading(true)
        if(isMentorActive){
            // request1
            const {data:{ mentorMenteeSessionsMeta:{ count:count1 }}} = await requestToGraphql(gql`
            {
                mentorMenteeSessionsMeta(
                    filter: {
                    and: [
                        { mentorSession_some: { user_some: { id: "${userId}" } } }
                        { sessionStartDate_gt: "${new Date()}" }
                    ]
                    }
                ) {
                    count
                }
            }`)
            // request2
            const {data: {batchSessionsMeta:{ count:count2 }}} = await requestToGraphql(gql`
            {
                batchSessionsMeta(
                filter: {
                and: [
                    { mentorSession_some: { user_some: { id: "${userId}" } } }
                    { sessionStartDate_gt: "${new Date()}" }
                ]
                }
            ) {
                count
            }}`)
            let count = count1+count2
            console.log('count', count)
            if(count > 0){
                setShowPopup(true)
            }
            else{
                //no popup in this case
                //request toggle
                const { updateMentorProfile: resultObj } = await updateMentorActiveStatus(mentorId, {isMentorActive: !isMentorActive}) 
                if(resultObj && resultObj.id){
                    setisMentorActive(resultObj.isMentorActive)
                    setLoading(false)
                }}}
        else {
            setShowPopup(true)
        }
    }
    const updateStatus = async () => {
        //request toggle
        setSubmitting(true)
        const { updateMentorProfile: resultObj } = await updateMentorActiveStatus(mentorId, {isMentorActive: !isMentorActive}) 
        if(resultObj && resultObj.id){
            setisMentorActive(resultObj.isMentorActive)
            setShowPopup(false)
            setSubmitting(false)
            setLoading(false)
        }      
    } 
    return(
        <>
            <Popconfirm
            title={`${isMentorActive ? 'mentor is already assigned to session, do you really want to mark mentor inactive' : 'Do you want to make this mentor active'}?`}
            visible={showPopup}
            onConfirm={updateStatus}
            onCancel={() => setShowPopup(!showPopup)}
            placement='topRight'
            okText='Yes'
            okButtonProps={{ loading: submitting }}
            cancelText='Cancel'
            key='toggle'
        >
        <Switch disabled={loading} style={{marginLeft: '8px'}} checked={isMentorActive} onClick={handleClick}/>
        </Popconfirm>
        </>
    )
    
}
export default MentorActiveSwitch