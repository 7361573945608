import React from "react";
import styled from "styled-components";

import { Computer } from "../../../../constants/icons";
import Stack from "../../../../components/Stack";

const NoComputer = () => {
	return (
		<NoComputerContainer gap={8}>
			<IconContainer>
				<Computer />
			</IconContainer>

			<Stack gap={8}>
				<Heading>
					No Computer&apos;s Inspected
				</Heading>
                
				<Text>
					Start Inspection to add computers.
				</Text>
			</Stack>
		</NoComputerContainer>
	);
};

const NoComputerContainer = styled(Stack)`
	justify-content: center;
	align-items: center;
	border: 1px solid #d2d2d2;
	border-radius: 12px;
	height: 338px;
`;

const IconContainer = styled(Stack)`
	justify-content: center;
	align-items: center;
	background-color: rgba(220, 220, 220, 0.2);
	border-radius: 50%;
	height: 88px;
	width: 88px;
	padding: 20px;
`;

const Heading = styled.h3`
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
    text-align: center;
	color: #282828;
`;

const Text = styled.p`
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
    text-align: center;
	color: #858585;
`;

export default NoComputer;
