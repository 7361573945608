import styled from 'styled-components'
import { Button, Pagination } from 'antd'
import { TekieAmethyst } from '../../constants/colors'

const Container = styled.div`
  padding: 10px 60px 10px 10px;
`

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => props.justify ? props.justify : 'flex-start'};
  align-items: ${props => props.align ? props.align : 'flex-start'};
`

const NavButton = styled(Button)`
  margin-right: 15px;
  border-radius: 5px;
  &&& {
    ${props => props.isSelected ? `
      color: #FFFFFF;
      background: ${TekieAmethyst};
    ` : `
      color: #333333;
    `};
    :hover {
      border-color: ${TekieAmethyst};
    }
    :focus {
      border-color: ${TekieAmethyst};
    }
  }
`

const FilterButton = styled(Button)`
  &&& {
    width: 86px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding: 7px 16px 7px 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    font-family: Inter;
    font-weight: 500;
    color: #282828;
    :hover {
      border: 1px solid ${TekieAmethyst};
    }
    :focus {
      border: 1px solid ${TekieAmethyst};
    }
    > i {
      width: 12px;
      height: 12px;
      margin-right: 8px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
`

const PaginationButton = styled(Pagination)`
  .ant-pagination-item:hover, .ant-pagination-item-active, .ant-pagination-next:hover, .ant-pagination-prev:hover {
    border-color: ${TekieAmethyst};
  }
  .ant-pagination-item a:hover, .ant-pagination-item-active a, .ant-pagination-next a:hover, .ant-pagination-prev a:hover {
    color: ${TekieAmethyst};
  }
`

Container.FlexContainer = FlexContainer
Container.NavButton = NavButton
Container.FilterButton = FilterButton
Container.PaginationButton = PaginationButton

export default Container