import gql from 'graphql-tag'
import duck from '../../duck'
import getIdArrForQuery from '../../utils/getIdArrForQuery'

const updateCoursePackagesBatch = async (batchId, allottedMentorConnectId, coursePackageCoursesConnectIds, input) => duck.query({
  query: gql`
    mutation($input: BatchUpdate){
        updateBatch(
          id: "${batchId}"
          ${allottedMentorConnectId ? `allottedMentorConnectId: "${allottedMentorConnectId}"` : ''}
          ${(coursePackageCoursesConnectIds && coursePackageCoursesConnectIds.length) ? `coursePackageCoursesConnectIds: [${getIdArrForQuery(coursePackageCoursesConnectIds)}]` : ''}
          input: $input
        ) {
            id
        }
    }
    `,
  variables: {
    input,
  },
  type: 'batch/update',
  key: 'batch',
})

export default updateCoursePackagesBatch
