import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const fetchLeadExtraFields = async (userIds = '') => duck.query({
  query: gql`
    query{
        salesOperations(filter: { client_some: { id_in: [${userIds}] } }) {
          id
          knowCoding
          leadStatus
          ageNotAppropriate
          learningAptitudeIssue
          lookingForAdvanceCourse
          noPayingPower
          notAQualifiedLeadComment
          notInterestedInCoding
          notRelevantDifferentStream
          client {
            id
          }
        }
    }
  `,
  type: 'salesOperations/fetch',
  key: 'leadExtraDetails',
  changeExtractedData: (extractedData, originalData) => {
    extractedData.salesOperation = get(originalData, 'salesOperations')
    return extractedData
  }
})

export default fetchLeadExtraFields
