import React, { Component } from 'react';
import { Form, notification, Pagination, Popconfirm, Popover, Table } from 'antd';
import { PaperClipOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Modal, Button } from 'antd';
import TeacherDetails from './Component/TeacherDetails'
import ScheduleManagement from './Component/ScheduleManagement';
import ReviewAndCreate from './Component/ReviewAndCreate';
import AddUsers from '../../../../actions/SchoolOnboarding/AddUsers';
import { get } from 'lodash';
import UpdateUser from '../../../../actions/SchoolOnboarding/UpdateUser';
import UpdateMentorProfile from '../../../../actions/SchoolOnboarding/UpdateMentorProfile';
import fetchMentorProfileData from '../../../../actions/SchoolOnboarding/FetchMentorProfileData';
import { Event } from '../../../EventDashboard/createEvent.style';
import DeleteUser from '../../../../actions/SchoolOnboarding/DeleteUser';
import FetchMentorProfilesMeta from '../../../../actions/SchoolOnboarding/FetchMentorProfileMeta';
export default class TeachersTab extends Component {
    constructor(props) {
        super(props);
        const ids = '';
        this.state = {
            ID: ids,
            modal2Visible: false,
            ModalEditVisible: false,
            onNext: 0,
            maxTabAccesed: 0,
            data: [],
            index: -1,
            addTableData: 0,
            addUserId: [],
            addMentorProfileId: [],
            loading: false,
            pageChange: 0,
            pageCount: 0,
            showPopup: false,
            isSubmitting: false,
            passWordValidationOnEdit: 0,
            mentorProfileRowData: {
                Name: '',
                Email: '',
                Phone: '',
                setPassword: '',
                confirmPassword: '',
                SelectGradesId: [],
                SelectGrades: [],
                AccessType: '',
                CountryCode: '+91',
                displayName: '',

                SchedulingLearningClass: 0,
                SchedulingAdHocClass: 0,
                ReschedulingClass: 0,
                SchedulingClass: 0,
                DeleteSessions: 0,

                CreatingAClassroom: 0,
                SendNotices: 0,
                AddInviteStudent: 0,
                AccessRecordings: 0,
                LiveSessionProgress: 0,

                AccessCourseControl: 0,
                CreateTests: 0,
                EvaluateTests: 0,
                AddToQBanks: 0,

                AccessReports: 0,
                DownloadReports: 0,
                ShareReports: 0,
            },
            ErrorObject: {
                NameError: 0,
                EmailError: 0,
                PhoneError: 0,
                setPasswordError: 0,
                confirmPasswordError: 0,
                SelectGradesError: 0,
                AccessTypeError: 0
            },
            TeacherDetail: [],
        }
    }
    componentDidMount = () => {
        if (this.props.schoolId !== '') {
            fetchMentorProfileData(this.props.schoolId, 5, 0);
            FetchMentorProfilesMeta(this.props.schoolId, 5, 0);
        }
    }
    componentDidUpdate = async (prevProps, prevState) => {

        const { AddUserStatus, AddUserData, AddUserError, schoolId, AddMentorStatus, AddMentorData, AddMentorError, UpdateUserStatus, UpdateUserError, loading, MentorProfilesData, MentorProfilesStatus, MentorProfilesMetaData, MentorProfilesMetaStatus, DeleteUserStatus } = this.props;
        const { TeacherDetail, mentorProfileRowData, data, addMentorProfileId, addUserId, index, pageCount, pageChange } = this.state;

        if (MentorProfilesStatus && !get(MentorProfilesStatus.toJS(), 'loading')
            && get(MentorProfilesStatus.toJS(), 'success') &&
            (prevProps.MentorProfilesStatus !== MentorProfilesStatus)) {

            const MentorProfilesDatas = MentorProfilesData && MentorProfilesData.toJS() || null;
            let TeacherObj = [], addUserIdObj = [], dataObj = [], addMentorProfileIdObj = [];
            MentorProfilesDatas.map((datas, i) => {

                let selectGradesIds = [], selectGradesdatas = [];
                get(datas, 'schoolClasses').map((schoolData) => {
                    selectGradesIds = [...selectGradesIds, get(schoolData, 'id')]
                })
                get(datas, 'schoolClasses').map((schoolData) => {
                    let data = `${get(schoolData, 'grade')} ${get(schoolData, 'section')}`;
                    selectGradesdatas = [...selectGradesdatas, data]
                })
                const obj = {
                    Name: get(datas, 'user.name'),
                    Email: get(datas, 'user.email'),
                    Phone: get(datas, 'user.phone.number') || '',
                    setPassword: '',
                    confirmPassword: '',
                    SelectGradesId: selectGradesIds,
                    SelectGrades: selectGradesdatas,
                    AccessType: get(datas, 'accessType'),
                    CountryCode: get(datas, 'user.phone.countryCode') || '',
                    displayName: get(datas, 'user.displayName'),

                    SchedulingLearningClass: get(datas, 'scheduleManagement.isSchedulingLearningClass') === true ? 0 : 1,
                    SchedulingAdHocClass: get(datas, 'scheduleManagement.isSchedulingAdhocClass') === true ? 0 : 1,
                    ReschedulingClass: get(datas, 'scheduleManagement.isReschedulingClasses') === true ? 0 : 1,
                    SchedulingClass: get(datas, 'scheduleManagement.isSchedulingTestClass') === true ? 0 : 1,
                    DeleteSessions: get(datas, 'scheduleManagement.isDeleteSessions') === true ? 0 : 1,

                    CreatingAClassroom: get(datas, 'classroomControl.isCreatingClass') === true ? 0 : 1,
                    SendNotices: get(datas, 'classroomControl.isSendingNotice') === true ? 0 : 1,
                    AddInviteStudent: get(datas, 'classroomControl.shouldAddOrInviteStudent') === true ? 0 : 1,
                    AccessRecordings: get(datas, 'classroomControl.isAccessRecording') === true ? 0 : 1,
                    LiveSessionProgress: get(datas, 'classroomControl.isAccessLiveSessionProgress') === true ? 0 : 1,

                    AccessCourseControl: get(datas, 'courseControl.shouldAccessCourse') === true ? 0 : 1,
                    CreateTests: get(datas, 'courseControl.shouldCreateTest') === true ? 0 : 1,
                    EvaluateTests: get(datas, 'courseControl.shouldEvaluateTest') === true ? 0 : 1,
                    AddToQBanks: get(datas, 'courseControl.shouldAddToQuestionBank') === true ? 0 : 1,

                    AccessReports: get(datas, 'sessionReporting.shouldAccessReports') === true ? 0 : 1,
                    DownloadReports: get(datas, 'sessionReporting.shouldDownloadReports') === true ? 0 : 1,
                    ShareReports: get(datas, 'sessionReporting.shouldShareReports') === true ? 0 : 1,
                }
                const tableObj = {
                    key: i + 1,
                    SrNo: i + 1,
                    TeacherName: get(datas, 'user.name'),
                    PhoneNumber: `${get(datas, 'user.phone.countryCode') || '-'} ${get(datas, 'user.phone.number') || '-'}`,
                    Email: get(datas, 'user.email'),
                }
                TeacherObj = [...TeacherObj, obj];
                dataObj = [...dataObj, tableObj];
                addUserIdObj = [...addUserIdObj, get(datas, 'user.id')];
                addMentorProfileIdObj = [...addMentorProfileIdObj, get(datas, 'id')]
            })
            let warningPopupShow = [];
            for (var i = 0; i < MentorProfilesDatas.length; i++) {
                warningPopupShow = [...warningPopupShow, false]
            }
            this.setState({
                addUserId: addUserIdObj,
                addMentorProfileId: addMentorProfileIdObj,
                data: dataObj,
                showPopup: warningPopupShow,
                TeacherDetail: TeacherObj,
            })
        }

        if (MentorProfilesMetaStatus && !get(MentorProfilesMetaStatus.toJS(), 'loading')
            && get(MentorProfilesMetaStatus.toJS(), 'success') &&
            (prevProps.MentorProfilesMetaStatus !== MentorProfilesMetaStatus)) {
            this.setState({
                pageCount: (MentorProfilesMetaData && MentorProfilesMetaData.toJS()).count,
            })
        }
        if ((AddUserStatus && !get(AddUserStatus.toJS(), 'loading')
            && get(AddUserStatus.toJS(), 'success')) && (prevProps.AddUserStatus !== AddUserStatus)) {
            const obj = {
                Name: '',
                Email: '',
                Phone: '',
                setPassword: '',
                confirmPassword: '',
                SelectGradesId: [],
                SelectGrades: [],
                AccessType: '',
                CountryCode: '+91',
                displayName: '',

                SchedulingLearningClass: 0,
                SchedulingAdHocClass: 0,
                ReschedulingClass: 0,
                SchedulingClass: 0,
                DeleteSessions: 0,

                CreatingAClassroom: 0,
                SendNotices: 0,
                AddInviteStudent: 0,
                AccessRecordings: 0,
                LiveSessionProgress: 0,

                AccessCourseControl: 0,
                CreateTests: 0,
                EvaluateTests: 0,
                AddToQBanks: 0,

                AccessReports: 0,
                DownloadReports: 0,
                ShareReports: 0,
            }
            this.setState({
                mentorProfileRowData: obj,
            })
            this.setState({
                modal2Visible: false,
                maxTabAccesed: 0,
                onNext: 0,
            })
            this.setState({
                loading: false,
            })
            notification.success({
                message: 'Added Successfully'
            })
            fetchMentorProfileData(this.props.schoolId, 5, pageChange);
            FetchMentorProfilesMeta(this.props.schoolId, 5, pageChange);
        }
        if (AddUserStatus && !get(AddUserStatus.toJS(), 'loading')
            && get(AddUserStatus.toJS(), 'failure') &&
            (prevProps.AddUserError !== AddUserError && AddUserError.toJS().length)) {
            const errorObj = AddUserError.toJS().pop()
            notification.info({
                message: `Error`,
                description: get(errorObj, 'error.errors[0].message'),
            });
            this.setState({
                loading: false,
            })
        }

        if ((AddMentorStatus && !get(AddMentorStatus.toJS(), 'loading')
            && get(AddMentorStatus.toJS(), 'success')) && (prevProps.AddMentorStatus !== AddMentorStatus)) {

            this.setState({
                addMentorProfileId: [...addMentorProfileId, AddMentorData.toJS().id],
            })
            notification.success({
                message: 'Added Successfully'
            })
            const tableObj = {
                key: TeacherDetail.length + 1,
                SrNo: TeacherDetail.length + 1,
                TeacherName: mentorProfileRowData.Name,
                PhoneNumber: `${mentorProfileRowData.CountryCode} ${mentorProfileRowData.Phone}`,
                Email: mentorProfileRowData.Email,
            }
            this.setState({
                addUserId: [...addUserId, AddUserData.toJS()[TeacherDetail.length].id],
                data: [...data, tableObj],
                TeacherDetail: [...TeacherDetail, mentorProfileRowData],
            })
            const obj = {
                Name: '',
                Email: '',
                Phone: '',
                setPassword: '',
                confirmPassword: '',
                SelectGradesId: [],
                SelectGrades: [],
                AccessType: '',
                CountryCode: '+91',
                displayName: '',

                SchedulingLearningClass: 0,
                SchedulingAdHocClass: 0,
                ReschedulingClass: 0,
                SchedulingClass: 0,
                DeleteSessions: 0,

                CreatingAClassroom: 0,
                SendNotices: 0,
                AddInviteStudent: 0,
                AccessRecordings: 0,
                LiveSessionProgress: 0,

                AccessCourseControl: 0,
                CreateTests: 0,
                EvaluateTests: 0,
                AddToQBanks: 0,

                AccessReports: 0,
                DownloadReports: 0,
                ShareReports: 0,
            }
            this.setState({
                mentorProfileRowData: obj,
            })
            this.setState({
                modal2Visible: false,
                maxTabAccesed: 0,
                onNext: 0,
            })
            this.setState({
                loading: false,
            })
            fetchMentorProfileData(this.props.schoolId, 5, pageChange);
            FetchMentorProfilesMeta(this.props.schoolId, 5, pageChange);
        }

        if ((AddMentorStatus && !get(AddMentorStatus.toJS(), 'loading')
            && get(AddMentorStatus.toJS(), 'failure')) && (prevProps.AddMentorError !== AddMentorError)) {
            notification.info({
                message: `Error`,
                description: get(get(AddMentorError.toJS()[0], 'error').errors[0], 'message'),
            });
            this.setState({
                loading: false,
            })
        }


        if ((UpdateUserStatus && !get(UpdateUserStatus.toJS(), 'loading')
            && get(UpdateUserStatus.toJS(), 'success') &&
            (prevProps.UpdateUserStatus !== UpdateUserStatus))) {

            const tableObj = {
                key: index + 1,
                SrNo: index + 1,
                TeacherName: mentorProfileRowData.Name,
                PhoneNumber: `${mentorProfileRowData.CountryCode} ${mentorProfileRowData.Phone}`,
                Email: mentorProfileRowData.Email,
            }
            const dataValues = data.map((data, i) => {
                if (i === index)
                    return tableObj;
                else
                    return data;
            })
            const TeacherDEtailValues = TeacherDetail.map((detail, i) => {
                if (i === index)
                    return mentorProfileRowData;
                else
                    return detail;
            })
            // await UpdateMentorProfile(addMentorProfileId[index], schoolId, mentorProfileRowData, addUserId[index]);
            notification.success({
                message: 'updated Successfully'
            })
            this.setState({
                data: dataValues,
                TeacherDetail: TeacherDEtailValues,
            })
            const obj = {
                Name: '',
                Email: '',
                Phone: '',
                setPassword: '',
                confirmPassword: '',
                SelectGradesId: [],
                SelectGrades: [],
                AccessType: '',
                CountryCode: '+91',
                displayName: '',

                SchedulingLearningClass: 0,
                SchedulingAdHocClass: 0,
                ReschedulingClass: 0,
                SchedulingClass: 0,
                DeleteSessions: 0,

                CreatingAClassroom: 0,
                SendNotices: 0,
                AddInviteStudent: 0,
                AccessRecordings: 0,
                LiveSessionProgress: 0,

                AccessCourseControl: 0,
                CreateTests: 0,
                EvaluateTests: 0,
                AddToQBanks: 0,

                AccessReports: 0,
                DownloadReports: 0,
                ShareReports: 0,
            }
            this.setState({
                mentorProfileRowData: obj,

            })
            this.setState({
                ModalEditVisible: false,
                maxTabAccesed: 0,
                onNext: 0,
            })
            this.setState({
                loading: false,
            })
            fetchMentorProfileData(this.props.schoolId, 5, pageChange);
            FetchMentorProfilesMeta(this.props.schoolId, 5, pageChange);
        }
        if ((UpdateUserStatus && !get(UpdateUserStatus.toJS(), 'loading')
            && get(UpdateUserStatus.toJS(), 'failure') &&
            (prevProps.UpdateUserError !== UpdateUserError && UpdateUserError.toJS().length))) {
            const errorObj = UpdateUserError.toJS().pop()
            notification.info({
                message: `Error`,
                description: get(errorObj, 'error.errors[0].message'),
            });
            this.setState({
                loading: false,
            })
        }

        if ((DeleteUserStatus && !get(DeleteUserStatus.toJS(), 'loading')
            && get(DeleteUserStatus.toJS(), 'success') &&
            (prevProps.DeleteUserStatus !== DeleteUserStatus))) {

            notification.success({
                message: 'Deleted Successfully'
            })
            const deleteTeacherObj = TeacherDetail.filter((detail, i) => {
                return (i !== (index))
            })
            const deleteDataObj = data.filter((datas, i) => {
                return (i !== (index))
            }).map((filterData, i) => {
                const tableObj = {
                    key: i + 1,
                    SrNo: i + 1,
                    TeacherName: filterData.TeacherName,
                    PhoneNumber: filterData.PhoneNumber,
                    Email: filterData.Email,
                }
                return tableObj
            })
            const addUserIdObj = addUserId.filter((obj, i) => {
                return (i !== (index))
            })
            const addMentorProfileIdObj = addMentorProfileId.filter((obj, i) => {
                return (i !== (index))
            })
            this.setState({
                data: deleteDataObj,
                TeacherDetail: deleteTeacherObj,
                addUserId: addUserIdObj,
                addMentorProfileId: addMentorProfileIdObj,
            })
            fetchMentorProfileData(this.props.schoolId, 5, pageChange);
            FetchMentorProfilesMeta(this.props.schoolId, 5, pageChange);

        }
    }
    setModal2Visible(modal2Visible) {
        this.setState({ modal2Visible });
    }
    setModalEditVisible(ModalEditVisible) {
        this.setState({ ModalEditVisible });
    }
    setOnNext = () => {
        const { mentorProfileRowData, ErrorObject, onNext, TeacherDetail, data, addTableData, passWordValidationOnEdit } = this.state;
        const { AddUserStatus, AddUserData, AddUserError, schoolId } = this.props;
        if (this.state.onNext === 0) {
            if (mentorProfileRowData.Name !== '' && mentorProfileRowData.Email !== '' && mentorProfileRowData.setPassword !== '' && mentorProfileRowData.confirmPassword !== '' && mentorProfileRowData.AccessType !== '') {
                if (mentorProfileRowData.Name.trim().length >= 3 && /^[A-Za-z0-9\s]*$/.test(mentorProfileRowData.Name)) {
                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mentorProfileRowData.Email) && mentorProfileRowData.Email.trim().length) {
                        if ((mentorProfileRowData.setPassword.trim().length >= 6 && mentorProfileRowData.setPassword.trim().length <= 30) || (mentorProfileRowData.confirmPassword.trim().length >= 6 && mentorProfileRowData.confirmPassword.trim().length <= 30)) {
                                if (mentorProfileRowData.setPassword.trim() === mentorProfileRowData.confirmPassword.trim()) {
                                    this.setState({
                                        onNext: this.state.onNext + 1,
                                        maxTabAccesed: this.state.maxTabAccesed + 1,
                                    })
                                }
                                else {
                                    notification.info({
                                        message: `Error`,
                                        description:
                                            'Password Mismatch...!!!!',
                                    });
                                    const obj = {
                                        Name: mentorProfileRowData.Name,
                                        Email: mentorProfileRowData.Email,
                                        Phone: mentorProfileRowData.Phone,
                                        setPassword: get(mentorProfileRowData, 'setPassword'),
                                        confirmPassword: get(mentorProfileRowData, 'confirmPassword'),
                                        SelectGradesId: mentorProfileRowData.SelectGradesId,
                                        SelectGrades: mentorProfileRowData.SelectGrades,
                                        AccessType: mentorProfileRowData.AccessType,
                                        CountryCode: mentorProfileRowData.CountryCode,
                                        displayName: mentorProfileRowData.displayName,

                                        SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
                                        SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
                                        ReschedulingClass: mentorProfileRowData.ReschedulingClass,
                                        SchedulingClass: mentorProfileRowData.SchedulingClass,
                                        DeleteSessions: mentorProfileRowData.DeleteSessions,

                                        CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
                                        SendNotices: mentorProfileRowData.SendNotices,
                                        AddInviteStudent: mentorProfileRowData.AddInviteStudent,
                                        AccessRecordings: mentorProfileRowData.AccessRecordings,
                                        LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

                                        AccessCourseControl: mentorProfileRowData.AccessCourseControl,
                                        CreateTests: mentorProfileRowData.CreateTests,
                                        EvaluateTests: mentorProfileRowData.EvaluateTests,
                                        AddToQBanks: mentorProfileRowData.AddToQBanks,

                                        AccessReports: mentorProfileRowData.AccessReports,
                                        DownloadReports: mentorProfileRowData.DownloadReports,
                                        ShareReports: mentorProfileRowData.ShareReports,
                                    }
                                    return this.setMentorProfileRowData(obj)
                                }
                            }
                            else {
                                return notification.info({
                                    message: `Error`,
                                    description:
                                        'Passowrd should be greater than 6 and less than 30!!!!',
                                });
                            }
                        if (mentorProfileRowData.Phone.trim().length) {
                            if (!mentorProfileRowData.Phone.match(/^\d{10}$/)) {
                                return notification.info({
                                    message: `Error`,
                                    description:
                                        'Enter Correct Phone Number!!!!',
                                });
                            }
                        }
                    }
                    else {
                        return notification.info({
                            message: `Error`,
                            description:
                                'Enter Correct Email Address!!!!',
                        });
                    }
                }
                else {
                    return notification.info({
                        message: `Error`,
                        description:
                            'Name should be greater than 3 and it should only contain number, characters and space!!!!',
                    });
                }
            }
            else {
                notification.info({
                    message: `Error`,
                    description:
                        'Input Fields Cannot be Empty...!!!!',
                });
                const errorObj = {
                    NameError: mentorProfileRowData.Name === '' ? 1 : 0,
                    EmailError: mentorProfileRowData.Email === '' ? 1 : 0,
                    PhoneError: 0,
                    setPasswordError: mentorProfileRowData.setPassword === '' && passWordValidationOnEdit === 0 ? 1 : 0,
                    confirmPasswordError: mentorProfileRowData.confirmPassword === '' && passWordValidationOnEdit === 0 ? 1 : 0,
                    SelectGradesError: 0,
                    AccessTypeError: mentorProfileRowData.AccessType === '' ? 1 : 0,
                }
                return this.setState({
                    ErrorObject: errorObj,
                })
            }
        }
        else {
            this.setState({
                onNext: this.state.onNext + 1,
                maxTabAccesed: this.state.maxTabAccesed + 1,
            })
        }

        if (onNext >= 0) {
            AddUsers(schoolId, mentorProfileRowData);
            this.setState({
                loading: true,
            })
        }
    }
    setEditOnNext = () => {
        const { mentorProfileRowData, ErrorObject, onNext, TeacherDetail, data, addTableData, addUserId, passWordValidationOnEdit } = this.state;
        const { AddUserStatus, AddUserData, AddUserError, schoolId, MentorProfilesMetaData, MentorProfilesMetaStatus } = this.props;
        if (this.state.onNext === 0) {
            if (mentorProfileRowData.Name !== '' && mentorProfileRowData.Email !== '' && mentorProfileRowData.AccessType !== '') {
                if (mentorProfileRowData.Name.trim().length >= 3 && /^[A-Za-z0-9\s]*$/.test(mentorProfileRowData.Name)) {
                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mentorProfileRowData.Email) && mentorProfileRowData.Email.trim().length) {
                        if (mentorProfileRowData.setPassword.trim().length > 0 || mentorProfileRowData.confirmPassword.trim().length > 0) {

                                if ((mentorProfileRowData.setPassword.trim().length >= 6 && mentorProfileRowData.setPassword.trim().length <= 30) || (mentorProfileRowData.confirmPassword.trim().length >= 6 && mentorProfileRowData.confirmPassword.trim().length <= 30)) {
                                    if (mentorProfileRowData.setPassword.trim() === mentorProfileRowData.confirmPassword.trim()) {
                                        this.setState({
                                            onNext: this.state.onNext + 1,
                                            maxTabAccesed: this.state.maxTabAccesed + 1,
                                        })
                                    }
                                    else {
                                        notification.info({
                                            message: `Error`,
                                            description:
                                                'Password Mismatch...!!!!',
                                        });
                                        const obj = {
                                            Name: mentorProfileRowData.Name,
                                            Email: mentorProfileRowData.Email,
                                            Phone: mentorProfileRowData.Phone,
                                            setPassword: get(mentorProfileRowData, 'setPassword'),
                                            confirmPassword: get(mentorProfileRowData, 'confirmPassword'),
                                            SelectGradesId: mentorProfileRowData.SelectGradesId,
                                            SelectGrades: mentorProfileRowData.SelectGrades,
                                            AccessType: mentorProfileRowData.AccessType,
                                            CountryCode: mentorProfileRowData.CountryCode,
                                            displayName: mentorProfileRowData.displayName,

                                            SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
                                            SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
                                            ReschedulingClass: mentorProfileRowData.ReschedulingClass,
                                            SchedulingClass: mentorProfileRowData.SchedulingClass,
                                            DeleteSessions: mentorProfileRowData.DeleteSessions,

                                            CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
                                            SendNotices: mentorProfileRowData.SendNotices,
                                            AddInviteStudent: mentorProfileRowData.AddInviteStudent,
                                            AccessRecordings: mentorProfileRowData.AccessRecordings,
                                            LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

                                            AccessCourseControl: mentorProfileRowData.AccessCourseControl,
                                            CreateTests: mentorProfileRowData.CreateTests,
                                            EvaluateTests: mentorProfileRowData.EvaluateTests,
                                            AddToQBanks: mentorProfileRowData.AddToQBanks,

                                            AccessReports: mentorProfileRowData.AccessReports,
                                            DownloadReports: mentorProfileRowData.DownloadReports,
                                            ShareReports: mentorProfileRowData.ShareReports,
                                        }
                                        return this.setMentorProfileRowData(obj)
                                    }
                                }
                                else {
                                    return notification.info({
                                        message: `Error`,
                                        description:
                                            'Passowrd should be greater than 6 and less than 30!!!!',
                                    });
                                }
                            }
                            else {
                                this.setState({
                                    onNext: this.state.onNext + 1,
                                    maxTabAccesed: this.state.maxTabAccesed + 1,
                                })
                            }
                        if (mentorProfileRowData.Phone.trim().length) {
                            if (!mentorProfileRowData.Phone.match(/^\d{10}$/)) {
                                return notification.info({
                                    message: `Error`,
                                    description:
                                        'Enter Correct Phone Number!!!!',
                                });
                            }
                        }
                    }
                    else {
                        return notification.info({
                            message: `Error`,
                            description:
                                'Enter Correct Email Address!!!!',
                        });
                    }
                }
                else {
                    return notification.info({
                        message: `Error`,
                        description:
                            'Name should be greater than 3 and it should only contain number, characters and space!!!!',
                    });
                }
            }
            else {
                notification.info({
                    message: `Error`,
                    description:
                        'Input Fields Cannot be Empty...!!!!',
                });
                const errorObj = {
                    NameError: mentorProfileRowData.Name === '' ? 1 : 0,
                    EmailError: mentorProfileRowData.Email === '' ? 1 : 0,
                    PhoneError: 0,
                    setPasswordError: mentorProfileRowData.setPassword === '' ? 1 : 0,
                    confirmPasswordError: mentorProfileRowData.confirmPassword === '' ? 1 : 0,
                    SelectGradesError: 0,
                    AccessTypeError: mentorProfileRowData.AccessType === '' ? 1 : 0,
                }
                return this.setState({
                    ErrorObject: errorObj,
                })
            }
        }
        else {
            this.setState({
                onNext: this.state.onNext + 1,
                maxTabAccesed: this.state.maxTabAccesed + 1,
            })
        }

        if (onNext >= 0) {
            UpdateUser(addUserId[this.state.index], mentorProfileRowData, schoolId);
            this.setState({
                loading: true,
            })
        }
    }

    goBackOnNext = (value) => {
        const { mentorProfileRowData, ErrorObject, passWordValidationOnEdit } = this.state;
        if (this.state.onNext === 0) {
            if (mentorProfileRowData.Name !== '' && mentorProfileRowData.Email !== '' && (mentorProfileRowData.setPassword !== '' || passWordValidationOnEdit === 1) && (mentorProfileRowData.confirmPassword !== '' || passWordValidationOnEdit === 1) && mentorProfileRowData.AccessType !== '') {
                if (mentorProfileRowData.Name.trim().length >= 3 && /^[A-Za-z0-9\s]*$/.test(mentorProfileRowData.Name)) {
                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mentorProfileRowData.Email) && mentorProfileRowData.Email.trim().length) {
                        if (passWordValidationOnEdit === 0) {
                                if ((mentorProfileRowData.setPassword.trim().length >= 6 && mentorProfileRowData.setPassword.trim().length <= 30) || (mentorProfileRowData.confirmPassword.trim().length >= 6 && mentorProfileRowData.confirmPassword.trim().length <= 30)) {
                                    if (mentorProfileRowData.setPassword.trim() === mentorProfileRowData.confirmPassword.trim()) {
                                        this.setState({
                                            onNext: value,
                                        })
                                    }
                                    else {
                                        notification.info({
                                            message: `Error`,
                                            description:
                                                'Password Mismatch...!!!!',
                                        });
                                        const obj = {
                                            Name: mentorProfileRowData.Name,
                                            Email: mentorProfileRowData.Email,
                                            Phone: mentorProfileRowData.Phone,
                                            setPassword: get(mentorProfileRowData, 'setPassword'),
                                            confirmPassword: get(mentorProfileRowData, 'confirmPassword'),
                                            SelectGradesId: mentorProfileRowData.SelectGradesId,
                                            SelectGrades: mentorProfileRowData.SelectGrades,
                                            AccessType: mentorProfileRowData.AccessType,
                                            CountryCode: mentorProfileRowData.CountryCode,
                                            displayName: mentorProfileRowData.displayName,

                                            SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
                                            SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
                                            ReschedulingClass: mentorProfileRowData.ReschedulingClass,
                                            SchedulingClass: mentorProfileRowData.SchedulingClass,
                                            DeleteSessions: mentorProfileRowData.DeleteSessions,

                                            CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
                                            SendNotices: mentorProfileRowData.SendNotices,
                                            AddInviteStudent: mentorProfileRowData.AddInviteStudent,
                                            AccessRecordings: mentorProfileRowData.AccessRecordings,
                                            LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

                                            AccessCourseControl: mentorProfileRowData.AccessCourseControl,
                                            CreateTests: mentorProfileRowData.CreateTests,
                                            EvaluateTests: mentorProfileRowData.EvaluateTests,
                                            AddToQBanks: mentorProfileRowData.AddToQBanks,

                                            AccessReports: mentorProfileRowData.AccessReports,
                                            DownloadReports: mentorProfileRowData.DownloadReports,
                                            ShareReports: mentorProfileRowData.ShareReports,
                                        }
                                        return this.setMentorProfileRowData(obj)
                                    }
                                }
                                else {
                                    return notification.info({
                                        message: `Error`,
                                        description:
                                            'Passowrd should be greater than 6 and less than 30!!!!',
                                    });
                                }
                            } else {

                                if (mentorProfileRowData.setPassword.length > 0 || mentorProfileRowData.confirmPassword.length > 0) {

                                    if ((mentorProfileRowData.setPassword.length >= 6 && mentorProfileRowData.setPassword.length <= 30) || (mentorProfileRowData.confirmPassword.length >= 6 && mentorProfileRowData.confirmPassword.length <= 30)) {
                                        if (mentorProfileRowData.setPassword === mentorProfileRowData.confirmPassword) {
                                            this.setState({
                                                onNext: value,
                                            })
                                        }
                                        else {
                                            notification.info({
                                                message: `Error`,
                                                description:
                                                    'Password Mismatch...!!!!',
                                            });
                                            const obj = {
                                                Name: mentorProfileRowData.Name,
                                                Email: mentorProfileRowData.Email,
                                                Phone: mentorProfileRowData.Phone,
                                                setPassword: get(mentorProfileRowData, 'setPassword'),
                                                confirmPassword: get(mentorProfileRowData, 'confirmPassword'),
                                                SelectGradesId: mentorProfileRowData.SelectGradesId,
                                                SelectGrades: mentorProfileRowData.SelectGrades,
                                                AccessType: mentorProfileRowData.AccessType,
                                                CountryCode: mentorProfileRowData.CountryCode,
                                                displayName: mentorProfileRowData.displayName,

                                                SchedulingLearningClass: mentorProfileRowData.SchedulingLearningClass,
                                                SchedulingAdHocClass: mentorProfileRowData.SchedulingAdHocClass,
                                                ReschedulingClass: mentorProfileRowData.ReschedulingClass,
                                                SchedulingClass: mentorProfileRowData.SchedulingClass,
                                                DeleteSessions: mentorProfileRowData.DeleteSessions,

                                                CreatingAClassroom: mentorProfileRowData.CreatingAClassroom,
                                                SendNotices: mentorProfileRowData.SendNotices,
                                                AddInviteStudent: mentorProfileRowData.AddInviteStudent,
                                                AccessRecordings: mentorProfileRowData.AccessRecordings,
                                                LiveSessionProgress: mentorProfileRowData.LiveSessionProgress,

                                                AccessCourseControl: mentorProfileRowData.AccessCourseControl,
                                                CreateTests: mentorProfileRowData.CreateTests,
                                                EvaluateTests: mentorProfileRowData.EvaluateTests,
                                                AddToQBanks: mentorProfileRowData.AddToQBanks,

                                                AccessReports: mentorProfileRowData.AccessReports,
                                                DownloadReports: mentorProfileRowData.DownloadReports,
                                                ShareReports: mentorProfileRowData.ShareReports,
                                            }
                                            return this.setMentorProfileRowData(obj)
                                        }
                                    }
                                    else {
                                        return notification.info({
                                            message: `Error`,
                                            description:
                                                'Passowrd should be greater than 6 and less than 30!!!!',
                                        });
                                    }
                                }
                                else {
                                    this.setState({
                                        onNext: this.state.onNext + 1,
                                        maxTabAccesed: this.state.maxTabAccesed + 1,
                                    })
                                }
                            }
                        if (mentorProfileRowData.Phone.trim().length) {
                            if (!mentorProfileRowData.Phone.match(/^\d{10}$/)) {
                                return notification.info({
                                    message: `Error`,
                                    description:
                                        'Enter Correct Phone Number!!!!',
                                });
                            }

                        }
                    }
                    else {
                        return notification.info({
                            message: `Error`,
                            description:
                                'Enter Correct Email Address!!!!',
                        });
                    }
                }
                else {
                    return notification.info({
                        message: `Error`,
                        description:
                            'Name should be greater than 3 and it should only contain number, characters and space!!!!',
                    });
                }
            }
            else {
                notification.info({
                    message: `Error`,
                    description:
                        'Input Fields Cannot be Empty...!!!!',
                });
                const errorObj = {
                    NameError: mentorProfileRowData.Name === '' ? 1 : 0,
                    EmailError: mentorProfileRowData.Email === '' ? 1 : 0,
                    PhoneError: 0,
                    setPasswordError: mentorProfileRowData.setPassword === '' ? 1 : 0,
                    confirmPasswordError: mentorProfileRowData.confirmPassword === '' ? 1 : 0,
                    SelectGradesError: 0,
                    AccessTypeError: mentorProfileRowData.AccessType === '' ? 1 : 0,
                }
                return this.setState({
                    ErrorObject: errorObj,
                })
            }
        }
        else {
            if (mentorProfileRowData.setPassword === mentorProfileRowData.confirmPassword) {
                this.setState({
                    onNext: value,
                })
            }
        }

    }
    setMentorProfileRowData = (object) => {
        this.setState({
            mentorProfileRowData: object,
        })
    }
    editTable = (Index) => {
        const { mentorProfileRowData, TeacherDetail, pageChange } = this.state;
        this.setState({
            index: (Index),
            mentorProfileRowData: TeacherDetail[Index],
            passWordValidationOnEdit: 1,
        })
        this.setModalEditVisible(true);

    }

    handleOk = (prevState, Index) => {
        const { addUserId, showPopup } = this.state;

        if (prevState.showPopup !== showPopup) {

            this.setState({
                isSubmitting: true,
            });

            let warningPopupShow = [];
            warningPopupShow = showPopup.map((showPopup, i) => {
                if (Index === i) {
                    return false;
                }
                else
                    return showPopup;
            })
            setTimeout(() => {
                this.setState({
                    showPopup: warningPopupShow,
                    isSubmitting: false,
                });
            }, 800);

            this.setState({
                index: (Index),
            })
            DeleteUser(addUserId[Index]);
        }
    }

    handleCancel = (prevState, Index) => {
        const { showPopup, isSubmitting } = this.state;

        if (prevState.showPopup !== showPopup) {
            let warningPopupShow = [];
            warningPopupShow = showPopup.map((showPopup, i) => {
                if (Index === i) {
                    return false;
                }
                else
                    return showPopup;
            })
            this.setState({ showPopup: warningPopupShow });

        }
    };

    DeleteRow = (prevState, Index) => {
        const { showPopup } = this.state;


        if (prevState.showPopup !== showPopup) {
            let warningPopupShow = [];
            warningPopupShow = showPopup.map((showPopup, i) => {
                if (Index === i) {
                    return true;
                }
                else
                    return showPopup;
            })
            this.setState({ showPopup: warningPopupShow });
        }



    }
    changePage = (e) => {
        this.setState({
            pageChange: ((e - 1) * 5),
        })
        fetchMentorProfileData(this.props.schoolId, 5, ((e - 1) * 5));
        FetchMentorProfilesMeta(this.props.schoolId, 5, ((e - 1) * 5));
    }
    render() {
        const { classGrades, schoolName, schoolId, AddUserStatus, MentorProfilesStatus } = this.props;
        const { onNext, mentorProfileRowData, maxTabAccesed, ErrorObject, TeacherDetail, data, loading, index, pageCount, showPopup, isSubmitting, passWordValidationOnEdit } = this.state;
        const columns = [
            {
                title: 'Sr No.',
                width: 40,
                dataIndex: 'SrNo',
                key: 'SrNo',
            },
            {
                title: 'Teacher Name',
                width: 100,
                dataIndex: 'TeacherName',
                key: 'TeacherName',
            },
            {
                title: 'Phone Number',
                width: 100,
                dataIndex: 'PhoneNumber',
                key: 'PhoneNumber',
            },
            {
                title: 'Email',
                width: 100,
                dataIndex: 'Email',
                key: 'Email',
            },
            {
                title: 'Action',
                key: 'operation',
                width: 20,
                align: 'center',
                render: (text, record, index) => (
                    <>
                        <div style={{
                            display: "flex",
                            alignItems: "center"
                        }}>
                            <a><EditOutlined style={{ fontSize: "25px" }} onClick={(e) => { this.editTable(index) }} /></a>
                            {/* <a style={{ marginRight: "18px" }}><PaperClipOutlined style={{ fontSize: "25px" }} /></a> */}
                            {/* <a style={{ marginLeft: "25px" }}>
                                <Popconfirm
                                    title={`Do you want to delete this Teacher ?`}
                                    placement='top'
                                    visible={showPopup[index]}
                                    okText='Yes'
                                    cancelText='Cancel'
                                    key='delete'
                                    okButtonProps={{ loading: isSubmitting }}
                                    overlayClassName='popconfirm-overlay-primary'
                                    onConfirm={(prevState) => this.handleOk(prevState, index)}
                                    onCancel={(prevState) => this.handleCancel(prevState, index)}
                                >
                                    <DeleteOutlined style={{ fontSize: "25px" }}
                                        onClick={(prevState) => {
                                            this.DeleteRow(prevState, index)
                                        }}
                                    />
                                </Popconfirm>
                            </a> */}
                        </div>
                    </>),
            },
        ];
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button type="primary" onClick={() => this.setModal2Visible(true)} style={{ width: "150px",marginBottom: "25px" }}>
                        Add Teachers
                    </Button>
                    <div>Total Teacher`s: {pageCount || 0}</div>
                </div>
                <Modal
                    centered
                    loading={loading}
                    visible={this.state.modal2Visible}
                    maskClosable={false}
                    onOk={() => {
                        this.setOnNext()
                    }}
                    onCancel={() => {
                        this.setModal2Visible(false)
                        const obj = {
                            Name: '',
                            Email: '',
                            Phone: '',
                            setPassword: '',
                            confirmPassword: '',
                            SelectGradesId: [],
                            SelectGrades: [],
                            AccessType: '',
                            CountryCode: '+91',
                            displayName: '',

                            SchedulingLearningClass: 0,
                            SchedulingAdHocClass: 0,
                            ReschedulingClass: 0,
                            SchedulingClass: 0,
                            DeleteSessions: 0,

                            CreatingAClassroom: 0,
                            SendNotices: 0,
                            AddInviteStudent: 0,
                            AccessRecordings: 0,
                            LiveSessionProgress: 0,

                            AccessCourseControl: 0,
                            CreateTests: 0,
                            EvaluateTests: 0,
                            AddToQBanks: 0,

                            AccessReports: 0,
                            DownloadReports: 0,
                            ShareReports: 0,
                        }
                        this.setState({
                            mentorProfileRowData: obj,
                            maxTabAccesed: 0,
                            onNext: 0,
                        })

                    }}
                    okText={"Add Teacher"}
                    okButtonProps={{ loading: loading }}
                    cancelButtonProps={{
                        style: {
                            display: 'none',
                        }
                    }}
                    bodyStyle={{ height: "70vh", overflowY: "scroll" }}
                >
                    <TeacherDetails
                        schoolName={schoolName}
                        onNext={onNext}
                        goBackOnNext={value => this.goBackOnNext(value)}
                        maxTabAccesed={maxTabAccesed}
                        classGrades={classGrades}
                        modal2Visible={this.state.modal2Visible}
                        setMentorProfileRowData={obj => this.setMentorProfileRowData(obj)}
                        mentorProfileRowData={mentorProfileRowData}
                        ErrorObject={ErrorObject}
                        passWordValidationOnEdit={passWordValidationOnEdit}
                    />
                </Modal>
                <Modal
                    centered
                    loading={loading}
                    visible={this.state.ModalEditVisible}
                    maskClosable={false}
                    onOk={() => {
                        this.setEditOnNext()
                    }}
                    onCancel={() => {
                        this.setModalEditVisible(false)
                        const obj = {
                            Name: '',
                            Email: '',
                            Phone: '',
                            setPassword: '',
                            confirmPassword: '',
                            SelectGradesId: [],
                            SelectGrades: [],
                            AccessType: '',
                            CountryCode: '+91',
                            displayName: '',

                            SchedulingLearningClass: 0,
                            SchedulingAdHocClass: 0,
                            ReschedulingClass: 0,
                            SchedulingClass: 0,
                            DeleteSessions: 0,

                            CreatingAClassroom: 0,
                            SendNotices: 0,
                            AddInviteStudent: 0,
                            AccessRecordings: 0,
                            LiveSessionProgress: 0,

                            AccessCourseControl: 0,
                            CreateTests: 0,
                            EvaluateTests: 0,
                            AddToQBanks: 0,

                            AccessReports: 0,
                            DownloadReports: 0,
                            ShareReports: 0,
                        }

                        this.setState({
                            passWordValidationOnEdit: 0,
                            mentorProfileRowData: obj,
                            maxTabAccesed: 0,
                            onNext: 0,
                        })
                    }}
                    okText={"Update Teacher"}
                    okButtonProps={{ loading: loading }}
                    cancelButtonProps={{
                        style: {
                            display: 'none',
                        }
                    }}
                    bodyStyle={{ height: "70vh", overflowY: "scroll" }}
                >
                    <>
                        <TeacherDetails
                            schoolName={schoolName}
                            onNext={onNext}
                            goBackOnNext={value => this.goBackOnNext(value)}
                            maxTabAccesed={maxTabAccesed}
                            classGrades={classGrades}
                            setMentorProfileRowData={obj => this.setMentorProfileRowData(obj)}
                            mentorProfileRowData={mentorProfileRowData}
                            ErrorObject={ErrorObject}
                            passWordValidationOnEdit={passWordValidationOnEdit}
                        /> </>
                </Modal>
                <Table
                    loading={MentorProfilesStatus !== undefined ? get(MentorProfilesStatus.toJS(), 'loading') : false}
                    columns={columns}
                    dataSource={data}
                    scroll={{ x: 1300 }}
                    pagination={false}

                />
                {(pageCount > 5) ?
                    <Pagination style={{ float: 'right' }}
                        total={pageCount}
                        defaultPageSize={5}
                        defa
                        onChange={(e) => { this.changePage(e) }}
                    ></Pagination>
                    : ''
                }
            </>
        )
    }
}
