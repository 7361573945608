import React from 'react'
import Papa from "papaparse";
import { Icon } from 'antd';
import Stack from "../../../../components/Stack";

function FileDrop({onDrop = () => {}, disabled = false,files = []}) {

  const fileRef= React.useRef(null)
  const [totalFile, setTotalFile] = React.useState(files.length)
  const [status,setStatus ] = React.useState({
    loading: false,
    success: false,
  })
  const [overlayDrop,setOverlayDrop] = React.useState(false)

  const handleDragEnter = (e) => {
    e.preventDefault();
    setOverlayDrop(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setOverlayDrop(false);
  };

  // for drag and drop
  const handleDrop = async (e) => {
    e.preventDefault();
    setStatus({ loading: true, success: false });
    const files = e.dataTransfer.files;
    const parseData = await parseFiles(files);
    setTotalFile(parseData.length)
    onDrop(parseData)
    setStatus({ loading: false, success: true });
  };

  const configureContent = {
    default: (
        <>
           <div className="upload-drag-info">
              <h3>Select Csv files to import</h3>
              <p className="upload-text">or drag files to this </p>
            </div>
        </>
    ),
    loading: (
        <>
            <p>This is  loading</p>
        </>
    ),
    success: (
            <>
            <Stack gap={6}>
                <Icon type="check-circle"  theme="filled" twoToneColor="#eb2f96"style={{
                    color: '#77E045',
                    fontSize: '30px',
                }} />
                <div style={{
                    color: '#62905A',
                    fontWeight: '500'
                }}>
                {totalFile}  {totalFile> 1 ? 'files are' : 'file is'} uploaded Successfully 
                </div>

            </Stack>
            </>
    
        )
  }
  const content = configureContent[files.length > 0 ? "success" : status.loading ? "loading" : "default"]
  return (
        <div
            className={`upload-container ${!disabled ? "disabled" : ""}`}
            onClick={() => fileRef.current.click()}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <input
              type="file"
              ref={fileRef}
              multiple
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              hidden
              onChange={async (e) => {
                setStatus({ loading: true, success: false });
                const files = e.target.files;
                const parseData = await parseFiles(files);
                setTotalFile(parseData.length)
                onDrop(parseData)
                setStatus({ loading: false, success: true });
              }}
            />

           {content}
          </div>
  )
}
async function parseFiles(files) {
    const fileData = [];
    for (let i = 0; i < files.length; i++) {
      await new Promise((resolve, reject) => {
        Papa.parse(files[i], {
          header: true,
          complete: (results) => {
            fileData.push({
              data: results.data,
              name: files[i].name,
            });
            resolve();
          },
          error: (error) => {
            reject(error);
          },
        });
      });
    }
    return fileData;
  }

export default FileDrop