import { get } from 'lodash'
import React from 'react'
import calculatePercentage from '../../demandSupply-utils'
import Styles from '../../DemandSupplyDashboard.style'
import SupplyCalendarWrapper from '../SupplyCalendar.style'

const SuppyDashboardStats = ({ supplyStatsData = {} }) => (
  <Styles.FlexContainer justify='flex-start'>
    <SupplyCalendarWrapper.InfoBox>
      <h3>Total Slots Opened</h3>
      <h1>{get(supplyStatsData, 'totalSlotsOpened', 0)}</h1>
    </SupplyCalendarWrapper.InfoBox>
    <SupplyCalendarWrapper.InfoBox>
      <h3>Total Slots Assigned</h3>
      <h1>{get(supplyStatsData, 'assignedBatchSession', 0) + get(supplyStatsData, 'assignedMMSession', 0)}</h1>
    </SupplyCalendarWrapper.InfoBox>
    <SupplyCalendarWrapper.InfoBox>
      <h3>Demo Completion</h3>
      <h1>{calculatePercentage(
          (get(supplyStatsData, 'batchSessionDemoCompleted', 0) + get(supplyStatsData, 'mmsDemoCompleted', 0)),
          (get(supplyStatsData, 'totalBookedBatchSession', 0) + get(supplyStatsData, 'menteeSessionDemoBooked', 0))
        )}%
      </h1>
    </SupplyCalendarWrapper.InfoBox>
    <SupplyCalendarWrapper.InfoBox>
      <h3>Utility</h3>
      <h1>{calculatePercentage(
          (get(supplyStatsData, 'batchSessionDemoCompleted', 0) + get(supplyStatsData, 'mmsDemoCompleted', 0)),
          get(supplyStatsData, 'totalSlotsOpened')
        )}%
      </h1>
    </SupplyCalendarWrapper.InfoBox>
  </Styles.FlexContainer>
)

export default SuppyDashboardStats
