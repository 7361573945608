const oneToOne = 'oneToOne'
const oneToTwo = 'oneToTwo'
const oneToThree = 'oneToThree'
const oneToFour = 'oneToFour'
const oneToFive = 'oneToFive'
const oneToSix = 'oneToSix'
const oneToSeven = 'oneToSeven'
const oneToEight = 'oneToEight'
const oneToNine = 'oneToNine'
const oneToTen = 'oneToTen'
const oneToEleven = 'oneToEleven'
const oneToTwelve = 'oneToTwelve'
const oneToThirty = 'oneToThirty'


export {
  oneToOne,
  oneToTwo,
  oneToThree,
  oneToFour,
  oneToFive,
  oneToSix,
  oneToSeven,
  oneToEight,
  oneToNine,
  oneToTen,
  oneToEleven,
  oneToTwelve,
  oneToThirty
}
