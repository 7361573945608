import gql from "graphql-tag"
import duck from '../../../duck'
import getDataFromLocalStorage from "../../../utils/extract-from-localStorage"

const addEventVariable = async (input) => {
    await duck.query({
      query: gql`
        mutation($input: CommsVariableInput!) {
          addCommsVariable(
            input: $input
            createdByConnectId: "${getDataFromLocalStorage('login.id')}" 
            ) {
              id
              whatsappVariableName
              emailVariableName
              dataField
              createdAt
              createdBy {
                name
              }
              eventsMeta {
                count
              }
            }
          }
        `,
      variables: {
        input
      },
      type: 'commsVariables/add',
      key: 'commsVariables'
    })
  }
  
  export default addEventVariable