import { get } from 'lodash'
import moment from 'moment'
import getSlot from '../../pages/MentorRatingAnalysis/utils'

const convertMentorRatingsData = (mentorRatingsData = []) => {
  const refinedMentorRatingData = {
    name: get(mentorRatingsData, '[0].mentorSession.user.name', '-'),
    meta: get(mentorRatingsData, 'mentorMenteeSessionsMeta.count', 0),
    data: [],
  }

  const menteeData = []
  mentorRatingsData.mentorMenteeSessions.forEach((mentorMenteeSession) => {
    menteeData.push({
      menteeName: get(mentorMenteeSession.menteeSession, 'user.name', '-'),
      menteeComment: get(mentorMenteeSession, 'comment', '-'),
      menteeTopic: get(mentorMenteeSession.topic, 'title', '-'),
      menteeCourse: get(mentorMenteeSession.course, 'title', '-'),
      rating: get(mentorMenteeSession, 'rating', 0) || 0,
      slot: getSlot(mentorMenteeSession.menteeSession),
      bookingDate: moment(get(mentorMenteeSession.menteeSession, 'bookingDate', '')).format('DD-MM-YYYY')
    })
  })

  refinedMentorRatingData.data = menteeData
  return refinedMentorRatingData
}

export default convertMentorRatingsData
