import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'
import uploadFile from '../utils/uploadFile'


const updateSenseiMentorTeam = async (props, nameOfTeam, thumbnailFile) => {
  duck.query({
    query: gql`
          mutation{
              updateSenseiMentorTeam(id:"${get(props.senseiProfile, 'senseiMentorTeams[0].id')}",input:{name:"${nameOfTeam}"},senseiProfileConnectId:"${props.senseiId}"){
                  id
                  name
                  teamProfilePic{
                    id
                    uri
                  }
              }
          }
          `,
    type: 'senseiTeams/update',
    key: 'senseiTeams',
    changeExtractedData: (extracted) => {
      if (thumbnailFile) {
        (async function imageUpload() {
          try {
            await uploadFile(thumbnailFile, { fileBucket: 'python' }, { typeField: 'teamProfilePic', typeId: get(props.senseiProfile, 'senseiMentorTeams[0].id'), type: 'SenseiMentorTeam', })
          } catch (error) {
            console.log(error)
          }
        }())
      }
      return {
        ...extracted
      }
    } })
}

export default updateSenseiMentorTeam
