import React, { useState, useRef } from 'react'
import { Button } from 'antd'
import { get } from 'lodash'
import { CSVLink } from 'react-csv'
import { DownloadOutlined } from '@ant-design/icons'
import requestToGraphql from '../../../utils/requestToGraphql'
import gql from 'graphql-tag';
import { getMentorRating } from '../common-util/ums-utils'
import getFullPath from '../../../utils/getFullPath'

const DownloadMentors = (props) => {
  const downloadRef = useRef();
  const [mentorData, setMentorData] = useState([])
  const [loading, setLoading] = useState(false)
  const perPageQueries = props.perPageQueries
  const curPage = props.curPage

  const getCapitalized = (str = '') => {
    const str2 = str.charAt(0).toUpperCase() + str.slice(1);
    return str2
  }

  const fetchMentorData = async () => {
    let mentorData = []
    setLoading(true)
    const { data: { usersMeta: { count: totalUserCount } } } = await requestToGraphql(gql`
          {
        usersMeta(filter: {
          and: [
            { role: mentor }
            { secondaryRole_exists: false }
          ]
        }) {
          count
        }
      }
    `)
    let loopCount;
    if (totalUserCount > perPageQueries) loopCount = (totalUserCount + perPageQueries - 1) / perPageQueries
    else loopCount = 2
    let dataCount = 1
    for (let i = curPage; i < loopCount; i++) {
      const { data } = await requestToGraphql(gql`
          {
          users(
          filter: {
            and: [
              { role: mentor }
              { secondaryRole_exists: false }
            ]
          },
              orderBy: createdAt_DESC,
              ${perPageQueries ? `first: ${perPageQueries}` : ''}
              skip: ${perPageQueries ? perPageQueries * (i - 1) : 0}
                  ) {
              id
              name
              username
              email
              role
              profilePic {
                id
                uri
              }
              userBankDetail {
                id
                bankName
                accountNumber
                ifscCode
                branchName
                documentFile {
                  id
                  uri
                }
              }
              mentorProfile {
                id
                isMentorActive
                experienceYear
                sessionLink
                googleMeetLink
                meetingId
                meetingPassword
                knownLanguages{
                  value
                }
                pythonCourseRating5
                pythonCourseRating4
                pythonCourseRating3
                pythonCourseRating3
                pythonCourseRating2
                pythonCourseRating1
                studentProfile {
                  id
                  user {
                      id
                    }
                  }
                }
                phone{
                  countryCode
                  number
                }
                status
                gender
                dateOfBirth
                createdAt
                updatedAt
                savedPassword
                fromReferral
                country
                timezone
                phoneVerified
              }}
          `)
      let tempData = []
      for (let j = 0; j < get(data, 'users', []).length; j++) {
        let curData = {}
        curData.no = dataCount
        curData.name = get(data, `users[${j}].name`);
        curData.username = get(data, `users[${j}].username`)
        curData.email = get(data, `users[${j}].email`)
        curData.dob = get(data, `users[${j}].dateOfBirth`)
        curData.phone = `${get(data, `users[${j}].phone.countryCode`)} ${get(data, `users[${j}].phone.number`)}`
        curData.isMentorActive = get(data, `users[${j}].mentorProfile.isMentorActive`) ? 'YES' : 'NO'
        curData.mentorRating = getMentorRating(get(data, `users[${j}].mentorProfile`))
        curData.phoneVerified = get(data, `users[${j}].phoneVerified`);
        curData.sessionLink = get(data, `users[${j}].mentorProfile.sessionLink`)===null?'':get(data, `users[${j}].mentorProfile.sessionLink`)
        curData.meetingId = get(data, `users[${j}].mentorProfile.meetingId`)===null?'':get(data, `users[${j}].mentorProfile.meetingId`)
        curData.meetingPassword = get(data, `users[${j}].mentorProfile.meetingPassword`)===null?'':get(data, `users[${j}].mentorProfile.meetingPassword`)
        curData.googleMeetLink = get(data, `users[${j}].mentorProfile.googleMeetLink`)===null?'':get(data, `users[${j}].mentorProfile.googleMeetLink`)
        curData.accountNumber = get(data, `users[${j}].userBankDetail.accountNumber`)
        curData.bankName = get(data, `users[${j}].userBankDetail.bankName`)
        curData.branchName = get(data, `users[${j}].userBankDetail.branchName`)
        curData.ifscCode = get(data, `users[${j}].userBankDetail.ifscCode`)
        curData.documentFileUri = getFullPath(get(data, `users[${j}].userBankDetail.documentFile.uri`) || '')
        curData.profilePicUri = getFullPath(get(data, `users[${j}].profilePic.uri`) || '')
        curData.knownLanguages = get(data,`users[${j}].mentorProfile.knownLanguages`)?get(data,`users[${j}].mentorProfile.knownLanguages`).map((languagesObj)=>{return getCapitalized(get(languagesObj,'value'))}):''
        ++dataCount
        tempData.push(curData)
      }
      mentorData = [...mentorData, ...tempData]
    }


    setMentorData(mentorData)
    setLoading(false)
    downloadRef.current && downloadRef.current.link.click()
  }
  return (
    <>
      <CSVLink headers={props.headers()} data={mentorData} ref={downloadRef} display={{ display: 'none' }} filename='mentor_users.csv' />
      <Button type='primary' style={{ marginTop: '10px', width: 210 }} loading={loading} onClick={fetchMentorData}>
        {!loading && <DownloadOutlined />} {'Download User\'s Data'}
      </Button>
    </>
  )
}

export default DownloadMentors