import React, { useEffect, useRef, useState } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import CoursePackageDetails from "./components/CoursePackageDetails/CoursePackageDetails";
import SelectCourses from "./components/SelectCourses/SelectCourses";
import SelectClasses from "./components/SelectClassses/SelectClasses";
import { get, sortBy } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { notification, Spin, Tooltip } from "antd";
import {
  CoursePackageContainer,
  StyledButton,
  ButtonContainer,
  NavigationButtonContainer,
  NavigationButton,
  TopNavigationContainer,
} from "./CoursePackage.style";
import getIdArrForQuery from "../../utils/getIdArrForQuery";
import SessionSequence from "./components/SessionSequence/SessionSequence";
import addCoursePackage from "../../actions/coursePackage/addCoursePackage";
import fetchCoursePackage from "../../actions/CoursePackageMaker/fetchCoursePackage";
import RevisionSession from "./components/RevisionSession/RevisionSession";
import updateCoursePackages from "../../actions/CoursePackageMaker/updateCoursePackages";
import usePrevious from "./usePrevious";
import syncCoursePackageMappings from "./syncCoursePackageMappings";

const headings = {
  1: "Package Details",
  2: "Add Courses",
  3: "Add Sessions to Package",
  4: "Add Number of revision Sessions",
  5: "Sequence of Sessions",
};

const CoursePackage = ({
  fetchedCourses,
  fetchCourseStatus,
  coursePackage,
  addCoursePackageStatus,
  fetchCoursePackageStatus,
  allState,
  updateCoursePackageStatus,
}) => {
  const history = useHistory();
  const params = useParams();
  const [currentStep, setCurrentStep] = useState(1);
  const [choosenCourses, setChoosenCourses] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [input, setInput] = useState({ category: "technology" });
  const [canGetTopics, setCanGetTopics] = useState(false);
  const [loading, setLoading] = useState(false);
  const [topicIdArray, setTopicIdArray] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [topicObj, setTopicObj] = useState([]);
  const [packageData, setPackageData] = useState(null);
  const [mapCourseWithTopic, setMapCourseWithTopic] = useState([]);
  const [revision, setRevision] = useState(null);
  const [revisionSessionArray, setRevisionSessionArray] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectAll, setSelectAll] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [navButtonStatus, setNavButtonStatus] = useState({});
  const [isTeacherTraining, setIsTeacherTraining] = useState(false);
  const [isTopicsChanged, setIsTopicsChanged] = useState(false);
  const prevAddCourseStatus = usePrevious(addCoursePackageStatus);
  const prevUpdateCourseStatus = usePrevious(updateCoursePackageStatus);

  useEffect(() => {
    if (get(params, "packageId")) {
      const obj = { 1: 1, 2: 1, 3: 0, 4: 1, 5: 0 };
      setNavButtonStatus(obj);
      fetchCoursePackage(get(params, "packageId"));
    } else {
      const obj = { 1: 1, 2: 0, 3: 0, 4: 0, 5: 0 };
      setNavButtonStatus(obj);
    }
  }, []);

  useEffect(() => {
    if (
      fetchedCourses &&
      fetchCourseStatus &&
      !get(fetchCourseStatus.toJS(), "loading") &&
      get(fetchCourseStatus.toJS(), "success")
    ) {
      setAllCourses(fetchedCourses.toJS());
    }
  }, [fetchedCourses]);

  useEffect(() => {
    if (get(params, "packageId")) {
      const result = coursePackage && coursePackage.toJS();
      getPackageDetails(result);
    }
  }, [coursePackage]);

  useEffect(() => {
    if (get(params, "packageId")) {
      const result = coursePackage && coursePackage.toJS();
      setMappedCourses(result);
    }
  }, [choosenCourses]);

  useEffect(() => {
    if (
      addCoursePackageStatus &&
      !get(addCoursePackageStatus.toJS(), "loading") &&
      get(addCoursePackageStatus.toJS(), "success") &&
      prevAddCourseStatus !== undefined &&
      prevAddCourseStatus !== addCoursePackageStatus
    ) {
      setShowLoader(false);
      notification.success({
        message: "Package added successfully",
      });
    }
  }, [addCoursePackageStatus]);

  useEffect(() => {
    if (
      updateCoursePackageStatus &&
      !get(updateCoursePackageStatus.toJS(), "loading") &&
      get(updateCoursePackageStatus.toJS(), "success") &&
      prevUpdateCourseStatus !== undefined &&
      prevUpdateCourseStatus !== updateCoursePackageStatus
    ) {
      notification.success({
        message: "Package updated successfully",
      });
    }
  }, [updateCoursePackageStatus]);

  useEffect(() => {
    let columnsFromBackend = [];
    let columnLength = columnsFromBackend.length;
    choosenCourses
      .filter((course, idx) => idx >= columnLength)
      .forEach((course, idx) => {
        columnsFromBackend = [
          ...columnsFromBackend,
          {
            [`${idx + columnLength}a`]: {
              items: (sortBy(get(course, "topics", []), "order") || [])
                .filter((item, idx) => get(item, "status") == "published")
                .map((item) => {
                  return {
                    ...item,
                    checked: false,
                    visibility: true,
                    courseTitle: course.title,
                    courseCategory: course.category,
                  };
                }),
            },
            [`${idx + columnLength}b`]: {
              items: (sortBy(get(course, "topics", []), "order") || [])
                .filter((item, idx) => get(item, "status") == "published")
                .map((item) => {
                  return {
                    ...item,
                    checked: false,
                    visibility: false,
                    courseTitle: course.title,
                    courseCategory: course.category,
                  };
                }),
            },
          },
        ];
      });
    handleColumns(columnsFromBackend);
    const selectAllState = {};
    columnsFromBackend.forEach((item, idx) => {
      const sourceColumnId = `${idx}a`;
      const sourceItems = columnsFromBackend[idx][sourceColumnId];
      const copiedItems = [...sourceItems.items];
      let temp = 0;
      copiedItems.forEach((obj) => {
        if (obj.checked) {
          ++temp;
        }
      });
      selectAllState[idx] = temp === copiedItems.length ? true : false;
    });
    setSelectAll(selectAllState);
  }, [choosenCourses]);

  const handleColumns = (values) => {
    setColumns([...values]);
  };

  const getPackageDetails = (res) => {
    if (Object.keys(res).length) {
      setPackageData(res);
      const input = {
        category: "technology",
        title: get(res, "title", ""),
        minGrade: get(res, "minGrade", ""),
        maxGrade: get(res, "maxGrade", ""),
        description: get(res, "description", ""),
        internalName: get(res, "internalName", ""),
      };
      setInput(input);
      setIsTeacherTraining(get(res, "packageType") === "normal" ? false : true);
      setRevision(get(res, "revisionSessionCount"));
      let selectedTopics = get(res, "topics"); //selected topics of all courses
      const revisionArray = [];
      selectedTopics.forEach((item, index) => {
        if (item.isRevision) {
          item.id = `id_${index}`;
          revisionArray.push(item);
        }
      });
      setRevisionSessionArray(revisionArray);
      const courses = get(res, "courses", []);
      let choosenCourses = [];
      let choosenTopics = {};
      if (courses && courses.length) {
        for (let i = 0; i < courses.length; i++) {
          const choosenCourse = {
            id: get(courses[i], "id", ""),
            category: get(courses[i], "category", ""),
            choosenGrade: get(courses[i], "minGrade", ""),
            maxGrade: get(courses[i], "maxGrade", ""),
            minGrade: get(courses[i], "minGrade", ""),
            title: get(courses[i], "title", ""),
            topics: sortBy(get(courses[i], "topics", []), "order"),
          };
          choosenCourses.push(choosenCourse);
        }
      }
      let mapCourseWithTopic = [];
      let topicIdArrayCopy = [];
      for (let i = 0; i < selectedTopics.length; i++) {
        const targetTopicId = get(selectedTopics[i], "topic.id", "");
        const classType = get(selectedTopics[i], "topic.classType", "");
        const chapterTitle = get(selectedTopics[i], "topic.chapter.title", "");
        const order = get(selectedTopics[i], "order", "");
        for (let j = 0; j < courses.length; j++) {
          const topics = sortBy(get(courses[j], "topics", []), "order");
          const courseCategory = get(courses[j], "category", "");
          const isMandatory =
            (courseCategory === "tools" || courseCategory === "programming") &&
            classType === "theory";
          for (let z = 0; z < topics.length; z++) {
            if (targetTopicId === get(topics[z], "id", "")) {
              topicIdArrayCopy.push({ topicConnectId: targetTopicId, order });
              const temp = {
                title: get(topics[z], "title", ""),
                id: targetTopicId,
                courseTitle: get(courses[j], "title", ""),
                checked: true,
                visibility: true,
                chapterName: chapterTitle,
                courseCategory: courseCategory,
                isMandatory: isMandatory,
                order,
                course: j,
                topic: z,
                courseId: get(courses[j], "id", ""),
              };
              mapCourseWithTopic.push(temp);
            }
          }
        }
      }
      handleTopics(topicIdArrayCopy);
      setTopicObj(selectedTopics);
      setChoosenCourses(choosenCourses);
      setMapCourseWithTopic(mapCourseWithTopic);
    }
  };

  const setMappedCourses = (res) => {
    if (Object.keys(res).length) {
      let selectedTopics = get(res, "topics"); //selected topics of all courses

      let mapCourseWithTopic = [];
      for (let i = 0; i < selectedTopics.length; i++) {
        const targetTopicId = get(selectedTopics[i], "topic.id", "");
        const classType = get(selectedTopics[i], "topic.classType", "");
        const chapterTitle = get(selectedTopics[i], "topic.chapter.title", "");
        const order = get(selectedTopics[i], "order", "");
        for (let j = 0; j < choosenCourses.length; j++) {
          const topics = sortBy(get(choosenCourses[j], "topics", []), "order");
          const courseCategory = get(choosenCourses[j], "category", "");
          const isMandatory =
            (courseCategory === "tools" || courseCategory === "programming") &&
            classType === "theory";
          for (let z = 0; z < topics.length; z++) {
            if (targetTopicId === get(topics[z], "id", "")) {
              const temp = {
                title: get(topics[z], "title", ""),
                id: targetTopicId,
                courseTitle: get(choosenCourses[j], "title", ""),
                checked: true,
                visibility: true,
                chapterName: chapterTitle,
                courseCategory: courseCategory,
                isMandatory: isMandatory,
                order,
                course: j,
                topic: z,
                courseId: get(choosenCourses[j], "id", ""),
              };
              mapCourseWithTopic.push(temp);
            }
          }
        }
      }
      setMapCourseWithTopic(mapCourseWithTopic);
    }
  };

  const handleCourses = (course, operation) => {
    if (operation == "add") {
      setChoosenCourses([...choosenCourses, ...course]);
    }
    if (operation == "delete") {
      setChoosenCourses(course);
    }
  };
  const handleTopics = (idArray) => {
    setTopicIdArray(idArray);
  };
  const handleInput = (type, value) => {
    if (type == "title" && value != "") setInput({ ...input, title: value });
    if (type == "internalName" && value != "")
      setInput({ ...input, internalName: value });
    if (type == "description") setInput({ ...input, description: value });
    if (type == "minGrade" && value != "-")
      setInput({ ...input, minGrade: value });
    if (type == "maxGrade" && value != "-")
      setInput({ ...input, maxGrade: value });
  };

  const handleRevision = (value) => {
    setRevision(value);
  };

  const handleSetSessionArray = (values) => {
    setTopicObj(values);
  };

  const handleRevisionSessionArray = (values) => {
    setRevisionSessionArray(values);
  };

  const getFormComponentOnStep = (step) => {
    switch (step) {
      case 1: {
        return (
          <CoursePackageDetails
            handleInput={handleInput}
            input={input}
            packageData={packageData}
            isTeacherTraining={isTeacherTraining}
            setIsTeacherTraining={setIsTeacherTraining}
          />
        );
      }
      case 2: {
        return (
          <SelectCourses
            fetchedCourses={(fetchedCourses && fetchedCourses.toJS()) || []}
            setSelectedCourse={setSelectedCourse}
            selectedCourse={selectedCourse}
            fetchCourseStatus={fetchCourseStatus}
            choosenCourses={choosenCourses}
            handleCourses={handleCourses}
            isTeacherTraining={isTeacherTraining}
            setIsTopicsChanged={setIsTopicsChanged}
          />
        );
      }
      case 3: {
        return (
          <SelectClasses
            mapCourseWithTopic={mapCourseWithTopic}
            choosenCourses={choosenCourses}
            handleTopics={handleTopics}
            canGetTopics={canGetTopics}
            setTopicObj={setTopicObj}
            columns={columns}
            handleColumns={handleColumns}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            setIsTopicsChanged={setIsTopicsChanged}
          />
        );
      }
      case 4: {
        return (
          <RevisionSession
            revision={revision}
            handleRevision={handleRevision}
            revisionSessionArray={revisionSessionArray}
            handleRevisionSessionArray={handleRevisionSessionArray}
            sessionArray={topicObj}
          />
        );
      }
      case 5: {
        return (
          <SessionSequence
            setIsTopicsChanged={setIsTopicsChanged}
            setTopicIdArray={setTopicIdArray}
            topicIdArray={setTopicObj}
            choosenCourses={choosenCourses}
            sessionArray={topicObj}
            handleSetSessionArray={handleSetSessionArray}
            mapCourseWithTopic={mapCourseWithTopic}
            revisionSessionArray={revisionSessionArray}
            isTeacherTraining={isTeacherTraining}
          />
        );
      }
      default: {
        return <CoursePackageDetails />;
      }
    }
  };

  const getTopicsByOrder = () => {
    let arr = topicIdArray;
    let orderValue = 1;
    if (topicObj) {
      for (let i = 0; i < topicObj.length; i++) {
        const id = get(topicObj[i], "id", "");
        for (let j = 0; j < arr.length; j++) {
          arr[i].isMandatory = topicObj[i].isMandatory;
          if (get(arr[j], "topicConnectId", "") === id) {
            arr[j].order = orderValue++;
            break;
          }
        }
      }
      revisionSessionArray.forEach((object) => {
        const tempObj = {};
        tempObj.title = get(object, "title");
        tempObj.description = get(object, "description");
        tempObj.isRevision = get(object, "isRevision");
        tempObj.previousTopicOrder = get(object, "previousTopicOrder");
        tempObj.revisionOrder = get(object, "revisionOrder");
        arr.push(tempObj);
      });
    }
    return arr;
  };

  const handleNavButtonClick = (button) => {
    if (get(params, "packageId")) {
      if (button == 2) {
        setCanGetTopics(true);
        const newObj = { ...navButtonStatus };
        newObj[3] = 1;
        setNavButtonStatus(newObj);
      }
      if (button == 3) {
        const newObj = { ...navButtonStatus };
        newObj[5] = 1;
        setNavButtonStatus(newObj);
      }
    }
    setCurrentStep(button);
  };

  const renderNavigationButtons = () => (
    <TopNavigationContainer>
      {get(params, "packageId") ? (
        <h2 style={{ marginBottom: "15px", letterSpacing: "0.03em" }}>
          {get(input, "title")} (Grade {get(input, "minGrade")}-
          {get(input, "maxGrade")})
        </h2>
      ) : null}
      <NavigationButtonContainer>
        {[1, 2, 3, 4, 5].map((button) => (
          <>
            <Tooltip title={headings[button]} placement="bottom">
              <NavigationButton
                isSelected={currentStep === button}
                onClick={() => handleNavButtonClick(button)}
                disabled={!navButtonStatus[button]}
                style={{
                  border: navButtonStatus[button] ? "#8C61CB" : "#A8A7A7",
                  cursor: navButtonStatus[button] ? "pointer" : "default",
                }}
              >
                <span
                  style={{
                    background: navButtonStatus[button] ? "#8C61CB" : "#A8A7A7",
                  }}
                >
                  {button}
                </span>
              </NavigationButton>
            </Tooltip>
            {button < 5 && (
              <ArrowRightOutlined
                style={{ marginRight: "40px", color: "#A8A7A7" }}
              />
            )}
          </>
        ))}
      </NavigationButtonContainer>
    </TopNavigationContainer>
  );

  const showErrorMessage = () => {
    if (currentStep === 1) {
      if (
        !get(input, "title") ||
        get(input, "title").trim().length === 0 ||
        !get(input, "internalName") ||
        get(input, "internalName").trim().length === 0 ||
        !get(input, "minGrade") ||
        !get(input, "maxGrade")
      ) {
        notification.warn({
          message: "Input fields cannot be empty",
        });
        return true;
      }
      if (
        get(input, "title").trim().length < 3 ||
        get(input, "title").trim().length > 100
      ) {
        notification.warn({
          message: "title characters should be in between 3 to 100",
        });
        return true;
      }
      if (
        get(input, "internalName").trim().length < 3 ||
        get(input, "title").trim().length > 100
      ) {
        notification.warn({
          message: "internalName characters should be in between 3 to 100",
        });
        return true;
      }
      if (
        get(input, "description") &&
        (get(input, "description").trim().length < 6 ||
          get(input, "description").trim().length > 1000)
      ) {
        notification.warn({
          message: "description characters should be in between 6 to 1000",
        });
        return true;
      }
    }
    if (currentStep === 3) {
      if (!topicIdArray.length) {
        notification.warn({
          message: "Please add sessions in package",
        });
        return true;
      }
    }
    if (currentStep === 4) {
      if (revision === null || isNaN(revision)) {
        notification.warn({
          message: "Please add number of revision sessions",
        });
        return true;
      }
      if (revision > topicObj.length) {
        notification.warn({
          message:
            "revision can not be greater than topic selected in previous section",
        });
        return true;
      }
      const filtered =
        revisionSessionArray &&
        revisionSessionArray.filter(
          (item) => item.title === "" && item.title.trim() === ""
        );
      if (filtered.length > 0) {
        notification.warn({
          message: "title can not be empty",
        });
        return true;
      }
    }
    return false;
  };

  const submitCoursePackage = async () => {
    const coursesConnectIds =
      choosenCourses.length > 0 &&
      `[${getIdArrForQuery(
        choosenCourses.map((course, idx) => get(course, "id"))
      )}]`;
    const newInput = {};
    Object.keys(input).forEach((item) => {
      if (input[item] !== null && input[item] !== undefined) {
        newInput[item] = input[item];
      }
    });
    const inputObj = {
      ...newInput,
      revisionSessionCount: revision ? parseInt(revision) : 0,
      packageType: isTeacherTraining ? "teacherTraining" : "normal",
      topics: get(params, "packageId")
        ? { replace: getTopicsByOrder() }
        : getTopicsByOrder(),
    };
    setLoading(true);
    if (get(params, "packageId")) {
      const packageId = get(params, "packageId");
      await updateCoursePackages({
        packageId,
        inputObj,
        coursesConnectIds,
      }).then((res) => {
        if (get(res, "updateCoursePackage.id")) {
          history.push(`/coursePackages/${get(res, "updateCoursePackage.id")}`);
        }
      });
    } else {
      await addCoursePackage(inputObj, coursesConnectIds).then((res) => {
        setShowLoader(true);
        if (get(res, "addCoursePackage.id")) {
          history.push(`/coursePackages/${get(res, "addCoursePackage.id")}`);
        }
      });
    }
    if (isTopicsChanged) {
      await syncCoursePackageMappings(get(params, "packageId"));
    }
  };
  return (
    <>
      {get(
        fetchCoursePackageStatus && fetchCoursePackageStatus.toJS(),
        "loading"
      ) || showLoader ? (
        <div
          style={{
            width: "100%",
            height: "58vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <CoursePackageContainer>
          {renderNavigationButtons()}
          {getFormComponentOnStep(currentStep)}
          <ButtonContainer>
            <div style={{ display: "flex" }}>
              {get(params, "packageId") ? (
                <StyledButton
                  onClick={() =>
                    history.push(`/coursePackages/${get(params, "packageId")}`)
                  }
                  style={{ marginRight: "24px" }}
                  btn={"package-details"}
                >
                  Package Details
                </StyledButton>
              ) : null}
              {currentStep > 1 ? (
                <StyledButton
                  onClick={() => {
                    if (loading) return null;
                    if (currentStep > 1) {
                      setCurrentStep(currentStep - 1);
                    }
                  }}
                  style={{ marginRight: "24px" }}
                  btn={"prev"}
                >
                  Back
                </StyledButton>
              ) : null}
              {currentStep < 5 ? (
                <StyledButton
                  onClick={() => {
                    if (loading) return null;
                    if (showErrorMessage()) {
                      return;
                    }
                    submitCoursePackage();
                  }}
                  btn={"draft"}
                >
                  Save as draft
                </StyledButton>
              ) : null}
            </div>
            {currentStep <= 5 ? (
              <StyledButton
                loading={loading}
                onClick={async () => {
                  if (loading) return null;
                  if (showErrorMessage()) {
                    return;
                  }
                  if (currentStep < 5) {
                    setCurrentStep(currentStep + 1);
                    const newObj = { ...navButtonStatus };
                    newObj[currentStep + 1] = 1;
                    setNavButtonStatus(newObj);
                  }
                  if (currentStep == 2) {
                    setCanGetTopics(true);
                  }
                  if (currentStep == 5) {
                    submitCoursePackage();
                  }
                }}
                btn={currentStep == 5 ? "submit" : "next"}
              >
                {currentStep == 5 ? "Submit" : "Save and Continue"}
              </StyledButton>
            ) : null}
          </ButtonContainer>
        </CoursePackageContainer>
      )}
    </>
  );
};

export default CoursePackage;
