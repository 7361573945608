import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'
import uploadFile from '../utils/uploadFile'
import removeFromUserBankDetailDocumentFile from './eventSetting/removeFromUserBankDetailDocumentFile'

const updateUserBankDetails = async (userBankDetailUpdate,addUserBankDetailsId,webImage,prevImageId) => duck.query({
    query: gql`
    mutation($input: UserBankDetailUpdate!) {
        updateUserBankDetail(input: $input,id:"${addUserBankDetailsId}") {
          id
        }
      }
  `,
  variables: {
    input: {
        bankName: get(userBankDetailUpdate,'bankName'),
        accountNumber: get(userBankDetailUpdate,'accountNumber'),
        ifscCode: get(userBankDetailUpdate,'ifscCode'),
        branchName: get(userBankDetailUpdate,'branchName'),
    },
},
    type: 'userBankDetail/update',
    key: 'userBankDetail',
    changeExtractedData: (extractedData, originalData) => {
        extractedData.userBankDetails = get(originalData, 'updateUserBankDetail');
        if (webImage && typeof (webImage) !== 'string' ) {
            (async function imageUpload() {
                try {
                      if (prevImageId) {
                          await removeFromUserBankDetailDocumentFile(get(originalData, 'updateUserBankDetail.id'), prevImageId)
                      }
                    await uploadFile(webImage, {
                        fileBucket: 'python'
                    }, {
                        typeField: 'documentFile', typeId: get(originalData, 'updateUserBankDetail.id'), type: 'UserBankDetail',
                    })
                } catch (error) {
                    console.log(error)
                }
            }())
        }
        return { ...extractedData }
    }
})


export default updateUserBankDetails
