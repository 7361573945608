import gql from 'graphql-tag'
import duck from '../../duck'

const updateAdhocSession = (batchSessionId, input) => {
  duck.query({
    query: gql`
      mutation updateAdhocSession($input: AdhocSessionUpdate){
        updateAdhocSession(
          input: $input,
          id:"${batchSessionId}"
        ) {
          id
        }
      }
    `,
    variables: {
      input,
      callBatchAPI: true
    },
    type: 'adhocSessions/update',
    key: 'adhocSessions'
  })
}

export default updateAdhocSession
