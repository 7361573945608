import React from 'react'
import { Col, Row } from 'antd'
import { SendOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { debounce, get } from 'lodash'
import Styles from './BatchRescheduleModal.style'
import Modal from '../Modal.styles'
import { TekieAmethyst } from '../../../../constants/colors'
import { CalendarSvg } from '../../../../constants/icons'
import updateSchoolBatch from '../../../../actions/SchoolOnboarding/updateSchoolBatch'

const TimePickerFormat = 'hh a'

const rawStyle = {
  position: 'relative',
  textAlign: 'center',
  justifyContent: 'center',
  margin: '20px 25px',
  width: '100%'
}

const pTagStyle = {
  fontFamily: 'Inter',
  letterSpacing: 0,
  fontSize: '16px',
  fontWeight: 'bold',
  marginBottom: '0'
}
const smallTagStyle = {
  fontFamily: 'Inter',
  letterSpacing: 0,
  fontSize: '12px',
  marginBottom: '0'
}

const AvailabilityModal = ({
  isModalVisible = true,
  setModalVisibility,
  ongoingRescheduleDetails,
  occupiedBatchInfo,
  updateBatchStatus,
  rescheduleOrDeleteB2B2CSession,
  isLoading,
}) => {
  const [selectedDateRange, setSelectedDateRange] =
    React.useState({ startDate: null, endDate: null })
  const [selectedTimeRange, setSelectedTimeRange] =
    React.useState({ startHour: null, endHour: null })

  React.useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('........CHECKING', { occupiedBatchInfo, ongoingRescheduleDetails })
  }, [occupiedBatchInfo, ongoingRescheduleDetails])

  const closeModal = (shouldFetch = false) => {
    setModalVisibility(false, shouldFetch)
  }
  /** Queries */

  const onConfirm = async () => {
    const studentIdArr = [get(ongoingRescheduleDetails, 'studentData.student.id')]
    if (studentIdArr && studentIdArr.length) {
      await updateSchoolBatch({
        batchId: get(occupiedBatchInfo, 'id'),
        studentIds: studentIdArr,
      })
    }
    rescheduleOrDeleteB2B2CSession(null, null)
  }

  /** Render Methods */
  const renderModalHeader = () => (
    <>
      <Styles.HeaderIcon>
        <Modal.Icon theme='twoTone'
          marginRight='0px'
          fillSvg={TekieAmethyst}
          component={CalendarSvg}
        />
      </Styles.HeaderIcon>
      <Styles.HeaderDetails>
        <Modal.HeaderTitle>
          Shift Student
        </Modal.HeaderTitle>
        <Modal.HeaderDescription>
          Similar Batch Already Exists.
        </Modal.HeaderDescription>
      </Styles.HeaderDetails>
    </>
  )

  const renderDateTimeSection = () => (
    <>
      {/* Time Picker Section */}
      <Modal.FlexContainer flexDirection='column' style={{ padding: '14px 20px 0px 0px' }}>
        <Styles.SecondaryText>Timings</Styles.SecondaryText>
        <Modal.FlexContainer style={{ padding: '0px', alignItems: 'center' }}>
          <Modal.CustomTimePicker
            use12Hours
            format={TimePickerFormat}
            hideDisabledOptions
            allowClear={false}
            placeholder='Start'
            disabledHours={() => {
              if (new Date().setHours(0, 0, 0, 0)
                === new Date(selectedDateRange.startDate).setHours(0, 0, 0, 0)) {
                return [...Array(new Date().getHours() + 1).keys()].slice(1)
              }
            }}
            onChange={(value) => {
              const selectedRange = { ...selectedTimeRange,
startHour: get(value, '_d').getHours() }
              setSelectedTimeRange(selectedRange)
            }}
          />
        </Modal.FlexContainer>
      </Modal.FlexContainer>
      {/* Date Picker Section */}
      <Modal.FlexContainer flexDirection='column' style={{ padding: '14px 0px 0px 0px' }}>
        <Styles.SecondaryText>Date</Styles.SecondaryText>
        <Modal.FlexContainer style={{ padding: '0px', alignItems: 'center' }}>
          <Modal.CustomDatePicker
            onChange={(value) => {
              const dateRange = { ...selectedDateRange, startDate: value }
              setSelectedDateRange(dateRange)
            }}
            placeholder='Start Date'
            allowClear={false}
            value={selectedDateRange.startDate}
            disabled={!selectedTimeRange.startHour}
            disabledDate={(current) => current &&
              current < new Date().setDate((new Date().getDate()) - 1)
            }
            format='DD MMMM YYYY'
            style={{ width: '100%' }}
          />
        </Modal.FlexContainer>
      </Modal.FlexContainer>
    </>
  )

  const renderModalFooter = () => (
    <Modal.FlexContainer style={{ alignItems: 'center', justifyContent: 'flex-end', width: '100%', padding: 0 }}>
      <Modal.SecondaryButton
        onClick={() => closeModal()}
        style={{ marginRight: '10px' }}
      >
        Cancel
      </Modal.SecondaryButton>
      {get(selectedDateRange, 'startDate', null) && (
        <Modal.SecondaryButton
          style={{ marginRight: '10px' }}
          disabled={get(updateBatchStatus, 'loading')}
          loading={isLoading}
          onClick={() => rescheduleOrDeleteB2B2CSession(
            selectedTimeRange.startHour,
            selectedDateRange.startDate)}
        >
          {isLoading && <Modal.Spinner />}
          Reschedule
        </Modal.SecondaryButton>
      )}
      {get(occupiedBatchInfo, 'id') && (
        <Modal.PrimaryButton
          disabled={isLoading}
          loading={get(updateBatchStatus, 'loading')}
          onClick={debounce(onConfirm, 500)}
        >
          {get(updateBatchStatus, 'loading') && <Modal.Spinner />}
          Shift Student
        </Modal.PrimaryButton>
      )}
    </Modal.FlexContainer>
  )

  return (
    <Modal.WithBackdrop visible={isModalVisible}>
      <Modal.ModalBox visible={isModalVisible} minWidth='500px'>
        <Modal.CloseIcon theme='twoTone'
          onClick={() => closeModal()}
        />
        {/* Modal Header */}
        <Modal.Header>
          {renderModalHeader()}
        </Modal.Header>
        {/* Modal Contents */}
        <Modal.Content>
          <Row style={rawStyle}>
            <Col span={6}>
              <p style={pTagStyle}>Student</p>
              <small style={smallTagStyle}>{get(ongoingRescheduleDetails, 'studentData.student.user.name')}</small>
            </Col>
            <Col span={8}>
              <SendOutlined style={{ fontSize: '50px', color: '#009688' }} />
            </Col>
            <Col span={6}>
              <p style={pTagStyle}>Batch</p>
              <small style={smallTagStyle}>{get(occupiedBatchInfo, 'code')}</small>
            </Col>
          </Row>
          <Modal.FlexContainer flexDirection='row'
            style={{ flexWrap: 'wrap', justifyContent: 'center' }}
          >
            <Styles.SecondaryText style={{ padding: '8px' }}>Or Reschedule it for later</Styles.SecondaryText>
          </Modal.FlexContainer>
          <Modal.FlexContainer flexDirection='row'
            style={{ flexWrap: 'wrap', justifyContent: 'center' }}
          >
            {renderDateTimeSection()}
          </Modal.FlexContainer>
        </Modal.Content>
        {/* Modal Footer */}
        <Modal.Footer>
          {renderModalFooter()}
        </Modal.Footer>
      </Modal.ModalBox>
    </Modal.WithBackdrop>
  )
}

export default withRouter(AvailabilityModal)
