/* eslint-disable */
import React, { useState, useEffect } from 'react'
import './reportCards.scss'
import { databaseIcon } from '../../CoursesCard/assets'
import Reports from '..'
import { fetchSessionReportsData } from '../../../fetchData'
import getFullPath from '../../../../../utils/getFullPath'
import { format } from 'date-fns'
import { get } from 'lodash'

export default function ReportCards({ courseTitle, studentId, courseDetailId, courseType, studentName, coursesCount, batchSessions, courseThumbnail, courseTopics, paymentReports, courseCreationDate }) {
    const downloadLogo = databaseIcon()
    const [showDetailedReport, setShowDetailedReport] = useState(false)
    const [completedSessions, setCompletedSessions] = useState()
    const [courseSessionReports, setCourseSessionReports] = useState([])
    const [dataFetching, setDataFetching] = useState(false)
    const viewReportHandler = () => {
        setShowDetailedReport(!showDetailedReport)
    }

    useEffect(() => {
        fetchCoursesData()
    }, [courseDetailId])

    const fetchCoursesData = async () => {
        let completedSessionsCount = 0
        setDataFetching(true)
        const res = await fetchSessionReportsData(studentId, courseDetailId)
        setCourseSessionReports(get(res, 'mentorMenteeSessions').sort((course1, course2) => {
            return new Date(course1.sessionStartDate) - new Date(course2.sessionStartDate)
        }))
        setDataFetching(false)
        get(res,'mentorMenteeSessions').map(session => {
            if (session.sessionStatus === 'completed') {
                completedSessionsCount++
            }
        })
        setCompletedSessions(completedSessionsCount)
    }

    const progressPercentageHandler = () => {
        let ProgressPercentage = 0
        if (completedSessions === 0) {
            ProgressPercentage = 0
        }
        else {
            ProgressPercentage = ((completedSessions / coursesCount) * 100).toFixed(2)
        }
        return ProgressPercentage
    }

    const startDateHandler = () => {
        if (courseSessionReports.length > 0) {
             if (get(courseSessionReports[0],'sessionStatus')==='completed' || get(courseSessionReports[0],'sessionStatus')==='started') {
                let day = (get(courseSessionReports[0],'sessionStartDate').split('-')[2]).split('T')[0]
                let month = (format(get(courseSessionReports[0],'sessionStartDate'), 'MMMM')).substring(0, 3)
                return `${day} ${month}`
            }
            else {
                    let day = (get(courseSessionReports[0], 'menteeSession.bookingDate').split('-')[2]).split('T')[0]
                    let month = (format(get(courseSessionReports[0], 'menteeSession.bookingDate'), 'MMMM')).substring(0, 3)
                    return `${day} ${month}`
            }
        }
        else {
            return ''
        }
    }
    
    const endDateHandler = () => {
        let lastIndex = courseSessionReports.length - 1
        if (courseTopics.length === completedSessions) {
            let day = (get(courseSessionReports[lastIndex], 'sessionEndDate').split('-')[2]).split('T')[0]
            let month = (format(get(courseSessionReports[lastIndex], 'sessionEndDate'), 'MMMM')).substring(0, 3)
            return `${day} ${month}`
        }
        else {
            return 'Present'
        }
    }
    return (
        <>
        {dataFetching && <div className="skeletonLoaderComponent">
        </div>    
        }
             {!dataFetching && <div className="reportCardContainer">
                 <div className="reportCardTopSectionContainer">
                    <div className="reportCardTopSectionContainerLeftContainer">
                        <img src={getFullPath(courseThumbnail)} alt="" className="courseReportCardImage" />
                        <div className="reportNameAndDurationText">
                            <h2>{courseTitle}</h2>
                            <p>Duration:<span style={{ color: "#333333", fontWeight: '600' }}> {startDateHandler()}-{startDateHandler() === '' ? '' : endDateHandler()}</span></p>
                        </div>
                    </div>
                    <button className="reportCardSessionViewReportBtn" onClick={viewReportHandler}>
                        {downloadLogo}
                        <p>{showDetailedReport ? 'Hide Report' : 'View Report'}</p>
                    </button>
                </div>
                <div className="reportProgressDetailsContainer">
                    <div className="reportProgressDetailsTextContainer">
                        <p>Sessions Complete</p>
                        <p>{`${completedSessions}/${coursesCount}`}</p>
                        <p className="reportProgressPercentageText">{`${progressPercentageHandler()}%`}</p>
                    </div>
                    <div className="reportProgressBarDetailsOuter">
                        <div className="reportProgressBarDetailsInner" style={{ width: `${progressPercentageHandler()}%` }}>
                        </div>
                    </div>
                </div>
            </div>} 
            {showDetailedReport && <Reports courseDetailId={courseDetailId} studentId={studentId} courseTitle={courseTitle} courseType={courseType} studentName={studentName} coursesCount={coursesCount} completedSessions={completedSessions} showDetails={showDetailedReport} batchSessions={batchSessions} courseThumbnail={courseThumbnail} courseSessionReports={courseSessionReports} paymentReports={paymentReports} />}
        </>
    )
}
