import gql from 'graphql-tag'
import duck from '../../duck'

const updateAdhocSession = async (id, input, key) => (
  duck.query({
    query: gql`
      mutation (
        $input: AdhocSessionUpdate
      ) {
        updateAdhocSession(
          id: "${id}",
          input: $input
        ) {
          id
          createdAt
          course {
            id
            title
            thumbnail {
              uri
            }
            secondaryCategory
            topics(filter: {
              status: published
            }) {
              id
              order
              title
              thumbnailSmall {
                uri
              }
            }
          }
          sessionStatus
          sessionStartDate
          sessionEndDate
          sessionRecordingLink
          mentorSession {
            user {
              id
              name
              mentorProfile {
                googleMeetLink
                sessionLink
              }
            }
          }
        }
      }
     `,
    variables: {
      input
    },
    type: (key === 'mentorSession') ? 'mentorSessions/update' : 'mentorMenteeSessions/update',
    key: (key === 'mentorSession') ? 'updateAdhocSession' : 'mentorClasses',
  })
)

export default updateAdhocSession
