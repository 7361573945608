import gql from 'graphql-tag'
import duck from '../../duck'

const addSenseiProfile = async (id) => {
  duck.query({
    query: gql`
        mutation{
            addSenseiProfile(input:{},userConnectId:"${id}"){
                id
            }
        }`,
    type: 'senseiProfile/add',
    key: 'senseiProfile',
  })
}

export default addSenseiProfile
