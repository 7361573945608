const headerConfig = [
  {
    label: 'Student name',
    key: 'name',
  },
  {
    label: 'Grade',
    key: 'grade',
  },
  {
    label: 'Section',
    key: 'section',
  },
  {
    label: 'Classroom Title',
    key: 'classroomTitle',
  },
  {
    label: 'Email',
    key: 'email',
  },
  {
    label: 'Password',
    key: 'password',
  },
  {
    label: 'Roll No.',
    key: 'rollNo',
  },
  {
    label: 'Key',
    key: 'userId',
  },
]

export default headerConfig
