/*eslint-disable*/
import { decodeBase64, isBase64 } from "./base64Utility"

const getDecodedStatement = (data) => {
  if (data) {
    if (isBase64(data)) {
      return decodeBase64(data)
    }
  }
  return data
}

export default getDecodedStatement