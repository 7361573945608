import gql from 'graphql-tag'
import { get } from 'lodash';
import duck from '../../duck';
import uploadFile from '../utils/uploadFile';


const addEventJoinReason = async (input) => {
    const updatedInput = {}
    updatedInput.title = input.details
    return duck.query({
        query: gql`
           mutation addEventJoinReason($input:EventJoinReasonInput!) {
            addEventJoinReason(input:$input) {
                  id
                  title
                }
              }
              `,
        variables: {
            input: updatedInput
        },
        type: 'eventJoinReasons/add',
        key: 'eventJoinReasons',
        changeExtractedData: (extracted, original) => {
            const eventJoinReasonId = get(original, 'addEventJoinReason.id')
            if (input.imageUrl) {
                (async function imageUpload() {
                    try {
                        await uploadFile(input.imageUrl, { fileBucket: 'python' }, { typeField: 'picture', typeId: eventJoinReasonId, type: 'EventJoinReason', })
                    } catch (error) {
                        console.log(error)
                    }
                }())
            }
            return { ...extracted }
        }
    })
}

export default addEventJoinReason