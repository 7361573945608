import gql from "graphql-tag";
import { get } from "lodash";

import duck from "../../duck";

const fetchComments = async (schoolId) => {
	return duck.query({
		query: gql`
			query {
			    school(id: "${schoolId}") {
                    id
					name
					comments {
						comment
						commentDate
						commentBy {
							name
						}
					}
				}
			}
		`,
		type: "school/fetch",
		key: "school",
		changeExtractedData: (extractedData, originalData) => {
			extractedData.school = get(originalData, 'school')
			return { ...extractedData }
		}
	});
};

export default fetchComments;
