import gql from 'graphql-tag'
import duck from '../../duck'

const deleteFile = async (id) => {
  duck.query({
    query: gql`
          mutation{
              deleteFile(id:"${id}") {
                  id
                  name
              }
          }
      `,
    type: 'uploadFiles/delete',
    key: 'uploadFiles',
  })
}

export default deleteFile
