import styled from 'styled-components'
import { Spin } from 'antd'

const StyledSpinner = styled(Spin)`
  &&&{
    display: flex;
    justify-content: center;
  }
`

export default StyledSpinner
