import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const FETCH_BATCH = (id) => gql`
{
  batch(
    id : "${id}"
  ){
    id
    students {
      user {
        id
        name
      }
    }
    coursePackage {
      title
      id
      coursePackageTopics: topics {
        order
        coursePackageTopic: topic {
          id
          title
          thumbnailSmall {
            uri
          }
          learningObjectives {
            id
            title 
          }
        }
      }
    }
  }
}
`
const fetchTrainingClasses = (id) => duck.query({
  query: FETCH_BATCH(id),
  type: 'teacherTrainingClasses/fetch',
  key: `teacherTrainingClasses/${id}`,
  changeExtractedData: (extractedData, originalData) => {
    extractedData.coursePackages = {
      id: get(originalData, 'batch.coursePackage.id'),
      title: get(originalData, 'batch.coursePackage.title'),
      topic: get(originalData, 'batch.coursePackage.coursePackageTopics', []).map(topic => ({
        ...get(topic, 'coursePackageTopic', {}),
        order: get(topic, 'order', 0),
        id: get(topic, 'coursePackageTopic.id', ''),
      }))
    }
    return extractedData
  }
})

export default fetchTrainingClasses
