const userCSVHeaderConfig = {
  menteeHeaderConfig: [
    {
      label: 'Sr. No',
      key: 'no',
    },
    {
      label: 'Name',
      key: 'menteeName',
    },
    {
      label: 'Parent Name',
      key: 'parentName',
    },
    {
      label: 'Parent Email',
      key: 'email',
    },
    {
      label: 'Parent Phone',
      key: 'phone',
    },
    {
      label: 'Phone Verified',
      key: 'phoneVerified',
    },
    {
      label: 'Grade',
      key: 'grade',
    },
    {
      label: 'Status',
      key: 'currentStatus',
    },
    {
      label: 'Booking Details',
      key: 'sessionDetails',
    },
    {
      label: 'Source',
      key: 'fromReferral',
    },
    {
      label: 'Timezone',
      key: 'timezone',
    },
    {
      label: 'Verification Status',
      key: 'verificationStatus',
    },
    {
      label: 'utmCampaign',
      key: 'utmData.utmCampaign',
    },
    {
      label: 'utmContent',
      key: 'utmData.utmContent',
    },
    {
      label: 'utmMedium',
      key: 'utmData.utmMedium',
    },
    {
      label: 'utmSource',
      key: 'utmData.utmSource',
    },
    {
      label: 'utmTerm',
      key: 'utmData.utmTerm',
    },
    {
      label: 'Created At',
      key: 'createdAtString',
    },
    {
      label: 'Updated At',
      key: 'updatedAtString',
    },
  ],
  otherRoleHeaderConfig: [
    {
      label: 'Sr. No',
      key: 'no',
    },
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: 'Username',
      key: 'username',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: 'Gender',
      key: 'gender',
    },
    {
      label: 'D.O.B',
      key: 'dob',
    },
    {
      label: 'Phone',
      key: 'phone',
    },
    {
      label: 'Phone Verified',
      key: 'phoneVerified',
    },
  ]
}

export default userCSVHeaderConfig
