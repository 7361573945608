import { get, sortBy } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { fetchMessageQuestionAndComic } from '../../../../../actions/courseMaker'
import { CHATBOT, COMICSTRIP, LEARNING_OBJECTIVE, MESSAGE, PRACTICE_QUESTION, LEARNING_SLIDE } from '../../../../../constants/CourseComponents'
import { CloseIcon, LoView, TopContainer } from '../../../AddSessions.styles'
import SelectInput from '../../SelectInput'
import { ComicView, MessageView, QuestionView } from '../TopicComponents'
import { Select, Input as InputField, notification } from 'antd'
import { MDTable } from '../../../../AddCourse/AddCourse.styles'

class LearningObjective extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchVal: '',
      selectedLoComponent: []
    }
  }
  componentDidUpdate = async (prevProps) => {
    const { courseId } = this.props
    if (prevProps.courseId !== courseId
      && courseId) {
      await fetchMessageQuestionAndComic(courseId)
    }
  }
  onSelect = (value) => {
    const { onValueSelect, uniqueName } = this.props
    onValueSelect(value, uniqueName, LEARNING_OBJECTIVE)
  }

  onDeselect = (value) => {
    const { onValueDeselect, uniqueName } = this.props
    onValueDeselect(value, LEARNING_OBJECTIVE, uniqueName)
  }

  getQuestionTypeOptions = (selectedData) => {
    let questionTypeFields = []
    if (selectedData && selectedData.length > 0) {
      const { chatbot, comicStrips, learningSlides, messages, questionBank } = selectedData[0]
      if (get(chatbot, 'count') > 0) {
        questionTypeFields.push(CHATBOT)
      }
      if (comicStrips && comicStrips.length > 0) {
        questionTypeFields.push(COMICSTRIP)
      }
      if (learningSlides && learningSlides.length > 0) {
        questionTypeFields.push(LEARNING_SLIDE)
      }
      if (messages && messages.length > 0) {
        questionTypeFields.push(MESSAGE)
      }
      if (questionBank && questionBank.length > 0) {
        questionTypeFields.push(PRACTICE_QUESTION)
      }
    }
    return questionTypeFields
  }

  handleAddInSelect = (value) => {
    const { selectedLoComponent, onValueSelect, uniqueName } = this.props
    const newValue = [
      ...selectedLoComponent,
      {
        ...value,
        componentName: get(value, 'label'),
        order:
          selectedLoComponent.length === 0
            ? 1
            : Math.max(...selectedLoComponent.map((data) => data.order)) + 1,
        id:
          selectedLoComponent.length === 0
            ? 1
            : Math.max(...selectedLoComponent.map((data) => data.order)) + 1,
      },
    ]
    onValueSelect(newValue, uniqueName, LEARNING_OBJECTIVE, false, true) 
  }

  checkifLsContainPq = () => {
    const { selectedData } = this.props
    let learningSlidesTempAns = []
    if (selectedData.length > 0 && get(selectedData[0], 'learningSlides', []).length > 0) {
      const learningSlidesTemp = get(selectedData[0], 'learningSlides')
      learningSlidesTempAns = learningSlidesTemp.filter(data => get(data, 'type') === 'practiceQuestion')
    }
    return learningSlidesTempAns.length > 0 ? true : false
  }

  doesKeyAlreadyExist = () => {
    const { selectedLoComponent } = this.props
    let selectedLoComponentTemp = []
    if (selectedLoComponent && selectedLoComponent.length > 0) {
      selectedLoComponentTemp = selectedLoComponent.filter(item => (get(item, 'key') === 'learningSlide' && this.checkifLsContainPq()) || get(item, 'key') === 'chatbot' || get(item, 'key') === 'practiceQuestion')
    }
    return selectedLoComponentTemp.length > 0 ? true : false
  }
  handleSelectLoComponent = (value) => {
    const { selectedLoComponent } = this.props
    if (get(value, 'key') === 'chatbot') {
      const isChatExist = selectedLoComponent && selectedLoComponent.find(component => get(component, 'componentName') === 'message')
      if (isChatExist) {
        return notification.warn({
          message: 'Cannot add chatbot as message already exists.'
        })
      }
    }
    if (get(value, 'key') === 'message') {
      const isChatExist = selectedLoComponent && selectedLoComponent.find(component => get(component, 'componentName') === 'chatbot')
      if (isChatExist) {
        return notification.warn({
          message: 'Cannot add message as chatbot already exists.'
        })
      }
    }
    if (get(value, 'key') === 'chatbot' || get(value, 'key') === 'practiceQuestion' || (get(value, 'key') === 'learningSlide' && this.checkifLsContainPq())) {
      if (this.doesKeyAlreadyExist()) {
        notification.warn({
          message: 'only practice questions or chatbot or learning slides can be added'
        })
      } else {
        this.handleAddInSelect(value)
      }
    } else {
      this.handleAddInSelect(value)
    }
  }

  handleRemoveLoComponent = ({ label }) => {
    const { selectedLoComponent, onValueDeselect, uniqueName } = this.props
    const newLoComponent = [...selectedLoComponent].filter(
      (compo) => get(compo, 'label') !== label
    )
    newLoComponent.forEach((_, index) => {
      newLoComponent[index].order = index + 1
    })
    onValueDeselect(newLoComponent, LEARNING_OBJECTIVE, uniqueName, false, true)
  }

  handleLoComponentOrderChange = ({ value, name }, compoName) => {
    const { selectedLoComponent, uniqueName, handleChangeOrder } = this.props
    const newComponent = [...selectedLoComponent]
    const isValueExist = newComponent.find(
      (component) => get(component, 'order') === Number(value)
    )
    if (isValueExist) {
      notification.warn({
        message: 'order already exist'
      })
      return
    }
    const isExist = newComponent.find(
      (component) => get(component, 'label') === compoName
    )
    if (isExist) isExist[name] = Number(value)
    const newData = newComponent.filter(
      (component) => get(component, 'label') !== compoName
    )
    handleChangeOrder([...newData, isExist], uniqueName)
  }

  render() {
    const { searchVal } = this.state
    const { learningObectiveFetchingStatus,
      loList, selectedValue,
      selectedData, selectedLoComponent
    } = this.props
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TopContainer justify='center'>
            <SelectInput
              searchVal={searchVal}
              placeholder='Search Lo'
              loading={learningObectiveFetchingStatus && get(learningObectiveFetchingStatus.toJS(), 'loading')}
              values={selectedValue}
              onSelect={this.onSelect}
              onDeselect={this.onDeselect}
              onChange={value => this.setState({ searchVal: value })}
              data={loList}
            />
          </TopContainer>
          {/* <div style={{ marginLeft: '30px', maxWidth: '60%' }}>
            <div>
              <h3>
                Select LO Components :
              </h3>
              <Select
                mode='multiple'
                labelInValue
                placeholder='Type min 3 characters'
                style={{ width: '100%', margin: '10px 0' }}
                filterOption={false}
                value={sortBy(selectedLoComponent, 'id')}
                onSelect={this.handleSelectLoComponent}
                onDeselect={this.handleRemoveLoComponent}
              >
                {this.getQuestionTypeOptions(selectedData).map((item) => (
                  <Select.Option value={item} key={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div>
              <MDTable
                columns={[
                  {
                    title: 'Order',
                    dataIndex: 'order',
                    key: 'order',
                    align: 'center',
                    width: 200,
                    render: (data, component) => (
                      <InputField
                        label='Order'
                        placeholder='Order'
                        name='order'
                        type='order'
                        value={component.order || ''}
                        onChange={({ target }) =>
                          this.handleLoComponentOrderChange(
                            target,
                            get(component, 'label')
                          )
                        }
                      />
                    ),
                  },
                  {
                    title: 'Component Name',
                    dataIndex: 'label',
                    key: 'label',
                    align: 'center',
                  },
                ]}
                dataSource={sortBy(selectedLoComponent, 'id')}
                pagination={false}
              />
            </div>
          </div> */}
        </div>
        <div style={{ width: '100%' }}>
          {
            selectedData.map(({ messages, questionBank, comicStrips, id }) => (
              <LoView
                key={id}
              >
                <CloseIcon
                  style={{ border: '1px solid', right: '8px', padding: '5px', zIndex: '10' }}
                  onClick={() => this.onDeselect({ key: id, id, ...messages, questionBank })}
                />
                {messages && messages.length > 0 && <MessageView messages={messages} />}
                {questionBank && questionBank.length > 0 &&
                  <QuestionView questions={questionBank} />}
                {comicStrips && comicStrips.length > 0 && (
                  <ComicView comicStrips={comicStrips} />
                )}
              </LoView>
            ))
          }
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  learningObectiveFetchingStatus: state.data.getIn(['learningObjectives', 'fetchStatus', 'learningObjectives']),
})

export default connect(mapStateToProps)(LearningObjective)
