import React, { useState } from 'react'
import { Button, Select, Popconfirm } from 'antd'

const EditEventPreview = (props) => {

    const [temp, setTemp] = useState('')
    const [showPopup, setShowPopup] = useState(false)
    const [editPopup, setEditPopup] = useState(false)
    
    const updateStatus = async () => {
      props.editEventStatus(temp)
      setShowPopup(false)
    }

    const updateEditStatus = async () => {
      props.setEdit(!props.edit)
      setEditPopup(false)
    }

    const PopupText = (
      <div style={{ alignItems: 'center' }}>
        <h4 style={{ fontWeight: 'bold' }}>Are you Sure ?</h4>
        <p>{`Do you really want to
        ${temp === 'published' ? 'Publish' :
        temp === 'unpublished' ? 'Draft' :
        temp === 'cancelled' ? 'Cancel' :
        'Reschedule'} 
        ${props.eventStatus === 'draft' ? 'a Drafted' : 
        props.eventStatus === 'published' ? 'a Published': 
        props.eventStatus === 'cancelled' ? 'a Cancelled': 'this'} Event ?`}</p>
        <p>This will change the listing details as well.</p>
      </div>
    )

    const EditPopupText = (
      <div style={{ alignItems: 'center' }}>
        <h4 style={{ fontWeight: 'bold' }}>Are you Sure ?</h4>
        <p>{`Do you really want to edit ${props.eventStatus === 'draft' ? 'a Drafted' : 
        props.eventStatus === 'published' ? 'a Published': 
        props.eventStatus === 'cancelled' ? 'a Cancelled': 'this'} Event ?`}</p>        
        <p>This will change the listing details as well.</p>
      </div>
    )

    return (
      <div style={{ display:'flex', margin: '0 2rem', width: '280px', justifyContent: 'space-evenly', alignItems:'center' }}>
        <Popconfirm
          title={PopupText}
          visible={showPopup}
          onConfirm={updateStatus}
          icon=""
          onCancel={() => setShowPopup(!showPopup)}
          placement='bottom'
          okText='Confirm'
          okType='primary'
          cancelText='Cancel'
          key='toggle'
        >
          <Select
            value={props.eventStatus}
            style={{ width:'150px' }}
            disabled={!props.edit}
            onSelect={(value) => {
              if (value !== props.eventStatus) {
                setShowPopup(true)
                setTemp(value)
              }
            }}
          >
            <Select.Option key='published' value='published'>Published</Select.Option>
            <Select.Option key='rescheduled' value='rescheduled'>Rescheduled</Select.Option>
            <Select.Option key='unpublished' value='unpublished'>Draft</Select.Option>
            <Select.Option key='cancelled' value='cancelled'>Cancelled</Select.Option>
          </Select>
        </Popconfirm>
        <Popconfirm
          title={EditPopupText}
          icon=''
          visible={editPopup}
          onConfirm={updateEditStatus}
          onCancel={() => setEditPopup(!editPopup)}
          placement='bottom'
          okText='Confirm'
          okType='primary'
          cancelText='Cancel'
        >
        <Button
          onClick={() => setEditPopup(true)}
          style={{ width: '100px', fontSize: '0.8rem', background: '#F05C5C', color: '#ffffff' }}
        >
          Edit Event
        </Button>
        </Popconfirm>
      </div>
    )
}

export default EditEventPreview