import styled from 'styled-components';

const StyledFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 24px;
`
const InputContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    label {
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        width: 100%;
        color: #333333;
        margin: 8px 0;
        :after {
            ${props => !props.isTextAreaLabel ? `
                content:" *";
                color: #D34B57;
            ` : ''}
        }
    }
    input {
        padding: 8px 12px;
        height: 42px;
        border: 1px solid #AAAAAA;
        box-sizing: border-box;
        width: 100%;
    }
    textarea {
        border: 1px solid #AAAAAA;
        width: 100%;
    }
`
const FormInputContainer= styled.div`
    width: 451px;
`
const GradeInputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`
const GradeInputDropdown = styled.div`
    display: flex;
    flex-direction: column;
    > h3 {
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        width: 100%;
        color: #333333;
        margin: 8px 0;
        :after {
            content:" *";
            color: #D34B57;
        }
    }
    .ant-select-selection--single {
        position: relative;
        height: 42px;
        cursor: pointer;
        padding: 4px 8px 4px 12px;
        border-radius: 8px;
        border: 1px solid #AAAAAA;
    }
`

const SwitchContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 24px;
    > h3 {
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #333333;
        margin: 8px 15px 8px 0;
    }
`

export {
    StyledFormContainer,
    InputContainer,
    FormInputContainer,
    GradeInputContainer,
    GradeInputDropdown,
    SwitchContainer
}
