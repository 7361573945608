import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../../duck'

const fetchChapters = async (courseId) =>
  duck.query({
    query: gql`
        {
        chapters(
          filter: { 
            courses_some: { id: "${courseId}" } 
          }
        ) {
            id
            order
            status
            courses{
              id
            }
            thumbnail {
              id
              uri
            }
            title
            description
            createdAt
            topics {
              id
              title
            }
        }
        chaptersMeta(filter: { courses_some: { id: "${courseId}" } }) {
          count
        }
      }
    `,
    type: 'chapters/fetch',
    key: 'chapters',
    changeExtractedData: (extractedData, originalData) => {
      extractedData.chapters = get(originalData, 'chapters')
      extractedData.topic = []
      return { ...extractedData }
    },
  })

export default fetchChapters

