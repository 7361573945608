import { PlusOutlined } from '@ant-design/icons'
import { Icon, Input, notification, Spin, Button, Select, Checkbox, Divider, Dropdown, Menu } from 'antd'
import { get, sortBy } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import {
  DragDropContext, Draggable, Droppable
} from 'react-beautiful-dnd'
import { onAddIframeLink } from '../../../ContentMaker/contentUtils'
import {
  filterKey, getOrderAutoComplete, getOrdersInUse,
  getSuccessStatus, isPythonCourse
} from '../../../../utils/data-utils'
import {
  ComponentPool, ComponentTab,
  StyledButton, StyledModal, StyledSwitch, TopContainer
} from '../../AddSessions.styles'
import getComponentName from '../../../../utils/getComponentName'
import dotIcons from '../../../../assets/dotsIcon.png'
import {
  LearningObjective, CodingAssignment,
  Quiz, Project, Practice, Videos, HomeworkPractice
} from './SessionTabs'
import {
  ASSIGNMENT, HOMEWORK_ASSIGNMENT, HOMEWORK_PRACTICE, LEARNING_OBJECTIVE,
  LEARNING_SLIDE,
  PRACTICE,
  PROJECT, QUIZ, VIDEO
} from '../../../../constants/CourseComponents'
import {
  addTopicSession, updateTopicSessions,
  removeComponentFromSession, fetchSingleComponent,
  fetchTopicData,
  fetchMessageQuestionAndComic,
} from '../../../../actions/courseMaker'
import parseChatStatement from '../../../../utils/parseStatement'
import Dropzone from '../../../../components/Dropzone'
import getFullPath from '../../../../utils/getFullPath'
import SearchInput from '../SearchInput'
import HomeWorkAssignment from './SessionTabs/HomeWorkAssignment'
import { PUBLISHED_STATUS, TEACHER_TRAINING, UNPUBLISHED_STATUS, NORMAL } from '../../../../constants/questionBank'
import classType from '../../../../constants/classType'
import { ContentEditor } from '../../../../components/ContentEditor'
import { encodeBase64, isBase64 } from '../../../../utils/base64Utility'
import IframePreview from './IframePreview'
import './style.scss'
import getDecodedStatement from '../../../../utils/getDecodedStatement'

class SessionModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      order: 0,
      sessionTopic: '',
      sessionDescription: '',
      selectedComponents: [],
      selectedTab: '',
      videoList: [],
      quizList: [],
      projectList: [],
      practiceList: [],
      loList: [],
      assignmentList: [],
      chaptersList: [],
      homeworkAssignmentList: [],
      homeworkPracticeList: [],
      selectedChapter: '',
      externalDescriptionEnabled: false,
      referenceContent: '',
      referenceContentLink: '',
      initialReferenceContent: '',
      imageFile: null,
      imageUrl: null,
      smallThumbnail: null,
      smallThumbnailUrl: null,
      isTrial: false,
      courseId: get(props, 'match.params.courseId'),
      selectedCourseLo: [],
      showLoading: false,
      status: PUBLISHED_STATUS,
      tools: [],
      programming: [],
      theory: [],
      classType: '',
      showIframeViewModal: false,
      iframeViewWidth: 500,  
      contentType: NORMAL,
      contentStatus: UNPUBLISHED_STATUS,
    }
  }
  componentDidMount = async () => {
    const { sessionData, operation, editData, match, contentType } = this.props
    const courseIdFromRoute = get(match, 'params.courseId')
    if (operation === 'add') {
      const orders = getOrdersInUse(sessionData)
      const orderAutoComplete = getOrderAutoComplete(orders)
      this.setState({
        order: orderAutoComplete,
        isTrial: true,
        tools: [{ value: '' }],
        theory: [{ value: '' }],
        programming: [{ value: '' }],
        classType: classType.lab,
        contentType: contentType === 'allCourses' ? NORMAL : contentType 
      })
      const { chapters: chaptersList = [] } =
        await fetchTopicData(null, courseIdFromRoute)
      const { learningObjectives: loData } = await fetchMessageQuestionAndComic(courseIdFromRoute)
      this.setState({
        chaptersList,
        selectedCourseLo: loData || []
      })
    } else if (operation === 'edit') {
      this.setState({
        showLoading: true
      })
      const { learningObjectives: loData } = await fetchMessageQuestionAndComic(courseIdFromRoute)
      this.setState({
        order: get(editData, 'order'),
        isTrial: get(editData, 'isTrial'),
        sessionTopic: get(editData, 'title'),
        referenceContent: get(editData, 'referenceContent'),
        sessionDescription: get(editData, 'description', ''),
        selectedCourseLo: loData || [],
        status: get(editData, 'status'),
        contentType: get(editData, 'contentType'),
        contentStatus: get(editData, 'contentStatus'),
      }, () => this.setDefaultValue())
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { videoFetchingStatus, questionBankFetchingStatus,
      projectFetchingStatus, practiceFetchingStatus,
      learningObectiveFetchingStatus, assignmentFetchingStatus,
      homeworkAssignmentFetchStatus, homeworkPracticeFetchStatus } = this.props
    if (getSuccessStatus(videoFetchingStatus, prevProps.videoFetchingStatus)) {
      this.setComponentSelectorList('videoList', this.props.videoData ?
        this.props.videoData.toJS() : [])
    }
    if (getSuccessStatus(questionBankFetchingStatus, prevProps.questionBankFetchingStatus)) {
      this.setComponentSelectorList('quizList', this.props.questionBankData ?
        this.props.questionBankData.toJS() : [])
    }
    if (getSuccessStatus(projectFetchingStatus, prevProps.projectFetchingStatus)) {
      this.setComponentSelectorList('projectList', this.props.projectData ?
        this.props.projectData.toJS() : [])
    }
    if (getSuccessStatus(practiceFetchingStatus, prevProps.practiceFetchingStatus)) {
      this.setComponentSelectorList('practiceList', this.props.practiceData ?
        this.props.practiceData.toJS() : [])
    }
    if (getSuccessStatus(learningObectiveFetchingStatus,
      prevProps.learningObectiveFetchingStatus)) {
      this.setComponentSelectorList('loList', this.props.learningObjectiveData ?
        this.props.learningObjectiveData.toJS() : [])
    }
    if (getSuccessStatus(assignmentFetchingStatus, prevProps.assignmentFetchingStatus)) {
      this.setComponentSelectorList('assignmentList', this.props.assignmentData ?
        this.props.assignmentData.toJS() : [])
    }

    if (getSuccessStatus(homeworkAssignmentFetchStatus, prevProps.homeworkAssignmentFetchStatus)) {
      this.setComponentSelectorList('homeworkAssignmentList', this.props.homeworkAssignmentData ?
        this.props.homeworkAssignmentData.toJS() : [])
    }
    if (getSuccessStatus(homeworkPracticeFetchStatus, prevProps.homeworkPracticeFetchStatus)) {
      this.setComponentSelectorList('homeworkPracticeList', this.props.homeworkPracticeData ?
        this.props.homeworkPracticeData.toJS() : [])
    }
    const { selectedTab, courseId } = this.state
    const isLOTabActive = selectedTab.includes(LEARNING_OBJECTIVE)
    if (prevState.courseId !== courseId && isLOTabActive) {
      await fetchMessageQuestionAndComic(courseId)
    }
  }

  setComponentSelectorList = (type, componentData = []) => {
    const { courseId } = this.state
    const { match } = this.props
    const courseIdFromRoute = get(match, 'params.courseId')
    if (type === 'quizList') {
      componentData = componentData.filter(data =>
        get(data, 'courses', []).map(course => get(course, 'id')).includes(courseIdFromRoute))
    } else {
      componentData = componentData.filter(data =>
        get(data, 'courses', []).map(course => get(course, 'id')).includes(courseId))
    }
    this.setState({
      [type]: componentData
    })
  }
  getComponentCount = (componentName) => {
    const { selectedComponents } = this.state
    const isExist = selectedComponents.find(component =>
      get(component, 'componentName') === componentName)
    if (isExist) {
      const componentArray = selectedComponents.filter(component =>
        get(component, 'componentName') === componentName)
      return componentArray.length
    }
    return 0
  }
  renderComponentPool = () => {
    const { coursesList, match } = this.props
    const courseIdFromRoute = get(match, 'params.courseId')
    const { selectedComponents } = this.state
    let components = []
    if (courseIdFromRoute && coursesList.length > 0) {
      components = get(coursesList.find(course =>
        get(course, 'id') === courseIdFromRoute), 'courseComponentRule')
    }
    if (components.length > 0) {
      const componentPool = get(coursesList.find(course =>
        get(course, 'id') === courseIdFromRoute), 'courseComponentRule')
      return (
        <ComponentPool>
          {sortBy(componentPool, 'order').map(component => (
            <div className='componentTags'
              selected={selectedComponents.map(compo =>
                get(compo, 'componentName')).includes(get(component, 'componentName'))}
              onClick={() => this.handleSelectComponent(component)}
            >
              <p>
                {getComponentName(get(component, 'componentName'))}
                <span>
                  ({this.getComponentCount(get(component, 'componentName'))})
                </span>
              </p>
              <PlusOutlined />
            </div>
          ))}
        </ComponentPool>
      )
    }
    return <h3><strong>No Component Available</strong></h3>
  }
  handleSelectComponent = (component) => {
    const { selectedComponents } = this.state
    const nextOrder = selectedComponents.length === 0 ? 1 :
      Math.max(...selectedComponents.map((data) => data.order)) + 1
    let newComponents = []
    if (get(component, 'componentName') === QUIZ || get(component, 'componentName') === ASSIGNMENT
      || get(component, 'componentName') === HOMEWORK_ASSIGNMENT) {
      const isExist = selectedComponents.find(comp => get(comp, 'componentName') === get(component, 'componentName'))
      if (!isExist) {
        newComponents = [...selectedComponents, {
          ...component,
          order: nextOrder,
          uniqueName: `${get(component, 'componentName')}${nextOrder}`,
          selectedValue: [],
          selectedData: [],
          selectedLo: []
        }]
      } else {
        notification.warn({
          message: 'Component Already added'
        })
        return
      }
    } else {
      const { match, coursesList } = this.props
      const courseIdFromRoute = get(match, 'params.courseId')
      const componentPool = get(coursesList.find(course =>
        get(course, 'id') === courseIdFromRoute), 'courseComponentRule')
      const componentMinMaxValue = componentPool.find(compo =>
        get(compo, 'componentName') === get(component, 'componentName'))
      const existCount = selectedComponents.filter(comp =>
        get(comp, 'componentName') === get(component, 'componentName'))
      if (existCount.length < get(componentMinMaxValue, 'max')) {
        newComponents = [...selectedComponents, {
          ...component,
          order: nextOrder,
          uniqueName: `${get(component, 'componentName')}${nextOrder}`,
          selectedValue: [],
          selectedData: [],
          selectedLoComponent: []
        }]
      } else {
        notification.warn({
          message: `Maximum ${get(componentMinMaxValue, 'max')} 
          ${getComponentName(get(component, 'componentName'))} can be added`
        })
        return
      }
    }
    this.setState({
      selectedComponents: newComponents,
      selectedTab: `${get(component, 'componentName')}${nextOrder}`
    })
  }

  handleRemoveComponent = (component) => {
    const { selectedComponents } = this.state
    const isExist = selectedComponents.find(comp =>
      get(comp, 'uniqueName') === get(component, 'uniqueName'))
    if (isExist) {
      this.setState({
        selectedComponents: selectedComponents.filter(comp =>
          get(comp, 'uniqueName') !== get(component, 'uniqueName'))
      }, async () => {
        this.onRemoveComponent(isExist, true)
        const componentsArray = [...this.state.selectedComponents]
        let newTab = ''
        if (componentsArray.length > 0) {
          newTab = get(componentsArray[componentsArray.length - 1], 'uniqueName')
        }
        this.setState({
          selectedTab: newTab
        })
      })
    }
  }

  rowStyle = (isDragging, dragglePropsStyle) => (
    {
      maxWidth: '450px',
      ...dragglePropsStyle
    }
  )
  reorder = (data, startIndex, endIndex) => {
    const result = Array.from(data)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  onDragEnd = (result) => {
    // dropped outside the list
    const { selectedComponents } = this.state
    if (!result.destination) {
      return
    }
    const data = [...selectedComponents]
    const draggedContent = this.reorder(
      data,
      result.source.index,
      result.destination.index
    )
    const newComponents = []
    draggedContent.forEach((component, i) => {
      const order = i + 1
      newComponents.push({
        ...component,
        order,
        uniqueName: `${get(component, 'componentName')}${order}`,
      })
    })
    this.setState({
      selectedComponents: newComponents
    })
  }

  setDefaultValue = async () => {
    const { editData, match } = this.props
    const courseIdFromRoute = get(match, 'params.courseId')
    const fetchedData = await fetchTopicData(get(editData, 'id'), courseIdFromRoute)
    const { chapters: chaptersData } = fetchedData
    const { topic: topicData } = fetchedData
    let referenceContent = get(topicData,'referenceContent', '');
    
    this.setState({
      tools: get(fetchedData, 'topic.tools').length > 0 ? get(fetchedData, 'topic.tools') : [{value:""}],
      theory: get(fetchedData, 'topic.theory').length > 0 ? get(fetchedData, 'topic.theory') : [{value:""}],
      programming: get(fetchedData, 'topic.programming').length > 0 ? get(fetchedData, 'topic.programming') : [{ value: "" }],
      initialReferenceContent: referenceContent || '',
      referenceContentLink: isBase64(referenceContent) ? '' : referenceContent,
      // externalDescriptionEnabled: referenceContent && !isBase64(referenceContent),
      iframeViewWidth: get(topicData,'referenceContentWidth', '') || 500,
      classType: get(topicData, 'classType') || classType.lab
    })
    if (chaptersData && chaptersData.length > 0) {
      this.setState({
        chaptersList: chaptersData
      })
    }
    if (topicData) {
      const newSelectedComponent = []
      get(topicData, 'topicComponentRule', []).forEach(component => {
        if (get(component, 'componentName') === QUIZ) {
          let selectedValue = []
          let selectedData = []
          const selectedLo = []
          if (isPythonCourse(courseIdFromRoute)) {
            selectedValue = get(topicData, 'quiz', []).map((quiz) => ({
              label: parseChatStatement({
                statement: get(quiz, 'statement')
              }),
              key: get(quiz, 'id')
            }))
            selectedData = get(topicData, 'quiz', []).map(quiz => ({
              order: get(quiz, 'order', 0),
              data: quiz
            }))
            if (get(topicData, 'quiz', []).length > 0) {
              get(topicData, 'quiz', []).forEach(quiz => {
                const addedLoIds = selectedLo.map(lo => get(lo, 'key'))
                const currentCourseLo = this.state.selectedCourseLo.map(lo => get(lo, 'id'))
                const loFromQuizOfCurrCourse = get(quiz, 'learningObjectives', []).find(lo =>
                  currentCourseLo.includes(get(lo, 'id')))
                if (loFromQuizOfCurrCourse && !addedLoIds.includes(get(loFromQuizOfCurrCourse, 'id'))) {
                  selectedLo.push({
                    key: get(loFromQuizOfCurrCourse, 'id'),
                    label: get(loFromQuizOfCurrCourse, 'title'),
                  })
                }
              })
            }
          } else {
            selectedValue = get(topicData, 'quiz', []).map((quiz) => ({
              label: parseChatStatement({
                statement: get(quiz, 'question.statement')
              }),
              key: get(quiz, 'question.id')
            }))
            selectedData = sortBy(get(topicData, 'quiz', []).map(quiz => ({
              order: get(quiz, 'order', 0),
              data: get(quiz, 'question')
            })), 'order')
            if (get(topicData, 'quiz', []).length > 0) {
              get(topicData, 'quiz', []).forEach(quiz => {
                const addedLoIds = selectedLo.map(lo => get(lo, 'key'))
                const currentCourseLo = this.state.selectedCourseLo.map(lo => get(lo, 'id'))
                const loFromQuizOfCurrCourse = get(quiz, 'question.learningObjectives', []).find(lo =>
                  currentCourseLo.includes(get(lo, 'id')))
                if (loFromQuizOfCurrCourse && !addedLoIds.includes(get(loFromQuizOfCurrCourse, 'id'))) {
                  selectedLo.push({
                    key: get(loFromQuizOfCurrCourse, 'id'),
                    label: get(loFromQuizOfCurrCourse, 'title'),
                  })
                }
              })
            }
          }
          newSelectedComponent.push({
            ...component,
            uniqueName: `${get(component, 'componentName')}${get(component, 'order')}`,
            selectedValue,
            selectedData,
            selectedLo
          })
        } else if (get(component, 'componentName') === ASSIGNMENT) {
          let selectedValue = []
          let selectedData = []
          if (isPythonCourse(courseIdFromRoute)) {
            selectedValue = get(topicData, 'assignmentQuestions', []).map((quiz) => ({
              label: parseChatStatement({
                statement: get(quiz, 'statement')
              }),
              key: get(quiz, 'id')
            }))
            selectedData = get(topicData, 'assignmentQuestions').map(assignment => ({
              order: get(assignment, 'order', 0),
              data: assignment
            }))
          } else {
            selectedValue = get(topicData, 'assignmentQuestions', []).map((quiz) => ({
              label: parseChatStatement({
                statement: get(quiz, 'assignmentQuestion.statement')
              }),
              key: get(quiz, 'assignmentQuestion.id')
            }))
            selectedData = sortBy(get(topicData, 'assignmentQuestions').map(assignment => ({
              order: get(assignment, 'order', 0),
              data: get(assignment, 'assignmentQuestion')
            })), 'order')
          }
          newSelectedComponent.push({
            ...component,
            uniqueName: `${get(component, 'componentName')}${get(component, 'order')}`,
            selectedValue,
            selectedData
          })
        } else if (get(component, 'componentName') === HOMEWORK_ASSIGNMENT) {
          let selectedValue = []
          let selectedData = []
          if (isPythonCourse(courseIdFromRoute)) {
            selectedValue = get(topicData, 'homeworkAssignment', []).map((quiz) => ({
              label: parseChatStatement({
                statement: get(quiz, 'statement')
              }),
              key: get(quiz, 'id')
            }))
            selectedData = get(topicData, 'homeworkAssignment').map(assignment => ({
              order: get(assignment, 'order', 0),
              data: assignment
            }))
          } else {
            selectedValue = get(topicData, 'homeworkAssignment', []).map((quiz) => ({
              label: parseChatStatement({
                statement: get(quiz, 'assignmentQuestion.statement')
              }),
              key: get(quiz, 'assignmentQuestion.id')
            }))
            selectedData = sortBy(get(topicData, 'homeworkAssignment').map(assignment => ({
              order: get(assignment, 'order', 0),
              data: get(assignment, 'assignmentQuestion')
            })), 'order')
          }
          newSelectedComponent.push({
            ...component,
            uniqueName: `${get(component, 'componentName')}${get(component, 'order')}`,
            selectedValue,
            selectedData,
          })
        } else {
          let data = {}
          let selectedLoComponentTemp = []
          if (get(component, 'video')) {
            data = get(component, 'video')
          } else if (get(component, 'blockBasedProject')) {
            data = get(component, 'blockBasedProject')
          } else if (get(component, 'learningObjective')) {
            data = get(component, 'learningObjective')
            let LocRule = get(component, 'learningObjectiveComponentsRule', [])
            if (LocRule && LocRule.length > 0) {
              LocRule.forEach(item => {
                const obj = {}
                obj.id = item.order
                obj.order = item.order
                obj.componentName = item.componentName
                obj.label = item.componentName
                obj.key = item.componentName
                selectedLoComponentTemp.push(obj)
              })
            }
          }
          let fetchedData = {
            ...component,
            uniqueName: `${get(component, 'componentName')}${get(component, 'order')}`,
            selectedValue: [{ label: get(data, 'title'), key: get(data, 'id') }],
            selectedData: [data]
          }
          fetchedData.selectedLoComponent = selectedLoComponentTemp
          newSelectedComponent.push(fetchedData)
        }
      })
      this.setState({
        selectedComponents: newSelectedComponent,
        selectedChapter: get(topicData, 'chapter.id', ''),
        showLoading: false
      }, () => {
        if (get(topicData, 'topicComponentRule', []).length > 0) {
          this.setState({
            selectedTab: get(this.state, 'selectedComponents[0].uniqueName', '')
          })
        }
      })
    } else {
      notification.warn({
        message: 'Something went wrong'
      })
    }
  }

  onValueSelect = async (value, uniqueName, type, fromQuizLo = false, fromLO = false) => {
    const { selectedComponents } = this.state
    if (type === QUIZ || type === ASSIGNMENT || type === HOMEWORK_ASSIGNMENT) {
      const commonAddFunc = (existIndex, newData) => {
        const newComponent = [...selectedComponents]
        const [findComponent] = newComponent.splice(existIndex, 1)
        findComponent.selectedValue = [value, ...findComponent.selectedValue]
        const nextOrder = findComponent.selectedData.length === 0 ? 1 :
          Math.max(...findComponent.selectedData.map((data) => data.order)) + 1
        findComponent.selectedData = [{
          order: nextOrder,
          data: newData
        }, ...findComponent.selectedData]
        findComponent.selectedData = sortBy(findComponent.selectedData, 'order')
        newComponent.splice(existIndex, 0, findComponent)
        this.setState({
          selectedComponents: newComponent
        })
      }
      const existIndex = selectedComponents.findIndex(component => get(component, 'uniqueName') === uniqueName)
      if (existIndex !== -1) {
        if (type === QUIZ) {
          if (fromQuizLo) {
            // adding multiple quiz from the selected LO
            const { data: componentdata } = await fetchSingleComponent(get(value, 'key'), 'QuestionsFromLo')
            if (componentdata && get(componentdata, 'questionBanks', []).length > 0) {
              const newComponent = [...selectedComponents]
              const [findComponent] = newComponent.splice(existIndex, 1)
              findComponent.selectedLo = [value, ...findComponent.selectedLo]
              const quizValues = [...findComponent.selectedValue]
              const quizData = [...findComponent.selectedData]
              get(componentdata, 'questionBanks', []).forEach(question => {
                const selectedQuizValue = quizValues.map(quiz => get(quiz, 'key'))
                if (!selectedQuizValue.includes(get(question, 'id'))) {
                  quizValues.unshift({
                    key: get(question, 'id'),
                    label: get(question, 'statement')
                  })
                }
                const selectedQuizData = quizData.map(quiz => get(quiz, 'data.id'))
                if (!selectedQuizData.includes(get(question, 'id'))) {
                  const nextOrder = quizData.length === 0 ? 1 :
                    Math.max(...quizData.map((data) => data.order)) + 1
                  quizData.push({
                    order: nextOrder,
                    data: question
                  })
                }
              })
              findComponent.selectedValue = [...quizValues]
              findComponent.selectedData = sortBy(quizData, 'order')
              newComponent.splice(existIndex, 0, findComponent)
              this.setState({
                selectedComponents: newComponent
              })
            } else {
              notification.warn({
                message: 'No Quiz available'
              })
            }
          } else {
            // adding single quiz at a time
            const { data: componentdata } = await fetchSingleComponent(get(value, 'key'), type)
            let newData = {}
            if (componentdata) {
              newData = get(componentdata, 'questionBank')
            }
            commonAddFunc(existIndex, newData)
          }
        } else if (type === ASSIGNMENT || type === HOMEWORK_ASSIGNMENT) {
          const { data: componentdata } = await fetchSingleComponent(get(value, 'key'), type)
          let newData = {}
          if (componentdata) {
            newData = get(componentdata, 'assignmentQuestion')
          }
          commonAddFunc(existIndex, newData)
        }
      }
    } else {
      const existIndex = selectedComponents.findIndex(component => get(component, 'uniqueName') === uniqueName)
      if (existIndex !== -1) {
        let newData = {}
        if (!fromLO) {
          const { data: componentdata } = await fetchSingleComponent(get(value, 'key'), type)
          if (componentdata) {
            if (type === VIDEO) {
              newData = get(componentdata, 'video')
            } else if (type === LEARNING_OBJECTIVE) {
              newData = get(componentdata, 'learningObjective')
            } else if (type === PRACTICE || type === PROJECT || type === HOMEWORK_PRACTICE) {
              newData = get(componentdata, 'blockBasedProject')
            }
          }
        }
        const newComponent = [...selectedComponents]
        const [findComponent] = newComponent.splice(existIndex, 1)
        if (fromLO) {
          findComponent.selectedLoComponent = [...value]
        } else {
          findComponent.selectedValue = [value]
          findComponent.selectedData = [newData]
        }
        if (type === LEARNING_OBJECTIVE) {
          const lsWithPQ = (get(newData, 'learningSlides', []) || []).filter(ls => get(ls, 'type') === 'practiceQuestion');
          const practiceQuestions = (get(newData, 'questionBank', []) || []);
          if (!lsWithPQ.length && practiceQuestions.length) {
            return notification.warn({
              message: 'Please create slides and import questions before mapping to session.'
            })
          }
        }
        newComponent.splice(existIndex, 0, findComponent)
        this.setState({
          selectedComponents: newComponent
        })
      }
    }
  }
  onRemoveComponent = (data, shouldDelete) => {
    const { operation, editData, match } = this.props
    const componentName = get(data, 'componentName')
    const courseIdFromRoute = get(match, 'params.courseId')
    if (operation === 'edit' && shouldDelete) {
      get(data, 'selectedValue', []).forEach(value => {
        if (isPythonCourse(courseIdFromRoute)) {
          removeComponentFromSession(
            get(editData, 'id'),
            get(value, 'key'),
            componentName
          )
        } else if (componentName !== QUIZ
          && componentName !== ASSIGNMENT
          && componentName !== HOMEWORK_ASSIGNMENT) {
          removeComponentFromSession(
            get(editData, 'id'),
            get(value, 'key'),
            componentName
          )
        }
      })
    }
  }
  onValueDeselect = async (value, type, uniqueName, fromQuizLo, fromLO=false) => {
    const { selectedComponents } = this.state
    if (type === QUIZ || type === ASSIGNMENT || type === HOMEWORK_ASSIGNMENT) {
      const existIndex = selectedComponents.findIndex(component => get(component, 'uniqueName') === uniqueName)
      if (existIndex !== -1) {
        const newComponent = [...selectedComponents]
        const [findComponent] = newComponent.splice(existIndex, 1)
        const prevSelectedValue = [...findComponent.selectedValue]
        const prevSelectedData = [...findComponent.selectedData]
        const commonDeleteFunc = () => {
          findComponent.selectedValue = prevSelectedValue.filter(val => get(val, 'key') !== get(value, 'key'))
          findComponent.selectedData = prevSelectedData.filter(val => get(val, 'data.id') !== get(value, 'key'))
          findComponent.selectedData = sortBy(findComponent.selectedData, 'order')
          newComponent.splice(existIndex, 0, findComponent)
          this.setState({
            selectedComponents: newComponent
          })
        }
        if (type === QUIZ) {
          if (fromQuizLo) {
            const prevSelectedLo = [...findComponent.selectedLo]
            let prevSelectedQuizData = [...findComponent.selectedData]
            let prevSelectedQuizValue = [...findComponent.selectedValue]
            const { data: componentdata } = await fetchSingleComponent(get(value, 'key'), 'QuestionsFromLo')
            findComponent.selectedLo = prevSelectedLo.filter(val => get(val, 'key') !== get(value, 'key'))
            if (componentdata && get(componentdata, 'questionBanks', []).length > 0) {
              get(componentdata, 'questionBanks', []).forEach(quiz => {
                prevSelectedQuizValue = prevSelectedQuizValue.filter(val => get(val, 'key') !== get(quiz, 'id'))
                prevSelectedQuizData = prevSelectedQuizData.filter(val => get(val, 'data.id') !== get(quiz, 'id'))
              })
              findComponent.selectedData = sortBy(prevSelectedQuizData, 'order')
              findComponent.selectedValue = prevSelectedQuizValue
              newComponent.splice(existIndex, 0, findComponent)
              this.setState({
                selectedComponents: newComponent
              })
            }
          } else commonDeleteFunc()
        } else if (type === ASSIGNMENT || type === HOMEWORK_ASSIGNMENT) commonDeleteFunc()
      }
    } else {
      const existIndex = selectedComponents.findIndex(component => get(component, 'uniqueName') === uniqueName)
      if (existIndex !== -1) {
        const foundData = selectedComponents.find(component => get(component, 'uniqueName') === uniqueName)
        const newComponent = [...selectedComponents]
        if (fromLO) {
          const [findComponent] = newComponent.splice(existIndex, 1)
          findComponent.selectedLoComponent = [...value]
          newComponent.splice(existIndex, 0, findComponent)
        } else {
          newComponent.splice(existIndex, 1)
          this.onRemoveComponent(foundData)
        }
        this.setState({
          selectedComponents: newComponent
        })
      }
    }
    if (!fromLO) {
      const { operation, editData, match } = this.props
      const courseIdFromRoute = get(match, 'params.courseId')
      if (operation === 'edit') {
        if (isPythonCourse(courseIdFromRoute)) {
          await removeComponentFromSession(
            get(editData, 'id'),
            get(value, 'key'),
            type
          )
        } else if (type !== QUIZ && type !== ASSIGNMENT) {
          await removeComponentFromSession(
            get(editData, 'id'),
            get(value, 'key'),
            type
          )
        }
      }
    }
  }

  disableSave = () => {
    const {
      selectedComponents, sessionTopic, order,selectedChapter
    } = this.state
    let count = 0
    selectedComponents.forEach((component) => {
      if (get(component, 'selectedData', []).length === 0
        || get(component, 'selectedValue', []).length === 0) {
        count += 1
      }
    })
    const { operation } = this.props
    if (operation === 'add') {
      if (count === 0 && sessionTopic !== '' && order > 0 && selectedChapter !== '') {
        return false
      }
    } else if (operation === 'edit') {
      if (sessionTopic !== '' && order > 0 && selectedChapter !== '') {
        return false
      }
    }
    return true
  }

  onReorderSave = (dataArray, component) => {
    const newSelectedComponent = [...this.state.selectedComponents]
    const componentInd = newSelectedComponent.findIndex(compo =>
      get(compo, 'uniqueName') === get(component, 'uniqueName'))
    if (componentInd >= 0) {
      newSelectedComponent[componentInd].selectedData = sortBy(dataArray, 'order')
      this.setState({
        selectedComponents: newSelectedComponent
      })
    }
  }
  handleChangeOrder = (value, uniqueName) => {
    const { selectedComponents } = this.state
    const existIndex = selectedComponents.findIndex(component => get(component, 'uniqueName') === uniqueName)
    if (existIndex !== -1) {
      const newComponent = [...selectedComponents]
      const [findComponent] = newComponent.splice(existIndex, 1)
      findComponent.selectedLoComponent = [...value]
      newComponent.splice(existIndex, 0, findComponent)
      this.setState({
        selectedComponents: newComponent
      })
    }
  }
  renderTabs = () => {
    const { selectedTab, selectedComponents,
      videoList, quizList, projectList, practiceList,
      loList, assignmentList, courseId, selectedCourseLo,
      homeworkAssignmentList, homeworkPracticeList } = this.state
    const { match } = this.props
    return selectedComponents.map(component => {
      if (get(component, 'componentName') === VIDEO && get(component, 'uniqueName') === selectedTab) {
        return (
          <Videos
            key={get(component, 'uniqueName')}
            onValueSelect={this.onValueSelect}
            onValueDeselect={this.onValueDeselect}
            videoList={videoList}
            courseId={courseId}
            {...component}
          />
        )
      } else if (get(component, 'componentName') === LEARNING_OBJECTIVE && get(component, 'uniqueName') === selectedTab) {
        return (
          <LearningObjective
            key={get(component, 'uniqueName')}
            onValueSelect={this.onValueSelect}
            onValueDeselect={this.onValueDeselect}
            handleChangeOrder={this.handleChangeOrder}
            loList={loList}
            courseId={courseId}
            {...component}
          />
        )
      } else if (get(component, 'componentName') === ASSIGNMENT && get(component, 'uniqueName') === selectedTab) {
        return (
          <CodingAssignment
            key={get(component, 'uniqueName')}
            onValueSelect={this.onValueSelect}
            onValueDeselect={this.onValueDeselect}
            assignmentList={assignmentList}
            onReorderSaved={(dataArray) =>
              this.onReorderSave(dataArray, component)}
            courseId={courseId}
            {...match}
            {...component}
          />
        )
      } else if (get(component, 'componentName') === QUIZ && get(component, 'uniqueName') === selectedTab) {
        return (
          <Quiz
            key={get(component, 'uniqueName')}
            onValueSelect={this.onValueSelect}
            onValueDeselect={this.onValueDeselect}
            quizList={quizList}
            loList={selectedCourseLo}
            onReorderSaved={(dataArray) =>
              this.onReorderSave(dataArray, component)}
            courseId={courseId}
            {...match}
            {...component}
          />
        )
      } else if (get(component, 'componentName') === PROJECT && get(component, 'uniqueName') === selectedTab) {
        return (
          <Project
            key={get(component, 'uniqueName')}
            onValueSelect={this.onValueSelect}
            onValueDeselect={this.onValueDeselect}
            projectList={projectList}
            courseId={courseId}
            {...component}
          />
        )
      } else if (get(component, 'componentName') === PRACTICE && get(component, 'uniqueName') === selectedTab) {
        return (
          <Practice
            key={get(component, 'uniqueName')}
            onValueSelect={this.onValueSelect}
            onValueDeselect={this.onValueDeselect}
            practiceList={practiceList}
            courseId={courseId}
            {...component}
          />
        )
      } else if (get(component, 'componentName') === HOMEWORK_ASSIGNMENT && get(component, 'uniqueName') === selectedTab) {
        return (
          <HomeWorkAssignment
            key={get(component, 'uniqueName')}
            onValueSelect={this.onValueSelect}
            onValueDeselect={this.onValueDeselect}
            assignmentList={homeworkAssignmentList}
            onReorderSaved={(dataArray) =>
              this.onReorderSave(dataArray, component)}
            courseId={courseId}
            {...match}
            {...component}
          />
        )
      } else if (get(component, 'componentName') === HOMEWORK_PRACTICE && get(component, 'uniqueName') === selectedTab) {
        return (
          <HomeworkPractice
            key={get(component, 'uniqueName')}
            onValueSelect={this.onValueSelect}
            onValueDeselect={this.onValueDeselect}
            practiceList={homeworkPracticeList}
            courseId={courseId}
            {...component}
          />
        )
      }
    })
  }

  onSave = async () => {
    const {
      selectedComponents, sessionTopic, order,
      imageFile, sessionDescription, isTrial,
      selectedChapter, smallThumbnail,referenceContent,referenceContentLink,
      status,externalDescriptionEnabled, contentType, contentStatus
    } = this.state
    let { tools, theory, programming } = this.state
    const { operation, editData, match } = this.props
    const courseIdFromRoute = get(match, 'params.courseId')
    const componentInput = []
    let questionsConnectId = []
    let assignmentConnectId = []
    const videoConnectId = []
    const loConnectId = []
    const blockBasedConnectId = []
    const topicQuestions = []
    const topicAssignmentQuestions = []
    const topicHomeworkAssignmentQuestion = []
    let updateComponentQuery = ''
    selectedComponents.forEach((component, ind) => {
      if (get(component, 'componentName') === VIDEO) {
        const videoId = get(component, 'selectedValue[0].key')
        if (videoId) {
          videoConnectId.push(videoId)
          updateComponentQuery += `video${ind}: updateVideo(id: "${videoId}", 
          coursesConnectIds: ["${courseIdFromRoute}"]) {
            id
          }`
          componentInput.push({
            componentName: get(component, 'componentName'),
            order: get(component, 'order'),
            videoConnectId: videoId
          })
        }
      } else if (get(component, 'componentName') === QUIZ) {
        if (isPythonCourse(courseIdFromRoute)) {
          questionsConnectId = get(component, 'selectedValue', []).map(quiz => get(quiz, 'key'))
        } else {
          get(component, 'selectedData', []).forEach(data => {
            topicQuestions.push({
              order: get(data, 'order'),
              questionConnectId: get(data, 'data.id')
            })
          })
        }
        get(component, 'selectedValue', []).forEach((quiz, index) => {
          updateComponentQuery += `quiz${ind}${index}: updateQuestionBank(id: "${get(quiz, 'key')}",
            coursesConnectIds: ["${courseIdFromRoute}"]) {
              id
            }`
        })
        componentInput.push({
          componentName: get(component, 'componentName'),
          order: get(component, 'order'),
        })
      } else if (get(component, 'componentName') === PROJECT) {
        const projectId = get(component, 'selectedValue[0].key')
        if (projectId) {
          blockBasedConnectId.push(projectId)
          updateComponentQuery += `project${ind}: updateBlockBasedProject(id: "${projectId}",
            coursesConnectIds: ["${courseIdFromRoute}"]) {
              id
            }`
          componentInput.push({
            componentName: get(component, 'componentName'),
            order: get(component, 'order'),
            blockBasedProjectConnectId: projectId
          })
        }
      } else if (get(component, 'componentName') === PRACTICE) {
        const practiceId = get(component, 'selectedValue[0].key')
        if (practiceId) {
          blockBasedConnectId.push(practiceId)
          updateComponentQuery += `practice${ind}: updateBlockBasedProject(id: "${practiceId}",
            coursesConnectIds: ["${courseIdFromRoute}"]) {
              id
            }`
          componentInput.push({
            componentName: get(component, 'componentName'),
            order: get(component, 'order'),
            blockBasedProjectConnectId: practiceId
          })
        }
      } else if (get(component, 'componentName') === HOMEWORK_PRACTICE) {
        const homeworkPracticeId = get(component, 'selectedValue[0].key')
        if (homeworkPracticeId) {
          blockBasedConnectId.push(homeworkPracticeId)
          updateComponentQuery += `homeworkPractice${ind}: updateBlockBasedProject(id: "${homeworkPracticeId}",
            coursesConnectIds: ["${courseIdFromRoute}"]) {
              id
            }`
          componentInput.push({
            componentName: get(component, 'componentName'),
            order: get(component, 'order'),
            blockBasedProjectConnectId: homeworkPracticeId
          })
        }
      } else if (get(component, 'componentName') === LEARNING_OBJECTIVE) {
        const loId = get(component, 'selectedValue[0].key')
        if (loId) {
          updateComponentQuery += `lo${ind}: updateLearningObjective(id: "${loId}",
            coursesConnectIds: ["${courseIdFromRoute}"]) {
              id
            }`
          loConnectId.push(loId)
          let learningObjectiveComponentsRule = [{ order: 1, componentName: LEARNING_SLIDE }]
          const selectedLoComponentTemp = get(component, 'selectedLoComponent', [])
          if (selectedLoComponentTemp.length > 0) {
            selectedLoComponentTemp.forEach(item => {
              let obj = {}
              obj.componentName = item.componentName
              obj.order = item.order
              // learningObjectiveComponentsRule.push(obj)
            })
          }
          componentInput.push({
            componentName: get(component, 'componentName'),
            order: get(component, 'order'),
            learningObjectiveConnectId: loId,
            learningObjectiveComponentsRule
          })
        }
      } else if (get(component, 'componentName') === ASSIGNMENT) {
        if (isPythonCourse(courseIdFromRoute)) {
          assignmentConnectId = [...assignmentConnectId, ...get(component, 'selectedValue', []).map(quiz => get(quiz, 'key'))]
        } else {
          get(component, 'selectedData', []).forEach(data => {
            topicAssignmentQuestions.push({
              order: get(data, 'order'),
              assignmentQuestionConnectId: get(data, 'data.id')
            })
          })
        }
        get(component, 'selectedValue', []).forEach((quiz, index) => {
          updateComponentQuery += `assignment${ind}${index}: updateAssignmentQuestion(id: "${get(quiz, 'key')}", 
            coursesConnectIds: ["${courseIdFromRoute}"]) {
              id
            }`
        })
        componentInput.push({
          componentName: get(component, 'componentName'),
          order: get(component, 'order'),
        })
      } else if (get(component, 'componentName') === HOMEWORK_ASSIGNMENT) {
        if (isPythonCourse(courseIdFromRoute)) {
          assignmentConnectId = [...assignmentConnectId, ...get(component, 'selectedValue', []).map(quiz => get(quiz, 'key'))]
        } else {
          get(component, 'selectedData', []).forEach(data => {
            topicHomeworkAssignmentQuestion.push({
              order: get(data, 'order'),
              assignmentQuestionConnectId: get(data, 'data.id')
            })
          })
        }
        get(component, 'selectedValue', []).forEach((quiz, index) => {
          updateComponentQuery += `assignment${ind}${index}: updateAssignmentQuestion(id: "${get(quiz, 'key')}", 
            coursesConnectIds: ["${courseIdFromRoute}"]) {
              id
            }`
        })
        componentInput.push({
          componentName: get(component, 'componentName'),
          order: get(component, 'order'),
        })
      }
    })
    tools = tools.filter((tool) => { 
      let trimVal = get(tool, 'value').trim();
      if(trimVal !== "") return true;
      return false;
     })
    theory = theory.filter((item) => { 
      let trimVal = get(item, 'value').trim();
      if(trimVal !== "") return true;
      return false;
     })
    programming = programming.filter((item) => { 
      let trimVal = get(item, 'value').trim();
      if(trimVal !== "") return true;
      return false;
     })
    if (operation === 'add') {
      let newReferenceContent = referenceContent
      //  externalDescriptionEnabled && (newReferenceContent = referenceContentLink);

      let addInput = {
        input: {
          order: Number(order),
          title: sessionTopic,
          topicComponentRule: componentInput,
          description: sessionDescription,
          isTrial,
          status,
          tools,
          theory,
          programming,
          classType: this.state.classType,
          referenceContent: newReferenceContent,
          referenceContentWidth: Number(this.state.iframeViewWidth),
          contentType,
          contentStatus,
        },
        videoConnectId: [...new Set(videoConnectId)],
        loConnectId: [...new Set(loConnectId)],
        blockBasedConnectId: [...new Set(blockBasedConnectId)],
        courseId: [courseIdFromRoute],
        chapterConnectId: selectedChapter || '',
        updateComponentQuery,
        thumbnail: imageFile,
        smallThumbnail
      }
      if (isPythonCourse(courseIdFromRoute)) {
        addInput = {
          ...addInput,
          questionsConnectId: [...new Set(questionsConnectId)],
          assignmentConnectId: [...new Set(assignmentConnectId)],
        }
      } else {
        addInput = {
          ...addInput,
          input: {
            ...addInput.input,
            topicQuestions,
            topicAssignmentQuestions,
            topicHomeworkAssignmentQuestion
          },
        }
      }
      await addTopicSession(addInput, courseIdFromRoute)
    } else if (operation === 'edit') {
      let newReferenceContent = referenceContent;
      // externalDescriptionEnabled && (newReferenceContent = referenceContentLink);
      let editInput = {
        topicId: get(editData, 'id'),
        input: {
          order: Number(order),
          title: sessionTopic,
          description: sessionDescription || '',
          isTrial,
          status,
          topicComponentRule: {
            replace: componentInput
          },
          tools: {
            replace: tools
            },
          programming: {
            replace: programming
          },
          theory: {
            replace: theory
          },
          classType: this.state.classType,
          referenceContent: newReferenceContent,
          referenceContentWidth: Number(this.state.iframeViewWidth),
          contentType,
          contentStatus,
        },
        videoConnectId: [...new Set(videoConnectId)],
        loConnectId: [...new Set(loConnectId)],
        blockBasedConnectId: [...new Set(blockBasedConnectId)],
        chapterConnectId: selectedChapter || '',
        courseId: courseIdFromRoute,
        updateComponentQuery,
        thumbnail: imageFile,
        smallThumbnail
      }
      if (isPythonCourse(courseIdFromRoute)) {
        editInput = {
          ...editInput,
          questionsConnectId: [...new Set(questionsConnectId)],
          assignmentConnectId: [...new Set(assignmentConnectId)],
        }
      } else {
        editInput = {
          ...editInput,
          input: {
            ...editInput.input,
            topicQuestions: {
              replace: topicQuestions
            },
            topicAssignmentQuestions: {
              replace: topicAssignmentQuestions
            },
            topicHomeworkAssignmentQuestion: {
              replace: topicHomeworkAssignmentQuestion
            }
          }
        }
      }
      await updateTopicSessions(editInput, courseIdFromRoute)
    }
  }
  thumbnailRef = React.createRef()
  smallThumbnailRef = React.createRef()
  onThumbnailDrop = (file) => {
    if (file) {
      this.setState({ imageFile: file })
    }
  }
  onSmallThumbnailDrop = (file) => {
    if (file) {
      this.setState({ smallThumbnail: file })
    }
  }
  onCourseChange = () => {
    this.setState({
      videoList: [],
      loList: [],
      assignmentList: [],
      projectList: [],
      practiceList: [],
      homeworkAssignmentList: [],
      homeworkPracticeList: [],
    })
  }
  menu = (component) =>  (
  <Menu style={{marginTop: '-10px'}}>
    <Menu.Item className='component-menu-item' onClick={() => {this.handleRemoveComponent(component)}} style={{letterSpacing: 0}} key="0">
      <Icon type="delete" /> 

      Delete
    </Menu.Item>
    </Menu>
  );
  render() {
    const { order, sessionTopic, selectedComponents,
      selectedTab, imageFile, imageUrl, sessionDescription,
      isTrial, selectedChapter, chaptersList, smallThumbnail,
      smallThumbnailUrl, courseId, showLoading, status,showIframeViewModal, contentType, contentStatus } = this.state
    const { operation, topicDataFetchingStatus, editData,
      coursesList, topicAddingStatus, topicUpdateStatus, match } = this.props
    const isQuizTabActive = selectedTab.includes('quiz')
    const courseIdFromRoute = get(match, 'params.courseId')
    const addingStatus = topicAddingStatus && topicAddingStatus.getIn([`topics/${courseIdFromRoute}`])
    const updatingStatus = topicUpdateStatus && topicUpdateStatus.getIn([`topics/${courseIdFromRoute}`])
    return (
      <div className='add-session-modal'>
        <Spin spinning={operation === 'edit' && showLoading}>
            {showIframeViewModal && <IframePreview
            openIframePreview={showIframeViewModal}
            onClose={() => this.setState({ showIframeViewModal: false })}
            width={this.state.iframeViewWidth} 
            onSubmit={() => {}}
            source={this.state.referenceContentLink}
            handleChange={(value) => this.setState({iframeViewWidth: value}) }
          />}
          <StyledModal>
            <TopContainer>
              <h1 style={{fontSize: '19px'}}>
                Session Details
              </h1>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex',marginLeft: 'auto' }} >
                  <div className='cs-label'>Session Publish Status</div>
                  <StyledSwitch
                    bgcolor={status === PUBLISHED_STATUS ? '#64da7a' : '#ff5744'}
                    checked={status === PUBLISHED_STATUS}
                    onChange={() => this.setState({
                      status:
                        status === PUBLISHED_STATUS ? UNPUBLISHED_STATUS : PUBLISHED_STATUS
                    })}
                    size='default'
                  />
                </div>
                <div style={{ display: 'flex',marginLeft: 'auto' }} >
                  <div className='cs-label'>Content Publish Status</div>
                  <StyledSwitch
                    bgcolor={contentStatus === PUBLISHED_STATUS ? '#64da7a' : '#ff5744'}
                    checked={contentStatus === PUBLISHED_STATUS}
                    onChange={() => this.setState({
                      contentStatus:
                        contentStatus === PUBLISHED_STATUS ? UNPUBLISHED_STATUS : PUBLISHED_STATUS
                    })}
                    size='default'
                  />
                </div>
              </div>
            </TopContainer>
            <div className='cs-session-container'>
              <div className='cs-input-container'>
                <h4 className='cs-label'>Select Chapter <span style={{ color: 'red' }}>*</span></h4>
                <SearchInput
                  value={selectedChapter}
                  loading={topicDataFetchingStatus
                    && get(topicDataFetchingStatus.toJS(), 'loading')}
                  placeholder='Select a Course'
                  onChange={(value) => this.setState({ selectedChapter: value })}
                  dataArray={chaptersList}
                />
              </div>
                 <div className='cs-input-container' >
                <h4 className='cs-label'>Class Type</h4>
                <Select
                  value={this.state.classType}
                  placeholder='Select Class type'
                  style={{  textTransform: 'capitalize' }}
                  onChange={(value) => this.setState({ classType: value })}
                >
                  {Object.keys(classType).map(cl =>
                    <Select.Option value={cl} key={cl} style={{ textTransform: 'capitalize' }} >{cl}</Select.Option>)}
                </Select>
              </div>
              <div className='cs-input-container'><h4 className='cs-label'>Session Order</h4>
                <Input
                  value={order}
                  type='number'
                  onChange={({ target: { value } }) =>
                    this.setState({ order: value })}
                />
              </div>
              <div className='cs-input-container'>
                <h4 className='cs-label'>Session Topic <span style={{ color: 'red' }}>*</span></h4>
                <Input
                  value={sessionTopic}
                  onBlur={() => this.setState({ sessionTopic: sessionTopic.trim() })}
                  type='text'
                  onChange={({ target: { value } }) =>
                    this.setState({ sessionTopic: value })}
                />
              </div>
              <div className='cs-input-container' >
                <h4 className='cs-label'>Session Description</h4>
                <Input.TextArea
                  value={sessionDescription}
                  rows={1}
                  type='text'
                  onChange={({ target: { value } }) =>
                    this.setState({ sessionDescription: value })}
                />
              </div>
              
           
            </div>
          
            
              <div className='cs-ref-content-container'>
              
            <div className='cs-ref-content'>
              <h3 className='cs-label'>Teacher Manual Content</h3>
              {/* <div className='cs-enable-doc-check'>
              <Checkbox
                id='externalDescriptionEnabled'
                checked={this.state.externalDescriptionEnabled}
                onChange={({ target: { checked } }) => this.setState({ externalDescriptionEnabled: checked })}
                />
                <span>Enable doc embed</span>
            </div> */}
            
              {this.state.externalDescriptionEnabled ? (
                <>
                <div style={{display: 'flex',gap: '1rem',alignItems: 'center'}}>

                <Input
                  label='External Description Link'
                  placeholder='External Description Link'
                  name='referenceContent'
                  type='text'
                  value={this.state.referenceContentLink || ''}
                  onChange={(e) => {
                    this.setState({ referenceContentLink: e.target.value })
                    onAddIframeLink(e.target.value)
                  }}
                  textArea
                  inputStyles={{ gridTemplateColumns: 'auto' }}
                />
                <Button className='cs-preview-doc-btn'onClick={() => {
                  this.setState({showIframeViewModal: true});
                
              }}>
                Preview Doc
              </Button>
                </div>
                </>
              ) :
                <div style={{ width: '60%' }}>
                  <ContentEditor
                    id='344'
                    initialValue={getDecodedStatement(this.state.initialReferenceContent)}
                    inputValue={this.state.referenceContent}
                    
                    placeholder='Create Description'
                    name='referenceContent'
                    type='text'
                    bulletsEnabled
                    init={{
                      height: 250,
                      selector: `SM-344`,
                      placeholder: "Start adding your create description here...",
                    }}
                    onChange={(_, editor) => {
                      const data = encodeBase64(editor.getContent());
                      this.setState({ referenceContent: data })
                    }}
                  />
                </div>
              }
              
            </div>

              
            </div>
            <Divider />
            {/* divider */}
            <div className="cs-component-pools">

              <div className="cs-component-container cs-input-container">
              <h3 className='cs-label'>Component Pool</h3>
                {this.renderComponentPool()}  
              </div>
              <div className="cs-component-upload-file">
              <div style={{ width: '188px' }}>
                <h3 className='cs-label'>Thumbnail</h3>
                <Dropzone
                  width='100%'
                  height='130px'
                  getDropzoneFile={this.onThumbnailDrop}
                  ref={this.thumbnailRef}
                  defaultImage={getFullPath(get(editData, 'thumbnail.uri', '')) || imageUrl}
                  defaultFile={imageFile}
                  fancy
                  onImageUrl={imgUrl => this.setState({ imageUrl: imgUrl })}
                >Click or drag to attach
                </Dropzone>
              </div>
              <div style={{ width: '188px' }}>
                <h3 className='cs-label'>Small Thumbnail</h3>
                <Dropzone
                  width='100%'
                  height='130px'
                  getDropzoneFile={this.onSmallThumbnailDrop}
                  ref={this.smallThumbnailRef}
                  fancy
                  defaultImage={getFullPath(get(editData, 'thumbnailSmall.uri', '')) || smallThumbnailUrl}
                  defaultFile={smallThumbnail}
                  onImageUrl={imgUrl => this.setState({ smallThumbnailUrl: imgUrl })}
                >Click or drag to attach
                </Dropzone>
              </div>
                </div>  
            </div>
            <Divider />

            <div>
              {
                !isQuizTabActive && (
                  <div style={{ display: 'flex', alignItems: 'flex-start', flex: '1',flexDirection: 'column' }}>
                    <h3 className='cs-label' style={{ width: '12%' }}>Select Course</h3>
                    <SearchInput
                      value={courseId}
                      placeholder='Select a Course'
                      onChange={(value) => this.setState({ courseId: value },
                        () => this.onCourseChange())}
                      dataArray={coursesList}
                    />
                  </div>
                )
              }
            </div>
            <div style={{ margin: '15px 0' }} />
            <div style={{
              marginTop: '15px', display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center'
            }}>
              <h3>isTeacherTraining : </h3>
              <StyledSwitch
                bgcolor={contentType === TEACHER_TRAINING ? '#64da7a' : '#ff5744'}
                checked={contentType === TEACHER_TRAINING}
                onChange={() => this.setState({
                  contentType:
                    contentType === TEACHER_TRAINING ? NORMAL : TEACHER_TRAINING
                })}
                size='default'
              />
            </div>
            {
              selectedComponents.length > 0 && (
                <DragDropContext onDragEnd={this.onDragEnd} >
                  <Droppable droppableId='droppable' direction='horizontal' >
                    {provided => (
                      <div ref={provided.innerRef}
                      className="cs-components-draggable"
                        {...provided.droppableProps}
                        style={{
                          width: '100vw',
                          display: 'flex',
                          gap: 10,
                          overflowX: 'auto',
                          padding: '0 20px'
                        }}
                      >
                        {
                          sortBy(selectedComponents, 'order').map((component, i) => (
                            <Draggable index={i}
                              draggableId={component.order}
                              key={component.order}
                            >
                              {
                                (provid, snapshot) => (
                                  <div
                                    ref={provid.innerRef}
                                    {...provid.draggableProps}
                                    {...provid.dragHandleProps}
                                    style={this.rowStyle(snapshot.isDragging,
                                        provid.draggableProps.style)}
                                  >
                                     
                                    <ComponentTab
                                      selected={selectedTab === get(component, 'uniqueName')}
                                      onClick={() => this.setState({ selectedTab: get(component, 'uniqueName') })}
                                    >
                                      <img style={{ height: '10px', width: '10px', objectFit: 'contain' }} src={dotIcons} alt='Icons' />
                                      
                                      <strong style={{fontWeight:'600', margin: '0 4px', whiteSpace: 'nowrap' }}>
                                        {getComponentName(get(component, 'componentName'))}
                                      </strong>
                                      <Dropdown className='dropdown-container' overlay={this.menu(component)} trigger={['click']} onClick={(e) => e.stopPropagation()}>
                                        <div className="component-dropdown-btn">
                                          <svg width="20" height="20" viewBox="0 0 6 6" fill="none">
                                            <path d="M1.5 3.375C1.70711 3.375 1.875 3.20711 1.875 3C1.875 2.79289 1.70711 2.625 1.5 2.625C1.29289 2.625 1.125 2.79289 1.125 3C1.125 3.20711 1.29289 3.375 1.5 3.375Z" fill="black"/>
                                            <path d="M3 3.375C3.20711 3.375 3.375 3.20711 3.375 3C3.375 2.79289 3.20711 2.625 3 2.625C2.79289 2.625 2.625 2.79289 2.625 3C2.625 3.20711 2.79289 3.375 3 3.375Z" fill="black"/>
                                            <path d="M4.5 3.375C4.70711 3.375 4.875 3.20711 4.875 3C4.875 2.79289 4.70711 2.625 4.5 2.625C4.29289 2.625 4.125 2.79289 4.125 3C4.125 3.20711 4.29289 3.375 4.5 3.375Z" fill="black"/>
                                          </svg>
                                          </div>
                                      </Dropdown>
                                      
                                    </ComponentTab>
                                  </div>
                                )
                              }
                            </Draggable>
                          ))
                        }
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )
            }
            <div style={{ margin: '15px 0' }} />
            <TopContainer justify='flex-end'>
              <StyledButton
                icon='file'
                id='add-btn'
                disabled={this.disableSave()}
                onClick={this.onSave}
                loading={operation === 'add' ? addingStatus && get(addingStatus.toJS(), 'loading')
                  : updatingStatus && get(updatingStatus.toJS(), 'loading')}
              >
                {operation === 'add' ? 'SAVE' : 'UPDATE'}
              </StyledButton>
            </TopContainer>
            {this.renderTabs()}
          </StyledModal>
        </Spin>
      </div>
    )
  }
}



const mapStateToProps = (state) => ({
  videoFetchingStatus: state.data.getIn(['videos', 'fetchStatus', 'videos']),
  videoData: state.data.getIn(['videos', 'data']),
  questionBankFetchingStatus: state.data.getIn(['questionBanks', 'fetchStatus', 'questionBanks']),
  questionBankData: state.data.getIn(['questionBanks', 'data']),
  projectFetchingStatus: state.data.getIn(['blockBasedProjects', 'fetchStatus', 'project']),
  projectData: filterKey(state.data.getIn(['blockBasedProjects', 'data']), 'project'),
  practiceFetchingStatus: state.data.getIn(['blockBasedProjects', 'fetchStatus', 'practice']),
  practiceData: filterKey(state.data.getIn(['blockBasedProjects', 'data']), 'practice'),
  learningObectiveFetchingStatus: state.data.getIn(['learningObjectives', 'fetchStatus', 'learningObjectives']),
  learningObjectiveData: filterKey(state.data.getIn(['learningObjectives', 'data']), 'learningObjectives'),
  assignmentFetchingStatus: state.data.getIn(['assignmentQuestions', 'fetchStatus', 'assignmentQuestions']),
  assignmentData: filterKey(state.data.getIn(['assignmentQuestion', 'data']), 'assignmentQuestions'),
  topicDataFetchingStatus: state.data.getIn(['sessionTopic', 'fetchStatus', 'sessionTopic']),
  homeworkAssignmentFetchStatus: state.data.getIn(['assignmentQuestions', 'fetchStatus', 'homeworkAssignment']),
  homeworkAssignmentData: filterKey(state.data.getIn(['assignmentQuestion', 'data']), 'homeworkAssignment'),
  homeworkPracticeFetchStatus: state.data.getIn(['blockBasedProjects', 'fetchStatus', 'homeworkPractice']),
  homeworkPracticeData: filterKey(state.data.getIn(['blockBasedProjects', 'data']), 'homeworkPractice')
})

export default connect(mapStateToProps)(SessionModal)
