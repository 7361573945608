/*eslint-disable*/
import { ThunderboltFilled } from '@ant-design/icons'
import { get } from 'lodash'
import React from 'react'
import { PreviewEditor } from '../../../../../../components/ContentEditor'
import { isBase64 } from '../../../../../../utils/base64Utility'
import getDecodedStatement from '../../../../../../utils/getDecodedStatement'
import getFullPath from '../../../../../../utils/getFullPath'
import parseChatStatement from '../../../../../../utils/parseStatement'
import { CloseIcon, TopContainer } from '../../../../AddSessions.styles'
import { ProjectDescription } from '../AssignmentView/Assignment.styles'
import ProjectCard from './Project.styles'

const ProjectView = ({ project, onDelete }) => {
  const newSelected = []
  const difficultyVal = get(project, 'difficulty', 1)
  if (difficultyVal === 1) newSelected.push('One')
  if (difficultyVal === 2) newSelected.push('One', 'Two')
  if (difficultyVal === 3) newSelected.push('One', 'Two', 'Three')
  const imageStyle = { height: '150px', width: '150px', objectFit: 'contain' }
  return (
    <ProjectCard>
      <CloseIcon onClick={onDelete} />
      {get(project, 'projectThumbnail.id') &&
        <div><img style={imageStyle} src={getFullPath(get(project, 'projectThumbnail.uri'))} alt='platformLogo' /></div>}
      <h3 style={{ width: '80%' }}>{parseChatStatement({ statement: get(project, 'title', '-') })}</h3>
      <h3>Difficulty:
        <TopContainer justify='center'>
          <ThunderboltFilled style={{ color: newSelected.includes('One') ? '#FFDD09' : 'lightgray', fontSize: '35px' }} />
          <ThunderboltFilled style={{ color: newSelected.includes('Two') ? '#FFDD09' : 'lightgray', fontSize: '35px' }} />
          <ThunderboltFilled style={{ color: newSelected.includes('Three') ? '#FFDD09' : 'lightgray', fontSize: '35px' }} />
        </TopContainer>
      </h3>
      {get(project, 'externalPlatformLogo.id') &&
        <div><h3>Platform Logo</h3> <img style={imageStyle} src={getFullPath(get(project, 'externalPlatformLogo.uri'))} alt='platformLogo' /></div>}
      <h3>Platform Link: {get(project, 'externalPlatformLink', '-')}</h3>
      <h3 style={{ width: '100%' }}>Project Creation Description:
        <ProjectDescription>
          {isBase64(get(project, 'projectCreationDescription')) ?
            <PreviewEditor
              value={getDecodedStatement(get(project, 'projectCreationDescription'))}
              init={{
                selector: `PRVPCD-${get(project, 'projectCreationDescription')}`
              }} />
            :
            parseChatStatement({ statement: get(project, 'projectCreationDescription') })
          }
        </ProjectDescription></h3>
      <h4 style={{ width: '100%' }}>Project Description:
        <ProjectDescription>
          {isBase64(get(project, 'projectDescription')) ?
            <PreviewEditor
              value={getDecodedStatement(get(project, 'projectDescription'))}
              init={{
                selector: `PRVPD-${get(project, 'projectDescription')}`
              }} />
            :
            parseChatStatement({ statement: get(project, 'projectDescription') })
          }
        </ProjectDescription></h4>
      <h4>Answer Description: {get(project, 'answerDescription', '-')}</h4>
    </ProjectCard>
  )
}

export default ProjectView
