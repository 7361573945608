import { get } from 'immutable'
import React from 'react'
import UmsDashboardStyle from '../UmsDashboard.style'

const sessionDetailsCount = [
    { name: 'converted', text: 'Converted', bgColor: 'rgb(109, 185, 33)' },
    { name: 'rescheduledSession', text: 'Rescheduled', bgColor: '#c6fffc', clickable: true },
    { name: 'startedSession', text: 'Started', bgColor: '#fff7e9', clickable: true },
    { name: 'completeSession', text: 'Completed', bgColor: '#fff8bc', clickable: true },
    { name: 'allotedSession', text: 'Alloted', bgColor: '#e8e8e8', clickable: true }
]

const userMetaCount = [
    { name: 'bookedUser', text: 'Booked', bgColor: 'rgb(109, 185, 33)' },
    { name: 'usersMeta', text: 'Users', bgColor: '#c6fffc' },
    { name: 'verifiedAndBookedUser', text: 'Verified Bookings', bgColor: '#fff7e9', clickable: true },
    { name: 'verifiedUser', text: 'Verified User', bgColor: '#fff8bc', clickable: true },
]

export const SessionDetailsMeta = ({ sessionsAndSalesCount = {}, showUserCounts = false,
    userDetail = {}, 
    dataFetchingStatus, verifiedAndBookedUser, verifiedAndBookedUserOnClick,
    verifiedUsers, verifiedUsersOnChange,
    ...props }) => {
    const getCountDiv = () => {
        const detailsDivs = []
        if (sessionsAndSalesCount && !get(dataFetchingStatus, 'loading')
            && get(dataFetchingStatus, 'success')) {
            const details = { ...userDetail, ...sessionsAndSalesCount }
            for (const count in details) {
                let findDetail = sessionDetailsCount.find(detail => get(detail, 'name') === count)
                if (showUserCounts) {
                    findDetail = userMetaCount.find(detail => get(detail, 'name') === count)
                }
                if (findDetail && findDetail.clickable) {
                    detailsDivs.push(
                        <UmsDashboardStyle.SessionCountDiv
                            bgColor={findDetail.bgColor}
                            clickable={findDetail.clickable || false}
                            onClick={get(props, `${count}OnClick`)}
                            selected={get(props, count)}
                        >
                            {findDetail.text} ({get(details, count)})
                        </UmsDashboardStyle.SessionCountDiv>
                    )
                } else if (findDetail) {
                    detailsDivs.push(
                        <UmsDashboardStyle.SessionCountDiv
                            bgColor={findDetail.bgColor}
                            clickable={findDetail.clickable || false}
                        >
                            {findDetail.text} ({get(details, count)})
                        </UmsDashboardStyle.SessionCountDiv>
                    )
                }
            }
        }
        return detailsDivs
    }
    if (true) {
        return (
            <UmsDashboardStyle.LeadAnalyticsTable cellPadding='5'>
                <tr>
                    <th scope='col'>
                        Users:
                    </th>
                    <th scope='col'>{get(sessionsAndSalesCount, 'usersMeta')}</th>
                    <th scope='col'>
                        Booked Users:
                    </th>
                    <th scope='col'>{get(sessionsAndSalesCount, 'bookedUser')}</th>
                </tr>
                <tr>
                    <th scope='col'>
                        <UmsDashboardStyle.SessionCountDiv
                            clickable
                            onClick={() => verifiedUsersOnChange('verifiedUsers')}
                            isSelected={verifiedUsers}
                        >
                            Verified Users:
                        </UmsDashboardStyle.SessionCountDiv>
                    </th>
                    <th scope='col'>{get(sessionsAndSalesCount, 'verifiedUser')}</th>
                    <th scope='col'>
                        <UmsDashboardStyle.SessionCountDiv
                            clickable
                            isSelected={verifiedAndBookedUser}
                            onClick={() => verifiedAndBookedUserOnClick('verifiedAndBookedUser')}
                        >
                            Verified & Booked Users:
                        </UmsDashboardStyle.SessionCountDiv>
                    </th>
                    <th scope='col'>{get(sessionsAndSalesCount, 'verifiedAndBookedUser')}</th>
                </tr>
            </UmsDashboardStyle.LeadAnalyticsTable>
        )
    }
    return (
        <UmsDashboardStyle.FlexContainerDiv>
            {getCountDiv()}
        </UmsDashboardStyle.FlexContainerDiv>
    )
}

export default SessionDetailsMeta