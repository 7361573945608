import gql from "graphql-tag"
import requestToGraphql from "../../utils/requestToGraphql"

export const updateEbook = async (
    input,
    ebookId,
) => {
    const { title, description, resourceURL, internalName, grades } = input
    const query = gql`
        mutation {
            updateEBook(id: "${ebookId}", input: {
                title: "${title}",
                description: "${description}",
                resourceURL: "${resourceURL}",
                internalName: "${internalName}",
                grades: {
                    replace: [${
                        grades.map(grade => {
                            return `{value: ${grade.value}}`
                        }
                        )
                    }]
                }
            }){
                id
            }
            
            }
    `

    const response = await requestToGraphql(query)
    return response
}