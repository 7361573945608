import { PlusOutlined } from '@ant-design/icons';
import { InputNumber, Select, Switch, Icon } from 'antd';
import React, { Component } from 'react';
import { get } from 'lodash';
import { Event, SessionCard } from '../createEvent.style';
import EventsStyles from '../createEvent.style';
import "./certificateTemplate.css"
import { notification } from 'antd';
import EnterTestDataModal from '../Tickets/EnterTestDataModal';
import { StyledBackButton, StyledButton, StyledError, StyledFooter } from '../SpeakerPrizes/SpeakerPrizes.style';
import { Document, Page, pdfjs } from 'react-pdf';
import fontkit from '@pdf-lib/fontkit'
import { degrees, PDFDocument, rgb } from 'pdf-lib';
import "./certificateTemplate.css"
import addEventCertificateEmbed from '../../../actions/eventDashboard/addEventCertificateEmbed';
import EventNavigation from '../Components/EventNavigation';
import uploadCertificatePdf from '../../../actions/eventDashboard/uploadCertificatePdf';
import updateEvent from '../../../actions/eventDashboard/updateEvent';
import UtmCodeForm from './UtmCodeForm';
import downloadFile from '../../../utils/downloadFile-util';
import fetchCommsVariables from '../../../actions/eventDashboard/fetchCommsVariables';
import updateEventComms from '../../../actions/eventDashboard/updateEventComms';
import { DEFAULT_TEMPLATE_DATA, timeFieldNames, attendeesFieldNames, dateFieldNames } from '../Tickets/utils.js'
import fetchEventPreview from '../../../actions/eventDashboard/fetchEventPreview';
import getFullPath from '../../../utils/getFullPath';
import updateEventCertificateEmbed from '../../../actions/eventDashboard/updateEventCertificateEmbed';
import removeFromEventAction from '../../../actions/eventDashboard/removeFromEventAction';
import PageLoader from '../Components/PageLoader';

const dataURItoBlob = (dataURI) => {
    const byteString = window.atob(dataURI)
    const arrayBuffer = new ArrayBuffer(byteString.length)
    const int8Array = new Uint8Array(arrayBuffer)
    for (let i = 0; i < byteString.length; i += 1) {
        int8Array[i] = byteString.charCodeAt(i)
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' })
    return blob
}

const { Option } = Select

const options = [
    {
        value: '/python/course/Nunito-Bold.ttf',
        label: 'Nunito Bold Font',
    },
    {
        value: '/python/course/Nunito-Regular.ttf',
        label: 'Nunito Regular Font',
    },
];

const variable = [
    { value: 'studentName', label: 'Student Name' },
    { value: 'parentName', label: 'Parent Name' },
    { value: 'studentGrade', label: 'Student Grade' },
    { value: 'eventDate', label: 'Event Date' },
    { value: 'eventName', label: 'Event Name' },
    // { value: 'speakerName', label: 'Speaker Name' },
    // { value: 'parentEmail', label: 'Parent Email' },
    // { value: 'parentPhone', label: 'Parent Phone' },
    // { value: 'eventTime', label: 'Event Time' },
    { value: 'summary', label: 'Summary' },
]

let initialLink = 'www.tekie.in/events?'

class CertificateTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            utm_codes: [
                {
                    utmSource: '',
                    utmCampaign: '',
                    utmTerm: '',
                    utmMedium: '',
                    utmContent: '',
                    webUrl: ''
                }
            ],
            Template: ['0', '1'],
            Time: ['Hour(s)', 'Minute(s)', 'Day(s)', 'Week(s)', 'Month(s)', 'Year(s)'], //Ask Defaults
            Variable: ['Student Name', 'Event Name', 'Event Date', 'Event Time', 'Student Grade'], //Ask Defaults
            Event: ['Before the event', 'After the event', 'After the Registeration'],
            showModal: false,
            pdf: null,
            existingPdfBytes: null,
            x: 0,
            y: 0,
            text_size: 10,
            font_family: null,
            variable: null,
            arrayBuffer: null,
            font: null,
            varible: null,
            utm_source: null,
            utm_campaign: null,
            utm_term: null,
            utm_medium: null,
            event_page_link: "www.tekie.in/event?",
            Attendees: ['To attendees', 'To non-attendees', 'To all'], //Ask Defaults
            showModal: false,
            visible: false,
            showAttendee: false,
            currentTemplateData: {},
            currentTemplateId: null,
            modalData: [],
            isSaving: false,
            error: false,
            showComms: true,
            variableDropdown: variable.map(item => ({ ...item, disabled: false })),
            certificateTemplate: [
                {
                    uploadedPDF: "",
                    x_c: 0,
                    y_c: 0,
                    red: 0,
                    green: 0,
                    blue: 0,
                    rotate: 0,
                    text_size: 0,
                    font_family: '/python/course/Nunito-Bold.ttf',
                    variable: '',
                }
            ],
            allMandatoryFields: {
                pdf: { content: null, isAbsent: false }
            },
            templateName: '',
            addedCommsRule: [],
            eventData: null,
            certificatePdf: null
        }
    }

    // isUtmCodesFormEmpty = (utmCodes) => {
    //     for (const form of utmCodes) {
    //         if (!form.utmSource || !form.utmMedium || !form.utmCampaign || !form.utmTerm) {
    //             return true
    //         }
    //     }
    //     return false
    // }
    isModalDataEmpty = (modalData) => {
        for (const form of modalData) {
            if (!form.templateName || form.commsVariables.length === 0) {
                return true
            }
        }
        return false
    }

    isEmbedEmpty = (certificateTemplate) => {
        for (const certificate of certificateTemplate) {
            Object.keys(certificate).forEach(key => {
                if (key !== 'uploadedPDF' && certificate[key] === '') {
                    return true
                }
            })
        }
        return false
    }
    validateForm = () => {
        const { pdf, utm_codes, modalData, showComms, certificateTemplate } = this.state
        const isEmptyTextSize = certificateTemplate.find(template =>
            !template.text_size || !template.x_c || !template.y_c || !template.variable)
        if (!pdf || this.isEmbedEmpty(certificateTemplate) || isEmptyTextSize) {
            this.setState({ error: true })
            return false
        }
        else if (showComms && this.isModalDataEmpty(modalData)) {
            this.setState({ error: true })
            return false
        } else {
            this.setState({ error: false })
            return true
        }
    }

    handleFormSubmit = async () => {
        if (this.validateForm()) {
            let isPassedTemplateCheck = false
            const { modalData } = this.state
            const ifStudentNameExist = this.state.certificateTemplate.find((certificate) => get(certificate, 'variable') === 'studentName')
            if (!ifStudentNameExist) return notification.warn({ message: 'Student name is mandatory in certificate embed' })
            if (this.state.showComms) {
                let saveData = []
                let errors = []
                let commsVariables = [];
                for (let i = 0; i < modalData.length; i++) {
                    const elem = modalData[i]
                    if (modalData[i].templateName.length < 3) {
                        errors.push(`Template Name for template-${i + 1} cannot be shorter than three characters.`)
                        break
                    }
                    if (!modalData[i].isTested) {
                        errors.push(`Template ${i + 1} not tested.`)
                        break
                    } else {
                        get(elem, 'commsVariables', []).forEach(comm => {
                            commsVariables.push(get(comm, 'id'))
                        })
                        const data = {
                            templateName: elem.templateName,
                            commsVariables: elem.commsVariables.map(comm => ({
                                dataField: get(comm, 'dataField'), emailVariableName: get(comm, 'label')
                            })),
                            condition: elem.condition,
                            attendanceFilter: elem.attendanceFilter,
                            unit: elem.unit,
                            value: elem.value,
                            isTested: elem.isTested,
                            isPassed: elem.isPassed,
                            isEmailRule: true
                        }
                        saveData.push(data)
                    }
                }
                if (!errors.length) {
                    isPassedTemplateCheck = true
                } else {
                    notification.error({
                        message: errors[0]
                    })
                    return null
                }
                const { id } = this.props.match.params;
                saveData = [...saveData, ...this.state.addedCommsRule]
                const newCommsVariables = [...new Set(commsVariables)]
                if (isPassedTemplateCheck) updateEventComms(id, saveData, {
                    commsVariablesConnectIds: newCommsVariables
                })
            }
            try {
                this.setState({
                    isSaving: true
                })
                const { certificateTemplate, utm_codes } = this.state
                const embedIds = []
                for (let i = 0; i < certificateTemplate.length; i++) {
                    let elem = certificateTemplate[i]
                    elem = {
                        ...elem,
                        font_family: get(elem, 'font_family'),
                        variable: get(elem, 'variable')
                    }
                    if (get(elem, 'id')) {
                        const res = await updateEventCertificateEmbed(get(elem, 'id'), elem)
                        if (get(res, 'updateEventCertificateEmbed.id')) embedIds.push(get(res, 'updateEventCertificateEmbed.id'))
                    } else if (get(elem, 'text_size')) {
                        const res = await addEventCertificateEmbed(elem)
                        if (get(res, 'addEventCertificateEmbed.id')) embedIds.push(get(res, 'addEventCertificateEmbed.id'))
                    }
                }
                if (this.state.pdf && typeof (this.state.pdf) !== 'string') {
                    const eventData = this.props.eventData && this.props.eventData.toJS()
                    const baseCertificateId = get(eventData[0], 'baseCertificate.id')
                    if (baseCertificateId) {
                        const res = await uploadCertificatePdf(this.state.pdf, this.props.match.params.id, baseCertificateId)
                    } else {
                        const res = await uploadCertificatePdf(this.state.pdf, this.props.match.params.id, '')
                    }
                }
                let certificateTemplateIds = this.props.fetchedEventEmbeds
                certificateTemplateIds = certificateTemplateIds && certificateTemplateIds.toJS()
                certificateTemplateIds = certificateTemplateIds.map(item => item.id)
                const updatedUtmCodes = []
                for (let code of utm_codes) {
                    const codeObj = {}
                    const utmParamsList = Object.keys(code)
                    utmParamsList.forEach(key => {

                        if (code[key]) {
                            codeObj[key] = code[key]
                        }
                    })
                    Object.keys(codeObj).length && updatedUtmCodes.push(codeObj)
                }
                if (certificateTemplateIds.length > 0 || get(utm_codes, '[0].webUrl')) {
                    if (!updatedUtmCodes.length) {
                        await updateEvent({
                            embedsConnectIds: embedIds,
                            isEmailCommsEnabled: this.state.showComms,
                        }, { eventId: this.props.match.params.id })
                    } else {
                        await updateEvent({
                            embedsConnectIds: embedIds, utm: { replace: updatedUtmCodes },
                            isEmailCommsEnabled: this.state.showComms,
                        }, { eventId: this.props.match.params.id })
                    }
                }

                let updatedStatus = this.props.eventUpdateStatus && this.props.eventUpdateStatus.toJS()
                if (!get(updatedStatus, 'loading') && get(updatedStatus, 'success')) {
                    this.setState({
                        isSaving: false
                    })
                    const updatedEvent = this.props.fetchedEvents.toJS()
                    this.props.history.push(`/eventDashboard/events/${get(updatedEvent[0], 'id')}/eventPreview`)
                }
            } catch (error) {
                this.setState({
                    isSaving: false
                })
            }
        }

    }

    addCertificatetemplates = () => {
        if (this.state.certificateTemplate.length < 5) {
            let temp = this.state.certificateTemplate
            temp.push(
                {
                    uploadedPDF: "",
                    x_c: 0,
                    y_c: 0,
                    red: 0,
                    green: 0,
                    blue: 0,
                    rotate: 0,
                    text_size: 0,
                    font_family: '/python/course/Nunito-Bold.ttf',
                    variable: '',
                }
            )
            this.setState({ certificateTemplate: temp })
        }
    }

    addUTM = () => {
        let temp = this.state.utm_codes
        temp.push(
            {
                utmSource: '',
                utmCampaign: '',
                utmTerm: '',
                utmMedium: '',
                utmContent: '',
                webUrl: ''
            }
        )

        this.setState({ utm_codes: temp })

    }

    onUpload = async (e) => {
        if (e.target.files && e.target.files[0]) {
            let file = e.target.files[0]
            this.setState({ pdf: file, certificatePdf: file });

            let reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onloadend = (evt) => {
                if (evt.target.readyState == FileReader.DONE) {
                    let arrayBuffer = evt.target.result;
                    this.setState({ arrayBuffer: arrayBuffer });
                }
            }
        }
    }

    async componentDidMount() {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
        const { id } = this.props.match.params
        initialLink = `www.tekie.in/events/${id}?`
        let modalDataArr = []
        for (let i = 0; i < this.state.Template.length; i++) {
            modalDataArr = [...modalDataArr, { ...DEFAULT_TEMPLATE_DATA, id: i }]
        }
        this.setState({
            modalData: modalDataArr,
            currentTemplateData: {},
        })
        await fetchCommsVariables()
        await fetchEventPreview(id)
    }

    componentDidUpdate(prevProps) {
        const { eventData, eventDataFetchStatus } = this.props
        if (eventDataFetchStatus && !get(eventDataFetchStatus.toJS(), 'loading')
            && get(eventDataFetchStatus.toJS(), 'success')
            && (prevProps.eventDataFetchStatus !== eventDataFetchStatus)) {
            const eventsData = eventData && eventData.toJS()
            let embedsArr = []
            let utmArr = []
            let variableArr = [...this.state.variableDropdown]
            let modalDataArr = []
            let Template = []
            const addedCommsRule = []
            if (get(eventsData, '[0].eventCommsRule', []).length) {
                get(eventsData, '[0].eventCommsRule', []).forEach((comm, index) => {
                    if (get(comm, 'isEmailRule', false)) {
                        const addedComms = get(comm, 'commsVariables', []).map(variable => get(variable, 'dataField'))
                        const templateData = []
                        const addedVariables = get(comm, 'commsVariables', []).map(variable => get(variable, 'emailVariableName'))
                        get(eventsData, '[0].commsVariables', []).forEach((variable) => {
                            if (addedComms.includes(get(variable, 'dataField'))
                                && addedVariables.includes(get(variable, 'emailVariableName'))) {
                                templateData.push({
                                    value: '',
                                    id: get(variable, 'id'),
                                    keyId: get(variable, 'id'),
                                    label: get(variable, 'emailVariableName'),
                                    dataField: get(variable, 'dataField')
                                })
                            }
                        })
                        Template.push(index)
                        modalDataArr.push({
                            ...comm,
                            id: modalDataArr.length,
                            commsVariables: templateData,
                            templateFieldData: templateData
                        })
                    } else {
                        addedCommsRule.push(comm)
                    }
                })
            }
            if (!modalDataArr.length) {
                for (let i = 0; i < this.state.Template.length; i++) {
                    modalDataArr = [...modalDataArr, { ...DEFAULT_TEMPLATE_DATA, id: i }]
                    Template.push(i)
                }
            }
            if (get(eventsData[0], 'embeds', []).length > 0) {
                const embeds = get(eventsData[0], 'embeds')
                embeds.forEach((embed) => {
                    variableArr = variableArr.map((item) => item.value === get(embed, 'variableName') ? { ...item, disabled: true } : item)
                    embedsArr.push({
                        id: get(embed, 'id'),
                        uploadedPDF: "",
                        x_c: get(embed, 'xDim'),
                        y_c: get(embed, 'yDim'),
                        red: get(embed, 'red'),
                        green: get(embed, 'green'),
                        blue: get(embed, 'blue'),
                        rotate: get(embed, 'rotate'),
                        text_size: get(embed, 'textSize'),
                        font_family: get(embed, 'fontFamily'),
                        variable: get(embed, 'variableName'),
                    })
                })
            }
            if (get(eventsData[0], 'utm', []).length > 0) {
                const utms = get(eventsData[0], 'utm')
                utms.forEach((utm) => {
                    utmArr.push({
                        utmSource: get(utm, 'utmSource'),
                        utmCampaign: get(utm, 'utmCampaign'),
                        utmTerm: get(utm, 'utmTerm'),
                        utmMedium: get(utm, 'utmMedium'),
                        utmContent: get(utm, 'utmContent'),
                        webUrl: get(utm, 'webUrl'),
                    })
                })
            }
            if (!utmArr.length) {
                utmArr = [
                    {
                        utmSource: '',
                        utmCampaign: '',
                        utmTerm: '',
                        utmMedium: '',
                        utmContent: '',
                        webUrl: ''
                    }
                ]
            }
            if (!embedsArr.length) {
                embedsArr = [
                    {
                        uploadedPDF: "",
                        x_c: 0,
                        y_c: 0,
                        red: 0,
                        green: 0,
                        blue: 0,
                        rotate: 0,
                        text_size: 0,
                        font_family: '/python/course/Nunito-Bold.ttf',
                        variable: '',
                    }
                ]
            }
            const pdf = getFullPath(get(eventsData[0], 'baseCertificate.uri'))
            this.setState({
                certificateTemplate: embedsArr,
                variableDropdown: variableArr,
                modalData: modalDataArr,
                utm_codes: utmArr,
                addedCommsRule: addedCommsRule,
                pdf: pdf,
                certificatePdf: pdf,
                showComms: get(eventsData, '[0].isEmailCommsEnabled', false),
                eventData: get(eventsData, '[0]')
            }, () => {
                if (this.state.pdf) {
                    fetch(this.state.pdf).then(res => res.blob()).then(blob => {
                        let reader = new FileReader();
                        reader.readAsArrayBuffer(blob);
                        reader.onloadend = (evt) => {
                            if (evt.target.readyState == FileReader.DONE) {
                                let arrayBuffer = evt.target.result;
                                this.setState({ arrayBuffer: arrayBuffer });
                            }
                        }
                    })
                }
            })
        }
    }

    isTemplateChecked = (result, _error, templateData, templateId) => {
        const { modalData } = this.state
        const selectedTemplate = modalData.find((elem) => elem.id === templateId)
        const templateDataKeys = Object.keys(templateData)
        let templateFieldData = []
        templateDataKeys.forEach((key) => {
            templateFieldData = [...templateFieldData, { dataField: key, emailVariableName: templateData[key] }]
        })
        const newTemplate = { ...selectedTemplate, templateFieldData: templateFieldData, isPassed: true, isTested: true }
        const newData = modalData.map((elem) => (elem.id === templateId) ? { ...newTemplate } : elem)
        this.setState({ modalData: newData })
    }

    selectEvent = (value, templateId) => {
        if (value === 'After the event') {
            const { modalData } = this.state
            const selectedTemplate = modalData.find((elem) => elem.id === templateId)
            const newTemplate = { ...selectedTemplate, showAttendee: true }
            const newData = modalData.map((elem) => (elem.id === templateId) ? { ...newTemplate } : elem)
            this.setState({ modalData: newData })
        } else {
            const { modalData } = this.state
            const selectedTemplate = modalData.find((elem) => elem.id === templateId)
            const newTemplate = { ...selectedTemplate, showAttendee: false }
            const newData = modalData.map((elem) => (elem.id === templateId) ? { ...newTemplate } : elem)
            this.setState({ modalData: newData })
        }
    }
    getTemplate = (i) => {
        let comms = (this.props.commsTags && this.props.commsTags.toJS()) || []
        comms = comms.filter(comm => get(comm, 'id') && get(comm, 'emailVariableName'))
        return (
            <div style={{ display: 'flex' }}>
                <Event.IndexNumber>{i + 1}</Event.IndexNumber>
                <div style={{ width: '98%', position: 'relative' }}>
                    <div style={{ display: 'flex', marginBottom: '30px' }}>
                        <div style={{ width: '30%' }}>
                            <SessionCard.InputLabel>Template Identifier</SessionCard.InputLabel>
                            <SessionCard.input
                                placeholder='Code_Carnival Bash'
                                size='large'
                                value={get(this.state, `modalData[${i}].templateName`)}
                                onChange={(e) => {
                                    const { modalData } = this.state
                                    const newData = modalData.map((elem) => (elem.id === i) ? { ...elem, templateName: e.target.value } : elem)
                                    this.setState({
                                        modalData: newData
                                    })
                                }}
                            />
                            {
                                (this.state.error && !get(this.state, `modalData[${i}].templateName`).trimStart()) && <StyledError>Required*</StyledError>
                            }
                        </div>
                        <div style={{ width: '40%', marginLeft: '15px' }}>
                            <SessionCard.InputLabel>Variable Used</SessionCard.InputLabel>
                            <Select
                                labelInValue
                                size='large'
                                style={{ minWidth: '65%' }}
                                mode='multiple'
                                value={get(this.state, `modalData[${i}].commsVariables`, []).map(comm =>
                                    ({ key: get(comm, 'id'), label: get(comm, 'label') }))}
                                onChange={(value, _option) => {
                                    if (!value.length) {
                                        const { modalData } = this.state
                                        const selectedTemplate = modalData.find((elem) => elem.id === i)
                                        const newTemplate = { ...selectedTemplate, commsVariables: [] }
                                        const clearData = modalData.map((elem) => (elem.id === i) ? { ...newTemplate } : elem)
                                        this.setState({ modalData: clearData })
                                    }
                                }}
                                onSelect={(value, _option) => {
                                    const { modalData } = this.state
                                    const selectedOption = comms && comms.find((variable) => variable.id === get(value, 'key'))
                                    const selectedTemplate = modalData.find((elem) => elem.id === i)
                                    const addData = [...selectedTemplate.commsVariables,
                                    {
                                        value: '',
                                        id: get(selectedOption, 'id'),
                                        label: get(selectedOption, 'emailVariableName'),
                                        dataField: get(selectedOption, 'dataField')
                                    }
                                    ]
                                    const newTemplate = { ...selectedTemplate, commsVariables: addData }
                                    const newData = modalData.map((elem) => (elem.id === i) ? { ...newTemplate } : elem)
                                    this.setState({ modalData: newData })
                                }}
                                onDeselect={(value, _option) => {
                                    const { modalData } = this.state
                                    const selectedTemplate = modalData.find((elem) => elem.id === i)
                                    const updateData = selectedTemplate.commsVariables.filter((elem) => elem.id !== get(value, 'key'))
                                    const newTemplate = { ...selectedTemplate, commsVariables: updateData }
                                    const newData = modalData.map((elem) => (elem.id === i) ? { ...newTemplate } : elem)
                                    this.setState({ modalData: newData })
                                }}
                            >
                                {comms.map((variable) =>
                                (
                                    <Select.Option
                                        key={get(variable, 'id')}
                                        value={get(variable, 'id')}>{get(variable, 'emailVariableName')}</Select.Option>
                                )
                                )}
                            </Select>
                            {
                                (this.state.error && !get(this.state, `modalData[${i}].commsVariables`, []).length) && <StyledError>Required*</StyledError>
                            }
                        </div>
                        <div style={{ margin: '20px 0 0 20px' }}>
                            <SessionCard.TestButton
                                isTested={this.state.modalData
                                    && this.state.modalData[i]
                                    && this.state.modalData[i].isPassed}
                                onClick={() =>
                                    this.testVisible(comms, this.state.modalData[i].commsVariables, get(this.state, `modalData[${i}].templateName`), i)}
                            >
                                Test Template
                            </SessionCard.TestButton>
                        </div>
                    </div>
                    <div style={{ width: '70%' }}>
                        <SessionCard.InputLabel>Trigger Time</SessionCard.InputLabel>
                        <InputNumber
                            min={1}
                            value={get(this.state, `modalData[${i}].value`)}
                            size='large'
                            style={{ width: '10%' }}
                            onChange={(value) => {
                                const { modalData } = this.state
                                const selectedTemplate = modalData.find((elem) => elem.id === i)
                                const newTemplate = { ...selectedTemplate, value: value }
                                const newData = modalData.map((elem) => (elem.id === i) ? { ...newTemplate } : elem)
                                this.setState({ modalData: newData })
                            }}
                        />
                        <Select
                            style={{ marginLeft: '10px', minWidth: '15%' }}
                            size='large'
                            value={get(this.state, `modalData[${i}].unit`)}
                            onChange={(value) => {
                                const { modalData } = this.state
                                const selectedTemplate = modalData.find((elem) => elem.id === i)
                                let newValue;
                                timeFieldNames.forEach((field) => {
                                    if (field.value === value) {
                                        newValue = field.fieldName
                                    }
                                })
                                const newTemplate = { ...selectedTemplate, unit: newValue }
                                const newData = modalData.map((elem) => (elem.id === i) ? { ...newTemplate } : elem)
                                this.setState({ modalData: newData })
                            }}
                        >
                            {this.state.Time.map((time) => (
                                <Select.Option value={time} key={time}>{time}</Select.Option>
                            ))}
                        </Select>
                        <Select
                            value={get(this.state, `modalData[${i}].condition`)}
                            style={{ marginLeft: '15px', minWidth: '27%' }}
                            size='large'
                            onChange={(value) => {
                                const { modalData } = this.state
                                const selectedTemplate = modalData.find((elem) => elem.id === i)
                                let newValue;
                                dateFieldNames.forEach((field) => {
                                    if (field.value === value) {
                                        newValue = field.fieldName
                                    }
                                })
                                const newTemplate = { ...selectedTemplate, condition: newValue }
                                const newData = modalData.map((elem) => (elem.id === i) ? { ...newTemplate } : elem)
                                this.setState({ modalData: newData }, () => this.selectEvent(value, i))
                            }}
                        >
                            {this.state.Event.map((event) => (
                                <Select.Option value={event} key={event}>{event}</Select.Option>
                            ))}
                        </Select>
                        {this.state.modalData && this.state.modalData[i]
                            && this.state.modalData[i].showAttendee &&
                            <Select
                                value={get(this.state, `modalData[${i}].attendanceFilter`)}
                                style={{ marginLeft: '15px', minWidth: '23%' }}
                                size='large'
                                onChange={(value) => {
                                    const { modalData } = this.state
                                    const selectedTemplate = modalData.find((elem) => elem.id === i)
                                    let newValue;
                                    attendeesFieldNames.forEach((field) => {
                                        if (field.value === value) {
                                            newValue = field.fieldName
                                        }
                                    })
                                    const newTemplate = { ...selectedTemplate, attendanceFilter: newValue }
                                    const newData = modalData.map((elem) => (elem.id === i) ? { ...newTemplate } : elem)
                                    this.setState({ modalData: newData })
                                }}
                            >
                                {this.state.Attendees.map((attendee) => (
                                    <Select.Option value={attendee} key={attendee}>{attendee}</Select.Option>
                                ))}
                            </Select>}
                    </div>
                    {i > 1 && <Icon
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                        }}
                        onClick={() => this.removeTemplate(i)} type='close' />}
                    <Event.TempDivider dashed />
                </div>
            </div>
        )
    }
    removeTemplate = (index) => {
        const { modalData, Template } = this.state
        this.setState({
            modalData: modalData.filter((data, ind) => ind !== index),
            Template: Template.filter((temp, ind) => ind !== index)
        })
    }

    removeUtmForm = (idx) => {
        const { utm_codes } = this.state
        const filteredUtmCodes = utm_codes.filter((form, index) => index !== idx)
        this.setState({
            utm_codes: filteredUtmCodes
        })
    }

    closeModal = () => {
        this.setState({
            visible: false,
            currentTemplateData: {},
            templateName: '',
            currentTemplateId: null
        })
    }

    testVisible = (comms = [], data, templateName, id) => {
        const checkParentNumberFlag = data.find((item) => item.dataField === 'parentEmail')
        let updatedData = [...data]
        if (!checkParentNumberFlag) {
            const selectedOption = comms.find((item) => get(item, 'dataField') === 'parentEmail')
            updatedData = [...updatedData,
            {
                value: '',
                id: get(selectedOption, 'id'),
                label: get(selectedOption, 'emailVariableName'),
                dataField: get(selectedOption, 'dataField')
            }
            ]
        }
        this.setState({
            currentTemplateData: updatedData,
            currentTemplateId: id,
            templateName,
            visible: true
        })
    }

    makeColorInteger = (string) => {
        if (string) return Number((parseInt(string) / 255).toFixed(2))
        return 0
    }

    generateCertificate = async (file) => {
        const { certificateTemplate } = this.state

        try {

            const pdfDoc = await PDFDocument.load(file)
            pdfDoc.registerFontkit(fontkit)

            for (let i = 0; i < certificateTemplate.length; i++) {

                const fontBytes = await fetch(`${process.env.REACT_APP_FILE_BASE_URL}${certificateTemplate[i].font_family}`).then((res) =>
                    res.arrayBuffer()
                )
                const font = await pdfDoc.embedFont(fontBytes)
                const page = pdfDoc.getPages()
                const firstPage = page[0]
                firstPage.drawText(`${get(certificateTemplate, `[${i}].variable`)}`, {
                    x: certificateTemplate[i].x_c ? parseInt(certificateTemplate[i].x_c) : 0,
                    y: certificateTemplate[i].y_c ? parseInt(certificateTemplate[i].y_c) : 0,
                    size: certificateTemplate[i].text_size ? parseInt(certificateTemplate[i].text_size) : 0,
                    font: font,
                    color: rgb(this.makeColorInteger(certificateTemplate[i].red), this.makeColorInteger(certificateTemplate[i].green), this.makeColorInteger(certificateTemplate[i].blue)),
                    rotate: degrees(certificateTemplate[i].rotate ? parseInt(certificateTemplate[i].rotate) : 0),
                })
            }

            const pdfBase64 = await pdfDoc.saveAsBase64()
            const pdfBytes = await pdfDoc.save()

            const blob = dataURItoBlob(pdfBase64)
            const url = URL.createObjectURL(blob)
            this.setState({ certificatePdf: blob })
        } catch (e) {
            console.log('PDF GENERATION EXCEPTION => ', e)
        }
    }
    coordinateHandler = ({ coordType, idx }, event) => {

        const { certificateTemplate } = this.state
        const updatedCertificateTemplate = certificateTemplate.map((certificate, index) => {
            if (idx === index && coordType) {
                return coordType === 'x_c' ? { ...certificate, x_c: event.target.value } : { ...certificate, y_c: event.target.value }
            } return certificate
        })
        this.setState({ certificateTemplate: updatedCertificateTemplate });
    }

    fontChangeHandler = (idx, value) => {
        const { certificateTemplate } = this.state
        const updatedCertificateTemplate = certificateTemplate.map((certificate, index) => {
            if (index === idx) {
                return { ...certificate, font_family: value }
            } return certificate
        })
        this.setState({ certificateTemplate: updatedCertificateTemplate });
    }

    fontSizeHandler = (idx, event) => {
        const { certificateTemplate } = this.state
        const updatedCertificateTemplate = certificateTemplate.map((certificate, index) => {
            if (index === idx) {
                return { ...certificate, text_size: event.target.value }
            } return certificate
        })
        this.setState({ certificateTemplate: updatedCertificateTemplate });
    }

    varibleChangeHandler = (idx, value) => {
        const { certificateTemplate } = this.state
        const updatedCertificateTemplate = certificateTemplate.map((certificate, index) => {
            if (index === idx) {
                return { ...certificate, variable: value }
            } return certificate
        })
        let newVariableDropdown = [...this.state.variableDropdown]
        if (updatedCertificateTemplate.length) {
            newVariableDropdown = this.state.variableDropdown.map((item) => {
                const addedVariables = updatedCertificateTemplate.map(variableVal => variableVal.variable)
                if (addedVariables.includes(item.value)) {
                    return { ...item, disabled: true }
                } else return { ...item , disabled: false }
            })
        }
        this.setState({
            certificateTemplate: updatedCertificateTemplate,
            variableDropdown: newVariableDropdown,
        });
    }

    myChangeHandler_source = (event, idx) => {

        const { utm_codes } = this.state
        let value = event.target.value
        const updatedUtmCodes = utm_codes.map((code, index) => {
            if (index === idx) {
                return { ...code, utmSource: value }
            } return code
        })
        initialLink += value
        this.setState({ utm_codes: updatedUtmCodes });
    }

    myChangeHandler_campaign = (event, idx) => {
        const { utm_codes } = this.state
        const updatedUtmCodes = utm_codes.map((code, index) => {
            if (index === idx) {
                return { ...code, utmCampaign: event.target.value }
            } return code
        })
        this.setState({ utm_codes: updatedUtmCodes });
    }

    myChangeHandler_term = (event, idx) => {
        const { utm_codes } = this.state
        const updatedUtmCodes = utm_codes.map((code, index) => {
            if (index === idx) {
                return { ...code, utmTerm: event.target.value }
            } return code
        })
        this.setState({ utm_codes: updatedUtmCodes });
    }

    myChangeHandler_medium = (event, idx) => {
        const { utm_codes } = this.state
        const updatedUtmCodes = utm_codes.map((code, index) => {
            if (index === idx) {
                return { ...code, utmMedium: event.target.value }
            } return code
        })
        this.setState({ utm_codes: updatedUtmCodes });
    }
    myChangeHandler_content = (event, idx) => {
        const { utm_codes } = this.state
        const updatedUtmCodes = utm_codes.map((code, index) => {
            if (index === idx) {
                return { ...code, utmContent: event.target.value }
            } return code
        })
        this.setState({ utm_codes: updatedUtmCodes });
    }

    chooseRgb = (event, idx, color) => {
        const { certificateTemplate } = this.state
        const updatedCertificateTemplate = certificateTemplate.map((certificate, index) => {
            if (idx === index && color) {
                if (color === 'red') return { ...certificate, red: event.target.value }
                if (color === 'green') return { ...certificate, green: event.target.value }
                if (color === 'blue') return { ...certificate, blue: event.target.value }
            } return certificate
        })
        this.setState({ certificateTemplate: updatedCertificateTemplate })
    }

    chooseRotate = (event, idx) => {
        const { certificateTemplate } = this.state
        const updatedCertificateTemplate = certificateTemplate.map((certificate, index) => {
            if (idx === index) {
                return { ...certificate, rotate: event.target.value }
            } return certificate
        })
        this.setState({ certificateTemplate: updatedCertificateTemplate })
    }

    deleteCoordinatesForm = async (item, idx) => {
        const { certificateTemplate, eventData } = this.state
        const newDropdown = this.state.variableDropdown.map((elem) => elem.value === item.variable ? { ...elem, disabled: false } : elem)
        if (get(item, 'id')) {
            const { id } = this.props.match.params;
            const findEmbed = get(eventData, 'embeds', []).find(embed => get(embed, 'id') === get(item, 'id'))
            if (findEmbed) {
                removeFromEventAction(id, '', get(item, 'id'), 'embed')
            }
        }
        const updatedCertificateTemplate = certificateTemplate.filter((code, index) => index !== idx)
        this.setState({
            certificateTemplate: updatedCertificateTemplate,
            variableDropdown: newDropdown,
        });
    }

    setUtmCodes = (value) => {
        this.setState({ utm_codes: value })
    }

    render() {
        const { isAddingEventCertificateEmbeds, eventDataFetchStatus } = this.props
        const { Template, certificateTemplate, visible, variableDropdown } = this.state
        let updatedStatus = this.props.eventUpdateStatus && this.props.eventUpdateStatus.toJS()
        const { id } = this.props.match.params
        const currentPath = this.props.match.path.split('/')
        const currentNav = currentPath[currentPath.length - 1]
        return (
            <Event.EventsWrapper style={{ height: '100%', width: '100%' }}>
                <PageLoader isfetching={eventDataFetchStatus && get(eventDataFetchStatus.toJS(), 'loading')} />
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                    <EventsStyles.PageTitle style={{ marginLeft: '14px' }}>Create Event</EventsStyles.PageTitle>
                    {id && <EventNavigation id={id} isCurrent={currentNav} />}
                </div>
                <EnterTestDataModal
                    modalId={this.state.currentTemplateId}
                    modalData={this.state.currentTemplateData}
                    templateName={this.state.templateName}
                    isTemplateChecked={this.isTemplateChecked}
                    closeModal={this.closeModal}
                    visible={visible}
                    isMail={true}
                />
                <div style={{ margin: '0px 20px 20px 20px', backgroundColor: 'white', height: '95vh', overflow: 'scroll' }}>
                    <div>
                        <SessionCard.InfoContainer>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div>
                                    <SessionCard.InfoTitle className='infoTitle'>
                                        <Event.Email />
                                        Email Comms
                                        <SessionCard.Subtitle>
                                            Enter comms to be sent via Email
                                        </SessionCard.Subtitle>
                                    </SessionCard.InfoTitle>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingLeft: '30px' }}>
                                    <div className='toggleText'>OFF</div>
                                    <Switch style={this.state.showComms ? { backgroundColor: '#0ca789' } : { backgroundColor: 'grey' }}
                                        onChange={() => this.setState({ showComms: !this.state.showComms })}
                                        checked={Boolean(this.state.showComms)}
                                    />
                                    <div className='toggleText'>ON</div>
                                </div>
                            </div>
                            {
                                this.state.showComms && (
                                    <div style={{ padding: '20px 0 20px 20px' }} >
                                        {Template.map((e, index) => this.getTemplate(index))}
                                        <div style={{ marginLeft: '50px' }}>
                                            <SessionCard.PlusButton
                                                type="primary"
                                                back='#cbbedf'
                                                shape='circle'
                                                size='large'
                                                onClick={() => {
                                                    this.setState({
                                                        Template: [...Template, this.state.Template.length]
                                                    }, () => {
                                                        this.setState({
                                                            modalData: [...this.state.modalData,
                                                            { ...DEFAULT_TEMPLATE_DATA, id: this.state.Template.length - 1 }]
                                                        })
                                                    })
                                                }}
                                            ><PlusOutlined style={{ fontSize: '20px' }} /></SessionCard.PlusButton>
                                            <SessionCard.Smalltitle>Add more Templates</SessionCard.Smalltitle>
                                        </div>
                                    </div>
                                )
                            }

                        </SessionCard.InfoContainer>
                    </div>

                    <div>
                        <div className='heading' style={{ marginLeft: '30px', paddingTop: '50px' }} >
                            <div>
                                <img width="25px" src="https://img.icons8.com/ios-glyphs/50/000000/student-center.png" />
                            </div>
                            <div>
                                Certificate Template
                            </div>
                        </div>
                        <div className='v-row' >
                            <div className='upload' style={{ marginLeft: '90px' }} >
                                <div>
                                    <img width='20px' src="https://img.icons8.com/material-rounded/24/000000/upload--v1.png" />
                                </div>
                                <input className='upload-inp' type='file' accept='application/pdf' onChange={(e) => { this.onUpload(e) }} placeholder='Upload PDF' />
                                {
                                    (this.state.error && !this.state.pdf) && <StyledError>Required*</StyledError>
                                }
                            </div>
                            <div className='generate' onClick={() => { this.generateCertificate(this.state.arrayBuffer) }} >
                                Generate PDF
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ marginLeft: '50px' }}>
                                {
                                    certificateTemplate.map((item, index) => {
                                        return (
                                            <div className='parent' >
                                                <div>
                                                    <div key={index} className='content' >
                                                        <div className='large-number' >
                                                            {index + 1}
                                                        </div>
                                                        <div>
                                                            <div className='v-row' >
                                                                <div className='v-cell' >
                                                                    <div className='v-head' >
                                                                        X Coordinate
                                                                    </div>
                                                                    <div>
                                                                        <input onChange={(e) => this.coordinateHandler({ coordType: 'x_c', idx: index }, e)} name="x" type="number" value={item.x_c} defaultValue={item.x_c} className='v-inp' />
                                                                    </div>
                                                                    {(this.state.error && !get(this.state, `certificateTemplate[${index}].x_c`)) && (
                                                                        <StyledError>Required*</StyledError>
                                                                    )}
                                                                </div>

                                                                <div className='v-cell' >
                                                                    <div className='v-head' >
                                                                        Y Coordinate
                                                                    </div>
                                                                    <div>
                                                                        <input onChange={(e) => this.coordinateHandler({ coordType: 'y_c', idx: index }, e)} name="y" type="number" value={item.y_c} defaultValue={item.y_c} className='v-inp' />
                                                                    </div>
                                                                    {(this.state.error && !get(this.state, `certificateTemplate[${index}].y_c`)) && (
                                                                        <StyledError>Required*</StyledError>
                                                                    )}
                                                                </div>

                                                                <div className='v-cell' >
                                                                    <div className='v-head' >
                                                                        Text Size
                                                                    </div>
                                                                    <div>
                                                                        <input onChange={(e) => this.fontSizeHandler(index, e)} name="text_size" min={0} type="number" value={item.text_size} defaultValue={item.text_size} className='v-inp' />
                                                                    </div>
                                                                    {!get(this.state, `certificateTemplate[${index}].text_size`) && (
                                                                        <StyledError>Required*</StyledError>
                                                                    )}
                                                                </div>

                                                                <div className='v-cell' >
                                                                    <div className='v-head' >
                                                                        Font Family
                                                                    </div>
                                                                    <div>
                                                                        <Select
                                                                            value={item.font_family}
                                                                            placeholder={item.font_family}
                                                                            style={{ width: 200 }}
                                                                            onChange={(value) => this.fontChangeHandler(index, value)}>
                                                                            {options.map(option =>
                                                                                <Option value={option.value} key={option.value}>{option.label}</Option>
                                                                            )}
                                                                        </Select>
                                                                        {/* <Cascader name='font' value={item.font_family} options={options} placeholder={item.font_family} onChange={(e) => this.fontChangeHandler(index, e)} className='v-inp-2' /> */}
                                                                    </div>
                                                                    {!get(this.state, `certificateTemplate[${index}].font_family`) && (
                                                                        <StyledError>Required*</StyledError>
                                                                    )}
                                                                </div>

                                                                <div className='v-cell' >
                                                                    <div className='v-head' >
                                                                        Variable
                                                                    </div>
                                                                    <div>
                                                                        <Select
                                                                            value={item.variable}
                                                                            placeholder={item.variable}
                                                                            style={{ width: 200 }}
                                                                            onChange={(value) => this.varibleChangeHandler(index, value)}>
                                                                            {this.state.variableDropdown.map(option =>
                                                                                <Option value={option.value}
                                                                                    disabled={option.disabled}
                                                                                    key={option.value}>{option.label}</Option>)}
                                                                        </Select>
                                                                        {/* <Cascader name='variable' value={item.variable} options={this.state.variableDropdown} placeholder={item.variable} onChange={(value) => this.varibleChangeHandler(index, value)} className='v-inp-2' /> */}
                                                                    </div>
                                                                    {!get(this.state, `certificateTemplate[${index}].variable`) && (
                                                                        <StyledError>Required*</StyledError>
                                                                    )}
                                                                </div>
                                                                <div className='v-cell' >
                                                                    <div className='v-head' >
                                                                        Red
                                                                    </div>
                                                                    <div>
                                                                        <input name="red" onChange={(e) => this.chooseRgb(e, index, 'red')} type="number" min={0} max={255} value={item.red} defaultValue={item.red} className='v-inp' />
                                                                    </div>
                                                                    {/* {(this.state.error && !get(this.state, `certificateTemplate[${index}].red`)) && (
                                                                        <StyledError>Required*</StyledError>
                                                                    )} */}
                                                                </div>
                                                                <div className='v-cell' >
                                                                    <div className='v-head' >
                                                                        Green
                                                                    </div>
                                                                    <div>
                                                                        <input name="green" onChange={(e) => this.chooseRgb(e, index, 'green')} type="number" min={0} max={255} value={item.green} defaultValue={item.green} className='v-inp' />
                                                                    </div>
                                                                    {/* {(this.state.error && !get(this.state, `certificateTemplate[${index}].green`)) && (
                                                                        <StyledError>Required*</StyledError>
                                                                    )} */}
                                                                </div>
                                                                <div className='v-cell' >
                                                                    <div className='v-head' >
                                                                        Blue
                                                                    </div>
                                                                    <div>
                                                                        <input name="blue" onChange={(e) => this.chooseRgb(e, index, 'blue')} type="number" min={0} max={255} value={item.blue} defaultValue={item.blue} className='v-inp' />
                                                                    </div>
                                                                    {/* {(this.state.error && !get(this.state, `certificateTemplate[${index}].blue`)) && (
                                                                        <StyledError>Required*</StyledError>
                                                                    )} */}
                                                                </div>
                                                                <div className='v-cell' >
                                                                    <div className='v-head' >
                                                                        Rotate
                                                                    </div>
                                                                    <div>
                                                                        <input name="rotate" onChange={(e) => this.chooseRotate(e, index)} type="number" min={-359} max={359} value={item.rotate} defaultValue={item.rotate} className='v-inp' />
                                                                    </div>
                                                                    {/* {(this.state.error && !get(this.state, `certificateTemplate[${index}].rotate`)) && (
                                                                        <StyledError>Required*</StyledError>
                                                                    )} */}
                                                                </div>
                                                                {index >= 1 &&
                                                                    <div style={{ cursor: 'pointer', position: 'absolute', top: '-12px', right: '-16px' }} onClick={() => { this.deleteCoordinatesForm(item, index) }} >
                                                                        <img width='30px' src="https://img.icons8.com/material-outlined/50/000000/cancel--v1.png" />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='content-right' >
                                <div className='certificate-head' >
                                    Certificate Preview
                                </div>
                                {
                                    this.state.certificatePdf ? (
                                        <div className='certificate'>
                                            <Document file={this.state.certificatePdf} >
                                                <Page pageNumber={1} />
                                            </Document>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )
                                }
                                <div className='download-btn' onClick={() => this.state.certificateTemplate
                                    && downloadFile(this.state.certificatePdf, 'certificate-template.pdf')} >
                                    <div>
                                        <img width="20px" src="https://img.icons8.com/windows/32/26e07f/download--v1.png" />
                                    </div>
                                    <div>
                                        Download Sample
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='content' >
                            <div className='plus' onClick={this.addCertificatetemplates}  >
                                +
                            </div>
                            <div className='add-more' >
                                Add more variable (Upto 5)
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='heading' style={{ paddingLeft: '30px', paddingTop: '50px' }}  >
                            <div>
                                <img width='30px' src="https://img.icons8.com/material-outlined/24/000000/source-code.png" />
                            </div>
                            <div>
                                UTM Codes
                            </div>
                        </div>
                        <div style={{ marginLeft: '80px' }}>
                            {
                                this.state.utm_codes.map((item, index) => {
                                    return (
                                        <UtmCodeForm item={item} removeUtmForm={this.removeUtmForm} formError={this.state.error} index={index} utmCodes={this.state.utm_codes} setUtmCodes={this.setUtmCodes} initialLink={initialLink} />
                                    )
                                })
                            }
                        </div>
                        <div style={{ paddingLeft: '20px' }}>
                            {this.state.error && !this.state.utm_codes.length && (
                                <StyledError>Required*</StyledError>
                            )}
                        </div>
                        <div className='content' >
                            <div className='plus' onClick={this.addUTM} >
                                +
                            </div>
                            <div className='add-more' >
                                Add more codes
                            </div>
                        </div>
                    </div>
                </div>

                <StyledFooter>
                    <StyledBackButton mRight onClick={() => this.props.history.goBack()}>
                        Back
                    </StyledBackButton>
                    <StyledButton
                        loading={isAddingEventCertificateEmbeds || this.state.isSaving}
                        disabled={isAddingEventCertificateEmbeds || this.state.isSaving}
                        onClick={this.handleFormSubmit}>
                        {isAddingEventCertificateEmbeds ? 'Saving...' : ' Save & Continue'}
                    </StyledButton>
                </StyledFooter>
            </Event.EventsWrapper>
        );
    }
}

export default CertificateTemplate;