/* eslint-disable */
import "prismjs/themes/prism-tomorrow.css"; // only needed for code highlighting
import React from 'react'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import { NotionRenderer } from 'react-notion'
import 'react-notion/src/styles.css'
import { LeftOutlined } from '@ant-design/icons'
import CoursesStyle from './MentorCourse.style'


class Courses extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: {},
      isFetching: true,
      fetchError: ''
    }
  }
  componentDidMount = async () => {
    const cid = get(this.props, 'match.params.courseId')
    if (cid) {
      const data = await fetch(
        `https://notion-api.splitbee.io/v1/page/${cid}`
      ).then(res => res.json())
        .catch(() => {
          this.setState({
            fetchError: 'something went wrong, please reload the page',
            isFetching: false
          })
        })
      if (data) {
        this.setState({
          page: data,
          isFetching: false
        })
        const tempAll = document.querySelectorAll('.notion-page-link')
        tempAll.forEach(item => {
          const id = item.href.split('/').pop()
          item.href = `/mentorDashboard/course/${id}`
        })
      }
    }
  }
  componentDidUpdate = async (prevProps) => {
    const cid = this.props.match.params.courseId
    const prevCid = prevProps.match.params.courseId
    if (cid !== prevCid) {
      const data = await fetch(
        `https://notion-api.splitbee.io/v1/page/${cid}`
      ).then(res => res.json())
      this.setState({
        page: data,
        isFetching: false
      })
    }
  }

  render() {
    return (
      <>
        {this.state.isFetching ? (
          <div className='loading-container show'>
            <div className='loading-bar-container'>
              <div />
            </div>
          </div>
        ) : null}
        <CoursesStyle.PageTitleContainer>
          <CoursesStyle.PageTitle to='/mentorDashboard/course'><LeftOutlined /> Back to Courses</CoursesStyle.PageTitle>
        </CoursesStyle.PageTitleContainer>
        {this.state.fetchError !== '' && (
          <CoursesStyle.FetchError>{this.state.fetchError}</CoursesStyle.FetchError>
        )}
        <CoursesStyle.FlexContainer>
          <NotionRenderer
            blockMap={this.state.page}
            fullPage='true'
            hideHeader='false'
            customBlockComponents={{
              page: ({ blockValue, renderComponent }) => {
                return <Link to={`/mentorDashboard/course/${blockValue.id}`}>{renderComponent()}</Link>
              },
              drive: ({ blockValue }) => {
                return (
                  <a
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      cursor: 'pointer',
                      alignItems: 'center',
                      backgroundColor: '#EEE',
                      padding: '4px 12px',
                      margin: '12px 0px',
                      borderRadius: '6px',
                    }}
                    onClick={() => {
                      if (blockValue && blockValue.format && get(blockValue, 'format.drive_properties')) {
                        window.open(get(blockValue, 'format.drive_properties.url'), '_blank', 'noreferrer')
                      }
                    }}
                  >
                    <span style={{
                      width: '15px',
                      height: '18px',
                      display: 'flex',
                      backgroundPosition: 'center',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      marginRight: '6px',
                      backgroundImage: `url(${get(blockValue, 'format.drive_properties.icon')})`,
                    }} />
                    {get(blockValue, 'format.drive_properties.title')}
                  </a>
                )
              }
            }}
          />
        </CoursesStyle.FlexContainer>
      </>
    )
  }
}

export default Courses
