import styled from 'styled-components'

const MentorClassesStyle = styled.div`
    height: 100%;
    width: 100%;
    margin: 0 auto;
    font-family: 'Inter' !important;
`
const TopContainer = styled.div`
    letter-spacing: 0.5px;
`
const flexDiv1 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
const flexDiv2 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justifyContent: center;
`
const progressDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
`

const progress = styled.div`
    font-size: 12px;
    color: #757575;
    font-weight: 400;
    margin-right: 5px;
`
const ClassInfo = styled.div`
    color: #9E9E9E ;
    fontSize: 12px;
    fontWeight: 400;
`
const ClassSubInfo = styled.div`
    color: #212121 ;
    fontSize: 12px;
    fontWeight: 500;
`
const batchInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2%;
    margin-bottom: 2%;
`
const batchColor = styled.div`
    border-radius: 4px;
    width: 20px;
    height: 20px;
    margin-right: 14px;
`
const batchBuss = styled.div`
    font-size: 16px;
    font-weight: 500;
    margin-right: 14px;
    color: '#111111'
`
const batchType = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: #01AA93;
    border-radius: 4px;
    font-size: 8px;
    padding: 5px;
    font-weight: 500;
`
const courseImageBorder = styled.div`
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 16px;
    width: 201px;
    height: 201px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
`

const Container = styled.div`
    display: flex;
    flex-direction: row;
    padding-right: 30px
`
const Divider = styled.div`
    border-bottom: 1px solid #EEEEEE;
    margin-right: 10%;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 600px;
`
const feedbackBox = styled.div`
    border: 0.2px solid #bbaeae;
    box-sizing: border-box;
    border-radius: 8px;
    width: 79%;
    margin-left: 10px;
    margin-top: 12px;
    margin-bottom: 10px;
    padding: 5px;
    padding-left: 5px;
    font-size: 12px;
`

const DateBox = styled.div`
    font-size: 12px;
    font-weight: 500;
    color: #9E9E9E;
    padding-bottom: 20px;
`

const ClassTop = styled.div`
    color: #616161;
    font-size: 10px;
`

MentorClassesStyle.TopContainer = TopContainer
MentorClassesStyle.flexDiv1 = flexDiv1
MentorClassesStyle.flexDiv2 = flexDiv2
MentorClassesStyle.progressDiv = progressDiv
MentorClassesStyle.ClassInfo = ClassInfo
MentorClassesStyle.ClassSubInfo = ClassSubInfo
MentorClassesStyle.batchColor = batchColor
MentorClassesStyle.batchBuss = batchBuss
MentorClassesStyle.batchType = batchType
MentorClassesStyle.batchInfo = batchInfo
MentorClassesStyle.courseImageBorder = courseImageBorder
MentorClassesStyle.progress = progress
MentorClassesStyle.Container = Container
MentorClassesStyle.Divider = Divider
MentorClassesStyle.feedbackBox = feedbackBox
MentorClassesStyle.DateBox = DateBox
MentorClassesStyle.ClassTop = ClassTop

export default MentorClassesStyle
