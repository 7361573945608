import { Button } from 'antd'
import { Formik, Form } from 'formik'
import { get } from 'lodash'
import React from 'react'
import FormInput from './FormInput'
import { trainerAddFormValidation, trainerEditFormValidation } from './trainerFormValidation'

const AddTrainerForm = ({ initialState, handleSubmit, trainerAddUpdateStatus, operation }) => {
  return (
    <Formik
      initialValues={initialState}
      onSubmit={handleSubmit}
      validateOnBlur
      validationSchema={operation === 'edit' ? trainerEditFormValidation : trainerAddFormValidation}
    >
      {({ values, handleChange }) => (
        <Form style={{ padding: '0 10px' }} id='form'>
          <div
            style={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridGap: 15
            }}
      >
        <FormInput
          value={values.name}
          onChange={handleChange}
          placeholder='Type Name'
          name='name'
          addonBefore='Name: '
        />
        <FormInput
          value={values.email}
          onChange={handleChange}
          placeholder='Type Email'
          name='email'
          type='email'
          addonBefore='Email: '
        />
        <FormInput
          value={values.phoneNumber}
          onChange={handleChange}
          placeholder='Type Phone number'
          name='phoneNumber'
          type='number'
          disabled={operation === 'edit' && !get(initialState, 'phoneNumber')}
          addonBefore='Phone Number: '
        />
        <FormInput
          value={values.username}
          onChange={handleChange}
          placeholder='Type Username'
          name='username'
          addonBefore='username'
        />
        <FormInput
            value={values.password}
            onChange={handleChange}
            placeholder='Type Password'
            name='password'
            addonBefore='Password'
        />
        <FormInput
            value={values.confirmPassword}
            onChange={handleChange}
            placeholder='Confirm Password'
            name='confirmPassword'
            addonBefore='confirmPassword'
        />
        </div>
        <Button
        type='primary'
        icon='file'
        id='add-btn'
        htmlType='submit'
        loading={
        trainerAddUpdateStatus && get(trainerAddUpdateStatus.toJS(), 'loading')
        }
        style={{ marginTop: '15px' }}
    >
        {operation === 'add' ? 'Add' : 'Update'}
    </Button>
    </Form>
      )}
    </Formik>
  )
}

export default AddTrainerForm