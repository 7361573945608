import { appConfig } from '../config'

const getPaySlabSlots = () => {
  let paySlabSlots = ''
  for (let i = 0; i < appConfig.timeSlots.length; i += 1) {
    paySlabSlots += `slot${i}{
    amount
    currency
  }`
  }
  return paySlabSlots
}

export default getPaySlabSlots
