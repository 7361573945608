import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../../duck'
import getSlotNames from '../../../utils/slots/slot-names'
import fetchMentorMenteeSessionsForDemand from './fetchMentorMenteeSessionsForDemand'

const fetchMenteeSessionsForDemand = async (filters, fromMentorDashboard = false) =>
  duck.query({
    query: gql`
      {
        menteeSessionsForDemand: menteeSessions(
          filter: {
          and: [
            { topic_some:{ order: 1 } }
            ${filters || ''}
            { studentProfile_some: { batch_exists: false } }
          ]
          }
        ) {
          id
          user{
            id
            name
            studentProfile{
              grade
              batch{
                id
              }
            }
          }
          course {
            id
            title
            thumbnail {
              uri
            }
          }
          topic {
            id
            title
            order
            thumbnailSmall{
              uri
            }
          }
        bookingDate
        createdAt
        ${getSlotNames()}
        mentorAvailabilitySlot {
          id
          ${fromMentorDashboard ? `paySlab {
          id
        }` : ''}
        }
        ${fromMentorDashboard ? '' : `broadCastedMentors {
          id
        }`}
      }
    }
    `,
    type: 'menteeSessionsForDemand/fetch',
    key: fromMentorDashboard ? 'mentorDashboard' : 'menteeSessionsForDemand',
    changeExtractedData: (extractedData, originalData) => {
      extractedData.user = []
      extractedData.course = []
      extractedData.topic = []
      const menteeSessionsForDemand = []
      get(originalData, 'menteeSessionsForDemand', []).forEach(menteeSession => {
        if (!get(menteeSession, 'user.studentProfile.batch.id')) {
          menteeSessionsForDemand.push(menteeSession)
        }
      })
      let menteeSessionIds = ''
      extractedData.menteeSessionsForDemand = menteeSessionsForDemand
      extractedData.menteeSessionsForDemand.forEach(menteeSession => {
        menteeSessionIds += `"${get(menteeSession, 'id')}"`
      })
      if (menteeSessionIds && !fromMentorDashboard) {
        fetchMentorMenteeSessionsForDemand(menteeSessionIds)
      }
      return { ...extractedData }
    },
  })

export default fetchMenteeSessionsForDemand

