import React from 'react'
import { SpeakerCardsContainer, SpeakerCardTab } from '../EventSettings.style'
import { get } from 'lodash'
import getFullPath from '../../../../utils/getFullPath'
import defaultImg from '../../../../assets/defaultImg.png'
import { Spin } from 'antd'
import Text from 'antd/lib/typography/Text'

class Speakers extends React.Component {
  renderSpeakerCard = (card, idx) => {
    const { user: { name, email, phone, profilePic }, 
      roleAtOrganization, organization, about, linkedInLink } = card
      return (
      <SpeakerCardTab.SpeakerCard>
          <SpeakerCardTab.SpeakerCardNumber>
            {this.props.page + (idx+1)}
          </SpeakerCardTab.SpeakerCardNumber>
          <SpeakerCardTab.SpeakerImageContainer>
            <SpeakerCardTab.SpeakerCardImg src={getFullPath(get(profilePic, 'uri')) || defaultImg} />
          </SpeakerCardTab.SpeakerImageContainer>
          <SpeakerCardTab.SpeakerCardDetails>
            <SpeakerCardTab.SpeakerCardInfoCard>
              <SpeakerCardTab.SpeakerCardInfoTitle>Speaker Name</SpeakerCardTab.SpeakerCardInfoTitle>
              <SpeakerCardTab.SpeakerCardInfoDetails>
              <Text
                  style={{ width: 180, color: 'rgb(0 0 0)' }}
                  ellipsis={{ tooltip: name }}
                >
                  {name}
                </Text>
              </SpeakerCardTab.SpeakerCardInfoDetails>
            </SpeakerCardTab.SpeakerCardInfoCard>
            <SpeakerCardTab.SpeakerCardInfoCard>
              <SpeakerCardTab.SpeakerCardInfoTitle>Speaker Email</SpeakerCardTab.SpeakerCardInfoTitle>
              <SpeakerCardTab.SpeakerCardInfoDetails>
                <Text   
                  style={{ width: 180, color: 'rgb(0 0 0)' }}
                  ellipsis={{ tooltip: email }}
                >
                  {email}
                </Text>
              </SpeakerCardTab.SpeakerCardInfoDetails>
            </SpeakerCardTab.SpeakerCardInfoCard>
            <SpeakerCardTab.SpeakerCardInfoCard>
              <SpeakerCardTab.SpeakerCardInfoTitle>Role At Organization</SpeakerCardTab.SpeakerCardInfoTitle>
              <SpeakerCardTab.SpeakerCardInfoDetails>
                <Text
                  style={{ width: 180, color: 'rgb(0 0 0)' }}
                  ellipsis={{ tooltip: roleAtOrganization }}
                >
                  {roleAtOrganization}
                </Text>
              </SpeakerCardTab.SpeakerCardInfoDetails>
            </SpeakerCardTab.SpeakerCardInfoCard>
            <SpeakerCardTab.SpeakerCardInfoCard>
              <SpeakerCardTab.SpeakerCardInfoTitle>About the Speaker</SpeakerCardTab.SpeakerCardInfoTitle>
              <SpeakerCardTab.SpeakerCardInfoDetails>
                <Text
                  style={{ width: 180, color: 'rgb(0 0 0)' }}
                  ellipsis={{ tooltip: about }}
                >
                  {about}
                </Text>
              </SpeakerCardTab.SpeakerCardInfoDetails>
            </SpeakerCardTab.SpeakerCardInfoCard>
            <SpeakerCardTab.SpeakerCardInfoCard>
              <SpeakerCardTab.SpeakerCardInfoTitle>Speaker Phone Number</SpeakerCardTab.SpeakerCardInfoTitle>
              <SpeakerCardTab.SpeakerCardInfoDetails>{phone ? `${get(phone,'countryCode')}-${get(phone,'number')}`:''}</SpeakerCardTab.SpeakerCardInfoDetails>
            </SpeakerCardTab.SpeakerCardInfoCard>
            <SpeakerCardTab.SpeakerCardInfoCard>
              <SpeakerCardTab.SpeakerCardInfoTitle>Linkedin Profile URL</SpeakerCardTab.SpeakerCardInfoTitle>
              <SpeakerCardTab.SpeakerCardInfoDetails>
                <a href={linkedInLink} target='_blank'>
                  <Text
                    style={{ width: 180, color: '#6496FE', textDecoration: 'underline', textDecorationColor: '#6496FE' }}
                    ellipsis={{ tooltip: linkedInLink }}
                  >
                    {linkedInLink}
                  </Text>
                </a>
              </SpeakerCardTab.SpeakerCardInfoDetails>
            </SpeakerCardTab.SpeakerCardInfoCard>
            <SpeakerCardTab.SpeakerCardInfoCard>
              <SpeakerCardTab.SpeakerCardInfoTitle>Organization</SpeakerCardTab.SpeakerCardInfoTitle>
              <SpeakerCardTab.SpeakerCardInfoDetails>
                <Text
                  style={{ width: 180, color: 'rgb(0 0 0)' }}
                  ellipsis={{ tooltip: organization }}
                >
                  {organization}
                </Text>
              </SpeakerCardTab.SpeakerCardInfoDetails>
            </SpeakerCardTab.SpeakerCardInfoCard>
            <SpeakerCardTab.SpeakerCardEditButton style={{ whiteSpace: 'nowrap' }}onClick={() => this.props.editSpeaker(card)}>
              <span style={{  paddingLeft: '5px' }}>Edit</span>
            </SpeakerCardTab.SpeakerCardEditButton>
          </SpeakerCardTab.SpeakerCardDetails>
        </SpeakerCardTab.SpeakerCard>
      )
    }

  render() {
    const { data, isLoading = false } = this.props
    return (
    <SpeakerCardsContainer>
        <Spin spinning={isLoading || false} >
          <div style={{ overflowX: 'scroll' }}>
          {data.map((card, idx) => this.renderSpeakerCard(card, idx))}
        </div>
      </Spin>
    </SpeakerCardsContainer>
    )
  }
}

export default Speakers