import gql from 'graphql-tag'
import duck from '../../duck'

const removeTeamProfilePic = async (teamId, fileId) => duck.query({
  query: gql`
  mutation {
    removeFromSenseiMentorTeamTeamProfilePic(senseiMentorTeamId:"${teamId}",fileId:"${fileId}"){
        senseiMentorTeam{
            id
        }
    }
  }
  `,
  type: 'senseiTeams/delete',
  key: 'removePicFromTeam',
})

export default removeTeamProfilePic
