import { notification, Tooltip } from 'antd'
import React from 'react'
import Container from './RevisionSession.style'
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { get } from 'lodash'
import { useParams } from 'react-router-dom'

const RevisionSession = ({ revision, handleRevision, revisionSessionArray, handleRevisionSessionArray, sessionArray }) => {
    const params = useParams()
    const constructRevisionArray = (value) => {
        const tempArray = []
        for (let i=0; i<value; i++) {
            const obj = {
                title: `Revision-${i+1}`,
                description: '',
                isRevision: true,
                id: `id_${i}`
            }
            tempArray.push(obj)
        }
        handleRevisionSessionArray(tempArray)
    } 

    const handleRevisionSession = (e) => {
        const value = e.target.value
        handleRevision(value)
        if (value > sessionArray.length) {
            notification.warn({
                message: 'revision can not be greater than topic selected in previous section'
            })
        } else {
            constructRevisionArray(value)
        }
    }

    const handleValueChange = (i, type, value) => {
        const tempArray = [...revisionSessionArray]
        tempArray[i][type] = value
        handleRevisionSessionArray(tempArray)
    }

    const handleClickAdd = () => {
        let value
        if (revision == null || revision == NaN) {
            value = 1
        } else {
            value = parseInt(revision) + 1
        }
        if (value > sessionArray.length) {
            notification.warn({
                message: 'revision can not be greater than topic selected in previous section'
            })
        } else {
            const obj = {
                title: `Revision-${value}`,
                description: '',
                isRevision: true,
                id: `id_${value-1}`
            }
            const tempArray = [...revisionSessionArray]
            tempArray.push(obj)
            handleRevision(value)
            handleRevisionSessionArray(tempArray)
        }
    }

    const handleDeleteRevision = (i) => {
        const tempArray = [...revisionSessionArray]
        tempArray.splice(i, 1)
        tempArray.forEach((item, index) => item.id = `id_${index}`)
        handleRevision(parseInt(revision)-1)
        handleRevisionSessionArray(tempArray)
    }

    return (
        <Container>
            <Container.RevisionSessionContainer>
                <h3>Add Number of revision Sessions</h3>
                <input
                    value={revision}
                    onChange={handleRevisionSession}
                    disabled={get(params, 'packageId')}
                />
            </Container.RevisionSessionContainer>
            {revision && revision != 0 && revision <= sessionArray.length ? (
                <Container.TableContainer>
                    {revisionSessionArray && revisionSessionArray.length == revision && revisionSessionArray.map((item, i) => (
                        <div
                            style={{ display: 'flex' }}
                        >
                            <Container.TableRow>
                                <span>{i+1}</span>
                                <input
                                    placeholder='title'
                                    value={get(item, 'title')}
                                    onChange={(e) => handleValueChange(i, 'title', e.target.value)}
                                />
                                <input
                                    placeholder='description'
                                    value={get(item, 'description')}
                                    onChange={(e) => handleValueChange(i, 'description', e.target.value)}
                                />
                            </Container.TableRow>
                            <Tooltip title='Delete revision' placement='bottom'>
                                <DeleteOutlined style={{ marginLeft: '15px' }} onClick={() => handleDeleteRevision(i)}/>
                            </Tooltip>
                        </div>
                    ))}
                </Container.TableContainer>
            ): ''}
            <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                <Tooltip title='Add revision' placement='bottom'>
                    <PlusCircleOutlined onClick={handleClickAdd} />
                </Tooltip>
            </div>
        </Container>
    )
}

export default RevisionSession