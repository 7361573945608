import React, { useState } from 'react'
import { FlexContainer, PrimaryButton, VerticalLine } from '../Classrooms.styles'
import AddGradeSections from './AddGradeSections'
import Section from './Section'

function Class({ classGrade, handleAddSection, openEditClassroom }) {
    const { grade, sections } = classGrade
    return (
        <FlexContainer
            align='center'
            width='100%'
            direction='column'
        >
            <FlexContainer
                align='center'
                width='100%'
                justify='space-between'
            >
                <h3>{grade}</h3>
                <VerticalLine />
                <PrimaryButton
                    onClick={() => handleAddSection({ grade })}
                    style={{ background: '#FFF', color: '#8C61CB', border: '1px solid #8C61CB' }}
                >
                    + Add New Section
                </PrimaryButton>
            </FlexContainer>
            <FlexContainer
                gap='20px'
                justify='flex-start'
                width='100%'
                style={{
                    marginTop: '18px',
                    marginBottom: '30px',
                    overflow: 'scroll',
                    flexWrap: 'nowrap'
                }}
            >
                {sections && sections.length && sections.map(section => (
                    <Section
                        grade={grade}
                        section={section}
                        sections={sections}
                        openEditClassroom={openEditClassroom}
                    />
                ))}
            </FlexContainer>
        </FlexContainer>
    )
}

export default Class