import styled from 'styled-components'

const CalendarContainer = styled.div`
    width: 100%;
    max-width: 1000px;
    padding-top: 1.4vw;
`

const StyledWrapper = styled.div`
  .fc-media-screen .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button-group {
      background: none;
      button:first-child {
        background-color: #4DAAE1 !important;
        border: 0.25px solid #D3D3D3 !important;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        /* font-family: Roboto !important; */
        font-style: normal;
        font-weight: 500 !important;
        /* font-size: 5px; */
        /* line-height: 6px; */
        color: #FFFFFF !important;
        box-shadow: 0px 8px 8px rgb(0 0 0 / 25%);
      }
  
  button:last-child {
    background-color: #A8A7A7 !important;
    border: 0.25px solid #D3D3D3 !important;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /* font-family: Roboto !important; */
    font-style: normal;
    font-weight: 500 !important;
    /* font-size: 5px; */
    /* line-height: 6px; */
    color: #000000 !important;
    box-shadow: 0px 8px 8px rgb(0 0 0 / 25%);
    }
  }
`
const ModalContainer = styled.div`
      width: 100%;
      height: 100%;
      background-color: #fff;
      border: 1px solid #000;
      position: absolute;
      top: 0%;
      z-index: 9;
      background-color: rgba(0, 0, 0, 0.22);
`

export {CalendarContainer, StyledWrapper, ModalContainer}
