import gql from 'graphql-tag'
import duck from '../../duck'

const FETCH_BATCH_DASHBOARD_DETAILS = (salesId, sales) => gql`
query {
  courses(filter: {status: published}) {
    id
    title
  }
  coursePackages(filter: {status: published}) {
    id
    title
    internalName
  }
  ${salesId ? `user(id:"${salesId}"){
    id
    name
    ${sales ? 'salesExecutiveProfile' : 'senseiProfile'} {
      id
      mentors(orderBy:createdAt_DESC){
        id
        user{
          id
          name
          email
          phone{
            countryCode
            number
        }
        }
      }
    }
    }` :
    'users(filter: { and: [{ role: mentor } { mentorProfile_exists: true }] }) {id role name email secondaryRole}'}
}
`
const fetchBatchDashboardDetails = async (salesId, sales) => duck.query({
  query: FETCH_BATCH_DASHBOARD_DETAILS(salesId, sales),
  type: 'courses/fetch',
  key: 'course',
})

export default fetchBatchDashboardDetails
