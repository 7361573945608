import React from 'react'
import { get } from 'lodash'
import { UserOutlined } from '@ant-design/icons'
import batchIcon from '../../../assets/studentTab.png'
import getFullPath from '../../../../src/utils/getFullPath'
import BatchStyle from './BatchContainer.styles'
import './antdTableStyle.scss'

const MenteeContainer = ({ session }) => (
    <div>
        <BatchStyle.TopContainer>
        <BatchStyle.BatchLogoDiv>
          <img src={batchIcon} alt='' />
        </BatchStyle.BatchLogoDiv>
        <BatchStyle.Heading>Individual</BatchStyle.Heading>
        <BatchStyle.BatchCodeDiv>
          <BatchStyle.BatchCode>1 : 1</BatchStyle.BatchCode>
        </BatchStyle.BatchCodeDiv>
        <UserOutlined style={{ paddingLeft: '60px' }} />
        <BatchStyle.StudentHeading>Student</BatchStyle.StudentHeading>
        <div style={{ fontSize: '17px', paddingLeft: '15px', color: 'black', fontWeight: '700' }}>1</div>
        </BatchStyle.TopContainer>
        <div style={{ background: 'white', borderRadius: '8px', paddingBottom: '5px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div style={{ dsiplay: 'flex', flexDirection: 'column', paddingLeft: '30px', width: '30%' }}>
          <BatchStyle.FontDiv>Course</BatchStyle.FontDiv>
          <div style={{ fontSize: '14px' }}>{get(session, 'courseData.title', '')}</div>
      </div>
      <div style={{ dsiplay: 'flex', flexDirection: 'column', paddingLeft: '30px', width: '30%' }}>
          <BatchStyle.FontDiv>Student</BatchStyle.FontDiv>
          <div style={{ fontSize: '14px' }}>{get(session, 'menteeInfo.user.name', '')}</div>
      </div>
      <BatchStyle.courseImage src={getFullPath(get(session, 'courseData.thumbnail.uri', ''))} alt='' />
        </div>
    </div>

)

export default MenteeContainer
