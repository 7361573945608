import React from 'react';
import styled from 'styled-components'

const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: ${props=>props.isHeading ? `50%` : `95%`};
    justify-content: ${props => props.justify ? props.justify : 'flex-start'}
`
const MainContainer = styled.div`
    .drag-drop-container {
        height: 335px;
        overflow-y: auto;
        ::-webkit-scrollbar {
            width: 4px;
        }
        ::-webkit-scrollbar-thumb {
            background-color: #A8A7A7;
            outline: 1px solid #A8A7A7;
            border-radius: 5px;
        }
    }
`

const DraggableContainerIndex = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #212121;
`
const DraggableContainerTitle = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #212121;
`
const DraggableContainerDescription = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.02em;
    font-size: 12px;
    line-height: 16px;
    color: #212121;
`
const CollapsableTitle = styled.p`
    margin: 0;
    margin-right: 50px;
    letter-spacing: 0.01em;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
`
const CollapsableButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    padding: 4px 8px;
    height: 24px;
    color: #757575;
    background: #F2F2F2;
    border-radius: 4px;
`
const CollapsableText = styled.p`
    letter-spacing: 0.02em;
    font-family: 'Inter';
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
`
const DraggableHeadContainer = styled.div`
    display: flex;
    align-items: center;
    background: #F5F5F5;
    padding: 12px 23px;
    border-radius: 6px;
`
const DraggableContainerHead = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.02em;
    line-height: 16px;
    color: #212121;
    margin: 0;
`
export {
    FlexContainer,
    MainContainer,
    DraggableContainerIndex,
    DraggableContainerTitle,
    CollapsableTitle,
    CollapsableButton,
    CollapsableText,
    DraggableContainerHead,
    DraggableHeadContainer,
    DraggableContainerDescription,
}
