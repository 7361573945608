/* eslint-disable */
import React, { useEffect } from 'react'
import "./studentProfileLeftbar.scss"
import { notificationsSvg, dashboardSvg, ratingsSvg, studentSvg } from './assets'
import UpOutlined from '@ant-design/icons/UpOutlined'
import DownOutlined from '@ant-design/icons/UpOutlined'
import { useHistory } from 'react-router'
import fetchUserProfile from '../../actions/userProfile/fetchUserProfile'
import { get } from 'lodash'
import getFullPath from '../../utils/getFullPath'
import { getMentorRating } from '../DemandSupplyDashboard/demandSupply-utils'

export default function StudentProfileLeftbar() {
    const notificationsLogo = notificationsSvg()
    const [userDetails, setUserDetails] = React.useState({})
    const history = useHistory()
    const dashboardLogo = dashboardSvg()
    const ratingsLogo = ratingsSvg()
    const studentLogo = studentSvg()
    const redirectHandler = () => {
        history.push('/')
    }

    useEffect(() => {
        const userData = async () => {
            const res = await fetchUserProfile()
            setUserDetails(res.user)
        }
        userData()
    }, [])
  const calculateMentorRating = () => {
        let ratingNum = 0
        let ratingDen = 0
        const mentorInfo = get(userDetails, 'mentorProfile') ?
          get(userDetails, 'mentorProfile') : {}
        Object.keys(mentorInfo).forEach((key) => {
          if (key.includes('pythonCourseRating') && mentorInfo[key] > 0) {
            const ratingValue = key.split('pythonCourseRating')[1]
            ratingNum += ratingValue * mentorInfo[key]
            ratingDen += mentorInfo[key]
          }
        })
        if (ratingNum > 0 && ratingDen > 0) {
          return (ratingNum / ratingDen).toFixed(2)
        }
        return 'NA'
      }
    
    return (
        <div className="leftbarWrapper">
            <div className="leftbarTopContainer">
                <img src="https://www.linkpicture.com/q/Tekie-text-1_1.png" alt="" onClick={redirectHandler} className="leftbarLogoImage" />
                <div className="logoContainer">
                    {notificationsLogo}
                    {/* <div className="notificationBadge">
                        <p className="numberOfNotificationText">12</p>
                    </div> */}
                </div>
            </div>
            <div className="leftbarContentContainer">
                <div className="DetailsContent">
                    <img src={getFullPath(get(userDetails, 'profilePic.uri'))} alt="" className="userImage" />
                    <div className="nameAnRatingWrapper">
                        <h5 className="currentUserName">{get(userDetails, 'name')}</h5>
                        <span className="RatingWrapper">{ratingsLogo}<p className="rating">{calculateMentorRating()}</p></span>
                    </div>
                </div>
                <div className="profileChangerBtn">
                    <p><UpOutlined className="previousButton" /></p>
                    <p ><DownOutlined className="nextButton" /></p>
                </div>
            </div>
            <ul className="leftbarOptions">
                <li className="leftbarlistItem">
                    {dashboardLogo}
                    <h5 className="optionName">Dashboard</h5>
                </li>
                <li className="leftbarlistItem">
                    {studentLogo}
                    <h5 className="optionName">Students</h5>
                </li>
            </ul>
        </div>
    )
}
