import gql from 'graphql-tag'
import duck from '../../../../duck'

const updateEventSpeakerProfile = async (id, input) =>
  duck.query({
    query: gql`
      mutation($input: EventSpeakerProfileUpdate) {
        updateEventSpeakerProfile(
          id: "${id}"
          input: $input
        ) {
            id
            user {
              name
              email
              phone {
                number
              }
              profilePic {
                id
                uri
              }
            }
            roleAtOrganization
            organization
            about
            linkedInLink
        }
      }
    `,
    variables: {
      input
    },
    type: 'eventSpeakerProfiles/update',
    key: 'eventSpeakerProfiles',
  })

export default updateEventSpeakerProfile