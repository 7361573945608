import styled from 'styled-components'

const DropDownContainer = styled.div`
    display: flex;
    justify-content: space-between;
    h3 {
        font-weight: 500;
        color: #000000;
    }
`
export { DropDownContainer }
