import gql from 'graphql-tag'
import duck from '../../duck'

const mentorMentorMenteeQuery = (filter, skip, gte, lte) => `
{
    mentorMenteeSessions(filter: ${filter}
        {sessionStatus_in: [completed, started]},
        {sessionStartDate_gte: "${gte}"},
        {sessionStartDate_lte: "${lte}"},
        {menteeSession_exists: true},
        {source_not: school},
      ]
    }, orderBy: createdAt_DESC, first: 20, skip: ${skip}) {
      sessionStartDate
      leadStatus
      sessionStatus
      topic {
        title
        order
      }
      country
      menteeSession {
        user {
          name
          id
        }
      }
      salesOperation{
        leadStatus
        userPaymentPlan{
          product{
            type
          }
        }
      }
      mentorSession {
        user {
          name
          id
        }
      }
    }
  }
`
const batchSessionQuery = (filter, skip, gte, lte) => `
{
  batchSessions(filter: ${filter}
      {sessionStartDate_gte: "${gte}"},
      {sessionStartDate_lte: "${lte}"},
      {sessionStatus_in:[completed, started]},
      {batch_some: {type_not: normal}}
    ]},
    orderBy: createdAt_DESC, first: 20, skip: ${skip}) {
    id
    sessionStatus
    bookingDate
    topic {
      title
      order
    }
    batch {
      type
      allottedMentor {
        id
        name
      }
    }
  }
}
`
const fetchMentorMenteeEarnings = async (
  filter,
  skip,
  gte,
  lte
) => duck.query({
  query: gql`
     query
       ${mentorMentorMenteeQuery(filter, skip, gte, lte)}
    `,
  type: 'mentorMenteeSession/fetch',
  key: 'mentorMenteeSession',
})

const fetchBatchSessionEarnings = async (
  filter,
  skip,
  gte,
  lte
) => duck.query({
  query: gql`
     query
       ${batchSessionQuery(filter, skip, gte, lte)}
    `,
  type: 'batchSessions/fetch',
  key: 'batchSessions',
})


export { fetchMentorMenteeEarnings, fetchBatchSessionEarnings }
