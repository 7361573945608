/*eslint-disable*/
import React from 'react'
import { Preview } from 'tekie-content-editor'
import config from '../../config/serverConfig'
import './preview.scss'

const PreviewEditorComponent = ({ id, init = {}, ...props }) => {
  return (
    <div className="previewComponent">
      <Preview
        {...props}
        id={id}
        env={process.env.REACT_APP_NODE_ENV || 'staging'}
        urlPrefix={config.cloudFrontBaseUrl || config.fileBaseUrl} 
        init={{
          selector: 'textarea#tekie-tms',
          font_formats: 'Nunito; Arial=arial,helvetica,sans-serif; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Helvetica=helvetica; arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times',
          plugins: `autolink directionality code image link media template codesample table hr toc advlist lists wordcount imagetools textpattern noneditable quickbars autoresize`,
          autoresize_bottom_margin: 0,
          // imagetools_cors_hosts: ['picsum.photos'],
          menubar: false,
          // menubar: 'edit insert format tools table help',
          toolbar: false,
          image_caption: true,
          fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt',
          content_style: 'body { font-family:Nunito,Arial,sans-serif; font-size:14pt}',
          setup: (editor) => {
            editor.on('init', () => {
              Array.from(editor.getDoc().querySelectorAll('.mce-object-iframe')).map((el) => {
                el.addEventListener('click', () => {
                  el.setAttribute('data-mce-selected', '2')
                });
              });
              Array.from(editor.getDoc().querySelectorAll('.mce-object-video')).map((el) => {
                el.addEventListener('click', () => {
                  el.setAttribute('data-mce-selected', '2')
                });
              });
            });
            
          },
          ...init,
        }}
      />
    </div>
  )
}

export default PreviewEditorComponent
