import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const fetchMentorProfileData = async (schoolId,first,skip) => duck.query({
        query: gql`{
        mentorProfiles(filter:{schools_some:{id:"${schoolId}"}}, first:${first}, skip:${skip}){
          id
          user{
            id
            name
            email
            phone{
              countryCode
              number
            }
            displayName
          }
          scheduleManagement{
            isSchedulingLearningClass
            isSchedulingAdhocClass
            isReschedulingClasses
            isSchedulingTestClass
            isDeleteSessions
          }
          classroomControl {
            isCreatingClass
            isSendingNotice
            shouldAddOrInviteStudent
            isAccessRecording
            isAccessLiveSessionProgress
          }
          courseControl {
            shouldAccessCourse
            shouldCreateTest
            shouldEvaluateTest
            shouldAddToQuestionBank
          }
          sessionReporting {
            shouldAccessReports
            shouldDownloadReports
            shouldShareReports
          }
          accessType
          schoolClasses{
            id
            grade
            section
          }
        }
      }
    `,
        type: 'mentorProfiles/fetch',
        key: 'mentorProfiles',
        changeExtractedData: (extractedData, originalData) => {
          extractedData.mentorProfiles = get(originalData, 'mentorProfiles');
          extractedData.schoolClasses=[];
          return { ...extractedData }
        }
    })

export default fetchMentorProfileData

