import { get } from 'lodash'

const getOverallRating = (element) => {
  const star5 = get(element, 'pythonCourseRating5', 0) || 0
  const star4 = get(element, 'pythonCourseRating4', 0) || 0
  const star3 = get(element, 'pythonCourseRating3', 0) || 0
  const star2 = get(element, 'pythonCourseRating2', 0) || 0
  const star1 = get(element, 'pythonCourseRating1', 0) || 0

  if (star1 === 0 && star2 === 0 && star3 === 0 && star4 === 0 && star5 === 0) {
    return 0
  }

  const rating = (star5 * 5 + star4 * 4 + star3 * 3 + star2 * 2 + star1 * 1) /
      (star1 + star2 + star3 + star4 + star5)

  return rating.toPrecision(2)
}

export default getOverallRating
