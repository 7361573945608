import React, { useState,useEffect } from 'react'
import { get } from 'lodash'
import {Checkbox} from 'antd'
import {HeadingDiv,TypeHolder,TerminalText,ShowChat,
CheckBoxHolder,LayoutTwoDiv,HeadingHolder,StyledTextArea} from './LayoutTwo.style'
const LayoutTwo =(props)=> {
  const[showInput,setShowInput]=useState(true)
  const[showOutput,setShowOutput]=useState(false)
  const[inputVal,setInputVal]=useState('')
  const[outputVal,setOutputVal]=useState('')
  
  useEffect(()=>{
    if(get(props.selectedContent,'codeOutput','')){
     setShowOutput(true)
    } else if(get(props.selectedContent,'codeOutput','') ===null &&showOutput===true) {
         setShowOutput(false)
    }
   },[props.number,get(props.selectedContent,'codeOutput','')])

  useEffect(()=>{
    setInputVal(get(props.selectedContent,'codeInput',''))
    setOutputVal(get(props.selectedContent,'codeOutput',''))
  },[props.number,get(props.selectedContent,'codeOutput',''),get(props.selectedContent,'codeInput','')])

  const setCodeInput =(value)=>{
   props.Helper(value,'codeInput','',props.slideContents,props.number)
   setInputVal(value)
}
const setCodeOutput =(value)=>{
  props.Helper(value,'codeOutput','',props.slideContents,props.number)
   setOutputVal(value)
}
  const handleShowInput=(e)=>{
    props.Helper(e.target.checked,'showCodeInput','',props.slideContents,props.number)
    setShowInput(e.target.checked)
}
const handleShowOutput=(e)=>{
    props.Helper(e.target.checked,'showCodeOutput','',props.slideContents,props.number)
    setShowOutput(e.target.checked)
}
    return <LayoutTwoDiv>
         <HeadingDiv>
             <HeadingHolder>
             <TerminalText>Terminal Input</TerminalText>
             <TypeHolder>
                <ShowChat>Show in Slide?</ShowChat>
                <CheckBoxHolder>
                 <Checkbox checked={showInput} onChange={(e)=>{handleShowInput(e)}}/>
                </CheckBoxHolder>
              </TypeHolder>
             </HeadingHolder>
           {showInput===true ?<StyledTextArea onChange={(e)=>{setCodeInput(e.target.value)}} value={inputVal} defaultValue={inputVal}/>:""}
        </HeadingDiv>
        <HeadingDiv>
          <HeadingHolder>
             <TerminalText>Terminal Output</TerminalText>
             <TypeHolder>
                <ShowChat>Show in Slide?</ShowChat>
                <CheckBoxHolder>
                 <Checkbox checked={showOutput} onChange={(e)=>{handleShowOutput(e)}}/>
                </CheckBoxHolder>
           </TypeHolder>
             </HeadingHolder>
           {showOutput===true ?<StyledTextArea onChange={(e)=>{setCodeOutput(e.target.value)}} value={outputVal} defaultValue={outputVal}/>:""}
        </HeadingDiv>
    </LayoutTwoDiv>
}
export default LayoutTwo;