import gql from 'graphql-tag'
import duck from '../../duck'
import getIdArrForQuery from '../../utils/getIdArrForQuery'

const createB2B2CBatch = async (batchCode, schoolId, campaignId,
  courseId, bookingDate, selectedSlot, allottedMentorConnectId,
  mentorSessionConnectId, classesConnectIds, studentsConnectIds) => (
  duck.query({
    query: gql`
      mutation{
      addBatch(input: {
        code: "${batchCode}",
        type: b2b2c,
        b2b2ctimeTable:{
          bookingDate: "${bookingDate}",
          ${selectedSlot}: true,
          ${mentorSessionConnectId ? `mentorSessionConnectId: "${mentorSessionConnectId}"` : ''}
        }
      },
        ${schoolId ? `schoolConnectId:"${schoolId}"` : ''},
        ${campaignId ? `campaignConnectId:"${campaignId}"` : ''},
        ${allottedMentorConnectId ? `allottedMentorConnectId:"${allottedMentorConnectId}"` : ''},
        ${(studentsConnectIds && studentsConnectIds.length) ? `studentsConnectIds: [${getIdArrForQuery(studentsConnectIds)}]` : ''}
        ${(classesConnectIds && classesConnectIds.length) ? `classesConnectIds: [${getIdArrForQuery(classesConnectIds)}]` : ''}
        courseConnectId: "${courseId}") {
        id
        course {
          createdAt
          updatedAt
        }
        code
        type
        description
        studentsMeta {
          count
        }
        allottedMentor {
          name
        }
        currentComponent {
          currentTopic {
            title
            order
          }
        }
      }
    }
  `,
    type: 'batch/add',
    key: 'addBatch',
  })
)

export default createB2B2CBatch
