const B2B = 'b2b'

const B2C = 'b2c'

const B2B2C = 'b2b2c'

const VERTICALS = [B2B, B2C, B2B2C]

export {
  B2B2C, B2B, B2C, VERTICALS
}
