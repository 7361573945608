import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MainTable from '../../../components/MainTable'
import SVGIcon from '../../../assets/icons'
import DeleteModal from './UploadModal/DeleteModal'
import EditModal from './UploadModal/EditModal'

const CopyIcon = ({ id, file, deleteStatus, uri, mimeType, usageCount = 0 }) => {
  const [visible, setVisible] = useState(false)
  const [editModal, setEditModal] = useState(false)

  const visibleEditModal = () => {
    setEditModal(true)
  }

  const hideEditModal = () => {
    setEditModal(false)
  }

  return (
    <MainTable.ActionItem>
      <div>
        {visible && <DeleteModal
          id={id}
          closeModal={setVisible}
          file={file}
          deleteStatus={deleteStatus}
        />}
      </div>
      <div>
        <MainTable.ActionItem.IconWrapper onClick={visibleEditModal}>
          <SVGIcon.EditIcon />
        </MainTable.ActionItem.IconWrapper>
      </div>
      <EditModal
        id={id}
        uri={uri}
        mimeType={mimeType}
        file={file}
        closeModal={hideEditModal}
        editModal={editModal}
      />
      <div>
        <MainTable.ActionItem.IconWrapper
          onClick={() => {
            if (usageCount < 1) {
              setVisible(true)
            }
          }}
          style={{
            marginLeft: '25px',
            cursor: usageCount < 1 ? 'pointer' : 'no-drop',
          }}
        >
          <SVGIcon.DeleteIcon color={usageCount < 1 ? '#FF5744' : '#aaa'} />
        </MainTable.ActionItem.IconWrapper>
      </div>
    </MainTable.ActionItem>
  )
}
CopyIcon.propTypes = {
  id: PropTypes.string.isRequired,
}
export default CopyIcon
