const appConfig = {
  appToken: process.env.REACT_APP_TEKIE_APP_TOKEN,
  timeSlots: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
  bookingDaysCutOff: 20,
  keysToRemoveFromLocalStorageOnLogout: [
    'fetchedCompletedSessionLength',
    'currCompletedSessionQueryFetchedCount',
    'type',
    'block',
    'country',
    'countriesData'
  ]
}

export default appConfig
