import gql from 'graphql-tag'
import { MENTOR, SCHOOL_TRAINER } from '../../constants/roles'
import duck from '../../duck'

const fetchTrainers = async ({ first = 10, skip = 0, filterString = '', fromSchoolOnboarding = false }) =>
  duck.query({
    query: gql`
  {
    users(
        filter: { and: [{ role: ${MENTOR} }, { roles_in: [${SCHOOL_TRAINER}] } ${filterString}] }
        ${!fromSchoolOnboarding ? `
        first: ${first},
        skip: ${first * skip}
        ` : ''}
        orderBy: createdAt_DESC
    ) {
        id
        name
        email
        username
        roles
        createdAt
        phone {
        countryCode
        number
        }
    }
    ${!fromSchoolOnboarding ? `
    trainersMeta: usersMeta(filter: { and: [{ role: ${MENTOR} }, { roles_in: [${SCHOOL_TRAINER}] } ${filterString}] }) {
      count
    }
    ` : ''}
    }
`,
    type: 'users/fetch',
    key: 'schoolTrainer',
  })

export default fetchTrainers
