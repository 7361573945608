import styled from 'styled-components'
import { Switch } from 'antd'

const MainContainer = styled.div`
    border: 1px solid #000;
    padding: 28px 16px 5px 24px;
    .table-data-container {
        width: 100%;
        min-height: 56px;
        box-shadow: inset 0px -1px 0px #EEEEEE;
        display: flex;
        padding: 0 5px;
        align-items: center;
    }
    .name-number-style {
        width: 15%;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #212121;
        margin: 0;
        letter-spacing: 0.02em;
    }
    .chapter-title-style {
        width: 15%;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #212121;
        margin: 0;
        letter-spacing: 0.02em;
    }
    .collapsable-button {
        width: 15%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: none;
        outline: none;
        padding: 0;
        background: none;
        cursor: pointer;
        >span {
            padding: 4px 8px;
            min-height: 24px;
            color: #757575;
            background: #F2F2F2;
            border-radius: 4px;
            font-size: 12px;
            line-height: 16px;
        }
    }
`

const HeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`

const MainContainerHeading = styled.h3`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
    letter-spacing: 0.02em;
    margin: 0;
`

const ReshuffleContainer = styled.div`
    display: flex;
    align-items: center;
    > h3 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #212121;
        letter-spacing: 0.02em;
        margin: 0;
        margin-right: 12px;
    }
`

const ReshuffleSwitch = styled(Switch)`

`

const TableHeadingContainer = styled.div`
    display: flex;
    padding: 0 5px;
    align-items: center;
    background: #F5F5F5;
    border-radius: 6px;
    min-height: 40px;
    > p {
        width: 15%;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #212121;
        margin: 0;
        letter-spacing: 0.02em;
    }
`

export {
    MainContainer,
    HeadingContainer,
    MainContainerHeading,
    ReshuffleContainer,
    ReshuffleSwitch,
    TableHeadingContainer,
}