import React, { useEffect, useState } from "react";
import { Collapse, Checkbox } from "antd";
import { MainContainerHeading } from "../SelectCourses/SelectCourses.style";
import {
  FlexContainer,
  MainContainer,
  DraggableContainerIndex,
  DraggableContainerTitle,
  CollapsableTitle,
  CollapsableButton,
  CollapsableText,
  DraggableContainerHead,
  DraggableHeadContainer,
  DraggableContainerDescription,
} from "./SelectClasses.style";
import { get, sortBy } from "lodash";
import "./SelectClasses.style.scss";

const { Panel } = Collapse;

const SelectClasses = ({
  choosenCourses,
  canGetTopics,
  handleTopics,
  setTopicObj,
  mapCourseWithTopic,
  columns,
  handleColumns,
  selectAll,
  setSelectAll,
  setIsTopicsChanged,
}) => {
  useEffect(() => {
    if (mapCourseWithTopic && mapCourseWithTopic.length > 0) {
      const selectAllState = {};
      for (let i = 0; i < mapCourseWithTopic.length; i++) {
        const index = mapCourseWithTopic[i].topic;
        const idx = mapCourseWithTopic[i].course;
        const sourceColumnId = `${idx}a`;
        const sourceItems = columns[idx][sourceColumnId];
        const copiedItems = [...sourceItems.items];
        if (copiedItems[index]) {
          copiedItems[index].checked = true;
        }
        const [destinationKey] = Object.keys(columns[idx]).filter(
          (column) => column !== sourceColumnId
        );
        const destItems = columns[idx][destinationKey].items;
        destItems.forEach((obj) => {
          if (mapCourseWithTopic[i].id === obj.id) {
            obj.checked = true;
            obj.visibility = true;
          }
        });
        let temp = 0;
        copiedItems.forEach((obj) => {
          if (obj.checked) {
            ++temp;
          }
        });
        selectAllState[idx] = temp === copiedItems.length ? true : false;
        let newColumn = [...columns];
        newColumn[idx] = {
          [sourceColumnId]: {
            ...sourceItems,
            items: copiedItems,
          },
          [destinationKey]: {
            ...columns[idx][destinationKey],
            items: destItems,
          },
        };
        handleColumns(newColumn);
      }
      setSelectAll(selectAllState);
    }
  }, []);
  const callback = (key) => {};
  const onChange = (e, index, item, column, sourceColumnId, idx) => {
    setIsTopicsChanged(true);
    if (e.target.checked) {
      const sourceItems = columns[idx][sourceColumnId];
      const copiedItems = [...sourceItems.items];
      copiedItems[index].checked = true;
      const [destinationKey] = Object.keys(columns[idx]).filter(
        (column) => column !== sourceColumnId
      );
      const destItems = columns[idx][destinationKey].items;
      destItems.forEach((obj) => {
        if (item.id === obj.id) {
          obj.checked = true;
          obj.visibility = true;
        }
      });
      let newColumn = [...columns];
      newColumn[idx] = {
        [sourceColumnId]: {
          ...sourceItems,
          items: copiedItems,
        },
        [destinationKey]: {
          ...columns[idx][destinationKey],
          items: destItems,
        },
      };
      handleColumns(newColumn);
    }
    if (!e.target.checked) {
      if (sourceColumnId == `${idx}a`) {
        const sourceItems = columns[idx][sourceColumnId];
        const copiedItems = [...sourceItems.items];
        copiedItems[index].checked = false;
        const [destinationKey] = Object.keys(columns[idx]).filter(
          (column) => column !== sourceColumnId
        );
        const destItems = columns[idx][destinationKey];
        const copiedDestItems = [...destItems.items];
        copiedDestItems.forEach((obj) => {
          if (item.id === obj.id) {
            obj.checked = false;
            obj.visibility = false;
          }
        });
        let newColumns = [...columns];
        newColumns[idx] = {
          [sourceColumnId]: {
            ...sourceItems,
            items: copiedItems,
          },
          [destinationKey]: {
            ...columns[idx][destinationKey],
            items: copiedDestItems,
          },
        };
        handleColumns(newColumns);
      }
      if (sourceColumnId == `${idx}b`) {
        const sourceItems = columns[idx][sourceColumnId];
        const [destinationKey] = Object.keys(columns[idx]).filter(
          (column) => column !== sourceColumnId
        );
        const copiedSourceItems = [...sourceItems.items];
        const destItems = columns[idx][destinationKey];
        const copiedDestItems = [...destItems.items];
        copiedSourceItems.forEach((obj) => {
          if (item.id === obj.id) {
            obj.checked = false;
            obj.visibility = false;
          }
        });
        copiedDestItems.forEach((obj) => {
          if (item.id === obj.id) {
            obj.checked = false;
          }
        });
        let newColumn = [...columns];
        newColumn[idx] = {
          [destinationKey]: {
            ...columns[idx][destinationKey],
            items: copiedDestItems,
          },
          [sourceColumnId]: {
            ...sourceItems,
            items: copiedSourceItems,
          },
        };
        handleColumns(newColumn);
      }
    }
  };
  const calculateActiveSessions = (column, idx) => {
    let items = column && column[`${idx}b`].items;
    let count = 0;
    for (let i = 0; items && i < items.length; i++) {
      if (
        get(items[i], "checked") == true &&
        get(items[i], "visibility") == true
      )
        count++;
    }
    return count;
  };
  useEffect(() => {
    if (canGetTopics) {
      let topicIdArrayCopy = [],
        topicArray = [];
      let topicObj = [];
      for (let i = 0; i < columns.length; i++) {
        let values = get(columns[i][`${i}b`], "items");
        for (let j = 0; values && j < values.length; j++) {
          let isMandatory = false;
          if (
            get(values[j], "checked") == true &&
            get(values[j], "visibility") == true
          ) {
            mapCourseWithTopic.find((item, index) => {
              if (item.id == values[j].id) {
                isMandatory = item.isMandatory;
              }
            });
            topicIdArrayCopy.push({
              topicConnectId: get(values[j], "id"),
              order: topicIdArrayCopy.length + 1,
            });
            topicObj.push({
              title: get(values[j], "title"),
              id: get(values[j], "id"),
              courseTitle: get(values[j], "courseTitle"),
              chapterName: get(values[j], "chapter.title"),
              courseCategory: get(values[j], "courseCategory"),
              description: get(values[j], "description"),
              isMandatory,
              order: topicObj.length + 1,
            });
          }
        }
      }
      handleTopics(topicIdArrayCopy);
      setTopicObj(topicObj);
    }
  }, [columns]);

  const handleSelectAll = (idx) => {
    setIsTopicsChanged(true);
    const tempSelectAll = selectAll;
    tempSelectAll[idx] = !selectAll[idx];
    setSelectAll(tempSelectAll);
    const sourceKey = `${idx}a`;
    const destKey = `${idx}b`;
    const sourceItems = columns[idx][sourceKey];
    const destItems = columns[idx][destKey];
    const copiedSourceItems = [...sourceItems.items];
    const copiedDestItems = [...destItems.items];
    copiedSourceItems.forEach((obj) => {
      obj.checked = selectAll[idx];
    });
    copiedDestItems.forEach((obj) => {
      obj.checked = selectAll[idx];
      obj.visibility = selectAll[idx];
    });
    let newColumn = [...columns];
    newColumn[idx] = {
      [sourceKey]: {
        ...sourceItems,
        items: copiedSourceItems,
      },
      [destKey]: {
        ...destItems,
        items: copiedDestItems,
      },
    };
    handleColumns(newColumn);
  };
  return (
    <MainContainer className="selectClassesContainer">
      <MainContainerHeading>Add Sessions to Package</MainContainerHeading>
      {columns &&
        columns.length > 0 &&
        choosenCourses.map((course, idx) => (
          <Collapse
            defaultActiveKey={["0"]}
            onChange={callback}
            expandIconPosition="right"
          >
            <Panel
              header={
                <FlexContainer justify="space-between">
                  <FlexContainer isHeading={true}>
                    <CollapsableTitle>{get(course, "title")}</CollapsableTitle>
                    <CollapsableButton>
                      {get(course, "category")}
                    </CollapsableButton>
                  </FlexContainer>
                  <CollapsableText>
                    Session Selected:{" "}
                    {columns[idx] && calculateActiveSessions(columns[idx], idx)}
                  </CollapsableText>
                </FlexContainer>
              }
              key={idx}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "100%",
                  padding: "0px 13px",
                }}
              >
                {Object.entries(columns[idx]).map(
                  ([columnId, column], mainIndex) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          width: mainIndex === 0 ? "60%" : "38%",
                        }}
                        key={columnId}
                      >
                        <div
                          style={{
                            margin: 8,
                            width: "100%",
                            border: mainIndex === 1 ? "1px solid #000" : null,
                          }}
                        >
                          <>
                            <DraggableHeadContainer>
                              {mainIndex === 0 && (
                                <Checkbox
                                  style={{ width: "10%", height: "14px" }}
                                  checked={selectAll[idx]}
                                  onChange={() => handleSelectAll(idx)}
                                />
                              )}
                              <DraggableContainerHead
                                style={{
                                  width: mainIndex === 0 ? "8%" : "20%",
                                  textAlign:
                                    mainIndex === 1 ? "center" : "left",
                                  marginRight: mainIndex === 1 ? "10%" : null,
                                }}
                              >
                                #
                              </DraggableContainerHead>
                              <DraggableContainerHead
                                style={{
                                  width: mainIndex === 0 ? "28%" : "70%",
                                }}
                              >
                                Session Title
                              </DraggableContainerHead>
                              {mainIndex === 0 && (
                                <DraggableContainerHead
                                  style={{ width: "54%" }}
                                >
                                  Description
                                </DraggableContainerHead>
                              )}
                            </DraggableHeadContainer>
                            <div className="drag-drop-container">
                              {(
                                sortBy(get(column, "items", []), "order") || []
                              ).map((item, index) => {
                                if (item.visibility) {
                                  return (
                                    <div
                                      style={{
                                        userSelect: "none",
                                        boxShadow: "inset 0px -1px 0px #EEEEEE",
                                        minHeight: "56px",
                                        display: "flex",
                                        alignItems: "center",
                                        color: "white",
                                        padding: "4px 23px",
                                      }}
                                    >
                                      {mainIndex === 0 && (
                                        <Checkbox
                                          checked={item.checked}
                                          onChange={(e) =>
                                            onChange(
                                              e,
                                              index,
                                              item,
                                              column,
                                              columnId,
                                              idx
                                            )
                                          }
                                        />
                                      )}
                                      <DraggableContainerIndex
                                        style={{
                                          width: mainIndex === 0 ? "8%" : "20%",
                                          textAlign:
                                            mainIndex === 1 ? "center" : "left",
                                          marginRight:
                                            mainIndex === 1 ? "10%" : null,
                                        }}
                                      >
                                        {index + 1}
                                      </DraggableContainerIndex>
                                      <DraggableContainerTitle
                                        style={{
                                          width:
                                            mainIndex === 0 ? "28%" : "70%",
                                        }}
                                      >
                                        {get(item, "title")}
                                      </DraggableContainerTitle>
                                      {mainIndex === 0 && (
                                        <DraggableContainerDescription
                                          style={{ width: "54%" }}
                                        >
                                          {item.description === null
                                            ? "-"
                                            : get(item, "description")}
                                        </DraggableContainerDescription>
                                      )}
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </Panel>
          </Collapse>
        ))}
    </MainContainer>
  );
};

export default SelectClasses;
