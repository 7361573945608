import gql from 'graphql-tag'
import duck from '../../duck'

const FETCH_LEAD_PARTNERS_UTM = (agentId) => gql`
  query{
    leadPartners${agentId ? `(filter: { agent_some: { id: "${agentId}" } })` : ''}{
      id
      title
      createdAt
      agents {
        id
        agent {
          id
          name
        }
      }
    }
    leadPartnersMeta${agentId ? `(filter: { agent_some: { id: "${agentId}" } })` : ''}{
      count
    }
  }
`
const fetchLeadPartnersUtm = async (agentId) => duck.query({
  query: FETCH_LEAD_PARTNERS_UTM(agentId),
  type: 'leadPartners/fetch',
  key: 'leadPartners',
  changeExtractedData: (extractedData, originalData) => {
    extractedData.leadPartners = originalData.leadPartners
    extractedData.leadPartnersMeta = originalData.leadPartnersMeta
    return { ...extractedData }
  }
})

export default fetchLeadPartnersUtm
