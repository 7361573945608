/* eslint-disable max-len */
import gql from 'graphql-tag'
import duck from '../../duck'
import getIdArrForQuery from '../../utils/getIdArrForQuery'

const getBatchFilterFronIds = (batchFilterIds, schoolFilterIds, courseFilterIds, modalFilterIds, paidFreeFilter, isTeacherTraining) => {
  let filters = ''
  if (batchFilterIds.length > 0) {
    filters += `{id_in: [${getIdArrForQuery(batchFilterIds)}]}`
  }
  if (schoolFilterIds.length > 0) {
    filters += `{school_some:{id_in:[${getIdArrForQuery(schoolFilterIds)}]}}`
  }
  if (courseFilterIds.length > 0) {
    filters += `{course_some:{id_in:[${getIdArrForQuery(courseFilterIds)}]}}`
  }
  if (modalFilterIds.length > 0) {
    filters += `{type_in:[${modalFilterIds}]}`
  }
  if (isTeacherTraining) {
    filters += `{isTeacherTraining: true}`
  }
  if (paidFreeFilter.length > 0 & !isTeacherTraining) {
    if (paidFreeFilter.includes('pro') && !paidFreeFilter.includes('free')) {
      filters += '{currentComponent_some:{currentTopic_some:{order_gte:2}}}'
    }
    if (paidFreeFilter.includes('free') && !paidFreeFilter.includes('pro')) {
      filters += '{currentComponent_some:{currentTopic_some:{order_lte:1}}}'
    }
  }
  return filters
}
const getMentorMenteeFilterFronIds = (courseFilterIds, studentNameFilter, paidFreeFilter) => {
  let filters = ''
  if (courseFilterIds.length > 0) {
    filters += `{course_some:{id_in:[${getIdArrForQuery(courseFilterIds)}]}}`
  }
  if (studentNameFilter.length > 0) {
    filters += `{menteeSession_some:{user_some:{name_contains:"${studentNameFilter}"}}},`
  }
  if (paidFreeFilter.length > 0) {
    if (paidFreeFilter.includes('pro') && !paidFreeFilter.includes('free')) {
      filters += '{topic_some: {order_gte: 2}}'
    }
    if (paidFreeFilter.includes('free') && !paidFreeFilter.includes('pro')) {
      filters += '{topic_some: {order_lte: 1}}'
    }
  }

  return filters
}
const batchQuery = (dataPerPage, skipCount, userId, batchFilters, isTeacherTraining) => `
batches(
  orderBy: createdAt_DESC,
  ${!isTeacherTraining ? `
    first: ${dataPerPage},
    skip: ${skipCount},` : 
  ''}
  filter: {and: [
    ${batchFilters},
    ${userId && userId.length > 0 ? `{allottedMentor_some: {id_in: [${userId},
  ]}}` : ''}]}) {
  id
  code
  type
  description
  courseData: course {
    id
    title
    secondaryCategory
    thumbnail{
      uri
    }
  }
  coursePackageData: coursePackage {
    id
    title
  }
  studentsMeta {
    count
  }
  studentData: students {
    userData: user {
      name
    }
  }
  schoolData: school {
    name
    logo {
      uri
    }
  }
  classes{
    grade
  }
  currentComponent {
    currentTopic {
      order
    }
  }
}
batchesMeta(filter: {and: [${batchFilters}, ${userId && userId.length > 0 ? `{allottedMentor_some: {id_in: [${userId}]}}` : ''}]}) {
  count
}`
const mentorMenteeSessions = (dataPerPage, skip, mentorMenteeFilters, userId) => `
  mentorMenteeSessions(filter: {and: [  
  ${mentorMenteeFilters},
  ${userId && userId.length > 0 ? `{mentorSession_some: {user_some: {id_in: [${userId}]}}}` : ''}, {menteeSession_exists: true}
  {source_not: school}
]}, orderBy: sessionStartDate_DESC, first: ${dataPerPage}, skip: ${skip}) {
  id
  courseData: course{
    id
    title
    secondaryCategory
    topicsMeta{
      count
    }
    thumbnail{
      uri
    }
  }
  currentTopic: topic {
    order
  }
  sessionStatus
  salesOperation {
    leadStatus
  }
  menteeInfo: menteeSession {
    id
    user {
      id
      name
    }
  }
}
mentorMenteeSessionsMeta(filter: {and: [
  ${mentorMenteeFilters},  
  ${userId && userId.length > 0 ? `{mentorSession_some: {user_some: {id_in: [${userId}]}}}` : ''}, {menteeSession_exists: true}
  {source_not: school}
]}) {
  count
}
`

const fetchMentorSessions = async (
  userId,
  batchFilterIds,
  schoolFilterIds,
  courseFilterIds,
  modalFilterIds,
  paidFreeFilter,
  studentNameFilter,
  dataPerPage = 10,
  skipCount = 0,
  shouldFetchBatches = true,
  shouldFetchMenteeMentorSession = true,
) => {
  const isTeacherTraining = window.location.pathname.includes('/teacherTraining')
  const batchFilters = getBatchFilterFronIds(batchFilterIds, schoolFilterIds, courseFilterIds, modalFilterIds, paidFreeFilter, isTeacherTraining)
  const mentorMenteeFilters = getMentorMenteeFilterFronIds(courseFilterIds, studentNameFilter, paidFreeFilter)
  return duck.query({
    query: gql`
     query {
       ${shouldFetchBatches ? batchQuery(dataPerPage, skipCount, userId, batchFilters, isTeacherTraining) : ''}
       ${shouldFetchMenteeMentorSession ? mentorMenteeSessions(dataPerPage, skipCount, mentorMenteeFilters, userId) : ''}
    }`,
    type: 'mentorSessions/fetch',
    key: 'mentorSessionsForMentorDashboardClasses',
  })
}

export default fetchMentorSessions
