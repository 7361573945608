import React, { useEffect, useState } from 'react'
import { Modal, notification, Radio, Select } from 'antd'
import sections from '../../../../../constants/sections'
import { GradeSectionsContainer, GradeSectionBoxContainer, GradeContainer, GradeButton,
    SectionsContainer, FlexContainer, StyledSelect, PrimaryButton, AntModal } from '../Classrooms.styles'
import { get } from 'lodash'
import { LoadingOutlined } from '@ant-design/icons'

const { Option } = Select

const grades = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

function AddGradeSections({ addGradeModalVisible, addGradeSections, schoolClassesAddStatus, handleClose, multiSelect=false, addSectionFor=null }) {
    const [gradesSections, setGradesSections] = useState([])

    useEffect(() => {
        setGradesSections([])
    }, [])

    useEffect(() => {
        if (addSectionFor) {
            const grade = addSectionFor.substring(5)
            const obj = {
                grade: grade,
                section: '-'
            }
            setGradesSections([obj])
        }
    }, [addSectionFor])

    const handleSubmitGradeSections = () => {
        let isError = false
        if (addSectionFor) {
            const section = get(gradesSections, '[0].section')
            if (section === '-') {
                notification.error({ message: 'please select section' })
                isError = true
            }
        } else {
            gradesSections.forEach(gradeSection => {
                const classSection = get(gradeSection, 'section')
                const start = get(classSection, 'start')
                const end = get(classSection, 'end')
                const startIndex = sections.indexOf(start)
                const endIndex = sections.indexOf(end)
                if (startIndex > endIndex) {
                    notification.error({ message: 'please select section in correct sequence' })
                    isError = true
                }
            })
        }
        if (!isError) {
            addGradeSections({ gradesSections, addSectionFor })
        }
    }

    const doesGradeMatch = (grade) => {
        const gradeSectionsTemp = [...gradesSections]
        const filtered = gradeSectionsTemp.find(item => get(item, 'grade') === grade)
        return filtered
    }

    const renderGrades = () => {
        return (
            <GradeContainer>
                {grades.map((grade) => (
                    <GradeButton
                        onClick={() => {
                            if (doesGradeMatch(grade)) {
                                const gradesSectionsTemp = [...gradesSections]
                                const updatedClassGrades = gradesSectionsTemp.filter(item => get(item, 'grade') !== grade)
                                setGradesSections(updatedClassGrades)
                            } else {
                                const obj = {
                                    grade: grade,
                                    section: {
                                        start: 'A',
                                        end: 'B'
                                    }
                                }
                                if (multiSelect) {
                                    setGradesSections([...gradesSections, obj])
                                } else {
                                    setGradesSections([obj])
                                }
                            }
                        }}
                        disabled={addSectionFor}
                        isSelected={addSectionFor ? `Grade${grade}` === addSectionFor : doesGradeMatch(grade)}
                    >
                        {grade}
                    </GradeButton>
                ))}
            </GradeContainer>
        )
    }

    const renderSections = () => {
        return (
            <FlexContainer direction='column'>
                {gradesSections.map((gradesSection, index) => (
                    <FlexContainer
                        direction='column'
                        style={{ marginBottom: '20px' }}
                    >
                        <p style={{ marginBottom: '16px' }}>Grade {get(gradesSection, 'grade')} {addSectionFor ? 'Section' : 'Sections'}</p>
                        {addSectionFor ? (
                            <StyledSelect
                                style={{ width: '170px' }}
                                value={get(gradesSections, '[0].section')}
                                onChange={value => {
                                    const newGradeSections = [...gradesSections]
                                    newGradeSections[0].section = value
                                    setGradesSections(newGradeSections)
                                }}
                            >
                                {sections.map(s => <Option value={s}>{s}</Option>)}
                            </StyledSelect>
                        ) : (
                            <FlexContainer align='center'>
                                <StyledSelect
                                    style={{ width: '170px' }}
                                    value={get(gradesSection, 'section.start')}
                                    onChange={value => {
                                        const newGradeSections = [...gradesSections]
                                        newGradeSections[index].section.start = value
                                        setGradesSections(newGradeSections)
                                    }}
                                >
                                    {sections.map(s => <Option value={s}>{s}</Option>)}
                                </StyledSelect>
                                <div style={{ margin: '0 10px' }} >to</div>
                                <StyledSelect
                                    style={{ width: '170px' }}
                                    value={get(gradesSection, 'section.end')}
                                    onChange={value => {
                                        const newGradeSections = [...gradesSections]
                                        newGradeSections[index].section.end = value
                                        setGradesSections(newGradeSections)
                                    }}
                                >
                                    {sections.map(s => <Option value={s}>{s}</Option>)}
                                </StyledSelect>
                            </FlexContainer>
                        )}
                    </FlexContainer>
                ))}
            </FlexContainer>
        )
    }

    const handleModalClose = () => {
        handleClose()
        setGradesSections([])
    }

    return (
        <AntModal
            title="Add Classrooms"
            visible={addGradeModalVisible}
            onCancel={handleModalClose}
            centered
            footer={null}
            width='430px'
        >
            <GradeSectionsContainer>
                <FlexContainer>
                    {renderGrades()}
                </FlexContainer>
                {gradesSections.length ? (
                    <GradeSectionBoxContainer>
                        {renderSections()}
                    </GradeSectionBoxContainer>
                ) : null}
            </GradeSectionsContainer>
            <FlexContainer justify='center' width='100%'>
                <PrimaryButton
                    width='100%'
                    onClick={handleSubmitGradeSections}
                    disabled={gradesSections.length === 0}
                >
                    {schoolClassesAddStatus && get(schoolClassesAddStatus.toJS(), 'loading', '') ? <LoadingOutlined /> : 'Add Grades & Sections'}
                </PrimaryButton>
            </FlexContainer>
        </AntModal>
    )
}

export default AddGradeSections