import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const FetchMentorProfilesMeta = async (schoolId,first,skip) => duck.query({
    query: gql`
        {
            mentorProfilesMeta(filter:{schools_some:{id:"${schoolId}"}}){
                count
        }
      }
    `,
    type: 'mentorProfilesMeta/fetch',
    key: 'mentorProfilesMeta',
    changeExtractedData: (extractedData, originalData) => {
        extractedData.mentorProfilesMeta = get(originalData, 'mentorProfilesMeta');
        return { ...extractedData }
    }
})

export default FetchMentorProfilesMeta

