import { get } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { FlexContainer, MappedLink, NotMapped, PrimaryButton, SectionMappingContainer, SectionsContainer } from '../Classrooms.styles'
import { CrossIcons } from '../utils/svgs'

function Section({ grade, section, openEditClassroom, sections }) {
    const findMasterBatch = () => {
        const batchData = get(section, 'batchData', [])
        const masterBatch = batchData.length && batchData.find(item => get(item, 'inheritedFrom') === null)
        return masterBatch
    }

    let batchData = findMasterBatch()
    if (!batchData) {
        const batchesData = get(section, 'batchData', [])
        batchData = batchesData.length && batchesData[0]
    }

    const renderNotMapped = () => {
        return (
            <NotMapped>
                <CrossIcons />
                <h6>Not Mapped</h6>
            </NotMapped>
        )
    }

    const classroomTitle = (batchData) => {
        const classroomTitle = get(batchData, 'classroomTitle')
        const gradeSection = `${get(batchData, 'classes[0].grade', '')}${get(batchData, 'classes[0].section', '')}`
        return classroomTitle ? classroomTitle : gradeSection
    }

    return (
        <SectionsContainer>
            <FlexContainer justify='space-between'>
                <h3>Section {get(section, 'section')}</h3>
                <h4 style={{ fontWeight: '400' }}>{classroomTitle(batchData)}</h4>
            </FlexContainer>
            <FlexContainer>
                <SectionMappingContainer>
                    <span>Teachers Assigned</span>
                    {!batchData ? (
                        renderNotMapped()
                    ) : (
                        get(batchData, 'allottedMentor.id') ? (
                            <p>
                                {get(section, 'batchData', []).map(data => (
                                    <p>{get(data, 'allottedMentor.name')},</p>
                                ))}
                            </p>
                        ) : (
                            <MappedLink to='/sms/school-dashboard/:schoolId/teacher'>+ create teacher</MappedLink>
                        )
                    )}
                </SectionMappingContainer>
                <SectionMappingContainer>
                    <span>Students</span>
                    {!batchData ? (
                        renderNotMapped()
                    ) : (
                        get(batchData, 'studentsMeta.count') ? (
                            <p>{get(batchData, 'studentsMeta.count')}</p>
                        ) : (
                            <MappedLink to='/sms/school-dashboard/:schoolId/students'>+ create students</MappedLink>
                        )
                    )}
                </SectionMappingContainer>
                <SectionMappingContainer style={{ marginTop: '25px' }}>
                    <span>Course Package</span>
                    {!batchData ? (
                        renderNotMapped()
                    ) : (
                        get(batchData, 'coursePackage.id') ? (
                            <p>{get(batchData, 'coursePackage.title')}</p>
                        ) : (
                            <MappedLink to='/coursePackages'>+ create course package</MappedLink>
                        )
                    )}
                </SectionMappingContainer>
            </FlexContainer>
            <PrimaryButton
                onClick={() => openEditClassroom({ grade, section, sections })}
            >
                Edit Classroom
            </PrimaryButton>
        </SectionsContainer>
    )
}

export default Section