import styled from 'styled-components'
import { Link } from 'react-router-dom'

const CoursesStyle = styled.div`
    height: 100%;
    width: 100%;
    margin: 0 auto;
    font-family: 'Inter' !important;
`

const PageTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-items: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
`

const PageTitle = styled(Link)`
    font-size: 12px;
    letter-spacing: 0px;
    color: #757575;
    padding: 10px 0;
`
const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const FetchError = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #616161;
`

CoursesStyle.FlexContainer = FlexContainer
CoursesStyle.PageTitleContainer = PageTitleContainer
CoursesStyle.PageTitle = PageTitle
CoursesStyle.FetchError = FetchError

export default CoursesStyle
