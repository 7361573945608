import { debounce, get } from 'lodash'
import moment from 'moment'
import { notification } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useLocation, useHistory } from 'react-router-dom'
import React, { useState } from 'react'
import { TekieAmethyst } from '../../../../constants/colors'
import { CalendarSvg } from '../../../../constants/icons'
import Modal from '../Modal.styles'
import AddDaySlotsModalStyle from './AddDaySlotsModal.style'
import getDataFromLocalStorage from '../../../../utils/extract-from-localStorage'
import { getMentorSessionData } from '../../utils'
import updateMentorSession from '../../../../actions/mentorSessions/updateMentorSession'
import addMentorSession from '../../../../actions/mentorSessions/addMentorSessions'

const TimePickerFormat = 'hh a'

const AddDaySlotsModal = ({
  isModalVisible,
  setModalVisibility,
  addSlotsToLocalState,
  slotToDelete, ...props }) => {
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const resetState = () => {
    setLoading(false)
    const query = new URLSearchParams(location.search)
    const slotId = query.get('slot')
    if (slotId) history.push('/mentorDashboard')
  }
  const closeModal = (shouldFetch = false) => {
    resetState()
    setModalVisibility(false, shouldFetch)
  }
  const getSlotsFromSelectedTime = () => {
    const selectedSlots = get(props, 'selectedSlotsToOpen')
    const slots = []
    const slotsObj = {}
    selectedSlots.forEach(slot => {
      slotsObj[`slot${new Date(get(slot, 'startTime')).getHours()}`] = true
      slots.push({
        slot: `slot${new Date(get(slot, 'startTime')).getHours()}`,
        id: get(slot, 'id')
      })
    })
    return { slots, slotsObj }
  }
  const addMentorSessionQuery = async () => {
    setLoading(true)
    const { slots, slotsObj } = getSlotsFromSelectedTime()
    const mentorId = getDataFromLocalStorage('login.id')
    const availabilityDate = new Date(new Date(get(props, 'selectedSlotsToOpen[0].startTime')).setHours(0, 0, 0, 0)).toISOString()
    const input = {
      availabilityDate,
      sessionType: 'trial',
      ...slotsObj
    }
    const slotsToUpdate = {
      availabilityDate,
      sessionType: 'trial',
      ...slotsObj
    }
    const mentorSessionData = await getMentorSessionData(availabilityDate, mentorId)
    const mentorSessionObj = get(mentorSessionData, 'data.mentorSessions[0]', null)
    const acceptanceObjects = []
    slots.forEach(slot => {
      acceptanceObjects.push({
        slotName: get(slot, 'slot'),
        date: availabilityDate,
        mentorAvailabilitySlotId: get(slot, 'id'),
        requestType: 'supply'
      })
    })
    if (mentorSessionObj) {
      input.acceptanceObjects = {
        replace: acceptanceObjects
      }
      updateMentorSession(input, get(mentorSessionObj, 'id'))
        .then(async (res) => {
          if (res && res.updateMentorSession) {
            notification.success({
              message: 'Session added successfully'
            })
            closeModal(true)
            addSlotsToLocalState({ ...slotsToUpdate })
          } else {
            setLoading(false)
          }
        })
    } else {
      input.acceptanceObjects = acceptanceObjects
      addMentorSession(input, mentorId).then(async (res) => {
        if (res && res.addMentorSession) {
          notification.success({
            message: 'Session added successfully'
          })
          closeModal(true)
          addSlotsToLocalState({ ...input })
        } else {
          setLoading(false)
        }
      })
    }
  }
  const onConfirm = async () => {
    await addMentorSessionQuery()
  }
  const renderModalHeader = () => (
    <>
      <AddDaySlotsModalStyle.HeaderIcon>
        <Modal.Icon theme='twoTone'
          marginRight='0px'
          fillSvg={TekieAmethyst}
          component={CalendarSvg}
        />
      </AddDaySlotsModalStyle.HeaderIcon>
      <AddDaySlotsModalStyle.HeaderDetails>
        <Modal.HeaderTitle>
          Add Availibility
        </Modal.HeaderTitle>
        <Modal.HeaderDescription>
          Let Tekie know when you{'\''}re available for taking classes.
        </Modal.HeaderDescription>
      </AddDaySlotsModalStyle.HeaderDetails>
    </>
  )
  const renderModalFooter = () => (
    <Modal.FlexContainer style={{ alignItems: 'center', width: '100%', padding: 0, justifyContent: 'flex-end' }}>
      <Modal.SecondaryButton
        onClick={() => closeModal()}
        style={{ marginRight: '10px' }}
      >
        Cancel
      </Modal.SecondaryButton>
      <Modal.PrimaryButton
        loading={loading}
        onClick={debounce(onConfirm, 500)}
      >
        {loading && <Modal.Spinner />}
        Confirm
      </Modal.PrimaryButton>
    </Modal.FlexContainer>
  )

  const renderDateTimeSection = (slot) => (
    <Modal.FlexContainer>
      <Modal.FlexContainer flexDirection='column' style={{ padding: '14px 20px 0px 0px' }}>
        <AddDaySlotsModalStyle.SecondaryText>Timings
                  <Modal.Icon
                    style={{
                        fontSize: '14px',
                        marginLeft: '6px'
                      }}
                    onClick={() => slotToDelete(get(slot, 'id'))}
                    component={DeleteOutlined}
                  />
        </AddDaySlotsModalStyle.SecondaryText>
        <Modal.FlexContainer style={{ padding: '0px', alignItems: 'center' }}>
          <Modal.CustomTimePicker
            use12Hours
            format={TimePickerFormat}
            hideDisabledOptions
            allowClear={false}
            inputReadOnly
            open={false}
            value={moment(get(slot, 'startTime'))}
            placeholder='Start'
            disabledHours={() => {
                    if (new Date().setHours(0, 0, 0, 0)
                      === new Date(get(slot, 'startTime')).setHours(0, 0, 0, 0)) {
                      return [...Array(new Date().getHours() + 1).keys()].slice(1)
                    }
                  }}
          />
          <AddDaySlotsModalStyle.SecondaryText style={{ padding: '8px' }}>
            to
          </AddDaySlotsModalStyle.SecondaryText>
          <Modal.CustomTimePicker
            use12Hours
            format={TimePickerFormat}
            hideDisabledOptions
            allowClear={false}
            inputReadOnly
            open={false}
            value={moment(get(slot, 'endTime'))}
            placeholder='End'
            disabledHours={() =>
              [...Array(get(slot, 'startTime') + 1).keys()].slice(1)
            }
          />
        </Modal.FlexContainer>
      </Modal.FlexContainer>
      <Modal.FlexContainer flexDirection='column' style={{ padding: '14px 0px 0px 0px' }}>
        <AddDaySlotsModalStyle.SecondaryText>
          Date
        </AddDaySlotsModalStyle.SecondaryText>
        <Modal.FlexContainer style={{ padding: '0px', alignItems: 'center' }}>
          <Modal.CustomDatePicker
            placeholder='Start Date'
            allowClear={false}
            inputReadOnly
            open={false}
            value={moment(get(slot, 'startTime'))}
            disabledDate={(current) => current &&
                    current < new Date().setDate((new Date().getDate()) - 1)
                  }
            format='DD MMMM YYYY'
            style={{ width: '100%' }}
          />
        </Modal.FlexContainer>
      </Modal.FlexContainer>
    </Modal.FlexContainer>
  )
  return (
    <Modal.WithBackdrop visible={isModalVisible}>
      <Modal.ModalBox visible={isModalVisible}>
        <Modal.CloseIcon theme='twoTone'
          onClick={() => closeModal()}
        />
        {/* Modal Header */}
        <Modal.Header>
          {renderModalHeader()}
        </Modal.Header>
        {/* Modal Contents */}
        <Modal.Content>
          {get(props, 'selectedSlotsToOpen', []).map(slot => renderDateTimeSection(slot))}
        </Modal.Content>
        {/* Modal Footer */}
        <Modal.Footer>
          {renderModalFooter()}
        </Modal.Footer>
      </Modal.ModalBox>
    </Modal.WithBackdrop>
  )
}

export default AddDaySlotsModal
