import { Button, Input, Table } from 'antd'
import styled from 'styled-components'
import colors from '../../../constants/colors'
import antdButtonColor from '../../../utils/mixins/antdButtonColor'

const ManageTrainerStyle = styled.div`
    height: 100%;
    width: 100%;
    margin: 0 auto;
`

const TopContainer = styled.div`
    display:flex;
    justify-content:space-between;
    padding-bottom:20px;
`
const StyledInput = styled(Input)`
    min-width: 200px;
    display: flex;
    align-items: left;
    border-width: 0 0 2px 0 !important;
`

const StyledButton = styled(Button)`
  &&& {
    ${antdButtonColor(colors.subThemeColor)}
  }
`
const MDTable = styled(Table)`
& .ant-table-content{
  padding:15px;
  color: #122b4a;
  background-color: #ffffff;
  margin-top:20px;
  border:1px solid lightgray;
  border-radius:6px;
  box-shadow:0px 4px 4px lightgray;
}
 & .ant-table-thead > tr > th{
  background-color: rgba(18, 43, 74, 0.17);
  margin: 0 0 78px 1px !important;
  font-weight: 600;
  color: #122b4a;
 }
 & tbody > tr{
   background-color: rgba(228, 228, 228, 0.35);
 }
 & tbody > tr .anticon-delete svg{
  width: 16.8px;
  height: 22.2px;
  color: ${colors.table.deleteIcon};
 }
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.direction ? props.direction : 'row'};
  justify-content: ${props => props.justify ? props.justify : 'flex-start'};
  align-items: ${props => props.align ? props.align : 'flex-start'};
  width: 100%;
`

const BatchName = styled.p`
  border: 1px solid #eee;
  padding: 3px 5px;
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 10px;
`

ManageTrainerStyle.TopContainer = TopContainer
ManageTrainerStyle.StyledInput = StyledInput
ManageTrainerStyle.StyledButton = StyledButton
ManageTrainerStyle.MDTable = MDTable
ManageTrainerStyle.FlexContainer = FlexContainer
ManageTrainerStyle.BatchName = BatchName
export default ManageTrainerStyle