const studentHeaders = [
  {
    label: 'Sr. No',
    key: 'no',
  },
  {
    label: 'Student Name',
    key: 'studentName',
  },
  {
    label: 'Grade',
    key: 'grade',
  },
  {
    label: 'Section',
    key: 'section',
  },
  {
    label: 'Parent Name',
    key: 'parentName',
  },
  {
    label: 'Parent Phone',
    key: 'phone',
  },
  {
    label: 'Parent Email',
    key: 'email',
  },
]

export default studentHeaders
