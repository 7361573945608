/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react'
import { get } from 'lodash'
import { Formik } from 'formik'
import { Form, notification } from 'antd'
import Dropzone from '../../../../components/Dropzone'
import MainModal from '../../../../components/MainModal'
import { Input } from '../../../AddCourse/components/Forms/FormElements'
import getFullPath from '../../../../utils/getFullPath'
import updateSenseiMentorTeam from '../../../../actions/senseiProfiles/updateSenseiMentorTeam'
import addSenseiMentorTeam from '../../../../actions/senseiProfiles/addSenseiMentorTeam'
import removeTeamProfilePic from '../../../../actions/senseiProfiles/removeTeamProfilePic'

const AddTeamsModal = (props) => {
  const [teamName, setTeamName] = useState('')
  const thumbnailRef = useRef()
  const [thumbnailFile, setThumbnailFile] = useState(null)
  const [thumbnailUrl, setThumbnailUrl] = useState(null)
  const [IsEditMode, setIsEditMode] = useState(false)

  const onCancel = () => {
    props.onCancel()
  }

  const onSave = async (nameOfTeam) => {
    const { senseiProfile } = props
    if (IsEditMode) {
      if (get(props.senseiProfile, 'senseiMentorTeams[0].teamProfilePic.id') && thumbnailFile) { await removeTeamProfilePic(get(senseiProfile, 'senseiMentorTeams[0].id'), get(props.senseiProfile, 'senseiMentorTeams[0].teamProfilePic.id')) }
      await updateSenseiMentorTeam(props, nameOfTeam, thumbnailFile)

      if (props.visible) {
        notification.success({ message: 'Team updated successfully' })
        onCancel()
      }
    } else {
      await addSenseiMentorTeam(props, nameOfTeam, thumbnailFile)
      if (props.visible) {
        notification.success({ message: 'Team added successfully' })
        onCancel()
      }
    }
  }

  const isSaving = () => !!(props.isUpdatingSenseiTeam || props.isAddingSenseiTeam)

  const onDropThumbnail = (file) => {
    setThumbnailFile(file)
  }

  useEffect(() => {
    if (get(props.senseiProfile, 'senseiMentorTeams[0]')) {
      setIsEditMode(true)
    }
    setTeamName(get(props.senseiProfile, 'senseiMentorTeams[0].name'))
    setThumbnailUrl(`${getFullPath(
      get(props.senseiProfile, 'senseiMentorTeams[0].teamProfilePic.uri')
    )}`)
  }, [props.visible])

  return (

        <MainModal
          visible={props.visible}
          title={[
          <div>
            <div>{props.title}</div>
          </div>]}
          onCancel={onCancel}
          maskClosable='true'
          width='500px'
          footer={
          [
            <MainModal.SaveButton
              disabled={!teamName}
              type='primary'
              htmlType='submit'
              onClick={() => onSave(teamName)}
            >
              {' '}
              {isSaving() ? 'Adding...' : 'ADD'}
            </MainModal.SaveButton>
          ]}
        ><Formik
          initialValues={{ thumbnailFile }}

        >
           <Form id='mentor-edit-form'>
          <MainModal.FormItem label='Choose Team logo:'>
          <Dropzone
            style={{ height: '200px', width: '100%', marginBottom: '15px' }}
            getDropzoneFile={onDropThumbnail}
            ref={thumbnailRef.current}
            defaultImage={thumbnailUrl}
            defaultFile={thumbnailFile}
            onImageUrl={imgUrl => setThumbnailUrl(imgUrl)}
          >Click or drag to attach
          </Dropzone>
          </MainModal.FormItem>
          <MainModal.FormItem label='Add Team Name:'>
          <Input

            placeholder='Geeky....'
            name='title'
            type='text'
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
          />
          </MainModal.FormItem>
           </Form>
         </Formik>
        </MainModal>

  )
}

export default AddTeamsModal
