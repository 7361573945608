import gql from 'graphql-tag'
import duck from '../../duck'
import getIdArrForQuery from '../../utils/getIdArrForQuery'

const updateTimeTableSchedule = async (id, input, schoolConnectId, batchConnectIds) => await duck.query({
  query: gql`
    mutation($input:TimetableScheduleUpdate!){
      updateTimetableSchedule(
        id: "${id}",
        ${schoolConnectId ? `schoolConnectId:"${schoolConnectId}"` : ''},
        ${batchConnectIds ? `batchConnectIds:[${getIdArrForQuery(batchConnectIds)}]` : ''},
        input:$input
      ){
        id
      }
    }
  `,
  variables: {
    input
  },
  type: 'timeTableSchedule/update',
  key: 'updateTimeTableSchedule'
})

export default updateTimeTableSchedule