import gql from 'graphql-tag'
import duck from '../../duck'

const fetchMentorsForBroadcast = async (filterQuery) =>
  duck.query({
    query: gql`
      {
        mentorsForBroadcast: users(filter: { and: [
          { role: mentor },
          { secondaryRole_exists: false },
          { mentorProfile_exists: true },
          { mentorProfile_some: { isMentorActive: true } }
          ${filterQuery || ''}
        ] }) {
          id
          name
          gender
          createdAt
          mentorProfile {
            id
            pythonCourseRating1
            pythonCourseRating2
            pythonCourseRating3
            pythonCourseRating4
            pythonCourseRating5
            codingLanguages {
              value
            }
          }
          profilePic {
            id
            uri
          }
        }
      }
    `,
    type: 'mentorsForBroadcast/fetch',
    key: 'mentorsForBroadcast',
  });

export default fetchMentorsForBroadcast

