import { Radio, Tooltip } from 'antd'
import React from 'react'
import PropTypes from 'prop-types'
import Main from './SchoolSection.style'

const SchoolSectionNav = ({ userSecondaryRole, activeTab, changeTab, data, studentsCount }) => (
  
  <Main>
    <Main.RadioGroup
      defaultValue={activeTab}
      value={activeTab}
      buttonStyle='solid'
      onChange={(e) => {if(data)changeTab(e.target.value)}}
    >
      <Radio.Button value='/sms/school-dashboard/:schoolId/classrooms'>Classrooms</Radio.Button>
      {(!userSecondaryRole || userSecondaryRole !== 'teacherTrainer') && (
        <>
          {/* <Tooltip title='To be Deprecated' placement='top'>
            <Radio.Button value='/sms/school-dashboard/:schoolId/grade'>Grades</Radio.Button>
          </Tooltip> */}
          <Radio.Button value='/sms/school-dashboard/:schoolId/students'>Students ({studentsCount})</Radio.Button>
          <Tooltip title='To be Deprecated' placement='top'>
            <Radio.Button value='/sms/school-dashboard/:schoolId/batches'>Batches</Radio.Button>
          </Tooltip>
        </>
      )}
      <Radio.Button value='/sms/school-dashboard/:schoolId/teacher'>Teacher</Radio.Button>
      {(!userSecondaryRole || userSecondaryRole !== 'teacherTrainer') && (
        <>
          <Radio.Button value='/sms/school-dashboard/:schoolId/upload-students'>Upload Students</Radio.Button>
          <Radio.Button value='/sms/school-dashboard/:schoolId/teacher-training'>Teacher Training</Radio.Button>
          <Radio.Button value='/sms/school-dashboard/:schoolId/lab-inspection'>Lab Inspection</Radio.Button>
        </>
      )}
      {/* <Radio.Button value='/sms/school-dashboard/:schoolId/campaigns'>Campaigns</Radio.Button> */}
      {/* <Radio.Button value='/sms/school-dashboard/:schoolId/schedule'>Schedule</Radio.Button> */}
    </Main.RadioGroup>
  </Main>
)

SchoolSectionNav.propTypes = {
  activeTab: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired,
}

export default SchoolSectionNav
