const topicJourneyRoutes = {
  learningObjectives: '/learning-objectives',
  episode: '/episode',
  techTalk: '/tech-talk',
  questionBank: '/questionbank',
  badges: '/badges',
  assignment: '/assignment',
  cheatsheet: '/cheatSheet',
  workbook: '/workbook',
  project: '/project',
  contentComic: '/comic',
  contentTechTalk: '/content-techTalk',
  contentQuestions: '/content-questions',
  contentLearningSlides: '/content-learningSlides'
}
export default topicJourneyRoutes
