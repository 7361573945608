import styled from 'styled-components'
import { Select, Button } from 'antd'

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
`
const UploadContainer = styled.div`
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  padding: 20px; 
  height: 400px;
  margin: 10px 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`
const EditContainer = styled.div`
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  padding: 15px; 
  height: 250px;
  margin: 10px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
`
const Title = styled.div`
  background-color: #FF5744;
  color: #fff;
  height: 37px;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 10px;
`
const Paragraph = styled.div`
  color: #005773;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  padding: 20px;
  margin: 0 50px 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const SaveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 2px;
`
const DragFile = styled.div`
  color: #00ADE6;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  text-align: center;
`
const Size = styled.div`
  color: ${props => props.font ? props.font : '#00ADE6'};
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  background-color: ${props => props.color ? props.color : '#D7FAFB'};
  min-width: 68px;
  height: 25px;
  padding: 6px;
  
`
const FileInfo = styled.div`
  height: 70%; 
  width: 100%;
  overflow: auto;
  margin-top: 10px;
  color: #005773;
`
const FileName = styled.div`
  color: #005773;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: ${props => props.size ? '18px' : '14px'};
  line-height: ${props => props.size ? '25px' : '16px'};
  overflow: hidden;
`
const Path = styled.div`  
  width: 100%;
  margin-bottom: 10px;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 31px;
  color: #005773;
  display: flex;
`
const StyledButton = styled(Button)`
  &&& {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
    color: #FFFFFF;
    letter-spacing: 0.03em;
  }
`
const CancelButton = styled(Button)`
  &&& {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    background: #FFFFFF;
    border: 1px solid #80D6F3;
    width: 90px;
    height: 40px;
    border-radius: 40px;
    padding: 12px 16px 11px;
  }
`
const UploadButton = styled(Button)`
  &&& {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    background: conic-gradient(from -3.29deg at 100% -13%, #35E4E9 0deg, #00ADE6 360deg);
    border: 1px solid #00ADE6;
    width: ${props => props.wide ? '157px' : '124px'};
    text-shadow: initial;
    height: 40px;
    border-radius: 40px;
    padding: 12px 16px 11px;
  }
`
const ConfirmButton = styled(Button)`
  &&& {
    background: #FF5744;
    border-radius: 40px;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
  }
`
const StyledSelect = styled(Select)`
min-width: 160px;
display: flex;
align-items: left;
`

const StyledOption = styled(Select.Option)``

const StyledTableWrapper = styled.div`
height: 260px;
overflow:scroll;`

const Main = styled.div``

Main.Button = StyledButton
Main.Size = Size
Main.Cancel = CancelButton
Main.Upload = UploadButton
Main.FileName = FileName
Main.Title = Title
Main.DragFile = DragFile
Main.FileInfo = FileInfo
Main.ConfirmButton = ConfirmButton
Main.SaveButton = SaveButton
Main.Path = Path
Main.Paragraph = Paragraph
Main.TopContainer = TopContainer
Main.UploadContainer = UploadContainer
Main.EditContainer = EditContainer
Main.Select = StyledSelect
Main.Option = StyledOption
Main.StyledTableWrapper = StyledTableWrapper
export default Main
