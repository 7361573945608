import gql from "graphql-tag"
import { get } from "lodash"
import requestToGraphql from "../../utils/requestToGraphql"

const fetchCoursesWithFilter = async (title) => {
    const courses = await requestToGraphql(gql`{
    courses(filter: {
      title_contains: "${title}"
    }){
      title
    }
}`)
  return get(courses, 'data.courses')
}

export default fetchCoursesWithFilter;