import gql from 'graphql-tag'
import { get } from 'lodash'
import { notification } from 'antd'
import requestToGraphql from '../../utils/requestToGraphql'
import errors from '../../constants/errors'


export const LOGIN_LOADING = 'LOGIN_LOADING'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

const LOGIN_QUERY = (username, password) => gql`
  mutation {
    login(input: {
      username:"${username}",
      password:"${password}"
    }) {
      id,
      token,
      name,
      role,
      secondaryRole,
      email,
      phone{
        number
      },
      mentorProfile {
        googleMeetLink
        sessionLink
      }
    }
  }
`
const forgetPasswordQuery = (phone, countryCode, password, confirmPassword) => gql`mutation {
  resetPasswordAndLogin(
    input: {
      phone: { number: "${phone}", countryCode: "+${countryCode}" }
      password: "${password}"
      confirmPassword: "${confirmPassword}"
    }
  ) {
    id
    token
    name
    role
    secondaryRole
    email
    phone {
      number
    }
    mentorProfile {
      googleMeetLink
      sessionLink
    }
  }
}`

const forgotPasswordEmail = (
  authToken,
  password,
  confirmPassword
) => gql`mutation {
  resetPasswordAndLogin(
    input: {
      linkToken: "${authToken}"
      password: "${password}"
      confirmPassword: "${confirmPassword}"
    }
  ) {
    id
    token
    name
    role
    secondaryRole
    email
    phone {
      number
    }
    mentorProfile {
      googleMeetLink
      sessionLink
    }
  }
}`;
const loginFailure = error => ({
  type: LOGIN_FAILURE,
  error
})

const loginLoading = () => ({
  type: LOGIN_LOADING
})

const loginSuccess = ({ name, token, id, role, secondaryRole, email, phone, mentorProfile }) => ({
  type: LOGIN_SUCCESS,
  name,
  token,
  id,
  role,
  secondaryRole,
  email,
  phone: get(phone, 'number', ''),
  googleMeetLink: get(mentorProfile, 'googleMeetLink', ''),
  sessionLink: get(mentorProfile, 'sessionLink', ''),
})

const login = (username,
  password,
  phone,
  countryCode,
  resetpassword,
  confirmPassword,
  authToken) => async dispatch => {
  try {
    dispatch(loginLoading())
    if (username === null && password === null && authToken === '') {
      const { data } = await requestToGraphql(forgetPasswordQuery(
        phone,
        countryCode,
        resetpassword,
        confirmPassword))
      const { resetPasswordAndLogin: loginData } = data
      if (loginData.token) {
        dispatch(loginSuccess(loginData))
      } else {
        dispatch(loginFailure(errors.EmptyDataError))
      }
    } else if (username === null && password === null) {
      const { data } = await requestToGraphql(forgotPasswordEmail(
        authToken,
        resetpassword,
        confirmPassword))
      const { resetPasswordAndLogin: loginData } = data
      if (loginData.token) {
        dispatch(loginSuccess(loginData))
      } else {
        dispatch(loginFailure(errors.EmptyDataError))
      }
    } else {
      const { data } = await requestToGraphql(LOGIN_QUERY(username, password))
      const { login: loginData } = data
      if (loginData.token) {
        dispatch(loginSuccess(loginData))
      } else {
        dispatch(loginFailure(errors.EmptyDataError))
      }
    }
  } catch (e) {
    const error = get(e, 'errors[0].message')
    if (error && username === null && password === null) {
      notification.error({ message: error })
    } else if (error) {
      dispatch(loginFailure(error))
    } else {
      dispatch(loginFailure(errors.UnexpectedError))
    }
  }
}

export default login
