import { PlusOutlined } from '@ant-design/icons';
import { Alert, Tooltip } from 'antd';
import React, { Component } from 'react';
import UploadMoment from './component/UploadMoments/UploadMoment';
import WinnerDetails from './component/winnerDetailsList/WinnerDetails'
import WinnerHeading from './component/WinnersHeading/WinnerHeading';
import WrapupEvent from './component/WrapupEvent/WrapupEvent';
import { AddbuttonStyle, ButtonStyle, FlexContainer, OuterDiv, PlusStyle, StyledBackButton, StyledButton } from './EventCompletion.style';
import { notification } from 'antd';
import fetchPrizes from '../../actions/eventCompletion/fetchPrize';
import addEventWinner from '../../actions/eventCompletion/addEventWinner';
import eventUpdate from '../../actions/eventCompletion/eventUpdate';
import eventWinners from '../../actions/eventCompletion/eventWinners';
import { get } from 'lodash';
import getFullPath from '../../utils/getFullPath';
import updateEventWinner from '../../actions/eventCompletion/UpdateEventWinner'
// import updateEventWinner from '../../actions/eventCompletion/updateEventWinner';
import deleteEventWinners from '../../actions/eventCompletion/deleteEventWinners';
import PageLoader from '../EventDashboard/Components/PageLoader';
import { Event } from '../EventDashboard/createEvent.style';

export default class EventCompletion extends Component {
    constructor(props) {
        super(props);
        const ids = this.props.match.params.id;
        this.state = {
            Id: ids,
            winnerList: [],
            winnerSwitch: 1,
            uploadSwitch: 1,
            wrapupSwitch: 1,
            eventConnectId: '',
            prizeConnectId: '',
            mutationState: [],
            momentUploadLink: '',
            fetchedDataCount: -1,
            isFetching: true,
            saveAndDiscardLoader: false,
            redirect: 0,
            selectWinnersMsg: "No Prizes added to select Winners",
            isLoading: false,
            totalPrizeAvailableCount: 0,
        };
    }

    componentDidMount = async () => {

        await fetchPrizes(this.state.Id);
        this.setState({
            Id: this.props.match.params.id,
        })
        await eventWinners(this.state.Id);
        const { prizeData,winnersData } = this.props;
        const prizeDatas = prizeData && prizeData.toJS() || null;
        const winnersDatas = winnersData && winnersData.toJS();

        if (prizeDatas.length !== 0) {
            this.setState({
                selectWinnersMsg: "",
            });
        }
        if (prizeDatas.length === 0) {
            this.setState({
                selectWinnersMsg: "No Prizes added to select Winners",
                winnerSwitch: 1,
            });
        }
        if(winnersDatas[0].registeredUsersMeta.count===0){
            this.setState({
                selectWinnersMsg: "Cannot select event winners",
                winnerSwitch: 1,
                
            });
        }
        let count = 0;
        for (const PrizeObj of prizeDatas) {
            count += get(PrizeObj, 'maxRank') - get(PrizeObj, 'minRank') + 1;
        }
        this.setState({
            totalPrizeAvailableCount: count,
            totalWinnersThatCanBeAdded: winnersDatas[0].registeredUsersMeta.count,
        })
    }
    componentDidUpdate = async (prevProps, prevState) => {
        const { winnersData, fetchWinnersData, prizeData, prizeDataFetchStatus } = this.props;
        const winnersDatas = winnersData && winnersData.toJS();
        const prizeDatas = prizeData && prizeData.toJS() || null;
        const prevPrizeDatas = prevProps.prizeData && prevProps.prizeData.toJS() || null;
      
        if (prevState.isLoading !== this.state.isLoading && !this.state.isLoading) {
            await this.props.history.push('/eventDashboard/events')
        }
        if (prevPrizeDatas.length !== prizeDatas.length && prizeDatas.length !== 0) {
            this.setState({
                selectWinnersMsg: "",
            });
        }
        if (prevPrizeDatas.length !== prizeDatas.length && prizeDatas.length === 0) {
            this.setState({
                selectWinnersMsg: "Add Prizes to select Winners",
                winnerSwitch: 1,
            });
        }

        if (fetchWinnersData && !get(fetchWinnersData.toJS(), 'loading') && get(fetchWinnersData.toJS(), 'success')
         && (prevProps.fetchWinnersData !== fetchWinnersData)) {
            if (winnersDatas.length > 0) {
                if (winnersDatas[0].eventWinners.length > 0) {
                    let arr = [], MutationArr = [];
                    winnersDatas[0].eventWinners.map((Wdata) => {
                        const data = {
                            studentProfileId: get(Wdata, 'studentProfile.id'),
                            prizeConnectId: get(Wdata, 'prize.id'),
                            winnerName: get(Wdata, 'studentProfile.user.name'),
                            eventWinnerId: get(Wdata, 'id'),
                            prizeWon: get(Wdata, 'prize.title'),
                            prizeWonId: get(Wdata, 'prize.id') || null,
                            prevUploadImageId: get(Wdata, 'image.id') || null,
                            prevUploadImageLink: getFullPath(get(Wdata, 'image.uri')) || '',
                            uploadImageLink: getFullPath(get(Wdata, 'image.uri')) || '',
                            registeredPhoneNumber: get(Wdata, 'studentProfile.parents[0].user.phone.number'),
                            uploadImageFileObject: null,
                            imageSizeError: '',
                        }
                        arr = [...arr, data];
                        const mutationobj = {
                            prizeConnectId: Wdata.prize.id,
                            eventConnectId: winnersDatas[0].id,
                        }
                        MutationArr = [...MutationArr, mutationobj]
                    })
                    this.setState({
                        winnerList: arr,
                        fetchedDataCount: arr.length - 1,
                        mutationState: MutationArr,
                        Id: this.props.match.params.id,
                        isFetching: false,
                    })
                }
                
                this.setState({
                    winnerSwitch: winnersDatas[0].showWinnersOnWeb === true ? 0 : 1,
                    uploadSwitch: winnersDatas[0].showMomentsOnWeb === true ? 0 : 1,
                    wrapupSwitch: winnersDatas[0].showCompletedEvent === true ? 0 : 1,
                    momentUploadLink: winnersDatas[0].momentFromEventLink,
                })
                if(winnersDatas[0].registeredUsersMeta.count===0){
                    this.setState({
                        selectWinnersMsg: "Cannot select event winners",
                        winnerSwitch: 1,
                    });
                }
            }
            if (winnersDatas[0]) {
                this.setState({
                    isFetching: false,
                })
            }
        }
    }
    AddWinners = (phoneNumberData) => {
        const { winnerList, totalPrizeAvailableCount, totalWinnersThatCanBeAdded } = this.state;
        const { prizeData } = this.props;
        const prizeDatas = prizeData && prizeData.toJS() || null;
        if (winnerList.length + 1 > totalPrizeAvailableCount || winnerList.length  >=totalWinnersThatCanBeAdded) {

        }
        else {
            phoneNumberData.length = 0;
            const WinnerObject = {
                studentProfileId: '',
                prizeConnectId: '',
                winnerName: "",
                eventWinnerId: "",
                prizeWon: '',
                prizeWonId: "",
                changesMadeState: 0,
                prevUploadImageId: null,
                prevUploadImageLink: null,
                registeredPhoneNumber: '',
                uploadImageFileObject: null,
                uploadImageLink: null,
                imageSizeError: '',
            }
            const mutationObject = {
                prizeConnectId: "",
                eventConnectId: this.state.Id,
            }
            if (winnerList.length >= 1) {
                if (winnerList[winnerList.length - 1].winnerName !== "" && winnerList[winnerList.length - 1].prizeWon !== "" && winnerList[winnerList.length - 1].registeredPhoneNumber !== "") {
                    if(winnerList[winnerList.length - 1].imageSizeError===''){
                        this.setState({
                            winnerList: [...winnerList, WinnerObject]
                        })
                    }
                   else{
                    notification.info({
                        message: `Error`,
                        description:
                            'Upload image of recommended size!!!!',
                    });
                   }
                }
                else {
                    notification.info({
                        message: `Error`,
                        description:
                            'Input Fields cannot be empty!!!!',
                    });
                }
                const mutationobj = {
                    prizeConnectId: this.state.prizeConnectId,
                    eventConnectId: this.state.Id,
                }
                this.setState({
                    mutationState: [...this.state.mutationState, mutationobj]
                })

            }
            else {
                this.setState({
                    winnerList: [...winnerList, WinnerObject],
                    mutationState: [...this.state.mutationState, mutationObject],
                })
            }

        }

    }

    SaveAndPublish = async () => {
        this.setState({
            isLoading: true,
        });
        const { mutationState, winnerSwitch, uploadSwitch, wrapupSwitch, momentUploadLink, Id, winnerList, fetchedDataCount, saveAndDiscardLoader } = this.state;
        const { UpdateEvent, AddEventWinner } = this.props;
        const updateEventWinners = get(UpdateEvent && UpdateEvent.toJS() || null, 'updateEventWinner.loading');
        const addEventWinners = get(AddEventWinner && AddEventWinner.toJS() || null, 'addEventWinner.loading');
        const countObj = winnerList.filter((winnerData, i) => {
            return (winnerData.winnerName === "" || winnerData.prizeWon === "" || winnerData.registeredPhoneNumber === "")

        })
        const imageSizeObj = winnerList.filter((winnerData, i) => {
            return (winnerData.imageSizeError !== '')

        })
        const splittedDatas = []
        for (let i = 0; i < winnerList.length; i++) {
        const currentSplitLength = splittedDatas.length
        if (splittedDatas[currentSplitLength - 1]) {
            if (splittedDatas[currentSplitLength - 1].length < 5) {
            splittedDatas[currentSplitLength - 1].push(winnerList[i])
            } else {
            splittedDatas.push([winnerList[i]])
            }
        } else {
            splittedDatas[0] = [winnerList[i]]
        }
        }
        let isInvalidLength = 0
        for (let ind = 0; ind < splittedDatas.length; ind += 1) {
            if (splittedDatas[ind].length === 2 || splittedDatas[ind].length === 4) {
                const finalData = splittedDatas.filter((_, i) => i < ind)
                let newFinalData = []
                finalData.forEach(data => newFinalData = [...newFinalData, ...data])
                const newLength = newFinalData.length + splittedDatas[ind].length
                isInvalidLength = newLength
                break;
            }
        }
        if (isInvalidLength) {
            return notification.warn({
                message: `Total winners cannot be ${isInvalidLength}!`
            })
        }
        if (countObj.length === 0) {
            if (imageSizeObj.length === 0) {
                let index = 0;
                for (const wData of winnerList) {

                    if (index <= fetchedDataCount) {
                        if (wData.changesMadeState === 1) {
                            const prizeId = this.state.prizeConnectId === '' ? wData.prizeWonId : this.state.prizeConnectId;
                            await updateEventWinner(wData.eventWinnerId, wData.prizeWonId, wData)
                        }
                    }
                    else {
                        await addEventWinner(wData.studentProfileId, this.state.Id, wData.prizeWonId, wData);
                    }
                    index++;
                }
                this.setState({
                    fetchedDataCount: winnerList.length - 1,
                })
                const input = {
                    showWinnersOnWeb: winnerSwitch === 0 ? true : false,
                    showMomentsOnWeb: uploadSwitch === 0 ? true : false,
                    momentFromEventLink: momentUploadLink === null ? "" : momentUploadLink,
                    showCompletedEvent: wrapupSwitch === 0 ? true : false,
                }
                await eventUpdate(input, Id)
                if (updateEventWinners === false || addEventWinners === false) {
                    this.setState({
                        saveAndDiscardLoader: false,
                    })
                }
                if (updateEventWinners === true || addEventWinners === true) {
                    this.setState({
                        saveAndDiscardLoader: true,
                    })
                }
            }
            else {
                notification.info({
                    message: `Error`,
                    description:
                        'Upload image of recommended size!!!!',
                });
            }
        }
        else {
            notification.info({
                message: `Error`,
                description:
                    'Input Fields cannot be empty!!!!',
            });
        }
        this.setState({
            isLoading: false,
        });

    }


    fetchCount = (Count) => {
        this.setState({
            count: Count,
        })
    }
    updateOrAddWinnerDetails = (updateDetails) => {
        this.setState({
            winnerList: updateDetails,
        })
    }
    deleteEventWinnersDetails = (index) => {
        const { winnerList, fetchedDataCount } = this.state;
        deleteEventWinners(winnerList[index].eventWinnerId);
        if (index <= fetchedDataCount) {
            this.setState({
                fetchedDataCount: fetchedDataCount - 1,
            })
        }
    }
    eventConnectId = (id) => {
        this.setState({
            eventConnectId: id,
        })
    }
    prizeConnectId = (id) => {
        this.setState({
            prizeConnectId: id,
        })
    }
    updateMutationState = (data) => {
        this.setState({
            mutationState: data,
        })
    }
    eventMomentUploadLink = (link) => {
        this.setState({
            momentUploadLink: link,
        })
    }
    toggleWhenPrizeNull = () => {
        this.setState({
            winnerSwitch: 1,
        })
    }
    toggleWinnerSwitch = () => {
        this.setState({
            winnerSwitch: this.state.winnerSwitch === 0 ? 1 : 0,
        })
    }
    toggleUploadSwitch = () => {
        this.setState({
            uploadSwitch: this.state.uploadSwitch === 0 ? 1 : 0,
        })
    }
    toggleWrapupEventSwitch = () => {
        this.setState({
            wrapupSwitch: this.state.wrapupSwitch === 0 ? 1 : 0,
        })
    }
    render() {
        const { winnerSwitch, uploadSwitch, winnerList, wrapupSwitch, mutationState, fetchedDataCount, isFetching, selectWinnersMsg, momentUploadLink, totalPrizeAvailableCount,totalWinnersThatCanBeAdded } = this.state;
        const { studentProfileDataLoading, studentProfileData, prizeData, winnersData, updateEventWinnerStatus, addEventWinnerStatus } = this.props;
        const phoneNumberData = studentProfileData && studentProfileData.toJS();
        const phoneNumberDataLoading = studentProfileDataLoading && studentProfileDataLoading.toJS() || null;
        const prizeDatas = prizeData && prizeData.toJS() || null;
        const winnersDatas = winnersData && winnersData.toJS();
        const updateEventWinnersLoading = get(updateEventWinnerStatus && updateEventWinnerStatus.toJS() || null, 'updateEventWinner.loading');
        const addEventWinnersLoading = get(addEventWinnerStatus && addEventWinnerStatus.toJS() || null, 'addEventWinner.loading');
        return (<>
            <Event.EventsWrapper style={{height:"91vh"}}>
                <PageLoader isfetching={isFetching} />
                <div style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "24px",
                    letterSpacing: "0px",
                    color: "#212121",
                    padding: "6px 10px 18px 22px",

                }}>Event Winners</div>
                <OuterDiv >
                    <FlexContainer>
                        <WinnerHeading selectWinnersMsg={selectWinnersMsg} toggle={this.toggleWinnerSwitch} switchColor={winnerSwitch} />
                        {winnerSwitch === 0 ? (<>
                            {winnerList.length > 0 ? (
                                <div style={{
                                    paddingLeft: "75%",
                                    color: "black"
                                }}><b>{`Upload Student's Image`}</b></div>
                            ) : (
                                <div style={{
                                    marginLeft: "9.5%",
                                    marginTop: "27px",
                                    fontSize: "32px"
                                }}>
                                    <b>{`Enter Winners Details....!!!!`}</b>
                                </div>
                            )}
                            {winnerList.map((list, index) => {
                                return (
                                    <>
                                        <div key={index}>
                                            <WinnerDetails index={index}
                                                list={list}
                                                winnerList={winnerList}
                                                updateOrAddWinnerDetails={updateDetails => this.updateOrAddWinnerDetails(updateDetails)}
                                                phoneNumberData={phoneNumberData}
                                                phoneNumberDataLoading={phoneNumberDataLoading}
                                                prizeDatas={prizeDatas}
                                                eventConnectId={id => this.eventConnectId(id)}
                                                prizeConnectId={id => this.prizeConnectId(id)}
                                                mutationState={mutationState}
                                                updateMutationState={data => this.updateMutationState(data)}
                                                fetchedDataCount={fetchedDataCount}
                                                fetchCount={(count) => this.fetchCount(count)}
                                                deleteEventWinnersDetails={(index) => this.deleteEventWinnersDetails(index)}
                                                isFetching={isFetching}
                                                toggleWhenPrizeNull={toggle => this.toggleWhenPrizeNull(toggle)}
                                            />
                                        </div>
                                    </>
                                )
                            })

                            }
                            <div style={{
                                display: "flex",
                                alignItems: "center", marginTop: "50px", marginTop: "50px",
                            }}>

                                {(winnerList.length + 1 > totalPrizeAvailableCount || winnerList.length  >=totalWinnersThatCanBeAdded) ? (<>
                                    <AddbuttonStyle>
                                        <Tooltip
                                            title={(winnerList.length + 1 > totalPrizeAvailableCount || winnerList.length >=totalWinnersThatCanBeAdded) ? `Cannot add More Event Winners` : ''}
                                            placement='topLeft'
                                        >
                                            <PlusStyle style={{ cursor:"not-allowed" }} >
                                                <PlusOutlined style={{
                                                    fontSize: "22px",
                                                    color: "rgb(65,75,178)"
                                                }} />
                                            </PlusStyle>
                                        </Tooltip>
                                    </AddbuttonStyle>
                                </>) : (<>
                                    <AddbuttonStyle onClick={() => { this.AddWinners(phoneNumberData, winnersDatas) }}>
                                        <Tooltip
                                            title={(winnerList.length + 1 > totalPrizeAvailableCount || winnerList.length >=totalWinnersThatCanBeAdded) ? `Cannot add More Event Winners` : ''}
                                            placement='topLeft'
                                        >
                                            <PlusStyle>
                                                <PlusOutlined style={{
                                                    fontSize: "22px",
                                                    color: "rgb(65,75,178)"
                                                }} />
                                            </PlusStyle>
                                        </Tooltip>
                                    </AddbuttonStyle>
                                </>)}
                                <div style={{ marginLeft: "20px" }}>
                                    {winnerList.length > 0 ?
                                        <b>{`Add more winners`}</b>
                                        : <b>{`Add Winners`}</b>}
                                </div>
                            </div>


                        </>) : null
                        }
                        <div style={{ marginTop: "50px" }}>
                            <UploadMoment toggle={this.toggleUploadSwitch} switchColor={uploadSwitch} eventLink={momentUploadLink} eventMomentUploadLink={(link) => this.eventMomentUploadLink(link)} />
                        </div>
                        <div style={{ marginTop: "50px" }}>
                            <WrapupEvent toggleWrapupEventSwitch={this.toggleWrapupEventSwitch} switchColor={wrapupSwitch} />
                        </div >
                    </FlexContainer>

                </OuterDiv>
            </Event.EventsWrapper>
            <ButtonStyle>

                <StyledBackButton style={{
                    backgroundColor: "rgb(128,128,128)",
                    color: "white",
                    width: "36%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "34px",
                    borderRadius: "4px",
                }}
                    onClick={() => { this.setState({ redirect: 1, }) }}
                >
                    {`Discard`}
                </StyledBackButton>
                <StyledButton className="btn bg-grey" style={{
                    backgroundColor: "rgb(51,61,161)",
                    marginLeft: "37px",
                    width: "44%",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "34px",
                    borderRadius: "4px",
                    cursor: "pointer",
                }}
                    loading={this.state.isLoading}
                    disabled={updateEventWinnersLoading || addEventWinnersLoading}
                    onClick={() => this.SaveAndPublish()}
                >
                    {`Save & Publish`}
                </StyledButton>

            </ButtonStyle>

        </>
        )
    }
}

