import { connect } from 'react-redux'
import { filterKey } from '../../../../utils/data-utils'
import BatchesTab from './BatchesTab'

const mapStateToProps = (state) => ({
  campaignBatches: filterKey(state.data.getIn(['batches', 'data']), 'campaignBatches'),
  campaignBatchesFetching: state.data.getIn(['campaignBatches', 'fetchStatus', 'campaignBatches']),
  campaignBatchesUpdate: state.data.getIn(['campaignBatches', 'updateStatus', 'campaignBatches']),
  batchesMeta: state.data.getIn(['campaignBatchesMeta', 'data', 'count']),
  trainingBatches: filterKey(state.data.getIn(['batch', 'data']), 'trainingBatches'),
  trainingBatchesFetching: state.data.getIn(['batch', 'fetchStatus', 'trainingBatches']),
  trainingBatchesUpdate: state.data.getIn(['batch', 'updateStatus', 'trainingBatches']),
  trainingBatchesAdd: state.data.getIn(['batch', 'addStatus', 'trainingBatches']),
  trainingBatchesAddFailure: state.data.getIn(['errors', 'batch/add']),
  trainingBatchesUpdateFailure: state.data.getIn(['errors', 'batch/update']),
  trainingBatchesDeleteFailure: state.data.getIn(['errors', 'batch/delete']),
  trainingBatchesDelete: state.data.getIn(['batch', 'deleteStatus', 'trainingBatches']),
  trainingBatchesMeta: state.data.getIn(['trainingBatchesMeta', 'data', 'count']),
  manageTrainersFetchStatus: state.data.getIn(['users', 'fetchStatus', 'schoolTrainer']),
  trainers: filterKey(state.data.getIn(['user', 'data']), 'schoolTrainer'),
  trainingCoursePackagesFetchStatus: state.data.getIn(['coursePackages', 'fetchStatus', 'coursePackages']),
  coursePackagesData: filterKey(state.data.getIn(['coursePackages', 'data']), 'coursePackages'),
})


export default connect(mapStateToProps)(BatchesTab)
