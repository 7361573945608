import React, { useState } from 'react'
import { Popconfirm } from 'antd'
import { StyledSwitch, ChapterFlex } from '../../../AddChapters/AddChapter.style'
import { updateEventTag, updateEventCategory } from '../../../../actions/eventDashboard/eventSettings'

const DisplayStatus = (props) => {
  const { id, status, tabType } = props
  const [isSubmitting, setSubmitting] = useState(false)
  const [showPopup, setShowPopup] = useState(false)

  const updateStatus = async () => {
    setSubmitting(true)
    setShowPopup(true)
    if (tabType === 'Tag') {
      const input = { displayOnWebsite: !status }
      const res = await updateEventTag({ id, input })
      if (res && res.id) {
        setShowPopup(false)
        setSubmitting(false)
      }
    }
    if (tabType === 'Category') {
      const input = { displayOnWebsite: !status }
      const res = await updateEventCategory({ id, input })
      if (res && res.id) {
        setShowPopup(false)
        setSubmitting(false)
      }
    }
    setSubmitting(false)
    setShowPopup(false)
  }

  const checkStatus = () => status === true

  const PopupText = (
    <div style={{ alignItems: 'center' }}>
      <h4 style={{ fontWeight: 'bold' }}>Are you Sure ?</h4>
      <p>{`You want to ${checkStatus() ? 'remove' : 'display'} this ${tabType} ${checkStatus() ? 'from' : 'on'} the Website?`}</p>
      <p>This may affect the events listing Page.</p>
    </div>
  )

  return (
    <ChapterFlex style={{ justifyContent: 'center', padding: '0px 30px' }}>
      <Popconfirm
        title={PopupText}
        visible={showPopup}
        onConfirm={updateStatus}
        icon=""
        onCancel={() => setShowPopup(!showPopup)}
        placement='bottom'
        okText={`${checkStatus() ? 'Remove From Website' : 'Display on Website'}`}
        okType={checkStatus() ? 'danger' : 'primary'}
        okButtonProps={{ loading: isSubmitting }}
        cancelText='Cancel'
        key='toggle'
      >
        <StyledSwitch
          bgcolor={checkStatus() ? '#64da7a' : '#ff5744'}
          checked={checkStatus()}
          defaultChecked={checkStatus()}
          onChange={() => setShowPopup(!showPopup)}
          size='default'
          disabled={props.shouldBeDisabled}
        />
      </Popconfirm>
    </ChapterFlex>
  )
}

export default DisplayStatus