import gql from 'graphql-tag'
import { get } from 'lodash'
import userStatusConst from '../../constants/userStatusConst'
import duck from '../../duck'
import requestToGraphql from '../../utils/requestToGraphql'
import convertSchoolCountAndGrade from './convertSchoolCountandGrade'
const fetchSchoolGrades = async (id) =>
  duck.query({
    query: gql`
    {
      school(id: "${id}") {
        id
        name
        code
        hubspotId
        studentsMeta {
          count
        }
        schoolCampaignCode
        academicYears {
          id
          startDate
          endDate
        }
        logo {
          id
          uri
        }
        studentsUploadStatus
        studentsDraftCSVConfig {
          title
          parentNameHeader
          parentEmailHeader
          studentNameHeader
          rollNoHeader
          gradeHeader
          sectionHeader
        }
        studentsDraftCSV {
			      childName
            parentName
            rollNo
            grade
            section
            parentEmail
            status
            error
	    }
        classes {
          id
          grade
          section
          gradeDisplayName
          sectionDisplayName
        }
      }
    }
    `,
   type: 'schoolClasses/fetch',
    key: 'schoolClasses',
    changeExtractedData: async (extractedData, originalData) => {
      let studentsCount = []
      const { schoolClasses, grades, studentsMeta } = convertSchoolCountAndGrade(originalData, studentsCount)
      extractedData.schoolClasses = schoolClasses
      extractedData.user = []
      return {
        ...extractedData,
        schoolDashboardCount: {
          code: get(originalData, 'school.code'),
          studentsMeta: get(originalData, 'school.studentsMeta.count'),
          gradeMeta: grades.length,
          campaignsMeta: get(originalData, 'campaignsMeta.count', 0),
          batchesMeta: get(originalData, 'batchesMeta.count', 0),
          logo: get(originalData, 'school.logo.uri', ''),
          hubspotId: get(originalData, 'school.hubspotId', '-'),
          schoolCampaignCode: get(originalData, 'school.schoolCampaignCode'),
            studentsUploadStatus: get(
            originalData,
            "school.studentsUploadStatus"
          ),
          studentsDraftCSV: get(originalData, "school.studentsDraftCSV", []),
          studentsDraftCSVConfig: get(originalData, "school.studentsDraftCSVConfig", []),
        },
        }
      }
    }
  )

export default fetchSchoolGrades;
