import { DatePicker, Table } from 'antd'
import styled from 'styled-components'
import { TekieAmethyst } from '../../../constants/colors'


const DemandCalendarWrapper = styled.div`
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
letter-spacing: 0.4px;
& a:hover {
  color: #605959;
}
.supply-calendar-loading-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background: white;
  &.show {
    opacity: 0.6;
    pointer-events: initial;
  }
  pointer-events: none;
  opacity: 0;
  transition: 0.2s all ease-in-out;
}
.supply-dashboard-calender-loading-container {
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
position: absolute;
bottom: 0;
left: 0;
z-index: 999;
padding: 12px 20px;
}
.supply-dashboard-calender-loading{
font-size: 16px;
font-family: 'Inter';
font-weight: 450;
}
`

const EventViewContainer = styled.div`
display: flex;
flex-direction: column;
padding-top: 10px;
`

const NavigateButton = styled.button`
display: flex;
justify-content: space-between;
width: fit-content;
border: none;
background-color: transparent;
cursor: pointer;
padding: 8px;
align-items: center;
span{
  font-size: 16px;
  margin: 0 5px;
}
`

const EventViewCard = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 12px;
width: 100%;
overflow-y: auto;
max-height: 85vh;
background: #FFFFFF;
min-height: 100%;
`

const EventCard = styled.div`
padding: 15px;
box-sizig: border-box;
display: flex;
flex-direction: column;
width: 100%;
h1{
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 18px;
display: flex;
align-items: center;
color: #424242;
}
.dateTime{
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;
display: flex;
align-items: center;
color: #9E9E9E;
}
h2{
font-family: Inter;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 24px;
display: flex;
align-items: center;
color: #212121;
margin: 0;
}
h3{
font-family: Inter;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 18px;
color: #000000;
}
h4{
  margin: 0;
font-family: Inter;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 32px;
color: #000000;
}
`

const DemandSupplyTable = styled(Table)`
& thead{
background: #F5F5F5;
}
& thead tr th:first-of-type, & thead tr th:last-of-type{
border-radius: 6px;
}
& thead tr th{
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;
color: #212121;
}
& .ant-table-placeholder{
border-top: 0 !important;
}
`

const TabButton = styled.div`
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
padding-bottom: 5px;
cursor: pointer;
${props => props.isActive ? `
color: #8C61CB;
border-bottom: 2px solid #8C61CB;
` : `
color: #212121;
opacity: 0.4;
border-bottom: 2px solid transparent;
`}
`

const MentorRatingBox = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 4px 6px;
background: #FEF6E6;
border-radius: 4px;
color: #FAAD14;
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;
margin-left: 15px;
max-width: max-content;
& .anticon{
  margin-right: 5px;
}
`

const MentorFeature = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 4px 8px;
background: #F2F2F2;
border-radius: 4px;
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;
color: #212121;
flex: none;
order: 0;
flex-grow: 0;
margin: 0px 5px;
&:first-of-type{
  margin-left: 0 !important;
}
`

const MentorProfile = styled.div`
width: 20px;
height: 20px;
background: url(${props => props.profilePic || 'none'});
border-radius: 50px;
border: 0.5px solid lightgray;
background-size: contain;
`

const CustomDatePicker = styled(DatePicker)`
    &.ant-calendar-picker {
        width: 100px;
    }
    &.ant-calendar-picker:hover {
        &.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
            border-color: ${TekieAmethyst};
        }
    }
    & .ant-calendar-today-btn {
        color: ${TekieAmethyst};
    }
    .ant-calendar {
        border-radius: 8px !important;
    }
    .ant-calendar-today > div {
        border-color: ${TekieAmethyst} !important;
        color: ${TekieAmethyst} !important;
    }
    .ant-calendar-selected-day .ant-calendar-date {
        background: ${TekieAmethyst} !important;
        color: white !important;
    }
    .ant-calendar-picker-input.ant-input {
        font-family: 'Inter';
        color: #333333;
        font-weight: normal;
        letter-spacing: 0px;
        border-radius: 8px;
        border: 1.5px solid #AAAAAA;
        height: 42px;
    }
    .ant-time-picker-panel-combobox .ant-time-picker-panel-select li:focus {
        color: ${TekieAmethyst} !important;
    }
`

DemandCalendarWrapper.EventViewContainer = EventViewContainer
DemandCalendarWrapper.NavigateButton = NavigateButton
DemandCalendarWrapper.EventViewCard = EventViewCard
DemandCalendarWrapper.EventCard = EventCard
DemandCalendarWrapper.DemandSupplyTable = DemandSupplyTable
DemandCalendarWrapper.TabButton = TabButton
DemandCalendarWrapper.MentorRatingBox = MentorRatingBox
DemandCalendarWrapper.MentorFeature = MentorFeature
DemandCalendarWrapper.MentorProfile = MentorProfile
DemandCalendarWrapper.CustomDatePicker = CustomDatePicker

export default DemandCalendarWrapper
