/* eslint-disable quotes */
import React from 'react'
import styled from 'styled-components'
import { Button, DatePicker, Divider, Input, Select, Switch, TimePicker } from 'antd'
import { MailFilled, PlusOutlined, TagsFilled, WhatsAppOutlined } from '@ant-design/icons'


const EventsStyles = styled.div`
    height: 100%;
    width: 100%;
    margin: 0 auto;
    font-family: 'Inter' !important;
`

const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    .ant-progress-bg{
        background: rgba(1, 170, 147, 1) !important;
    }
`
const Container = styled.div`
    width: 100%;
    height: 85vh;
    background: #FFFFFF;
    padding: 15px;
    overFlow: scroll;
`
const SessionCard = styled.div`
    width: 100%;
    height: 200px;
    padding: 25px 25px;
    display: flex;
    
`

const Card = styled.div`
    width: 48%;
    height: 50%;
`
const Grid = styled.div`
    display: grid;
    grid-template-areas: "img img""img img";
`

const input = styled((props) => <Input {...props} />)`
    width: 100%;
    height: 50px;
`

const datePicker = styled((props) => <DatePicker {...props} />)`
    width: 30%;
    margin-right: 20px;
`

const timePicker = styled((props) => <TimePicker {...props} />)`
    width: 35%;
`
const select = styled((props) => <Select {...props} />)`
    width: 100%;
    height: 50px;
    margin: 20px 0;
`
const EmailSwitch = styled(Switch)`
    &&&{
        margin-top: 15px;
    }
    &.ant-switch-checked {
        background-color: #89c540;
    }
`

const InfoContainer = styled.div`
`
const PageTitle = styled.div`
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0px;
    color: #212121;
    padding: 6px 10px 18px;
`
const ModalTitle = styled.div`
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #212121;
    width: 30%;
    margin: auto;
`
const Subtitle = styled.div`
    font-family: Inter;    
    font-style: normal;
    font-size: 13px;
    letter-spacing: 0px;
    color: #424242;
    padding: 0px 35px;
`
const Smalltitle = styled.span`
    font-family: Inter;    
    font-style: normal;
    font-size: 13px;
    letter-spacing: 0px;
    color: #424242;
    margin-left: 10px;
`
const Heading = styled.div`
    font-family: Inter;    
    font-style: normal;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #424242;
    padding: 0px 35px;
`
const Span = styled.span`
    font-family: Inter;    
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    color: #424242;
    position: absolute;
    top: -35px;
    background: #fff;
    width: 100px;
`
const InputTitle = styled.span`
    font-family: Inter;    
    font-style: normal;
    font-size: 18px;
    font-weight: bold;
    color: #857979d9;
    background-color: #FFFFFF;
    position: absolute;
    top: -38px;
    width: 200px;
`
const InputLabel = styled.div`
    font-family: Inter;    
    font-style: normal;
    font-size: 18px;
    font-weight: bold;
    color: #857979d9;
    width: 400px;
`
const TestButton = styled(Button)`
  &&& {
    color: ${props => props.isTested ? 'rgb(242, 238, 233)' : 'rgb(12,167,137)'};
    background: ${props => props.isTested ? 'rgb(12,167,137)' : 'white'};
    border: 1px solid rgb(133,211,196);
    font-family: Inter;
    font-style: normal;
    font-size: 14px;
    line-height: 120%;
    &:active {
        background: rgb(12,167,137);
        color: #fff;
    }
  }
`
const SubmitButton = styled(Button)`
  &&& {
    color: #fff;
    border: 1px solid ${props => props.back ? props.back : '#673525'};
    background: ${props => props.back ? props.back : '#673525'};
    font-family: Inter;
    font-style: normal;
    font-size: 14px;
    line-height: 120%;
  }
`
const PlusButton = styled(Button)`
  &&& {
    color:  rgb(65, 75, 178);
    background: ${props => props.back ? props.back : 'rgb(217, 219, 240)'};
    border: none;
    font-family: Inter;                                                                                                                                                             
    font-style: normal;                                                                                                                                                                                                                                                                                                                                                                 
    font-size: 14px;                                                                                                                                                                                                                                                                                                
    line-height: 120%;                              
    font-weight: bold;
  }
`
const TempDivider = styled(Divider)`
  &&& {
      border-width: 5px 8px 0;
  }
`
const InfoTitle = styled.div`
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 0px;
    color: #212121;
    padding: 6px 10px 10px;
`
const IndexNumber = styled.div`
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #00ADE6;
    margin: 10px;
`
const Ticket = styled((props) => <TagsFilled {...props} />)`
    margin-right: 10px;
`
const WhatsApp = styled((props) => <WhatsAppOutlined {...props} />)`
    margin-right: 10px;
`
const Email = styled((props) => <MailFilled {...props} />)`
    margin-right: 10px;
`
const Plus = styled((props) => <PlusOutlined {...props} />)`
    color: #533782;
`
const Event = styled.div`
`

const EventsWrapper = styled.div`
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
letter-spacing: 0.4px;
& a:hover {
  color: #605959;
}
.event-calendar-loading-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background: white;
  &.show {
    opacity: 0.6;
    pointer-events: initial;
  }
  pointer-events: none;
  opacity: 0;
  transition: 0.2s all ease-in-out;
}
.event-dashboard-calender-loading-container {
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
position: absolute;
bottom: 0;
left: 0;
z-index: 999;
padding: 12px 20px;
}
.event-dashboard-calender-loading{
font-size: 16px;
font-family: 'Inter';
font-weight: 450;
}
@keyframes indeterminate-scale-animation {
  0% {
    left: 0%;
    width: 0%;
  }
  20% {
    left: 0%;
    width: 30%;
    animation-timing-function: cubic-bezier(.152313,.196432,.648374,1.004315);
  }
  60% {
    left: 70%;
    animation-timing-function: cubic-bezier(.257759,.003163,.211762,1.381790);
    width: 30%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}
.loading-bar-container {
  width: 100%;
  background:#8C61CB;
  height: 5px;
  div {
    height: 100%;
    width: 0%;
    position: relative;
    left: 0;
    background: white;
    animation:indeterminate-scale-animation 1.5s linear infinite;
  }
}
`
Event.Ticket = Ticket
Event.WhatsApp = WhatsApp
Event.Email = Email
Event.Plus = Plus
Event.IndexNumber = IndexNumber
Event.TempDivider = TempDivider
Event.EventsWrapper = EventsWrapper

EventsStyles.FlexContainer = FlexContainer
EventsStyles.Container = Container
EventsStyles.PageTitle = PageTitle
EventsStyles.ModalTitle = ModalTitle

SessionCard.InfoContainer = InfoContainer
SessionCard.InfoTitle = InfoTitle
SessionCard.InputTitle = InputTitle
SessionCard.InputLabel = InputLabel
SessionCard.Subtitle = Subtitle
SessionCard.Smalltitle = Smalltitle
SessionCard.Heading = Heading
SessionCard.Card = Card
SessionCard.input = input
SessionCard.select = select
SessionCard.EmailSwitch = EmailSwitch
SessionCard.TestButton = TestButton
SessionCard.SubmitButton = SubmitButton
SessionCard.PlusButton = PlusButton
SessionCard.Span = Span
SessionCard.Grid = Grid
SessionCard.datePicker = datePicker
SessionCard.timePicker = timePicker

export { SessionCard, Event }
export default EventsStyles