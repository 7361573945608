import { connect } from 'react-redux'
import EventCompletion from './EventCompletion'
import withUpdatedDashboardNav from '../../components/withUpdatedNav'
import { EVENT_DASHBOARD } from '../../constants/navItemsConst'

const EventCompletionNav = withUpdatedDashboardNav(EventCompletion)({
  title: 'Event Overview',
  activeNavItem: 'Events',
  showUMSNavigation: true,
  renderNavItems: EVENT_DASHBOARD,
  noPadding: true
})
const mapStateToProps = (state) => ({
  studentProfileDataLoading: state.data.getIn(['studentProfiles','fetchStatus']),
  studentProfileData: state.data.getIn(['studentProfiles','data']),
  prizeData: state.data.getIn(['eventPrizes','data']),
  prizeDataFetchStatus: state.data.getIn(['eventPrizes','fetchStatus','eventPrizes']),
  winnersData: state.data.getIn(['event','data']),
  fetchWinnersData: state.data.getIn(['event','fetchStatus','event']),
  updateEventWinnerStatus: state.data.getIn(['updateEventWinner','updateStatus']),
  addEventWinnerStatus: state.data.getIn(['addEventWinner','addStatus']),
})


export default connect(mapStateToProps)(EventCompletionNav);

