import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { get } from "lodash";

import addComment from "../../../../actions/SchoolOnboarding/addComment";
import getDataFromLocalStorage from "../../../../utils/extract-from-localStorage";
import addLabInspection from "../../../../actions/SchoolOnboarding/addLabInspection";
import updateLabInspection from "../../../../actions/SchoolOnboarding/updateLabInspection";

const FormContext = React.createContext({
  formData: {},
  setFormData: () => {},
  activeStep: 1,
  setActiveStep: () => {},
  commentSubmitHandler: () => {},
  isAddCommentModalOpen: false,
  openAddCommentModal: () => {},
  closeAddCommentModal: () => {},
  commentLoading: false,
  addLabInspectionHandler: () => {},
  addLabInspectionLoading: false,
});

const FormContextProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  const [activeStep, setActiveStep] = useState(1);
  const [commentLoading, setCommentLoading] = useState(false);
  const [isAddCommentModalOpen, setIsAddCommentModalOpen] = useState(false);
  const [addLabInspectionLoading, setAddLabInspectionLoading] = useState(false);

  const params = useParams();
  const schoolId = get(params, "schoolId", null);

  const loggedInUserId = getDataFromLocalStorage("login.id");

  // add comment modal handlers
  const openAddCommentModal = () => setIsAddCommentModalOpen(true);
  const closeAddCommentModal = () => setIsAddCommentModalOpen(false);

  const commentSubmitHandler = async (values, resetForm) => {
    if (!values.comment) return;

    setCommentLoading(true);
    const payload = {
      comment: values.comment,
      commentType: values.commentType,
      schoolConnectId: schoolId,
      currentUserId: loggedInUserId,
    };

    await addComment(payload);
    setCommentLoading(false);
    resetForm();
    closeAddCommentModal();
  };

  const addLabInspectionHandler = async (step2data) => {
    setAddLabInspectionLoading(true);
    if (!formData) return;
    const payload = {
      totalNumberOfComputers: step2data.totalComputers,
      totalNumberOfWorkingComputers: step2data.workingComputers,
      projectInteractivePanel: step2data.interactivePanel,
      speakers: step2data.speakers,
      powerBackup: step2data.powerBackup,
      powerBackupType: step2data.powerBackupType,
      internetConnection: step2data.internetMode,
      labName: formData.lab.value,
      inspectionDate: formData.inspectionDate.toISOString(),
    };

    if (formData.lab.label === formData.lab.value) {
      await addLabInspection(payload, formData.inspector, schoolId);
      setAddLabInspectionLoading(false);
      return;
    }

    await updateLabInspection(
      formData.lab.value,
      { ...payload, labName: formData.lab.label },
      formData.inspector,
      schoolId
    );
    setAddLabInspectionLoading(false);
  };

  const value = {
    formData,
    setFormData,
    activeStep,
    setActiveStep,
    commentSubmitHandler,
    commentLoading,
    isAddCommentModalOpen,
    openAddCommentModal,
    closeAddCommentModal,
    addLabInspectionHandler,
    addLabInspectionLoading,
  };

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};

const useFormContext = () => {
  const context = React.useContext(FormContext);

  if (context === undefined) {
    throw new Error("useForm must be used within a FormContextProvider");
  }

  return context;
};

export { FormContextProvider, useFormContext };
