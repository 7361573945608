import gql from 'graphql-tag'
import duck from '../../duck'

const removeCertificatePdf = async (eventId, fileId) =>
  duck.query({
    query: gql`
      mutation {
        removeFromBaseCertificateEvent( 
          eventId: "${eventId}"
          fileId: "${fileId}"
        ) {
            fieldName
            typeName
            file {
              id
            }
        }
      }
    `,
    type: 'event/delete',
    key: 'removeFromBaseCertificateEvent',
  })

export default removeCertificatePdf