/* eslint-disable max-len */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Icon, Tooltip } from 'antd'
import { get } from 'lodash'
import TableRowOfAssignTime from './TableRowOfAssignTime'
import TableHeadOfAssignTime from './TableHeadOfAssignTime'
import { Table } from '../../../../components/StyledComponents'
import MainTable from '../../../../components/MainTable'
import getDataFromLocalStorage from '../../../../utils/extract-from-localStorage'
import { ADMIN, MENTOR, UMS_ADMIN } from '../../../../constants/roles'

const AttendanceSvg = () => (
  <svg width='1em' height='1m' viewBox='0 0 512 512' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='219' y='17' width='254' height='191' fill='#E6F7FF' />
    <path d='M467.309 16.7681H221.454C215.326 16.7681 210.359 21.7351 210.359 27.8631V114.314L222.664 106.674C225.795 104.729 229.139 103.417 232.548 102.696V38.9581H456.213V198.974H232.549V173.084L210.359 186.862V210.07C210.359 216.198 215.326 221.165 221.454 221.165H467.309C473.436 221.165 478.404 216.198 478.404 210.07V27.8631C478.404 21.7351 473.436 16.7681 467.309 16.7681Z' fill='#87C5F9' />
    <path d='M306 78.356C303.081 74.654 297.715 74.021 294.014 76.938L255.797 107.071C259.446 109.456 262.647 112.651 265.098 116.598C265.793 117.715 266.396 118.864 266.932 120.029L304.583 90.342C308.285 87.424 308.919 82.057 306 78.356Z' fill='#87C5F9' />
    <path d='M121.537 63.87C139.174 63.87 153.472 49.5722 153.472 31.935C153.472 14.2978 139.174 0 121.537 0C103.899 0 89.6016 14.2978 89.6016 31.935C89.6016 49.5722 103.899 63.87 121.537 63.87Z' fill='#87C5F9' />
    <path d='M252.009 124.728C247.52 117.499 238.022 115.277 230.791 119.765L199.585 139.14C199.455 113.261 199.524 126.995 199.441 110.329C199.34 90.3241 182.983 74.0481 162.977 74.0481H147.818C134.867 107.636 139.039 95.1681 128.046 123.678L132.669 103.547C132.989 102.039 132.757 100.467 132.014 99.1171L125.75 87.7241L131.309 77.6151C132.138 76.1071 131.045 74.2591 129.324 74.2591H114.053C112.333 74.2591 111.238 76.1071 112.068 77.6151L117.638 87.7451L111.362 99.1591C110.634 100.484 110.396 102.024 110.69 103.506L114.695 123.678C112.536 118.079 97.6108 79.3721 95.5578 74.0481H80.0918C60.0868 74.0481 43.7288 90.3231 43.6278 110.329L43.0588 223.529C43.0168 232.039 49.8798 238.972 58.3898 239.015C58.4168 239.015 58.4418 239.015 58.4688 239.015C66.9418 239.015 73.8328 232.167 73.8748 223.684L74.4438 110.484C74.4438 110.466 74.4438 110.448 74.4438 110.431C74.4678 108.751 75.8428 107.405 77.5228 107.418C79.2028 107.43 80.5568 108.796 80.5568 110.476L80.5638 270.857C94.6698 270.257 107.74 275.345 117.545 284.28V221.712H125.528V293.485C131.151 301.753 134.442 311.728 134.442 322.459C134.442 332.236 131.71 341.387 126.973 349.19C131.839 349.213 136.565 349.859 141.072 351.051C147.148 345.78 154.457 341.9 162.509 339.915C162.509 60.5731 162.174 233.288 162.174 110.497C162.174 108.718 163.613 107.276 165.392 107.273C167.171 107.269 168.616 108.705 168.624 110.484C168.678 121.291 168.848 155.074 168.907 166.835C168.935 172.414 171.977 177.543 176.86 180.242C181.734 182.936 187.695 182.796 192.443 179.848L247.047 145.945C254.275 141.458 256.498 131.957 252.009 124.728Z' fill='#87C5F9' />
    <path d='M429.221 356.634C447.89 356.634 463.024 341.5 463.024 322.831C463.024 304.162 447.89 289.028 429.221 289.028C410.552 289.028 395.418 304.162 395.418 322.831C395.418 341.5 410.552 356.634 429.221 356.634Z' fill='#87C5F9' />
    <path d='M511.46 405.811C511.353 384.635 494.039 367.407 472.862 367.407C463.725 367.407 396.279 367.407 388.081 367.407C391.718 374.475 393.785 382.476 393.785 390.957C393.785 399.962 391.38 409.37 386.285 417.739C405.189 418.503 421.753 428.649 431.434 443.636H472.013V406.206C472.013 404.364 473.473 402.854 475.314 402.791C477.155 402.728 478.716 404.136 478.84 405.973C478.84 405.973 478.84 405.974 478.84 405.975L479.03 443.636H511.651L511.46 405.811Z' fill='#87C5F9' />
    <path d='M290.467 390.956C290.467 382.327 292.606 374.193 296.362 367.036C274.353 367.036 248.509 367.036 221.094 367.036C224.566 373.975 226.532 381.792 226.532 390.065C226.532 399.786 223.801 408.991 219.062 416.796C234.62 416.87 248.974 423.334 259.345 434.063C269.399 424.241 283.105 418.149 298.182 418.068C292.947 409.616 290.467 400.126 290.467 390.956Z' fill='#87C5F9' />
    <path d='M264.82 288.655C246.152 288.655 231.016 303.787 231.016 322.458C231.016 341.086 246.123 356.261 264.82 356.261C283.338 356.261 298.623 341.296 298.623 322.458C298.623 303.808 283.518 288.655 264.82 288.655Z' fill='#87C5F9' />
    <path d='M123.216 390.065C123.216 381.813 125.172 374.012 128.627 367.085C127.17 367.013 133.299 367.036 39.1416 367.036C18.0736 367.036 0.650563 384.174 0.543563 405.44L0.351562 443.636C15.2586 443.636 18.2576 443.636 32.9726 443.636L33.1636 405.605C33.1736 403.721 34.7046 402.203 36.5866 402.208C38.4686 402.214 39.9906 403.74 39.9906 405.622V443.636H85.7176C95.5726 427.882 112.518 417.99 130.961 417.23C125.955 409.168 123.216 399.865 123.216 390.065Z' fill='#87C5F9' />
    <path d='M82.7874 288.655C64.1194 288.655 48.9844 303.789 48.9844 322.458C48.9844 341.042 64.0304 356.261 82.7874 356.261C101.323 356.261 116.591 341.246 116.591 322.458C116.591 303.788 101.456 288.655 82.7874 288.655Z' fill='#87C5F9' />
    <path d='M422.532 473.807C422.427 452.629 405.112 435.401 383.935 435.401C381.689 435.401 300.966 435.401 298.428 435.401C277.252 435.401 258.827 452.628 258.72 473.805L258.445 472.914C258.34 451.822 241.104 434.51 219.848 434.51C195.304 434.51 160.053 434.51 134.341 434.51C113.165 434.51 94.7404 451.737 94.6334 472.914L94.4414 512H127.062L127.253 473.078C127.261 471.456 128.58 470.148 130.201 470.152C131.822 470.156 133.133 471.472 133.133 473.093V512.001C152.254 512.001 201.616 512.001 219.525 512.001V473.093C219.525 471.357 220.93 469.949 222.666 469.944C224.401 469.94 225.815 471.341 225.824 473.077L226.015 512C232.684 512 284.253 512 291.149 512L291.34 473.969C291.34 473.969 291.34 473.968 291.34 473.967C291.349 472.346 292.668 471.039 294.289 471.043C295.91 471.047 297.22 472.363 297.22 473.984V512C316.341 512 365.703 512 383.612 512V473.984C383.612 472.248 385.017 470.84 386.753 470.835C388.488 470.831 389.902 472.232 389.911 473.968L390.102 511.999H422.723L422.532 473.807Z' fill='#87C5F9' />
    <path d='M175.932 424.131C194.819 424.131 210.13 408.82 210.13 389.933C210.13 371.046 194.819 355.735 175.932 355.735C157.045 355.735 141.734 371.046 141.734 389.933C141.734 408.82 157.045 424.131 175.932 424.131Z' fill='#87C5F9' />
    <path d='M342.069 425.019C360.956 425.019 376.267 409.708 376.267 390.821C376.267 371.934 360.956 356.623 342.069 356.623C323.182 356.623 307.871 371.934 307.871 390.821C307.871 409.708 323.182 425.019 342.069 425.019Z' fill='#87C5F9' />
  </svg>
)

const BatchMappingSvg = () => (
  <svg width='1em' height='1em' viewBox='0 0 512 512' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='86' cy='355' r='32' fill='#E6F7FF' />
    <circle cx='258' cy='355' r='32' fill='#E6F7FF' />
    <circle cx='430' cy='355' r='32' fill='#E6F7FF' />
    <rect x='58' y='23' width='383' height='254' fill='#E6F7FF' />
    <path d='M334.701 36.8085H296.509C294.674 36.8085 292.914 36.0796 291.616 34.782C290.319 33.4845 289.59 31.7246 289.59 29.8896C289.59 28.0546 290.319 26.2948 291.616 24.9972C292.914 23.6997 294.674 22.9707 296.509 22.9707H334.701C336.536 22.9707 338.296 23.6997 339.594 24.9972C340.891 26.2948 341.62 28.0546 341.62 29.8896C341.62 31.7246 340.891 33.4845 339.594 34.782C338.296 36.0796 336.536 36.8085 334.701 36.8085Z' fill='#87C5F9' />
    <path d='M446.756 277.033H65.247C63.412 277.033 61.6522 276.304 60.3546 275.007C59.0571 273.709 58.3281 271.95 58.3281 270.115V29.8896C58.3281 28.0546 59.0571 26.2948 60.3546 24.9972C61.6522 23.6997 63.412 22.9707 65.247 22.9707H181.762C183.597 22.9707 185.357 23.6997 186.654 24.9972C187.952 26.2948 188.681 28.0546 188.681 29.8896C188.681 31.7246 187.952 33.4845 186.654 34.782C185.357 36.0796 183.597 36.8085 181.762 36.8085H72.166V263.196H439.837V36.8085H371.755C369.92 36.8085 368.16 36.0796 366.863 34.782C365.565 33.4845 364.836 31.7246 364.836 29.8896C364.836 28.0546 365.565 26.2948 366.863 24.9972C368.16 23.6997 369.92 22.9707 371.755 22.9707H446.756C448.591 22.9707 450.351 23.6997 451.649 24.9972C452.946 26.2948 453.675 28.0546 453.675 29.8896V270.115C453.675 271.95 452.946 273.709 451.649 275.007C450.351 276.304 448.591 277.033 446.756 277.033Z' fill='#87C5F9' />
    <path d='M255.999 36.8085H219.813C217.978 36.8085 216.219 36.0796 214.921 34.782C213.623 33.4845 212.895 31.7246 212.895 29.8896C212.895 28.0546 213.623 26.2948 214.921 24.9972C216.219 23.6997 217.978 22.9707 219.813 22.9707H255.999C257.834 22.9707 259.594 23.6997 260.892 24.9972C262.189 26.2948 262.918 28.0546 262.918 29.8896C262.918 31.7246 262.189 33.4845 260.892 34.782C259.594 36.0796 257.834 36.8085 255.999 36.8085Z' fill='#87C5F9' />
    <path d='M491.242 489.002H359.88C358.045 489.002 356.285 488.273 354.987 486.975C353.69 485.678 352.961 483.918 352.961 482.083V449.239C352.974 438.699 357.166 428.594 364.619 421.141C372.072 413.688 382.177 409.495 392.717 409.482H458.398C468.94 409.493 479.046 413.685 486.5 421.138C493.955 428.592 498.149 438.697 498.161 449.239V482.083C498.161 483.918 497.432 485.678 496.135 486.975C494.837 488.273 493.077 489.002 491.242 489.002ZM366.799 475.164H484.324V449.239C484.314 442.366 481.58 435.778 476.72 430.919C471.86 426.06 465.271 423.328 458.398 423.32H392.717C385.845 423.328 379.257 426.061 374.398 430.92C369.539 435.779 366.806 442.367 366.799 449.239V475.164Z' fill='#87C5F9' />
    <path d='M425.561 395.831C417.317 395.833 409.258 393.389 402.403 388.81C395.548 384.231 390.204 377.721 387.049 370.105C383.894 362.489 383.068 354.108 384.676 346.022C386.284 337.936 390.253 330.509 396.083 324.68C401.912 318.85 409.339 314.881 417.425 313.273C425.511 311.665 433.892 312.491 441.508 315.646C449.124 318.802 455.633 324.145 460.213 331C464.792 337.855 467.236 345.915 467.234 354.159C467.219 365.206 462.824 375.798 455.012 383.609C447.2 391.421 436.609 395.817 425.561 395.831V395.831ZM425.561 326.317C420.055 326.316 414.671 327.947 410.092 331.006C405.512 334.064 401.943 338.412 399.834 343.499C397.726 348.587 397.173 354.185 398.247 359.586C399.32 364.987 401.972 369.949 405.865 373.843C409.759 377.738 414.719 380.39 420.12 381.465C425.521 382.54 431.12 381.989 436.208 379.882C441.295 377.775 445.644 374.206 448.704 369.627C451.763 365.049 453.396 359.666 453.396 354.159C453.389 346.778 450.454 339.702 445.236 334.482C440.018 329.263 432.942 326.326 425.561 326.317V326.317Z' fill='#87C5F9' />
    <path d='M457.063 489.002H394.06C392.225 489.002 390.465 488.273 389.167 486.975C387.87 485.678 387.141 483.918 387.141 482.083V457.341C387.141 455.506 387.87 453.746 389.167 452.448C390.465 451.151 392.225 450.422 394.06 450.422C395.895 450.422 397.654 451.151 398.952 452.448C400.25 453.746 400.978 455.506 400.978 457.341V475.136H450.144V457.341C450.144 455.506 450.873 453.746 452.171 452.448C453.468 451.151 455.228 450.422 457.063 450.422C458.898 450.422 460.658 451.151 461.956 452.448C463.253 453.746 463.982 455.506 463.982 457.341V482.055C463.986 482.966 463.81 483.869 463.463 484.711C463.117 485.554 462.608 486.32 461.965 486.965C461.323 487.611 460.559 488.123 459.717 488.472C458.876 488.822 457.974 489.002 457.063 489.002Z' fill='#87C5F9' />
    <path d='M321.68 489.002H190.317C188.482 489.002 186.722 488.273 185.425 486.975C184.127 485.678 183.398 483.918 183.398 482.083V449.239C183.411 438.699 187.604 428.594 195.057 421.141C202.51 413.688 212.614 409.495 223.155 409.482H288.843C299.383 409.495 309.487 413.688 316.94 421.141C324.393 428.594 328.586 438.699 328.599 449.239V482.083C328.599 483.918 327.87 485.678 326.572 486.975C325.275 488.273 323.515 489.002 321.68 489.002ZM197.236 475.164H314.761V449.239C314.754 442.367 312.021 435.779 307.162 430.92C302.303 426.061 295.714 423.328 288.843 423.32H223.155C216.283 423.328 209.695 426.061 204.836 430.92C199.977 435.779 197.244 442.367 197.236 449.239V475.164Z' fill='#87C5F9' />
    <path d='M255.999 395.831C247.755 395.833 239.696 393.389 232.84 388.81C225.985 384.231 220.642 377.721 217.487 370.105C214.331 362.489 213.505 354.108 215.113 346.022C216.721 337.936 220.691 330.509 226.52 324.68C232.35 318.85 239.777 314.881 247.863 313.273C255.948 311.665 264.329 312.491 271.945 315.646C279.562 318.802 286.071 324.145 290.65 331C295.23 337.855 297.673 345.915 297.672 354.159C297.659 365.207 293.264 375.799 285.452 383.612C277.64 391.424 267.047 395.818 255.999 395.831V395.831ZM255.999 326.317C250.492 326.316 245.109 327.947 240.529 331.006C235.95 334.064 232.38 338.412 230.272 343.499C228.163 348.587 227.611 354.185 228.684 359.586C229.758 364.987 232.409 369.949 236.303 373.843C240.196 377.738 245.157 380.39 250.558 381.465C255.959 382.54 261.557 381.989 266.645 379.882C271.733 377.775 276.082 374.206 279.141 369.627C282.201 365.049 283.834 359.666 283.834 354.159C283.828 346.777 280.894 339.7 275.676 334.48C270.457 329.26 263.38 326.324 255.999 326.317V326.317Z' fill='#87C5F9' />
    <path d='M287.501 489.002H224.497C222.662 489.002 220.902 488.273 219.605 486.975C218.307 485.678 217.578 483.918 217.578 482.083V457.341C217.578 455.506 218.307 453.746 219.605 452.448C220.902 451.151 222.662 450.422 224.497 450.422C226.332 450.422 228.092 451.151 229.389 452.448C230.687 453.746 231.416 455.506 231.416 457.341V475.136H280.582V457.341C280.582 455.506 281.311 453.746 282.608 452.448C283.906 451.151 285.666 450.422 287.501 450.422C289.336 450.422 291.096 451.151 292.393 452.448C293.691 453.746 294.42 455.506 294.42 457.341V482.055C294.423 482.966 294.247 483.869 293.901 484.711C293.555 485.554 293.046 486.32 292.403 486.965C291.76 487.611 290.996 488.123 290.155 488.472C289.314 488.822 288.412 489.002 287.501 489.002Z' fill='#87C5F9' />
    <path d='M152.117 489.002H20.7549C18.9198 489.002 17.16 488.273 15.8624 486.975C14.5649 485.678 13.8359 483.918 13.8359 482.083V449.239C13.8488 438.697 18.0424 428.592 25.4968 421.138C32.9513 413.685 43.0577 409.493 53.599 409.482H119.28C129.82 409.495 139.925 413.688 147.378 421.141C154.831 428.594 159.024 438.699 159.036 449.239V482.083C159.036 483.918 158.307 485.678 157.01 486.975C155.712 488.273 153.952 489.002 152.117 489.002V489.002ZM27.6738 475.164H145.199V449.239C145.191 442.367 142.458 435.779 137.599 430.92C132.74 426.061 126.152 423.328 119.28 423.32H53.599C46.7266 423.328 40.1378 426.06 35.2776 430.919C30.4175 435.778 27.6829 442.366 27.6738 449.239V475.164Z' fill='#87C5F9' />
    <path d='M86.4374 395.831C78.1937 395.831 70.1351 393.387 63.2808 388.807C56.4264 384.226 51.0843 377.717 47.9298 370.1C44.7754 362.484 43.9504 354.103 45.5592 346.018C47.168 337.933 51.1383 330.506 56.9679 324.677C62.7976 318.849 70.2249 314.88 78.3104 313.272C86.3959 311.665 94.7765 312.491 102.392 315.647C110.008 318.803 116.517 324.146 121.096 331.001C125.675 337.856 128.118 345.915 128.117 354.159C128.104 365.208 123.709 375.801 115.895 383.614C108.081 391.427 97.4869 395.82 86.4374 395.831V395.831ZM86.4374 326.317C80.9308 326.317 75.5479 327.95 70.9694 331.009C66.3908 334.068 62.8223 338.417 60.715 343.504C58.6077 348.592 58.0564 354.19 59.1306 359.59C60.2049 364.991 62.8566 369.952 66.7503 373.846C70.6441 377.739 75.605 380.391 81.0057 381.465C86.4065 382.54 92.0046 381.988 97.092 379.881C102.179 377.774 106.528 374.205 109.587 369.627C112.646 365.048 114.279 359.665 114.279 354.159C114.274 346.776 111.339 339.698 106.118 334.478C100.898 329.258 93.8198 326.322 86.4374 326.317V326.317Z' fill='#87C5F9' />
    <path d='M117.938 489.002H54.9345C53.0995 489.002 51.3397 488.273 50.0421 486.975C48.7446 485.678 48.0156 483.918 48.0156 482.083V457.341C48.0156 455.506 48.7446 453.746 50.0421 452.448C51.3397 451.151 53.0995 450.422 54.9345 450.422C56.7696 450.422 58.5294 451.151 59.827 452.448C61.1245 453.746 61.8535 455.506 61.8535 457.341V475.136H111.019V457.341C111.019 455.506 111.748 453.746 113.046 452.448C114.343 451.151 116.103 450.422 117.938 450.422C119.773 450.422 121.533 451.151 122.831 452.448C124.128 453.746 124.857 455.506 124.857 457.341V482.055C124.861 482.966 124.685 483.869 124.338 484.711C123.992 485.554 123.483 486.32 122.84 486.965C122.198 487.611 121.434 488.123 120.592 488.472C119.751 488.822 118.849 489.002 117.938 489.002Z' fill='#87C5F9' />
    <path d='M386.704 93.3569H125.294C123.459 93.3569 121.699 92.6279 120.402 91.3304C119.104 90.0328 118.375 88.273 118.375 86.438C118.375 84.603 119.104 82.8431 120.402 81.5455C121.699 80.248 123.459 79.519 125.294 79.519H386.704C388.539 79.519 390.299 80.248 391.597 81.5455C392.894 82.8431 393.623 84.603 393.623 86.438C393.623 88.273 392.894 90.0328 391.597 91.3304C390.299 92.6279 388.539 93.3569 386.704 93.3569Z' fill='#87C5F9' />
    <path d='M386.704 156.942H125.294C123.459 156.942 121.699 156.213 120.402 154.915C119.104 153.618 118.375 151.858 118.375 150.023C118.375 148.188 119.104 146.428 120.402 145.131C121.699 143.833 123.459 143.104 125.294 143.104H386.704C388.539 143.104 390.299 143.833 391.597 145.131C392.894 146.428 393.623 148.188 393.623 150.023C393.623 151.858 392.894 153.618 391.597 154.915C390.299 156.213 388.539 156.942 386.704 156.942Z' fill='#87C5F9' />
    <path d='M386.706 220.534H241.872C240.037 220.534 238.277 219.805 236.98 218.507C235.682 217.21 234.953 215.45 234.953 213.615C234.953 211.78 235.682 210.02 236.98 208.722C238.277 207.425 240.037 206.696 241.872 206.696H386.706C388.541 206.696 390.301 207.425 391.598 208.722C392.896 210.02 393.625 211.78 393.625 213.615C393.625 215.45 392.896 217.21 391.598 218.507C390.301 219.805 388.541 220.534 386.706 220.534Z' fill='#87C5F9' />
  </svg>
)

const BatchMappingIcon = props => <Icon component={BatchMappingSvg} {...props} />

const AttendanceIcon = props => <Icon component={AttendanceSvg} {...props} />
class TableOfAssignTime extends Component {
  render() {
    const columnsTemplate = ' minmax(50px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(100px, 0.7fr) minmax(100px, 0.7fr) minmax(100px, 1fr) minmax(100px, 0.7fr) minmax(100px, 1.2fr) minmax(100px, 1.2fr) minmax(100px, 1.2fr) minmax(100px, 1.2fr) minmax(50px, 1.2fr)'
    const minWidth = '1200px'
    const rowLayoutProps = {
      minWidth,
      columnsTemplate
    }
    const { currentBatchCode, batchSessionsData, adhocSessionsData, mentorUserId, dateSelected, isFetchingBatchSessions, isUpdatingBatchSession, hasUpdatedBatchSession, paramBatchCode, hasAddedBatchSession, hasDeletedBatchSession, documentType } = this.props
    let selectedBatchCode = ''
    if (currentBatchCode && paramBatchCode) {
      selectedBatchCode = currentBatchCode
    } else if (currentBatchCode) {
      selectedBatchCode = currentBatchCode
    } else if (paramBatchCode) {
      selectedBatchCode = paramBatchCode
    }
    const combinedSessionsData = batchSessionsData.concat(adhocSessionsData)
    const sortedCombinedSessionsData = combinedSessionsData.sort((a, b) => {
      const keyA = new Date(get(a, 'bookingDate'))
      const keyB = new Date(get(b, 'bookingDate'))
      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
      return 0
    })
    return (
      <Table style={{ width: '100%', overflowX: 'scroll' }}>
        <TableHeadOfAssignTime {...rowLayoutProps} />
        {isFetchingBatchSessions && !hasUpdatedBatchSession && !hasAddedBatchSession && !hasDeletedBatchSession ?
          <div
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}
          >
            <Icon type='loading' spin style={{ fontSize: '60px' }} />
          </div>
          :
          <div style={{ padding: '10px' }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              padding: '10px'
            }}
            >
              <div
                style={{
                  padding: '5px',
                  backgroundColor: '#0880ae',
                  width: '15%',
                  color: '#FFFFFF',
                  textAlign: 'center',
                  marginRight: '10px'
                }}
              >
                {selectedBatchCode}
                {
                  currentBatchCode || paramBatchCode ?
                    <Link
                      rel='noopener noreferrer'
                      to={`/sms/batchMapping/${selectedBatchCode}`}
                    >
                      <Tooltip
                        placement='top'
                        title='Click to View Batch-User Mapping'
                      >
                        <BatchMappingIcon
                          style={{ color: '#fff', marginLeft: '.75rem', fontSize: '20px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', flex: 1 }}
                        />
                      </Tooltip>
                    </Link> : <div />
                }
                {
                  currentBatchCode || paramBatchCode ?
                    <Link
                      rel='noopener noreferrer'
                      to={`/sms/batchAttendance/${selectedBatchCode}`}
                    >
                      <Tooltip
                        placement='top'
                        title='Click to View Attendance'
                      >
                        <AttendanceIcon
                          style={{ color: '#fff', marginLeft: '.75rem', fontSize: '20px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', flex: 1 }}
                        />
                      </Tooltip>
                    </Link> : <div />
                }
              </div>
              {documentType !== 'classroom' ? (
                <Button
                  style={{ marginRight: '10px' }}
                  type='primary'
                  onClick={() => this.props.showModal('add')}
                  disabled={!currentBatchCode && !paramBatchCode}
                >
                  Add TimeTable
                </Button> 
              ) : null}
              {
                (getDataFromLocalStorage('login.role') === ADMIN
                  || getDataFromLocalStorage('login.role') === UMS_ADMIN
                  || getDataFromLocalStorage('login.role') === MENTOR)
                  && documentType !== 'classroom'
                  ?
                  <Button
                    type='primary'
                    onClick={() => this.props.showModal('addAdhoc')}
                    disabled={!currentBatchCode && !paramBatchCode}
                  >
                    Add Adhoc
                  </Button>
                  : <div />
              }
            </div>
            {(!this.props.hasFetchedBatchSessions && !this.props.hasAddedBatchSession && !this.props.hasUpdatedBatchSession && !this.props.hasDeletedBatchSession) ?
              <MainTable.Item>
                {
                  getDataFromLocalStorage('login.role') === MENTOR ? <h3>Select Batch Code</h3> : <h3>Select Mentor and Batch Code</h3>
                }
              </MainTable.Item>
              :
              sortedCombinedSessionsData.map((data, index) => <TableRowOfAssignTime
                data={data}
                columnsTemplate={columnsTemplate}
                minWidth={minWidth}
                showModal={this.props.showModal}
                topic={this.props.topic}
                index={index}
                mentorUserId={mentorUserId}
                dateSelected={dateSelected}
                isFetchingBatchSessions={isFetchingBatchSessions}
                isUpdatingBatchSession={isUpdatingBatchSession}
                hasUpdatedBatchSession={hasUpdatedBatchSession}
              />)}
          </div>
        }
      </Table>
    )
  }
}

export default TableOfAssignTime
