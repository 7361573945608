import { Icon } from 'antd'
import React from 'react'
import { Table } from '../../../components/StyledComponents'
import SchoolOverviewTableStyle from '../SchoolOverviewTable.style'
import SchoolOverviewTableHead from './SchoolOverviewTableHead'
import SchoolOverviewTableRow from './SchoolOverviewTableRow'

const SchoolOverviewTableMain = (props) => {
  const columnsTemplate = 'minmax(300px,1fr) minmax(150px,1fr) minmax(150px,1fr) minmax(290px,1fr) minmax(50px,1fr) minmax(100px,1fr) minmax(50px,1fr) minmax(320px,1fr) minmax(100px,1fr)'
  const minWidth = '1700px'
  const rowLayoutProps = {
    minWidth,
    columnsTemplate
  }
  const { showLoading,
    schoolsData,
    showModal,
    fetchSchoolData
  } = props
  return (
    <Table style={{ width: '100%', overflowX: 'scroll' }}>
      <SchoolOverviewTableHead {...rowLayoutProps} />
      {
        showLoading === true ?
          <SchoolOverviewTableStyle.IconContainer>
            <Icon type='loading' style={{ fontSize: '40px' }} spin />
          </SchoolOverviewTableStyle.IconContainer>
          :
          schoolsData.map((data, index) => <SchoolOverviewTableRow
            key={data.id}
            columnsTemplate={columnsTemplate}
            backgroundColor={
              (index % 2 === 0) ?
                '#daead259 !important'
                :
                '#ffffff !important'
            }
            minWidth={minWidth}
            data={data}
            index={index}
            showModal={showModal}
            fetchSchoolData={fetchSchoolData}
          />)
      }
    </Table>
  )
}

export default SchoolOverviewTableMain
