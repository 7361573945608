import React from 'react'
import moment from 'moment'
import { get } from 'lodash'
import { Card, Avatar } from 'antd'
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons'
import StudentIcon from '../../../assets/studentIcon.png'
import getFullPath from '../../../utils/getFullPath'
import './antdTableStyle.scss'

const { Meta } = Card;

const CardPreview = (props) => {
    return (
        <div>
            <Card
                style={{ width: '300px', border: '1px solid #e8e8e8', borderRadius: '10px' }}
                cover={
                    <img
                        style={{ width: '297px', height: '180px', objectFit: 'contain' }}
                        alt="example"
                        src={getFullPath(get(props, 'data.listingImage.uri')) || "https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"}
                    />
                }
            >
                <div className="trending">
                    🔥 TRENDING
                </div>
                <div className="CardAvatar" style={{ width: '160px' }}>
                    <Avatar style={{ width: '17%', height: '17%', position: 'relative', left: '-35px', border: '2px solid white' }} src={StudentIcon} />
                    <Avatar style={{ width: '17%', height: '17%', position: 'relative', left: '-70px', border: '2px solid white' }} src={StudentIcon} />
                    <p style={{ width: '95px', left: '0px' }}>{`${get(props, 'data.registeredUsersMeta.count') || ''} Participants`}</p>
                </div>
                <div className="desc" >
                    <h1>{get(props, 'data.name')}</h1>
                    <div className="calender"><CalendarOutlined /><p>{moment(get(props, 'data.selectedDateRange.startDate')).format('Do MMM') || '17th Nov'}</p></div>
                    <div className="clock"><ClockCircleOutlined />
                        <p>
                            {`${moment(get(props, 'data.timeValues.startTime')).set('minutes', 0).format('hh:mm a')} to 
                                ${moment(get(props, 'data.timeValues.endTime')).set('minutes', 0).format('hh:mm a')}` || '5:00 pm to 6:00 pm'}
                        </p>
                    </div>
                </div>
                <div className="tags">
                    {
                        get(props, 'data.tags', []).length > 0 ? (
                            get(props, 'data.tags').map((tag, index) => {
                                if (index < 4) {
                                    return (
                                        <p className="tag">{tag.title}</p>
                                    )
                                }
                                else {
                                    return (<></>)
                                }
                            })
                        ) : (
                            <>
                                <p className="tag">Web Development </p>
                                <p className="tag">HTML </p>
                                <p className="tag">CSS </p>
                            </>
                        )
                    }
                </div>
            </Card>
        </div>
    )
}

export default CardPreview;