/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import { ceil, get, debounce } from "lodash";
import Blockly from "blockly";
import { BlocklyWorkspace } from "tekie-blockly";
import { Checkbox, Select, Radio, Tooltip } from "antd";
import { Input, PublishInput } from "./FormElements";
import Dropzone from "../../../../../components/Dropzone";
import { FlexContainer, StyledButton } from "../../ContentPractice.styles";
import getFullPath from "../../../../../utils/getFullPath";
import { getSelectedValues } from "../../../../../utils/data-utils";
import { onAddIframeLink, projectValidation } from "../../../contentUtils";
import { removeFromCourseComponent } from "../../../../../actions/contentMaker";
import { PRACTICE } from "../../../../../constants/CourseComponents";
import { ContentEditor } from "../../../../../components/ContentEditor";
import {
  encodeBase64,
  isBase64,
  decodeBase64,
} from "../../../../../utils/base64Utility";
import getDecodedStatement from "../../../../../utils/getDecodedStatement";
import { BLOCKLY_PLAYGROUND_THEME } from "../../../../../utils/blocklyUtils";
import gql from "graphql-tag";
import requestToGraphql from "../../../../../utils/requestToGraphql";

const GET_GSUIT_DETAILS = (id) => gql`
  query {
    getGsuiteFileOrFolderDetails(id:"${id}")
    {
      id
      name
      createdTime
    }
  }
`;

const EditPracticeForm = (props) => {
  const {
    handleEditPractice,
    editFormData,
    practiceUpdateStatus,
    orderInUse,
    coursesFetchStatus,
    coursesList,
    answerFormatOptions,
  } = props;

  const externalPlatformLogoRef = useRef();
  const [externalPlatformLogo, setExternalPlatformLogo] = useState(null);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [currentCheckedLink, setCurrentCheckedLink] = useState(4);
  const [currentCheckedBlock, setCurrentCheckedBlock] = useState(3);
  const [currentCheckedGSuitType, setCurrentCheckedGSuitType] = useState();
  const [
    currentCheckedGSuitCreationType,
    setCurrentCheckedGSuitCreationType,
  ] = useState();
  const [currentAnswerFormat, setCurrentAnswerFormat] = useState("");
  const [xmlData, setXmlData] = React.useState("");
  const [gsuiteTempleteURLError, setGsuiteTempleteURLError] = useState("");
  const [inputLinkChangeOfCreateCTA, setInputLinkChangeOfCreateCTA] = useState(
    ""
  );
  const [
    gsuiteTempleteURLSuceessMessage,
    setGsuiteTempleteURLSuceessMessage,
  ] = useState("");
  const [isDataFetching, setIsDataFetching] = useState(true);
  var init = 1;
  const onDropPlatformLogo = (file) => {
    setExternalPlatformLogo(file);
  };
  const editFormRef = useRef();

  const roboticsLayoutOptions = [
    { label: "Maker Board", value: "mkbSim" },
    { label: "A-Bot: Grade 3 Session 1", value: "uoloAbotAdvSim_g3s1" },
    { label: "A-Bot: Grade 3 Session 2", value: "uoloAbotAdvSim_g3s2" },
    { label: "A-Bot: Grade 3 Session 3", value: "uoloAbotAdvSim_g3s3" },
    { label: "A-Bot: Grade 3 Session 4", value: "uoloAbotAdvSim_g3s4" },
    { label: "A-Bot: Grade 3 Session 5", value: "uoloAbotAdvSim_g3s5" },
    { label: "A-Bot: Grade 3 Session 6", value: "uoloAbotAdvSim_g3s6" },
    { label: "A-Bot: Grade 4 Session 1", value: "uoloAbotAdvSim_g4s1" },
    { label: "A-Bot: Grade 4 Session 2", value: "uoloAbotAdvSim_g4s2" },
    { label: "A-Bot: Grade 4 Session 3", value: "uoloAbotAdvSim_g4s3" },
    { label: "A-Bot: Grade 4 Session 4", value: "uoloAbotAdvSim_g4s4" },
    { label: "A-Bot: Grade 4 Session 5", value: "uoloAbotAdvSim_g4s5" },
    { label: "A-Bot: Grade 4 Session 6", value: "uoloAbotAdvSim_g4s6" },
    { label: "A-Bot: Grade 5 Session 1", value: "uoloAbotAdvSim_g5s1" },
    { label: "A-Bot: Grade 5 Session 2", value: "uoloAbotAdvSim_g5s2" },
    { label: "A-Bot: Grade 5 Session 3", value: "uoloAbotAdvSim_g5s3" },
    { label: "A-Bot: Grade 5 Session 4", value: "uoloAbotAdvSim_g5s4" },
    { label: "A-Bot: Grade 5 Session 5", value: "uoloAbotAdvSim_g5s5" },
    { label: "A-Bot: Grade 5 Session 6", value: "uoloAbotAdvSim_g5s6" },
  ];

  useEffect(() => {
    const newSelectedCourse = getSelectedValues(
      editFormData,
      coursesList,
      "courses"
    );
    setSelectedCourses(newSelectedCourse);
    const {
      layout,
      initialBlocks,
      gsuiteFileType,
      gsuiteTempleteURL,
    } = editFormData;
    if (layout === "gsuite") {
      gsuiteFileType === "document"
        ? setCurrentCheckedGSuitType(1)
        : gsuiteFileType === "presentation"
        ? setCurrentCheckedGSuitType(2)
        : setCurrentCheckedGSuitType(3);
      gsuiteTempleteURL
        ? setCurrentCheckedGSuitCreationType(1)
        : setCurrentCheckedGSuitCreationType(2);
    }
    layout === "externalPlatform"
      ? setCurrentCheckedLink(1)
      : layout === "fileUpload"
      ? setCurrentCheckedLink(3)
      : layout === "playground"
      ? setCurrentCheckedLink(2)
      : layout === "gsuite"
      ? setCurrentCheckedLink(4)
      : layout === "mit"
      ? setCurrentCheckedLink(5)
      : layout === "scratch"
      ? setCurrentCheckedLink(6)
      : layout === "mkbSim" || layout.includes("uoloAbotAdvSim")
      ? setCurrentCheckedLink(7)
      : null;
    initialBlocks ? setCurrentCheckedBlock(1) : setCurrentCheckedBlock(2);
    initialBlocks ? setXmlData(decodeBase64(initialBlocks)) : null;
    const answerFormatTemp = get(editFormData, "answerFormat", "");
    if (answerFormatTemp === answerFormatOptions[0].key) {
      editFormData.answerGoogleEmbedLink = editFormData.answerFormatDescription;
    } else if (answerFormatTemp === answerFormatOptions[1].key) {
      editFormData.answerContent = editFormData.answerFormatDescription;
    }
    setCurrentAnswerFormat(answerFormatTemp);
    setInputLinkChangeOfCreateCTA(editFormData.externalPlatformLink);
  }, [editFormData.id]);

  useEffect(() => {
    if (currentCheckedLink !== 4) {
      setCurrentCheckedGSuitCreationType(0);
    }
  }, [currentCheckedLink]);

  const validateGSuitTempleteURL = async (gsuiteTempleteURL) => {
    let isValidated = false;
    if (gsuiteTempleteURL && !gsuiteTempleteURL.includes("docs.google.com")) {
      setGsuiteTempleteURLError("Wrong URL");
      return false;
    }
    let splittingUrl;
    if (gsuiteTempleteURL) {
      splittingUrl = gsuiteTempleteURL.split("/");
    }
    let id = "";
    if (gsuiteTempleteURL && gsuiteTempleteURL.includes("https")) {
      id = splittingUrl[5];
    } else if (gsuiteTempleteURL && !gsuiteTempleteURL.includes("https")) {
      id = splittingUrl[3];
    }
    setIsDataFetching(false);
    if (gsuiteTempleteURL) {
      await requestToGraphql(GET_GSUIT_DETAILS(id))
        .then((gsuiteFileDetails) => {
          if (gsuiteFileDetails) {
            setGsuiteTempleteURLError("");
            setGsuiteTempleteURLSuceessMessage("This link is validated.");
            isValidated = true;
          }
        })
        .catch((err) => {
          if (err) {
            isValidated = false;
            setGsuiteTempleteURLError(
              "Wrong url or not have enough permission"
            );
            setGsuiteTempleteURLSuceessMessage("");
          }
        });
    }
    setIsDataFetching(true);
    return isValidated;
  };

  const handleSubmit = async (value, meta) => {
    if (value.layout === "playground") {
      value.externalPlatformLink = "";
      value.answerDescription = "";
    }
    if (
      value.layout === "gsuite" ||
      value.layout === "fileUpload" ||
      value.layout === "mit" ||
      value.layout === "scratch" ||
      value.layout === "mkbSim" ||
      value.layout.includes("uoloAbotAdvSim")
    ) {
      value.isSubmitAnswer = true;
    }
    value.layout === "externalPlatform" || currentCheckedBlock == 2
      ? (value.initialBlocks = "")
      : null;
    value.answerFormat = currentAnswerFormat;
    if (value.answerFormat === answerFormatOptions[0].key) {
      value.answerFormatDescription = value.answerGoogleEmbedLink;
    } else if (value.answerFormat === answerFormatOptions[1].key) {
      value.answerFormatDescription = value.answerContent;
    }
    if (currentCheckedLink === 4 && currentCheckedGSuitCreationType === 1) {
      if (value.externalPlatformLink.includes("https"))
        value.gsuiteTempleteURL = value.externalPlatformLink;
      else value.gsuiteTempleteURL = "https://" + value.externalPlatformLink;
      value.gsuiteFileType = "";
    } else {
      value.gsuiteTempleteURL = "";
    }
    const updatedValues = {};
    for (const item in value) {
      if (
        item !== answerFormatOptions[0].key &&
        item !== answerFormatOptions[1].key
      ) {
        updatedValues[item] = value[item];
      }
    }
    handleEditPractice(
      updatedValues,
      meta,
      externalPlatformLogo,
      selectedCourses
    );
  };

  const onSelect = (value) => {
    setSelectedCourses([...selectedCourses, value]);
  };

  const onDeselect = (value) => {
    const newCourse = selectedCourses.filter(
      (course) => get(course, "key") !== get(value, "key")
    );
    const addedCourses = get(editFormData, "courses", []).map((course) =>
      get(course, "id")
    );
    if (addedCourses.includes(get(value, "key"))) {
      removeFromCourseComponent(
        get(value, "key"),
        get(editFormData, "id"),
        PRACTICE
      );
    }
    setSelectedCourses(newCourse);
  };
  const checkTextType = (value) => {
    if (isBase64(value)) {
      return getDecodedStatement(value);
    } else return value;
  };

  const onLinkChange = (value) => {
    setCurrentCheckedLink(value.target.value);
    if (value.target.value === 1) {
      setCurrentCheckedBlock(3);
    }
  };

  const onBlockChange = (value) => {
    setCurrentCheckedBlock(value.target.value);
  };

  const onGSuitCreationChange = (value) => {
    if (value.target.value === 2) {
      setGsuiteTempleteURLError("");
    }
    setCurrentCheckedGSuitCreationType(value.target.value);
  };

  const onFileTypeChange = (value) => {
    setCurrentCheckedGSuitType(value.target.value);
  };

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const radioStyleInside = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
    marginLeft: "20px",
  };

  const saveXML = (workspace) => {
    var xml = Blockly.Xml.workspaceToDom(Blockly.mainWorkspace);
    var xml_text = Blockly.Xml.domToText(xml);
    setXmlData(xml_text);
    return xml_text;
    // var xml_got = Blockly.Xml.textToDom(xml_text)
  };

  const workspaceConfiguration = {
    readOnly: false,
    grid: false,
    move: {
      scrollbars: {
        horizontal: true,
        vertical: true,
      },
      drag: true,
      wheel: true,
    },
    zoom: {
      controls: true,
      wheel: true,
      startScale: 1.0,
      maxScale: 2,
      minScale: 0.3,
      scaleSpeed: 1.2,
      pinch: true,
    },
    trashcan: true,
  };
  const handleOnChange = (e, handleChange = () => {}) => {
    handleChange(e);
    setInputLinkChangeOfCreateCTA(e.target.value.trim());
    if (currentCheckedLink === 4 && currentCheckedGSuitCreationType === 1) {
      debounce(async () => {
        if (e.target && e.target.value) {
          await validateGSuitTempleteURL(e.target.value.trim());
        }
      }, 1000)();
    }
  };

  const filterOption = (input, option) =>
    get(option, "props.children.props.children")
      ? get(option, "props.children.props.children")
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      : false;

  return (
    <Formik
      initialValues={editFormData}
      onSubmit={handleSubmit}
      validateOnBlur
      validationSchema={projectValidation}
      innerRef={editFormRef}
    >
      {({ values, handleChange, setFieldValue }) => (
        <Form style={{ padding: "0 10px", letterSpacing: "0" }} id="form">
          <FlexContainer
            style={{ alignItems: "flex-start", justifyContent: "flex-start" }}
          >
            <div style={{ flex: "0.6" }}>
              <Input
                label="Project Name"
                placeholder="Project Name"
                name="title"
                type="text"
                value={values.title || ""}
                onChange={handleChange}
                setFieldValue={setFieldValue}
              />
              <div>
                <ContentEditor
                  id={get(editFormData, "id")}
                  setFieldValue={setFieldValue}
                  inputRef="projectDescription"
                  placeholder="Project Description"
                  name="projectDescription"
                  title="Project Description"
                  inputValue={
                    (!isBase64(values.projectDescription) &&
                      values.projectDescription) ||
                    ""
                  }
                  bulletsEnabled
                  handleChange={handleChange}
                  initialValue={checkTextType(
                    get(editFormData, "projectDescription")
                  )}
                  init={{
                    height: 250,
                    selector: `PF-${get(editFormData, "id")}`,
                    placeholder: "Start adding your question title here...",
                  }}
                  onChange={(_, editor) => {
                    editFormRef.current.setFieldValue(
                      "projectDescription",
                      encodeBase64(editor.getContent())
                    );
                  }}
                />
              </div>
            </div>
            <div style={{ width: "200px", flex: "0.4" }}>
              <h3>Select Courses</h3>
              <Select
                mode="multiple"
                labelInValue
                placeholder="Select Courses"
                loading={
                  coursesFetchStatus &&
                  !get(coursesFetchStatus.toJS(), "loading")
                }
                filterOption={filterOption}
                value={selectedCourses}
                disabled
                onSelect={onSelect}
                onDeselect={onDeselect}
                style={{ width: "100%" }}
              >
                {coursesList.map((item) => (
                  <Select.Option value={get(item, "id")} key={get(item, "id")}>
                    <Tooltip title={get(item, "title")}>
                      {get(item, "title")}
                    </Tooltip>
                  </Select.Option>
                ))}
              </Select>
            </div>
          </FlexContainer>
          <FlexContainer justify="space-between" modalGrid>
            <FlexContainer style={{ alignItems: "flex-start", flex: "0.3" }}>
              <h3>Platform Thumbnail:</h3>
              <Dropzone
                style={{ height: "200px", width: "100%", marginBottom: "15px" }}
                getDropzoneFile={onDropPlatformLogo}
                ref={externalPlatformLogoRef}
                defaultImage={getFullPath(
                  get(editFormData, "externalPlatformLogo.uri")
                )}
                defaultFile={externalPlatformLogo}
              >
                Click or drag to attach
              </Dropzone>
            </FlexContainer>
          </FlexContainer>
          <div>
            <span>Enable doc embed : </span>
            <Checkbox
              id="externalDescriptionEnabled"
              checked={values.externalDescriptionEnabled}
              onChange={({ target: { checked } }) =>
                setFieldValue("externalDescriptionEnabled", checked)
              }
            />
          </div>
          <FlexContainer>
            <div style={{ width: "60%" }}>
              {get(values, "externalDescriptionEnabled", false) ? (
                <Input
                  label="External Description Link"
                  placeholder="External Description Link"
                  name="projectCreationDescription"
                  type="text"
                  value={values.projectCreationDescription || ""}
                  onChange={(e) =>
                    setFieldValue(
                      "projectCreationDescription",
                      onAddIframeLink(e.target.value)
                    )
                  }
                  textArea
                  inputStyles={{ width: "100%", gridTemplateColumns: "auto" }}
                />
              ) : (
                <ContentEditor
                  id={`2_${get(editFormData, "id")}`}
                  initialValue={checkTextType(
                    get(editFormData, "projectCreationDescription")
                  )}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  name="projectCreationDescription"
                  type="text"
                  title="Create Description"
                  shrinkedEditor
                  inputRef="projectCreationDescription"
                  placeholder="Create Description"
                  bulletsEnabled
                  inputValue={
                    (!isBase64(values.projectCreationDescription) &&
                      values.projectCreationDescription) ||
                    ""
                  }
                  init={{
                    height: 250,
                    selector: `PFCD-${get(editFormData, "id")}`,
                    placeholder: "Start adding your question title here...",
                  }}
                  onChange={(_, editor) => {
                    editFormRef.current.setFieldValue(
                      "projectCreationDescription",
                      encodeBase64(editor.getContent())
                    );
                  }}
                />
              )}
            </div>
          </FlexContainer>
          <FlexContainer style={{ justifyContent: "flex-start" }}>
            <h3>Select Layout </h3>
            <Radio.Group
              onChange={(e) => {
                onLinkChange(e);
                let layout;
                if (e.target.value === 1) {
                  layout = "externalPlatform";
                } else if (e.target.value === 2) {
                  layout = "playground";
                } else if (e.target.value === 3) {
                  layout = "fileUpload";
                } else if (e.target.value === 4) {
                  layout = "gsuite";
                } else if (e.target.value === 5) {
                  layout = "mit";
                } else if (e.target.value === 6) {
                  layout = "scratch";
                }
                return setFieldValue("layout", layout);
              }}
              value={currentCheckedLink}
            >
              <Radio style={radioStyle} value={1}>
                Practice Link
              </Radio>
              {get(editFormData, "layout") === "playground" ? (
                <>
                  <Radio style={radioStyle} value={2}>
                    Blockly Code Playground
                  </Radio>
                </>
              ) : null}
              <Radio style={radioStyle} value={3}>
                File Upload
              </Radio>
              <Radio style={radioStyle} value={4}>
                G-Suite File
              </Radio>
              <Radio style={radioStyle} value={5}>
                MIT
              </Radio>
              <Radio style={radioStyle} value={6}>
                Scratch
              </Radio>
              <Radio style={radioStyle} value={7}>
                Robotics
              </Radio>
              {currentCheckedLink === 4 ? (
                <Radio.Group
                  onChange={async (e) => {
                    onGSuitCreationChange(e);
                    if (e.target.value === 1)
                      validateGSuitTempleteURL(
                        inputLinkChangeOfCreateCTA
                          ? inputLinkChangeOfCreateCTA
                          : values.externalPlatformLink
                      );
                  }}
                  value={currentCheckedGSuitCreationType}
                >
                  <Radio style={radioStyleInside} value={1}>
                    From Template File{" "}
                  </Radio>
                  <Radio style={radioStyleInside} value={2}>
                    New File
                  </Radio>
                </Radio.Group>
              ) : null}
              {currentCheckedGSuitCreationType === 2 ? (
                <FlexContainer style={{ marginLeft: "40px" }}>
                  <Radio.Group
                    onChange={(e) => {
                      onFileTypeChange(e);
                      let gsuiteFileType;
                      if (e.target.value === 1) {
                        gsuiteFileType = "document";
                      } else if (e.target.value === 2) {
                        gsuiteFileType = "presentation";
                      } else if (e.target.value === 3) {
                        gsuiteFileType = "spreadsheet";
                      }
                      return setFieldValue("gsuiteFileType", gsuiteFileType);
                    }}
                    value={currentCheckedGSuitType}
                  >
                    <Radio value={1}>Google Doc</Radio>
                    <Radio value={2}>Google Slides</Radio>
                    <Radio value={3}>Google Sheets</Radio>
                  </Radio.Group>
                </FlexContainer>
              ) : null}
              {currentCheckedLink === 7 ? (
                <select
                  value={values.layout}
                  onChange={(e) => {
                    setFieldValue("layout", e.target.value);
                  }}
                >
                  <option value="">Robotics Session</option>
                  {roboticsLayoutOptions.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : null}
            </Radio.Group>
          </FlexContainer>

          {currentCheckedLink === 2 ? (
            <FlexContainer style={{ justifyContent: "flex-start" }}>
              <h3>Set Initial Blocks </h3>
              <Radio.Group onChange={onBlockChange} value={currentCheckedBlock}>
                <Radio style={radioStyle} value={1}>
                  Yes
                </Radio>
                <Radio style={radioStyle} value={2}>
                  No
                </Radio>
              </Radio.Group>
            </FlexContainer>
          ) : null}

          {currentCheckedBlock === 1 ? (
            <div
              style={{ width: "100%", height: "500px", marginBottom: "20px" }}
            >
              <BlocklyWorkspace
                useDefaultToolbox
                workspaceConfiguration={workspaceConfiguration}
                onWorkspaceChange={(workspace) => {}}
                customTheme={BLOCKLY_PLAYGROUND_THEME}
                onInject={(e) => {}}
                onXmlChange={(workspace) => {
                  init === 1
                    ? setFieldValue("initialBlocks", encodeBase64(xmlData))
                    : setFieldValue(
                        "initialBlocks",
                        encodeBase64(saveXML(workspace))
                      );
                  init++;
                }}
                initialXml={xmlData}
              />
            </div>
          ) : null}
          {values.layout !== "gsuite" &&
            values.layout !== "fileUpload" &&
            values.layout !== "mit" &&
            values.layout !== "scratch" &&
            values.layout !== "mkbSim" &&
            !values.layout.includes("uoloAbotAdvSim") && (
              <div style={{ marginBottom: "10px" }}>
                <label style={{ cursor: "pointer" }} htmlFor="isSubmitAnswer">
                  Can kids submit this practice?
                  <Checkbox
                    id="isSubmitAnswer"
                    style={{ marginLeft: "20px" }}
                    checked={values.isSubmitAnswer}
                    onChange={({ target: { checked } }) =>
                      setFieldValue("isSubmitAnswer", checked)
                    }
                  />
                </label>
              </div>
            )}
          {currentCheckedLink === 1 ? (
            <FlexContainer>
              <Input
                inputStyles={{ flex: "0.6" }}
                label="Submit Description"
                placeholder="Submit Description"
                name="answerDescription"
                type="text"
                textArea
                value={values.answerDescription || ""}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                inputRef="answerDescription"
              />
            </FlexContainer>
          ) : null}
          <FlexContainer>
            <Input
              practice
              inputStyles={{ flex: "0.6" }}
              label="Create CTA link"
              placeholder="Create CTA link"
              name="externalPlatformLink"
              type="text"
              onBlur={() => {
                if (values && values.externalPlatformLink) {
                  setFieldValue(
                    "externalPlatformLink",
                    values.externalPlatformLink.trim(),
                    ""
                  );
                }
              }}
              value={values.externalPlatformLink || ""}
              onChange={(e) => handleOnChange(e, handleChange)}
              setFieldValue={setFieldValue}
            />
          </FlexContainer>
          <FlexContainer>
            {gsuiteTempleteURLError && currentCheckedLink === 4 && (
              <div style={{ flex: "0.6" }}>
                <p style={{ color: "red", fontWeight: "700" }}>
                  {gsuiteTempleteURLError}
                </p>
              </div>
            )}
            {gsuiteTempleteURLSuceessMessage && currentCheckedLink === 4 && (
              <div style={{ flex: "0.6" }}>
                <p style={{ color: "green", fontWeight: "700" }}>
                  {gsuiteTempleteURLSuceessMessage}
                </p>
              </div>
            )}
          </FlexContainer>
          <FlexContainer>
            <Input
              practice
              inputStyles={{ flex: "0.6" }}
              label="Create CTA Label"
              placeholder="Create CTA Label"
              name="platFormLinkLabel"
              type="text"
              onBlur={() => {
                if (values && values.externalPlatformLink) {
                  setFieldValue(
                    "platFormLinkLabel",
                    get(values, "platFormLinkLabel", "").trim()
                  );
                }
              }}
              value={values.platFormLinkLabel || ""}
              onChange={handleChange}
              setFieldValue={setFieldValue}
            />
          </FlexContainer>
          <FlexContainer justify="flex-start">
            <Input
              placeholder="Enter Order"
              inputStyles={{ marginBottom: "0" }}
              type="number"
              name="order"
              label="Order"
              value={values.order || ""}
              order
              values={values}
              orderInUse={orderInUse}
              setFieldValue={setFieldValue}
              onChange={(e) => handleChange(e)}
            />
            <PublishInput values={values} setFieldValue={setFieldValue} />
          </FlexContainer>
          <FlexContainer style={{ justifyContent: "flex-start" }}>
            <h3>Select Answer Format </h3>
            <Radio.Group
              onChange={(e) => {
                setFieldValue("answerGoogleEmbedLink", "");
                setFieldValue("answerContent", "");
                setCurrentAnswerFormat(e.target.value);
              }}
              value={currentAnswerFormat}
            >
              {answerFormatOptions.map((item) => (
                <Radio style={radioStyle} value={item.key}>
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
          </FlexContainer>
          {/* {currentAnswerFormat === answerFormatOptions[0].key ? <FlexContainer>
            <Input
              inputStyles={{ flex: '0.6' }}
              label='Enter Answer Link'
              placeholder='Enter Answer Link'
              name='answerLink'
              type='text'
              onBlur={() => setFieldValue('answerLink', get(values, 'answerLink', '').trim())}
              value={values.answerLink || ''}
              onChange={handleChange}
              setFieldValue={setFieldValue}
            />
          </FlexContainer> : null} */}
          {currentAnswerFormat === answerFormatOptions[0].key ? (
            <FlexContainer>
              <Input
                label="External Google Embed Link"
                placeholder="External Google Embed Link"
                name="answerGoogleEmbedLink"
                type="text"
                value={values.answerGoogleEmbedLink || ""}
                onChange={(e) =>
                  setFieldValue(
                    "answerGoogleEmbedLink",
                    onAddIframeLink(e.target.value)
                  )
                }
                textArea
                inputStyles={{ width: "60%", gridTemplateColumns: "auto" }}
              />
            </FlexContainer>
          ) : null}
          {currentAnswerFormat === answerFormatOptions[1].key ? (
            <FlexContainer>
              <ContentEditor
                id={`3_${get(editFormData, "id")}`}
                initialValue={checkTextType(get(editFormData, "answerContent"))}
                setFieldValue={setFieldValue}
                inputRef="answerContent"
                name="answerContent"
                placeholder="Answer Content Description"
                title="Answer Content Description"
                handleChange={handleChange}
                inputValue={
                  (!isBase64(values.answerContent) && values.answerContent) ||
                  ""
                }
                bulletsEnabled
                init={{
                  height: 250,
                  selector: `BBPE-${get(editFormData, "id")}`,
                  placeholder: "Start adding your answer here...",
                }}
                onChange={(_, editor) => {
                  editFormRef.current.setFieldValue(
                    "answerContent",
                    encodeBase64(editor.getContent())
                  );
                }}
              />
            </FlexContainer>
          ) : null}
          <FlexContainer justify="center">
            <StyledButton
              icon="file"
              id="add-btn"
              htmlType="submit"
              loading={
                practiceUpdateStatus &&
                get(practiceUpdateStatus.toJS(), "loading")
              }
              disabled={
                (gsuiteTempleteURLError && currentCheckedLink === 4) ||
                (!inputLinkChangeOfCreateCTA &&
                  currentCheckedLink === 4 &&
                  currentCheckedGSuitCreationType === 1) ||
                (currentCheckedLink === 4 &&
                  !currentCheckedGSuitCreationType) ||
                (currentCheckedGSuitCreationType === 2 &&
                  !currentCheckedGSuitType) ||
                !isDataFetching
              }
            >
              Update
            </StyledButton>
          </FlexContainer>
        </Form>
      )}
    </Formik>
  );
};

export default EditPracticeForm;
