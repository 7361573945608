import React from 'react'
import { Layout, Menu, Button } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import getFullPath from '../../../utils/getFullPath';
import {
    ArrowRightOutlined, CalendarOutlined,
    ClockCircleOutlined, FieldTimeOutlined,
    GiftOutlined, FieldBinaryOutlined, SafetyCertificateOutlined
} from '@ant-design/icons'
import PrizeCard from './PrizeCard'
import ReactHtmlParser from 'react-html-parser';
import '../styles.css'
import { decodeBase64 } from '../../../utils/base64Utility';


const { Header, Content, Footer } = Layout;

const WebPreview = (props) => {

    return (
        <Layout className="layout" style={{ width: '800px' }}>
            <Header className="navbar">
                <img src='/images/tekieLogo.png' className="logo" alt='tekie logo' />
                <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']} className="menu">
                    <Menu.Item>Student Community</Menu.Item>
                    <Menu.Item>Resources</Menu.Item>
                    <Menu.Item>Careers</Menu.Item>
                    <Menu.Item>Blog</Menu.Item>
                    <Button type="primary" shape="round" size='middle' className="registerBtn">Register Now<ArrowRightOutlined /></Button>
                </Menu>
            </Header>
            <Content style={{
                padding: '10px 50px',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${getFullPath(get(props, 'data.eventBanner.uri')) || "https://thumbs.gfycat.com/BowedEmbarrassedBrocketdeer-size_restricted.gif"})`,
                backgroundSize: 'contain'
            }}>
                <img src='https://marketplace-assets-production.s3-us-west-2.amazonaws.com/vault/items/preview-576d0bba-064c-4d7d-a6c1-57e00a14153a-dRl2M.jpg' className='event_logo' alt='event-logo' />
                <h1 className='event_title'>{get(props, 'data.name')}</h1>
                <p className='event_line_1'>{get(props, 'data.summary') || 'Summary'}</p>
                <p className='event_line_2'><CalendarOutlined style={{ marginRight: '5px' }} />
                    {moment(get(props, 'data.selectedDateRange.startDate')).format('Do MMMM YYYY') || '18th December 2021'}
                </p>
                <p className='event_line_2'><ClockCircleOutlined style={{ marginRight: '5px' }} />
                    {`${moment(get(props, 'data.timeValues.startTime')).set('minutes', 0).format('hh:mm A')} Onwards` || '03:00 PM Onwards'}
                </p>
                <p className='event_line_2'><FieldTimeOutlined style={{ marginRight: '5px' }} />60-75 Minutes</p>
                <Button type="primary" shape="round" size='middle' className="registerBtn2">Register Now<ArrowRightOutlined /></Button>
                <p className='event_line_3'>*no prior coding knowledge required</p>
            </Content>
            <Content className="site-layout-content">
                <h1 className="Overview">Overview</h1>
                <p className='overview_desc'>{ReactHtmlParser(decodeBase64(get(props, 'data.overview', '')))}</p>
            </Content>
            <Content className="site-layout-content">
                <h1 className="Overview">Prizes</h1>
                <div className="PrizeContainer">
                    {
                        get(props, 'data.prizes', []).length > 0 &&
                        get(props, 'data.prizes').map((prize, index) => {
                            return (
                                <PrizeCard
                                    data={prize}
                                    index={index}
                                />
                            )
                        })
                    }
                </div>
            </Content>
            <Content style={{
                margin: '10px 22px 10px 50px',
                backgroundImage: 'url("https://thumbs.gfycat.com/BowedEmbarrassedBrocketdeer-size_restricted.gif")',
                borderRadius: '8px',
                textAlign: 'center'
            }}>
                <p className='event_line_1' style={{ margin: '20px 0', fontWeight: 'bold', fontSize: '18px' }}>Why you should join {get(props, 'data.name')}</p>
                <div className="PrizeContainer">
                    {
                        get(props, 'data.eventJoinReasons', []).length > 0 ? (
                            get(props, 'data.eventJoinReasons').map((reason, index) => {
                                if (index < 5) {
                                    return (
                                        <div className="bottomCard">
                                            <img
                                                src={getFullPath(get(reason, 'picture.uri'))}
                                                style={{ height: '2em', width: '2em', objectFit: 'contain' }}
                                            />
                                            <p>{reason.title}</p>
                                        </div>
                                    )
                                }
                                else {
                                    return (<></>)
                                }
                            })
                        ) : (
                            <>
                                <div className="bottomCard">
                                    <GiftOutlined className="bottomIcon" />
                                    <p>Become a certified Spy master in Binary language.</p>
                                </div>
                                <div className="bottomCard">
                                    <FieldBinaryOutlined className="bottomIcon" />
                                    <p>Learn to Translate numbers, Letters and words into Binary Code.</p>
                                </div>
                                <div className="bottomCard">
                                    <SafetyCertificateOutlined className="bottomIcon" />
                                    <p>Earn a free spy tool & stand a chance to win exciting prizes.</p>
                                </div>
                            </>
                        )
                    }

                </div>
                <Button type="primary" shape="round" size='middle' className="registerBtn2" style={{ margin: '20px 0' }}>Register Now<ArrowRightOutlined /></Button>
            </Content>
            <Footer style={{ height: '40px', paddingBottom: '50px' }}>
                <p style={{ textAlign: 'left' }}>
                    © 2020, Kiwhode Learning Pvt Ltd. All Rights Reserved.
                </p>
                <p style={{ position: 'relative', top: '-20px', left: '534px', width: '170px' }}>
                    Privacy | Terms | Refund
                </p>
            </Footer>
        </Layout>
    )
}

export default WebPreview