/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import MainTable from '../../../../components/MainTable'
import { Table } from '../../../../components/StyledComponents'

const color = 'rgba(215, 250, 251, 0.6)'

const TopicsTableHead = ({ columnsTemplate, minWidth }) => (
  <Table.UploadRow columnsTemplate={columnsTemplate} minWidth={minWidth} gap >
    <Table.Item backgroundColor={color} Radius='22px 0 0 0' />
    <Table.Item backgroundColor={color}>
      <MainTable.UploadTitle>
        Name
      </MainTable.UploadTitle>
    </Table.Item>
    <Table.Item backgroundColor={color}><MainTable.UploadTitle>Type</MainTable.UploadTitle></Table.Item>
    <Table.Item backgroundColor={color}><MainTable.UploadTitle>Size</MainTable.UploadTitle></Table.Item>
    <Table.Item backgroundColor={color}><MainTable.UploadTitle>Action</MainTable.UploadTitle></Table.Item>
    <Table.Item backgroundColor={color} Radius='0 22px 0 0' />
  </Table.UploadRow>
)

TopicsTableHead.propTypes = {
  columnsTemplate: PropTypes.string.isRequired,
  minWidth: PropTypes.string.isRequired
}

export default TopicsTableHead
