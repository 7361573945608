import gql from "graphql-tag"
import requestToGraphql from "../../utils/requestToGraphql"
import duck from "../../duck"

export const fetchEbooks = async () => {
    const query = gql`
        query {
            eBooks{
                id
                title
                description
                resourceURL
                internalName
                thumbnail{
                    id
                }
                ebookChapters{
                    id
                    order
                    title
                    pageNumber
                }
                packages: coursePackage{
                    title
                    id
                }
                grades{
                    value
                }
            }
        }
    `
    return duck.query({
        query: query,
        type: 'eBooks/fetch',
        key: 'eBooks',
    });
}