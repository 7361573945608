import gql from 'graphql-tag'
import duck from '../../../duck'


const addComicImage = async ({ input, comicId, imageConnectId }) =>
  duck.query({
    query: gql`
    mutation($input: ComicImageInput!) {
      addComicImage(
        input: $input,
        ${comicId ? `comicStripConnectId: "${comicId}"` : ''},
        ${imageConnectId ? `imageConnectId: "${imageConnectId}"` : ''}
      ) {
        id
        order
        image {
          id
          uri
        }
      }
    }
    `,
    variables: {
      input
    },
    type: 'comicImages/add',
    key: 'comicImages',
  })

export default addComicImage
