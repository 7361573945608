import gql from "graphql-tag";
import duck from "../../duck";

const fetchLabInspections = async (schoolId, activeLab) =>
  duck.query({
    query: gql`
    query{
      labInspections(filter:{
        and: [
          {
            school_some:{
              id: "${schoolId}"
            }
          },
          {
            ${activeLab ? `labName: "${activeLab}"` : ""}
          }
        ]
      }) {
        id
        labName
        labNo
        description
        createdAt
        school {
          id
          name
        }
        labConfiguration {
          totalNumberOfComputers
          totalNumberOfWorkingComputers
          projectInteractivePanel
          speakers
          powerBackupType
          powerBackup
          internetConnection
          serviceProviderType
          inspectionDate
          internetSpeed
        }
        systems {
          id
          serialNo
          uniqueDeviceId
          createdAt
          updatedAt
          status
          comment
          inspectionMode
          systemInformation
          inspectionChecks {
            name
            type
            spec
            status
          }
          inspectionMode
          uniqueDeviceId
        }
      }
    }
    `,
    type: "labInspections/fetch",
    key: "labInspections",
    changeExtractedData: (extractedData, originalData) => {
      return { ...originalData }
    }
  });

export default fetchLabInspections;
