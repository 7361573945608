import gql from "graphql-tag"
import duck from '../../../duck'

const fetchEventTags = async (first, skip, filterQuery, searchQuery) => {
    await duck.query({
      query: gql`
        query {
          contentTags(
            filter: { and: [
            { isEventTag: true }
            ${filterQuery ? `{ ${filterQuery} }`: ''}
            ${searchQuery ? `{ ${searchQuery} }`: ''}
          ]}
            orderBy: createdAt_ASC
            first: ${first}
            skip: ${first * skip}

          ) {
            id
            title
            displayOnWebsite
            createdAt
            tagStatus
            createdBy {
              name
            }
            eventsMeta {
              count
            }
          }
          contentTagsMeta(
            filter: { and: [
              { isEventTag: true }
              ${filterQuery ? `{ ${filterQuery} }`: ''}
              ${searchQuery ? `{ ${searchQuery} }`: ''}
            ]}
          ) {
            count
          }
        }
    `,
      type: 'contentTags/fetch',
      key: 'contentTags'
    })
  }
  
  export default fetchEventTags