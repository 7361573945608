import React from 'react'
import ConfirmModal from './ModalConfirm.style'
import packageSvg from '../../../../assets/package.svg'

const ModalConfirm = ({ showModal, heading, main, confirm, onCancel, onConfirm }) => {
    return (
        <ConfirmModal
            visible={showModal}
            onCancel={() => onCancel()}
            footer={null}
        >
            <ConfirmModal.ModalTopContainer>
                <span style={{ backgroundImage: `url(${packageSvg})` }}></span>
                <h4>{heading}</h4>
            </ConfirmModal.ModalTopContainer>
            <ConfirmModal.ModalContentContainer>
                {main}
            </ConfirmModal.ModalContentContainer>
            <ConfirmModal.ModalButtonsContainer>
                <ConfirmModal.ModalCancelButton
                    onClick={() => onCancel()}
                >Cancel</ConfirmModal.ModalCancelButton>
                <ConfirmModal.ModalConfirmButton
                    onClick={() => onConfirm()}
                >{confirm}</ConfirmModal.ModalConfirmButton>
            </ConfirmModal.ModalButtonsContainer>
        </ConfirmModal>
    )
}

export default ModalConfirm