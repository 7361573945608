import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const fetchMentorAvailabilitySlotsForMentors = async (filter) => (
  duck.query({
    query: gql`
  {
  mentorAvailabilitySlots(
    filter: {
      and: [
        ${filter || ''}
      ]
    }
  ) {
    id
    date
    slotName
    paySlab{
      id
    }
    verticals{
      value
    }
    mentorSessionsMeta {
      count
    }
    count
  }
}
  `,
    type: 'mentorAvailabilitySlots/fetch',
    key: 'mentorAvailabilitySlots',
    changeExtractedData: (extractedData, originalData) => {
      extractedData.mentorAvailabilitySlots = get(originalData, 'mentorAvailabilitySlots', [])
      return { ...extractedData }
    },
  })
)

export default fetchMentorAvailabilitySlotsForMentors
