/* eslint-disable no-console */
import React, { useState } from 'react'
import Modal from 'react-modal'
import { Input, message } from 'antd'
import Main from '../../Upload.style'
import getFullPath from '../../../../utils/getFullPath'
import SVGIcon from '../../../../assets/icons'
import updateFile from '../../../../actions/upload/updateFile'

const EditModal = ({ id, editModal, closeModal, file, mimeType, uri }) => {
  const [filename, setName] = useState(file.trim())
  const renameFile = (e) => {
    setName(e.target.value.trim())
  }

  const changeFileName = () => {
    if (!filename || !filename.trim()) {
      message.warning('filename should not be blank or Not only spaces')
    } else {
      updateFile(id, filename.trim())
      closeModal()
    }
  }

  return (
    <Modal
      isOpen={editModal}
      onRequestClose={closeModal}
      style={
        {
            overlay: {
                background: 'rgba(0, 23, 31, 0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            content: {
                color: 'white',
                width: '60%',
                height: 'fit-content',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                overflow: 'hidden',
                padding: '0px',
                paddingBottom: '8px',
                borderRadius: '10px'
            }
        }
    }
    >
        <Main.Title>
            Edit File
        </Main.Title>
        <Main.EditContainer>
            <div>
        {(mimeType === 'pdf' || mimeType === 'octet-stream' || mimeType === 'mpeg' || mimeType === 'mp4' || mimeType === 'json') ?
        null
        :
          <img src={getFullPath(uri)}
            alt='Thumbnail'
            style={{ height: '150px', minWidth: '100%' }}
          />
        }
            </div>

            <Input
              prefix={<SVGIcon.FileIcon />}
              placeholder='Edit FileName'
              defaultValue={file.trim()}
              onChange={renameFile}
            />
        </Main.EditContainer>
        <Main.SaveButton>
        <Main.ConfirmButton style={{ color: '#fff', marginRight: '15px' }} onClick={changeFileName}>
             Save
        </Main.ConfirmButton>
        <Main.Cancel
          type='primary'
          onClick={() => {
            closeModal()
            setName(file)
          }}
          ghost
        >Cancel
        </Main.Cancel>
        </Main.SaveButton>
    </Modal>
  )
}
export default EditModal
