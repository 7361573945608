import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'

const fetchClassroomGrades = async (schoolId, academicYearConnectId) =>
    duck.query({
        query: gql`{
            classroomGrades: schoolClasses(filter: {
                and: [
                    { school_some: { id: "${schoolId}" }}
                    { academicYears_some: { id: "${academicYearConnectId}" }}
                ]
            }) {
                id
                grade
                section
                gradeDisplayName
                sectionDisplayName
                students {
                    id
                    grade
                    section
                    user {
                        id
                        name
                        status
                    }
                }
            }
        }
        `,
    type: 'classroomGrades/fetch',
    key: 'classroomGrades',
    changeExtractedData: (extractedData, originalData) => {
        const schoolClasses = get(originalData, 'classroomGrades')
        const gradeSectionMap = {}
        const classroomGrades = []
        schoolClasses.length && schoolClasses.forEach(item => {
            const section = {
                id: get(item, 'id'),
                section: get(item, 'section'),
                sectionDisplayName: get(item, 'sectionDisplayName'),
            }
            if (gradeSectionMap[get(item, 'grade')]) {
                const sectionsTemp = gradeSectionMap[get(item, 'grade')].sections
                sectionsTemp.push(section)
                gradeSectionMap[get(item, 'grade')].sections = sectionsTemp
            } else {
                const obj = {
                    grade: get(item, 'grade'),
                    id: get(section, 'id'),
                    gradeDisplayName: get(item, 'gradeDisplayName'),
                    sections: [section]
                }
                gradeSectionMap[get(item, 'grade')] = obj
            }
        })
        Object.keys(gradeSectionMap).length && Object.keys(gradeSectionMap).map(item => {
            classroomGrades.push(gradeSectionMap[item])
        })
        const obj = {
            classroomGrades
        }
        return obj
    },
})

export default fetchClassroomGrades

