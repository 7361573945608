import styled from "styled-components";

const Label = styled.label`
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: ${(props) => (props.color ? props.color : "#858585")}
`;

export default Label;