import gql from 'graphql-tag'
import { get } from 'lodash';
import duck from '../../duck';
import getIdArrForQuery from '../../utils/getIdArrForQuery';
import uploadFile from '../utils/uploadFile';
import removeFromEventAction from './removeFromEventAction'


const updateEventAction = async (eventId, input = {},
    { category, tags = [], bannerImage, prevBannerId, eventMobileImage, prevMobileImage }) => {
    return duck.query({
        query: gql`
           mutation updateEvent($input:EventUpdate) {
            updateEvent(input:$input,id:"${eventId}",
            ${category ? `categoryConnectId:"${category}"` : ''}
            ${tags.length ? `tagsConnectIds: [${getIdArrForQuery(tags)}]` : ''}
            ) {
                  id
                  name
                  summary
                }
              }
              `,
        variables: {
            input
        },
        type: 'events/update',
        key: 'events',
        changeExtractedData: (extracted, original) => {
            const eventId = get(original, 'updateEvent.id')
            if (bannerImage) {
                (async function imageUpload() {
                    try {
                        if (prevBannerId) {
                           await removeFromEventAction(eventId, prevBannerId, '', 'banner')
                        }
                        await uploadFile(bannerImage, {
                            fileBucket: 'python'
                        }, { typeField: 'eventBanner', typeId: eventId, type: 'Event', })
                    } catch (error) {
                        console.log(error)
                    }
                }())
            }
            if (eventMobileImage) {
                (async function imageUpload() {
                    try {
                        if (prevMobileImage) {
                            await removeFromEventAction(eventId, prevMobileImage, '', 'mobileBanner')
                        }
                        await uploadFile(eventMobileImage, {
                            fileBucket: 'python'
                        }, { typeField: 'eventMobileBanner', typeId: eventId, type: 'Event', })
                    } catch (error) {
                        console.log(error)
                    }
                }())
            }
            return { ...extracted }
        }
    })
}

export default updateEventAction