import { Collapse, Switch } from 'antd';
import React, { Component } from 'react';
import NameANdImageHeader from '../Constant/NameANdImageHeader';
import ScheduleControlReporting from '../Constant/ScheduleControlReporting';
import { DivFlexStyle, ReviewBodyDiv, SMReviewDiv } from '../TeachersTab.styles';
import Loader2 from "../../../../../assets/Loader2.png"
import DownArrowIcon from '../Constant/DownArrowIcon';
import NotDone from "../../../../../assets/NotDone.png"
import Done from "../../../../../assets/Done.png"
import BImage from "../../../../../assets/BImage.png"
import Line from "../../../../../assets/Line.png"
const { Panel } = Collapse;
export default class ReviewAndCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ScheduleManagementUpDown: 0,
            ClassRoomControlUpDown: 0,
            CourseControlUpDown: 0,
            ReportingUpDown: 0,
        }
    }
    render() {
        const { schoolName, mentorProfileRowData, onNext, goBackOnNext,maxTabAccesed } = this.props;
        return (
            <>
                <NameANdImageHeader schoolName={schoolName} Loader={Loader2} Loader1={Done} Loader2={Done} Loader3={NotDone} BImage={BImage} Line={Line} onNext={onNext} goBackOnNext={value => goBackOnNext(value)} maxTabAccesed={maxTabAccesed}/>
                <div style={{ fontWeight: "bold" }}>Review And Create</div>
                <div style={{ margin: "" }}>
                    <div style={{ margin: "20px" }}>
                        <Collapse style={{
                            background: "rgb(128 128 128 / 13%)",
                            border: "0px",
                            borderRadius:"8px",
                        }}
                            defaultActiveKey={['1']}>
                            <Panel header="Schedule Management" key="1">
                                <ReviewBodyDiv >
                                    <DivFlexStyle>
                                        <div>Scheduling Learning Class</div>
                                        <Switch disabled
                                            checked={mentorProfileRowData.SchedulingLearningClass === 0 ? true : false}
                                        />
                                    </DivFlexStyle>
                                    <DivFlexStyle>
                                        <div>Scheduling Ad-Hoc Class</div>
                                        <Switch disabled
                                            checked={mentorProfileRowData.SchedulingAdHocClass === 0 ? true : false}
                                        />
                                    </DivFlexStyle>
                                    <DivFlexStyle>
                                        <div>Rescheduling Class</div>
                                        <Switch disabled
                                            checked={mentorProfileRowData.ReschedulingClass === 0 ? true : false}
                                        />
                                    </DivFlexStyle>
                                    <DivFlexStyle>
                                        <div>Scheduling Class</div>
                                        <Switch disabled
                                            checked={mentorProfileRowData.SchedulingClass === 0 ? true : false}
                                        />
                                    </DivFlexStyle>
                                    <DivFlexStyle>
                                        <div>Delete Sessions</div>
                                        <Switch disabled
                                            checked={mentorProfileRowData.DeleteSessions === 0 ? true : false}
                                        />
                                    </DivFlexStyle>
                                </ReviewBodyDiv>
                              
                            </Panel>
                        </Collapse>
                    </div>

                    <div style={{ margin: "20px" }}>
                        <Collapse 
                        style={{
                            background: "rgb(128 128 128 / 13%)",
                            border: "0px",
                            borderRadius:"8px",
                        }}
                        defaultActiveKey={['2']}>
                            <Panel header="Classromm Control" key="2">
                                <ReviewBodyDiv>
                                    <DivFlexStyle>
                                        <div>Creating a Classroom</div>
                                        <Switch disabled
                                            checked={mentorProfileRowData.CreatingAClassroom === 0 ? true : false}
                                        />
                                    </DivFlexStyle>
                                    <DivFlexStyle>
                                        <div>Send Notices</div>
                                        <Switch disabled
                                            checked={mentorProfileRowData.SendNotices === 0 ? true : false}
                                        />
                                    </DivFlexStyle>
                                    <DivFlexStyle>
                                        <div>Add / Invite Student</div>
                                        <Switch disabled
                                            checked={mentorProfileRowData.AddInviteStudent === 0 ? true : false}
                                        />
                                    </DivFlexStyle>
                                    <DivFlexStyle>
                                        <div>Access Recordings</div>
                                        <Switch disabled
                                            checked={mentorProfileRowData.AccessRecordings === 0 ? true : false}
                                        />
                                    </DivFlexStyle>
                                    <DivFlexStyle>
                                        <div>Live Session Progress</div>
                                        <Switch disabled
                                            checked={mentorProfileRowData.LiveSessionProgress === 0 ? true : false}
                                        />
                                    </DivFlexStyle>
                                </ReviewBodyDiv>
                            </Panel>
                        </Collapse>
                    </div>
                    <div style={{ margin: "20px" }}>
                        <Collapse 
                        style={{
                            background: "rgb(128 128 128 / 13%)",
                            border: "0px",
                            borderRadius:"8px",
                        }}
                        defaultActiveKey={['2']}>
                            <Panel header="Course Control" key="2">
                                <ReviewBodyDiv>
                                    <DivFlexStyle>
                                        <div>Access Course Control</div>
                                        <Switch disabled
                                            checked={mentorProfileRowData.AccessCourseControl === 0 ? true : false}
                                        />
                                    </DivFlexStyle>
                                    <DivFlexStyle>
                                        <div>Create Tests</div>
                                        <Switch disabled
                                            checked={mentorProfileRowData.CreateTests === 0 ? true : false}
                                        />
                                    </DivFlexStyle>
                                    <DivFlexStyle>
                                        <div>Evaluate Tests</div>
                                        <Switch disabled
                                            checked={mentorProfileRowData.EvaluateTests === 0 ? true : false}
                                        />
                                    </DivFlexStyle>
                                    <DivFlexStyle>
                                        <div>Add to Q Banks</div>
                                        <Switch disabled
                                            checked={mentorProfileRowData.AddToQBanks === 0 ? true : false}
                                        />
                                    </DivFlexStyle>
                                </ReviewBodyDiv>
                            </Panel>
                        </Collapse>
                    </div>
                    <div style={{ margin: "20px" }}>
                       
                        <Collapse 
                        style={{
                            background: "rgb(128 128 128 / 13%)",
                            border: "0px",
                            borderRadius:"8px",
                        }}
                        defaultActiveKey={['2']}>
                            <Panel header="Course Control" key="2">
                                <ReviewBodyDiv>
                                    <DivFlexStyle>
                                        <div>Access Reports</div>
                                        <Switch disabled
                                            checked={mentorProfileRowData.AccessReports === 0 ? true : false}
                                        />
                                    </DivFlexStyle>
                                    <DivFlexStyle>
                                        <div>Download Reports</div>
                                        <Switch disabled
                                            checked={mentorProfileRowData.DownloadReports === 0 ? true : false}
                                        />
                                    </DivFlexStyle>
                                    <DivFlexStyle>
                                        <div>Share Reports</div>
                                        <Switch disabled
                                            checked={mentorProfileRowData.ShareReports === 0 ? true : false}
                                        />
                                    </DivFlexStyle>
                                </ReviewBodyDiv>
                            </Panel>
                        </Collapse>
                    </div>
                </div>
            </>
        )
    }
}
