import { SearchOutlined, StarFilled, UserOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { get } from 'lodash'
import React from 'react'
import { FemaleSvg, MaleSvg } from '../../../../../constants/icons'
import getFullPath from '../../../../../utils/getFullPath'
import getSlotLabel from '../../../../../utils/slots/slot-label'
import { checkIfNotOldSlot } from '../../../../SlotsInfo/component/UsersTable/slots-utils'
import customNotification from '../../../components/CustomNotification'
import {
  getMentorDataFromBatchSession,
  getMentorRating, renderCodingLanguage, getSelectedSlots
} from '../../../demandSupply-utils'
import Styles from '../../../DemandSupplyDashboard.style'
import DemandCalendarWrapper from '../../DemandCalendar.style'

class AssignMentor extends React.Component {
  state = {
    searchMentor: ''
  }
  onSelectMentor = (mentor) => {
    const { onStateChange, sessionData, byPassAssignment = false } = this.props
    const isBatchSession = get(sessionData, 'recordType') === 'batchSession'
    const { menteeExistMessage } = this.getMentorBgAndStatus(mentor)
    const { rawBookingDate, sessionStatus, startTime } = sessionData
    const slotNumber = new Date(startTime).getHours()
    if (sessionStatus && sessionStatus !== 'allotted') {
      customNotification(`Session already ${sessionStatus}!`, '', null, 'error')
      return
    }
    if (!checkIfNotOldSlot(rawBookingDate, slotNumber, byPassAssignment)) {
      customNotification('Cannot assign mentor for old slots!', '', null, 'error')
      return
    }
    if (!isBatchSession && menteeExistMessage) {
      customNotification(menteeExistMessage, '', null, 'warn')
      return
    }

    onStateChange('selectedMentorId', get(mentor, 'id'))
    onStateChange('selectedMentorSessionId', get(mentor, 'sessionId'))
    onStateChange('defaultMentorId', '')
  }
  getTableColumn = () => {
    const { selectedMentorId, defaultMentorId,
      selectedMentorSessionId, sessionData } = this.props
    const isBatchSession = get(sessionData, 'recordType') === 'batchSession'
    const columns = [
      {
        title: 'Mentor',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        render: (name, record) => (
          <Styles.FlexContainer justify='center'>
            {getFullPath(get(record, 'profilePic.uri'))
              ? <DemandCalendarWrapper.MentorProfile style={{ marginRight: 10 }} profilePic={getFullPath(get(record, 'profilePic.uri'))} />
              : <Styles.StyledProfileIcon component={UserOutlined} />}
            {name}
            {get(record, 'gender', 'female')
              && (get(record, 'gender', 'female').toLowerCase() === 'female'
                  ? <FemaleSvg /> : <MaleSvg />)}
          </Styles.FlexContainer>
        )
      },
      {
        title: 'Rating',
        dataIndex: 'rating',
        key: 'rating',
        align: 'center',
        render: (_, record) => (
          <DemandCalendarWrapper.MentorRatingBox style={{ margin: '0 auto' }}>
            <StarFilled />
            {getMentorRating(get(record, 'mentorProfile'))}
          </DemandCalendarWrapper.MentorRatingBox>
        )
      },
      {
        title: 'Langauges',
        dataIndex: 'languages',
        key: 'languages',
        align: 'center',
        render: (_, record) => renderCodingLanguage(get(record, 'mentorProfile'))
      },
      {
        title: 'Select',
        dataIndex: 'checked',
        key: 'checked',
        align: 'center',
        render: (_, record) => (
          <Tooltip title={(isBatchSession ? this.getMentorBgAndStatus(record).batchExistMessage
            : this.getMentorBgAndStatus(record).menteeExistMessage)
            || this.getMentorBgAndStatus(record).prevOrNextSlotExist}
            overlayClassName='styledTooltip-demandEvent'
          >
            <Styles.Checkbox
              disabled={selectedMentorSessionId === get(record, 'sessionId')}
              onChange={() => this.onSelectMentor(record)}
              checked={selectedMentorId === get(record, 'id') || defaultMentorId === get(record, 'id')}
            />
          </Tooltip>
        )
      }
    ]
    return columns
  }

  getMentorBgAndStatus = (mentor) => {
    const { sessionData, selectedMentorSessionId } = this.props
    const userId = get(sessionData, 'session.user.id')
    const mentorFromSessionId = get(sessionData, 'mentorMenteeSession.mentorSession.user.id')
    const time = new Date(get(sessionData, 'startTime')).getHours()
    const batchSessions = get(mentor, 'batchSessions', [])
    const menteeSessions = get(mentor, 'existMenteeSession', [])
    let menteeExistMessage = ''
    const { showMentor, batchExistWithEmptyStudent } =
      getMentorDataFromBatchSession(batchSessions, time, get(sessionData, 'recordType') === 'batchSession' ? sessionData : null)
    const mentorSlotsArray = []
    if (menteeSessions && menteeSessions.length > 0) {
      menteeSessions.forEach(mmSession => {
        if (get(mmSession, 'user.id') !== userId) {
          const selectedSlots = getSelectedSlots(mmSession)
          mentorSlotsArray.push(...selectedSlots)
          if (selectedSlots.length > 0 && selectedSlots.includes(`slot${time}`)) {
            menteeExistMessage = `Mentor is assigned for ${get(mmSession, 'user.name')}`
          }
        }
      })
    }
    let prevOrNextSlotExist = ''
    const uniqueSlots = [...new Set(mentorSlotsArray)]
    let prevSlot = ''
    let nextSlot = ''
    uniqueSlots.forEach(slot => {
      const slotNumber = Number(slot.split('slot')[1])
      // checking prev, next slots status for the mentor
      if ((time - 1) === slotNumber) {
        prevSlot = getSlotLabel((time - 1)).startTime
      }
      if ((time + 1) === slotNumber) {
        nextSlot = getSlotLabel((time + 1)).startTime
      }
    })
    if (prevSlot || nextSlot) {
      prevOrNextSlotExist = `Mentor is booked at ${prevSlot ?
        `${prevSlot}` : ''} ${nextSlot ? `${prevSlot ? 'or' : ''} ${nextSlot}` : ''}, but you can assign it anyway.`
    }
    const isActive = selectedMentorSessionId === get(mentor, 'sessionId')
    let status = 'noStatus'
    if (isActive) {
      status = 'active'
    }
    if (menteeExistMessage !== '') {
      if (mentorFromSessionId !== get(mentor, 'id')) {
        status = 'inactive'
      }
    }
    let batchExistMessage = ''
    if (batchExistWithEmptyStudent) {
      batchExistMessage = `${get(mentor, 'name')} is assigned to batch ${get(batchExistWithEmptyStudent, 'batch.code')} with 0 students, but you can select and assign it anyway.`
    }
    return {
      status,
      menteeExistMessage,
      prevOrNextSlotExist,
      showMentor,
      batchExistWithEmptyStudent,
      batchExistMessage
    }
  }
  render() {
    const { mentorsList = [], isMentorLoading = false } = this.props
    const { searchMentor } = this.state
    const newMentorsList = mentorsList.filter(mentor =>
      this.getMentorBgAndStatus(mentor).showMentor && get(mentor, 'sessionId') &&
      get(mentor, 'name', '').toLowerCase().includes(searchMentor.toLowerCase()))
    return (
      <>
        <Styles.SearchInput
          suffix={<SearchOutlined />}
          value={searchMentor}
          onChange={({ target: { value } }) => this.setState({ searchMentor: value })}
          placeholder='Search for mentor'
        />
        <DemandCalendarWrapper.DemandSupplyTable
          dataSource={newMentorsList}
          loading={isMentorLoading}
          columns={this.getTableColumn()}
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
        {this.props.children}
      </>
    )
  }
}

export default AssignMentor
