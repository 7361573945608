import styled from 'styled-components'

const Container = styled.div`
    .anticon svg {
        display: inline-block;
        width: 25px;
        height: 25px;
        cursor: pointer;
    }
`

const RevisionSessionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 451px;
    > h3 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #333333;
        margin: 8px 0px;
        letter-spacing: 0.02em;
        :after {
            content:" *";
            color: #D34B57;
        }
    }
    > input {
        padding: 8px 12px;
        width: 100%;
        height: 42px;
        background: #FFFFFF;
        border: none;
        outline: none;
        border: 1px solid #AAAAAA;
        box-sizing: border-box;
    }
`

const TableContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 30px;
`

const TableRow = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 30px;
    width: 100%;
    border: 1px solid #eee;
    margin-bottom: 4px;
    > span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #212121;
        width: 20%;
    }
    > input {
        border: none;
        outline: none;
        border: 1px solid #AAAAAA;
        width: 35%;
        margin: 0 5%;
        padding: 4px 8px;
        border-radius: 2px;
    }
`

Container.RevisionSessionContainer = RevisionSessionContainer
Container.TableContainer = TableContainer
Container.TableRow = TableRow

export default Container