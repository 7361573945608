import gql from 'graphql-tag'
import { get } from 'lodash'
import duck from '../../duck'
import getIdArrForQuery from '../../utils/getIdArrForQuery'


const UpdateMentorProfile = async (id, schoolId, mentorProfileRowData, userConnectId) =>
  duck.query({
    query: gql`
    mutation {
        updateMentorProfile(
          id:"${id}"
          input: {
            scheduleManagement: {
              isSchedulingLearningClass: ${get(mentorProfileRowData, 'SchedulingLearningClass') === 0 ? true : false}
              isSchedulingAdhocClass: ${get(mentorProfileRowData, 'SchedulingAdHocClass') === 0 ? true : false}
              isReschedulingClasses: ${get(mentorProfileRowData, 'ReschedulingClass') === 0 ? true : false}
              isSchedulingTestClass: ${get(mentorProfileRowData, 'SchedulingClass') === 0 ? true : false}
              isDeleteSessions: ${get(mentorProfileRowData, 'DeleteSessions') === 0 ? true : false}
            }
            classroomControl: {
              isCreatingClass: ${get(mentorProfileRowData, 'CreatingAClassroom') === 0 ? true : false}
              isSendingNotice: ${get(mentorProfileRowData, 'SendNotices') === 0 ? true : false}
              shouldAddOrInviteStudent: ${get(mentorProfileRowData, 'AddInviteStudent') === 0 ? true : false}
              isAccessRecording: ${get(mentorProfileRowData, 'AccessRecordings') === 0 ? true : false}
              isAccessLiveSessionProgress: ${get(mentorProfileRowData, 'LiveSessionProgress') === 0 ? true : false}
            }
            courseControl: {
              shouldAccessCourse: ${get(mentorProfileRowData, 'AccessCourseControl') === 0 ? true : false}
              shouldCreateTest: ${get(mentorProfileRowData, 'CreateTests') === 0 ? true : false}
              shouldEvaluateTest: ${get(mentorProfileRowData, 'EvaluateTests') === 0 ? true : false}
              shouldAddToQuestionBank: ${get(mentorProfileRowData, 'AddToQBanks') === 0 ? true : false}
            }
            sessionReporting: {
              shouldAccessReports: ${get(mentorProfileRowData, 'AccessReports') === 0 ? true : false}
              shouldDownloadReports: ${get(mentorProfileRowData, 'DownloadReports') === 0 ? true : false}
              shouldShareReports: ${get(mentorProfileRowData, 'ShareReports') === 0 ? true : false}
            }
          accessType: ${get(mentorProfileRowData, 'AccessType')}
          }
          userConnectId: "${userConnectId}"
          schoolsConnectIds: ["${schoolId}"]
          ${(get(mentorProfileRowData, 'SelectGradesId', []) || []).length ? `schoolClassesConnectIds: [${get(mentorProfileRowData, 'SelectGradesId').map((id) => {
      return `"${id}"`
    })}]` : ''}
        ){
            id
        }
      }
    `,
    type: 'updateMentorProfile/update',
    key: 'updateMentorProfile',
  })

export default UpdateMentorProfile