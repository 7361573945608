import gql from 'graphql-tag'
import { get } from 'lodash'
import requestToGraphql from '../../utils/requestToGraphql'

const fetchLeadPartnerAgent = async (agentId) => {
  const data = await requestToGraphql(gql`{
  leadPartnerAgent(id: "${agentId}") {
    id
    utmDetails {
      id
    }
  }
}
`)
  return get(data, 'data.leadPartnerAgent.utmDetails', [])
}

export default fetchLeadPartnerAgent
