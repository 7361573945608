import gql from 'graphql-tag'
import { get } from 'lodash';
import duck from '../../duck';
import getIdArrForQuery from '../../utils/getIdArrForQuery';
import uploadFile from '../utils/uploadFile';
import removeFromEventAction from './removeFromEventAction';


const updateEvent = async (input, eventId, selectedSchoolsIds = [], fromSchoolUpdate = false) => {
    let updatedInput = {}
    if (input.summary && input.overview) {
        updatedInput.summary = input.summary
        updatedInput.overview = input.overview
    }
    if (input.utm) {
        updatedInput.utm = input.utm
    }
    updatedInput.isEmailCommsEnabled = get(input, 'isEmailCommsEnabled', false)
    if (fromSchoolUpdate) {
        updatedInput = { ...input }
    }
    return duck.query({
        query: gql`
           mutation updateEvent($input:EventUpdate) {
            updateEvent(input:$input,id:"${eventId.eventId}",
            ${get(input, 'eventJoinReasonsConnectIds', []).length > 0 ? `eventJoinReasonsConnectIds: [${getIdArrForQuery(input.eventJoinReasonsConnectIds)}]` : ''},
            ${selectedSchoolsIds.length > 0 ? `schoolsConnectIds: [${getIdArrForQuery(selectedSchoolsIds)}]` : ''},
            ${get(input, 'speakersConnectIds', []).length > 0 ? `speakersConnectIds: [${getIdArrForQuery(input.speakersConnectIds)}]` : ''},
            ${get(input, 'prizesConnectIds', []).length > 0 ? `prizesConnectIds: [${getIdArrForQuery(input.prizesConnectIds)}]` : ''},
            ${get(input, 'embedsConnectIds', []).length > 0 ? `embedsConnectIds: [${getIdArrForQuery(input.embedsConnectIds)}]` : ''}
            ) {
                  id
                  name
                  summary
                }
              }
              `,
        variables: {
            input: updatedInput
        },
        type: 'events/update',
        key: 'events',
        changeExtractedData: (extracted, original) => {
            const eventId = get(original, 'updateEvent.id')
            if (input.eventListingImage && typeof input.eventListingImage !== 'string') {
                (async function imageUpload() {
                    try {
                        if (get(input, 'prevImageId')) {
                            await removeFromEventAction(eventId, get(input, 'prevImageId'), '', 'listingImage')
                        }
                        await uploadFile(input.eventListingImage, {
                            fileBucket: 'python'
                        }, {
                            typeField: 'listingImage', typeId: eventId, type: 'Event',
                        })
                    } catch (error) {
                        console.log(error)
                    }
                }())
            }
            return { ...extracted }
        }
    })
}

export default updateEvent