import styled from 'styled-components'

const ClassComponentStyle = styled.div`
    height: 100%;
    width: 100%;
    margin: 0 auto;
    font-family: 'Inter' !important;
`

const Container = styled.div`
    padding-top: 80px;
    padding-left: 30px
`
const SubContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    border-radius: 10px
`
const ImageBorder = styled.div`
    width: 96px;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 16px;
`

const FlexBox1 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 15px;
`
const FlexBox2 = styled.div`
    display: flex;
    flex-direction: row;
`

const Completed = styled.div`
    font-size: 10px;
    color: black;
    background-color: #F5F5F5;
    margin-left: 15px;
`
const Title = styled.div`
    font-size: 18px;
    color: black;
    font-weight: 550;
    padding-top: 10px;
`
const Clock = styled.div`
    font-size: 12px;
    color: #757575;
    font-weight: 500;
    padding-top: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Dot = styled.div`
    height: 3px;
    width: 3px;
    border-radius: 50%;
    background-color: #757575;
    margin-right: 6px;
    margin-left: 6px;
`

const Divider = styled.div`
    border-bottom: 1px solid #EEEEEE;
    margin-right: 10%;
    padding-top: 5px;
    padding-bottom: 5px;
`

const Detail = styled.div`
    color: #424242;
    padding-top: 10px;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 500;
`
const FeedbackBox = styled.div`
    border: 1px solid rgb(142, 200, 248);
    padding: 5px;
    margin-right: 15%;
    border-radius: 6px;
    margin-left: 10px;
    margin-top: 5px;
`

ClassComponentStyle.Container = Container
ClassComponentStyle.SubContainer = SubContainer
ClassComponentStyle.ImageBorder = ImageBorder
ClassComponentStyle.FlexBox1 = FlexBox1
ClassComponentStyle.FlexBox2 = FlexBox2
ClassComponentStyle.Completed = Completed
ClassComponentStyle.Title = Title
ClassComponentStyle.Clock = Clock
ClassComponentStyle.Dot = Dot
ClassComponentStyle.Divider = Divider
ClassComponentStyle.Detail = Detail
ClassComponentStyle.FeedbackBox = FeedbackBox

export default ClassComponentStyle
