const PENDING = 'pending'
const ACCEPTED = 'accepted'
const REJECTED = 'rejected'

const USER_SAVED_CODE_STATUS = {
  PENDING,
  ACCEPTED,
  REJECTED,
}

export default USER_SAVED_CODE_STATUS
