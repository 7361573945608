
import gql from 'graphql-tag'
import { get } from 'lodash';
import duck from '../../duck';
import uploadFile from '../utils/uploadFile';
import getIdArrForQuery from '../../utils/getIdArrForQuery';
import moment from 'moment';

const addNewEvent = async (input) => {
    const {
        eventBasicInfo: { title, organiser, type, category, tags },
        eventLocation: { venue, online },
        locationType, startDate, endDate, selectedRange, timeZone, keyCount, date,
        eventMobileBanner
    } = input
    let { startTime, endTime } = input
    startTime = parseInt(moment(startTime).format('HH'))
    endTime = parseInt(moment(endTime).format('HH'))
    const slots = [{ slot0: false },
    { slot1: false },
    { slot2: false },
    { slot3: false },
    { slot4: false },
    { slot5: false },
    { slot6: false },
    { slot7: false },
    { slot8: false },
    { slot9: false },
    { slot10: false },
    { slot11: false },
    { slot12: false },
    { slot13: false },
    { slot14: false },
    { slot15: false },
    { slot16: false },
    { slot17: false },
    { slot18: false },
    { slot19: false },
    { slot20: false },
    { slot21: false },
    { slot22: false },
    { slot23: false },]

    for (let i = startTime; i <= endTime; i++) {
        if (endTime - startTime === 1) {
            if (i === startTime) {
                slots[i][`slot${startTime}`] = true
            }
        } else if (startTime === endTime) {
            for (let j = 0; j < slots.length; j++) {
                slots[j][`slot${j}`] = true
            }
        } else {
            for (let k = startTime; k < endTime; k++) {
                slots[k][`slot${k}`] = true
            }
        }
    }

    const eventTimeTableRule = {
        startDate: moment(startDate).startOf('day').toISOString(),
        endDate: moment(endDate).startOf('day').toISOString(),
    }
    for (let i = 0; i < slots.length; i++) {
        if (slots[i] && slots[i][`slot${i}`] === true) {
            eventTimeTableRule[`slot${i}`] = true
        }
    }
    const weekDaysMap = {
        S: 'sunday',
        M: 'monday',
        T: 'tuesday',
        W: 'wednesday',
        Th: 'thursday',
        F: 'friday',
        Sa: 'saturday'
    }
    for (let key in selectedRange[0]) {
        if (selectedRange[0][key]) {
            eventTimeTableRule[weekDaysMap[key]] = true
        }
    }
    const eventDetails = {
        name: title, type: type,
        dateType: date, organizer: organiser,
        locationType: locationType,
        geoLocation: venue.venueName,
        address: venue.address, city: venue.city,
        state: venue.state,
        pincode: (venue.pincode && parseInt(venue.pincode)) || 0,
        eventTimeTableRule: eventTimeTableRule, timeZone: timeZone,
        sessionLink: online.sessionLink, meetingId: online.meetingId,
        meetingPassword: online.meetingPassword, date: startDate
    }

    await duck.query({
        query: gql`
           mutation addEvent($input: EventInput!) {
                addEvent(input: $input ,categoryConnectId:"${category}", ${tags.length > 0 ? `tagsConnectIds: [${getIdArrForQuery(tags)}]` : ''}) {
                  id
                }
              }
              `,
        variables: {
            input: eventDetails
        },
        type: 'events/add',
        key: `events${keyCount}`,
        changeExtractedData: (extracted, original) => {
            const eventId = get(original, 'addEvent.id')
            if (input.imageFile) {
                (async function imageUpload() {
                    try {
                        await uploadFile(input.imageFile, { fileBucket: 'python' }, { typeField: 'eventBanner', typeId: eventId, type: 'Event', })
                    } catch (error) {
                        console.log(error)
                    }
                }())
            }
            if (eventMobileBanner) {
                (async function imageUpload() {
                    try {
                        await uploadFile(eventMobileBanner, { fileBucket: 'python' }, { typeField: 'eventMobileBanner', typeId: eventId, type: 'Event', })
                    } catch (error) {
                        console.log(error)
                    }
                }())
            }

            return { ...extracted }
        }
    })
}

export default addNewEvent
