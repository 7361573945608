import * as Yup from 'yup'

const stringRequired = Yup.string().trim().min(3, 'Minimum 3 characters').required('Required')
const phoneNumber = Yup.string().matches(/^[6-9]\d{9}$/, 'phoneNumber (Invalid format)').notRequired()
const email = Yup.string().email('Invalid Email').notRequired().nullable()
const password = Yup.string().required('password is required').min(6, 'Password should be atleast of 6 character.').nullable()
const confirmPassword = Yup.string().oneOf([Yup.ref('password'), null], 'passwords must match').nullable()

export const trainerAddFormValidation = Yup.object().shape({
  name:stringRequired,
  username: stringRequired,
  phoneNumber,
  email,
  password,
  confirmPassword,
})

export const trainerEditFormValidation = Yup.object().shape({
  name:stringRequired,
  username: stringRequired,
  phoneNumber,
  email,
  password: Yup.string().notRequired('password is required').min(6, 'Password should be atleast of 6 character.').nullable(),
  confirmPassword,
})
