import { notification } from 'antd'
import React from 'react'
import { get } from 'lodash'
import { CSVLink } from 'react-csv'
import excelDownload from '../../../../actions/excelDownload/excelDownload'
import createLoginLink from '../../../../actions/loginLink/createLoginLink'
import MainModal from '../../../../components/MainModal'
import convertToHours from '../../../../utils/convertToHours'
import {
  CloseIcon, FlexContainer, StyledDivider,
} from '../../SchoolOnBoarding.style'
import headerConfig from '../header'
import GenerateLinkEditForm from './GenerateLinkEditForm'
class GenerateLinkModal extends React.Component {
  state = {
    data: [],
    loading: false,
    emailLoading: false
  }
  downloadRef = React.createRef()
  onEmailCredentials = async (values) => {
    try {
      this.setState({ emailLoading: true })
      const { schoolId } = this.props
      const { duration, linkVisitLimit = 2 } = values
      const { type, grade, section } = values
      if (!type || !duration || !grade || !section) {
        this.setState({ emailLoading: false })
        return notification.warn({
          message: 'Please enter values'
        })
      }
      const newDuration = convertToHours(duration, type)
      let filterQuery;
      if (grade === 'ALL' && section === 'ALL')
        filterQuery = `input: {linkVisitLimit: ${linkVisitLimit || 2},schoolId: "${schoolId}", expiresIn: ${newDuration}}`
      else if (grade === 'ALL' && section !== 'ALL')
        filterQuery = `input: {linkVisitLimit: ${linkVisitLimit || 2},schoolId: "${schoolId}",section: ${section}, expiresIn: ${newDuration}}`
      else if (grade !== 'ALL' && section === 'ALL')
        filterQuery = `input: {linkVisitLimit: ${linkVisitLimit || 2},schoolId: "${schoolId}", grade: ${grade}, expiresIn: ${newDuration}}`
      else
        filterQuery = `input: {linkVisitLimit: ${linkVisitLimit || 2},schoolId: "${schoolId}", grade: ${grade}, section: ${section}, expiresIn: ${newDuration}}`

      const result = await createLoginLink(filterQuery, 1)
      if (result) {
        this.setState({ emailLoading: false })
        notification.success({
          message: 'Mail send successfully'
        })
      }
    } catch (err) {
      
    }
  }
  onDownloadExcel = async (values) => {
    try {
      this.setState({ loading: true})
      const { schoolId } = this.props
      const { grade, section, linkVisitLimit = 2, duration, type } = values
      if (!type || !duration || !grade || !section) {
        this.setState({ loading: false })
        return notification.warn({
          message: 'Please enter values'
        })
      }
      const newDuration = convertToHours(duration, type)
      let filterQuery;
      if (grade === 'ALL' && section === 'ALL')
        filterQuery = `input: {linkVisitLimit: ${linkVisitLimit || 2},schoolId: "${schoolId}", expiresIn: ${newDuration}}`
      else if (grade === 'ALL' && section !== 'ALL')
        filterQuery = `input: {linkVisitLimit: ${linkVisitLimit || 2},schoolId: "${schoolId}",section: ${section}, expiresIn: ${newDuration}, isDownloadExcel:true}`
      else if (grade !== 'ALL' && section === 'ALL')
        filterQuery = `input: {linkVisitLimit: ${linkVisitLimit || 2},schoolId: "${schoolId}", grade: ${grade}, expiresIn: ${newDuration}, isDownloadExcel:true}`
      else
        filterQuery = `input: {linkVisitLimit: ${linkVisitLimit || 2},schoolId: "${schoolId}", grade: ${grade}, section: ${section}, expiresIn: ${newDuration}, isDownloadExcel:true}`
      const result = await excelDownload(filterQuery, 1)
      const value = get(result, 'getMagicLink', []).map((data) => ({
        linkUri: get(data, 'linkUri'),
        parentName: get(data, 'user.studentProfile.parents[0].user.name'),
        email: get(data, 'user.studentProfile.parents[0].user.email'),
        phoneNumber: get(data, 'user.studentProfile.parents[0].user.phone.number')
          ? `${get(data, 'user.studentProfile.parents[0].user.phone.countryCode')} ${get(data, 'user.studentProfile.parents[0].user.phone.number')}` : '-',
        studentSection: get(data, 'user.studentProfile.section'),
        studentName: get(data, 'user.name'),
        studentGrade: get(data, 'user.studentProfile.grade'),
        createdAt: new Date()
      }))
      this.setState({ data: value, loading: false }, () => setTimeout(() => {
        this.downloadRef.current.link.click()
      }))
    } catch (err) {
      
    }
  }
  render() {
    const {
      visible, title,
      schoolName,
      parentSignUpStatus,
      editStudent,
      onClose,
      classGrades
    } = this.props
    return (
      <MainModal
        visible={visible}
        onCancel={onClose}
        maskClosable
        bodyStyle={{ padding: 0 }}
        width='650px'
        closable={false}
        centered
        destroyOnClose
        footer={null}
      >
        <CSVLink
          style={{ display: 'none' }}
          filename='Link_Data.csv'
          data={this.state.data}
          headers={headerConfig}
          ref={this.downloadRef}
        />
        <FlexContainer noPadding style={{ width: '100%' }}>
          <div style={{ padding: '0.5vw 1.5vw' }}>
            <h1>{title}</h1>
            <h3>
              {schoolName}
            </h3>
            <CloseIcon onClick={onClose} />
          </div>
        </FlexContainer>
        <StyledDivider style={{ marginBottom: '1vw' }} />
        <FlexContainer
          justify='center'
          style={{ flexDirection: 'column', paddingBottom: '1vw' }}
        >
          <GenerateLinkEditForm
            loadingStatus={this.state.loading}
            editStudent={editStudent}
            emailLoading={this.state.emailLoading}
            onModalClose={onClose}
            onEmailCredentials={this.onEmailCredentials}
            onDownloadExcel={this.onDownloadExcel}
            classGrades={classGrades}
          />
        </FlexContainer>
      </MainModal>
    )
  }
}

export default GenerateLinkModal
