import gql from "graphql-tag"
import { get } from "lodash"
import { SCHOOL_TRAINER } from "../../constants/roles"
import requestToGraphql from "../../utils/requestToGraphql"

const fetchInternalMentors = async () => {
    const mentors = await requestToGraphql(gql`{
  users(
    filter: {
      and: [
        { role: mentor }
        { roles_not_in: [${SCHOOL_TRAINER}] }
        { mentorProfile_some: { teacherOrganisation: internal } }
      ]
    }
    orderBy: createdAt_DESC
  ) {
    id
    role
    name
    secondaryRole
    roles
  }
}`)
    return get(mentors, 'data.users', [])
}

export default fetchInternalMentors;