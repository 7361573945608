import gql from 'graphql-tag'
import duck from '../../duck'

const removeLinksFromMentorAvailabilitySlots = async (mentorAvailabilitySlotId, linkedId, type) =>
  duck.query({
    query: gql`
      mutation {
        ${type === 'school' ? `removeFromMentorAvailabilitySlotSchool(
            mentorAvailabilitySlotId: "${mentorAvailabilitySlotId}"
            schoolId: "${linkedId}"
        ) {
            mentorAvailabilitySlot {
            id
            }
        }` : ''}
        ${type === 'mentor' ? `removeFromMentorAvailabilitySlotMentor(
            mentorAvailabilitySlotId: "${mentorAvailabilitySlotId}"
            mentorProfileId: "${linkedId}"
        ) {
            mentorAvailabilitySlot {
            id
            }
        }` : ''}
        ${type === 'campaign' ? `removeFromMentorAvailabilitySlotCampaign(
            mentorAvailabilitySlotId: "${mentorAvailabilitySlotId}"
            campaignId: "${linkedId}"
        ) {
            mentorAvailabilitySlot {
            id
            }
        }` : ''}
        ${type === 'paySlab' ? `removeFromMentorAvailabilitySlotPaySlab(
            mentorAvailabilitySlotId: "${mentorAvailabilitySlotId}"
            mentorSupplyPaySlabId: "${linkedId}"
        ) {
            mentorAvailabilitySlot {
            id
            }
        }` : ''}
    }
    `,
    type: 'mentorAvailabilitySlots/delete',
    key: 'mentorAvailabilitySlots',
  })

export default removeLinksFromMentorAvailabilitySlots

