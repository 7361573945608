import gql from "graphql-tag";
import duck from "../../../duck";
import getIdArrForQuery from "../../../utils/getIdArrForQuery";

const addLearningSlideContent = async (input, learningSlideIds = []) => {
  if (learningSlideIds.length > 0) {
    return duck.query({
      query: gql`
    mutation($input: LearningSlideContentInput!) {
      addLearningSlideContent(input: $input, ${
        learningSlideIds.length > 0
          ? `learningSlidesConnectIds: [${getIdArrForQuery(learningSlideIds)}]`
          : ""
      }) {
        id
    }
    }
    `,
      variables: {
        input,
      },
      type: "addLearningSlideContent/add",
      key: "addLearningSlideContent",
    });
  }
};

export default addLearningSlideContent;
